import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { GlgRoundedButtonIcon, GlgTooltip } from '../../../shared/components'

class RoundTournaments extends Component {
  constructor(props){
    super(props)
  }

  stringParameterize(str) {
    return str.trim().toLowerCase()
      .replace(/[^a-zA-Z0-9 -]/g, '')
      .replace(/ +/g, ' ')
      .replace(/[\s-]/g, '_')
  }

  render() {
    const { title, links, notDcp, hasDivisions, tournaments, linkedTournaments, multiRoundTournaments } = this.props.content // , userScoredTournaments

    return (
        <div className='widget-content-default widget-content-round-tournaments'>
          <div className='title'>
            { title }

            <a href={ links.manage_tournaments.path } className='btn btn-primary widget-white-title-button'>
              { links.manage_tournaments.name }
            </a>
          </div>
          <div className='content'>
            {
              links.add_multi_round_tournaments.permission && links.add_multi_round_tournaments.enabled
                ? <GlgRoundedButtonIcon text={ links.add_multi_round_tournaments.name }
                                        url={ links.add_multi_round_tournaments.path }
                                        icon={ links.add_multi_round_tournaments.icon }
                                        anchorClass='new_spec'
                                        />
                : null
            }
            {
              links.tournament_library.permission && links.tournament_library.enabled && notDcp
                ? <GlgRoundedButtonIcon text={ links.tournament_library.name }
                                        url={ links.tournament_library.path }
                                        icon={ links.tournament_library.icon }
                                        anchorClass='new_spec pull-right'
                                        />
                : null
            }
            <table className='table table-striped'>
              <thead>
                <tr className='header'>
                  <th>{ tournaments.header.name }</th>
                  { hasDivisions ? <th>{ tournaments.header.divisions }</th> : null }
                  <th>{ tournaments.header.format }</th>
                  <th>{ tournaments.header.competitions }</th>
                  <th>{ tournaments.header.balls }</th>
                  <th>{ notDcp ? tournaments.header.handicap : tournaments.header.skill }</th>
                  <th>{ notDcp ? tournaments.header.purse : ' ' }</th>
                  <th>{ notDcp ? tournaments.header.points : ' ' }</th>
                </tr>
              </thead>
              <tbody>
                {
                  tournaments.data.length === 0
                    ? <tr className='no-tournaments'>
                        <td className='.center.notice' colSpan={ tournaments.hasDivisions ? '8' : '7' }>{ tournaments.missing_text }</td>
                    </tr>
                    : tournaments.data.map( rowData =>
                      <tr key={ rowData.id } id={ `tourney_${rowData.id}` }>
                        <td className='nametext'>
                          { rowData.name.display_icon ? <i className='gga-cart'></i> : null }
                          { rowData.name.default_icon ? 
                            <img
                              className={`${rowData.name.default_icon === undefined ? 'hidden' : 'align-icon'}`}
                              src={ rowData.name.default_icon }
                              title={`${rowData.name.default_icon_tooltip}`}
                            /> : null }
                          {
                            rowData.name.disabled
                             ? <span title={ rowData.name.tooltip } className='title-disabled'>{ rowData.name.text }</span>
                             : <a href={ rowData.name.url } >
                                { rowData.name.text }
                              </a>
                          }
                        </td>
                        {
                          rowData.divisions.display
                          ? <td className='division'>
                            <span dangerouslySetInnerHTML={{__html: rowData.divisions.text }} />
                            { rowData.divisions.display_icon
                            ? <GlgTooltip tooltip={ rowData.divisions !== undefined ? rowData.divisions.icon_tooltip : '' } placement='top' >
                              <i className='gga-info-circle'></i>
                            </GlgTooltip> : null }
                          </td>
                          : null
                        }
                        <td className='format'>{ !window.I18n ? '' : window.I18n.t(`models.level.nodes.format.${this.stringParameterize(rowData.format)}`, {defaultValue: rowData.format}) }</td>
                        <td className='competitions'>{ !window.I18n ? '' : window.I18n.t(`models.level.nodes.competition.${this.stringParameterize(rowData.competitions)}`, {defaultValue: rowData.competitions}) }</td>
                        <td className='balls'>{ !window.I18n ? '' : window.I18n.t(`models.level.nodes.balls.${this.stringParameterize(rowData.balls)}`, {defaultValue: rowData.balls}) }</td>
                        <td className='handicap'>{ !window.I18n ? '' : window.I18n.t(`models.level.nodes.handicap.${this.stringParameterize(rowData.handicap)}`, {defaultValue: rowData.handicap}) }</td>
                        <td className='purse'>{ rowData.purse ? <i className='fa fa-check'></i> : null }</td>
                        <td className='points'>{ rowData.points ? <i className='fa fa-check'></i> : null }</td>
                      </tr>)
                }
              </tbody>
            </table>

            {
              links.add_linked_tournaments.permission && links.add_linked_tournaments.enabled
                ? <GlgTooltip tooltip={ links.add_linked_tournaments.tooltip } placement='right' >
                  <span className='intermediate-container'>
                    <GlgRoundedButtonIcon text={ links.add_linked_tournaments.name }
                                          url={ links.add_linked_tournaments.path }
                                          icon={ links.add_linked_tournaments.icon }
                                          anchorClass='new_spec'
                                          />
                  </span>
                </GlgTooltip>
                : null
            }
            {
              notDcp && linkedTournaments.show
                ? <table className='table table-striped'>
                  <thead>
                    <tr className='header'>
                      <th className='description'>{ linkedTournaments.header.name }</th>
                      <th>{ linkedTournaments.header.type }</th>
                      <th className='format'>{ linkedTournaments.header.format }</th>
                      {/* <th className='actions'>{ linkedTournaments.header.actions }</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      linkedTournaments.data.length === 0
                        ? <tr className='no-tournaments'>
                          <td colSpan='3'>{ linkedTournaments.missing_text }</td>
                        </tr>
                        : linkedTournaments.data.map( rowData =>
                          <tr key={ rowData.id } id={ `linked_tournament_${rowData.id}` }>
                            <td className='nametext'>
                              <a href={ rowData.name.disabled ? 'javascript:void(0)' : rowData.name.url }
                                 title={ rowData.name.tooltip }
                                 className={ rowData.name.disabled ? 'link-type-disabled' : '' }
                                 target={ rowData.name.disabled ? '_self' : '_blank'}>
                                { rowData.name.text }
                              </a>
                            </td>
                            <td>{ rowData.type }</td>
                            <td className='handicap'>{ rowData.format }</td>
                          </tr>)
                    }
                  </tbody>
                </table>
                : null
            }


            {/* {
              links.add_user_scored_tournaments.permission && links.add_user_scored_tournaments.enabled
                ? <GlgRoundedButtonIcon text={ links.add_user_scored_tournaments.name }
                                        url={ links.add_user_scored_tournaments.path }
                                        icon={ links.add_user_scored_tournaments.icon }
                                        anchorClass='new_spec'
                                        />
                : null
            }
            {
              links.add_user_scored_tournaments.permission
                ? <table className='table table-striped'>
                  <thead>
                    <tr className='header'>
                      <th>{ userScoredTournaments.header.name }</th>
                      <th>{ userScoredTournaments.header.scoredBy }</th>
                    <th className='sort' dangerouslySetInnerHTML={{__html: userScoredTournaments.header.actions }} />
                    </tr>
                  </thead>
                  <tbody>
                    {
                      userScoredTournaments.data.length === 0
                        ? <tr className='no-tournaments'>
                            <td className='.center.notice' colSpan='3'>{ userScoredTournaments.missing_text }</td>
                        </tr>
                        : userScoredTournaments.data.map( rowData =>
                          <tr key={ rowData.id } id={ `linked_tournament_${rowData.id}` }>
                            <td className='nametext'>
                              <p className={ rowData.name.url !== '#' ? 'link-type' : '' }>{ rowData.name.text }</p>
                            </td>
                            <td>{ rowData.scored_by }</td>
                            <td></td>
                          </tr>)
                    }
                  </tbody>
                </table>
                : null
            } */}

            {
              multiRoundTournaments.data.length !== 0
                ? <Fragment>
                  <div className='page_title add-margin-top-20'>
                    <div className='text'>
                      { multiRoundTournaments.title }
                    </div>
                  </div>

                  <table className='table table-striped'>
                    <thead>
                      <tr className='header'>
                        <th>{ multiRoundTournaments.header.name }</th>
                        { hasDivisions ? <th>{ multiRoundTournaments.header.divisions }</th> : null}
                        <th>{ multiRoundTournaments.header.format }</th>
                        <th>{ multiRoundTournaments.header.competitions }</th>
                        <th>{ multiRoundTournaments.header.balls }</th>
                        <th>{ multiRoundTournaments.header.handicap }</th>
                        <th>{ multiRoundTournaments.header.purse }</th>
                        <th>{ multiRoundTournaments.header.points }</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        multiRoundTournaments.data.length === 0
                        ? <tr className='no-tournaments'>
                            <td className='.center.notice' colSpan='8'>{ multiRoundTournaments.missing_text }</td>
                        </tr>
                        : multiRoundTournaments.data.map( rowData =>
                          <tr key={ rowData.id } id={ `tourney_${rowData.id}` }>
                            <td className='nametext'>
                              { rowData.name.display_icon ? <i className='gga-cart'></i> : null }
                              { rowData.name.default_icon ? 
                                <img
                                  className={`${rowData.name.default_icon === undefined ? 'hidden' : 'align-icon'}`}
                                  src={ rowData.name.default_icon }
                                  title={`${rowData.name.default_icon_tooltip}`}
                                /> : null }
                              {
                                rowData.name.disabled
                                ? <span title={ rowData.name.tooltip } className='title-disabled'>{ rowData.name.text }</span>
                                : <a href={ rowData.name.url }>{ rowData.name.text }</a>
                              }
                            </td>
                            {
                              rowData.divisions.display
                              ? <td className='division'>
                                <span dangerouslySetInnerHTML={{__html: rowData.divisions.text }} />
                                { rowData.divisions.display_icon
                                ? <GlgTooltip tooltip={ rowData.divisions !== undefined ? rowData.divisions.icon_tooltip : '' } placement='top' >
                                  <i className='gga-info-circle'></i>
                                </GlgTooltip> : null }
                              </td>
                              : null
                            }
                            <td className='format'>{ !window.I18n ? '' : window.I18n.t(`models.level.nodes.format.${this.stringParameterize(rowData.format)}`, {defaultValue: rowData.format}) }</td>
                            <td className='competitions'>{ !window.I18n ? '' : window.I18n.t(`models.level.nodes.competition.${this.stringParameterize(rowData.competitions)}`, {defaultValue: rowData.competitions}) }</td>
                            <td className='balls'>{ !window.I18n ? '' : window.I18n.t(`models.level.nodes.balls.${this.stringParameterize(rowData.balls)}`, {defaultValue: rowData.balls}) }</td>
                            <td className='handicap'>{ !window.I18n ? '' : window.I18n.t(`models.level.nodes.handicap.${this.stringParameterize(rowData.handicap)}`, {defaultValue: rowData.handicap}) }</td>
                            <td className='purse'>{ rowData.purse ? <i className='fa fa-check'></i> : null }</td>
                            <td className='points'>{ rowData.points ? <i className='fa fa-check'></i> : null }</td>
                          </tr>)
                      }
                    </tbody>
                  </table>
                </Fragment>
              : null
            }

          </div>
        </div>
    )
  }
}

RoundTournaments.propTypes = { 
  content: PropTypes.object,
}

RoundTournaments.defaultProps = {
  content: {
    title: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.round_tournaments.title'),
  },
}

export default RoundTournaments
