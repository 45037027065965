import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import TeamGolferPick from './team_golfer_pick_component'
import LeaveDraftPopup from './leave_draft_popup'


const useInterval = (callback, delay) => {
  const intervalRef = useRef()
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [ callback ])

  useEffect(() => {
    if (typeof delay === 'number') {
      intervalRef.current = setInterval(() => callbackRef.current(), delay)
      return () => clearInterval(intervalRef.current)
    }
  }, [ delay ])

  return intervalRef
}

const NowPickingArea = ({nowPicking, pickTime, draftGolfer, picks, draftsIndexUrl, saveInProgress, removeFromNowPicking}) => {

  const timerInitialState = {min: Math.floor(pickTime / 60), sec: pickTime % 60}
  const [ leaveDraftPopupOpen, setLeaveDraftPopupOpen ] = useState(false)
  const [ currentPickTimer, setCurrentPickTimer ] = useState(timerInitialState)
  const [ timerPaused, setTimerPaused ] = useState(false)
  const [ timeout, setTimeout ] = useState(false)

  useInterval(() => {
    const t = currentPickTimer.min * 60 + currentPickTimer.sec - 1
    const min = Math.floor(t / 60)
    const sec = t % 60

    if (t < 0) {
      setTimeout(true)
      const children = $('.min_sec').children()
      for (let i = 0; i < children.length; i++) {
        children[i].classList.add('blinking_text')
      }
      $('.timer i')[0].classList.add('disabled')
    } else {
      setCurrentPickTimer({min: min, sec: sec})
    }
  }, timerPaused || timeout ? null : 1000)

  useEffect(() => {
    setCurrentPickTimer(timerInitialState)
    setTimerPaused(false)
    setTimeout(false)
    const children = $('.min_sec').children()
    for (let i = 0; i < children.length; i++) {
      children[i].classList.remove('blinking_text')
    }
    $('.timer i')[0].classList.remove('disabled')
  }, [ picks ])

  return (
    <div className="now_picking_area">
      <a className="leave_draft" href="#" onClick={() => setLeaveDraftPopupOpen(true)}>
        {window.I18n.t('draft.components.manager_side.now_picking_area.leave_draft')}</a>
      <div className="picking_area_title">
        <div className="pick_text">{window.I18n.t('draft.components.manager_side.now_picking_area.now_picking')}</div>
        <div className="timer">
          <div className="min_sec">
            <div>{currentPickTimer.min.toString().padStart(2, '0')}</div>
            <p>:</p>
            <div>{currentPickTimer.sec.toString().padStart(2, '0')}</div>
          </div>
          <div><i className={`fa fa-solid fa-${timerPaused ? 'play' : 'pause'}`}
                  onClick={(e) => !e.target.classList.contains('disabled') && setTimerPaused(!timerPaused)}></i>
          </div>
        </div>
        <button type="button" disabled={!nowPicking?.golfer || saveInProgress} className="btsp_orange_button button_right"
                onClick={draftGolfer}>{window.I18n.t('draft.components.manager_side.now_picking_area.draft')}
        </button>
      </div>
      <TeamGolferPick type={'now'} pick={nowPicking} saveInProgress={saveInProgress} removeFromNowPicking={removeFromNowPicking}/>
      <LeaveDraftPopup open={leaveDraftPopupOpen} draftsIndexUrl={draftsIndexUrl} saveInProgress={saveInProgress}/>
    </div>
  )
}

NowPickingArea.propTypes = {
  nowPicking: PropTypes.any,
  pickTime: PropTypes.number,
  draftGolfer: PropTypes.func,
  draftsIndexUrl: PropTypes.string,
  saveInProgress: PropTypes.bool,
  picks: PropTypes.array,
  removeFromNowPicking: PropTypes.func,
}

export default NowPickingArea
