import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GlgPopup from '../../../shared/components/glg_popup'
import GlgTable from '../../../shared/components/glg_table'
//import {Col, Row} from 'react-bootstrap'

import MoneyEarnedInformationTableRow from './money_earned_information_table_row'

import {
  GlgLoadingSpinner,
  GlgTableCol,
} from 'SharedComponents'

class MoneyEarnedInformationComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let table = ''
    if (this.props.tournaments && this.props.tournaments.length > 0) {
      const extraProps = {}
      table = <GlgTable items={ this.props.tournaments } rowComponent={ MoneyEarnedInformationTableRow } rowExtraProps={ extraProps } enableHorizontalScroll={ false }><GlgTableCol width={ '40%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.tournament') }></GlgTableCol><GlgTableCol width={ '10%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.pos') }></GlgTableCol><GlgTableCol width={ '15%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.score') }></GlgTableCol><GlgTableCol width={ '10%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.to_par') }></GlgTableCol><GlgTableCol width={ '20%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.earnings') }></GlgTableCol></GlgTable>
    } else {
      if (!this.props.dataIsLoading) {
        table = !window.I18n ? '' : window.I18n.t('player_activity.participation_history.no_info_available')
      }
    }

    const innerData = <div><GlgLoadingSpinner show={ this.props.dataIsLoading }></GlgLoadingSpinner>{ table }</div>

    const popup = <GlgPopup show={ this.props.showPopup } showBackdrop={ false } title={ this.props.eventTitle } showSaveButton={ false } onClose={ this.props.onClosePopup } modalClass={ 'money_earned_modal' } size={ 'xl' }>{ innerData }</GlgPopup>

    return popup
  }
}

MoneyEarnedInformationComponent.propTypes = {
  //Checks if the data is loading (for the spinner)
  dataIsLoading: PropTypes.bool,
  //Show the popup or not
  showPopup: PropTypes.bool.isRequired,
  //On close of the popup
  onClosePopup: PropTypes.func.isRequired,
  //Event
  eventTitle: PropTypes.string,
  //Tournaments
  tournaments: PropTypes.array,
}

export default MoneyEarnedInformationComponent
