import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loader from 'react-loader-spinner'

export class MembersToggle extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <div id={this.props.dropdownId}><span className="glg-loading-spinner">{!this.props.filterOptionsSet ? <Loader type="ThreeDots" color={ '#0088CE' } height={ 15 } width={ 30 }></Loader> : !window.I18n ? '' : window.I18n.t('members_page.components.members_filter.members_toogle.include')}</span><span className="dropdown_button_icon pull-right"><i className="gga-carret-down"></i></span></div>
  }
}

MembersToggle.propTypes = {
  dropdownId: PropTypes.string.isRequired,
  filterOptionsSet: PropTypes.bool.isRequired,
}

MembersToggle.defaultProps = {
  dropdownId: 'show_more_members',
}

export default MembersToggle
