import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const popupTeamRow = ({scoreData, isMultiRound, matchedScores}) => {

  const scores = [ ...scoreData.scores ]

  return (
    <tr className='scores_row'>
        <td className='with_border'>Team Score</td>
        { [ ...Array(9) ].map( ( _, i) => (
            <Fragment key={i}>
                <td key={'hole-' + i} className={matchedScores[i] ? 'with_border' : 'with_border yellow_column'}>{scores[i]}</td>
                { (i + 1) % 3 === 0 && i !== 8 && <td className="with_border gray_column"></td> }
            </Fragment>
        )) }
        <td className='with_border blue_column'>{scoreData.out}</td>
        { [ ...Array(9) ].map( ( _, i) => (
            <Fragment key={i}>
                <td key={'hole-' + i} className={matchedScores[i + 9] ? 'with_border' : 'with_border yellow_column'}>{scores[i + 9]}</td>
                { (i + 1) % 3 === 0 && i !== 8 && <td className="with_border gray_column"></td> }
            </Fragment>
        )) }
        <td className='with_border blue_column'>{scoreData.in}</td>
        <td className='with_border blue_column red'>{scoreData.total_score !== 0 ? scoreData.round_score : ''}</td>
        { isMultiRound && <td className='with_border blue_column red'>{scoreData.total_score !== 0 ? scoreData.total_score : ''}</td> }
    </tr>
  )
}

popupTeamRow.propTypes = {
  scoreData: PropTypes.object,
  isMultiRound: PropTypes.bool,
  matchedScores: PropTypes.array,
}

export default popupTeamRow
