import { ActionTypes } from '../actions'

const {
  TOGGLE_MENU,
  LOAD_WIDGETS,
  SELECT_MENU_ITEM,
  TOGGLE_NEW_ITEMS_MODAL,
  GET_NEW_ITEMS_LINKS,
  NEW_ITEMS_MODAL_SEARCH,
  NEW_ITEMS_SELECT_ROUND,
} = ActionTypes

const initialState = {
  mobileVisibility: false,
  selectedMenuItem: null,
  menuItems: [],
}

//* REDUX - Reducer
const menu = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        mobileVisibility: !state.mobileVisibility,
      }

    case LOAD_WIDGETS:
      return {
        ...state,
        test: true,
      }

    case SELECT_MENU_ITEM:
      return {
        ...state,
        selectedMenuItem: action.id,
      }

    case TOGGLE_NEW_ITEMS_MODAL:
      return {
        ...state,
        menuItems: {
          ...state.menuItems,
          newItems: {
            ...state.menuItems.newItems,
            visible: action.visible,
          },
        },
      }

    case GET_NEW_ITEMS_LINKS:
      return {
        ...state,
        menuItems: {
          ...state.menuItems,
          newItems: {
            ...state.menuItems.newItems,
            leagueLinks: action.data.leagueLinks,
            roundLinks: action.data.roundLinks,
            selectedRound: action.data.selectedRound,
          },
        },
      }

    case NEW_ITEMS_SELECT_ROUND:
      return {
        ...state,
        menuItems: {
          ...state.menuItems,
          newItems: {
            ...state.menuItems.newItems,
            selectedRound: action.selectedRound,
          },
        },
      }

    case NEW_ITEMS_MODAL_SEARCH:
      return {
        ...state,
        menuItems: {
          ...state.menuItems,
          newItems: {
            ...state.menuItems.newItems,
            searchTerm: {
              ...state.menuItems.newItems.searchTerm,
              value: action.searchTerm,
            },
          },
        },
      }

    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default menu
