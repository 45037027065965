import { ActionTypes } from '../actions'
import _ from 'lodash'
import Cookies from 'js-cookie'

const {
  FETCH_PARTICIPATION_HISTORY_DATA_REQUEST,
  FETCH_PARTICIPATION_HISTORY_DATA_RECEIVED,
  FETCH_MONEY_EARNED_INFORMATION_DATA_REQUEST,
  FETCH_MONEY_EARNED_INFORMATION_DATA_RECEIVED,
  TOGGLE_MONEY_EARNED_INFORMATION_POPUP,
  CHANGE_PARTICIPATION_HISTORY_FILTER,
  DELETE_CHECK_IN_RESPONSE,
  TOGGLE_LEADERBOARD_POPUP,
  FETCH_LEADERBOARDS_DATA_RECEIVED,
  FETCH_LEADERBOARDS_DATA_REQUEST,
  VIEW_CHANGE_OPTION,
  PAGE_CHANGED,
} = ActionTypes


function pageChangedState(action, state){
  const newState = _.clone(state)
  if (action.entity === 'round') {
    if (action.period === 'future') {
      newState.pageFutureRounds = action.page
    } else if (action.period === 'past') {
      newState.pagePastRounds = action.page
    }
  } else if (action.entity === 'event') {
    if (action.period === 'future') {
      newState.pageFutureEvents = action.page
    } else if (action.period === 'past') {
      newState.pagePastEvents = action.page
    }
  }
  return newState
}

const participationHistory = (state = {}, action) => {
  let newState = {}
  let loadedEventsIds

  switch (action.type) {
    case FETCH_PARTICIPATION_HISTORY_DATA_REQUEST:
      return {
        ...state,
        dataIsLoading: true,
      }
    case FETCH_PARTICIPATION_HISTORY_DATA_RECEIVED:
      if ('futureRounds' in action.data){
        return {
          ...state,
          ...action.data,
          dataIsLoading: false,
          loadedRoundsData: true,
        }
      } else {
        return {
          ...state,
          ...action.data,
          dataIsLoading: false,
          loadedEventsData: true,
        }
      }
    case CHANGE_PARTICIPATION_HISTORY_FILTER:
      return {
        ...state,
        seasonId: action.value,
        pageFutureRounds: 1,
        pagePastRounds: 1,
        pageFutureEvents: 1,
        pagePastEvents: 1,
        loadedEventsData: false,
        loadedRoundsData: false,
      }
    case FETCH_MONEY_EARNED_INFORMATION_DATA_REQUEST:
      newState = _.clone(state)
      if (!newState.areLoadingEventsIds.some(eventId => eventId === action.eventId)) {
        newState.areLoadingEventsIds.push(action.eventId)
      }
      return {
        ...newState,
      }
    case FETCH_MONEY_EARNED_INFORMATION_DATA_RECEIVED:
      newState = _.clone(state)
      newState.loadedEvents = {
        ...state.loadedEvents,
        ...action.data,
      }
      loadedEventsIds = Object.keys(newState.loadedEvents)
      newState.areLoadingEventsIds = newState.areLoadingEventsIds.filter(eventId => {
        const value = loadedEventsIds.every(loadedEventId => loadedEventId !== eventId)
        return value
      })
      return {
        ...newState,
      }
    case TOGGLE_MONEY_EARNED_INFORMATION_POPUP:
      return {
        ...state,
        isOpenedEventPopup: action.shown,
        currentlyOpenedEventPopup: action.eventId,
      }
    case DELETE_CHECK_IN_RESPONSE:
      newState = _.clone(state)
      newState.checkInList = state.checkInList.filter(checkin => String(checkin.id) !== `${action.data.checkInId}`)
      newState.checkInsCount = newState.checkInList.length
      return {
        ...newState,
      }
    case TOGGLE_LEADERBOARD_POPUP:
      return {
        ...state,
        isOpenedLeaderboardPopup: action.shown,
        currentlyRoundLeaderboardPopup: action.roundId,
      }
    case FETCH_LEADERBOARDS_DATA_REQUEST:
      return {
        ...state,
        loadingLeaderboards: true,
      }
    case FETCH_LEADERBOARDS_DATA_RECEIVED:
      return {
        ...state,
        loadingLeaderboards: false,
      }
    case VIEW_CHANGE_OPTION:
      Cookies.set('view_option', action.option)
      return {
        ...state,
        viewOption: action.option,
      }
    case PAGE_CHANGED:
      return {
        ...pageChangedState(action, state),
      }
    default:
      return state
  }
}

export default participationHistory
