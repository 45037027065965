import _ from 'lodash'

import { ActionTypes } from '../actions'

const {
  FETCH_INITIAL_DATA_RECEIVED,
  FETCH_ALL_DATA_RECEIVED,
  ADD_TO_CART_RESPONSE,
} = ActionTypes

const leagues = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INITIAL_DATA_RECEIVED: {
      const receivedLeagues = _.mapValues(action.data.leagues, league => ({
        ...state[league.id],
        ...league,
      }))
      return {
        ...state,
        ...receivedLeagues,
      }
    }
    case FETCH_ALL_DATA_RECEIVED: {
      const receivedLeagues = _.mapValues(action.data.leagues, league => ({
        ...state[league.id],
        ...league,
      }))
      return {
        ...state,
        ...receivedLeagues,
      }
    }
    case ADD_TO_CART_RESPONSE: {
      const receivedLeagues = _.mapValues(_.keyBy(action.data.leagues, 'id'), league => ({
        ...state[league.id], 
        registrationInfo: {
          ...state[league.id].registrationInfo,
          eligibility_message: league.registrationInfo,
        },
        condition3: league.condition3,
      }))
      return {
        ...state,
        ...receivedLeagues,
        [action.data.product_id]: {
          ...state[action.data.product_id],
          cartCondition: true,
          cartConditionWithInclude: true,
          hasCartship: true,
        },
      }
    }
    default:
      return state
  }
}

export default leagues
