import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { mapToSelectArray } from 'Shared/helpers'

import PopupTypes from '../../popup_types'
import {
  GlgPopup,
  GlgSelect,
  GlgPopupForm,
  GlgFormInput,
} from 'SharedComponents'

class ChangeSeasonPopup extends Component {
  constructor(props) {
    super(props)
    this.onClose = props.onClose.bind(this, PopupTypes.CHANGE_SEASON)
    this.onSave = this.onSave.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    return this.props.show !== nextProps.show
  }

  onSave(data) {
    const propsData = this.props.data
    this.props.onSave(propsData.leagueId,
                      data.to_season_id)
  }

  getLeagueName() {
    if (this.props.data) {
      return this.props.data.leagueName || ''
    } else {
      return ''
    }
  }

  getCurrentSeason() {
    if (this.props.data) {
      return this.props.data.seasonId || ''
    } else {
      return ''
    }
  }

  render() {
    const seasonsSelectOptions = mapToSelectArray(this.props.seasonsOptions)

    return <GlgPopup title={ `Select new season for ${ this.getLeagueName() }` } show={ this.props.show } onClose={ this.onClose } onSave={ this.onSave }><GlgPopupForm><GlgFormInput label="Season"><GlgSelect name="to_season_id" items={ seasonsSelectOptions } defaultValue={ this.getCurrentSeason() }></GlgSelect></GlgFormInput></GlgPopupForm></GlgPopup>
  }
}

ChangeSeasonPopup.propTypes = {
  data: PropTypes.object,
  show: PropTypes.bool.isRequired,
  seasonsOptions: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default ChangeSeasonPopup
