import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import { DISPLAY_MODES } from '../../actions'
import EditTabs from '../containers/edit_tabs'
import DisplayTabs from '../containers/display_tabs'
import AddBlock from '../containers/add_block'
import DisplayBlocks from '../containers/display_blocks'
import CreateBlock from '../containers/create_block'
import ManageTabs from '../containers/manage_tabs'
import ManageColumns from '../containers/manage_columns'
import CreateTab from '../containers/create_tab'
import ManageWidget from '../containers/manage_widgets'
import NewPageCreate from '../containers/new_page_create'
import EditBlock from '../containers/edit_block'
import ManageColumnProps from '../containers/manage_column_props'
import ReorderBlocks from '../containers/reoreder_blocks'
import BlocksReorderButton from '../containers/blocks_reorder_button'
import Popup from '../presentational/popup'

class Page extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let render
    switch (this.props.displayMode) {
      case DISPLAY_MODES.BLOCK: {
        render = <Row>
          <CreateBlock />
        </Row>
        break
      }
      case DISPLAY_MODES.EDIT_BLOCK: {
        render = <Row>
          <EditBlock />
        </Row>
        break
      }
      case DISPLAY_MODES.COLUMN: {
        render = <Row>
          <ManageColumns />
        </Row>
        break
      }
      case DISPLAY_MODES.TABS: {
        render = <Row>
          <ManageTabs />
        </Row>
        break
      }
      case DISPLAY_MODES.TAB_FORM: {
        render = <Row>
          <CreateTab />
        </Row>
        break
      }
      case DISPLAY_MODES.WIDGET: {
        render = <Row>
          <ManageWidget />
        </Row>
        break
      }
      case DISPLAY_MODES.NEW_PAGE: {
        render = <Row>
          <NewPageCreate />
        </Row>
        break
      }
      case DISPLAY_MODES.EDIT_COLUMN_PROPS: {
        render = <Row>
          <ManageColumnProps />
        </Row>
        break
      }
      case DISPLAY_MODES.BLOCK_REORDER: {
        render = <Row>
          <ReorderBlocks />
        </Row>
      }
    }

    return (
      <div id='multi-widget-admin'>
        <Row className="main-page-row">
          <DisplayTabs />
          <Col xs={12} className="btn-container">
            {
              this.props.blocks.filter( b => { return (this.props.tabs.length === 0 || b.tabId === this.props.activeTab) }).length === 0 &&
              <Col xs={6} className="left-container">
                  <AddBlock activeTab={ this.props.activeTab } />
                </Col>
            }
            <Col xs={6} className="right-container manage_tabs_button">
              <EditTabs tabs={ this.props.tabs } />
              <BlocksReorderButton blocks={ this.props.blocks.filter( block => block.tabId === this.props.activeTab ) } />
            </Col>
            <DisplayBlocks />
            { 
              this.props.blocks.filter( b => { return (this.props.tabs.length === 0 || b.tabId === this.props.activeTab) }).length > 0 &&
                <Col xs={12} className="left-container">
                  <AddBlock activeTab={ this.props.activeTab } />
                </Col>
            }
          </Col>
        </Row>
        <Popup CONTENT={ render } displayMode={ this.props.displayMode }/>
      </div>
    )
  }
}

Page.propTypes = {
  activeTab: PropTypes.number,
  displayMode: PropTypes.string,
  tabs: PropTypes.array,
  blocks: PropTypes.array,
  usesTranslations: PropTypes.bool,
  availableLanguages: PropTypes.any,
  currentLanguage: PropTypes.string,
}

export default Page
