export default {
  CHANGE_CATEGORY: 'changeCategory',
  CHANGE_SEASON: 'changeSeason',
  CHANGE_TOUR: 'changeTour',
  CLONE_EVENT: 'cloneEvent',
  CLONE_EVENT_COPY_OPTIONS: 'cloneEventCopyOptions',
  CLONE_EVENT_DATES: 'cloneEventDates',
  QUICK_EVENT: 'quickEvent',
  CREATE_EVENT: 'createEvent',
  CATEGORY_POPUP: 'categoryPopup',
  INVITATION_LIST_REGISTRATION: 'invitationListRegistration',
  EXISTING_TIMES: 'existingTimes',
}
