import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GlgButton } from 'SharedComponents'
import { NONE } from '../constants'
import EditableImageTitle from './editable_image_title'

class AlbumPhotoPickerComponent extends Component {
  
  constructor(props) {
    super(props)

    this.state = {
      isFetchingData: false,
      isUploadingData: false,
      selected: [],
      selectedForUpload: [],
      imagesLinks: [],
    }

    this.selectAll = this.selectAll.bind(this)
    this.unselectAll = this.unselectAll.bind(this)
    this.importSelectedToRoll = this.importSelectedToRoll.bind(this)
    this.togglePhotoSelect = this.togglePhotoSelect.bind(this)
    this.updateImageTitle = this.updateImageTitle.bind(this)
  }

  togglePhotoSelect(index) {
    const { selected } = this.state
    if (selected.includes(index)) {
      this.setState({
        selected: this.state.selected.filter(photoID => photoID !== index),
      })
    } else {
      this.setState({ 
        selected: [ ...this.state.selected, index ],
      })
    }
  }

  selectAll() {
    const { selectedAlbumPhotos } = this.props
    this.setState({
      selected: selectedAlbumPhotos.map((album, index) => index),
    })
  }

  unselectAll() {
    this.setState({
      selected: [],
    })
  }

  importSelectedToRoll() {
    const { selected } = this.state
    const { addToRoll, selectAlbum, selectedAlbumPhotos } = this.props
    selected.map(albumID => {
      addToRoll(selectedAlbumPhotos[albumID].id, 'photo', selectedAlbumPhotos[albumID].link)
    })
    selectAlbum([])
    this.unselectAll()
  }

  updateImageTitle(imageId, newImageTitle) {
    const { selectedAlbumPhotos, selectAlbum } = this.props
    const imageInFormData = new FormData()
    imageInFormData.append('image_id', imageId )
    imageInFormData.append('image_title', newImageTitle )

    $.ajax({
      url: this.props.updateImageTitlePath,
      data: imageInFormData,
      processData: false,
      contentType: false,
      type: 'PUT',
      success: () => {
        const photos = selectedAlbumPhotos
        photos[photos.findIndex(photo => photo.id === imageId)].title = newImageTitle
        selectAlbum(photos)
      },
      error: () => {},
    })
  }

  render() {
    const { selected } = this.state
    const { selectedAlbumPhotos } = this.props

    if (selectedAlbumPhotos.length === 0) {
      return <div className="div"></div>
    }

    return <div className="div"><div className={ 'well white_background clearfix album_photo_picker_container' }><ul className={ 'logos col-xs-12 album_photo_picker_logo_container' }>{ selectedAlbumPhotos && selectedAlbumPhotos.map( (image, index) => <li key={ index } className={ 'align-items-center album_photo_picker_pictures' }><div className={ `logo ${this.state.selected.includes(index) ? 'selected' : ''}` } onClick={ () => this.togglePhotoSelect(index) }>{ image.link === NONE ?
                    <div className="logo-none">{ NONE }</div> :
                    <img src={ image.link } />
                  }</div><center className="margin_top_5"><EditableImageTitle title={ image.title } updateImageTitle={ (newImageTitle) => this.updateImageTitle(image.id, newImageTitle) }></EditableImageTitle></center></li>)}</ul></div><div className="album_photo_picker_buttons_container"><div className="album_photo_picker_buttons"><GlgButton text={ 'Select All' } icon="arrow-circle-up" color="orange" disabled={ selected.length === selectedAlbumPhotos.length } onClick={ this.selectAll }></GlgButton><div className="add-margin-left-10"><GlgButton text={ 'Unselect All' } icon="arrow-circle-up" color="orange" disabled={ selected.length === 0 } onClick={ this.unselectAll }></GlgButton></div></div><div><GlgButton text={ 'Import Selected to Roll' } icon="arrow-circle-up" color="orange" disabled={ selected.length === 0 } onClick={ this.importSelectedToRoll }></GlgButton></div></div></div>
  }
}

AlbumPhotoPickerComponent.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  showNone: PropTypes.bool,
  uploadText: PropTypes.string,
  saveText: PropTypes.string.isRequired,
  saveDisabledText: PropTypes.string.isRequired,
  fetchImagesPath: PropTypes.string.isRequired,
  uploadImagePath: PropTypes.string.isRequired,
  updateImageTitlePath: PropTypes.string.isRequired,
  addToRoll: PropTypes.func.isRequired,
  selectedAlbumPhotos: PropTypes.array.isRequired,
  selectAlbum: PropTypes.func.isRequired,
}

AlbumPhotoPickerComponent.defaultProps = {
  uploadText: 'Upload',
  showNone: false,
  show: true,
  saveText: 'Save',
  saveDisabledText: 'Save',
}

export default AlbumPhotoPickerComponent
