import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MenuBarContainer from '../containers/menu_bar_container'
import WidgetContainer from '../containers/widget_container'
// import Widget from './widget'

const variableHeightWidgets = [
  'event_setup_other_options',
  'event_setup_handicaps',
  'event_setup_scoring',
  'event_setup_pairings',
  'event_setup_rounds',
  'event_setup_registration',
  'event_setup_divisions',
  'event_setup_flights',
  'event_setup_tournaments',
  'event_setup_import_golfers',
  'event_setup_tee_sheet',
]

class Page extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount() {
    const withVariableHeightWidgets = this.props.widgets.some(widget => variableHeightWidgets.includes(widget.content_type))

    if (withVariableHeightWidgets && window.chrome) {
      document.body.onscroll = () => {
        document.documentElement.scrollTop = Math.min(document.body.scrollHeight - $('#main_content').height() + 10, document.documentElement.scrollTop)
      }
    }
  }

  render() {
    const widgetsLodaded = ! this.props.widgets.some(w => w.content === undefined)
    const fitContentHeight = widgetsLodaded && this.props.widgets.some(widget => variableHeightWidgets.includes(widget.content_type))

    return (
      <div id='dashboard-page-content'>
        <MenuBarContainer />
        <div id='dashboard-page-widget-container' className={fitContentHeight ? 'fit-content-height' : ''}>
          {
            this.props.widgets.map( widget => <WidgetContainer key={ widget.id } widget={ widget } />)
          }
        </div>
      </div>
    )
  }
}

Page.propTypes = {
  widgets: PropTypes.array,
}

export default Page
