import { ActionTypes } from '../actions'

const {
  OPEN_DELETE_ROSTER_CONFIRMATION_POPUP,
  CLOSE_DELETE_ROSTER_CONFIRMATION_POPUP,
  GET_ANSWER_REQUEST,
  GET_ANSWER_RECEIVED,
  DELETE_ROSTER_REQUEST,
  DELETE_ROSTER_RECEIVED,
  TOGGLE_DOWNLOAD_ROSTER,
} = ActionTypes

const initialState = {
  actionMessage: '',
  isLoading: false,
  isOpen: false,
  isDeleteRosterLoading: false,
  isRosterDownloaded: false,
}

const deleteRosterConfirmation = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_DELETE_ROSTER_CONFIRMATION_POPUP:
      return {
        ...state,
        isOpen: true,
      }
    case CLOSE_DELETE_ROSTER_CONFIRMATION_POPUP:
      return {
        ...state,
        isOpen: false,
      }
    case GET_ANSWER_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_ANSWER_RECEIVED:
      return {
        ...state,
        isLoading: false,
        actionMessage: action.actionMessage,
      }
    case DELETE_ROSTER_REQUEST:
      return {
        ...state,
        isDeleteRosterLoading: true,
      }
    case DELETE_ROSTER_RECEIVED:
      return {
        ...state,
        isDeleteRosterLoading: false,
      }
    case TOGGLE_DOWNLOAD_ROSTER:
      return {
        ...state,
        isRosterDownloaded: true,
      }
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default deleteRosterConfirmation
