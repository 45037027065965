import React from 'react'
import configureStore from 'Shared/configure_store'
import indexPage from '../reducers/index_page'
import { GlobalProvider } from 'Shared/global_context'
import PropTypes from 'prop-types'

import MarketingCampaignsIndexContainer from './marketing_campaigns_index_container'

const MarketingCampaignsIndexRoot = (props) => {
  const state = {
    marketingCampaigns: props.marketingCampaigns,
  }
  const store = configureStore(indexPage, state)

  return <GlobalProvider store={ store }><MarketingCampaignsIndexContainer newMarketingCampaignPath={ props.newMarketingCampaignPath } matchingRecipientsPath={ props.matchingRecipientsPath }></MarketingCampaignsIndexContainer></GlobalProvider>
}

MarketingCampaignsIndexRoot.propTypes = {
  newMarketingCampaignPath: PropTypes.string.isRequired,
  matchingRecipientsPath: PropTypes.string.isRequired,
  marketingCampaigns: PropTypes.array.isRequired,
}

export default MarketingCampaignsIndexRoot
