import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DrivingDistancesTableContainer from '../containers/driving_distances_table_container'
import EditDistancePopupContainer from '../containers/edit_distance_popup_container'
import DrivingDistancesFilterContainer from '../containers/driving_distances_filter_container'
import DrivingDistancesSearchContainer from '../containers/driving_distances_search_container'
import {Row} from 'react-bootstrap'
import {GlgLoadingSpinner} from '../../shared/components'

class DrivingDistancesComponent extends Component {
  constructor(props) {
    super(props)
    this.refresh = this.refresh.bind(this)
  }

  componentDidMount() {
    this.props.loadPlayers(this.props.stationId)
    this.timer = setInterval(this.refresh, 6000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  refresh() {
    this.props.refreshPlayers(this.props.stationId)
  }

  render() {
    return (
        <Row className={'add-margin-left-10 add-margin-right-10'}>
            <DrivingDistancesFilterContainer />
            <DrivingDistancesSearchContainer />
          { this.props.players ? <DrivingDistancesTableContainer /> : <GlgLoadingSpinner className={'text-center'}/> }
            {this.props.visible && <EditDistancePopupContainer stationId={this.props.stationId} />}
        </Row>
    )
  }
}

DrivingDistancesComponent.propTypes = {
  test: PropTypes.string,
  roundId: PropTypes.string,
  visible: PropTypes.bool,
  stationId: PropTypes.string,
  closePopup: PropTypes.func,
  loadPlayers: PropTypes.func,
  refreshPlayers: PropTypes.func,
  players: PropTypes.array,
}

export default DrivingDistancesComponent
