import React, { Component } from 'react'
import PropTypes from 'prop-types'

import StatisticContainer from '../containers/statistic_container'
import StatisticPopupContainer from '../containers/statistic_popup_container'
import {NotificationContainer} from 'react-notifications'

class PgaRankingsComponent extends Component{
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={'pga_rankings_container'}>
        {
          this.props.statistics.map( statistic => {
            if (this.props.hasShotTrackings || statistic.key === 'driving_distances') {
              return <StatisticContainer key={statistic.name} shot_statistic={statistic}/>
            }
            })
        }
        {this.props.showPopup && <StatisticPopupContainer/>}
        <NotificationContainer/>
      </div>
    )
  }
}

PgaRankingsComponent.propTypes = {
  fetchInitialDataUrl: PropTypes.string,
  leagueId: PropTypes.string,
  statistics: PropTypes.array,
  showPopup: PropTypes.bool,
  hasShotTrackings: PropTypes.bool,
}

export default PgaRankingsComponent

