import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { GlgTooltipIcon } from 'SharedComponents'

const css2obj = css => css && Object.fromEntries(
  css
  .split(';')
  .filter(x => x)
  .map(prop => {
    const [ a, b ] = prop.split(':')
    return [ _.camelCase(a), b ]
  })
)

class EventSetupCourseDetails extends Component {
  constructor(props){
    super(props)
    this.state = {
      dataChanged: false,
    }
  }

  componentDidUpdate() {
    if (this.state.dataChanged) {
      this.props.save()
      this.setState({dataChanged: false})
    }
  }

  handleChange(setter, keys, value) {
    setter(keys)(value)
    this.setState({dataChanged: true})
  }

  render() {
    const { title, header, courses } = this.props.content
    const internationalize = (key) => !window.I18n ? '' : window.I18n.t(key)
    const setter = (keys) => (value) => this.props.setData(keys, value)

    return (
      <div className='widget-content-default widget-event-setup-course-details'>
        <div className='title'>
          { title }
        </div>

        <div className='content flex-list-layout'>
          <div className='table-responsive'>
            <table className='table table-striped'>
              <thead>
                <tr className='header'>
                  <th className='selected'>{header.selected}</th>
                  <th className='name'>{header.name}</th>
                  <th className='whs_compliance'>{header.whs_compliance}</th>
                  <th className='abbreviation'>{header.abbreviation}</th>
                </tr>
              </thead>

              <tbody>
                {
                  courses.map((course, index) =>
                    <tr key={course.id}>
                      <td>
                        <div
                          className={`icheckbox_square-orange icheck-item ${course.valid ? 'checked' : ''}`}
                          onClick={() => this.handleChange(setter, [ 'courses', index, 'valid' ], !course.valid)} 
                        >
                          <input
                            className='i-checks icheck-input'
                            type="checkbox"
                            defaultChecked={course.valid}
                          />
                        </div>
                      </td>

                      <td>
                        <span>{ course.name }</span>
                        {
                          course.source_id && course.source &&
                            <i className='courses_source'>
                              { `(${internationalize(`courses.bootstrap_course_table_data.${course.source}`)}: ${course.source_id})` }
                            </i>
                        }
                        {
                          course.city && course.state &&
                            <span className='courses_city_state'>
                              { `${course.city}, ${course.state}` }
                            </span>
                        }
                        {
                          course.strokes_position &&
                            <>
                              <br/>
                              <i>
                                { internationalize('courses.bootstrap_course_table_data.include_tee_rotations') + ' ' }
                                {
                                  course.strokes_position === 'regular' ?
                                    internationalize('courses.bootstrap_course_table_data.strokes_fall_where_they_lie') :
                                    internationalize('courses.bootstrap_course_table_data.strokes_fall_evenly')
                                }
                              </i>
                            </>
                        }
                      </td>

                      {
                        course.whs_compliance ?
                          <td className='whs_compliance'>
                            <label className='whs_compliant_label' style={css2obj(course.whs_compliance.warning_style)}>
                              { course.whs_compliance.label }
                              <GlgTooltipIcon
                                tooltip={
                                  <div className="whs_tooltip_message">
                                    <div dangerouslySetInnerHTML={{ __html: course.whs_compliance.tooltip_message }} />
                                  </div>
                                }
                                placement='right'
                              />
                            </label>
                          </td>
                          :
                          <td> </td>
                      }

                      <td className='abbreviation'>
                        { course.abbreviation }
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>

      </div>
    )
  }
}

EventSetupCourseDetails.propTypes = {
  content: PropTypes.object,
  setData: PropTypes.func,
  save: PropTypes.func,
}

EventSetupCourseDetails.defaultProps = {
  content: {
    title: 'COURSE DETAILS',
    data: {},
  },
}

export default EventSetupCourseDetails

