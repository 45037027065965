import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {computeDisplayedStatistic} from '../helpers'
import GlgLoadingSpinner from '../../shared/components/glg_loading_spinner'

class StatisticTableComponent extends Component{
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
  }

  handleScroll(){
    if (this.props.isPopup){
      const scrollingDivOffset = $('.shot_stat_table_wrapper').offset().top
      const initialPosition = $('.shot_stat_table_wrapper table').offset().top

      if (scrollingDivOffset > initialPosition) {
        $('.shot_stat_table_wrapper table thead').css('transform', `translateY(${scrollingDivOffset - initialPosition - 0.5}px)`)
      } else {
        $('.shot_stat_table_wrapper table thead').css('transform', 'translateY(0)')
      }
    }
  }

  render() {
    const numElements = typeof this.props.content !== 'undefined' && this.props.isPopup ? this.props.content.length : 10
    return (
      <div className={this.props.isPopup ? 'shot_stat_table_wrapper' : ''} onScroll={this.handleScroll}>
        <table className={'shot_stat_table'}>
          <thead>
          {!this.props.isPopup &&
          <tr className={'header'}>
            <th className={'with_border'} colSpan={3}>{this.props.name}</th>
          </tr>
          }
          <tr className={'header'}>
            {this.props.columns.map((column) => <th key={column.toLowerCase().replaceAll(' ', '_')} className={'with_border'}>{column}</th>)}
          </tr>
          </thead>
          <tbody>
          {
            typeof this.props.content === 'undefined' ?
              <tr>
                <td colSpan={3}>
                  <GlgLoadingSpinner/>
                </td>
              </tr>
              : this.props.content.length === 0 ? 
                  <tr>
                    <td colSpan={'3'}>{this.props.statKey === 'driving_distances' ? window.I18n.t('pga_rankings.distances_not_available') : window.I18n.t('pga_rankings.not_available')}</td>
                  </tr>
              : this.props.content.slice(0, numElements).map((item) =>
                <tr key={item[0]}>
                  <td>{item[1].rank}</td>
                  <td>
                  {
                    this.props.statKey === 'driving_distances' ?
                    item[1].name
                    :
                    <a href={`${this.props.pathToPlayerStats}?member_id=${item[0]}&is_for_portal=true`}>{item[1].name}</a>
                  }
                  </td>
                  <td className={'percentage'}>{computeDisplayedStatistic(this.props.statKey, item[1].statistic)}</td>
                </tr>
              )
          }
            {typeof this.props.content !== 'undefined' && !this.props.isPopup && this.props.content.length > 0 &&
            <tr>
              <td colSpan={3}><a onClick={() => this.props.showPopupOnClick(this.props.statKey, this.props.name, this.props.columns, this.props.content)}>{window.I18n.t('pga_rankings.view_more_btn')}</a></td>
            </tr>
          }
          </tbody>
        </table>
      </div>
    )
  }
}

StatisticTableComponent.propTypes = {
  name: PropTypes.string,
  columns: PropTypes.array,
  content: PropTypes.array,
  statKey: PropTypes.string,
  pathToPlayerStats: PropTypes.string,
  showPopupOnClick: PropTypes.func,
  isPopup: PropTypes.bool,
}

export default StatisticTableComponent
