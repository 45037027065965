import { connect } from 'react-redux'
import StatisticComponent from '../components/statistic_component'
import {loadStatisticData, openPopupAction} from '../actions'

const mapStateToProps = (state) => {
  return {
    leagueId: state.misc.leagueId,
    pathToPlayerStats: state.misc.rounds[state.misc.rounds.length - 1].pathToPlayerStats,
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  loadStatisticData: () => dispatch(loadStatisticData(props.shot_statistic.key)),
  openPopup: (statKey, title, columns, content) => dispatch(openPopupAction(statKey, title, columns, content)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticComponent)

