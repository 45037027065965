import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, InputGroup, FormControl } from 'react-bootstrap'
import { Icon as FaIcon } from 'react-fa'
const { Addon } = InputGroup

class GlgTextInput extends PureComponent {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
    this.onReset = this.onReset.bind(this)
  }

  componentDidMount() {
    // Edge case: slightly delay the focus while the popup is appearing
    setTimeout(() => {
      if (this.props.focusOnMount && this.inputRef) {
        this.inputRef.focus()
      }
    }, 10)
  }

  onChange(event) {
    if (this.props.onChange) {
      this.props.onChange(event.target.value)
    }
  }
  
  onKeyPress(event) {
    if (event.charCode === 13) {
      if (this.props.onKeyPress) {
        this.props.onKeyPress(event.target.value)
      }
    }
  }

  onReset() {
    if (this.props.onChange) {
      this.props.onChange('')
    }
    this.inputRef.focus()
  }

  render() {
    let textInput = <FormControl type="text" value={ this.props.value } placeholder={ this.props.placeholder } onChange={ this.onChange } inputRef={ ref => { this.inputRef = ref } } onKeyPress={ this.onKeyPress } required={ this.props.required }></FormControl>

    if (this.props.withAddon) {
      textInput = <InputGroup>{ textInput }<Addon onClick={ this.onReset } className="clickable"><a><FaIcon name="eraser"></FaIcon></a></Addon></InputGroup>
    }

    return <FormGroup className="glg-text-input">{ textInput }</FormGroup>
  }
}

GlgTextInput.propTypes = {
  // The controlled value of the text input
  value: PropTypes.string,
  // Callback when the input value changes
  onChange: PropTypes.func,
  // Callback when enter is pressed
  onKeyPress: PropTypes.func,
  // If true, it will render the erase icon to the right of the text input
  withAddon: PropTypes.bool,
  // Text input placeholder
  placeholder: PropTypes.string,
  // If true, the text input will be focused after mounting the component
  focusOnMount: PropTypes.bool,
  // If true, the text input will be required
  required: PropTypes.bool,
}

GlgTextInput.defaultProps = {
  withAddon: false,
  placeholder: !window.I18n ? '' : window.I18n.t('shared.components.glg_text_input.search'),
  focusOnMount: false,
  required: false,
}

export default GlgTextInput
