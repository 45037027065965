import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
  GlgTableRow,
  GlgTableCell,
  GlgTooltip,
} from 'SharedComponents'

const MembershipProgramsTableRow = ({ ...props }) => {
  let renewalDate = ''
  let renewalDateCell
  if (props.hasRenewals) {
    if (props.renewal_date) {
      if (props.isExpired) {
        renewalDate = 
<pre className='error'>ReferenceError: window is not defined
    at eval (eval at &lt;anonymous&gt; (/golfgenius/node_modules/haml/lib/haml.js:595:11), &lt;anonymous&gt;:1:49)
    at Function.&lt;anonymous&gt; (/golfgenius/node_modules/haml/lib/haml.js:595:11)
    at execute (/golfgenius/node_modules/haml/lib/haml.js:602:8)
    at Function.render (/golfgenius/node_modules/haml/lib/haml.js:587:12)
    at renderHAML (/golfgenius/node_modules/haml-jsx-loader/lib/haml-jsx.js:78:21)
    at /golfgenius/node_modules/haml-jsx-loader/lib/haml-jsx.js:101:16
    at replaceMatching (/golfgenius/node_modules/haml-jsx-loader/lib/replace-matching.js:67:15)
    at renderHamlJSX (/golfgenius/node_modules/haml-jsx-loader/lib/haml-jsx.js:96:10)
    at module.exports (/golfgenius/node_modules/haml-jsx-loader/index.js:10:14)
    at LOADER_EXECUTION (/golfgenius/node_modules/loader-runner/lib/LoaderRunner.js:119:14)
    at runSyncOrAsync (/golfgenius/node_modules/loader-runner/lib/LoaderRunner.js:120:4)
    at iterateNormalLoaders (/golfgenius/node_modules/loader-runner/lib/LoaderRunner.js:232:2)
    at Array.&lt;anonymous&gt; (/golfgenius/node_modules/loader-runner/lib/LoaderRunner.js:205:4)
    at Storage.finished (/golfgenius/node_modules/enhanced-resolve/lib/CachedInputFileSystem.js:55:16)
    at /golfgenius/node_modules/enhanced-resolve/lib/CachedInputFileSystem.js:91:9
    at /golfgenius/node_modules/graceful-fs/graceful-fs.js:123:16
    at FSReqCallback.readFileAfterClose [as oncomplete] (node:internal/fs/read/context:68:3)</pre>

      } else {
        renewalDate = props.renewal_date 
      }
    }
    renewalDateCell = <GlgTableCell>{ renewalDate }</GlgTableCell>
  }
  
  return <GlgTableRow { ...props } isSortable={ false }><GlgTableCell><GlgTooltip tooltip={ props.name }><span>{ _.truncate(props.name, { length: 40 }) }</span></GlgTooltip></GlgTableCell><GlgTableCell>{ props.join_date }</GlgTableCell>{ renewalDateCell }</GlgTableRow>
}

MembershipProgramsTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  join_date: PropTypes.string.isRequired,
  renewal_date: PropTypes.string,
  isExpired: PropTypes.bool.isRequired,
  hasRenewals: PropTypes.bool,
}

export default MembershipProgramsTableRow
