import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import memoize from 'lodash.memoize'

import { KeyEnum } from './inline_suggest/key_enum'
import Suggestion from './inline_suggest/Suggestion'
import Input from './inline_suggest/Input'
import {
  filterSuggestions,
  getNeedleFromString,
  getNextSafeIndexFromArray,
  getPreviousSafeIndexFromArray,
} from './inline_suggest/utils.js'

const Wrapper = styled.div`
  position: relative;
`

export class GlgInlineSuggest extends React.Component {
  constructor(props) {
    super(props)

    console.error('props: ', props)

    this.fireOnChange = this.fireOnChange.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleOnBlur = this.handleOnBlur.bind(this)
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this)
    this.handleOnKeyUp = this.handleOnKeyUp.bind(this)
    this.getMatchedSuggestions = this.getMatchedSuggestions.bind(this)
    this.getNeedle = this.getNeedle.bind(this)

    this.memoizedFilterSuggestions = memoize(filterSuggestions)

    this.state = {
      activeIndex: -1,
      focused: false,
      value: '',
    }
  }


  render() {
    return (
      <Wrapper className={this.props.className}>
        <Input
          value={this.state.value}
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
          onKeyDown={this.handleOnKeyDown}
          onKeyUp={this.handleOnKeyUp}
        />
        <Suggestion
          value={this.state.value}
          needle={this.getNeedle()}
          shouldRenderSuggestion={this.props.shouldRenderSuggestion}
        />
      </Wrapper>
    )
  }

  fireOnChange = (newValue) => {
    if (this.props.onInputChange) {
      this.props.onInputChange(newValue)
    }
  }

  handleOnChange = (e) => {
    const valueFromEvent = e.currentTarget.value
    const { getSuggestionValue, suggestions, ignoreCase } = this.props

    const newMatchedArray = this.memoizedFilterSuggestions(
      valueFromEvent,
      suggestions,
      Boolean(ignoreCase),
      getSuggestionValue,
    )

    this.setState({
      activeIndex: newMatchedArray.length > 0 ? 0 : -1,
      value: valueFromEvent,
    })
    this.fireOnChange(valueFromEvent)
  }

  handleOnBlur = () => {
    if (this.props.onInputBlur) {
      this.props.onInputBlur(this.state.value)
    }
  }

  handleOnKeyDown = (e) => {
    if (this.state.activeIndex === -1) {
      return
    }

    const { keyCode } = e
    const { navigate } = this.props

    const allowedKeyCodes = [
      KeyEnum.TAB,
      KeyEnum.ENTER,
      KeyEnum.UP_ARROW,
      KeyEnum.DOWN_ARROW,
    ]

    if (allowedKeyCodes.includes(keyCode)) {
      e.preventDefault()
    }

    if (
      navigate &&
      (keyCode === KeyEnum.DOWN_ARROW || keyCode === KeyEnum.UP_ARROW)
    ) {
      const matchedSuggestions = this.getMatchedSuggestions()

      this.setState({
        activeIndex:
          keyCode === KeyEnum.DOWN_ARROW
            ? getNextSafeIndexFromArray(
              matchedSuggestions,
              this.state.activeIndex
            )
            : getPreviousSafeIndexFromArray(
              matchedSuggestions,
              this.state.activeIndex
            ),
      })
    }
  }

  handleOnKeyUp = (e) => {
    const { keyCode } = e

    if (
      this.state.activeIndex >= 0 &&
      (keyCode === KeyEnum.TAB ||
        keyCode === KeyEnum.ENTER ||
        keyCode === KeyEnum.RIGHT_ARROW)
    ) {
      const matchedSuggestions = this.getMatchedSuggestions()
      const matchedValue = matchedSuggestions[this.state.activeIndex]

      const newValue = this.props.getSuggestionValue
        ? this.props.getSuggestionValue(matchedValue)
        : String(matchedValue)

      this.setState({
        value: newValue,
      })

      this.fireOnChange(newValue)

      if (this.props.onMatch) {
        this.props.onMatch(matchedValue)
      }
    }
  }

  getMatchedSuggestions = () => {
    return this.memoizedFilterSuggestions(
      this.state.value,
      this.props.suggestions,
      Boolean(this.props.ignoreCase),
      this.props.getSuggestionValue
    )
  }

  getNeedle = () => {
    const matchedSuggestions = this.getMatchedSuggestions()

    if (!matchedSuggestions[this.state.activeIndex]) {
      return ''
    }

    return getNeedleFromString(
      this.props.getSuggestionValue
        ? this.props.getSuggestionValue(
          matchedSuggestions[this.state.activeIndex]
        )
        : String(matchedSuggestions[this.state.activeIndex]),
      this.state.value
    )
  }
}

GlgInlineSuggest.propTypes = {
  className: PropTypes.string,
  shouldRenderSuggestion: PropTypes.bool,
  onInputChange: PropTypes.func,
  getSuggestionValue: PropTypes.func,
  onInputBlur: PropTypes.func,
  onMatch: PropTypes.func,
  ignoreCase: PropTypes.bool,
  suggestions: PropTypes.array,
  navigate: PropTypes.bool,
}

GlgInlineSuggest.defaultProps = {
  ignoreCase: true,
  suggestions: [],
  switchBetweenSuggestions: false,
  value: '',
}

export default GlgInlineSuggest
