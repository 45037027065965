import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { FormGroup } from 'react-bootstrap'
import {
  GlgPopup,
  GlgPopupForm,
  GlgRadio,
  GlgCheckbox,
} from '../../../../shared/components'

class WalkingScorerStationPopup extends Component {
  constructor(props){
    super(props)
    this.state = {
      currentValues: {
        fillMissingScores: null,
        editAfterVerification: null,
        extraHoles: '',
      },
      savedValues: {
        fillMissingScores: null,
        editAfterVerification: null,
        extraHoles: '',
      },
    }
  }

  handleChange(key, value) {
    this.setState(state => ({
      ...state,
      currentValues: {
        ...state.currentValues,
        [key]: value,
      },
    }))
  }

  saveValues() {
    this.setState(state => ({
      ...state,
      savedValues: {
        fillMissingScores: state.currentValues.fillMissingScores,
        editAfterVerification: state.currentValues.editAfterVerification,
        extraHoles: this.props.playoffHoles ? state.currentValues.extraHoles : 'none',
      },
    }))
  }

  resetCurrentValues() {
    this.setState(state => ({
      ...state,
      currentValues: {
        fillMissingScores: state.savedValues.fillMissingScores,
        editAfterVerification: state.savedValues.editAfterVerification,
        extraHoles: state.savedValues.extraHoles,
      },
    }))
  }

  render() {
    const { show, title, playoffHoles } = this.props

    return (
      <GlgPopup title={ title }
                saveButtonText='Save'
                closeButtonText='Cancel'
                closeButtonClass='hidden'
                show={ show }
                onSave={ () => { this.saveValues(); this.props.onSave(this.state.currentValues) } }
                onClose={ this.props.onClose }
                onShow={ () => this.resetCurrentValues() }
                saveButtonEnabled={ true }
                modalClass='set-flash-message'>
        <GlgPopupForm className='flash-manager margin_top_10 margin_bottom_10 well white_background' id='walking-scorer-station-form'>
          <FormGroup className='add-margin-top-5'>
            <li className="form_row">
              <div className="row">
                <div className="col-sm-3 col-md-3 col-lg-3 label_names hidden-xs">
                  <label>
                    Permissions
                  </label>
                </div>
                <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                  <div className="description col-xs-12 col-sm-12 col-md-12 col-lg-12 no_padding hidden-lg hidden-md hidden-sm">
                    <div className="bold">
                      <label>
                        Permissions
                      </label>
                    </div>
                  </div>
                  <div className="content_fields col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no_padding">
                      <div className="hidden">
                        <label className="btsp_icheck_label">
                          <GlgCheckbox name="fill_missing_scores"
                                        value="1"
                                        label="Allow scorer to fill in missing prior scores"
                                        onChange={ (val) => this.handleChange('fillMissingScores', val) }
                                        defaultChecked={ this.state.savedValues.fillMissingScores || (this.props.walkingScorerStationSettings().fill_missing_scores === 'checked' ? true : false) }
                                        inline={ true }>
                          </GlgCheckbox>
                        </label>
                      </div>
                      <label className="btsp_icheck_label">
                        <GlgCheckbox name="edit_after_verification"
                                      value="1"
                                      label="Allow scorer to edit their scores after verification"
                                      onChange={ (val) => this.handleChange('editAfterVerification', val) }
                                      defaultChecked={ this.state.savedValues.editAfterVerification || (this.props.walkingScorerStationSettings().edit_after_verification === 'checked' ? true : false) }
                                      inline={ true }>
                        </GlgCheckbox>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="form_row extra_holes">
              <div className="row">
                <div className="col-sm-3 col-md-3 col-lg-3 label_names hidden-xs">
                  <label>
                    Extra Holes
                  </label>
                </div>
                <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                  <div className="description col-xs-12 col-sm-12 col-md-12 col-lg-12 no_padding hidden-lg hidden-md hidden-sm">
                    <div className="bold">
                      <label>
                        Extra Holes
                      </label>
                    </div>
                  </div>
                  {!playoffHoles && <div className="description col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="bold">
                      Note: a match play tournament using extra holes as tie breaking is required to use the features below on the mobile apps.
                    </div>
                  </div>}
                  <div className="content_fields col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no_padding">
                      <div className="checkbox_radio_container">
                        <label className="btsp_icheck_label">
                          <GlgRadio name="extra_holes"
                                    value="none"
                                    label="Scorers cannot access extra holes"
                                    onChange={ (val) => this.handleChange('extraHoles', val) }
                                    defaultChecked={ this.state.savedValues.extraHoles === 'none' || (this.props.walkingScorerStationSettings().extra_holes === 'none' && this.state.savedValues.extraHoles === '') ? true : false }
                                    inline={ true }>
                          </GlgRadio>
                        </label>
                      </div>
                      <div className="checkbox_radio_container">
                        <label className="btsp_icheck_label">
                          <GlgRadio name="extra_holes"
                                    value="edit"
                                    label="Scorers can enter scores for extra holes added by the manager"
                                    onChange={ (val) => this.handleChange('extraHoles', val) }
                                    defaultChecked={ this.state.savedValues.extraHoles === 'edit' || (this.props.walkingScorerStationSettings().extra_holes === 'edit' && this.state.savedValues.extraHoles === '') ? true : false }
                                    inline={ true }>
                          </GlgRadio>
                        </label>
                      </div>
                      <div className="checkbox_radio_container">
                        <label className="btsp_icheck_label">
                          <GlgRadio name="extra_holes"
                                    value="add"
                                    label="Scorers can enter scores and add new extra holes on the app"
                                    onChange={ (val) => this.handleChange('extraHoles', val) }
                                    defaultChecked={ this.state.savedValues.extraHoles === 'add' || (this.props.walkingScorerStationSettings().extra_holes === 'add' && this.state.savedValues.extraHoles === '') ? true : false }
                                    inline={ true }>
                          </GlgRadio>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </FormGroup>
        </GlgPopupForm>
      </GlgPopup>
    )
  }
}

WalkingScorerStationPopup.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  playoffHoles: PropTypes.bool,
  walkingScorerStationSettings: PropTypes.func,
}

WalkingScorerStationPopup.defaultProps = {
  showModal: false,
}

export default WalkingScorerStationPopup
