import React, {useRef, useState, useEffect } from 'react'
import MemberCardInfo from './member_card_info_component'
import MemberContainer from '../containers/member_container'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import StickyMemberContainer from '../containers/sticky_member_container'

const StickyMemberCard = (props) => {

  const isSplitting = props.index && props.type === 'SPLIT'
  const isMerging = props.index && props.type === 'MERGE'
  const [ wrapperHeight, setWrapperHeight ] = useState(0)

  const wrapperRef = useRef()

  useEffect(() => {
    const intervalId = setInterval(() => {
      setWrapperHeight(wrapperRef.current.clientHeight)
    }, 250)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <div className="member-card-button-wrapper">
      <div className='sitcky-member-card-container'>
        <table className='mc-outer-table'>
          <tbody>
            <tr className='sticky-members-row'>
              <td className='sticky-mc-info'>
                <div>
                  <h4>
                    {props.memberCard.first_name + ' ' + props.memberCard.last_name}
                  </h4>
                  <MemberCardInfo memberCard = {props.memberCard} index = {props.index}/>
                </div>
              </td>
              <td>
                <div className={(props.type === 'SPLIT' && props.members.length > 6) ? 'sticky-split-div' : null}>
                  <table className={isSplitting ? 'mc-table-rounded-rows' : 'mc-table'}>
                    <thead>
                      <tr ref={wrapperRef}>
                        {isSplitting && <th className='column-2'/>}
                        <th className='column-7'>{window.I18n.t('member_cards.date')}</th>
                        <th className = 'column-10'>{window.I18n.t('member_cards.name')}</th>
                        <th className = 'column-10'>{window.I18n.t('member_cards.strength')}</th>
                        <th className = 'column-20'>{window.I18n.t('member_cards.event')}</th>
                        <th className = 'column-15'>{window.I18n.t('member_cards.email')}</th>
                        <th className = 'column-15'>{window.I18n.t('member_cards.handicap_network_id')}</th>
                        <th className = 'column-7'>{window.I18n.t('member_cards.user_id')}</th>
                        <th className = 'column-20'>{window.I18n.t('member_cards.identifier_id')}</th>
                        {/* <th>Custom ID</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {props.members.map((member) => {
                        return ( 
                          <MemberContainer key = {member.id} member = {member} isSticky = {true} isMerging = {isMerging} isSplitting = {isSplitting}/>
                        ) 
                      }) }
                    </tbody>
                  </table>
                  {
                    isMerging && props.newMembers.length !== 0 && 
                      <div className='sticky-members-div' style={{height: wrapperHeight * 1.75}}>
                        { isMerging && props.newMembers.map((member) => {
                          return ( 
                            <StickyMemberContainer member = {member} key = {member.id}/>
                          )
                        })}
                      </div>
                  }
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='button-container'>
        <Button className = 'save-button' disabled={props.newMembers.length === 0} onClick={props.clearSticky}>{props.type.charAt(0) + props.type.slice(1).toLowerCase()}</Button>
        <Button className = 'cancel-button' onClick={props.cancel}>{window.I18n.t('member_cards.cancel')}</Button>
      </div>
    </div>
  )
}

StickyMemberCard.propTypes = {
  memberCard: PropTypes.object,
  members: PropTypes.array,
  newMembers: PropTypes.array,
  index: PropTypes.string,
  clearSticky: PropTypes.func,
  cancel: PropTypes.func,
  type: PropTypes.string,
}
export default StickyMemberCard
