import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MenuItem from './menu_item'
import MenuItemCloseButtonContainer from '../../containers/menu_item_close_button_container'
import MenuRoundItems from './menu_round_items'
import MenuEventSetupPlus from './menu_event_setup_plus'
import NewItems from '../content/popups/new_items'

class Menu extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { eventOverview, rounds, customerCenter, newRound, icons, newItems, expressEvent, eventSetup, wizard } = this.props.menuItems

    return (
      <div className={ `dashboard-menu dashboard-menu-collapse dashboard-menu-animate-left ${!this.props.mobileVisibility ? 'md-hidden' : ''}` } id="dashboard-menu">
        <MenuItemCloseButtonContainer />

        {
          !eventSetup.enabled
            ? <MenuItem icon='fa fa-plus-square-o'
                        label={ newItems.label }
                        active={ false }
                        url={ '#' }
                        isNewItems={ true }
                        openNewItemsModal={ () => this.props.toggleNewItemsModal(true) } />
            : null
        }

        {
          expressEvent.enabled
            ? <MenuItem icon={ icons.express_event }
                        label={ expressEvent.name }
                        active={ false }
                        
                        url={ expressEvent.path }/>
            : null
        }

        {
          wizard.enabled && !eventSetup.enabled
            ? <MenuItem icon={ icons.event_setup_wizard }
                        label={ wizard.name }
                        active={ false }
                        url={ wizard.path }/>
            : null
        }

        {
          eventSetup.enabled
            ? <MenuEventSetupPlus icon={ icons.event_setup }
                                  sections={ eventSetup.sections }
                                  label={ eventSetup.name }
                                  selectedMenuItem={ this.props.selectedMenuItem }
                                  selectMenuItem={ this.props.selectMenuItem } />
            : null
        }

        <MenuItem icon={ icons.event_overview }
                  label={ eventOverview.label }
                  active={ eventOverview.id === this.props.selectedMenuItem }
                  selectMenuItem={ () => this.props.selectMenuItem(eventOverview.id, eventOverview.widgetPath, eventOverview.lastViewedPagePath) } />

        {
          (!eventSetup.enabled || rounds.length > 0) &&
            <MenuRoundItems icon={ icons.rounds }
              roundItems={ rounds }
              selectedMenuItem={ this.props.selectedMenuItem }
              selectMenuItem={ this.props.selectMenuItem }
              newRound={ newRound }
              scrollMenuItemIntoView={ this.props.scrollMenuItemIntoView }
              openNewItemsModal={ () => this.props.toggleNewItemsModal(true) }
              newItemLabel={ newItems.label } />
        }

        <MenuItem icon={ icons.customer_center } label={ !window.I18n ? '' : window.I18n.t('dashboard_menu.components.menu.customer_center') } active={ false } url={ customerCenter } />
        <MenuItem type={ 'no-lite dashboard-menu-item' }icon={ icons.navigator } label={ !window.I18n ? '' : window.I18n.t('dashboard_menu.components.menu.navigator') } active={ false } selectMenuItem={ () => $('.navigator-menu.clickable')[0].click() } />
        <MenuItem defaultIcon='fa fa-question-circle-o' label={ !window.I18n ? '' : window.I18n.t('dashboard_menu.components.menu.help') } active={ false } selectMenuItem={ () => $('#support_bubble').click() } />

        <NewItems newItems={ newItems }
                  onClose={ () => this.props.toggleNewItemsModal(false) }
                  rounds={ rounds }
                  newItemsSearch={ searchTerm => this.props.newItemsSearch(searchTerm) }
                  newItemsSelectRound={ selectedRound => this.props.newItemsSelectRound(selectedRound) } />
      </div>
    )
  }
}

Menu.propTypes = {
  menuItems: PropTypes.object,
  eventOverview: PropTypes.object,
  rounds: PropTypes.object,
  mobileVisibility: PropTypes.bool,
  selectedMenuItem: PropTypes.string,
  selectMenuItem: PropTypes.func,
  scrollMenuItemIntoView: PropTypes.func,
  toggleNewItemsModal: PropTypes.func,
  newItemsSearch: PropTypes.func,
  newItemsSelectRound: PropTypes.func,
}

export default Menu
