import { connect } from 'react-redux'
import RoundProgress from '../../components/content/round_progress'
import { setRoundStatus, toggleRoundOptions } from '../../actions/index'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRoundStatus: (status, url, roundId) => dispatch(setRoundStatus(ownProps.widgetId, url, status, roundId)),
  toggleRoundOptions: (url, option, roundId) => dispatch(toggleRoundOptions(ownProps.widgetId, url, option, roundId)),
})

export default connect(
  null,
  mapDispatchToProps,
)(RoundProgress)
