import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
  GlgCheckbox,
} from 'SharedComponents'

class CheckboxPanel extends PureComponent {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    let statuses = _.clone(this.props.options)
    statuses = statuses.filter(status => status.checked === true)
    

    this.state = {
      checkAllText: statuses.length === this.props.options.length ? !window.I18n ? '' : window.I18n.t('customer_directories.filters.event_status_checkbox.uncheck_all') : !window.I18n ? '' : window.I18n.t('customer_directories.filters.event_status_checkbox.check_all'),
    }
  }
  
  handleChange(checked, value) {
    let statuses = _.clone(this.props.options)
    statuses = statuses.filter(status => status.checked === true)
    statuses = statuses.map(status => status.value)

    if (checked && value) {
      statuses.push(value)
    } else if (statuses.length > 1) {
      statuses = statuses.filter(status => status !== value)
    }

    this.props.onChange('status', statuses)
  }

  checkAll(props) {
    let statuses = _.clone(this.props.options)
    statuses = statuses.filter(status => status.checked === true)
    statuses = statuses.map(status => status.value)

    const selectedStatuses = statuses.length === this.props.options.length ? [ props.options[0].value ] : props.options.map(o => o.value)

    this.props.onChange('status', selectedStatuses)
  }
  render() {
    const panelWidth = document.getElementById('event_status').offsetWidth - 2
    return <div className="panel panel-default"><div style={{width: panelWidth}} className="panel-body"><Fragment>  <a href='javascript:void(0)' onClick={() => this.checkAll(this.props)}>{this.state.checkAllText}</a>  <hr style={{marginTop: '10px', marginBottom: '10px'}}/>  {this.props.options.map((item, index) => <div key={index}><div className="add-margin-top-10"></div><GlgCheckbox key={index} name={item.label} value={item.value} label={item.label} checked={ item.checked } onChange={ this.handleChange }></GlgCheckbox></div>)}</Fragment></div></div>
  }
}

CheckboxPanel.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
}

export default CheckboxPanel

