import React, {useState, Fragment, memo} from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'
import { GlgRadio, GlgSelect } from 'SharedComponents'
import styled from 'styled-components'
import { ScheduleItems } from '../directory_setup/components/schedules/SchedulesNew'
import { SponsorItems, AddSponsorsPopup } from '../directory_setup/components/sponsors/SponsorsNew'
import { BannerItems, AddPhotoPopup } from '../directory_setup/components/banners/BannersNew'
import ThumbnailsCrop, { ThumbnailsCropPopup } from '../directory_setup/components/thumbnails/ThumbnailsCrop'
import ThumbnailsUpload, {ThumbnailsUploadPopup} from '../directory_setup/components/thumbnails/ThumbnailsUpload'
import { getAuthenticityToken } from 'Shared/helpers'
import IframeContainerThumbnail from './IframeContainerThumbnail'
import _ from 'lodash'

const StyledFormComponents = styled.div`
  .rmargin-top-15 {
    margin-top: -15px;
  }

  .pointer {
    cursor: pointer;
  }

  .fa-calendar {
    font-size: 10px;
  }

  .event-subtype {
    width: 250px;
    margin-bottom: 20px;
  }

  .banner-error {
    float: left;
    margin-top: -29px;
    display: inline-block;
  }

  .inline_element {
    display: inline-block;
  }
`

const GolfHubRoot = ({schedules, sponsors, courseAlbums, leagueId, setup, isCharityOuting, thumbnail, eventCardPreviewData}) => {
  const [ newSchedules, setNewSchedules ] = useState([ {time: new Date(), activity: ''} ])
  const [ newSponsors, setNewSponsors ] = useState([])
  const [ newSponsorsUpdated, setNewSponsorsUpdated ] = useState({})
  const [ newBannersUpdated, setNewBannersUpdated ] = useState({})
  const [ sponsorModalOpen, setSponsorModalOpen ] = useState(false)
  const [ sponsorDeleted, setSponsorDeleted ] = useState([])
  const [ newCourseAlbums, setNewCourseAlbums ] = useState([])
  const [ courseAlbumModalOpen, setCourseAlbumModalOpen ] = useState(false)
  const [ courseAlbumDeleted, setCourseAlbumDeleted ] = useState([])
  const [ newThumbnail, setNewThumbnail ] = useState({ image: '', crop: { x: 0, y: 0 }})
  const [ thumbnailSetting, setThumbnailSetting ] = useState(setup.thumbnail_setting)

  const setNewSponsor = (records) => {
    setNewSponsors(records)

    window.glg.changeNewRecords('sponsor', records)

    const formData = new FormData
    records.forEach(sponsor => {
      formData.append('sponsor_urls[]', sponsor.url)
      formData.append('sponsor_photos[]', sponsor.imageFile)
    })

    fetch(`/leagues/${leagueId}/create_temp_sponsors`, { method: 'POST', body: formData, headers: { 'X-CSRF-Token': getAuthenticityToken() } } )
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      window.glg.showNewRecords('sponsors', data.sponsor.photos)
      const newSponsors = {}
      data.sponsor.photos.map((sponsor, index) => { newSponsors[records[index]?.image] = sponsor })
      setNewSponsorsUpdated(newSponsors)
    })
  }

  const setNewCourseAlbum = (records) => {
    setNewCourseAlbums(records)
    window.glg.changeNewRecords('course_album', records)

    const formData = new FormData
    records.forEach(ca => {
      formData.append('course_album_photos_crop[]', JSON.stringify(ca.crop))
      formData.append('course_album_photos[]', ca.imageFile)
    })

    fetch(`/leagues/${leagueId}/create_temp_course_albums`, { method: 'POST', body: formData, headers: { 'X-CSRF-Token': getAuthenticityToken() } } )
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      window.glg.showNewRecords('course_album', data.course_album.photos.map((courseImage) => (courseImage.course_url)))
      const newBanners = {}
      data.course_album.photos.map((banner, index) => { newBanners[records[index]?.image] = banner })
      setNewBannersUpdated(newBanners)
    })
  }

  const submitForm = () => {
    const form = document.querySelector('.edit_golfhub_data')
    const formData = new FormData(form)

    formData.append('event_description', window.glg.CKEDITOR.instances['event_description'].getData())

    if (newCourseAlbums.length > 0) {
      newCourseAlbums.forEach(i => {
        formData.append('course_album_photos_crop[]', JSON.stringify(i.crop))
        formData.append('course_album_photos[]', i.imageFile)
      })
    }

    if (newSponsors.length > 0) {
      newSponsors.forEach(i => {
        formData.append('sponsor_urls[]', i.url)
        formData.append('sponsor_photos[]', i.imageFile)
      })
    }

    if (newThumbnail.image !== '') {
      formData.append('thumbnail_photo_crop', JSON.stringify(newThumbnail.crop))
      formData.append('thumbnail_photo', newThumbnail.imageFile)
      formData.append('ggsd_thumbnail_setting', thumbnailSetting)
    } else {
      formData.append('ggsd_thumbnail_setting', 'crop')
      formData.append('banner_thumbnail_no_crop', true)
    }

    formData.append('charity_outing', isCharityOuting)

    const saveAndPublishForm = document.querySelector('.edit_golfhub_data.save_and_publish')
    let saveRoute = `/leagues/${leagueId}/update_golfhub_data`
    if (saveAndPublishForm !== null) {
      saveRoute = `/leagues/${leagueId}/save_and_publish_to_directory`
    }
    
    fetch(saveRoute, {
      method: 'PATCH',
      body: formData,
      headers: {'X-CSRF-Token': getAuthenticityToken()}})
    .then((res) => {
      return res.text()
    })
    .then((res) => {
      eval(res)
      if (saveAndPublishForm !== null) {
        document.querySelector('.save_and_publish_golfhub_data').disabled = false
        document.querySelector('.save_and_publish_golfhub_data').value = window.I18n.t('views.golfhub.save_and_publish')
        saveAndPublishForm.classList.remove('save_and_publish')
      } else {
        document.querySelector('.save_golfhub_data').disabled = false
        document.querySelector('.save_golfhub_data').value = window.I18n.t('add_members.save')
      }
      return false
    })
  }

  window.glg.submitRegistrationFormFromGolfhubPage = submitForm
  return (
      <StyledFormComponents>
        <TemplateFields
          label='Schedule'
          items={schedules}
          defaultValue={setup.directory_schedule_id}
          inputName='schedule'
          newRecords={newSchedules}
          setNewRecords={setNewSchedules}
        />
        <TemplateFields
          label='Sponsor'
          items={sponsors}
          defaultValue={setup.sponsor_template_id}
          inputName='sponsor'
          newRecords={newSponsors}
          setNewRecords={setNewSponsors}
          deleted={sponsorDeleted}
          setDeleted={setSponsorDeleted}
          setModalOpen={setSponsorModalOpen}
          newRecordsUpdated={newSponsorsUpdated}
        />
        <TemplateFields
          label='Banner'
          items={courseAlbums}
          defaultValue={setup.course_album_id}
          inputName='course_album'
          newRecords={newCourseAlbums}
          setNewRecords={setNewCourseAlbums}
          deleted={courseAlbumDeleted}
          setDeleted={setCourseAlbumDeleted}
          setModalOpen={setCourseAlbumModalOpen}
          newRecordsUpdated={newBannersUpdated}
          thumbnail={thumbnail}
          eventCardPreviewData={eventCardPreviewData}
          thumbnail_setting={setup.thumbnail_setting}
          leagueId={leagueId}
          mainSetNewThumbnail={setNewThumbnail}
          mainSetThumbnailSetting={setThumbnailSetting}
        />
        <AddSponsorsPopup
          modalOpen={sponsorModalOpen}
          setModalOpen={setSponsorModalOpen}
          images={newSponsors}
          setImages={setNewSponsor}
        />
        <AddPhotoPopup
          modalOpen={courseAlbumModalOpen}
          setModalOpen={setCourseAlbumModalOpen}
          images={newCourseAlbums}
          setImages={setNewCourseAlbum}
        />
      </StyledFormComponents>
  )
}

// eslint-disable-next-line react/display-name
const TemplateFields = memo((props) => {
  let settingValue = props.defaultValue ? 'template' : 'none'
  if (props.inputName === 'course_album' && settingValue === 'none') {
    settingValue = 'new'
  }

  const [ setting, setSetting ] = useState(settingValue)
  const onRegisteredItemSelected = (e) => {
    window.glg.changeSelected(props.inputName, e)

    if (props.inputName === 'course_album') {
      window.glg.userSelectedAlbum(e)
      handleCourseAlbumChangeForThumbnail(props.items.find(courseAlbum => courseAlbum.id === e))
    }
  }

  const [ defaultValue, setDefaultValue ] = useState(props.defaultValue)

  if (props.inputName === 'course_album') {
    window.glg.forceBannerUpdate = (id) => {
      setDefaultValue(id)
      window.glg.changeSelected(props.inputName, id)
    }
  }

  const onRadioSelected = (e) => {
    if (e === 'new') {
      window.glg.changeNewRecords(props.inputName, props.newRecords)
    } else if (e === 'none') {
      window.glg.changeSelected(props.inputName, [])
    } else {
      if (props.inputName === 'course_album'){
        window.glg.changeSelected(props.inputName, selectedCourseAlbum.id || props.items[0].id)
      } else {
        window.glg.changeSelected(props.inputName, props.defaultValue || props.items[0].id)
      }
    }
  }

  const setNewRecords = (records) => {
    props.setNewRecords(records)
    window.glg.changeNewRecords(props.inputName, records)

    if (props.inputName === 'course_album') {
      window.glg.showNewRecords('course_album', records.map((record) => { return props.newRecordsUpdated[record.image].course_url }))
    } else if (props.inputName === 'sponsor') {
      window.glg.showNewRecords('sponsors', records.map((record) => { return props.newRecordsUpdated[record.image] }))
    }
  }

  if (props.inputName === 'course_album') {
    window.glg.uploadedNewBanner = () => {
      return props.newRecords.length > 0
    }
  }

  const [ thumbnailCropModalOpen, setThumbnailCropModalOpen ] = useState(false)
  const [ thumbnailUploadModalOpen, setThumbnailUploadModalOpen ] = useState(false)
  const [ selectedCourseAlbum, setSelectedCourseAlbum ] = useState(props.items.find(courseAlbum => courseAlbum.id === props.defaultValue))
  const [ selectedThumbnail, setSelectedThumbnail ] = useState(props.thumbnail || selectedCourseAlbum)
  const [ previewData, setPreviewData ] = useState(props.eventCardPreviewData)
  const [ newThumbnail, setNewThumbnail ] = useState({ image: '', crop: { x: 0, y: 0 }})
  const [ thumbnailSetting, setThumbnailSetting ] = useState(props.thumbnail_setting)
  const [ loadingForNewBanner, setLoadingForNewBanner ] = useState(false)

  const handleCourseAlbumChangeForThumbnail = (newSelectedCourseAlbum) => {
    setSelectedCourseAlbum(newSelectedCourseAlbum)
    if (thumbnailSetting === 'crop' || (newThumbnail.image === '' && !props.thumbnail)) {
      setSelectedThumbnail(newSelectedCourseAlbum)
      setNewThumbnail({ image: '', crop: { x: 0, y: 0 }})
      props.mainSetNewThumbnail({ image: '', crop: { x: 0, y: 0 }})
    }
  }

  const handleChooseCrop = () => {
    setThumbnailSetting('crop')
    props.mainSetThumbnailSetting('crop')
    setSelectedThumbnail(selectedCourseAlbum)
    setNewThumbnail({ image: '', crop: { x: 0, y: 0 }})
    props.mainSetNewThumbnail({ image: '', crop: { x: 0, y: 0 }})
  }

  const setNewSelectedThumbnail = (record) => {
    setNewThumbnail(record)
    props.mainSetNewThumbnail(record)
    const formData = new FormData

    formData.append('thumbnail_photo_crop', JSON.stringify(record.crop))
    formData.append('thumbnail_photo', record.imageFile)

    setPreviewData({
      ...previewData,
      loading: true,
    })

    fetch(`/leagues/${props.leagueId}/create_temp_thumbnail`, { method: 'POST', body: formData, headers: { 'X-CSRF-Token': getAuthenticityToken() } } )
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        const newThumbnail = data.thumbnail
        setSelectedThumbnail(newThumbnail)
        setPreviewData({
          ...previewData,
          loading: false,
        })
      })
  }

  if (props.inputName === 'course_album' && props.newRecords.length > 0) {
    if (props.newRecords.length !== Object.keys(props.newRecordsUpdated).length && !previewData.loading) {
      setLoadingForNewBanner(true)
      setPreviewData({
        ...previewData,
        loading: true,
      })
    } else if (props.newRecords.length === Object.keys(props.newRecordsUpdated).length && loadingForNewBanner) {
      setLoadingForNewBanner(false)
      setPreviewData({
        ...previewData,
        loading: false,
      })
      if (props.newRecords.length === 1) {
        setSelectedThumbnail(selectedCourseAlbum)
      }
    }
  }

  return (
    <Fragment>
      <div className="row">
        <Col xs={12} className="description no-margin-bottom no-padding add-margin-top-30">
          <Col xs={12} sm={3} md={3} lg={3} className="no-margin-bottom no-padding add-padding-top-5 add-padding-left-15">
            <label>{props.label}</label>
          </Col>
          <Col xs={12} sm={9} className="no-padding description no-margin-bottom">
            <Col xs={12} sm={3} md={3} lg={3} className="inline_container add-padding-top-5">
              <GlgRadio
                name={props.inputName}
                value="template"
                label={'Template'}
                defaultChecked={ props.defaultValue !== null }
                onChange={(v) => { setSetting(v); onRadioSelected(v) }}
              />
            </Col>
            {setting === 'template' && (
              <Col xs={12} sm={9} md={9} lg={9} className="inline_container add-margin-top-5">
                <GlgSelect
                  name={'selected_' + props.inputName}
                  defaultValue={ props.inputName === 'course_album' ? selectedCourseAlbum.id : defaultValue }
                  value={ props.inputName === 'course_album' ? selectedCourseAlbum.id : defaultValue }
                  items={ props.items.map((i) => ({label: i.name, value: i.id}))}
                  onChange={ onRegisteredItemSelected }
                />
              </Col>
            )}
            <Col xs={12} className="description no-margin-bottom no-padding add-padding-left-15">
              <Col xs={12} sm={3} md={3} lg={3} className="no-margin-bottom no-padding inline_container margin-top-5 add-padding-top-5">
                <GlgRadio
                  name={props.inputName}
                  value="new"
                  label={'New ' + props.label}
                  defaultChecked={ false || (props.inputName === 'course_album' && setting !== 'template') }
                  onChange={(v) => { setSetting(v); onRadioSelected(v) }}
                />
              </Col>
              {setting === 'new' && (
                <Col xs={12} sm={9} md={9} lg={9} className="inline_container add-padding-top-5">
                  {props.inputName === 'schedule' && (
                    <ScheduleItems
                      schedules={props.newRecords}
                      setSchedules={setNewRecords}/>
                  )}
                  {props.inputName === 'sponsor' && (
                    <SponsorItems
                      images={props.newRecords}
                      setImages={setNewRecords}
                      deleted={props.deleted}
                      setDeleted={props.setDeleted}
                      setModalOpen={props.setModalOpen}
                    />
                  )}
                  {props.inputName === 'course_album' && (
                    <div>
                      <BannerItems
                        images={props.newRecords}
                        setImages={setNewRecords}
                        deleted={props.deleted}
                        setDeleted={props.setDeleted}
                        setModalOpen={props.setModalOpen}
                      />
                      <div className='inline_element'>
                        <div className='banner-error hidden error_text'>At least one photo is required</div>
                      </div>
                    </div>
                  )}
                </Col>
              )}
            </Col>

            <Col xs={12} className="inline_container margin-top-5 add-padding-top-5">
              {props.inputName !== 'course_album' && (
                <GlgRadio
                  name={props.inputName}
                  value="none"
                  label={'No ' + props.label}
                  defaultChecked={ !props.defaultValue }
                  onChange={(v) => { setSetting(v); onRadioSelected(v) }}
                />
              )}
            </Col>
          </Col>
        </Col>
      </div>
      { props.inputName === 'course_album' &&
        <div className="row">
          <Col xs={12} className="description no-margin-bottom no-padding add-margin-top-30">
            <Col xs={12} sm={3} md={3} lg={3} className="no-margin-bottom no-padding add-padding-top-5 add-padding-left-15">
              <label>{'Thumbnail'}</label>
            </Col>
            <Col xs={12} sm={9} className="no-padding description no-margin-bottom">
              <Col xs={12} sm={12} md={12} lg={6} className="no-padding description no-margin-bottom">
                <Col xs={12} className="description no-margin-bottom no-padding add-padding-left-15">
                  <Col xs={12} sm={6} md={6} lg={6} className="no-margin-bottom no-padding inline_container margin-top-5 add-padding-top-5">
                    <GlgRadio
                      name={'thumbnail'}
                      value="crop"
                      label={'Crop from banner'}
                      defaultChecked={thumbnailSetting === 'crop'}
                      onChange={handleChooseCrop}
                    />
                  </Col>
                  {thumbnailSetting === 'crop' && (
                    <Col xs={12} sm={6} md={6} lg={6} className="inline_container add-padding-top-5">
                      <ThumbnailsCrop
                        setModalOpen={setThumbnailCropModalOpen}
                      />
                      <ThumbnailsCropPopup
                        modalOpen={thumbnailCropModalOpen}
                        setModalOpen={setThumbnailCropModalOpen}
                        selectedCourseAlbum={(setting === 'template' || _.isEmpty(props.newRecordsUpdated)) ? selectedCourseAlbum : {photos: [ props.newRecordsUpdated[props.newRecords[0].image] ]}}
                        newThumbnail={newThumbnail}
                        setNewThumbnail={setNewSelectedThumbnail}
                      />
                    </Col>
                  )}
                </Col>
                <Col xs={12} className="description no-margin-bottom no-padding add-padding-left-15">
                  <Col xs={12} sm={6} md={6} lg={6} className="no-margin-bottom no-padding inline_container margin-top-5 add-padding-top-5">
                    <GlgRadio
                      name={'thumbnail'}
                      value="upload"
                      label={'Upload thumbnail'}
                      defaultChecked={thumbnailSetting === 'upload'}
                      onChange={() => { setThumbnailSetting('upload'); props.mainSetThumbnailSetting('upload') }}
                    />
                  </Col>
                  {thumbnailSetting === 'upload' && (
                    <Col xs={12} sm={6} md={6} lg={6} className="inline_container add-padding-top-5">
                      <ThumbnailsUpload
                        setModalOpen={setThumbnailUploadModalOpen}
                      />
                      <ThumbnailsUploadPopup
                        modalOpen={thumbnailUploadModalOpen}
                        setModalOpen={setThumbnailUploadModalOpen}
                        setNewThumbnail={setNewSelectedThumbnail}
                      />
                    </Col>
                  )}
                </Col>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} className="no-padding description no-margin-bottom">
                <IframeContainerThumbnail
                  thumbnail={(setting === 'template' || _.isEmpty(props.newRecordsUpdated)) || newThumbnail['image'] !== '' ? selectedThumbnail : { photos: [ props.newRecordsUpdated[props.newRecords[0].image] ] }}
                  data={previewData}
                />
              </Col>
            </Col>
          </Col>
        </div>
      }
    </Fragment>
  )
})

GolfHubRoot.propTypes = {
  schedules: PropTypes.array,
  sponsors: PropTypes.array,
  courseAlbums: PropTypes.array,
  leagueId: PropTypes.string,
  setup: PropTypes.object,
  isCharityOuting: PropTypes.string,
  courseDetailsPath: PropTypes.string,
  tabularViewPath: PropTypes.string,
  thumbnail: PropTypes.object,
  eventCardPreviewData: PropTypes.object,
}

TemplateFields.propTypes = {
  label: PropTypes.string,
  items: PropTypes.array,
  defaultValue: PropTypes.string,
  inputName: PropTypes.string,
  newRecords: PropTypes.array,
  setNewRecords: PropTypes.func,
  newRecordsUpdated: PropTypes.object,
  deleted: PropTypes.array,
  setDeleted: PropTypes.func,
  setModalOpen: PropTypes.func,
  thumbnail: PropTypes.object,
  eventCardPreviewData: PropTypes.object,
  thumbnail_setting: PropTypes.string,
  leagueId: PropTypes.string,
  mainSetNewThumbnail: PropTypes.func,
  mainSetThumbnailSetting: PropTypes.func,
}

export default GolfHubRoot
