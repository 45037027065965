import React, { useState, useEffect, Fragment } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import PropTypes from 'react'
import { callAPI } from 'Shared/helpers'
import { GlgLoadingSpinner } from 'SharedComponents'
import { getAuthenticityToken } from 'Shared/helpers'

const SponsorsIndex = (props) => {

  const [ sponsors, setSponsors ] = useState( [ 1, 2, 3 ] )
  const [ dataIsLoading, setDataIsLoading ] = useState(true)

  useEffect(() => {
    
    callAPI('/api/customers/' + props.customerId + '/sponsors', 'GET')
      .then((json) => {
        setSponsors(json)
        setDataIsLoading(false)
      })
  }, [])
  
  const handleDelete = ({id, allowDestroy}) => {
    if (allowDestroy) {
      // eslint-disable-next-line no-alert
      if (confirm(window.I18n.t('views.golfhub.remove_sponsor_confirmation_message')) === true) {
        let temp = [ ...sponsors ]
        temp = temp.filter((v) => v.id !== id)
        
        fetch('/api/customers/' + props.customerId + '/sponsors/' + id, {
          method: 'DELETE',
          headers: {'X-CSRF-Token': getAuthenticityToken()}})
        .then(() => setSponsors(temp))
      }
    } else {
      // eslint-disable-next-line no-alert
      alert(window.I18n.t('views.golfhub.unable_to_remove_sponsors'))
    }
  }

  return (
    <Row className='no-margin'>
      <Col className='add-padding-all-20' xs={ 12 }>
        <div className='btsp_bullet_button pointer' onClick={() => props.setPage('new')}>
          <div className='icon'><i className='gga-file-plus'></i></div>
          <div className='btsp_align-middle'>New Sponsor Template</div>
        </div>
        { dataIsLoading && (<GlgLoadingSpinner className='text-center' show={dataIsLoading}/>)}

        { !dataIsLoading && sponsors.length === 0 && (
          <div className='text-center'>No sponsors templates yet</div>
        )}
        { !dataIsLoading && sponsors.length > 0 && (
          <div className='table-responsive btsp_table_container table_with_dropdown'>
            <table className='table table-striped table-drop'>
              <thead>
                <tr className='header'>
                  <td className='w-20 middle'><b>NAME</b></td>
                  <td className='w-10 middle'></td>
                  <td className='pdl-40 middle'><b>SPONSORS</b></td>
                  <td className='w-10 middle'></td>
                </tr>
              </thead>
              <tbody>
                {sponsors.map(sponsor => (
                  <tr key={sponsor.id}>
                    <SponsorsRow handleDelete={handleDelete} setPage={props.setPage} {...sponsor}/>
                  </tr>
                ))}
                
                
              </tbody>
                  
            </table>
          </div>
        )}
            

      </Col>
    </Row>
  )
}

const SponsorsRow = (props) => {
  const [ open, setOpen ] = useState( false )

  return (
    <Fragment>
      <td className='w-20 template-name'>
        <a onClick={() => props.setPage('edit/' + props.id)}>{props.name}</a>
      </td>
      <td className='w-10 middle blue-label-container'>
        {
          props.primary === true && 
            <div className='blue-label'>
              { window.I18n.t('views.golfhub.default') }
            </div>
        }
      </td>
      <td className='template-content'>
        { props.photos && props.photos.map((p, index) => (
          <div className='img-container' key={props.id + '-' + index}>
            <img src={p}/>
          </div>
        ))}
      </td>
      <td className='w-10 template-content'>
        <div className={'dropMenu dropdown' + (open ? ' open' : '')} onClick={() => setOpen(!open)}>
          <a className='btn btn-default btn-xs new_dropdown_button_icon' href='javascript:void(0);'>
            <i className={'fa fa-lg ' + (open ? 'fa-caret-up' : 'fa-caret-down') }/>
          </a>
          <ul className='dropdown-menu'>
            <li><a onClick={() => props.setPage('edit/' + props.id)}>Edit</a></li>   
            <li><a onClick={() => props.handleDelete({id: props.id, allowDestroy: props.allowDestroy})}>Delete</a></li>
          </ul>
        </div>
      </td>
    </Fragment>
  )
}

SponsorsRow.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  primary: PropTypes.string,
  photos: PropTypes.array,
  allowDestroy: PropTypes.bool,
  setPage: PropTypes.func,
  handleDelete: PropTypes.func,
}

SponsorsIndex.propTypes = {
  setPage: PropTypes.func,
  customerId: PropTypes.string,
}

export default SponsorsIndex
