import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
  GlgList,
} from 'SharedComponents'

import NavigatorOptionContainer from '../containers/navigator_option_container'

class NavigatorBookmarksComponent extends Component {
  constructor(props) {
    super(props)
    this.handleCategoriesSortEnd = this.handleCategoriesSortEnd.bind(this)
  }

  handleCategoriesSortEnd(fromIndex, toIndex) {
    this.props.reorderBookmarks(this.props.bookmarkedNavigatorOptions[fromIndex].id,
                                this.props.bookmarkedNavigatorOptions[toIndex].id)
  }

  render() {
    return (
      !_.isEmpty(this.props.bookmarkedNavigatorOptions) ? <GlgList items={ this.props.bookmarkedNavigatorOptions } itemComponent={ NavigatorOptionContainer } isSortable={ true } onSortEnd={ this.handleCategoriesSortEnd }></GlgList> : <p className="navigator-search-text">{ !window.I18n ? '' : window.I18n.t('navigator.components.navigator_bookmarks_component.search_text') }</p>
    )
  }
}

NavigatorBookmarksComponent.propTypes = {
  bookmarkedNavigatorOptions: PropTypes.array.isRequired,
  reorderBookmarks: PropTypes.func.isRequired,
}

export default NavigatorBookmarksComponent
