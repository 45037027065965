import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'

import ParticipationHistoryTableRow from './participation_history_table_row'

import {
  GlgTable,
  GlgTableCol,
  GlgLoadingSpinner,
} from 'SharedComponents'
import PaginationComponent from './pagination_component'

class EventListComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let events = ''
    let pagination = ''
    let sectionTitle = this.props.sectionTitle

    if (!this.props.dataIsLoading){
      if (this.props.eventsCount === 0) {
        events = <div className="no-info">{ this.props.noInfoMessage }</div>
      } else {
        const totalColumn = <tr><td className="hidden"></td><td className="glg-table-header" style={{ width: '40%' }}><div className="bold">{ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.total_earnings') }</div></td><td className="glg-table-header" style={{ width: '20%' }}></td><td className="glg-table-header" style={{ width: '20%' }}></td><td className="glg-table-header" style={{ width: '20%' }}>{ this.props.totalMoneyEarned }</td></tr>
        const extraProps = (!this.props.moneyEarnedEnabled) ?
          { target: this.props.target,
            moneyEarnedEnabled: this.props.moneyEarnedEnabled }
          : { target: this.props.target,
            moneyEarnedEnabled: this.props.moneyEarnedEnabled,
            areLoadingEventsIds: this.props.areLoadingEventsIds,
            loadedEvents: this.props.loadedEvents,
            fetchMoneyEarnedInformation: this.props.fetchMoneyEarnedInformation,
            isOpenedEventPopup: this.props.isOpenedEventPopup,
            currentlyOpenedEventPopup: this.props.currentlyOpenedEventPopup,
            onPopupOpen: this.props.onPopupOpen }
        const lastEventIndex = this.props.page * 10
        const firstEventIndex = lastEventIndex - 10
        const visibleEvents = this.props.events.slice(firstEventIndex, lastEventIndex)
        events = (!this.props.moneyEarnedEnabled) ? <GlgTable items={ visibleEvents } rowComponent={ ParticipationHistoryTableRow } rowExtraProps={ extraProps }><GlgTableCol width={ '40%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.name') }></GlgTableCol><GlgTableCol width={ '20%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.season') }></GlgTableCol><GlgTableCol width={ '30%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.date') }></GlgTableCol><GlgTableCol width={ '10%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.actions') }></GlgTableCol></GlgTable> : <GlgTable items={ visibleEvents } rowComponent={ ParticipationHistoryTableRow } rowExtraProps={ extraProps } showFooter={ true } footerComponent={ totalColumn }><GlgTableCol width={ '40%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.name') }></GlgTableCol><GlgTableCol width={ '20%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.season') }></GlgTableCol><GlgTableCol width={ '30%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.date') }></GlgTableCol><GlgTableCol width={ '10%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.earnings') }></GlgTableCol></GlgTable>
        sectionTitle += ' • ' + this.props.eventsCount
        if (this.props.eventsCount > 10) {
          pagination = <PaginationComponent page={ this.props.page } elementsCount={ this.props.eventsCount } setCurrentPage={ this.props.setCurrentPage }></PaginationComponent>
        }
      }
    }

    return <Col xs={ 12 }><Row className="title no-lateral-margin">{ sectionTitle }</Row><Row className="no-lateral-margin"><GlgLoadingSpinner show={ this.props.dataIsLoading }></GlgLoadingSpinner>{ pagination }{ events }</Row></Col>
  }
}

EventListComponent.propTypes = {
  //The array of events
  events: PropTypes.array.isRequired,
  //Checks if money earned column is enabled
  moneyEarnedEnabled: PropTypes.bool.isRequired,
  //Total money earned
  totalMoneyEarned: PropTypes.string,
  //The number of events
  eventsCount: PropTypes.number.isRequired,
  //The title of the section (either Past Events or Future Events)
  sectionTitle: PropTypes.string.isRequired,
  //The message to display when no events are found
  noInfoMessage: PropTypes.string.isRequired,
  //The loaded additional information for the events
  loadedEvents: PropTypes.object,
  //The ids of the events that are loading
  areLoadingEventsIds: PropTypes.array,
  //The function used to fetch data about the money earned
  fetchMoneyEarnedInformation: PropTypes.func,
  //Checks if the data is loading (for the spinner)
  dataIsLoading: PropTypes.bool,
  //Is an event money earned additional information popup opened
  isOpenedEventPopup: PropTypes.bool,
  //The id of the event whose popup is opened
  currentlyOpenedEventPopup: PropTypes.string,
  //The function called when a popup link is clicked
  onPopupOpen: PropTypes.func,
  target: PropTypes.string,
  page: PropTypes.number,
  setCurrentPage: PropTypes.func,
}

export default EventListComponent
