import { combineReducers } from 'redux'
import channels from './channels'
import triggers from './triggers'
import recipientList from './recipient_list'
import schedule from './schedule'
import deliveryReports from './delivery_reports'
import { ActionTypes } from '../actions/setup'

const {
  SET_NAME,
} = ActionTypes

function general(state = {}, action) {
  switch (action.type){
    case SET_NAME:
      return {
        ...state,
        name: action.name,
      }
    default:
      return state
  }
}

export default combineReducers({
  general,
  channels,
  triggers,
  recipientList,
  schedule,
  deliveryReports,
})
