import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import DoubleScoreVerificationTableContainer from '../containers/double_score_verification_table_container'
import VerificationTeamPopupContainer from '../containers/verification_team_popup_container'
import VerificationPopupContainer from '../containers/verification_popup_container'

class DoubleScoreVerificationComponent extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.timer = setInterval(this.props.refreshData, 20000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    const { step, courseName, leagueName, showPopup, showTeamPopup, teamscoreVerification } = this.props
    const pageTitle = step === 'manager_step' ? courseName : leagueName + ' - ' + courseName

    return (
      <Fragment>
        <div className='row row-no-padding'>
          <div className='alert product_warning stick_to_breadcrumb'>
            <ul className='list-inline'>
              <li className='bold-note'>Note:</li>
              <li>
                This page displays the list of players that require score verification. 
                To start the verification process, please click on a player to bring up 
                the verification popup. Once verification is complete, the player is 
                removed from this list.
              </li>
            </ul>
          </div>
        </div>
        <div className='page_title'>
          <div className='text'>{pageTitle}</div>
        </div>
        { showPopup && !teamscoreVerification && <VerificationPopupContainer />}
        { showTeamPopup && teamscoreVerification && <VerificationTeamPopupContainer />}
        <DoubleScoreVerificationTableContainer />
      </Fragment>
    )
  }
}

DoubleScoreVerificationComponent.propTypes = {
  leagueId: PropTypes.string,
  roundId: PropTypes.string,
  doubleScoreVerificationId: PropTypes.string,
  step: PropTypes.string, 
  leagueName: PropTypes.string,
  courseName: PropTypes.string,
  refreshData: PropTypes.func,
  showPopup: PropTypes.bool,
  showTeamPopup: PropTypes.bool,
  teamscoreVerification: PropTypes.bool,
}

export default DoubleScoreVerificationComponent
