import { ActionTypes } from '../actions'

const {
  SET_DATA,
  RESET_TIMER,
  CLEAR_TIMER,
} = ActionTypes

const initialState = {
  fetchDataPath: '#',
  title: '',
  current: {},
  upcoming: {},
  timer: null,
}

const misc = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        current: action.data.current,
        upcoming: action.data.upcoming,
      }

    case RESET_TIMER:
      if (state.timer !== null) {
        clearInterval(state.timer)
      }

      return {
        ...state,
        timer: setInterval(() => action.fetchMethod(), 30000),
      }

    case CLEAR_TIMER:
      if (state.timer !== null) {
        clearInterval(state.timer)
      }

      return {
        ...state,
        timer: null,
      }

    default:
      return {
        ...state,
      }
  }
}

export default misc

// case RESET_TIMER:
//     if (state.timer !== null) {
//       clearInterval(state.timer)
//     }

//     return {
//       ...state,
//       timer: setInterval(() => action.refreshMethod(), 30000),
//     }
