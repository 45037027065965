import React from 'react'
import configureStore from 'Shared/configure_store'
import rootReducer from '../../reducers/setup'
import { GlobalProvider } from 'Shared/global_context'
import DeliveryReportsContainer from '../../containers/delivery_reports/delivery_reports_container'
import PropTypes from 'prop-types'

const DeliveryReportsRoot = (props) => {
  const state = {
    deliveryReports: {
      emails: {
        currentPage: 1,
        reports: [],
        filters: {
          player: 'default',
          subject: 'default',
          status: 'default',
        },
      },
      textMessages: {
        total: props.total_text_message_reports,
        currentPage: 1,
        reports: [],
        filters: {
          player: 'default',
          description: 'default',
        },
      },
    },
  }  
  const store = configureStore(rootReducer, state)  

  return <GlobalProvider store={ store }><DeliveryReportsContainer paths={props.paths} filterDropdownValues={props.filter_dropdown_values} hasChannel={props.has_channel}></DeliveryReportsContainer></GlobalProvider>
}

DeliveryReportsRoot.propTypes = {
  total_text_message_reports: PropTypes.number.isRequired,
  paths: PropTypes.shape({
    load_email_delivery_reports: PropTypes.string.isRequired,
    load_text_message_delivery_reports: PropTypes.string.isRequired,
  }).isRequired,
  filter_dropdown_values: PropTypes.shape({
    email: PropTypes.shape({
      players: PropTypes.array.isRequired,
      subjects: PropTypes.array.isRequired,
      statuses: PropTypes.array.isRequired,
    }),
    text_message: PropTypes.shape({
      players: PropTypes.array.isRequired,
      descriptions: PropTypes.array.isRequired,
    }),
  }).isRequired,
  has_channel: PropTypes.shape({
    email: PropTypes.number,
    textMessage: PropTypes.number,
  }).isRequired,
}

export default DeliveryReportsRoot

