import { connect } from 'react-redux'
import DoubleScoreVerificationTableComponent from '../components/double_score_verification_table_component'

import { openPopup } from '../actions'
import { openTeamPopup } from '../actions'

const mapStateToProps = (state) => {
  return {
    tees: state.misc.tees,
    foursomes: state.misc.foursomes,
    isMultiRound: state.misc.isMultiRound,
    holeView: state.misc.holeView,
    teamscoreVerification: state.misc.teamscoreVerification,
  }
}

const mapDispatchToProps = (dispatch) => ({
  openPopup: (playerId, playerName, scores, shots, pars, teeTime) => dispatch(openPopup(playerId, playerName, scores, shots, pars, teeTime)),
  openTeamPopup: (players, scoresData, teamName, teeTime, foursomeId, teamTotals, stablefordPoints) => dispatch(openTeamPopup(players, scoresData, teamName, teeTime, foursomeId, teamTotals, stablefordPoints)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DoubleScoreVerificationTableComponent)
