import React, { Component } from 'react'
import PropTypes from 'prop-types'

class DigitalScorecardsStationPopup extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const link = this.props.link
    const text = window.I18n.t('rounds.bootstrap_partials.portal_and_mobile.digital_scorecards_pupup')
    return (
      <div className="modal" id="digital_scorecards_stations" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-body">
                  <div style={{whiteSpace: 'pre-wrap'}}>{text.replace(/<br\s*\/?>/gi, '\n')}</div>
                  <br/>
                  <a className='btn button btsp_gray_button' onClick={() => $('#digital_scorecards_stations').modal('hide')}>{window.I18n.t('rounds.bootstrap_partials.portal_and_mobile.cancel_button')}</a>
                  <a href={ link } className='btsp_orange_button save_button btn' >{window.I18n.t('rounds.bootstrap_partials.portal_and_mobile.continue_button')}</a>
                </div>
            </div>
        </div>
      </div>
    )
  }
}


DigitalScorecardsStationPopup.propTypes = {
  link: PropTypes.string,
}


export default DigitalScorecardsStationPopup
