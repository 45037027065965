import { connect } from 'react-redux'
import StickyMember from '../components/sticky_member_component'

const mapStateToProps = (_, props) => {
  return { 
    member: props.member,
  }
}

export default connect(
  mapStateToProps,
  null,
)(StickyMember)
