import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Preview from './preview'
import Hover from './hover'
import Processing from './processing'
import Download from './download'

class ReportCard extends Component {
  constructor(props){
    super(props)
  }

  stringParameterize(str) {
    return str.trim().toLowerCase()
      .replace(/[^a-zA-Z0-9 -]/g, '')
      .replace(/ +/g, ' ')
      .replace(/[\s-]/g, '_')
  }

  render() {
    const { report, reportStates } = this.props

    const reportContent = (reportState, report) => {
      switch (reportState) {
        case reportStates.preview:
          return <Preview report={ report } />
        case reportStates.hover:
          return <Hover report={ report }
                        reportPrint={ this.props.reportPrint }
                        reportAction={ this.props.reportAction }
                        reportImport={ this.props.reportImport } />
        case reportStates.processing:
          return <Processing report={ report }
                             reportPollJobStatus= { this.props.reportPollJobStatus } />
        case reportStates.download:
          return <Download report={ report }
                           reportSetState={ () => this.props.reportSetState(report.id, reportStates.hover) }
                           reportGenerateDoc={ () => this.props.reportGenerateDoc(report.id, report.name, report.job.pdfPath, report.job.primaryDocxService) } 
                           reportGenerateTxt={ () => this.props.reportGenerateTxt(report.id, report.name, report.job.pdfPath, report.job.generateTxt) } />
        default:
          return <Preview report={ report } />
      }
    }

    return (
      <div className='widget-report-card'>
        <div className='top-bar'>{ !window.I18n ? '' : window.I18n.t(`download_center.item_name.${this.stringParameterize(report.name)}`, {defaultValue: report.name}) }</div>
        <div className='preview'
             onMouseEnter={ () => report.state === reportStates.preview ? this.props.reportSetState(report.id, reportStates.hover) : null }
             onMouseLeave={ () => report.state === reportStates.hover ? this.props.reportSetState(report.id, reportStates.preview) : null } >
          { reportContent(report.state, report) }
        </div>
        <div className='bottom-bar'>
          <span>
            <i className='fa fa-fw fa-list'></i> { report.list_count }
          </span>
          <span>
            <i className='fa fa-fw fa-search modal-preview-button' onClick={ () => this.props.reportTogglePreviewModal(report.id, report.image_url, report.name) }></i>
          </span>
          <span>
            <i className='fa fa-fw fa-print'></i> { report.print_count }
          </span>
        </div>
      </div>
    )
  }
}

ReportCard.propTypes = {
  report: PropTypes.object,
  reportAction: PropTypes.func,
  reportGenerateDoc: PropTypes.func,
  reportGenerateTxt: PropTypes.func,
  reportImport: PropTypes.func,
  reportPollJobStatus: PropTypes.func,
  reportPrint: PropTypes.func,
  reportSetState: PropTypes.func,
  reportStates: PropTypes.object,
  reportTogglePreviewModal: PropTypes.func,
}

ReportCard.defaultProps = {
  report: {
    name: '',
    image_url: '#',
    list_count: 0,
    print_count: 0,
  },
}

export default ReportCard
