import { ActionTypes } from '../actions/library'

function library(state = {}, action) {
  switch (action.type){
    case ActionTypes.SET_LIBRARY_UNSAVED_CHANGES:
      return {
        ...state,
        hasUnsavedChanges: action.value,
      }

    case ActionTypes.SET_TEMPLATE:
      return {
        ...state,
        setTemplate: action.value,
      }

    case ActionTypes.SET_AUTO_INCLUDE_OPTION:
      return {
        ...state,
        autoIncludeOption: action.value,
      }

    case ActionTypes.TOGGLE_ROUND:
      return {
        ...state,
        rounds: {
          ...state.rounds,
          [action.roundId]: {
            ...state.rounds[action.roundId],
            checked: action.checked,
          },
        },
      }

    case ActionTypes.CHECK_ALL_ROUNDS:
      return {
        ...state,
        rounds: Object.keys(state.rounds).map( (roundId) => {
          return {...state.rounds[roundId], checked: action.check}
        })
          .reduce((accumulator, round) => ({ ...accumulator, [round.id]: round}), {}),
      }

    default:
      return state
  }
}

export default library
