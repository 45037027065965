import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import teeTimeIcon from 'widgets/icons/tee_time.png'
import courseIcon from 'widgets/icons/course.png'
import holeIcon from 'widgets/icons/hole.png'
import playerIcon from 'widgets/icons/player.png'
import { useSelector } from 'react-redux'
import {moveHere, unselect, selectAll, reinitialise} from '../actions'
import OttScreen from '../ott_screen'
import OttSlot from './ott_slot'
import OttErrorPopup from './ott_error_popup'
import { callAPI } from 'Shared/helpers'

const OttDesktopLayout = () => {
  // Component State
  const dispatch = useDispatch()

  // Redux State
  const teeSheetData = useSelector(state => state.data )
  const checkedSlots = useSelector(state => state.checkedSlots )
  const dt = Object.values(teeSheetData)
  const state = useSelector(state => state )
  const activeScreen = useSelector(state => state.activeScreen)
  const playersCount = useSelector(state => state.playersCount)
  const currentMemberId = useSelector(state => state.currentMemberId)
  const roundId = useSelector(state => state.roundId)
  const leagueId = useSelector(state => state.leagueId)
  const useBayTerminology = useSelector(state => state.useBayTerminology)
  const [ isErrorPopupOpen, setErrorPopupOpen ] = useState(false)
  const [ errorPopupMessage, setErrorPopupMessage ] = useState('')
  const [ isOverlayVisible, setIsOverlayVisible ] = useState(true)

  // UseEffects
  useEffect(() => {

  }, [ state ])


  // Business Logic

  const handleScroll = () => {
    setIsOverlayVisible(false)
  }

  const closeErrorPopup = () => {
    setErrorPopupOpen(false)
  }

  const handleExpiration = () => {
    setErrorPopupOpen(true)
    reinitialiseState()
    setErrorPopupMessage(window.I18n.t('players_choose_tee_times.ott_2_0.error.expired_reservation'))
  }

  const reinitialiseState = () => {
    const url = `/leagues/${leagueId}/widgets/players_choose_tee_times/reinitialise_and_set_current_member_for_admin`
    callAPI(url, 'POST', { round_id: roundId, selected_member_id: currentMemberId })
      .then((resp) => {
        dispatch(reinitialise(resp.message))
      })
      .catch(() => {
      })
  }



  // Business Logic
  const moveHereButton = (e, foursomeId) => {
    e.preventDefault()

    dispatch(moveHere(foursomeId, checkedSlots))
  }

  const unselectButton = (e, foursomeId) => {
    e.preventDefault()

    dispatch(unselect(foursomeId))
  }

  const selectAllButton = (e, foursomeId) => {
    e.preventDefault()

    dispatch(selectAll(foursomeId))
  }

  // Render
  return (
    <div className={'table-parent-style'} onScroll={handleScroll} onClick={handleScroll}>
      <table className={'table-style'}>
        <thead>
          <tr>
            <th className={'header-style'}> <div className={'header-style2'}>  { playersCount < 5 && (<img src={teeTimeIcon}/> )} &nbsp; { window.I18n.t('players_choose_tee_times.ott_2_0.table.tee_time')} </div> </th>
            <th className={'header-style'}> <div className={'header-style2'}> { playersCount < 5 && (<img src={courseIcon}/> )} &nbsp; {window.I18n.t('players_choose_tee_times.ott_2_0.table.course')}</div></th>
            <th className={'header-style'}> <div className={'header-style2'}> { playersCount < 5 && (<img src={holeIcon}/> ) } &nbsp; {useBayTerminology ? window.I18n.t('players_choose_tee_times.ott_2_0.table.bay') : window.I18n.t('players_choose_tee_times.ott_2_0.table.hole')}</div> </th>
            { Array.from(Array(playersCount).keys()).map(playerNumber => (
              <th key={playerNumber} className={'player-header-style'}> <img src={playerIcon}/> {window.I18n.t('players_choose_tee_times.ott_2_0.table.player')} {playerNumber + 1} </th>
            ))}
            {(activeScreen === OttScreen.CHANGE_TEE_TIME || activeScreen === OttScreen.REMOVE_PLAYERS) && (<th className={'player-header-style'}> </th>)}
          </tr>
        </thead>
        <tbody className={'table-style'}>
        {dt.map((row, index) => (
          <React.Fragment key={index}>
            {row.subtitle && <td colSpan="10" className={'subtitle-style'}>{row.subtitle}</td>}
            <tr>
              <td className={'bold-cell-style'}>
                <span>{row.time || '–'}</span>
              </td>
              <td className={'bold-cell-style'}>
                <span>{row.course || '–'}</span>
              </td>
              <td className={'cell-style'}>
                <span>{row.hole || '–'}</span>
              </td>
              { Array.from(Array(playersCount).keys()).map(position => (
                <td key={`${row.foursome_id}_${position}`} className={'colored-box-style'}>
                  <OttSlot
                    position={position}
                    foursome_id={row.foursome_id}
                    player={row.foursome_players[position].player}
                    owned_by_current_member={row.foursome_players[position].owned_by_current_member}
                    is_reserved={row.foursome_players[position].is_reserved}
                    is_selected={row.foursome_players[position].is_selected}
                    is_valid_for_moving={row.foursome_players[position].is_valid_for_moving}
                    is_checked={row.foursome_players[position].is_checked}
                    reservation_seconds_left={row.foursome_players[position].reservation_seconds_left}
                    handleExpiration={handleExpiration}
                  />
                </td>
              ))}
              {activeScreen === OttScreen.CHANGE_TEE_TIME && row.foursome_players.some(player => player.is_checked) && // edit mode and current foursome has checked players
                (<td className={'edit-column'}>
                  {row.has_players_owned_by_members === true 
                  ? // if there are players owned by current member => Unselect all button
                    <div className={'edit-button-class'} onClick={(e) => unselectButton(e, row.foursome_id)}> {window.I18n.t('players_choose_tee_times.ott_2_0.table.unselect')} </div>
                  : 
                    ( row.foursome_players.some(player => player.is_valid_for_moving === true) === true 
                      ? // if there are spots valid for moving: Move here button
                        <button className={'edit-button-class'} onClick={(e) => moveHereButton(e, row.foursome_id)}> {window.I18n.t('players_choose_tee_times.ott_2_0.table.move_here')}</button>
                      : // otherwise, disabled move here
                        <div className={'disabled-color'} > {window.I18n.t('players_choose_tee_times.ott_2_0.table.move_here')} </div> )}</td>)}

              {activeScreen === OttScreen.CHANGE_TEE_TIME && !(row.foursome_players.some(player => player.is_checked)) //  edit mode and current foursome has no checked players
                && (<td className={'edit-column'}> {
                  row.has_players_owned_by_members === true 
                  ? // if there are players owner by current member => Select all button
                    <div className={'no-border'} onClick={(e) => selectAllButton(e, row.foursome_id)}> {window.I18n.t('players_choose_tee_times.ott_2_0.table.select_all')} </div>
                  :
                    (checkedSlots.length !== 0 && row.foursome_players.some(player => player.is_valid_for_moving === true) === true ) ? // if there are some players => move here button. else: nothing displayed
                      <button className={'edit-button-class'} onClick={(e) => moveHereButton(e, row.foursome_id)}> {window.I18n.t('players_choose_tee_times.ott_2_0.table.move_here')}</button> :
                      <div> </div>
                }
                </td>)}

              {activeScreen === OttScreen.REMOVE_PLAYERS && row.foursome_players.some(player => player.owned_by_current_member && player.player !== null) === true
                && (<td className={'edit-column'}> {
                  row.foursome_players.some(player => player.is_checked && player.owned_by_current_member) === true 
                    ?
                      <button className={'edit-button-class'} onClick={(e) => unselectButton(e, row.foursome_id)}> {window.I18n.t('players_choose_tee_times.ott_2_0.table.unselect')} </button>
                    :
                      <button className={'edit-button-class'} onClick={(e) => selectAllButton(e, row.foursome_id)}> {window.I18n.t('players_choose_tee_times.ott_2_0.table.select_all')} </button>
                }

                </td>)}

              {activeScreen === OttScreen.REMOVE_PLAYERS && !row.foursome_players.some(player => player.owned_by_current_member && player.player !== null) === true
                && (<td className={'edit-column'}> {
                  <div></div>
                }
                </td>)}


            </tr>

            {isErrorPopupOpen && <OttErrorPopup message={errorPopupMessage} handleClose={closeErrorPopup}/>}
          </React.Fragment>

        ))}
        </tbody>
      </table>
      { isOverlayVisible && ( <div className="scrolling-overlay">
        <div>
          <div className="scroll-downs">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
        </div>
        <div className="scroll-text">SCROLL</div>
      </div> )}
    </div>
  )
}

// PropTypes
OttDesktopLayout.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      column1: PropTypes.string.isRequired,
      column2: PropTypes.string.isRequired,
    })
  ).isRequired,
}
export default OttDesktopLayout

