import { connect } from 'react-redux'
import SideBySideTableComponent from '../components/side_by_side_table_component'

const mapStateToProps = (state) => {

  return {
    frontLines: state.misc.frontLines,
    backLines: state.misc.backLines,
    showMessage: state.misc.showMessage,
    message: state.misc.message,
    score: state.misc.score,
    showCourse: state.misc.showCourse,
  }
}

export default connect(
  mapStateToProps,
  null,
)(SideBySideTableComponent)
