import { connect } from 'react-redux'
import UpdateBlockForm from '../presentational/update_block_form'
import { updateBlock, displayMode, DISPLAY_MODES } from '../../actions'

const mapStateToProps = state => ({
  title: state.blocks.filter( block => block.id === state.filters.active_block )[0].title,
  titles: state.blocks.filter( block => block.id === state.filters.active_block )[0].titles,
  id: state.filters.active_block,
  usesTranslations: state.filters.uses_translations,
  availableLanguages: state.filters.available_languages,
})

const mapDispatchToProps = dispatch => ({
  onUpdate: (blockID, title, titles) => {
    dispatch(updateBlock(blockID, title, titles))
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
  onCancel: () => {
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateBlockForm)
