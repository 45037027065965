import { connect } from 'react-redux'
import MarketingCampaignsSetupComponent from '../components/marketing_campaigns_setup_component'

import {
  setName,
} from '../actions/setup'

const mapStateToProps = (state) => {
  return {
    general: state.general,
    channels: state.channels,
    triggers: state.triggers,
    recipientList: state.recipientList,
    schedule: state.schedule,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setName: (name) => { dispatch(setName(name)) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MarketingCampaignsSetupComponent)
