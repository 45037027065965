import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GlgRoundedButtonIcon, GlgPopup, GlgSelect } from 'SharedComponents'
import Preloader from './preloader'

const internationalize = (key) => !window.I18n ? '' : window.I18n.t(key)

class SingleAndMultiRoundTournamentsContainer extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { links, tournaments, isOpenSelectRoundPopup, rounds, selectedRound } = this.props.content

    return (
      <div className='single_and_multi_tournaments_container'>
        {
          links.add_multi_round_tournaments.permission
            ? <GlgRoundedButtonIcon
              text={ links.add_multi_round_tournaments.name }
              icon={ links.add_multi_round_tournaments.icon }
              onClick={ () => { this.props.loadTournament(links.add_multi_round_tournaments.path, false) }}
              anchorClass={ `new_spec ${links.add_multi_round_tournaments.enabled ? '' : 'disabled'}` }
              />
            : null

        }

        {
          links.add_single_round_tournaments.permission
            ?
              <>
                <GlgRoundedButtonIcon
                  text={ links.add_single_round_tournaments.name }
                  icon={ links.add_single_round_tournaments.icon }
                  onClick={() => {
                    this.props.setData([ 'isOpenSelectRoundPopup' ], true)
                    this.props.setData([ 'selectedRound' ], rounds[0].id )
                  }}
                  anchorClass={ `new_spec ${links.add_single_round_tournaments.enabled ? '' : 'disabled'}` }
                />
                <GlgPopup
                  title={internationalize('pairings.additional_tournaments.select_round')}
                  show={isOpenSelectRoundPopup || false}
                  saveButtonText={internationalize('dashboard_plus.widgets.select')}
                  onClose={() => this.props.setData([ 'isOpenSelectRoundPopup' ], false)}
                  onSave={() => {
                    this.props.setData([ 'isOpenSelectRoundPopup' ], false)
                    this.props.loadTournament(decodeURI(links.add_single_round_tournaments.path).replace(/{round_id}/g, selectedRound))
                  }}
                >
                  <GlgSelect
                    items={ rounds.map(r => ({ label: r.name, value: r.id })) }
                    value={ selectedRound }
                    onChange={ (v) => this.props.setData([ 'selectedRound' ], v) }
                    usePortal
                    menuShouldBlockScroll
                  />
                </GlgPopup>
              </>
            : null

        }
        <div className='table-responsive'>
          { /* Single and Multi Round tournaments */ }
          <table className='table table-striped'>
            <thead>
              <tr className='header'>
                {
                  tournaments.headers.map((headerText, index) =>
                    <th key={ `${headerText}-${index}` }>{ headerText }</th>)
                }
              </tr>
            </thead>

            <tbody>
              {
                ((tournaments.single_tournaments || []).length === 0 &&
                  (tournaments.multi_tournaments || []).length === 0) ?
                    <tr>
                      <td className='center notice' colSpan={tournaments.headers.length}>
                        { tournaments.no_tournaments_notice }
                      </td>
                    </tr>
                    :
                    <>
                      {
                        tournaments.multi_tournaments.concat(tournaments.single_tournaments).map(spec =>
                          <tr key={spec.id}>
                            <td className='round'>
                              <label> { spec.round_name } </label>
                              <img
                                className={`${spec.icon_src === undefined ? 'hidden' : 'align-icon'}`}
                                src={ spec.icon_src }
                                title={`${spec.default_tournament_tooltip}`}
                              />
                            </td>
                            <td className='name'>
                              {
                                spec.edit_link ?
                                <a href='javascript:void(0)'
                                   onClick={() => { this.props.loadTournament(spec.edit_link) }} >
                                  { spec.name }
                                </a>
                                :
                                <span>{ spec.name }</span>
                              }
                            </td>
                            { 'division' in spec && <td className='division'> { spec.division } </td> }
                            <td className='format'> { spec.format } </td>
                            <td className='competition'> { spec.competition } </td>
                            <td className='balls'> { spec.balls } </td>
                            <td className='handicap'> { spec.handicap } </td>
                            <td className='purse'>{ spec.purse ? <i className='fa fa-check'></i> : null }</td>
                            <td className='points'> { spec.points ? <i className='fa fa-check'></i> : null } </td>
                            <td className='actions'>
                              {
                                spec.edit_link &&
                                  <a href='javascript:void(0)'
                                    onClick={() => { this.props.loadTournament(spec.edit_link) }} >
                                    { internationalize('buttons.edit') }
                                  </a>
                              }
                              { spec.edit_link && spec.delete_link && <label>•</label> }
                              {
                                spec.delete_link &&
                                  <a
                                    href='javascript:void(0)'
                                    className={ (this.props.content.deleting || {})[spec.id] && 'disabled' }
                                    onClick={() => {
                                      this.props.deleteTournament(spec.delete_link)
                                      this.props.setData([ 'deleting', spec.id ], true)
                                    }}>
                                    {internationalize('buttons.delete')}
                                  </a>
                              }
                            </td>
                          </tr>
                        )
                      }
                    </>
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

class LinkedTournamentsContainer extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { links, tournaments } = this.props.content
    const linkedTournaments = this.props.content.linked_tournaments

    return (
      <div className='linked_tournaments_container'>
        {
          links.add_linked_tournaments.permission
            ? <GlgRoundedButtonIcon
              text={ links.add_linked_tournaments.name }
              icon={ links.add_linked_tournaments.icon }
              onClick={ () => { this.props.loadTournament(links.add_linked_tournaments.path, false) }}
              anchorClass={ `new_spec ${
                !links.add_linked_tournaments.enabled || tournaments.multi_tournaments.concat(tournaments.single_tournaments).length === 0 ? 'disabled' : ''
              }` }
              />
            : null

        }
        <div className='table-responsive'>
          { /* Linked tournaments */ }
          <table className='table table-striped'>
            <thead>
              <tr className='header'>
                {
                  linkedTournaments.headers.map((headerText, index) =>
                    <th key={ `${headerText}-${index}` }>{ headerText }</th>)
                }
              </tr>
            </thead>

            <tbody>
              {
                (linkedTournaments.tournaments || []).length === 0 ?
                  <tr>
                    <td className='center notice' colSpan={3}>
                      { linkedTournaments.no_tournaments_notice }
                    </td>
                  </tr>
                  :
                  linkedTournaments.tournaments.map(event =>
                  <tr key={event.id}>
                    <td className='name'>
                      <a href='javascript:void(0)'
                        onClick={() => { this.props.loadTournament(event.edit_link) }} >
                        { event.name }
                      </a>
                    </td>

                    <td className='format'>{ event.format }</td>

                    <td className='actions'>
                      <a href='javascript:void(0)'
                        onClick={() => { this.props.loadTournament(event.edit_link) }} >
                        { internationalize('buttons.edit') }
                      </a>
                      <label>•</label>
                      <a
                        href='javascript:void(0)'
                        className={ (this.props.content.deleting || {})[event.id] && 'disabled' }
                        onClick={() => {
                          this.props.deleteTournament(event.delete_link)
                          this.props.setData([ 'deleting', event.id ], true)
                        }} >
                        { internationalize('buttons.delete') }
                      </a>
                    </td>
                  </tr>
                  )
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

class EventSetupTournaments extends Component {
  constructor(props){
    super(props)
  }

  componentWillUnmount() {
    this.props.content.activeTournamentURL && this.props.markTournamentLoaded(false)
  }


  render() {
    const { activeTournamentURL, loadedTournament } = this.props.content

    return (
      <div className='widget-content-default widget-event-setup-tournaments'>
        <div className='title'>
          { this.props.content.title }
        </div>

        <div className='content flex-list-layout'>
          {
            !activeTournamentURL ?
              <>
                <SingleAndMultiRoundTournamentsContainer {...this.props} />
                <LinkedTournamentsContainer {...this.props} />
              </>
              :
              <>
                <iframe
                  id='tournament_frame'
                  width='100%'
                  height='0'
                  frameBorder='0'
                  src={activeTournamentURL}
                  onLoad={() => {
                    const content = $('#tournament_frame').contents()

                    document.getElementById('tournament_frame').height = `${content.height()}px`
                    content.find('#support_bubble').hide()
                    content.find('.cancel_button').click(this.props.unloadTournament)

                    const submitButton = content.find(':submit')
                    const formSubmit = (form) => {
                      submitButton.attr('disabled', true)
                      submitButton.val(internationalize('buttons.saving'))
                      this.props.saveTournament(form.attr('action'), form.attr('method'), form.serialize())
                    }
                    submitButton.click(e => {
                      e.preventDefault()
                      if (content.find('#club_score_input_terminals_default_tournament_checkbox').is(':checked')) {
                        window.glg.specs2.save_club_score_input_terminals_default_tournament(content, formSubmit)
                      } else {
                        formSubmit(content.find('form'))
                      }
                    })

                    this.props.markTournamentLoaded()
                  }}
                />
                { !loadedTournament && <Preloader /> }
              </>
          }
        </div>
      </div>
    )
  }
}

EventSetupTournaments.propTypes = {
  content: PropTypes.object,
  setData: PropTypes.func,
  loadTournament: PropTypes.func,
  unloadTournament: PropTypes.func,
  markTournamentLoaded: PropTypes.func,
  deleteTournament: PropTypes.func,
  saveTournament: PropTypes.func,
}
SingleAndMultiRoundTournamentsContainer.propTypes = EventSetupTournaments.propTypes
LinkedTournamentsContainer.propTypes = EventSetupTournaments.propTypes

EventSetupTournaments.defaultProps = {
  content: {
    title: 'TOURNAMENTS SETUP',
    data: {},
  },
}

export default EventSetupTournaments
