import { connect } from 'react-redux'
import StatisticPopupComponent from '../components/statistic_popup_component'
import {closePopupAction, loadStatisticData} from '../actions'


const mapStateToProps = (state) => {
  return {
    visible: state.popup.visible,
    rounds: state.misc.rounds,
    title: state.popup.statistic.title,
    statistic: state.popup.statistic,
    selectedRound: state.popup.selectedRound,
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadStatisticData: (key, roundId) => { dispatch(loadStatisticData(key, roundId, true)) },
  closePopup: () => dispatch(closePopupAction()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticPopupComponent)
