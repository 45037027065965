import React from 'react'
import PropTypes from 'prop-types'
import rootReducer from '../reducers'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import Layout from '../components/layout'

const KeySettingsRoot = ({ initialState }) => {
  const state = {
    table: {
      ...initialState,
      isDataFetched: false,
      modifiedData: {},
      modifiedDataCount: 0,
    },
  }

  //TODO Redux Dev Tools
  const store = configureStore(rootReducer, state, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
  // window.glg.member.dispatch = store.dispatch

  return (
    <GlobalProvider store = { store }>
      <Layout/>
    </GlobalProvider>
  )
}

KeySettingsRoot.propTypes = {
  initialState: PropTypes.any,
}

export default KeySettingsRoot

