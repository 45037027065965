import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { mapToSelectArray } from 'Shared/helpers'

import PopupTypes from '../../popup_types'
import {
  GlgPopup,
  GlgSelect,
  GlgPopupForm,
  GlgFormInput,
} from 'SharedComponents'

class ChangeTourPopup extends Component {
  constructor(props) {
    super(props)
    this.onClose = props.onClose.bind(this, PopupTypes.CHANGE_TOUR)
    this.onSave = this.onSave.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    return this.props.show !== nextProps.show
  }

  onSave(data) {
    const propsData = this.props.data
    this.props.onSave(propsData.leagueId,
                      data.to_tour_id)
  }

  getLeagueName() {
    if (this.props.data) {
      return this.props.data.leagueName || ''
    } else {
      return ''
    }
  }

  getCurrentSeason() {
    if (this.props.data) {
      return this.props.data.tourId || ''
    } else {
      return ''
    }
  }

  render() {
    const toursSelectOptions = mapToSelectArray(this.props.toursOptions)

    return <GlgPopup title={ `Select new tour for ${ this.getLeagueName() }` } show={ this.props.show } onClose={ this.onClose } onSave={ this.onSave }><GlgPopupForm><GlgFormInput label="Tour"><GlgSelect name="to_tour_id" items={ toursSelectOptions } defaultValue={ this.getCurrentSeason() }></GlgSelect></GlgFormInput></GlgPopupForm></GlgPopup>
  }
}

ChangeTourPopup.propTypes = {
  data: PropTypes.object,
  show: PropTypes.bool.isRequired,
  toursOptions: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default ChangeTourPopup
