import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import textContent from 'react-addons-text-content'

import { getUniqueElementId } from '../helpers'

class OverflowTooltip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showTooltip: false,
    }
    this.tooltipId = getUniqueElementId()
  }

  updateState() {
    const showTooltip = this.element.offsetWidth < this.element.scrollWidth
    if (this.state.showTooltip !== showTooltip) {
      this.setState({
        showTooltip,
      })
    }
  }

  componentDidMount() {
    this.updateState()
  }

  componentDidUpdate() {
    this.updateState()
  }

  render() {
    const tooltip = <Tooltip id={ this.tooltipId }>{ textContent(this.props.children) }</Tooltip>

    const childElement = <div {...this.props.divProps } ref={ element => this.element = element }>{ this.props.children }</div>

    if (this.state.showTooltip) {
      return <OverlayTrigger placement={ this.props.placement } overlay={ tooltip }>{ childElement }</OverlayTrigger>
    } else {
      return childElement
    }
  }
}

OverflowTooltip.propTypes = {
  // The placement of the tooltip: top, bottom, left, right
  placement: PropTypes.string,
  // Props for the wrapper div
  divProps: PropTypes.object,

  children: PropTypes.node,
}

OverflowTooltip.defaultProps = {
  placement: 'top',
}

export default OverflowTooltip
