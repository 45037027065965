import React from 'react'
import PropTypes from 'prop-types'
import {Modal} from 'react-bootstrap'

const LeaveDraftPopup = ({open, draftsIndexUrl, saveInProgress}) => {

  const onLeaveDraftClicked = () => {
    $('.leave_draft_popup .btsp_orange_button').attr('disabled', true)
      .text(window.I18n.t('buttons.redirecting'))
    window.location.href = draftsIndexUrl
  }

  return (
    <Modal className="leave_draft_popup" show={open} popup="true">
      <Modal.Body>
        <div className="text-center">
          <h4>{window.I18n.t('draft.components.manager_side.leave_draft_popup.draft_will_be_saved')}</h4>
          <button type="button" className="btsp_orange_button" onClick={onLeaveDraftClicked} disabled={saveInProgress}>
            {window.I18n.t('buttons.ok')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

LeaveDraftPopup.propTypes = {
  open: PropTypes.bool,
  draftsIndexUrl: PropTypes.string,
  saveInProgress: PropTypes.bool,
}

export default LeaveDraftPopup
