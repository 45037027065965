import React from 'react'
import PropTypes from 'prop-types'
import TeamGolferPick from './team_golfer_pick_component'

const UpcomingPicksArea = ({upcomingPicks}) => {

  return (
    upcomingPicks.length > 0 &&
    <div className="upcoming_picks_area">
      <div className="pick_text picking_area_title">
        {window.I18n.t('draft.components.manager_side.upcoming_picks_area.upcoming_picks')}
      </div>
      {upcomingPicks.map((pick, idx) =>
        <TeamGolferPick key={idx} type={'upcoming'} pick={pick}/>,
      )}
    </div>
  )
}

UpcomingPicksArea.propTypes = {
  upcomingPicks: PropTypes.array,
}

export default UpcomingPicksArea
