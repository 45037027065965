import React from 'react'
import PropTypes from 'prop-types'
import {GlgTableRow, GlgTableCell} from 'SharedComponents'

import { getTotalColumnClass, getAbsColumnValue } from 'Shared/course_boards'

const sideBySideTableRowComponent = ({...props}) => {
  const totalColor = getTotalColumnClass(props.total)
  const absTotal = getAbsColumnValue(props.total)

  return (
    <GlgTableRow { ...props } className={props.className + ' ' + totalColor}>
      <GlgTableCell>{props.empty ? '' : props.hole}</GlgTableCell>
      <GlgTableCell>{props.empty ? '' : props.name}</GlgTableCell>
      {props.showCourse && <GlgTableCell>{props.empty ? '' : props.course}</GlgTableCell>}
      <GlgTableCell>{props.empty ? '' : absTotal}</GlgTableCell>
    </GlgTableRow>
  )
}

sideBySideTableRowComponent.propTypes = {
  today: PropTypes.string,
  name: PropTypes.string,
  hole: PropTypes.string,
  total: PropTypes.string,
  className: PropTypes.string,
  empty: PropTypes.bool,
  showCourse: PropTypes.bool,
  course: PropTypes.string,
}

export default sideBySideTableRowComponent
