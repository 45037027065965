import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const RelativeLabel = styled.label`
  position: relative;
`

function Checkbox({label, customLabel, selected, key, setOption, className, isDisabled = false}) {
  return (
    <RelativeLabel className={`icheck-label ${className}`} htmlFor={key}>
      <div className={`icheckbox_square-orange icheck-item ${selected ? 'checked' : ''}`}>
        <input id={key} className='i-checks icheck-input' type="checkbox" value={selected} onChange={event => setOption(event.target.checked)} checked={selected} disabled={isDisabled}/>
      </div>
      {
        customLabel || <span>{label}</span>
      }
    </RelativeLabel>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  customLabel: PropTypes.node,
  selected: PropTypes.bool,
  key: PropTypes.string,
  setOption: PropTypes.func,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
}

export default Checkbox
