import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'

import { GlgTable, GlgTableCol } from 'SharedComponents'
import SideBySidePreviewRowComponent from './side_by_side_preview_row_component'
import { getCellParClass, getAbsColumnValue } from 'Shared/course_boards'

class SideBySidePreviewComponent extends Component {
  constructor(props) {
    super(props)
    this.renderFooter = this.renderFooter.bind(this)
  }

  renderFooter() {
    const { message, score } = this.props
    const color = getCellParClass(score)
    const absValue = getAbsColumnValue(score)
    return (
      <Col xs={12} sm={12} md={12} lg={12} className='footer-row no-padding'>
        <Col xs={11} sm={11} md={11} lg={11} className='message'>{message}</Col>
        <Col xs={1} sm={1} md={1} lg={1} className={'score ' + color}>{absValue}</Col>
      </Col>
    )
  }

  render() {
    const { frontLines, backLines, showMessage, showCourse, withTeams } = this.props
    const footer = this.renderFooter()
    const rowExtraProps = {
      showCourse: showCourse,
      withTeams: withTeams,
    }
    
    return (
      <Fragment>
        <Col xs={12} sm={12} md={12} lg={12} className='no-padding add-margin-top-20'>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className='page_title'>
              <div className='text'>Preview</div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className='btsp_form_content preview'>
              <div className='side_by_side_preview_container'>
                <Col xs={6} sm={6} md={6} lg={6} className='text-center side-header'>Front 9 Start</Col>
                <Col xs={6} sm={6} md={6} lg={6} className='text-center side-header'>Back 9 Start</Col>
                <Col xs={6} sm={6} md={6} lg={6} className='no-padding'>
                  <GlgTable items={frontLines}
                            rowComponent={ SideBySidePreviewRowComponent }
                            rowExtraProps={ rowExtraProps }
                            maxHeight={ 500 }
                            showFooter={false}>
                    <GlgTableCol width='5%'>Thru</GlgTableCol>
                    <GlgTableCol width='65%'>Name</GlgTableCol>
                    { showCourse && <GlgTableCol width='15%'>Course</GlgTableCol> }
                    <GlgTableCol width='5%'>Total</GlgTableCol>
                  </GlgTable>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} className='no-padding'>
                  <GlgTable items={backLines}
                            rowComponent={ SideBySidePreviewRowComponent }
                            rowExtraProps={ rowExtraProps }
                            maxHeight={ 500 }
                            showFooter={false}>
                    <GlgTableCol width='5%'>Thru</GlgTableCol>
                    <GlgTableCol width='65%'>Name</GlgTableCol>
                    { showCourse && <GlgTableCol width='15%'>Course</GlgTableCol> }
                    <GlgTableCol width='5%'>Total</GlgTableCol>
                  </GlgTable>
                </Col>
                {showMessage && footer}
              </div>
            </div>
          </Col>
        </Col>
      </Fragment>
    )

  }
}

SideBySidePreviewComponent.propTypes = {
  frontLines: PropTypes.array,
  backLines: PropTypes.array,
  showMessage: PropTypes.bool,
  message: PropTypes.string,
  score: PropTypes.string,
  showCourse: PropTypes.bool,
  withTeams: PropTypes.bool,
}

export default SideBySidePreviewComponent
