import { createSelector } from 'reselect'
import { toIntValue } from 'Shared/course_boards'

const getSortColumn = state => state.sortParams.column
const getSortOrder = state => state.sortParams.sortOrder
const getPlayers = state => state.misc.players

const dispositions = [ 'DNF', 'DQ', 'NC', 'NS', 'WD' ]

const sortNotStartedPlayers = (players, time, order) => {
  return players.slice(0).sort( (p1, p2) => {
    const p1Hole = p1.hole.replace(`${time}`, '')
                          .trim()               
                          .split(':')

    const p2Hole = p2.hole.replace(`${time}`, '')
                          .trim()
                          .split(':')

    if (order === 'asc') {
      if (p1Hole[0] === p2Hole[0]) {
        return p1Hole[1] < p2Hole[1] ? -1 : 1
      } else {
        return p1Hole[0] < p2Hole[0] ? -1 : 1
      }
    } else {
      if (p1Hole[0] === p2Hole[0]) {
        return p2Hole[1] < p1Hole[1] ? -1 : 1
      } else {
        return p2Hole[0] < p1Hole[0] ? -1 : 1
      }
    }
  })
}

const sortByHole = (order, players) => {
  let startedPlayers = []
  let notStartedPlayers = []
  let dispositionPlayers = []
  let notStartedAMPlayers = []
  let notStartedPMPlayers = []

  players.map( player => {
    if (player.hole.includes('AM') ) {
      notStartedAMPlayers.push(player)
    } else if (player.hole.includes('PM')) {
      notStartedPMPlayers.push(player)
    } else if (dispositions.includes(player.hole)) { 
      dispositionPlayers.push(player) 
    } else {
      startedPlayers.push(player)
    }
  })

  startedPlayers = startedPlayers.slice(0).sort( (p1, p2) => {
    if (order === 'asc') {
      return toIntValue(p1.hole) - toIntValue(p2.hole)
    } else {
      return toIntValue(p2.hole) - toIntValue(p1.hole)
    }
  })

  dispositionPlayers = dispositionPlayers.slice(0).sort( (p1, p2) => {
    if (order === 'asc') {
      return p1.hole < p2.hole ? -1 : 1
    } else {
      return p2.hole < p1.hole ? -1 : 1
    }
  })

  notStartedAMPlayers = sortNotStartedPlayers(notStartedAMPlayers, 'AM', order)
  notStartedPMPlayers = sortNotStartedPlayers(notStartedPMPlayers, 'PM', order)

  if (order === 'asc') {
    notStartedPlayers = notStartedAMPlayers.concat(notStartedPMPlayers)
  } else {
    notStartedPlayers = notStartedPMPlayers.concat(notStartedAMPlayers)
  }

  startedPlayers = startedPlayers.concat(notStartedPlayers)
  return startedPlayers.concat(dispositionPlayers)
}

const sortByName = (column, order, players) => {
  return players.slice(0).sort( (p1, p2) => {
    if (order === 'asc') {
      return p1[`${column}`] < p2[`${column}`] ? -1 : 1
    } else {
      return p2[`${column}`] < p1[`${column}`] ? -1 : 1
    }
  })
}

const sortByPar = (column, order, players) => {
  return players.slice(0).sort( (p1, p2) => {
    if (order === 'asc') {
      return toIntValue(p1[`${column}`]) - toIntValue(p2[`${column}`])
    } else {
      return toIntValue(p2[`${column}`]) - toIntValue(p1[`${column}`])
    }
  })
}

const getSortedPlayers = createSelector(
  [ getSortColumn, getSortOrder, getPlayers ],
  (column, order, players) => {
    if ( column === null || order === null ) {
      return players
    } else if ( column === 'firstName' || column === 'lastName' || column === 'name' || column === 'course' ) {
      return sortByName(column, order, players)
    } else if ( column === 'today' || column === 'total' ) {
      return sortByPar(column, order, players)
    } else {
      return sortByHole(order, players)
    }
  },
)

export default getSortedPlayers
