import React, { Component } from 'react'
// import PropTypes from 'prop-types'

class PrintOptionsModal extends Component {
  constructor(props){
    super(props)
  }

  componentDidUpdate() {
    // inits
    window.glg.new_design_shared.modaliChecks('#print_report, #print_report_options')
    // gpu print options forms
    window.glg.gpu_reports.init_filtering()
    window.glg.gpu_reports.restore_settings()
    window.glg.gpu_reports.context_field_gsub()
    window.glg.layout.initDropdownMenus()
  }

  render() {
    return (
      // eslint-disable-next-line react/no-string-refs
      <div className="modal" id="print_report" ref="modal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div className="modal-dialog">
              <div className="modal-content">
                  <div className="modal-body">
                  </div>
              </div>
          </div>
      </div>
    )
  }
}

// PrintOptionsModal.propTypes = {}

export default PrintOptionsModal
