import { ActionTypes } from '../actions'

const {
  REFRESH_DATA,
  ADD_MEMBERS_TO_MEMBER_CARD,
  REMOVE_MEMBERS_FROM_MEMBER_CARD,
  FETCH_MORE_DATA_RECEIVED,
  SHOW_HIDE_MEMBERS,
  SHOW_MEMBERS_REQUEST,
} = ActionTypes

const initialState = {
  members: {},
}
const members = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_DATA:
      return {
        ...state,
        ...action.data.members,
      }
    case FETCH_MORE_DATA_RECEIVED:
      return {
        ...state,
        ...action.data.members,
      }
    case ADD_MEMBERS_TO_MEMBER_CARD:
      return {
        ...state,
        [action.id]: state[action.id].concat(action.members),
      }
    case REMOVE_MEMBERS_FROM_MEMBER_CARD:
      return {
        ...state,
        [action.id]: state[action.id].filter(member => !action.members.includes(member)),
      }
    case SHOW_HIDE_MEMBERS:
      return {
        ...state,
        [action.id]: action.members,
        loadingMembersMc: null,
      }
    case SHOW_MEMBERS_REQUEST:
      return {
        ...state,
        loadingMembersMc: action.mcID,
      }
    default:
      return state
  }
}

export default members
