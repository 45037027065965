import { connect } from 'react-redux'
import EmailDeliveryReportsComponent from '../../components/delivery_reports/email_delivery_reports_component'

import {
  setCurrentEmailReports,
  setEmailFilterValue,
} from '../../actions/delivery_reports'

const mapStateToProps = (state) => {
  return state.deliveryReports
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentEmailReports: (emailDeliveryReports, page) => { dispatch(setCurrentEmailReports(emailDeliveryReports, page)) },
  setEmailFilterValue: (filterType) => (filterValue) => { dispatch(setEmailFilterValue(filterType, filterValue)) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailDeliveryReportsComponent)
