/*
 * TODO: optimize this:
 * - either use virtualized-select (while still allowing dynamic option heights)
 * - or use customer menu list rendered and use some windowing library
 */
import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ReactSelect, { components } from 'react-select'
const { MenuList, MenuPortal } = components
import { FormControl } from 'react-bootstrap'
import FaIcon from 'react-fa'

// Using custom indicator separator component to show the search icon
const searchIconStyle = {
  position: 'relative',
  left: 8,
  opacity: 0.4,
}
const CustomIndicatorSeparator = ({ innerProps }) => {
  return <Fragment><span style={ searchIconStyle }><FaIcon name="search"></FaIcon></span><span { ...innerProps }></span></Fragment>
}
CustomIndicatorSeparator.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
}

// Using custom menu list component to fix react-select v2 performance issues
// This is a hack!
const CustomMenuList = ({ children, ...props }) => {
  return <MenuList { ...props }>{children.length ? children.map((option, index) => {
        delete option.props.innerProps.onMouseMove
        delete option.props.innerProps.onMouseOver

        return <div key={ index }>{ option }</div>
      }) : <div style={ emptyResultsStyles }>{ window.I18n.t('shared.components.glg_select.no_results_found') }</div>}</MenuList>
}
const emptyResultsStyles = {
  padding: 4,
  paddingLeft: 10,
  color: 'grey',
}
CustomMenuList.propTypes = {
  children: PropTypes.node,
}

const CustomMenuPortal = ({ children, ...props }) => <MenuPortal { ...props }><div className="menu-portal-inner">{ children }</div></MenuPortal>
CustomMenuPortal.propTypes = {
  children: PropTypes.node,
}

class GlgSelect extends PureComponent {
  constructor(props) {
    super(props)
    const value = props.defaultValue || props.value
    this.state = {
      selectedOption: this.getSelectedOption(value),
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleResize = this.handleResize.bind(this)
  }

  handleResize() {
    this.forceUpdate()
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  getSelectedOption(value) {
    let initialSelected
    if (value !== null && value !== undefined) {
      if (this.props.isMulti) {
        let found
        initialSelected = []
        value.forEach( val => {
          found = _.find(this.props.items, val)
          found && initialSelected.push(found)
        })
      } else {
        initialSelected = _.find(this.props.items, { value: value })
      }
    }
    if (!initialSelected && !this.props.noPreFill) {
      initialSelected = this.props.items[0]
    }
    return initialSelected
  }

  // When the items list is changed
  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      if (this.props.value) {
        this.setState({
          selectedOption: this.getSelectedOption(this.props.value),
        })
      }
    }
  }

  handleChange(selectedOption) {
    if (selectedOption !== this.state.selectedOption) {
      this.setState({ selectedOption })
      if (this.props.onChange && selectedOption) {
        if (this.props.isMulti) {
          this.props.onChange(selectedOption)
        } else {
          this.props.onChange(selectedOption.value, selectedOption.label)
        }
      }
      if (selectedOption && selectedOption.url) {
        window.location.href = selectedOption.url
      }
    }
  }

  isCustomSelector(selectedOption) {
    return this.props.isCustomized &&
          ( selectedOption !== 'All' && !selectedOption.includes('(Current)') )
  }

  render() {
    const selectProps = _.omit(this.props, [
      'items', 'onChange', 'value', 'disabled', 'searchable',
      'extraInputs', 'width', 'height', 'usePortal', 'closeMenuOnScroll',
      'menuPlacement',
    ])

    selectProps.clearable = selectProps.clearable || false
    selectProps.searchable = selectProps.searchable || false
    selectProps.escapeClearsValue = selectProps.escapeClearsValue || false
    selectProps.deleteRemoves = selectProps.deleteRemoves || false
    selectProps.backspaceRemoves = selectProps.backspaceRemoves || false
    selectProps.isDisabled = this.props.disabled || false
    selectProps.isSearchable = this.props.searchable || false
    selectProps.isMulti = this.props.isMulti || false

    if (this.props.usePortal && !window.glg.new_design_shared.isMobile()) {
      selectProps.menuPortalTarget = document.body
      selectProps.menuPlacement = this.props.menuPlacement || 'auto'
      selectProps.closeMenuOnScroll = e => (
        this.props.closeMenuOnScroll &&
        $(e.target).closest('.menu-portal-inner').length === 0
      )
    }

    selectProps.components = {
      MenuList: CustomMenuList,
      MenuPortal: CustomMenuPortal,
    }

    // Add search icon if the select is searchable
    if (this.props.searchable) {
      selectProps.components.IndicatorSeparator = CustomIndicatorSeparator
    }

    if (this.props.busy) {
      selectProps.isDisabled = true
      selectProps.isLoading = true
    }

    const zIndex = 10003
    const customStyles = {
      option: (base, state) => ({
        ...base,
        cursor: 'pointer',
        color: state.isDisabled ? '#d7d7d7' : 'inherit',
        ['&:hover']: {
          backgroundColor: '#ebf5ff',
        },
        backgroundColor: state.isSelected ? '#f5faff' : 'transparent',
      }),
      control: (base, state) => {
        const controlStyles = {
          ...base,
          ['&:hover']: {
            borderColor: 'hsl(0, 0%, 70%)',
          },
          borderRadius: 0,
          borderColor: 'hsl(0, 0%, 80%)',
          boxShadow: 'none',
          backgroundColor: state.isDisabled ? 'hsl(0,0%,97%)' : 'hsl(0,0%,100%)',
          flexWrap: 'nowrap',
        }
        if (this.props.width) {
          controlStyles.width = this.props.width
        }
        if (this.props.height) {
          controlStyles.height = this.props.height
          controlStyles.minHeight = this.props.height
        }
        return controlStyles
      },
      indicatorSeparator: base => ({
        ...base,
        background: 'none',
      }),
      menu: (base, state) => {
        const menuStyles = {
          ...base,
          borderRadius: 0,
          marginTop: 0,
          marginBottom: 0,
          border: '1px solid hsl(0,0%,80%)',
          boxShadow: 'none',
          zIndex,
        }
        if (state.placement === 'bottom') {
          menuStyles.borderTopWidth = 0
        } else if (state.placement === 'top') {
          menuStyles.borderBottomWidth = 0
        }
        if (this.props.width) {
          menuStyles.width = this.props.width
        }
        return menuStyles
      },
      menuList: base => ({
        ...base,
        maxHeight: 200,
      }),
      menuPortal: base => ({
        ...base,
        zIndex,
      }),
    }
    const customClass = this.isCustomSelector(this.state.selectedOption?.label) ? 'custom_selector' : ''

    let formatOptionLabel
    if (this.props.cloneEventSelectLeague) {
      formatOptionLabel = ({ label, season }) => (season !== null) ? (
          <div>{label}<br /> &nbsp;<em><small>Season: {season}</small></em></div>
      ) : (<div>{label}</div>)
    }

    return <div className="glg-react-select">{this.props.extraInputs && this.state.selectedOption &&
         this.props.extraInputs.map((extraInput, index) => <FormControl name={ extraInput.name } type="hidden" value={ this.state.selectedOption[extraInput.key] } key={ index }></FormControl>)}<ReactSelect options={ this.props.items } className={ customClass } value={ this.state.selectedOption } onChange={ this.handleChange } styles={ customStyles } placeholder={ this.props.placeholder } formatOptionLabel={ formatOptionLabel } isOptionDisabled={ this.props.isOptionDisabled } { ...selectProps }></ReactSelect></div>
  }
}

GlgSelect.propTypes = {
  // The input name
  name: PropTypes.string,
  // Placeholder
  placeholder: PropTypes.string,
  // The array of options
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]).isRequired,
    label: PropTypes.string.isRequired,
    // If this is present, clicking the option will redirect to url
    url: PropTypes.string,
  })).isRequired,
  // Controlled selected option value
  value: PropTypes.string,
  // Callback when the selected value changes
  onChange: PropTypes.func,
  // Initially selected option value
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    })),
  ]),
  // Whether default value should exist
  noPreFill: PropTypes.bool,
  // By default, there is only an input for the value of the option
  // Use this if there are more values per option
  extraInputs: PropTypes.array,
  // If the select is disabled, false by default
  disabled: PropTypes.bool,
  // Whether we can filter the options
  searchable: PropTypes.bool,
  // Overrides disabled property with 'true' and displays spinner
  busy: PropTypes.bool,
  // Whether we can choose multiple options
  isMulti: PropTypes.bool,
  // Width of the select and menu options
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  // Height of the select (not the menu)
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  // When the select is inside a scrollable container, we might want to use
  // a portal so that the menu can move outside of the container.
  usePortal: PropTypes.bool,
  // Only relevant if usePortal = true. If true, you can't scroll while menu is open.
  menuShouldBlockScroll: PropTypes.bool,
  // Only relevant if usePortal = true. If true, the menu closes when scrolling.
  closeMenuOnScroll: PropTypes.bool,
  // Only relevant if usePortal = true. Sets the menu placement for portal menu
  menuPlacement: PropTypes.oneOf([ 'bottom', 'auto', 'top' ]),
  // Only relevant for the select league dropdown in clone popup
  cloneEventSelectLeague: PropTypes.bool,
  // Only relevant for season, category and filter events from "Events, Leagues & Trips"
  isCustomized: PropTypes.bool,
  // If this is present, the option will be disabled (unselectable)
  isOptionDisabled: PropTypes.func,
}

GlgSelect.defaultProps = {
  disabled: false,
  busy: false,
  usePortal: false,
  menuShouldBlockScroll: false,
  closeMenuOnScroll: false,
  menuPlacement: 'auto',
}

export default GlgSelect
