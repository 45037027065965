import React from 'react'
import PropTypes from 'prop-types'
import GolferPick from '../available_picks/golfer_pick_component'

const TeamGolferPick = ({type, saveInProgress, pick, removeFromNowPicking}) => {

  return (
    <div className="team_golfer_pick">
      <div className="section">
        <div className="bold">{window.I18n.t('draft.components.manager_side.team_golfer_pick_component.team')}</div>
        <div>{pick?.teamName}</div>
      </div>
      <div className="section">
        <div className="bold">{window.I18n.t('draft.components.manager_side.team_golfer_pick_component.captain')}</div>
        <div>{pick?.teamCaptain}</div>
      </div>
      <div className="section">
        {type !== 'upcoming' &&
          <>
            <div className="bold">{window.I18n.t('draft.components.manager_side.team_golfer_pick_component.golfer')}</div>
            <div>{type === 'now' && pick?.golfer ?
              <GolferPick type="now" saveInProgress={saveInProgress} golfer={pick.golfer} removeFromNowPicking={removeFromNowPicking}/> : pick?.golfer?.name}</div>
          </>
        }
      </div>
    </div>
  )
}

TeamGolferPick.propTypes = {
  type: PropTypes.string,
  saveInProgress: PropTypes.bool,
  pick: PropTypes.any,
  removeFromNowPicking: PropTypes.func,
}

export default TeamGolferPick
