import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { GlgPopup } from '../../../../shared/components'

class ReportPreview extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { visible, imgSrc, reportName } = this.props.reportPreviewModal

    return (
      <GlgPopup title={ reportName }
                closeButtonText= { !window.I18n ? '' : window.I18n.t('buttons.close').charAt(0)
                  .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.close').slice(1)) }
                show={ visible }
                onClose={ this.props.onClose }
                showSaveButton={ false }
                modalClass='event-dashboard-report-preview-modal' >
        <img src={ imgSrc } width='100%' height='100%' />
      </GlgPopup>
    )
  }
}

ReportPreview.propTypes = {
  onClose: PropTypes.func,
  reportPreviewModal: PropTypes.object,
}

export default ReportPreview
