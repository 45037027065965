import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const teeData = ({tee, scoreDisplay, isMultiRound, isStableford, pastRounds, showPar}) => {
  return (
    <Fragment>
      <tr className='yardage_row'>
        <td rowSpan='1' className='first'>{tee.name}</td>
        <td className='second'>{!window.I18n ? '' : window.I18n.t('group_view.tee_data.yardage')}</td>
        {isMultiRound && <td></td>}
        {[ ...Array(9) ].map( ( _, i) => <td key={'hole-' + i}>{tee.yardage[i]}</td>)}
        { scoreDisplay && <td>{tee.yardageSumFront}</td>}
        {[ ...Array(9) ].map( ( _, i) => <td key={'hole-' + i}>{tee.yardage[i + 9]}</td>)}
        {scoreDisplay && <td>{tee.yardageSumBack}</td>}
        {!scoreDisplay && isMultiRound ? null : <td>{tee.yardageSum}</td>}
        {scoreDisplay && !isStableford && isMultiRound && <td></td>}
        {scoreDisplay && !isStableford && isMultiRound && <td></td>}
        {isStableford && isMultiRound && <td></td>}
        {!scoreDisplay && isMultiRound && 
          pastRounds.map( round => <td key={round}></td>)
        }
        {!scoreDisplay && isMultiRound && <td></td>}
      </tr>
      {showPar && 
        <tr className='par_row'>
          <td rowSpan='1' className='first'></td>
          <td className='second'>{!window.I18n ? '' : window.I18n.t('group_view.tee_data.par')}</td>
          {isMultiRound && <td></td>}
          {[ ...Array(9) ].map( ( _, i) => <td key={'hole-' + i}>{tee.par[i]}</td>)}
          {scoreDisplay && <td>{tee.parSumFront}</td>}
          {[ ...Array(9) ].map( ( _, i) => <td key={'hole-' + i}>{tee.par[i + 9]}</td>)}
          {scoreDisplay && <td>{tee.parSumBack}</td>}
          {!scoreDisplay && isMultiRound ? null : <td>{tee.parSum}</td>}
          {scoreDisplay && !isStableford && isMultiRound && <td></td>}
          {scoreDisplay && !isStableford && isMultiRound && <td></td>}
          {isStableford && isMultiRound && <td></td>}
          {!scoreDisplay && isMultiRound && 
            pastRounds.map( round => <td key={round}></td>)
          }
          {!scoreDisplay && isMultiRound && <td></td>}
        </tr>
      }
    </Fragment>
  )
}

teeData.propTypes = {
  tee: PropTypes.object,
  scoreDisplay: PropTypes.bool,
  isMultiRound: PropTypes.bool,
  isStableford: PropTypes.bool,
  pastRounds: PropTypes.array,
  showPar: PropTypes.bool,
}

export default teeData
