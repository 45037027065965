import {showErrorNotification, callAPI} from 'Shared/helpers'

export const ActionTypes = {
  FETCH_DATA_REQUEST: 'FETCH_DATA_REQUEST',
  FETCH_DATA_RECEIVED: 'FETCH_DATA_RECEIVED',
  RESET_TIMER: 'RESET_TIMER',
  CLEAR_TIMER: 'CLEAR_TIMER',
}

export const fetchDataRequest = (data) => ({
  type: ActionTypes.FETCH_DATA_REQUEST,
  data,
})

export const fetchDataReceived = (data) => ({
  type: ActionTypes.FETCH_DATA_RECEIVED,
  data,
})

export const clearTimer = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLEAR_TIMER,
  })
}

export const fetchData = (tourEventId = null, reloading) => (dispatch, getState) => {
  dispatch(fetchDataRequest({reloading: reloading}))
  const state = getState()
  const url = `${state.tourEventResultsData.fetchDataBaseUrl}/${tourEventId || state.tourEventResultsData.selectedTourEvent}/load_results`

  if (!reloading) {
    dispatch(clearTimer())
  }

  callAPI(url, 'GET')
    .then(response => {
      dispatch(fetchDataReceived(response))
    })
    .catch(() => {
      showErrorNotification(window.I18n.t('tour_event_results.actions.error_fetching_data'))
    })
}

export const resetTimer = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_TIMER,
    refreshMethod: () => dispatch(fetchData(null, true)),
  })
}
