// Enum containing different OTT screens. Used to determine which screen to render.
const OttScreen = {
  CREATE_BOOKING: 'CREATE_BOOKING',
  SIGNUP_ER_MEMBER: 'SIGNUP_ER_MEMBER',
  EDIT_BOOKING: 'EDIT_BOOKING',
  CHANGE_TEE_TIME: 'CHANGE_TEE_TIME',
  ADD_ADDITIONAL_PLAYERS: 'ADD_ADDITIONAL_PLAYERS',
  REMOVE_PLAYERS: 'REMOVE_PLAYERS',
  ADMIN_OR_MANAGER_SCREEN: 'ADMIN_OR_MANAGER_SCREEN',
}

export default OttScreen
