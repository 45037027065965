export const ActionTypes = {
  REMOVE_TV_SHOW: 'REMOVE_TV_SHOW',
  TOGGLE_AUTO_INCLUDE: 'TOGGLE_AUTO_INCLUDE',
}

export const removeTvShow = (tvShowId) => {
  return {
    type: ActionTypes.REMOVE_TV_SHOW,
    tvShowId,
  }
}

export const toggleAutoInclude = (tvShowId) => {
  return {
    type: ActionTypes.TOGGLE_AUTO_INCLUDE,
    tvShowId,
  }
}
