import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import GolferPick from './golfer_pick_component'
import GolferNameFilter from './golfer_name_rank_filter'

const AvailablePicksArea = ({availableGolfers, saveInProgress, moveToNowPicking}) => {

  const [ filteredGolfers, setFilteredGolfers ] = useState(availableGolfers)
  const [ filterLetters, setFilterLetters ] = useState('')

  useEffect(() => {
    filterGolfers(filterLetters)
  }, [ availableGolfers ])

  const filterGolfers = (filterLetters) => {
    const newFilteredGolfers = availableGolfers.filter(g => g.name.toLowerCase()
      .includes(filterLetters) || g.rank.toString().includes(filterLetters))
    setFilteredGolfers(newFilteredGolfers)
    setFilterLetters(filterLetters)
  }

  const onEnterKeyPress = (resetFilter) => {
    if (filteredGolfers.length === 1 && !saveInProgress) {
      moveToNowPicking(filteredGolfers[0])
      setFilterLetters('')
      resetFilter('')
    }
  }

  return (
    <div className="available_picks_area">
      <div className="golfer_search_area">
        <div className="golfers_count">
          {window.I18n.t('draft.components.manager_side.available_picks_area_component.count_golfers', {golfers_count: filteredGolfers.length})}
        </div>
        <GolferNameFilter filterGolfers={filterGolfers} onEnterKeyPress={onEnterKeyPress}/>
      </div>
      <ul className="golfer_picks_container">
        {filteredGolfers.map(golfer =>
          <GolferPick key={golfer.id} saveInProgress={saveInProgress} golfer={golfer} moveToNowPicking={moveToNowPicking}/>,
        )}
      </ul>
    </div>
  )
}

AvailablePicksArea.propTypes = {
  availableGolfers: PropTypes.array,
  saveInProgress: PropTypes.bool,
  moveToNowPicking: PropTypes.func,
}

export default AvailablePicksArea
