import { connect } from 'react-redux'
import AddGolfersManuallyComponent from '../../components/add_golfers_manually/add_golfers_manually_component'
import { closeAddGolfersManuallyPopup, openEditCustomFieldsPopup, fetchLeagueMetadata, saveNewMemberField, addMoreGolfers, addNewMembers, toggleTermsAndPrivacy } from '../../actions/index'

const mapStateToProps = (state) => ({
  show: state.addNewMembers.showAddGolfersManuallyPopup,
  membersMetadata: state.addNewMembers.membersMetadata,
  newMembers: state.addNewMembers.newMembers,
  nrCustomFields: state.addNewMembers.nrCustomFields,
  isNewMembersSaving: state.addNewMembers.isNewMembersSaving,
  termsAndPrivacyChecked: state.addNewMembers.termsAndPrivacyChecked,
  customerIsGolfAdvisor: state.misc.customerIsGolfAdvisor,
  indexType: state.misc.indexType,
  hasAutomatedHandicaps: state.misc.hasAutomatedHandicaps,
  hasDivisions: state.misc.hasDivisions,
  prettyHandicapMethod: state.misc.prettyHandicapMethod,
  branding: state.misc.branding,
  termsAndConditionsUrl: state.misc.termsAndConditionsUrl,
  privacyPolicyUrl: state.misc.privacyPolicyUrl,
})

const mapDispatchToProps = (dispatch) => ({
  closeAddGolfersManuallyPopup: (resetMembers) => dispatch(closeAddGolfersManuallyPopup(resetMembers)),
  openEditCustomFieldsPopup: (playerIndex) => dispatch(openEditCustomFieldsPopup(playerIndex)),
  fetchLeagueMetadata: () => dispatch(fetchLeagueMetadata()),
  saveNewMemberField: (name, value, index) => dispatch(saveNewMemberField(name, value, index)),
  addMoreGolfers: () => dispatch(addMoreGolfers()),
  addNewMembers: () => dispatch(addNewMembers()),
  toggleTermsAndPrivacy: (checked, type) => dispatch(toggleTermsAndPrivacy(checked, type)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddGolfersManuallyComponent)
