import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { GlgTooltipIcon } from 'SharedComponents'

const RelativeLabel = styled.label`
  position: relative;
`

function RadioOption({id, label, value, selected, setOption, enabled = true, tooltip = null, key = null, className = ''}) {
  return (
    <RelativeLabel key={ key || `${id}_${value}`} className={`icheck-label ${enabled ? '' : 'disabled'} ${className}`} htmlFor={`${id}_${value}`}>
      <div className={`iradio_square-orange icheck-item ${selected ? 'checked' : ''}`}>
        <input
          id={`${id}_${value}`}
          className='i-checks icheck-input'
          type='radio'
          name={id}
          value={value}
          onChange={ event => enabled && setOption(event.target.value) }
          checked={selected}
          disabled={!enabled}
        />
      </div>
      <span>{label}</span>
      { tooltip && <GlgTooltipIcon tooltip={ tooltip } /> }
    </RelativeLabel>
  )
}

RadioOption.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  selected: PropTypes.bool,
  setOption: PropTypes.func,
  enabled: PropTypes.bool,
  tooltip: PropTypes.any,
  key: PropTypes.string,
  className: PropTypes.string,
}

export default RadioOption
