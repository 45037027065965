import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Block from './block'
import { Col } from 'react-flexbox-grid'

class ListBlocks extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Col xs={12} className="blocks-list">
        {this.props.blocks.length >= 1 ? ( 
          this.props.blocks.map(block =>
            <Block key={block.id} onRemove={ () => this.props.removeBlock(block.id) } onEdit={ () => this.props.editBlock(block.id) } currentLanguage={ this.props.currentLanguage } {...block} />
          )
        ) : (
          ''
        )}
      </Col>
    )
  }
}

ListBlocks.propTypes = {
  blocks: PropTypes.array,
  removeBlock: PropTypes.func,
  editBlock: PropTypes.func,
  usesTranslations: PropTypes.bool,
  availableLanguages: PropTypes.any,
  currentLanguage: PropTypes.any,
}

export default ListBlocks
