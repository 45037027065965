import { connect } from 'react-redux'
import _ from 'lodash'

import PopupTypes from '../popup_types'
import {
  closePopup,
  updateOption,
  createOption,
} from '../actions'

import NavigatorOptionsPopupsComponent from '../components/navigator_options_popups_component'

const mapStateToProps = state => {
  const locationSuggestions = _
    .chain(_.values(state.navigatorOptions))
    .map(navigatorOption => (
      navigatorOption.location.split('>')[0].trim()
    ))
    .uniq()
    .compact()
    .value()
  return {
    navigatorOptionPopup: state.popupStates[PopupTypes.NAVIGATOR_OPTION_POPUP],
    locationSuggestions,
  }
}

const mapDispatchToProps = (dispatch) => ({
  closePopup: id => dispatch(closePopup(id)),
  onOptionEdit: (id, data) => dispatch(updateOption(id, data)),
  onOptionCreate: data => dispatch(createOption(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigatorOptionsPopupsComponent)
