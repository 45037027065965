import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { getAuthenticityToken } from 'Shared/helpers'

const StyledFormComponents = styled.div`
.flyer {
  padding: 0px 10px;
  margin-bottom: 15px;

  .name-container {
    padding: 5px 0px 5px 20px;
    display: flex;
    justify-content: center;
    font-size: 15px;
    background-color: #343f48;
    color: white;
    margin-bottom: 2px;
    
    .flyer_name {
      width: 90%;
      display: flex;
      justify-content: center;
    }

    .flyer_input_edit {
      color: black;
      height: 20px;
    }
  }

  .delete-flyer {
    background-color: grey;

    .flyer-icon-delete {
      display: flex;
      justify-content: center;
      width: inherit;
      padding: 5px 0px;
    }
  }

  .image-container {
    height: 403px;
    width: 285px;
    position: relative;

    .image, .menu {
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
    }

    .image {
      pointer-events: initial;
      cursor: default;
      position: absolute;
      height: 369px;

      .flyer_thumb {
        max-width: 8.5in;
        max-height: 11in;
        width: 100%;
        height: 369px;
      }
    }

    .menu-color {
      background: #ff6632;
      position: absolute;
      height: 369px;
      width: 285px;
    }

    .menu-buttons {
      position: absolute;
      height: 369px;
      width: 285px;
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-content: center;
      flex-wrap: wrap;
      padding-bottom: 10px;

      .redirect-button {
        background-color: #ff6632;
        border-radius: 3px;
        color: white;
        width: 210px;
        height: 35px;
        margin: 5px 0px;
        border: 1.5px solid white;
        font-size: 16px;
      }
    }
  }

  .overflow-image {
    opacity: 0.9;
    z-index: 100;
  }

  .overflow-buttons {
    z-index: 1000;
  }
}      
`

const Flyer = (props) => {
  const [ showInput, setShowInput ] = useState(false)
  const [ flyerName, setFlyerName ] = useState(props.data.name)
  const [ isSwitchTime, setIsSwitchTime ] = useState(false)
  const adminAndTemplate = props.isAdmin && props.isSystemTemplate
  const [ isDownloadingPng, setIsDownloadingPng ] = useState(false)
  const [ isDownloadingPdf, setIsDownloadingPdf ] = useState(false)

  const updateFlyerLog = async (description) => {
    const params = {
      desc: description,
      league_id: props.leagueId,
    }

    return fetch(`/leagues/${props.leagueId}/flyers/create_flyer_log`, {
      method: 'POST',
      headers: 
      {
        'X-CSRF-Token': getAuthenticityToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })
  }

  const editFlyer = (saveAsTemplate) => {
    const linkToFlyer = `/leagues/${props.leagueId}/flyers/flyer?predefined=${props.data.id}&saveAsTemplate=${saveAsTemplate}&isSystemTemplate=${props.isSystemTemplate}&newSystemTemplate=false&newFlyer=false`

    window.location.href = linkToFlyer
  }

  const toggleIn = () => {
    setIsSwitchTime(true)
  }
  
  const toggleOff = () => {
    setIsSwitchTime(false)
  }

  const toggleShow = () => {
    setShowInput(!showInput)
  }

  const handleInputChange = (event) => {
    setFlyerName(event.target.value)
  }

  const handleInputBlur = () => {
    updateName()
  }

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      updateName()
    }
  }

  const updateName = () => {
    fetch(`/leagues/${props.leagueId}/flyers/update_name?flyer_id=${props.data.id}&is_system_template=${props.isSystemTemplate}&new_name=${flyerName}`, {
      method: 'PATCH',
      headers: {'X-CSRF-Token': getAuthenticityToken()},
    })
    .then(() => {
      const flyerInArrayIndex = props.flyers.findIndex(flyer => flyer.id === props.data.id)
      const updatedFlyers = [ ...props.flyers ]
      updatedFlyers[flyerInArrayIndex].name = flyerName

      props.setFlyers(updatedFlyers)
      props.setFlyersAfterfilters(updatedFlyers)

      setShowInput(false)
    })
  }

  const convertToBlob = async (clause, scene) => {
    try {
      const mimePdfOrPng = clause === 'pdf' ? 'application/pdf' : 'image/png'

      return await props.imglyInstance.engine.block.export(scene, mimePdfOrPng)
    } catch (error) {
      throw error
    }
  }

  const setDownloadText = (clause, value) => {
    if (clause === 'pdf') {
      setIsDownloadingPdf(value)
    } else {
      setIsDownloadingPng(value)
    }
  }

  const getSceneFromURL = async (clause, fileUrl) => {
    try {
      await props.imglyInstance.engine.scene.loadFromURL(fileUrl)
      const scenes = clause === 'pdf' ? [ await props.imglyInstance.engine.scene.get() ] : await props.imglyInstance.engine.scene.getPages()
  
      return scenes
    } catch (error) {
      return false
    }
  }

  const printFlyer = async (clause, fileUrl, event) => {
    setDownloadText(clause, true)
    const scenes = await getSceneFromURL(clause, fileUrl)

    if (scenes !== false) {
      scenes.forEach(async (scene) => {
        try {
          await convertToBlob(clause, scene).then(blob => {
            const anchor = document.createElement('a')
            anchor.href = URL.createObjectURL(blob)
            anchor.download = `flyer.${clause === 'pdf' ? 'pdf' : 'png'}`
            anchor.click()
          })
        } catch (error) {
          window.show_flash(window.I18n.t('views.golfhub.error_download_flyer'))
          window.hide_flash()
        }
      })

      event.preventDefault()
      await updateFlyerLog(window.I18n.t('views.golfhub.flyer_stats.print_flyer'))
    } else {
      window.show_flash(window.I18n.t('views.golfhub.error_download_flyer'))
      window.hide_flash()
    }

    setDownloadText(clause, false)
  }

  return (
    <StyledFormComponents>
      <div className="flyer">
        <div className="name-container">
          <div className="flyer_name">
            {showInput ? 
              <input type="text" 
                value={flyerName} 
                placeholder={flyerName} 
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onKeyPress={handleInputKeyPress}
                className='flyer_input_edit' /> 
              : 
              flyerName}
          </div>
          {(adminAndTemplate || !props.isSystemTemplate) &&
            <div className="edit_flyer_name" onClick={() => toggleShow()}>
              <i className='fa fa-pencil fa-fw'></i>
            </div>
          }
        </div>
        <div id="image-container" className="image-container" onMouseEnter={toggleIn} onMouseLeave={toggleOff}>
          <div className='image'>
            <img
              className="flyer_thumb"
              src={props.data.blob_image.url}
              alt="Image"
            />
          </div>
          <div className={`menu-color${isSwitchTime ? ' overflow-image' : ' hidden'}`}></div>
          <div className={`menu-buttons${isSwitchTime ? ' overflow-buttons' : ' hidden'}`}>
            {props.isSystemTemplate && 
              <Button onClick={() => editFlyer(false)} className = 'redirect-button'>
                { window.I18n.t('views.golfhub.flyer_action.new_flyer') }
              </Button>
            }
            {(adminAndTemplate || !props.isSystemTemplate) &&
              <>
                <Button onClick={() => editFlyer(true)} className = 'redirect-button'>
                  { adminAndTemplate ? window.I18n.t('views.golfhub.flyer_action.edit_system_template') : window.I18n.t('views.golfhub.flyer_action.edit_flyer') }
                </Button>
                {!props.isSystemTemplate && 
                  <>
                    <Button onClick={(event) => printFlyer('png', props.data['blob']['url'], event)} className = 'redirect-button'>
                      { isDownloadingPng ? window.I18n.t('views.golfhub.download_in_progress') : window.I18n.t('views.golfhub.download_png') }
                    </Button>
                    <Button onClick={(event) => printFlyer('pdf', props.data['blob']['url'], event)} className = 'redirect-button'>
                      { isDownloadingPdf ? window.I18n.t('views.golfhub.download_in_progress') : window.I18n.t('views.golfhub.download_pdf') }
                    </Button>
                  </>
                }
                <Button onClick={() => props.handleFlyerDelete()} className = 'redirect-button'>
                  { adminAndTemplate ? window.I18n.t('views.golfhub.flyer_action.delete_system_flyer') : window.I18n.t('views.golfhub.flyer_action.delete_flyer') }
                </Button>
              </>
            }
          </div>
        </div>
      </div>
    </StyledFormComponents>
  )
}

Flyer.propTypes = {
  data: PropTypes.object,
  canDelete: PropTypes.bool,
  handleFlyerDelete: PropTypes.func,
  leagueId: PropTypes.string,
  isAdmin: PropTypes.bool,
  isSystemTemplate: PropTypes.bool,
  flyers: PropTypes.array,
  setFlyers: PropTypes.func,
  flyersAfterFilters: PropTypes.array,
  setFlyersAfterfilters: PropTypes.func,
  imglyInstance: PropTypes.object,
}

export default Flyer
