import { connect } from 'react-redux'
import _ from 'lodash'

import {
  changeFilter,
  changeSortingColumn,
  changeSortingOrder,
  openPopup,
} from '../actions'
import CategoriesFiltersComponent from '../components/categories_filters_component'
import {
  getSeasonsOptions, getToursOptions, getCategoriesOptions,
  checkShowSeasons, checkShowTours, checkShowRegistration,
} from '../selectors'

const mapStateToProps = state => {
  const seasonsOptions = getSeasonsOptions(state, { addAll: true })
  const showSeasons = checkShowSeasons(state, { checkLeagues: true })

  const userIsTourManager = state.misc.userIsTourManager
  const toursOptions = getToursOptions(state, { addAll: true, addEmptyOption: !userIsTourManager })
  const showTours = checkShowTours(state, { checkLeagues: true })

  const categoriesOptions = getCategoriesOptions(state, { addAll: true })
  const showCategoryFilter = _.size(state.categories) > 1

  const leagueName = state.filters.leagueName
  const lastVisitedLeagueData = state.misc.lastVisitedLeagueData
  const registrationLeagueData = state.misc.registrationLeagueData
  const showRegistrationOptions = checkShowRegistration(state)

  return {
    seasonsOptions,
    showSeasons,
    categoriesOptions,
    showCategoryFilter,
    toursOptions,
    showTours,
    leagueName,
    viewType: state.filters.viewType,
    advancedFiltersOn: state.filters.advancedFiltersOn,
    selectedSeason: state.filters.season,
    selectedCategory: state.filters.category,
    selectedTour: state.filters.tour,
    selectedRegistration: state.filters.registration,
    sortingColumn: state.sorting.column,
    sortingOrder: state.sorting.order || '',
    lastVisitedLeagueData,
    registrationLeagueData,
    showRegistrationOptions,
    userIsCustomerManager: state.misc.userIsCustomerManager,
    userIsTourManager: state.misc.userIsTourManager,
    dataIsLoading: !!state.misc.dataIsLoading,
    loadingFilters: state.filters.loading,
    golfHubEnabled: state.misc.golfHubEnabled,
  }
}

const mapDispatchToProps = dispatch => ({
  changeFilter: (filter, value) => dispatch(changeFilter(filter, value)),
  changeSortingColumn: (column) => dispatch(changeSortingColumn(column)),
  changeSortingOrder: (order) => dispatch(changeSortingOrder(order)),
  openPopup: (id, data) => dispatch(openPopup(id, data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesFiltersComponent)
