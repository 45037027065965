import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { GlgCheckbox, GlgRadio } from 'SharedComponents'
import { Col } from 'react-flexbox-grid'
import LibraryRoundsModal from './library_rounds_modal'

class LibrarySectionComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }
    this.handleRadioChange = this.handleRadioChange.bind(this)
    this.toggleRoundsModal = this.toggleRoundsModal.bind(this)
  }

  handleRadioChange(value) {
    this.props.libraryDispatchers.setAutoIncludeOption(value)
    this.setState({
      showModal: value === 'include_selected',
    })
  }

  toggleRoundsModal(e, showModal) {
    e?.preventDefault()
    this.setState({
      showModal: showModal,
    })
  }

  render() {
    const {
      library: {
        setTemplate,
        autoIncludeOption,
        textHash,
        rounds,
      },
      libraryDispatchers: { handleSetTemplate },
    } = this.props
    const { showModal } = this.state
    const showAutoIncludeOptions = setTemplate && (Object.keys(rounds).length > 0)

    return <Fragment><GlgCheckbox label={ textHash.check_box_text } key="set_template" name="set_template" checked={ setTemplate } onChange={ handleSetTemplate } inline={ true }></GlgCheckbox><div className="break clearfix"></div>{ showAutoIncludeOptions && <div className="auto_include_options add-margin-top-5"><Col xs={ 12 } sm={ 4 } md={ 4 } lg={ 3 } className="no-padding-left no-padding-right"><GlgRadio name="auto_include" value="do_not_include" label={ textHash.do_not_include_text } defaultChecked={ autoIncludeOption === 'do_not_include' } onChange={ this.handleRadioChange } inline={ true }></GlgRadio></Col><Col xs={ 12 } sm={ 4 } md={ 4 } lg={ 5 } className="no-padding-left no-padding-right"><GlgRadio name="auto_include" value="include_all" label={ textHash.auto_include_all_text } defaultChecked={ autoIncludeOption === 'include_all' } onChange={ this.handleRadioChange } inline={ true }></GlgRadio></Col><Col xs={ 12 } sm={ 4 } md={ 4 } lg={ 4 } className="no-padding-left no-padding-right"><GlgRadio name="auto_include" value="include_selected" label={ textHash.auto_include_selected_text } defaultChecked={ autoIncludeOption === 'include_selected' } onChange={ this.handleRadioChange } inline={ true }></GlgRadio>{ (autoIncludeOption === 'include_selected') && <a id="edit_included_rounds" href={ '#' } onClick={ (e) => this.toggleRoundsModal(e, true) } className="add-margin-left-25">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.library_section_component.edit') }</a>}</Col></div>}{ <LibraryRoundsModal 
            show={ showModal }
            onClose={ () => this.toggleRoundsModal(null, false) }
            library={ this.props.library }
            libraryDispatchers= { this.props.libraryDispatchers }
        /> }</Fragment>
  }
}

LibrarySectionComponent.propTypes = {
  library: PropTypes.object.isRequired,
  libraryDispatchers: PropTypes.object.isRequired,
}

export default LibrarySectionComponent
