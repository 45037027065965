import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  GlgRadio,
} from 'SharedComponents'

const handlePrint = ({selectedSpec, printReport, id}) => {
  printReport(null, 'GET', {spec: selectedSpec})
  $(`#${id}`).modal('hide')
}

const TournamentSelectPopup = ({id, rciId, dcpSpecs, printReport}) => {
  const defaultSelectedSpec = dcpSpecs[0] ? dcpSpecs[0].id : null
  const [ selectedSpec, setSelectedSpec ] = useState(defaultSelectedSpec)
  
  return (
    <div className='modal fade'
         id={id}
         tabIndex='-1'
         role='dialog'
         aria-labelledby='mySmallModalLabel'>
      <div className='modal-dialog modal-sl' role='document'>
        <div className='modal-content'>
          <div className='create_list'>
            <div className='page_title'>
              <div className='text'>
                {!window.I18n ? '' : window.I18n.t('download_center.tournament_select.dcp_results_spreadsheet')}
              </div>
            </div>
            <div className='btsp_square_solid'>
              <b>
                {!window.I18n ? '' : window.I18n.t('download_center.tournament_select.select_tournament')}
              </b>
              { dcpSpecs.map((spec, index) => (
                <GlgRadio key = {rciId + '-' + spec.id}
                          name="league[product]"
                          defaultChecked = {index === 0}
                          value={$`spec.id`}
                          label={spec.name}
                          onChange={() => setSelectedSpec(spec.id)}
                />
              ))}
            </div>
            <a className='btsp_white_button pull-left'
               onClick={ () => { $(`#${id}`).modal('hide') }}>
               {!window.I18n ? '' : window.I18n.t('download_center.tournament_select.cancel')}
            </a>
            <a className='btsp_orange_button pull-right' onClick={() => handlePrint({selectedSpec, printReport, id})}>Download</a>
          </div>
        </div>
      </div>
    </div>
  )
}

TournamentSelectPopup.propTypes = {
  id: PropTypes.String,
  rciId: PropTypes.string,
  dcpSpecs: PropTypes.array,
  printReport: PropTypes.func,
}

export default TournamentSelectPopup
