import { connect } from 'react-redux'
import _ from 'lodash'

import { openPopup } from '../actions'
import CustomerActionsComponent from '../components/customer_actions_component'

const mapStateToProps = (state) => ({
  userIsCustomerManager: state.misc.userIsCustomerManager,
  userIsTourManager: state.misc.userIsTourManager,
  dataIsLoading: !!state.misc.dataIsLoading,
  userCanCloneEvents: _.sumBy(Object.values(state.categories), category => category.leaguesCount) > 0,
  isSalesDemo: state.misc.isSalesDemo,
  userIsVisageSuperAdmin: state.misc.userIsVisageSuperAdmin,
  manageVisageURL: state.misc.manageVisageURL,
  customerOnlyEvents: state.misc.customerOnlyEvents,
  customerOnlyLeagues: state.misc.customerOnlyLeagues,
  customerNeedsSubscription: state.misc.customerNeedsSubscription,
  customerTripOnly: state.misc.customerTripOnly,
  canCreateTrips: state.misc.canCreateTrips,
  canCreateDCP: state.misc.canCreateDCP,
})

const mapDispatchToProps = (dispatch) => ({
  openPopup: (id) => dispatch(openPopup(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerActionsComponent)
