import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'

export class LargeStringComponent extends Component {
  constructor(props){
    super(props)
    this.uniqueId = new Date().getTime()
  }

  componentDidMount() {
    let answer = ''
    let answerId = ''
    if (this.props.answer) {
      answer = this.props.answer.value
      answerId = this.props.answer.answerId
    }
    window.glg.metadata.initAnswerSearch(this.uniqueId, this.props.field.id, '100%', this.props.leagueId, answer, answerId, this.props.onChange)
  }

  render(){
    return <Col xs={12} md={8}>
      <select className={`search_answer_${this.props.field.id}_${this.uniqueId}`}/>
    </Col>
  }
}

LargeStringComponent.propTypes = {
  field: PropTypes.object.isRequired,
  answer: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  leagueId: PropTypes.string,
}

export default LargeStringComponent
