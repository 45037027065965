import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import TournamentSelectPopup from './tournament_select_popup'
import ReportCenterItemDropdown from './report_center_item_dropdown'

class ReportCenterItemInline extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tournamentSelectPopupId: `tournament_select_${this.props.current_report_center_list.id}_${this.props.report_center_item.id}`,
    }
  }
  toggleTournamentModal() {
    $(`#${this.state.tournamentSelectPopupId}`).modal('show')
    window.glg.new_design_shared.computeAndApplyBorder()
  }

  stringParameterize(str) {
    return str.trim().toLowerCase()
      .replace(/[^a-zA-Z0-9 -]/g, '')
      .replace(/ +/g, ' ')
      .replace(/[\s-]/g, '_')
  }

  render() {
    return (
      <Fragment>
        <div className= { 'report-list-item report_center_item_inline' + ( this.props.job.error ? ' error' : '' ) + ( this.props.report_center_item.display ? '' : ' hidden') + (this.props.job.id || this.props.job.completed_links ? ' in_progress' : '')} >
          <div className= { 'name' + ([ 'Gpu::Report', 'Pairing' ].indexOf(this.props.report_center_item.report_type) !== -1 && this.props.report_center_item.name.length > 18 ? ' small-name' : '')}>
            <div className='nameContainer'>
              { [ 'unrestricted', 'restricted' ].includes(this.props.report_center_item.access) &&
                !this.props.isTeamCaptain && this.props.report_center_item.name !== 'Scores for CDGA' &&
                <div>
                  {
                    [ 'Gpu::Report', 'SpreadsheetReport', 'PublisherTemplate', 'Gpu::Scorecard' ].indexOf(this.props.report_center_item.report_type) !== -1 && !this.props.current_report_center_list.read_only &&
                      <a onClick={this.props.deleteItem} className='pull-left edit-name-link'>
                        <i className='fa fa-trash fa-fw'></i>
                      </a>
                  }
                  { this.props.report_center_item.access !== 'restricted' &&
                    // edit icon for non-static reports
                    (
                    ([ 'Gpu::Report', 'SpreadsheetReport', 'Gpu::Scorecard' ].indexOf(this.props.report_center_item.report_type) === -1 || this.props.current_report_center_list.read_only) ?
                      <span></span>
                    : 
                      (this.props.edit_name === false &&
                        <a onClick={this.props.toggleItemName} className='pull-right edit-name-link'>
                          <i className='fa fa-pencil fa-fw'></i>
                        </a>
                      )
                    )
                  }
                </div>
              }
              { // name input field
                this.props.edit_name ? (
                  <input autoFocus type='text'
                    defaultValue={this.props.report_center_item.name}
                    ref={(input) => { this.nameInput = input }}
                    onKeyPress={ target => {
                                                      if (target.key === 'Enter') {
                                                        this.props.saveItemName(this.nameInput.value)
                                                      }
                                                   }
                    }
                    onBlur={ () => { this.props.saveItemName(this.nameInput.value) }} />
                ) : (
                  !window.I18n ? '' : window.I18n.t(`download_center.item_name.${this.stringParameterize(this.props.report_center_item.name)}`, {defaultValue: this.props.report_center_item.name.toUpperCase()})
                )
              }
            </div>
          </div>
          
          <div className='body'>
            {
              //job in progress 
              this.props.job.id !== null &&
                <div className="job">
                  <img src={require('bootstrap_theme/job/Spinning-circle.gif')} />
                  { 
                    this.props.job.status_updates.length > 0 &&
                      <i className="gga-info-circle" title={this.props.job.status_updates.join('.\n')}></i>
                  }
                </div>
            }

            <div className={ this.props.job.id === null && this.props.job.completed_links === null ? '' : 'hidden' }>
              <div className={ 'picture' + ((this.props.report_center_item.image_url === null) ? ' no-image' : '')}>
                <img className={this.props.report_center_item.report_type === 'SpreadsheetReport' && this.props.report_center_item.image_url !== null ? 'spreadsheet' : '' } src={this.props.report_center_item.image_url || require('report_center/no-image.png')} loading="lazy"/>
              </div>
              <div className='menu'>
                {/* report buttons saga */}
                { 
                  //accomodate to _blank links for action. TO DO: generalize number/types of links
                  this.props.report_center_item.action !== null ?
                    this.props.report_center_item.action[0].length === 2 ?
                      <div>
                        {/* for tee sheet only print inline first action */}
                        {
                          [ 'TeesheetSetting', 'TeesheetAlphalistSetting', 'CartSignsSetting' ].indexOf(this.props.report_center_item.report_type) !== -1 ?
                            <a onClick={ [ 'Gpu::Report', 'Pairing' ].indexOf(this.props.report_center_item.report_type) !== -1 ?
                                //if GPU::Report - tell modal to load print_options form from edit_conflicts_path and submit throught printReport or cancel throught setJobId of this very rci
                                //otherwise just printReport right away
                                () => { this.props.openPrintModal({ edit_conflicts_path: this.props.report_center_item.action[0][1], rci_base_printReport: this.props.printReport, rci_base_setJobId: this.props.setJobId}) }
                              : () => { this.props.printReport(this.props.report_center_item.action[0][1]) } }>
                              <div className='btsp_orange_button small'>
                                {this.props.report_center_item.action[0][0]}
                              </div>
                            </a>
                          :
                            <a onClick={ () => { this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, this.props.report_center_item.action[0][1], this.props.report_center_item.edit_path[0][0]) } }>
                              <div className={ 'btsp_orange_button small'}>
                              {this.props.report_center_item.action[0][0]}
                              </div>
                            </a>
                        }
                        <div className='rcSpacer'></div>
                        <a onClick={ () => { this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, this.props.report_center_item.action[1][1], this.props.report_center_item.edit_path[1][0]) } }>
                          <div className={ 'btsp_orange_button small'}>
                          {this.props.report_center_item.action[1][0]}
                          </div>
                        </a>
                      </div>
                    :
                      <Fragment>
                        <a onClick={ [ 'Gpu::Report', 'Pairing', 'Gpu::Scorecard', 'LeagueWinners' ].indexOf(this.props.report_center_item.report_type) !== -1 ?
                            //if GPU::Report - tell modal to load print_options form from edit_conflicts_path and submit throught printReport or cancel throught setJobId of this very rci
                            //otherwise just printReport right away
                            () => { this.props.openPrintModal({ edit_conflicts_path: this.props.report_center_item.action[1], rci_base_printReport: this.props.printReport, rci_base_setJobId: this.props.setJobId}) }
                          : () => { this.props.report_center_item.report_type === 'DCPResult' ? this.toggleTournamentModal() : this.props.printReport() } }>
                          <div className='btsp_orange_button'>
                          {this.props.report_center_item.action[0]}
                          </div>
                        </a>
                        {
                          this.props.report_center_item.needs_import && this.props.current_report_center_list.id === '-2' &&
                            <a onClick={() => this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, '', 'Copy') }>
                              <div className='btsp_orange_button'>
                              {!window.I18n ? '' : window.I18n.t('download_center.import')}
                              </div>
                            </a>
                        }
                      </Fragment>
                      
                  : ''
                }
                { !this.props.isTeamCaptain &&
                  <div>
                    { 
                      this.props.report_center_item.edit_path !== null && this.props.report_center_item.name !== 'Scores for CDGA' ?
                        <div>
                          { 
                            // fit first edit & copy on on row otherwise separate rows
                            [ 'unrestricted' ].includes(this.props.report_center_item.access) &&
                            this.props.report_center_item.edit_path[0].length === 2 && this.props.report_center_item.report_id !== null ?
                              <div>
                                <a onClick={ () => { this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, this.props.report_center_item.edit_path[0][1], this.props.report_center_item.edit_path[0][0]) } }>
                                  <div className={ this.props.current_report_center_list.id === '-1' && !this.props.report_center_item.needs_import ? 'btsp_orange_button' : 'btsp_orange_button small' }>
                                    {this.props.report_center_item.edit_path[0][0]}
                                  </div>
                                </a>
                                { this.props.current_report_center_list.id === '-1' && !this.props.report_center_item.needs_import ? null : <div className='rcSpacer'></div> }
                                { this.props.current_report_center_list.id === '-1' ?
                                  (this.props.report_center_item.needs_import ?
                                  <a onClick={() => this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, '', 'Copy') }>
                                    <div className='btsp_orange_button small'>
                                    {!window.I18n ? '' : window.I18n.t('download_center.import')}
                                    </div>
                                  </a> : null) :
                                  <a onClick={() => this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, '', 'Copy') }>
                                    <div className='btsp_orange_button small'>
                                    {!window.I18n ? '' : window.I18n.t('download_center.copy')}
                                    </div>
                                  </a>
                                  }
                                <a onClick={ () => { this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, this.props.report_center_item.edit_path[1][1], this.props.report_center_item.edit_path[1][0]) } }>
                                  <div className={ 'btsp_orange_button'}>
                                  {this.props.report_center_item.edit_path[1][0]}
                                  </div>
                                </a>
                              </div>
                            : 
                              <div>
                                {
                                  [ 'unrestricted' ].includes(this.props.report_center_item.access) &&
                                  (
                                    this.props.report_center_item.edit_path[0].length === 2 ?
                                    <div>
                                      <a onClick={ () => { this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, this.props.report_center_item.edit_path[0][1], this.props.report_center_item.edit_path[0][0]) } }>
                                        <div className={ 'btsp_orange_button'}>
                                        {this.props.report_center_item.edit_path[0][0]}
                                        </div>
                                      </a>
                                      <a onClick={ () => { this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, this.props.report_center_item.edit_path[1][1], this.props.report_center_item.edit_path[1][0]) } }>
                                        <div className={ 'btsp_orange_button'}>
                                        {this.props.report_center_item.edit_path[1][0]}
                                        </div>
                                      </a>
                                    </div>
                                  :
                                    <a onClick={ () => { this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, this.props.report_center_item.edit_path[1], this.props.report_center_item.edit_path[0]) } }>
                                      <div className={ 'btsp_orange_button'}>
                                        {this.props.report_center_item.edit_path[0]}
                                      </div>
                                    </a>
                                  )
                                }
                                {
                                  this.props.report_center_item.report_id !== null &&
                                    (this.props.current_report_center_list.id !== '-1' ?
                                      <a onClick={() => this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, '', 'Copy') }>
                                        <div className='btsp_orange_button no-lite'>
                                        {!window.I18n ? '' : window.I18n.t('download_center.copy')}
                                        </div>
                                      </a>
                                    :
                                     this.props.report_center_item.needs_import &&
                                      <a onClick={() => this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, '', 'Copy') }>
                                        <div className='btsp_orange_button'>
                                        {!window.I18n ? '' : window.I18n.t('download_center.import')}
                                        </div>
                                      </a>
                                    )
                                }
                              </div>
                          }
                        </div> : ''
                    }
                    {
                      this.props.report_center_item.enabled_tooltip && 
                        <div className="report-disabled">{ this.props.report_center_item.enabled_tooltip }</div>
                    }
                    {
                      ([ 'unrestricted', 'restricted' ].includes(this.props.report_center_item.access) &&
                        ((this.props.current_report_center_list.id === '-1' && !this.props.report_center_item.needs_import) || !this.props.current_report_center_list.read_only)) &&
                        <ReportCenterItemDropdown 
                          layout={this.props.layout}
                          report_center_lists={this.props.report_center_lists}
                          current_report_center_list={this.props.current_report_center_list}
                          report_center_item={this.props.report_center_item}
                          addRemoveItemToList={this.props.addRemoveItemToList}
                          stringParameterize={this.stringParameterize}/>
                    }
                  </div>  
                }
              </div>
            </div>

            {
              //overlay after download has finished succesfully
              this.props.job.id === null && this.props.job.completed_links !== null &&
                <div className='menu post_print'>
                  <a onClick={ () => this.props.setJobId({ id: null, error: false, completed_links: null}) }>
                    <i className='fa fa-times'></i>
                  </a>
                  { 
                    this.props.job.completed_links['pdf_path'] ?
                      <div>
                        <a target='_new'
                           href={ this.props.job.completed_links['pdf_path'] }>
                          <div className={ 'btsp_orange_button'}>
                            { this.props.job.completed_links.button_label }
                          </div>
                        </a>
                      </div> : ''
                  }
                  { 
                    this.props.job.completed_links['primary_docx_service'] !== null ?
                      (
                        <a onClick={ this.props.generateDocx }>
                          <div className='btsp_orange_button'>
                          {!window.I18n ? '' : window.I18n.t('download_center.generate_doc')}
                          </div>
                        </a>
                      ) : (
                        (this.props.job.completed_links['docx_path'] || null) !== null ?
                          <div>
                            <a target='_new'
                               href={this.props.job.completed_links['docx_path']}>
                              <div className={ 'btsp_orange_button'}>
                              {!window.I18n ? '' : window.I18n.t('download_center.download_doc')}
                              </div>
                            </a>
                          </div> : ''
                      ) 
                  }
                  { 
                    this.props.job.completed_links['generate_txt'] !== null ?
                      (
                        <a onClick={ this.props.generateTxt }>
                          <div className='btsp_orange_button'>
                          {!window.I18n ? '' : window.I18n.t('download_center.generate_txt')}
                          </div>
                        </a>
                      ) : (
                        (this.props.job.completed_links['txt_path'] || null) !== null ?
                          <div>
                            <a target='_new'
                               href={this.props.job.completed_links['txt_path']}>
                              <div className={ 'btsp_orange_button'}>
                              {!window.I18n ? '' : window.I18n.t('download_center.download_txt')}
                              </div>
                            </a>
                          </div> : ''
                      ) 
                  }
                </div>
            }
          </div>
          
          <div className='stats'>
            <span className='pull-left'>
              <i className='fa fa-fw fa-list'></i>
              { this.props.report_center_item.list_count || 0}
            </span>
            { 
              this.props.report_center_item.image_url ?
                <a onClick={ this.props.togglePreview } className="zoom">
                  <i className='fa fa-fw fa-search'></i>
                  {
                    this.props.show_preview && 
                      <div>
                        <div className="shadow"></div>
                        <img src={this.props.report_center_item.image_url || require('report_center/no-image.png')} />
                        <a onClick={ this.props.togglePreview }>
                          <img src={require('bootstrap_theme/close.png')} className="close_button"/>
                        </a>  
                      </div>
                  }
                </a>
              :
                <i className="gga-info-circle" title={!window.I18n ? '' : window.I18n.t('download_center.run_report_for_preview')}></i>
            }
            <span className='pull_right'>
              <i className='fa fa-fw fa-print'></i>
              { this.props.report_center_item.print_count || 0 }
            </span>
          </div>
        </div>
        {this.props.report_center_item.report_type === 'DCPResult' && (
          <TournamentSelectPopup id={this.state.tournamentSelectPopupId} rciId={this.props.report_center_item.id} dcpSpecs={this.props.dcpSpecs} printReport={this.props.printReport} />
        )}
      </Fragment>
    )
  }
}

ReportCenterItemInline.propTypes = {
  job: PropTypes.object.isRequired,
  report_center_item: PropTypes.object.isRequired,
  report_center_lists: PropTypes.array.isRequired,
  current_report_center_list: PropTypes.object.isRequired,
  edit_name: PropTypes.bool.isRequired,
  show_preview: PropTypes.bool.isRequired,
  deleteItem: PropTypes.func.isRequired,
  toggleItemName: PropTypes.func.isRequired,
  saveItemName: PropTypes.func.isRequired,
  cloneItem: PropTypes.func.isRequired,
  togglePreview: PropTypes.func.isRequired,
  generateDocx: PropTypes.func.isRequired,
  generateTxt: PropTypes.func.isRequired,
  redirectUnlessJobsRunning: PropTypes.func.isRequired,
  setJobId: PropTypes.func.isRequired,
  printReport: PropTypes.func.isRequired,
  openPrintModal: PropTypes.func.isRequired,
  addRemoveItemToList: PropTypes.func.isRequired,
  isTeamCaptain: PropTypes.bool.isRequired,
  dcpSpecs: PropTypes.array,
  importEvent: PropTypes.func.isRequired,
  layout: PropTypes.string.isRequired,
}

export default ReportCenterItemInline
