import React, { Component } from 'react'
import { Col } from 'react-flexbox-grid'
import PropTypes from 'prop-types'

class TvShowSections extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <div className="btsp_form_content grey_border" id="tv_show_setup"><ul>{ this.props.children.filter( (child) => typeof child === 'object' ).map( (child, index) => <li key={index} className="form_row"><Col sm={ 3 } lg={ 2 } className="label_names hidden-xs"><div className="section_name">{ child.props.name }</div></Col><Col xs={ 12 } sm={ 9 } lg={ 10 }>{ child }</Col></li>) }</ul></div>
  }
}

TvShowSections.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
}

export default TvShowSections
