export const getStatDataByKey = (statKey, distanceUnit) => {
  switch (statKey){
    case 'fairway_hit':
      return {
        key: 'fairway_hit',
        name: window.I18n.t('pga_rankings.statistic_components_titles.fairway_hit'),
        columns: [
          window.I18n.t('pga_rankings.statistic_components_columns.rank'),
          window.I18n.t('pga_rankings.statistic_components_columns.player'),
          window.I18n.t('pga_rankings.statistic_components_columns.percentage'),
        ],
      }
    case 'gir':
      return {
        key: 'gir',
        name: window.I18n.t('pga_rankings.statistic_components_titles.gir'),
        columns: [
          window.I18n.t('pga_rankings.statistic_components_columns.rank'),
          window.I18n.t('pga_rankings.statistic_components_columns.player'),
          window.I18n.t('pga_rankings.statistic_components_columns.percentage'),
        ],
      }
    case 'scramble':
      return {
        key: 'scramble',
        name: window.I18n.t('pga_rankings.statistic_components_titles.scramble'),
        columns: [
          window.I18n.t('pga_rankings.statistic_components_columns.rank'),
          window.I18n.t('pga_rankings.statistic_components_columns.player'),
          window.I18n.t('pga_rankings.statistic_components_columns.percentage'),
        ],
      }
    case 'sand_save':
      return {
        key: 'sand_save',
        name: window.I18n.t('pga_rankings.statistic_components_titles.sand_save'),
        columns: [
          window.I18n.t('pga_rankings.statistic_components_columns.rank'),
          window.I18n.t('pga_rankings.statistic_components_columns.player'),
          window.I18n.t('pga_rankings.statistic_components_columns.percentage'),
        ],
      }
    case 'putts_per_gir':
      return {
        key: 'putts_per_gir',
        name: window.I18n.t('pga_rankings.statistic_components_titles.putts_per_gir'),
        columns: [
          window.I18n.t('pga_rankings.statistic_components_columns.rank'),
          window.I18n.t('pga_rankings.statistic_components_columns.player'),
          window.I18n.t('pga_rankings.statistic_components_columns.average'),
        ],
      }
    case 'putts':
      return {
        key: 'putts',
        name: window.I18n.t('pga_rankings.statistic_components_titles.putts'),
        columns: [
          window.I18n.t('pga_rankings.statistic_components_columns.rank'),
          window.I18n.t('pga_rankings.statistic_components_columns.player'),
          window.I18n.t('pga_rankings.statistic_components_columns.putts_hole'),
        ],
      }
    case 'birdies_b':
      return {
        key: 'birdies_b',
        name: window.I18n.t('pga_rankings.statistic_components_titles.birdies_b'),
        columns: [
          window.I18n.t('pga_rankings.statistic_components_columns.rank'),
          window.I18n.t('pga_rankings.statistic_components_columns.player'),
          window.I18n.t('pga_rankings.statistic_components_columns.birdies_hole'),
        ],
      }
    case '1_putts':
      return {
        key: '1_putts',
        name: window.I18n.t('pga_rankings.statistic_components_titles.1_putts'),
        columns: [
          window.I18n.t('pga_rankings.statistic_components_columns.rank'),
          window.I18n.t('pga_rankings.statistic_components_columns.player'),
          window.I18n.t('pga_rankings.statistic_components_columns.1_putts_hole'),
        ],
      }
    case 'driving_distances':
      return {
        key: 'driving_distances',
        name: window.I18n.t('pga_rankings.statistic_components_titles.driving_distances'),
        columns: [
          window.I18n.t('pga_rankings.statistic_components_columns.rank'),
          window.I18n.t('pga_rankings.statistic_components_columns.player'),
          distanceUnit,
        ],
      }
    default:
      return {
        key: '',
        name: '',
        columns: [],
      }
  }
}

export const computeDisplayedStatistic = (statKey, statistic) => {
  if ( (typeof statistic.nominator === 'undefined' || typeof statistic.denominator === 'undefined' || statistic.denominator === 0) && statKey !== 'driving_distances'){
    return 'N/A'
  }
  switch (statKey){
    case 'putts_per_gir':{
      const fraction = statistic.nominator / statistic.denominator
      return `${statistic.nominator}/${statistic.denominator} - ${fraction.toFixed(2)}`
    }
    case 'putts':{
      const fraction = statistic.nominator / statistic.denominator
      return `${statistic.nominator}/${statistic.denominator} - ${fraction.toFixed(2)}`
    }
    case 'driving_distances':{
      return (statistic.as_number).toFixed(2)
    }
    default:{
      const fraction = (statistic.nominator / statistic.denominator) * 100
      return `${statistic.nominator}/${statistic.denominator} - ${fraction.toFixed(2)}%`
    }
  }
}
