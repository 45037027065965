import React, { useState, useCallback, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'
import Cropper from 'react-easy-crop'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-body {
    height: 670px;
  }

  .modal-dialog {
    width: 60%;
  }
  
  .crop-container {
    position: relative;
    height: 500px;
  }

`

const ThumbnailsUpload = (props) => {
  return (
    <Fragment>
      <div className='add-margin-bottom-15 btsp_bullet_button pointer' onClick={() => props.setModalOpen(true)}>
        <div className='icon'><i className='gga-circle-plus'></i></div>
        <div className='btsp_align-middle'>Upload</div>
      </div>
    </Fragment>
  )
}

export const ThumbnailsUploadPopup = (props) => {
  const [ imagePreview, setImagePreview ] = useState({ image: '', crop: ''})
  const [ disableImageSave, sedDisableImageSave ] = useState(true)
  const [ crop, setCrop ] = useState({ x: 0, y: 0 })
  const [ zoom, setZoom ] = useState(1)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    if (imagePreview.image) {
      setImagePreview({image: imagePreview.image, imageFile: imagePreview.imageFile, crop: croppedAreaPixels})
    }
  }, [ imagePreview.image ])

  const handleImageUpload = (e) => {
    const file = e.target.files[0]
    setImagePreview({image: URL.createObjectURL(file), imageFile: file, crop: ''})

    let message = ''
    if (file.size > 6000000) { // bytes
      message = 'File exceeds maximum size.'
    }
    
    if ([ 'image/jpeg', 'image/png', 'image/gif', 'image/bmp' ].indexOf(file.type) < 0) {
      message += ' File format should be jpg, jpeg, gif, png, bmp.'
    }

    if (message !== '') {
      $('.error-message').removeClass('hidden')
      $('.error-message').html(message)
      sedDisableImageSave(true)

    } else {
      $('.error-message').addClass('hidden').disabled = false
      sedDisableImageSave(false)
    }
  }

  const handleModalClose = () => {
    props.setModalOpen(false)
    setImagePreview({ image: '', crop: ''})
  }

  const saveImage = () => {
    props.setNewThumbnail(imagePreview)
    handleModalClose()
  }

  return (
    <StyledModal show={props.modalOpen} onHide={() => handleModalClose()}>
      <Modal.Header closeButton>
        Add Thumbnail Photo
      </Modal.Header>
      <Modal.Body>
        <input className='add-margin-bottom-10' type='file' accept='image/jpeg, image/png, image/gif, image/bmp' onChange={(e) => handleImageUpload(e)}/>
        <div className='help-message add-margin-bottom-10'>
          <div>{window.I18n.t('views.golfhub.accepted_photo_formats')}</div>
          <div>{window.I18n.t('views.golfhub.thumbnail_acceptable_format')}</div>
        </div>
        <div className='error-message btsp_alert_notices hidden add-margin-bottom-10 add-padding-all-5'></div>
        <div className='crop-container'>
          <Cropper
            image={imagePreview.image}
            crop={crop}
            zoom={zoom}
            aspect={16 / 9}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handleModalClose()}>{window.I18n.t('views.golfhub.modal.close')}</Button>
        <Button className='add-margin-left-10 button btsp_orange_button' disabled={!imagePreview.image || disableImageSave} onClick={() => saveImage()} >{window.I18n.t('views.golfhub.modal.save')}</Button>
      </Modal.Footer>
    </StyledModal>
  )
}

ThumbnailsUpload.propTypes = {
  setModalOpen: PropTypes.func,
}

ThumbnailsUploadPopup.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  setNewThumbnail: PropTypes.func,
}

export default ThumbnailsUpload
