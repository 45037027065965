export const changeTeamColumnDisplay = (tableToDisplay) => {
  const teamDiv = $(tableToDisplay).find('table.result_scope:not(.ryder) td.name div.team')
  const teamAffiliationDiv = $(tableToDisplay).find('table.result_scope:not(.ryder) td.name div.affiliation.team')
  const playerDiv = $(tableToDisplay).find('table.result_scope:not(.ryder) td.name div.player')
  if (teamDiv.length > 0) {
    playerDiv.addClass('hidden')
    teamDiv.removeClass('hidden')
    teamAffiliationDiv.removeClass('hidden')
    alignFlagsUsga(tableToDisplay)
  }
}

export const removeAMPMFromTime = (tableToDisplay) => {
  let thruDivHeaders = $(tableToDisplay).find('table.result_scope:not(.ryder) th.thru')
  thruDivHeaders = thruDivHeaders.add($(tableToDisplay).find('table.result_scope:not(.ryder) th.past_round_thru'))
  let thruDivs
  if ($(tableToDisplay).find('td.past_round_thru .score_to_print_abbv').length > 0 || $(tableToDisplay).find('td.thru .score_to_print_abbv').length > 0) {
    thruDivs = $(tableToDisplay).find($(tableToDisplay).find('table.result_scope:not(.ryder) td.thru .score_to_print_abbv'))
    thruDivs = thruDivs.add('table.result_scope:not(.ryder) td.past_round_thru .score_to_print_abbv')
  } else {
    thruDivs = $(tableToDisplay).find('table.result_scope:not(.ryder) td.thru')
    thruDivs = thruDivs.add($(tableToDisplay).find('table.result_scope:not(.ryder) td.past_round_thru'))
  }

  let AMPresent = false
  let PMPresent = false
  for (let i = 0; i < thruDivs.length; i++) {
    const thruText = $(thruDivs[i]).text()
    if (thruText.match(/(AM|am)/g)) {
      AMPresent = true
    }
    if (thruText.match(/(PM|pm)/g)) {
      PMPresent = true
    }
  }

  if (AMPresent && PMPresent) {
    $(thruDivHeaders).css('min-width', '5em')
  } else {
    for (let i = 0; i < thruDivs.length; i++) {
      const thruText = $(thruDivs[i]).text()
      $(thruDivs[i]).text(thruText.replace(/(AM|PM|am|pm)/, ''))
    }
  }
  return AMPresent && PMPresent
}

export const getMaxAbbrevationLength = (tableToDisplay) => {
  let maxAbbrevationLength = 0
  if ($(tableToDisplay).find('td.past_round_thru .tv_score_and_abbrevation').length > 0) {
    const abbrevationLengths = $(tableToDisplay).find('td.past_round_thru .tv_score_and_abbrevation span.course_abbreviation')
    .map(function() {
      return $(this).text()
      .trim().length
    })
    .get()
    maxAbbrevationLength = Math.max(...abbrevationLengths)
  }
  return maxAbbrevationLength
}

export const adjustColWidth = (containerSelector, sliderFontSize, bothAMPM, maxAbbrevationLength, applyUSGATheme, matchPlay) => {
  if (!applyUSGATheme || matchPlay) {
    return
  }

  let thruColumnHeaders = $(`${containerSelector} table:not(.ryder) th.thru`)
  thruColumnHeaders = thruColumnHeaders.add($(`${containerSelector} table:not(.ryder) th.past_round_thru`))

  // Set the average number of pixels per character
  const pixelsPerCharTimestamp = sliderFontSize / 1.5
  const pixelsPerCharDefault = sliderFontSize
  const pixelsPerCharAbbv = 14

  // get the width of the table (will be used to calculate relative percentages for column widths)
  const tableWidthPixels = $(`${containerSelector} table.result_scope:not(.ryder)`).width()

  // Set the width of the score columns (max 3 characters: "123")
  const scoreColumnsWidthPixels = pixelsPerCharDefault * 3
  const scoreColumnsWidthPercent = scoreColumnsWidthPixels / tableWidthPixels * 100
  $(`${containerSelector} table:not(.ryder) th:not(.name, .pos, .thru, .past_round_thru)`).css('width', scoreColumnsWidthPercent + '%')

  // Set the width of the thru columns
  const numCharsTimestamp = bothAMPM ? '00:00 AM*'.length : '00:00*'.length
  const thruColumnWidthPixels = numCharsTimestamp * pixelsPerCharTimestamp + maxAbbrevationLength * pixelsPerCharAbbv
  const thruColumnWidthPercent = thruColumnWidthPixels / tableWidthPixels * 100
  thruColumnHeaders.css('width', thruColumnWidthPercent + '%')
  
  // If there is an abbreviation, allocate the necessary width for it
  if (maxAbbrevationLength > 0) {
    const scoreToPrintWidthPixels = numCharsTimestamp * pixelsPerCharTimestamp
    const scoreToPrintWidthPercent = scoreToPrintWidthPixels / thruColumnWidthPixels * 100
    $(`${containerSelector} table.result_scope:not(.ryder) td.past_round_thru .tv_score_and_abbrevation .score_to_print_abbv`).css('width', scoreToPrintWidthPercent + '%')
    $(`${containerSelector} table.result_scope:not(.ryder) td.thru .tv_score_and_abbrevation .score_to_print_abbv`).css('width', scoreToPrintWidthPercent + '%')

    const abbrevationWidthPixels = maxAbbrevationLength * pixelsPerCharAbbv
    const abbrevationWidthPercent = abbrevationWidthPixels / thruColumnWidthPixels * 100
    $(`${containerSelector} table.result_scope:not(.ryder) td.past_round_thru .tv_score_and_abbrevation .col_course_abbreviation`).css('width', abbrevationWidthPercent + '%')
    $(`${containerSelector} table.result_scope:not(.ryder) td.thru .tv_score_and_abbrevation .col_course_abbreviation`).css('width', abbrevationWidthPercent + '%')
  }

  // Set the width of the pos column (max 3 characters: "DNF")
  const posColumnWidthPercent = pixelsPerCharDefault * 3 / tableWidthPixels * 100 
  $(`${containerSelector} table:not(.ryder) th.pos`).css('width', posColumnWidthPercent + '%')

  // Set the width of the name column
  const noScoreColumns = $(`${containerSelector} table.result_scope:not(.ryder) th:not(.name, .pos, .thru, .past_round_thru)`).length
  const noThruColumns = thruColumnHeaders.length
  const nameColumnWidthPercent = Math.max(10, 100 - scoreColumnsWidthPercent * noScoreColumns - thruColumnWidthPercent * noThruColumns - posColumnWidthPercent)
  $(`${containerSelector} table.result_scope:not(.ryder) th.name`).css('width', nameColumnWidthPercent + '%')

  // Make sure the header text has enough space
  $(`${containerSelector} table.result_scope:not(.ryder) th.past_round_to_par`).each(function() {
    const headerText = $(this).text()
    const numCharHeaderText = headerText.length
    const headerWidthPixels = numCharHeaderText * 10
    const headerWidthPercent = headerWidthPixels / tableWidthPixels * 100
    if (headerWidthPixels > scoreColumnsWidthPixels) {
      $(this).css('width', headerWidthPercent + '%')
    }
  })
}

const alignFlagsUsga = (tableToDisplay) => {
  const countryFlags = $(tableToDisplay).find('table.result_scope:not(.ryder) td.name .flag-icon')
  const teamLogos = $(tableToDisplay).find('table.result_scope:not(.ryder) td.name .image-wrapper .div-image .image')
  countryFlags.css('vertical-align', 'baseline')
  teamLogos.css('vertical-align', 'text-top')

  const affiliation = $(tableToDisplay)
    .find('table.result_scope:not(.ryder) td.name .affiliation')
    .text()
  if (affiliation.trim() === '') {
    const stateFlags = $(tableToDisplay).find('table.result_scope:not(.ryder) td.name .flags svg')
    stateFlags.css('vertical-align', 'baseline')
  }
}

export const adjustCutListColspan = (tableToDisplay) => {
  const rows = $(tableToDisplay).find('tr.aggregate-row')
  if (rows.length === 0) {
    return
  }  

  const noOfColumns = $(rows[0]).children('td').length
  if (noOfColumns > 0) {
    $(tableToDisplay)
      .find('td.cut_list_tr, tr.total_purse_allocated td, tr.total_points_allocated td, td.section_list_tr')
      .attr('colSpan', noOfColumns)
  }
}
