import React, { Component } from 'react'
import PropTypes from 'prop-types'

class EmailAndTexts extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { title, modes, links, selected, texts, emails } = this.props.content

    return (
      <div className='widget-content-default widget-content-email-and-texts'>
        <div className='title'>
          { title }

          {
            texts.permisson
            ? <div className='btn-group' role='group'>
              <button
                type='button'
                className={ `btn btn-default ${selected === 'emails' ? 'active' : ''}` }
                onClick={ () => this.props.selectEmailsOrTexts('emails') }>
                { modes.emails }
              </button>
              <button
                type='button'
                className={ `btn btn-default ${selected === 'texts' ? 'active' : ''}` }
                onClick={ () => this.props.selectEmailsOrTexts('texts') }>
                { modes.texts }
              </button>
            </div>
            : null
          }
        </div>
        <div className='content'>
          { selected === 'texts' ?
            <div className='texts'>
              <div className='buttons-container'>
                <a href={ links.manage_text_messages.path } className='btn btn-primary top-buttons wide-button'>{ links.manage_text_messages.name }</a>
                <a href={ links.compose_text.path } className='btn btn-primary top-buttons'>{ links.compose_text.name }</a>
                <a href={ links.text_delivery_report.path } className='btn btn-primary top-buttons'>{ links.text_delivery_report.name }</a>
              </div>
              <div className={ `texts-container ${texts.data.length === 0 ? 'stylize' : ''}` }>
                {
                  texts.data.length === 0
                    ? texts.missing_text
                    : <table className='table table-striped'>
                        <thead>
                          <tr className='header'>
                            { texts.header.map( headerText => <th key={ headerText }>{ headerText }</th>) }
                          </tr>
                        </thead>
                        <tbody>
                          {
                            texts.data.map( text =>
                              <tr key={ text.sent_at }>
                                <td>{ text.description }</td>
                                <td>{ text.recipients }</td>
                                <td>{ text.sent_at }</td>
                                <td>{ text.actions.map( action => <a key={ action.path } href={ action.path }>{ action.name }</a>) }</td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                }
              </div>
            </div> : null
          }
          { selected === 'emails' ?
            <div className='emails'>
              <div className='buttons-container'>
                <a href={ links.manage_emails.path } className='btn btn-primary top-buttons'>{ links.manage_emails.name }</a>
                <a href={ links.compose_email.path } className='btn btn-primary top-buttons'>{ links.compose_email.name }</a>
                <a href={ links.email_delivery_report.path } className='btn btn-primary top-buttons'>{ links.email_delivery_report.name }</a>
                <a href={ links.email_configurations.path } className='btn btn-primary top-buttons'>{ links.email_configurations.name }</a>
              </div>
              <div className={ `emails-container ${emails.data.length === 0 ? 'stylize' : ''}` }>
                {
                  emails.data.length === 0
                    ? emails.missing_text
                    : <table className='table table-striped'>
                        <thead>
                          <tr className='header'>
                            { emails.header.map( headerText => <th key={ headerText }>{ headerText }</th>) }
                          </tr>
                        </thead>
                        <tbody>
                          {
                            emails.data.map( email =>
                              <tr key={ email.sent_at }>
                                <td><a href={ email.subject.path }>{ email.subject.name }</a></td>
                                <td>{ email.recipients }</td>
                                <td>{ email.sent_at }</td>
                                {/* <td>{ email.actions }</td> */}
                                {/* <td>{ email.tournaments_info.map( tournamentInfo => <p key={ tournamentInfo }>{ tournamentInfo }</p>) }</td> */}
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                }
              </div>
            </div> : null
          }
        </div>
      </div>
    )
  }
}

EmailAndTexts.propTypes = { 
  content: PropTypes.object,
  selectEmailsOrTexts: PropTypes.func,
}


EmailAndTexts.defaultProps = {
  content: {
    title: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.title'),
    modes: {
      texts: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.texts'),
      emails: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.emails'),
    },
    links: {
      manage_text_messages: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.manage_text_messages'),
        path: '#',
      },
      compose_text: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.compose_text_messages'),
        path: '#',
      },
      text_delivery_report: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.text_messages_delivery_report'),
        path: '#',
      },
      email_configurations: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.email_configurations'),
        path: '#',
      },
      manage_emails: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.manage_emails'),
        path: '#',
      },
      compose_email: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.compose_email'),
        path: '#',
      },
      email_delivery_report: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.email_delivery_report'),
        path: '#',
      },
    },
    selected: 'emails',
    texts: {
      header: [ !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.description'), !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.recipients'), !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.sent_at'), !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.actions') ],
      missing: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.no_sent_messages'),
      data: [],
    },
    emails: {
      header: [ !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.subject'), !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.recipients'), !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.sent_at'), !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.actions') ],
      missing: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.email_and_texts.no_emails_sent'),
      data: [],
    },
    show_widget: true,
  },
}

export default EmailAndTexts
