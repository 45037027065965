import { combineReducers } from 'redux'

import statistics from './statistics'
import misc from './misc'
import popup from './popup'

export default combineReducers({
  statistics,
  misc,
  popup,
})
