import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RadioGroup from './event_setup/radio_group'
import { GlgSelect } from 'SharedComponents'
import InputForm from './event_setup/input_form'


function GenderedHandicapIndex({label, value, setOption, parentObject}) {

  function completeForm(index, gender) {
    setOption[gender](index)
    parentObject.setState({dataChanged: true})
  }

  return (
    <div className='gendered_hi_container'>
      <label>{label}</label>
      <div className='mf_values_container'>
        <div>
          <label>M</label>
          <InputForm
                  id={`${label}_M`}
                  value={value['M'] || ''}
                  onEditCompleted={(index) => index !== value['M'] && completeForm(index, 'M')}
                />
        </div>
        <div>
          <label>F</label>
          <InputForm
                  id={`${label}_F`}
                  value={value['F'] || ''}
                  onEditCompleted={(index) => index !== value['F'] && completeForm(index, 'F')}
                />
        </div>
      </div>
    </div>
  )
}

GenderedHandicapIndex.propTypes = {
  label: PropTypes.string,
  value: PropTypes.object,
  setOption: PropTypes.object,
  parentObject: PropTypes.object,
}

class EventSetupHandicaps extends Component {
  constructor(props){
    super(props)
    this.state = {
      dataChanged: false,
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.content.data !== prevProps.content.data && this.props.content.dataChanged) || this.state.dataChanged) {
      this.props.save()
      this.setState({dataChanged: false})
    }
  }


  render() {
    const { title, data } = this.props.content
    const setter = (keys) => (value) => this.props.setData([ 'data' ].concat(keys), value)

    const badPeoriaConfiguration = data.day_handicap.selected === 'yes' && data.day_handicap_method.value === 'peoria' && (! data.peoria_holes.holes.some(h => h === 1))

    return (
      <div className='widget-content-default widget-event-setup-handicaps'>
        <div className='title'>
          { title }
        </div>

        <div className='content flex-list-layout'>
          <ul>
            <li key='card_type'>
              <RadioGroup
                id='card_type'
                {...data['card_type']}
                setOption={ setter([ 'card_type', 'selected' ])}
                vertical={true}
              />
            </li>

            <li key='maximum_handicap_indexes_title'>
              <label>{data['maximum_handicap_indexes']['title']}</label>
              <br />
              <label className='font-weight-normal'>{data['maximum_handicap_indexes']['explanation']}</label>
              <br />
            </li>

            <li key='maximum_handicap_index_18_hole'>
              <GenderedHandicapIndex
                {...data['maximum_handicap_indexes']['eighteen_hole']['handicap_index']}
                setOption={{
                  'M': setter([ 'maximum_handicap_indexes', 'eighteen_hole', 'handicap_index', 'value', 'M' ]),
                  'F': setter([ 'maximum_handicap_indexes', 'eighteen_hole', 'handicap_index', 'value', 'F' ]),
                }}
                parentObject={this}
              />
            </li>
            <li key='maximum_course_index_18_hole'>
              <GenderedHandicapIndex
                {...data['maximum_handicap_indexes']['eighteen_hole']['course_handicap']}
                setOption={{
                  'M': setter([ 'maximum_handicap_indexes', 'eighteen_hole', 'course_handicap', 'value', 'M' ]),
                  'F': setter([ 'maximum_handicap_indexes', 'eighteen_hole', 'course_handicap', 'value', 'F' ]),
                }}
                parentObject={this}
              />
            </li>

            {
              data['card_type'].selected === '9 hole' &&
                <>
                  <li key='maximum_handicap_index_9_hole'>
                    <GenderedHandicapIndex
                      {...data['maximum_handicap_indexes']['nine_hole']['handicap_index']}
                      setOption={{
                        'M': setter([ 'maximum_handicap_indexes', 'nine_hole', 'handicap_index', 'value', 'M' ]),
                        'F': setter([ 'maximum_handicap_indexes', 'nine_hole', 'handicap_index', 'value', 'F' ]),
                      }}
                      parentObject={this}
                    />
                  </li>
                  <li key='maximum_course_index_9_hole'>
                    <GenderedHandicapIndex
                      {...data['maximum_handicap_indexes']['nine_hole']['course_handicap']}
                      setOption={{
                        'M': setter([ 'maximum_handicap_indexes', 'nine_hole', 'course_handicap', 'value', 'M' ]),
                        'F': setter([ 'maximum_handicap_indexes', 'nine_hole', 'course_handicap', 'value', 'F' ]),
                      }}
                      parentObject={this}
                    />
                  </li>
                </>
            }

            <br/>
            <li key='allocate_by_subset'>
              <RadioGroup
                id='allocate_by_subset'
                {...data['allocate_by_subset']}
                setOption={ setter([ 'allocate_by_subset', 'selected' ])}
                vertical={true}
              />
            </li>

            <li key='day_handicap'>
              <RadioGroup
                id='day_handicap'
                {...data['day_handicap']}
                setOption={ setter([ 'day_handicap', 'selected' ])}
                vertical={true}
              />
            </li>

            {
              data.day_handicap.selected === 'yes' &&
                <li key='day_handicap_method'>
                  <label>{ data.day_handicap_method.label }</label>
                  <div className='day_handicap_method'>
                    <GlgSelect
                      items={ data.day_handicap_method.options }
                      value={ data.day_handicap_method.value }
                      onChange={ setter([ 'day_handicap_method', 'value' ])}
                      usePortal={ true }
                      menuShouldBlockScroll={ true }
                    />
                  </div>
                </li>
            }

            {
              data.day_handicap.selected === 'yes' && data.day_handicap_method.value === 'peoria' &&
                <li key='peoria_holes' id='handicap_holes'>
                  <div className='peoria_hint'>
                    { data.peoria_holes.label }
                  </div>

                  {
                    (holes => {
                      const zip = (a, b) => a.map((v, i) => [ v, b[i] ])
                      holes = holes.map((v, i) => ({ value: v, index: i }))
                      const pairedHoles = zip(holes.slice(0, holes.length / 2), holes.slice(holes.length / 2))

                      return (
                        <div className='peoria_holes_container'>
                          {
                            pairedHoles.map(([ h1, h2 ]) =>
                              <div key={`holes_pair_${h1.index + 1}_${h2.index + 1}`} className='holes_pair'>
                                <label className='icheck-label' htmlFor={`peoria_hole_${h1.index}`}>
                                  <div className={`icheckbox_square-orange icheck-item ${h1.value === 1 ? 'checked' : ''}`}>
                                    <input
                                      id={`peoria_hole_${h1.index}`}
                                      className='i-checks icheck-input'
                                      type="checkbox"
                                      value={h1.value === 1}
                                      checked={h1.value === 1}
                                      onChange={event => setter([ 'peoria_holes', 'holes', h1.index ])(event.target.checked ? 1 : 0)}
                                    />
                                  </div>
                                  <span> { h1.index + 1 } </span>
                                </label>

                                <label className='icheck-label' htmlFor={`peoria_hole_${h2.index}`}>
                                  <div className={`icheckbox_square-orange icheck-item ${h2.value === 1 ? 'checked' : ''}`}>
                                    <input
                                      id={`peoria_hole_${h2.index}`}
                                      className='i-checks icheck-input'
                                      type="checkbox"
                                      value={h2.value === 1}
                                      checked={h2.value === 1}
                                      onChange={event => setter([ 'peoria_holes', 'holes', h2.index ])(event.target.checked ? 1 : 0)}
                                    />
                                  </div>
                                  <span> { h2.index + 1 } </span>
                                </label>
                              </div>
                            )
                          }
                        </div>
                      )
                    })(data.peoria_holes.holes)
                  }

                  {
                    badPeoriaConfiguration &&
                      <label className='peoria_hint warning'>
                        { data.peoria_holes.warning_message }
                      </label>
                  }
                </li>
            }
          </ul>
        </div>

      </div>
    )
  }
}

EventSetupHandicaps.propTypes = {
  content: PropTypes.object,
  setData: PropTypes.func,
  save: PropTypes.func,
}

EventSetupHandicaps.defaultProps = {
  content: {
    title: 'HANDICAPS',
    data: {},
  },
}

export default EventSetupHandicaps
