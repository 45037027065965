import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import MenuItem from './menu_item'

class MenuRoundItems extends Component {
  constructor(props){
    super(props)
  }

  render() {
    return (
      <Fragment>
        <MenuItem icon={ this.props.icon }
                  url={ this.props.newRound.path }
                  label={ this.props.newRound.name }
                  active={ false } />
        <div className='dashbord-menu-rounds-container'>
            {
              this.props.roundItems.map( (menuItem, index ) =>
                <Fragment key={ menuItem.id }>
                  <div className={ `dashboard-menu-round-item-marker ${this.props.selectedMenuItem === menuItem.id ? 'active' : ''}` }>
                    { this.props.roundItems.length > 1 ? (this.props.selectedMenuItem === menuItem.id ? '◉' : '●') : null}
                  </div>
                  { index !== 0 ? <div className='dashboard-menu-round-item-line'></div> : null }
                  <MenuItem url={ menuItem.url }
                            label={ menuItem.label }
                            title={ menuItem.title }
                            active={ this.props.selectedMenuItem === menuItem.id }
                            selectMenuItem={ () => this.props.selectMenuItem(menuItem.id, menuItem.widgetPath, menuItem.lastViewedPagePath) }
                            type='dashboard-menu-round-items' />
                </Fragment>)
            }
        </div>
      </Fragment>
    )
  }
}

MenuRoundItems.propTypes = {
  roundItems: PropTypes.array,
  newRound: PropTypes.object,
  icon: PropTypes.string,
  selectedMenuItem: PropTypes.string,
  selectMenuItem: PropTypes.func,
  openNewItemsModal: PropTypes.func,
  newItemLabel: PropTypes.string,
}

export default MenuRoundItems
