import { showErrorNotification, callAPI, showNotification } from 'Shared/helpers'
import { pluralizeGolfers } from '../helpers'
import _ from 'lodash'

export const ActionTypes = {
  FETCH_MEMBERS_DATA_REQUEST: 'FETCH_MEMBERS_DATA_REQUEST',
  FETCH_MEMBERS_DATA_RECEIVED: 'FETCH_MEMBERS_DATA_RECEIVED',
  FETCH_INITIAL_MEMBERS_DATA_REQUEST: 'FETCH_INITIAL_MEMBERS_DATA_REQUEST',
  FETCH_INITIAL_MEMBERS_DATA_RECEIVED: 'FETCH_INITIAL_MEMBERS_DATA_RECEIVED',
  FETCH_FIELDS_DATA_REQUEST: 'FETCH_FIELDS_DATA_REQUEST',
  FETCH_FIELDS_DATA_RECEIVED: 'FETCH_FIELDS_DATA_RECEIVED',
  OPEN_FIELDS_SIDEBAR: 'OPEN_FIELDS_SIDEBAR',
  CLOSE_FIELDS_SIDEBAR: 'CLOSE_FIELDS_SIDEBAR',
  OPEN_HANDLE_FORMAT_SIDEBAR: 'OPEN_HANDLE_FORMAT_SIDEBAR',
  CLOSE_HANDLE_FORMAT_SIDEBAR: 'CLOSE_HANDLE_FORMAT_SIDEBAR',
  SET_MEMBER_COLUMNS_REQUEST: 'SET_MEMBER_COLUMNS_REQUEST',
  SET_MEMBER_COLUMNS_RECEIVED: 'SET_MEMBER_COLUMNS_RECEIVED',
  CHECK_MEMBER_FIELD: 'CHECK_MEMBER_FIELD',
  UNCHECK_MEMBER_FIELD: 'UNCHECK_MEMBER_FIELD',
  SWAP_FIELDS: 'SWAP_FIELDS',
  SET_SIDEBAR_ICONS: 'SET_SIDEBAR_ICONS',
  CLEAR_CELL_MEASURER_CACHE: 'CLEAR_CELL_MEASURER_CACHE',
  CHANGE_MEMBERS_FILTER: 'CHANGE_MEMBERS_FILTER',
  OPEN_ADD_GOLFERS_MANUALLY_POPUP: 'OPEN_ADD_GOLFERS_MANUALLY_POPUP',
  CLOSE_ADD_GOLFERS_MANUALLY_POPUP: 'CLOSE_ADD_GOLFERS_MANUALLY_POPUP',
  OPEN_EDIT_CUSTOM_FIELDS_POPUP: 'OPEN_EDIT_CUSTOM_FIELDS_POPUP',
  CLOSE_EDIT_CUSTOM_FIELDS_POPUP: 'CLOSE_EDIT_CUSTOM_FIELDS_POPUP',
  FETCH_LEAGUE_METADATA_REQUEST: 'FETCH_LEAGUE_METADATA_REQUEST',
  FETCH_LEAGUE_METADATA_RECEIVED: 'FETCH_LEAGUE_METADATA_RECEIVED',
  NEW_MEMBER_FIELD_CHANGED: 'NEW_MEMBER_FIELD_CHANGED',
  ADD_MORE_GOLFERS: 'ADD_MORE_GOLFERS',
  CURRENT_CUSTOM_FIELDS_CHANGED: 'CURRENT_CUSTOM_FIELDS_CHANGED',
  ADD_NEW_MEMBERS_REQUEST: 'ADD_NEW_MEMBERS_REQUEST',
  ADD_NEW_MEMBERS_RECEIVED: 'ADD_NEW_MEMBERS_RECEIVED',
  FETCH_MEMBERS_DATA_RECEIVED_WITH_MEMBER_IDS: 'FETCH_MEMBERS_DATA_RECEIVED_WITH_MEMBER_IDS',
  GET_MEMBERS_COUNT_REQUEST: 'GET_MEMBERS_COUNT_REQUEST',
  GET_MEMBERS_COUNT_RECEIVED: 'GET_MEMBERS_COUNT_RECEIVED',
  GET_FILTER_OPTIONS_REQUEST: 'GET_FILTER_OPTIONS_REQUEST',
  GET_FILTER_OPTIONS_RECEIVED: 'GET_FILTER_OPTIONS_RECEIVED',
  CHANGE_FILTER_OPTIONS: 'CHANGE_FILTER_OPTIONS',
  OPEN_UPLOAD_SPREADSHEET_SIDEBAR: 'OPEN_UPLOAD_SPREADSHEET_SIDEBAR',
  CLOSE_UPLOAD_SPREADSHEET_SIDEBAR: 'CLOSE_UPLOAD_SPREADSHEET_SIDEBAR',
  TOGGLE_TERMS_AND_PRIVACY: 'TOGGLE_TERMS_AND_PRIVACY',
  DELETE_MEMBER_REQUEST: 'DELETE_MEMBER_REQUEST',
  DELETE_MEMBER_RECEIVED: 'DELETE_MEMBER_RECEIVED',
  OPEN_LEAGUE_PROPAGATION_POPUP: 'OPEN_LEAGUE_PROPAGATION_POPUP',
  CLOSE_LEAGUE_PROPAGATION_POPUP: 'CLOSE_LEAGUE_PROPAGATION_POPUP',
  GET_LEAGUES_PROPAGATION_REQUEST: 'GET_LEAGUES_PROPAGATION_REQUEST',
  GET_LEAGUES_PROPAGATION_RECEIVED: 'GET_LEAGUES_PROPAGATION_RECEIVED',
  PROPAGATE_LEAGUES_REQUEST: 'PROPAGATE_LEAGUES_REQUEST',
  PROPAGATE_LEAGUES_RECEIVED: 'PROPAGATE_LEAGUES_RECEIVED',
  OPEN_DELETE_ROSTER_CONFIRMATION_POPUP: 'OPEN_DELETE_ROSTER_CONFIRMATION_POPUP',
  CLOSE_DELETE_ROSTER_CONFIRMATION_POPUP: 'CLOSE_DELETE_ROSTER_CONFIRMATION_POPUP',
  GET_ANSWER_REQUEST: 'GET_ANSWER_REQUEST',
  GET_ANSWER_RECEIVED: 'GET_ANSWER_RECEIVED',
  DELETE_ROSTER_REQUEST: 'DELETE_ROSTER_REQUEST',
  DELETE_ROSTER_RECEIVED: 'DELETE_ROSTER_RECEIVED',
  UPLOAD_ROSTER_SPREADSHEET_REQUEST: 'UPLOAD_ROSTER_SPREADSHEET_REQUEST',
  REGISTER_MEMBER_REQUEST: 'REGISTER_MEMBER_REQUEST',
  REGISTER_MEMBER_RECEIVED: 'REGISTER_MEMBER_RECEIVED',
  OPEN_REGISTER_MEMBER_POPUP: 'OPEN_REGISTER_MEMBER_POPUP',
  CLOSE_REGISTER_MEMBER_POPUP: 'CLOSE_REGISTER_MEMBER_POPUP',
  GET_REGISTER_MEMBER_DATA_REQUEST: 'GET_REGISTER_MEMBER_DATA_REQUEST',
  GET_REGISTER_MEMBER_DATA_RECEIVED: 'GET_REGISTER_MEMBER_DATA_RECEIVED',
  UNREGISTER_MEMBER_REQUEST: 'UNREGISTER_MEMBER_REQUEST',
  UNREGISTER_MEMBER_RECEIVED: 'UNREGISTER_MEMBER_RECEIVED',
  CONFIRM_MEMBER_REQUEST: 'CONFIRM_MEMBER_REQUEST',
  CONFIRM_MEMBER_RECEIVED: 'CONFIRM_MEMBER_RECEIVED',
  UPDATE_MEMBER_REGISTRATION: 'UPDATE_MEMBER_REGISTRATION',
  TOGGLE_ACTIONS_DROPDOWN: 'TOGGLE_ACTIONS_DROPDOWN',
  EDIT_MEMBER_REQUEST: 'EDIT_MEMBER_REQUEST',
  EDIT_MEMBER_RECEIVED: 'EDIT_MEMBER_RECEIVED',
  UPDATE_GRID_WIDTH: 'UPDATE_GRID_WIDTH',
  UPDATE_CONTAINER_WIDTH: 'UPDATE_CONTAINER_WIDTH',
  TOGGLE_MODAL_CHANGE_HADNICAP_INDEX: 'TOGGLE_MODAL_CHANGE_HADNICAP_INDEX',
  CANCEL_PROPAGATE_LEAGUES_REQUEST: 'CANCEL_PROPAGATE_LEAGUES_REQUEST',
  CANCEL_PROPAGATE_LEAGUES_RECEIVED: 'CANCEL_PROPAGATE_LEAGUES_RECEIVED',
  RESET_PASSWORD_MEMBER_REQUEST: 'RESET_PASSWORD_MEMBER_REQUEST',
  RESET_PASSWORD_MEMBER_RECEIVED: 'RESET_PASSWORD_MEMBER_RECEIVED',
  TOGGLE_DOWNLOAD_ROSTER: 'TOGGLE_DOWNLOAD_ROSTER',
  REFORMAT_HANDLES_REQUEST: 'REFORMAT_HANDLES_REQUEST',
  REFORMAT_HANDLES_RECEIVED: 'REFORMAT_HANDLES_RECEIVED',
}

export const getMembersDataParams = (state, stopIndex, searchParams = {}, memberIds = []) => {
  const loadedMembersCount = state.membersData.loadedMembersCount
  const page = loadedMembersCount === 0 ? 1 : Math.floor(stopIndex / loadedMembersCount + 1)
  searchParams = {
    ...state.membersData.searchParams,
    ...searchParams,
  }
  const params = {
    ...searchParams,
    league: {
      ...searchParams.filters,
    },
    membership_programs_refresh_table: searchParams.filters && searchParams.filters.membershipProgramsRefreshTable,
    comes_from: 'customer_roster',
    member_ids: memberIds,
    page,
  }
  return params
}

export const autoOpen = () => (dispatch, getState) => {
  const state = getState()
  switch (state.misc.openOption) {
    case 'add_golfers_options':
      dispatch(openAddGolfersManuallyPopup())
      break
    case 'upload_roster_options':
      dispatch(openUploadSpreadsheetSidebar())
      break
    case 'league_dashboard':
      dispatch(openEditMemberModal(state.misc.memberId))
      break
    case 'new_user':
      dispatch(openRegisterMemberPopup(state.misc.memberId))
      dispatch(getRegisterMemberData(state.misc.memberId))
      break
  }
}

export const fetchInitialMembersData = (searchParams = {}) => (dispatch, getState) => {
  const state = getState()
  if (!state.membersData.isLoading && !state.membersData.isMembersCountLoading) {
    const dataURL = state.membersData.fetchMembersDataUrl
    const params = getMembersDataParams(state, 0, searchParams)
    
    dispatch({
      type: ActionTypes.FETCH_INITIAL_MEMBERS_DATA_REQUEST,
    })
    callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.FETCH_INITIAL_MEMBERS_DATA_RECEIVED,
          data: response,
        })
        dispatch(clearCellMeasurerCacheAction())
        dispatch(fetchMembersCount())
        if (!state.leftSidebarFields.sidebarIconsSet) {
          dispatch(autoOpen())
          dispatch(setSidebarIcons())
        }
        if (!state.membersData.filterOptionsSet) {
          fetchFilterOptions()(dispatch, getState)
        }
      })
      .catch(() => {
        showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_fetching_initial_data'))
      })
  }
}

export const fetchMembersDataForMembers = (searchParams = {}, memberIds = []) => (dispatch, getState) => {
  const state = getState()
  if (!state.membersData.isLoading) {
    const dataURL = state.membersData.fetchMembersDataUrl
    const params = getMembersDataParams(state, 0, searchParams, memberIds)
    dispatch(clearCellMeasurerCacheAction())
    callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.FETCH_MEMBERS_DATA_RECEIVED_WITH_MEMBER_IDS,
          data: response,
          paramMemberIds: memberIds,
        })
        dispatch({
          type: ActionTypes.ADD_NEW_MEMBERS_RECEIVED,
        })
      })
      .catch(() => {
        showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_fetching_members_data_for_members'))
      })
  }
}
if (window.glg && window.glg.member) {
  window.glg.member.fetchMembersDataForMembers = fetchMembersDataForMembers
}

export const fetchMembersData = (stopIndex, searchParams = {}) => (dispatch, getState) => {
  const state = getState()
  if (!state.membersData.isLoading && !state.membersData.isMembersCountLoading) {
    const dataURL = state.membersData.fetchMembersDataUrl
    const params = getMembersDataParams(state, stopIndex, searchParams)
    dispatch({
      type: ActionTypes.FETCH_MEMBERS_DATA_REQUEST,
      searchParams,
    })
    callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.FETCH_MEMBERS_DATA_RECEIVED,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_fetching_members_data'))
      })
  }
}

export const fetchMembersCount = () => (dispatch, getState) => {
  const state = getState()
  const dataURL = state.membersData.getMembersCountUrl
  const params = {
    ...state.membersData.searchParams,
    league: {
      ...state.membersData.searchParams.filters,
    },
    comes_from: 'customer_roster',
  }
  dispatch({
    type: ActionTypes.GET_MEMBERS_COUNT_REQUEST,
  })
  callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.GET_MEMBERS_COUNT_RECEIVED,
        membersCount: response.membersCount,
      })
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_fetching_members_number'))
    })
}

export const fetchFilterOptions = () => (dispatch, getState) => {
  const state = getState()
  const dataURL = state.membersData.loadFilterOptionsUrl
  const params = {
    ...state.membersData.searchParams,
    league: {
      ...state.membersData.searchParams.filters,
    },
    comes_from: 'customer_roster',
  }
  dispatch({
    type: ActionTypes.GET_FILTER_OPTIONS_REQUEST,
  })
  callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.GET_FILTER_OPTIONS_RECEIVED,
        filterOptions: response.filterOptions,
      })
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_fetching_filter_options'))
    })
}

export const setSidebarIcons = () => ({
  type: ActionTypes.SET_SIDEBAR_ICONS,
})

export const fetchFieldsData = () => (dispatch, getState) => {
  const state = getState()
  const dataURL = state.leftSidebarFields.fetchFieldsDataUrl
  if (!state.leftSidebarFields.isLoading) {
    dispatch({
      type: ActionTypes.FETCH_FIELDS_DATA_REQUEST,
    })
    callAPI(dataURL, 'GET', {}, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.FETCH_FIELDS_DATA_RECEIVED,
          data: response,
          indexType: state.misc.indexType,
          prettyHandicapMethod: state.misc.prettyHandicapMethod,
          membershipPrograms: state.misc.membershipPrograms,
        })
      })
      .catch(() => {
        showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_fetching_fields_data'))
      })
  }
}

export const fetchLeagueMetadata = () => (dispatch, getState) => {
  const state = getState()
  const dataURL = state.addNewMembers.fetchLeagueMetadataUrl
  if (!state.addNewMembers.isLoading) {
    dispatch({
      type: ActionTypes.FETCH_LEAGUE_METADATA_REQUEST,
    })
    callAPI(dataURL, 'GET', {}, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.FETCH_LEAGUE_METADATA_RECEIVED,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_fetching_league_metadata'))
      })
  }
}

export const setMemberColumns = (data) => (dispatch, getState) => {
  const state = getState()
  const dataURL = state.leftSidebarFields.setMemberColumnsUrl
  if (!state.leftSidebarFields.isMemberColumnsLoading) {
    dispatch({
      type: ActionTypes.SET_MEMBER_COLUMNS_REQUEST,
    })
    $.ajax({
      method: 'PATCH',
      url: dataURL,
      data: {
        format: 'json',
        ...data,
      },
      success: () => {
        dispatch(fetchInitialMembersData(true))
        dispatch({
          type: ActionTypes.SET_MEMBER_COLUMNS_RECEIVED,
        })
      },
      error: () => {
        showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_setting_member_columns'))
        dispatch({
          type: ActionTypes.SET_MEMBER_COLUMNS_RECEIVED,
        })
      },
    })
  }
}

export const openFieldsSidebar = () => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_FIELDS_SIDEBAR,
  })
}

export const closeFieldsSidebar = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_FIELDS_SIDEBAR,
  })
}

export const openHandleFormatSidebar = () => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_HANDLE_FORMAT_SIDEBAR,
  })
}

export const closeHandleFormatSidebar = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_HANDLE_FORMAT_SIDEBAR,
  })
}

export const checkMemberField = (field) => (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: ActionTypes.CHECK_MEMBER_FIELD,
    field,
    indexType: state.misc.indexType,
    prettyHandicapMethod: state.misc.prettyHandicapMethod,
    membershipPrograms: state.misc.membershipPrograms,
  })
}

export const uncheckMemberField = (field) => (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: ActionTypes.UNCHECK_MEMBER_FIELD,
    field,
    indexType: state.misc.indexType,
    prettyHandicapMethod: state.misc.prettyHandicapMethod,
    membershipPrograms: state.misc.membershipPrograms,
  })
}

export const swapFields = (sourceIndex, destinationIndex) => (dispatch) => {
  dispatch({
    type: ActionTypes.SWAP_FIELDS,
    sourceIndex,
    destinationIndex,
  })
}

export const changeFilterOptions = (category, value, checked) => (dispatch, getState) => {
  const state = getState()
  const newFilters = {}
  for (const cat of Object.keys(state.membersData.filterOptions)) {
    const filters = state.membersData.filterOptions[cat]
    if (cat === 'membership_programs') {
      newFilters['show_membership_program_members'] = ''
      for (const f of filters) {
        if (f.value === 'show_no_membership_program_members') {
          if (cat === category && value === 'show_no_membership_program_members') {
            newFilters['show_no_membership_program_members'] = checked
          } else {
            newFilters['show_no_membership_program_members'] = f.checked
          }
        } else {
          if (f.value === value) {
            if (cat === category && checked) {
              newFilters['show_membership_program_members'] += f.value + ','
            }
          } else {
            if (f.checked) {
              newFilters['show_membership_program_members'] += f.value + ','
            }
          }
        }
      }
    } else if (cat === 'show_only') {
      const filters = state.membersData.filterOptions[cat]
      let status = filters.filter(x => x.checked)
        .map(x => x.value)
        .join(' ')
      if (cat === category) {
        if (checked){
          status = status === '' ? value : status + ' ' + value
        } else {
          status = status.replace(value, '').trim()
        }
      }
      newFilters['show_membership_program_status'] = status
    } else {
      const filters = state.membersData.filterOptions[cat]
      for (const f of filters) {
        if (f.value === value) {
          newFilters[f.value] = checked
        } else {
          newFilters[f.value] = f.checked
        }
      }
    }
  }
  newFilters['membershipProgramsRefreshTable'] = true
  dispatch({
    type: ActionTypes.CHANGE_FILTER_OPTIONS,
    value,
    checked,
    category,
  })
  dispatch(fetchInitialMembersData({ filters: newFilters }))
}

const clearCellMeasurerCacheAction = () => ({
  type: ActionTypes.CLEAR_CELL_MEASURER_CACHE,
})

export const clearCellMeasurerCache = () => (dispatch) => {
  dispatch(clearCellMeasurerCacheAction())
}

export const openAddGolfersManuallyPopup = () => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_ADD_GOLFERS_MANUALLY_POPUP,
  })
}

export const closeAddGolfersManuallyPopup = (resetMembers) => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_ADD_GOLFERS_MANUALLY_POPUP,
    resetMembers,
  })
}

export const openEditCustomFieldsPopup = (playerIndex) => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_EDIT_CUSTOM_FIELDS_POPUP,
    playerIndex,
  })
}

export const closeEditCustomFieldsPopup = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_EDIT_CUSTOM_FIELDS_POPUP,
  })
}

export const openUploadSpreadsheetSidebar = () => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_UPLOAD_SPREADSHEET_SIDEBAR,
  })
}

export const closeUploadSpreadsheetSidebar = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_UPLOAD_SPREADSHEET_SIDEBAR,
  })
}

export const saveNewMemberField = (name, value, index) => (dispatch) => {
  dispatch({
    type: ActionTypes.NEW_MEMBER_FIELD_CHANGED,
    name,
    value,
    index,
  })
}

export const changeCurrentCustomFields = (id, category, name, value, answer, answerId = '') => (dispatch) => {
  dispatch({
    type: ActionTypes.CURRENT_CUSTOM_FIELDS_CHANGED,
    id,
    category,
    name,
    value,
    answer,
    answerId,
  })
}

export const addMoreGolfers = () => (dispatch) => {
  dispatch({
    type: ActionTypes.ADD_MORE_GOLFERS,
  })
}

export const formatNewMembersSaveData = (newMembers) => {
  const data = {
    last_name: [],
    first_name: [],
    email: [],
    index: [],
    nine_index: [],
    handicap_network_id: [],
    member_metadata: [],
    terms_and_privacy_general: true,
  }

  for (const member of newMembers) {
    data['last_name'].push(member.lastName)
    data['first_name'].push(member.firstName)
    data['email'].push(member.email)
    data['index'].push(member.hi18Hole)
    data['nine_index'].push(member.hi9Hole)
    data['handicap_network_id'].push(member.handicapNetworkId)
    for (const cfId of Object.keys(member.customFields)){
      let safeCfAnswer
      if (typeof member.customFields[cfId] !== 'object' && typeof member.customFields[cfId] !== 'string' && typeof member.customFields[cfId] !== 'number') {
        safeCfAnswer = []
        for (const cfAnswer of member.customFields[cfId]) {
          safeCfAnswer.push(cfAnswer.replace(/"/g, '$QUOTE$')
            .replace(/\\/g, '$SLASH$')
            .replace(/\r\n/g, '\\r\\n'))
        }
      } else {
        if (typeof member.customFields[cfId] === 'object') {
          safeCfAnswer = member.customFields[cfId].answerId
        } else {
          safeCfAnswer = member.customFields[cfId].replace(/"/g, '$QUOTE$')
            .replace(/\\/g, '$SLASH$')
            .replace(/\r\n/g, '\\r\\n')
        }
      }
      member.customFields[cfId] = safeCfAnswer
    }
    data['member_metadata'].push(JSON.stringify(member.customFields).replace(/"/g, '&quot;'))
  }

  return data
}

export const addNewMembers = () => (dispatch, getState) => {
  const state = getState()
  const newMembers = _.clone(state.addNewMembers.newMembers)
  const dataURL = state.addNewMembers.saveNewMembersUrl
  const data = formatNewMembersSaveData(newMembers)

  if (!state.leftSidebarFields.isMemberColumnsLoading) {
    dispatch({
      type: ActionTypes.ADD_NEW_MEMBERS_REQUEST,
    })
    $.ajax({
      method: 'POST',
      url: dataURL,
      data: {
        format: 'json',
        ...data,
      },
      success: (response) => {
        if (response.conflictPath) {
          window.location.href = response.conflictPath
        } else {
          if (response.memberIds && response.memberIds.length > 0){
            dispatch(fetchMembersDataForMembers(state.membersData.searchParams, response.memberIds))
            let message = ''
            if (response.updatedMembersCount > 0 && response.addedMembersCount > 0){
              message = !window.I18n ? '' : window.I18n.t('members_page.actions.index.successful_add_and_update', { count_added: response.addedMembersCount, golfers_added: pluralizeGolfers(response.addedMembersCount), count_updated: response.updatedMembersCount, golfers_updated: pluralizeGolfers(response.updatedMembersCount)})
            } else if (response.addedMembersCount > 0) {
              message = !window.I18n ? '' : window.I18n.t('members_page.actions.index.successful_add', { count_added: response.addedMembersCount, golfers_added: pluralizeGolfers(response.addedMembersCount)})
            } else if (response.updatedMembersCount > 0) {
              message = !window.I18n ? '' : window.I18n.t('members_page.actions.index.successful_update', { count_updated: response.updatedMembersCount, golfers_updated: pluralizeGolfers(response.updatedMembersCount)})
            }
            showNotification(message)
          } else {
            dispatch(closeAddGolfersManuallyPopup(true))
            showNotification(response.message)
          }
        }
      },
      error: () => {
        showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_adding_new_members'))
      },
    })
  }
}

export const toggleTermsAndPrivacy = (checked, toggleType) => (dispatch) => {
  dispatch({
    type: ActionTypes.TOGGLE_TERMS_AND_PRIVACY,
    checked,
    toggleType,
  })
}

export const openLeaguesPropagationPopup = () => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_LEAGUE_PROPAGATION_POPUP,
  })
}

export const closeLeaguesPropagationPopup = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_LEAGUE_PROPAGATION_POPUP,
  })
}

export const getLeaguesPropagations = () => (dispatch, getState) => {
  const state = getState()
  const dataURL = state.leaguesPropagation.loadPropagationLeaguesUrl
  dispatch({
    type: ActionTypes.GET_LEAGUES_PROPAGATION_REQUEST,
  })
  callAPI(dataURL, 'GET', {}, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.GET_LEAGUES_PROPAGATION_RECEIVED,
        leagues: response.leagues,
      })
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_fetching_leagues_data'))
    })
}

export const propagateLeagues = (checkedLeagues, action) => (dispatch, getState) => {
  const state = getState()
  const dataURL = state.leaguesPropagation.propagateLeaguesUrl
  dispatch({
    type: ActionTypes.PROPAGATE_LEAGUES_REQUEST,
    action,
  })
  $.ajax({
    method: 'POST',
    url: dataURL,
    data: {
      format: 'json',
      customer: {
        league_ids: Object.keys(checkedLeagues).filter(x => checkedLeagues[x] === true),
      },
      button_action: action,
    },
    success: (response) => {
      dispatch({
        type: ActionTypes.PROPAGATE_LEAGUES_RECEIVED,
      })
      dispatch({
        type: ActionTypes.TOGGLE_MODAL_CHANGE_HADNICAP_INDEX,
        value: false,
      })
      dispatch(closeLeaguesPropagationPopup())
      if (response.jobPath) {
        window.location.href = response.jobPath
      }
    },
    error: () => {
      dispatch({
        type: ActionTypes.PROPAGATE_LEAGUES_RECEIVED,
      })
      showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_propagating_leagues'))
    },
  })
}

export const openDeleteRosterConfirmationPopup = () => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_DELETE_ROSTER_CONFIRMATION_POPUP,
  })
}

export const closeDeleteRosterConfirmationPopup = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_DELETE_ROSTER_CONFIRMATION_POPUP,
  })
}

export const getDeleteRosterConfirmationAnswer = () => (dispatch, getState) => {
  const state = getState()
  const dataURL = state.deleteRosterConfirmation.getResetRosterAnswerLeagueUrl
  dispatch({
    type: ActionTypes.GET_ANSWER_REQUEST,
  })
  callAPI(dataURL, 'GET', {}, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.GET_ANSWER_RECEIVED,
        actionMessage: response.actionMessage,
      })
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_fetching_leagues_data'))
    })
}

export const deleteEntireRoster = () => (dispatch, getState) => {
  const state = getState()
  const dataURL = state.deleteRosterConfirmation.resetRosterLeagueUrl
  dispatch({
    type: ActionTypes.DELETE_ROSTER_REQUEST,
  })
  $.ajax({
    method: 'POST',
    url: dataURL,
    data: {
      format: 'json',
    },
    success: () => {
      dispatch({
        type: ActionTypes.DELETE_ROSTER_RECEIVED,
      })
      dispatch(closeDeleteRosterConfirmationPopup())
      dispatch(closeFieldsSidebar())
      dispatch(fetchInitialMembersData())
    },
    error: () => {
      dispatch({
        type: ActionTypes.DELETE_ROSTER_RECEIVED,
      })
      showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_deleting_roster'))
    },
  })
}

export const uploadRosterSpreadsheet = (data) => (dispatch, getState) => {
  const state = getState()
  const dataURL = state.leftSidebarFields.uploadRosterSpreadsheetUrl
  dispatch({
    type: ActionTypes.UPLOAD_ROSTER_SPREADSHEET_REQUEST,
  })
  $.ajax({
    method: 'POST',
    url: dataURL,
    data: {
      format: 'json',
      spreadsheetfile: {
        ...data,
      },
      terms_and_privacy_upload_roster: state.leftSidebarFields.termsAndPrivacyChecked.uploadRoster,
    },
    success: (response) => {
      window.location.href = response.jobPath
    },
    error: () => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_updating_roster'))
    },
  })
}

export const openRegisterMemberPopup = (memberId) => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_REGISTER_MEMBER_POPUP,
  })
  dispatch(toggleActionsDropdown(false, memberId))
}

export const closeRegisterMemberPopup = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_REGISTER_MEMBER_POPUP,
  })
}

export const getRegisterMemberData = (memberId) => (dispatch, getState) => {
  const state = getState()
  const endpoint = 'register_member_inline'
  const dataURL = '/leagues/' + state.misc.leagueId + '/members/' + memberId + `/${endpoint}`
  dispatch({
    type: ActionTypes.GET_REGISTER_MEMBER_DATA_REQUEST,
  })
  callAPI(dataURL, 'GET', [], new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.GET_REGISTER_MEMBER_DATA_RECEIVED,
        data: response.data,
      })
    })
    .catch(error => {
      showErrorNotification(error.errorMessage)
    })
}

export const unregisterMember = (memberId) => (dispatch, getState) => {
  const state = getState()
  const endpoint = 'unregister_member_inline'
  const dataURL = '/leagues/' + state.misc.leagueId + '/members/' + memberId + `/${endpoint}`
  dispatch({
    type: ActionTypes.UNREGISTER_MEMBER_REQUEST,
    id: memberId,
  })
  callAPI(dataURL, 'GET', [], new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.UNREGISTER_MEMBER_RECEIVED,
      })
      dispatch(fetchMembersDataForMembers(state.membersData.searchParams, [ response.memberId ]))
      dispatch(toggleActionsDropdown(false, response.memberId))
    })
    .catch(error => {
      showErrorNotification(error)
    })
}

export const registerMember = (memberId) => (dispatch, getState) => {
  const state = getState()
  const endpoint = 'register'
  const dataURL = '/leagues/' + state.misc.leagueId + '/members/' + memberId + `/${endpoint}`
  const data = {
    user: {
      email: state.registerMember.registerMemberValues.email,
      first_name: state.registerMember.registerMemberValues.first_name,
      last_name: state.registerMember.registerMemberValues.last_name,
      temporary_password: state.registerMember.registerMemberValues.temporary_password,
    },
    permanent_password: state.registerMember.registerMemberValues.permanent_password ? 'true' : '',
    propagate_user: state.registerMember.registerMemberValues.propagate_user,
    email_message: state.registerMember.registerMemberValues.email_message,
  }
  dispatch({
    type: ActionTypes.REGISTER_MEMBER_REQUEST,
  })
  $.ajax({
    method: 'POST',
    url: dataURL,
    data: {
      format: 'json',
      ...data,
    },
    success: (response) => {
      dispatch({
        type: ActionTypes.REGISTER_MEMBER_RECEIVED,
      })
      dispatch(closeRegisterMemberPopup())
      dispatch(fetchMembersDataForMembers(state.membersData.searchParams, [ response.memberId ]))
    },
    error: () => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_registering_member'))
    },
  })
}

export const updateRegistration = (field, value) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_MEMBER_REGISTRATION,
    field,
    value,
  })
}

export const confirmMember = (memberId) => (dispatch, getState) => {
  const state = getState()
  const endpoint = 'confirm_self_registered'
  const dataURL = '/leagues/' + state.misc.leagueId + '/members/' + memberId + `/${endpoint}`
  dispatch({
    type: ActionTypes.CONFIRM_MEMBER_REQUEST,
    id: memberId,
  })
  $.ajax({
    method: 'GET',
    url: dataURL,
    data: {
      format: 'json',
    },
    success: (response) => {
      dispatch({
        type: ActionTypes.CONFIRM_MEMBER_RECEIVED,
      })
      dispatch(fetchMembersDataForMembers(state.membersData.searchParams, [ response.memberId ]))
      dispatch(toggleActionsDropdown(false, response.memberId))
    },
    error: () => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_confirming_member'))
    },
  })
}

export const deleteMember = (memberId, action = 'delete', noSync = false) => (dispatch, getState) => {
  const state = getState()
  const dataURL = '/leagues/' + state.misc.leagueId + '/members/' + memberId
  dispatch({
    type: ActionTypes.DELETE_MEMBER_REQUEST,
    id: memberId,
  })
  $.ajax({
    method: 'DELETE',
    url: dataURL,
    data: {
      format: 'json',
      soft_delete: state.misc.customerSoftDelete,
      no_sync: noSync,
    },
    success: (response) => {
      dispatch({
        type: ActionTypes.DELETE_MEMBER_RECEIVED,
        id: response.id,
        action,
      })
      if (action === 'undelete') {
        dispatch(fetchMembersDataForMembers(state.membersData.searchParams, [ response.id ]))
      }
      dispatch(toggleActionsDropdown(false, response.id))
    },
    error: () => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_action_member', { delete_or_undelete: action === 'delete' ? (!window.I18n ? '' : window.I18n.t('members_page.actions.index.deleting')) : (!window.I18n ? '' : window.I18n.t('members_page.actions.index.undeleting')) }))
    },
  })
}

export const resetPasswordMember = (memberId, memberEmail) => (dispatch, getState) => {
  const state = getState()
  const dataURL = state.misc.resetPasswordUrl
  dispatch({
    type: ActionTypes.RESET_PASSWORD_MEMBER_REQUEST,
    id: memberId,
  })
  $.ajax({
    method: 'GET',
    url: dataURL,
    data: {
      format: 'json',
      email: memberEmail,
    },
    success: (response) => {
      dispatch({
        type: ActionTypes.RESET_PASSWORD_MEMBER_RECEIVED,
        id: memberId,
      })
      dispatch(toggleActionsDropdown(false, memberId))
      showNotification(response.reset.message)
    },
    error: () => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_resetting_password'))
    },
  })
}

export const openEditMemberModal = (memberId) => (dispatch, getState) => {
  const state = getState()
  const dataURL = '/leagues/' + state.misc.leagueId + '/members/' + memberId + '/edit_member_modal'
  dispatch({
    type: ActionTypes.EDIT_MEMBER_REQUEST,
    id: memberId,
  })
  $.ajax({
    method: 'GET',
    url: dataURL,
    data: {
      format: 'js',
    },
    success: () => {
      dispatch({
        type: ActionTypes.EDIT_MEMBER_RECEIVED,
        id: memberId,
      })
      dispatch(toggleActionsDropdown(false, memberId))
      dispatch(closeRegisterMemberPopup())
    },
    error: () => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_opening_edit_member'))
    },
  })
}

export const toggleActionsDropdown = (value, id) => (dispatch) => {
  dispatch({
    type: ActionTypes.TOGGLE_ACTIONS_DROPDOWN,
    value,
    id,
  })
}

export const updateGridWidth = (cellCacheIndex) => (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: ActionTypes.UPDATE_GRID_WIDTH,
    cellCacheIndex,
    nrColumns: state.membersData.columns.length,
  })
}

export const updateContainerWidth = (width) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_CONTAINER_WIDTH,
    width,
  })
}

export const toggleDownloadRoster = () => (dispatch) => {
  dispatch({
    type: ActionTypes.TOGGLE_DOWNLOAD_ROSTER,
  })
}

export const reformatHandles = (handle, selectedField, overrideFirstName, overrideWithKanji) => (dispatch, getState) => {
  const state = getState()
  const dataURL = state.leftSidebarFields.setHandleFormatUrl
  dispatch({
    type: ActionTypes.REFORMAT_HANDLES_REQUEST,
  })
  $.ajax({
    method: 'PATCH',
    url: dataURL,
    data: {
      format: 'json',
      league: { handles: handle, variable_settings_custom_handle_name: selectedField},
      override_first_name: overrideFirstName,
      override_with_kanji: overrideWithKanji,
    },
    success: (response) => {
      window.location.href = response.jobUrl
      dispatch({
        type: ActionTypes.REFORMAT_HANDLES_RECEIVED,
      })
    },
    error: () => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('members_page.actions.index.error_reformatting_handles'))
    },
  })
}
