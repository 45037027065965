import { connect } from 'react-redux'
import {filterData, exportToExcel, deletePaymentMethod, defaultPaymentMethod} from '../actions'

import TransactionsHistoryComponent from '../components/transactions_history/transactions_history_component'
import getTransactions from '../selectors/transactions_event_selector'

const mapStateToProps = (state) => {
  const transactions = getTransactions(state)
  
  return {
    transactions,
    playerName: state.transactionsHistory.playerName,
    hasTransactions: state.transactionsHistory.hasTransactions,
    events: state.transactionsHistory.events,
    eventId: state.transactionsHistory.eventId,
    exportToExcelUrl: state.exportToExcelUrl,
    dataIsLoading: state.transactionsHistory.dataIsLoading,
    payments: state.transactionsHistory.payments,
    cbEnabled: state.transactionsHistory.cbEnabled,
    cbBalance: state.membershipPrograms.cbBalance,
    currency: state.transactionsHistory.currency,
  }
}

const mapDispatchToProps = (dispatch) => ({
  filterTransactionsHistory: (eventId) => dispatch(filterData('transactionsHistory', eventId)),
  exportToExcel: () => dispatch(exportToExcel()),
  deletePaymentMethod: (paymentMethodId) => dispatch(deletePaymentMethod(paymentMethodId)),
  defaultPaymentMethod: (paymentMethodId) => dispatch(defaultPaymentMethod(paymentMethodId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionsHistoryComponent)
