
const initialState = {
  matchLines: null,
  acknowledgedLines: null,
  refreshUrl: null,
  acknowedgedDate: null,
  lastUpdatedDate: null,
  acknowledgeUrl: null,
  isTeam: null,
  teamNames: null,
  todayPoints: null,
  totalPoints: null,
  acknowledgedTodayPoints: null,
  acknowledgedTotalPoints: null,
}

const misc = (state = initialState, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
      }
  }
}

export default misc
