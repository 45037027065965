import { connect } from 'react-redux'
import StickyMemberCard from '../components/sticky_member_card_component'
import { clearSticky, cancelSticky } from '../actions'

const mapStateToProps = (state) => {
  return { 
    memberCard: state.sticky.memberCard,
    members: state.sticky.members,
    newMembers: state.sticky.newMembers,
    index: state.sticky.index,
    type: state.sticky.type,
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearSticky: () => dispatch(clearSticky()),
  cancel: () => dispatch(cancelSticky()),
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StickyMemberCard)
