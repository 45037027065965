import { connect } from 'react-redux'
import EventSetupImportGolfers from '../../components/content/event_setup_import_golfers'
import { setGenericData, ajaxRequest } from '../../actions/index'
import { formatNewMembersSaveData } from 'members_page/actions/index'
import { showNotification, showErrorNotification } from 'Shared/helpers'
import _ from 'lodash'

const internationalize = (...args) => !window.I18n ? '' : window.I18n.t(...args)

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { links } = ownProps.content
  const membersData = ownProps.content.members_data
  const addMembersManually = ownProps.content.add_members_manually
  const importFrom = ownProps.content.import_from
  const modalOpened = ownProps.content.modal_opened
  const searchOptions = ownProps.content[`${importFrom.selected}_options`]
  const memberSearchOptions = ownProps.content.member_search

  const prepareMembersReload = (newMemberSearchOptions) => {
    dispatch(setGenericData(ownProps.widgetId, [ 'member_search' ], {
      ...memberSearchOptions,
      ...newMemberSearchOptions,
    }))
    dispatch(setGenericData(ownProps.widgetId, [ 'members_data', 'loading_state' ], 'should_load'))
  }

  const loadMembersData = (successCallback = null) => {
    dispatch(setGenericData(ownProps.widgetId, [ 'members_data', 'loading_state' ], 'loading'))
    dispatch(ajaxRequest(links.member_search.path, 'post', memberSearchOptions, 'json'))
      .then(({ response }) => {
        if (memberSearchOptions.page > 1 && response.members.length === 0) {
          prepareMembersReload({ page: memberSearchOptions.page - 1 })
        } else {
          successCallback && successCallback()
          dispatch(setGenericData(ownProps.widgetId, [ 'members_data' ], {
            ...response,
            loading_state: 'loaded',
          }))
        }
      })
  }

  const newManualMember = () => ({
    lastName: '',
    firstName: '',
    email: '',
    handicapNetworkId: '',
    hi18Hole: '',
    hi9Hole: '',
    divisions: '',
    customFields: {},
  })

  const openModal = (name) => {
    dispatch(setGenericData(ownProps.widgetId, [ 'modal_opened' ], name))
  }

  const closeModal = () => {
    if (modalOpened) {
      dispatch(setGenericData(ownProps.widgetId, [ 'modal_opened' ], false))
    } else {
      if (searchOptions?.selected === 'select') {
        dispatch(setGenericData(ownProps.widgetId, [ `${importFrom.selected}_options`, 'selected' ], 'quick_search'))
      }

      if (importFrom.selected === 'manually') {
        dispatch(setGenericData(ownProps.widgetId, [ 'import_from', 'selected' ], importFrom.old_selected))
      }
    }
  }

  return {
    setData: (keys, value) => {
      dispatch(setGenericData(ownProps.widgetId, keys, value))
      if (! ownProps.content.dataChanged) {
        dispatch(setGenericData(ownProps.widgetId, [ 'dataChanged' ], true))
      }
    },

    quickImportMember: (memberId) => {
      const importFrom = ownProps.content.import_from.selected
      let fromLeagueId = ownProps.content.master_roster_id
      if (importFrom !== 'master_roster') {
        fromLeagueId = ownProps.content.past_leagues.value
      }

      let url = decodeURI(links.quick_import.path)
      url = url.replace(/{from_league_id}/g, fromLeagueId)
      url = url.replace(/{member_id}/g, memberId)

      dispatch(ajaxRequest(url, 'get', {}, 'text')).then(() => {
        loadMembersData(() => {
          $('#quick_search_select')
            .val(null)
            .trigger('change')
        })
      })
    },

    deleteMember: (member) => {
      dispatch(setGenericData(ownProps.widgetId, [ 'deletedMembers', member.id ], true))
      dispatch(ajaxRequest(member.member_path, 'delete')).finally( () => {
        dispatch(setGenericData(ownProps.widgetId, [ 'deletedMembers', member.id ], false))

        const pagesCount = Math.max(1, Math.ceil((membersData.members_count - 1) / 50))
        const page = Math.min(memberSearchOptions.page, pagesCount)

        prepareMembersReload({ page })
      })
    },

    newManualMember,
    closeModal,

    initializeAddMembersManually: () => {
      dispatch(setGenericData(ownProps.widgetId, [ 'add_members_manually' ], {
        ...addMembersManually,
        newMembers: Array.from({ length: 5 }, newManualMember),
        nrCustomFields: [],
        termsAndPrivacyChecked: {
          addGolfersManually: false,
        },
      }))
    },

    saveAddNewMembers: () => {
      const newMembers = _.clone(addMembersManually.newMembers)
      const data = formatNewMembersSaveData(newMembers)

      if (membersData.loading_state === 'loaded') {
        dispatch(setGenericData(ownProps.widgetId, [ 'add_members_manually', 'isNewMembersSaving' ], true))
        dispatch(ajaxRequest(addMembersManually.saveNewMembersUrl, 'post', data, 'json'))
          .then(({ response }) => {
            dispatch(setGenericData(ownProps.widgetId, [ 'add_members_manually', 'isNewMembersSaving' ], false))
            closeModal()
            if (response.conflictPath) {
              showErrorNotification(internationalize('members_page.actions.index.error_adding_new_members'))
              dispatch(setGenericData(ownProps.widgetId, [ 'add_members_manually', 'conflictUrl' ], response.conflictPath + '&no_menu=true'))
              dispatch(setGenericData(ownProps.widgetId, [ 'modal_loading' ], true))
              openModal('members_add_conflict')
            } else {
              showNotification(response.message)
              prepareMembersReload({
                page: 1,
                filter_letters: '',
              })
            }
          })
      }
    },

    solveConflicts: (url, method, data) => {
      dispatch(ajaxRequest(url, method, data, 'text'))
        .finally(() => {
          closeModal()
          prepareMembersReload({
            page: 1,
            filter_letters: '',
          })
        })
    },

    // data loading

    loadMembersData,
    prepareMembersReload,
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(EventSetupImportGolfers)
