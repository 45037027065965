import React, { Component, Fragment } from 'react'
import { Col } from 'react-flexbox-grid'
import PropTypes from 'prop-types'
import { FormControl } from 'react-bootstrap'
import TvShowSections from './tv_show_sections'
import TvShowSection from './tv_show_section'
import GeneralComponent from './general_component'
import HeaderSectionComponent from './header_section_component'
import FooterSectionComponent from './footer_section_component'
import SlidesSectionComponent from './slides_section_component'
import HeaderSetupComponent from './header_setup_component'
import FooterSetupComponent from './footer_setup_component'
import LibrarySectionComponent from './library_section_component'
import {
  buildTvShow,
  buildNewShowSlideshowPath,
  buildLibrarySettings,
} from '../helpers'
import { USGA_SILVER, USGA_OLD_BLUE } from '../constants'
import { GlgCheckbox } from 'SharedComponents'
import { getAuthenticityToken, showErrorNotification } from 'Shared/helpers'

class TvShowSetup extends Component {

  constructor(props) {
    super(props)

    this.periodicSave = this.periodicSave.bind(this)

    this.mainPageSaveBar = this.mainPageSaveBar.bind(this)
    this.headerAndFooterSetupSaveBar = this.headerAndFooterSetupSaveBar.bind(this)
    this.saveOrIndex = this.saveOrIndex.bind(this)
    this.cancelTvShow = this.cancelTvShow.bind(this)
  }

  periodicSave() {
    const {
      paths,
      general,
      header,
      footer,
      slides,
    } = this.props

    const changesOccurred = general.hasUnsavedChanges ||
                            header.hasUnsavedChanges ||
                            footer.hasUnsavedChanges ||
                            slides.hasUnsavedChanges

    if (changesOccurred) {
      $.ajax({
        url: paths.save,
        data: buildTvShow(this.props),
        type: 'POST',
        success: () => {
          this.props.generalDispatchers.markAsSaved()
          this.props.headerDispatchers.markAsSaved()
          this.props.footerDispatchers.markAsSaved()
          this.props.slidesDispatchers.markAsSaved()
        },
        error: () => {
        },
      })
    }
  }

  saveOrIndex(e) {
    const {
      general,
      library,
      libraryDispatchers,
      paths,
    } = this.props

    e.preventDefault()
    if (library.hasUnsavedChanges) {
      const tvShow = buildLibrarySettings(library)
      const requestOptions = {
        method: 'POST',
        headers: { 
          'X-CSRF-Token': getAuthenticityToken(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(tvShow),
      }
      fetch(paths.save, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw new Error()
        })
        .then(() => {
          libraryDispatchers.markAsSaved()
          window.location.href = paths.index
        })
        .catch(() => {
          const message = !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_show_setup_component.library_save_error')
          showErrorNotification(message)
        })
    } else {
      window.location.href = general.isTemplate ? paths.library : paths.index
    }
  }

  cancelTvShow() {
    const paths = this.props.paths
    const requestOptions = {
      method: 'DELETE',
      headers: { 
        'X-CSRF-Token': getAuthenticityToken(),
        'Content-Type': 'application/json',
      },
    }
    fetch(paths.delete, requestOptions)
      .then(response => {
        if (response.ok) {
          window.location.href = paths.index
        }
      })
  }

  mainPageSaveBar() {
    const tvShowId = this.props.general.id
    const newTvShow = this.props.newTvShow

    return <div className="save_bar">{newTvShow && <a href={ '#' } onClick={ () => {
            if (window.confirm(!window.I18n ? '' : window.I18n.t('tv_shows.components.tv_show_setup_component.delete_confirm'))) {
              this.cancelTvShow()
            }
          } } className="btsp_gray_button">{ !window.I18n ? '' : window.I18n.t('buttons.cancel').charAt(0)
              .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.cancel').slice(1)) }</a>}<a href={ '#' } onClick={ this.saveOrIndex } className="btsp_orange_button">{ !window.I18n ? '' : window.I18n.t('buttons.save').charAt(0)
            .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.save').slice(1)) }</a><a href={ buildNewShowSlideshowPath(this.props.paths.slideshow, tvShowId) } target={'_blank'} className="btsp_orange_button">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_show_setup_component.play') }</a></div>
  }

  headerAndFooterSetupSaveBar() {
    return <div className="save_bar"><a onClick={ () => { this.props.generalDispatchers.changeViewDisplayed('main') } } className="btsp_gray_button">{ !window.I18n ? '' : window.I18n.t('buttons.back').charAt(0)
            .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.back').slice(1)) }</a><a onClick={ () => { this.props.generalDispatchers.changeViewDisplayed('main') } } className="btsp_orange_button">{ !window.I18n ? '' : window.I18n.t('buttons.done').charAt(0)
            .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.done').slice(1)) }</a></div>
  }

  handleUsgaThemeChange(isChecked) {
    const {
      generalDispatchers: {
        setHasUSGATheme,
      },
      headerDispatchers: {
        selectBackgroundType,
        setBackgroundColor,
        changeOpacity,
        setText,
      },
    } = this.props
    setHasUSGATheme(isChecked)
    if (isChecked) {
      // apply USGA theme for the header, but still editable
      const headerEditorStyle = 
        `<p style="text-align: center">
          <span style="font-size: 38px;">
          <span style="font-weight: bold;">
          <span style="color: ${USGA_OLD_BLUE};">
            %event.name%
          </span></span></span>
        </p>`
      selectBackgroundType('color')
      setBackgroundColor(USGA_SILVER)
      changeOpacity('100')
      setText(headerEditorStyle)
    }
  }

  componentDidMount(){
    this.interval = setInterval( () => this.periodicSave(), 3000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const {
      brand,
      canUseUsgaTheme,
      general: {
        viewDisplayed,
        name,
        hasUSGATheme,
      },
      generalDispatchers: {
        setName,
      },
    } = this.props

    return <Fragment>{ viewDisplayed === 'main' && <TvShowSections><TvShowSection name={ !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_show_setup_component.name') }><Col xs={ 12 } sm={ 6 } lg={ 3 } className="no-padding"><FormControl type="text" name="tv_show_name" value={ name } autoFocus={ true } onChange={ (e) => { setName(e.target.value) } }></FormControl></Col></TvShowSection><TvShowSection name={ !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_show_setup_component.general') }>{ <GeneralComponent
                  paths={ this.props.paths }
                  {...this.props.general}
                  {...this.props.generalDispatchers}
              /> }</TvShowSection><TvShowSection name={ !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_show_setup_component.header') }>{ <HeaderSectionComponent
                  changeViewDisplayed={ this.props.generalDispatchers.changeViewDisplayed }
                  {...this.props.header}
                  {...this.props.headerDispatchers}
              /> }</TvShowSection><TvShowSection name={ !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_show_setup_component.footer') }>{ <FooterSectionComponent
                  changeViewDisplayed={ this.props.generalDispatchers.changeViewDisplayed }
                  {...this.props.footer}
                  {...this.props.footerDispatchers}
              /> }</TvShowSection><TvShowSection name={ !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_show_setup_component.slides') }>{ <SlidesSectionComponent
                  currentRoundId={ this.props.general.currentRoundId }
                  paths={ this.props.paths }
                  extra={ this.props.extra }
                  { ...this.props.slides }
                  {...this.props.slidesDispatchers }
              /> }</TvShowSection>{ !this.props.general.isTemplate && <TvShowSection name={ !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_show_setup_component.library') }>{ <LibrarySectionComponent 
                    library={ this.props.library }
                    libraryDispatchers= { this.props.libraryDispatchers }
                /> }</TvShowSection>}{ canUseUsgaTheme && <TvShowSection name={'Theme'}><GlgCheckbox label={'USGA Theme'} key={'has_usga_theme'} name={'has_usga_theme'} checked={ hasUSGATheme } onChange={ (isChecked) => this.handleUsgaThemeChange(isChecked) } inline={true}></GlgCheckbox></TvShowSection>}</TvShowSections>}{ viewDisplayed === 'headerSettings' &&
          <HeaderSetupComponent
            paths={ this.props.paths }
            brand={ brand }
            {...this.props.header}
            {...this.props.headerDispatchers}
            ckeditorStyle={ this.props.paths.ckeditorStyle }
          />
        }{
          viewDisplayed === 'footerSettings' &&
          <FooterSetupComponent
            paths={ this.props.paths }
            {...this.props.footer}
            {...this.props.footerDispatchers}
            ckeditorStyle={ this.props.paths.ckeditorStyle }
          />
        }{ viewDisplayed === 'main' && this.mainPageSaveBar() }{ [ 'headerSettings', 'footerSettings' ].includes(viewDisplayed) && this.headerAndFooterSetupSaveBar() }</Fragment>
  }
}

TvShowSetup.propTypes = {
  paths: PropTypes.object.isRequired,
  brand: PropTypes.string.isRequired,
  newTvShow: PropTypes.bool.isRequired,
  canUseUsgaTheme: PropTypes.bool.isRequired,
  general: PropTypes.object.isRequired,
  header: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
  slides: PropTypes.object.isRequired,
  library: PropTypes.object.isRequired,
  extra: PropTypes.object,
  generalDispatchers: PropTypes.object.isRequired,
  headerDispatchers: PropTypes.object.isRequired,
  footerDispatchers: PropTypes.object.isRequired,
  slidesDispatchers: PropTypes.object.isRequired,
  libraryDispatchers: PropTypes.object.isRequired,
}

export default TvShowSetup
