import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class GlgIcon extends PureComponent {
  render() {
    const { icon, iconClass, ...iconProps } = this.props
    return <i className={`gga-${ icon } ${ iconClass }`} { ...iconProps }></i>
  }
}

GlgIcon.propTypes = {
  // Name of the icon (.gga-arrow-up will be 'arrow-up')
  icon: PropTypes.string.isRequired,
  // Class names to append to element
  iconClass: PropTypes.string,
}

GlgIcon.defaultProps = {
  iconClass: '',
}

export default GlgIcon
