import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { Col } from 'react-flexbox-grid'
import { GlgTooltipIcon } from 'SharedComponents'
import { sortByOptions } from '../helpers'

class Filters extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    window.glg.new_design_shared.init_selectpicker()
  }

  componentDidUpdate() {
    window.glg.new_design_shared.init_selectpicker()
  }

  render() {
    return (
      <Fragment>
        <Col xs={12} sm={6} md={6} lg={3}>
          <div className='pull-left add-margin-right-10 bold'>{!window.I18n ? '' : window.I18n.t('group_view.filters.select_tournament')}</div>
          <GlgTooltipIcon placement='bottom' tooltip={!window.I18n ? '' : window.I18n.t('group_view.tournaments_available')}/>
          <Col xs={12} sm={10} md={8} lg={12} className='no-padding-left tournament_selector'>
            <select className='selectpicker form-control tournament_select' value={this.props.selectedTournamentId} onChange={ (event) => this.props.changeTournament(event.target.value) }>
              {
                Object.keys(this.props.tournaments).map( id =>
                  <option key={id} value={id}>{this.props.tournaments[id]}</option>
                )
              }
            </select>
          </Col>
        </Col>

        <Col xs={12} sm={6} md={6} lg={3}>
          <div className='pull-left add-margin-right-10 bold'>{!window.I18n ? '' : window.I18n.t('group_view.filters.sort_by')}</div>
          <Col xs={12} sm={10} md={8} lg={12} className='no-padding-left tournament_selector'>
            <div className='add-padding-right-55'>
              <select className='selectpicker form-control' value={this.props.sortOption} onChange={ (event) => this.props.changeSortOption(event.target.value)}>
                {
                  sortByOptions.map( option =>
                    <option key={option.key} value={option.value}>{ !window.I18n ? '' : window.I18n.t(`group_view.filters.sort_by_options.${option.key}`, {defaultValue: option.name}) }</option>
                  )
                }
              </select>
            </div>
            <div className='input-group-addon sorting-order-addon' onClick={() => this.props.changeSortOrderOption()}>
              <a>
                {this.props.sortOrder === 'asc' && <img src={require('bootstrap_theme/icons/sort-by-down.png')} className='sorting-order-icon'/>}
                {this.props.sortOrder === 'desc' && <img src={require('bootstrap_theme/icons/sort-by-up.png')} className='sorting-order-icon'/>}
              </a>  
            </div>
          </Col>
        </Col>

        { this.props.holes.length > 1 && 
          <Col xs={12} sm={6} md={6} lg={2}>
            <div className='pull-left add-margin-right-10 bold'>{!window.I18n ? '' : window.I18n.t('group_view.filters.starting_hole')}</div>
            <Col xs={12} sm={10} md={8} lg={12} className='no-padding-left tournament_selector'>
              <select className='selectpicker form-control tournament_select' value={this.props.selectedHole} onChange={ (event) => this.props.changeStartingHole(event.target.value)}>
                <option key='all' value='all'>{!window.I18n ? '' : window.I18n.t('group_view.all')}</option>
                {
                  this.props.holes.map( holeNumber => 
                    <option key={holeNumber} value={holeNumber}>{!window.I18n ? '' : window.I18n.t('group_view.hole', {hole: holeNumber})}</option>
                  )
                }
              </select>
            </Col>
          </Col>
        }

        { this.props.courses.length > 1 &&
          <Col xs={12} sm={6} md={6} lg={2}>
            <div className='pull-left add-margin-right-10 bold'>{!window.I18n ? '' : window.I18n.t('group_view.filters.course')}</div>
            <Col xs={12} sm={10} md={8} lg={12} className='no-padding-left tournament_selector'>
              <select className='selectpicker form-control tournament_select' value={this.props.selectedCourse} onChange={ (event) => this.props.changeCourse(event.target.value)}>
                <option key='all' value='all'>{!window.I18n ? '' : window.I18n.t('group_view.all')}</option>
                {
                  this.props.courses.map( course => 
                    <option key={course.id} value={course.id}>{course.name}</option>
                  )
                }
              </select>
            </Col>
          </Col>
        }
      </Fragment>
    )
  }
}

Filters.propTypes = {
  tournaments: PropTypes.object,
  rounds: PropTypes.object,
  holes: PropTypes.array,
  courses: PropTypes.array,
  changeDisplayOption: PropTypes.func,
  changeTournament: PropTypes.func,
  selectedTournamentId: PropTypes.string,
  isStableford: PropTypes.bool,
  displayOption: PropTypes.string,
  selectedHole: PropTypes.string,
  selectedCourse: PropTypes.string,
  changeStartingHole: PropTypes.func,
  changeCourse: PropTypes.func,
  changeSortOption: PropTypes.func,
  changeSortOrderOption: PropTypes.func,
  sortOption: PropTypes.string,
  sortOrder: PropTypes.string,
  showRounds: PropTypes.bool,
  roundId: PropTypes.string,
  changeRound: PropTypes.func,
}

export default Filters
