import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { GlgPopup } from 'SharedComponents'

import PopupHolesRow from './popup_holes_row'
import PopupParRow from './popup_par_row'
import PopupScoresTeamRow from './popup_scores_team_row'
import PopupShotsTeamRow from './popup_shots_team_row'
import PopupTeamRow from './popup_team_row'
import PopupVerifyScoresTeamComponent from './popup_verify_scores_team_component'
import PopupStablefordPointsRow from './popup_stableford_points_component'
class VerificationTeamPopupComponent extends Component {
  constructor(props) {
    super(props)
    const teamScores = this.props.scoresData
    const players = this.props.players
    const playersMatchedScores = []
    let teamMatchedScores = []
    players.map( (player, index) => {
      playersMatchedScores[index] = Array(18).fill()
          .map(() => true)
    })
    teamMatchedScores = Array(18).fill()
        .map(() => true)

    const initialScores = teamScores
    if (!this.props.autoFillScore) {
      initialScores.map( (playerScores) => {
        playerScores.scores_array = Array(18).fill()
      })
    }

    this.state =
      {
        scores: initialScores,
        teamTotals: this.props.teamTotals,
        playersMatchedScores: playersMatchedScores,
        teamMatchedScores: teamMatchedScores,
        stablefordPoints: this.props.stablefordPoints,
      }
      
    this.handleScoreChange = this.handleScoreChange.bind(this)
    this.onSave = this.onSave.bind(this)
    this.onShow = this.onShow.bind(this)
    this.keyPress = this.keyPress.bind(this)
    this.moveToLeft = this.moveToLeft.bind(this)
    this.moveToRight = this.moveToRight.bind(this)
    this.backSpacePressed = this.backSpacePressed.bind(this)
    this.tabPressed = this.tabPressed.bind(this)
    this.handleOrientationChange = this.handleOrientationChange.bind(this)
  }

  handleScoreChange(playerIndex, holeNumber, score) {
    const {teamTotals, playersMatchedScores, teamMatchedScores, stablefordPoints} = this.state
    const {players} = this.props
    const allScores = this.state.scores[playerIndex]
    const scores = allScores.scores_array
    const grossTeamScores = teamTotals.scores

    let inScore = allScores.in
    let outScore = allScores.out
    let roundScore = allScores.round_score
    let totalScore = allScores.total_score

    let outTeamScore = teamTotals.out
    let inTeamScore = teamTotals.in
    let roundTeamScore = teamTotals.round_score
    let totalTeamScore = teamTotals.total_score
    
    const stablefordPointsArray = stablefordPoints.stableford_points_array
    const stablefordValues = JSON.parse(stablefordPoints.stableford_values ?? '{}')
    const oldStablefordPoint = stablefordPointsArray?.[holeNumber]
    const pars = stablefordPoints.par_array
    let stablefordIn = stablefordPoints.stableford_in
    let stablefordOut = stablefordPoints.stableford_out
    let stablefordTotal = stablefordPoints.stableford_total
    let stablefordAggregated = stablefordPoints.aggregated_total

    let isX = false
    let onlyXScores = true
    if (typeof score === 'string' && score.includes('X')) {
      score = 0
      isX = true
    } else {
      onlyXScores = false
    }
    score = parseInt(score)
    if ( score === '' || isNaN(score) || score === 0 || (typeof score === 'string' && score.includes('X')) ) {
      score = 9999
    }

    //Compute Team Scores
    let minimumScore = score
    
    players.map( (player, index) => {
      if (index !== playerIndex) {
        let playerScore = this.state.scores[index].scores_array[holeNumber] || player.score_data.scores_array[holeNumber]
        if (typeof playerScore === 'string' && playerScore.includes('X')) {
          playerScore = 9999
        } else {
          onlyXScores = false
          playerScore = parseInt(playerScore)
        }
        if ((!isNaN(playerScore) && playerScore < minimumScore) || isNaN(parseInt(minimumScore))) {
          minimumScore = playerScore
        }
      } else {
        let oldScore = players[playerIndex].score_data.original_scores[holeNumber]
        if (typeof oldScore === 'string' && oldScore.includes('X')) {
          oldScore = 9999
        } else if (!isNaN(parseInt(oldScore)) && (score !== 9999 && score !== 0)) {
          onlyXScores = false
        }
        if ((score === 9999 || score === 0) && !isNaN(parseInt(oldScore))) {
          if (oldScore < minimumScore) {
            minimumScore = oldScore
          }
        }
      }
    })
    if ( minimumScore === 9999) {
      minimumScore = 0
      onlyXScores = true
    }
    grossTeamScores[holeNumber] = minimumScore
    let newStablefordValue
    if (onlyXScores && stablefordValues) {
      const highestKey = Math.max(...Object.keys(stablefordValues).map(Number))
      newStablefordValue = stablefordValues[highestKey.toString()]
    } else {
      newStablefordValue = stablefordValues?.[(parseInt(minimumScore) - pars[holeNumber]).toString()]
    }
    if (stablefordPointsArray) {
      stablefordPointsArray[holeNumber] = parseInt(newStablefordValue)
    }

    //Compute New Scores
    let oldScore = parseInt(players[playerIndex].score_data.original_scores[holeNumber])

    if ( oldScore === null || isNaN(oldScore) || (typeof score === 'string' && score.includes('X')) ) {
      oldScore = 9999
    }
    
    if ( score !== oldScore && score !== 9999 && score !== 0 && oldScore !== 9999) {
      playersMatchedScores[playerIndex][holeNumber] = false
    } else {
      playersMatchedScores[playerIndex][holeNumber] = true
    }
    let scoreChanged = false
    playersMatchedScores.map (matchedArray => {
      if (matchedArray[holeNumber] === false ) {
        scoreChanged = true
      }
    })
    if (scoreChanged) {
      teamMatchedScores[holeNumber] = false
    } else {
      teamMatchedScores[holeNumber] = true
    }

    if (score !== 9999 && score !== 0 && !(typeof score === 'string' && score.includes('X'))) {
      scores[holeNumber] = score
    } else if (isX) {
      scores[holeNumber] = 'X'
    } else {
      scores[holeNumber] = undefined
    }

    const hasAllOutScores = !scores.slice(0, 9).includes(undefined) && !scores.slice(0, 9).includes(null)
    const hasAllOutTeamScores = !grossTeamScores.slice(0, 9).includes(undefined) && !grossTeamScores.slice(0, 9).includes(null)

    const hasAllInScores = !scores.slice(9).includes(undefined) && !scores.slice(9).includes(null)
    const hasAllInTeamScores = !grossTeamScores.slice(9).includes(undefined) && !grossTeamScores.slice(9).includes(null)

    if ( holeNumber < 9 ) {
      outScore = scores.slice(0, 9).reduce((partialSum, s) => partialSum + (s === 'X' ? 0 : s), 0)
      outScore = hasAllOutScores ? outScore : undefined

      outTeamScore = grossTeamScores.slice(0, 9).reduce((partialSum, s) => partialSum + (s === 'X' ? 0 : s), 0)
      outTeamScore = hasAllOutTeamScores ? outTeamScore : undefined
      stablefordOut = parseInt(stablefordOut) - parseInt(oldStablefordPoint) + parseInt(newStablefordValue)
    } else if (holeNumber >= 9) {
      inScore = scores.slice(9).reduce((partialSum, s) => partialSum + (s === 'X' ? 0 : s), 0)
      inScore = hasAllInScores ? inScore : undefined

      inTeamScore = grossTeamScores.slice(9).reduce((partialSum, s) => partialSum + (s === 'X' ? 0 : s), 0)
      inTeamScore = hasAllInTeamScores ? inTeamScore : undefined
      stablefordIn = parseInt(stablefordIn) - parseInt(oldStablefordPoint) + parseInt(newStablefordValue)
    }

    stablefordTotal = parseInt(stablefordTotal) - parseInt(oldStablefordPoint) + parseInt(newStablefordValue)
    stablefordAggregated = parseInt(stablefordAggregated) - parseInt(oldStablefordPoint) + parseInt(newStablefordValue)
    if (hasAllInScores && hasAllOutScores) {
      roundScore = outScore + inScore
      totalScore = parseInt(totalScore) - allScores.round_score + roundScore
    }
    if (hasAllInTeamScores && hasAllOutTeamScores) {
      roundTeamScore = outTeamScore + inTeamScore
      totalTeamScore = parseInt(totalTeamScore) - teamTotals.round_score + roundTeamScore
    }
    
    allScores.scores_array = scores
    allScores.in = inScore
    allScores.out = outScore
    allScores.round_score = roundScore
    allScores.total_score = totalScore
    this.setState({
      scores: this.state.scores.map((score, index) => index === playerIndex ? allScores : score),
      teamTotals: {...teamTotals,
        scores: grossTeamScores,
        out: outTeamScore,
        in: inTeamScore,
        round_score: roundTeamScore,
        total_score: totalTeamScore,
      },
      playersMatchedScores: playersMatchedScores,
      teamMatchedScores: teamMatchedScores,
      stablefordPoints: {...stablefordPoints,
        stableford_points_array: stablefordPointsArray,
        stableford_out: stablefordOut,
        stableford_in: stablefordIn,
        stableford_total: stablefordTotal,
        aggregated_total: stablefordAggregated,
      }})

    if (score >= 2) {
      const nextField = document.querySelectorAll(`input[name=score-${holeNumber + 1}]`)[playerIndex]
      if (nextField !== null) {
        nextField.focus()
      }
    }
  }

  keyPress(playerIndex, holeNumber, event) {
    switch (event.key) {
      case 'ArrowLeft':
        event.preventDefault()
        this.moveToLeft(playerIndex, holeNumber)
        break
      case 'ArrowRight':
        event.preventDefault()
        this.moveToRight(playerIndex, holeNumber)
        break
      case 'Backspace':
        this.backSpacePressed(playerIndex, holeNumber, event)
        break
      case 'Tab':
        event.preventDefault()
        this.tabPressed(playerIndex, holeNumber, event)
        break
    }
  }

  moveToLeft(playerIndex, holeNumber) {
    const previousField = document.querySelectorAll(`input[name=score-${holeNumber + -1}]`)[playerIndex]
    if (previousField !== null) {
      previousField.focus()
    }
  }

  moveToRight(playerIndex, holeNumber) {
    const nextField = document.querySelectorAll(`input[name=score-${holeNumber + 1}]`)[playerIndex]
    if (nextField !== null) {
      nextField.focus()
    }
  }

  backSpacePressed(playerIndex, holeNumber, event) {
    const scores = [ ...this.state.scores ]
    if ( scores[holeNumber] === 0 || scores[holeNumber] === null) {
      event.preventDefault()
      this.moveToLeft(playerIndex, holeNumber)
    }
  }

  tabPressed(playerIndex, holeNumber, event) {
    if (event.shiftKey) {
      this.moveToLeft(playerIndex, holeNumber)
    } else {
      this.moveToRight(playerIndex, holeNumber)
    }
  }

  handleOrientationChange() {
    const focusedInput = document.activeElement
    if (focusedInput !== null) {
      focusedInput.blur()
      setTimeout( () => focusedInput.focus(), 100)
    }
  }

  onSave() {
    const { scores } = this.state
    const { verifyScore } = this.props
    verifyScore(scores)
  }

  onShow() {
    const firstInput = document.querySelector('input[name=score-0]')
    if (firstInput !== null) {
      setTimeout( () => firstInput.focus(), 100)
    }
    window.glg.new_design_shared.applyCustomScrollbar('.' + this.props.step + '_verification_table', 'horizontal')
    window.addEventListener('orientationchange', this.handleOrientationChange)
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.handleOrientationChange)
  }

  render() {
    const { players, teamName, show, tableTitle, isMultiRound, step, showShots, closePopup, scoresData, tournamentType, isStableford } = this.props
    const title = teamName
    let saveButtonEnabled = true
    const hasScores = []
    this.state.scores.map( (scoreArray, index) => {
      hasScores[index] = scoreArray.scores_array.map( (score, sindex) => {
        const allX = this.state.scores.every(sa => Array.isArray(sa.original_scores[sindex]) ? sa.original_scores[sindex].includes('X') : typeof sa.original_scores[sindex] === 'string' && sa.original_scores[sindex].includes('X')
        )        
        if (allX && isStableford) {
          return true
        } else if ((score !== 0 && score !== undefined && score !== null && scoreArray.original_scores[sindex] !== null)
             || scoreArray.original_scores[sindex] === null) {
          return true
        } else {
          return false
        }
      })
    })
    const boolArr = hasScores.reduce((a, b) => a.map((c, i) => b[i] || c))
    if (boolArr.includes(false)) {
      saveButtonEnabled = false
    }

    return (
      <GlgPopup modalClass='modal-lg large-popup'
                title={ title }
                show={ show }
                onClose={ closePopup }
                onSave={ this.onSave }
                onShow={ this.onShow }
                manualClose={ true }
                saveButtonEnabled={ saveButtonEnabled }
                saveButtonText='Verify Scores'>

        <div className={step + '_verification_table btsp_ps_container btsp_fheader_table_container'}>
          <table className={step + '_verification btsp_fheader_table'}>
            <thead>
              <tr className='header'>
                <th colSpan={ isMultiRound ? '27' : '26'}>{tableTitle}</th>
              </tr>
            </thead>
            <tbody>
              {
                players.map( (player, index) => {
                  const scoreData = { ...player.score_data }
                  const teamScoreData = scoresData[index]
                  const shots = [ ...player.shots_data ]
                  const pars = [ ...player.pars_array ]
                  const {teamMatchedScores, teamTotals, stablefordPoints} = this.state
                  return (
                    <Fragment key={player.id}>
                        { index === 0 &&
                        <PopupHolesRow isMultiRound={isMultiRound}
                                       matchedScores={teamMatchedScores}/> }
                        { index === 0 &&
                        <PopupParRow pars={pars}
                                    isMultiRound={isMultiRound}
                                    matchedScores={teamMatchedScores}/> }
                        <PopupScoresTeamRow scoreData={scoreData}
                                    isMultiRound={isMultiRound}
                                    matchedScores={teamMatchedScores}
                                    player={player}/>
                        { showShots &&
                        <PopupShotsTeamRow shots={shots}
                                   isMultiRound={isMultiRound}
                                   matchedScores={teamMatchedScores}
                                   player={player}/> }
                      
                        <PopupVerifyScoresTeamComponent isMultiRound={isMultiRound}
                                                    scoreData={teamScoreData}
                                                    handleScoreChange={this.handleScoreChange}
                                                    matchedScores={teamMatchedScores}
                                                    handleKeyPress={this.keyPress}
                                                    playerIndex={index}/>
                      
                        { tournamentType === 'BestBall' && index === players.length - 1 &&
                        <PopupTeamRow scoreData={teamTotals}
                                      isMultiRound={isMultiRound}
                                      matchedScores={teamMatchedScores}/>
                        }
                       { isStableford && tournamentType === 'BestBall' && index === players.length - 1 &&
                       <PopupStablefordPointsRow scoreData={stablefordPoints}
                                                 isMultiRound={isMultiRound}
                                                 matchedScores={teamMatchedScores}/>
                       }

                    </Fragment>

                  )
                })
              }
            </tbody>
          </table>
        </div>
      </GlgPopup>
    )
  }
}

VerificationTeamPopupComponent.propTypes = {
  players: PropTypes.array,
  teamName: PropTypes.string,
  show: PropTypes.bool,
  closePopup: PropTypes.func,
  tableTitle: PropTypes.string,
  isMultiRound: PropTypes.bool,
  autoFillScore: PropTypes.bool,
  showShots: PropTypes.bool,
  verifyScore: PropTypes.func,
  step: PropTypes.string,
  scoresData: PropTypes.array,
  tournamentType: PropTypes.string,
  useTeamTotals: PropTypes.bool,
  teamTotals: PropTypes.object,
  stablefordPoints: PropTypes.object,
  isStableford: PropTypes.bool,
}

export default VerificationTeamPopupComponent
