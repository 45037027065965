import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { displayMode, DISPLAY_MODES } from '../../actions'

const EditTabs = ({ dispatch, tabs }) => {
  return (
    <div>
      <button type="button"
              onClick={(e) => { e.preventDefault(); dispatch(displayMode(DISPLAY_MODES.TABS)) } }
              className="manage-tabs-btn"
              disabled={ tabs.length === 1 && tabs[0].title === '' && tabs[0].titles === '' }>
        { window.I18n.t('multi_widgets.manage_tabs') }
      </button>
    </div>
  )
}

EditTabs.propTypes = {
  dispatch: PropTypes.func,
  tabs: PropTypes.array,
}

export default connect()(EditTabs)
