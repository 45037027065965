import { callAPI, showErrorNotification, showNotification, getAuthenticityToken } from 'Shared/helpers'

export const ActionTypes = {
  FETCH_MORE_DATA_REQUEST: 'FETCH_MORE_DATA_REQUEST',
  FETCH_MORE_DATA_RECEIVED: 'FETCH_MORE_DATA_RECEIVED',
  REFRESH_DATA: 'REFRESH_DATA',
  CHANGE_FILTERS: 'CHANGE_FILTERS',
  CHANGE_SEASON: 'CHANGE_SEASON',
  CHANGE_STRENGTH: 'CHANGE_STRENGTH',
  SET_STICKY: 'SET_STICKY',
  CLEAR_STICKY: 'CLEAR_STICKY',
  CANCEL_STICKY: 'CANCEL_STICKY',
  SET_SCROLL_Y: 'SET_SCROLL_Y',
  ADD_MEMBERS_TO_STICKY: 'ADD_MEMBERS_TO_STICKY',
  REMOVE_MEMBERS_FROM_STICKY: 'REMOVE_MEMBERS_FROM_STICKY',
  ADD_MEMBERS_TO_SPLIT: 'ADD_MEMBERS_TO_SPLIT',
  REMOVE_MEMBERS_FROM_SPLIT: 'REMOVE_MEMBERS_FROM_SPLIT',
  ADD_MEMBERS_TO_MEMBER_CARD: 'ADD_MEMBERS_TO_MEMBER_CARD',
  REMOVE_MEMBERS_FROM_MEMBER_CARD: 'REMOVE_MEMBERS_FROM_MEMBER_CARD',
  INCREMENT_PAGE: 'INCREMENT_PAGE',
  SHOW_HIDE_MEMBERS: 'SHOW_HIDE_MEMBERS',
  SHOW_MEMBERS_REQUEST: 'SHOW_MEMBERS_REQUEST',
  SPLIT_MEMBERS_FROM_MEMBER_CARD: 'SPLIT_MEMBERS_FROM_MEMBER_CARD',
}

export const fetchMoreData = () => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.INCREMENT_PAGE,
  })
  const state = getState()
  const url = state.misc.fetchInitialDataUrl
  const params = {
    customer_id: state.misc.customerId,
    filters: state.filters,
    page: state.misc.page,
  }
  dispatch({ type: ActionTypes.FETCH_MORE_DATA_REQUEST })
  if ( !state.memberCards.isLoading ) {
    callAPI(url, 'GET', params)
        .then((json) => {
          dispatch({
            type: ActionTypes.FETCH_MORE_DATA_RECEIVED,
            data: json,
          })
        })
        .catch(error => {
          showErrorNotification(error || window.I18n.t('member_cards.some_error'))
        })
  }
}

export const fetchMoreMembers = (memberCardId) => async (dispatch, getState) => {
  const state = getState()
  const url = state.misc.fetchMoreMembersUrl

  const params = {
    id: memberCardId,
  }

  await dispatch({
    type: ActionTypes.SHOW_MEMBERS_REQUEST,
    mcID: memberCardId,
  })

  const response = await callAPI(url, 'GET', params)

  dispatch({
    type: ActionTypes.SHOW_HIDE_MEMBERS,
    members: response.members,
    id: response.memberCardId,
  })
}

export const fetchLessMembers = (memberCardId) => async (dispatch, getState) => {
  const state = getState()
  const url = state.misc.fetchLessMembersUrl

  const params = {
    id: memberCardId,
  }

  const response = await callAPI(url, 'GET', params)

  dispatch({
    type: ActionTypes.SHOW_HIDE_MEMBERS,
    members: response.members,
    id: response.memberCardId,
  })
}

export const refreshData = () => (dispatch, getState) => {
  const state = getState()
  const url = state.misc.fetchInitialDataUrl
  const params = {
    customer_id: state.misc.customerId,
    filters: state.filters,
  }
  callAPI(url, 'GET', params)
    .then(response => {
      dispatch({
        type: ActionTypes.REFRESH_DATA,
        data: response,
      })
    })
    .catch(error => {
      showErrorNotification(error || window.I18n.t('member_cards.some_error'))
    })
}

export const changeFilters = (newFilters) => (dispatch) => {
  dispatch({
    type: ActionTypes.CHANGE_FILTERS,
    data: newFilters,
  })
  dispatch(refreshData())
}

export const changeSeason = (newSeason) => (dispatch) => {
  dispatch({
    type: ActionTypes.CHANGE_SEASON,
    data: newSeason,
  })
  dispatch(refreshData())
}

export const changeStrength = (newStrength) => (dispatch) => {
  dispatch({
    type: ActionTypes.CHANGE_STRENGTH,
    data: newStrength,
  })
  dispatch(refreshData())
}

const recalculateSeasonPoints = (url, runAsResque) => {
  const params = {
    runAsResque: runAsResque,
  }
  return async () => {
    return callAPI(url, 'PUT', params)
      .then( response => {
        response.error && showErrorNotification(response.error)
      })
      .catch(error => {
        showErrorNotification(error || window.I18n.t('member_cards.season_points_error'))
      })
  }
}

export const saveData = () => async (dispatch, getState) => {
  const state = getState()
  const params = { 
    changedMemberCards: JSON.stringify(state.misc.changedMemberCards),
    changedMembers: JSON.stringify(state.misc.changedMembers),
    splittedMembers: JSON.stringify(state.misc.splittedMembers),
  }
  const url = state.misc.editMemberCardURL
  callAPI(url, 'PUT', params)
    .then( response => {
      response.error ? showErrorNotification(response.error) : showNotification(window.I18n.t('member_cards.changes_done_success'))
      dispatch(recalculateSeasonPoints(state.misc.recalculateUrl, false))
    })
    .catch(error => {
      showErrorNotification(error || window.I18n.t('member_cards.some_error'))
    })
    .finally(() => {
      dispatch(refreshData())
    })
}

export const setSticky = (memberCard, index, type) => async (dispatch, getState) => {
  if (type === 'SPLIT') {
    await dispatch(fetchMoreMembers(memberCard['id']))
  } else if (type === 'MERGE') {
    await dispatch(fetchLessMembers(memberCard['id']))
  }
  const state = getState()
  const memberCardId = memberCard['id']
  const members = state.members[memberCardId]
  dispatch({
    type: ActionTypes.SET_STICKY,
    data: {
      memberCard: memberCard,
      members: members,
      index: index,
      type: type,
    },
  })
}

export const clearSticky = () => (dispatch, getState) => {
  const state = getState()
  const newMembers = state.sticky.newMembers
  const newMemberCardId = state.sticky.index

  newMembers.forEach(member => {
    dispatch(removeMembersFromMemberCard([ member ], member.member_card_id ))
    member.member_card_id = newMemberCardId
  })
  if (state.sticky.type === 'MERGE') {
    dispatch(addMembersToMemberCard(newMembers, newMemberCardId))
  } else if (state.sticky.type === 'SPLIT') {
    dispatch(splitMembersFromMemberCard(newMembers))
  }

  dispatch(saveData())
  dispatch({
    type: ActionTypes.CLEAR_STICKY,
  })
}

export const cancelSticky = () => (dispatch, getState) => {
  const state = getState()
  const memberCardId = state.sticky.index
  dispatch(fetchLessMembers(memberCardId))
  dispatch({
    type: ActionTypes.CLEAR_STICKY,
  })
}

export const addAllMembersToSticky = (memberCardId) => async (dispatch, getState) => {
  await dispatch(fetchMoreMembers(memberCardId))
  const state = getState()
  const members = state.members[memberCardId]
  dispatch({
    type: ActionTypes.ADD_MEMBERS_TO_STICKY,
    members: members,
  })
}

export const addMembersToSticky = (members) => async (dispatch) => {  
  dispatch({
    type: ActionTypes.ADD_MEMBERS_TO_STICKY,
    members: members,
  })
}

export const removeMembersFromSticky = (members) => (dispatch) => {
  dispatch({
    type: ActionTypes.REMOVE_MEMBERS_FROM_STICKY,
    members: members,
  })
}

export const addMembersToSplit = (members) => (dispatch) => {  
  dispatch({
    type: ActionTypes.ADD_MEMBERS_TO_SPLIT,
    members: members,
  })
}

export const removeMembersFromSplit = (members) => (dispatch) => {
  dispatch({
    type: ActionTypes.REMOVE_MEMBERS_FROM_SPLIT,
    members: members,
  })
}

export const removeMembersFromMemberCard = (members, id) => (dispatch) => {
  dispatch({
    type: ActionTypes.REMOVE_MEMBERS_FROM_MEMBER_CARD,
    members: members,
    id: id,
  })
}

export const addMembersToMemberCard = (members, id) => (dispatch) => {
  dispatch({
    type: ActionTypes.ADD_MEMBERS_TO_MEMBER_CARD,
    members: members,
    id: id,
  })
}

export const splitMembersFromMemberCard = (members) => (dispatch) => {
  dispatch({
    type: ActionTypes.SPLIT_MEMBERS_FROM_MEMBER_CARD,
    members: members,
  })
}

export const saveSettings = (mcIdentifierID, handicapNamingRule, userNamingRule) => (dispatch, getState) => {

  const state = getState()

  const params = { 
    newCustomField: mcIdentifierID,
    handicapNamingRule: handicapNamingRule,
    userNamingRule: userNamingRule,
  }
  const url = state.misc.updateMatchingRulesUrl
  callAPI(url, 'PUT', params)
    .then( response => {
      response.error ? 
        showErrorNotification(response.error) : 
        showNotification(response.success_message)
    })
    .catch(error => {
      showErrorNotification(error || window.I18n.t('member_cards.some_error'))
    })
    .finally(() => {
      dispatch(refreshData())
    })
}

export const syncMrRecord = (memberCardId) => (dispatch, getState) => {

  const state = getState()
  
  const params = { 
    memberCardId: memberCardId,
  }
  const url = state.misc.syncMrUrl

  callAPI(url, 'PUT', params)
    .then(response => {
      response.error ? showErrorNotification(response.error) : showNotification(window.I18n.t('member_cards.single_mr_sync_success'))
    })
    .catch(error => {
      showErrorNotification(error || window.I18n.t('member_cards.some_error'))
    })
    .finally(() => {
      dispatch(refreshData())
    })
}

export const syncAllMrRecords = () => (dispatch, getState) => {

  const state = getState()
 
  const url = state.misc.syncAllMrUrl

  const params = {}

  callAPI(url, 'PUT', params)
    .then(response => {
      response.error ? showErrorNotification(response.error) : showNotification(window.I18n.t('member_cards.mass_mr_sync_start'))
    })
    .catch(error => {
      showErrorNotification(error || window.I18n.t('member_cards.some_error'))
    })
    .finally(() => {
      dispatch(refreshData())
    })
}

export const relinkAllGolfers = () => (dispatch, getState) => {
  const state = getState()

  const url = state.misc.relinkAllGolfersUrl

  const params = {}

  callAPI(url, 'GET', params)
    .then(response => {
      response.error ? showErrorNotification(response.error) : showNotification(window.I18n.t('member_cards.all_member_relinked'))
    })
    .catch(error => {
      showErrorNotification(error || window.I18n.t('member_cards.some_error'))
    })
    .finally(() => {
      dispatch(refreshData())
    })
}

export const relinkMembers = (memberCardId) => (dispatch, getState) => {

  const state = getState()
  const url = state.misc.relinkUrl

  const params = { 
    memberCardId: memberCardId,
  }

  callAPI(url, 'GET', params)
    .then( response => {
      response.error ? showErrorNotification(response.error) : showNotification(window.I18n.t('member_cards.relinking_members'))
    })
    .catch(error => {
      showErrorNotification(error || window.I18n.t('member_cards.some_error'))
    })
    .finally(() => {
      dispatch(refreshData())
    })
 
}

export const deleteRelinkMembers = (memberCardId) => (dispatch, getState) => {

  const state = getState()
  const url = state.misc.deleteRelinkUrl + '/' + memberCardId

  const params = { 
    memberCardId: memberCardId,
  }

  callAPI(url, 'DELETE', params, new Headers({ 'Accept': 'application/json', 'X-CSRF-Token': getAuthenticityToken(), 'Content-Type': 'application/json'}))
    .then( response => {
      response.error ? showErrorNotification(response.error) : showNotification(window.I18n.t('member_cards.relinking_members'))
    })
    .catch(error => {
      showErrorNotification(error || window.I18n.t('member_cards.some_error'))
    })
    .finally(() => {
      dispatch(refreshData())
    })
}

export const checkResqueStatus = () => (dispatch, getState) => {

  const state = getState()

  const url = state.misc.checkResqueStatusUrl

  const params = {}

  callAPI(url, 'GET', params)
    .then( response => {
      response.error && showErrorNotification(response.error)
      if (response.mrSyncStatus === 'completed') {
        showNotification(window.I18n.t('member_cards.multiple_mr_sync_success'))
        dispatch(refreshData())
      }
    })
    .catch(error => {
      showErrorNotification(error || window.I18n.t('member_cards.some_error'))
    })
}

export const recalculateAll = () => (dispatch, getState) => {
  const state = getState()

  dispatch(recalculateSeasonPoints(state.misc.recalculateUrl, true))

  showNotification(window.I18n.t('member_cards.recalculation_started'))
}
