import { ActionTypes } from '../actions'
import _ from 'lodash'

import {
  reorderListPositions,
  deleteListItem,
  addListItem,
} from 'Shared/list_helpers'

const {
  FETCH_INITIAL_DATA_RECEIVED,
  CHANGE_BOOKMARKED_STATUS,
  REORDER_BOOKMARKS,
  CLEAR_ALL_BOOKMARKS,
} = ActionTypes

/*
const DEFAULT_BOOKMARK_DATA = {
  navigatorOptionId: '',
  position: 1, // 1-based
}
*/

// The bookmark data is indexed by the navigator id
const bookmarks = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INITIAL_DATA_RECEIVED:
      return {
        ...action.data.bookmarks,
      }
    case CHANGE_BOOKMARKED_STATUS:
      if (action.status) {
        return addListItem(state, action.id, {
          id: action.id,
        })
      } else {
        return deleteListItem(state, action.id)
      }
    case REORDER_BOOKMARKS: {
      const fromPosition = state[action.fromOptionId].position
      const toPosition = state[action.toOptionId].position
      const reorderedBookmarks = reorderListPositions(_.values(state), fromPosition, toPosition)
      return _.keyBy(reorderedBookmarks, 'id')
    }
    case CLEAR_ALL_BOOKMARKS:
      return {}
    default:
      return state
  }
}

export default bookmarks
