import { connect } from 'react-redux'
import MembersLeftSidebarComponent from '../components/members_left_sidebar_component'
import { openFieldsSidebar, closeFieldsSidebar, fetchFieldsData, openHandleFormatSidebar, 
  closeHandleFormatSidebar, openAddGolfersManuallyPopup, openUploadSpreadsheetSidebar, 
  closeUploadSpreadsheetSidebar,
} from '../actions/index'

const mapStateToProps = (state) => ({
  isOpen: state.leftSidebarFields.isOpen,
  isMembersDataLoading: state.membersData.isLoading,
  imageUrls: state.leftSidebarFields.imageUrls,
  showAddGolfersManuallyPopup: state.addNewMembers.showAddGolfersManuallyPopup,
  isCustomerRosterLeague: state.misc.isCustomerRosterLeague,
  canManageLeague: state.misc.canManageLeague,
  isDailySync: state.misc.isDailySync,
  customerIsGolfAdvisor: state.misc.customerIsGolfAdvisor,
  newLeagueImportPath: state.misc.newLeagueImportPath,
  isTourManager: state.misc.isTourManager,
  downloadLeagueSpreadsheetfilesPath: state.misc.downloadLeagueSpreadsheetfilesPath,
  schedulePrintLeagueMembersPath: state.misc.schedulePrintLeagueMembersPath,
  bulkUploadPhotosPath: state.misc.bulkUploadPhotosPath,
})

const mapDispatchToProps = (dispatch) => ({
  openFieldsSidebar: () => dispatch(openFieldsSidebar()),
  closeFieldsSidebar: () => dispatch(closeFieldsSidebar()),
  openHandleFormatSidebar: () => dispatch(openHandleFormatSidebar()),
  closeHandleFormatSidebar: () => dispatch(closeHandleFormatSidebar()),
  fetchFieldsData: () => dispatch(fetchFieldsData()),
  openAddGolfersManuallyPopup: () => dispatch(openAddGolfersManuallyPopup()),
  openUploadSpreadsheetSidebar: () => dispatch(openUploadSpreadsheetSidebar()),
  closeUploadSpreadsheetSidebar: () => dispatch(closeUploadSpreadsheetSidebar()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MembersLeftSidebarComponent)
