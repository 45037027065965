import _ from 'lodash'
import { PopupActionTypes } from 'Shared/actions'
export { openPopup, closePopup } from 'Shared/actions'
import { showErrorNotification, showNotification, objectToSnakeCase } from 'Shared/helpers'

export const ActionTypes = {
  ...PopupActionTypes,
  UPDATE_NAVIGATOR_OPTION: 'UPDATE_NAVIGATOR_OPTION',
  DELETE_NAVIGATOR_OPTION: 'DELETE_NAVIGATOR_OPTION',
  CREATING_NAVIGATOR_OPTION: 'CREATING_NAVIGATOR_OPTION',
  CREATED_NAVIGATOR_OPTION: 'CREATED_NAVIGATOR_OPTION',
}

export const updateOption = (id, data) => (dispatch, getState) => {
  const url = getState().navigatorOptions[id].updateURL
  const optionName = getState().navigatorOptions[id].name
  $.ajax({
    method: 'PATCH',
    url,
    dataType: 'json',
    data: {
      format: 'json',
      ...objectToSnakeCase(data),
    },
    success: (responseData) => {
      if (responseData.errorMessages && !_.isEmpty(responseData.errorMessages)) {
        showErrorNotification(`Error while updating navigator option ${ optionName }. ${ responseData.errorMessages }`)
      }
    },
    error: () => {
      showErrorNotification(`Internal error while updating navigator option ${ optionName }.`)
    },
  })
  dispatch({
    type: ActionTypes.UPDATE_NAVIGATOR_OPTION,
    id,
    data: {
      ...data.navigatorOption,
      helpLinks: data.helpLinks,
    },
  })
}

export const deleteOption = id => (dispatch, getState) => {
  const url = getState().navigatorOptions[id].updateURL
  const optionName = getState().navigatorOptions[id].name
  $.ajax({
    method: 'DELETE',
    url,
    success: (responseData) => {
      if (responseData.errorMessages && !_.isEmpty(responseData.errorMessages)) {
        showErrorNotification(`Error while deleting navigator option ${ optionName }. ${ responseData.errorMessages }`)
      } else {
        showNotification(`Successfully deleted navigator option ${ optionName }`)
      }
    },
    error: () => {
      showErrorNotification(`Internal error while deleting navigator option ${ optionName }.`)
    },
  })
  dispatch({
    type: ActionTypes.DELETE_NAVIGATOR_OPTION,
    id,
  })
}

export const createOption = data => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.CREATING_NAVIGATOR_OPTION,
  })

  const url = getState().misc.createURL
  const optionName = data.navigatorOption.name
  $.ajax({
    method: 'POST',
    url,
    data: {
      format: 'json',
      ...objectToSnakeCase(data),
    },
    success: (responseData) => {
      if (responseData.errorMessages && !_.isEmpty(responseData.errorMessages)) {
        showErrorNotification(`Error while creating navigator option ${ optionName }. ${ responseData.errorMessages }`)
      } else {
        dispatch({
          type: ActionTypes.CREATED_NAVIGATOR_OPTION,
          data: {
            id: responseData.id,
            ...data.navigatorOption,
            updateURL: responseData.updateURL,
            helpLinks: data.helpLinks,
          },
        })
        showNotification(`Successfully created navigator option ${ optionName }`)
      }
    },
    error: () => {
      showErrorNotification(`Internal error while creating navigator option ${ optionName }.`)
    },
  })
}
