import { ActionTypes } from '../actions' 

const {
  OPEN_TEAM_VERIFICATION_POPUP,
  CLOSE_TEAM_POPUP,
} = ActionTypes

const initialState = {
  visible: false,
  players: [],
  scoresData: [],
  teamName: '',
  teeTime: '',
  foursomeId: '',
  teamTotals: {
    in: null,
    out: null,
    round_score: null,
    total_score: null,
    scores: [],
  },
  stablefordPoints: {
    stableford_points_arary: [],
    stableford_out: null,
    stableford_in: null,
    stableford_total: null,
  },
}

const teamPopup = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_TEAM_VERIFICATION_POPUP:
      return {
        ...state,
        visible: true,
        players: action.players,
        scoresData: action.scoresData,
        teamName: action.teamName,
        teeTime: action.teeTime,
        foursomeId: action.foursomeId,
        teamTotals: action.teamTotals,
        stablefordPoints: action.stablefordPoints,
      }
    case CLOSE_TEAM_POPUP:
      return {
        ...state,
        visible: false,
        players: [],
        scoresData: action.scoresData,
        teamName: '',
        teeTime: '',
        foursomeId: '',
        teamTotals: {
          in: null,
          out: null,
          round_score: null,
          total_score: null,
          scores: [],
        },
        stablefordPoints: {
          stableford_points_arary: [],
          stableford_out: null,
          stableford_in: null,
          stableford_total: null,
        },
      }
    default:
      return state
  }
}

export default teamPopup
