import React from 'react'
import PropTypes from 'prop-types'
import rootReducer from '../../reducers'
import configureStore from '../../../shared/configure_store'
import { GlobalProvider } from '../../../shared/global_context'
import FilterWrapper from './filter_wrapper'
import { DISPLAY_MODES, setBlockIndex, setColumnIndex, setTabIndex } from '../../actions'

const Root = ({
  initialState = null,
  newPagePath = null,
  usesTranslations = null,
  availableLanguages = null,
  currentLanguage = null,
}) => {
  // Default state
  let state = {
    tabs: [],
    blocks: [],
    columns: [],
    filters: {
      active_tab: -1000,
      display_mode: DISPLAY_MODES.DEFAULT,
      widgets: [],
      active_column: null,
      active_block: null,
      position: null,
      iframe_pointer_options: 'auto',
      draggable_element: null,
      dragged_over_element: null,
      dragged_over_position: null,
      new_page_path: null,
      form_submitted: null,
      available_languages: availableLanguages,
      uses_translations: usesTranslations,
      current_language: currentLanguage,
    },
  }
  // Setup state from controller
  const newState = JSON.parse(initialState)
  if (newState !== null) {
    if (newState.tabs.length !== 0) {
      const colPosition = newState.columns.length !== 0 ? newState.columns.sort( (a, b) => a.index < b.index ? 1 : -1 )[0].index : 0
      setColumnIndex(colPosition)
      setTabIndex(newState.tabs.sort( (a, b) => a.index < b.index ? -1 : 1 )[0].position)
      state = { ...state, filters: { ...state.filters, active_tab: newState.tabs[0].id, widgets: newState.sections, new_page_path: newPagePath }}
      newState.tabs.map(tab => {
        state = { ...state, tabs: [ ...state.tabs, { id: tab.id, title: tab.title, titles: tab.titles, editing: false, deleting: false, index: tab.position } ] }
      })
      let blockPosition = 0
      newState.blocks.sort( (a, b) => a.position < b.position ? -1 : 1 ).map(block => {
        state = { ...state, blocks: [ ...state.blocks, { id: block.id, title: block.title, titles: block.titles, layout: `${block.layout}`, tabId: block.tab_id, index: blockPosition++ } ] }
      })
      setBlockIndex(blockPosition)
      newState.columns.map(column => {
        state = { ...state, columns: [ ...state.columns, { id: column.id, position: (column.position === 0 ? 'left' : 'right'), height: column.height, blockId: column.block_id, index: column.index, title: column.title, titles: column.titles, view_more: column.view_more, widget_id: column.widget_id, display: true } ] }
      })
    } else {
      state = { ...state, filters: { ...state.filters, widgets: newState.sections, new_page_path: newPagePath }}
    }
  }

  const store = configureStore(rootReducer, state)
  const globalData = {}

  // expose the dispatch function to non-React code
  // this is needed because we want to open popups from the dropdown menu
  window.glg.dispatch = store.dispatch

  // inject the state in the form before it is submitted
  $('form').submit( () => {
    $('<input />').attr('type', 'hidden')
        .attr('name', 'multi_widgets_state')
        .attr('value', JSON.stringify(store.getState()))
        .appendTo('form')
    return true
  })

  return (
    <GlobalProvider data={ globalData } store={ store }>
      <FilterWrapper />
    </GlobalProvider>
  )
}

Root.propTypes = {
  initialState: PropTypes.any,
  newPagePath: PropTypes.string,
  usesTranslations: PropTypes.bool,
  availableLanguages: PropTypes.any,
  currentLanguage: PropTypes.string,
}

export default Root
