import { connect } from 'react-redux'
import CourseBoardComponent from '../components/course_board_component'

import { refreshData } from '../actions'

const mapStateToProps = (state) => {

  return {
    roundGgid: state.misc.roundGgid,
    lines: state.misc.lines,
    showMessage: state.misc.showMessage,
    message: state.misc.message,
    score: state.misc.score,
    boardAvailable: state.misc.boardAvailable,
    showCourse: state.misc.showCourse,
    lastUpdate: state.misc.lastUpdate,
    initialShowMessage: state.misc.initialShowMessage,
    initialNoRows: state.misc.initialNoRows,
  }
}

const mapDispatchToProps = (dispatch) => ({
  refreshData: (message) => dispatch(refreshData(message)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourseBoardComponent)
