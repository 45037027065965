import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Row, Col } from 'react-bootstrap'

class CreditBookComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    if (this.props.cbEnabled) {
      return <div className="transactions"><Row className="title"><span>{window.I18n.t('credit_balance')}</span></Row><Col xs={ 12 }><p className="no-info">{`${window.I18n.t('credit_books.player_dashboard.available_balance')}: ${this.props.currency} ${this.props.cbBalance ? this.props.cbBalance : 0} (${window.I18n.t('credit_books.player_dashboard.credit')})`}</p></Col></div>
    } else {
      return null
    }
    
  }
}

CreditBookComponent.propTypes = {
  editRegistrationLink: PropTypes.string.isRequired,
  dataIsLoading: PropTypes.bool,
  creditBook: PropTypes.array.isRequired,
  target: PropTypes.string,
  MRR: PropTypes.bool,
  cbEnabled: PropTypes.bool,
  cbBalance: PropTypes.number,
  currency: PropTypes.string,
}

export default CreditBookComponent
