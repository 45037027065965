import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'react-loader-spinner'
import { Row, Col } from 'react-flexbox-grid'

const GlgLoadingSpinner = ({ show, className, height, width, color }) => {
  if (show) {
    return <Row center="xs" className={ `glg-loading-spinner ${ className || '' }` }><Col xs={12}><Loader type="Oval" color={ color } height={ height } width={ width }></Loader></Col></Row>
  } else {
    return null
  }
}

GlgLoadingSpinner.propTypes = {
  show: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
}

GlgLoadingSpinner.defaultProps = {
  show: true,
  height: 50,
  width: 50,
  color: '0088CE',
}

export default GlgLoadingSpinner
