import { connect } from 'react-redux'
import HoleByHoleTableComponent from '../components/hole_by_hole_table_component'

const mapStateToProps = (state) => {

  return {
    lines: state.misc.frontLines,
    showMessage: state.misc.showMessage,
    message: state.misc.message,
    score: state.misc.score,
    showCourse: state.misc.showCourse,
    tees: state.misc.tees,
    roundsCount: state.misc.roundsCount,
    showRounds: state.misc.showRounds,
    showTotal: state.misc.showTotal,
  }
}

export default connect(
  mapStateToProps,
  null,
)(HoleByHoleTableComponent)
