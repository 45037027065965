import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

class MenuItem extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount() {
    if (this.props.active && this.props.type === 'dashboard-menu-round-items') {
      const activeElement = document.getElementsByClassName('dashboard-menu-round-items active')[0]
      if (activeElement !== undefined) {
        const topPos = activeElement.offsetTop
        document.getElementsByClassName('dashbord-menu-rounds-container')[0].scrollTop = (topPos - 140)
      }
    }
  }

  render() {
    const { icon, defaultIcon, url, active, label, title, type, isNewItems } = this.props

    return (
      <Fragment>
        {
          isNewItems
            ? <span className={ `${type} rounds-menu-button-new-items` } onClick={ this.props.openNewItemsModal }>
              <i className={ `${icon} dashbord-menu-items-icon` }></i>
              { label }
            </span>
          : <a href={ url }
               className={ `${type} ${active ? 'active' : '' }` }
               onClick={ this.props.selectMenuItem }
               title={ title !== undefined ? title : '' }
               target={ url === '#' ? '_self' : '_blank'} >
            { icon !== undefined && icon !== '' ? <img src={ icon } className='dashbord-menu-items-icon' /> : null }
            { defaultIcon !== undefined ? <i className={ `${defaultIcon} dashbord-menu-items-icon` }></i> : null }
            { label }
          </a>
        }
      </Fragment>
    )
  }
}

MenuItem.propTypes = {
  icon: PropTypes.string,
  defaultIcon: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  active: PropTypes.bool,
  type: PropTypes.string,
  selectMenuItem: PropTypes.func,
  isNewItems: PropTypes.bool,
  openNewItemsModal: PropTypes.func,
  newItemLabel: PropTypes.string,
}

MenuItem.defaultProps = {
  icon: '',
  active: false,
  url: '#',
  type: 'dashboard-menu-item',
  isNewRound: false,
}

export default MenuItem
