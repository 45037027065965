import { ActionTypes } from '../actions' 

const {
  OPEN_POPUP,
  CLOSE_POPUP,
} = ActionTypes

const initialState = {
  visible: false,
  playerName: '',
  foursomePosition: 0,
  holeId: null,
  holeNumber: 0,
  shots: [],
  isCompleted: false,
  par: 0,
  scoreVerified: false,
}

const popup = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_POPUP:
      return {
        ...state,
        visible: true,
        playerName: action.playerName,
        foursomePosition: action.foursomePosition,
        holeId: action.holeId,
        holeNumber: action.holeNumber,
        shots: action.shots,
        isCompleted: action.isCompleted,
        par: action.par,
        scoreVerified: action.scoreVerified,
      }
    case CLOSE_POPUP:
      return {
        ...state,
        visible: false,
        playerName: '',
        foursomePosition: 0,
        holeId: null,
        holeNumber: 0,
        shots: [],
        par: 0,
        scoreVerified: false,
      }
    default:
      return state
  }
}

export default popup
