import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GlgPopup, GlgPopupForm, GlgLoadingSpinner } from 'SharedComponents'

export class DeleteRosterConfirmationPopup extends Component {
  constructor(props) {
    super(props)
    this.onSave = this.onSave.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  onSave() {
    this.props.deleteEntireRoster()
    window.onbeforeunload = null
  }

  onClose(){
    this.props.closeDeleteRosterConfirmationPopup()
    window.onbeforeunload = null
  }
  
  render() {
    const popUpTitle = ''
    //+ (this.props.disableSaveFields ? 'disabled' : '')
    const continueButtonText = this.props.isDeleteRosterLoading ? (!window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.delete_roster_confirmation_popup.deleting')) : (!window.I18n ? '' : window.I18n.t('buttons.continue').charAt(0)
      .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.continue').slice(1)))
    const disableContinueButton = this.props.isDeleteRosterLoading || !this.props.isRosterDownloaded
    return <GlgPopup title={() => popUpTitle} modalClass="delete_roster_confirmation" show={ this.props.show } onClose={ this.onClose } manualClose={ true } showCloseButton={ false } showSaveButton={ false } customButtons={[
                  { class: 'gdpr_btn btsp_orange_button save_button pull-right btn ' + (disableContinueButton ? 'disabled' : ''), text: continueButtonText, onClick: this.onSave, disabled: disableContinueButton },
                  { class: 'cancel_roster btn button btsp_gray_button', text: !window.I18n ? '' : window.I18n.t('buttons.cancel').charAt(0)
                    .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.cancel').slice(1)), onClick: this.onClose },
                ]}><GlgPopupForm><GlgLoadingSpinner show={ this.props.isLoading } height={ 50 } width={ 50 } className="loading-spinner"></GlgLoadingSpinner><strong>{ !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.delete_roster_confirmation_popup.data_lost', { action_message: this.props.actionMessage }) }<br />{ !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.delete_roster_confirmation_popup.download_roster_first') }</strong><fieldset className="submit add-margin-top-15"><a href={this.props.downloadLeagueSpreadsheetfilesPath} target="_blank" className="btsp_orange_button" onClick={this.props.toggleDownloadRoster}>{ !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.delete_roster_confirmation_popup.download_roster_to_spreadsheet') }</a></fieldset></GlgPopupForm></GlgPopup>
  }
}

DeleteRosterConfirmationPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  closeDeleteRosterConfirmationPopup: PropTypes.func.isRequired,
  deleteEntireRoster: PropTypes.func.isRequired,
  toggleDownloadRoster: PropTypes.func.isRequired,
  downloadLeagueSpreadsheetfilesPath: PropTypes.string.isRequired,
  actionMessage: PropTypes.string.isRequired,
  isDeleteRosterLoading: PropTypes.bool.isRequired,
  isRosterDownloaded: PropTypes.bool.isRequired,
}

export default DeleteRosterConfirmationPopup
