import React, { Component } from 'react'
import PropTypes from 'prop-types'

import PopupTypes from '../../popup_types'
import GeneralCreateLeaguePopup from './general_create_league_popup'

class CreateEventPopup extends Component {
  constructor(props) {
    super(props)
    this.onClose = props.onClose.bind(this, PopupTypes.CREATE_EVENT)
  }

  shouldComponentUpdate(nextProps) {
    return this.props.show !== nextProps.show
  }

  getTitle() {
    if (this.props.customerOnlyEvents) {
      return !window.I18n ? '' : window.I18n.t('customer_center_menu.components.popups.create_event_popup.create_an_event')
    } else if (this.props.customerOnlyLeagues) {
      return !window.I18n ? '' : window.I18n.t('customer_center_menu.components.popups.create_event_popup.create_a_league')
    } else if (!this.props.canCreateTrips) {
      return !window.I18n ? '' : window.I18n.t('customer_center_menu.components.popups.create_event_popup.create_an_event_or_league')
    } else {
      return !window.I18n ? '' : window.I18n.t('customer_center_menu.components.popups.create_event_popup.create_an_event_or_league_or_trip')
    }
  }

  render() {
    return <GeneralCreateLeaguePopup type="createEvent" title={ this.getTitle() } saveButtonText={ !window.I18n ? '' : window.I18n.t('customer_center_menu.components.popups.create_event_popup.get_started') } show={ this.props.show } formURL={ this.props.formURL } onClose={ this.onClose }></GeneralCreateLeaguePopup>
  }
}

CreateEventPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  customerOnlyEvents: PropTypes.bool.isRequired,
  customerOnlyLeagues: PropTypes.bool,
  canCreateTrips: PropTypes.bool,
  // customerNeedsSubscription: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  formURL: PropTypes.string,
}

export default CreateEventPopup
