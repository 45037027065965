import { connect } from 'react-redux'

import DrivingDistancesTableComponent from '../components/driving_distances_table_component'
import { getSortedDistances } from '../selectors'

const mapStateToProps = (state) => {
  return {
    players: getSortedDistances(state) || [],
    distanceUnit: state.misc.distanceUnit,
    adjustment: state.station.adjustment,
  }
}

export default connect(
  mapStateToProps
)(DrivingDistancesTableComponent)
