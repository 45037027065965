import { connect } from 'react-redux'

import GroupViewFiltersComponent from '../components/group_view_filters_component'
import GroupViewPortalFiltersComponent from '../components/group_view_portal_filters_component'

import { changeDisplayOptionFilter, changeTournament, changeCourseFilter, changeStartingHoleFilter, changeSortOption, changeSortOrderOption, changeRound, showHideFilters} from '../actions'

const mapStateToProps = (state) => {
  return {
    tournaments: state.misc.tournaments,
    holes: state.groupViewData.holes,
    selectedTournamentId: state.misc.selectedTournamentId,
    isStableford: state.groupViewData.isStableford,
    displayOption: state.filters.displayOption,
    courses: state.groupViewData.courses,
    selectedTee: state.filters.selectedTee,
    selectedCourse: state.filters.selectedCourse,
    sortOrder: state.filters.sortOrder,
    sortOption: state.filters.sortByOption,
    isPortal: state.misc.isPortal,
    rounds: state.misc.rounds,
    showRounds: !!state.misc.rounds,
    roundId: state.misc.roundId,
    showFilters: state.filters.showFilters,
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeDisplayOption: (value) => dispatch(changeDisplayOptionFilter(value)),
  changeTournament: (value) => dispatch(changeTournament(value)),
  changeStartingHole: (value) => dispatch(changeStartingHoleFilter(value)),
  changeCourse: (value) => dispatch(changeCourseFilter(value)),
  changeSortOption: (value) => dispatch(changeSortOption(value)),
  changeSortOrderOption: () => dispatch(changeSortOrderOption()),
  changeRound: (value) => dispatch(changeRound(value)),
  showHideFilters: () => dispatch(showHideFilters()),
})

export default {
  GroupViewFilters: connect(mapStateToProps, mapDispatchToProps)(GroupViewFiltersComponent),
  GroupViewPortalFilters: connect(mapStateToProps, mapDispatchToProps)(GroupViewPortalFiltersComponent),
}
