import { connect } from 'react-redux'
import ListColumns from '../presentational/list_columns'
import {
  columnChangePosition,
  DISPLAY_MODES,
  displayMode,
  removeColumn,
  resizeColumn,
  setActiveBlock,
  setActiveColumn,
  setActivePosition,
  setColumnDisplay,
  setDraggebleElement,
  setDraggedOverElement,
  setDraggedOverPosition,
  setIframePointerOptions,
  setIframeFormState,
  swapColumns,
} from '../../actions'

const setupContent = (columns, blockID, layout) => {
  const allColumns = columns.filter(c => c.blockId === blockID)
                            .sort( (a, b) => a.index >= b.index ? 1 : -1 )
  let data = {}
  switch (layout) {
    case '1': {
      data = {
        columns: allColumns,
        layout: layout,
      }
      break
    }
    case '2': {
      data = {
        left: {
          columns: allColumns.filter(c => c.position === 'left'),
          width: 6,
        },
        right: {
          columns: allColumns.filter(c => c.position === 'right'),
          width: 6,
        },
        layout: layout,
      }
      break
    }
    case '3': {
      data = {
        left: {
          columns: allColumns.filter(c => c.position === 'left'),
          width: 4,
        },
        right: {
          columns: allColumns.filter(c => c.position === 'right'),
          width: 8,
        },
        layout: layout,
      }
      break
    }
    case '4': {
      data = {
        left: {
          columns: allColumns.filter(c => c.position === 'left'),
          width: 8,
        },
        right: {
          columns: allColumns.filter(c => c.position === 'right'),
          width: 4,
        },
        layout: layout,
      }
      break
    }
  }

  return data
}

const mapStateToProps = (state, ownProps) => ({
  data: setupContent(state.columns, ownProps.blockID, ownProps.LAYOUT),
  blockID: ownProps.blockID,
  iframePointerOptions: state.filters.iframe_pointer_options,
  draggableElement: state.filters.draggable_element,
  draggedOverElement: state.filters.dragged_over_element,
  draggedOverPosition: state.filters.dragged_over_position,
  widgets: state.filters.widgets,
  usesTranslations: state.filters.uses_translations,
  availableLanguages: state.filters.available_languages,
  currentLanguage: state.filters.current_language,
})

const mapDispatchToProps = dispatch => ({
  addColumn: (_blockID, _position) => {
    dispatch(setActivePosition(_position))
    dispatch(setActiveBlock(_blockID))
    dispatch(displayMode(DISPLAY_MODES.COLUMN))
  },

  editColumn: (_id) => {
    dispatch(setActiveColumn(_id))
    dispatch(displayMode(DISPLAY_MODES.WIDGET))
  },

  editColumnProps: (_id) => {
    dispatch(setActiveColumn(_id))
    dispatch(displayMode(DISPLAY_MODES.EDIT_COLUMN_PROPS))
  },

  resizeColumn: (_id, _height) => dispatch(resizeColumn(_id, _height)),
  swapColumns: (_colID1, _colID2, _position) => dispatch(swapColumns(_colID1, _colID2, _position)),
  setIframePointerOptions: _option => dispatch(setIframePointerOptions(_option)),
  setDraggebleElement: _element => dispatch(setDraggebleElement(_element)),
  setDraggedOverElement: _element => dispatch(setDraggedOverElement(_element)),
  removeColumn: id => dispatch(removeColumn(id)),
  setColumnDisplay: (_id, _display) => dispatch(setColumnDisplay(_id, _display)),
  setDraggedOverPosition: _position => dispatch(setDraggedOverPosition(_position)),
  columnChangePosition: (_id, _position) => dispatch(columnChangePosition(_id, _position)),
  setIframeFormState: (_state) => dispatch(setIframeFormState(_state)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListColumns)
