import { createSelector } from 'reselect'
import _ from 'lodash'

const getLeaguesById = (state, { useManagedCustomer = false } = {}) => {
  if (useManagedCustomer) {
    return state.managedCustomerData.leagues || state.leagues
  } else {
    return state.leagues
  }
}
const getSeasonsCount = (state, { useManagedCustomer = false } = {}) => {
  let seasons
  if (useManagedCustomer) {
    seasons = state.managedCustomerData.seasons || state.seasons
  } else {
    seasons = state.seasons
  }
  return _.size(seasons)
}

const getIsTMLite = (state = {}) => {
  return state.misc.isTmLite
}

const checkShowSeasons = createSelector(
  [ getLeaguesById, getSeasonsCount, getIsTMLite ],
  (leaguesById, seasonsCount, isTmLite) => (
    (seasonsCount > 0 || !(_.isEmpty(leaguesById))) && !isTmLite
  ),
)

export default checkShowSeasons
