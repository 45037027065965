import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Image } from 'react-bootstrap'
import { Row, Col } from 'react-flexbox-grid'

const dropDownArrow = function(cssClass = '') {
  const arrowClass = ('accordion-arrow' + ` ${cssClass}`).trim()
  return (
    <svg className={arrowClass} xmlns="http://www.w3.org/2000/svg">
      <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" fill='grey'/>
    </svg>
  )
}

class AccordionSection extends Component {

  constructor(props){
    super(props)
    this.onClick = this.onClick.bind(this)
  }

  onClick() {
    this.props.onClick(this.props.label)
  }

  render() {
    const {
      onClick,
      props: { isOpen, label, iconPath },
    } = this

    return <div className="section add-margin-top-10 add-margin-bottom-10"><div className="section_header"><Row onClick={onClick} className="vertical-align"><Col xs={ 11 } className="title">{iconPath && <div className="icon-container"><Image src={iconPath} className="icon add-margin-left-15"></Image></div>}<span className="header_text">{label}</span></Col><Col xs={ 1 }><div className="arrow-container add-margin-right-15">{!isOpen && dropDownArrow()}{isOpen && dropDownArrow('rotate-180')}</div></Col></Row></div><div className="section_content">{isOpen && <Row className="no-margin"><Col xs={ 12 }><hr />{this.props.children}</Col></Row>}</div></div>
  }
}

AccordionSection.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  iconPath: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default AccordionSection
