import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { GlgCheckbox } from 'SharedComponents'

const GlgMultiSelect = (props) => {
  const [ isDropdownOpen, setIsDropdownOpen ] = useState(false)
  const wrapperRef = useRef(null)

  const handleClick = (e) => {
    e.preventDefault()
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsDropdownOpen(false)
    }
  }

  const checkedItems = props.items.filter(item => item.isChecked)
  const uncheckedItems = props.items.filter(item => !item.isChecked)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className='glg-multi-select' ref={wrapperRef}>
      <button 
        className='glg-multi-select-input'
        onClick={handleClick}
        >
        <span>
          {checkedItems[0]?.isChecked 
            ? checkedItems[0].label
            : props.placeholder
          }
        </span> 

        <div className='glg-multi-select-counter-and-arrow'>
          {checkedItems.length > 1 &&
            <span className='glg-multi-select-counter'>+{checkedItems.length}</span>
          } 

          <span className='glg-multi-select-arrow'>{isDropdownOpen ? '▲' : '▼'}</span>
        </div>
      </button>

      {isDropdownOpen && (
        <div className='glg-multi-select-dropdown'>
          <span className='glg-multi-select-heading'>Checked ({checkedItems.length})</span>
          <br />

          {checkedItems.map((item) => (
            <div key={item.value} className='glg-multi-select-option'>
              <GlgCheckbox
                name={item.value}
                className="custom-checkbox"
                checked={item.isChecked}
                onChange={(isChecked) => props.onCheckboxToggle(item.value, isChecked)}
              />
              <label className='glg-multi-select-label' htmlFor={item.value}>{item.label}</label>
            </div>
          ))}

          <span className='glg-multi-select-heading'>Unchecked ({uncheckedItems.length})</span>
          <br />

          {uncheckedItems.map((item) => (
            <div key={item.value} className='glg-multi-select-option'>
              <GlgCheckbox
                name={item.value}
                className="custom-checkbox"
                checked={item.isChecked}
                onChange={(isChecked) => props.onCheckboxToggle(item.value, isChecked)}
              />
              <label className='glg-multi-select-label' htmlFor={item.value}>{item.label}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

GlgMultiSelect.propTypes = { 
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired,
  })).isRequired,
  onCheckboxToggle: PropTypes.func,
  placeholder: PropTypes.string,
}

GlgMultiSelect.defaultProps = {
  placeholder: 'Select...',
}



export default GlgMultiSelect
