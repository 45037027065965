import { connect } from 'react-redux'
import ThruBoard from '../components/thru_board'

import { resetTimer, clearTimer } from '../actions'

const mapStateToProps = (state) => {
  return {
    title: state.misc.title,
    current: state.misc.current,
    upcoming: state.misc.upcoming,
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetTimer: () => dispatch(resetTimer()),
  clearTimer: () => dispatch(clearTimer()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThruBoard)
