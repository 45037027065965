import React, { Component } from 'react'
import PropTypes from 'prop-types'

class GlgSwitch extends Component {

  constructor(props){
    super(props)

    this.state = {
      checked: this.props.checked || this.props.defaultChecked,
    }

    this.heightInPixels = props.heightInPixels || 25
    this.fontSize = props.fontSize || '14px'

    this.width = props.widthInPixels || 75
    this.switchSwitchMargin = 5
    this.switchSwitchWidth = this.heightInPixels - 2 * this.switchSwitchMargin
    this.switchRightPosition = this.width - 2 * this.switchSwitchMargin - this.switchSwitchWidth

    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    this.setState({
      checked: this.props.checked !== undefined ? this.props.checked : e.target.checked,
    })
    
    if (typeof this.props.onChange === 'function') {
      this.props.onChange()
    }
  }

  render() {
    const switchSwitchStyle = {
      width: `${this.switchSwitchWidth}px`,
      margin: `${this.switchSwitchMargin}px`,
    }

    if (this.props.checked !== undefined) {
      switchSwitchStyle.right = this.props.checked ? '0px' : `${this.switchRightPosition}px`
    } else {
      switchSwitchStyle.right = this.state.checked ? '0px' : `${this.switchRightPosition}px`
    }

    const switchInnerStyle = {
      height: `${this.heightInPixels}px`,
      lineHeight: `${this.heightInPixels}px`,
      fontSize: `${this.fontSize}px`,
    }

    return <div style={{width: `${this.width}px`}} className="glg-switch"><input type="checkbox" ref={ this.props.innerRef } name={ this.props.name } id={ this.props.id } checked={ this.props.checked } defaultChecked={ this.props.defaultChecked } onChange={ this.onChange } disabled={ this.props.disabled } className="switch-checkbox" /><label htmlFor={ this.props.id } className="switch-label"><span className={ `switch-inner ${ this.props.disabled ? 'switch-disabled' : '' }`} data-enabled={ this.props.text[0] } data-disabled={ this.props.text[1] } style={ switchInnerStyle }></span><span className={ `switch-switch ${ this.props.disabled ? 'switch-disabled' : '' }` } style={ switchSwitchStyle }></span></label></div>
  }
}

GlgSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  innerRef: PropTypes.object,
  text: function(props, propName) {
    if (!Array.isArray(props.text) || props.text.length !== 2 || props.text.every((el) => { typeof el === 'string' })) {
      return new Error(`${propName} needs to be an array of 2 strings.`)
    }
  },
  name: PropTypes.string,
  widthInPixels: PropTypes.number,
  heightInPixels: PropTypes.number,
  fontSize: PropTypes.string,
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default GlgSwitch
