import React from 'react'
import { GlgTableRow, GlgTableCell, GlgLabel } from 'SharedComponents'
import PropTypes from 'prop-types'

const labelType = (status) => {
  switch (status){
    case 'queued':
      return 'info'
    case 'sent':
      return 'primary'
    case 'delivered':
      return 'success'
    case 'undelivered':
      return 'warning'
    case 'failed':
      return 'danger'
    default:
      return 'default'
  }
}

const TextMessageDeliveryReportTableRow = ({...props}) => {
  return <GlgTableRow { ...props } isSortable={ false }><GlgTableCell>{ props.player_name }</GlgTableCell><GlgTableCell>{ props.description }</GlgTableCell><GlgTableCell><a data-toggle={'modal'} data-target={'#text_message_body_' + props.id} href={''}>{ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.text_message_delivery_report_table_row.view') }</a><div role={'dialog'} id={'text_message_body_' + props.id} className="modal fade"><div className="modal-dialog"><div className="modal-content"><div className="modal-header"><h3 className="modal-title">{ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.text_message_delivery_report_table_row.text_message_body') }  </h3></div><div className="modal-body white_background">{props.body && <div dangerouslySetInnerHTML={{__html: props.body}}></div> || <p className="btsp_clear_text">{ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.text_message_delivery_report_table_row.no_content') }</p>}</div><div className="modal-footer add-padding-right-30"><a data-toggle={'modal'} data-target={'#text_message_body_' + props.id} className="btsp_gray_button">{ !window.I18n ? '' : window.I18n.t('buttons.close').charAt(0)
                    .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.close').slice(1)) }</a></div></div></div></div></GlgTableCell><GlgTableCell>{ props.to }</GlgTableCell><GlgTableCell className="vertical-align"><GlgLabel type={labelType(props.status.type)} className="capitalize">{ !window.I18n ? '' : window.I18n.t(`marketing_campaigns.components.delivery_reports.text_message_delivery_report_table_row.status.${props.status.type}`, {defaultValue: props.status.type}) }</GlgLabel>{ props.status.message && <i title={props.status.message} className="gga-info-circle add-margin-left-5"></i> }</GlgTableCell><GlgTableCell>{ props.formatDate(new Date(props.updated_at)) }</GlgTableCell></GlgTableRow>
}

TextMessageDeliveryReportTableRow.propTypes = {
  id: PropTypes.number.isRequired,
  player_name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  status: PropTypes.shape({
    type: PropTypes.string.isRequired,
    message: PropTypes.string,
  }).isRequired,
  updated_at: PropTypes.string.isRequired,
  formatDate: PropTypes.func.isRequired,
}

export default TextMessageDeliveryReportTableRow
