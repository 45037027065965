import { connect } from 'react-redux'
import { showNotification, showErrorNotification } from 'Shared/helpers'
import EventSetupTournaments from '../../components/content/event_setup_tournaments.jsx'
import {
  setGenericData,
  ajaxRequest,
} from '../../actions/index'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => {
  const setData = (keys, value) => { dispatch(setGenericData(ownProps.widgetId, keys, value)) }
  const refresh = () => {
    const { refreshing } = ownProps.content
    if (! refreshing) {
      setData([ 'refreshing' ], true)
      dispatch(ownProps.refresh)
    }
  }
  const unloadTournament = () => {
    setData([ 'activeTournamentURL' ], null)
    setData([ 'loadedTournament' ], false)
    refresh()
  }
  const loadTournament = (url, withCheck = true) => {
    setData([ 'activeTournamentURL' ], url)

    withCheck && dispatch(ajaxRequest(url, 'head'))
      .catch(({xhr}) => {
        unloadTournament()

        if (xhr.status === 404) {
          showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.tournament_does_not_exist'))
        }
      })
  }
  const markTournamentLoaded = (loaded = true) => { setData([ 'loadedTournament' ], loaded) }
  const deleteTournament = (url) => {
    dispatch(ajaxRequest(url, 'delete', {}, 'json'))
      .catch(({xhr}) => {
        if (xhr.status === 404) {
          showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.tournament_does_not_exist'))
        }
      })
      .finally(() => {
        refresh()
        setTimeout(() => { !ownProps.content.activeTournamentURL && refresh() }, 1000)
      })
  }
  const saveTournament = (url, method, data) => {
    dispatch(ajaxRequest(url, method, data, 'json'))
      .then(({ response }) => {
        response.message && showNotification(response.message)
      })
      .catch(({ xhr }) => {
        if (xhr.responseJSON?.message) {
          showErrorNotification(xhr.responseJSON.message)
        } else {
          showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.tournament_save_error'))
        }
      })
      .finally(() => {
        unloadTournament()
      })
  }

  return {
    setData,
    loadTournament,
    unloadTournament,
    markTournamentLoaded,
    deleteTournament,
    saveTournament,
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(EventSetupTournaments)
