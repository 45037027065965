import { ActionTypes } from '../actions'
import { resetHash } from '../helpers'

const {
  OPEN_REGISTER_MEMBER_POPUP,
  CLOSE_REGISTER_MEMBER_POPUP,
  GET_REGISTER_MEMBER_DATA_REQUEST,
  GET_REGISTER_MEMBER_DATA_RECEIVED,
  REGISTER_MEMBER_REQUEST,
  REGISTER_MEMBER_RECEIVED,
  UNREGISTER_MEMBER_REQUEST,
  UNREGISTER_MEMBER_RECEIVED,
  UPDATE_MEMBER_REGISTRATION,
  DELETE_MEMBER_REQUEST,
  DELETE_MEMBER_RECEIVED,
  EDIT_MEMBER_REQUEST,
  EDIT_MEMBER_RECEIVED,
  RESET_PASSWORD_MEMBER_REQUEST,
  RESET_PASSWORD_MEMBER_RECEIVED,
  CONFIRM_MEMBER_REQUEST,
  CONFIRM_MEMBER_RECEIVED,
} = ActionTypes

const initialState = {
  data: {
    user: {},
  },
  isLoading: false,
  isOpen: false,
  isRegisterMemberLoading: false,
  isUnregisterMemberLoading: {},
  isDeleteMemberLoading: {},
  isOpenEditMemberModalLoading: {},
  isResetPasswordMemberLoading: {},
  isConfirmMemberLoading: {},
  member: {},
  registerMemberValues: {
    email: '',
    first_name: '',
    last_name: '',
    temporary_password: '',
  },
}

const registerMember = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_REGISTER_MEMBER_POPUP:
      return {
        ...state,
        isOpen: true,
      }
    case CLOSE_REGISTER_MEMBER_POPUP:
      return {
        ...state,
        isOpen: false,
      }
    case GET_REGISTER_MEMBER_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_REGISTER_MEMBER_DATA_RECEIVED: {
      const registerMemberValuesClone = {
        email: action.data.user && action.data.user.email ? action.data.user.email : action.data.member.email,
        first_name: action.data.user && action.data.user.first_name ? action.data.user.first_name : action.data.member.first_name,
        last_name: action.data.user && action.data.user.last_name ? action.data.user.last_name : action.data.member.last_name,
        temporary_password: action.data.passwordString,
        permanent_password: false,
        propagate_user: true,
        email_message: action.data.email_message,
      }
      // console.log(registerMemberValuesClone)
      return {
        ...state,
        isLoading: false,
        member: {
          ...action.data.member,
          fullName: action.data.fullName,
          id: action.data.memberId,
        },
        registerMemberValues: {
          ...registerMemberValuesClone,
        },
        data: action.data,
      }
    }
    case REGISTER_MEMBER_REQUEST:
      return {
        ...state,
        isRegisterMemberLoading: true,
      }
    case REGISTER_MEMBER_RECEIVED:
      return {
        ...state,
        isRegisterMemberLoading: false,
      }
    case UNREGISTER_MEMBER_REQUEST:
      return {
        ...state,
        isUnregisterMemberLoading: {
          ...state.isUnregisterMemberLoading,
          [action.id]: true,
        },
      }
    case UNREGISTER_MEMBER_RECEIVED: {
      return {
        ...state,
        isUnregisterMemberLoading: resetHash(state.isUnregisterMemberLoading),
      }
    }
    case CONFIRM_MEMBER_REQUEST:
      return {
        ...state,
        isConfirmMemberLoading: {
          ...state.isConfirmMemberLoading,
          [action.id]: true,
        },
      }
    case CONFIRM_MEMBER_RECEIVED: {
      return {
        ...state,
        isConfirmMemberLoading: resetHash(state.isUnregisterMemberLoading),
      }
    }
    case UPDATE_MEMBER_REGISTRATION:
      return {
        ...state,
        registerMemberValues: {
          ...state.registerMemberValues,
          [action.field]: action.value,
        },
      }
    case DELETE_MEMBER_REQUEST:
      return {
        ...state,
        isDeleteMemberLoading: {
          ...state.isDeleteMemberLoading,
          [action.id]: true,
        },
      }
    case DELETE_MEMBER_RECEIVED: {
      return {
        ...state,
        isDeleteMemberLoading: resetHash(state.isDeleteMemberLoading),
      }
    }
    case EDIT_MEMBER_REQUEST:
      return {
        ...state,
        isOpenEditMemberModalLoading: {
          ...state.isOpenEditMemberModalLoading,
          [action.id]: true,
        },
      }
    case EDIT_MEMBER_RECEIVED: {
      return {
        ...state,
        isOpenEditMemberModalLoading: resetHash(state.isOpenEditMemberModalLoading),
      }
    }
    case RESET_PASSWORD_MEMBER_REQUEST:
      return {
        ...state,
        isResetPasswordMemberLoading: {
          ...state.isResetPasswordMemberLoading,
          [action.id]: true,
        },
      }
    case RESET_PASSWORD_MEMBER_RECEIVED: {
      return {
        ...state,
        isResetPasswordMemberLoading: resetHash(state.isResetPasswordMemberLoading),
      }
    }
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default registerMember
