export const ActionTypes = {
  REFRESH_DATA: 'REFRESH_DATA',
}

export const refreshData = (message) => (dispatch) => {
  dispatch({
    type: ActionTypes.REFRESH_DATA,
    message,
  })
}
