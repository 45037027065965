import {ActionTypes} from '../actions/index'
import { CellMeasurerCache } from 'react-virtualized'
import CellMeasurerCacheDecorator from '../components/cell_measurer_cache_decorator'

const {
  CLEAR_CELL_MEASURER_CACHE,
  UPDATE_GRID_WIDTH,
  UPDATE_CONTAINER_WIDTH,
} = ActionTypes

const sharedCellMeasurerCache = new CellMeasurerCache({
  defaultWidth: 70,
  fixedHeight: true,
})

const initialState = {
  cellCache1: new CellMeasurerCacheDecorator({
    cellMeasurerCache: sharedCellMeasurerCache,
    columnIndexOffset: 0,
    rowIndexOffset: 0,
  }),
  cellCache2: new CellMeasurerCacheDecorator({
    cellMeasurerCache: sharedCellMeasurerCache,
    columnIndexOffset: 0,
    rowIndexOffset: 1,
  }),
  gridWidth: 0,
  containerWidth: 0,
}

const getColumnWidth = ({ index, totalWidth, cellCache, nrColumns }) => {
  if (index < nrColumns - 1) {
    let gridWidth = 0
    for (let i = 0; i < nrColumns; i++) {
      gridWidth += cellCache.columnWidth({ index: i })
    }
    const columnWidthRemainder = gridWidth < totalWidth - 10 ? (totalWidth - gridWidth - 10) / (nrColumns - 1) : 0
    return cellCache.columnWidth({ index }) + columnWidthRemainder
  } else {
    return cellCache.columnWidth({ index })
  }
}

const cellMeasurerCache = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_CELL_MEASURER_CACHE: {
      state.cellCache1.clearAll()
      state.cellCache2.clearAll()
      return {
        ...state,
      }
    }
    case UPDATE_GRID_WIDTH: {
      const nrColumns = action.nrColumns
      let gridWidth = 0
      for (let i = 0; i < nrColumns; i++) {
        gridWidth += getColumnWidth({ index: i, totalWidth: state.containerWidth, cellCache: action.cellCacheIndex === 1 ? state.cellCache1 : state.cellCache2, nrColumns })
      }
      return {
        ...state,
        gridWidth: gridWidth,
      }
    }
    case UPDATE_CONTAINER_WIDTH: 
      return {
        ...state,
        containerWidth: action.width,
      }
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default cellMeasurerCache
