import { connect } from 'react-redux'

import {
  addLeaguesWithPagination,
} from '../actions'

import CustomerCenterContentComponent from '../components/customer_center_content_component'

const mapStateToProps = (state) => ({
  viewType: state.filters.viewType,
  dataIsLoading: !!state.misc.dataIsLoading,
  isSalesDemo: state.misc.isSalesDemo,
})

const mapDispatchToProps = (dispatch) => ({
  addLeaguesWithPagination: () => dispatch(addLeaguesWithPagination(null)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerCenterContentComponent)
