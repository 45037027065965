import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from 'react-bootstrap'
import DayPickerInput from 'react-day-picker/DayPickerInput'

import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment'

class GlgDatePicker extends Component {
  constructor(props) {
    super(props)
    this.handleDayChange = this.handleDayChange.bind(this)
    this.state = {
      date: '',
    }
  }

  getPlaceholder() {
    if (this.props.showPlaceholder) {
      return formatDate(new Date())
    } else {
      return null
    }
  }

  handleDayChange(day) {
    let formattedDay = ''
    if (day) {
      formattedDay = formatDate(day)
    }
    this.setState({
      date: formattedDay,
    })
    if (this.props.onChange) {
      this.props.onChange(formattedDay)
    }
  }


  render() {
    const CustomInput = this.props.inputComponent
    return <div className="glg-date-picker"><FormControl name={ this.props.name } type="hidden" value={ this.state.date }></FormControl><DayPickerInput formatDate={ formatDate } parseDate={ parseDate } placeholder={ this.props.placeholderMethod ? this.props.placeholderMethod() : this.getPlaceholder() } component={CustomInput} inputProps={ { className: 'date-picker-input' } } onDayChange={ this.handleDayChange } clickUnselectsDay={ true } value={this.props.value} dayPickerProps={{
                          disabledDays: this.props.past ? {after: new Date()} : '',
                        }}></DayPickerInput></div>
  }
}

GlgDatePicker.propTypes = {
  // The input name
  name: PropTypes.string.isRequired,
  // Whether to show placeholder (today's date) or not
  showPlaceholder: PropTypes.bool,
  // Callback when the date is changed
  onChange: PropTypes.func,
  inputComponent: PropTypes.func,
  value: PropTypes.string,
  // Should show dates only from the past
  past: PropTypes.bool,
  // Method to display a custom placeholder 
  placeholderMethod: PropTypes.func,
}

GlgDatePicker.defaultProps = {
  showPlaceholder: false,
}

export default GlgDatePicker
