import { createSelector } from 'reselect'

const getCourse = state => state.filters.selectedCourse
const getTees = state => state.groupViewData.tees

const getTeesFilteredByCourse = createSelector(
  [ getCourse, getTees ],
  ( course, tees ) => {
    if (course === 'all') {
      return tees
    } else {
      const filteredTees = {}
      Object.keys(tees).map( key => {
        filteredTees[key] = tees[key].filter( tee => tee.courseId === course)
      })
      return filteredTees
    }
  },
)

export default getTeesFilteredByCourse
