import React from 'react'
import PropTypes from 'prop-types'
import {
  GlgTableRow,
  GlgTableCell,
} from 'SharedComponents'

const SalesDemoLeagueRowComponent = ({
  name, customerName, leagueURL, portalURL, userName, userURL,
  ...props
}) => <GlgTableRow { ...props } isSortable={ false }><GlgTableCell><a href={ leagueURL } className="add-margin-left-20">{ `${ name } at ${ customerName }` }</a></GlgTableCell><GlgTableCell><a href={ portalURL } target="_blank" rel="noopener noreferrer">Portal</a></GlgTableCell><GlgTableCell><a href={ userURL }>{ `Sign in as ${ userName }` }</a></GlgTableCell></GlgTableRow>

SalesDemoLeagueRowComponent.propTypes = {
  name: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
  leagueURL: PropTypes.string.isRequired,
  portalURL: PropTypes.string.isRequired,
  userName: PropTypes.string,
  userURL: PropTypes.string,
}

SalesDemoLeagueRowComponent.defaultProps = {
}

export default SalesDemoLeagueRowComponent
