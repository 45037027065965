import React, { Component } from 'react'
import {
  GlgCheckbox,
  GlgTooltipIcon,
} from 'SharedComponents'
import PropTypes from 'prop-types'
import { getCategoryName } from '../../helpers'

export class MembersCheckboxPanel extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }
  
  handleChange(checked, value, category) {
    this.props.onChange(checked, value, category)
  }

  render() {
    return <div className="panel panel-default show_more_members"><div style={{padding: 0}} className="panel-body">{Object.keys(this.props.options).map((category, index) => <div key={index} className="glg-category">{ category !== 'status' && <label className="btsp_uppercase_text btsp_bold_text no-margin">                { getCategoryName(category) }</label> 
              }{this.props.options[category].map((filterOption, index2) => <div key={index2} className="glg-field"><span className="checkbox_radio_container"><label><span><GlgCheckbox key={index} disabled={ this.props.isMembersDataLoading || this.props.isMembersCountLoading } name={filterOption.label} value={filterOption.value} label={filterOption.label} checked={filterOption.checked === 'true' || filterOption.checked === true} onChange={(checked, value) => this.handleChange(checked, value, category)} inline={true}></GlgCheckbox>{filterOption.title && <GlgTooltipIcon tooltip={filterOption.title}></GlgTooltipIcon>}</span>{filterOption.circle && <span><div className={'circle ' + filterOption.circle}></div></span>}</label></span></div>)}</div>)}</div></div>
  }
}

MembersCheckboxPanel.propTypes = {
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isMembersDataLoading: PropTypes.bool.isRequired,
  isMembersCountLoading: PropTypes.bool.isRequired,
}

export default MembersCheckboxPanel
