import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import FlyerImage from './FlyerImage'
import { getAuthenticityToken } from 'Shared/helpers'

const FlyerShapeList = (props) => {
  
  const [ shapes, setShapes ] = useState(props.shapes)
  const [ showShapes, setShowShapes ] = useState(false)

  const handleShapeDelete = (deleteShape) => {
    // eslint-disable-next-line no-alert
    if (confirm('Are you sure you want to delete this shape?')) {
      fetch(`/leagues/${props.leagueId}/flyer_shapes/${deleteShape.id}`, {
        method: 'DELETE',
        headers: {'X-CSRF-Token': getAuthenticityToken()},
      })
      .then(() => {
        const newShapes = shapes.filter((shape) => shape.id !== deleteShape.id)
        setShapes(newShapes)
        props.setShapes(newShapes)
      })
    }
  }

  const toggleShow = () => {
    setShowShapes(!showShapes)
  }

  useEffect(() => {
    setShapes(props.shapes)
  }, [ props.shapes ])

  return (
    <div className="list">
      <div className="category" onClick={toggleShow}>
        <div className="category_name">
          Shapes
        </div>
        <div className="caret-display">
          <i className={`fa ${!showShapes ? 'fa-caret-down' : 'fa-caret-up'}`}></i>
        </div>
      </div>
      {showShapes && 
        <div className="images_list">
          {shapes !== null && shapes.length > 0 ? ( shapes.map((shape) => {
            return (
              <FlyerImage
                key={shape.id}
                url={shape.url}
                deleteImage={() => handleShapeDelete(shape)}>
              </FlyerImage>
            )
          })) : (
            <div className="no_results_found">
              { window.I18n.t('views.golfhub.no_results_found') }
            </div>
          )}
        </div>
      }
    </div>
  )
}

FlyerShapeList.propTypes = {
  shapes: PropTypes.array,
  setShapes: PropTypes.func,
  leagueId: PropTypes.string,
}

export default FlyerShapeList
