import React, { Component } from 'react'
import PropTypes from 'prop-types'

import PopupTypes from '../../popup_types'
import GeneralCreateLeaguePopup from './general_create_league_popup'

class QuickEventPopup extends Component {
  constructor(props) {
    super(props)
    this.onClose = props.onClose.bind(this, PopupTypes.QUICK_EVENT)
  }

  shouldComponentUpdate(nextProps) {
    return this.props.show !== nextProps.show
  }

  render() {
    return <GeneralCreateLeaguePopup type="quickEvent" title={ !window.I18n ? '' : window.I18n.t('customer_center_menu.components.popups.quick_event_popup.title') } saveButtonText={ !window.I18n ? '' : window.I18n.t('customer_center_menu.components.popups.quick_event_popup.get_started') } show={ this.props.show } formURL={ this.props.formURL } onClose={ this.onClose }></GeneralCreateLeaguePopup>
  }
}

QuickEventPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  formURL: PropTypes.string,
}

export default QuickEventPopup
