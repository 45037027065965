import { ActionTypes } from '../actions'

const {
  SET_STICKY,
  CLEAR_STICKY,
  ADD_MEMBERS_TO_STICKY,
  REMOVE_MEMBERS_FROM_STICKY,
  ADD_MEMBERS_TO_SPLIT,
  REMOVE_MEMBERS_FROM_SPLIT,
} = ActionTypes

const initialState = {
  memberCard: {},
  members: [],
  index: null,
  newMembers: [],
}

const sticky = (state = initialState, action) => {

  switch (action.type) {
    case SET_STICKY:
      return {
        ...state,
        ...action.data,
      }
    case CLEAR_STICKY:
      return initialState
    case ADD_MEMBERS_TO_STICKY:
      return {
        ...state,
        newMembers: state.newMembers.concat(action.members.filter(member => {
          if (state.newMembers.find(m => m.id === member.id)) {
            return false
          }
          return true
        },
        )),
      }
    case REMOVE_MEMBERS_FROM_STICKY:
      return {
        ...state,
        newMembers: state.newMembers.filter(member => {
          if (action.members.find(m => m.id === member.id)) {
            return false
          }
          return true
        }),
      }
    case ADD_MEMBERS_TO_SPLIT:
      return {
        ...state,
        newMembers: state.newMembers.concat(action.members),
      }
    case REMOVE_MEMBERS_FROM_SPLIT:
      return {
        ...state,
        newMembers: [
          ...state.newMembers.filter(member => !action.members.includes(member)),
        ],
      }
      
    default: 
      return state
  }
}

export default sticky
