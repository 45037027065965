import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, InputGroup, FormControl } from 'react-bootstrap'
import { Icon as FaIcon } from 'react-fa'
const { Addon } = InputGroup

class ImageTitleFilter extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      searchTimeout: 0,
      title: this.props.value,
    }

    this.onChange = this.onChange.bind(this)
    this.onReset = this.onReset.bind(this)
    this.inputRef = React.createRef()
  }

  onChange(e) {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout)
    }

    this.setState({
      title: e.target.value,
      searchTimeout: setTimeout(() => {
        this.props.onChange(this.state.title)
      }, 500),
    })
  }

  onReset() {
    this.setState({
      title: '',
    })
    this.props.onChange('')
    this.inputRef.focus()
  }

  render() {
    return <FormGroup className={ 'filter btsp_filter' }><InputGroup><FormControl type="text" value={ this.state.title } placeholder={ !window.I18n ? '' : window.I18n.t('tv_shows.components.image_title_filter.search') } onChange={ this.onChange } inputRef={ ref => { this.inputRef = ref } }></FormControl><Addon><a onClick={ this.onReset } className="clickable"><FaIcon name="eraser"></FaIcon></a></Addon></InputGroup></FormGroup>
  }
}

ImageTitleFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ImageTitleFilter
