import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { Row } from 'react-flexbox-grid'

import { GlgLoadingSpinner } from 'SharedComponents'

import ScoringControlInterfaceTableContainer from '../containers/scoring_control_interface_table_container'
import Filters from '../containers/filters_container'

class ScoringControlInterface extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.fetchInitialData()

    this.props.resetTimer()
  }

  componentWillUnmount() {
    this.props.clearTimer()
  }

  render() {
    return (
      this.props.isLoading
        ? <GlgLoadingSpinner className='add-margin-top-20 text-center'
                             width={85} 
                             height={85} />
        : this.props.hasTournaments
          ? <Fragment>
            <Row className='no-margin'>
              <Filters />
            </Row>

            <div className='scoring_control_interface_container'>
              <ScoringControlInterfaceTableContainer />
            </div>

            <div className='save_bar changed'>
              <a className={`button btsp_gray_button ${this.props.anyNewScores ? '' : 'disabled'}`} onClick={this.props.refreshContent}>Cancel</a>
              <a className={`button btsp_orange_button ${this.props.anyNewScores ? '' : 'disabled'}`} onClick={() => this.props.saveScores(this.props.newScores)}>Save</a>
            </div>
          </Fragment>
          : <Fragment>
            <div className='scoring_control_interface_container'>
              <h3>{this.props.noTournamentsMessage}</h3>
            </div>
          </Fragment>
    )
  }
}

ScoringControlInterface.propTypes = {
  fetchInitialData: PropTypes.func,
  saveScores: PropTypes.func,
  isLoading: PropTypes.bool,
  refreshContent: PropTypes.func,
  anyNewScores: PropTypes.bool,
  newScores: PropTypes.object,

  resetTimer: PropTypes.func,
  clearTimer: PropTypes.func,


  hasTournaments: PropTypes.bool,
  noTournamentsMessage: PropTypes.string,
}

export default ScoringControlInterface
