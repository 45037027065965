import { combineReducers } from 'redux'
import misc from './misc'
import popup from './popup'
import teamPopup from './teamPopup'

export default combineReducers({
  misc,
  popup,
  teamPopup,
})
