import { connect } from 'react-redux'
import _ from 'lodash'

import { getSortedVisibleLeagueIds } from '../selectors'
import { toggleCategory, addLeaguesWithPagination, openPopup, deleteCategory } from '../actions'
import CategoryComponent from '../components/category_component'

const mapStateToProps = (state, props) => {
  const category = state.categories[props.id]
  const leagueIds = getSortedVisibleLeagueIds(state, props.id)
  const color = category.color || '#333333'

  const leagueNameFilterActive = !_.isEmpty(state.filters.leagueName)
  const opened = category.opened || leagueNameFilterActive

  return {
    name: category.name,
    opened,
    color,
    specialStatus: category.specialStatus,
    isFree: category.isFree,
    updateURL: category.updateURL,
    leagueIds,
    userIsCustomerManager: state.misc.userIsCustomerManager,
    leaguesCount: category.leaguesCount,
    loadingData: category.loadingData,
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  toggleCategory: () => dispatch(toggleCategory(props.id)),
  addLeaguesWithPagination: () => dispatch(addLeaguesWithPagination(props.id)),
  openPopup: (id, data) => dispatch(openPopup(id, data)),
  deleteCategory: id => dispatch(deleteCategory(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryComponent)
