import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

import { GlgButton } from 'SharedComponents'

import QuickEventButton from './action_buttons/quick_event_button'
import CreateEventButton from './action_buttons/create_event_button'
import CloneEventButton from './action_buttons/clone_event_button'

const CustomerActionsComponent = ({
  openPopup, userIsCustomerManager, userIsTourManager, dataIsLoading, userCanCloneEvents,
  isSalesDemo, userIsVisageSuperAdmin, manageVisageURL, customerOnlyEvents, customerOnlyLeagues, customerTripOnly, customerNeedsSubscription,
  canCreateTrips, canCreateDCP,
}) => {
  return <Fragment>{(userIsCustomerManager || userIsTourManager) && (!customerTripOnly) && <Row>{!customerOnlyLeagues && <Col sm={ 12} lg={ true }><QuickEventButton openPopup={ openPopup } disabled={ dataIsLoading }></QuickEventButton></Col>}<Col sm={12} lg={ true }><CreateEventButton openPopup={ openPopup } customerOnlyEvents={ customerOnlyEvents } customerNeedsSubscription={ customerNeedsSubscription} customerOnlyLeagues={ customerOnlyLeagues} canCreateTrips={ canCreateTrips } disabled={ dataIsLoading } canCreateDCP={ canCreateDCP }></CreateEventButton></Col>{!isSalesDemo && userCanCloneEvents && <Col sm={12} lg={ true } className="no-lite"><CloneEventButton openPopup={ openPopup } customerOnlyEvents={ customerOnlyEvents } customerNeedsSubscription={ customerNeedsSubscription} customerOnlyLeagues={ customerOnlyLeagues } canCreateTrips={ canCreateTrips } disabled={ dataIsLoading } canCreateDCP={ canCreateDCP }></CloneEventButton></Col>}</Row>}{userIsVisageSuperAdmin && <GlgButton text="Manage Visage Support" href={ manageVisageURL } className="add-margin-bottom-10"></GlgButton>}</Fragment>
}

CustomerActionsComponent.propTypes = {
  userIsCustomerManager: PropTypes.bool.isRequired,
  userIsTourManager: PropTypes.bool.isRequired,
  dataIsLoading: PropTypes.bool.isRequired,
  userCanCloneEvents: PropTypes.bool.isRequired,
  isSalesDemo: PropTypes.bool,
  userIsVisageSuperAdmin: PropTypes.bool,
  manageVisageURL: PropTypes.string,
  customerOnlyEvents: PropTypes.bool.isRequired,
  customerOnlyLeagues: PropTypes.bool.isRequired,
  canCreateTrips: PropTypes.bool.isRequired,
  customerNeedsSubscription: PropTypes.bool.isRequired,
  customerTripOnly: PropTypes.bool.isRequired,
  openPopup: PropTypes.func.isRequired,
  canCreateDCP: PropTypes.bool,
}

export default CustomerActionsComponent
