import { ActionTypes } from '../actions/slides'
import { gridItemCompare } from '../helpers'

function slides(state = {}, action) {
  switch (action.type) {
    case ActionTypes.SET_UNSAVED_CHANGES:
      return {
        ...state,
        hasUnsavedChanges: action.value,
      }

    case ActionTypes.SET_NEW_SLIDE_PROPERTY:
      return {
        ...state,
        slidePopup: {
          ...state.slidePopup,
          [action.property]: action.value,
        },
      }

    case ActionTypes.SET_NEW_SLIDE_CONFIGURATION_PROPERTY:
      return {
        ...state,
        slidePopup: {
          ...state.slidePopup,
          configuration: {
            ...state.slidePopup.configuration,
            [action.property]: action.value,
          },
        },
      }

    case ActionTypes.ADD_SLIDE:
      return {
        ...state,
        tvShowSlides: state.tvShowSlides.concat([ action.slide ]),
      }

    case ActionTypes.UPDATE_SLIDE:
      return {
        ...state,
        tvShowSlides: state.tvShowSlides.map( (slide) => {
          return slide.id === action.slide.id ? {...slide, ...action.slide} : slide
        } ),
      }

    case ActionTypes.DELETE_SLIDE:
      return {
        ...state,
        tvShowSlides: state.tvShowSlides.filter( slide => slide.id !== action.slideId ),
      }

    case ActionTypes.CHECK_NEW_SLIDE_DIVISION:
      return {
        ...state,
        slidePopup: {
          ...state.slidePopup,
          configuration: {
            ...state.slidePopup.configuration,
            divisionIds: state.slidePopup.configuration.divisionIds.includes(action.divisionId) ?
              state.slidePopup.configuration.divisionIds :
              [ ...state.slidePopup.configuration.divisionIds, action.divisionId ],
            flightIdsAndValuesByDivisionId: {
              ...state.slidePopup.configuration.flightIdsAndValuesByDivisionId,
              [action.divisionId]: state.slidePopup.configuration.divisionIds.includes(action.divisionId) ?
                state.slidePopup.configuration.flightIdsAndValuesByDivisionId[action.divisionId] :
                state.slidePopup.dataSources.flightsByDivisionId[action.divisionId],
            },
          },
        },
      }

    case ActionTypes.UNCHECK_NEW_SLIDE_DIVISION:
      return {
        ...state,
        slidePopup: {
          ...state.slidePopup,
          configuration: {
            ...state.slidePopup.configuration,
            divisionIds: state.slidePopup.configuration.divisionIds.filter( divisionId => divisionId !== action.divisionId ),
            flightIdsAndValuesByDivisionId: {
              ...state.slidePopup.configuration.flightIdsAndValuesByDivisionId,
              [action.divisionId]: [],
            },
          },
        },
      }

    case ActionTypes.CHECK_NEW_SLIDE_FLIGHT:
      return {
        ...state,
        slidePopup: {
          ...state.slidePopup,
          configuration: {
            ...state.slidePopup.configuration,
            divisionIds: state.slidePopup.configuration.divisionIds.includes(action.divisionId) ?
              state.slidePopup.configuration.divisionIds :
              [ ...state.slidePopup.configuration.divisionIds, action.divisionId ],
            flightIdsAndValuesByDivisionId: {
              ...state.slidePopup.configuration.flightIdsAndValuesByDivisionId,
              [action.divisionId]: (state.slidePopup.configuration.flightIdsAndValuesByDivisionId[action.divisionId] || []).includes(action.flight) ?
                state.slidePopup.configuration.flightIdsAndValuesByDivisionId[action.divisionId] :
                [ ...(state.slidePopup.configuration.flightIdsAndValuesByDivisionId[action.divisionId] || []), action.flight ],
            },
          },
        },
      }

    case ActionTypes.UNCHECK_NEW_SLIDE_FLIGHT:
      return {
        ...state,
        slidePopup: {
          ...state.slidePopup,
          configuration: {
            ...state.slidePopup.configuration,
            divisionIds: state.slidePopup.configuration.flightIdsAndValuesByDivisionId[action.divisionId].filter( flight => flight.value !== action.flightId ).length > 0 ?
              state.slidePopup.configuration.divisionIds :
              state.slidePopup.configuration.divisionIds.filter( divisionId => divisionId !== action.divisionId ),
            flightIdsAndValuesByDivisionId: {
              ...state.slidePopup.configuration.flightIdsAndValuesByDivisionId,
              [action.divisionId]: state.slidePopup.configuration.flightIdsAndValuesByDivisionId[action.divisionId].filter( flight => flight.value !== action.flightId ),
            },
          },
        },
      }

    case ActionTypes.COMPUTE_SLIDES_LAYOUT: {
      let y = -1

      return {
        ...state,
        slidesLayout: state.tvShowSlides.sort( (a, b) => a.position - b.position ).map( (slide, index) => {
          if ( index % action.colsNumber === 0 ) {
            y += 1
          }

          return {
            i: slide.id,
            x: index % action.colsNumber,
            y: y,
            w: 1,
            h: 1,
          }
        }),
      }
    }

    case ActionTypes.SET_SLIDES_POSITIONS: {
      // `action.currentGridLayout` only contains visible slides, i.e. those not part of Advanced slides
      // we must ensure slides within Advanced slides get a position as well

      const visibleSlides = action.currentGridLayout
                                  .sort(gridItemCompare)
                                  .map( (gridItem, index) => {
                                    return {
                                      ...state.tvShowSlides.find( slide => slide.id === gridItem.i ),
                                      position: index,
                                    }
                                  })
      const slideIdsWithinAdvancedSlides = state.tvShowSlides
                                                .filter( slide => slide.type === 'advanced' )
                                                .map( slide => Object.keys(slide.settings.containedSlidesById) )
                                                .flat()
                                                // uniq
                                                .filter( (slide, index, self) => self.indexOf(slide) === index )
      const slidesWithinAdvancedSlides = state.tvShowSlides
                                              .filter( slide => slideIdsWithinAdvancedSlides.includes(slide.id) )
                                              .map( (slide, index) => {
                                                return {
                                                  ...slide,
                                                  position: index + visibleSlides.length,
                                                }
                                              } )

      return {
        ...state,
        tvShowSlides: [ ...visibleSlides, ...slidesWithinAdvancedSlides ],
      }
    }

    case ActionTypes.PLAINLY_SET_SLIDES_PROPERTY:
      return {
        ...state,
        [action.property]: action.value,
      }

    default:
      return state
  }
}

export default slides
