import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'
import {Form, Button} from 'react-bootstrap'
import { GlgRadio, GlgTooltipIcon, GlgSelect, GlgCheckbox } from 'SharedComponents'

export class HandleFormatComponent extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      handle: this.props.membersHandle,
      hideOverride: !(('Custom Handle' === this.props.membersHandle) || ('John Doe' === this.props.membersHandle)),
      hideKanjiOverride: !(('Kanji Name' === this.props.membersHandle) || ('Doe John alternate' === this.props.membersHandle)),
      hasOverrideFirstName: this.props.hasOverrideFirstName,
      hasOverrideWithKanji: this.props.hasOverrideWithKanji,
      selectedField: this.props.customHandlesName,
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.getCurrentSelectedField = this.getCurrentSelectedField.bind(this)
  }
  
  handleClick(value){
    this.setState({handle: value})
    if (value === 'John Doe'){
      this.setState({hideOverride: false, hideKanjiOverride: true})
    } else {
      if (value === 'Doe John alternate'){
        this.setState({hideOverride: true, hideKanjiOverride: false})
      } else {
        this.setState({hideOverride: true, hideKanjiOverride: true})
      }
    }
  }
  
  handleSubmit(e){
    e.preventDefault()
    this.props.reformatHandles(this.state.handle, this.state.selectedField, this.state.hasOverrideFirstName, this.state.hasOverrideWithKanji)
  }

  handleSelect(value){
    this.setState({selectedField: value})
  }

  getCurrentSelectedField(){
    if (this.props.customHandlesName === ''){
      return 'None'
    } else {
      return this.props.customHandlesName
    }

  }

  render() {
    const disableReformatNamesButton = this.props.isReformatNamesLoading
    const isPgaEnabled = this.props.isPgaCustomer
    const isJapanCustomer = this.props.isJapanCustomer
    const pgaHandleFormatLabel = <span style={{padding: 0}}>{ !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.handle_format_component.pga_handle_format') }<GlgTooltipIcon tooltip={ !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.handle_format_component.pga_handle_format_info') }></GlgTooltipIcon></span>
    const OverrideCheckboxLabel = <span className="no-padding">{!window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.handle_format_component.override_name')}< GlgTooltipIcon tooltip={ !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.handle_format_component.override_info')} /></span>
    const KanjiOverrideCheckboxLabel = <span className="no-padding">{!window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.handle_format_component.jga.override_with_kanji')}</span>
    const isCustom = ('Custom Handle' === this.state.handle && !isPgaEnabled)
    const isKanji = ('Kanji Name' === this.state.handle && isJapanCustomer)
    return <Col xs={12} className={'leftsidebar_container no_padding_right no_padding_left left_sidebar_open_container'} id="fields_options_content"><Form onSubmit={this.handleSubmit} style={{width: '100%'}}><div className="btsp_ps_container_normal_scroll leftsidebar_paddings" id="choose_league_columns_scroll"><div className="league_form"><div className="title">{ !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.handle_format_component.handle_format') }</div><div className="clear subtitle">{ !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.handle_format_component.handle_format_info') }</div><div className="name_formats sidebar_form_content"><GlgRadio name="handles" label={ !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.handle_format_component.doe_j') } value="Doe, J" defaultChecked={ 'Doe, J' === this.state.handle } onChange={ this.handleClick }></GlgRadio><GlgRadio name="handles" label={ !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.handle_format_component.doe_john') } value="Doe, John" defaultChecked={ 'Doe, John' === this.state.handle } onChange={ this.handleClick }></GlgRadio><GlgRadio name="handles" label={ !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.handle_format_component.j_doe') } value="J. Doe" defaultChecked={ 'J. Doe' === this.state.handle } onChange={ this.handleClick }></GlgRadio><GlgRadio name="handles" label={ !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.handle_format_component.john_doe') } value="John Doe" defaultChecked={ 'John Doe' === this.state.handle || isCustom} onChange={ this.handleClick }></GlgRadio>{ (!this.state.hideOverride && !isPgaEnabled)
                  ? <div className='no-padding add-padding-left-20'>
                      <GlgCheckbox name='override_first_name'
                        label={OverrideCheckboxLabel}
                        key= {'override_first_name'}
                        checked={ this.state.hasOverrideFirstName }
                        onChange={(isChecked) => { this.setState({ hasOverrideFirstName: isChecked }) } }
                       />
                       { (this.state.hasOverrideFirstName) 
                        ?
                        <div className='select-container'>
                            { !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.handle_format_component.override_select_label') }
                            <GlgSelect
                              name={'variable_settings_custom_handle_name'}
                              items={ this.props.customFieldsNames }
                              defaultValue={ this.getCurrentSelectedField()}
                              onChange={this.handleSelect}
                              />
                        </div>
                        : null
                      }
                  </div>
                  : null
                }{isPgaEnabled && <GlgRadio name="handles" label={ pgaHandleFormatLabel } value="Custom Handle" defaultChecked={ 'Custom Handle' === this.state.handle } onChange={ this.handleClick }></GlgRadio>}<GlgRadio name="handles" label={ !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.handle_format_component.doe_john_alternate') } value="Doe John alternate" defaultChecked={ 'Doe John alternate' === this.state.handle || isKanji} onChange={ this.handleClick }></GlgRadio>{ (!this.state.hideKanjiOverride && isJapanCustomer)
                  ? <div className='no-padding add-padding-left-20'>
                    <GlgCheckbox name='override_with_kanji'
                                 label={KanjiOverrideCheckboxLabel}
                                 key= {'override_with_kanji'}
                                 checked={ this.state.hasOverrideWithKanji }
                                 onChange={(isChecked) => { this.setState({ hasOverrideWithKanji: isChecked }) } }
                    />
                  </div>
                  : null
                }</div></div></div><fieldset className="submit arrange_right submit_buttons no-top-border"><a href="#" className="btn button btsp_white_button close_sidebar" onClick={this.props.closeHandleFormatSidebar}>{ !window.I18n ? '' : window.I18n.t('buttons.cancel').charAt(0)
                .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.cancel').slice(1)) }</a><Button type="submit" className={`btsp_orange_button pull-right ${disableReformatNamesButton ? 'disabled' : ''}`} disabled={disableReformatNamesButton}>{disableReformatNamesButton ? (!window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.handle_format_component.reformatting')) : (!window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.handle_format_component.reformat_names'))}</Button></fieldset></Form></Col>
  }
}

HandleFormatComponent.propTypes = {
  isMembersLoading: PropTypes.bool.isRequired,
  isReformatNamesLoading: PropTypes.bool.isRequired,
  isPgaCustomer: PropTypes.bool.isRequired,
  isJapanCustomer: PropTypes.bool.isRequired,
  multiGrid: PropTypes.any,
  membersHandle: PropTypes.string.isRequired,
  setHandleFormatUrl: PropTypes.string.isRequired,
  history: PropTypes.any,
  closeHandleFormatSidebar: PropTypes.func.isRequired,
  reformatHandles: PropTypes.func.isRequired,
  customHandlesName: PropTypes.string.isRequired,
  customFieldsNames: PropTypes.array.isRequired,
  hasOverrideFirstName: PropTypes.bool.isRequired,
  hasOverrideWithKanji: PropTypes.bool.isRequired,
}

export default HandleFormatComponent
