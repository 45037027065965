// import { PopupActionTypes } from '../../shared/actions'
// import PopupTypes from '../popup_types'
// import { openPopup } from '../../shared/actions'
// export { openPopup, closePopup } from '../../shared/actions'

export const ActionTypes = {
  // ...PopupActionTypes,
  // Tab related actions
  CREATE_TAB: 'CREATE_TAB',
  EDIT_TAB: 'EDIT_TAB',
  SAVE_TABS: 'SAVE_TABS',
  UPDATE_TAB: 'UPDATE_TAB',
  REMOVE_TAB: 'REMOVE_TAB',
  DELETE_TAB: 'DELETE_TAB',
  SWAP_TABS: 'SWAP_TABS',
  DEFAULT_TAB_MODE: 'DEFAULT_TAB_MODE',
  // Block related actions
  CREATE_BLOCK: 'CREATE_BLOCK',
  EDIT_BLOCK: 'EDIT_BLOCK',
  UPDATE_BLOCK: 'UPDATE_BLOCK',
  REMOVE_BLOCK: 'REMOVE_BLOCK',
  BLOCK_REORDER: 'BLOCK_REORDER',
  // Column related actions
  CREATE_COLUMN: 'CREATE_COLUMN',
  UPDATE_COLUMN: 'UPDATE_COLUMN',
  SWAP_COLUMNS: 'SWAP_COLUMNS',
  RESIZE_COLUMN: 'RESIZE_COLUMN',
  REMOVE_COLUMN: 'REMOVE_COLUMN',
  COLUMN_DISPLAY: 'COLUMN_DISPLAY',
  COLUMN_CHANGE_POSITION: 'COLUMN_CHANGE_POSITION',
  // Filters related methods
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  SET_ACTIVE_COLUMN: 'SET_ACTIVE_COLUMN',
  SET_ACTIVE_BLOCK: 'SET_ACTIVE_BLOCK',
  SET_ACTIVE_POSITION: 'SET_ACTIVE_POSITION',
  SET_IFRAME_POINTER_OPTIONS: 'SET_IFRAME_POINTER_OPTIONS',
  SET_DRAGGABLE_ELEMENT: 'SET_DRAGGABLE_ELEMENT',
  SET_DRAGGED_OVER_ELEMENT: 'SET_DRAGGED_OVER_ELEMENT',
  SET_DRAGGED_OVER_POSITION: 'SET_DRAGGED_OVER_POSITION',
  DISPLAY_MODE: 'DISPLAY_MODE',
  SET_IFRAME_FORM_STATE: 'SET_IFRAME_FORM_STATE',
  ADD_NEW_WIDGET: 'ADD_NEW_WIDGET',
}

let tabID = -1000
let tabINDEX = 0
export const setTabIndex = _index => tabINDEX = _index
export const createTab = (_title, _titles) => ({
  type: ActionTypes.CREATE_TAB,
  id: tabID++,
  title: _title,
  titles: _titles,
  index: ++tabINDEX,
})

export const editTab = (_id) => ({
  type: ActionTypes.EDIT_TAB,
  id: _id,
})

export const saveTabs = (_tabs) => ({
  type: ActionTypes.SAVE_TABS,
  tabs: _tabs,
})

export const updateTab = (_id, _title) => ({
  type: ActionTypes.UPDATE_TAB,
  id: _id,
  title: _title,
})

export const updateBlock = (_id, _title, _titles) => ({
  type: ActionTypes.UPDATE_BLOCK,
  id: _id,
  title: _title,
  titles: _titles,
})

export const removeTab = (_id) => ({
  type: ActionTypes.REMOVE_TAB,
  id: _id,
})


export const setActiveTab = (_id) => ({
  type: ActionTypes.SET_ACTIVE_TAB,
  id: _id,
})

export const swapTabs = (_tabID1, _tabID2) => ({
  type: ActionTypes.SWAP_TABS,
  tabID1: _tabID1,
  tabID2: _tabID2,
})

export const DISPLAY_MODES = {
  DEFAULT: 'DEFAULT',
  BLOCK: 'BLOCK',
  COLUMN: 'COLUMN',
  WIDGET: 'WIDGET',
  TAB_FORM: 'TAB_FORM',
  TABS: 'TABS',
  NEW_PAGE: 'NEW_PAGE',
  EDIT_COLUMN_PROPS: 'EDIT_COLUMN_PROPS',
  BLOCK_REORDER: 'BLOCK_REORDER',
}

export const displayMode = (_mode) => ({
  type: ActionTypes.DISPLAY_MODE,
  display_mode: _mode,
})

export const setActiveColumn = (_columnId) => ({
  type: ActionTypes.SET_ACTIVE_COLUMN,
  column_id: _columnId,
})

export const setActiveBlock = (_blockId) => ({
  type: ActionTypes.SET_ACTIVE_BLOCK,
  block_id: _blockId,
})

export const setActivePosition = (_position) => ({
  type: ActionTypes.SET_ACTIVE_POSITION,
  position: _position,
})

export const setIframePointerOptions = (_option) => ({
  type: ActionTypes.SET_IFRAME_POINTER_OPTIONS,
  option: _option,
})

export const setDraggebleElement = (_element) => ({
  type: ActionTypes.SET_DRAGGABLE_ELEMENT,
  element: _element,
})

export const setDraggedOverElement = (_element) => ({
  type: ActionTypes.SET_DRAGGED_OVER_ELEMENT,
  element: _element,
})

export const setDraggedOverPosition = _position => ({
  type: ActionTypes.SET_DRAGGED_OVER_POSITION,
  position: _position,
})

export const setIframeFormState = (_status) => ({
  type: ActionTypes.SET_IFRAME_FORM_STATE,
  status: _status,
})

export const addNewWidget = (_widget) => ({
  type: ActionTypes.ADD_NEW_WIDGET,
  widget: _widget,
})


let blockID = -1000
let blockIndex = 0
export const setBlockIndex = _index => blockIndex = _index
export const createBlock = (_tabId, _title, _titles, _layout) => ({
  type: ActionTypes.CREATE_BLOCK,
  id: blockID++,
  tabId: _tabId,
  title: _title,
  titles: _titles,
  layout: _layout,
  index: ++blockIndex,
})

export const editBlock = (_id) => ({
  type: ActionTypes.EDIT_BLOCK,
  id: _id,
})

export const removeBlock = (_id) => ({
  type: ActionTypes.REMOVE_BLOCK,
  id: _id,
})

export const reorderBlocks = _blocks => ({
  type: ActionTypes.BLOCK_REORDER,
  blocks: _blocks,
})

let columnID = -1000
let columnINDEX = 0
export const setColumnIndex = _index => columnINDEX = _index
export const createColumn = (_blockId, _widgetId, _position, _title, _titles, _viewMore) => ({
  type: ActionTypes.CREATE_COLUMN,
  id: columnID++,
  blockId: _blockId,
  widget_id: _widgetId,
  position: _position,
  index: ++columnINDEX,
  title: _title,
  titles: _titles,
  viewMore: _viewMore,
})

export const updateColumn = (_id, _title, _titles, _viewMore) => ({
  type: ActionTypes.UPDATE_COLUMN,
  id: _id,
  title: _title,
  titles: _titles,
  viewMore: _viewMore,
})

export const resizeColumn = (_id, _height) => ({
  type: ActionTypes.RESIZE_COLUMN,
  id: _id,
  height: _height,
})

export const swapColumns = (_colID1, _colID2, _position) => ({
  type: ActionTypes.SWAP_COLUMNS,
  colID1: _colID1,
  colID2: _colID2,
  position: _position,
})

export const removeColumn = (_id) => ({
  type: ActionTypes.REMOVE_COLUMN,
  id: _id,
})

export const setColumnDisplay = (_id, _display) => ({
  type: ActionTypes.COLUMN_DISPLAY,
  id: _id,
  display: _display,
})

export const columnChangePosition = (_id, _position) => ({
  type: ActionTypes.COLUMN_CHANGE_POSITION,
  id: _id,
  position: _position,
})
