import React, {Component} from 'react'
import { GlgBasicSelect } from 'SharedComponents'
import { Col } from 'react-flexbox-grid'
import { FormControl } from 'react-bootstrap'
import PropTypes from 'prop-types'

export class AffiliationComponent extends Component {
  constructor(props){
    super(props)
    this.hideSelectAndShowInput = this.hideSelectAndShowInput.bind(this)
    this.state = {
      addNewField: false,
    }
  }
  
  hideSelectAndShowInput(e){
    e.preventDefault()
    this.setState({addNewField: true})
  }
  
  render(){
    // eslint-disable-next-line no-console
    console.log(this.props.answer)
    const affiliation1 = <Col xs={12} md={8}><Col xs={10} className="existent_affiliation"><GlgBasicSelect items={this.props.affiliationAnswers.map(x => ({value: x, label: x}))} value={this.props.answer ? this.props.answer : ''} onChange={(val) => this.props.onChange(this.props.field.id, this.props.field.category, this.props.field.name, val)} selectClass="form-control" prompt={ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.custom_fields_components.affiliation_component.no_affiliation') }></GlgBasicSelect></Col><Col xs={2} className="button_affiliation_remove vcenter"><button title={ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.custom_fields_components.affiliation_component.add_new_affiliation') } onClick={this.hideSelectAndShowInput} className="add_new_affiliation_button small grey">+</button></Col></Col>
    const affiliation2 = <Col xs={12} md={8}><FormControl type="input" name={`answer[${this.props.field.id}]`} defaultValue={this.props.answer} onChange={(e) => this.props.onChange(this.props.field.id, this.props.field.category, this.props.field.name, e.target.value)}></FormControl></Col>
    return !this.state.addNewField && (this.props.affiliationAnswers.length > 0 && (this.props.affiliationAnswers.indexOf(this.props.field.answer) || !!this.props.field.answer === false)) ? affiliation1 : affiliation2
  }
}

AffiliationComponent.propTypes = {
  field: PropTypes.object.isRequired,
  answer: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  affiliationAnswers: PropTypes.array.isRequired,
}

export default AffiliationComponent
