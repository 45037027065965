import { connect } from 'react-redux'
import MemberComponent from '../components/member_component'
import { addMembersToSticky, removeMembersFromSticky, addMembersToSplit, removeMembersFromSplit } from '../actions'


const mapStateToProps = (state, props) => {
  return { 
    member: props.member,
    stickyId: state.sticky.index,
    isSticky: props.isSticky,
    isMerging: props.isMerging,
    isSplitting: props.isSplitting,
    type: props.type,
    mergedMemberIds: state.sticky.newMembers.map(member => member['id'] ),
    mcIdentifierSet: state.misc.mcIdentifierSet,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addMembersToSticky: (member) => dispatch(addMembersToSticky(member)),
    removeMembersFromSticky: (member) => dispatch(removeMembersFromSticky(member)),
    addMembersToSplit: (member) => dispatch(addMembersToSplit(member)),
    removeMembersFromSplit: (member) => dispatch(removeMembersFromSplit(member)),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberComponent)
