import { connect } from 'react-redux'
import EventSetupCourseDetails from '../../components/content/event_setup_course_details'
import {
  setGenericData,
  saveEventSettingsWidget,
  loadWidgetContent,
} from '../../actions/index'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { courses, links } = ownProps.content

  return {
    setData: (keys, value, {updateDataChanged = true} = {}) => {
      dispatch(setGenericData(ownProps.widgetId, keys, value))
      if (updateDataChanged && !ownProps.content.dataChanged) {
        dispatch(setGenericData(ownProps.widgetId, [ 'dataChanged' ], true))
      }
    },
    save: () => {
      dispatch(saveEventSettingsWidget(ownProps.widgetId, links.save.path, {
        course_ids: courses.filter(c => c.valid).map(c => c.id),
        commit: 'Save',
      }, {
        dataType: 'html',
      })).then( ({ state }) => {
        const dependentWidgets = state.widgets[state.menu.selectedMenuItem].filter(w => w.content_type === 'event_setup_pairings')
        dependentWidgets.forEach(widget => {
          dispatch(loadWidgetContent(widget.data_path, widget.id))
        })
      })
    },
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(EventSetupCourseDetails)

