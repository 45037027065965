import { combineReducers } from 'redux'
import tabs from './tabs'
import filters from './filters'
import blocks from './blocks'
import columns from './columns'

export default combineReducers({
  tabs,
  filters,
  blocks,
  columns,
})
