import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

class ColumnContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      HEIGHT: null,
      startY: null,
    }
    this.finishedLoading = this.finishedLoading.bind(this)
    this.resizeCol = this.resizeCol.bind(this)
    this.startPosition = this.startPosition.bind(this)

    this.handleMousemove = this.handleMousemove.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousemove', e => this.handleMousemove(e))
    document.addEventListener('mouseup', () => {
      this.setState({ startY: null })
      this.props.setIframePointerOptions('auto')
    })
  }

  conponentWillUnmount() {
    document.removeEventListener('mousemove', true)
    document.removeEventListener('mouseup', true)
  }

  handleMousemove(e) {
    if (this.state.startY !== null) {
      this.resizeCol(e)
    }
  }

  finishedLoading() {
    setTimeout(
      () => {
        this.setState({
          loaded: true,
        })

        this.props.setIframeFormState()
      }, 1000)
  }

  startPosition(_val) {
    this.setState({
      startY: _val,
    })
  }

  resizeCol(e) {
    const newHeight = this.state.HEIGHT + (e.clientY - this.state.startY)
    this.props.resizeColumn(newHeight > 262 ? newHeight : 262)
  }

  render() {
    return (
      <Row className="column-content" style={{ height: `${this.props.HEIGHT}px` }}>
        {this.state.loaded !== true ?
          this.props.URL !== null
          ? <Col top="xs" className="loader">{window.I18n.t('multi_widgets.column_content.loading')}</Col>
          : window.I18n.t('multi_widgets.column_content.page_deleted')
           : null}
        {
          this.props.URL !== null &&
            <iframe width="100%"
              height="100%"
              src={this.props.URL}
              frameBorder="0"
              onLoad={() => this.finishedLoading()}
              style={{ pointerEvents: this.props.IframePointerOption, visibility: this.state.loaded ? 'visible' : 'hidden' }}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
        }
        {
          this.props.URL !== null &&
          <div className="resize-handle"
            onMouseDown={(e) => {
              this.startPosition(e.clientY)
              this.setState({ HEIGHT: this.props.HEIGHT })
              this.props.setIframePointerOptions('none')
            }}
            onMouseUp={() => { this.startPosition(null); this.setState({ HEIGHT: null }) }}>
            <div className="hotcorner-left"></div>
            <div className="hotcorner-right"></div>
          </div>
        }
      </Row>
    )
  }
}

ColumnContent.propTypes = {
  URL: PropTypes.string,
  HEIGHT: PropTypes.number,
  IframePointerOption: PropTypes.string,
  setIframePointerOptions: PropTypes.func,
  setIframeFormState: PropTypes.func,
  onEdit: PropTypes.func,
  resizeColumn: PropTypes.func,
}

export default ColumnContent
