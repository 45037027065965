import React, { Component } from 'react'
import PropTypes from 'prop-types'

class RoundPlayers extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { title, tableHeaders, data } = this.props.content

    return (
      <div className='widget-content-default widget-round-players'>
        <div className='title'>
          { title }
        </div>
        <div className='content'>
          <div className='round-players table-responsive'>
              <table className='table table-striped'>
                <thead>
                  <tr className='header'>
                    <th>{ tableHeaders.rounds }</th>
                    <th>{ tableHeaders.players }</th>
                  </tr>
                </thead>
                <tbody>
                    {
                        data.length > 0
                            ? data.map(row =>
                                <tr key={ row.name }>
                                    <td>{ row.name }</td>
                                    <td>{ row.playerCount }</td>
                                </tr>
                            )
                            : null
                    }
                </tbody>
              </table>
            </div>
        </div>
      </div>
    )
  }
}

RoundPlayers.propTypes = { 
  content: PropTypes.object,
}

RoundPlayers.defaultProps = {
  content: {
    count: '0 $',
    description: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.round_players.description'),
  },
}

export default RoundPlayers
