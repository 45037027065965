import React, { useState } from 'react'
import PropTypes from 'prop-types'

// The base tab component used for the Filtering and Bulk Edit tabs
const KeySettingsBaseTab = (props) => {
  // Component state
  const [ isExpanded, setIsExpanded ] = useState(false)
  
  // View Helpers
  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  // Render View
  return (
    <div className="expandable-box">
      {/* Header */}
      <button className="toggle-button" onClick={toggleExpand}>
        <strong>{props.title}</strong> 
        <span className="toggle-arrow">{isExpanded ? '▲' : '▼'}</span>
      </button>

      {/* Separator */}
      {isExpanded && (
        <div className="separator-container">
          <hr className="separator-line" />
        </div>
      )}

      {/* Content */}
      {isExpanded && (
        <>{props.children}</>  
      )}
    </div>
  )
}

KeySettingsBaseTab.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default KeySettingsBaseTab
