import { createSelectorCreator } from 'reselect'
import _ from 'lodash'

const hashFn = (...args) => {
  return args.reduce(
    (acc, val) => acc + '-' + JSON.stringify(val),
    '',
  )
}

const createDeepSelector = createSelectorCreator(
  _.memoize,
  hashFn,
)

export default createDeepSelector
