import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const popupScoresTeamRow = ({scoreData, isMultiRound, matchedScores, player}) => {

  const scores = [ ...scoreData.scores_array ]

  return (
    <tr className='scores_row'>
      <td className='with_border'>
        Score
        <div className='details'>{player.name}</div>
      </td>
      { [ ...Array(9) ].map( ( _, i) => (
        <Fragment key={i}>
          <td key={'hole-' + i} className={matchedScores[i] ? 'with_border' : 'with_border yellow_column'}>{scores[i]}</td>
          { (i + 1) % 3 === 0 && i !== 8 && <td className="with_border gray_column"></td> }
        </Fragment>
      )) }
      <td className='with_border blue_column'>{scoreData.out !== 0 ? scoreData.out : ''}</td>
      { [ ...Array(9) ].map( ( _, i) => (
        <Fragment key={i}>
          <td key={'hole-' + i} className={matchedScores[i + 9] ? 'with_border' : 'with_border yellow_column'}>{scores[i + 9]}</td>
          { (i + 1) % 3 === 0 && i !== 8 && <td className="with_border gray_column"></td> }
        </Fragment>
      )) }
      <td className='with_border blue_column'>{scoreData.in !== 0 ? scoreData.in : ''}</td>
      <td className='with_border blue_column'>{scoreData.round_score !== 0 ? scoreData.round_score : ''}</td>
      { isMultiRound && <td className='with_border blue_column'>{scoreData.total_score !== 0 ? scoreData.total_score : ''}</td> }
    </tr>
  )
}

popupScoresTeamRow.propTypes = {
  scoreData: PropTypes.object,
  isMultiRound: PropTypes.bool,
  matchedScores: PropTypes.array,
}

export default popupScoresTeamRow
