import React from 'react'
import PropTypes from 'prop-types'

import GlgGeneralIcon from './glg_general_icon'

const GlgRoundedButtonIcon = ({ text, icon, url, anchorClass, onClick, target = '_self' }) => {
  return <a className={ `clickable ${ anchorClass }` } href={ url } target={ target } rel="noopener noreferrer" onClick={ onClick }><div className="btsp_bullet_button"><div className="icon"><GlgGeneralIcon icon={ icon }></GlgGeneralIcon></div><div className="btsp_align-middle">{ text }</div></div></a>
}

GlgRoundedButtonIcon.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  url: PropTypes.string,
  onClick: PropTypes.func,
  anchorClass: PropTypes.string,
  target: PropTypes.string,
}

GlgRoundedButtonIcon.defaultProps = {
  anchorClass: '',
}

export default GlgRoundedButtonIcon
