import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { GlgPopup } from 'SharedComponents'

class EditDistancePopupComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      distance: this.props.distance,
    }  
    this.handleValueChanged = this.handleValueChanged.bind(this)
    this.onSave = this.onSave.bind(this)
  }
  
  handleValueChanged(event) {
    const target = event.target
    const value = target.value
    
    this.setState({distance: value})
  }

  onSave() {
    const { distanceId, stationId, editDistance } = this.props
    const { distance } = this.state
    
    editDistance(distanceId, stationId, distance)
  }
  
  render() {
    const { playerName, closePopup, show } = this.props  
    const { distance } = this.state
    const title = window.I18n.t('driving_distances.popup_title', {name: playerName})
    return (
      <GlgPopup title={ title }
                show={ show }
                onClose={ closePopup }
                onSave={ this.onSave }
                manualClose={ true }>
        <div className='btsp_form_content grey_border edit_distance add-padding-20 form-inline'>
          { window.I18n.t('driving_distances.distance') }
          <input type='number'
                 value={distance}
                 onChange={this.handleValueChanged}
                 className='add-margin-left-30 form-control'/>
          <span className='add-margin-left-10'>
            { this.props.distanceUnit }
          </span>
        </div>
      </GlgPopup>
    )
  }
}

EditDistancePopupComponent.propTypes = {
  playerName: PropTypes.string,
  distance: PropTypes.number,
  closePopup: PropTypes.func,
  show: PropTypes.bool,
  editDistance: PropTypes.func,
  distanceId: PropTypes.string,
  stationId: PropTypes.string,
  distanceUnit: PropTypes.string,
}

export default EditDistancePopupComponent
