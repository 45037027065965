/*
 * Returns leagues that pass all filters in a sorted order (by the chosen column)
 * Received optional 3rd parameter categoryId: it only takes leagues from that category
 */
import createDeepSelector from './create_deep_selector'
import _ from 'lodash'
import getVisibleLeagueIds from './get_visible_league_ids'
import { getColumnSortingValue } from '../column_helpers'
import getCategoriesNames from './get_categories_names'

const isEmpty = (value) => _.isNil(value) || value === ''

const getLeagueCategoryName = (leaguesById, leagueId, categoryNamesbyId) => {
  const categoryId = leaguesById[leagueId].category
  return categoryNamesbyId[categoryId]
}

const getLeaguesById = state => state.leagues
const getSortingOptions = state => state.sorting

const getSortedVisibleLeagueIds = createDeepSelector(
  [ getLeaguesById, getVisibleLeagueIds, getSortingOptions, getCategoriesNames ],
  (leaguesById, leagueIds, sortingOptions, categoryNamesbyId) => {
    if (leaguesById && Object.keys(leaguesById).length === 0) {

      return []
    }

    const computeComparisonValues = leagueId => [
      getColumnSortingValue(leaguesById[leagueId],
                            sortingOptions.column,
                            getLeagueCategoryName(leaguesById, leagueId, categoryNamesbyId)),
      leaguesById[leagueId].startDate,
      leaguesById[leagueId].name,
    ]

    const sortedLeagueIds = _.clone(leagueIds)

    sortedLeagueIds.sort((leagueId1, leagueId2) => {
      const league1 = computeComparisonValues(leagueId1)
      const league2 = computeComparisonValues(leagueId2)
      for (let i = 0; i < league1.length; i++) {
        if (!isEmpty(league1[i]) || !isEmpty(league2[i])) {
          if (isEmpty(league1[i]) !== isEmpty(league2[i])) {
            return isEmpty(league1[i]) < isEmpty(league2[i]) ? -1 : 1
          } else if (league1[i] !== league2[i]) {
            return league1[i] < league2[i] ? -1 : 1
          }
        }
      }
      return 0
    })

    if (sortingOptions.column !== 'position' &&
        sortingOptions.column !== 'none' &&
        sortingOptions.order === 'desc') {
      sortedLeagueIds.reverse()
    }

    return sortedLeagueIds
  }
)

export default getSortedVisibleLeagueIds
