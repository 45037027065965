import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const popupShotsRow = ({shots, isMultiRound, matchedScores}) => {
  return (
    <tr className='shots_row'>
      <td className='with_border'>Shots</td>
      { [ ...Array(9) ].map( ( _, i) => (
        <Fragment key={i}>
          <td key={'hole-' + i} className={matchedScores[i] ? 'with_border' : 'with_border yellow_column'}>{shots[i]}</td>
          { (i + 1) % 3 === 0 && i !== 8 && <td className="with_border gray_column"></td> }
        </Fragment>
      )) }
      <td className='with_border blue_column'></td>
      { [ ...Array(9) ].map( ( _, i) => (
        <Fragment key={i}>
          <td key={'hole-' + i} className={matchedScores[i + 9] ? 'with_border' : 'with_border yellow_column'}>{shots[i + 9]}</td>
          { (i + 1) % 3 === 0 && i !== 8 && <td className="with_border gray_column"></td> }
        </Fragment>
      )) }
      <td className='with_border blue_column'></td>
      <td className='with_border blue_column'></td>
      { isMultiRound && <td className='with_border blue_column'></td> }
    </tr>
  )
}

popupShotsRow.propTypes = {
  shots: PropTypes.array,
  isMultiRound: PropTypes.bool,
  matchedScores: PropTypes.array,
}

export default popupShotsRow
