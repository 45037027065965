import { ActionTypes } from '../actions/header'

function header(state = {}, action) {
  switch (action.type) {
    case ActionTypes.SET_HEADER_UNSAVED_CHANGES:
      return {
        ...state,
        hasUnsavedChanges: action.value,
      }

    case ActionTypes.TOGGLE_VISIBLE:
      return {
        ...state,
        isVisible: !state.isVisible,
      }

    case ActionTypes.CHANGE_HEIGHT:
      return {
        ...state,
        height: parseInt(action.newHeight),
      }

    case ActionTypes.CHANGE_OPACITY:
      return {
        ...state,
        backgroundOpacity: parseInt(action.newOpacity),
      }

    case ActionTypes.PLAINLY_SET_HEADER_PROPERTY:
      return {
        ...state,
        [action.property]: action.value,
      }

    case ActionTypes.SET_POPUP_PROPERTY:
      return {
        ...state,
        popups: {
          ...state.popups,
          [action.popupType]: {
            ...state.popups[action.popupType],
            [action.property]: action.propertyValue,
          },
        },
      }

    default:
      return state
  }
}

export default header
