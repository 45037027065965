import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { getTodayWithTimezone } from 'Shared/helpers'
import {
  GlgTooltip,
} from 'SharedComponents'

class LinksComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    e.preventDefault()
    this.props.linksProps.onClick(this.props.linksProps.addToCartPath)
  }

  render() {
    const linksProps = this.props.linksProps
    let hidden
    linksProps.hasCartship ? hidden = '' : hidden = 'hidden'

    let registrationMessage
    const today = getTodayWithTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)


    if (linksProps.cartMerchantAccountId) {
      if (linksProps.merchantAccountId === undefined || linksProps.merchantAccountId.length === 0 || linksProps.registrationWithPayments === false) {
        registrationMessage = !window.I18n ? '' : window.I18n.t('customer_directories.tooltips.no_payments_required', { product: linksProps.product})
      }
      if (linksProps.cartMerchantAccountType) {
        if (linksProps.merchantAccountId.toString() !== linksProps.cartMerchantAccountId.toString() && linksProps.merchantAccountTypeAccount === 'nmi' || (linksProps.merchantAccountTypeAccount !== linksProps.cartMerchantAccountType)) {
          registrationMessage = !window.I18n ? '' : window.I18n.t('customer_directories.tooltips.cant_add_to_cart', { product: linksProps.product})
        }
      }
    }
    const elementsForRender = []
    elementsForRender.push( <div className={hidden + ' semi-bold proceed_to_cart'}><GlgTooltip tooltip={!window.I18n ? '' : window.I18n.t('customer_directories.tooltips.can_checkout_now')}><a href={linksProps.websiteMultiRegistrationCartURL + '?context=multi_registration'} target={'_top'}>{ !window.I18n ? '' : window.I18n.t('customer_directories.links.checkout') }</a></GlgTooltip><div className="circle_divider"></div></div>)
    if ((!linksProps.conditionForCart || !linksProps.leagueMultiEventsRegistration) && this.props.status !== 'Closed') {
      elementsForRender.push( <span className="semi-bold"><a href={linksProps.GGIDGuestLoginURL} target={'_top'}>{ !window.I18n ? '' : window.I18n.t('customer_directories.links.view') }</a></span>)
    }
    if (linksProps.status === 'Closed' || linksProps.status === '') {
      elementsForRender.push( <span className="semi-bold">{linksProps.status !== '' && (!linksProps.conditionForCart || !linksProps.leagueMultiEventsRegistration) && this.props.status !== 'Closed' && (linksProps.status === '' && (linksProps.teeSheetPath || linksProps.resultsPath)) && <div className="circle_divider"></div>}{linksProps.status !== '' && linksProps.teeSheetPath && <a href={linksProps.teeSheetPath} target={'_top'}>{ !window.I18n ? '' : window.I18n.t('customer_directories.links.tee_sheet') }</a>}{linksProps.teeSheetPath && linksProps.resultsPath && !moment(today).isBefore(linksProps.startDate) && <div className="circle_divider"></div>}{linksProps.resultsPath && !moment(today).isBefore(linksProps.startDate) && <Fragment>{!linksProps.teeSheetPath && this.props.status !== 'Closed' && <div className="circle_divider"></div>}<a href={linksProps.resultsPath} target={'_top'}>{ !window.I18n ? '' : window.I18n.t('customer_directories.links.results') }</a></Fragment>}{!(linksProps.resultsPath && !moment(today).isBefore(linksProps.startDate)) && !linksProps.teeSheetPath && linksProps.status !== '' && <a href={linksProps.GGIDGuestLoginURL} target={'_top'}>{ !window.I18n ? '' : window.I18n.t('customer_directories.links.view') }</a>}</span>)
    }
    if (linksProps.condition1 && linksProps.MRMember) {
      if (linksProps.condition2) {
        if (!linksProps.conditionForCart || !linksProps.leagueMultiEventsRegistration) {
          elementsForRender.push(<div className="circle_divider"></div>)
        }
        elementsForRender.push(<span className="semi-bold"><a href={linksProps.registerPath} className={'your_registration_button'} target={'_top'}>{ !window.I18n ? '' : window.I18n.t('customer_directories.links.edit_registration') }</a></span>)
      } else {
        if (linksProps.condition3) {
          if (linksProps.registrationTypes && linksProps.registrationTypes.length > 0) {
            if (linksProps.leagueMultiEventsRegistration) {
              if (!linksProps.hasCartship) {
                if (linksProps.hasCartship) {
                  elementsForRender.push(<div id={'cartship-button-League-' + linksProps.id} className={'add_to_cart_btn'}><a href={linksProps.cartCartshipPath} className={'customer_directory_add_button remove_from_cart_btn'} id={'add-to-cart-btn-League-' + linksProps.id}>{ !window.I18n ? '' : window.I18n.t('customer_directories.links.remove_from_cart') }</a><div className="circle_divider"></div></div>)
                } else {
                  let disabled
                  registrationMessage ? disabled = 'disabled' : disabled = ''
                  if (disabled) {
                    elementsForRender.push(<div id={'cartship-button-League-' + linksProps.id} className={'add_to_cart_btn'}><span className="semi-bold"><GlgTooltip tooltip={registrationMessage}><a href={linksProps.cartCartshipPath} onClick={ e => e.preventDefault()} className={'customer_directory_add_button remove_from_cart_btn ' + disabled} id={'add-to-cart-btn-League-' + linksProps.id}>{ !window.I18n ? '' : window.I18n.t('customer_directories.links.add_to_cart') }</a></GlgTooltip><div className="circle_divider"></div></span></div>)
                  } else {
                    elementsForRender.push(<div id={'cartship-button-League-' + linksProps.id} className={'add_to_cart_btn'}><span className="semi-bold"><a href={linksProps.cartCartshipPath} onClick={ e => this.handleClick(e)} className={'customer_directory_add_button remove_from_cart_btn'} id={'add-to-cart-btn-League-' + linksProps.id}>{ !window.I18n ? '' : window.I18n.t('customer_directories.links.add_to_cart') }</a><div className="circle_divider"></div></span></div>)
                  }
                }

              } else {
                elementsForRender.push(<div id={'cartship-button-League-' + linksProps.id} className={'add_to_cart_btn'}></div>)
              }
            }
          }
          if (!linksProps.conditionForCart || !linksProps.leagueMultiEventsRegistration) {
            elementsForRender.push(<span className="semi-bold"><div className="circle_divider"></div></span>)
          }
          if (linksProps.cartConditionWithInclude) {
            elementsForRender.push(<span className="semi-bold"><GlgTooltip tooltip={registrationMessage ? registrationMessage : !window.I18n ? '' : window.I18n.t('customer_directories.tooltips.already_in_cart')}><a onClick={ (e) => { e.preventDefault() } } className={'registration_button disabled'} target={'_top'}>{ !window.I18n ? '' : window.I18n.t('customer_directories.links.register_now') }</a></GlgTooltip></span>)
          } else {
            if (linksProps.cartCondition) {
              elementsForRender.push(<span className="semi-bold"><GlgTooltip tooltip={registrationMessage ? registrationMessage : !window.I18n ? '' : window.I18n.t('customer_directories.tooltips.add_to_cart')}><a onClick={ (e) => { e.preventDefault() } } className={'registration_button disabled'} target={'_top'}>{ !window.I18n ? '' : window.I18n.t('customer_directories.links.register_now') }</a></GlgTooltip></span>)
            } else {
              let disabled
              registrationMessage ? disabled = 'disabled' : disabled = ''
              if (disabled) {
                elementsForRender.push(<span className="semi-bold"><GlgTooltip tooltip={registrationMessage ? registrationMessage : !window.I18n ? '' : window.I18n.t('customer_directories.tooltips.already_in_cart')}><a onClick={ (e) => { e.preventDefault() } } className={'registration_button disabled'} target={'_top'}>{ !window.I18n ? '' : window.I18n.t('customer_directories.links.register_now') }</a></GlgTooltip></span>)
              } else {
                let displayText
                if (linksProps.willBeDisabled){
                  displayText = !window.I18n ? '' : window.I18n.t('customer_directories.links.waitlist_full')
                } else if (linksProps.willBeWaitlisted) {
                  displayText = !window.I18n ? '' : window.I18n.t('customer_directories.links.add_to_waitlist')
                } else if (linksProps.willBePending) {
                  displayText = !window.I18n ? '' : window.I18n.t('customer_directories.links.add_to_pending')
                }
                if (linksProps.willBeDisabled){
                  elementsForRender.push(<span className="semi-bold"><GlgTooltip tooltip={window.I18n.t('customer_directories.links.waitlist_full_tooltip')}><a href={linksProps.willBeDisabled ? null : linksProps.registerPath} className={linksProps.willBeDisabled ? 'registration_button disabled' : 'registration_button'} target={'_top'}>{displayText ? displayText : !window.I18n ? '' : window.I18n.t('customer_directories.links.register_now')}</a></GlgTooltip></span>)
                } else {
                  elementsForRender.push(<span className="semi-bold"><a href={ !(!linksProps.hasOpenTeeTimes || linksProps.hasBookingAfterRegister) ? linksProps.bookNowPath : linksProps.registerPath} className={'registration_button'} target={'_top'}>{displayText ? displayText : !window.I18n ? '' : linksProps.hasOpenTeeTimes && !linksProps.hasBookingAfterRegister ? window.I18n.t('customer_directories.links.book_now') : window.I18n.t('customer_directories.links.register_now')}</a></span>)
                }
              }
            }
          }
        } else if (linksProps.eligibilityMessage) {
          if (linksProps.leagueMultiEventsRegistration && linksProps.registrationTypes && linksProps.registrationTypes.length > 0) {
            elementsForRender.push(<span className="semi-bold"><GlgTooltip tooltip={registrationMessage ? registrationMessage : linksProps.eligibilityMessage}><a onClick={ (e) => { e.preventDefault() } } className={'add_to_cart_button disabled'}>{ !window.I18n ? '' : window.I18n.t('customer_directories.links.add_to_cart') }</a></GlgTooltip><div className="circle_divider"></div></span>)
          }
          if (linksProps.cartConditionWithInclude) {
            elementsForRender.push(<span className="semi-bold"><GlgTooltip tooltip={registrationMessage ? registrationMessage : !window.I18n ? '' : window.I18n.t('customer_directories.tooltips.already_in_cart')}><a onClick={ (e) => { e.preventDefault() } } className={'registration_button disabled'}>{ !window.I18n ? '' : window.I18n.t('customer_directories.links.register_now') }</a></GlgTooltip></span>)
          } else {
            if (linksProps.showLinkToRenew){
              if (!linksProps.conditionForCart || !linksProps.leagueMultiEventsRegistration) {
                elementsForRender.push(<span className="semi-bold"><div className="circle_divider"></div></span>)
              }
              elementsForRender.push(<span className="semi-bold"><a href={linksProps.widgetsMPPath}>{ !window.I18n ? '' : window.I18n.t('customer_directories.links.renew_membership') }</a></span>)
            } else if (linksProps.eligibilityMessage) {
              if ((!linksProps.conditionForCart || !linksProps.leagueMultiEventsRegistration) && this.props.status !== 'Closed'){
                elementsForRender.push(<div className="circle_divider"></div>)
              }

              elementsForRender.push(<span className="semi-bold"><GlgTooltip tooltip={registrationMessage ? registrationMessage : linksProps.eligibilityMessage}><a onClick={ (e) => { e.preventDefault() } } className={'registration_button disabled'}>{ !window.I18n ? '' : window.I18n.t('customer_directories.links.register_now') }</a></GlgTooltip></span>)
            } else if (linksProps.hasCartship) {
              elementsForRender.push(<span className="semi-bold"><GlgTooltip tooltip={!window.I18n ? '' : window.I18n.t('customer_directories.tooltips.add_more_to_cart')}><a onClick={ (e) => { e.preventDefault() } } className={'registration_button disabled'}>{ !window.I18n ? '' : window.I18n.t('customer_directories.links.register_now') }</a></GlgTooltip></span>)
            }
          }
        }
      }
    }

    return elementsForRender.map( (element, index) => <span key={index}>{element}</span>)
  }
}

LinksComponent.propTypes = {
  linksProps: PropTypes.shape({
    hasCartship: PropTypes.bool,
    websiteMultiRegistrationCartURL: PropTypes.string,
    condition1: PropTypes.bool,
    condition2: PropTypes.bool,
    condition3: PropTypes.bool,
    condition4: PropTypes.bool,
    conditionForCart: PropTypes.bool,
    leagueMultiEventsRegistration: PropTypes.bool,
    GGIDGuestLoginURL: PropTypes.string,
    MRMember: PropTypes.object,
    registerPath: PropTypes.string,
    bookNowPath: PropTypes.string,
    hasOpenTeeTimes: PropTypes.bool,
    hasBookingAfterRegister: PropTypes.bool,
    product: PropTypes.string,
    registrationTypes: PropTypes.array,
    id: PropTypes.string,
    cartCartshipPath: PropTypes.string,
    cartCondition: PropTypes.bool,
    eligibilityMessage: PropTypes.string, 
    cartConditionWithInclude: PropTypes.bool,
    widgetsMPPath: PropTypes.string,
    teeSheetPath: PropTypes.string,
    resultsPath: PropTypes.string,
    onClick: PropTypes.func,
    addToCartPath: PropTypes.string,
    cartMerchantAccountId: PropTypes.any,
    merchantAccountId: PropTypes.any,
    registrationWithPayments: PropTypes.any,
    cartMerchantAccountType: PropTypes.any,
    merchantAccountTypeAccount: PropTypes.any,
    status: PropTypes.any,
    startDate: PropTypes.any,
    willBeWaitlisted: PropTypes.any,
    willBePending: PropTypes.any,
    willBeDisabled: PropTypes.any,
    showLinkToRenew: PropTypes.any,
  }),
  status: PropTypes.string,


}
export default LinksComponent
