import { showErrorNotification, callAPI } from 'Shared/helpers'

export const ActionTypes = {
  OPEN_POPUP: 'OPEN_POPUP',
  CLOSE_POPUP: 'CLOSE_POPUP',
  EDIT_DISTANCE: 'EDIT_DISTANCE',
  LOAD_PLAYERS: 'LOAD_PLAYERS',
  START_LOAD_PLAYERS: 'START_LOAD_PLAYERS',
  CHANGE_SORT_OPTION: 'CHANGE_SORT_OPTION',
  CHANGE_SORT_ORDER_OPTION: 'CHANGE_SORT_ORDER_OPTION',
  CHANGE_SEARCH_VALUE: 'CHANGE_SEARCH_VALUE',
}

export const openPopupAction = (playerName, distance, distanceId) => ({
  type: ActionTypes.OPEN_POPUP,
  playerName,
  distance,
  distanceId,
})

export const closePopupAction = () => ({
  type: ActionTypes.CLOSE_POPUP,
})

export const openPopup = (playerName, distance, distanceId) => (dispatch) => {
  dispatch(openPopupAction(playerName, distance, distanceId))
}

export const closePopup = () => (dispatch) => {
  dispatch(closePopupAction())
}

export const editDistanceAction = (data) => ({
  type: ActionTypes.EDIT_DISTANCE,
  data,
})

export const loadPlayersForStationAction = (data) => ({
  type: ActionTypes.LOAD_PLAYERS,
  data,
})

export const loadPlayersForStationStartedAction = () => ({
  type: ActionTypes.START_LOAD_PLAYERS,
})

export const changeSortOptionAction = (value) => ({
  type: ActionTypes.CHANGE_SORT_OPTION,
  value,
})

export const changeSortOrderOptionAction = (value) => ({
  type: ActionTypes.CHANGE_SORT_ORDER_OPTION,
  value,
})

export const changeSearchValueAction = (value) => ({
  type: ActionTypes.CHANGE_SEARCH_VALUE,
  value,
})

export const changeSortOption = (value) => (dispatch) => {
  if (value === 'distance') {
    dispatch(changeSortOrderOptionAction('desc'))
  }
  dispatch(changeSortOptionAction(value))
}

export const changeSortOrderOption = () => (dispatch, getState) => {
  const state = getState()
  const order = state.filters.sortOrder === 'asc' ? 'desc' : 'asc'
  dispatch(changeSortOrderOptionAction(order))
}

export const changeSearchValue = (value) => (dispatch) => {
  dispatch(changeSearchValueAction(value))
}

export const editDistance = (distanceId, stationId, distance) => (dispatch, getState) => {
  const state = getState()
  const roundId = state.misc.roundId
  const url = '/rounds/' + roundId + '/driving_distances/edit_distance'
  const params = {}
  params['distanceId'] = distanceId
  params['distance'] = distance
  params['stationId'] = stationId
  
  callAPI(url, 'POST', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch(closePopupAction())
        dispatch(editDistanceAction(response))
      })
      .catch(error => {
        dispatch(closePopupAction())
        if ( error !== undefined) {
          showErrorNotification(error)
        } else {
          showErrorNotification(window.I18n.t('driving_distances.error'))
        }
      })
}

export const loadPlayersForStation = (stationId) => (dispatch, getState) => {
  const state = getState()
  const url = state.misc.fetchPlayersForStationPath
  const adjustment = state.misc.drivingStations.find(station => station.id === stationId).adjustment
  const params = {}
  params['stationId'] = stationId
  dispatch(loadPlayersForStationStartedAction())
  callAPI(url, 'GET', params)
    .then(response => {
      const data = {}
      data['players'] = response
      data['adjustment'] = adjustment
      dispatch(loadPlayersForStationAction(data))
    })
    .catch(error => {
      if ( error !== undefined) {
        showErrorNotification(error)
      } else {
        showErrorNotification(window.I18n.t('driving_distances.error'))
      }
    })
}

export const refreshPlayersForStation = (stationId) => (dispatch, getState) => {
  const state = getState()
  const url = state.misc.fetchPlayersForStationPath
  const adjustment = state.misc.drivingStations.find(station => station.id === stationId).adjustment
  const params = {}
  params['stationId'] = stationId
  callAPI(url, 'GET', params)
      .then(response => {
        const data = {}
        data['players'] = response
        data['adjustment'] = adjustment
        dispatch(loadPlayersForStationAction(data))
      })
      .catch(error => {
        if ( error !== undefined) {
          showErrorNotification(error)
        } else {
          showErrorNotification(window.I18n.t('driving_distances.error'))
        }
      })
}
