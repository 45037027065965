import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, InputGroup, FormControl } from 'react-bootstrap'
import { Icon as FaIcon } from 'react-fa'
const { Addon } = InputGroup

class LeagueNameFilter extends PureComponent {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.onReset = this.onReset.bind(this)
    this.inputRef = React.createRef()
  }

  onChange(event) {
    this.props.onChange('leagueName', event.target.value)
  }

  onReset() {
    this.props.onChange('leagueName', '')
    this.inputRef.focus()
  }

  render() {
    const searchText = (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.league_name.search'))
    return <FormGroup className={ this.props.loadingFilters ? 'filter btsp_filter filter_league_name_container' : 'filter btsp_filter' }><InputGroup><FormControl type="text" value={ this.props.value } placeholder={ searchText } onChange={ this.onChange } inputRef={ ref => { this.inputRef = ref } }></FormControl><Addon><a onClick={ this.onReset } className="clickable"><FaIcon name="eraser"></FaIcon></a></Addon></InputGroup></FormGroup>
  }
}

LeagueNameFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  loadingFilters: PropTypes.bool,
}

export default LeagueNameFilter
