import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
const OverflowTooltip = props => {

  const textElementRef = useRef()
  const [ isOverflowing, setIsOverflowing ] = useState(false)

  const compareSize = () => {
    const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth ||
                    textElementRef.current.scrollHeight > textElementRef.current.clientHeight
    setIsOverflowing(compare)
  }

  useEffect(() => {
    window.addEventListener('mouseover', compareSize)

    return () => {
      window.removeEventListener('mouseover', compareSize)
    }
  }, [])

  return (
    <div ref={textElementRef} title={isOverflowing ? props.description : ''} className={props.additionalClasses}>
      {props.description}
    </div>
  )
}

OverflowTooltip.propTypes = { 
  description: PropTypes.string,
  additionalClasses: PropTypes.string,
}

export default OverflowTooltip
