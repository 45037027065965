import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from 'react-bootstrap'

class EditableImageTitle extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditing: false,
      title: this.props.title,
    }

    this.onChange = this.onChange.bind(this)
    this.switchToEditable = this.switchToEditable.bind(this)
    this.handleTitleUpdate = this.handleTitleUpdate.bind(this)
    this.cancelEditing = this.cancelEditing.bind(this)
    this.inputRef = React.createRef()
  }

  componentDidUpdate() {
    this.inputRef.current && this.inputRef.current.focus()
  }

  onChange(e) {
    this.setState({
      title: e.target.value,
    })
  }

  switchToEditable(isEditing) {
    this.setState({
      isEditing: isEditing,
    })
  }

  handleTitleUpdate(event) {
    if (event.type === 'mousedown' || (event.type === 'keydown' && event.key === 'Enter')) {
      if (this.state.title) {
        this.switchToEditable(false)
        this.props.updateImageTitle(this.state.title)
      } else {
        this.setState({
          isEditing: false,
          title: !window.I18n ? '' : window.I18n.t('tv_shows.components.editable_image_title.no_name'),
        })
        this.props.updateImageTitle(!window.I18n ? '' : window.I18n.t('tv_shows.components.editable_image_title.no_name'))
      }
    }
  }

  cancelEditing() {
    this.setState({
      isEditing: false,
      title: this.props.title,
    })
  }

  render() {
    return (
      this.state.isEditing ? <span><FormControl type="text" value={ this.state.title } onChange={ this.onChange } onBlur={ this.cancelEditing } inputRef={ ref => { this.inputRef.current = ref } } onKeyDown={ this.handleTitleUpdate } placeholder={ !window.I18n ? '' : window.I18n.t('tv_shows.components.editable_image_title.image_title') } className="edit-title"></FormControl><input type="submit" value={ !window.I18n ? '' : window.I18n.t('tv_shows.components.editable_image_title.save_title') } onMouseDown={ this.handleTitleUpdate } className="save-title no-border white_background" /></span> : <a onClick={ () => this.switchToEditable(true) } className="blue-force-link truncate-title image-title-width">{ this.state.title }</a>
    )
  }
}

EditableImageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  updateImageTitle: PropTypes.func.isRequired,
}

export default EditableImageTitle
