import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { mapToSelectArray } from 'Shared/helpers'

import PopupTypes from '../../popup_types'
import {
  GlgPopup,
  GlgSelect,
  GlgPopupForm,
  GlgFormInput,
} from 'SharedComponents'

class ChangeCategoryPopup extends Component {
  constructor(props) {
    super(props)
    this.onClose = props.onClose.bind(this, PopupTypes.CHANGE_CATEGORY)
    this.onSave = this.onSave.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    return this.props.show !== nextProps.show
  }

  onSave(data) {
    const propsData = this.props.data
    this.props.onSave(propsData.leagueId,
                      data.to_category_id)
  }

  getLeagueName() {
    if (this.props.data) {
      return this.props.data.leagueName || ''
    } else {
      return ''
    }
  }

  getCurrentCategory() {
    if (this.props.data) {
      return this.props.data.categoryId || ''
    } else {
      return ''
    }
  }

  render() {
    const categoriesSelectOptions = mapToSelectArray(this.props.categoriesOptions)

    return <GlgPopup title={ `Select new category for ${ this.getLeagueName() }` } show={ this.props.show } onClose={ this.onClose } onSave={ this.onSave }><GlgPopupForm><GlgFormInput label="Category"><GlgSelect name="to_category_id" items={ categoriesSelectOptions } defaultValue={ this.getCurrentCategory() }></GlgSelect></GlgFormInput></GlgPopupForm></GlgPopup>
  }
}

ChangeCategoryPopup.propTypes = {
  data: PropTypes.object,
  categoriesOptions: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default ChangeCategoryPopup
