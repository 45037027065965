/*
 * GlgTableCell is used by GlgTable to build table cells.
 */

import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import OverflowTooltip from './overflow_tooltip'

const GlgTableCell = ({ children, ...props }) => {
  // We don't pass title on because we show our own tooltip
  const divProps = _.omit(props, 'title')
  return <OverflowTooltip divProps={ divProps }>{ children }</OverflowTooltip>
}

GlgTableCell.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GlgTableCell
