import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FormGroup, ControlLabel } from 'react-bootstrap'
import { Row, Col } from 'react-flexbox-grid'

import { getColumnType } from '../../column_helpers'
import { GlgSelect } from 'SharedComponents'

import SortingOrderToggle from './sorting_order_toggle'

class SortingFilter extends PureComponent {
  render() {
    let sortOptions = _.cloneDeep(SORT_BY_OPTIONS)
    if (!this.props.includeToursOption) {
      sortOptions.splice(10, 1)
    }
    if (!this.props.includeSeasonsOption) {
      sortOptions.splice(9, 1)
    }
    if (this.props.includeCategoryOption) {
      sortOptions.splice(9, 0, {
        value: 'categoryName',
        label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.sorting_filter.category')),
      })
    }
    if (!this.props.userIsCustomerManager && !this.props.userIsTourManager) {
      sortOptions[0] = {
        value: 'none',
        label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.sorting_filter.none')),
      }
    }
    if (!this.props.showRegistrationOptions) {
      sortOptions = sortOptions.filter(option => (
        !SORT_BY_REGISTRATION_OPTIONS.includes(option.value)
      ))
    }

    const showToggle = this.props.sortingColumn !== 'position' &&
                       this.props.sortingColumn !== 'none'

    const sortByText = (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.sorting_filter.sort_by'))
    return <FormGroup><Row><Col xs={true}><ControlLabel className="add-margin-top-5">{sortByText}</ControlLabel></Col></Row><Row><Col xs={ showToggle ? 10 : 12 } sm={ showToggle ? 9 : 12 } className={ showToggle ? 'no-padding-right' : '' }><GlgSelect items={ sortOptions } value={ this.props.sortingColumn } onChange={ this.props.onSortingColumnChange } disabled={ this.props.disabled }></GlgSelect></Col>{showToggle && <Col xs={2} sm={3} className="no-padding-left"><SortingOrderToggle sortingOrder={ this.props.sortingOrder } sortingColumnType={ getColumnType(this.props.sortingColumn) } onChange={ this.props.onSortingOrderChange } disabled={ this.props.disabled }></SortingOrderToggle></Col>}</Row></FormGroup>
  }
}

SortingFilter.propTypes = {
  sortingColumn: PropTypes.string.isRequired,
  sortingOrder: PropTypes.string.isRequired,
  onSortingColumnChange: PropTypes.func.isRequired,
  onSortingOrderChange: PropTypes.func.isRequired,
  includeSeasonsOption: PropTypes.bool.isRequired,
  includeToursOption: PropTypes.bool.isRequired,
  includeCategoryOption: PropTypes.bool.isRequired,
  userIsCustomerManager: PropTypes.bool.isRequired,
  userIsTourManager: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  showRegistrationOptions: PropTypes.bool.isRequired,
}

const SORT_BY_OPTIONS = [
  {
    value: 'startDate',
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.sorting_filter.options.start_date')),
  },
  {
    value: 'endDate',
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.sorting_filter.options.end_date')),
  },
  {
    value: 'name',
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.sorting_filter.options.name')),
  },
  {
    value: 'registrationStatus',
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.sorting_filter.options.registration_status')),
  },
  {
    value: 'registrationStartDate',
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.sorting_filter.options.registration_start_date')),
  },
  {
    value: 'registrationCloseDate',
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.sorting_filter.options.registration_close_date')),
  },
  {
    value: 'confirmedGolfersCount',
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.sorting_filter.options.confirmed_golfers_count')),
  },
  {
    value: 'waitlistedGolfersCount',
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.sorting_filter.options.waitlisted_golfers_count')),
  },
  {
    value: 'pendingRegistrationCount',
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.sorting_filter.options.pending_registration_count')),
  },
  {
    value: 'season',
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.sorting_filter.options.season')),
  },
  {
    value: 'tour',
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.sorting_filter.options.tour')),
  },
  {
    value: 'position',
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.sorting_filter.options.drag_drop_manual_sorting')),
  },
]

const SORT_BY_REGISTRATION_OPTIONS = [
  'registrationStatus',
  'registrationStartDate',
  'registrationCloseDate',
  'waitlistedGolfersCount',
  'pendingRegistrationCount',
]

export default SortingFilter
