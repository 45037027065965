import { connect } from 'react-redux'
import TabForm from '../presentational/tab_form'
import { createTab, displayMode, DISPLAY_MODES } from '../../actions'

const mapStateToProps = state => ({
  tabs: state.tabs,
  activeTab: state.filters.active_tab,
  usesTranslations: state.filters.uses_translations,
  availableLanguages: state.filters.available_languages,
  currentLanguage: state.filters.current_language,
})

const mapDispatchToProps = dispatch => ({
  createTab: (_title, _titles) => {
    dispatch(createTab(_title, _titles))
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
  cancel: () => {
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TabForm)
