import { connect } from 'react-redux'
import HoleByHolePreviewComponent from '../components/hole_by_hole_preview_component'

import { getPlayersOnMonsterBoard } from 'Shared/course_boards'

const mapStateToProps = (state) => {
  const lines = getPlayersOnMonsterBoard(state)

  return {
    lines: lines,
    showMessage: state.misc.showMessage,
    message: state.misc.message,
    score: state.misc.score,
    showCourse: state.misc.showCourse,
    withTeams: state.misc.withTeams,
    tees: state.misc.tees,
    roundsCount: state.misc.roundsCount,
    showRounds: state.misc.showRounds,
    showTotal: state.misc.showTotal,
  }
}

export default connect(
  mapStateToProps,
  null,
)(HoleByHolePreviewComponent)
