import React, { Component } from 'react'
import PropTypes from 'prop-types'

class RegistrationsCount extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { count, description } = this.props.content

    return (
      <div className='widget-content-statistics widget-content-statistics-darker'>
        <div className='count'>{ count }</div>
        <div className='description'>{ description }</div>
      </div>
    )
  }
}

RegistrationsCount.propTypes = { 
  content: PropTypes.object,
}

RegistrationsCount.defaultProps = {
  content: {
    count: '0 $',
    description: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.registrations_count.description'),
  },
}

export default RegistrationsCount
