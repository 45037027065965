import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

class PrePlayAudit extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount(prevProps) {
    if (prevProps === undefined) {
      this.props.getAuditsStatus()
    }
  }

  componentDidUpdate() {
    const runningAudits = Object.entries(this.props.content.audits)
                                .map(audit => audit[1].status)
                                .filter(status => status === 'running')
    if (runningAudits.length !== 0) {
      setTimeout(
        () => this.props.getAuditsStatus(),
        5000
      )
    }
    Object.entries(this.props.content.audits)
          .forEach(audit => [ 'warning', 'failed' ].includes(audit[1].status) && audit[1].errors.messages === '' ? this.props.getAuditErrorMessages(audit[1].errors.path) : null)

  }

  stringParameterize(str) {
    return str.trim().toLowerCase()
      .replace(/[^a-zA-Z0-9 -]/g, '')
      .replace(/ +/g, ' ')
      .replace(/[\s-]/g, '_')
  }

  render() {
    const { title, links, preloaderPath, audits } = this.props.content

    return (
      <div className='widget-content-default widget-content-rounds widget-content-pre-play-audit'>
        <div className='title'>
          { title }

          <a href={ links.pre_play_audit.path } className='btn btn-primary widget-white-title-button'>
            { links.pre_play_audit.name }
          </a>
        </div>
        <div className='content'>
          <span className='btn btn-primary widget-white-content-button' onClick={ () => this.props.refresh() }>
            { links.audit_status.name }
          </span>
          {
            Object.entries(audits).map((audit) =>
              <div key={ audit[0] } className='audit-status-data'>
                <div className='last-run-container'>
                  { !window.I18n ? '' : window.I18n.t(`dataauditlog_rounds.${this.stringParameterize(audit[1].name)}.name`, {defaultValue: audit[1].name}) }
                  <p className='last-run'>{ `• ${!window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.pre_play_audit.last_run')}: ${audit[1].last_run_at}` }</p>
                </div>
                <div className={ `status-block ${audit[1].status}` }>
                  { audit[1].status === 'running' ? <Fragment><img src={ preloaderPath } alt='preloader' className='preloader' /> { !window.I18n ? '' : window.I18n.t(`dataauditlog_rounds.result.${this.stringParameterize(audit[1].status)}`, {defaultValue: audit[1].status}) }</Fragment> : null }
                  { audit[1].status === 'passed' ? <Fragment><i className='fa fa-check'></i> { !window.I18n ? '' : window.I18n.t(`dataauditlog_rounds.result.${this.stringParameterize(audit[1].status)}`, {defaultValue: audit[1].status}) }</Fragment> : null }
                  { audit[1].status === 'warning' ? <Fragment><i className='fa fa-times'></i> { !window.I18n ? '' : window.I18n.t(`dataauditlog_rounds.result.${this.stringParameterize(audit[1].status)}`, {defaultValue: audit[1].status}) }</Fragment> : null }
                  { ![ 'running', 'passed', 'warning' ].includes(audit[1].status) ? <Fragment><i className='fa fa-exclamation'></i> { !window.I18n ? '' : window.I18n.t(`dataauditlog_rounds.result.${this.stringParameterize(audit[1].status)}`, {defaultValue: audit[1].status}) }</Fragment> : null }
                </div>
                {
                  audit[1].errors.messages !== ''
                    ? <div className={ `audit-message ${audit[1].status}` }>
                      <div className='type'>{ !window.I18n ? '' : window.I18n.t(`dataauditlog_rounds.result.${this.stringParameterize(audit[1].status)}`, {defaultValue: audit[1].status}) }</div>
                      <div className='message' dangerouslySetInnerHTML={{__html: audit[1].errors.messages }} />
                    </div>
                  : null
                }
              </div>
            )
          }
        </div>
      </div>
    )
  }
}

PrePlayAudit.propTypes = {
  content: PropTypes.object,
  getAuditsStatus: PropTypes.func,
  getAuditErrorMessages: PropTypes.func,
  refresh: PropTypes.func,
}


PrePlayAudit.defaultProps = {
  content: {
    title: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.pre_play_audit.title'),
    links: {
      audit_status: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.pre_play_audit.run_pre_play_audit'),
        path: '#',
      },
    },
    preloaderPath: '#',
    audits: [],
  },
}

export default PrePlayAudit
