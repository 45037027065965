import React from 'react'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'
import PropTypes from 'prop-types'
import indexPageReducer from '../reducers/tvShowsIndex'

import TvShowsIndexContainer from './tv_shows_index_container'

const TvShowsIndexRoot = (props) => {
  const state = initialState(props)
  const store = configureStore(indexPageReducer, state)

  return <GlobalProvider store={ store }><TvShowsIndexContainer paths={ props.paths } requestProtocol={ props.requestProtocol } isLibrary={ props.isLibrary }></TvShowsIndexContainer></GlobalProvider>
}

const initialState = (props) => {
  return {
    tvShows: props.tvShows,
  }
}

TvShowsIndexRoot.propTypes = {
  tvShows: PropTypes.array,
  paths: PropTypes.object.isRequired,
  requestProtocol: PropTypes.string.isRequired,
  isLibrary: PropTypes.bool.isRequired,
}

export default TvShowsIndexRoot
