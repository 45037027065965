import { connect } from 'react-redux'
import MatchPlayBoard from '../components/match_play_board'

const mapStateToProps = (state) => {
  return {
    matchLines: state.misc.matchLines,
    acknowledgedLines: state.misc.acknowledgedLines,
    refreshUrl: state.misc.refreshUrl,
    acknowedgedDate: state.misc.acknowedgedDate,
    lastUpdatedDate: state.misc.lastUpdatedDate,
    acknowledgeUrl: state.misc.acknowledgeUrl,
    isTeam: state.misc.isTeam,
    teamNames: state.misc.teamNames,
    todayPoints: state.misc.todayPoints,
    totalPoints: state.misc.totalPoints,
    acknowledgedTodayPoints: state.misc.acknowledgedTodayPoints,
    acknowledgedTotalPoints: state.misc.acknowledgedTotalPoints,
  }
}

export default connect(
  mapStateToProps
)(MatchPlayBoard)
