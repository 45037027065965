import { connect } from 'react-redux'
import BoardTableComponent from '../components/board_table_component'

import { getTableColumns, getPlayersOnBoard } from 'Shared/course_boards'
import { removeLine, reorderLines, handleShowMessageChanged, handleMessageChanged, handleScoreChanged } from '../actions' 

const mapStateToProps = (state, ownProps) => {
  const isMultiRound = state.misc.isMultiRound
  const withTeams = state.misc.withTeams
  const showCourse = state.misc.showCourse
  const columns = getTableColumns(isMultiRound, withTeams, showCourse, true)
  const lines = getPlayersOnBoard(state)

  return {
    isMultiRound: isMultiRound,
    withTeams: withTeams,
    showCourse: showCourse,
    lines: lines,
    columns: columns,
    showMessage: state.misc.showMessage,
    message: state.misc.message,
    score: state.misc.score,
    noRows: state.misc.noRowsOnCourseLeaderboard,
    setHeight: ownProps.setHeight,
  }
}

const mapDispatchToProps = (dispatch) => ({
  reorderLines: (fromPosition, toPosition) => dispatch(reorderLines(fromPosition, toPosition)),
  removeLine: (position) => dispatch(removeLine(position)),
  handleShowMessageChanged: (value) => dispatch(handleShowMessageChanged(value)),
  handleMessageChanged: (value) => dispatch(handleMessageChanged(value)),
  handleScoreChanged: (value) => dispatch(handleScoreChanged(value)),
})


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BoardTableComponent)
