import React, {useEffect, useRef, useState} from 'react'
import {toggleRefreshQueue, refreshSessionStartTime, update} from '../actions'
import {useDispatch, useSelector} from 'react-redux'
import { callAPI } from 'Shared/helpers'
import OttScreen from '../ott_screen'
import { createPortal } from 'react-dom'


const OttRefreshStatusBar = () => {

  const isRefreshQueuedUp = useSelector(state => state.isRefreshQueuedUp)
  const lastRefreshTime = useSelector(state => state.lastRefreshTime)
  const fullTeeSheet = useSelector(state => state.fullTeeSheet)
  const sessionStartTime = useSelector(state => state.sessionStartTime)
  const isSwapModeOn = useSelector(state => state.isSwapModeOn)
  const isShortPollingEnabled = useSelector(state => state.shortPollingEnablement)
  const activeScreen = useSelector(state => state.activeScreen)
  const leagueId = useSelector(state => state.leagueId)
  const roundId = useSelector(state => state.roundId)
  const twoHoursSinceRegistration = useSelector(state => state.twoHoursSinceRegistration)
  const currentUser = useSelector(state => state.currentUser)
  const isLoggedInWithGgid = useSelector(state => state.isLoggedInWithGgid)
  const dispatch = useDispatch()
  const currentMemberId = useSelector(state => state.currentMemberId)
  const [ nextRefresh, setNextRefresh ] = useState(0)

  const isRefreshQueuedUpRef = useRef(isRefreshQueuedUp)
  const lastRefreshTimeRef = useRef(lastRefreshTime)
  const activeScreenRef = useRef(activeScreen)
  const currentMemberIdRef = useRef(currentMemberId)
  const sessionStartTimeRef = useRef(sessionStartTime)

  useEffect(() => {
    isRefreshQueuedUpRef.current = isRefreshQueuedUp
  }, [ isRefreshQueuedUp ])

  useEffect(() => {
    lastRefreshTimeRef.current = lastRefreshTime
  }, [ lastRefreshTime ])

  useEffect(() => {
    activeScreenRef.current = activeScreen
  }, [ activeScreen ])

  useEffect(() => {
    sessionStartTimeRef.current = sessionStartTime
  }, [ sessionStartTime ])

  useEffect(() => {
    currentMemberIdRef.current = currentMemberId
    dispatch(refreshSessionStartTime(true))
  }, [ currentMemberId ])

  useEffect(() => {
    const intervalId = setInterval(() => {

      const currentTime = new Date()

      const timeDifference = currentTime - lastRefreshTimeRef.current

      const secondsDifference = timeDifference / 1000

      const isGreaterThan8Seconds = secondsDifference > getShortPollingPeriod()

      const isGreaterThan2Seconds = secondsDifference > 2

      setNextRefresh(getShortPollingPeriod() - Math.round(secondsDifference))

      if (((isRefreshQueuedUpRef.current && isGreaterThan2Seconds) || isGreaterThan8Seconds) && shouldUseLiveOtt()) {
        dispatch(toggleRefreshQueue(false))
        setNextRefresh(0)
        updateTeeSheet()
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [ ] )

  const shouldUseLiveOtt = () => {

    const dormantUser = (activeScreen !== OttScreen.ADMIN_OR_MANAGER_SCREEN) && getSessionTime > 600

    if (activeScreenRef.current === OttScreen.REMOVE_PLAYERS || activeScreenRef.current === OttScreen.EDIT_BOOKING || fullTeeSheet || dormantUser || isSwapModeOn || !isShortPollingEnabled || isLoggedInWithGgid || !currentUser) {
      return false
    }

    return true
  }

  const updateTeeSheet = () => {
    const url = `/leagues/${leagueId}/widgets/players_choose_tee_times/update_tee_sheet`
    callAPI(url, 'POST', { round_id: roundId, selected_member_id: currentMemberId })
      .then((resp) => {
        dispatch(update(resp.message))

      })
      .catch(() => {
      })

  }

  const getShortPollingPeriod = () => {

    if (activeScreen === OttScreen.ADMIN_OR_MANAGER_SCREEN && (currentMemberIdRef.current === null || currentMemberIdRef.current === '' )) {
      if (getSessionTime < 900) {
        return twoHoursSinceRegistration ? 120 : 60
      } else {
        return 180
      }

    }
    return getIncreasedShortPollingPeriodForBasicCase()
  }

  const getSessionTime = () => {

    const currentTime = new Date()

    const timeDifference = currentTime - sessionStartTimeRef.current

    return timeDifference / 1000

  }

  const getIncreasedShortPollingPeriodForBasicCase = () => {

    const secondsDifference = getSessionTime()

    if (secondsDifference < 180) {
      return twoHoursSinceRegistration ? 20 : 10
    } else if (secondsDifference < 360) {
      return twoHoursSinceRegistration ? 60 : 20
    } else if (secondsDifference < 600) {
      return twoHoursSinceRegistration ? 60 : 45
    }
  }

  const formatDate = (date) => {

    let hours = date.getHours()
    const minutes = date.getMinutes()
      .toString()
      .padStart(2, '0')
    const ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'

    return `${hours}:${minutes} ${ampm}`
  }

  return (
    shouldUseLiveOtt() === true &&
      <div className="ott-refresh-status-bar">
        <div className="ott-refresh-status-bar__text">
          {nextRefresh <= 0
            ? window.I18n.t('players_choose_tee_times.ott_2_0.status_bar.refreshing')
            : window.I18n.t('players_choose_tee_times.ott_2_0.status_bar.message', {last_refresh_time: `${formatDate(lastRefreshTime)}`, next_refresh: `${nextRefresh}`})
          }
        </div>
      </div>
  )
}


export default OttRefreshStatusBar
