import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'
import BoardRowComponent from './board_row_component'

import { GlgTable, GlgTableCol, GlgCheckbox } from 'SharedComponents'
import { getColumnName, getColumnWidth } from 'Shared/course_boards'
import GlgTextInput from '../../shared/components/glg_text_input'
import {getHeight} from '../helpers'

class BoardTableComponent extends Component {
  constructor(props) {
    super(props)
    this.renderHeaderCell = this.renderHeaderCell.bind(this)
    this.renderFooter = this.renderFooter.bind(this)
    this.handleResize = this.handleResize.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.props.setHeight(getHeight() - 20)
  }

  componentDidUpdate() {
    window.addEventListener('resize', this.handleResize)
    this.props.setHeight(getHeight() - 20)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize() {
    this.props.setHeight(getHeight() - 20)
  }

  renderHeaderCell(column) {
    let cellData
    if (column === 'actions') {
      cellData = getColumnName(column)
    } else {
      cellData = this.getSortableColumnHeader(column)
    }
    return (
      <GlgTableCol dataKey={column} key={column} width={getColumnWidth(column)}>
        {cellData}
      </GlgTableCol>
    )
  }

  renderFooter() {
    const { isMultiRound, withTeams, showCourse, showMessage, 
      message, score, handleShowMessageChanged,
      handleScoreChanged, handleMessageChanged,
    } = this.props

    const scoreSpan = isMultiRound ? 2 : 1
    let messageSpan = withTeams ? 2 : 3
    if (showCourse) {
      messageSpan += 1
    }

    return (
      <tr className='footer-row'>
        <td className='glg-table-header' colSpan={1}>
          <GlgCheckbox name='show-message'
                      checked={showMessage}
                      onChange={(checked) => handleShowMessageChanged(checked)}/>
        </td>
        <td className='glg-table-header message-cell' colSpan={messageSpan}>
          <span>{ window.I18n.t('course_leaderboards.message') }:</span>
          <GlgTextInput placeholder='' value={message} onChange={(value) => handleMessageChanged(value)}/>
        </td>
        <td className='glg-table-header score-cell' colSpan={scoreSpan}>
          <span>{ window.I18n.t('course_leaderboards.score') }:</span>
          <GlgTextInput placeholder='' value={score} onChange={(value) => handleScoreChanged(value)}/>
        </td>
        <td className='glg-table-header'/>
      </tr>
    )
  }

  render() {
    const { columns, lines, removeLine, reorderLines } = this.props
    const rowExtraProps = {
      columns: columns,
      removeLine: removeLine,
    }
    const footer = this.renderFooter()
    
    return (
      <Fragment>
        <Col xs={12} sm={12} md={6} lg={6} className='no-padding'>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className='page_title'>
              <div className='text'>{ window.I18n.t('course_leaderboards.on_course_leaderboard_design') }</div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className='course_leaderboard_table'>
            <GlgTable items={lines}
                      rowComponent={ BoardRowComponent}
                      rowExtraProps={ rowExtraProps }
                      isSortable={true}
                      onSortEnd={reorderLines}
                      showFooter={true}
                      footerComponent={ footer }>
              {
                columns.map( column => (
                  <GlgTableCol key={column} width={getColumnWidth(column)}>
                    {getColumnName(column)}
                  </GlgTableCol>
                ))
              }
            </GlgTable>
          </Col>
        </Col>
      </Fragment>
    )
  }
}

BoardTableComponent.propTypes = {
  isMultiRound: PropTypes.bool,
  withTeams: PropTypes.bool,
  showCourse: PropTypes.bool,
  lines: PropTypes.array,
  columns: PropTypes.array,
  showMessage: PropTypes.bool,
  message: PropTypes.string,
  score: PropTypes.string,
  removeLine: PropTypes.func,
  reorderLines: PropTypes.func,
  handleShowMessageChanged: PropTypes.func,
  handleScoreChanged: PropTypes.func,
  handleMessageChanged: PropTypes.func,
  setHeight: PropTypes.func,
}

export default BoardTableComponent
