import { ActionTypes } from '../actions/index'

const {
  OPEN_LEAGUE_PROPAGATION_POPUP,
  CLOSE_LEAGUE_PROPAGATION_POPUP,
  GET_LEAGUES_PROPAGATION_REQUEST,
  GET_LEAGUES_PROPAGATION_RECEIVED,
  PROPAGATE_LEAGUES_REQUEST,
  PROPAGATE_LEAGUES_RECEIVED,
} = ActionTypes

const initialState = {
  leagues: [],
  isLoading: false,
  isOpen: false,
  isPropagateLeaguesLoading: false,
  isCancelPropagateLeaguesLoading: false,
}

const leaguesPropagation = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_LEAGUE_PROPAGATION_POPUP:
      return {
        ...state,
        isOpen: true,
      }
    case CLOSE_LEAGUE_PROPAGATION_POPUP:
      return {
        ...state,
        isOpen: false,
      }
    case GET_LEAGUES_PROPAGATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_LEAGUES_PROPAGATION_RECEIVED:
      return {
        ...state,
        isLoading: false,
        leagues: action.leagues,
      }
    case PROPAGATE_LEAGUES_REQUEST:
      return {
        ...state,
        isPropagateLeaguesLoading: action.action === 'propagate',
        isCancelPropagateLeaguesLoading: action.action === 'cancel',
      }
    case PROPAGATE_LEAGUES_RECEIVED:
      return {
        ...state,
        isPropagateLeaguesLoading: false,
        isCancelPropagateLeaguesLoading: false,
      }
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default leaguesPropagation
