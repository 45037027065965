import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MenuContainer from '../containers/menu_container'
import Page from './page'


class Layout extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.props.refreshCurrentWidgets(this.props.widgets, this.props.getLinksPath) //* REDUX - call the method from mapDispatchToProps
      }
    })
  }

  render() {
    // Due to how the app is structure this is the only way to change the parent elements style without modifing other parts of the app
    // --------------------------------------------------------------------------------------------------------------------------------
    $('#breadcrumb').css('margin-bottom', '0px')
    $('#main_content').css('padding', '1px')
    $('.powered_by_margin.add-margin-bottom-30').css('display', 'none')

    window.redo_qtip()

    return (
      <span id='dashboard-v2-layout'>
        <MenuContainer getLinksPath={ this.props.getLinksPath } />
        <Page widgets={ this.props.widgets } />
      </span>
    )
  }
}

Layout.propTypes = {
  menuItems: PropTypes.object,
  mobileVisibility: PropTypes.bool,
  toggleMenu: PropTypes.func,
  selectedMenuItem: PropTypes.string,
  getLinksPath: PropTypes.string,
  widgets: PropTypes.array,
  refreshCurrentWidgets: PropTypes.func,
}

export default Layout
