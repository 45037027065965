import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GlgButton, GlgLoadingSpinner } from 'SharedComponents'
import { NONE, SUCCESS } from '../constants'
class PhotoRollComponent extends Component {
  
  constructor(props) {
    super(props)

    this.state = {
      isFetchingData: false,
      isUploadingData: false,
      selected: '',
      selectedForUpload: [],
      imagesLinks: [],
    }

    this.setStateProperty = this.setStateProperty.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleUpload = this.handleUpload.bind(this)
  }

  setStateProperty(property, value) {
    this.setState({
      ...this.state,
      [property]: value,
    })
  }

  handleFileChange(e) {
    const fileType = e.target.files[0].type

    if (!(fileType === 'image/x-png' || fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg')) {
      // eslint-disable-next-line no-alert
      alert(!window.I18n ? '' : window.I18n.t('photo_editor.album.file_type_alert'))
      this.setState({
        ...this.state,
        selectedForUpload: [],
        filenameForUpload: '',
      })
    } else {
      this.setState({
        selectedForUpload: e.target.files,
      }, () => this.handleUpload())    
    }
  }

  handleUpload() {
    const imageInFormData = new FormData()
    imageInFormData.append('image', this.state.selectedForUpload[0] )
    this.setStateProperty('isUploadingData', true)

    $.ajax({
      url: this.props.uploadImagePath,
      data: imageInFormData,
      processData: false,
      contentType: false,
      type: 'POST',
      success: (response) => {
        if (response.status === SUCCESS) {
          this.props.addToRoll(response.id, 'photo', response.link)
        }
      },
      error: () => {},
      complete: () => { this.setStateProperty('isUploadingData', false) },
    })
  }

  render() {
    const minHeight = this.props.items.length > 0 ? { minHeight: '300px' } : {}

    return <div className="div"><div className={'well white_background clearfix text-align-center'} style={{
            maxHeight: '300px',
            ...minHeight,
          }}>{ this.props.items.length === 0 && 'The photo roll is empty. Please choose some photos. ' }<ul className="logos col-xs-12 album_picker_roll_container">{ this.props.items.length > 0 && this.props.items.map( (item, index) => <li key={ index } className={ 'photo_roll_container' }><div className={ 'logo' }>{
                    item.type === 'album' ?
                      item.photo[0]['link'] === NONE ?
                      <div className="logo-none">{ NONE }</div> :
                      <img src={ item.photo[0]['link'] } />
                    :
                      item.photo === NONE ?
                      <div className="logo-none">{ NONE }</div> :
                      <img src={ item.photo } />
                  }</div><div className="text-center photo_roll_delete_label">{ `${item.type === 'album' ? 'Album' : 'Photo'}` }<i onClick={ () => this.props.removeFromRoll(index) } className="fa fa-trash-o photo_roll_delete_icon"></i></div></li>)}</ul></div><div style={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}><div className="vertical-align-items"><div className="hidden"><input id="btn-choose-image" type="file" accept="image/x-png,image/png,image/gif,image/jpeg,image/jpg" onChange={ this.handleFileChange } ref={fileInput => this.fileInput = fileInput} /></div><GlgButton text={ this.state.isUploadingData ? 'Uploading...' : this.props.uploadText } icon="arrow-circle-up" color="orange" disabled={ this.state.isUploadingData } onClick={ () => this.fileInput.click() }></GlgButton><div className="add-margin-left-5"><GlgLoadingSpinner show={ this.state.isUploadingData } width={ 20 } height={ 20 }></GlgLoadingSpinner></div></div></div></div>
  }
}

PhotoRollComponent.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  showNone: PropTypes.bool,
  uploadText: PropTypes.string,
  saveText: PropTypes.string.isRequired,
  saveDisabledText: PropTypes.string.isRequired,
  fetchImagesPath: PropTypes.string.isRequired,
  uploadImagePath: PropTypes.string.isRequired,
  addToRoll: PropTypes.func.isRequired,
  removeFromRoll: PropTypes.func.isRequired,
  items: PropTypes.array,
}

PhotoRollComponent.defaultProps = {
  uploadText: 'Upload to roll',
  showNone: false,
  show: true,
  saveText: 'Save',
  saveDisabledText: 'Save',
}

export default PhotoRollComponent
