import React, {Component} from 'react'
import { GlgCheckbox } from 'SharedComponents'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'

export class ArrayComponent extends Component {
  constructor(props){
    super(props)
  }
  
  render(){
    let arrayComponent
    if (this.props.field.value){
      arrayComponent = <Col xs={12} md={8}>{this.props.field.value.map((v, i) => <div key={`string_component_${i}`} className="checkbox_radio_container"><GlgCheckbox name={`answer[${this.props.field.id}]`} label={v} value={v} defaultChecked={this.props.answer ? this.props.answer.indexOf(v) > -1 : false} disabled={this.props.field.disabled} radioClass={'i-checks'} onChange={(val) => this.props.onChange(this.props.field.id, this.props.field.category, this.props.field.name, val, v)}></GlgCheckbox></div>)}</Col>
    } else {
      arrayComponent = <Col xs={12} md={8}><span style={{fontWeight: 'bold'}}>{ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.custom_fields_components.array_component.without_answer') }</span></Col>
    }
    return arrayComponent
  }
}

ArrayComponent.propTypes = {
  field: PropTypes.object.isRequired,
  answer: PropTypes.array,
  onChange: PropTypes.func.isRequired,
}

export default ArrayComponent
