import React from 'react'
import PropTypes from 'prop-types'
import {GlobalProvider} from '../../../shared/global_context'
import configureStore from '../../../shared/configure_store'
import ManagerSideContainer from './manager_side_container'
import managerSideReducer from '../../reducers/manager_side_index'

const DraftManagerSideRoot = (initialState) => {

  const pickedGolfers = initialState.picks.map(p => p.golfer?.id).filter(id => id)

  const state = {
    draftManagerSide: {
      draftGolferUrl: initialState.draftGolferUrl,
      undoDraftGolferUrl: initialState.undoDraftGolferUrl,
      draftCompletedUrl: initialState.draftCompletedUrl,
      draftsIndexUrl: initialState.draftsIndexUrl,
      golfers: initialState.golfers,
      pickTime: initialState.pickTime,
      availableGolfers: initialState.golfers.filter(g => !pickedGolfers.includes(g.id)),
      picks: initialState.picks,
      pickingPos: initialState.pickingPos,
      teamCount: initialState.teamCount,
      nowPicking: initialState.picks[initialState.pickingPos],
      lastPick: initialState.pickingPos > 0 ? initialState.picks[initialState.pickingPos - 1] : null,
      upcomingPicks: initialState.picks.slice(initialState.pickingPos + 1, initialState.pickingPos + 4),
      openModal: false,
      saveInProgress: false,
    },
  }

  const store = configureStore(managerSideReducer, state)

  return (
    <GlobalProvider store={store}>
      <ManagerSideContainer/>
    </GlobalProvider>
  )
}

DraftManagerSideRoot.propTypes = {
  initialState: PropTypes.any,
}

export default DraftManagerSideRoot
