import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

const CalendarCustomInput = forwardRef(
  ({ value, onClick, onChange, onKeyDown, withTime = false, isDisabled = false }, ref) => {
    return (
      <div className='input-group'>
        <input
          type='text'
          className='form-text form-control date_for_age datepicker_past'
          value={value}
          onClick={onClick}
          onKeyDown={onKeyDown}
          onChange={onChange}
          disabled={isDisabled}
          ref={ref}
        />
        <span className='input-group-addon input-date-age-gender' onClick={onClick}>
          <i className={withTime ? 'fa fa-clock-o' : 'gga-set-up-your-events-date'} />
        </span>
      </div>
    )
  }
)

CalendarCustomInput.displayName = 'CalendarCustomInput'

CalendarCustomInput.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  withTime: PropTypes.bool,
  isDisabled: PropTypes.bool,
  ref: PropTypes.any,
}

export default CalendarCustomInput

