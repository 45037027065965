import { combineReducers } from 'redux'
import categories from './categories'
import leagues from './leagues'
import seasons from './seasons'
import tours from './tours'
import filters from './filters'
import customers from './customers'
import sorting from './sorting'
import managedCustomerData from './managed_customer_data'
import misc from './misc'

import popupReducerCreator from 'Shared/reducers/popup_reducer_creator'
import PopupTypes from '../popup_types'
const popupStates = popupReducerCreator(PopupTypes)

export default combineReducers({
  categories,
  leagues,
  seasons,
  tours,
  popupStates,
  filters,
  customers,
  sorting,
  managedCustomerData,
  misc,
})
