import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

class NewPageForm extends Component {
  constructor(props) {
    super(props)

    this.props.setIframeFormState(null)
    this.onLoad = this.onLoad.bind(this)
  }

  onLoad() {
    document.getElementById('new-page-iframe-loader').classList.add('hidden')
    document.getElementById('new-page-form-iframe').classList.remove('hidden')
  }

  render() {
    if (this.props.iframeFormStatus === 'submitting') {
      document.getElementById('new-page-iframe-loader').classList.remove('hidden')
      document.getElementById('new-page-form-iframe').classList.add('hidden')
    } else if (this.props.iframeFormStatus === 'cancel') {
      this.props.close()
      this.props.setIframeFormState(null)
    } else if (typeof this.props.iframeFormStatus !== 'string' && this.props.iframeFormStatus !== null) {
      this.props.addNewWidget(this.props.iframeFormStatus)
      this.props.setIframeFormState(null)
      this.props.createColumn(this.props.blockID, this.props.iframeFormStatus.page.widget_id, this.props.position, '', true)
    }

    return (
      <Col xs={12} className="iframe-forms-content">
        <Row>
          <Col xs={12}>
            <Col xs={8}>
              <h1 className="title">{window.I18n.t('multi_widgets.pages.new_page_title')}</h1>
            </Col>
            <Col xs={4}>
              <span onClick={ () => this.props.close() } className="pull-right x-button">
                <i className="fa fa-times fa-fw"></i>
              </span>
            </Col>
          </Col>
          <Col xs={12} className="inner-content">
            <div className="form-row" id="modal-setting">
              <div id="new-page-iframe-loader" className="loader">{window.I18n.t('multi_widgets.pages.loading')}</div>
              <iframe width="100%"
                      height="500px"
                      src={ this.props.URL }
                      frameBorder="0"
                      id="new-page-form-iframe"
                      onLoad={ () => this.onLoad() }
                      className='hidden'
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
            </div>
          </Col>
        </Row>
      </Col>
    )
  }
}

NewPageForm.propTypes = {
  URL: PropTypes.string,
  iframeFormStatus: PropTypes.any,
  blockID: PropTypes.any,
  position: PropTypes.any,
  setIframeFormState: PropTypes.func,
  close: PropTypes.func,
  addNewWidget: PropTypes.func,
  createColumn: PropTypes.func,
}

export default NewPageForm
