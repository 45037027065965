import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { FormGroup, FormControl } from 'react-bootstrap'
import {
  GlgPopup,
  GlgPopupForm,
  GlgRadio,
  GlgCheckbox,
} from '../../../../shared/components'

class SetFlashMessage extends Component {
  constructor(props){
    super(props)
  }

  render() {
    return (
      <GlgPopup id='set-flash-message'
                title={ !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.popups.set_flash_message.title') }
                saveButtonText={ !window.I18n ? '' : window.I18n.t('buttons.save').charAt(0)
                  .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.save').slice(1)) }
                saveDisableWith={ !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.popups.set_flash_message.creating') }
                closeButtonText={ !window.I18n ? '' : window.I18n.t('buttons.cancel').charAt(0)
                  .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.cancel').slice(1)) }
                show={ this.props.showModal }
                onSave={ () => this.props.onSave() }
                // formURL={ this.props.formURL }
                onClose={ () => this.props.onClose() }
                saveButtonEnabled={ true }
                modalClass='set-flash-message'>
        <GlgPopupForm className='flash-manager margin_top_10 margin_bottom_10 well white_background' id='set-flash-message-form'>
            <FormGroup className='add-margin-top-5'>
                <FormControl name='website[message]'
                             type='text'
                             placeholder= { !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.popups.set_flash_message.flash_message') }
                             onChange={ event => this.props.changeFlashMessageData('message', event.target.value) }
                             defaultValue={ this.props.formData.message } />
            </FormGroup>
            <FormGroup className='add-margin-top-5'>
                <GlgCheckbox name='website[show_flash_with_manager_name]'
                             label={ !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.popups.set_flash_message.include_manager_name') }
                             onChange={ () => this.props.changeFlashMessageData('show_flash_with_manager_name', !this.props.formData.show_flash_with_manager_name) }
                             defaultChecked={ this.props.formData.show_flash_with_manager_name } />
                <GlgRadio name='website[show_flash_for_everyone]'
                          value='true'
                          label={ !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.popups.set_flash_message.show_for_everyone') }
                          onChange={ () => this.props.changeFlashMessageData('show_flash_for_everyone', true) }
                          defaultChecked={ this.props.formData.show_flash_for_everyone } />
                <GlgRadio name='website[show_flash_for_everyone]'
                          value='false'
                          label={ !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.popups.set_flash_message.show_for_viewers_who_logged_in') }
                          onChange={ () => this.props.changeFlashMessageData('show_flash_for_everyone', false) }
                          defaultChecked={ !this.props.formData.show_flash_for_everyone } />
            </FormGroup>
        </GlgPopupForm>
      </GlgPopup>
    )
  }
}

SetFlashMessage.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  changeFlashMessageData: PropTypes.func,
  formURL: PropTypes.string,
  formData: PropTypes.object,
}


SetFlashMessage.defaultProps = {
  showModal: false,
}

export default SetFlashMessage
