import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { GlgLoadingSpinner } from 'SharedComponents'
import EventsListContainer from '../containers/events_list_container'
import PortalsListContainer from '../containers/portals_list_container'

class DirectoriesContentComponent extends Component {
  render() {
    return <Fragment><GlgLoadingSpinner className="add-margin-top-20" show={this.props.dataIsLoading} color={'#5092fc'}></GlgLoadingSpinner><EventsListContainer></EventsListContainer>{ this.props.showPortals && <PortalsListContainer></PortalsListContainer>}</Fragment>
  }
}

DirectoriesContentComponent.propTypes = {
  viewType: PropTypes.string,
  dataIsLoading: PropTypes.bool.isRequired,
  showPortals: PropTypes.bool,
}

export default DirectoriesContentComponent
