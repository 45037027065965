import React, {Fragment} from 'react'
import PropTypes from 'prop-types'

const GolferInfoComponent = ({name, profileUrl, totalEvents, earnings, won, isPortal = false}) => {

  return (
    <Fragment>
      <div className={`business_card ${profileUrl ? 'has-photo' : 'no-photo'} ${isPortal ? 'portal' : ''} with_link`}>
        <div className="left_panel">
          <div>
            <div className="player-photo-and-name">
              {profileUrl && <img src={profileUrl ? profileUrl : require('home/logos/default_logo_large.png')}/>}
              <div className="name-and-link">
                <div>
                  <strong>
                    {name}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right_panel">
          <div className="one">
            <div>
              <div>
                <div className="constrain">
                  {totalEvents} {window.I18n.t('tour_games_season_standings.golfer_info_component.tour_games')}
                </div>
              </div>
              <div>
                <div className="half">
                  <div className="profile-info">
                    {earnings.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}
                  </div>
                  <div className="profile-label">
                    {window.I18n.t('tour_games_season_standings.golfer_info_component.earnings')}
                  </div>
                </div>
                <div className="half">
                  <div className="profile-info">
                    {won}
                  </div>
                  <div className="profile-label">
                    {window.I18n.t('tour_games_season_standings.golfer_info_component.won')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )

}

GolferInfoComponent.propTypes = {
  name: PropTypes.string,
  profileUrl: PropTypes.string,
  totalEvents: PropTypes.number,
  earnings: PropTypes.number,
  won: PropTypes.number,
  isPortal: PropTypes.bool,
}

export default GolferInfoComponent
