import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from './checkbox'

export const extractValues = (obj) => obj && Object.keys(obj.options).reduce((res, key) => {
  const option = obj.options[key]
  res[option.key] = option.selected; return res
}, {})

function CheckboxOptions({label, options, setOption, customizedOptions = {}, conditionalChildrenByKey = {}, vertical = true, className = ''}) {
  if ($.isEmptyObject(options)) {
    return (<></>)
  }

  return (
    <div className={`checkbox-options ${className}`}>
      {
        label && <b>{label}</b>
      }

      <div className={`checkbox-container ${vertical ? 'vertical' : ''}`}>
        {
          Object.keys(options).map(key => {
            const option = options[key]

            return (
              option &&
              <div key={key}>
                <Checkbox
                  {...option}
                  key={key}
                  setOption={setOption([ 'options', key, 'selected' ])}
                  customLabel={key in customizedOptions ? customizedOptions[key] : null }
                />
                {
                  key in conditionalChildrenByKey && conditionalChildrenByKey[key].condition &&
                    <div key={`${key}_children`} className={`children ${key}_children`}>
                      { conditionalChildrenByKey[key].value }
                    </div>
                }
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

CheckboxOptions.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  selected: PropTypes.bool,
  setOption: PropTypes.func,
  customizedOptions: PropTypes.object,
  conditionalChildrenByKey: PropTypes.object, // example: { option_key: { condition: true/false, node: node-to-render } }
  vertical: PropTypes.bool,
  className: PropTypes.string,
}

export default CheckboxOptions
