import {connect} from 'react-redux'
import AvailablePicksArea from '../../components/manager_side/available_picks/available_picks_area_component'
import {moveToNowPicking} from '../../actions/manager_side_index'

const mapStateToProps = (state) => ({
  availableGolfers: state.draftManagerSide.availableGolfers,
  saveInProgress: state.draftManagerSide.saveInProgress,
})

const mapDispatchToProps = (dispatch) => ({
  moveToNowPicking: (golfer) => dispatch(moveToNowPicking(golfer)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AvailablePicksArea)
