import React, { Component, Fragment } from 'react'
import SummaryComponent from './summary_component'
import { GlgRoundedButtonIcon } from 'SharedComponents'
import { Row, Col } from 'react-flexbox-grid'
import PropTypes from 'prop-types'


class MarketingCampaignsIndex extends Component {
  constructor(props){
    super(props)

    this.handleDelete = this.handleDelete.bind(this)
    this.changeStatus = this.changeStatus.bind(this)
  }

  handleDelete(marketingCampaign) {
    const {
      removeMarketingCampaign,
    } = this.props
    
    $.ajax({
      url: `/customers/${marketingCampaign.customer_id}/marketing_campaigns/${marketingCampaign.id}`,
      type: 'DELETE',
      success: () => {
        removeMarketingCampaign(marketingCampaign.id)
      },
      error: () => {
        // eslint-disable-next-line no-console
        console.log('Error delete')
      },
    })
  }

  changeStatus(marketingCampaign) {
    const {
      changeStatusOnState,
    } = this.props

    $.ajax({
      url: `/customers/${marketingCampaign.customer_id}/marketing_campaigns/${marketingCampaign.id}/change_status`,
      type: 'PATCH',
      success: (response) => {
        changeStatusOnState(marketingCampaign.id, response.new_status)
      },
      error: () => {
        // eslint-disable-next-line no-console
        console.log('Error change status')
      },
    })
  }

  render(){
    const {
      newMarketingCampaignPath,
      matchingRecipientsPath,
      marketingCampaigns,
    } = this.props

    return <Fragment><Row><Col sm={ 6 }><GlgRoundedButtonIcon text={ window.I18n.t('marketing_campaigns.components.marketing_campaigns_index_component.new_campaign') } icon="gga-emails" url={newMarketingCampaignPath}></GlgRoundedButtonIcon></Col></Row><Row className="add-margin-bottom-10"><Col xs={12} className="revert-grid">{marketingCampaigns.length === 0 && <Row className="add-margin-top-10 add-margin-bottom-10 text-align-center"><Col xs={ 12 }></Col>{ window.I18n.t('marketing_campaigns.components.marketing_campaigns_index_component.no_campaigns_defined') }</Row>}{marketingCampaigns.map(marketingCampaign => {
              return <Row key={ marketingCampaign.id } className="add-margin-top-10 add-margin-bottom-10"><Col xs={ 12 } className="revert-grid"><SummaryComponent marketingCampaign={ marketingCampaign } matchingRecipientsPath={ matchingRecipientsPath } handleDelete={ () => { this.handleDelete(marketingCampaign) } } changeStatus={ () => { this.changeStatus(marketingCampaign) } }></SummaryComponent></Col></Row>
            })}</Col></Row></Fragment>
  }
}

MarketingCampaignsIndex.propTypes = {
  newMarketingCampaignPath: PropTypes.string.isRequired,
  matchingRecipientsPath: PropTypes.string.isRequired,
  marketingCampaigns: PropTypes.array.isRequired,
  removeMarketingCampaign: PropTypes.func.isRequired,
  changeStatusOnState: PropTypes.func.isRequired,
}

export default MarketingCampaignsIndex
