import { ActionTypes } from '../actions/general'

function general(state = {}, action) {
  switch (action.type){
    case ActionTypes.SET_GENERAL_UNSAVED_CHANGES:
      return {
        ...state,
        hasUnsavedChanges: action.value,
      }

    case ActionTypes.SET_GENERAL_POPUP_PROPERTY:
      return {
        ...state,
        popups: {
          ...state.popups,
          [action.popupType]: {
            ...state.popups[action.popupType],
            [action.property]: action.propertyValue,
          },
        },
      }

    case ActionTypes.PLAINLY_SET_GENERAL_PROPERTY:
      return {
        ...state,
        [action.property]: action.value,
      }

    default:
      return state
  }
}

export default general
