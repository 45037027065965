/*
 * Returns an array of leagues in the format needed by the leagues table.
 * It computes the list of leagues either from passed leagueIds or from state and props.
 */
// import _ from 'lodash'
import createDeepSelector from './create_deep_selector'
import { getVisibleLeagueIds, getLeaguesFromIds } from './index'

const getLeagues = (state, leagueIds) => {
  if (!leagueIds) {
    leagueIds = getVisibleLeagueIds(state)
  }
  return getLeaguesFromIds(state.leagues, leagueIds)
}

const getFormattedLeagues = createDeepSelector(
  [ getLeagues ],
  (leagues) => {
    return leagues.map(league => {
      return {
        ...league,
      }
    })
  }
)

export default getFormattedLeagues
