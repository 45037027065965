import { createSelector } from 'reselect'
import _ from 'lodash'

const getLeaguesById = state => state.leagues
const getOpenRegistrationEnabled = state => !!state.misc.openRegistrationEnabled
const getCategories = state => state.categories

const checkShowRegistration = createSelector(
  [ getLeaguesById, getOpenRegistrationEnabled, getCategories ],
  (leaguesById, openRegistrationEnabled, categories) => {
    const maxLeaguesCount = Object.values(categories)
                                  .map(category => category.leaguesCount)
                                  .reduce((a, b) => a + b, 0)

    const anyLeaguesWithOpenRegistration = _.some(leaguesById, league => (
      league.openRegistrationData &&
      league.openRegistrationData.hasOpenRegistration),
    )

    if (maxLeaguesCount > 0 || _.isEmpty(leaguesById)) {
      return true
    } else {
      return openRegistrationEnabled && anyLeaguesWithOpenRegistration
    }
  },
)

export default checkShowRegistration
