import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
const classNames = require('classnames')

import LeaderboardComponent from './leaderboard_component'
import PhotosComponent from './photos_component'
import VideoComponent from './video_component'
import TeeSheetComponent from './tee_sheet_component'
import TextComponent from './text_component'
import { interpretPlaceholders } from '../../helpers'
import { LEADERBOARD_TYPE, PHOTOS_TYPE, VIDEO_TYPE, TEE_SHEET_TYPE, TEXT_TYPE } from '../../constants'

class AdvancedComponent extends Component {
  constructor(props) {
    super(props)

    const haveSlidesFinishedScrolling = { ...this.props.slide.containedSlidesById }
    Object.keys(haveSlidesFinishedScrolling).forEach( slideId => haveSlidesFinishedScrolling[slideId] = false )

    this.initialState = {
      haveSlidesFinishedScrolling,
      containingSlidesHeader: {
        minHeight: 0,
      },
      tableRowsMinimumHeights: {
        flightsHeader: 0,
        header: 0,
        row: 0,
      },
    }

    this.state = { ...this.initialState }

    this.resetState = this.resetState.bind(this)
    this.resetVideo = this.resetVideo.bind(this)
    this.setHeaderMinimumHeight = this.setHeaderMinimumHeight.bind(this)
    this.setTableMinimumHeights = this.setTableMinimumHeights.bind(this)
    this.setContainingSlidePlaceholderValues = this.setContainingSlidePlaceholderValues.bind(this)
  }

  componentDidMount() {
    this.resetState()
    this.setPlaceholderValues()
    this.setHeaderMinimumHeight()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.slide.id !== this.props.slide.id) {
      this.resetState()
      this.resetVideo(prevProps)
    }

    this.setHeaderMinimumHeight(prevProps)
    this.setTableMinimumHeights()
  }

  resetVideo(prevProps) {
    const prevContainedSlidesById = Object.keys(prevProps.slide.containedSlidesById)
    const currContainedSlidesById = Object.keys(this.props.slide.containedSlidesById)

    prevContainedSlidesById.forEach( (containedSlideId, index) => {
      if (containedSlideId === currContainedSlidesById[index] && Object.values(this.props.slide.containedSlidesById)[index].displayType === 'video' ) {
        this.setState({ resetVideo: true })
      }
    })
  }

  resetState() {
    const haveSlidesFinishedScrolling = { ...this.props.slide.containedSlidesById }
    Object.keys(haveSlidesFinishedScrolling).forEach( slideId => haveSlidesFinishedScrolling[slideId] = this.props.slide.containedSlidesById[slideId] ? false : true )
    this.setState({
      haveSlidesFinishedScrolling,
      containingSlidesHeader: {
        minHeight: 0,
      },
    })
  }

  setPlaceholderValues() {
    const {
      slide,
      setPlaceholderValues,
    } = this.props

    const placeholderValues = {
      eventName: Object.values(slide.containedSlidesById)[0].eventName,
      roundDate: Object.values(slide.containedSlidesById)[0].roundDate,
    }

    setPlaceholderValues(placeholderValues)
  }

  setHeaderMinimumHeight(prevProps) {
    if ( _.isEqual(_.get(prevProps, 'slide.containedSlidesById', -1), this.props.slide.containedSlidesById) ) {
      return
    }

    this.setState({
      containingSlidesHeader: {
        ...this.state.containingSlidesHeader,
        minHeight: 0,
      },
    }, () => {
      this.setState({
        containingSlidesHeader: {
          ...this.state.containingSlidesHeader,
          minHeight: Math.max( $('#left .slide_header').outerHeight(), $('#right .slide_header').outerHeight() ),
        },
      })
    })
  }

  setTableMinimumHeights() {
    const leftSlide = this.getLeftSlide()
    const rightSlide = this.getRightSlide()

    if (
      leftSlide.displayType !== 'table' ||
      rightSlide.displayType !== 'table' ||
      leftSlide.tournamentFormatNodeId !== rightSlide.tournamentFormatNodeId ||
      leftSlide.tournamentCompetitionNodeId !== rightSlide.tournamentCompetitionNodeId
    ) {

      if ( !_.isEqual( this.initialState.tableRowsMinimumHeights, this.state.tableRowsMinimumHeights) ) {
        this.setState( { tableRowsMinimumHeights: this.initialState.tableRowsMinimumHeights } )
      }

      return
    }

    const leftFlightHeaderHeight = $('#left .current-slide-data table.result_scope tr.header:has(td.scope_name)').height()
    const rightFlightHeaderHeight = $('#right .current-slide-data table.result_scope tr.header:has(td.scope_name)').height()
    const leftHeaderHeight = $('#left .current-slide-data table.result_scope tr.header:not(:has(td.scope_name))').height()
    const rightHeaderHeight = $('#right .current-slide-data table.result_scope tr.header:not(:has(td.scope_name))').height()
    const leftRowHeight = Math.max.apply(null, Array.prototype.map.call(
      $('#left .current-slide-data table.result_scope tr.aggregate-row'),
      row => $(row).height()
    ))
    const rightRowHeight = Math.max.apply(null, Array.prototype.map.call(
      $('#right .current-slide-data table.result_scope tr.aggregate-row'),
      row => $(row).height()
    ))

    const newState = {
      tableRowsMinimumHeights: {
        flightsHeader: Math.max( leftFlightHeaderHeight, rightFlightHeaderHeight ),
        header: Math.max( leftHeaderHeight, rightHeaderHeight ),
        row: Math.max( leftRowHeight, rightRowHeight ),
      },
    }

    if ( !_.isEqual(newState.tableRowsMinimumHeights, this.state.tableRowsMinimumHeights) ) {
      this.setState( newState )
    }
  }

  setContainingSlidePlaceholderValues(containedSlideId) {
    return (placeholderValues) => {
      if ( this.props.slide.containedSlidesById[containedSlideId].divisionName !== placeholderValues.divisionName ) {
        this.props.setContainingSlideCurrentDivisionName(this.props.slide.id, containedSlideId, placeholderValues.divisionName)
      }
    }
  }

  slideFinishedScrolling(slideId) {
    this.setState({
      ...this.state,
      haveSlidesFinishedScrolling: {
        ...this.state.haveSlidesFinishedScrolling,
        [slideId]: true,
      },
    }, this.moveToNextSlideIfAllFinishedScrolling)
  }

  moveToNextSlideIfAllFinishedScrolling() {
    const haveAllFinishedScrolling = Object.values(this.state.haveSlidesFinishedScrolling)
                                           .reduce( (accumulator, current) => accumulator && current )
    if (haveAllFinishedScrolling) {
      this.setState({ resetVideo: false })
      this.props.moveToNextSlide()
    }
  }

  getLeftSlide() {
    return Object.values(this.props.slide.containedSlidesById).filter( slide => slide.x === '0' )[0]
  }

  getRightSlide() {
    return Object.values(this.props.slide.containedSlidesById).filter( slide => slide.x === '1' )[0]
  }

  getComponentName(slide) {
    const mapTypesToComponents = {
      [ LEADERBOARD_TYPE ]: LeaderboardComponent,
      'table': LeaderboardComponent,
      'brackets': LeaderboardComponent,
      [ PHOTOS_TYPE ]: PhotosComponent,
      [ VIDEO_TYPE ]: VideoComponent,
      [ TEE_SHEET_TYPE ]: TeeSheetComponent,
      [ TEXT_TYPE ]: TextComponent,
    }

    return mapTypesToComponents[slide.displayType]
  }

  render() {
    const { blueBox, isFireTv, slide, hasUSGATheme, tvShowSettings, currentSlideIndex } = this.props
    const { containingSlidesHeader, tableRowsMinimumHeights, resetVideo } = this.state

    const leftSlide = this.getLeftSlide()
    const rightSlide = this.getRightSlide()
    const leftSlideContainerId = '#left'
    const rightSlideContainerId = '#right'
    const slideHeaderClasses = classNames({
      'slide_header': true,
      'usga-theme': hasUSGATheme,
    })
    const LeftSlideComponent = this.getComponentName(leftSlide)
    const RightSlideComponent = this.getComponentName(rightSlide)

    return <div className="tv-event clearfix"><div id="left">{ LeftSlideComponent && <Fragment><div className={slideHeaderClasses} dangerouslySetInnerHTML={{__html: interpretPlaceholders(leftSlide.headerText, slide.containedSlidesById[leftSlide.id]) }} style={{ minHeight: containingSlidesHeader.minHeight }}></div><div style={{ overflow: 'hidden' }}><LeftSlideComponent isFireTv={ isFireTv } slide={ { ...leftSlide } } containerId={ leftSlideContainerId } minHeights={ {...tableRowsMinimumHeights} } blueBox={ blueBox } tvShowSettings={ tvShowSettings } currentSlideIndex={ currentSlideIndex } moveToNextSlide={ () => { this.slideFinishedScrolling(leftSlide.id) } } setPlaceholderValues={ this.setContainingSlidePlaceholderValues(leftSlide.id) } hasUSGATheme={ hasUSGATheme } advancedSlideHeaderHeight={ this.state.containingSlidesHeader.minHeight } resetVideo={ resetVideo }></LeftSlideComponent></div></Fragment> }</div><div id="right">{ RightSlideComponent && <Fragment><div className={slideHeaderClasses} dangerouslySetInnerHTML={{__html: interpretPlaceholders(rightSlide.headerText, slide.containedSlidesById[rightSlide.id]) }} style={{ minHeight: containingSlidesHeader.minHeight }}></div><div style={{ overflow: 'hidden' }}><RightSlideComponent isFireTv={ isFireTv } slide={ { ...rightSlide } } containerId={ rightSlideContainerId } minHeights={ {...tableRowsMinimumHeights} } blueBox={ blueBox } tvShowSettings={ tvShowSettings } currentSlideIndex={ currentSlideIndex } moveToNextSlide={ () => { this.slideFinishedScrolling(rightSlide.id) } } setPlaceholderValues={ this.setContainingSlidePlaceholderValues(rightSlide.id) } hasUSGATheme={ hasUSGATheme } advancedSlideHeaderHeight={ this.state.containingSlidesHeader.minHeight } resetVideo={ resetVideo }></RightSlideComponent></div></Fragment> }</div></div>
  }
}

AdvancedComponent.propTypes = {
  isFireTv: PropTypes.bool,
  slide: PropTypes.object.isRequired,
  blueBox: PropTypes.object,
  moveToNextSlide: PropTypes.func.isRequired,
  setContainingSlideCurrentDivisionName: PropTypes.func.isRequired,
  setPlaceholderValues: PropTypes.func,
  hasUSGATheme: PropTypes.bool,
  currentSlideIndex: PropTypes.number.isRequired,
  tvShowSettings: PropTypes.object.isRequired,
}

AdvancedComponent.defaultProps = {
  isFireTv: false,
  hasUSGATheme: false,
}

export default AdvancedComponent
