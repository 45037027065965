import { connect } from 'react-redux'
import MembersTableComponent from '../components/members_table_component'
import {
  fetchMembersData, fetchInitialMembersData, deleteMember, openRegisterMemberPopup, updateContainerWidth, updateGridWidth,
  getRegisterMemberData, unregisterMember, confirmMember, toggleActionsDropdown, openEditMemberModal, resetPasswordMember,
} from '../actions/index'

const mapStateToProps = (state) => {
  return {
    members: state.membersData.members,
    lastNameOnly: state.membersData.lastNameOnly,
    columns: state.membersData.columns,
    isLoading: state.membersData.isLoading,
    reloadAll: state.membersData.reloadAll,
    searchParams: state.membersData.searchParams,
    cellCache1: state.cellMeasurerCache.cellCache1,
    cellCache2: state.cellMeasurerCache.cellCache2,
    membersCount: state.membersData.membersCount,
    membershipPrograms: state.membersData.membershipPrograms,
    isDeleteMemberLoading: state.registerMember.isDeleteMemberLoading,
    isConfirmMemberLoading: state.registerMember.isConfirmMemberLoading,
    isOpenEditMemberModalLoading: state.registerMember.isOpenEditMemberModalLoading,
    canUseMasterRosterRegistration: state.membersData.canUseMasterRosterRegistration,
    leagueId: state.misc.leagueId,
    isTourManager: state.misc.isTourManager,
    canOpenMemberModal: state.misc.canOpenMemberModal,
    indexType: state.misc.indexType,
    prettyHandicapMethod: state.misc.prettyHandicapMethod,
    customerSoftDelete: state.misc.customerSoftDelete,
    isGcatCustomer: state.misc.isGcatCustomer,
    isUnregisterMemberLoading: state.registerMember.isUnregisterMemberLoading,
    isActionsDropdownOpen: state.membersData.isActionsDropdownOpen,
    leagueProduct: state.misc.leagueProduct,
    gridWidth: state.cellMeasurerCache.gridWidth,
    hasMrSyncEnabled: state.misc.hasMrSyncEnabled,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchMembersData: (stopIndex, searchParams) => dispatch(fetchMembersData(stopIndex, searchParams)),
  fetchInitialMembersData: (searchParams) => dispatch(fetchInitialMembersData(searchParams)),
  deleteMember: (memberId, action, noSync) => dispatch(deleteMember(memberId, action, noSync)),
  resetPasswordMember: (memberId, memberEmail) => dispatch(resetPasswordMember(memberId, memberEmail)),
  openRegisterMemberPopup: (memberId) => dispatch(openRegisterMemberPopup(memberId)),
  getRegisterMemberData: (memberId) => dispatch(getRegisterMemberData(memberId)),
  unregisterMember: (memberId) => dispatch(unregisterMember(memberId)),
  confirmMember: (memberId) => dispatch(confirmMember(memberId)),
  toggleActionsDropdown: (val, memberId) => dispatch(toggleActionsDropdown(val, memberId)),
  openEditMemberModal: (memberId) => dispatch(openEditMemberModal(memberId)),
  updateContainerWidth: (width) => dispatch(updateContainerWidth(width)),
  updateGridWidth: (cellCacheIndex) => dispatch(updateGridWidth(cellCacheIndex)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersTableComponent)
