import { ActionTypes } from '../actions'
const {
  CHANGE_FILTER,
  CHANGE_FILTER_TIMER,
  DELETE_CATEGORY,
  FETCH_INITIAL_DATA_RECEIVED,
  SET_FILTER_LOADING_STATUS,
} = ActionTypes

const initialFilters = {
  leagueName: '',
  season: 'all',
  category: 'all',
  tour: 'all',
  registration: 'all',
  viewType: 'category',
  advancedFiltersOn: true,
  loading: false,
}

const filters = (state = initialFilters, action) => {
  if (!state.leagueName) {
    state = {
      ...initialFilters,
      ...state,
    }
  }
  switch (action.type) {
    case FETCH_INITIAL_DATA_RECEIVED:
      return {
        ...state,
        ...action.data.filters,
      }
    case DELETE_CATEGORY: {
      let category = state.category
      if (action.id === category) {
        category = 'all'
      }
      return {
        ...state,
        category,
      }
    }
    case CHANGE_FILTER:
      return {
        ...state,
        [action.filter]: action.value,
      }
    case CHANGE_FILTER_TIMER:
      return {
        ...state,
        timer: action.timer,
      }
    case SET_FILTER_LOADING_STATUS:
      return {
        ...state,
        loading: action.loading,
      }
    default:
      return state
  }
}

export default filters
