import React from 'react'
import { MenuItem, Form, ControlLabel, Col, Row, Dropdown } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import StickyMemberCardContainer from '../containers/sticky_member_card_container'
import GlgTextInput from '../../shared/components/glg_text_input'

export const FILTER_TYPES = {
  NAME: window.I18n.t('member_cards.name'),
  EMAIL: window.I18n.t('member_cards.email'),
  HANDICAP_NETWORK_ID: window.I18n.t('member_cards.handicap_network_id'),
  USER_ID: window.I18n.t('member_cards.user_id'),
  IDENTIFIER_ID: window.I18n.t('member_cards.identifier_id'),
  LEAGUE: window.I18n.t('member_cards.event'),
}

export const STRENGTH_TYPES = {
  STRONG: window.I18n.t('member_cards.strong'),
  MEDIUM: window.I18n.t('member_cards.medium'),
  WEAK: window.I18n.t('member_cards.weak'),
  MANUAL: window.I18n.t('member_cards.manual'),
  ORIGINAL: window.I18n.t('member_cards.original'),
  ALL: window.I18n.t('member_cards.all'),
}


const FiltersComponent = (props) => { 

  const [ activeFilter, setFilter ] = useState(props.field)
  const [ filterValue, setFilterValue ] = useState(props.fieldValue)
  const [ searchTimer, setSearchTimer ] = useState()

  const strength = props.strength
  const season = props.activeSeason

  const handleSubmit = (e) => {
    e.preventDefault()
    const filters = {
      field: activeFilter,
      fieldValue: filterValue,
    }
    props.changeFilters(filters)
  }

  const handleChange = (value) => {
    setFilterValue(value)
  }

  useEffect(() => {
    if (searchTimer) {
      clearTimeout(searchTimer)
    }

    setSearchTimer(
      setTimeout(() => {
        const filters = {
          field: activeFilter,
          fieldValue: filterValue,
        }
        props.changeFilters(filters)
      }, 600)
    )
  }, [ activeFilter, filterValue ])
  
  return (
    <div className= 'sticky-container'>
      {props.stickyIndex && <StickyMemberCardContainer/>}
      <Form className ='filter-form' onSubmit = {(event) => handleSubmit(event)}>
        <Row>
          <Col md={3} className='filter-column'>
            <ControlLabel className='mc-filter-label'>{!window.I18n ? '' : window.I18n.t('member_cards.season')}</ControlLabel>
            <Dropdown className='mc-filter-dropdown' id='mc-season-dropdown'>
              <Dropdown.Toggle>
                {season}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{overflow: 'auto', maxHeight: '12em'}}>
                {
                  Object.keys(props.seasons).map( (key) => {
                    const label = key
                    return (
                      <MenuItem key = {label} eventKey = {label} onSelect= { (option) => {
                        props.changeSeason(option)
                      } } >{label}</MenuItem>
                    )
                  })
                }
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={3} className='filter-column'>
            <ControlLabel className='mc-filter-label'>{!window.I18n ? '' : window.I18n.t('member_cards.strength')}</ControlLabel>
            <Dropdown className='mc-filter-dropdown' id='mc-strength-dropdown'>
              <Dropdown.Toggle>
                {STRENGTH_TYPES[strength]}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {
                  Object.keys(STRENGTH_TYPES).map(key => {
                    const strength = STRENGTH_TYPES[key]
                    return (
                      <MenuItem key = {key} eventKey = {key} onSelect={ (option) => {
                        props.changeStrength(option)
                      } } >{strength}</MenuItem>
                    )
                  })
                }
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={3} className='filter-column filter-type'>
            <ControlLabel className='mc-filter-label'>{window.I18n.t('member_cards.search')}</ControlLabel>
            <Dropdown className='mc-filter-dropdown' id='mc-filter-dropdown'>
              <Dropdown.Toggle>
                {FILTER_TYPES[activeFilter]}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {
                  Object.keys(FILTER_TYPES).map((key) => {
                    const filter = FILTER_TYPES[key]
                    return (
                      <MenuItem key = {key} eventKey = {key} onSelect= { (option) => { 
                        setFilter(option)
                      } } >{filter}</MenuItem>
                    )  
                  })
                }
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={3} className='filter-column filter-input'>
            <GlgTextInput placeholder = '' value={filterValue} onChange = {(value) => handleChange(value) } withAddon = {true}/>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
FiltersComponent.propTypes = {
  changeFilters: PropTypes.func,
  changeSeason: PropTypes.func,
  changeStrength: PropTypes.func,
  field: PropTypes.string,
  fieldValue: PropTypes.string,
  strength: PropTypes.string,
  activeSeason: PropTypes.string,
  stickyIndex: PropTypes.string,
  scrollY: PropTypes.number,
  seasons: PropTypes.object,
}

export default FiltersComponent
