import { ActionTypes } from '../actions' 

const {
  REFRESH_DATA,
  EDIT_HOLE,
} = ActionTypes

const initialState = {
}

const misc = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_DATA:
      return {
        ...state,
        ...action.data,
      }
    case EDIT_HOLE:
      return {
        ...state,
        foursomes: state.foursomes.map( foursome => {
          return ( foursome.id === action.data.foursome_id ) ? 
            {
              ...foursome,
              players: foursome.players.map( player => {
                return ( player.id === action.data.player_id ) ?
                  {
                    ...player,
                    today: action.data.round_to_par,
                    total: action.data.total_to_par,
                    score: action.data.round_score,
                    out: player.out + action.data.out,
                    in: player.in + action.data.in,
                    out_completed: action.data.out_completed,
                    in_completed: action.data.in_completed,
                    holes_data: player.holes_data.map( hole => {
                      return ( hole.hole_id === action.data.hole_id ) ? 
                        {
                          ...hole,
                          completed: action.data.completed,
                          score: action.data.score,
                          shots_display: action.data.shots_display,
                          shots_summary: action.data.shots_summary,
                        }
                        : hole
                    }),
                  } 
                  : player
              }),
            }
            : foursome
        }),
      }
    default:
      return state
  }
}

export default misc
