import { connect } from 'react-redux'
import _ from 'lodash'

import SalesDemoContentComponent from '../../components/sales_demo/sales_demo_content_component'

const mapStateToProps = (state) => {
  const categories = _.values(state.categories)
  return {
    categories,
  }
}

const mapDispatchToProps = () => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesDemoContentComponent)
