export const ActionTypes = {
  SET_UNSAVED_CHANGES: 'SET_UNSAVED_CHANGES',
  SET_NEW_SLIDE_PROPERTY: 'SET_NEW_SLIDE_PROPERTY',
  SET_NEW_SLIDE_CONFIGURATION_PROPERTY: 'SET_NEW_SLIDE_CONFIGURATION_PROPERTY',
  ADD_SLIDE: 'ADD_SLIDE',
  UPDATE_SLIDE: 'UPDATE_SLIDE',
  DELETE_SLIDE: 'DELETE_SLIDE',
  CHECK_NEW_SLIDE_DIVISION: 'CHECK_NEW_SLIDE_DIVISION',
  UNCHECK_NEW_SLIDE_DIVISION: 'UNCHECK_NEW_SLIDE_DIVISION',
  CHECK_NEW_SLIDE_FLIGHT: 'CHECK_NEW_SLIDE_FLIGHT',
  UNCHECK_NEW_SLIDE_FLIGHT: 'UNCHECK_NEW_SLIDE_FLIGHT',
  COMPUTE_SLIDES_LAYOUT: 'COMPUTE_SLIDES_LAYOUT',
  SET_SLIDES_POSITIONS: 'SET_SLIDES_POSITIONS',
  PLAINLY_SET_SLIDES_PROPERTY: 'PLAINLY_SET_SLIDES_PROPERTY',
}

export const setUnsavedChanges = (value) => {
  return {
    type: ActionTypes.SET_UNSAVED_CHANGES,
    value,
  }
}

export const setNewSlideProperty = (property, value) => {
  return {
    type: ActionTypes.SET_NEW_SLIDE_PROPERTY,
    property,
    value,
  }
}

export const setNewSlideConfigurationProperty = (property, value) => {
  return {
    type: ActionTypes.SET_NEW_SLIDE_CONFIGURATION_PROPERTY,
    property,
    value,
  }
}

export const addSlide = (slide) => {
  return {
    type: ActionTypes.ADD_SLIDE,
    slide,
  }
}

export const updateSlide = (slide) => {
  return {
    type: ActionTypes.UPDATE_SLIDE,
    slide,
  }
}

export const removeSlide = (slideId) => {
  return {
    type: ActionTypes.DELETE_SLIDE,
    slideId,
  }
}

export const checkNewSlideDivision = (divisionId) => {
  return {
    type: ActionTypes.CHECK_NEW_SLIDE_DIVISION,
    divisionId,
  }
}

export const uncheckNewSlideDivision = (divisionId) => {
  return {
    type: ActionTypes.UNCHECK_NEW_SLIDE_DIVISION,
    divisionId,
  }
}

export const checkNewSlideFlight = (divisionId, flight) => {
  return {
    type: ActionTypes.CHECK_NEW_SLIDE_FLIGHT,
    divisionId,
    flight,
  }
}

export const uncheckNewSlideFlight = (divisionId, flightId) => {
  return {
    type: ActionTypes.UNCHECK_NEW_SLIDE_FLIGHT,
    divisionId,
    flightId,
  }
}

export const computeSlidesLayout = (colsNumber) => {
  return {
    type: ActionTypes.COMPUTE_SLIDES_LAYOUT,
    colsNumber,
  }
}

export const setSlidesPositions = (currentGridLayout) => {
  return {
    type: ActionTypes.SET_SLIDES_POSITIONS,
    currentGridLayout,
  }
}

export const plainlySetSlidesProperty = (property, value) => {
  return {
    type: ActionTypes.PLAINLY_SET_SLIDES_PROPERTY,
    property,
    value,
  }
}
