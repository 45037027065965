import React from 'react'
import PropTypes from 'prop-types'

// The first th from a KeySettingsThGroup will display the expandable group name.
const ThGroupLabel = (props) => {
  if (!props.groupName) { return null }

  return (props.groupName && (
    <button className="th-group-name" onClick={props.onClick}>
      <span>{props.groupName}</span>
      &nbsp;
      <span className='th-group-arrow'>{props.isExpanded ? '►' : '▼'}</span>
    </button>
  ))
}

ThGroupLabel.propTypes = {
  groupName: PropTypes.string,
  onClick: PropTypes.func,
  isExpanded: PropTypes.bool,
}

const KeySettingsTh = (props) => {
  if (props.isHidden) { return null }

  return (
    <th>
      <div>
        { props.isHeaderTwoLeveled && <>
            <div className='th-top-level'>
              { (props.isExpanded && props.groupName) &&
                <ThGroupLabel groupName={props.groupName} onClick={props.onToggle} isExpanded={props.isExpanded} /> 
              }
            </div>
            <div className='th-separator' />
          </>
        }
        
        { props.isExpanded 
          ? <span>{props.settingName}</span> 
          : 
            (props.groupName)
              ? <ThGroupLabel groupName={props.groupName} onClick={props.onToggle} isExpanded={props.isExpanded} />
              : <span>{props.settingName}</span>
        }
      </div>
    </th>
  )
}

KeySettingsTh.propTypes = {
  settingName: PropTypes.string.isRequired,
  groupName: PropTypes.string,
  isExpanded: PropTypes.bool,
  isHidden: PropTypes.bool,
  onToggle: PropTypes.func,
  isHeaderTwoLeveled: PropTypes.bool,
}

KeySettingsTh.defaultProps = {
  isExpanded: false,
}

export default KeySettingsTh
