import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import { NotificationContainer } from 'react-notifications'

import CCPopupsContainer from '../containers/cc_popups_container'
import CustomerActionsContainer from '../containers/customer_actions_container'
import CustomerFilterContainer from '../containers/customer_filter_container'
import CustomerCenterContentContainer from '../containers/customer_center_content_container'
import CategoriesFiltersContainer from '../containers/categories_filters_container'
import SalesDemoContentContainer from '../containers/sales_demo/sales_demo_content_container'
import WelcomeComponent from './welcome_component'

class CustomerCenterComponent extends Component {
  componentDidMount() {
    if (this.props.shouldLoadData) {
      this.props.fetchData(this.props.shouldLoadInitialDataForListView ? this.props.filters : null)
    }
  }

  render() {
    return <Fragment>{!this.props.isSalesDemo ? <Fragment><CustomerFilterContainer></CustomerFilterContainer><Row><Col xs={true}><CustomerActionsContainer></CustomerActionsContainer></Col></Row>{!this.props.showWelcome ? <Fragment><Row className="categories-filters-wrapper"><Col xs={true}><CategoriesFiltersContainer></CategoriesFiltersContainer></Col></Row><Row><Col xs={true} className="no-padding"><CustomerCenterContentContainer></CustomerCenterContentContainer></Col></Row></Fragment> : <WelcomeComponent></WelcomeComponent>}</Fragment> : <Fragment><Row><Col xs={true}><CustomerActionsContainer></CustomerActionsContainer></Col></Row><Row><Col xs={true}><SalesDemoContentContainer></SalesDemoContentContainer></Col></Row></Fragment>}<CCPopupsContainer></CCPopupsContainer><NotificationContainer></NotificationContainer></Fragment>
  }
}

CustomerCenterComponent.propTypes = {
  isSalesDemo: PropTypes.bool,
  shouldLoadData: PropTypes.bool.isRequired,
  shouldLoadInitialDataForListView: PropTypes.bool,
  showWelcome: PropTypes.bool.isRequired,
  fetchData: PropTypes.func.isRequired,
  filters: PropTypes.object,
}

export default CustomerCenterComponent
