import { ActionTypes } from '../actions/slideshowData'

function slideshowData(state = {}, action) {
  switch (action.type){
    case ActionTypes.PLAINLY_SET_BLUE_BOX_PROPERTY:
      return {
        ...state,
        blueBox: {
          ...state.blueBox,
          [action.property]: action.value,
        },
      }

    case ActionTypes.SET_CONTAINING_SLIDE_PROPERTY:
      return {
        ...state,
        slides: state.slides.map( slide => {
          return slide.id === action.advancedSlideId ? {
            ...slide,
            containedSlidesById: {
              ...slide.containedSlidesById,
              [action.containedSlideId]: {
                ...slide.containedSlidesById[action.containedSlideId],
                [action.property]: action.value,
              },
            },
          } : slide
        }),
      }

    case ActionTypes.TOGGLE_BLUE_BOX_PROPERTY:
      return {
        ...state,
        blueBox: {
          ...state.blueBox,
          [action.property]: !state.blueBox[action.property],
        },
      }

    case ActionTypes.MOVE_TO_NEXT_SLIDE:
      return {
        ...state,
        currentSlide: {
          ...state.currentSlide,
          index: (state.currentSlide.index + 1) % state.slides.length || 0,
        },
      }

    case ActionTypes.UPDATE_TO_LATEST_SLIDESHOW:
      return {
        ...state,
        tvShowSettings: action.tvShowSettings,
        slides: action.slides,
      }

    case ActionTypes.SET_HEADER_HEIGHT:
      return {
        ...state,
        tvShowSettings: {
          ...state.tvShowSettings,
          header_settings: {
            ...state.tvShowSettings.header_settings,
            height: action.headerHeight,
          },
        },
      }

    case ActionTypes.SET_FOOTER_HEIGHT:
      return {
        ...state,
        tvShowSettings: {
          ...state.tvShowSettings,
          footer_settings: {
            ...state.tvShowSettings.footer_settings,
            height: action.footerHeight,
          },
        },
      }

    default:
      return state
  }
}

export default slideshowData
