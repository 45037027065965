import { showErrorNotification, callAPI } from 'Shared/helpers'

export const ActionTypes = {
  FETCH_INITIAL_DATA_REQUEST: 'FETCH_INITIAL_DATA_REQUEST', 
  FETCH_INITIAL_DATA_RECEIVED: 'FETCH_INITIAL_DATA_RECEIVED',
  CHANGE_TOURNAMENT_REQUEST: 'CHANGE_TOURNAMENT_REQUEST',
  CHANGE_TOURNAMENT_RECEIVED: 'CHANGE_TOURNAMNET_RECEIVED',
  CHANGE_DISPLAY_OPTION_FILTER: 'CHANGE_DISPLAY_OPTION_FILTER',
  CHANGE_COURSE_FILTER: 'CHANGE_COURSE_FILTER',
  CHANGE_STARTING_HOLE_FILTER: 'CHANGE_TEE_FILTER',
  CHANGE_SORT_OPTION: 'CHANGE_SORT_OPTION',
  CHANGE_SORT_ORDER_OPTION: 'CHANGE_SORT_ORDER_OPTION',
  CHANGE_ROUND_REQUEST: 'CHANGE_ROUND_REQUEST',
  CHANGE_ROUND_RECEIVED: 'CHANGE_ROUND_RECEIVED',
  REFRESH_DATA: 'REFRESH_DATA',
  SHOW_HIDE_FILTERS: 'SHOW_HIDE_FILTERS',
}

export const fetchInitialDataRequest = () => ({
  type: ActionTypes.FETCH_INITIAL_DATA_REQUEST,
})

export const fetchInitialDataReceived = (data) => ({
  type: ActionTypes.FETCH_INITIAL_DATA_RECEIVED,
  data,
})

export const changeDisplayOptionFilterAction = (value) => ({
  type: ActionTypes.CHANGE_DISPLAY_OPTION_FILTER,
  value,
})

export const changeTournamentRequest = (tournamentId) => ({
  type: ActionTypes.CHANGE_TOURNAMENT_REQUEST,
  tournamentId,
})

export const changeTournamentReceived = (data) => ({
  type: ActionTypes.CHANGE_TOURNAMENT_RECEIVED,
  data,
})

export const changeCourseFilterAction = (value) => ({
  type: ActionTypes.CHANGE_COURSE_FILTER,
  value,
})

export const changeStartingHoleFilterAction = (value) => ({
  type: ActionTypes.CHANGE_STARTING_HOLE_FILTER,
  value,
})

export const changeSortOptionAction = (value) => ({
  type: ActionTypes.CHANGE_SORT_OPTION,
  value,
})

export const changeSortOrderOptionAction = (value) => ({
  type: ActionTypes.CHANGE_SORT_ORDER_OPTION,
  value,
})

export const changeRoundRequestAction = (roundId) => ({
  type: ActionTypes.CHANGE_ROUND_REQUEST,
  roundId,
})

export const changeRoundReceivedAction = (data) => ({
  type: ActionTypes.CHANGE_ROUND_RECEIVED,
  data,
})

export const refreshDataAction = (data) => ({
  type: ActionTypes.REFRESH_DATA,
  data,
})

export const showHideFiltersAction = (value) => ({
  type: ActionTypes.SHOW_HIDE_FILTERS,
  value,
})


export const fetchInitialData = () => (dispatch, getState) => {
  dispatch(fetchInitialDataRequest())
  const state = getState()
  const url = state.misc.fetchInitialDataUrl
  const params = {
    tournament_id: state.misc.selectedTournamentId,
    round_id: state.misc.roundId,
  }
  callAPI(url, 'GET', params)
    .then(response => {
      dispatch(fetchInitialDataReceived(response))
    })
    .catch(() => {
      showErrorNotification('Error while fetching initial data!')
    })
}

export const changeDisplayOptionFilter = (value) => (dispatch) => {
  dispatch(changeDisplayOptionFilterAction(value))
}

export const changeTournament = (id) => (dispatch, getState) => {
  dispatch(changeTournamentRequest(id))
  const state = getState()
  const url = state.misc.fetchInitialDataUrl
  const params = {
    tournament_id: id,
  }
  callAPI(url, 'GET', params)
    .then(response => {
      dispatch(changeTournamentReceived(response))
    })
    .catch(() => {
      showErrorNotification('Error while fetching initial data!')
    })
}

export const changeRound = (roundId) => (dispatch, getState) => {
  dispatch(changeRoundRequestAction(roundId))
  const state = getState()
  const url = state.misc.fetchInitialDataUrl
  const tournamentId = state.misc.selectedTournamentId
  const params = {
    round_id: roundId,
    tournament_id: tournamentId,
  }
  callAPI(url, 'GET', params)
    .then(response => {
      dispatch(changeRoundReceivedAction(response))
    })
    .catch(() => {
      showErrorNotification('Error while fetching initial data!')
    })
}

export const changeCourseFilter = (value) => (dispatch) => {
  dispatch(changeCourseFilterAction(value))
}

export const changeStartingHoleFilter = (value) => (dispatch) => {
  dispatch(changeStartingHoleFilterAction(value))
}

export const changeSortOption = (value) => (dispatch) => {
  dispatch(changeSortOptionAction(value))
}

export const changeSortOrderOption = () => (dispatch, getState) => {
  const state = getState()
  const order = state.filters.sortOrder === 'asc' ? 'desc' : 'asc'
  dispatch(changeSortOrderOptionAction(order))
}

export const refreshData = () => (dispatch, getState) => {
  const state = getState()
  const url = state.misc.fetchInitialDataUrl
  const params = {
    tournament_id: state.misc.selectedTournamentId,
    round_id: state.misc.roundId,
  }
  callAPI(url, 'GET', params)
    .then(response => {
      if (response.hasResults) {
        dispatch(refreshDataAction(response))
      }
    })
    .catch(() => {
      showErrorNotification('Error while refreshing data!')
    })
}

export const showHideFilters = () => (dispatch, getState) => {
  const state = getState()
  const showFilters = state.filters.showFilters
  dispatch(showHideFiltersAction(!showFilters))
}
