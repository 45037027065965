import { ActionTypes } from '../actions'

const {
  INCREMENT_PAGE,
  ADD_MEMBERS_TO_MEMBER_CARD,
  SPLIT_MEMBERS_FROM_MEMBER_CARD,
  REFRESH_DATA,
} = ActionTypes

const initialState = {
  misc: {},
}
const misc = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_DATA:
      return {
        ...state,
        ...action.data.misc,
        changedMemberCards: {},
        changedMembers: [],
        splittedMembers: [],
      }
    case ADD_MEMBERS_TO_MEMBER_CARD:
      return {
        ...state,
        changedMembers: state.changedMembers.concat(action.members),
      }
    case SPLIT_MEMBERS_FROM_MEMBER_CARD:
      return {
        ...state,
        splittedMembers: state.splittedMembers.concat(action.members),
      }
    case INCREMENT_PAGE:
      return {
        ...state,
        page: state.page + 1,
      }
    default:
      return state
  }
}

export default misc
