import { connect } from 'react-redux'
import { closeRegisterMemberPopup, registerMember, updateRegistration, openEditMemberModal } from '../../actions/index'
import RegisterMemberPopup from '../../components/register_member/register_member_popup'

const mapStateToProps = (state) => ({
  show: state.registerMember.isOpen,
  isLoading: state.registerMember.isLoading,
  data: state.registerMember.data,
  isRegisterMemberLoading: state.registerMember.isRegisterMemberLoading,
  member: state.registerMember.member,
  registerMemberValues: state.registerMember.registerMemberValues,
  ckeditorPath: state.misc.ckeditorPath,
  newUserSessionUrl: state.misc.newUserSessionUrl,
  isOpenEditMemberModalLoading: state.registerMember.isOpenEditMemberModalLoading,
  editEmailTemplateRegisterMemberWithPasswordUrl: state.misc.editEmailTemplateRegisterMemberWithPasswordUrl,
  editEmailTemplateRegisterMemberWithoutPasswordUrl: state.misc.editEmailTemplateRegisterMemberWithoutPasswordUrl,
  isGcatCustomer: state.misc.isGcatCustomer,
})

const mapDispatchToProps = (dispatch) => ({
  closeRegisterMemberPopup: () => dispatch(closeRegisterMemberPopup()),
  registerMember: (memberId) => dispatch(registerMember(memberId)),
  updateRegistration: (field, value) => dispatch(updateRegistration(field, value)),
  openEditMemberModal: (id) => dispatch(openEditMemberModal(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterMemberPopup)
