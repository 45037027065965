import { showErrorNotification, callAPI } from 'Shared/helpers'

export const ActionTypes = {
  CHANGE_SORT_OPTION: 'CHANGE_SORT_OPTION',
  CHANGE_SORT_ORDER_OPTION: 'CHANGE_SORT_ORDER_OPTION',
  REFRESH_DATA: 'REFRESH_DATA',
  OPEN_POPUP: 'OPEN_POPUP',
  CLOSE_POPUP: 'CLOSE_POPUP',
  EDIT_HOLE: 'EDIT_HOLE',
}

export const changeSortOptionAction = (value) => ({
  type: ActionTypes.CHANGE_SORT_OPTION,
  value,
})

export const changeSortOrderOptionAction = (value) => ({
  type: ActionTypes.CHANGE_SORT_ORDER_OPTION,
  value,
})

export const refreshDataAction = (data) => ({
  type: ActionTypes.REFRESH_DATA,
  data,
})

export const openPopupAction = (playerName, foursomePosition, holeId, holeNumber, shots, isCompleted, par, scoreVerified) => ({
  type: ActionTypes.OPEN_POPUP,
  playerName,
  foursomePosition,
  holeId,
  holeNumber,
  shots,
  isCompleted,
  par,
  scoreVerified,
})

export const editHoleAction = (data) => ({
  type: ActionTypes.EDIT_HOLE,
  data,
})

export const closePopupAction = () => ({
  type: ActionTypes.CLOSE_POPUP,
})

export const changeSortOption = (value) => (dispatch) => {
  dispatch(changeSortOptionAction(value))
}

export const changeSortOrderOption = () => (dispatch, getState) => {
  const state = getState()
  const order = state.filters.sortOrder === 'asc' ? 'desc' : 'asc'
  dispatch(changeSortOrderOptionAction(order))
}

export const refreshData = () => (dispatch, getState) => {
  const state = getState()
  const url = state.misc.refreshDataUrl
  callAPI(url, 'GET', {})
    .then(response => {
      dispatch(refreshDataAction(response))
    })
    .catch(() => {
      showErrorNotification('Error while refreshing data!')
    })
}

export const openPopup = (playerName, foursomePosition, holeId, holeNumber, shots, isCompleted, par, scoreVerified) => (dispatch) => {
  dispatch(openPopupAction(playerName, foursomePosition, holeId, holeNumber, shots, isCompleted, par, scoreVerified))
}

export const closePopup = () => (dispatch) => {
  dispatch(closePopupAction())
}

export const editHole = (holeId, shots, completed) => (dispatch, getState) => {
  const state = getState()
  const leagueId = state.misc.leagueId
  const roundId = state.misc.roundId
  const url = '/leagues/' + leagueId + '/rounds/' + roundId + '/shot_by_shot/shot_trackings/' + holeId
  const params = {}
  params['completed'] = completed
  shots.map(shot => { params[`shots[${shot.shot_number}]`] = shot.position })

  callAPI(url, 'PUT', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch(closePopupAction())
      dispatch(editHoleAction(response))
    })
    .catch(error => {
      dispatch(closePopupAction())
      if ( error !== undefined ) {
        showErrorNotification(error)
      } else {
        showErrorNotification('Error while updating data!')
      }
    })
}

export const clearAllShots = (holeId) => (dispatch, getState) => {
  const state = getState()
  const leagueId = state.misc.leagueId
  const roundId = state.misc.roundId
  const url = '/leagues/' + leagueId + '/rounds/' + roundId + '/shot_by_shot/shot_trackings/' + holeId + '/clear_all_shots'

  callAPI(url, 'DELETE', {}, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch(closePopupAction())
        dispatch(editHoleAction(response))
      })
      .catch(error => {
        dispatch(closePopupAction())
        if ( error !== undefined ) {
          showErrorNotification(error)
        } else {
          showErrorNotification('Error while updating data!')
        }
      })
}
