import React from 'react'
import PropTypes from 'prop-types'
import { Panel } from 'react-bootstrap'
import { Row, Col } from 'react-flexbox-grid'

import { GlgIcon } from 'SharedComponents'

const GeneralCreateEventButton = ({ actionName, actionDescription, iconName, disabled, openPopup }) => <a onClick={ disabled ? null : openPopup } className={ disabled ? '' : 'clickable' }><Panel className="league-creation-button"><Row><Col sm={12} md={10} className="first-col"><Row center="xs" className="action-name"><Col xs={true}><h4>{ actionName }</h4></Col></Row><Row center="xs" className="action-description"><Col xs={true}>{ actionDescription }</Col></Row></Col><Col md={2} className="second-col hidden-xs"><GlgIcon icon={ iconName }></GlgIcon></Col></Row></Panel></a>

GeneralCreateEventButton.propTypes = {
  actionName: PropTypes.string.isRequired,
  actionDescription: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  openPopup: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default GeneralCreateEventButton
