import { ActionTypes } from '../actions'

const {
  REFRESH_DATA,
  FETCH_MORE_DATA_REQUEST,
  FETCH_MORE_DATA_RECEIVED,
} = ActionTypes

const initialState = {
  loading: false,
}
const memberCards = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MORE_DATA_RECEIVED:
      return {
        ...state,
        ...action.data.memberCards,
        isLoading: false,
      }
    case FETCH_MORE_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      } 
    case REFRESH_DATA:
      return {
        ...action.data.memberCards,
      }
    default:
      return state
  }
}

export default memberCards
