import React from 'react'
import PropTypes from 'prop-types'
import rootReducer from '../reducers'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import DoubleScoreVerificationContainer from './double_score_verification_container'

const DoubleScoreVerificationRoot = ({leagueId, roundId, doubleScoreVerificationId, leagueName, roundName, courseName, step, tees, foursomes, isMultiRound, holeView, autoFillScore, shotTrackingEnabled, refreshDataUrl, verifyScoresUrl, verifyTeamScoresUrl, teamscoreVerification, tournamentType, useTeamTotals, isStableford}) => {
  const state = {
    misc: {
      leagueId,
      roundId,
      doubleScoreVerificationId,
      leagueName,
      roundName,
      courseName,
      step,
      tees,
      foursomes,
      isMultiRound,
      holeView,
      autoFillScore,
      shotTrackingEnabled,
      refreshDataUrl,
      verifyScoresUrl,
      verifyTeamScoresUrl,
      teamscoreVerification,
      tournamentType,
      useTeamTotals,
      isStableford,
    },
  }

  const store = configureStore(rootReducer, state)

  return (
    <GlobalProvider store= { store }>
      <DoubleScoreVerificationContainer />
    </GlobalProvider>
  )
}

DoubleScoreVerificationRoot.propTypes = {
  leagueId: PropTypes.string,
  roundId: PropTypes.string,
  doubleScoreVerificationId: PropTypes.string,
  leagueName: PropTypes.string,
  roundName: PropTypes.string,
  courseName: PropTypes.string,
  step: PropTypes.string,
  tees: PropTypes.object,
  foursomes: PropTypes.array,
  isMultiRound: PropTypes.bool,
  holeView: PropTypes.string,
  autoFillScore: PropTypes.bool,
  shotTrackingEnabled: PropTypes.bool,
  refreshDataUrl: PropTypes.string,
  verifyScoresUrl: PropTypes.string,
  verifyTeamScoresUrl: PropTypes.string,
  teamscoreVerification: PropTypes.bool,
  tournamentType: PropTypes.string,
  useTeamTotals: PropTypes.bool,
  isStableford: PropTypes.bool,
}

export default DoubleScoreVerificationRoot
