import {ActionTypes} from '../actions'

const {
  FETCH_DATA_REQUEST,
  FETCH_DATA_RECEIVED,
  RESET_TIMER,
  CLEAR_TIMER,
} = ActionTypes

const initialState = {
  fetchDataBaseUrl: '',
  playersResults: [],
  gameState: '',
  selectedTourEvent: -1,
  tourEvents: {},
  dataIsLoading: true,
  reloading: false,
  timer: null,
}

const tourEventResultsData = (state = initialState, action) => {
  switch (action.type) {
    case RESET_TIMER:
      if (state.timer !== null) {
        clearInterval(state.timer)
      }
      return {
        ...state,
        timer: setInterval(() => action.refreshMethod(), 60000),
      }
    case CLEAR_TIMER:
      if (state.timer !== null) {
        clearInterval(state.timer)
      }

      return {
        ...state,
        timer: null,
      }
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        ...action.data,
        dataIsLoading: true,
      }
    case FETCH_DATA_RECEIVED:
      if ([ 'closed', 'cancelled' ].includes(action.data.gameState) && state.timer !== null){
        clearInterval(state.timer)
      }

      return {
        ...state,
        ...action.data,
        timer: [ 'closed', 'cancelled' ].includes(action.data.gameState) ? null : state.timer,
        dataIsLoading: false,
        reloading: false,
      }
    default:
      return state
  }
}

export default tourEventResultsData
