import { connect } from 'react-redux'
import RoundTournaments from '../../components/content/round_tournaments'
// import { setRoundStatus, toggleRoundOptions } from '../../actions/index'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => ({
  refresh: () => dispatch(ownProps.refresh),
})

export default connect(
  null,
  mapDispatchToProps,
)(RoundTournaments)
