import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'


import TeeData from './tee_data'
import FoursomeDataContainer from '../containers/foursome_data_container'

class ScoringControlInterfaceTable extends Component {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
    this.tableHeaderRef = React.createRef()
    this.state = {
      smallWindowsHeaderPosition: 0,
    }
  }

  updateDimensions = () => {
    if (this.state.smallWindowsHeaderPosition !== this.tableHeaderRef.current.getBoundingClientRect().top){
      this.setState({
        smallWindowsHeaderPosition: this.tableHeaderRef.current.getBoundingClientRect().top,
      })
      this.handleScroll()
    }
  }

  componentDidMount() {
    window.parent.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.updateDimensions)
    this.initialHeaderPositionTop = this.tableHeaderRef.current.getBoundingClientRect().top
  }

  componentWillUnmount() {
    window.parent.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.updateDimensions)
  }

  handleScroll() {
    const headerPosition = window.innerWidth < 1200 ? this.state.smallWindowsHeaderPosition : this.initialHeaderPositionTop
    if (window.parent.pageYOffset > headerPosition) {
      this.tableHeaderRef.current.style.transform = `translateY(${window.parent.pageYOffset - headerPosition}px)`
    } else {
      this.tableHeaderRef.current.style.transform = 'translateY(0)'
    }
  }

  render() {
    const tees = { ...this.props.tees }
    const foursomes = [ ...this.props.foursomes ]

    return (
      <Fragment>
        {this.props.hasResults &&
          <div className='scoring_control_interface_table'>
            <table className='scoring_control_interface'>
              <thead ref={this.tableHeaderRef}>
                <tr className='header'>
                  <th className='with_border first'>{!window.I18n ? '' : window.I18n.t('group_view.table.group_info')}</th>
                  <th className='with_border second'>{!window.I18n ? '' : window.I18n.t('group_view.table.hole')}</th>
                  {this.props.isMultiRound && <th className='with_border'>{!window.I18n ? '' : window.I18n.t('group_view.table.prior')}</th>}
                  {[ ...Array(9) ].map( ( _, i) => <th key={'hole-' + i} className='with_border'>{i + 1}</th>)}
                  {this.props.scoreDisplay && <th className='with_border'>{!window.I18n ? '' : window.I18n.t('group_view.table.out')}</th>}
                  {[ ...Array(9) ].map( ( _, i) => <th key={'hole-' + i} className='with_border'>{i + 10}</th>)}
                  {this.props.scoreDisplay && <th className='with_border'>{!window.I18n ? '' : window.I18n.t('group_view.table.in')}</th>}
                  {!this.props.scoreDisplay && this.props.isMultiRound ? null : <th className='with_border'>{!window.I18n ? '' : window.I18n.t('group_view.table.total')}</th>}
                  {this.props.scoreDisplay && !this.props.isStableford && this.props.isMultiRound && <th className='with_border'>{!window.I18n ? '' : window.I18n.t('group_view.table.today')}</th>}
                  {this.props.scoreDisplay && !this.props.isStableford && this.props.isMultiRound && <th className='with_border'>{!window.I18n ? '' : window.I18n.t('group_view.table.par_total')}</th>}
                  {this.props.isStableford && this.props.isMultiRound && <th className='with_border'>{!window.I18n ? '' : window.I18n.t('group_view.table.overall')}</th>}
                  {!this.props.scoreDisplay && this.props.isMultiRound && 
                    this.props.pastRounds.map( round => <th className='with_border' key={round}>{round}</th>)
                  }
                  {!this.props.scoreDisplay && this.props.isMultiRound && <th className='with_border'>{!window.I18n ? '' : window.I18n.t('group_view.table.total')}</th>}
                </tr>
                {
                  Object.keys(tees).map( key => {
                    return tees[key].map( (tee, index) => 
                      <TeeData key={tee.id} 
                               tee={tee}
                               scoreDisplay={this.props.scoreDisplay}
                               isMultiRound={this.props.isMultiRound}
                               isStableford={this.props.isStableford}
                               pastRounds={this.props.pastRounds}
                               showPar={index === tees[key].length - 1} />)
                  })
                }
              </thead>
              <tbody>
                {
                  foursomes.map( (foursome, index) => {
                    const isLast = index === foursomes.length - 1

                    return <FoursomeDataContainer key={`${foursome.id}-${Math.random()}`}
                                                  foursome={foursome}
                                                  scoreDisplay={this.props.scoreDisplay}
                                                  isStableford={this.props.isStableford}
                                                  isMultiRound={this.props.isMultiRound}
                                                  holeView={this.props.holeView}
                                                  isPortal={this.props.isPortal}
                                                  isLast={isLast} />
                  })
                }
              </tbody>
            </table>
          </div> 
        }
      </Fragment>
    )
  }
}

ScoringControlInterfaceTable.propTypes = {
  tees: PropTypes.object,
  foursomes: PropTypes.array,
  scoreDisplay: PropTypes.bool,
  isStableford: PropTypes.bool,
  isMultiRound: PropTypes.bool,
  isLoadingTournament: PropTypes.bool,
  pastRounds: PropTypes.array,
  holeView: PropTypes.string,
  isPortal: PropTypes.bool,
  hasResults: PropTypes.bool,
}

export default ScoringControlInterfaceTable
