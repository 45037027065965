import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { scoreClass } from '../../shot_by_shot_view/helpers'

const foursomeTeamData = ({foursome, isMultiRound, foursomeIndex, holeView, openTeamPopup}) => {
  const teams = [ ...foursome.teams ]
  const visibleTeams = teams.filter( team => team.verified_for_step === false)
  return (
    <Fragment>
      {
        visibleTeams.map( (team, index ) => {
          const players = [ ...team.players ]
          return (
            <Fragment key={team.name}>
              {
                players.map( (player, pindex) => {
                  const scoreData = { ...player.score_data }
                  const scores = [ ...scoreData.scores_array ]
                  const pars = [ ...player.pars_array ]
                  const firstRowClass = (index === 0 && pindex === 0) ? ' first_row' : ''
                  const allCompleted = scoreData.in_completed && scoreData.out_completed
                  return (
                    <tr key={player.id} className={'player_row' + firstRowClass}>
                      { index === 0 && pindex === 0 &&
                        <td rowSpan={foursome.size} className='first'>
                          <p>{foursome.position}.</p>
                          <p><b>Starting Hole: </b>{foursome.starting_hole}</p>
                          <p>{foursome.pretty_tee_time}</p>
                        </td>
                      }
                      { pindex === 0 &&
                        <td rowSpan={team.size} className={index === visibleTeams.length - 1 ? 'second last_team' : 'second'}>
                        <span className='team_name'>
                          {team.verify ? <a onClick={() => openTeamPopup(players, team.scores_data, team.pretty_team_name, foursome.pretty_tee_time, foursome.id, team.team_totals, team.stableford_points)}>{player.team_name}</a> : player.team_name}
                        </span>
                        </td>
                      }
                    <td className='second'>
                    <div className='handle_text'>
                      <span className='full_name'>
                        {player.name}
                      </span>
                    </div>
                    <div className='disp_label'>{player.disp}</div>
                    </td>
                    <td className="gray_column"></td>
                    { [ ...Array(9) ].map((_, i) => (
                      <Fragment key={i}>
                        <td key={i} className={scoreClass(scores[i], pars[i], holeView)}><span>{scores[i]}</span></td>
                        {(i + 1) % 3 === 0 && i !== 8 && <td className="gray_column"></td>}
                      </Fragment>
                    )) }
                    <td className='blue_column'>{scoreData.out_completed ? scoreData.out : ''}</td>
                    { [ ...Array(9) ].map((_, i) => (
                        <Fragment key={i}>
                          <td key={i} className={scoreClass(scores[i + 9], pars[i + 9], holeView)}>
                            <span>{scores[i + 9]}</span></td>
                          {(i + 1) % 3 === 0 && i !== 8 && <td className="gray_column"></td>}
                        </Fragment>
                    )) }
                    <td className='blue_column'>{scoreData.in_completed ? scoreData.in : ''}</td>
                    <td className='blue_column'>{allCompleted ? scoreData.round_score : ''}</td>
                    { isMultiRound && <td className='blue_column'>{allCompleted ? scoreData.today : ''}</td> }
                    { isMultiRound && <td className='blue_column'>{allCompleted ? scoreData.total : ''}</td> }
                    { isMultiRound && <td className='blue_column'>{allCompleted ? scoreData.total_score : ''}</td> }
                  </tr>
                  )
                })
              }
            </Fragment>
          )
        })
      }
      { foursomeIndex === 0 && <tr><td colSpan={isMultiRound ? 32 : 29} className='gray_row'/></tr> }
    </Fragment>
  )
}

foursomeTeamData.propTypes = {
  foursome: PropTypes.object,
  isMultiRound: PropTypes.bool,
  foursomeIndex: PropTypes.number,
  holeView: PropTypes.string,
  openTeamPopup: PropTypes.func,
}

export default foursomeTeamData
