import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { scoreClass } from '../../shot_by_shot_view/helpers'

const foursomeData = ({foursome, isMultiRound, foursomeIndex, holeView, openPopup}) => {
  const players = [ ...foursome.players ]
  return (
    <Fragment>
      {
        players.filter( player => player.verified_for_step === false).map( (player, index) => {
          const scoreData = { ...player.score_data }
          const shots = [ ...player.shots_data ]
          const scores = [ ...scoreData.scores_array ]
          const pars = [ ...player.pars_array ]
          const firstRowClass = index === 0 ? ' first_row' : ''
          const allCompleted = scoreData.in_completed && scoreData.out_completed
          return (
            <tr key={player.id} className={'player_row' + firstRowClass}>
              { index === 0 &&
                <td rowSpan={foursome.size} className='first'>
                  <p>{foursome.position}.</p>
                  <p><b>Starting Hole: </b>{foursome.starting_hole}</p>
                  <p>{foursome.pretty_tee_time}</p>
                </td>
              }
              <td className='second'>
              <div className='handle_text'>
                <span className='full_name'>
                  { player.verify ? <a onClick={() => openPopup(player.id, player.name, scoreData, shots, pars, foursome.pretty_tee_time)}>{player.name}</a> : player.name}
                </span>
              </div>
                <div className='disp_label'>{player.disp}</div>
              </td>
              { [ ...Array(9) ].map( ( _, i) => (
                <Fragment key={i}>
                  <td key={i} className={scoreClass(scores[i], pars[i], holeView)}><span>{scores[i]}</span></td>
                  { (i + 1) % 3 === 0 && i !== 8 && <td className="gray_column"></td> }
                </Fragment>
              )) }
              <td className='blue_column'>{scoreData.out_completed ? scoreData.out : ''}</td>
              { [ ...Array(9) ].map( ( _, i) => (
                <Fragment key={i}>
                  <td key={i} className={scoreClass(scores[i + 9], pars[i + 9], holeView)}><span>{scores[i + 9]}</span></td>
                  { (i + 1) % 3 === 0 && i !== 8 && <td className="gray_column"></td> }
                </Fragment>
              )) }
              <td className='blue_column'>{scoreData.in_completed ? scoreData.in : ''}</td>
              <td className='blue_column'>{allCompleted ? scoreData.round_score : ''}</td>
              { isMultiRound && <td className='blue_column'>{allCompleted ? scoreData.today : ''}</td>}
              { isMultiRound && <td className='blue_column'>{allCompleted ? scoreData.total : ''}</td> }
              { isMultiRound && <td className='blue_column'>{allCompleted ? scoreData.total_score : ''}</td> }
            </tr>
          )
        })
      }
      { foursomeIndex === 0 && <tr><td colSpan={isMultiRound ? 30 : 27} className='gray_row'/></tr> }
    </Fragment>
  )
}

foursomeData.propTypes = {
  foursome: PropTypes.object,
  isMultiRound: PropTypes.bool,
  foursomeIndex: PropTypes.number,
  holeView: PropTypes.string,
  openPopup: PropTypes.func,
}

export default foursomeData
