import { connect } from 'react-redux'

import EditDistancePopupComponent from '../components/edit_distance_popup_component'
import { closePopup, editDistance } from '../actions'

const mapStateToProps = (state, ownProps) => {
  return {
    playerName: state.popup.playerName,
    distance: state.popup.distance,
    show: state.popup.visible,
    distanceId: state.popup.distanceId,
    stationId: ownProps.stationId,
    distanceUnit: state.misc.distanceUnit,
  }
}

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => dispatch(closePopup()),
  editDistance: (distanceId, stationId, distance) => dispatch(editDistance(distanceId, stationId, distance)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDistancePopupComponent)
