import { connect } from 'react-redux'
import _ from 'lodash'
import {
  addToCart,
} from '../actions'

import EventsListComponent from '../components/events_list_component'

import {
  getFormattedLeagues,
} from '../selectors'

const mapStateToProps = (state) => {
  const formattedLeagues = getFormattedLeagues(state)
  const formattedLeaguesIds = Object.keys(formattedLeagues)
  let statusesIndex = []
  if (formattedLeaguesIds.length > 0) {
    statusesIndex.push( { status: formattedLeagues[formattedLeaguesIds[0]].registered === true ? 'registered' : formattedLeagues[formattedLeaguesIds[0]].registrationInfo.status, id: formattedLeagues[formattedLeaguesIds[0]].id } )
  }
  if (statusesIndex[0] && statusesIndex[0].status === 'registered') {
    for (let i = 1; i < formattedLeagues.length; i++) {
      if (formattedLeagues[formattedLeaguesIds[i]].registrationInfo.status !== statusesIndex[statusesIndex.length - 1].status) {
        if (!formattedLeagues[formattedLeaguesIds[i]].registered) {
          statusesIndex.push( { status: formattedLeagues[formattedLeaguesIds[i]].registrationInfo.status || 'registration-disabled', id: formattedLeagues[formattedLeaguesIds[i]].id })
          break 
        }
      }
    }
  } else {
    statusesIndex = []
  }

  if (state.misc.shouldSort !== null) {
    formattedLeagues.sort((a, b) => {
      const dateA = new Date(a.startDate)
      const dateB = new Date(b.startDate)
      return state.misc.shouldSort ? dateA - dateB : dateB - dateA
    })
  }

  return {
    viewType: state.filters.viewType,
    leagues: formattedLeagues,
    cartMerchantAccountId: state.misc.cartMerchantAccountId,
    cartMerchantAccountType: state.misc.cartMerchantAccountType,
    statusFilter: state.filters.status,
    idsForLabels: _.mapValues(_.keyBy(statusesIndex, 'id'), 'status'),
    dataIsLoading: !!state.misc.dataIsLoading,
    scrollPosition: state.filters.scroll,
    showCourseInfo: state.misc.showCourseInfo,
    directoryHideGolfers: state.misc.directoryHideGolfers,
    shouldSort: state.misc.shouldSort,
    shouldShowFilters: state.misc.shouldShowFilters,
    shouldHideRegistration: state.misc.shouldHideRegistration,
  }
}

const mapDispatchToProps = dispatch => ({
  addToCart: (url) => dispatch(addToCart(url)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsListComponent)
