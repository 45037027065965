import { callAPI} from 'Shared/helpers'

export const ActionTypes = {
  SHOW_TABLE_DATA: 'SHOW_TABLE_DATA',
  SET_COURSES_ENABLED: 'SET_COURSES_ENABLED',
  EDIT_LEAGUE: 'EDIT_LEAGUE',
  EDIT_LEAGUE_REGISTRATION: 'EDIT_LEAGUE_REGISTRATION',
  EDIT_ROUND: 'EDIT_ROUND',
  EDIT_LEAGUE_COURSES_ENABLED: 'EDIT_LEAGUE_COURSES_ENABLED',
  SUBMIT: 'SUBMIT',
  TOGGLE_LEAGUE_CHECKBOX: 'TOGGLE_LEAGUE_CHECKBOX',
  TOGGLE_ROUND_CHECKBOX: 'TOGGLE_ROUND_CHECKBOX',
  SELECT_ALL_CHECKBOXES: 'SELECT_ALL_CHECKBOXES',
  SELECT_ALL_LEAGUES_AND_EVENTS_CHECKBOXES: 'SELECT_ALL_LEAGUES_AND_EVENTS_CHECKBOXES',
  SELECT_ALL_ROUNDS_CHECKBOXES: 'SELECT_ALL_ROUNDS_CHECKBOXES',
  CLEAR_SELECTION_CHECKBOXES: 'CLEAR_SELECTION_CHECKBOXES',
  APPLY_BULK_EDIT: 'APPLY_BULK_EDIT',
  MARK_DATA_AS_FETCHED: 'MARK_DATA_AS_FETCHED',
  MARK_DATA_AS_UNFETCHED: 'MARK_DATA_AS_UNFETCHED',
  ZERO_MODIFIED_DATA_COUNT: 'ZERO_MODIFIED_DATA_COUNT',
}

export const showTableData = (data) => {
  return {
    type: ActionTypes.SHOW_TABLE_DATA,
    payload: { data },
  }
}

export const setCoursesEnabled = (data) => {
  return {
    type: ActionTypes.SET_COURSES_ENABLED,
    payload: { data },
  }
}

export const markDataAsFetched = () => {
  return {
    type: ActionTypes.MARK_DATA_AS_FETCHED,
    payload: {},
  }
}

export const markDataAsUnfetched = () => {
  return {
    type: ActionTypes.MARK_DATA_AS_UNFETCHED,
    payload: {},
  }
}

export const editLeague = (leagueKey, field, value) => {
  return {
    type: ActionTypes.EDIT_LEAGUE,
    payload: { leagueKey, field, value },
  }
}

export const editLeagueRegistration = (leagueKey, field, value) => {
  return {
    type: ActionTypes.EDIT_LEAGUE_REGISTRATION,
    payload: { leagueKey, field, value },
  }
}

export const editLeagueCoursesEnabled = (leagueKey, courseKey, isEnabled) => {
  return {
    type: ActionTypes.EDIT_LEAGUE_COURSES_ENABLED,
    payload: { leagueKey, courseKey, isEnabled },
  }
}

export const editRound = (leagueKey, roundKey, field, value) => {
  return {
    type: ActionTypes.EDIT_ROUND,
    payload: { leagueKey, roundKey, field, value },
  }
}

export const submitAction = (response) => {
  return {
    type: ActionTypes.SUBMIT,
    payload: { response },
  }
}

export const toggleLeagueCheckbox = (leagueKey) => {
  return {
    type: ActionTypes.TOGGLE_LEAGUE_CHECKBOX,
    payload: { leagueKey },
  }
}

export const toggleRoundCheckbox = (leagueKey, roundKey) => {
  return {
    type: ActionTypes.TOGGLE_ROUND_CHECKBOX,
    payload: { leagueKey, roundKey },
  }
}

export const selectAllCheckboxes = (isCurrentPageSelected) => {
  return {
    type: ActionTypes.SELECT_ALL_CHECKBOXES,
    payload: { isCurrentPageSelected },
  }
}

export const selectAllLeaguesAndEventsCheckboxes = (isCurrentPageSelected) => {
  return {
    type: ActionTypes.SELECT_ALL_LEAGUES_AND_EVENTS_CHECKBOXES,
    payload: { isCurrentPageSelected },
  }
}

export const selectAllRoundsCheckboxes = (isCurrentPageSelected) => {
  return {
    type: ActionTypes.SELECT_ALL_ROUNDS_CHECKBOXES,
    payload: { isCurrentPageSelected },
  }
}

export const clearSelectionCheckboxes = (isCurrentPageSelected) => {
  return {
    type: ActionTypes.CLEAR_SELECTION_CHECKBOXES,
    payload: { isCurrentPageSelected },
  }
}

export const zeroModifiedDataCount = () => {
  return {
    type: ActionTypes.ZERO_MODIFIED_DATA_COUNT,
    payload: {},
  }
}

export const fetchTableData = (pageNumber, searchLeagueQuery, selectedCategories, selectedSeasons, advancedFilter, recreateLeaguesAndRoundsSelected, callback, showPastRecords) => {
  return (dispatch, getState) => {
    const state = getState()
    const url = `/customers/${state['table']['data']['customer_id']}/key_settings/get_table_data?page=${pageNumber}`
    const leaguesAndRoundsSelectedJSON = JSON.stringify(state['table']['data']['leagues_and_rounds_selected'])
    const params = {
      search_query: searchLeagueQuery,
      selected_categories: selectedCategories,
      selected_seasons: selectedSeasons,
      advanced_filter: JSON.stringify(advancedFilter),
      recreate_leagues_and_rounds_selected: recreateLeaguesAndRoundsSelected,
      leagues_and_rounds_selected: leaguesAndRoundsSelectedJSON,
      show_past_records: showPastRecords,
    }
    callAPI(url, 'POST', params, {})
      .then((response) => {
        dispatch(showTableData(response))
        if (callback){ callback() }
        if (!response['table'] || Object.keys(response['table']).length === 0) {
          return null
        }
        return fetchCoursesEnabled(response)
      })
      .then((response) => {
        if (!response) { return null }
        dispatch(setCoursesEnabled(response))
      })
      .catch(() => {
        console.error('Error while fetching the data!')
      })
  }
}

export const fetchCoursesEnabled = (state) => {
  const url = `/customers/${state['customer_id']}/key_settings/get_leagues_courses_enabled`
  const leagueIds = Object.keys(state['table'])
  const params = { league_ids: leagueIds }
  return callAPI(url, 'GET', params, {})
}

export const loadCoursesEnabled = () => (dispatch, getState) => {
  const state = getState()
  fetchCoursesEnabled(state['table']['data'])
    .then((response) => {
      dispatch(setCoursesEnabled(response))
    })
    .catch(() => {
      console.error('Error while fetching the data!')
    })
}

export const updateSettings = () => (dispatch, getState) => {
  const state = getState()
  const modifiedData = state['table']['modifiedData']
  const modifiedDataJSON = JSON.stringify(modifiedData)
  const url = `/customers/${state['table']['data']['customer_id']}/key_settings/update_settings`
  const params = {
    modified_data: modifiedDataJSON,
  }
  
  return callAPI(url, 'PUT', params, new Headers({ 'Accept': 'application/json' }))
    .then((response) => {
      dispatch(submitAction(response))
    })
    .catch((e) => {
      console.error('Error while updating the data!', e)
    })
}

export const applyBulkEdit = (operation) => (dispatch, getState) => {
  const state = getState()
  const url = `/customers/${state['table']['data']['customer_id']}/key_settings/bulk_edit`
  const params = {
    leagues_and_rounds_selected: JSON.stringify(state['table']['data']['leagues_and_rounds_selected']),
    operation: JSON.stringify(operation),
  }

  return callAPI(url, 'PUT', params, new Headers({ 'Accept': 'application/json' }))
    .then(() => { })
    .catch((e) => {
      console.error('Error while updating the data!', e)
    })
}
