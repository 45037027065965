import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StatisticTableComponent from './statistic_table_component'

class StatisticComponent extends Component{
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.loadStatisticData()
    this.timer = setInterval(this.props.loadStatisticData, 60000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    return (
      <div className={'stat_container'}>
        <StatisticTableComponent statKey={this.props.shot_statistic.key}
                                   name={this.props.shot_statistic.name}
                                   columns={this.props.shot_statistic.columns}
                                   content={this.props.shot_statistic.content}
                                   pathToPlayerStats={this.props.pathToPlayerStats}
                                   showPopupOnClick={this.props.openPopup}
                                   isPopup={false}
        />
      </div>
    )
  }
}

StatisticComponent.propTypes = {
  leagueId: PropTypes.string,
  shot_statistic: PropTypes.object,
  loadStatisticData: PropTypes.func,
  pathToPlayerStats: PropTypes.string,
  openPopup: PropTypes.func,
}

export default StatisticComponent

