import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { GlgCheckbox } from 'SharedComponents'

export class TermsAndPrivacyComponent extends Component {
  constructor(props){
    super(props)
  }
  
  render(){
    let brandName = ''
    switch (this.props.branding) {
      case 'golfgenius' || 'golf_genius_trip_manager':
        brandName = 'Golf Genius'
        break
      case 'brs':
        brandName = 'BRS'
        break
      case 'golf_canada':
        brandName = 'Golf Canada'
        break
      default:
        brandName = 'Golf Genius'
    }
    const text = <div>{ !window.I18n ? '' : window.I18n.t('members_page.components.terms_and_privacy.terms_and_privacy_component.confirm',
          { type: this.props.type === 'general' ? (!window.I18n ? '' : window.I18n.t('members_page.components.terms_and_privacy.terms_and_privacy_component.submitted')) : (!window.I18n ? '' : window.I18n.t('members_page.components.terms_and_privacy.terms_and_privacy_component.in_spreadsheet')), brand_name: brandName }) }<a href={this.props.termsAndConditionsUrl} target="_blank">{ ` ${!window.I18n ? '' : window.I18n.t('members_page.components.terms_and_privacy.terms_and_privacy_component.terms_of_service')}` }</a>{ ` ${!window.I18n ? '' : window.I18n.t('members_page.components.terms_and_privacy.terms_and_privacy_component.and')} ` }<a href={this.props.privacyPolicyUrl} target="_blank">{ ` ${!window.I18n ? '' : window.I18n.t('members_page.components.terms_and_privacy.terms_and_privacy_component.privacy_policy')}` }</a></div>
    return <div className="checkbox_prevent_wrapping"><GlgCheckbox name={`terms_and_privacy_${this.props.type}`} defaultChecked={ this.props.checked } label={text} onChange={(val) => this.props.toggleTermsAndPrivacy(val)}></GlgCheckbox></div>
  }
}

TermsAndPrivacyComponent.propTypes = {
  type: PropTypes.string.isRequired,
  branding: PropTypes.string.isRequired,
  termsAndConditionsUrl: PropTypes.string.isRequired,
  privacyPolicyUrl: PropTypes.string.isRequired,
  toggleTermsAndPrivacy: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
}

export default TermsAndPrivacyComponent
