import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { InputGroup, FormControl } from 'react-bootstrap'
import { Icon as FaIcon } from 'react-fa'

class CalendarInputComponent extends Component {
  constructor(props){
    super(props)
  }
  
  render() {
    return <InputGroup onClick={ this.props.onClick }><FormControl value={ this.props.value }></FormControl><span className="input-group-addon" style={{ background: 'white', display: 'table-cell' }}><FaIcon name="calendar" style={{ color: '#4F4F4F' }}></FaIcon></span></InputGroup>
  }
}

CalendarInputComponent.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
}

export default CalendarInputComponent
