import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import SaveBarContainer from '../containers/save_bar_container'
import LeaderboardTableContainer from '../containers/leaderboard_table_container'
import HoleByHoleTableContainer from '../containers/hole_by_hole_table_container'
import SideBySideTableContainer from '../containers/side_by_side_table_container'
import HoleByHolePreviewComponent from '../containers/hole_by_hole_preview_container'
import SideBySidePreviewContainer from '../containers/side_by_side_preview_container'

class MonsterBoardComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      oldLines: [ ...props.lines ],
      oldMessage: props.message,
      oldScore: props.score,
      oldShowMessage: props.showMessage,
      oldShowRounds: props.showRounds,
      oldShowTotal: props.showTotal, 
    }
    this.changesNotPublished = this.changesNotPublished.bind(this)
  }

  componentDidMount() {
    this.timer = setInterval(this.props.refreshData, 30000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.unSavedChanges === true && this.props.unSavedChanges === false) {
      this.setState({oldLines: [ ...this.props.lines ], oldShowMessage: this.props.showMessage, oldMessage: this.props.message, oldScore: this.props.score })
    }
  }

  changesNotPublished() {
    const { oldLines, oldMessage, oldScore, oldShowMessage, oldShowRounds, oldShowTotal } = this.state
    const { lines, message, score, showMessage, showRounds, showTotal, unSavedChanges } = this.props
    
    if (oldMessage === message && oldScore === score && 
      oldShowMessage === showMessage && 
      oldShowRounds === showRounds && oldShowTotal === showTotal && 
      _.isEqual(oldLines, lines)) {
      return false
    }
    return unSavedChanges
  }

  render() {
    const { roundGgid, deviceView } = this.props
    const boardPath = '/monster_boards/' + roundGgid
    const showMessage = this.changesNotPublished()

    return (
      <Fragment>
        <div className='row row-no-padding'>
          <div className='alert product_warning stick_to_breadcrumb'>
            <ul className='list-inline'>
              <li className='bold-note'>Note:</li>
              <li>
                The Monster Board is accessible at <a href={boardPath} target='_blank'>{'www.golfgenius.com/monster_boards/' + roundGgid}</a>
              </li>
            </ul>
          </div>
        </div>
        <LeaderboardTableContainer/>
        { deviceView === 'hole-by-hole' ? < HoleByHoleTableContainer/> : < SideBySideTableContainer/> }
        { deviceView === 'hole-by-hole' ? <HoleByHolePreviewComponent/> : <SideBySidePreviewContainer/> }
        <SaveBarContainer showMessage={showMessage}/>
      </Fragment>
    )
  }
}

MonsterBoardComponent.propTypes = {
  isMultiRound: PropTypes.bool,
  roundGgid: PropTypes.string,
  deviceView: PropTypes.string,
  refreshData: PropTypes.func,
  lines: PropTypes.array,
  message: PropTypes.string,
  score: PropTypes.string,
  showMessage: PropTypes.bool,
  showRounds: PropTypes.bool,
  showTotal: PropTypes.bool,
  unSavedChanges: PropTypes.bool,
}

export default MonsterBoardComponent
