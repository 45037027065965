export default {
  ALL: 'all',
  UPCOMING: 'upcoming',
  PAST: 'past',
  OPEN: 'registration_open',
  WILL_OPEN: 'registration_will_open',
  OPEN_FOR_INVITATION: 'invitation_only',
  OPEN_FOR_MEMBERSHIP: 'open_for_membership',
  CLOSED: 'closed',
  WAITLIST_NOT_EMPTY: 'waitlist_not_empty',
  PENDING_LIST_NOT_EMPTY: 'pending_list_not_empty',
  NON_ARCHIVED: 'non_archived',
  GOLF_HUB_CREATED: 'golf_hub_created',
  GOLF_HUB_PUBLISHED: 'golf_hub_published',
  GOLF_HUB_NOT_CREATED: 'golf_hub_not_created',
}
