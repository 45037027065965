/*
 * Add column data types and common column names, column data keys etc.
 */

import _ from 'lodash'
import moment from 'moment-timezone'
import { getTodayWithTimezone, formatDateTime } from 'Shared/helpers'

export const getColumnDataKey = (column) => {
  switch (column) {
    case 'season': return 'seasonName'
    case 'tour': return 'tourName'
    case 'confirmedGolfersCount': return 'golfers'
    case 'waitlistedGolfersCount': return 'golfers'
    case 'pendingRegistrationCount': return 'golfers'
    default:
      return column
  }
}

export const isExtraColumn = (column) => {
  return [
    'endDate',
    'registrationStartDate',
    'registrationCloseDate',
  ].includes(column)
}

export const isRegistrationExtraColumn = (column) => {
  return [
    'registrationStartDate',
    'registrationCloseDate',
  ].includes(column)
}

export const getColumnName = (column) => {
  switch (column) {
    case 'endDate': return 'End Date'
    case 'registrationStartDate': return 'Registration Start Date'
    case 'registrationCloseDate': return 'Registration Close Date'
    default:
      return column
  }
}

export const getColumnSortingValue = (league, column, categoryName) => {
  switch (column) {
    case 'confirmedGolfersCount':
      return league.golfers && league.golfers.confirmed
    case 'waitlistedGolfersCount':
      return league.golfers && league.golfers.waitlisted
    case 'pendingRegistrationCount':
      return league.golfers && league.golfers.pending
    case 'registrationStatus':
      return computeRegistrationMessage(league)
    case 'categoryName':
      return categoryName && categoryName.toLowerCase()
    case 'registrationStartDate':
      return league.openRegistrationData &&
             league.openRegistrationData.registrationStartsOn
    case 'registrationCloseDate':
      return league.openRegistrationData &&
             league.openRegistrationData.registrationEndsOn
    default: {
      let value = league[column]
      if (typeof value === 'string') {
        value = value.toLowerCase()
      }
      return value
    }
  }
}

const NUMBER_COLUMNS = [
  'confirmedGolfersCount',
  'waitlistedGolfersCount',
  'pendingRegistrationCount',
]
const DATE_COLUMNS = [
  'startDate',
  'endDate',
  'registrationStartDate',
  'registrationCloseDate',
]
export const getColumnType = (column) => {
  if (NUMBER_COLUMNS.includes(column)) {
    return 'number'
  } else if (DATE_COLUMNS.includes(column)) {
    return 'date'
  } else {
    return 'string'
  }
}

export const computeRegistrationMessage = (league) => {
  const {
    hasOpenRegistration,
    openRegistrationStatus,
    registrationInvitationListStartsOn,
    membershipProgramsInfo,
    registrationStartsOn,
    registrationEndsOn,
  } = league.openRegistrationData
  if (hasOpenRegistration) {
    const today = getTodayWithTimezone(league.timeZone)
    const minMembershipOpeningDate = _.min(membershipProgramsInfo.map(mp => moment(mp.startDate).tz(league.timeZone)))
    const earliestFutureOpeningDate = _.find(_.compact([ registrationInvitationListStartsOn, minMembershipOpeningDate, registrationStartsOn ]), date => {
      return moment(date).tz(league.timeZone)
                  .isAfter(today)
    })

    if (openRegistrationStatus === 'open') {
      let message = 'Open'
      if (registrationEndsOn) {
        message += `, closes ${ formatDateTime(registrationEndsOn, league.timeZone) }`
      }
      return message
    } else if (openRegistrationStatus === '' && earliestFutureOpeningDate &&
               (!registrationEndsOn ||
                moment(earliestFutureOpeningDate).isBefore(moment(registrationEndsOn)))) {
      return `Will open ${ formatDateTime(earliestFutureOpeningDate, league.timeZone) }`
    } else if (league.golfers.confirmed === 0) {
      return 'Not yet open'
    } else if (openRegistrationStatus === '') {
      return 'Closed'
    } else if (openRegistrationStatus === 'invitation_list') {
      return 'Invitation List'
    } else if (openRegistrationStatus === 'membership') {
      return 'Membership Programs'
    }
  }
  return ''
}

export const registrationDisplaysTime = (registrationMessage) => {
  return registrationMessage.includes('closes') ||
         registrationMessage.toLowerCase().includes('will open')
}

export const isRegistrationOpenForMembership = (registrationMessage) => {
  return registrationMessage === 'Membership Programs'
}
