import { connect } from 'react-redux'
import DeliveryReportsComponent from '../../components/delivery_reports/delivery_reports_component'

const mapStateToProps = (state) => {
  return state.deliveryReports
}

const mapDispatchToProps = () => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryReportsComponent)
