export const PopupActionTypes = {
  OPEN_POPUP: 'OPEN_POPUP',
  CLOSE_POPUP: 'CLOSE_POPUP',
}

export const openPopup = (id, data) => ({
  type: PopupActionTypes.OPEN_POPUP,
  id,
  data,
})

export const closePopup = id => ({
  type: PopupActionTypes.CLOSE_POPUP,
  id,
})
