import { connect } from 'react-redux'
import UploadSpreadsheetComponent from '../../components/sidebar_content/upload_spreadsheet_component'
import { closeUploadSpreadsheetSidebar, toggleTermsAndPrivacy, uploadRosterSpreadsheet } from '../../actions/index'

const mapStateToProps = (state) => ({
  show: state.leftSidebarFields.showUploadSpreadsheetPopup,
  termsAndPrivacyChecked: state.leftSidebarFields.termsAndPrivacyChecked,
  filepickerOptions: state.leftSidebarFields.filepickerOptions,
  isUploadRosterLoading: state.leftSidebarFields.isUploadRosterLoading,
  branding: state.misc.branding,
  termsAndConditionsUrl: state.misc.termsAndConditionsUrl,
  privacyPolicyUrl: state.misc.privacyPolicyUrl,
})

const mapDispatchToProps = (dispatch) => ({
  closeUploadSpreadsheetSidebar: () => dispatch(closeUploadSpreadsheetSidebar()),
  toggleTermsAndPrivacy: (checked, type) => dispatch(toggleTermsAndPrivacy(checked, type)),
  uploadRosterSpreadsheet: (data) => dispatch(uploadRosterSpreadsheet(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadSpreadsheetComponent)
