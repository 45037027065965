export const ActionTypes = {
  REMOVE_MARKETING_CAMPAIGN: 'REMOVE_MARKETING_CAMPAIGN',
  CHANGE_STATUS: 'CHANGE_STATUS',
}

export const removeMarketingCampaign = (marketingCampaignId) => {
  return {
    type: ActionTypes.REMOVE_MARKETING_CAMPAIGN,
    marketingCampaignId,
  }
}

export const changeStatus = (marketingCampaignId, newStatus) => {
  return {
    type: ActionTypes.CHANGE_STATUS,
    marketingCampaignId,
    newStatus,
  }
}
