import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ManagersList extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { list, more, description } = this.props.content

    return (
      <div className='widget-content-statistics'>
        <div className='list'>
          {
            list.map( manager =>
              <div key={ manager[1] } className='manager'>
                <a target="_blank" href={ `mailto:${manager[1]}` }>
                  { manager[0] }
                </a>
              </div> )
          }
          {
            more !== null
            ? <div key={ more[1] } className='manager'>
              <a target="_blank" href={ more[1] }>
                { more[0] }
              </a>
            </div>
            : null
          }
        </div>

        <div className='description'>{ description }</div>
      </div>
    )
  }
}

ManagersList.propTypes = { 
  content: PropTypes.object,
}


ManagersList.defaultProps = {
  content: {
    list: [],
    more: null,
    description: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.managers_list.description'),
  },
}

export default ManagersList
