import { createSelector } from 'reselect'

const getLeague = state => state.misc.potentialCloneCandidate
const getUsersById = state => state.managedCustomerData.users || state.users

const getLeagueManagers = createSelector(
  [ getLeague, getUsersById ],
  (league, usersById) => (
    league.managers.map(managerId => usersById[managerId])
  ),
)

export default getLeagueManagers
