import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

import {
  GlgSelect,
  StickToBreadcrumb,
} from 'SharedComponents'

class CustomerFilterComponent extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    const customers = this.props.customers.map(({ id, name, url }) => ({
      value: id,
      label: name,
      url,
    }))
    const accessInOtherAccountText = (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.customer_filter_component.access_in_other_account'))
    // below text should be replaced with the above translation
    return this.props.customers.length > 1 && <StickToBreadcrumb className="customer-filter-wrapper"><Row><Col xs={9}><div className="text">{accessInOtherAccountText}</div></Col><Col xs={3}><GlgSelect items={ customers } defaultValue={ this.props.activeCustomerId }></GlgSelect></Col></Row></StickToBreadcrumb>
  }
}

CustomerFilterComponent.propTypes = {
  customers: PropTypes.array.isRequired,
  activeCustomerId: PropTypes.string,
}

export default CustomerFilterComponent
