import React from 'react'
import PropTypes from 'prop-types'
import rootReducer from '../reducers'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import MonsterBoardDeviceContainer from './monster_board_device_container'

const CourseLeaderboardRoot = ({roundGgid, boardAvailable, frontLines, backLines, showMessage, message, score, showCourse, showRounds, showTotal, tees, deviceView, lastUpdate}) => {
  const state = {
    misc: {
      roundGgid,
      boardAvailable,
      frontLines,
      backLines,
      showCourse,
      showMessage,
      message,
      score,
      showRounds,
      showTotal,
      tees,
      deviceView,
      lastUpdate,
    },
  }

  const store = configureStore(rootReducer, state)

  return (
    <GlobalProvider store={ store }>
      <MonsterBoardDeviceContainer />
    </GlobalProvider>
  )
}

CourseLeaderboardRoot.propTypes = {
  roundGgid: PropTypes.string,
  boardAvailable: PropTypes.bool,
  frontLines: PropTypes.array,
  backLines: PropTypes.array,
  showCourse: PropTypes.bool,
  showMessage: PropTypes.bool,
  message: PropTypes.string,
  score: PropTypes.string,
  showRounds: PropTypes.bool,
  showTotal: PropTypes.bool,
  tees: PropTypes.array,
  deviceView: PropTypes.string,
  lastUpdate: PropTypes.string,
}

export default CourseLeaderboardRoot
