import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import { Icon as FaIcon } from 'react-fa'
import { FormControl } from 'react-bootstrap'

class GlgColorPicker extends PureComponent {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleChange = this.handleChange.bind(this)
    const color = props.initialColor || props.color || '#333333'
    this.state = {
      displayColorPicker: false,
      color,
    }
  }

  handleClick() {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleClose() {
    this.setState({ displayColorPicker: false })
  }

  handleChange(color) {
    this.setState({ color: color.hex })

    if (this.props.onChange) {
      this.props.onChange(color)
    }
  }

  render() {
    let styles = reactCSS({
      'default': {
        color: {
          display: 'inline-block',
          width: '20px',
          height: '20px',
          borderRadius: '2px',
          background: this.state.color,
          marginBottom: '-6px',
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
        icon: {
          marginLeft: '5px',
        },
        pickerWrapper: {
          position: 'absolute',
          zIndex: '1000',
          left: '45px',
        },
      },
    })

    if (this.props.pickerPosition === 'top') {
      styles = {
        ...styles,
        pickerWrapper: {
          ...styles.pickerWrapper,
          bottom: '0px',
        },
      }
    } else if (this.props.pickerPosition === 'bottom') {
      styles = {
        ...styles,
        pickerWrapper: {
          ...styles.pickerWrapper,
          top: '0px',
        },
      }
    }

    return <div className="glg-color-picker"><FormControl name={ this.props.name } type="hidden" value={ this.state.color }></FormControl><div style={ styles.swatch } onClick={ this.handleClick }><div style={ styles.color }></div><FaIcon name="caret-down" style={ styles.icon }></FaIcon></div>{this.state.displayColorPicker && <Fragment><div style={ styles.popover }></div><div style={ styles.cover } onClick={ this.handleClose }></div><div style={ styles.pickerWrapper }><SketchPicker color={ this.state.color } onChange={ this.handleChange } disableAlpha={ this.props.disableAlpha }></SketchPicker></div></Fragment>}</div>
  }
}

GlgColorPicker.propTypes = {
  // The input name
  name: PropTypes.string.isRequired,
  // The initial selected color
  initialColor: PropTypes.string,
  // Controlled picker color value
  color: PropTypes.string,
  // Decides whether the picker opens to the top or to the bottom of the page when clicked
  pickerPosition: PropTypes.oneOf([ 'top', 'bottom' ]),
  // Disable alpha (transparency) slider and text box
  disableAlpha: PropTypes.bool,
  // Callback on color value change
  onChange: PropTypes.func,
}

GlgColorPicker.defaultProps = {
  pickerPosition: 'bottom',
  disableAlpha: false,
}

export default GlgColorPicker
