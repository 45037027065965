export const ActionTypes = {
  SET_LIBRARY_UNSAVED_CHANGES: 'SET_LIBRARY_UNSAVED_CHANGES',
  SET_TEMPLATE: 'SET_TEMPLATE',
  SET_AUTO_INCLUDE_OPTION: 'SET_AUTO_INCLUDE_OPTION',
  TOGGLE_ROUND: 'TOGGLE_ROUND',
  CHECK_ALL_ROUNDS: 'CHECK_ALL_ROUNDS', 
}

export const setUnsavedChanges = (value) => {
  return {
    type: ActionTypes.SET_LIBRARY_UNSAVED_CHANGES,
    value,
  }
}

export const handleSetTemplate = (value) => {
  return {
    type: ActionTypes.SET_TEMPLATE,
    value,
  }
}

export const setAutoIncludeOption = (value) => {
  return {
    type: ActionTypes.SET_AUTO_INCLUDE_OPTION,
    value,
  }
}
export const toggleRound = (checked, roundId) => {
  return {
    type: ActionTypes.TOGGLE_ROUND,
    checked,
    roundId,
  }
}
export const checkAllRounds = (check) => {
  return {
    type: ActionTypes.CHECK_ALL_ROUNDS,
    check,
  }
}
