import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import GlgTooltip from './glg_tooltip'
import GlgIcon from './glg_icon'

class GlgTooltipIcon extends PureComponent {
  getIconClass() {
    if (_.isUndefined(this.props.iconClass)) {
      return 'add-margin-left-5'
    } else {
      return this.props.iconClass
    }
  }

  render() {
    return <GlgTooltip tooltip={ this.props.tooltip } placement={ this.props.placement }><GlgIcon icon="info-circle" iconClass={ this.getIconClass() }></GlgIcon></GlgTooltip>
  }
}

GlgTooltipIcon.propTypes = {
  // The text of the tooltip
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  // The placement of the tooltip
  placement: PropTypes.oneOf([ 'top', 'bottom', 'left', 'right' ]),
  // The class of the icon component
  iconClass: PropTypes.string,
}

GlgTooltipIcon.defaultProps = {
  placement: 'top',
}

export default GlgTooltipIcon
