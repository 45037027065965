import { connect } from 'react-redux'
import ListBlocks from '../presentational/list_blocks'
import { removeBlock, setActiveBlock, displayMode, DISPLAY_MODES } from '../../actions'

const getVisibleBlocks = (blocks, currentActiveTab) => {
  return blocks.filter(b => b.tabId === currentActiveTab).sort( (a, b) => a.index <= b.index ? -1 : 1 )
}

const mapStateToProps = state => ({
  blocks: getVisibleBlocks(state.blocks, state.filters.active_tab),
  usesTranslations: state.filters.uses_translations,
  availableLanguages: state.filters.available_languages,
  currentLanguage: state.filters.current_language,
})

const mapDispatchToProps = dispatch => ({
  removeBlock: id => dispatch(removeBlock(id)),
  editBlock: id => {
    dispatch(setActiveBlock(id))
    dispatch(displayMode(DISPLAY_MODES.EDIT_BLOCK))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListBlocks)
