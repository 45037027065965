import React, {Fragment, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import PlayersProfilesComponent from './players_profiles_component'
import GolferDetailedResultsTableComponent from './golfer_detailed_results_table_component'
import {GlgLoadingSpinner} from '../../shared/components'
import {Col} from 'react-flexbox-grid'

const TourEventResultsComponent = ({fetchTourEventResults, resetTimer, selectedTourEvent, playersResults, gameState, tourEvents, dataIsLoading, reloading}) => {

  const [ selectedEvent, setSelectedEvent ] = useState(selectedTourEvent)
  const [ visibleDetails, setVisibleDetails ] = useState({})
  const [ gameClosed, setGameClosed ] = useState(false)
  const [ gameCancelled, setGameCancelled ] = useState(false)
  
  useEffect(() => {
    setGameClosed(gameState === 'closed')
    setGameCancelled(gameState === 'cancelled')
  }, [ gameState ])

  useEffect(() => {
    fetchTourEventResults(selectedEvent, false)
    resetTimer()
  }, [ selectedEvent ])

  const showHidePlayerResults = (id) => {
    setVisibleDetails({
      ...visibleDetails,
      [id]: !visibleDetails[id],
    })
  }

  const changeTourEvent = (id) => {
    setSelectedEvent(id)
    setVisibleDetails({})
    if (tourEvents[id].path) {
      window.history.replaceState(null, null, tourEvents[id].path)
    }
  }

  return (
    <Fragment>
      <Col className="tour-event-select" xs={12} sm={8} md={8} lg={4}>
        <div className="bold">{window.I18n.t('tour_event_results.components.tour_event_results_component.select_tour_game')}</div>
        <Col xs={12} sm={10} md={10} lg={12} className="no-padding-left">
          <select className="selectpicker form-control" value={selectedEvent}
                  onChange={(event) => changeTourEvent(event.target.value)}>
            {
              Object.keys(tourEvents)
                .map(id =>
                  <option key={id} value={id}>{tourEvents[id].name}</option>,
                )
            }
          </select>
        </Col>
      </Col>

      {gameCancelled && <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 btsp_notices">
        <strong>{window.I18n.t('tour_event_results.components.tour_event_results_component.note')} </strong>
        {window.I18n.t('tour_event_results.components.tour_event_results_component.tour_game_cancelled')}
      </div>}

      <div><br/> <br/> <GlgLoadingSpinner className="text-center" show={dataIsLoading && !reloading}/> <br/></div>
      {(!dataIsLoading || reloading) &&
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top_20 no_padding">
          <div className="table-responsive">
            <table className="tour_event_results">
              <thead>
              <tr className="header">
                <th>{window.I18n.t('tour_event_results.components.tour_event_results_component.position')}</th>
                <th className="name">{window.I18n.t('tour_event_results.components.tour_event_results_component.name')}</th>
                <th>{window.I18n.t('tour_event_results.components.tour_event_results_component.thru')}</th>
                {tourEvents[selectedEvent].type === 'money_grabber'
                  ? <th>{window.I18n.t('tour_event_results.components.tour_event_results_component.player_money')}</th>
                  : <th>{window.I18n.t('tour_event_results.components.tour_event_results_component.to_par_total')}</th>}
                {gameClosed &&
                  <th>{window.I18n.t('tour_event_results.components.tour_event_results_component.earnings')}</th>}
              </tr>
              </thead>
              <tbody>
              {
                playersResults.length === 0
                  ? <tr>
                    <td className=".center.notice"
                        colSpan="8">{window.I18n.t('tour_event_results.components.tour_event_results_component.no_results_yet')}</td>
                  </tr>
                  :
                  playersResults.map((rowData, rIdx) =>
                    <>
                      <tr className={`aggregate-row ${rIdx & 1 ? 'even' : 'odd'}`} key={`player_result_${rIdx}`}>
                        <td>{rowData.rank}</td>
                        <td className="name">
                          <a href="#"
                             onClick={() => showHidePlayerResults(rIdx)}>{rowData.name}</a>
                        </td>
                        <td>{rowData.thru}</td>
                        {tourEvents[selectedEvent].type === 'money_grabber'
                          ? <td>{[ 0, undefined, null ].includes(rowData.player_money) ? '-' : rowData.player_money.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</td>
                          : <td>{rowData.total}</td>}
                        {gameClosed &&
                          <td>{[ 0, undefined, null ].includes(rowData.earnings) ? '-' : rowData.earnings.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</td>}
                      </tr>
                      <tr className={visibleDetails[rIdx] ? '' : 'hidden'} key={`details_player_result_${rIdx}`}>
                        <td colSpan="9" className="nohover">
                          <div className="player_results_container">
                            <PlayersProfilesComponent teamPlayersData={rowData.team}/>
                            <GolferDetailedResultsTableComponent teamPlayersData={rowData.team} columns={rowData.cols} gameType={tourEvents[selectedEvent].type} gameClosed={gameClosed}/>
                          </div>
                        </td>
                      </tr>
                    </>,
                  )}
              </tbody>
            </table>
          </div>
        </div>
      }
    </Fragment>
  )
}

TourEventResultsComponent.propTypes = {
  fetchTourEventResults: PropTypes.func.isRequired,
  resetTimer: PropTypes.func.isRequired,
  selectedTourEvent: PropTypes.string,
  playersResults: PropTypes.array,
  gameState: PropTypes.string,
  tourEvents: PropTypes.any,
  dataIsLoading: PropTypes.bool,
  reloading: PropTypes.bool,
}

export default TourEventResultsComponent
