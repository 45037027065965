import React from 'react'
import PropTypes from 'prop-types'

const MemberCardInfo = ({memberCard, index, handicapProvider}) => {
  return (
    <div>
      <h6>
        <b>{window.I18n.t('member_cards.member_card_id')}:</b> {index}
      </h6>
      {memberCard.user_id && 
        <h6>
          <b>{window.I18n.t('member_cards.user_id')}:</b> {memberCard.user_id}
        </h6>
      }
      {memberCard.email &&
        <h6>
          <b>{window.I18n.t('member_cards.email')}:</b> {memberCard.email}
        </h6>
      }
      {memberCard.handicap_network_id &&
        <h6>
          <b>{handicapProvider || window.I18n.t('member_cards.handicap_network_id')}:</b> {memberCard.handicap_network_id}
        </h6>
      }
      {memberCard.integration_id &&
        <h6>
          <b>{window.I18n.t('member_cards.identifier_id')}:</b> {memberCard.integration_id}
        </h6>
      }
      {memberCard.metadatum_answer &&
        <h6>
          <b>{memberCard.metadatum_name}:</b> {memberCard.metadatum_answer}
        </h6>
      }
      </div>
  )
}

MemberCardInfo.propTypes = {
  memberCard: PropTypes.object,
  index: PropTypes.string,
  handicapProvider: PropTypes.string,
}

export default MemberCardInfo
