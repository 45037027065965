import React, { Component, Fragment } from 'react'
import GlgTabs from 'SharedComponents/glg_tabs'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'


class PreviewComponent extends Component {
  constructor(props){
    super(props)

    this.state = {
      showSpinner: false,
    }

    this.buildRequestQuery = this.buildRequestQuery.bind(this)
  }

  buildRequestQuery(baseUrl) {
    const {
      marketingCampaign,
      updateCount,
    } = this.props

    let query = ''

    // do not build query on page load or when template is not set
    if (updateCount > 0) {
      query = baseUrl + '?marketingCampaign=' + encodeURIComponent(JSON.stringify(marketingCampaign))
    }
    return query
  }

  shouldComponentUpdate(nextProps, nextState) {
    const previewClicked = this.props.updateCount < nextProps.updateCount
    const spinnerChange = this.state.showSpinner !== nextState.showSpinner
    if (previewClicked) {
      this.setState({
        ...this.state,
        showSpinner: true,
      })
    }
    return previewClicked || spinnerChange
  }

  render(){
    const {
      marketingCampaign,
      previewPaths,
      updateCount,
    } = this.props

    if (!marketingCampaign.channels.email && !marketingCampaign.channels.textMessage) {
      return null
    }

    return <Fragment><Row className="no-margin"><Col xs={ 12 }><div className="preview-text">{ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.preview_component.preview') }{ updateCount > 0 && <i style={{display: this.state.showSpinner ? 'inline-block' : 'none'}} className="fa fa-spinner fa-spin add-margin-left-5"></i>}</div></Col></Row><GlgTabs className="add-margin-top-10">{marketingCampaign.channels.email && <div label={ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.preview_component.email') }><Row className="no-margin"><Col xs={ 12 } className="add-padding-all-20"><iframe src={ this.buildRequestQuery(previewPaths.email) } key={ updateCount } onLoad={ () => { this.setState({ ...this.state, showSpinner: false }) } } id="preview-container"></iframe></Col></Row></div>}{marketingCampaign.channels.textMessage && <div label={ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.preview_component.text_message') }><Row className="no-margin"><Col xs={ 12 } className="add-padding-all-20"><iframe src={ this.buildRequestQuery(previewPaths.textMessage) } key={ updateCount } onLoad={ () => { this.setState({ ...this.state, showSpinner: false }) } } id="preview-container"></iframe></Col></Row></div>}</GlgTabs></Fragment>
  }
}

PreviewComponent.propTypes = {
  marketingCampaign: PropTypes.object.isRequired,
  previewPaths: PropTypes.shape({
    email: PropTypes.string.isRequired,
    textMessage: PropTypes.string.isRequired,
  }),
  updateCount: PropTypes.number.isRequired,
}

export default PreviewComponent
