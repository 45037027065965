import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { callAPI } from 'Shared/helpers'
import { GlgLoadingSpinner } from 'SharedComponents'

const EditDataComponent = (props) => {
  const [ data, setData ] = useState()
  const id = new RegExp(/edit\/(\d+)/).exec(props.page)[1]

  useEffect(() => {
    callAPI('/api/customers/' + props.customerId + '/' + (props.tab === 'banners' ? 'course_albums' : props.tab) + '/' + id, 'GET')
      .then((json) => setData(json))
  }, [])

  const Component = props.component
  return data ? (
    <Component data={data} customerId={props.customerId} page={props.page} setPage={props.setPage}/>
  ) : (
    <GlgLoadingSpinner className='text-center' show={!data}/>
  )
}

EditDataComponent.propTypes = {
  customerId: PropTypes.string,
  component: PropTypes.component,
  page: PropTypes.string,
  setPage: PropTypes.func,
  tab: PropTypes.string,
}

export default EditDataComponent
