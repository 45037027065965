import React from 'react'
import PropTypes from 'prop-types'

const GolfersSection = ({importGolfersUrl, manuallyAddGolfersUrl, uploadFromSpreadsheetUrl, changeSection}) => {

  return (
    <div className='section' id='golfers-section'>
      <hr/>
      <div className='section_header' onClick={(e) => changeSection(e)}>
        <div className='row no-margin-right'>
          <div className='col-xs-11 col-sm-7 col-lg-6 title'>
            <div className='label label-primary'>1.</div>
            <span className='header_text'>{window.I18n.t('draft.components.golfers_component.title')}</span>
          </div>
          <div className='hidden-xs col-sm-4 col-lg-5'>
          </div>
          <div className='col-xs-1 caret-container add-padding-top-15'>
            <i className='gga-carret-down'></i>
            <i className='gga-carret-up'></i>
          </div>
        </div>
      </div>
      <hr/>

      <div className='section_content btsp_square_solid hidden'>
        <div className='row'>
          <div className='col-x2-12 col-sm-10 col-md-10 col-lg-10 buttons_container'>
            <a href={importGolfersUrl} className='btn btn-primary btsp_orange_button'>{window.I18n.t('draft.components.golfers_component.import_golfers')}</a>
            <a href={manuallyAddGolfersUrl} className='btn btn-primary btsp_orange_button'>{window.I18n.t('draft.components.golfers_component.manually_add_golfers')}</a>
            <a href={uploadFromSpreadsheetUrl} className='btn btn-primary btsp_orange_button'>{window.I18n.t('draft.components.golfers_component.upload_golfers_from_spreadsheet')}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

GolfersSection.propTypes = {
  importGolfersUrl: PropTypes.string,
  manuallyAddGolfersUrl: PropTypes.string,
  uploadFromSpreadsheetUrl: PropTypes.string,
  changeSection: PropTypes.func,
}

export default GolfersSection
