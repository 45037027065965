import { connect } from 'react-redux'

import DirectoriesContentComponent from '../components/directories_content_component'
// import {
//   getFormattedLeagues,
// } from '../selectors'

const mapStateToProps = (state) => {
  // const formattedLeagues = getFormattedLeagues(state, props, props.leagueIds)
  return {
    viewType: state.filters.viewType,
    dataIsLoading: !!state.misc.dataIsLoading,
    showPortals: state.misc.showPortals,
  }
}

const mapDispatchToProps = () => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DirectoriesContentComponent)
