import { connect } from 'react-redux'
import MonsterBoardComponent from '../components/monster_board_component'
import { refreshData } from '../actions'
import { getPlayersOnMonsterBoard, getPlayersOnFrontSide, getPlayersOnBackSide } from 'Shared/course_boards'

const mapStateToProps = (state) => {
  let lines

  if (state.misc.deviceView === 'hole-by-hole') {
    lines = getPlayersOnMonsterBoard(state)
  } else {
    const frontLines = getPlayersOnFrontSide(state)
    const backLines = getPlayersOnBackSide(state)
    lines = frontLines.concat(backLines)
  }

  return {
    isMultiRound: state.misc.isMultiRound,
    roundGgid: state.misc.roundGgid,
    deviceView: state.misc.deviceView,
    showMessage: state.misc.showMessage,
    message: state.misc.message,
    score: state.misc.score,
    showRounds: state.misc.showRounds,
    showTotal: state.misc.showTotal,
    unSavedChanges: state.misc.unSavedChanges,
    lines: lines,
  }
}

const mapDispatchToProps = (dispatch) => ({
  refreshData: () => dispatch(refreshData()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MonsterBoardComponent)
