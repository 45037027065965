import createDeepSelector from './create_deep_selector'
import _ from 'lodash'

const getTours = (state, { useManagedCustomer = false } = {}) => {
  if (useManagedCustomer) {
    return state.managedCustomerData.tours || state.tours
  } else {
    return state.tours
  }
}
const getAddAll = (state, { addAll = false } = {}) => addAll
const getAddEmptyOption = (state, { addEmptyOption = true } = {}) => addEmptyOption

const getToursOptions = createDeepSelector(
  [ getTours, getAddAll, getAddEmptyOption ],
  (toursById, addAll, addEmptyOption) => {
    let tours = _.values(toursById)
    tours = _.sortBy(tours, tour => tour.name)
    if (addAll) {
      tours.push({
        id: 'all',
        name: 'All',
      })
    }
    if (addEmptyOption) {
      tours.push({
        id: 'none',
        name: 'No Tour',
      })
    }
    return tours
  },
)

export default getToursOptions
