import { connect } from 'react-redux'
import Reports from '../../components/content/reports'
import {
  reportSetState,
  scrollReports,
  reportPrint,
  reportsSelectList,
  reportsSearch,
  reportGetListContent,
  reportAction,
  reportImport,
  reportPollJobStatus,
  reportGenerateDoc,
  reportGenerateTxt,
  reportTogglePreviewModal,
} from '../../actions/index'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => ({
  reportSetState: (reportID, status) => dispatch(reportSetState(ownProps.widgetId, reportID, status)),
  scrollReports: (direction) => scrollReports(direction),
  reportsSelectList: (selected) => {
    dispatch(reportsSelectList(ownProps.widgetId, selected))
    const noContent = ownProps.content.reportCenterLists[selected].report_center_items.length === 0
    dispatch(reportGetListContent(ownProps.widgetId, ownProps.content.links.load_reports.path, selected, noContent))
    dispatch(reportsSearch(ownProps.widgetId, ''))
  },
  reportsSearch: (searchTerm) => dispatch(reportsSearch(ownProps.widgetId, searchTerm)),
  reportPrint: (url, reportId) => {
    dispatch(reportPrint(ownProps.widgetId, url, reportId))
    dispatch(reportSetState(ownProps.widgetId, reportId, ownProps.content.reportStates.processing))
  },
  reportAction: (url, reportId, type) => dispatch(reportAction(ownProps.widgetId, url, reportId, type)),
  reportImport: (url, reportId, type) => dispatch(reportImport(ownProps.widgetId, url, reportId, type)),
  reportPollJobStatus: (reportId, jobId, jobType) => dispatch(reportPollJobStatus(ownProps.widgetId, ownProps.content.links.poll.path, reportId, jobId, jobType)),
  reportGenerateDoc: (reportId, reportName, pdfUrl, service) => dispatch(reportGenerateDoc(ownProps.widgetId, ownProps.content.links.convert_docx.path, ownProps.leagueId, reportId, reportName, pdfUrl, service)),
  reportGenerateTxt: (reportId, reportName, pdfUrl, generateTxt) => dispatch(reportGenerateTxt(ownProps.widgetId, ownProps.content.links.convert_txt.path, ownProps.leagueId, reportId, reportName, pdfUrl, generateTxt)),
  reportTogglePreviewModal: (visible, imgSrc, reportName) => dispatch(reportTogglePreviewModal(ownProps.widgetId, visible, imgSrc, reportName)),
})

export default connect(
  null,
  mapDispatchToProps,
)(Reports)
