import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'

const UploadFlyerShape = (props) => {

  const [ canSave, setCanSave ] = useState(false)
  const [ file, setFile ] = useState(null)
  const [ isSaving, setIsSaving ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ isError, setIsError ] = useState(false)

  const openModal = () => {
    setErrorMessage('')
    setIsError(false)
    props.setOpenModal()
  }

  const clearOldSave = () => {
    setCanSave(false)
    setIsSaving(false)
  }

  const onSave = () => {
    const formData = new FormData()
    formData.append('new_shape', file)

    setIsSaving(true)
    fetch(`/leagues/${props.leagueId}/flyer_shapes`, {
      method: 'POST',
      body: formData,
    })
    .then(response => response.json())
    .then(data => {
      const newShapes = [ ...props.shapes, {'id': data['id'], 'url': data['file']['url']} ]
      props.setShapes(newShapes)

      clearOldSave()
      props.setOpenModal()
    })
    .catch(error => {
      setErrorMessage(window.I18n.t('views.golfhub.flyer_upload_error.failed_upload'))
      setIsError(true)

      clearOldSave()
    })
  }
  
  const handleImageUpload = (e) => {
    const file = e.target.files[0]

    let message = ''
    if (file.size > 6000000) { // bytes
      message = window.I18n.t('views.golfhub.flyer_upload_error.exceeds_maximum_size')
    }
    
    if ([ 'image/svg+xml' ].indexOf(file.type) < 0) {
      message += ` ${window.I18n.t('views.golfhub.flyer_upload_error.file_format_shapes')}`
    }

    if (message === '') {
      setCanSave(true)
      setFile(file)
      setErrorMessage('')
      setIsError(false)
    } else {
      setCanSave(false)
      setErrorMessage(message)
      setIsError(true)
    }
  }

  return (
    <div className="modal">
      <Modal className='modal-golfhub' show={props.openModal} onHide={() => props.setOpenModal()}>
        <Modal.Header className='modal-header-golfhub'>
          <Modal.Title>
          { window.I18n.t('views.golfhub.add_image') }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-golfhub'>
          <input className='add-margin-bottom-10' type='file' accept='image/svg+xml' onChange={(e) => handleImageUpload(e)}/>
          {isError && 
            <div className="error-upload">
              { errorMessage }
            </div>
          }
          <div>{window.I18n.t('views.golfhub.accepted_shape_format')}</div>
        </Modal.Body>
        <Modal.Footer className='footer modal-footer-golfhub'>
          <Button onClick={() => openModal()} className = 'cancel-button'>Cancel</Button>
          <Button onClick={() => onSave()} disabled={!canSave} className='button btsp_orange_button save-button'>
            {isSaving ? window.I18n.t('views.golfhub.saving_asset') : window.I18n.t('views.golfhub.upload_asset')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

UploadFlyerShape.propTypes = {
  leagueId: PropTypes.string,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  shapes: PropTypes.array,
  setShapes: PropTypes.func,
}

export default UploadFlyerShape
