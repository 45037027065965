const modulo = (n, m) => ((n % m) + m) % m

// returns [ hour, AM/PM ] or empty strings
export const computeHour = (hour) => {
  if (!hour) { return [ '', '' ] }

  hour = modulo(parseInt(hour) || 0, 24)
  let amPm = null

  if (hour > 12) {
    hour -= 12
    amPm = 'PM'
  } else {
    amPm = 'AM'
  }

  return [ '' + hour, amPm ]
}

// returns minutes as string 00..59 or empty string
export const computeMinutes = (minutes) => {
  if (!minutes) { return '' }

  minutes = parseInt(minutes) || 0
  return ('0' + Math.max(0, Math.min(minutes, 59))).slice(-2)
}
