import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RadioGroup from './event_setup/radio_group'
import CheckboxOptions from './event_setup/checkbox_options'
import { GlgSelect, GlgTooltipIcon } from 'SharedComponents'


class EventSetupScoring extends Component {
  constructor(props){
    super(props)
  }

  componentDidUpdate(prevProps) {
    if (this.props.content.data !== prevProps.content.data && this.props.content.dataChanged) {
      this.props.save()
    }
  }

  render() {
    const { title, data } = this.props.content
    const setter = (keys) => (value) => this.props.setData([ 'data' ].concat(keys), value)
    const partialSetter = (origKeys) => (keys) => setter(origKeys.concat(keys))

    return (
      <div className='widget-content-default widget-event-setup-scoring'>
        <div className='title'>
          { title }
        </div>

        <div className='content flex-list-layout'>
          <ul>
            <li key='tie_breaking'>
              <RadioGroup
                id='tie_breaking'
                {...data['tie_breaking']}
                setOption={setter([ 'tie_breaking', 'selected' ])}
                vertical={true}
              />
            </li>

            {
              data['stableford_quota'] &&
                <li key='stableford_quota'>
                  <CheckboxOptions
                    {...data['stableford_quota']}
                    setOption={partialSetter([ 'stableford_quota' ])}
                  />
                </li>
            }

            <div key='display_options'>
              <li key='misc_options'>
                <CheckboxOptions
                  {...data['display_options']['misc_options']}
                  setOption={partialSetter([ 'display_options', 'misc_options' ])}
                  customizedOptions={{
                    country_flags: 'country_flags' in data.display_options.misc_options.options && (
                      <span className='inline-display-options'>
                        { data.display_options.misc_options.options.country_flags.label_parts.display }
                        <span>
                          <select
                            className='form-control'
                            onChange={e => setter([ 'display_options', 'misc_options', 'options', 'country_flags', 'display_leaderboard_options', 'selected' ])(e.target.value)}
                            defaultValue={ data.display_options.misc_options.options.country_flags.display_leaderboard_options.selected }>
                            {
                              data.display_options.misc_options.options.country_flags.display_leaderboard_options.options.map(option =>
                                <option key={option.value} value={option.value}>{option.label}</option>
                              )
                            }
                          </select>
                        </span>
                        { data.display_options.misc_options.options.country_flags.label_parts.on_the_leaderboard }
                      </span>
                    ),
                  }}
                />
              </li>

              {
                data['display_options']['hole_by_hole_view'] &&
                  <li key='hole_by_hole_view'>
                    <RadioGroup
                      id='hole_by_hole_view'
                      vertical={true}
                      {...data['display_options']['hole_by_hole_view']}
                      setOption={setter([ 'display_options', 'hole_by_hole_view', 'selected' ])}
                    />
                  </li>
              }

              {
                data['display_options']['player_name_format'] &&
                  <li key='player_name_format'>
                    <RadioGroup
                      id='player_name_format'
                      vertical={true}
                      {...data['display_options']['player_name_format']}
                      setOption={setter([ 'display_options', 'player_name_format', 'selected' ])}
                    />
                  </li>
              }

              <li key='holes_required'>
                <label>{ data.holes_required.label }</label>
                <div className='holes-required-container'>
                  <GlgSelect
                    items={ data.holes_required.options }
                    value={ data.holes_required.value }
                    onChange={ setter([ 'holes_required', 'value' ])}
                    usePortal={ true }
                    menuShouldBlockScroll={ true }
                  />
                  {
                    data['confirmation_live_scoring'] &&
                      <CheckboxOptions
                        {...data['confirmation_live_scoring']}
                        setOption={partialSetter([ 'confirmation_live_scoring' ])}
                        customizedOptions={{
                          'variable_settings_confirmation_after_9':
                          (({label, tooltip}) =>
                            <span>
                              {label}
                              <GlgTooltipIcon tooltip={ tooltip } />
                            </span>
                          )(data.confirmation_live_scoring.options.variable_settings_confirmation_after_9),
                        }}
                      />
                  }
                </div>
              </li>
            </div>
          </ul>
        </div>

      </div>
    )
  }
}

EventSetupScoring.propTypes = {
  content: PropTypes.object,
  setData: PropTypes.func,
  save: PropTypes.func,
}

EventSetupScoring.defaultProps = {
  content: {
    title: 'SCORING',
    data: {},
  },
}

export default EventSetupScoring
