import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import GlgTabs from '../../shared/components/glg_tabs'
import DrivingDistancesComponent from '../containers/driving_distances_container'

class DrivingDistancesTabComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Fragment>
        {
          this.props.drivingStations &&
          <GlgTabs className={'add-margin-top-10'}>
            { this.props.drivingStations.map( (station) =>
              <div key={station.id} label={station.name}>
                <DrivingDistancesComponent stationId={station.id}/>
              </div>
              )
            }
          </GlgTabs>
        }
      </Fragment>
    )
  }
}

DrivingDistancesTabComponent.propTypes = {
  drivingStations: PropTypes.array,
}

export default DrivingDistancesTabComponent
