import { ActionTypes } from '../actions/index_page'

const {
  REMOVE_MARKETING_CAMPAIGN,
  CHANGE_STATUS,
} = ActionTypes

function indexPage(state = {}, action) {
  switch (action.type){
    case REMOVE_MARKETING_CAMPAIGN:
      return {
        ...state,
        marketingCampaigns: state.marketingCampaigns.filter( campaign => {
          return campaign.id !== action.marketingCampaignId
        }),
      }
    case CHANGE_STATUS:
      return {
        ...state,
        marketingCampaigns: state.marketingCampaigns.map( campaign => {
          if (campaign.id === action.marketingCampaignId) {
            campaign.status = action.newStatus
          }
          return campaign
        }),
      }
    default:
      return state
  }

}

export default indexPage
