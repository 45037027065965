/*
 * Returns an array of leagues in the format needed by the leagues table.
 * It computes the list of leagues either from passed leagueIds or from state and props.
 */
import React from 'react'
import _ from 'lodash'
import createDeepSelector from './create_deep_selector'
import { getSortedVisibleLeagueIds, getLeaguesFromIds } from './index'
import { formatDate, formatDateTime } from 'Shared/helpers'
import { computeRegistrationMessage } from '../column_helpers'

const getSeasons = state => state.seasons
const getTours = state => state.tours
const getCategories = (state) => (
  _.mapValues(state.categories, category => (
    _.pick(category, [ 'specialStatus', 'name' ]))
  )
)

const getLeagues = (state, props, leagueIds) => {
  if (!leagueIds) {
    leagueIds = getSortedVisibleLeagueIds(state, props.categoryId)
  }
  return getLeaguesFromIds(state.leagues, leagueIds)
}

export const computeGolfersStatus = (league) => {
  const content = _.toPairs(league.golfers)
    .filter(data => data[1])
    .map((data, index) => `${index > 0 ? ' • ' : ''}${ data[1] } ${ data[0] }`)

  if (league.cancellationRequests && league.canManage){
    content.push(' • ')
    content.push(<a href={ league.cancellationRequests.link }>{ league.cancellationRequests.requests}</a>)
  }

  return content
}

const getFormattedLeagues = createDeepSelector(
  [ getLeagues, getSeasons, getTours, getCategories ],
  (leagues, seasons, tours, categories) => {
    return leagues.map(league => {
      const season = seasons[league.season]
      const seasonName = season ? season.name : ''
      const tour = tours[league.tour]
      const tourName = tour ? tour.name : ''
      const categoryId = league.category
      const categoryStatus = _.get(categories[categoryId], 'specialStatus')
      const categoryName = _.get(categories[categoryId], 'name')
      const registrationStartsOn = _.get(league.openRegistrationData, 'registrationStartsOn')
      const invitationListRegistrationStartsOn = _.get(league.openRegistrationData, 'registrationInvitationListStartsOn')
      const staggeredRegistrationStartsOn = _.get(league.openRegistrationData, 'membershipProgramsInfo[0].startDate')
      const anyRegistrationStartsOn = registrationStartsOn || invitationListRegistrationStartsOn || staggeredRegistrationStartsOn
      const registrationEndsOn = _.get(league.openRegistrationData, 'registrationEndsOn')
      return {
        ...league,
        categoryId,
        categoryStatus,
        categoryName,
        seasonId: league.season,
        seasonName,
        tourId: league.tour,
        tourName,
        golfers: computeGolfersStatus(league),
        startDate: formatDate(league.startDate),
        endDate: formatDate(league.endDate),
        registrationStartDate: formatDateTime(anyRegistrationStartsOn, league.timeZone),
        registrationCloseDate: formatDateTime(registrationEndsOn, league.timeZone),
        registrationMessage: computeRegistrationMessage(league),
      }
    })
  }
)

export default getFormattedLeagues
