import { connect } from 'react-redux'
import _ from 'lodash'

import AdminNavigatorOptionsComponent from '../components/admin_navigator_options_component'

import {
  openPopup,
  deleteOption,
} from '../actions'

const mapStateToProps = (state) => {
  let navigatorOptions = _.values(state.navigatorOptions)
  navigatorOptions = _.sortBy(navigatorOptions, [ 'context', 'name' ])

  return {
    navigatorOptions,
    creatingNavigatorOption: state.misc.creatingNavigatorOption,
  }
}

const mapDispatchToProps = (dispatch) => ({
  openPopup: (id, data) => dispatch(openPopup(id, data)),
  onOptionDelete: id => dispatch(deleteOption(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminNavigatorOptionsComponent)
