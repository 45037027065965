import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import EventStatusFilterTypes from '../event_status_filter_types'
import { formatDate } from 'Shared/helpers'

class FiltersLabelComponent extends Component {

  render() {
    const OPTIONS = [
      {
        value: EventStatusFilterTypes.REGISTERED,
        label: !window.I18n ? '' : window.I18n.t('customer_directories.filters.labels.my_registration'),
        checked: this.props.filters.status.includes(EventStatusFilterTypes.REGISTERED) && this.props.myRegistrationsVisible,
      },
      {
        value: EventStatusFilterTypes.OPEN,
        label: this.props.myRegistrationsVisible && this.props.filters.status.includes(EventStatusFilterTypes.REGISTERED) ? !window.I18n ? '' : window.I18n.t('customer_directories.filters.labels.registration_open_downcase') : !window.I18n ? '' : window.I18n.t('customer_directories.filters.labels.registration_open'),
        checked: this.props.filters.status.includes(EventStatusFilterTypes.OPEN),
      },
      {
        value: EventStatusFilterTypes.PAST,
        label: !window.I18n ? '' : window.I18n.t('customer_directories.filters.labels.past_events'),
        checked: this.props.filters.status.includes(EventStatusFilterTypes.PAST),
      },
      {
        value: EventStatusFilterTypes.UPCOMING,
        label: !window.I18n ? '' : window.I18n.t('customer_directories.filters.labels.upcoming_events'),
        checked: this.props.filters.status.includes(EventStatusFilterTypes.UPCOMING),
      },
    ]

    if (this.props.shouldShowLotteryFilter) {
      OPTIONS.push(
        {
          value: EventStatusFilterTypes.LOTTERY,
          label: !window.I18n ? '' : window.I18n.t('customer_directories.filters.event_status_options.lottery_events'),
          checked: this.props.filters.status.includes(EventStatusFilterTypes.LOTTERY),
        }
      )
    }

    const eventStatus = []
    OPTIONS.forEach( (item) => {
      if (item.checked) {
        eventStatus.push(item.label)
      }
    })
    let eventStatusLabel = eventStatus
    if (eventStatus.length > 1) {
      eventStatusLabel = eventStatus.slice(0, -1).join(', ') + ` ${!window.I18n ? '' : window.I18n.t('customer_directories.filters.labels.and')} ` + eventStatus.slice(-1)
    } 

    eventStatusLabel = `${eventStatusLabel}`

    let datesLabel = ', '

    if (this.props.filters.startDate !== '' && this.props.filters.endDate === '') {
      datesLabel = `${ !window.I18n ? '' : window.I18n.t('customer_directories.filters.labels.after_date', { date: formatDate(this.props.filters.startDate) }) }`
    } else if (this.props.filters.startDate === '' && this.props.filters.endDate !== ''){
      datesLabel = `${ !window.I18n ? '' : window.I18n.t('customer_directories.filters.labels.before_date', { date: formatDate(this.props.filters.endDate)} ) }`
    } else if (this.props.filters.startDate !== '' && this.props.filters.endDate !== '') {
      datesLabel = `${ !window.I18n ? '' : window.I18n.t('customer_directories.filters.labels.between_dates', { date1: formatDate(this.props.filters.startDate), date2: formatDate(this.props.filters.endDate)}) }`
    }
    if (datesLabel === ', ') {
      datesLabel = ''
    }
    let locationLabel = ''

    if (this.props.filters.location.coords.lat) {
      locationLabel = `${ !window.I18n ? '' : window.I18n.t('customer_directories.filters.labels.within_radius_location', { radius: this.props.filters.location.radius, city: this.props.filters.location.city }) }`
    }
    let filtersLabel = `${[ eventStatusLabel, datesLabel, locationLabel ].filter(Boolean).join(' ● ') + '.'}`
    filtersLabel = filtersLabel.charAt(0).toUpperCase() + filtersLabel.slice(1)
    return <Fragment>{!this.props.dataIsLoading && !this.props.filters.advancedFiltersOn && <div className="text-center"><div className="bold">{ !window.I18n ? '' : window.I18n.t('customer_directories.filters.active_filters') }</div><p className="light-grey">{filtersLabel}</p></div>}</Fragment>
  }
}

FiltersLabelComponent.propTypes = {
  dataIsLoading: PropTypes.bool,
  filters: PropTypes.shape({
    status: PropTypes.array,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    location: PropTypes.obj,
    advancedFiltersOn: PropTypes.bool,
  }),
  myRegistrationsVisible: PropTypes.bool,
  shouldShowLotteryFilter: PropTypes.bool,

}
export default FiltersLabelComponent
