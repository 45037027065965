import { connect } from 'react-redux'
import Page from '../presentational/page'

const mapStateToProps = state => ({
  activeTab: state.filters.active_tab,
  tabs: state.tabs,
  blocks: state.blocks,
  displayMode: state.filters.display_mode,
  usesTranslations: state.filters.uses_translations,
  availableLanguages: state.filters.available_languages,
  currentLanguage: state.filters.current_language,
})

export default connect(
  mapStateToProps,
)(Page)
