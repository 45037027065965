import { connect } from 'react-redux'
import MarketingCampaignsIndexComponent from '../components/marketing_campaigns_index_component'

import {
  removeMarketingCampaign,
  changeStatus,
} from '../actions/index_page'

const mapStateToProps = (state) => {
  return {
    marketingCampaigns: state.marketingCampaigns,
  }
}

const mapDispatchToProps = (dispatch) => ({
  removeMarketingCampaign: (marketingCampaignId) => { dispatch(removeMarketingCampaign(marketingCampaignId)) },
  changeStatusOnState: (marketingCampaignId, newStatus) => { dispatch(changeStatus(marketingCampaignId, newStatus)) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MarketingCampaignsIndexComponent)
