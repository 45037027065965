import React, { Component } from 'react'
import PropTypes from 'prop-types'
import YouTubeVideo from '../youtube_component'
import { computeHeight } from '../../helpers'
class VideoComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      player: undefined,
    }
    this.onVideoEnd = this.onVideoEnd.bind(this)
  }

  componentDidMount() {
    const { slide, setPlaceholderValues } = this.props
    if (slide.placeholderValues && setPlaceholderValues) {
      setPlaceholderValues(slide.placeholderValues)
    }
  }

  componentDidUpdate(prevProps) {
    const { player } = this.state
    if ((prevProps.resetVideo !== this.props.resetVideo) && this.props.resetVideo) {
      if (player) {
        player.playVideo()
      }
    }
  }

  onVideoEnd() {
    this.props.moveToNextSlide()
  }

  render() {
    const { slide, blueBox, tvShowSettings, advancedSlideHeaderHeight } = this.props  
    return <div style={{
          height: `calc(100vh - ${ computeHeight(tvShowSettings, blueBox, advancedSlideHeaderHeight) }px)`,
          width: '100%',
        }}><YouTubeVideo id={ slide.videoId } autoPlay={ true } onVideoEnd={ this.onVideoEnd } setPlayer={ (player) => this.setState({ player }) }></YouTubeVideo></div>
  }
}

VideoComponent.propTypes = {
  isFireTv: PropTypes.bool,
  slide: PropTypes.object.isRequired,
  blueBox: PropTypes.object,
  currentSlideIndex: PropTypes.number.isRequired,
  moveToNextSlide: PropTypes.func.isRequired,
  setPlaceholderValues: PropTypes.func,
  placeholderValues: PropTypes.object,
  hasUSGATheme: PropTypes.bool,
  tvShowSettings: PropTypes.object,
  advancedSlideHeaderHeight: PropTypes.number,
  resetVideo: PropTypes.bool,
}

VideoComponent.defaultProps = {
  isFireTv: false,
  hasUSGATheme: false,
}

export default VideoComponent
