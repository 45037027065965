import { combineReducers } from 'redux'
import memberCards from './member_cards'
import members from './members'
import misc from './misc'
import filters from './filters'
import sticky from './sticky'

export default combineReducers({
  memberCards,
  members,
  misc,
  filters,
  sticky,
})
