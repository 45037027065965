import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Row, Col } from 'react-flexbox-grid'

const LastVisitedLeagueComponent = ({ leagueName, leagueProduct, url }) => <Row><Col xs={true}>{url && <Fragment>Last Visited { _.capitalize(leagueProduct) }: <a href={ url }>{ leagueName }</a></Fragment>}</Col></Row>

LastVisitedLeagueComponent.propTypes = {
  leagueName: PropTypes.string.isRequired,
  leagueProduct: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default LastVisitedLeagueComponent
