import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { GlgPopup } from '../../../../shared/components'

class AdjustHandicap extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount() {
    $('.new-content-selectpicker').selectpicker()
  }

  componentDidUpdate() {
    setTimeout(
      () => {
        $('.new-content-selectpicker').selectpicker('destroy')
        $('.new-content-selectpicker').selectpicker('refresh')
        window.redo_qtip()
      },
      100
    )
  }


  render() {
    const { visible, searchTerm, leagueLinks, roundLinks, selectedRound } = this.props.newItems
    const rounds = this.props.rounds

    return (
      <GlgPopup title={ !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.popups.new_items.create_new') }
                closeButtonText={ !window.I18n ? '' : window.I18n.t('buttons.close').charAt(0)
                  .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.close').slice(1)) }
                show={ visible }
                onClose={ this.props.onClose }
                showSaveButton={ false } >
        <div className='new-items-modal-content'>
          {
            leagueLinks.length === 0
              ? <div className='new-items-preloader-container'>
                <div className='new-items-preloader'></div>
              </div>
              : <Fragment>
                {/* <span className='input-group'>
                  <input className='form-control'
                        autoComplete='false'
                        placeholder={ searchTerm.placeholder }
                        value={ searchTerm.value }
                        onChange={ event => this.props.newItemsSearch(event.target.value) } />

                  <span className='input-group-btn'>
                    <button className='btn btn-default' type='button' onClick={ () => this.props.newItemsSearch('') }>
                      <i className='fa fa-eraser'></i>
                    </button>
                  </span>
                </span> */}
                <div className={ `new-items-modal-content-league-links ${ rounds.length > 0 ? 'separator' : ''}` }>
                  {
                    leagueLinks.map(link => 
                      link.name.toLowerCase().includes(searchTerm.value.toLowerCase()) && link.show
                        ? <span className='new-item-link-container' key={ `${link.path}-${link.name}` }>
                          <a href={ link.enabled ? link.path : 'javascript:void(0);' }
                            target='_self' // { link.enabled ? '_blank' : '_self' }
                            title={ link.enabled ? '' : link.tooltip }
                            className={ `${link.enabled ? '' : 'new-item-link-disabled'}` }>
                            { link.name }
                          </a>
                        </span>
                        : null
                    )
                  }
                </div>
                {
                  rounds.length > 0
                    ? <Fragment>
                      <div className='new-items-modal-content-rounds-select'>
                        {
                          rounds.length === 1
                            ? <h6 className='title'>{ rounds[0].label }</h6>
                            : <div className='select-handicap'>
                                <select className='selectpicker new-content-selectpicker'
                                        onChange={ event => this.props.newItemsSelectRound(event.target.value) }>
                                {
                                  rounds.map(round =>
                                    <option key={ round.id }
                                            value={ round.id }
                                            selected={ round.id === (rounds.map(round => round.id).includes(selectedRound) ? selectedRound : `${rounds[0].id}` ) ? 'selected' : '' }>
                                      { round.label }
                                    </option> )
                                }
                              </select>
                            </div>
                      }
                    </div>
                    <div className='new-items-modal-content-rounds-links'>
                      {
                        Object.entries(roundLinks).map(links =>
                            links[0] === (rounds.map(round => round.id).includes(selectedRound) ? selectedRound : `${rounds[0].id}` )
                            ? links[1].map(link =>
                              link.name.toLowerCase().includes(searchTerm.value.toLowerCase()) && link.show
                                ? <span key={ `${link.path}-${link.name}` } className='new-item-link-container'>
                                  <a href={ link.enabled ? link.path : 'javascript:void(0);' }
                                    target={ link.enabled ? '_blank' : '_self' }
                                    title={ link.enabled ? '' : link.tooltip }
                                    className={ `new-item-link ${link.enabled ? '' : 'new-item-link-disabled'}` }>
                                      { link.name }
                                  </a>
                                </span>
                                : null)
                            : null
                        )
                      }
                    </div>
                  </Fragment>
                  : null
                }
              </Fragment>
          }
        </div>
      </GlgPopup>
    )
  }
}

AdjustHandicap.propTypes = {
  onClose: PropTypes.func,
  newItems: PropTypes.object,
  newItemsSearch: PropTypes.func,
  newItemsSelectRound: PropTypes.func,
  rounds: PropTypes.array,
}

export default AdjustHandicap
