import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { scoreClass } from '../helpers'

class HoleDataComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { playerName, foursomePosition, holeNumber, holeData, openPopup, par, scoreVerified, holeView } = this.props
    if ( holeData === undefined ) {
      return <td></td>
    } else if ( holeData.completed ) {
      return <td className={`${scoreClass(holeData.score, par, holeView)} cell`} onClick={() => openPopup(playerName, foursomePosition, holeData.hole_id, holeNumber, holeData.shots_summary, true, par, scoreVerified)}><span><a>{holeData.score}</a></span></td>
    } else {
      return <td className="cell" onClick={() => openPopup(playerName, foursomePosition, holeData.hole_id, holeNumber, holeData.shots_summary, false, par, scoreVerified)}><a>{holeData.shots_display}</a></td>
    }
  }
}

HoleDataComponent.propTypes = {
  playerName: PropTypes.string,
  foursomePosition: PropTypes.number,
  holeNumber: PropTypes.number,
  holeData: PropTypes.object,
  openPopup: PropTypes.func,
  par: PropTypes.number,
  holeView: PropTypes.string,
  scoreVerified: PropTypes.bool,
}

export default HoleDataComponent
