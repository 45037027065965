import { connect } from 'react-redux'
import EditWidgets from '../presentational/edit_widgets'
import { setActiveBlock, setActivePosition, setIframeFormState, displayMode, DISPLAY_MODES } from '../../actions'

const flatten = input => {
  const stack = [ ...input ]
  const res = []
  while (stack.length) {
    // pop value from stack
    const next = stack.pop()
    if (Array.isArray(next)) {
      // push back array items, won't modify the original input
      stack.push(...next)
    } else {
      res.push(next)
    }
  }
  //reverse to restore input order
  return res.reverse()
}

const mapStateToProps = state => ({
  URL: flatten(state.filters.widgets.map(section => section.pages))
  .find(page =>
    page.widget_id === state.columns.find(column => column.id === state.filters.active_column).widget_id
  ).edit,
  PreviwUrl: flatten(state.filters.widgets.map(section => section.pages))
  .find(page =>
    page.widget_id === state.columns.find(column => column.id === state.filters.active_column).widget_id
  ).url,
  iframeFormStatus: state.filters.form_submitted,
})

const mapDispatchToProps = dispatch => ({
  cancel: () => {
    dispatch(setIframeFormState(null))
    dispatch(setActivePosition(null))
    dispatch(setActiveBlock(null))
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },

  reloadIframe: (URL) => {
    $(`iframe[src*='${URL}']`)[0].contentWindow.location.reload(true)
  },

  setIframeFormState: (_state) => dispatch(setIframeFormState(_state)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditWidgets)
