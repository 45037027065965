import { connect } from 'react-redux'
import ChannelsComponent from '../components/channels_component'

import {
  toggleChannel,
  setChannelTemplate,
  setChannelAllTemplates,
} from '../actions/setup'

const mapStateToProps = (state) => {
  
  return {
    channels: state.channels,
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleChannel: (channel, defaultValue) => () => { dispatch(toggleChannel(channel, defaultValue)) },
  setChannelTemplate: (channel) => (template) => { dispatch(setChannelTemplate(channel, template)) },
  setChannelAllTemplates: (channel, templates) => { dispatch(setChannelAllTemplates(channel, templates)) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChannelsComponent)
