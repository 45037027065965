import React from 'react'
import PropTypes from 'prop-types'

import MoneyEarnedInformationComponent from './money_earned_information_component'

import {
  GlgTableRow,
  GlgTableCell,
} from 'SharedComponents'

const ParticipationHistoryTableRow = ({ ...props }) => {
  let date = props.starts
  if (props.ends !== '') {
    date += ' - ' + props.ends
  }
  let eventName = ''
  if (props.eventUrl){
    eventName = <a href={ props.eventUrl } target={ props.target }>  { props.name }</a>
  } else {
    eventName = props.name
  }

  let money = ''
  if (props.moneyEarnedEnabled){
    const handlePopupHide = () => props.onPopupOpen(false, '')

    const isDataLoading = props.areLoadingEventsIds.some(eventId => eventId === props.id)
    const isPopupActive = props.isOpenedEventPopup && props.currentlyOpenedEventPopup === props.id

    const handlePopupShow = () => {
      props.onPopupOpen(true, props.id)
      window.parent.scrollTo(0, 0)
      props.fetchMoneyEarnedInformation(props.id)
    }

    const loadedEventIds = Object.keys(props.loadedEvents)
    const tournaments = (loadedEventIds.some(eventId => eventId === props.id)) ? props.loadedEvents[props.id] : []

    if (props.hasEarnedMoney){
      money = <div className="money_information"><a onClick={ handlePopupShow }>{ props.moneyEarned }</a><MoneyEarnedInformationComponent dataIsLoading={ isDataLoading } showPopup={ isPopupActive } eventTitle={ props.name } onClosePopup={ handlePopupHide } tournaments={ tournaments }></MoneyEarnedInformationComponent></div>
    } else {
      money = props.moneyEarned
    }
  }

  return <GlgTableRow { ...props } isSortable={ false }><GlgTableCell>{ eventName }</GlgTableCell><GlgTableCell><div className="season_label label label-success">{ props.season }</div></GlgTableCell><GlgTableCell>{ date }</GlgTableCell>{ props.moneyEarnedEnabled ? <GlgTableCell>{ money }</GlgTableCell> : (props.cancelRegistrationUrl) ? <GlgTableCell><a href={ props.cancelRegistrationUrl } target={'_top'} className="btn btn-xs btn-block btn-default">  View Registration</a></GlgTableCell> : <GlgTableCell><a className="hidden">View Registration</a></GlgTableCell>
      }</GlgTableRow>
}

ParticipationHistoryTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  season: PropTypes.string,
  seasonId: PropTypes.string,
  eventUrl: PropTypes.string,
  starts: PropTypes.string.isRequired,
  ends: PropTypes.string.isRequired,
  hasEarnedMoney: PropTypes.bool,
  moneyEarned: PropTypes.string,
  moneyEarnedEnabled: PropTypes.bool.isRequired,
  target: PropTypes.string,
  areLoadingEventsIds: PropTypes.array,
  loadedEvents: PropTypes.object,
  fetchMoneyEarnedInformation: PropTypes.func,
  isOpenedEventPopup: PropTypes.bool,
  currentlyOpenedEventPopup: PropTypes.string,
  onPopupOpen: PropTypes.func,
  cancelRegistrationUrl: PropTypes.string,
}

export default ParticipationHistoryTableRow
