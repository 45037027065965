import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { areChildrenEqual } from '../helpers'

class GlgLabel extends Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.type !== nextProps.type) {
      return true
    }
    if (!areChildrenEqual(this.props.children, nextProps.children)) {
      return true
    }

    return false
  }

  getTypeClass() {
    return `label-${ this.props.type }`
  }

  getLabelClass() {
    let labelClass = this.props.className
    if (this.props.children) {
      labelClass += ` glg-label label ${ this.getTypeClass() }`
    }
    return labelClass
  }

  render() {
    return <div className={ this.getLabelClass() }><div>{ this.props.children }</div></div>
  }
}

GlgLabel.propTypes = {
  type: PropTypes.oneOf([ 'primary', 'success', 'info', 'warning', 'danger', 'default' ]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

GlgLabel.defaultProps = {
  type: 'default',
  className: '',
}

export default GlgLabel
