import _ from 'lodash'

import { ActionTypes } from '../actions'
import { reorderListPositions } from 'Shared/list_helpers'

const {
  FETCH_INITIAL_DATA_RECEIVED,
  OPEN_CATEGORY,
  CLOSE_CATEGORY,
  TOGGLE_CATEGORY,
  SET_CATEGORY_LEAGUE_COUNTS,
  SET_CATEGORY_LOADING_DATA,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  CREATE_CATEGORY,
  CREATE_CATEGORY_RESPONSE,
  REORDER_CATEGORIES,
  DELETE_LEAGUE,
  CHANGE_LEAGUE_CATEGORY,
  REORDER_LEAGUES,
} = ActionTypes

const DEFAULT_CATEGORY_DATA = {
  id: null,
  name: null,
  position: null,
  color: '#333333',
  specialStatus: null,
  isFree: false,
  opened: false,
  leagues: [],
  updateURL: null,
}

const changeLeagueCategory = (state, leagueId, fromCategoryId, toCategoryId) => {
  if (fromCategoryId !== toCategoryId) {
    return {
      ...state,
      [fromCategoryId]: {
        ...state[fromCategoryId],
        leagues: _.without(state[fromCategoryId].leagues, leagueId),
      },
      [toCategoryId]: {
        ...state[toCategoryId],
        leagues: [
          ...state[toCategoryId].leagues,
          leagueId,
        ],
      },
    }
  } else {
    return state
  }
}

const categories = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INITIAL_DATA_RECEIVED: {
      const receivedCategories = _.mapValues(action.data.categories, category => ({
        ...category,
        color: category.color || '#333333',
        opened: false,
      }))
      return {
        ...state,
        ...receivedCategories,
      }
    }
    case OPEN_CATEGORY:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          opened: true,
        },
      }
    case CLOSE_CATEGORY:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          opened: false,
        },
      }
    case TOGGLE_CATEGORY: {
      const tmpCategories = _.mapValues(state, category => ({
        ...category,
        opened: (category.id === action.id ? !category.opened : false),
        loadingData: false,
      }))

      return tmpCategories
    }
    case SET_CATEGORY_LEAGUE_COUNTS: {
      const tmpCategories = _.mapValues(state, category => ({
        ...category,
        leaguesCount: isNaN(action.categoryCounts[`${category.id}`]) ? category.leaguesCount : action.categoryCounts[`${category.id}`],
      }))

      return tmpCategories
    }
    case SET_CATEGORY_LOADING_DATA:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          loadingData: action.loadingData,
        },
      }
    case DELETE_CATEGORY: {
      const leagues = state[action.id].leagues
      const newState = _.omit(state, action.id)
      newState[action.defaultCategoryId].leagues.push(...leagues)
      return newState
    }
    case UPDATE_CATEGORY:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          ...action.data,
        },
      }
    case CREATE_CATEGORY: {
      const newState = _.mapValues(state, category => ({
        ...category,
        position: category.position + 1,
      }))
      newState[action.id] = {
        ...DEFAULT_CATEGORY_DATA,
        ...action.data,
      }
      return newState
    }
    case CREATE_CATEGORY_RESPONSE: {
      const newState = _.omit(state, action.oldId)
      if (action.newId) {
        const category = {
          ...state[action.oldId],
          id: action.newId,
          updateURL: action.updateURL,
        }
        newState[action.newId] = category
      }
      return newState
    }
    case REORDER_CATEGORIES: {
      const oldPosition = state[action.fromId].position
      const newPosition = state[action.toId].position
      const categories = _.values(state)
      let updatedCategories = reorderListPositions(categories, oldPosition, newPosition)
      updatedCategories = _.keyBy(updatedCategories, 'id')
      return {
        ...state,
        ...updatedCategories,
      }
    }
    case DELETE_LEAGUE:
      return {
        ...state,
        [action.categoryId]: {
          ...state[action.categoryId],
          leagues: _.without(state[action.categoryId].leagues, action.id),
        },
      }
    case CHANGE_LEAGUE_CATEGORY:
      return changeLeagueCategory(state, action.leagueId, action.fromCategoryId, action.toCategoryId)
    case REORDER_LEAGUES:
      if (action.fromCategoryId !== action.toCategoryId) {
        return changeLeagueCategory(state, action.fromId, action.fromCategoryId, action.toCategoryId)
      } else {
        return state
      }
    default:
      return state
  }
}

export default categories
