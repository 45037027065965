import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { FormGroup } from 'react-bootstrap'
import {
  GlgPopup,
  GlgPopupForm,
} from '../../../../shared/components'

class AdjustHandicap extends Component {
  constructor(props){
    super(props)
  }

  render() {
    return (
      <GlgPopup id='adjust-handicap'
                title={ this.props.formData.title }
                saveButtonText= { !window.I18n ? '' : window.I18n.t('buttons.save').charAt(0)
                  .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.save').slice(1)) }
                saveDisableWith={ !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.popups.adjust_handicap.creating') }
                closeButtonText={ this.props.formData.clear_adjustment_path.name }
                closeButtonClass='hidden'
                show={ this.props.showModal }
                onSave={ () => this.props.onSave() }
                // formURL={ this.props.formURL }
                onClose={ () => this.props.onClose() }
                saveButtonEnabled={ true }
                modalClass='set-flash-message'>
        <GlgPopupForm className='flash-manager margin_top_10 margin_bottom_10 well white_background' id='adjust-handicap-form'>
            <FormGroup className='add-margin-top-5'>
                <label>{ this.props.formData.label }</label>
                <br />
                <input name='ch'
                       type='text'
                       placeholder=''
                       className='form-control'
                       onChange={ event => this.props.setAdjustHandicapFormData(this.props.formData.golfer, event.target.value, this.props.formData.player_ids, this.props.formData.adjustment_path, this.props.formData.clear_adjustment_path) }
                       value={ this.props.formData.handicap } />
            </FormGroup>
        </GlgPopupForm>
      </GlgPopup>
    )
  }
}

AdjustHandicap.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  changeFlashMessageData: PropTypes.func,
  formURL: PropTypes.string,
  formData: PropTypes.object,
  setAdjustHandicapFormData: PropTypes.func,
}


AdjustHandicap.defaultProps = {
  showModal: false,
}

export default AdjustHandicap
