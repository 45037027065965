import { ActionTypes } from '../actions'

const {
  REFRESH_DATA,
} = ActionTypes

const initialState = {}

const misc = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_DATA:
      return {
        ...state,
        ...action.message,
      }
    default:
      return state
  }
}

export default misc
