import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { displayMode, DISPLAY_MODES } from '../../actions'

const BlocksReorderButton = ({ dispatch, blocks }) => {
  return (
    <div>
      <button type="button"
              onClick={(e) => { e.preventDefault(); dispatch(displayMode(DISPLAY_MODES.BLOCK_REORDER)) } }
              className="manage-tabs-btn"
              disabled={ blocks.length <= 1 }>
        { window.I18n.t('multi_widgets.reorder_blocks') }
      </button>
    </div>
  )
}

BlocksReorderButton.propTypes = {
  dispatch: PropTypes.func,
  blocks: PropTypes.array,
}

export default connect()(BlocksReorderButton)
