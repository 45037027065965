import { connect } from 'react-redux'

import SettingsModal from '../components/settings_modal_component'
import { saveSettings, syncAllMrRecords, relinkAllGolfers, recalculateAll } from '../actions'

const mapStateToProps = (state, props) => {
  return {
    show: props.show,
    possibleCustomFields: state.misc.possibleCustomFields,
    handicapProvider: state.misc.handicapProvider,
    ssoName: state.misc.ssoName,
    mcIdentifierSet: state.misc.mcIdentifierSet,
    handicapNamingRule: state.misc.handicapNamingRule,
    userNamingRule: state.misc.userNamingRule,
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveSettings: (mcIdentifierID, handicapNamingRule, userNamingRule) => dispatch(saveSettings(mcIdentifierID, handicapNamingRule, userNamingRule)),
  syncAllMrRecords: () => dispatch(syncAllMrRecords()),
  relinkAllGolfers: () => dispatch(relinkAllGolfers()),
  recalculateAll: () => dispatch(recalculateAll()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsModal)
