import React, { Component } from 'react'
import PropTypes from 'prop-types'

class EventOverview extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { title, links, courses, logo, banner } = this.props.content

    return (
      <div className='widget-content-default widget-content-event-overview'>
        <div className='title'>
          { title }

          <div className='title-buttons-container'>
            <a href={ links.event_profile.path } className='btn btn-primary widget-white-title-button margin_right_5'>
              { links.event_profile.name }
            </a>
          </div>
        </div>
        <div className='courses'>
          { courses.text }
          {
            courses.list.length >= 1
              ? courses.list.map( course => <a key={ course.path } href={ course.path }>{ course.name }</a> )
              : null
          }
        </div>
        <div className='content'>
          <div className='col-xs-12 no-padding-left no-padding-right add-margin-top-5 add-margin-bottom-5'>
            <div className='polaroid_image' style={ { backgroundImage: 'url(' + banner + ')', backgroundSize: '100%', height: '100px' }}></div>
          </div>
          <div className='col-xs-4 no-padding-left add-margin-top-5'>
            <div className='polaroid_image' style={ { backgroundImage: 'url(' + logo + ')', backgroundSize: '100%', height: '100px' }}></div>
          </div>
          <div className='col-xs-8 buttons-container'>
            <a href={ links.manage_courses.path } className='btn btn-primary widget-white-content-button'>
              { links.manage_courses.name }
            </a>
            <a href={ links.league_assets.path } className='btn btn-primary widget-white-content-button'>
              { links.league_assets.name }
            </a>
          </div>
        </div>
      </div>
    )
  }
}

EventOverview.propTypes = {
  content: PropTypes.object,
}

EventOverview.defaultProps = {
  content: {
    title: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_overview.title'),
    links: {
      event_profile: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_overview.event_profile'),
        path: '#',
      },
      manage_courses: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_overview.manage_courses'),
        path: '#',
      },
      league_assets: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_overview.logos_banners_backgrounds'),
        path: '#',
      },
    },
    courses: {
      text: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_overview.course') + ': -',
      list: [],
    },
    logo: '#',
    banner: '#',
  },
}

export default EventOverview
