import React from 'react'
import PropTypes from 'prop-types'
import {GlgTableRow, GlgTableCell} from 'SharedComponents'

const leaderboardRowComponent = ({columns, addLine, removeLine, ...props}) => {
  return (
    <GlgTableRow { ...props} className={ props.onBoard ? 'on-board-row' : props.className}>
      { columns.map( column => {
          if (column === 'actions') {
            return props['onBoard'] === true ? <GlgTableCell key={column}><a className='force_red' onClick={() => removeLine(props.position, props.front9)}>Hide</a></GlgTableCell> : <GlgTableCell key={column}><a onClick={() => addLine(props.memberIds, props.front9)}>Show</a></GlgTableCell>
          } else {
            if (column === 'total' && props[`${column}`] === '0') {
              return <GlgTableCell key={column}>E</GlgTableCell>
            } else {
              return <GlgTableCell key={column}>{props[`${column}`]}</GlgTableCell>
            }
          }
        })
      }
    </GlgTableRow>
  )
}

leaderboardRowComponent.propTypes = {
  columns: PropTypes.array,
  onBoard: PropTypes.bool,
  className: PropTypes.string,
  addLine: PropTypes.func,
  removeLine: PropTypes.func,
  position: PropTypes.number,
  memberIds: PropTypes.string,
  front9: PropTypes.bool,
}

export default leaderboardRowComponent
