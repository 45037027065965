import React, { Fragment, Component } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import PropTypes from 'prop-types'
import { GlgButton, GlgSwitch } from 'SharedComponents'

class HeaderSectionComponent extends Component {

  render(){
    const {
      isVisible,
      changeViewDisplayed,
      toggleVisible,
    } = this.props

    return <Fragment><Row className="vertical-align-items"><Col xs={ 12 } sm={ 8 } className="no-padding-left no-margin-top">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.header_section_component.customize_header') }</Col><Col xs={ 6 } sm={ 2 } className="no-padding-left"><GlgSwitch text={ [ (!window.I18n ? '' : window.I18n.t('tv_shows.components.header_section_component.visible')), 
                                                       (!window.I18n ? '' : window.I18n.t('tv_shows.components.header_section_component.hidden')) ] } widthInPixels={ 80 } checked={ isVisible } onChange={ toggleVisible } id="header-visible-switch"></GlgSwitch></Col><Col xs={ 6 } sm={ 2 } className="no-padding-left"><GlgButton text={ !window.I18n ? '' : window.I18n.t('buttons.edit') } icon="pencil" color="white" onClick={ () => changeViewDisplayed('headerSettings') }></GlgButton></Col></Row></Fragment>
  }
}

HeaderSectionComponent.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  changeViewDisplayed: PropTypes.func.isRequired,
  toggleVisible: PropTypes.func.isRequired,
}

export default HeaderSectionComponent
