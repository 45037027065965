import { ActionTypes } from '../actions/setup'

const {
  TOGGLE_CHANNEL,
  SET_CHANNEL_TEMPLATE,
  SET_CHANNEL_ALL_TEMPLATES,
} = ActionTypes

const initialState = {
  email: {
    checked: false,
    template: '',
  },
  textMessage: {
    checked: false,
    template: '',
  },
}

function channels(state = initialState, action) {
  switch (action.type){
    case TOGGLE_CHANNEL:
      return {
        ...state,
        [action.channel]: {
          ...state[action.channel],
          checked: !state[action.channel].checked,
          template: !state[action.channel].checked ? action.defaultValue : '',
        },
      }
    case SET_CHANNEL_TEMPLATE:
      return {
        ...state,
        [action.channel]: {
          ...state[action.channel],
          template: action.template,
        },
      }
    case SET_CHANNEL_ALL_TEMPLATES:
      return {
        ...state,
        [action.channel]: {
          ...state[action.channel],
          allTemplates: action.templates,
        },
      }
    default:
      return state
  }
}

export default channels
