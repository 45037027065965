import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Processing extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount(prevProps) {
    if (prevProps === undefined) {
      this.props.reportPollJobStatus(this.props.report.id, this.props.report.job.id, this.props.report.job.type)
    }
  }

  componentDidUpdate() {
    if (this.props.report.job.status === 'running') {
      setTimeout(
        () => this.props.reportPollJobStatus(this.props.report.id, this.props.report.job.id, this.props.report.job.type),
        5000
      )
    }
  }

  render() {
    const report = this.props.report

    return (
      <div className='processing'>
      <img src={ require('bootstrap_theme/job/Spinning-circle.gif') } />
      {
        report.job.status_updates && report.job.status_updates.length > 2
          && <i className="gga-info-circle" title={ report.job.status_updates.join('.\n') }></i>
      }
      </div>
    )
  }
}

Processing.propTypes = {
  report: PropTypes.object,
  reportPollJobStatus: PropTypes.func,
}


Processing.defaultProps = {
  report: {},
}

export default Processing
