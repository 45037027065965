import { connect } from 'react-redux'
import EventSetupDivisions from '../../components/content/event_setup_divisions'
import {
  saveEventSettingsWidget,
  deleteDivisions,
  setGenericData,
  pollJob,
  pollUpdateNotifyMessages,
  reloadWidgetContent,
} from '../../actions/index'
import moment from 'moment'
import { showNotification, showErrorNotification } from 'Shared/helpers'
import React from 'react'

const asHTML = v => <div dangerouslySetInnerHTML={{ __html: v }} />

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => ({
  removeDivision: (divisionId) => {
    const links = ownProps.content.links
    const oldDivisions = ownProps.content.divisions_without_divisionset
    const divisions = ownProps.content.divisions_without_divisionset.filter(division => division.id !== divisionId)
    dispatch(setGenericData(ownProps.widgetId, [ 'divisions_without_divisionset' ], divisions))
    if (!ownProps.content.dataChanged) {
      dispatch(setGenericData(ownProps.widgetId, [ 'dataChanged' ], true))
    }
    dispatch(deleteDivisions(ownProps.widgetId, links.delete_divisions.path, { object_id: divisionId }))
      .catch(() => {
        dispatch(setGenericData(ownProps.widgetId, [ 'divisions_without_divisionset' ], oldDivisions))
        dispatch(setGenericData(ownProps.widgetId, [ 'dataChanged' ], false))
      })
  },
  removeDivisionset: (divisionsetId) => {
    const links = ownProps.content.links
    const oldDivisionsets = ownProps.content.divisionsets
    const divisionsets = ownProps.content.divisionsets.filter(divisionset => divisionset.id !== divisionsetId)
    dispatch(setGenericData(ownProps.widgetId, [ 'divisionsets' ], divisionsets))
    if (!ownProps.content.dataChanged) {
      dispatch(setGenericData(ownProps.widgetId, [ 'dataChanged' ], true))
    }
    dispatch(deleteDivisions(ownProps.widgetId, links.delete_divisionsets.path, { object_id: divisionsetId }))
      .catch(() => {
        dispatch(setGenericData(ownProps.widgetId, [ 'divisionsets' ], oldDivisionsets))
        dispatch(setGenericData(ownProps.widgetId, [ 'dataChanged' ], false))
      })
      
  },
  setData: (keys, value) => {
    dispatch(setGenericData(ownProps.widgetId, keys, value))
    if (!ownProps.content.dataChanged) {
      dispatch(setGenericData(ownProps.widgetId, [ 'dataChanged' ], true))
    }
  },
  save: () => {
    const links = ownProps.content.links
    const createDivision = ownProps.content.create_division

    const requestData = {
      by_custom: createDivision.custom_types.selected,
      divisions: createDivision.divisions,
      with_tees: !!createDivision.tees,
      custom_field: createDivision.custom_fields?.selected,
      anchor_date: moment.unix(createDivision.date_for_age).format('DD-MM-Y'),
      golfers_divisions: {
        by: createDivision.golfers_divisions.selected,
        auto_fill: createDivision.golfers_divisions.selected === 'number_of_divisions' && createDivision.golfers_divisions.auto_fill.selected,
        count: createDivision.golfers_divisions.choices[createDivision.golfers_divisions.selected].value,
        maximum_index: createDivision.golfers_divisions.maximum_handicap.selected,
      },
    }

    dispatch(saveEventSettingsWidget(ownProps.widgetId, links.save.path, { create_divisions: requestData }, { withNotifications: false }))
      .then(({ response }) => {
        response.message && showNotification(asHTML(response.message))
        response.errors && showErrorNotification(asHTML(response.errors))
        dispatch(ownProps.refresh)
        dispatch(reloadWidgetContent('event_setup_flights'))

        if ('job_id' in response) {
          pollJob(response.job_id, update => pollUpdateNotifyMessages(update, asHTML))
            .catch((update) => {
              const message = update?.value?.errors?.join('\n') || (!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.save_settings_error'))
              showErrorNotification(asHTML(message))
            })
            .finally(() => {
              dispatch(ownProps.refresh)
              dispatch(reloadWidgetContent('event_setup_flights'))
            })
        }
      })
      .finally(() => {
        dispatch(setGenericData(ownProps.widgetId, [ 'addingDivision' ], false))
      })
  },
  setError: (key, val) => {
    dispatch(setGenericData(ownProps.widgetId, [ 'create_division', 'errors', key ], val))
  },
})

export default connect(
  null,
  mapDispatchToProps,
)(EventSetupDivisions)
