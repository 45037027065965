import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { GlgRoundedButtonIcon, GlgTooltip } from '../../../shared/components'

class EventTournaments extends Component {
  constructor(props){
    super(props)
  }

  stringParameterize(str) {
    return str.trim().toLowerCase()
      .replace(/[^a-zA-Z0-9 -]/g, '')
      .replace(/ +/g, ' ')
      .replace(/[\s-]/g, '_')
  }

  render() {
    const { title, links, multiTournaments, linkedTournaments } = this.props.content

    return (
      <div className='widget-content-default widget-content-event-tournaments'>
        <div className='title'>
          { title }
        </div>
        <div className='content'>
          {
            links.add_multi_round_tournaments.permission && links.add_multi_round_tournaments.enabled
            ? <GlgTooltip tooltip={ links.add_multi_round_tournaments.tooltip } placement='right' >
              <span className='intermediate-container'>
                <GlgRoundedButtonIcon text={ links.add_multi_round_tournaments.name }
                                      url={ links.add_multi_round_tournaments.path }
                                      icon='gga-new-tournament'
                                      anchorClass='new_spec'
                                      />
              </span>
            </GlgTooltip>
            : null
          }

          <table className='table table-striped'>
            <thead>
              <tr className='header'>
                <th className='sort' dangerouslySetInnerHTML={{__html: multiTournaments.header.sorting }} />
                <th>{ multiTournaments.header.name }</th>
                { multiTournaments.has_divisions ? <th className='no-lite'>{ multiTournaments.header.divisions }</th> : null}
                <th>{ multiTournaments.header.format }</th>
                <th>{ multiTournaments.header.competitions }</th>
                <th>{ multiTournaments.header.balls }</th>
                <th>{ multiTournaments.header.handicap }</th>
                <th className='no-lite'>{ multiTournaments.header.purse }</th>
                <th className='no-lite'>{ multiTournaments.header.points }</th>
                {/* <th dangerouslySetInnerHTML={{__html: multiTournaments.header.actions }} /> */}
              </tr>
            </thead>
            <tbody>
              {
                multiTournaments.data.length === 0
                ? <tr className='no-tournaments'>
                    <td className='.center.notice' colSpan='9'>{ multiTournaments.missing_text }</td>
                </tr>
                : multiTournaments.data.map( rowData =>
                  <tr key={ rowData.id } id={ `tourney_${rowData.id}` }>
                    <td className='sort'>{/*<i className='fa fa-bars drag_and_drop_icon'></i>*/}</td>
                    <td className='nametext'>
                      { rowData.name.display_icon ? <i className='gga-cart'></i> : null }
                      {
                        rowData.name.disabled
                          ? <span title={ rowData.name.tooltip } className='title-disabled'>{ rowData.name.text }</span>
                          : <a href={ rowData.name.url } >
                            { rowData.name.text }
                          </a>
                      }
                    </td>
                    {
                      rowData.divisions.display
                      ? <td className='division no-lite'>
                        <span dangerouslySetInnerHTML={{__html: rowData.divisions.text }} />
                        { rowData.divisions.display_icon
                        ? <GlgTooltip tooltip={ rowData.divisions !== undefined ? rowData.divisions.icon_tooltip : '' } placement='top' >
                          <i className='gga-info-circle'></i>
                        </GlgTooltip> : null }
                      </td>
                      : null
                    }
                    <td className='format'>{ !window.I18n ? '' : window.I18n.t(`models.level.nodes.format.${this.stringParameterize(rowData.format)}`, {defaultValue: rowData.format}) }</td>
                    <td className='competitions'>{ !window.I18n ? '' : window.I18n.t(`models.level.nodes.competition.${this.stringParameterize(rowData.competitions)}`, {defaultValue: rowData.competitions}) }</td>
                    <td className='balls'>{ !window.I18n ? '' : window.I18n.t(`models.level.nodes.balls.${this.stringParameterize(rowData.balls)}`, {defaultValue: rowData.balls}) }</td>
                    <td className='handicap'>{ !window.I18n ? '' : window.I18n.t(`models.level.nodes.handicap.${this.stringParameterize(rowData.handicap)}`, {defaultValue: rowData.handicap}) }</td>
                    <td className='purse no-lite'>{ rowData.purse ? <i className='fa fa-check'></i> : null }</td>
                    <td className='points no-lite'>{ rowData.points ? <i className='fa fa-check'></i> : null }</td>
                    {/* <td className='actions text-right'>
                      {
                        rowData.actions.display
                        ? <span>
                          <a href={ rowData.actions.edit_link_path } className='action-links'>{ rowData.actions.edit_link_name }</a>
                          •
                          <a href={ rowData.actions.delete_link_path } className='action-links'>{ rowData.actions.delete_link_name }</a>
                        </span>
                        : null
                      }
                    </td> */}
                  </tr>
                )
              }
            </tbody>
          </table>


          {
            links.add_linked_tournaments.permission && links.add_linked_tournaments.enabled
            ? <GlgTooltip tooltip={ links.add_linked_tournaments.tooltip } placement='right' >
              <span className='intermediate-container'>
                <GlgRoundedButtonIcon text={ links.add_linked_tournaments.name }
                                      url={ links.add_linked_tournaments.path }
                                      icon='gga-new-tournament'
                                      anchorClass='new_spec'
                                      />
              </span>
            </GlgTooltip>
            : null
          }


          {
            links.add_linked_tournaments && linkedTournaments.show
            ? <table className='table table-striped'>
              <thead>
                <tr className='header'>
                  <th className='description'>{ linkedTournaments.header.name }</th>
                  <th className='format'>{ linkedTournaments.header.format }</th>
                  {/* <th className='actions'>{ linkedTournaments.header.actions }</th> */}
                </tr>
              </thead>
              <tbody>
                {
                  linkedTournaments.data.length === 0
                  ? <tr className='no-tournaments'>
                    <td colSpan='2'>{ linkedTournaments.missing_text }</td>
                  </tr>
                  : linkedTournaments.data.map( rowData =>
                    <tr key={ rowData.id } id={ `linked_tournament_${rowData.id}` }>
                      <td className='nametext'>
                        <a href={ rowData.name.url }>{ rowData.name.text }</a>
                      </td>
                      <td className='handicap'>{ rowData.format }</td>
                      {/* <td className='actions text-right'>
                        <a href={ rowData.actions.edit_link_path } className='action-links'>{ rowData.actions.edit_link_name }</a>
                        •
                        <a href={ rowData.actions.delete_link_path } className='action-links'>{ rowData.actions.delete_link_name }</a>
                      </td> */}
                    </tr>
                  )
                }
              </tbody>
            </table>
            : null
          }
        </div>
      </div>
    )
  }
}

EventTournaments.propTypes = { 
  content: PropTypes.object,
}

EventTournaments.defaultProps = {
  content: {
    title: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_tournaments.title'),
    links: {
      add_multi_round_tournaments: {
        permission: false,
        path: '#',
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_tournaments.add_new_multi_round_tournament'),
        tooltip: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_tournaments.multi_round_tournament_ex'),
      },
      add_linked_tournaments: {
        permission: false,
        path: '#',
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_tournaments.add_linked_tournament'),
        tooltip: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_tournaments.linked_tournament_ex'),
      },
    },
    multiTournaments: {
      header: {
        sorting: '&nbsp;',
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_tournaments.name'),
        divisions: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_tournaments.divisions'),
        format: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_tournaments.format'),
        competitions: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_tournaments.competitions'),
        balls: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_tournaments.balls'),
        handicap: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_tournaments.handicap'),
        purse: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_tournaments.purse'),
        points: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_tournaments.points'),
        actions: '&nbsp;',
      },
      has_divisions: false,
      missing_text: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_tournaments.click_add_multi_round_tournament'),
      data: [],
    },
    linkedTournaments: {
      header: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_tournaments.name'),
        format: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_tournaments.format'),
        actions: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_tournaments.actions'),
      },
      missing_text: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_tournaments.click_add_linked_tournament'),
      data: [],
    },
  },
}

export default EventTournaments
