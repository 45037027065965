import { connect } from 'react-redux'

import PopupTypes from '../popup_types'
import {
  closePopup,
} from '../actions'
import PopupsComponent from '../components/popups_component'

const mapStateToProps = (state) => {
  const popupStates = state.popupStates
  return {
    helpLinks: popupStates[PopupTypes.HELP_LINKS],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closePopup: id => dispatch(closePopup(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PopupsComponent)
