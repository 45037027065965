import { createSelector } from 'reselect'

const getSortOption = state => state.filters.sortByOption
const getOrder = state => state.filters.sortOrder
const players = state => state.station.players
const filterName = state => state.station.filterName

const getSortedDistances = createSelector(
  [ getSortOption, getOrder, players, filterName ],
  (sortOption, order, players, filterName) => {
    if (filterName !== '' && filterName) {
      players = players.slice(0).filter(p => p.name.toLowerCase().includes(filterName.toLowerCase()))
    }
    if (sortOption === 'distance') {
      return players.slice(0).sort( (p1, p2) => {
        if (order === 'asc') {
          return p1.distance - p2.distance
        } else {
          return p2.distance - p1.distance
        }
      })
    } else if (sortOption === 'hole') {
      return players.slice(0).sort( (p1, p2) => {
        if (order === 'asc') {
          return p1.hole < p2.hole ? -1 : 1
        } else {
          return p2.hole < p1.hole ? -1 : 1
        }
      })
    } else if (sortOption === 'holeTime') {
      return players.slice(0).sort( (p1, p2) => {
        if (order === 'asc') {
          return p1.created < p2.created ? -1 : 1
        } else {
          return p2.created < p1.created ? -1 : 1
        }
      })
    } else if (sortOption === 'pairing') {
      return players.slice(0).sort( (p1, p2) => {
        if (order === 'asc') {
          return p1.pairing_number < p2.pairing_number ? -1 : 1
        } else {
          return p2.pairing_number < p1.pairing_number ? -1 : 1
        }
      })
    }
  },
)

export default getSortedDistances
