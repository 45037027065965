import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

class AddColumn extends Component {
  constructor(props) {
    super(props)

    this.onDragOver = this.onDragOver.bind(this)
  }

  onDragOver(e) {
    if (this.props.POSITION === null) { return }
    if (this.props.DraggedOverElement !== this.props.POSITION) {
      this.props.setDraggedOverElement(this.props.POSITION)
      $( '.dnd-placeholder' ).remove()
      e.target.closest('.add-content').insertAdjacentHTML('beforebegin', `<div class="col-xs-12 dnd-placeholder">${window.I18n.t('multi_widgets.add_column.drop_here')}</div>`)
    }
  }

  render() {
    return (
      <Col xs={12} onClick={ this.props.AddColumn }
          className="add-content">
        { this.props.count === 0 ?
          <span onDragOver={ e => this.onDragOver(e) }>
            <Row className="container">
              <Col xs={12}>
                <p>+</p>
                <h4>{window.I18n.t('multi_widgets.add_column.add_tile')}</h4>
              </Col>
            </Row>
          </span> :
        <Row top="xs" className="empty-menubar">{window.I18n.t('multi_widgets.add_column.add_another')}</Row> }
      </Col>
    )
  }
}

AddColumn.propTypes = {
  size: PropTypes.any,
  count: PropTypes.number,
  POSITION: PropTypes.any,
  AddColumn: PropTypes.func,
  DraggedOverElement: PropTypes.any,
  setDraggedOverElement: PropTypes.func,
}

export default AddColumn
