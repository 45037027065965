import React, { Component, Fragment } from 'react'
import {
  GlgTable,
  GlgTableCol,
  GlgRoundedButtonIcon,
  GlgTooltipIcon,
} from 'SharedComponents'
import { Row, Col } from 'react-flexbox-grid'
import TvShowTableRow from './tv_show_table_row'
import Loader from 'react-loader-spinner'
import PropTypes from 'prop-types'

class TvShowsIndex extends Component {

  constructor(props) {
    super(props)
    this.state = { loading: false }
    this.isSafari = this.isSafari.bind(this)
    this.reloadOnBackButton = this.reloadOnBackButton.bind(this)
  }

  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  }

  reloadOnBackButton(e, delay = 5000) {
    const perfEntries = performance.getEntriesByType('navigation')
    const backAction = e?.persisted || (perfEntries[0].type === 'back_forward')
    if (backAction) {
      this.setState({loading: true})
      setTimeout(() => {
        location.reload()
      }, delay)
    } else {
      this.setState({loading: false})
    }
  }

  componentDidMount() {
    if (this.isSafari()) {
      window.addEventListener('pageshow', this.reloadOnBackButton)
    } else {
      this.reloadOnBackButton(null, 1000)
    }
  }

  componentWillUnmount() {
    if (this.isSafari()) {
      window.removeEventListener('pageshow', this.reloadOnBackButton)
    }
  }

  render() {
    const {
      tvShows,
      paths,
      isLibrary,
    } = this.props

    return <Fragment>{ !isLibrary && <Row><Col xs={ 12 } sm={ 6 }><GlgRoundedButtonIcon text={ !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_shows_index_component.add_new_tv_show') } icon="gga-circle-plus" url={ paths.new } anchorClass={ this.state.loading ? 'disabled' : '' }></GlgRoundedButtonIcon></Col><Col xs={ 12 } sm={ 6 } className={ 'library_button_container' }><GlgRoundedButtonIcon text={ !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_shows_index_component.tv_show_library') } icon="gga-tournament-library" url={ paths.library } anchorClass={ this.state.loading ? 'disabled' : '' }></GlgRoundedButtonIcon></Col></Row>}{ this.state.loading ? <div className={ 'loader-container add-margin-bottom-10 text-center glg-loading-spinner ' + (isLibrary ? 'add-padding-top-47' : '') }><Loader type="Oval" height={ 40 } width={ 40 } /></div> : <Row>{ tvShows.length > 0 ? <Col xs={ 12 }><GlgTable items={ tvShows } rowComponent={ TvShowTableRow } rowExtraProps={{
                            requestProtocol: this.props.requestProtocol,
                            removeTvShow: this.props.removeTvShow,
                            toggleAutoInclude: this.props.toggleAutoInclude,
                          }}><GlgTableCol width={ '3%' } dataKey={ '' }></GlgTableCol><GlgTableCol width={ '32%' } dataKey={ !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_shows_index_component.name') }></GlgTableCol><GlgTableCol width={ '10%' } dataKey={ !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_shows_index_component.slides') }></GlgTableCol><GlgTableCol width={ '20%' } dataKey={ !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_shows_index_component.slideshow_link') }></GlgTableCol><GlgTableCol width={ '10%' } dataKey={ 'GGID' } className={ 'text-uppercase' }></GlgTableCol><GlgTableCol width={ '10%' } dataKey={ 'auto_include' } className={ isLibrary ? '' : 'hidden' }><span>{ !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_shows_index_component.auto_include') }<GlgTooltipIcon placement={ 'bottom' } tooltip={ !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_shows_index_component.auto_include_info') }></GlgTooltipIcon></span></GlgTableCol><GlgTableCol width={ isLibrary ? '15%' : '25%' } dataKey={ !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_shows_index_component.actions') }></GlgTableCol></GlgTable></Col> : <Col className={ 'text-align-center ' + (isLibrary ? 'add-padding-top-47' : '') } xs={ 12 }>{ !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_shows_index_component.no_tv_shows_currently_defined') }</Col>
          }</Row>
        }</Fragment>
  }
}

TvShowsIndex.propTypes = {
  tvShows: PropTypes.array,
  paths: PropTypes.object.isRequired,
  requestProtocol: PropTypes.string.isRequired,
  removeTvShow: PropTypes.func.isRequired,
  toggleAutoInclude: PropTypes.func.isRequired,
  isLibrary: PropTypes.bool.isRequired,
}

export default TvShowsIndex
