import React, { useState, useEffect, Fragment } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import PropTypes from 'react'
import { callAPI } from 'Shared/helpers'
import moment from 'moment'
import { GlgLoadingSpinner } from 'SharedComponents'
import { getAuthenticityToken } from 'Shared/helpers'

const SchedulesIndex = (props) => {

  const [ schedules, setSchedules ] = useState( [] )
  const [ dataIsLoading, setDataIsLoading ] = useState(true)

  useEffect(() => {
    
    callAPI('/api/customers/' + props.customerId + '/directory_schedules', 'GET')
      .then((json) => {
        setSchedules(json)
        setDataIsLoading(false)
      })
  }, [])

  const handleDelete = (id) => {
    // eslint-disable-next-line no-alert
    if (confirm(window.I18n.t('views.golfhub.remove_schedule_confirmation_message')) === true){
      let temp = [ ...schedules ]
      temp = temp.filter((v) => v.id !== id)
      
      fetch('/api/customers/' + props.customerId + '/directory_schedules/' + id, {
        method: 'DELETE',
        headers: {'X-CSRF-Token': getAuthenticityToken()}})
      .then(() => setSchedules(temp))
    }
  }

  return (
    <Row className='no-margin'>
      <Col className='add-padding-all-20' xs={ 12 }>
        <div className='btsp_bullet_button pointer' onClick={() => props.setPage('new')}>
          <div className='icon'><i className='gga-file-plus'></i></div>
          <div className='btsp_align-middle'>New Schedule Template</div>
        </div>
        { dataIsLoading && (<GlgLoadingSpinner className='text-center' show={dataIsLoading}/>)}

        { !dataIsLoading && schedules.length === 0 && (
          <div className='text-center'>No schedule templates yet</div>
        )}
        { !dataIsLoading && schedules.length > 0 && (
          <div className='table-responsive btsp_table_container table_with_dropdown'>
            <table className='table table-striped table-drop'>
              <thead>
                <tr className='header'>
                  <td className='w-20 middle'><b>NAME</b></td>
                  <td className='w-10 middle'></td>
                  <td className='pdl-40 middle'><b>SCHEDULE</b></td>
                  <td className='w-10 middle'></td>
                </tr>
              </thead>
              <tbody>
                {schedules.map(schedule => (
                  <tr key={schedule.id}>
                    <SchedulesRow handleDelete={handleDelete} setPage={props.setPage} {...schedule}/>
                  </tr>
                ))}
              </tbody>
                  
            </table>
          </div>
        )}
            

      </Col>
    </Row>
  )
}

const SchedulesRow = (props) => {
  const [ open, setOpen ] = useState( false )

  return (
    <Fragment>
      <td className='w-20 template-name'>
        <a onClick={() => props.setPage('edit/' + props.id)}>{props.name}</a>
      </td>
      <td className='w-10 middle blue-label-container'>
        {
          props.primary === true && 
            <div className='blue-label'>
              { window.I18n.t('views.golfhub.default') }
            </div>
        }
      </td>
      <td className='template-content'>
        {props.activities.map((a, index) => (
          <div key={props.id + '-' + index}>
            <span className='schedule-time'>
              <div className='w-40'>{moment(a.time).tz(props.timezone)
                .format('hh:mm a')}</div>
            </span>
            <span className='pdl-15 schedule-activity'>{a.activity}</span> 
          </div>
        ))}
      </td>
      <td className='w-10 template-content'>
        <div className={'dropMenu dropdown' + (open ? ' open' : '')} onClick={() => setOpen(!open)}>
          <a className='btn btn-default btn-xs new_dropdown_button_icon' href='javascript:void(0);'>
            <i className={'fa fa-lg ' + (open ? 'fa-caret-up' : 'fa-caret-down') }/>
          </a>
          <ul className='dropdown-menu'>
            <li><a onClick={() => props.setPage('edit/' + props.id)}>Edit</a></li>   
            <li><a onClick={() => props.handleDelete(props.id)}>Delete</a></li>
          </ul>
        </div>
      </td>
    </Fragment>
  )
}

SchedulesRow.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  primary: PropTypes.string,
  activities: PropTypes.array,
  setPage: PropTypes.func,
  handleDelete: PropTypes.func,
  timezone: PropTypes.string,
}

SchedulesIndex.propTypes = {
  setPage: PropTypes.func,
  customerId: PropTypes.string,
}

export default SchedulesIndex
