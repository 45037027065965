import React from 'react'
import PropTypes from 'prop-types'

import DrivingDistanceEditContainer from '../containers/driving_distance_edit_container'

const drivingDistanceData = ({player, adjustment}) => {
  return (
    <tr>
      <td className='gray_column'>
          <div className='handle_text'>
              <span className='pairing'>{player.pairing_number !== 999 ? player.pairing_number + 1 : '-'}</span>
          </div>
      </td>
      <td className='gray_column'>
        <div className='handle_text'>
            <span className='hole_number'>{player.hole !== 999 ? player.hole : '-'}</span>
        </div>
      </td>
      <td className='second'>
        <div className='handle_text'>
          <span className='full_name'>{player.name}</span>
        </div>
      </td>
      <td>
        <div className='handle_text'>
          <span className='distance'>{player.distance === 0 ? '-' : player.distance}</span>
        </div>
      </td>
      { adjustment !== 0 &&
      <td>
        <div className='handle_text'>
          <span className='distance_adjusted'>{player.distance === 0 ? '-' : parseInt(player.distance) + adjustment}</span>
        </div>
      </td>
      }
      <DrivingDistanceEditContainer playerName={player.name}
                                    distance={player.distance}
                                    distanceId={player.id}/>
    </tr>
  )
}

drivingDistanceData.propTypes = {
  player: PropTypes.object,
  adjustment: PropTypes.number,  
}

export default drivingDistanceData
