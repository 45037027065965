import { ActionTypes } from '../actions'

const {
  FETCH_INITIAL_DATA_REQUEST,
  FETCH_INITIAL_DATA_RECEIVED,
  CHANGE_TOURNAMENT_REQUEST,
  CHANGE_TOURNAMENT_RECEIVED,
  CHANGE_ROUND_REQUEST,
  CHANGE_ROUND_RECEIVED,
  REFRESH_DATA,
} = ActionTypes

const initialState = {
  isLoading: false,
  isLoadingTournament: false,
  tees: [],
  courses: [],
  foursomes: [],
  pastRounds: [],
  holes: [],
}

const groupViewData = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INITIAL_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_INITIAL_DATA_RECEIVED:
      return {
        ...state,
        ...action.data,
        isLoading: false,
      }
    case CHANGE_TOURNAMENT_REQUEST:
      return {
        ...state,
        isLoadingTournament: true,
      }
    case CHANGE_TOURNAMENT_RECEIVED:
      return {
        ...state,
        ...action.data,
        isLoadingTournament: false,
      }
    case CHANGE_ROUND_REQUEST: 
      return {
        ...state,
        isLoadingTournament: true,
      }
    case CHANGE_ROUND_RECEIVED:
      return {
        ...state,
        ...action.data,
        isLoadingTournament: false,
      }
    case REFRESH_DATA:
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}

export default groupViewData
