import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GlgTooltip } from 'SharedComponents'
import { Col } from 'react-bootstrap'


class RenewalDateComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let renewalDate
    if (this.props.renewalDate) {
      if (this.props.isExpired) {
        renewalDate = <div className="expired-membership"><GlgTooltip tooltip={ !window.I18n ? '' : window.I18n.t('player_activity.registration.membership.membership_expired') + '!' }><span className="expiration-date">{ this.props.renewalDate }<i className="fa fa-exclamation-circle"></i></span></GlgTooltip><hr /><a href={ this.props.membershipPortalLink } target={ this.props.target } className="btsp_small_orange_button renew-membership">{ !window.I18n ? '' : window.I18n.t('player_activity.registration.membership.renew_membership') }</a></div>
      } else {
        renewalDate = this.props.renewalDate
      }
    }
    
    return <Col sm={ this.props.colSmSize } xs={ 12 }><div className="panel panel-default"><div className="panel-heading text-center">{ !window.I18n ? '' : window.I18n.t('player_activity.registration.membership.renewal_date') }</div><div className="panel-body text-center">{ renewalDate }</div></div></Col>
  }
}

RenewalDateComponent.propTypes = {
  renewalDate: PropTypes.string.isRequired,
  isExpired: PropTypes.bool.isRequired,
  colSmSize: PropTypes.number.isRequired,
  membershipPortalLink: PropTypes.string,
  target: PropTypes.string,
}

RenewalDateComponent.defaultProps = {
  renewalDate: '',
  isExpired: true,
}

export default RenewalDateComponent
