import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FormGroup, ControlLabel } from 'react-bootstrap'
import { Row, Col } from 'react-flexbox-grid'

import RegistrationFilterTypes from '../../registration_filter_types'
import { GlgSelect } from 'SharedComponents'

class RegistrationFilter extends PureComponent {
  constructor(props) {
    super(props)
    this.onChange = props.onChange.bind(this, 'registration')
  }

  render() {
    let options = _.clone(OPTIONS)
    if (!this.props.showRegistrationOptions) {
      options = options.filter(option => (
        !REGISTRATION_OPTIONS_VALUES.includes(option.value)
      ))
    }
    if (!this.props.golfHubEnabled) {
      options = options.filter(option => (
        !GOLF_HUB_OPTIONS_VALUES.includes(option.value)
      ))
    }
    const filterEventsText = (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.registration_filter.filter_events'))
    return <FormGroup><Row><Col xs={true}><ControlLabel className="add-margin-top-5">{ filterEventsText }</ControlLabel></Col></Row><Row><Col xs={true}><GlgSelect items={ options } value={ this.props.value } onChange={ this.onChange } disabled={ this.props.disabled } isCustomized={ this.props.isCustomized }></GlgSelect></Col></Row></FormGroup>
  }
}

RegistrationFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  showRegistrationOptions: PropTypes.bool,
  golfHubEnabled: PropTypes.bool,
  isCustomized: PropTypes.bool,
}

const OPTIONS = [
  {
    value: RegistrationFilterTypes.ALL,
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.registration_filter.options.all')),
  },
  {
    value: RegistrationFilterTypes.UPCOMING,
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.registration_filter.options.upcoming')),
  },
  {
    value: RegistrationFilterTypes.PAST,
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.registration_filter.options.past')),
  },
  {
    value: RegistrationFilterTypes.OPEN,
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.registration_filter.options.registration_open')),
  },
  {
    value: RegistrationFilterTypes.WILL_OPEN,
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.registration_filter.options.registration_will_open')),
  },
  {
    value: RegistrationFilterTypes.OPEN_FOR_INVITATION,
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.registration_filter.options.registration_open_invitation_list')),
  },
  {
    value: RegistrationFilterTypes.OPEN_FOR_MEMBERSHIP,
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.registration_filter.options.registration_open_membership_programs')),
  },
  {
    value: RegistrationFilterTypes.CLOSED,
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.registration_filter.options.registration_closed')),
  },
  {
    value: RegistrationFilterTypes.WAITLIST_NOT_EMPTY,
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.registration_filter.options.waitlist')),
  },
  {
    value: RegistrationFilterTypes.PENDING_LIST_NOT_EMPTY,
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.registration_filter.options.pending_registration')),
  },
  {
    value: RegistrationFilterTypes.NON_ARCHIVED,
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.registration_filter.options.all_except_archived')),
  },
  {
    value: RegistrationFilterTypes.GOLF_HUB_CREATED,
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.registration_filter.options.golf_hub_page_created')),
  },
  {
    value: RegistrationFilterTypes.GOLF_HUB_PUBLISHED,
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.registration_filter.options.golf_hub_page_published')),
  },
  {
    value: RegistrationFilterTypes.GOLF_HUB_NOT_CREATED,
    label: (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.registration_filter.options.golf_hub_page_not_created')),
  },
]

const REGISTRATION_OPTIONS_VALUES = [
  RegistrationFilterTypes.OPEN,
  RegistrationFilterTypes.WILL_OPEN,
  RegistrationFilterTypes.OPEN_FOR_INVITATION,
  RegistrationFilterTypes.OPEN_FOR_MEMBERSHIP,
  RegistrationFilterTypes.CLOSED,
  RegistrationFilterTypes.WAITLIST_NOT_EMPTY,
  RegistrationFilterTypes.PENDING_LIST_NOT_EMPTY,
]

const GOLF_HUB_OPTIONS_VALUES = [
  RegistrationFilterTypes.GOLF_HUB_CREATED,
  RegistrationFilterTypes.GOLF_HUB_PUBLISHED,
  RegistrationFilterTypes.GOLF_HUB_NOT_CREATED,
]

export default RegistrationFilter
