import React, { Component } from 'react'
import { AutoSizer, InfiniteLoader, CellMeasurer, Grid } from 'react-virtualized'
import PropTypes from 'prop-types'
import { GlgCustomDropdown, GlgTooltipIcon, GlgLoadingSpinner } from 'SharedComponents'
import { getColumnName } from '../helpers'
import ActionsPanel from './actions/actions_panel'
import Loader from 'react-loader-spinner'
import {Scrollbar} from 'react-scrollbars-custom'

export class MembersTableComponent extends Component {  
  constructor(props) {
    super(props)
    this.loadMore = this.loadMore.bind(this)
    this.isRowLoaded = this.isRowLoaded.bind(this)
    this._cellHeaderRenderer = this._cellHeaderRenderer.bind(this)
    this._cellBodyRenderer = this._cellBodyRenderer.bind(this)
    this._onSectionRendered = this._onSectionRendered.bind(this)
    this._multiGrid = React.createRef()
    this._multiGrid2 = React.createRef()
    this.getColumnWidth = this.getColumnWidth.bind(this)
    this.sortMembers = this.sortMembers.bind(this)
    this.getSortIcon = this.getSortIcon.bind(this)
    this.getCellWithEditMemberModalLink = this.getCellWithEditMemberModalLink.bind(this)
    this.getSortableColumnHeader = this.getSortableColumnHeader.bind(this)
    this.getActionsCell = this.getActionsCell.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.getTotalWidth = this.getTotalWidth.bind(this)
    this.recomputeSize = this.recomputeSize.bind(this)
  }
  
  componentDidMount(){
    window.glg.member.editMemberModal(this.props.leagueId)
  }
  
  componentDidUpdate(){
    this.recomputeSize()
  }
  
  sortMembers(columnName){
    if (columnName !== 'glg_actions') {
      const sortOrder = this.props.searchParams.column === columnName ? (this.props.searchParams.sort_order === 'asc' ? 'desc' : 'asc') : 'asc'
      this.props.fetchInitialMembersData({ column: columnName, sort_order: sortOrder })
    }
  }
  
  getSortIcon(columnName){
    let sortIcon
    if (columnName === this.props.searchParams.column){
      if (this.props.searchParams.sort_order === 'asc'){
        sortIcon = <div className="icon_wrapper"><img src={require('widgets/asc.gif')} /></div>
      } else {
        sortIcon = <div className="icon_wrapper"><img src={require('widgets/desc.gif')} /></div>
      }
    } else {
      sortIcon = <span className="on_hover_icons"><i className="gga-carret-up"></i><i className="gga-carret-down"></i></span>
    }
    return sortIcon
  }
  
  getCellWithEditMemberModalLink(membersRowIndex, columnName) {
    const memberId = this.props.members[membersRowIndex].id
    return <div className="centered_table_content">{(columnName === 'full_name' || (columnName === 'last_name' && this.props.lastNameOnly)) && <span className="circle_container react_circle_container"><div className={'circle ' + this.props.members[membersRowIndex]['status']}></div></span>}<span><a href="#" className={(this.props.canOpenMemberModal ? ' can_open' : '')} onClick={() => this.props.openEditMemberModal(memberId)}>{ this.props.members[membersRowIndex][columnName] }</a></span><span style={{marginLeft: '5px'}} className="glg-loading-spinner">{this.props.isOpenEditMemberModalLoading[memberId] && <Loader type="ThreeDots" color={ '#0088CE' } height={ 15 } width={ 30 }></Loader>}</span></div>
  }
  
  getSortableColumnHeader(columnName) {
    const {name, title} = getColumnName(columnName, this.props.indexType, this.props.prettyHandicapMethod, this.props.membershipPrograms)
    return <div className="left-label"><span>{ name }</span>{title && <GlgTooltipIcon tooltip={title}></GlgTooltipIcon>}{ this.getSortIcon(columnName) }</div>
  }
  
  getActionsCell(membersRowIndex) {
    const panel = ActionsPanel
    const memberId = this.props.members[membersRowIndex].id
    const showLoader = this.props.isUnregisterMemberLoading[memberId] || this.props.isDeleteMemberLoading[memberId] || this.props.isOpenEditMemberModalLoading[memberId] || this.props.isConfirmMemberLoading[memberId]
    return showLoader ? <span className="pull-right glg-loading-spinner"><Loader type="ThreeDots" color={ '#0088CE' } height={ 15 } width={ 30 }></Loader></span> : <div className="dropdown member_actions_dropdown"><GlgCustomDropdown dropdownPanel={panel} onChange={ () => {} } member={this.props.members[membersRowIndex]} canOpenMemberModal={this.props.canOpenMemberModal} offsetY={-6} direction="right" isTourManager={this.props.isTourManager} deleteMember={this.props.deleteMember} resetPasswordMember={this.props.resetPasswordMember} isDeleteMemberLoading={this.props.isDeleteMemberLoading} canUseMasterRosterRegistration={this.props.canUseMasterRosterRegistration} openRegisterMemberPopup={this.props.openRegisterMemberPopup} getRegisterMemberData={this.props.getRegisterMemberData} unregisterMember={this.props.unregisterMember} customerSoftDelete={this.props.customerSoftDelete} confirmMember={this.props.confirmMember} isOpen={this.props.isActionsDropdownOpen[memberId]} setIsOpen={(val) => this.props.toggleActionsDropdown(val, memberId)} openEditMemberModal={this.props.openEditMemberModal} leagueProduct={this.props.leagueProduct} isGcatCustomer={this.props.isGcatCustomer} hasMrSyncEnabled={this.props.hasMrSyncEnabled}></GlgCustomDropdown></div>
  }

  getHiInfoLink(membersRowIndex){
    return <a href="#" className={'hs_info_link'} data-member-id={this.props.members[membersRowIndex]['id']}>   { this.props.members[membersRowIndex]['usga_index'] ? this.props.members[membersRowIndex]['usga_index'] : 'N/A'}</a>
  }
  
  _cellHeaderRenderer({columnIndex, key, parent, rowIndex, style}) {
    let cellData
    let classes = 'cell'
    const columnName = this.props.columns[columnIndex]
    if (columnName === 'glg_actions') {
      cellData = getColumnName(columnName, this.props.indexType, this.props.prettyHandicapMethod, this.props.membershipPrograms).name
    } else {
      cellData = this.getSortableColumnHeader(columnName)
    }
    classes += ' header'
    return <CellMeasurer cache={this.props.cellCache1} columnIndex={columnIndex} key={key} parent={parent} rowIndex={rowIndex}><div style={{...style, whiteSpace: 'nowrap', height: '40px', paddingLeft: columnIndex === 0 ? '25px' : '8px'}} className={classes} onClick={() => this.sortMembers(columnName)}>{cellData}</div></CellMeasurer>
  }
  
  _cellBodyRenderer({columnIndex, key, parent, rowIndex, style}) {
    let cellData
    let classes = 'cell'
    const columnName = this.props.columns[columnIndex]
    const lastNameOnly = this.props.columns.indexOf('last_name') > -1 && !(this.props.columns.indexOf('full_name') > -1)
    const isLink = (columnName === 'full_name' || (columnName === 'last_name' && lastNameOnly)) && this.props.canOpenMemberModal
    const membersRowIndex = rowIndex
    if (membersRowIndex < this.props.members.length){
      if (isLink){
        cellData = this.getCellWithEditMemberModalLink(membersRowIndex, columnName)
        classes += ' ' + this.props.members[membersRowIndex].error
      } else if (columnName === 'glg_actions') {
        cellData = this.getActionsCell(membersRowIndex)
      } else if (columnName === 'usga_index' && this.props.isGcatCustomer) {
        cellData = this.getHiInfoLink(membersRowIndex)
      } else {
        cellData = this.props.members[membersRowIndex][columnName]
      }
      if (membersRowIndex % 2 === 0){
        classes += ' evenRow'
      } else {
        classes += ' oddRow'
      }
      if (columnName === 'email') {
        classes += ' ' + this.props.members[membersRowIndex].error
      }
    }
    return <CellMeasurer cache={this.props.cellCache2} columnIndex={columnIndex} key={key} parent={parent} rowIndex={rowIndex}><div style={{...style, whiteSpace: 'nowrap', height: '30px', paddingLeft: columnIndex === 0 ? '25px' : '8px'}} className={classes}>{cellData}</div></CellMeasurer>
  }
  
  loadMore({stopIndex}){
    this.props.fetchMembersData(stopIndex)
  }
  
  isRowLoaded({index}){
    return !!this.props.members[index]
  }
  
  _onSectionRendered({ rowStartIndex, rowStopIndex }) {
    this._onRowsRendered({
      startIndex: rowStartIndex,
      stopIndex: rowStopIndex,
    })
  }
  
  getColumnWidth({index, totalWidth, cellCache}){
    if (index < this.props.columns.length - 1) {
      const nrColumns = this.props.columns.length
      let gridWidth = 0
      for (let i = 0; i < nrColumns; i++){
        gridWidth += cellCache.columnWidth({index: i})
      } 
      const columnWidthRemainder = gridWidth < totalWidth - 10 ? (totalWidth - gridWidth - 10) / (nrColumns - 1) : 0
      return cellCache.columnWidth({index}) + columnWidthRemainder
    } else {
      return cellCache.columnWidth({ index })
    }
  }
  
  getSpinnerStyle(width){
    const spinnerStyle = { height: '60px', width: width, textAlign: 'center', position: 'absolute', backgroundColor: '#f7f7f7' }
    if (this.props.members.length > 0) {
      spinnerStyle.bottom = 7
    } else {
      spinnerStyle.top = 60
    }
    return spinnerStyle
  }
  
  handleScroll(scrollValues) {
    const { scrollTop, scrollLeft } = scrollValues
    this._multiGrid.handleScrollEvent({ scrollTop, scrollLeft })
  }
  
  getTotalWidth(totalWidth, cellCache){
    const nrColumns = this.props.columns.length
    let gridWidth = 0
    for (let i = 0; i < nrColumns; i++){
      gridWidth += this.getColumnWidth({index: i, totalWidth, cellCache})
    }
    return gridWidth
  }
  
  renderThumb({style, ...props}){
    return <div {...props} style={{...style, backgroundColor: 'red', borderRadius: '5px'}}></div>
  }
  
  recomputeSize(){
    this._multiGrid.recomputeGridSize()
    this._multiGrid2.recomputeGridSize()
    this.props.updateGridWidth(1)
    this.props.updateGridWidth(2)
  }

  render() {
    return <InfiniteLoader isRowLoaded={ this.isRowLoaded } loadMoreRows={ this.loadMore } rowCount={ this.props.membersCount }>{({onRowsRendered, registerChild}) => {
          this._onRowsRendered = onRowsRendered
          return <AutoSizer ref={(el) => this.autoSizer = el} onResize={({width}) => {
                this.props.updateContainerWidth(width)
                this.recomputeSize()
              }
            }>{({width, height}) => {
                return <div style={{height: height, width: width}}><Scrollbar style={{height: height, width: width}} disableTracksWidthCompensation={true} trackXProps={{style: {display: 'none'}}} noScrollY={true}><Grid ref={(el) => {
                          this._multiGrid2 = el
                          registerChild(el)
                        }} onSectionRendered={this._onSectionRendered} columnCount={this.props.columns.length} columnWidth={({index}) => this.getColumnWidth({index, totalWidth: width, cellCache: this.props.cellCache1})} width={this.props.gridWidth} height={40} cellRenderer={this._cellHeaderRenderer} deferredMeasurementCache={this.props.cellCache1} rowCount={ 1 } rowHeight={ 40 } style={{overflowX: false, overflowY: false}}></Grid><Scrollbar onScroll={(props) => {
                          this._multiGrid.handleScrollEvent({scrollTop: props.scrollTop})
                        }} onScrollStop={() => {
                          this.recomputeSize()
                        }} style={{height: height - 40, width: this.props.gridWidth}} noScrollX={true}><Grid ref={(el) => {
                            this._multiGrid = el
                            registerChild(el)
                          }} onSectionRendered={this._onSectionRendered} columnCount={this.props.columns.length} columnWidth={({index}) => this.getColumnWidth({index, totalWidth: width, cellCache: this.props.cellCache2})} width={this.props.gridWidth} height={height - 40} cellRenderer={this._cellBodyRenderer} deferredMeasurementCache={this.props.cellCache2} rowCount={ this.props.members.length } rowHeight={ 30 } style={{overflowX: false, overflowY: false}}></Grid></Scrollbar>{ !this.props.isLoading && this.props.members.length === 0 && <div style={{height: '60px', width: width, textAlign: 'center', position: 'absolute', backgroundColor: '#f7f7f7', top: '50px'}}>                        { !window.I18n ? '' : window.I18n.t('members_page.components.members_table_component.no_golfers') }</div>}</Scrollbar>{ this.props.isLoading && <div style={this.getSpinnerStyle(width)}><GlgLoadingSpinner show={this.props.isLoading} height={56} width={56}></GlgLoadingSpinner></div>}</div>
              }}</AutoSizer>
        }}</InfiniteLoader>
  }
}

MembersTableComponent.propTypes = {
  membersCount: PropTypes.number.isRequired,
  leagueId: PropTypes.string.isRequired,
  members: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  fetchMembersData: PropTypes.func.isRequired,
  fetchInitialMembersData: PropTypes.func.isRequired,
  searchParams: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  reloadAll: PropTypes.bool.isRequired,
  canOpenMemberModal: PropTypes.bool.isRequired,
  lastNameOnly: PropTypes.bool.isRequired,
  cellCache1: PropTypes.object.isRequired,
  cellCache2: PropTypes.object.isRequired,
  indexType: PropTypes.string,
  prettyHandicapMethod: PropTypes.string,
  membershipPrograms: PropTypes.object.isRequired,
  isTourManager: PropTypes.bool.isRequired,
  canUseMasterRosterRegistration: PropTypes.bool.isRequired,
  customerSoftDelete: PropTypes.bool.isRequired,
  isUnregisterMemberLoading: PropTypes.object.isRequired,
  isDeleteMemberLoading: PropTypes.object.isRequired,
  isConfirmMemberLoading: PropTypes.object.isRequired,
  isOpenEditMemberModalLoading: PropTypes.object.isRequired,
  deleteMember: PropTypes.func.isRequired,
  resetPasswordMember: PropTypes.func.isRequired,
  openRegisterMemberPopup: PropTypes.func.isRequired,
  getRegisterMemberData: PropTypes.func.isRequired,
  unregisterMember: PropTypes.func.isRequired,
  confirmMember: PropTypes.func.isRequired,
  toggleActionsDropdown: PropTypes.func.isRequired,
  isGcatCustomer: PropTypes.bool.isRequired,
  isActionsDropdownOpen: PropTypes.object.isRequired,
  openEditMemberModal: PropTypes.func.isRequired,
  updateContainerWidth: PropTypes.func.isRequired,
  updateGridWidth: PropTypes.func.isRequired,
  leagueProduct: PropTypes.string.isRequired,
  gridWidth: PropTypes.number.isRequired,
  hasMrSyncEnabled: PropTypes.bool.isRequired,
}

export default MembersTableComponent
