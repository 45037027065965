import { ActionTypes } from '../actions'
import Cookies from 'js-cookie'

const {
  CHANGE_FILTER,
  FETCH_INITIAL_DATA_RECEIVED,
} = ActionTypes

const initialFilters = {
  leagueName: '',
  status: [ 'registration_open', 'my_registrations' ],
  tour: 'all',
  viewType: 'condensed',
  advancedFiltersOn: true,
  startDate: '',
  endDate: '',
  location: {
    coords: {lat: '', lng: ''},
    radius: '',
  },
  currentLocation: '',
}

const filters = (state = initialFilters, action) => {
  if (!state.leagueName) {
    state = {
      ...initialFilters,
      ...state,
    }
  }
  
  let filtersFromCookies
  let location
  let startDate
  let endDate

  switch (action.type) {
    case FETCH_INITIAL_DATA_RECEIVED:
      initialFilters.tour = action.data.misc.selectedTour
      initialFilters.viewType = action.data.filters.viewType

      filtersFromCookies = {}

      if (Cookies.get(`filters_${action.data.misc.customerId}`)) {
        const customerFiltersFromCookies = JSON.parse(Cookies.get(`filters_${action.data.misc.customerId}`))
        filtersFromCookies = customerFiltersFromCookies[action.data.misc.directoryId]
      }

      if (filtersFromCookies.location) {
        location = JSON.parse(filtersFromCookies.location)
      }
      
      if (!location || !location.coords.lat) {
        location = action.data.filters.location
      }

      startDate = null
      if (filtersFromCookies.startDate) {
        startDate = filtersFromCookies.startDate.replace(/\+/gi, ' ').replace('GMT ', 'GMT+')
      }

      endDate = null
      if (filtersFromCookies.endDate) {
        endDate = filtersFromCookies.endDate.replace(/\+/gi, ' ').replace('GMT ', 'GMT+')
      }

      state = {
        leagueName: filtersFromCookies.leagueName || action.data.filters.leagueName,
        status: filtersFromCookies.status || action.data.filters.status,
        tour: filtersFromCookies.tour || action.data.filters.tour,
        viewType: filtersFromCookies.viewType || action.data.filters.viewType,
        advancedFiltersOn: filtersFromCookies.advancedFiltersOn || action.data.filters.advancedFiltersOn || true,
        startDate: startDate || action.data.filters.startDate,
        endDate: endDate || action.data.filters.endDate,
        currentLocation: filtersFromCookies.currentLocation || action.data.filters.currentLocation,
        location: location,
      }

      return {
        ...state,
      }
    case CHANGE_FILTER:
      return {
        ...state,
        [action.filter]: action.value,
      }
    default:
      return state
  }
}

export default filters
