import { connect } from 'react-redux'

import HoleSummaryComponent from '../components/hole_summary_component'
import { closePopup, editHole, clearAllShots } from '../actions'

const mapStateToProps = (state) => {
  return {
    playerName: state.popup.playerName,
    foursomePosition: state.popup.foursomePosition,
    holeNumber: state.popup.holeNumber,
    holeId: state.popup.holeId,
    shots: state.popup.shots,
    show: state.popup.visible,
    isCompleted: state.popup.isCompleted,
    par: state.popup.par,
    scoreVerified: state.popup.scoreVerified,
    isAdmin: state.misc.isAdmin,
    isCustomerManager: state.misc.isCustomerManager,
  }
}

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => dispatch(closePopup()),
  editHole: (holeId, shots, completed) => dispatch(editHole(holeId, shots, completed)),
  clearAllShots: (holeId) => dispatch(clearAllShots(holeId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HoleSummaryComponent)
