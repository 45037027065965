import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, ControlLabel } from 'react-bootstrap'
import { Row, Col } from 'react-flexbox-grid'

import { mapToSelectArray } from 'Shared/helpers'
import { GlgSelect } from 'SharedComponents'

class SeasonFilter extends Component {
  constructor(props) {
    super(props)
    this.onChange = props.onChange.bind(this, 'season')
  }

  shouldComponentUpdate(nextProps) {
    // The content of the seasons doesn't change, so it's enough to check the count
    return this.props.seasonsOptions.length !== nextProps.seasonsOptions.length ||
           this.props.value !== nextProps.value
  }

  render() {
    const seasonsSelectOptions = mapToSelectArray(this.props.seasonsOptions, { changeNone: false })
    const seasonText = (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.season_filter.season'))
    return <FormGroup><Row><Col xs={true}><ControlLabel className="add-margin-top-5">{seasonText}</ControlLabel></Col></Row><Row><Col xs={true}><GlgSelect items={ seasonsSelectOptions } value={ this.props.value } onChange={ this.onChange } isCustomized={ this.props.isCustomized }></GlgSelect></Col></Row></FormGroup>
  }
}

SeasonFilter.propTypes = {
  seasonsOptions: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isCustomized: PropTypes.bool,
}

export default SeasonFilter
