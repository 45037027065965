import { ActionTypes } from '../actions'

const {
  CHANGE_FILTERS,
  CHANGE_SEASON,
  CHANGE_STRENGTH,
} = ActionTypes

const initialState = {
  field: 'NAME',
  fieldValue: '',
  strength: 'ALL',
  season: 'All',
}

const filters = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_FILTERS:
      return {
        ...state,
        ...action.data,
      }
    case CHANGE_SEASON:
      return {
        ...state,
        season: action.data,
      }
    case CHANGE_STRENGTH:
      return {
        ...state,
        strength: action.data,
      }
    default: 
      return state
  }
}

export default filters
