import createDeepSelector from './create_deep_selector'
import _ from 'lodash'

const getSeasons = (state, { useManagedCustomer = false } = {}) => {
  if (useManagedCustomer) {
    return state.managedCustomerData.seasons || state.seasons
  } else {
    return state.seasons
  }
}
const getAddAll = (state, { addAll = false } = {}) => addAll

const getSeasonsOptions = createDeepSelector(
  [ getSeasons, getAddAll ],
  (seasonsById, addAll) => {
    let allSeasons = _.values(seasonsById).map(season => ({
      id: season.id,
      name: `${ season.name }` + (season.isCurrent ? ` (${!window.I18n ? '' : window.I18n.t('customer_center_menu.selectors.current')})` : ''),
      isCurrent: season.isCurrent,
    }))
    allSeasons = _.sortBy(allSeasons, season => season.name)

    const currentSeasons = allSeasons.filter(season => season.isCurrent)
    const nonCurrentSeasons = allSeasons.filter(season => !season.isCurrent)

    let options = []
    options = options.concat(currentSeasons)
    if (addAll) {
      options.push({
        id: 'all',
        name: !window.I18n ? '' : window.I18n.t('customer_center_menu.selectors.all'),
      })
    }
    options.push({
      id: 'none',
      name: !window.I18n ? '' : window.I18n.t('customer_center_menu.selectors.no_season'),
    })
    options = options.concat(nonCurrentSeasons)

    return options
  },
)

export default getSeasonsOptions
