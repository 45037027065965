import React, { Fragment, Component, PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import FaIcon from 'react-fa'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import ReactResizeDetector from 'react-resize-detector'
import { isMobile } from 'react-device-detect'

import { getUniqueElementId } from '../helpers'

/*
 * Used for performance optimization: avoids re-rendering rows if not necessary
 */
class InnerList extends PureComponent {
  renderRow(item, index, provided, snapshot) {
    const parityClass = index % 2 === 0 ? 'odd' : 'even'
    const allProps = {
      ...item,
      className: parityClass,
      ...this.props.rowExtraProps,
      isSortable: !!this.props.isSortable,
      rowIndex: index,
    }
    const RowComponent = this.props.rowComponent
    return <RowComponent { ...allProps } innerRef={ provided.innerRef } draggableProps={ provided.draggableProps } dragHandleProps={ provided.dragHandleProps } isDragging={ snapshot.isDragging } columnChildren={ this.props.columnChildren }></RowComponent>
  }

  render() {
    return _.isEmpty(this.props.items) ? <Draggable draggableId={ `empty-draggable-${ this.props.droppableId }` } index={0} type="table">{(provided) => <Fragment><tr ref={ provided.innerRef } { ...provided.draggableProps }><td className="hidden" { ...provided.dragHandleProps }></td><td colSpan={100} className="text-center empty-row">{ provided.draggableProps.style.transform ? '' : this.props.emptyMessage }</td></tr>{ provided.placeholder }</Fragment>}</Draggable> : (
      this.props.items.map((item, index) => <Draggable draggableId={ item.id } index={ index } key={ item.id } type="table">{(provided, snapshot) => <Fragment>{ this.renderRow(item, index, provided, snapshot) }{ provided.placeholder }</Fragment>}</Draggable>)
    )
  }
}
InnerList.propTypes = {
  items: PropTypes.array.isRequired,
  rowExtraProps: PropTypes.object.isRequired,
  isSortable: PropTypes.bool,
  rowComponent: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.func.isRequired,
  ]),
  columnChildren: PropTypes.array.isRequired,
  emptyMessage: PropTypes.string,
  droppableId: PropTypes.string.isRequired,
}

class GlgTable extends Component {
  constructor(props) {
    super(props)
    this.handleSortEnd = this.handleSortEnd.bind(this)
    this.handleTableResize = this.handleTableResize.bind(this)
    this.droppableId = props.nestedId || getUniqueElementId()
    this.state = {
      tableOverflow: (isMobile ? 'scroll' : 'visible'),
    }
  }

  getTableStyle() {
    return {
      maxHeight: this.props.maxHeight,
      overflowY: this.state.tableOverflow,
    }
  }

  handleTableResize(width, height) {
    if (!isMobile && this.props.maxHeight) {
      const isOverflowed = height > this.props.maxHeight
      const newTableOverflow = isOverflowed ? 'scroll' : 'visible'
      this.setState({
        tableOverflow: newTableOverflow,
      })
    }
  }

  handleSortEnd(result) {
    if (!result.destination) {
      // dropped outside the table
      return
    }
    this.props.onSortEnd(result.source.index,
                         result.destination.index)
  }

  renderHeaderContent(props) {
    if (props.children) {
      return props.children
    } else if (props.dataKey !== '#') {
      return _.capitalize(props.dataKey)
    } else {
      return ''
    }
  }

  renderHeader(props) {
    const {
      sortingColumn,
      sortingOrder,
    } = this.props.rowExtraProps
    return <th className={ `glg-table-header ${props.className || ''}` } style={ { width: props.width } }>{ this.renderHeaderContent(props) }{sortingColumn === props.dataKey && <div className={ `sorted-column-icon ${ sortingOrder }` }><FaIcon name={ sortingOrder === 'desc' ? 'sort-up' : 'sort-down' }></FaIcon></div>}</th>
  }

  render() {
    let tableBody = <Droppable droppableId={ this.droppableId } type="table">{provided => <tbody ref={ provided.innerRef } { ...provided.droppableProps }><InnerList items={ this.props.items } rowExtraProps={ this.props.rowExtraProps } isSortable={ this.props.isSortable } rowComponent={ this.props.rowComponent } columnChildren={ this.props.children } emptyMessage={ this.props.emptyMessage } droppableId={ this.droppableId }></InnerList>{ provided.placeholder }</tbody>}</Droppable>

    // Check if table is already inside a DragDropContext
    if (!this.props.nestedId) {
      tableBody = <DragDropContext onDragEnd={ this.handleSortEnd }>{ tableBody }</DragDropContext>
    }

    return <div className={ `glg-react-table ${ this.props.enableHorizontalScroll ? 'table-responsive' : ''} ${ this.props.items.length === 0 ? 'empty-table' : '' }` } style={ this.getTableStyle() }><div><ReactResizeDetector handleHeight={ true } onResize={ this.handleTableResize }><div><table className="table">{this.props.showHeader && <thead><tr>{React.Children.map(this.props.children, child => (
                        child && this.renderHeader(child.props)
                      ))}</tr></thead>}{ tableBody }{this.props.showFooter && <tfoot>{ this.props.footerComponent }</tfoot>}</table></div></ReactResizeDetector></div></div>
  }
}

GlgTable.propTypes = {
  // The array of items. These will be passed on to the row component.
  items: PropTypes.array.isRequired,
  // The component that will be used to render a row
  rowComponent: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.func.isRequired,
  ]),
  // The component that will be used to render the footer
  footerComponent: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.func.isRequired,
  ]),
  // Whether to render the table header
  showHeader: PropTypes.bool,
  // Whether to render the table footer
  showFooter: PropTypes.bool,
  // If the table exceeds this height, it becomes scrollable
  maxHeight: PropTypes.number,
  // Extra props for the row component
  rowExtraProps: PropTypes.object,
  // Enable horizontal scroll
  enableHorizontalScroll: PropTypes.bool,
  // Whether the table rows should be sortable by drag&drop
  isSortable: PropTypes.bool,
  // If this table is already inside a DragDropContext, this is the id of the containing droppable
  nestedId: PropTypes.string,
  // Callback when the rows have just been reordered by drag&drop (only used if sortable = true)
  onSortEnd: PropTypes.func,
  // The message displayed when there are no items in table
  emptyMessage: PropTypes.string,

  children: PropTypes.node,
}

GlgTable.defaultProps = {
  isSortable: false,
  rowStyle: {},
  rowExtraProps: {},
  showHeader: true,
  showFooter: false,
  emptyMessage: '',
  enableHorizontalScroll: true,
}

export default GlgTable
