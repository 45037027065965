import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

class Dft extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { title, links, divisions } = this.props.content
    
    return (
      <div className='widget-content-default widget-content-dft'>
        <div className='title'>
          { title }

          <a href={ links.dft.path } className='btn btn-primary widget-white-title-button'>
            { links.dft.name }
          </a>
        </div>
        <div className='content'>
          {
            divisions.map(division =>
              <div key={ division.name } className='dft-container'>
                <div className={ division.teams.length === 0 ? 'dft-heading empty' : 'dft-heading' }>
                  <div className='dft-heading-title'>
                    <span className='dft-heading-title-name-box'>{ division.name }</span>
                    {
                      division.membershipLink.path === '#'
                        ? `${division.count.name}`
                        : <a href={ division.membershipLink.path }>{ division.count.name }</a>
                    }
                  </div>
                  <div className='dft-heading-actions'>
                    {
                      division.showActions
                        ? <Fragment>
                          <i className='fa fa-caret-up dft-heading-actions-arrow' onClick={ () => this.props.toggleDivisionActions(division.id) } />
                          <div className='dft-heading-actions-container'>
                            {
                              division.links.map(link =>
                                link.show
                                  ? <a key={ link.path } href={ link.path } className={ division.teams.length === 0 ? 'dft-content-link' : 'dft-content-link empty' }>{ link.name }</a>
                                  : null
                              )
                            }
                          </div>
                        </Fragment>
                        : <i className='fa fa-caret-down dft-heading-actions-arrow' onClick={ () => this.props.toggleDivisionActions(division.id) } />
                    }
                  </div>
                </div>
                <div className='dft-content'>
                  {
                    division.teams.map(types =>
                      <div key={ `${Math.random()}` } className='dft-team'>
                        <div className='dft-team-category'>
                          <span className='dft-heading-title-name-box'>
                            { types.category }
                          </span>
                        </div>
                        <div className='dft-team-levels' key={ `${Math.random()}` }>
                          {
                            types.values.map(team =>
                              <div className={ `dft-team-levels-container ${team.subteams.length !== 0 ? '' : 'empty'}` } key={ `${Math.random()}` }>
                                <div className='dft-team-first-level'>
                                  { team.disabled ? 
                                      <a href={ team.path } className='disabled' disabled='true' title={ team.title }>{ team.name }</a>
                                      : <a href={ team.path }>{ team.name }</a>
                                  }
                                </div>
                                <div className='dft-team-second-level'>
                                  {
                                    team.subteams.map(subteam =>
                                      <div key={ subteam.path } className='dft-team-second-level-item'>
                                        <span>
                                          { subteam.name }
                                        </span>
                                      </div>
                                    )
                                  }
                                </div>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>)
          }
        </div>
      </div>
    )
  }
}

Dft.propTypes = { 
  content: PropTypes.object,
  toggleDivisionActions: PropTypes.func,
}


Dft.defaultProps = {
  content: {
    message: '',
  },
}

export default Dft
