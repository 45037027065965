import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class GlgBasicSelect extends PureComponent {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }
  
  handleChange(e){
    this.props.onChange(e.target.value, e)
  }

  render() {
    return <select onChange={ this.handleChange } disabled={ this.props.disabled } name={ this.props.name } value={ this.props.value } prompt={ this.props.prompt } className={ this.props.selectClass }><option disabled={this.props.value !== ''}>{ this.props.prompt }</option>{ this.props.items.map((item) => <option value={item.value} key={item.value}>{ item.label }</option>)
        }</select>
  }
}

GlgBasicSelect.propTypes = {
  // The input name
  name: PropTypes.string,
  // The array of options
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  // Controlled selected option value
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  // Callback when the selected value changes
  onChange: PropTypes.func,
  // Initially selected option value
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  // If the select is disabled, false by default
  disabled: PropTypes.bool,
  //prompt
  prompt: PropTypes.string,
  // Custom classes to be added to select
  selectClass: PropTypes.string,
}

GlgBasicSelect.defaultProps = {
  disabled: false,
}

export default GlgBasicSelect
