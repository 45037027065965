import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const popupParRow = ({pars, isMultiRound, matchedScores}) => {

  const frontNineParsSum = pars.slice(0, 9).reduce((a, b) => a + b, 0)
  const backNineParsSum = pars.slice(9, 18).reduce((a, b) => a + b, 0)

  return (
    <tr className='par_row with_delimiter'>
      <td className='with_border'>Par</td>
      { [ ...Array(9) ].map( ( _, i) => (
        <Fragment key={i}>
          <td key={'hole-' + i} className={matchedScores[i] ? 'with_border' : 'with_border yellow_column'}>{pars[i]}</td>
          { (i + 1) % 3 === 0 && i !== 8 && <td className="with_border gray_column"></td> }
        </Fragment>
      )) }
      <td className='with_border blue_column'>{frontNineParsSum}</td>
      { [ ...Array(9) ].map( ( _, i) => (
        <Fragment key={i}>
          <td key={'hole-' + i} className={matchedScores[i + 9] ? 'with_border' : 'with_border yellow_column'}>{pars[i + 9]}</td>
          { (i + 1) % 3 === 0 && i !== 8 && <td className="with_border gray_column"></td> }
        </Fragment>
      )) }
      <td className='with_border blue_column'>{backNineParsSum}</td>
      <td className='with_border blue_column'>{frontNineParsSum + backNineParsSum}</td>
      { isMultiRound && <td className='with_border blue_column'></td> }
    </tr>
  )
}

popupParRow.propTypes = {
  pars: PropTypes.array,
  isMultiRound: PropTypes.bool,
  matchedScores: PropTypes.array,
}

export default popupParRow
