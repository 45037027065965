import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { Row } from 'react-flexbox-grid'

class Popup extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={ 'modal ' + this.props.displayMode} id="popup-element" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              { this.props.CONTENT }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Popup.propTypes = {
  CONTENT: PropTypes.any,
  displayMode: PropTypes.any,
}

export default Popup
