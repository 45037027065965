import PropTypes from 'prop-types'
import GolferInfoComponent from './golfer_info_component'
import TourEventsEarningsTable from './tour_events_earnings_table'
import React, {Fragment} from 'react'

const GolferDetailedResults = ({name, photo, earnings, timesPlayed, wonGames, backLink, tourEvents}) => {

  return (
    <Fragment>
      <a className="btn btn-default pull-left" href={backLink}>{window.I18n.t('buttons.back_capitalized')}</a>
      <div className="add-padding-top-45">
        <GolferInfoComponent
          profileUrl={photo}
          name={name} earnings={earnings} won={wonGames}
          totalEvents={timesPlayed}
          isPortal={true}/>
        <TourEventsEarningsTable tourEvents={tourEvents}/>
      </div>
    </Fragment>)
}

GolferDetailedResults.propTypes = {
  name: PropTypes.string,
  photo: PropTypes.string,
  earnings: PropTypes.any,
  timesPlayed: PropTypes.number,
  wonGames: PropTypes.number,
  backLink: PropTypes.string,
  tourEvents: PropTypes.array,
}

export default GolferDetailedResults
