import React from 'react'
import PropTypes from 'prop-types'

import NavigatorOptionContainer from '../containers/navigator_option_container'

const NavigatorOptionsList = ({
  navigatorOptions,
}) => <div className="navigator-options-list">{navigatorOptions.map((navigatorOption, index) => <NavigatorOptionContainer key={ navigatorOption.id } index={ index + 1 } { ...navigatorOption }></NavigatorOptionContainer>)}</div>

NavigatorOptionsList.propTypes = {
  navigatorOptions: PropTypes.array.isRequired,
}

export default NavigatorOptionsList
