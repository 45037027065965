import { ActionTypes } from '../actions'

const {
  FETCH_STATISTIC_DATA,
  FETCH_STATISTIC_DATA_FAILED,
} = ActionTypes

const initialState = {
}

const statistics = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STATISTIC_DATA:
      return state.map(statistic => statistic.key === action.key ? {...statistic, content: action.data.players} : statistic)
    case FETCH_STATISTIC_DATA_FAILED:
      return state.map(statistic => statistic.key === action.key ? {...statistic, content: statistic.content || [] } : statistic)
    default:
      return state
  }
}

export default statistics
