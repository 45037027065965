import { combineReducers } from 'redux'

import transactionsHistory from './transactions_history'
import participationHistory from './participation_history'
import membershipPrograms from './membership_programs'

export default combineReducers({
  transactionsHistory,
  participationHistory,
  membershipPrograms,
})
