import { connect } from 'react-redux'
import MembersFilterComponent from '../../components/members_filter/members_filter_component'
import { fetchInitialMembersData, changeFilterOptions } from '../../actions/index'

const mapStateToProps = (state) => ({
  dropdownId: 'show_more_members',
  filterOptions: state.membersData.filterOptions,
  searchParams: state.membersData.searchParams,
  isMembersDataLoading: state.membersData.isLoading,
  isMembersCountLoading: state.membersData.isMembersCountLoading,
  filterOptionsSet: state.membersData.filterOptionsSet,
})

const mapDispatchToProps = (dispatch) => ({
  fetchInitialMembersData: (searchParams) => dispatch(fetchInitialMembersData(searchParams)),
  changeFilterOptions: (category, value, checked) => dispatch(changeFilterOptions(category, value, checked)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MembersFilterComponent)
