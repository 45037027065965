import { connect } from 'react-redux'
import moment from 'moment'

import NavigatorComponent from '../components/navigator_component'

import {
  changeDropdownState,
  openPopup,
} from '../actions'

const mapStateToProps = (state, props) => ({
  isDropdownOpen: state.misc.isDropdownOpen,
  context: state.misc.context,
  filterValue: state.misc.navigatorFilter,
  // Do not show the new feature popup after May 1, 2019
  showFeatureNotification: props.showFeatureNotification && moment().isBefore(moment('2019-05-01')),
})

const mapDispatchToProps = dispatch => ({
  onDropdownStateChange: isOpen => dispatch(changeDropdownState(isOpen)),
  openPopup: () => dispatch(openPopup()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigatorComponent)
