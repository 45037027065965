import React, { Component } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { GlgSelect } from 'SharedComponents'
import PropTypes from 'prop-types'
import {
  SCHEDULE_FREQUENCY_OPTIONS,
  SCHEDULE_HOUR_OPTIONS,
  SCHEDULE_WEEKDAY_OPTIONS,
  SCHEDULE_DAYS_OF_MONTH_OPTIONS,
} from '../constants'

class ScheduleComponent extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const {
      schedule: {
        frequency,
        hour,
        weekday,
        dayOfMonth,
      },
      setScheduleFrequency,
      setScheduleHour,
      setScheduleWeekday,
      setScheduleDayOfMonth,
    } = this.props

    return <div><Row className="vertical-align add-margin-top-10 add-margin-bottom-5"><Col xs={3} sm={2} lg={1}><span>{ window.I18n.t('marketing_campaigns.components.marketing_campaigns_setup_component.schedule') }</span></Col><Col xs={9} sm={4} lg={2}><GlgSelect items={ SCHEDULE_FREQUENCY_OPTIONS.map((obj) => ({value: obj.value, label: window.I18n.t('marketing_campaigns.components.schedule_component')[obj.value]})) } value={frequency} onChange={ (selectedValue) => { setScheduleFrequency(selectedValue) } }></GlgSelect></Col></Row>{frequency !== 'right_now' && <Row className="vertical-align add-margin-top-5 add-margin-bottom-10"><Col xs={3} sm={2} lg={1}><span>{frequency === 'daily' && window.I18n.t('marketing_campaigns.components.schedule_component.when')}{frequency !== 'daily' && window.I18n.t('marketing_campaigns.components.schedule_component.every')}</span></Col>{frequency === 'weekly' && <Col xs={9} sm={4} lg={2}><GlgSelect items={ SCHEDULE_WEEKDAY_OPTIONS.map((obj, idx) => ({value: obj.value, label: window.I18n.t('date.day_names')[idx]})) } value={weekday} onChange={ (selectedWeekday) => { setScheduleWeekday(selectedWeekday) } }></GlgSelect></Col>}{frequency === 'monthly' && <Col xs={9} sm={4} lg={2}><GlgSelect items={ SCHEDULE_DAYS_OF_MONTH_OPTIONS } value={dayOfMonth} onChange={ (selectedDayOfMonth) => { setScheduleDayOfMonth(selectedDayOfMonth) } }></GlgSelect></Col>}{frequency !== 'daily' && <Row className="vertical-align add-margin-top-5 add-margin-bottom-5"><Col xs={3} sm={2} lg={1}><span>{ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.schedule_component.at_word') }</span></Col></Row>}<Col xs={9} sm={4} lg={2}><GlgSelect items={ SCHEDULE_HOUR_OPTIONS } value={ hour } onChange={ (selectedHour) => { setScheduleHour(selectedHour) } }></GlgSelect></Col></Row>}</div>
  }
}

ScheduleComponent.propTypes = {
  schedule: PropTypes.shape({
    frequency: PropTypes.string,
    hour: PropTypes.string,
    weekday: PropTypes.string,
    dayOfMonth: PropTypes.string,
  }),
  setScheduleFrequency: PropTypes.func.isRequired,
  setScheduleHour: PropTypes.func.isRequired,
  setScheduleWeekday: PropTypes.func.isRequired,
  setScheduleDayOfMonth: PropTypes.func.isRequired,
}

export default ScheduleComponent
