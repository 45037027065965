import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

class ReportCenterItemPublished extends Component {

  stringParameterize(str) {
    return str.trim().toLowerCase()
      .replace(/[^a-zA-Z0-9 -]/g, '')
      .replace(/ +/g, ' ')
      .replace(/[\s-]/g, '_')
  }
  
  render() {
    return (
      <div className={ 'report-list-item report_center_item_inline' + ( this.props.job.error ? ' error' : '' ) } id={ this.props.report_center_item.id }>
        <div className= { 'name' + (this.props.report_center_item.name.length > 18 ? ' small-name' : '')}>
          <div className='nameContainer'>
            {
              // link to original context
              [ 'Gpu::Report', 'SpreadsheetReport', 'PublisherTemplate', 'Gpu::Scorecard' ].indexOf(this.props.report_center_item.report_type) !== -1 && this.props.is_admin && this.props.originalContextLink ?
                <a title={this.props.originalContextLink} href={this.props.originalContextLink} target="_blank" className='pull-left edit-name-link'>
                  <i className='fa fa-external-link fa-fw'></i>
                </a> : <a></a>
            }
            { 
              // edit icon
              this.props.published_status === 'pending' ?
              ([ 'Gpu::Report', 'SpreadsheetReport', 'Gpu::Scorecard' ].indexOf(this.props.report_center_item.report_type) !== -1 &&
                (this.props.edit_name === false &&
                  <a onClick={this.props.toggleItemName} className='pull-right edit-name-link'>
                    <i className='fa fa-pencil fa-fw'></i>
                  </a>
                )
              ) : (
                <a></a>
              )

            }
            { // name input field
              this.props.edit_name ? (
                <input autoFocus type='text'
                  defaultValue={this.props.report_center_item.name}
                  ref={(input) => { this.nameInput = input }}
                  onKeyPress={ target => {
                                            if (target.key === 'Enter') {
                                              this.props.saveItemName(this.nameInput.value)
                                            }
                                          }
                  }
                  onBlur={ () => { this.props.saveItemName(this.nameInput.value) }} />
              ) : (
                !window.I18n ? '' : window.I18n.t(`download_center.item_name.${this.stringParameterize(this.props.report_center_item.name)}`, {defaultValue: this.props.report_center_item.name.toUpperCase()})
              )
            }
          </div>
        </div>
        <div className='body'>
          {
            //job in progress
            this.props.job.id !== null &&
              <div className="job">
                <img src={require('bootstrap_theme/job/Spinning-circle.gif')} />
              </div>
          }
          <div className={ this.props.job.id === null && this.props.job.completed_links === null ? '' : 'hidden' }>
            <div className={ 'picture' + ((this.props.report_center_item.image_url === null) ? ' no-image' : '')}>
              <img className={this.props.report_center_item.report_type === 'SpreadsheetReport' && this.props.report_center_item.image_url !== null ? 'spreadsheet' : '' } src={this.props.report_center_item.image_url || require('report_center/no-image.png')} />
            </div>
            <div className='menu'>
              <a onClick={ [ 'Gpu::Report', 'Pairing', 'Gpu::Scorecard' ].indexOf(this.props.report_center_item.report_type) !== -1 ?
                  //if GPU::Report - tell modal to load print_options form from edit_conflicts_path and submit throught printReport or cancel throught setJobId of this very rci
                  //otherwise just printReport right away
                  () => { this.props.openPrintModal({ edit_conflicts_path: this.props.report_center_item.action[1], rci_base_printReport: this.props.printReport, rci_base_setJobId: this.props.setJobId}) }
                : () => { this.props.printReport() } }>
                <div className='btsp_orange_button'>
                  {this.props.report_center_item.action[0]}
                </div>
              </a>
              {
                this.props.report_center_item.edit_path &&
                  (
                    this.props.report_center_item.edit_path[0].length === 2 ?
                      <div>
                        <a target="_blank" rel='noopener noreferrer'
                           href={ this.props.report_center_item.edit_path[0][1] } >
                          <div className={ 'btsp_orange_button'}>
                            {this.props.report_center_item.edit_path[0][0]}
                          </div>
                        </a>
                        <a target="_blank" rel='noopener noreferrer'
                           href={ this.props.report_center_item.edit_path[1][1] }>
                          <div className={ 'btsp_orange_button'}>
                            {this.props.report_center_item.edit_path[1][0]}
                          </div>
                        </a>
                      </div>
                    :
                      <a target="_blank" rel='noopener noreferrer'
                         href={ this.props.report_center_item.edit_path[1] }>
                        <div className={ 'btsp_orange_button'}>
                          {this.props.report_center_item.edit_path[0]}
                        </div>
                      </a>
                  )
              }
              <div className='btn-group'>
                <button className='btn btn-default btsp_orange_button hover-dropdown-button'>
                {!window.I18n ? '' : window.I18n.t('download_center.categories')} &nbsp;
                  <span className='caret'></span>
                </button>
                <ul className='hover-dropdown'>
                  {this.props.report_center_lists.filter(rcl => !rcl.read_only).map(function (rcl) {
                    return (
                      <li key={rcl.id}>
                        <div className="checkbox btsp_checkbox checkbox-primary no-margin">
                          <input id={ 'dd_inline_' + this.props.report_center_item.id + '_' + rcl.id }
                                 type='checkbox'
                                 className=''
                                 name='report_center_item[report_center_list_ids][]'
                                 onChange={(e) => {
                                   this.props.addRemoveItemToList(this.props.report_center_item.id, rcl.id, e)
                                 }}
                                 defaultChecked={rcl.report_center_items.map(rci => rci.parent_id).indexOf(this.props.report_center_item.id) !== -1 ? 'checked' : '' } />
                           <label htmlFor={ 'dd_inline_' + this.props.report_center_item.id + '_' + rcl.id }>
                              {!window.I18n ? '' : window.I18n.t(`download_center.report_center_list.names.${this.stringParameterize(rcl.name)}`, {defaultValue: rcl.name})}
                          </label>
                        </div>
                      </li>)
                  }, this)
                }
                </ul>
              </div>
            </div>
          </div>
          {
            //overlay after download has finished succesfully
            this.props.job.id === null && this.props.job.completed_links !== null &&
              <div className='menu post_print'>
                <a onClick={ () => this.props.setJobId({ id: null, error: false, completed_links: null}) }>
                  <i className='fa fa-times'></i>
                </a>
                {
                  this.props.job.completed_links['pdf_path'] ?
                    <div>
                      <a target='_blank' rel='noopener noreferrer'
                         href={this.props.job.completed_links['pdf_path']}>
                        <div className={ 'btsp_orange_button'}>
                          { this.props.job.completed_links.button_label }
                        </div>
                      </a>
                    </div> : ''
                }
                { 
                  this.props.job.completed_links['primary_docx_service'] !== null ?
                    (
                      <a onClick={ this.props.generateDocx }>
                        <div className='btsp_orange_button'>
                          {!window.I18n ? '' : window.I18n.t('download_center.generate_doc')}
                        </div>
                      </a>
                    ) : (
                      (this.props.job.completed_links['docx_path'] || null) !== null ?
                        <div>
                          <a target='_blank' rel='noopener noreferrer'
                             href={ this.props.job.completed_links['docx_path'] }>
                            <div className={ 'btsp_orange_button'}>
                              {!window.I18n ? '' : window.I18n.t('download_center.download_doc')}
                            </div>
                          </a>
                        </div> : ''
                    ) 
                }
                { 
                  this.props.job.completed_links['generate_txt'] !== null ?
                    (
                      <a onClick={ this.props.generateTxt }>
                        <div className='btsp_orange_button'>
                          {!window.I18n ? '' : window.I18n.t('download_center.generate_txt')}
                        </div>
                      </a>
                    ) : (
                      (this.props.job.completed_links['txt_path'] || null) !== null ?
                        <div>
                          <a target='_blank' rel='noopener noreferrer'
                             href={ this.props.job.completed_links['txt_path'] }>
                            <div className={ 'btsp_orange_button'}>
                              {!window.I18n ? '' : window.I18n.t('download_center.download_txt')}
                            </div>
                          </a>
                        </div> : ''
                    ) 
                }
              </div>
          }
        </div>
        <div className='customer-icon-wrapper float-left'>
          <a href="#" onClick={ () => { this.props.setPreview(this.props.report_center_item) } }>
            <div style={ { backgroundImage: 'url(' + (get(this.props.customers_info[this.props.report_center_item.customer_id], 'customer_logo') || require('usga/home/logos/default_logo_large.png')) + ')' } } className='customer-icon'/>
          </a>
        </div>
        <div className='stats pull-right'>
          <div className='customer-name'>
            <a href="#" onClick={ () => { this.props.setPreview(this.props.report_center_item) } }> { get(this.props.customers_info[this.props.report_center_item.customer_id], 'customer_name') || '-' } </a>
          </div>
          <span className='pull-left'>
            <i className='fa fa-fw fa-list'></i>
            { this.props.report_center_item.list_count || 0}
          </span>
          { 
            this.props.report_center_item.image_url &&
              <a onClick={ this.props.togglePreview } className="zoom">
                <i className='fa fa-fw fa-search'></i>
                {
                  this.props.show_preview && 
                    <div>
                      <div className="shadow"></div>
                      <img src={this.props.report_center_item.image_url || require('report_center/no-image.png')} />
                      <a onClick={ this.props.togglePreview }>
                        <img src={require('bootstrap_theme/close.png')} className="close_button"/>
                      </a>  
                    </div>
                }
              </a>
          }
          <span className='pull_right'>
            <i className='fa fa-fw fa-print'></i>
            { this.props.report_center_item.print_count || 0 }
          </span>
        </div>
      </div>
    )
  }
}

ReportCenterItemPublished.propTypes = {
  is_admin: PropTypes.bool.isRequired,
  published_status: PropTypes.string.isRequired,
  job: PropTypes.object.isRequired,
  report_center_item: PropTypes.object.isRequired,
  report_center_lists: PropTypes.array.isRequired,
  customers_info: PropTypes.object.isRequired,
  show_preview: PropTypes.bool.isRequired,
  setJobId: PropTypes.func.isRequired,
  generateDocx: PropTypes.func.isRequired,
  generateTxt: PropTypes.func.isRequired,
  setPreview: PropTypes.func.isRequired,
  togglePreview: PropTypes.func.isRequired,
  addRemoveItemToList: PropTypes.func.isRequired,
  printReport: PropTypes.func.isRequired,
  openPrintModal: PropTypes.func.isRequired,
  toggleItemName: PropTypes.func.isRequired,
  edit_name: PropTypes.bool.isRequired,
  saveItemName: PropTypes.func.isRequired,
  originalContextLink: PropTypes.string,
}

export default ReportCenterItemPublished
