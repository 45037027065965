import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledFormComponents = styled.div`
  .container {
    width: 200px;
    height: 200px;
    margin: 0px;
    padding: 0px;

    .image {
      display: flex;
      justify-content: center;

      .flyer_image {
        width: 160px;
        height: 160px;
        object-fit: contain;
      }
    }

    .delete-image {
      display: flex;
      justify-content: center;
      padding-top: 10px;

      .delete-button {
        color: red;
      }

      .delete-button:hover {
        cursor: pointer;
      }
    }
  }

`

const FlyerImage = (props) => {
  return (
    <StyledFormComponents>
      <div className="container">
        <div className="image">
          <img src={props.url} alt="Image" className='flyer_image' />
        </div>
        <div className="delete-image">
          <div className="delete-button" onClick={() => props.deleteImage()}>
            { window.I18n.t('views.golfhub.delete_image_from_asset') }
          </div>
        </div>
      </div>
    </StyledFormComponents>
  )
}

FlyerImage.propTypes = {
  url: PropTypes.string,
  deleteImage: PropTypes.func,
}

export default FlyerImage
