export const ActionTypes = {
  PLAINLY_SET_BLUE_BOX_PROPERTY: 'PLAINLY_SET_BLUE_BOX_PROPERTY',
  SET_CONTAINING_SLIDE_PROPERTY: 'SET_CONTAINING_SLIDE_PROPERTY',
  TOGGLE_BLUE_BOX_PROPERTY: 'TOGGLE_BLUE_BOX_PROPERTY',
  MOVE_TO_NEXT_SLIDE: 'MOVE_TO_NEXT_SLIDE',
  UPDATE_TO_LATEST_SLIDESHOW: 'UPDATE_TO_LATEST_SLIDESHOW',
  SET_HEADER_HEIGHT: 'SET_HEADER_HEIGHT',
  SET_FOOTER_HEIGHT: 'SET_FOOTER_HEIGHT',
}

export const plainlySetBlueBoxProperty = (property, value) => {
  return {
    type: ActionTypes.PLAINLY_SET_BLUE_BOX_PROPERTY,
    property,
    value,
  }
}

export const setContainingSlideProperty = (advancedSlideId, containedSlideId, property, value) => {
  return {
    type: ActionTypes.SET_CONTAINING_SLIDE_PROPERTY,
    advancedSlideId,
    containedSlideId,
    property,
    value,
  }
}

export const toggleBlueBoxProperty = (property) => {
  return {
    type: ActionTypes.TOGGLE_BLUE_BOX_PROPERTY,
    property,
  }
}

export const moveToNextSlide = () => {
  return {
    type: ActionTypes.MOVE_TO_NEXT_SLIDE,
  }
}

export const updateToLatestSlideshow = (updateResponse) => {
  return {
    type: ActionTypes.UPDATE_TO_LATEST_SLIDESHOW,
    tvShowSettings: updateResponse.tv_show,
    slides: updateResponse.slides,
  }
}

export const setHeaderHeight = (headerHeight) => {
  return {
    type: ActionTypes.SET_HEADER_HEIGHT,
    headerHeight,
  }
}

export const setFooterHeight = (footerHeight) => {
  return {
    type: ActionTypes.SET_FOOTER_HEIGHT,
    footerHeight,
  }
}
