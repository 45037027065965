import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

import { GlgIcon } from 'SharedComponents'

class SortingOrderToggle extends PureComponent {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    if (!this.props.disabled) {
      let other
      if (this.isSortOrderAscending()) {
        other = 'desc'
      } else {
        other = 'asc'
      }
      this.props.onChange(other)
    }
  }

  isSortOrderAscending() {
    return this.props.sortingOrder === 'asc' ||
           this.props.sortingOrder === ''
  }

  getIconType() {
    if (this.props.sortingColumnType === 'number' ||
        this.props.sortingColumnType === 'date') {
      return '1-9'
    } else {
      return 'az'
    }
  }

  render() {
    const iconType = this.getIconType()

    return <Row className={ `sorting-order-toggle clickable ${ this.props.disabled ? 'disabled' : '' }` } middle="xs" center="xs" onClick={ this.handleClick }><Col xs={true}>{this.isSortOrderAscending()
          ? <GlgIcon icon={ `sort-by-${ iconType }-down` }></GlgIcon>
          : <GlgIcon icon={ `sort-by-${ iconType }-up` }></GlgIcon>}</Col></Row>
  }
}

SortingOrderToggle.propTypes = {
  sortingOrder: PropTypes.string.isRequired,
  sortingColumnType: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default SortingOrderToggle
