import { ActionTypes } from '../actions'

const {
  CHANGE_SORT_OPTION,
  CHANGE_SORT_ORDER_OPTION,
} = ActionTypes

const initialState = {
  sortByOption: 'hole',
  sortOrder: 'asc',
}

const filters = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SORT_OPTION:
      return {
        ...state,
        sortByOption: action.value,
      }
    case CHANGE_SORT_ORDER_OPTION:
      return {
        ...state,
        sortOrder: action.value,
      }
    default:
      return state
  }
}

export default filters
