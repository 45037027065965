import {connect} from 'react-redux'
import Draft from '../../components/setup/draft_component'
import {
  addNewTeam,
  changeTeamName,
  deleteTeam,
  defaultSetupEmptyTeamset,
  changeTeamCaptain,
  reorderTeams,
  changeOrder,
  changeRoundOrder,
  changeRankingMethod,
  changePickTime,
  changePrevLeagueId,
  changeGrossScores,
  saveDraft,
} from '../../actions/setup_index'

const mapStateToProps = (state) => ({
  importGolfersUrl: state.misc.importGolfersUrl,
  manuallyAddGolfersUrl: state.misc.manuallyAddGolfersUrl,
  uploadFromSpreadsheetUrl: state.misc.uploadFromSpreadsheetUrl,
  teams: state.misc.teams,
  golfers: state.misc.golfers,
  previousLeagues: state.misc.previousLeagues,
  teamCaptains: state.misc.teamCaptains,
  order: state.drafts.order,
  roundsOrder: state.drafts.roundsOrder,
  roundsCount: state.misc.roundsCount,
  rankingMethod: state.drafts.rankingMethod,
  prevLeagueId: state.drafts.prevLeagueId,
  grossScores: state.drafts.grossScores,
  pickTime: state.drafts.pickTime,
  draftsUrl: state.misc.draftsUrl,
  saveInProgress: state.misc.saveInProgress,
  action: state.misc.action,
})

const mapDispatchToProps = (dispatch) => ({
  defaultSetupEmptyTeamset: () => dispatch(defaultSetupEmptyTeamset()),
  addNewTeam: () => dispatch(addNewTeam()),
  deleteTeam: (team) => dispatch(deleteTeam(team)),
  changeTeamName: (idx, newName) => dispatch(changeTeamName({idx, newName})),
  changeTeamCaptain: (data) => dispatch(changeTeamCaptain(data)),
  reorderTeams: (data) => dispatch(reorderTeams(data)),
  changeOrder: (data) => dispatch(changeOrder(data)),
  changeRoundOrder: (roundNo, order) => dispatch(changeRoundOrder({roundNo, order})),
  changeRankingMethod: (data) => dispatch(changeRankingMethod(data)),
  changePickTime: (data) => dispatch(changePickTime(data)),
  changePrevLeagueId: (data) => dispatch(changePrevLeagueId(data)),
  changeGrossScores: (data) => dispatch(changeGrossScores(data)),
  saveDraft: () => dispatch(saveDraft()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Draft)
