import { connect } from 'react-redux'
import EditCustomFieldsComponent from '../../components/add_golfers_manually/edit_custom_fields_component'
import { closeEditCustomFieldsPopup, openAddGolfersManuallyPopup, fetchLeagueMetadata, saveNewMemberField, changeCurrentCustomFields } from '../../actions/index'

const mapStateToProps = (state) => ({
  show: state.addNewMembers.showEditCustomFieldsPopup,
  isLoading: state.addNewMembers.isLoading,
  membersMetadata: state.addNewMembers.membersMetadata,
  affiliationAnswers: state.addNewMembers.affiliationAnswers,
  sortedCountries: state.addNewMembers.sortedCountries,
  playerIndex: state.addNewMembers.playerIndex,
  newMembers: state.addNewMembers.newMembers,
  disableSaveFields: state.addNewMembers.disableSaveFields,
  customFields: state.addNewMembers.currentCustomFields,
  ckeditorPath: state.misc.ckeditorPath,
  leagueId: state.addNewMembers.leagueId,
})

const mapDispatchToProps = (dispatch) => ({
  closeEditCustomFieldsPopup: () => dispatch(closeEditCustomFieldsPopup()),
  openAddGolfersManuallyPopup: () => dispatch(openAddGolfersManuallyPopup()),
  fetchLeagueMetadata: () => dispatch(fetchLeagueMetadata()),
  saveNewMemberField: (name, value, index) => dispatch(saveNewMemberField(name, value, index)),
  changeCurrentCustomFields: (id, category, name, value, answer, answerId) => dispatch(changeCurrentCustomFields(id, category, name, value, answer, answerId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCustomFieldsComponent)
