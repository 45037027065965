import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { displayMode, DISPLAY_MODES } from '../../actions'

const AddBlock = ({ dispatch }) => {
  return (
    <div>
      <button type="button" 
              onClick={(e) => { e.preventDefault(); dispatch(displayMode(DISPLAY_MODES.BLOCK)) } }
              className="add-block-btn">
        { window.I18n.t('multi_widgets.add_new_block') }
      </button>
    </div>
  )
}

AddBlock.propTypes = {
  dispatch: PropTypes.func,
  activeTab: PropTypes.number,
}

export default connect()(AddBlock)
