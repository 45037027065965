import _ from 'lodash'

import { ActionTypes } from '../actions'

const {
  UPDATE_NAVIGATOR_OPTION,
  DELETE_NAVIGATOR_OPTION,
  CREATED_NAVIGATOR_OPTION,
} = ActionTypes

const DEFAULT_NAVIGATOR_OPTIONS_DATA = {
  id: '',
  name: '',
  context: '',
  tags: [],
  alternateTags: [],
  location: '',
  url: '',
  weight: 1.0,
  internalId: '',
  helpLinks: [],
}

const navigatorOptions = (state = {}, action) => {
  switch (action.type) {
    case DELETE_NAVIGATOR_OPTION: {
      return _.omit(state, action.id)
    }
    case UPDATE_NAVIGATOR_OPTION:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          ...action.data,
        },
      }
    case CREATED_NAVIGATOR_OPTION: {
      return {
        ...state,
        [action.data.id]: {
          ...DEFAULT_NAVIGATOR_OPTIONS_DATA,
          ...action.data,
        },
      }
    }
    default:
      return state
  }
}

export default navigatorOptions
