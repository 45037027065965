import React from 'react'
import PropTypes from 'prop-types'
import errorIcon from 'widgets/icons/error.png'


const OttErrorPopup = ({message, handleClose}) => {
  return (
    <div className={'popup-container-style'}>
      <div className={'error-popup-style'}>
        <div className={'ott-title-error'}><img src={errorIcon}/>  Error </div>
        <div className={'ott-title-footer-style'}> {message} </div>
        <div className={'button-error-container-style'}>
          <div className={'back-button-style'} onClick={handleClose}>
            Back
          </div>
        </div>
      </div>
    </div>
  )
}

OttErrorPopup.propTypes = {
  message: PropTypes.string,
  handleClose: PropTypes.func,
}

export default OttErrorPopup
