import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import { GlgList } from 'SharedComponents'
import PortalItemComponent from './portal_item_component'

class PortalsListComponent extends PureComponent {

  componentDidUpdate(prevProps) {
    if (prevProps.dataIsLoading && !this.props.dataIsLoading) {
      setTimeout(() => {
        try {
          window.parent.scroll(0, this.props.scrollPosition)
        } catch (err) {
          // 1 == 1
        }
      }, 0)
    }
  }

  render() {
    return <Fragment>{this.props.portals.length > 0 && <Fragment><div className="page_title add-padding-top-10 font-size-14 labels-margin-xs"><div className="text">{ !window.I18n ? '' : window.I18n.t('customer_directories.portals') }</div></div><div className="add-margin-top-10"><GlgList items={ this.props.portals } itemComponent={ PortalItemComponent } isSortable={ false }></GlgList></div></Fragment>}</Fragment>      
  }
}

PortalsListComponent.propTypes = {
  portals: PropTypes.array,
  dataIsLoading: PropTypes.bool,
  scrollPosition: PropTypes.number,

}

export default PortalsListComponent
