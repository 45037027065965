import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import SalesDemoLeaguesTableContainer from '../../containers/sales_demo/sales_demo_leagues_table_container'

const SalesDemoContentComponent = ({ categories }) => <Fragment>{categories.map(category => <Fragment key={ category.id }><h3>{ category.name }</h3><SalesDemoLeaguesTableContainer categoryId={ category.id }></SalesDemoLeaguesTableContainer></Fragment>)}</Fragment>

SalesDemoContentComponent.propTypes = {
  categories: PropTypes.array.isRequired,
}

export default SalesDemoContentComponent
