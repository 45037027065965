import React, { Component } from 'react'
import Iframe from 'react-iframe'
import PropTypes from 'prop-types'

import { TabContainer, TabContent, TabPane, Nav, NavItem, Row, Navbar} from 'react-bootstrap'

import ParticipationHistoryContainer from '../containers/participation_history_container'
import TransactionsHistoryContainer from '../containers/transactions_history_container'
import MembershipProgramsContainer from '../containers/registration_container'

const { Collapse, Toggle } = Navbar

const playerProfile = <span className="section-title"><i className="gga-mr-popup-player-profile"></i>{ !window.I18n ? '' : window.I18n.t('player_activity.player_profile') }</span>
const registration = <span className="section-title"><i className="gga-mr-popup-registration"></i>{ !window.I18n ? '' : window.I18n.t('player_activity.registration_membership') }</span>
const tiSummary = <span className="section-title"><i className="gga-mr-popup-hi-18hole"></i>{ !window.I18n ? '' : window.I18n.t('player_activity.ti_summary') }</span>
const participationHistory = <span className="section-title"><i className="gga-mr-popup-participation-history"></i>{ !window.I18n ? '' : window.I18n.t('player_activity.participation_history_tab') }</span>
const transactions = <span className="section-title"><i className="gga-mr-popup-transactions"></i>{ !window.I18n ? '' : window.I18n.t('player_activity.transactions') }</span>
const tabTitles = {'player-profile': playerProfile,
  'registration': registration,
  'ti-summary': tiSummary,
  'participation-history': participationHistory,
  'transactions': transactions,
}

class PlayerActivityDashboardComponent extends Component {  
  constructor(props) {
    super(props)
    this.state = {
      playerProfileUrl: '/profiles/' + props.memberCardId + '?comesFromMasterRoster=true',
      HISummaryUrl: props.HISummaryUrl,
      buttonTitle: props.hasPlayerProfiles ? playerProfile : participationHistory,
      buttonMenuClass: 'down',
      defaultActiveKey: 'player-profile',
    }
    this.fetchMembershipProgramsData = this.props.fetchMembershipProgramsData.bind(this)
    this.fetchParticipationHistoryData = this.props.fetchParticipationHistoryData.bind(this)
    this.fetchTransactionsData = this.props.fetchTransactionsData.bind(this)
  }
  
  componentWillMount() {
    if (!this.props.hasPlayerProfiles) {
      this.fetchParticipationHistoryData()
      this.setState({ defaultActiveKey: 'participation-history' })
    }
  }
  
  render() {
    let target = '_blank'
    if (this.props.isGcatRequest) {
      target = '_top'
    }
    
    let playerProfileComp = ''
    let playerProfileCompContent = ''
    if (this.props.hasPlayerProfiles) {
      playerProfileComp = <NavItem eventKey="player-profile" as="li">{ tabTitles['player-profile'] }</NavItem>
        
      playerProfileCompContent = <TabPane eventKey="player-profile"><div className="player-profile"><Row className="title"><a href={ this.props.editProfileUrl } target={ target } className="edit-btn"><i className="fa fa-edit"></i>{ !window.I18n ? '' : window.I18n.t('player_activity.edit_player_profile') }</a></Row><div className="iframe-container"><Iframe url={this.state.playerProfileUrl} className="page-iframe embed-responsive-item" frameborder="0" scrolling={ 'auto' } mozallowfulscreen="true" name="page_iframe" webkitallowfullscreen="true"></Iframe></div></div></TabPane>
    }
    
    let tiSummaryComp = ''
    let tiSummaryCompContent = ''
    if (this.props.isGcat) {
      const userAgent = window.navigator.userAgent
      let scrolling = true

      if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        scrolling = false
      }

      tiSummaryComp = <NavItem eventKey="ti-summary" as="li">{ tabTitles['ti-summary'] }</NavItem>
      
      tiSummaryCompContent = <TabPane eventKey="ti-summary"><Row className="hi-summary no-margin"><div className="panel panel-default"><div className="panel-body hi-summary"><div className="text">{ !window.I18n ? '' : window.I18n.t('player_activity.current_am_tour_index') }</div><div className="value">{ this.props.usgaIndex }</div></div></div></Row><Row className="hi-summary no-margin"><div className="iframe-container"><Iframe url={this.state.HISummaryUrl} className="page-iframe embed-responsive-item" frameborder="0" scrolling={ scrolling ? 'auto' : 'no' } mozallowfulscreen="true" name="page_iframe" webkitallowfullscreen="true"></Iframe></div></Row></TabPane>
    }
    
    return <TabContainer defaultActiveKey={ this.state.defaultActiveKey } id="player-activity-tabs"><Row><Navbar collapseOnSelect={ true } expand="sm"><Toggle onClick={ () => this.setState({ buttonMenuClass: (this.state.buttonMenuClass === 'down' ? 'up' : 'down') }) }>{ this.state.buttonTitle }<i className={ 'glyphicon glyphicon-menu-' + this.state.buttonMenuClass }></i></Toggle><Collapse><Row><Nav as="ul" onSelect={ (selectedKey) => { this.setState({buttonTitle: tabTitles[selectedKey], buttonMenuClass: 'down' }) } }>{ playerProfileComp }<NavItem eventKey="participation-history" as="li" onClick={ this.fetchParticipationHistoryData }>{ tabTitles['participation-history' ]}</NavItem><NavItem eventKey="registration" as="li" onClick={ this.fetchMembershipProgramsData }>{ tabTitles['registration'] }</NavItem>{ tiSummaryComp }{ this.props.usesPayments && <NavItem eventKey="transactions" as="li" onClick={ this.fetchTransactionsData }>{ tabTitles['transactions'] }</NavItem>}</Nav></Row></Collapse></Navbar><TabContent>{ playerProfileCompContent }<TabPane eventKey="participation-history"><ParticipationHistoryContainer target={ target }></ParticipationHistoryContainer></TabPane><TabPane eventKey="registration"><MembershipProgramsContainer target={ target }></MembershipProgramsContainer></TabPane>{ tiSummaryCompContent }<TabPane eventKey="transactions"><TransactionsHistoryContainer target={ target }></TransactionsHistoryContainer></TabPane></TabContent></Row></TabContainer>
  }
}

PlayerActivityDashboardComponent.propTypes = {
  memberCardId: PropTypes.string.isRequired,
  HISummaryUrl: PropTypes.string.isRequired,
  usgaIndex: PropTypes.string.isRequired,
  fetchTransactionsData: PropTypes.func.isRequired,
  fetchParticipationHistoryData: PropTypes.func.isRequired,
  fetchMembershipProgramsData: PropTypes.func.isRequired,
  isGcat: PropTypes.bool,
  isGcatRequest: PropTypes.bool,
  hasPlayerProfiles: PropTypes.bool,
  editProfileUrl: PropTypes.string.isRequired,
  usesPayments: PropTypes.bool,
}

export default PlayerActivityDashboardComponent
