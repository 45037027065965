import React from 'react'
import PropTypes from 'prop-types'

import rootReducer from '../reducers'

import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'
import PgaRankingsContainer from './pga_rankings_container'
import { getStatDataByKey } from '../helpers'

const PgaRankingsRoot = ({fetchInitialDataUrl, leagueId, statistics, distanceUnit, hasShotTrackings, rounds}) => {
  const state = {
    misc: {
      fetchInitialDataUrl,
      leagueId,
      distanceUnit,
      hasShotTrackings,
      rounds,
    },
    statistics: statistics.map((stat) => getStatDataByKey(stat, distanceUnit)),
  }

  const store = configureStore(rootReducer, state)

  return (
    <GlobalProvider store = { store }>
      <PgaRankingsContainer/>
    </GlobalProvider>
  )
}

PgaRankingsRoot.propTypes = {
  fetchInitialDataUrl: PropTypes.string,
  leagueId: PropTypes.string,
  statistics: PropTypes.array,
  distanceUnit: PropTypes.string,
  hasShotTrackings: PropTypes.bool,
  rounds: PropTypes.array,
}

export default PgaRankingsRoot
