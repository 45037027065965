import { connect } from 'react-redux'
import EditTabs from '../presentational/edit_tabs'
import { editTab, swapTabs, updateTab, removeTab, displayMode, saveTabs, setActiveTab, DISPLAY_MODES } from '../../actions'

const mapStateToProps = state => ({
  tabs: state.tabs.sort( (a, b) => a.index <= b.index ? -1 : 1 ),
  activeTab: state.filters.active_tab,
  usesTranslations: state.filters.uses_translations,
  availableLanguages: state.filters.available_languages,
  currentLanguage: state.filters.current_language,
})

const mapDispatchToProps = dispatch => ({
  editTab: (id) => dispatch(editTab(id)),
  updateTab: (id, title) => dispatch(updateTab(id, title)),
  removeTab: id => dispatch(removeTab(id)),
  swapTabs: (tabID1, tabID2) => dispatch(swapTabs(tabID1, tabID2)),
  saveTabs: (tabs, activeTab) => { 
    dispatch(saveTabs(tabs))
    //switch to last exisintg tab
    if (tabs.length > 0 && tabs.filter( tab => tab.id === activeTab).length === 0) {
      dispatch(setActiveTab(tabs[tabs.length - 1].id))
    }
  },
  cancel: () => {
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditTabs)
