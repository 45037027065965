import React from 'react'
import PickingArea from './picking_area/picking_area_component'
import AvailablePicksAreaContainer from '../../containers/manager_side/available_picks_area_container'
import PopupModalContainer from '../../containers/manager_side/modal_popup_container'

const DraftManagerSide = () => {
  return (
    <div id="draft_manager_side">
      <PopupModalContainer/>
      <PickingArea/>
      <AvailablePicksAreaContainer/>
    </div>
  )
}

export default DraftManagerSide
