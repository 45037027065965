import _ from 'lodash'
import Fuse from 'fuse.js'

const FUZZY_SEARCH_OPTIONS = {
  includeScore: true,
  shouldSort: true,
  threshold: 1.0,
  location: 0,
  distance: 15,
  maxPatternLength: 32,
  minMatchCharLength: 0,
  includeMatches: true,
}

const TAGS_WEIGHT = 1.0
const ALTERNATE_TAGS_WEIGHT = 0.5
const WEIGHT_SCORE = 0.01
const FRAGMENTS_COEF_UPPER_BOUND = 3
const FRAGMENTS_WEIGHT = 0.07


/*
 * Returns a list of options sorted by how well they match based on a
 * search pattern (with a score field added).
 * A score of 1 means a perfect match and a 0 means total mismatch.
 * We assume a navigator option has this structure
 *  - weight
 *  - tags: array of strings (weight 1)
 *  - alternateTags: arrays of strings (weight 0.5)
 * The pattern is the search value (e.g. 'pr1nt sc0r3c').
 * The pattern will be broken down into tokens and the tokens will be matched
 * against the tags.
 *
 * relevancyFilter: for 0.3 we will only return options with at least 30% relevancy
 */
const fuzzySearch = (options, pattern, { relevancyFilter } = {}) => {
  if (_.isEmpty(pattern) || _.isEmpty(options)) {
    return options
  }

  const tokens = _.compact(pattern.split(/\/| /))
  let results = options.map(option => {
    const fuseTags = new Fuse(option.tags, FUZZY_SEARCH_OPTIONS)
    const fuseAlternateTags = new Fuse(option.alternateTags, FUZZY_SEARCH_OPTIONS)
    const checkers = [ [ fuseTags, TAGS_WEIGHT ], [ fuseAlternateTags, ALTERNATE_TAGS_WEIGHT ] ]

    // We sum up the score for every token, the higher the better
    let score = 0.0

    tokens.forEach(token => {
      const checkerScores = checkers.map(([ fuse, tagsWeight ]) => {
        // Fuse returns a list of tags sorted by their score (0 is a perfect match)
        const tokenTagScores = fuse.search(token)
        let bestTokenScore
        if (_.isEmpty(tokenTagScores)) {
          bestTokenScore = 1.0
        } else {
          bestTokenScore = tokenTagScores[0].score
          if (tokenTagScores[0].matches && tokenTagScores[0].matches[0]) {
            const matchingFragmentsCount = tokenTagScores[0].matches[0].indices.length
            const fragmentsCoef = Math.min(FRAGMENTS_COEF_UPPER_BOUND, matchingFragmentsCount - 1)
            const fragmentsPenalty = fragmentsCoef * FRAGMENTS_WEIGHT
            bestTokenScore = Math.min(1.0, bestTokenScore + fragmentsPenalty)
          }
        }
        const crtScore = 1.0 - bestTokenScore
        return crtScore * tagsWeight
      })

      const bestCheckerScore = _.max(checkerScores)
      score += bestCheckerScore
    })

    score += WEIGHT_SCORE * (option.weight || 1)

    return {
      ...option,
      score: score,
    }
  })

  results = _.orderBy(results, 'score', 'desc')

  // Scale down scores to be in the [0..1] range
  const maxScore = _.maxBy(results, 'score').score
  results.forEach(result => {
    result.score /= maxScore
  })

  if (relevancyFilter) {
    results = _.filter(results, option => option.score >= relevancyFilter)
  }

  return results
}

export default fuzzySearch
