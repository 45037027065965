import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import { Icon as FaIcon } from 'react-fa'
import { Droppable } from 'react-beautiful-dnd'

import PopupTypes from '../popup_types'
import { onConfirm } from 'Shared/helpers'

const Handle = props => <span className="clickable add-margin-right-10" { ...props }><FaIcon name="bars"></FaIcon></span>

class CategoryHeader extends PureComponent {
  constructor(props) {
    super(props)
    this.openPopup = props.openPopup.bind(this, PopupTypes.CATEGORY_POPUP)
    this.onEdit = this.onEdit.bind(this)
    this.onRemove = this.onRemove.bind(this)
  }

  onEdit(event) {
    this.openPopup({
      operation: 'edit',
      id: this.props.id,
      name: this.props.name,
      color: this.props.color,
      specialStatus: this.props.specialStatus,
      isFree: this.props.isFree,
    })
    event.stopPropagation()
  }

  onRemove(event) {
    onConfirm('Are you sure you want to delete this category?', () => {
      this.props.deleteCategory(this.props.id)
    })
    event.stopPropagation()
  }

  getStyles(color) {
    return {
      borderLeftWidth: 3,
      borderLeftStyle: 'solid',
      borderLeftColor: color,
    }
  }

  render() {
    const { name, leaguesCount, opened, color, showRemoveButton, onClick, dragHandleProps, loadingData } = this.props
    return <Fragment>{ /* You can drop league onto the category header */ }<Droppable droppableId={ `header_${ this.props.id }` } type="table">{provided => <div ref={ provided.innerRef } { ...provided.droppableProps }><div className="list-header" onClick={ onClick } style={ this.getStyles(color) } onMouseEnter={ this.handleHoverStart } onMouseLeave={ this.handleHoverEnd }><Row><Col xs={11}><Handle { ...dragHandleProps }></Handle><span className="title">{ name }</span><span className="delimiter add-margin-left-10 add-margin-right-10">&bull;</span><span className="leagues-count">{ leaguesCount }</span>{ loadingData && <Fragment><span><span className="delimiter add-margin-left-10 add-margin-right-10">&bull;</span><span className="category_preloader_container"><span className="category_preloader"></span></span></span></Fragment>}{this.props.userIsCustomerManager && <Fragment><span className="list-actions"><span className="delimiter add-margin-left-10 add-margin-right-10">&bull;</span><a className="edit-action" onClick={ this.onEdit }><FaIcon name="pencil-square-o"></FaIcon>Edit</a></span>{showRemoveButton && <span className="list-actions"><span className="delimiter add-margin-left-10 add-margin-right-10">&bull;</span><a className="remove-action" onClick={ this.onRemove }><FaIcon name="trash-o"></FaIcon>Remove</a></span>}</Fragment>}</Col><Col xs={1}><FaIcon name={ opened ? 'caret-up' : 'caret-down' } className="pull-right"></FaIcon></Col></Row></div>{ /* fake table for the league row placeholder */ }<table style={ { display: 'none' } }><tbody>{ provided.placeholder }</tbody></table></div>}</Droppable></Fragment>
  }
}

CategoryHeader.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  loadingData: PropTypes.bool,
  leaguesCount: PropTypes.number.isRequired,
  opened: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  isFree: PropTypes.bool,
  specialStatus: PropTypes.string,
  showRemoveButton: PropTypes.bool.isRequired,
  userIsCustomerManager: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  // From the sorting library; need to apply it to the handle
  dragHandleProps: PropTypes.object.isRequired,
  openPopup: PropTypes.func.isRequired,
}

export default CategoryHeader
