import { createSelector } from 'reselect'
import _ from 'lodash'

const getDefaultCourseById = (state, useManagedCustomer = false) => {
  if (useManagedCustomer) {
    return state.managedCustomerData.default_courses || state.default_courses
  } else {
    return state.default_courses
  }
}

const getSortedDefaultCourseIds = createSelector(
  [ getDefaultCourseById ],
  (defaultCoursesbyId) => {
    return _.sortBy(_.keys(defaultCoursesbyId), defaultCourseId => defaultCoursesbyId[defaultCourseId].position)
  },
)

export default getSortedDefaultCourseIds
