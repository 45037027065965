import { connect } from 'react-redux'
import _ from 'lodash'

import {
  fetchInitialData,
} from '../actions'

import CustomerCenterComponent from '../components/customer_center_component'

const mapStateToProps = (state) => {
  const anyLeagues = !_.isEmpty(state.leagues)
  const anyCategories = !_.isEmpty(state.categories)
  const showWelcome = !state.misc.dataIsLoading &&
                      !anyLeagues &&
                      !anyCategories &&
                      !state.misc.userIsAdmin &&
                      !state.misc.userIsTourManager &&
                      !state.misc.customerOnlyLeagues &&
                      state.misc.userIsCustomerManager &&
                      state.misc.branding === 'golfgenius' &&
                      state.misc.userSource !== 'visage-support'
  const shouldLoadData = !state.misc.isSalesDemo &&
                         !!state.misc.fetchInitialDataURL
  const shouldLoadInitialDataForListView = !anyLeagues && state.filters.viewType === 'list'
  const filters = state.filters
  return {
    isSalesDemo: state.misc.isSalesDemo,
    shouldLoadData,
    showWelcome,
    shouldLoadInitialDataForListView,
    filters,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchData: (filters) => dispatch(fetchInitialData(filters)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerCenterComponent)
