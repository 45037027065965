import { ActionTypes } from '../actions'

const {
  OPEN_VERIFICATION_POPUP,
  CLOSE_POPUP,
} = ActionTypes

const initialState = {
  visible: false,
  playerId: '',
  playerName: '',
  scoreData: {},
  shots: [],
  pars: [],
  teeTime: '',
}

const popup = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_VERIFICATION_POPUP:
      return {
        ...state,
        visible: true,
        playerId: action.playerId,
        playerName: action.playerName,
        scoreData: action.scoreData,
        shots: action.shots,
        pars: action.pars,
        teeTime: action.teeTime,
      }
    case CLOSE_POPUP:
      return {
        ...state,
        visible: false,
        playerId: '',
        playerName: '',
        scores: {},
        shots: [],
        pars: 0,
        teeTime: '',
      }
    default:
      return state
  }
}

export default popup
