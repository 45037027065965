import { connect } from 'react-redux'
import MembersPageComponent from '../components/members_page_component'
import { fetchInitialMembersData, openAddGolfersManuallyPopup, openUploadSpreadsheetSidebar, openRegisterMemberPopup, getRegisterMemberData } from '../actions/index'

const mapStateToProps = (state) => ({
  isSidebarOpen: Object.values(state.leftSidebarFields.isOpen).some(x => x === true),
  isOpen: state.leftSidebarFields.isOpen,
  leagueId: state.misc.leagueId,
  canOpenMemberModal: state.misc.canOpenMemberModal,
  modalChangeHandicapIndex: state.misc.modalChangeHandicapIndex,
  hsInfoLink: state.misc.hsInfoLink,
  isGcatCustomer: state.misc.isGcatCustomer,
  memberId: state.misc.memberId,
  openOption: state.misc.openOption,
  isLeaguesPropagationPopupOpen: state.leaguesPropagation.isOpen,
  showInvalidEmailsMessage: state.membersData.showInvalidEmailsMessage,
})

const mapDispatchToProps = (dispatch) => ({
  fetchInitialMembersData: () => dispatch(fetchInitialMembersData()),
  openAddGolfersManuallyPopup: () => dispatch(openAddGolfersManuallyPopup()),
  openUploadSpreadsheetSidebar: () => dispatch(openUploadSpreadsheetSidebar()),
  openRegisterMemberPopup: (memberId) => dispatch(openRegisterMemberPopup(memberId)),
  getRegisterMemberData: (memberId) => dispatch(getRegisterMemberData(memberId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersPageComponent)
