import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

class ReportCenterItemBlockArchived extends Component {
  stringParameterize(str) {
    return str.trim().toLowerCase()
                    .replace(/[^a-zA-Z0-9 -]/, '')
                    .replace(/\s/g, '_')
  }

  render() {
    return (
      <tr className={ 'report-list-item report_center_item_block' + ( this.props.report_center_item.display ? '' : ' hidden')}>
        <td className="report_center_item_title text-left">
          {
          <a>{!window.I18n ? '' : window.I18n.t(`download_center.item_name.${this.stringParameterize(this.props.report_center_item.name)}`, {defaultValue: this.props.report_center_item.name.toUpperCase()})}</a>
          }
        </td>
        <td className="text-center">
          <span>
            {
              <span className="add-margin-right-10">
                <i className="fa fa-list fa-fw text-center"></i>
                { this.props.report_center_item.list_count }
              </span>
            }
            <span className="add-margin-left-10">
              <i className="fa fa-print fa-fw text-center"></i>
              { this.props.report_center_item.print_count }
            </span>
          </span>
        </td>
        {/* report buttons saga */}
        <td className="text-right add-padding-right-30">
          { [ 'unrestricted', 'restricted' ].includes(this.props.report_center_item.access) &&
            <Fragment>
              {
                <span>
                  <a>{!window.I18n ? '' : window.I18n.t('download_center.unarchive')}</a>
                  &nbsp;•&nbsp;
                  <a onClick={this.props.deleteItem}>{!window.I18n ? '' : window.I18n.t('download_center.delete')}</a>
                </span>
              }
            </Fragment>
          }
        </td>
      </tr>
    )
  }
}

ReportCenterItemBlockArchived.propTypes = {
  report_center_item: PropTypes.object.isRequired,
  deleteItem: PropTypes.func.isRequired,
}

export default ReportCenterItemBlockArchived
