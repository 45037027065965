import React, { useRef, useEffect, useState } from 'react'
import MemberCardContainer from '../containers/member_card_container'
import FiltersContainer from '../containers/filters_container'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'
import { GlgLoadingSpinner, GlgTooltipIcon } from 'SharedComponents'
import SettingsModalContainer from '../containers/settings_modal_container'


const MemberCardsTable = (props) => {

  document.body.style.overflow = 'hidden'
  const [ isOverflowing, setIsOverflowing ] = useState(false)
  const [ tableHeight, setTableHeight ] = useState(0)
  const [ showModal, setShowModal ] = useState(false)
  const [ intervalIds, setIntervalIds ] = useState([])

  const breadcrumbElement = document.querySelector('#breadcrumb')
  const navbarElement = document.getElementsByClassName('navbar navbar-default header_navbar customer-navbar')[0]

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget
    setIsOverflowing((scrollTop + clientHeight >= scrollHeight - 1) && !props.isLoading)
    if (isOverflowing) {
      props.fetchMore()
    }
  }

  const wrapperRef = useRef(null)

  const namingRules = {
    full_name: 'Full Name',
    last_name: 'Last Name',
  }
  
  const showNameTooltip = props.userNamingRule !== 'full_name' || props.handicapNamingRule !== 'full_name'

  const toolTipText = window.I18n.t('member_cards.tooltip_text', { user_naming_rule: namingRules[props.userNamingRule], handicap_naming_rule: namingRules[props.handicapNamingRule]})
  
  useEffect(() => {
    const tableIntervalId = setInterval(() => {
      const filtersHeight = wrapperRef.current.clientHeight
      const height = window.innerHeight - (breadcrumbElement.clientHeight + navbarElement.clientHeight + filtersHeight)
      setTableHeight(height)
    }, 250)

    setIntervalIds(prevIds => [ ...prevIds, tableIntervalId ])

    const resqueIntervalId = setInterval(() => {
      props.checkResqueStatus()
    }, 5000)

    setIntervalIds(prevIds => [ ...prevIds, resqueIntervalId ])

    return () => intervalIds.forEach(clearInterval)
  }, [])

  if (props.memberCards && props.members){
    return (
      <div>
        <div ref={wrapperRef}>
          <FiltersContainer/>
        </div>
        <div
          style = {{
            width: '100%',
            height: tableHeight,
            overflow: 'auto',
          }} 
          onScroll = {handleScroll}>

          <Table striped >
            <thead className='mc-header'>
              <tr>
                <th>{window.I18n.t('member_cards.profiles')} ({props.nrProfiles} Found)</th>
                <th className='golfers-header'>
                  {window.I18n.t('member_cards.golfers')}
                  <div className='header-links-div'>
                    { props.isAdmin && <a href ='#' onClick={ () => setShowModal(true) }>{window.I18n.t('member_cards.settings')}</a> }
                    { showNameTooltip && <GlgTooltipIcon tooltip={toolTipText} placement='right'/>}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(props.memberCards).map((key) => {
                if (key !== 'isLoading'){
                  const memberCard = props.memberCards[key]
                  const members = props.members[key]
                  return (<MemberCardContainer 
                    memberCard = {memberCard}
                    members = {members || []} 
                    index = {key} 
                    key = {key}/>)
                }
              })}
            </tbody>
          </Table>  
          { isOverflowing && <div className='spinner-div'>
          { props.isLoading && <GlgLoadingSpinner/>}
          </div> }
        </div>
        <SettingsModalContainer show={showModal} onClose={() => setShowModal(false)}/>
      </div>
    )
  } else { return null }
}

MemberCardsTable.propTypes = {
  memberCards: PropTypes.object,
  members: PropTypes.object,
  fetchMore: PropTypes.func,
  isLoading: PropTypes.bool,
  isAdmin: PropTypes.bool,
  nrProfiles: PropTypes.number,
  switchLink: PropTypes.string,
  userNamingRule: PropTypes.string,
  handicapNamingRule: PropTypes.string,
  checkResqueStatus: PropTypes.func,
}
export default MemberCardsTable
