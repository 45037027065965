import { ActionTypes } from '../actions'
const {
  FETCH_INITIAL_DATA_RECEIVED,
} = ActionTypes

/*
const DEFAULT_TOUR_DATA = {
  id: null,
  name: null,
}
*/

const tours = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INITIAL_DATA_RECEIVED:
      return {
        ...state,
        ...action.data.tours,
      }
    default:
      return state
  }
}

export default tours
