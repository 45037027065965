import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'

class DirectoriesTitle extends PureComponent {

  componentDidUpdate() {
    window.glg.new_design_shared.computeAndApplyBorder()
  }
  render () {
    return <Fragment><div className={`page_title ${this.props.shouldHideHeader ? 'hidden' : ''}`}><div className="text">{this.props.subtitle}</div></div><div dangerouslySetInnerHTML={{__html: this.props.description}} className="description-color"></div></Fragment>
  }

}

DirectoriesTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  shouldHideHeader: PropTypes.bool,
}

export default DirectoriesTitle
