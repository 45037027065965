import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import NavigatorOptionPopup from './popups/navigator_option_popup'

const NavigatorOptionsPopupsComponent = ({
  closePopup,
  onOptionEdit,
  onOptionCreate,
  navigatorOptionPopup,
  locationSuggestions,
}) => <Fragment><NavigatorOptionPopup { ...navigatorOptionPopup } locationSuggestions={ locationSuggestions } onClose={ closePopup } onOptionEdit={ onOptionEdit } onOptionCreate={ onOptionCreate }></NavigatorOptionPopup></Fragment>

NavigatorOptionsPopupsComponent.propTypes = {
  navigatorOptionPopup: PropTypes.object.isRequired,
  locationSuggestions: PropTypes.array.isRequired,
  closePopup: PropTypes.func.isRequired,
  onOptionEdit: PropTypes.func.isRequired,
  onOptionCreate: PropTypes.func.isRequired,
}

export default NavigatorOptionsPopupsComponent
