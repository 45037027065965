/*
 * GlgTableCol is used by GlgTable to build the table header columns.
 */

import PropTypes from 'prop-types'

const GlgTableCol = () => {}

GlgTableCol.propTypes = {
  // Percentage of width to allot to this column (e.g. '10%')
  width: PropTypes.string,
  // An identifier for the column (e.g. 'name'), used as header content if no children specified
  dataKey: PropTypes.string,
  // The header content
  children: PropTypes.node,
}

GlgTableCol.defaultProps = {
  dataKey: '#',
}

export default GlgTableCol
