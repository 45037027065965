import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import {Input} from './commons'
import {GlgTooltipIcon} from '../../../../shared/components'

const DraftSettingsSection = ({
  changeSection,
  rankingMethod,
  changeRankingMethod,
  pickTime,
  changePickTime,
  previousLeagues,
  prevLeagueId,
  changePrevLeagueId,
  grossScores,
  changeGrossScores,
}) => {

  const [ prevLeagueInputValue, setPrevLeagueInputValue ] = useState(previousLeagues[prevLeagueId])

  const onPrevLeagueInputChange = (inputValue, {action}) => {
    if (action === 'input-blur') {
      setPrevLeagueInputValue(previousLeagues[prevLeagueId])
    } else if (action === 'input-change') {
      setPrevLeagueInputValue(inputValue)
    }
  }

  const onPrevLeagueChange = (option) => {
    changePrevLeagueId(option?.value)
    setPrevLeagueInputValue(option?.label)
  }

  const getLeague = () => {
    const label = previousLeagues[prevLeagueId]
    if (label !== undefined && label !== null) {
      return {value: prevLeagueId, label: label}
    } else {
      return null
    }
  }

  return (<div className="section" id="draft-settings-section">
    <hr/>
    <div className="section_header" onClick={(e) => changeSection(e)}>
      <div className="row no-margin-right">
        <div className="col-xs-11 col-sm-7 col-lg-6 title">
          <div className="label label-primary">5.</div>
          <span className="header_text">{window.I18n.t('draft.components.draft_settings_component.title')}</span>
        </div>
        <div className="hidden-xs col-sm-4 col-lg-5"/>
        <div className="col-xs-1 caret-container add-padding-top-15">
          <i className="gga-carret-down"/>
          <i className="gga-carret-up"/>
        </div>
      </div>
    </div>
    <hr/>

    <div className="section_content btsp_square_solid hidden">
      <div className="row">
        <label className="draft_label btsp_uppercase_text">
          {window.I18n.t('draft.components.draft_settings_component.ranking_method')}
        </label>
      </div>
      <div className="row">
        <label key="previous" className="col-xs-12 col-md-3 icheck-label no-padding-left">
          <div className={`iradio_square-orange icheck-item ${rankingMethod === 'previous' ? 'checked' : ''}`}>
            <input className="i-checks icheck-input" type="radio" value="previous"
                   onChange={e => changeRankingMethod(e.target.value)} checked={rankingMethod === 'previous'}/>
          </div>
          <span>{window.I18n.t('draft.components.draft_settings_component.previous_league_ranking')}</span>
          <a target="_blank"
             href="https://docs.golfgenius.com/article/show/171213-team-draft-ranking-method-ranking-from-the-previous-league"
             className="add-margin-left-5">{window.I18n.t('draft.components.draft_settings_component.learn_more')}
          </a>
        </label>

        <label key="alpha" className="col-xs-12 col-md-3 icheck-label no-padding-left">
          <div className={`iradio_square-orange icheck-item ${rankingMethod === 'alpha' ? 'checked' : ''}`}>
            <input className="i-checks icheck-input" type="radio" value="alpha"
                   onChange={e => changeRankingMethod(e.target.value)} checked={rankingMethod === 'alpha'}/>
          </div>
          <span>{window.I18n.t('draft.components.draft_settings_component.alphabetical')}</span>
        </label>

        <label key="hi" className="col-xs-12 col-md-3 icheck-label no-padding-left">
          <div className={`iradio_square-orange icheck-item ${rankingMethod === 'hi' ? 'checked' : ''}`}>
            <input className="i-checks icheck-input" type="radio" value="hi"
                   onChange={e => changeRankingMethod(e.target.value)} checked={rankingMethod === 'hi'}/>
          </div>
          <span>{window.I18n.t('draft.components.draft_settings_component.handicap_index')}</span>
        </label>
      </div>
      {rankingMethod === 'previous' &&
        <div className="row">
          <div className="col-xs-12 col-sm-8 col-md-6 col-lg-5 prev_league_dropdown">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label
                className="small_label">{window.I18n.t('draft.components.draft_settings_component.select_event_or_league')}</label>
            </div>
            <div className="col-xs-12 col-sm-9 col-md-8 col-lg-10">
              <Select options={Object.keys(previousLeagues).map(key => ({value: key, label: previousLeagues[key]}))}
                      value={getLeague()}
                      onChange={onPrevLeagueChange}
                      inputValue={prevLeagueInputValue}
                      onInputChange={onPrevLeagueInputChange}
                      controlShouldRenderValue={false}
                      isClearable={true}
                      menuPortalTarget={document.body}
                      styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                      placeholder={window.I18n.t('draft.components.draft_settings_component.search_for_a_league')}
                      loadingMessage={() => window.I18n.t('draft.components.draft_settings_component.loading')}
                      noOptionsMessage={() => window.I18n.t('draft.components.draft_settings_component.no_leagues')}
                      classNamePrefix="league_dropdown" components={{IndicatorSeparator: null, Input}}/>
            </div>
          </div>
          <div className="col-xs-12 col-sm-8 col-md-6 col-lg-5">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label className="small_label">
                {window.I18n.t('draft.components.draft_settings_component.gross_scores_counted')}
              </label>
            </div>
            <div className="col-xs-12 col-sm-9 col-md-8 col-lg-10">
              <input value={grossScores} type={'number'} min={0} pattern={'\\d*'} id="gross_scores"
                     className="form-text form-control" onChange={e => changeGrossScores(e.target.value)}/>
            </div>
          </div>
        </div>
      }
      <div className="row">
        <label className="draft_label btsp_uppercase_text">
          {window.I18n.t('draft.components.draft_settings_component.time_between_picks')}
          <GlgTooltipIcon
            tooltip={window.I18n.t('draft.components.draft_settings_component.time_between_picks_tooltip')}
            placement="bottom" iconClass="add-margin-left-5 add-top-3"/>
        </label>
      </div>
      <div className="row">
        {window.I18n.t('draft.components.draft_settings_component.time_allowed_between_picks')}
        <input value={pickTime} type={'number'} min={0} pattern={'\\d*'} id="pick_time"
               className="form-text form-control" onChange={e => changePickTime(e.target.value)}/>
        {window.I18n.t('draft.components.draft_settings_component.seconds')}
      </div>
    </div>
  </div>)
}

DraftSettingsSection.propTypes = {
  changeSection: PropTypes.func,
  rankingMethod: PropTypes.string,
  changeRankingMethod: PropTypes.func,
  pickTime: PropTypes.number,
  changePickTime: PropTypes.func,
  previousLeagues: PropTypes.any,
  prevLeagueId: PropTypes.string,
  changePrevLeagueId: PropTypes.func,
  grossScores: PropTypes.number,
  changeGrossScores: PropTypes.func,
}

export default DraftSettingsSection
