import { connect } from 'react-redux'
import ScoringControlInterface from '../components/scoring_control_interface'

import { fetchInitialData, saveScores, resetTimer, clearTimer, refreshContent } from '../actions'

const mapStateToProps = (state) => {
  return {
    leagueId: state.misc.leagueId,
    roundId: state.misc.roundId,
    isLoading: state.misc.isLoading,
    anyNewScores: Object.keys(state.scoringInterfaceData.newScores).length !== 0,
    newScores: state.scoringInterfaceData.newScores,
    hasTournaments: state.scoringInterfaceData.hasTournaments,
    noTournamentsMessage: state.scoringInterfaceData.noTournamentsMessage,
    // hasResults: state.scoringInterfaceData.hasResults,
    // selectedTournamentName: state.misc.selectedTournamentName,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchInitialData: () => dispatch(fetchInitialData()),
  saveScores: (newScores) => dispatch(saveScores(newScores)),
  refreshContent: () => dispatch(refreshContent()),
  resetTimer: () => dispatch(resetTimer()),
  clearTimer: () => dispatch(clearTimer()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScoringControlInterface)
