import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { BLUE_BOX_HOTZONE_VIEWPORT_WIDTH, BLUE_BOX_HOTZONE_VIEWPORT_HEIGHT, NONE } from '../../constants'
import HeaderComponent from '../header_component'
import BlueBox from '../blue_box_component'
import LeaderboardComponent from './leaderboard_component'
import AdvancedComponent from './advanced_component'
import FooterComponent from '../footer_component'
import PhotosComponent from './photos_component'
import VideoComponent from './video_component'
import TextComponent from './text_component'
import TeeSheetComponent from './tee_sheet_component'
import { ADVANCED_TYPE, PHOTOS_TYPE, VIDEO_TYPE, TEXT_TYPE, TEE_SHEET_TYPE } from '../../constants'


class TvShowSlideshowComponent extends Component {

  constructor(props) {
    super(props)

    this.state = {
      placeholderValues: {},
      hasMovedToNextSlide: false,
      tryCount: 0,
      retryLimit: 1,
    }

    this.setPlaceholderValues = this.setPlaceholderValues.bind(this)
    this.manageBlueBoxDisplay = this.manageBlueBoxDisplay.bind(this)
    this.moveToNextSlide = this.moveToNextSlide.bind(this)
  }

  fetchLatestSlides() {
    const {
      paths,
      slideshowData: {
        isFireTv,
        tvShowSettings,
        currentSlide,
        slides,
      },
    } = this.props
    let tryCount = this.state.tryCount

    $.ajax({
      url: paths.lastTvShowUpdateTimestamp,
      type: 'GET',
      data: isFireTv ? {
        firetv: true,
      } : null,
      success: (timestampResponse) => {
        if ( new Date( timestampResponse.lastUpdate ) > new Date( tvShowSettings.updated_at ) ) {
          const slide = slides[currentSlide.index]
          if (slide?.displayType === 'brackets' && slide.bracketIds.length > 1) {
            let saveTimestamp = false
            if (tryCount > this.state.retryLimit - 1) {
              saveTimestamp = true
              tryCount = 0
            } else {
              tryCount += 1
            }
            setTimeout(() => {
              this.fetchSlides(timestampResponse, saveTimestamp)
            }, 5000)

          } else {
            this.fetchSlides(timestampResponse, true)
          }
          this.setState({tryCount: tryCount})
        }
      },
      error: () => {
      },
    })
  }

  fetchSlides(timestampResponse, saveTimestamp) {
    const {
      paths,
      slideshowData: {
        isFireTv,
        currentSlide,
      },
      slideshowDispatchers,
    } = this.props
    $.ajax({
      url: paths.fetchSlides,
      type: 'GET',
      data: {
        ...{ periodicUpdateRequest: true },
        ...( isFireTv ? { firetv: true } : {} ),
      },
      success: (updateResponse) => {
        // if current slide index doesn't exist anymore
        if ( currentSlide.index >= updateResponse.slides.length ) {
          slideshowDispatchers.moveToNextSlide()
        }
        if (saveTimestamp) {
          updateResponse.tv_show.updated_at = timestampResponse.lastUpdate
        }
        slideshowDispatchers.updateToLatestSlideshow(updateResponse)
      },
      error: () => {
      },
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const hasSlideChanged = !prevState.hasMovedToNextSlide && this.state.hasMovedToNextSlide
    const hasColorThemeChanged = prevProps.slideshowData.blueBox.colorTheme !== this.props.slideshowData.blueBox.colorTheme

    if (hasSlideChanged) {
      this.setState({ hasMovedToNextSlide: false }, () => {
        this.fetchLatestSlides()
      })
    }

    if (hasColorThemeChanged) {
      document.body.classList.remove('dark')
      if ( this.props.slideshowData.blueBox.colorTheme === 'dark' ) {
        document.body.classList.add('dark')
      }
    }
  }

  setPlaceholderValues(placeholderValues) {
    const newState = { ...this.state }
    newState.placeholderValues = placeholderValues

    if ( !_.isEqual(newState, this.state) ) {
      this.setState( newState )
    }
  }

  manageBlueBoxDisplay(event) {
    const pageWidth = window.innerWidth
    const pageHeight = window.innerHeight

    const leftMarginWidth = pageWidth * (1 - BLUE_BOX_HOTZONE_VIEWPORT_WIDTH) / 2
    if ( !this.props.slideshowData.blueBox.show &&
      event.pageX > leftMarginWidth &&
      event.pageX < pageWidth - leftMarginWidth &&
      event.pageY < pageHeight * BLUE_BOX_HOTZONE_VIEWPORT_HEIGHT
    ) {
      this.props.slideshowDispatchers.blueBoxDispatchers.showBlueBox()
    }

    const blueBoxHeight = $('.control_pane').outerHeight()
    if ( this.props.slideshowData.blueBox.show && (
      event.pageX < leftMarginWidth ||
      event.pageX > pageWidth - leftMarginWidth ||
      event.pageY > blueBoxHeight + pageHeight * BLUE_BOX_HOTZONE_VIEWPORT_HEIGHT // add a vertical safety margin on "mouse out" to prevent flickering
    )) {
      this.props.slideshowDispatchers.blueBoxDispatchers.hideBlueBox()
    }
  }

  moveToNextSlide() {
    this.props.slideshowDispatchers.moveToNextSlide()
    this.setState({ hasMovedToNextSlide: true })
  }

  render() {
    const {
      slideshowData: {
        isFireTv,
        tvShowSettings,
        currentSlide,
        slides,
        blueBox,
      },
      slideshowDispatchers,
    } = this.props

    const backgroundStyle = _.get(tvShowSettings, 'general_settings.backgroundImage', NONE) === NONE ? {} : {backgroundImage: `url(${tvShowSettings.general_settings.backgroundImage})` }
    const hasUSGATheme = _.get(tvShowSettings, 'general_settings.hasUSGATheme') === 'true'

    /*
    { tvShowSettings.general_settings.sponsorLogos.map( (logoLink, index) => <img src={logoLink} key={index} />} */
    return <div onMouseMove={ this.manageBlueBoxDisplay } style={backgroundStyle} className="background"><aside style={{ marginLeft: `${blueBox.sideMargin}px` }} className="sponsor-logos-container"></aside><aside style={{ marginRight: `${blueBox.sideMargin}px` }}></aside><section><div style={{ height: `${blueBox.topMargin}px` }} className="top-margin"></div><header className={ hasUSGATheme ? 'usga-theme' : ''}>{ tvShowSettings.header_settings.isVisible === 'true' && 
              <HeaderComponent
                {...tvShowSettings.header_settings}
                {...( this.state.placeholderValues )}
              />
            }</header><main className="tvtournaments">{ slides.length === 0 ? <div className="no-slides-message">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.slideshow.tv_show_slideshow.no_slides') }</div> : <div className="tv-events">{ [ 'table', 'brackets' ].includes(slides[currentSlide.index].displayType) && <LeaderboardComponent isFireTv={ isFireTv } slide={ slides[currentSlide.index] } blueBox={ blueBox } setPlaceholderValues={ this.setPlaceholderValues } moveToNextSlide={ this.moveToNextSlide } hasUSGATheme={ hasUSGATheme }></LeaderboardComponent> }{ slides[currentSlide.index].displayType === ADVANCED_TYPE &&
                  <AdvancedComponent
                    isFireTv={ isFireTv }
                    slide={ slides[currentSlide.index] }
                    blueBox={ blueBox }
                    tvShowSettings={ tvShowSettings }
                    currentSlideIndex={ currentSlide.index }
                    setPlaceholderValues={ this.setPlaceholderValues }
                    moveToNextSlide={ this.moveToNextSlide }
                    hasUSGATheme={ hasUSGATheme }
                    { ...slideshowDispatchers.advancedSlideDispatchers }
                  />
                }{ slides[currentSlide.index].displayType === PHOTOS_TYPE &&
                  <PhotosComponent
                    isFireTv={ isFireTv }
                    slide={ slides[currentSlide.index] }
                    blueBox={ blueBox }
                    tvShowSettings={ tvShowSettings }
                    setPlaceholderValues={ this.setPlaceholderValues }
                    moveToNextSlide={ this.moveToNextSlide }
                    hasUSGATheme={ hasUSGATheme }
                    { ...slideshowDispatchers.advancedSlideDispatchers }
                  />
                }{ slides[currentSlide.index].displayType === VIDEO_TYPE &&
                  <VideoComponent
                    isFireTv={ isFireTv }
                    slide={ slides[currentSlide.index] }
                    blueBox={ blueBox }
                    tvShowSettings={ tvShowSettings }
                    currentSlideIndex={ currentSlide.index }
                    setPlaceholderValues={ this.setPlaceholderValues }
                    moveToNextSlide={ this.moveToNextSlide }
                    hasUSGATheme={ hasUSGATheme }
                    { ...slideshowDispatchers.advancedSlideDispatchers }
                  />
                }{ slides[currentSlide.index].displayType === TEXT_TYPE &&
                  <TextComponent
                    isFireTv={ isFireTv }
                    slide={ slides[currentSlide.index] }
                    blueBox={ blueBox }
                    tvShowSettings={ tvShowSettings }
                    setPlaceholderValues={ this.setPlaceholderValues }
                    moveToNextSlide={ this.moveToNextSlide }
                    hasUSGATheme={ hasUSGATheme }
                    { ...slideshowDispatchers.advancedSlideDispatchers }
                  />
                }{ slides[currentSlide.index].displayType === TEE_SHEET_TYPE &&
                  <TeeSheetComponent
                    isFireTv={ isFireTv }
                    slide={ slides[currentSlide.index] }
                    blueBox={ blueBox }
                    tvShowSettings={ tvShowSettings }
                    setPlaceholderValues={ this.setPlaceholderValues }
                    moveToNextSlide={ this.moveToNextSlide }
                    hasUSGATheme={ hasUSGATheme }
                    { ...slideshowDispatchers.advancedSlideDispatchers }
                  />
                }</div> }</main><footer>{ tvShowSettings.footer_settings.isVisible === 'true' &&
              <FooterComponent
                {...tvShowSettings.footer_settings}
                {...( this.state.placeholderValues )}
              />
            }</footer><div style={{ height: `${blueBox.bottomMargin}px` }} className="bottom-margin"></div></section>{
          <BlueBox
            isFireTv={ isFireTv }
            header={{
              isVisible: tvShowSettings.header_settings.isVisible === 'true',
              height: parseInt(tvShowSettings.header_settings.height),
            }}
            footer={{
              isVisible: tvShowSettings.footer_settings.isVisible === 'true',
              height: parseInt(tvShowSettings.footer_settings.height),
            }}
            {...this.props.slideshowData.blueBox}
            {...this.props.slideshowDispatchers.blueBoxDispatchers}
          />
        }<div className={`overlay ${blueBox.isScrolling ? 'play-animation' : 'pause-animation'}`}>{ blueBox.isScrolling ? <div className="overlay__play"></div> : <div className="overlay__pause">||</div> }</div></div>
  }
}

TvShowSlideshowComponent.propTypes = {
  tvShowSettings: PropTypes.object,
  slideshowData: PropTypes.object,
  slideshowDispatchers: PropTypes.object,
  paths: PropTypes.object.isRequired,
}

export default TvShowSlideshowComponent
