import { showErrorNotification, callAPI } from 'Shared/helpers'

export const ActionTypes = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_TABLE_DATA: 'SET_TABLE_DATA',

  CHANGE_TOURNAMENT_REQUEST: 'CHANGE_TOURNAMENT_REQUEST',
  CHANGE_TOURNAMENT_RECEIVED: 'CHANGE_TOURNAMNET_RECEIVED',
  CHANGE_DISPLAY_OPTION_FILTER: 'CHANGE_DISPLAY_OPTION_FILTER',
  CHANGE_COURSE_FILTER: 'CHANGE_COURSE_FILTER',
  CHANGE_STARTING_HOLE_FILTER: 'CHANGE_TEE_FILTER',
  CHANGE_SORT_OPTION: 'CHANGE_SORT_OPTION',
  CHANGE_SORT_ORDER_OPTION: 'CHANGE_SORT_ORDER_OPTION',
  CHANGE_ROUND_REQUEST: 'CHANGE_ROUND_REQUEST',
  CHANGE_ROUND_RECEIVED: 'CHANGE_ROUND_RECEIVED',
  REFRESH_DATA: 'REFRESH_DATA',
  SHOW_HIDE_FILTERS: 'SHOW_HIDE_FILTERS',

  RESET_TIMER: 'RESET_TIMER',
  CLEAR_TIMER: 'CLEAR_TIMER',

  CHANGE_SCORE: 'CHANGE_SCORE',
  SUBMIT_NEW_SCORES: 'SUBMIT_NEW_SCORES',
}

export const fetchInitialData = () => (dispatch, getState) => {
  const state = getState()
  const url = state.misc.fetchInitialDataPath
  const params = { tournament_id: state.misc.selectedTournamentId }


  dispatch({
    type: ActionTypes.SET_IS_LOADING,
    isLoading: true,
  })

  callAPI(url, 'GET', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.SET_TABLE_DATA,
        data: response,
      })

      dispatch({
        type: ActionTypes.SET_IS_LOADING,
        isLoading: false,
      })
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('scoring_control_interface.ajax.fetch_initial_data.error'))
    })
}

export const refreshContent = () => (dispatch, getState) => {
  const state = getState()
  const url = state.misc.fetchInitialDataPath
  const params = { tournament_id: state.misc.selectedTournamentId }

  callAPI(url, 'GET', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      if (response.foursomes.length !== 0) {
        // Reset new scores
        dispatch({
          type: ActionTypes.CHANGE_SCORE,
          newScores: {},
        })

        dispatch({
          type: ActionTypes.SET_TABLE_DATA,
          data: response,
        })

        dispatch({
          type: ActionTypes.SET_IS_LOADING,
          isLoading: false,
        })
      }
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('scoring_control_interface.ajax.refresh_data.error'))
    })
}

export const changeDisplayOptionFilter = (value) => (dispatch) => {
  dispatch({
    type: ActionTypes.CHANGE_DISPLAY_OPTION_FILTER,
    value,
  })
}

export const changeTournamentRequest = (tournamentId) => ({
  type: ActionTypes.CHANGE_TOURNAMENT_REQUEST,
  tournamentId,
})

export const changeTournamentReceived = (data) => ({
  type: ActionTypes.CHANGE_TOURNAMENT_RECEIVED,
  data,
})

export const changeCourseFilterAction = (value) => ({
  type: ActionTypes.CHANGE_COURSE_FILTER,
  value,
})

export const changeRoundRequestAction = (roundId) => ({
  type: ActionTypes.CHANGE_ROUND_REQUEST,
  roundId,
})

export const changeRoundReceivedAction = (data) => ({
  type: ActionTypes.CHANGE_ROUND_RECEIVED,
  data,
})

export const changeTournament = (id) => (dispatch, getState) => {
  dispatch(changeTournamentRequest(id))
  const state = getState()
  const url = state.misc.fetchInitialDataPath
  const params = {
    tournament_id: id,
  }

  dispatch(clearTimer())

  callAPI(url, 'GET', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch(changeTournamentReceived(response))
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('scoring_control_interface.ajax.change_tournament.error'))
    })
}

export const changeRound = (roundId) => (dispatch, getState) => {
  dispatch(changeRoundRequestAction(roundId))
  const state = getState()
  const url = state.misc.fetchInitialDataPath
  const tournamentId = state.misc.selectedTournamentId
  const params = {
    round_id: roundId,
    tournament_id: tournamentId,
  }

  dispatch(clearTimer())

  callAPI(url, 'GET', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch(changeRoundReceivedAction(response))
    })
    .catch(() => {
      showErrorNotification('Error while fetching initial data!')
    })
}

export const changeStartingHoleFilter = (value) => (dispatch) => {
  dispatch({
    type: ActionTypes.CHANGE_STARTING_HOLE_FILTER,
    value,
  })
}

export const changeCourseFilter = (value) => (dispatch) => {
  dispatch(changeCourseFilterAction(value))
}

export const changeSortOption = (value) => (dispatch) => {
  dispatch({
    type: ActionTypes.CHANGE_SORT_OPTION,
    value,
  })
}

export const changeSortOrderOption = () => (dispatch, getState) => {
  const state = getState()
  const order = state.filters.sortOrder === 'asc' ? 'desc' : 'asc'
  dispatch({
    type: ActionTypes.CHANGE_SORT_ORDER_OPTION,
    value: order,
  })
}

export const resetTimer = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_TIMER,
    refreshMethod: () => dispatch(refreshContent()),
  })
}

export const clearTimer = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLEAR_TIMER,
  })
}

export const showHideFiltersAction = (value) => ({
  type: ActionTypes.SHOW_HIDE_FILTERS,
  value,
})

export const showHideFilters = () => (dispatch, getState) => {
  const state = getState()
  const showFilters = state.filters.showFilters
  dispatch(showHideFiltersAction(!showFilters))
}


export const changeScore = (teeId, playerId, foursomeId, scores, newScoreValue, newScorePosition) => (dispatch, getState) => {
  // Don't do anything if the score id not valid
  if ([ null, undefined ].includes(newScoreValue) || !/^([1-9][0-9]?)$/.test(newScoreValue)) {
    return
  }

  // Get all existing modifyed scores
  const changedScores = getState().scoringInterfaceData.newScores
  const newPlayerScores = Object.prototype.hasOwnProperty.call(changedScores, playerId) ? changedScores[playerId]['player'][playerId] : {}

  // Return if the new score is the same as the original
  if (scores[newScorePosition] === newScoreValue) {
    // Clear new scores if there are no longer different from the original ones
    if (Object.keys(newPlayerScores).length !== 0) {
      const tmpScores = Object.values(newPlayerScores.score)
      tmpScores[newScorePosition] = newScoreValue

      if (JSON.stringify(tmpScores) === JSON.stringify(scores)) {
        dispatch({
          type: ActionTypes.CHANGE_SCORE,
          newScores: {},
        })

        return
      }
    }
  }

  const newScores = {}
  let tmpScores = scores
  if (Object.keys(newPlayerScores).length !== 0) {
    tmpScores = Object.values(newPlayerScores['score'])
  }

  tmpScores.forEach((score, index) =>
    newScores[index.toString()] = index === newScorePosition ? newScoreValue : score)

  const params = {
    'nr_loops': '1',
    'dirty': {'0': '1'},
    'foursome_id': foursomeId,
    'foursome_tee_id': teeId,
    'single_player_id': playerId,
    'foursome_clear_scores': {'0': '0'},
    'initial_scores': {[playerId]: scores.join(':')},
    // 'disposition': {'usga_index': '26.8', 'disp': '', 'cause': ''},
    'player': {
      [playerId]: {
        'score': {
          ...newScores,
        },
      },
    },
  }

  dispatch({
    type: ActionTypes.RESET_TIMER,
    refreshMethod: () => dispatch(refreshContent()),
  })

  dispatch({
    type: ActionTypes.CHANGE_SCORE,
    newScores: {
      ...changedScores,
      [playerId]: params,
    },
  })
}

export const saveScores = () => (dispatch, getState) => {
  const state = getState()
  const url = state.misc.updateScoresPath
  const params = { newScores: JSON.stringify(state.scoringInterfaceData.newScores) }

  callAPI(url, 'POST', params)
    .then(response => {
      if (response) {
        dispatch(refreshContent())
      }
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('scoring_control_interface.ajax.save_scores.error'))
    })
}
