import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { GlgCheckbox } from 'SharedComponents'
import { Modal, Button } from 'react-bootstrap'
import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { getAuthenticityToken } from 'Shared/helpers'
import { GlgLoadingSpinner } from 'SharedComponents'
import Loader from 'react-loader-spinner'

const StyledPage = styled.div`
  .name-input {
    width: 400px;
  }
`

const StyledModal = styled(Modal)`
  .modal-body {
    height: 320px;
  }
  
  .preview-container {
    margin: auto;
    margin-top: 40px;
    display: block;
    width: 15em; height: 10em;
    text-align: center;
    white-space: nowrap;
  }
  .preview-container img {
    display: block;
    width: auto; height: auto;
    max-width: 100%; max-height: 100%;
    vertical-align: middle;
  }
  .preview-container:after {
    display: block;
    height: 100%; width: 0;
    vertical-align: middle;
    content: "";
  }

`

const DraggableItem = styled.div`
  
  margin-top: 15px;

  .container {
    display: inline-block;
    width: calc(100% - 31px);
    max-width: 185px;
    height: 10em;
    padding: 5px;
    margin-bottom: 5px;
    text-align: center;
    white-space: nowrap;
  }
  .container img {
    display: inline-block;
    width: auto; height: auto;
    max-width: 100%; max-height: 100%;
    vertical-align: middle;
  }
  .container:after {
    display: inline-block;
    height: 100%; width: 0;
    vertical-align: middle;
    content: "";
  }
  
  .form-control {
    width: auto;
    display: inline;
  }

  i {
    cursor: pointer;
    margin-left: -31px;
  }

  .sponsor-url {
    width: calc(100% - 31px);
    max-width: 150px;
  }
`

const SponsorsNew = (props) => {
  const [ modalOpen, setModalOpen ] = useState(false)
  const [ images, setImages ] = useState(props.data?.photos || [])
  const [ formData, setFormData ] = useState(props.data || {primary: false})
  const [ deleted, setDeleted ] = useState([])
  const [ saveDisabled, setDisabled ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)

  const saveAlbum = (update) => {
    setIsLoading(true)
    if (saveDisabled) {
      return
    }

    setDisabled(true)

    const fd = new FormData()
    
    fd.append('name', formData.name)
    fd.append('primary', formData.primary)
    images.forEach(i => {
      fd.append('urls[]', i.url)
      fd.append('photos[]', i.imageFile || i.filename)
    })
    deleted.forEach(d => {
      fd.append('deleted[]', d || '')
    })
    if (update) {
      fetch('/api/customers/' + props.customerId + '/sponsors/' + props.data.id, {
        method: 'PATCH',
        body: fd,
        headers: {'X-CSRF-Token': getAuthenticityToken()}})
      .then(() => {
        setDisabled(true)
        setIsLoading(false)
        props.setPage('index')
      })
    } else {
      fetch('/api/customers/' + props.customerId + '/sponsors', {
        method: 'POST',
        body: fd,
        headers: {'X-CSRF-Token': getAuthenticityToken()}})
      .then(() => {
        setDisabled(true)
        setIsLoading(false)
        props.setPage('index')
      })
    }
  }

  const handleFormData = (e, key) => {
    const value = typeof(e) === 'object' ? e.target.value : e
    setFormData({...formData, [key]: value})
  }

  return (
    <StyledPage>
      {isLoading && 
        <div>
          <GlgLoadingSpinner 
          show={isLoading}
          className='add-margin-top-20 text-center'
          />
          <div className='save_bar'>
            <a className='button btsp_gray_button disabled'>Cancel</a>
            <a className='button btsp_orange_button disabled'>{props.data?.name ? 'Update' : 'Save'}</a>
          </div>
        </div>
        }
      {!isLoading &&
        <div>
          <div className='btsp_form_content col-xs-12'>
            <div className="col-xs-12 col-sm-11 col-md-11 col-lg-11 col-sm-offset-1 col-md-offset-1 col-lg-offset-1 tabs_content btsp_form_content">
              <ul className='btsp_ul'>
                <li className='form_row'>
                  <div className='row'>
                    <div className='col-sm-3 col-md-3 col-lg-3 hidden-xs'>
                      <label>Name</label>
                    </div>
                    <div className='col-xs-12 col-sm-9 col-md-9 col-lg-9'>
                      <input defaultValue={props.data?.name} onChange={(e) => handleFormData(e, 'name')} type='text' className='form-control form-text name-input'/>
                    </div>
                  </div>
                </li>
                <li className='form_row'>
                  <div className='row'>
                    <div className='col-sm-3 col-md-3 col-lg-3 hidden-xs'>
                      <label>Default</label>
                    </div>
                    <div className='col-xs-12 col-sm-9 col-md-9 col-lg-9'>
                      <GlgCheckbox 
                        name="primary"
                        value="true"
                        label="Mark this Sponsor template as default"
                        defaultChecked={ props.data?.primary }
                        onChange={ (e) => handleFormData(e, 'primary') }/>
                    </div>
                  </div>
                </li>
                <li className='form_row'>
                  <div className='row'>
                    <div className='col-sm-3 col-md-3 col-lg-3 hidden-xs'>
                      <label>Sponsors</label>
                    </div>
                    <div className='col-xs-12 col-sm-9 col-md-9 col-lg-9'>
                      <SponsorItems
                        images={images}
                        setImages={setImages}
                        deleted={deleted}
                        setDeleted={setDeleted}
                        setModalOpen={setModalOpen}
                      />
                    </div>

                  </div>
                </li>
              </ul>
            </div>
          </div>
          <AddSponsorsPopup
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            images={images}
            setImages={setImages}
          />
          <div className='save_bar'>
            <a className='button btsp_gray_button' onClick={() => props.setPage('index')}>Cancel</a>
            <a className='button btsp_orange_button' onClick={() => saveAlbum(props.data?.name ? 'update' : undefined)}>{props.data?.name ? 'Update' : 'Save'}</a>
          </div>
        </div>}
    </StyledPage>
  )
}

export const AddSponsorsPopup = (props) => {
  const [ imagePreview, setImagePreview ] = useState({image: ''})
  const [ disableImageSave, sedDisableImageSave ] = useState(true)

  const handleImageUpload = (e) => {
    const file = e.target.files[0]
    setImagePreview({image: URL.createObjectURL(file), imageFile: file, url: ''})

    let message = ''
    if (file.size > 6000000) { // bytes
      message = 'File exceeds maximum size.'
    }
    
    if ([ 'image/jpeg', 'image/png', 'image/gif', 'image/bmp' ].indexOf(file.type) < 0) {
      message += ' File format should be jpg, jpeg, gif, png, bmp.'
    }

    if (message !== '') {
      $('.error-message').removeClass('hidden')
      $('.error-message').html(message)
      sedDisableImageSave(true)

    } else {
      $('.error-message').addClass('hidden').disabled = false
      sedDisableImageSave(false)
    }
  }

  const handleModalClose = () => {
    props.setModalOpen(false)
    setImagePreview({ image: '' })
  }

  const saveImage = () => {
    props.setImages([ ...props.images, imagePreview ])
    handleModalClose()
  }

  return (
    <StyledModal show={props.modalOpen} onHide={() => handleModalClose()}>
      <Modal.Header closeButton>
        Add Sponsor&apos;s Logo
      </Modal.Header>
      <Modal.Body>
        <input className='add-margin-bottom-10' name='sponsor_file_from_popup' type='file' accept='image/jpeg, image/png, image/gif, image/bmp' onChange={(e) => handleImageUpload(e)}/>
        <div className='help-message add-margin-bottom-10'>
          <div>{window.I18n.t('views.golfhub.accepted_photo_formats')}</div>
          <div>{window.I18n.t('views.golfhub.sponsor_acceptable_format')}</div>
        </div>
        <div className='error-message btsp_alert_notices hidden add-margin-bottom-10 add-padding-all-5'></div>
        <div className='preview-container'>
          <img src={imagePreview.image} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handleModalClose()}>{window.I18n.t('views.golfhub.modal.close')}</Button>
        <Button className='add-margin-left-10 button btsp_orange_button' disabled={!imagePreview.image || disableImageSave} onClick={() => saveImage()} >{window.I18n.t('views.golfhub.modal.save')}</Button>
      </Modal.Footer>
    </StyledModal>
  )
}

export const SponsorItems = ({images, setImages, deleted, setDeleted, setModalOpen, showLoading}) => {
  const swapImages = (result) => {
    const tempArr = [ ...images ]
    const tempVar = tempArr[ result.source.index ]
    tempArr.splice(result.source.index, 1)
    tempArr.splice(result.destination.index, 0, tempVar)

    setImages(tempArr)
  }

  const removeImage = (index) => {
    const tempArr = [ ...images ]
    setDeleted([ ...deleted, tempArr.splice(index, 1)[0]?.id ])
    setImages(tempArr)
  }

  const handleImgData = (e, index) => {
    const tempArr = [ ...images ]
    tempArr[index].url = e.target.value
    setImages(tempArr)
  }

  return (
    <Fragment>
      <div className='add-margin-bottom-20 btsp_bullet_button pointer' onClick={() => setModalOpen(true)}>
        <div className='icon'><i className='gga-circle-plus'></i></div>
        <div className='btsp_align-middle'>Add Sponsor</div>
      </div>
      {images.length > 0 && (
        <DragDropContext onDragEnd={swapImages}>
          <Droppable droppableId="droppable">
          {(provided) => (
            <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className='img-container'>
              
              {images.map((img, i) => (
                <Draggable key={'draggable-' + i} draggableId={'draggable-' + i} index={i} >
                  {(prov) => (
                    <DraggableItem
                      ref={prov.innerRef}
                      {...prov.draggableProps}
                      {...prov.dragHandleProps}>
                      <i className='fa fa-bars fa-lg add-margin-right-10'></i>
                      <div className='container'><img src={img.image} key={i}/></div>
                      {showLoading === true && (
                        <span className="span pull-right glg-loading-spinner sponsor-loading">
                          <Loader type="Oval" color={ '#0088CE' } height={ 20 } width={ 30 }></Loader>
                        </span>
                      )}
                      <div className='inline_element'>
                        <input name='sponsor_urls[]' placeholder="Sponsor URL" value={img.url} onChange={(e) => handleImgData(e, i)} type='text' className='form-control form-text sponsor-url'/>
                        <i className='fa fa-trash fa-lg add-margin-left-10' onClick={() => removeImage(i)}></i>
                      </div>
                    </DraggableItem>
                  )}
                </Draggable>
              ))}
            </div>
          )}
          </Droppable>
        </DragDropContext>
      )}
    </Fragment>
  )
}

SponsorItems.propTypes = {
  setModalOpen: PropTypes.func,
  setImages: PropTypes.func,
  setDeleted: PropTypes.func,
  images: PropTypes.array,
  deleted: PropTypes.array,
  showLoading: PropTypes.bool,
}

AddSponsorsPopup.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  images: PropTypes.array,
  setImages: PropTypes.func,
}

SponsorsNew.propTypes = {
  setPage: PropTypes.func,
  customerId: PropTypes.string,
  data: PropTypes.object,
}

export default SponsorsNew
