import { ActionTypes } from '../actions'

const {
  FETCH_INITIAL_DATA_RECEIVED,
} = ActionTypes

const portals = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INITIAL_DATA_RECEIVED: {
      return {
        ...state,
        ...action.data.portals,
      }
    }
    default:
      return state
  }
}

export default portals
