import React, {Fragment} from 'react'
import PropTypes from 'prop-types'

import {
  GlgTableRow,
  GlgTableCell,
} from 'SharedComponents'
import {GlgButton, GlgLoadingSpinner, GlgPopup} from '../../../shared/components'
import Row from 'react-bootstrap/lib/Row'
import Iframe from 'react-iframe'

const ParticipationHistoryRoundTableRow = ({ ...props }) => {
  let roundName = ''
  if (props.eventUrl){
    roundName = <a href={ props.eventUrl } target={ props.target }>{ props.name }</a>
  } else {
    roundName = props.name
  }
  let season = ''
  if (props.season){
    season = props.season
  }
  let teeTime = ''
  if (props.tee_time){
    teeTime = props.tee_time
  }
  const isLeaderboardActive = props.past && props.isOpenedLeaderboardPopup && props.currentlyRoundLeaderboardPopup === props.id

  const handleLeaderboardOpen = () => {
    window.parent.scrollTo(0, 0)
    props.onLeaderboardOpen(true, props.id)
    props.fetchLeaderboardStart()
  }
  const handleLeaderboardClose = () => {
    props.onLeaderboardOpen(false, '')
  }

  const leaderboardLoaded = () => {
    props.fetchLeaderboardEnd()
  }

  return <GlgTableRow { ...props } isSortable={ false }><GlgTableCell>{ roundName }</GlgTableCell><GlgTableCell><div className="season_label label label-success">{ season }</div></GlgTableCell><GlgTableCell>{ props.starts }</GlgTableCell><GlgTableCell>{ teeTime }</GlgTableCell>{ props.past ? <GlgTableCell><GlgButton text={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.leaderboard') } onClick={ handleLeaderboardOpen } className="btn btn-xs btn-block btn-default view-leaderboard-btn"></GlgButton>{ isLeaderboardActive && <GlgPopup show={ isLeaderboardActive } showBackdrop={false} title={ props.name } showSaveButton={false} showCloseButton={false} onClose={ handleLeaderboardClose } modalClass={'leaderboard-popup'}><Fragment><GlgLoadingSpinner show={ props.loadingLeaderboards }></GlgLoadingSpinner><Row className="player_activity_leaderboard"><Iframe id="leaderboard-iframe" onLoad={leaderboardLoaded} className={ props.loadingLeaderboards ? 'hidden' : ''} url={ props.leaderboardUrl }></Iframe></Row></Fragment></GlgPopup>}</GlgTableCell> : (props.ottUrl) ? <GlgTableCell><GlgButton text={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.select_tee_time') } onClick={ () => window.open(props.ottUrl, '_blank') } className="btn btn-xs btn-block btn-default view-leaderboard-btn"></GlgButton></GlgTableCell> : (props.registrationUrl) ? <GlgTableCell><GlgButton text={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.edit_registration') } onClick={ () => window.open(props.registrationUrl, '_blank') } className="btn btn-xs btn-block btn-default view-leaderboard-btn"></GlgButton></GlgTableCell> : <GlgTableCell><div></div></GlgTableCell>
      }</GlgTableRow>
}

ParticipationHistoryRoundTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  season: PropTypes.string,
  seasonId: PropTypes.string,
  leaderboardUrl: PropTypes.string,
  eventUrl: PropTypes.string,
  starts: PropTypes.string.isRequired,
  target: PropTypes.string,
  tee_time: PropTypes.string,
  isOpenedLeaderboardPopup: PropTypes.bool,
  currentlyRoundLeaderboardPopup: PropTypes.string,
  onLeaderboardOpen: PropTypes.func,
  past: PropTypes.bool,
  loadingLeaderboards: PropTypes.bool,
  fetchLeaderboardStart: PropTypes.func,
  fetchLeaderboardEnd: PropTypes.func,
  ottUrl: PropTypes.string,
  registrationUrl: PropTypes.string,
}

export default ParticipationHistoryRoundTableRow
