import { hexToRgb, shrinkFontSize } from '../helpers'
import {
  USGA_SILVER,
  USGA_OLD_BLUE,
  GREY,
  BLUE_GREY,
} from '../constants'

// The order in which the functions are called is important!
export const applyMatchChangesHtmlInjection = (containerId, containerSelector, slide, fontSize) => {
  adjustMatchPointsPurseWidth(containerSelector, fontSize)
  renameMatchTableHeader(containerSelector, slide)
  changeMatchTeamColumnDisplay(containerSelector)
  moveMatchScoreIntoSpan(containerId, containerSelector)
}

export const applyMatchChangesForPage = (applyUSGATheme, containerSelector, slide, colorTheme, fontSize) => {
  adjustMatchPointsPurseWidth(containerSelector, fontSize)
  addHeightClassToMatchNames(containerSelector)
  changeMatchBackgroundOpacity(applyUSGATheme, containerSelector, slide, colorTheme)
  scalePointsAndPurseToFit(containerSelector)
  scaleScoreStatusToFit(containerSelector)
  scaleMatchHeaderToFit(containerSelector)
  centerTableHeaderText(containerSelector)
}

const renameMatchTableHeader = (containerSelector, slide) => {
  const scoreTh = $(`${containerSelector} table.ryder th.score:not(.hidden-web)`)
  $(scoreTh).text(slide.roundName.toUpperCase())

  const nameTh = $(`
    ${containerSelector} table.ryder th.name-match-play-right,
    ${containerSelector} table.ryder th.name-match-play-left
  `)
  $(nameTh).text('')
}

/**
 * Distribute the overflow text equally on both sides of the element.
 */
const centerTableHeaderText = (containerSelector) => {
  const scoreTh = $(`${containerSelector} table.ryder th.score:not(.hidden-web)`)
  if (scoreTh.length === 0) {
    return
  }

  const overflowDiff = $(scoreTh)[0].scrollWidth - $(scoreTh)[0].offsetWidth
  if (overflowDiff <= 0) {
    return
  }
  const halfOverflow = Math.round(overflowDiff / 2)
  $(scoreTh).css('transform', `translateX(-${halfOverflow}px)`)
}

export const changeMatchBackgroundOpacity = (applyUSGATheme, containerSelector, slide, colorTheme) => {
  if (!applyUSGATheme || $(`${containerSelector} table.ryder`).length === 0) {
    return
  }
  const bgOpacity = parseInt(slide.backgroundOpacity || 100) / 100
  const rgb = {}
  if (colorTheme === 'light') {
    rgb.names = hexToRgb(USGA_SILVER)
    rgb.score = hexToRgb(USGA_OLD_BLUE)
    rgb.tiedScore = hexToRgb(GREY)
  } else {
    rgb.names = hexToRgb(USGA_OLD_BLUE)
    rgb.score = hexToRgb(USGA_SILVER)
    rgb.tiedScore = hexToRgb(BLUE_GREY)
  }
  const namesBg = `rgba(${rgb.names.r}, ${rgb.names.g}, ${rgb.names.b}, ${bgOpacity})`
  const scoreBg = `rgba(${rgb.score.r}, ${rgb.score.g}, ${rgb.score.b}, ${bgOpacity})`
  const tiedScoreBg = `rgba(${rgb.tiedScore.r}, ${rgb.tiedScore.g}, ${rgb.tiedScore.b}, ${bgOpacity})`
  const isFirefox = typeof InstallTrigger !== 'undefined'
  const bgClip = isFirefox ? 'padding-box' : 'initial'
  $(`
    ${containerSelector} table.ryder tr.aggregate-row td.name-match-play-left,
    ${containerSelector} table.ryder tr.aggregate-row td.name-match-play-right,
    ${containerSelector} table.ryder tr.aggregate-row td.points,
    ${containerSelector} table.ryder tr.aggregate-row td.purse
  `)
    .css({
      'background': namesBg,
      'background-clip': bgClip,
    })
  
  $(`${containerSelector} table.ryder tr.aggregate-row td.score:not(.tied)`)
    .css({
      'background': scoreBg,
      'background-clip': bgClip,
    })
  $(`${containerSelector} table.ryder tr.aggregate-row td.score.winning-right .score-content`)
    .css('border-left-color', scoreBg)
  $(`${containerSelector} table.ryder tr.aggregate-row td.score.winning-left .score-content`)
    .css('border-right-color', scoreBg)

  $(`${containerSelector} table.ryder tr.aggregate-row td.score.tied`)
  .css({
    'background': tiedScoreBg,
    'background-clip': bgClip,
  })
}

/**
 *  Necessary to add text padding in relation to the winner arrow, using the CSS classes: .name-match-play-left.height-#{$i}
 */
const addHeightClassToMatchNames = (containerSelector) => {
  $(`
    ${containerSelector} table.ryder td.name-match-play-left,
    ${containerSelector} table.ryder td.name-match-play-right
  `).toArray()
    .forEach(el => {
      const prevHeightClass = el.className.match(/height-[0-9]*/g) || []
      $(el).removeClass(prevHeightClass[0])
      const height = $(el).outerHeight()
      $(el).addClass(`height-${height}`)
    })
}

const changeMatchTeamColumnDisplay = (containerSelector) => {
  const matchPlayName = $(`${containerSelector} table.ryder .match-play-name-container .match-play-name`)
  const playerNames = $(matchPlayName).children('.split-player-names.usga-theme')
  const teamName = $(matchPlayName).children('a.open-aggregate-details')

  if (playerNames.length > 0) {
    $(matchPlayName)
      .contents()
      .toArray()
      .forEach((child) => {
        if (child.nodeType === Node.TEXT_NODE) {
          child.remove()
        }
      })
    teamName.addClass('hidden')
    playerNames.removeClass('hidden')
  }
}

const moveMatchScoreIntoSpan = (containerId, containerSelector) => {
  const scoreDivs = $(`
    ${containerSelector} table.ryder .score .score-status,
    ${containerSelector} table.ryder .score .thru`,
  )
  for (let i = 0; i < scoreDivs.length; i++) {
    const id = `span-score-${containerId.substring(1)}${i}`
    $(scoreDivs[i]).append(`<span id="${id}"></span>`)

    const span = $(scoreDivs[i]).children('#' + id)[0]
    if (span !== undefined) {
      $(scoreDivs[i])
        .contents()
        .toArray()
        .forEach((child) => { 
          if (child.nodeType === Node.TEXT_NODE) {
            span.appendChild(child)
          }
        })
    }
  }
}

const scaleScoreStatusToFit = (containerSelector) => {
  $(`
    ${containerSelector} table.ryder .score .score-status,
    ${containerSelector} table.ryder .score .thru
  `).toArray()
    .forEach((div) => {
      const textChild = $(div).children('span')
      const scoreParent = $(div).parents('.score')
      const checkWidthExceedsLimit = (textChild, maxAllowedWidth) => $(textChild).width() > maxAllowedWidth
      shrinkFontSize(textChild, checkWidthExceedsLimit, textChild, $(scoreParent).width(), 10)
    })
}

const adjustMatchPointsPurseWidth = (containerSelector, sliderFontSize) => {
  $(`${containerSelector} table.ryder th.points, ${containerSelector} table.ryder th.purse`)
    .css('width', Math.ceil(sliderFontSize / 10) + '%') 
}

const largestElem = (elems) => {
  const maxWidth = $(elems)
    .toArray()
    .map((e) => e.scrollWidth)
    .max()
  return $(elems)
    .toArray()
    .find((e) => e.scrollWidth === maxWidth)
}

const scalePointsAndPurseToFit = (containerSelector) => {
  const checkWidthExceedsLimit = (td, maxAllowedWidth) => td.scrollWidth > maxAllowedWidth

  const tdPoints = $(`${containerSelector} table.ryder td.points`)
  if (tdPoints.length > 0) {
    const maxAllowedWidth = tdPoints[0].offsetWidth
    shrinkFontSize(tdPoints, checkWidthExceedsLimit, largestElem(tdPoints), maxAllowedWidth, 5)
  }
  const tdPurse = $(`${containerSelector} table.ryder td.purse`)
  if (tdPurse.length > 0) {
    const maxAllowedWidth = tdPurse[0].offsetWidth
    shrinkFontSize(tdPurse, checkWidthExceedsLimit, largestElem(tdPurse), maxAllowedWidth, 5)
  }
}

const scaleMatchHeaderToFit = (containerSelector) => {
  let thPointsPurseTotalWidth = 0
  const checkWidthExceedsLimit = (th, maxAllowedWidth) => th.scrollWidth > maxAllowedWidth

  const thPoints = $(`${containerSelector} table.ryder th.points`)
  if (thPoints.length > 0) {
    const maxAllowedWidth = thPoints[0].offsetWidth
    shrinkFontSize(thPoints, checkWidthExceedsLimit, thPoints[0], maxAllowedWidth, 4)
    thPointsPurseTotalWidth += maxAllowedWidth * thPoints.length
  }

  const thPurse = $(`${containerSelector} table.ryder th.purse`)
  if (thPurse.length > 0) {
    const maxAllowedWidth = thPurse[0].offsetWidth
    shrinkFontSize(thPurse, checkWidthExceedsLimit, thPurse[0], maxAllowedWidth, 4)
    thPointsPurseTotalWidth += maxAllowedWidth * thPurse.length
  }

  const thScore = $(`${containerSelector} table.ryder th.score:not(.hidden-web)`)
  if (thScore.length > 0) {
    const remainingWidth = $('tr.header.thead').width() - thPointsPurseTotalWidth
    shrinkFontSize(thScore, checkWidthExceedsLimit, thScore[0], remainingWidth, 10)
  }
}
