import { connect } from 'react-redux'
import BlockReorder from '../presentational/block_reorder'
import { reorderBlocks, displayMode, DISPLAY_MODES } from '../../actions'

const mapStateToProps = state => ({
  blocks: state.blocks.filter(block => block.tabId === state.filters.active_tab).sort( (a, b) => a.index <= b.index ? -1 : 1 ),
  columns: state.columns,
})

const mapDispatchToProps = dispatch => ({
  reorderBlocks: _blocks => dispatch(reorderBlocks(_blocks)),
  cancel: () => dispatch(displayMode(DISPLAY_MODES.DEFAULT)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BlockReorder)
