import React from 'react'
import rootReducer from '../reducers'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'
import MemberCardTableContainer from './member_card_table_container'
import PropTypes from 'prop-types'

const MemberCards = ({memberCards, members, misc}) => { 

  const state = {
    memberCards: memberCards,
    members: members,
    misc: misc,
  }
  const store = configureStore(rootReducer, state)

  return (
    <GlobalProvider store={ store }>
      <MemberCardTableContainer/>
    </GlobalProvider>
  )
}

MemberCards.propTypes = {
  memberCards: PropTypes.object,
  members: PropTypes.object,
  misc: PropTypes.object,
}
export default MemberCards
