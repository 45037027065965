import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ReportCenterItemDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      opened: false,
    }
    this.handleButtonClick = this.handleButtonClick.bind(this)
  }

  handleButtonClick() {
    this.setState({ opened: !this.state.opened })
  }
  
  render(){
    const dropdownItems = (
        <ul
          className="hover-dropdown"
          data-is-open={this.state.opened ? 'opened' : 'closed'}
          style={{ display: this.state.opened ? 'block' : 'none' }}>
        { this.state.opened &&
          this.props.report_center_lists
            .filter(rcl => !rcl.read_only && !rcl.archive_list)
            .map(rcl => (
              <li key={rcl.id}>
                <div className="checkbox btsp_checkbox checkbox-primary no-margin">
                  <input
                    id={'dd_inline_' + this.props.report_center_item.id + '_' + rcl.id}
                    type="checkbox"
                    name="report_center_item[report_center_list_ids][]"
                    onChange={e =>
                      this.props.addRemoveItemToList(
                        this.props.report_center_item.id,
                        this.props.current_report_center_list.id,
                        rcl.id,
                        e
                      )
                    }
                    checked={
                      rcl.report_center_items
                        .map(rci => rci.id)
                        .indexOf(this.props.report_center_item.id) !== -1
                        ? true
                        : false
                    }
                  />
                  <label htmlFor={'dd_inline_' + this.props.report_center_item.id + '_' + rcl.id}>
                    {!window.I18n
                      ? ''
                      : window.I18n.t(`download_center.report_center_list.names.${this.props.stringParameterize(rcl.name)}`, {
                          defaultValue: rcl.name,
                        })}
                  </label>
                </div>
              </li>
            ))}
      </ul>
    )

    return (
      this.props.layout === 'inline' ? 
        <div>
          <a onClick={ this.handleButtonClick } className='btsp_orange_button no-lite'>
            {!window.I18n ? '' : window.I18n.t('download_center.categories')}&nbsp;
            <span className='caret'></span>
          </a>
          { dropdownItems }
        </div>
        :
        <span>
          <button onClick={ this.handleButtonClick } className="btn btn-default" >
            <span>
              {!window.I18n ? '' : window.I18n.t('download_center.categories')} &nbsp;
            </span>
            <span className="caret"></span>
          </button>
          { dropdownItems }
      </span>
    )
  }
}

ReportCenterItemDropdown.propTypes = {
  layout: PropTypes.string.isRequired,
  report_center_lists: PropTypes.array.isRequired,
  current_report_center_list: PropTypes.object.isRequired,
  report_center_item: PropTypes.object.isRequired,
  addRemoveItemToList: PropTypes.func.isRequired,
  stringParameterize: PropTypes.func.isRequired,
}

export default ReportCenterItemDropdown
