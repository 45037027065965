import { connect } from 'react-redux'
import EventSetupPairings from '../../components/content/event_setup_pairings'
import {
  setGenericData,
  saveEventSettingsWidget,
  loadWidgetContent,
} from '../../actions/index'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { data, links, dataChanged } = ownProps.content

  const setDataChanged = () => dataChanged || dispatch(setGenericData(ownProps.widgetId, [ 'dataChanged' ], true))
  const set = (keys, value, dataChanged = true) => {
    dispatch(setGenericData(ownProps.widgetId, keys, value))
    dataChanged && setDataChanged()
  }

  const setHole = (hole, withTeeSheet) =>
    withTeeSheet ?
      set([ 'data', 'courses_tees', 'course_tee_hole' ], hole) :
      set([ 'data', 'courses_tees', 'variable_settings_hole_type' ], hole)

  const setCourseTee = (tee, withTeeSheet) => {
    if (withTeeSheet) {
      set([ 'data', 'courses_tees', 'course_tee_without_hole' ], tee)
      if ((data.courses_tees.course_tee_hole in data.courses_tees.tee_options[tee].holes) === false) {
        setHole(Object.values(data.courses_tees.tee_options[tee].holes)[0]?.value || -1, withTeeSheet)
      }
    } else {
      set([ 'data', 'courses_tees', 'variable_settings_default_course' ], tee)
      if (tee in data.courses_tees.default_courses && (data.courses_tees.variable_settings_hole_type in data.courses_tees.default_courses[tee].holes) === false) {
        setHole(Object.values(data.courses_tees.default_courses[tee].holes)[0]?.value || -1, withTeeSheet)
      }
      set([ 'data', 'courses_tees', 'variable_settings_default_tee_m' ], Object.values(data.courses_tees.default_courses[tee].tees)[0]?.value || '')
      set([ 'data', 'courses_tees', 'variable_settings_default_tee_f' ], Object.values(data.courses_tees.default_courses[tee].tees)[0]?.value || '')
    }
  }

  const setPairingGroupSize = (size) => {
    set([ 'data', 'pairing_group_size', 'value' ], size)
    if (size === 1 && data.courses_tees.variable_settings_default_course === -1) {
      setCourseTee(Object.values(data.courses_tees.default_courses)[0]?.value || -1, false) // set a first no-tee-sheet course
    }
  }

  const withTeeSheet = data.pairing_group_size.value !== 1
  return {
    setData: set,
    setHole: (hole) => setHole(hole, withTeeSheet),
    setCourseTee: (tee) => setCourseTee(tee, withTeeSheet),
    setPairingGroupSize,
    save: () => {
      const pairingsData = { }
      for (const key of [ 'pairing_group_size', withTeeSheet && 'pairing_groups_count' in data && 'pairing_groups_count' ].filter(e => e)) {
        pairingsData[data[key].key] = data[key].value
      }

      const courseTeeKeys = withTeeSheet ?
        [ 'course_tee_without_hole', 'course_tee_hole' ] :
        [
          'variable_settings_default_course',
          'variable_settings_hole_type',
          ...(data.courses_tees.default_tee_by_gender_enabled ? [ 'variable_settings_default_tee_m', 'variable_settings_default_tee_f' ] : []),
        ]

      for (const key of courseTeeKeys) {
        pairingsData[key] = data.courses_tees[key]
      }

      dispatch(saveEventSettingsWidget(ownProps.widgetId, links.save.path, {
        pairings_data: pairingsData,
        ...('default_attendance_status' in data && { [data.default_attendance_status.key]: data.default_attendance_status.value }),
        ...('tee_times' in data && {
          [data.tee_times.first_tee_time.key]: `${data.tee_times.first_tee_time.hour}:${data.tee_times.first_tee_time.min} ${data.tee_times.first_tee_time.am_pm}`,
          [data.tee_times.last_tee_time.key]: `${data.tee_times.last_tee_time.hour}:${data.tee_times.last_tee_time.min} ${data.tee_times.last_tee_time.am_pm}`,
          [data.tee_times.interval.key]: data.tee_times.interval.value,
          [data.tee_times.starters_time.key]: data.tee_times.starters_time.value,
        }),
        ...('hide_players_on_tee_times' in data && {
          [data.hide_players_on_tee_times.key]: data.hide_players_on_tee_times.selected,
        }),
        ...('ott_ggid_read_only' in data && {
          [data.ott_ggid_read_only.key]: data.ott_ggid_read_only.selected,
        }),

        // course selection
        [data.course_selection.allow_players_from_mobile.key]: data.course_selection.allow_players_from_mobile.selected,
        ...('attester_required' in data.course_selection && data.course_selection.allow_players_from_mobile.selected && {
          [data.course_selection.attester_required.key]: data.course_selection.attester_required.selected,
        }),
      })).then(({ state }) => {
        const dependentWidgets = state.widgets[state.menu.selectedMenuItem].filter(w => w.content_type === 'event_setup_round_signups')
        dependentWidgets.forEach(widget => {
          dispatch(loadWidgetContent(widget.data_path, widget.id))
        })
      })
    },
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(EventSetupPairings)

