import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown, Label, MenuItem } from 'react-bootstrap'
import MemberContainer from '../containers/member_container'
import { useState, useEffect } from 'react'
import MemberCardInfo from './member_card_info_component'
import { GlgLoadingSpinner } from 'SharedComponents'

const MemberCard = (props) => {

  const [ showAll, setShowAll ] = useState(false)
  const [ checkAll, setCheckAll ] = useState(false)
  
  useEffect(() => {
    setShowAll(props.members.length > 3)
  }, [ props.members ])

  const isMerging = props.indexSticky && props.type === 'MERGE'

  const handleMerge = () => {
    props.setSticky(props.memberCard, props.index, 'MERGE')
  }

  const handleSplit = () => {
    props.setSticky(props.memberCard, props.index, 'SPLIT')
  }

  const handleCheckAll = () => {
    checkAll ? props.removeMembersFromSticky(props.members) : props.addAllMembersToSticky(props.memberCard.id)
    setCheckAll(!checkAll)
  }

  const handleShowMore = () => {
    setShowAll(true)
    props.showMoreMembers(props.memberCard.id)
  }

  const handleShowLess = () => {
    setShowAll(false)
    props.showLessMembers(props.memberCard.id)
  }

  const handleSync = () => {
    props.syncMrRecord(props.memberCard.id)
  }

  const handleRelink = () => {
    props.relinkMembers(props.memberCard.id)
  }

  const handleDeleteRelink = () => {
    props.deleteRelinkMembers(props.memberCard.id)
  }

  const SelectAllButton = () => {
    if (isMerging) {
      return ( 
        <tr className="checkbox-row">
          <td colSpan='2'>
            <button  
              className={`mc-button ${checkAll ? 'selected' : ''}`}
              onClick={handleCheckAll}>
                {checkAll ? window.I18n.t('member_cards.deselect_all') : window.I18n.t('member_cards.select_all')}
            </button>
          </td>
        </tr>
      )
    }
    return null
  }

  const ExpandMembersButton = () => {
    return (
      <Button
        colSpan={2}
        className='mc-button show-all' 
        onClick={handleShowMore}>
        <span className='expand-icon-span'/>
        {window.I18n.t('member_cards.show_more_records')}
      </Button>
    )
  }

  const CollapseMembersButton = () => {
    return (
      <Button
        colSpan={2}
        className='mc-button show-all' 
        onClick={handleShowLess}>
        <span className='collapse-icon-span'/>
        {window.I18n.t('member_cards.show_less_records')}
      </Button>
    )
  }

  if (props.index === props.indexSticky || props.members.length === 0) {
    return null
  } else {
    return (
        <tr>
          <td className='column-15'>
            <div >
              <h5>
                <b>{props.memberCard.first_name + ' ' + props.memberCard.last_name}</b>
              </h5>
              <h5><Label>{props.memberCard.member_count } {window.I18n.t('member_cards.golfers_match')}</Label></h5>
              <MemberCardInfo memberCard = {props.memberCard} index = {props.index} handicapProvider = {props.handicapProvider}/>
              <div className='merge-split-button-div'>
                {!props.indexSticky && <Button className='mc-button' onClick={handleMerge}><span className='merge-icon-span'/>{window.I18n.t('member_cards.merge')}</Button>}
                {!props.indexSticky && <Button className='mc-button' onClick={handleSplit}><span className='split-icon-span'/>{window.I18n.t('member_cards.split')}</Button>}
                {!props.indexSticky &&
                  <Dropdown >
                    <Dropdown.Toggle className='other_actions_dropdown'></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <MenuItem key = 'sync' eventKey = 'sync' onSelect= { handleSync }>{window.I18n.t('member_cards.sync_mr_member')}</MenuItem>
                      <MenuItem key = 'relink' eventKey = 'relink' onSelect= { handleRelink }>{window.I18n.t('member_cards.relink')}</MenuItem>
                      <MenuItem key = 'delete_relink' eventKey = 'delete_relink' onSelect= { handleDeleteRelink }>{window.I18n.t('member_cards.delete_and_relink')}</MenuItem>
                    </Dropdown.Menu>
                  </Dropdown>
                }
              </div>
              {props.memberCard.member_count > 10 && showAll && <CollapseMembersButton/>}
            </div>
          </td>
          <td>
            <table className={isMerging ? 'mc-table-rounded-rows' : 'mc-table'}>
              <thead>
                <tr>
                  { isMerging && <th className='column-2'/>}
                  <th className='column-7'>{window.I18n.t('member_cards.date')}</th>
                  <th className = 'column-10'>{window.I18n.t('member_cards.name')}</th>
                  <th className = 'column-10'>{window.I18n.t('member_cards.strength')}</th>
                  <th className = 'column-20'>{window.I18n.t('member_cards.event')}</th>
                  <th className = 'column-15'>{window.I18n.t('member_cards.email')}</th>
                  <th className = 'column-15'>{props.handicapProvider || window.I18n.t('member_cards.handicap_network_id')}</th>
                  <th className = 'column-7'>{window.I18n.t('member_cards.user_id')}</th>
                  <th className = 'column-20'>{props.customFieldName && props.mcIdentifierSet ? props.customFieldName['default'] : window.I18n.t('member_cards.identifier_id')}</th>
                </tr>
              </thead>
              <tbody>
                <SelectAllButton/>
                {props.members.map((member) => {
                  return (
                    <MemberContainer member = {member} key = {member.id} isSticky = {false} type = {props.type} checked = {checkAll}/>
                ) 
                }) }
              </tbody>
            </table>
            {props.memberCard.member_count > 3 && ( showAll ? <CollapseMembersButton/> : <ExpandMembersButton/> )}
            {props.loadingMembersMc === props.index && <GlgLoadingSpinner height = {10}/>}
          </td>
        </tr>
    )
  }
}

MemberCard.propTypes = {
  memberCard: PropTypes.object,
  members: PropTypes.array,
  index: PropTypes.string,
  editData: PropTypes.func,
  setSticky: PropTypes.func,
  indexSticky: PropTypes.string,
  addAllMembersToSticky: PropTypes.func,
  removeMembersFromSticky: PropTypes.func,
  showMoreMembers: PropTypes.func,
  showLessMembers: PropTypes.func,
  type: PropTypes.string,
  loadingMembersMc: PropTypes.string,
  handicapProvider: PropTypes.string,
  mcIdentifierSet: PropTypes.bool,
  customFieldName: PropTypes.object,
  syncMrRecord: PropTypes.func,
  relinkMembers: PropTypes.func,
  deleteRelinkMembers: PropTypes.func,
}
export default MemberCard
