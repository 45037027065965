import { connect } from 'react-redux'
import Dft from '../../components/content/dft'
import { toggleDivisionActions } from '../../actions/index'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleDivisionActions: divisionId => dispatch(toggleDivisionActions(ownProps.widgetId, divisionId)),
})

export default connect(
  null,
  mapDispatchToProps,
)(Dft)
