import React from 'react'
import PropTypes from 'prop-types'
import rootReducer from '../reducers'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import CustomerCenterContainer from '../containers/customer_center_container'

const CustomerCenterRoot = ({
  fetchInitialDataURL, fetchCategoryLeaguesURL,
  fetchCloneLeaguesURL, cloneLeaguesDataList,
  fetchLeaguePotentialCloneCandidateURL, potentialCloneCandidate,
  filters, sorting,
  customers, customerId, userIsCustomerManager, managedCustomerId, toursEnabled,
  userSource, branding, userIsAdmin, userIsTourManager, initialPopupAction,
  // props for sales demo
  isSalesDemo, categories, leagues,
  // visage
  userIsVisageAdmin, userIsVisageSuperAdmin, manageVisageURL,
  // users with no customer id and no leagues
  lastVisitedLeagueData,
  // limits for B2C customers,
  customerOnlyLeagues, customerTripOnly, canCreateTrips, customerNeedsSubscription,
  golfHubEnabled, isTmLite,
}) => {
  let state
  if (!isSalesDemo) {
    // Only events for GCAT
    const customerOnlyEvents = (customerId === '18589')
    state = {
      misc: {
        fetchInitialDataURL,
        fetchCategoryLeaguesURL,
        fetchCloneLeaguesURL,
        fetchLeaguePotentialCloneCandidateURL,
        potentialCloneCandidate,
        cloneLeaguesDataList,
        customerId,
        userIsCustomerManager,
        managedCustomerId,
        toursEnabled,
        userIsVisageAdmin,
        userIsVisageSuperAdmin,
        manageVisageURL,
        userIsAdmin,
        userSource,
        branding,
        userIsTourManager,
        initialPopupAction,
        customerOnlyLeagues,
        customerNeedsSubscription,
        customerTripOnly,
        customerOnlyEvents,
        canCreateTrips,
        // Only passed in edge case
        lastVisitedLeagueData,
        golfHubEnabled,
        isTmLite,
      },
      filters,
      sorting,
      customers,
    }
  } else {
    state = {
      misc: {
        isSalesDemo,
        userIsCustomerManager: true,
      },
      categories,
      leagues,
    }
  }
  const store = configureStore(rootReducer, state)

  // expose the dispatch function to non-React code
  // this is needed because we want to open popups from the dropdown menu
  window.glg.dispatch = store.dispatch

  return <GlobalProvider store={ store }><CustomerCenterContainer></CustomerCenterContainer></GlobalProvider>
}

CustomerCenterRoot.propTypes = {
  fetchInitialDataURL: PropTypes.string,
  fetchCategoryLeaguesURL: PropTypes.string,
  fetchCloneLeaguesURL: PropTypes.string,
  fetchLeaguePotentialCloneCandidateURL: PropTypes.string,
  potentialCloneCandidate: PropTypes.any,
  cloneLeaguesDataList: PropTypes.array,
  filters: PropTypes.shape({
    viewType: PropTypes.string,
    advancedFiltersOn: PropTypes.bool,
    season: PropTypes.string,
    category: PropTypes.string,
    tour: PropTypes.string,
    registration: PropTypes.string,
  }),
  sorting: PropTypes.shape({
    column: PropTypes.string,
    order: PropTypes.string,
  }),
  customers: PropTypes.object,
  customerId: PropTypes.string,
  userIsCustomerManager: PropTypes.bool,
  managedCustomerId: PropTypes.string,
  toursEnabled: PropTypes.bool,
  userSource: PropTypes.string,
  branding: PropTypes.string,
  userIsAdmin: PropTypes.bool,
  userIsTourManager: PropTypes.bool,
  customerOnlyLeagues: PropTypes.bool,
  customerNeedsSubscription: PropTypes.bool,
  customerTripOnly: PropTypes.bool,
  canCreateTrips: PropTypes.bool,
  initialPopupAction: PropTypes.string,
  // props for sales demo
  isSalesDemo: PropTypes.bool,
  categories: PropTypes.object,
  leagues: PropTypes.object,
  // visage
  userIsVisageAdmin: PropTypes.bool,
  userIsVisageSuperAdmin: PropTypes.bool,
  manageVisageURL: PropTypes.string,
  // users with no customer id
  lastVisitedLeagueData: PropTypes.object,
  golfHubEnabled: PropTypes.bool,
  isTmLite: PropTypes.bool,
}

export default CustomerCenterRoot
