import { combineReducers } from 'redux'

import misc from './misc'
import scoringInterfaceData from './scoring_interface_data'
import filters from './filters'

export default combineReducers({
  misc,
  scoringInterfaceData,
  filters,
})
