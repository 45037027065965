import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Row, Col } from 'react-flexbox-grid'
import ReactGA from 'react-ga4'
import FaIcon from 'react-fa'
import PopupTypes from '../popup_types'

import {
  GlgTooltipIcon,
  GlgIcon,
  GlgSelect,
  GlgLoadingSpinner,
  GlgButton,
  GlgTooltip,
} from 'SharedComponents'

import NavigatorHandlers from '../navigator_handlers'
import { initializeReactGA4 } from 'shared/helpers'

class NavigatorOptionComponent extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleRoundChange = this.handleRoundChange.bind(this)
    this.handleLearnMoreClick = this.handleLearnMoreClick.bind(this)
    this.openPopup = props.openPopup.bind(this, PopupTypes.HELP_LINKS)
    initializeReactGA4()
  }

  handleClick() {
    if (!this.props.bookmarked) {
      ReactGA.event({
        category: !window.I18n ? '' : window.I18n.t('navigator.components.navigator_option_component.navigator'),
        action: !window.I18n ? '' : window.I18n.t('navigator.components.navigator_option_component.action'),
        label: this.props.name,
        value: this.props.index,
        dimension1: this.props.filterValue,
      })
    }

    if (this.props.internalId) {
      const handlerMethod = _.camelCase(`handle_${ this.props.internalId }`)
      if (_.isFunction(NavigatorHandlers[handlerMethod])) {
        if (this.props.onOptionClick) {
          this.props.onOptionClick()
        }
        NavigatorHandlers[handlerMethod](this.props.selectedRoundId)
      }
    }
  }

  handleRoundChange(roundId) {
    this.props.changeSelectedRound(this.props.id, roundId)
  }

  handleLearnMoreClick() {
    if (this.props.helpLinks.length === 1) {
      const helpLink = this.props.helpLinks[0]
      window.open(helpLink.url, '_blank')
    } else {
      this.openPopup({
        helpLinks: this.props.helpLinks,
        optionName: this.props.name,
      })
    }
  }

  renderLocation() {
    const location = this.props.location
    if (location.includes('%round')) {
      const roundIndex = location.indexOf('%round')
      const locationText = location.substr(0, roundIndex)
      const disabled = this.props.fetchingPermissions ||
                       this.props.disabled ||
                       _.isEmpty(this.props.roundOptions)
      return <Fragment><span className="location-text">{ locationText }</span><span className="round-selector"><GlgSelect items={ this.props.roundOptions } defaultValue={ this.props.defaultRoundId } onChange={ this.handleRoundChange } disabled={ disabled } width={ 170 } height={ 25 } usePortal={ true } closeMenuOnScroll={ true }></GlgSelect></span></Fragment>
    } else {
      return location
    }
  }

  render() {
    return <div className="navigator-option"><Row><div style={ { opacity: Math.max(0.1, this.props.score || 1.0) } } className="navigator-option-color"></div><div className="navigator-option-content"><Row middle="xs" className="add-padding-right-15"><Col xs={11}><div className="add-padding-all-15"><div className="name"><GlgIcon icon={ `star-${ this.props.bookmarked ? '' : 'un' }filled` } iconClass="favorite-icon clickable" onClick={
                               () => this.props.changeBookmarkedStatus(this.props.id, !this.props.bookmarked)
                             }></GlgIcon><span className={ `add-margin-left-10 ${ this.props.disabled ? 'text-italic text-gray' : '' }` }>{ this.props.name }</span>{!_.isEmpty(this.props.helpLinks) && <span onClick={ this.handleLearnMoreClick } className="add-margin-left-40 clickable text-gray"><span className="add-margin-right-3">{ !window.I18n ? '' : window.I18n.t('navigator.components.navigator_option_component.learn_more') }</span><span><FaIcon name="question-circle"></FaIcon></span></span>}</div><div className={ `navigator-location add-padding-top-5 ${ this.props.location.includes('%round') ? 'with-round-select' : ''}` }><span className="navigator-drag-handle" { ...this.props.dragHandleProps }>{this.props.dragHandleProps && <GlgTooltip tooltip={ !window.I18n ? '' : window.I18n.t('navigator.components.navigator_option_component.drag_and_drop_to_reorder') } placement="left"><FaIcon name="bars" className="clickable"></FaIcon></GlgTooltip>}</span>{ this.renderLocation() }</div></div></Col><Col xs={1}>{this.props.fetchingPermissions ? <div><GlgLoadingSpinner width={ 17 } height={ 17 } className="navigator-option-spinner"></GlgLoadingSpinner></div> : this.props.disabledMessage ? <GlgTooltipIcon tooltip={ this.props.disabledMessage } placement="left" iconClass={ '' }></GlgTooltipIcon> : <GlgButton text={!window.I18n ? '' : window.I18n.t('buttons.go').toUpperCase()} onClick={ this.handleClick } href={ this.props.url } size="small" className="add-padding-right-5 add-padding-left-5"></GlgButton>}</Col></Row></div></Row></div>
  }
}

NavigatorOptionComponent.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  url: PropTypes.string,
  internalId: PropTypes.string.isRequired,
  score: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
  disabledMessage: PropTypes.string,
  roundOptions: PropTypes.array.isRequired,
  selectedRoundId: PropTypes.string,
  defaultRoundId: PropTypes.string,
  bookmarked: PropTypes.bool.isRequired,
  filterValue: PropTypes.string.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
  helpLinks: PropTypes.array.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  fetchingPermissions: PropTypes.bool,
  dragHandleProps: PropTypes.object,
  changeSelectedRound: PropTypes.func.isRequired,
  changeBookmarkedStatus: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
}

NavigatorOptionComponent.defaultProps = {
}

export default NavigatorOptionComponent
