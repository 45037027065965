import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SponsorsIndex from '../components/sponsors/SponsorsIndex'
import BannersIndex from './banners/BannersIndex'
import SchedulesIndex from '../components/schedules/SchedulesIndex'
import BannersNew from './banners/BannersNew'
import SponsorsNew from '../components/sponsors/SponsorsNew'
import SchedulesNew from '../components/schedules/SchedulesNew'
import EditDataComponent from './EditDataComponent'
import HubIndex from './HubIndex'
import CheckboxIndex from './checkbox_settings/CheckboxIndex'

const ComponentsContainer = (props) => {

  const [ page, setPage ] = useState('index')
  if (props.tab === 'hub_index') {
    return (
      <HubIndex customerId={props.customerId} page={page} setPage={setPage} />
    )
  } else if (page === 'index') {
    if (props.tab === 'banners') {
      return (
        <BannersIndex customerId={props.customerId} page={page} setPage={setPage} />
      )
    } else if (props.tab === 'sponsors') {
      return (
        <SponsorsIndex customerId={props.customerId} page={page} setPage={setPage} />
      )
    } else if (props.tab === 'schedules') {
      return (
        <SchedulesIndex customerId={props.customerId} page={page} setPage={setPage} />
      )
    } else if (props.tab === 'settings') {
      return (
        <CheckboxIndex customerId={props.customerId} page={page} setPage={setPage} />
      )
    }
  } else if (page === 'new') {
    if (props.tab === 'banners') {
      return (
        <BannersNew customerId={props.customerId} page={page} setPage={setPage} />
      )
    } else if (props.tab === 'sponsors') {
      return (
        <SponsorsNew customerId={props.customerId} page={page} setPage={setPage} />
      )
    } else if (props.tab === 'schedules') {
      return (
        <SchedulesNew customerId={props.customerId} page={page} setPage={setPage} />
      )
    }
  } else if (page.indexOf('edit') >= 0) {
    let component, modelName
    if (props.tab === 'banners') {
      component = BannersNew
      modelName = 'course_albums'
    } else if (props.tab === 'sponsors') {
      component = SponsorsNew
      modelName = 'sponsors'
    } else if (props.tab === 'schedules') {
      component = SchedulesNew
      modelName = 'directory_schedules'
    }
    return (
      <EditDataComponent tab={modelName} customerId={props.customerId} component={component} page={page} setPage={setPage}/>
    )
  }
}

ComponentsContainer.propTypes = {
  tab: PropTypes.string,
  customerId: PropTypes.string,
  hubId: PropTypes.string,
  roleType: PropTypes.string,
}

export default ComponentsContainer
