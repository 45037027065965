import { connect } from 'react-redux'
import _ from 'lodash'

import PopupTypes from '../popup_types'
import {
  closePopup,
  openPopup,
  updateCategory,
  createCategory,
  changeLeagueCategory,
  changeLeagueSeason,
  changeLeagueTour,
  updateOpenRegistrationStatus,
  getLeagueFieldData,
} from '../actions'
import CCPopupsComponent from '../components/popups/cc_popups_component'

import {
  getLeagueRounds,
  getLeagueManagers,
  getCategoriesOptions,
  getSeasonsOptions,
  getToursOptions,
} from '../selectors'

const mapStateToProps = (state) => {
  const popupStates = state.popupStates

  const categoriesOptions = getCategoriesOptions(state)
  const changeCategory = {
    ...popupStates[PopupTypes.CHANGE_CATEGORY],
    categoriesOptions,
  }

  const seasonsOptions = getSeasonsOptions(state)
  const changeSeason = {
    ...popupStates[PopupTypes.CHANGE_SEASON],
    seasonsOptions,
  }

  const userIsTourManager = state.misc.userIsTourManager
  const toursOptions = getToursOptions(state, { addEmptyOption: !userIsTourManager })
  const changeTour = {
    ...popupStates[PopupTypes.CHANGE_TOUR],
    toursOptions,
  }

  const cloneEventDates = _.cloneDeep(popupStates[PopupTypes.CLONE_EVENT_DATES])
  if (cloneEventDates.data && cloneEventDates.data.fromLeague) {
    cloneEventDates.data.rounds = getLeagueRounds(state, cloneEventDates.data.fromLeague)
  }
  cloneEventDates.formURL = state.misc.cloneLeagueURL

  const cloneEventCopyOptions = _.cloneDeep(popupStates[PopupTypes.CLONE_EVENT_COPY_OPTIONS])
  if (cloneEventCopyOptions.data && cloneEventCopyOptions.data.fromLeague) {
    const leagueId = cloneEventCopyOptions.data.fromLeague
    cloneEventCopyOptions.data.roundsCount = getLeagueRounds(state, leagueId).length
    cloneEventCopyOptions.data.managers = getLeagueManagers(state, leagueId)
    const league = state.misc.potentialCloneCandidate
    if (league.openRegistrationData) {
      cloneEventCopyOptions.data.hasOpenRegistration = league.openRegistrationData.hasOpenRegistration
    }
    cloneEventCopyOptions.data.stripeAccount = league.stripeAccount
    cloneEventCopyOptions.data.copyStripeAndFees = league.copyStripeAndFees
    cloneEventCopyOptions.data.isExpressEvent = league.isExpressEvent
  }
  cloneEventCopyOptions.formURL = state.misc.cloneLeagueURL

  const createEvent = {
    ...popupStates[PopupTypes.CREATE_EVENT],
    customerOnlyEvents: state.misc.customerOnlyEvents,
    formURL: state.misc.createLeagueURL,
  }
  const cloneEvent = {
    ...popupStates[PopupTypes.CLONE_EVENT],
    customerOnlyEvents: state.misc.customerOnlyEvents,
  }
  const quickEvent = {
    ...popupStates[PopupTypes.QUICK_EVENT],
    formURL: state.misc.createLeagueURL,
  }
  return {
    categoriesOptions,
    seasonsOptions,
    toursOptions,
    quickEvent,
    createEvent,
    cloneEvent,
    cloneEventCopyOptions,
    cloneEventDates,
    categoryPopup: popupStates[PopupTypes.CATEGORY_POPUP],
    invitationListRegistrationPopup: popupStates[PopupTypes.INVITATION_LIST_REGISTRATION],
    existingTimesPopup: popupStates[PopupTypes.EXISTING_TIMES],
    changeCategory,
    changeSeason,
    changeTour,
    canCreateDCP: state.misc.canCreateDCP,
    userIsAdmin: state.misc.userIsAdmin,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closePopup: id => dispatch(closePopup(id)),
    updateCategory: data => dispatch(updateCategory(data.id, data)),
    createCategory: data => dispatch(createCategory(data)),
    changeLeagueCategory: (leagueId, toCategoryId) => {
      return dispatch(changeLeagueCategory(leagueId,
                                           toCategoryId))
    },
    changeLeagueSeason: (leagueId, seasonId) => dispatch(changeLeagueSeason(leagueId, seasonId)),
    changeLeagueTour: (leagueId, tourId) => dispatch(changeLeagueTour(leagueId, tourId)),
    updateOpenRegistrationStatus: (leagueId, status) => dispatch(updateOpenRegistrationStatus(leagueId, status)),
    advanceToCopyOptions: (data) => dispatch(openPopup(PopupTypes.CLONE_EVENT_COPY_OPTIONS, data)),
    advanceToDates: (data) => dispatch(openPopup(PopupTypes.CLONE_EVENT_DATES, data)),
    getLeagueFieldData: (name) => dispatch(getLeagueFieldData(name)),
    advanceToInvitationList: (data) => dispatch(openPopup(PopupTypes.INVITATION_LIST_REGISTRATION, data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CCPopupsComponent)
