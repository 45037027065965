import React from 'react'
import PropTypes from 'prop-types'
import TeamGolferPick from './team_golfer_pick_component'

const LastPickArea = ({lastPick, undoLastPick, saveInProgress}) => {

  return (
    lastPick &&
    <div className="last_pick_area">
      <div className="picking_area_title">
        <div className="pick_text">{window.I18n.t('draft.components.manager_side.last_pick_area.last_pick')}</div>
        <button type="button" disabled={saveInProgress}
                className={`btsp_white_button button_right ${saveInProgress ? 'disabled' : ''}`} onClick={undoLastPick}>
          {window.I18n.t('buttons.undo')}
        </button>
      </div>
      <TeamGolferPick type={'last'} pick={lastPick}/>
    </div>
  )
}

LastPickArea.propTypes = {
  lastPick: PropTypes.any,
  undoLastPick: PropTypes.func,
  saveInProgress: PropTypes.bool,
}

export default LastPickArea
