import React from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'react-bootstrap'
import CategoryHeader from './category_header'
import LeaguesTableContainer from '../containers/leagues_table_container'

const CategoryComponent = ({
  id, name, opened, color, specialStatus, isFree, leagueIds, updateURL,
  toggleCategory, addLeaguesWithPagination, openPopup, deleteCategory,
  dragHandleProps,
  userIsCustomerManager,
  leaguesCount,
  loadingData,
}) => {
  const showRemoveButton = !!(!specialStatus && updateURL)

  const handleScroll = (e) => {
    const currentScrollAmount = Math.round(e.target.scrollHeight - e.target.scrollTop)
    const currentScrollPosition =
      currentScrollAmount <= e.target.clientHeight
        ? e.target.clientHeight
        : currentScrollAmount
    const marginError = e.target.clientHeight / 100
    const bottom = currentScrollPosition >= (e.target.clientHeight - marginError) && currentScrollPosition <= (e.target.clientHeight + marginError)

    if (bottom) {
      addLeaguesWithPagination()
    }
  }

  return <div className="item-list"><CategoryHeader id={ id } name={ name } loadingData={ loadingData } leaguesCount={ leaguesCount } opened={ opened } color={ color } isFree={ isFree } specialStatus={ specialStatus } showRemoveButton={ showRemoveButton } userIsCustomerManager={ userIsCustomerManager } onClick={ toggleCategory } openPopup={ openPopup } deleteCategory={ deleteCategory } dragHandleProps={ dragHandleProps }></CategoryHeader><Collapse in={ opened }><div onScroll={ handleScroll }><LeaguesTableContainer leagueIds={ leagueIds } categoryId={ id } categoryColor={ color } maxHeight={ 350 }></LeaguesTableContainer></div></Collapse></div>
}

CategoryComponent.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  loadingData: PropTypes.bool,
  opened: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  specialStatus: PropTypes.string,
  isFree: PropTypes.bool,
  updateURL: PropTypes.string,
  leagueIds: PropTypes.array.isRequired,
  userIsCustomerManager: PropTypes.bool.isRequired,
  toggleCategory: PropTypes.func.isRequired,
  addLeaguesWithPagination: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  // From the sorting library; need to apply it to the handle
  dragHandleProps: PropTypes.object.isRequired,
  leaguesCount: PropTypes.number,
}

export default CategoryComponent
