import React, { Component } from 'react'
import PropTypes from 'prop-types'

class GlgRangeSlider extends Component {

  render() {

    const {
      value,
      min,
      max,
      step,
      onChange,
    } = this.props

    const unit = this.props.unit || ''

    return <div className={ `glg-range-slider ${this.props.className}`}><input ref={ this.props.innerRef } type="range" min={ min } max={ max } value={ value } step={ step } onChange={ (e) => { onChange(e.target.value) } } /><span className="add-padding-left-5">{ `${value}${unit}` }</span></div>
  }
}

GlgRangeSlider.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  className: '',
}

GlgRangeSlider.propTypes = {
  innerRef: PropTypes.object,
  value: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  // Unit of measure for range's value. If provided, it will pe displayed on the left side of the slider
  unit: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default GlgRangeSlider
