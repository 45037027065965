import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ReportCenterItemBase from './report_center_item_base'
import ReportCenterCreateUpdateList from './report_center_create_update_list'
import Preloader from 'medium-loader.gif'
import ReportCenterListPagination from './report_center_list_pagination'

const ITEMS_PER_PAGE = 30

class ReportCenterList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: this.props.report_center_lists.length === 1,
      page: 1,
    }
    this.setPage = this.setPage.bind(this)
    
  }

  getExpandedStatus() {
    return this.state.expanded || (this.props.search_term !== '' && this.props.report_center_list.display)
  }

  componentDidUpdate() {
    this.changePageOnEmpty()
  }

  additionalCommunityReports() {
    window.open(`${this.props.discoverReportsPath}?q=${this.props.search_term}`, '_blank')
  }

  stringParameterize(str) {
    return str.trim().toLowerCase()
                    .replace(/[^a-zA-Z0-9 -]/, '')
                    .replace(/\s/g, '_')
  }
  
  filteredItems(items, listId) {
    if (listId < 0) { // -2, -1 cases for Last Printed Reports and Community Reports 
      return items
    }

    return items.filter(rci => {
      return rci.display && rci.tour_id === this.props.current_tour_id
    })
  }

  paginatedItems(forDisplay = true) {
    if (this.props.search_term && forDisplay) {
      return this.filteredItems(this.props.report_center_list.report_center_items, this.props.report_center_list.id)
    } else {
      const start = (this.state.page - 1) * ITEMS_PER_PAGE
      const end = start + ITEMS_PER_PAGE

      return this.filteredItems(this.props.report_center_list.report_center_items, this.props.report_center_list.id).slice(start, end)
    }
  }
  
  setPage(newPage) {
    this.setState({ page: newPage })
  }
  
  changePageOnEmpty() {
    if (this.paginatedItems(false).length === 0 && this.state.page > 1) {
      this.setPage(this.state.page - 1)
    }
  }

  render() {
    const filteredCount = this.filteredItems(this.props.report_center_list.report_center_items, this.props.report_center_list.id).length
    return (
      <Fragment>
        {
          (this.props.report_center_list.id === '-2' && this.props.report_center_list.display) ?
          <Fragment><br /><p>{!window.I18n ? '' : window.I18n.t('download_center.report_center_list.your_search_results')}</p></Fragment> : null
        }
        <div className ={ 'row' + (this.getExpandedStatus() ? ' expanded' : '') + ( this.props.report_center_list.display ? '' : ' hidden') } data-id={this.props.data_id}>
          <div className="col-sm-12">
            <div className="report-list">
              <div className="report-list-header">
                <div className="col-sm-10 btsp_align-middle report-list-body">
                  { this.props.report_center_list.archive_list ? <a href='javascript: void(0)'><i className="fa fa-trash-o fa-report"></i></a> :
                    [ '-2', '-1' ].includes(this.props.report_center_list.id) ? <a href="javascript: void(0)"><i className="fa fa-star" style={ { visibility: 'hidden' }}></i></a> : this.props.report_center_list.favourite ?
                      (<a href='javascript: void(0)' onClick={this.props.toggleFavourite.bind(this, this.props.report_center_list.id, false) } ><i className="fa fa-star fa-report"></i></a>)
                    :
                      (<a href='javascript: void(0)' onClick={this.props.toggleFavourite.bind(this, this.props.report_center_list.id, true) } ><i className="fa fa-star-o fa-report"></i></a>)
                  }
                  <div className="report-list-category" onClick={ () => { this.setState({ expanded: !this.getExpandedStatus(), page: 1 }) }}>
                    <a className="list-header-name">
                      {!window.I18n ? '' : window.I18n.t(`download_center.report_center_list.names.${this.stringParameterize(this.props.report_center_list.name)}`, {defaultValue: this.props.report_center_list.name})}
                    </a>
                  </div>
                </div>
                {
                  ([ 'Basic Reports', 'Basic Spreadsheets', 'Report Composer', 'Page Composer', 'Spreadsheet Composer', 'Scorecards', 'This League', 'This Event', 'This Trip', 'Report Archive' ].indexOf(this.props.report_center_list.name) === -1 && !this.props.report_center_list.read_only && !this.props.isTeamCaptain ) ?
                    <div className="dropdown btsp_align-middle" style={ { zIndex: (1000 - this.props.index) }}>
                      <button className="btn btn-default dropdown-toggle no-lite" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {!window.I18n ? '' : window.I18n.t('download_center.report_center_list.actions')} &nbsp;
                        <span className="caret fw"></span>
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <li>
                          <a href="#"
                            data-toggle="modal"
                            data-target={'#create_edit_list_' + this.props.report_center_list.id }>
                              <i className="fa fa-pencil fa-fw"></i>
                              {!window.I18n ? '' : window.I18n.t('download_center.report_center_list.edit_category')}
                          </a>
                        </li>
                        {
                          this.props.report_center_list.report_center_items.length === 0 &&
                            <li role="separator" className="divider"></li>
                        }
                        {
                          this.props.report_center_list.report_center_items.length === 0 &&
                            <li><a onClick={ this.props.removeList.bind(this, this.props.report_center_list.id) }>
                              <i className="fa fa-trash-o fa-fw"></i>
                              {!window.I18n ? '' : window.I18n.t('download_center.report_center_list.delete_category')}
                            </a></li>
                    }
                      </ul>
                    </div> : this.props.report_center_list.id === '-2' ?
                    <div className="dropdown btsp_align-middle" style={ { zIndex: (1000 - this.props.index) }}>
                      <button className="btn btn-default dropdown-toggle" onClick={ this.additionalCommunityReports.bind(this, this.props.discoverReportsPath, this.props.search_term) }>
                      {!window.I18n ? '' : window.I18n.t('download_center.report_center_list.more_reports')}
                      </button>
                    </div> : null
                }
              </div>
              { (this.state.expanded || this.props.search_term) &&
                <div className={ 'report-list-container' + (this.getExpandedStatus() ? '' : ' hidden') + (' ' + this.props.layout)} >
                  { (this.props.layout === 'inline') ?
                    (this.props.report_center_list.id === '-2' && this.props.search_term !== '' && this.props.delay_timer !== null) ?
                    <img src={ Preloader } alt={!window.I18n ? '' : window.I18n.t('download_center.report_center_list.loading')} className="report-list-preloader" /> :
                    (
                      <div className="col-sm-12 inline">
                        {
                          this.paginatedItems().map((reportCenterItem, _index) => (
                            <ReportCenterItemBase key={this.props.report_center_list.id + '_' + reportCenterItem.id + '_' + _index}
                                                  report_center_lists={this.props.report_center_lists}
                                                  report_center_item={ reportCenterItem }
                                                  current_report_center_list={this.props.report_center_list}
                                                  addRemoveItemToList={this.props.addRemoveItemToList}
                                                  cloneItem={this.props.cloneItem}
                                                  importItem={this.props.importItem}
                                                  openPrintModal={this.props.openPrintModal}
                                                  index={ _index }
                                                  league_id={ this.props.league_id}
                                                  layout={ this.props.layout }
                                                  updateItemStateAttribute={ this.props.updateItemStateAttribute }
                                                  removeAllItemInstances={this.props.removeAllItemInstances}
                                                  mode="download_center"
                                                  isTeamCaptain={this.props.isTeamCaptain}
                                                  dcpSpecs={this.props.dcpSpecs}
                                                  toggleArchiveItem={this.props.toggleArchiveItem}/>
                          ))
                        }
                      </div>
                    ) : (
                          this.props.report_center_list.report_center_items.length > 0 &&
                            <table className="table table_with_dropdown">
                              <thead>
                                <tr>
                                  <th className=""><strong>{!window.I18n ? '' : window.I18n.t('download_center.report_center_list.report')}</strong></th>
                                  <th className="text-center"><strong>{!window.I18n ? '' : window.I18n.t('download_center.report_center_list.statistics')}</strong></th>
                                  <th className="text-right add-padding-right-30" colSpan="4"><strong>{!window.I18n ? '' : window.I18n.t('download_center.report_center_list.actions')}</strong></th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  this.paginatedItems().map((reportCenterItem, _index) => (
                                    <ReportCenterItemBase key={this.props.report_center_list.id + '_' + reportCenterItem.id + '_' + _index}
                                                          report_center_lists={this.props.report_center_lists}
                                                          report_center_item={ reportCenterItem }
                                                          current_report_center_list={this.props.report_center_list}
                                                          addRemoveItemToList={this.props.addRemoveItemToList}
                                                          cloneItem={this.props.cloneItem}
                                                          importItem={this.props.importItem}
                                                          openPrintModal={this.props.openPrintModal}
                                                          index={ _index }
                                                          league_id={ this.props.league_id}
                                                          layout={ this.props.layout }
                                                          updateItemStateAttribute={ this.props.updateItemStateAttribute }
                                                          removeAllItemInstances={this.props.removeAllItemInstances}
                                                          mode="download_center" 
                                                          isTeamCaptain={this.props.isTeamCaptain}
                                                          dcpSpecs={this.props.dcpSpecs}
                                                          toggleArchiveItem={this.props.toggleArchiveItem}/>
                                  ))
                                }
                              </tbody>
                            </table>
                    )
                  }
                </div>
              }
              {
                this.state.expanded &&
                filteredCount > 0 &&
                this.props.search_term === '' && 
                this.props.report_center_list.id !== '-1' &&
                filteredCount > ITEMS_PER_PAGE &&
                  <div className='report-list-pagination'>
                    <div className="col-sm-12 inline">
                      <ReportCenterListPagination
                        page={ this.state.page }
                        perPage={ ITEMS_PER_PAGE }
                        elementsCount={ filteredCount }
                        setCurrentPage={ this.setPage } />
                    </div>
                  </div>
              }
            </div>
          </div>
          <ReportCenterCreateUpdateList createList={this.props.createList}
                                        editList={this.props.editList}
                                        list={this.props.report_center_list} />
        </div>
      </Fragment>
    )
  }
}

ReportCenterList.propTypes = {
  league_id: PropTypes.string.isRequired,
  data_id: PropTypes.string.isRequired,
  report_center_lists: PropTypes.array.isRequired,
  report_center_list: PropTypes.object.isRequired,
  layout: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  search_term: PropTypes.string.isRequired,
  updateItemStateAttribute: PropTypes.func.isRequired,
  addRemoveItemToList: PropTypes.func.isRequired,
  removeAllItemInstances: PropTypes.func.isRequired,
  createList: PropTypes.func.isRequired,
  editList: PropTypes.func.isRequired,
  removeList: PropTypes.func.isRequired,
  openPrintModal: PropTypes.func.isRequired,
  cloneItem: PropTypes.func.isRequired,
  importItem: PropTypes.func.isRequired,
  toggleFavourite: PropTypes.func.isRequired,
  isTeamCaptain: PropTypes.bool.isRequired,
  dcpSpecs: PropTypes.array,
  discoverReportsPath: PropTypes.string,
  delay_timer: PropTypes.any,
  current_tour_id: PropTypes.any,
  toggleArchiveItem: PropTypes.func.isRequired,
}

export default ReportCenterList
