export const sortByOptions = [
  {
    key: 'pairing',
    value: 'pairing',
    name: 'Pairing group number',
  },
  {
    key: 'course_and_tee',
    value: 'course_and_tee',
    name: 'Course, Tee Time',
  },
]

export const scoreClass = (score, displayedScore, holeView) => {
  let scoreCls = displayedScore < 0 ? 'negative ' : ''

  if (holeView === 'colors') {
    if (score === 0) {
      return scoreCls
    } else if (score === -1) {
      scoreCls += 'birdie-hole'
    } else if (score <= -2) {
      scoreCls += 'eagle-hole'
    } else if (score === 1) {
      scoreCls += 'plus1-hole'
    } else if (score >= 2) {
      scoreCls += 'plus2-hole'
    }
  } else {
    if (score === 0) {
      return scoreCls
    } else if (score === -1) {
      scoreCls += 'simple_circle'
    } else if (score <= -2) {
      scoreCls += 'double_circle'
    } else if (score === 1) {
      scoreCls += 'simple_square'
    } else if (score >= 2) {
      scoreCls += 'double_square'
    }
  }

  return scoreCls
}
