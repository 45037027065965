import { connect } from 'react-redux'
import MemberCardsTable from '../components/member_card_table_component'
import {fetchMoreData, checkResqueStatus} from '../actions'


const mapStateToProps = (state) => {
  return { 
    memberCards: state.memberCards,
    members: state.members,
    misc: state.misc,
    isLoading: state.memberCards.isLoading,
    isAdmin: state.misc.isAdmin,
    nrProfiles: state.misc.nrProfiles,
    switchLink: state.misc.switchLink,
    userNamingRule: state.misc.userNamingRule,
    handicapNamingRule: state.misc.handicapNamingRule,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMore: () => dispatch(fetchMoreData()),
    checkResqueStatus: () => dispatch(checkResqueStatus()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberCardsTable)
