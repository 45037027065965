import { ActionTypes } from '../actions'

const { TOGGLE_MODAL_CHANGE_HADNICAP_INDEX } = ActionTypes

const initialState = {
}

const misc = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL_CHANGE_HADNICAP_INDEX:
      return {
        ...state,
        modalChangeHandicapIndex: action.value,
      }
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default misc
