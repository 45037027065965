import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'
import Toggle from 'react-bootstrap-toggle'
class SaveBarComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { submit, refreshData, showMessage, showRounds, showTotal, toggleRounds, toggleTotal, deviceView, lastDeviceUpdate } = this.props
    const hbh = deviceView === 'hole-by-hole'

    return (
      <div className='save_bar'>
        <Col xs={12} sm={12} md={12} lg={12} className='no-padding'>
          <Col xs={4} sm={4} md={hbh ? 2 : 4} lg={hbh ? 2 : 4}>
            <a className='btsp_gray_button' onClick={refreshData}>Refresh Leaderboard</a>
          </Col>
          { deviceView === 'hole-by-hole' && 
            <Col md={2} lg={2} className='toggle-container hidden-xs hidden-sm'>
              <Toggle on='YES' 
                      off='NO'
                      className='rounded'
                      active={showRounds}
                      onClick={ () => toggleRounds() }/>
              <div className='notice-container'>
                <span className='notice-message'>Show Round Scores</span>
              </div> 
            </Col>
          }
          { deviceView === 'hole-by-hole' && 
            <Col md={2} lg={2} className='toggle-container hidden-xs hidden-sm'>
              <Toggle on='YES' 
                      off='NO'
                      className='rounded'
                      active={showTotal}
                      onClick={ () => toggleTotal() }/>
              <div className='notice-container'>
                <span className='notice-message'>Show Total Score</span>
              </div> 
            </Col>
          }
          <Col xs={4} sm={4} md={4} lg={4} className='text-center'>
            <div className='add-margin-top-20'>
              { showMessage && <div className='bold-note notice-message'>There are changes that have not been published.</div>}
              { lastDeviceUpdate && <div className='bold-note large-font-note notice-message'>Last Update on: {lastDeviceUpdate}</div>}
            </div>
          </Col>
          <Col xs={4} sm={4} md={hbh ? 2 : 4} lg={hbh ? 2 : 4}>
            <a className='btsp_orange_button' onClick={submit}>Distribute to Device</a>
          </Col>
        </Col>
      </div>
    )
  }
}

SaveBarComponent.propTypes = {
  submit: PropTypes.func,
  refreshData: PropTypes.func,
  showMessage: PropTypes.bool,
  showRounds: PropTypes.bool,
  showTotal: PropTypes.bool,
  toggleRounds: PropTypes.func,
  toggleTotal: PropTypes.func,
  deviceView: PropTypes.string,
  lastDeviceUpdate: PropTypes.string,
}

export default SaveBarComponent
