import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'
import { Row } from 'react-bootstrap'
import _ from 'lodash'

class UpdateBlockForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: this.props.title,
      titles: this.props.titles,
    }
    this.setTitle = this.setTitle.bind(this)
  }

  setTitle(_title) {
    this.setState({
      title: _title,
    })
  }
  
  setTitles(_title, key) {
    this.setState({
      titles: {
        ...this.state.titles,
        [key]: _title,
      },
    })
  }

  render() {
    return (
      <Col xs={12} className="manage-columns">
        <Col xs={10} xsOffset={1} className="title">
          <h1>{_.startCase(_.toLower(window.I18n.t('multi_widgets.block.edit')))}</h1>
        </Col>
        <Col xs={10} xsOffset={1} className="inner-content">
          <Col xs={10} xsOffset={1} className="form-row">
          {this.props.usesTranslations ? (
            <Row>
              {this.props.availableLanguages.map(key => (
                <Row key={key}>
                  <Col xs={4} className="labels margin-bottom-20">{window.I18n.t('pages.edit.portal_page_options.title_' + key)}</Col>
                  <Col xs={6} className="form-group no-margin-bottom">
                    <input type="text"
                    placeholder={window.I18n.t('multi_widgets.block.title_placeholder')}
                    className="form-control"
                    value={ this.state.titles ? this.state.titles[key] : this.state.title }
                    onChange={ e => this.setTitles(e.target.value, key) }
                    autoFocus />
                  </Col>
                </Row>
              ))}
            </Row>
          ) : (
            <Col xs={12} className="form-group">
              <input type="text"
                    placeholder={window.I18n.t('multi_widgets.block.title_placeholder')}
                    className="form-control"
                    value={ this.state.title }
                    onChange={ e => this.setTitle(e.target.value) }
                    autoFocus />
            </Col>
          )}
          </Col>
        </Col>

        <Col xs={10} xsOffset={1} className="button-row">
          <button className="button submit save-page save-with-external-link btn btn-primary pull-right"
                  onClick={ e => { e.preventDefault(); this.props.onUpdate(this.props.id, this.state.title, this.state.titles) } } >
                {window.I18n.t('multi_widgets.save')}
          </button>
          <a className="button cancel grey btn btn-default pull-right margin-right-10"
             onClick={ e => { e.preventDefault(); this.props.onCancel() } } >
            {window.I18n.t('multi_widgets.cancel')}
          </a>
        </Col>
      </Col>
    )
  }
}

UpdateBlockForm.propTypes = {
  title: PropTypes.string,
  titles: PropTypes.any,
  id: PropTypes.any,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  usesTranslations: PropTypes.bool,
  availableLanguages: PropTypes.any,
}

export default UpdateBlockForm
