import { connect } from 'react-redux'

import DrivingDistancesFilterComponent from '../components/driving_distances_filter_component'
import { changeSortOption, changeSortOrderOption } from '../actions'

const mapStateToProps = (state) => {
  return {
    sortOrder: state.filters.sortOrder,
    sortOption: state.filters.sortByOption,
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeSortOption: (value) => dispatch(changeSortOption(value)),
  changeSortOrderOption: () => dispatch(changeSortOrderOption()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrivingDistancesFilterComponent)
