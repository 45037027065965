import React from 'react'
import PropTypes from 'prop-types'

const TeamsSection = ({changeSection, teams, addNewTeam, deleteTeam, changeTeamName}) => {

  return (<div className="section" id="teams-section">
    <hr/>
    <div className="section_header" onClick={(e) => changeSection(e)}>
      <div className="row no-margin-right">
        <div className="col-xs-11 col-sm-7 col-lg-6 title">
          <div className="label label-primary">2.</div>
          <span className="header_text">{window.I18n.t('draft.components.teams_component.title')}</span>
        </div>
        <div className="hidden-xs col-sm-4 col-lg-5"/>
        <div className="col-xs-1 caret-container add-padding-top-15">
          <i className="gga-carret-down"/>
          <i className="gga-carret-up"/>
        </div>
      </div>
    </div>
    <hr/>

    <div className="section_content btsp_square_solid hidden">
      <div className="row">
        {teams.map((team, tIdx) =>
          <div key={tIdx} className="col-xs-12 col-sm-12 col-md-12 col-lg-12 team_comp">
            <div className="col-xs-10 col-sm-8 col-md-6 col-lg-4 no_padding">
              <input className="form-control form-text" type="text" value={team.name}
                     placeholder={window.I18n.t('draft.components.teams_component.enter_team_name')}
                     id={team.id} data-idx={tIdx} autoFocus
                     onChange={(e) => changeTeamName(e.target.dataset.idx, e.target.value)}/>
            </div>
            {teams.length > 1 && <i className="gga-table-delete-member" data-idx={tIdx}
                                    onClick={(e) => deleteTeam(e.target.dataset.idx)}/>}
            {tIdx === teams.length - 1 && <i className="gga-circle-plus" onClick={addNewTeam}/>}
          </div>)}
      </div>
    </div>
  </div>)
}

TeamsSection.propTypes = {
  changeSection: PropTypes.func,
  teams: PropTypes.array,
  addNewTeam: PropTypes.func,
  deleteTeam: PropTypes.func,
  changeTeamName: PropTypes.func,
}

export default TeamsSection
