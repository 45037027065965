import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { GlgSelect, GlgIcon, GlgPopup } from 'SharedComponents'
import RadioGroup from './event_setup/radio_group'
import RadioOption from './event_setup/radio_option'
import Checkbox from './event_setup/checkbox'
import LabeledInput from './event_setup/labeled_input'
import Loader from 'react-loader-spinner'
import { getColumnName } from 'members_page/helpers'
import { switchExpression } from 'Shared/helpers'
import Preloader from './preloader'
import { Pagination } from 'react-bootstrap'
import _ from 'lodash'
import AddGolfersManuallyComponent from 'members_page/components/add_golfers_manually/add_golfers_manually_component'

const internationalize = (...args) => !window.I18n ? '' : window.I18n.t(...args)

// see app/views/members/bootstrap_index/_circle_state.html.haml
const circleState = (member, allValidMemberAnswers = null, allPartOfAnswers = null) => {
  let indicator = ''

  if (member.deleted) {
    indicator = 'member_deleted'
  } else if (member.tbd_member) {
    indicator = 'member_tbd'
  } else if (member.waitlist && member.waitlist_type === 'regular') {
    indicator = 'member_waitlisted'
  } else if (member.waitlist && member.waitlist_type === 'custom_field') {
    indicator = 'member_pending_registration'
  } else if ((member.guest_indicator || '').length > 0) {
    indicator = 'member_guest'
  } else if (member.self_registered_to_master_roster) {
    indicator = 'self_registered_members'
  } else if (allValidMemberAnswers && (member.id in allValidMemberAnswers) && allValidMemberAnswers[member.id] === 'No') {
    indicator = 'self_not_valid_member'
  } else if (allPartOfAnswers && ( member.id in allPartOfAnswers) && allPartOfAnswers[member.id] === 'No') {
    indicator = 'self_not_part_of_association'
  }

  return (
    indicator.length > 0 ?
      <span className='circle_container'>
        <div className={ `circle ${indicator}` } />
      </span>
      :
      ''
  )
}

const LeagueRosterTable = (props) => {
  const { product, membersData, deletedMembers, memberSearchOptions, loadMembersData, updateSearchTerm, prepareMembersReload, changePage } = props
  const [ searchTimeout, setSearchTimeout ] = useState(null)

  useEffect(() => {
    if (membersData.loading_state === 'should_load') {
      loadMembersData()
    }
  }, [ membersData.loading_state ])

  return (
    <div className='league_roster_table'>
      <div className='page_title no-margin no-padding-bottom'>
        <div className='text after_text_1'>
          { internationalize('imports.import_options.league_roster', { product: internationalize(`${product}_name`) }) }
        </div>
      </div>

      <div className='top_row'>
        <div className='number_of_golfers'>
          <div className='btsp_square_dotted'>
            <div className='pull-left icon_of_g'>
              <GlgIcon icon='event-roster-stats' />
            </div>

            <div className='pull-left number_of_g add-padding-left-10 g_number_container hidden-xs'>
              {
                membersData.loading_state === 'loaded' ?
                  <> { `${membersData.members_count} ${internationalize('members.bootstrap_members_page.golfer', { count: membersData.members_count })}` } </>
                :
                <span className='glg-loading-spinner'>
                  <Loader type="ThreeDots" color={ '#0088CE' } height={ 15 } width={ 30 } />
                </span>
              }
            </div>

            <div className='pull-left number_of_g_xs add-padding-left-10 g_number_container_xs hidden-sm hidden-md hidden-lg'>
              { membersData.members_count }
            </div>
          </div>
        </div>

        <div className="filter btsp_filter">
          <div className="input-group">
            <input
              type="text"
              name="filter_letters"
              value={memberSearchOptions.filter_letters}
              onChange={(e) => {
                const newValue = e.target.value
                updateSearchTerm(newValue)

                if (searchTimeout) { clearTimeout(searchTimeout) }
                setSearchTimeout(setTimeout(() => {
                  prepareMembersReload({ page: 1, filter_letters: newValue })
                }, 500))
              }}
              placeholder={internationalize('controllers.widgets.search')}
              className="form-control"
              autoComplete="off"
              data-myposition="bottom center"
              data-atposition="top center"
              title={ internationalize('shared.filter.search_by') }
            />
            <span className="input-group-addon">
              <a className="clear_letter_box" href="#" onClick={() => { prepareMembersReload({ page: 1, filter_letters: '' }) }} >
                <i className="fa fa-eraser" />
              </a>
            </span>
          </div>
        </div>
      </div>

      { /* The Table */ }

      {
        membersData.loading_state === 'loading' &&
          <Preloader />
      }

      {
        membersData.loading_state === 'loaded' &&
          <>
            <table className="members_table table table-striped">
              <thead>
                <tr className="header">
                  {membersData.columns.map((column, index) => (
                    <th className={ column } key={`${column}-${index}`}>{ getColumnName(column, 'none', props.prettyHandicapMethod).name }</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {membersData.members.length === 0 ? (
                  <tr>
                    <td className="center" colSpan={membersData.columns.length}>
                      { internationalize('dashboard_plus.event_setup_import_golfers.no_members_available') }
                    </td>
                  </tr>
                ) : (
                  membersData.members.map((member) => (
                    <tr key={`member_${member.id}`}>
                      {membersData.columns.map((column) => (
                        <td className={`member_${column}`} key={`member_${member.id}_${column}`}>
                          {
                            switchExpression({
                              'full_name': (
                                <div>
                                  { circleState(member) }
                                  <span>
                                    { `${member.full_name}${member.guest_indicator || ''}` }
                                  </span>
                                </div>
                              ),
                              'glg_actions': (
                                deletedMembers[member.id] ?
                                  <a href='javascript:void(0)' className='disabled'>{ internationalize('members.index.please_wait') }</a>
                                  :
                                  <a href='javascript:void(0)' onClick={() => props.deleteMember(member)}>{ member.deleted ? internationalize('members.index.un_delete') : internationalize('members.index.delete') }</a>
                              ),
                              '*': false,
                            })(column) || member[column]
                          }
                        </td>
                      ))}
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            {
              (() => {
                const pagesCount = Math.ceil(membersData.members_count / 50)
                if (pagesCount <= 1) { return }

                return (<div>
                  <Pagination>
                    <Pagination.Prev disabled={ memberSearchOptions.page === 1 } onClick={ () => changePage(memberSearchOptions.page - 1) } />
                    {
                      Array.from({length: pagesCount}, (_, pageNumber) =>
                        <Pagination.Item
                          key={ pageNumber + 1 }
                          active={ pageNumber + 1 === memberSearchOptions.page }
                          onClick={ () => changePage(pageNumber + 1) } >
                          { pageNumber + 1 }
                        </Pagination.Item>
                      )
                    }
                    <Pagination.Next disabled={ memberSearchOptions.page === pagesCount } onClick={ () => changePage(memberSearchOptions.page + 1) } />
                  </Pagination>
                </div>)
              })()
            }
          </>
      }
    </div>
  )
}

LeagueRosterTable.propTypes = {
  product: PropTypes.string.isRequired,
  prettyHandicapMethod: PropTypes.string.isRequired,
  membersData: PropTypes.object.isRequired,
  deletedMembers: PropTypes.object.isRequired,
  memberSearchOptions: PropTypes.object.isRequired,
  loadMembersData: PropTypes.func.isRequired,
  prepareMembersReload: PropTypes.func.isRequired,
  deleteMember: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  updateSearchTerm: PropTypes.object.isRequired,
}

const EventSetupImportGolfers = ({
  content,
  setData,
  loadMembersData,
  prepareMembersReload,
  quickImportMember,
  deleteMember,
  newManualMember,
  closeModal,
  initializeAddMembersManually,
  saveAddNewMembers,
  solveConflicts,
}) => {
  const { title, links, product } = content
  const importFrom = content.import_from
  const searchOptions = content[`${importFrom.selected}_options`]
  const includeCustomFields = content.include_all_custom_fields
  const pastLeagues = (importFrom.selected === 'other_leagues') ? content.past_leagues : {}
  const addMembersManually = content.add_members_manually
  const modalOpened = content.modal_opened
  const modalLoading = content.modal_loading

  const quickSearchRef = useRef(null)
  useEffect(() => {
    $(quickSearchRef.current).select2({
      language: {
        errorLoading: () => internationalize('assets.application.quick_search_members.select.error_loading'),
        inputTooShort: (e) => {
          return internationalize('assets.application.quick_search_members.select.input_too_short', { nr: e.minimum - e.input.length })
        },
        noResults: () => internationalize('assets.application.quick_search_members.select.no_results'),
        searching: () => internationalize('assets.application.quick_search_members.select.searching'),
      },
      width: '250px',
      placeholder: internationalize('assets.application.quick_search_members.search_for_a_player'),
      allowClear: true,
      minimumInputLength: 3,
      ajax: {
        url: links.quick_search.path,
        dataType: 'json',
        delay: 150,
        data: (params) => {
          const requestParams = {
            q: params.term,
            page: params.page,
            ...(importFrom.selected === 'other_leagues' && {
              from_league_id: pastLeagues.value,
            }),
          }

          return requestParams
        },
        processResults: (data, params) => {
          params.page = params.page || 1

          return {
            results: (data.items || []).map((item) => {
              if (! item) { return }

              item.id = item.id || item['id']
              item.name = item.name || item['item']
              item.custom_fields = item.custom_fields || []
              return item
            }),
            pagination: { more: params.page * 30 < data.total_count },
          }
        },
        cache: true,
      },
      escapeMarkup: (markup) => markup,
      templateResult: (repo) => {
        if (repo.loading) { return repo.text }
        if (repo.name !== undefined) {
          let customFieldsHtml = ''
          if (repo.custom_fields.length > 0) {
            const customFields = repo.custom_fields.filter((cf) => cf && cf.length > 0)
            customFieldsHtml = `<div class='custom_fields'>${ customFields.join(', ') }</div>`
          }
          return `<div class='select2-results__options' disabled=''>${ repo.name }${ customFieldsHtml }</div>`
        }
      },
      templateSelection: (repo) => repo.name || repo.text,
    })

    $(quickSearchRef.current).off('select2:select')
    $(quickSearchRef.current).on('select2:select', (e) => {
      quickImportMember(e.params.data.id)
    })
  }, [ importFrom, pastLeagues, searchOptions ])

  useEffect(() => {
    // initialize modal state
    if (importFrom.selected === 'manually') {
      initializeAddMembersManually()
    }
  }, [ importFrom.selected ])

  return (
    <div className='widget-content-default widget-event-setup-import-golfers'>
      <div className='title'>
        { title }
      </div>

      <div className='content flex-list-layout'>
        <ul>
          <li>
            <RadioGroup
              id='import_golfers_from'
              className='import_golfers_from'
              {...importFrom}
              setOption={ v => {
                setData([ 'import_from', 'old_selected' ], importFrom.selected)
                setData([ 'import_from', 'selected' ], v)

                $(quickSearchRef.current).select2('destroy')
              }}
            />

            {
              importFrom.selected === 'other_leagues' &&
                <div>
                  <LabeledInput spacing='50px' label={ pastLeagues.label } className='select_other_leagues' childrenClass='narrow' >
                    <GlgSelect
                      items={ pastLeagues.options }
                      value={ pastLeagues.value }
                      onChange={ v => setData([ 'past_leagues', 'value' ], v) }
                      searchable={ true }
                    />
                  </LabeledInput>
                </div>
            }
          </li>

          {
            searchOptions &&
              <li className='import_options'>
                <div className='radio-option import_options'>
                  <b>{searchOptions.label}</b>

                  <div className='options-container vertical'>
                    {searchOptions.choices.map((choice, index) => {
                      const Option =
                        <RadioOption
                          id='quick_search_or_select'
                          key={`quick_search_or_select_${index}`}
                          {...choice}
                          selected={choice.value === searchOptions.selected}
                          setOption={ v => {
                            setData([ `${importFrom.selected}_options`, 'selected' ], v)

                            if (v !== 'quick_search') {
                              $(quickSearchRef.current).select2('destroy')
                            }
                          }}
                        />

                      return switchExpression({
                        'quick_search': (
                          <div className='search_in_roster_container'>
                            { Option }

                            {
                              searchOptions.selected === 'quick_search' && <select id='quick_search_select' ref={quickSearchRef} />
                            }
                          </div>
                        ),
                        '*': (
                          <>{ Option }</>
                        ),
                      })(choice.value)
                    })}
                  </div>
                </div>
              </li>
          }

          <li>
            <Checkbox
              {...includeCustomFields}
              setOption={ v => setData([ 'include_all_custom_fields', 'selected' ], v) }
            />
          </li>
        </ul>

        <LeagueRosterTable
          product = { product }
          prettyHandicapMethod = { content.pretty_handicap_method }
          membersData = { content.members_data }
          deletedMembers = { content.deletedMembers || {} }
          memberSearchOptions = { content.member_search }
          loadMembersData = { loadMembersData }
          prepareMembersReload = { prepareMembersReload }
          updateSearchTerm = { (newValue) => { setData([ 'member_search', 'filter_letters' ], newValue) }}
          deleteMember = { deleteMember }
          changePage = { (pageNumber) => {
            if (pageNumber === content.member_search.page) { return }

            const membersData = content.members_data
            const pagesCount = Math.ceil(membersData.members_count / 50)

            if (pageNumber >= 1 && pageNumber <= pagesCount) {
              prepareMembersReload({ page: pageNumber })
            }
          }}
        />

        {
          !modalOpened && importFrom.selected === 'manually' &&
            <AddGolfersManuallyComponent
              show={ importFrom.selected === 'manually' }
              { ...addMembersManually }

              closeAddGolfersManuallyPopup={closeModal}
              toggleTermsAndPrivacy={ (checked, toggleType) => setData([ 'add_members_manually', 'termsAndPrivacyChecked', toggleType ], checked) }
              addMoreGolfers={ () => {
                const newMembersClone = _.clone(addMembersManually.newMembers)
                for (let i = 0; i < 5; ++i) {
                  newMembersClone.push(newManualMember())
                }
                setData([ 'add_members_manually', 'newMembers' ], newMembersClone)
              }}
              saveNewMemberField={ (field, value, index) => { setData([ 'add_members_manually', 'newMembers', index, field ], value) }}
              addNewMembers={ saveAddNewMembers }
              fetchLeagueMetadata={ () => {} }
              openEditCustomFieldsPopup={ () => {} }
            />
        }

        {
          !modalOpened && searchOptions?.selected === 'select' &&
            <GlgPopup
              show={true}
              title={ () => <div className='title'>Select from Roster</div> }
              onClose={closeModal}
              modalClass='dashboard_widget_modal select_from_roster'
              showCloseButton={ false }
              showSaveButton={ false }
            >
            </GlgPopup>
        }

        {
          modalOpened === 'members_add_conflict' &&
            <GlgPopup
              show={true}
              title={ () => <div className='title'>Members conflict resolution</div> }
              onClose={closeModal}
              modalClass='dashboard_widget_modal members_add_conflict'
              showCloseButton={ false }
              showSaveButton={ false }
            >
              <>
                <iframe
                  id='members_add_conflict_frame'
                  width='0'
                  height='0'
                  frameBorder='0'
                  src={addMembersManually.conflictUrl}
                  onLoad={() => {
                    setData([ 'modal_loading' ], false)
                    document.getElementById('members_add_conflict_frame').width = '100%'
                    document.getElementById('members_add_conflict_frame').height = '100%'

                    const content = $('#members_add_conflict_frame').contents()
                    content.find('.cancel').click(() => {
                      closeModal()
                      prepareMembersReload({
                        page: 1,
                        filter_letters: '',
                      })
                    })

                    const submitButton = content.find(':submit')
                    submitButton.click(e => {
                      e.preventDefault()
                      submitButton.attr('disabled', true)
                      submitButton.val(internationalize('buttons.saving'))

                      const form = content.find('form')
                      solveConflicts(form.attr('action'), form.attr('method'), form.serialize())
                    })
                  }}
                />
                { modalLoading && <Preloader /> }
              </>
            </GlgPopup>
        }
      </div>
    </div>
  )
}

EventSetupImportGolfers.propTypes = {
  content: PropTypes.object,
  setData: PropTypes.func,
  deleteMember: PropTypes.func,
  quickImportMember: PropTypes.func,
  loadMembersData: PropTypes.func,
  prepareMembersReload: PropTypes.func,
  newManualMember: PropTypes.func,
  closeModal: PropTypes.func,
  initializeAddMembersManually: PropTypes.func,
  saveAddNewMembers: PropTypes.func,
  solveConflicts: PropTypes.func,
}

EventSetupImportGolfers.defaultProps = {
  content: {
    registration: {
      title: 'IMPORT GOLFERS',
    },
  },
}

export default EventSetupImportGolfers
