import { connect } from 'react-redux'
import TvShowsIndexComponent from '../components/tv_shows_index_component'

import {
  removeTvShow,
  toggleAutoInclude,
} from '../actions/tvShowsIndex'

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeTvShow: (tvShowId) => { dispatch( removeTvShow(tvShowId) ) },
    toggleAutoInclude: (tvShowId) => { dispatch( toggleAutoInclude(tvShowId) ) },
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TvShowsIndexComponent)
