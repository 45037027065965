import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { scoreClass } from '../helpers'

class FoursomeData extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {foursome, aggregates, scoreDisplay, isStableford, isMultiRound, holeView, isPortal, isLast} = this.props

    return (
      <Fragment>
        {
          aggregates.map( (agg, index) => {
            const displayValues = scoreDisplay ? [ ...agg.scores ] : [ ...agg.parCumulative ]
            const firstRowClass = index === 0 ? ' first_row' : ''
            const lastFirstRowClass = index === 0 && isLast ? ' last' : ''
            return (
              <tr key={'foursome-' + foursome.id + '-agg-' + index} className={'player_row' + firstRowClass + lastFirstRowClass}>
                {index === 0 && 
                  <td rowSpan={foursome.size} className='first'>
                    <p>{foursome.position}.</p>
                    <p><b>{!window.I18n ? '' : window.I18n.t('group_view.foursome_data.course')}: </b>{foursome.courseName}</p>
                    <p><b>{!window.I18n ? '' : window.I18n.t('group_view.foursome_data.starting_hole')}: </b>{foursome.startingHole}</p>
                    <p>{foursome.prettyTeeTime}</p>
                  </td>
                }
                <td className={`second ${this.props.newScores.hasOwnProperty(agg.player_id) ? 'changed' : ''}`}>
                  <div className='handle_text'>
                    <span className='full_name'>{
                      agg.link_to_shot_stats ? <a href={agg.link_to_shot_stats}>{agg.name}</a> : agg.name
                    }</span>
                  </div>
                  {!isPortal && <div className='disp_label'>{agg.disposition}</div>}
                </td>
                {isMultiRound && <td>{agg.prior}</td>}

                {
                  [ ...Array(9) ].map( ( _, i) => {
                    const tmpScore = this.props.newScores.hasOwnProperty(agg.player_id) ? this.props.newScores[agg.player_id]['player'][agg.player_id]['score'][i] : displayValues[i]

                    return (<td className={`${isStableford ? '' : tmpScore !== null && scoreClass(agg.points[i], tmpScore, holeView)} ${tmpScore !== displayValues[i] ? 'changed' : ''} ${agg.display ? '' : 'disabled'}`} key={'hole-' + i}>
                    {
                       agg.display
                       ? <input value={tmpScore}
                                onChange={ (e) => this.props.changeScore(agg.tee_id, agg.player_id, foursome.id, agg.scores, e.target.value, i) }
                                onFocus={ (event) => event.target.select() } />
                       : null
                    }
                    </td>)
                  })
                }

                {scoreDisplay && <td className={agg.display ? '' : 'disabled'}>{agg.display ? agg.scoresSumFront : null}</td>}

                {
                  [ ...Array(9) ].map( ( _, i) => {
                    const tmpScore = this.props.newScores.hasOwnProperty(agg.player_id) ? this.props.newScores[agg.player_id]['player'][agg.player_id]['score'][i + 9] : displayValues[i + 9]

                    return (<td className={`${isStableford ? '' : tmpScore !== null && scoreClass(agg.points[i + 9], tmpScore, holeView)} ${tmpScore !== displayValues[i + 9] ? 'changed' : ''} ${agg.display ? '' : 'disabled'}`} key={'hole-' + i + 9}>
                    {
                       agg.display
                       ? <input value={tmpScore}
                              onChange={ (e) => this.props.changeScore(agg.tee_id, agg.player_id, foursome.id, agg.scores, e.target.value, i + 9) }
                              onFocus={ (event) => event.target.select() } />
                       : null
                    }
                    </td>)
                  })
                }

                {scoreDisplay && <td className={agg.display ? '' : 'disabled'}>{agg.display ? agg.scoresSumBack : null}</td>}
                {!scoreDisplay && isMultiRound ? null : <td className={agg.display ? '' : 'disabled'}>{agg.display ? agg.scoresSum : null}</td>}
                {scoreDisplay && !isStableford && isMultiRound && <td className={agg.display ? '' : 'disabled'}>{agg.display ? agg.todayPar : null}</td>}
                {scoreDisplay && !isStableford && isMultiRound && <td className={agg.display ? '' : 'disabled'}>{agg.display ? agg.totalPar : null}</td>}
                {isStableford && isMultiRound && <td className={agg.display ? '' : 'disabled'}>{agg.display ? agg.overall : null}</td>}
                {!scoreDisplay && isMultiRound && 
                  agg.pastResults.map( result => <td className={agg.display ? '' : 'disabled'} key={result.round}>{agg.display ? result.grossScore : null}</td>)
                }
                {!scoreDisplay && isMultiRound && <td className={agg.display ? '' : 'disabled'}>{agg.display ? agg.totalScore : null}</td>}
              </tr>
            )
          })
        }
      </Fragment>
    )
  }
}

FoursomeData.propTypes = {
  foursome: PropTypes.object,
  aggregates: PropTypes.array,
  scoreDisplay: PropTypes.bool,
  isStableford: PropTypes.bool,
  isMultiRound: PropTypes.bool,
  holeView: PropTypes.string,
  isPortal: PropTypes.bool,
  isLast: PropTypes.bool,
  changeScore: PropTypes.func,
  newScores: PropTypes.object,
}

export default FoursomeData
