import { connect } from 'react-redux'

import ShotByShotTableComponent from '../components/shot_by_shot_table_component'
import { getSortedPairings } from '../selectors'

const mapStateToProps = (state) => {
  return {
    tees: state.misc.tees,
    foursomes: getSortedPairings(state),
    isMultiRound: state.misc.isMultiRound,
  }
}

export default connect(
  mapStateToProps
)(ShotByShotTableComponent)
