import { connect } from 'react-redux'

import DrivingDistancesSearchComponent from '../components/driving_distances_search_component'
import { changeSearchValue } from '../actions'

const mapStateToProps = (state) => {
  return {
    filterName: state.station.filterName,
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeSearchValue: (value) => dispatch(changeSearchValue(value)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrivingDistancesSearchComponent)
