import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'
import { Row } from 'react-bootstrap'

class EditTabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      draggedItem: null,
      tabs: this.props.tabs,
      availableLanguages: this.props.availableLanguages,
    }
    this.onDragStart = this.onDragStart.bind(this)
    this.onDragOver = this.onDragOver.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.removeTab = this.removeTab.bind(this)
    this.swapTabs = this.swapTabs.bind(this)
    this.updateTabName = this.updateTabName.bind(this)
    this.editTab = this.editTab.bind(this)
    this.hideInput = this.hideInput.bind(this)
  }

  removeTab(id) {
    const result = window.confirm(window.I18n.t('multi_widgets.tabs.confirm_delete')) // eslint-disable-line no-alert
    if (result){
      const remainingTabs = this.state.tabs.filter(t => t.id !== id)
      this.setState({ tabs: remainingTabs })
    }
  }

  swapTabs(tab1Id, tab2Id) {
    const tab1 = this.state.tabs.filter(tab => tab.id === tab1Id)[0]
    const tab2 = this.state.tabs.filter(tab => tab.id === tab2Id)[0]

    this.setState({
      tabs: this.state.tabs.map(tab =>
        (tab.id === tab1Id)
          ? {...tab, index: tab2.index}
          : tab)
        .map(tab =>
          (tab.id === tab2Id)
            ? {...tab, index: tab1.index}
            : tab) })
  }

  updateTabName(_tabId, e) {
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    this.setState({ tabs: this.state.tabs.map(tab =>
      (tab.id === _tabId)
        ? {...tab, title: e.target.value}
        : tab) })
    return false
  }
  
  updateTabNames(_tabId, key, e) {
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    this.setState({ tabs: this.state.tabs.map(tab =>
      (tab.id === _tabId)
        ? {...tab, titles: {...tab.titles, [key]: e.target.value}}
        : tab) })
    return false
  }

  hideInput(_tabId, e) {
    if (e.key === 'Enter'){
      e.preventDefault()
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
      this.editTab(_tabId)
      return false
    }
  }

  editTab(_tabId){
    this.setState({ tabs:
      this.state.tabs.map(tab =>
        (tab.id === _tabId)
          ? {...tab, editing: !tab.editing}
          : tab),
    })
  }

  onDragStart(e, tabId) {
    this.setState({
      draggedItem: tabId,
    })
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/html', e.target.parentNode)
    e.dataTransfer.setDragImage(e.target.parentNode, e.target.parentNode.width, e.target.parentNode.height)
  }

  onDragOver(tabId) {
    // if the item is dragged over itself, ignore
    if (this.state.draggedItem === tabId) {
      return
    }
    this.swapTabs(tabId, this.state.draggedItem)
    return
  }

  onDragEnd() {
    this.setState({
      draggedItem: null,
    })
  }

  render() {
    return (
      <Col className="manage-tabs" xs={12}>
        <Col xs={10} xsOffset={1} className="title">
          <h1>{window.I18n.t('multi_widgets.tabs.manage_title')}</h1>
        </Col>
        <Col xs={10} xsOffset={1} className="inner-content">
          { this.state.tabs.sort( (a, b) => a.index <= b.index ? -1 : 1 ).map(tab =>
              <Col xs={12} key={tab.id} className="tab" onDragOver={() => this.onDragOver(tab.id)}>
                <Col xs={2} md={1}
                     className="movable-icon"
                     draggable
                     onDragStart={ (e) => this.onDragStart(e, tab.id) }
                     onDragEnd={this.onDragEnd} >
                  <i className="fa fa-bars"></i>
                </Col>
                <Col xs={6} md={9}>
                  { tab.editing === false ?
                    <span>
                      { tab.titles ? (tab.titles[this.props.currentLanguage] ? tab.titles[this.props.currentLanguage] : tab.title) : tab.title }
                    </span> :
                    this.props.usesTranslations ? (
                      <Row>
                        {this.state.availableLanguages.map(key => (
                          <Row key={key}>
                            <Col xs={4} className="labels margin-bottom-20">{window.I18n.t('pages.edit.portal_page_options.title_' + key)}</Col>
                            <Col xs={6} className="form-group no-margin-bottom">
                              <input type="text"
                              placeholder={`${window.I18n.t('multi_widgets.tabs.title_label')}...`}
                              value={ tab.titles ? tab.titles[key] : tab.title }
                              onChange={ (e) => this.updateTabNames(tab.id, key, e) }
                              onKeyPress={ (e) => this.hideInput(tab.id, e) }
                              //onBlur={ () => this.editTab(tab.id) }
                              autoFocus /> 
                            </Col>
                          </Row>
                        ))}
                        <button className="button submit save-page save-with-external-link btn btn-primary pull-right"
                                onClick={ () => this.editTab(tab.id) } >
                              {window.I18n.t('multi_widgets.save')}
                        </button>
                      </Row>
                    ) : (
                      <input type="text"
                            placeholder={`${window.I18n.t('multi_widgets.tabs.title_label')}...`}
                            value={ tab.title }
                            onChange={ (e) => this.updateTabName(tab.id, e) }
                            onKeyPress={ (e) => this.hideInput(tab.id, e) }
                            onBlur={ () => this.editTab(tab.id) }
                            autoFocus />
                    )
                  }
                </Col>
                <Col xs={2} md={1} onClick={ () => this.editTab(tab.id) }>
                    <i className="fa fa-pencil fa-fw"></i>
                </Col>
                {
                  this.state.tabs.length > 1 && <Col xs={2} md={1} onClick={ () => this.removeTab(tab.id) }>
                    <i className="fa fa-trash"></i>
                  </Col>
                }
            </Col> )}
        </Col>

        <Col xs={10} xsOffset={1} className="button-row">
          <button className="button submit save-page save-with-external-link btn btn-primary pull-right"
                  onClick={ e => { e.preventDefault(); this.props.saveTabs(this.state.tabs, this.props.activeTab); this.props.cancel(); return false } } >
                {window.I18n.t('multi_widgets.save')}
          </button>
          <a className="button cancel grey btn btn-default pull-right margin-right-10"
             onClick={ e => { e.preventDefault(); this.props.cancel() } } >
            {window.I18n.t('multi_widgets.cancel')}
          </a>
        </Col>
      </Col>
    )
  }
}

EditTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  editTab: PropTypes.func.isRequired,
  updateTab: PropTypes.func.isRequired,
  swapTabs: PropTypes.func,
  removeTab: PropTypes.func,
  saveTabs: PropTypes.func,
  cancel: PropTypes.func,
  activeTab: PropTypes.number,
  usesTranslations: PropTypes.bool,
  availableLanguages: PropTypes.any,
  currentLanguage: PropTypes.any,
}

export default EditTabs
