import React from 'react'
import NowPickingAreaContainer from '../../../containers/manager_side/now_picking_area_container'
import LastPickAreaContainer from '../../../containers/manager_side/last_pick_area_container'
import UpcomingPicksAreaContainer from '../../../containers/manager_side/upcoming_picks_area_container'

const PickingArea = () => {

  return (
    <div className="picking_area">
      <NowPickingAreaContainer/>
      <LastPickAreaContainer/>
      <UpcomingPicksAreaContainer/>
    </div>
  )
}
export default PickingArea
