import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { GlgLoadingSpinner } from 'SharedComponents'
import CategoriesListContainer from '../containers/categories_list_container'
import LeaguesTableContainer from '../containers/leagues_table_container'

class CustomerCenterContentComponent extends Component {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
  }

  handleScroll(e) {
    const currentScrollAmount = Math.round(e.target.scrollHeight - e.target.scrollTop)
    const currentScrollPosition =
      currentScrollAmount <= e.target.clientHeight
        ? e.target.clientHeight
        : currentScrollAmount
    const marginError = e.target.clientHeight / 100
    const bottom = currentScrollPosition >= (e.target.clientHeight - marginError) && currentScrollPosition <= (e.target.clientHeight + marginError)

    if (bottom) {
      this.props.addLeaguesWithPagination()
    }
  }

  render() {
    if (this.props.dataIsLoading) {
      return <GlgLoadingSpinner className="add-margin-top-20"></GlgLoadingSpinner>
    } else if (this.props.viewType === 'category') {
      return <CategoriesListContainer></CategoriesListContainer>
    } else {
      return <div className="add-margin-top-10"><div onScroll={ this.handleScroll } className="categories_list_container"><LeaguesTableContainer includeCategoryColumn={ true }></LeaguesTableContainer></div></div>
    }
  }
}

CustomerCenterContentComponent.propTypes = {
  viewType: PropTypes.string.isRequired,
  dataIsLoading: PropTypes.bool.isRequired,
  isSalesDemo: PropTypes.bool,
  addLeaguesWithPagination: PropTypes.func,
}

export default CustomerCenterContentComponent
