import { ActionTypes } from '../actions'

const {
  OPEN_POPUP,
  CLOSE_POPUP,
} = ActionTypes

const initialState = {
  visible: false,
  playerName: '',
  distance: 0,
  distanceId: null,
  stationId: null,
}

const popup = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_POPUP:
      return {
        ...state,
        visible: true,
        playerName: action.playerName,
        distance: action.distance,
        distanceId: action.distanceId,
        stationId: action.stationId,
      }
    case CLOSE_POPUP:
      return {
        ...state,
        visible: false,
        playerName: '',
        distance: 0,
        distanceId: null,
        stationId: null,
      }
    default:
      return state
  }
}

export default popup
