import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

class ReleasePurse extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { title, links, noContent, pursePoints, pursePointsTeam, handicapIndexRange, isCustomerJp } = this.props.content

    return (
      <div className='widget-content-default widget-content-release-purse'>
        <div className='title'>
          { title }

          {
            links.standings.show
              ? <a href={ links.standings.path } className='btn btn-primary widget-white-title-button'>
                { links.standings.name }
              </a>
              : null
          }
        </div>
        <div className='content'>
          {
            noContent.show
              ? <Fragment>
                {
                    pursePoints.show
                        ? <div className='data-container'>
                            <div className='data-container-title'>
                                { pursePoints.title }
                            </div>
                            <div className='data-container-content'>
                                {
                                    pursePoints.byPurse.show
                                        ? <div className='data'>
                                            <div className='data-title'>
                                                { pursePoints.byPurse.title }
                                            </div>
                                            <div className='data-content'>
                                                {
                                                    pursePoints.byPurse.members.map(member =>
                                                    <div key={ member.name } className='subdata-container'>
                                                        <div className='subdata-name'>{ member.name }</div>
                                                        <div className='subdata-value'>{ isCustomerJp && member.score.includes('.') ? member.score.slice(0, -3) : member.score }</div>
                                                    </div>)
                                                }
                                                {
                                                    <div className='subdata-container total'>
                                                        <div className='subdata-name'>{ pursePoints.byPurse.total.name }</div>
                                                        <div className='subdata-value'>{ isCustomerJp && pursePoints.byPurse.total.score.includes('.') ? pursePoints.byPurse.total.score.slice(0, -3) : pursePoints.byPurse.total.score }</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    pursePoints.byPoints.show
                                        ? <div className='data'>
                                            <div className='data-title'>
                                                { pursePoints.byPoints.title }
                                            </div>
                                            <div className='data-content'>
                                                {
                                                    pursePoints.byPoints.members.map(member =>
                                                    <div key={ member.name } className='subdata-container'>
                                                        <div className='subdata-name'>{ member.name }</div>
                                                        <div className='subdata-value'>{ member.score }</div>
                                                    </div>)
                                                }
                                                {
                                                    <div className='subdata-container total'>
                                                        <div className='subdata-name'>{ pursePoints.byPoints.total.name }</div>
                                                        <div className='subdata-value'>{ pursePoints.byPoints.total.score }</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        : null
                }

                {
                    pursePointsTeam.show
                        ? <div className='data-container'>
                            <div className='data-container-title'>
                                { pursePointsTeam.title }
                            </div>
                            <div className='data-container-content'>
                                {
                                    pursePointsTeam.byTeamPurse.show
                                        ? <div className='data'>
                                            <div className='data-title'>
                                                { pursePointsTeam.byTeamPurse.title }
                                            </div>
                                            <div className='data-content'>
                                                {
                                                    pursePointsTeam.byTeamPurse.members.map(member =>
                                                    <div key={ member.name } className='subdata-container'>
                                                        <div className='subdata-name'>{ member.name }</div>
                                                        <div className='subdata-value'>{ member.score }</div>
                                                    </div>)
                                                }
                                                {
                                                    <div className='subdata-container total'>
                                                        <div className='subdata-name'>{ pursePointsTeam.byTeamPurse.total.name }</div>
                                                        <div className='subdata-value'>{ pursePointsTeam.byTeamPurse.total.score }</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    pursePointsTeam.byTeamPoints.show
                                        ? <div className='data'>
                                            <div className='data-title'>
                                                { pursePointsTeam.byTeamPoints.title }
                                            </div>
                                            <div className='data-content'>
                                                {
                                                    pursePointsTeam.byTeamPoints.members.map(member =>
                                                    <div key={ member.name } className='subdata-container'>
                                                        <div className='subdata-name'>{ member.name }</div>
                                                        <div className='subdata-value'>{ member.score }</div>
                                                    </div>)
                                                }
                                                {
                                                    <div className='subdata-container total'>
                                                        <div className='subdata-name'>{ pursePointsTeam.byTeamPoints.total.name }</div>
                                                        <div className='subdata-value'>{ pursePointsTeam.byTeamPoints.total.score }</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        : null
                }

                {
                    handicapIndexRange.show
                        ? <div className='data-container'>
                            <div className='data-container-title'>
                                { handicapIndexRange.title }
                            </div>
                            <div className='data-container-content'>
                                {
                                    handicapIndexRange.lowest.show
                                        ? <div className='data'>
                                            <div className='data-title'>
                                                { handicapIndexRange.lowest.title }
                                            </div>
                                            <div className='data-content'>
                                                {
                                                    handicapIndexRange.lowest.members.map(member =>
                                                    <div key={ member.name } className='subdata-container'>
                                                        <div className='subdata-name'>{ member.name }</div>
                                                        <div className='subdata-value'>{ member.index }</div>
                                                    </div>)
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    handicapIndexRange.highest.show
                                        ? <div className='data'>
                                            <div className='data-title'>
                                                { handicapIndexRange.highest.title }
                                            </div>
                                            <div className='data-content'>
                                                {
                                                    handicapIndexRange.highest.members.map(member =>
                                                    <div key={ member.name } className='subdata-container'>
                                                        <div className='subdata-name'>{ member.name }</div>
                                                        <div className='subdata-value'>{ member.index }</div>
                                                    </div>)
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        : null
                }
              </Fragment>
              : <div className='no-content'>
                <div className='no-content-title'>{ noContent.title }</div>
                <div className='no-content-text'>{ noContent.text }</div>
              </div>
          }
        </div>
      </div>
    )
  }
}

ReleasePurse.propTypes = { 
  content: PropTypes.object,
}

ReleasePurse.defaultProps = {
  content: {
    count: '0 $',
    description: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.release_purse.description'),
  },
}

export default ReleasePurse
