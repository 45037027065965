import { connect } from 'react-redux'
import EditColumnPropsForm from '../presentational/edit_column_props_form'
import { updateColumn, setActiveBlock, setActivePosition, displayMode, DISPLAY_MODES } from '../../actions'

const mapStateToProps = state => ({
  id: state.filters.active_column,
  title: state.columns.filter( column => column.id === state.filters.active_column)[0].title,
  titles: state.columns.filter( column => column.id === state.filters.active_column)[0].titles,
  view_more: state.columns.filter( column => column.id === state.filters.active_column)[0].view_more,
  usesTranslations: state.filters.uses_translations,
  availableLanguages: state.filters.available_languages,
})

const mapDispatchToProps = dispatch => ({
  cancel: () => {
    dispatch(setActivePosition(null))
    dispatch(setActiveBlock(null))
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
  updateColumn: (_id, _title, _titles, _viewMore) => {
    dispatch(updateColumn(_id, _title, _titles, _viewMore))
    dispatch(setActivePosition(null))
    dispatch(setActiveBlock(null))
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditColumnPropsForm)
