import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { interpretPlaceholders } from '../helpers'

class FooterComponent extends Component {

  constructor(props) {
    super(props)

    this.previewStyle = this.previewStyle.bind(this)
    this.colorStyle = this.colorStyle.bind(this)
    this.imageStyle = this.imageStyle.bind(this)
  }

  previewStyle() {
    return {
      height: parseInt(this.props.height),
    }
  }

  colorStyle() {
    const {
      selectedBackground,
      backgroundColor,
      backgroundOpacity,
    } = this.props

    if (selectedBackground === 'color'){
      return {
        backgroundColor,
        opacity: backgroundOpacity / 100,
      }
    }

    return {}
  }

  imageStyle() {
    const {
      selectedBackground,
      backgroundOpacity,
    } = this.props

    if (selectedBackground === 'image'){
      return {
        opacity: backgroundOpacity / 100,
        objectFit: 'cover',
      }
    }

    return {}
  }

  render() {
    const {
      selectedBackground,
      backgroundImage,
      text,
      isPreview,
    } = this.props

    const interpretedText = isPreview ? text : interpretPlaceholders(text, this.props)

    return <div style={ this.previewStyle() } className="footer-preview vertical-align-items">{ selectedBackground === 'color' && <div style={ this.colorStyle() } className="background-color"></div>}{ selectedBackground === 'image' && <img src={ backgroundImage } style={ this.imageStyle() } className="background-image" />}<span dangerouslySetInnerHTML={{__html: interpretedText }}></span></div>
  }

}

FooterComponent.propTypes = {
  height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
  selectedBackground: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundOpacity: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
  text: PropTypes.string,
  eventName: PropTypes.string,
  tournamentName: PropTypes.string,
  roundDate: PropTypes.string,
  divisionName: PropTypes.string,
  isPreview: PropTypes.bool,
}

export default FooterComponent
