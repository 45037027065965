import { connect } from 'react-redux'
import SideBySideTableComponent from '../components/side_by_side_table_component'
import { getTableColumns, getPlayersOnFrontSide, getPlayersOnBackSide } from 'Shared/course_boards' 
import { removeLine, reorderSideLines, handleShowMessageChanged, handleMessageChanged, handleScoreChanged } from '../actions' 

const mapStateToProps = (state) => {
  const isMultiRound = state.misc.isMultiRound
  const withTeams = state.misc.withTeams
  const showCourse = state.misc.showCourse
  const columns = getTableColumns(isMultiRound, withTeams, showCourse, true, true)
  const frontLines = getPlayersOnFrontSide(state)
  const backLines = getPlayersOnBackSide(state)

  return {
    isMultiRound: isMultiRound,
    withTeams: withTeams,
    showCourse: showCourse,
    frontLines: frontLines,
    backLines: backLines,
    columns: columns,
    showMessage: state.misc.showMessage,
    message: state.misc.message,
    score: state.misc.score,
  }
}

const mapDispatchToProps = (dispatch) => ({
  removeLine: (position, front9) => dispatch(removeLine(position, front9)),
  reorderFrontLines: (fromPosition, toPosition) => dispatch(reorderSideLines(fromPosition, toPosition, true)),
  reorderBackLines: (fromPosition, toPosition) => dispatch(reorderSideLines(fromPosition, toPosition, false)),
  handleShowMessageChanged: (value) => dispatch(handleShowMessageChanged(value)),
  handleMessageChanged: (value) => dispatch(handleMessageChanged(value)),
  handleScoreChanged: (value) => dispatch(handleScoreChanged(value)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SideBySideTableComponent)
