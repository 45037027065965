import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Brackets extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { title, links, brackets } = this.props.content

    return (
      <div className='widget-content-default widget-content-brackets'>
        <div className='title'>
          { title }
          <a href={ links.new_brackets.path } className='btn btn-primary widget-white-title-button'>
            { links.new_brackets.name }
          </a>
        </div>
        <div className='content'>
          {
            brackets.map(bracket =>
              <div key={ bracket.name } className='bracket-block'>
                <a href={ bracket.path }>{ bracket.name }</a>
                <div className='middle'>
                  <div>
                    { bracket.players.name }
                    <span className='data'>
                      { bracket.players.value }
                    </span>
                    <span>
                      |
                    </span>
                  </div>
                  <div>
                    { bracket.fill_rate.name }
                    <span className='data'>
                      { bracket.fill_rate.value }
                    </span>
                    <span>
                      |
                    </span>
                  </div>
                </div>
                <div className='bottom'>
                  <div className='bottom-data'>{ bracket.region }</div>
                  <div className='bottom-data'>{ bracket.rounds }</div>
                  <div className='bottom-data'>{ bracket.tournament_settings }</div>
                  <div className='bottom-data' dangerouslySetInnerHTML={{__html: bracket.consolation }} />
                </div>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}

Brackets.propTypes = { 
  content: PropTypes.object,
}


Brackets.defaultProps = {
  content: {
    new_tournament_path: '#',
    brackets: [],
  },
}

export default Brackets
