import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'

import CheckInTableRow from './check_in_table_row'

import {
  GlgTable,
  GlgTableCol,
  GlgLoadingSpinner,
} from 'SharedComponents'

class CheckInListComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let checkins = ''
    let sectionTitle = this.props.sectionTitle
    
    if (!this.props.dataIsLoading){
      if (this.props.checkInsCount === 0) {
        checkins = <div className="no-info">{ this.props.noInfoMessage }</div>
      } else {
        checkins = <GlgTable items={ this.props.checkins } rowComponent={ CheckInTableRow } rowExtraProps={ {deleteCheckIn: this.props.deleteCheckIn} }><GlgTableCol width={ '40%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.date_time') }></GlgTableCol><GlgTableCol width={ '60%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.location') }></GlgTableCol><GlgTableCol width={ '40%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.action') }></GlgTableCol></GlgTable>
        sectionTitle += ' • ' + this.props.checkInsCount
      }
    }
    
    return <Col xs={ 12 }><Row className="title no-lateral-margin">{ sectionTitle }</Row><Row className="no-lateral-margin"><GlgLoadingSpinner show={ this.props.dataIsLoading }></GlgLoadingSpinner>{ checkins }</Row></Col>
  }
}

CheckInListComponent.propTypes = {
  checkins: PropTypes.array.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  noInfoMessage: PropTypes.string.isRequired,
  dataIsLoading: PropTypes.bool,
  checkInsCount: PropTypes.number.isRequired,
  deleteCheckIn: PropTypes.func,
}

export default CheckInListComponent
