import {ActionTypes} from '../actions'
import OttScreen from '../ott_screen'

const rootReducer = (state, action) => {
  let restult = []
  let updatedState = null
  switch (action.type) {
    case ActionTypes.SELECT_SLOT:
      return {
        ...state,
        data: toggleSlot(state.data, action.payload.slotId, action.payload.position),
        selectedSlots: [
          ...state.selectedSlots,
          {
            foursome_id: action.payload.slotId,
            player_position: action.payload.position,
          },
        ],
      }
    case ActionTypes.UNSELECT_SLOT:
      return {
        ...state,
        data: toggleSlot(state.data, action.payload.slotId, action.payload.position),
        selectedSlots: state.selectedSlots.filter( slot => slot.foursome_id !== action.payload.slotId || slot.player_position !== action.payload.position),
      }
    case ActionTypes.SET_ACTIVE_SCREEN:
      return setActiveScreen(state, action.payload.screen)
    case ActionTypes.MOVE_HERE:
      return {
        ...state,
        checkedSlots: [],
        selectedSlots: selectedSlotsOnChangingStarting(state.data, action.payload.foursomeId, action.payload.oldSlot, getFirstAvailablePosition(state.data, action.payload.foursomeId, state.playersCount), state.playersCount),
        data: moveSlotsStarting(state.data, action.payload.foursomeId, action.payload.oldSlot, getFirstAvailablePosition(state.data, action.payload.foursomeId, state.playersCount), state.playersCount),
      }
    case ActionTypes.SELECT_MOVE_HERE:
      return {
        ...state,
        checkedSlots: [],
        selectedSlots: selectedSlotsOnChangingStarting(state.data, action.payload.foursomeId, action.payload.oldSlot, action.payload.position, state.playersCount),
        data: moveSlotsStarting(state.data, action.payload.foursomeId, action.payload.oldSlot, action.payload.position, state.playersCount),
      }
    case ActionTypes.CHECK_SLOT:
      return {
        ...state,
        data: togglecheckBox(state.data, action.payload.slotId, action.payload.position, state.checkedSlots.length + 1),
        checkedSlots: [
          ...state.checkedSlots,
          {
            foursome_id: action.payload.slotId,
            position: action.payload.position,
          },
        ],
      }
    case ActionTypes.UNCHECK_SLOT:
      return {
        ...state,
        data: togglecheckBox(state.data, action.payload.slotId, action.payload.position, state.checkedSlots.length - 1),
        checkedSlots: state.checkedSlots.filter( slot => slot.foursome_id !== action.payload.slotId || slot.position !== action.payload.position),
      }
    case ActionTypes.UNSELECT:
      return {
        ...state,
        data: togglecheckBoxAll(state.data, action.payload.slotId, false, state.playersCount),
        checkedSlots: state.checkedSlots.filter( slot => slot.foursome_id !== action.payload.slotId),
      }
    case ActionTypes.UNSELECT_ALL:
      return {
        ...state,
        data: unselectAllSlots(state.data, action.payload.slotId, state.playersCount),
        selectedSlots: state.selectedSlots.filter( slot => slot.foursome_id !== action.payload.slotId),
      }
    case ActionTypes.SELECT_ALL:
      return {
        ...state,
        data: togglecheckBoxAll(state.data, action.payload.slotId, true, state.playersCount),
        checkedSlots: state.checkedSlots.concat(
          state.data[state.data.findIndex(item => item.foursome_id === action.payload.slotId)].foursome_players
            .map((player, index) => (player.owned_by_current_member ? index : null))
            .filter(position => position !== null)
            .map(position => ({
              foursome_id: action.payload.slotId,
              position: position,
            })),
        ),
      }
    case ActionTypes.SWITCH_PLAYERS:
      return {
        ...state,
        selectedSlots: [
          ...state.selectedSlots,
          {
            foursome_id: action.payload.id1,
            player_position: action.payload.p1,
          },
        ],
        data: switchPlayersData(state.data, action.payload.id1, action.payload.p1, action.payload.id2, action.payload.p2),
      }
    case ActionTypes.REINITIALISE:
      return reinitialise(state, action, false)
    case ActionTypes.UPDATE:
      restult = dataAfterUpdating(state.data, action.payload.newState.data, state.selectedSlots, state.playersCount, state.activeScreen, state.isAdminMode, state.checkedSlots)

      if (restult[1] === true) {
        updatedState = reinitialise(state, action, true)
        return setActiveScreen(updatedState, OttScreen.CHANGE_TEE_TIME)
      } else {
        return {
          ...state,
          data: restult[0],
          selectedSlots: slotsAfterUpdating(action.payload.newState.data, state.selectedSlots, state.playersCount),
          checkedSlots: state.checkedSlots,
          leagueId: action.payload.newState.league_id,
          roundId: action.payload.newState.round_id,
          minSlotsReg: action.payload.newState.min_slots_reg,
          maxSlotsReg: action.payload.newState.max_slots_reg,
          maxSlotsSignup: action.payload.newState.max_slots_signup,
          remainingSlotsSignupGuests: action.payload.newState.remaining_slots_signup_guests,
          remainingSlotsSignupMembers: action.payload.newState.remaining_slots_signup_members,
          remainingSlotsRegisterGolfers: action.payload.newState.remaining_slots_register_golfers,
          members: action.payload.newState.registered_members,
          roundName: action.payload.newState.round_name,
          roundPlayAt: action.payload.newState.round_play_at,
          playersCount: action.payload.newState.foursome_count,
          selectablePlayers: action.payload.newState.selectable_players,
          disabledPlayers: action.payload.newState.disabled_players,
          currentUser: action.payload.newState.current_user,
          userInMasterRoster: action.payload.newState.user_in_master_roster,
          isAdminMode: action.payload.newState.is_in_admin_mode,
          isUpdateErrorPopupOpen: showUpdatePopup(action.payload.newState.data, state.selectedSlots, state.playersCount, state.activeScreen) || restult[1] || state.isUpdateErrorPopupOpen,
          lastRefreshTime: new Date(),
        }
      }
    case ActionTypes.CLOSE_UPDATE_ERROR:
      return {
        ...state,
        isUpdateErrorPopupOpen: action.payload.toggle,
      }
    case ActionTypes.TOGGLE_REFRESH_QUEUE:
      return {
        ...state,
        isRefreshQueuedUp: action.payload.toggle,
      }
    case ActionTypes.TOGGLE_SWAP_MODE:
      return {
        ...state,
        isSwapModeOn: action.payload.toggle,
      }
    case ActionTypes.TOGGLE_REFRESH_STATUS_BAR:
      return {
        ...state,
        shortPollingEnablement: action.payload.toggle,
      }
    case ActionTypes.REFRESH_SESSION_START_TIME:
      return {
        ...state,
        sessionStartTime: new Date(),
      }
    case ActionTypes.HIDE_MOBILE_SCROLL:
      return {
        ...state,
        displayMobileScroller: false,
      }

    default:
      return {
        ...state,
      }
  }
}

const setActiveScreen = (state, screen) => {
  return {
    ...state,
    activeScreen: screen,
    bookBeforeRegister: state.bookBeforeRegister || screen === OttScreen.ADD_ADDITIONAL_PLAYERS,
    checkedSlots: getCheckedSLots(state.data, screen),
    data: updateValidSlots(state.data, getCheckedSLots(state.data, screen).length, screen),
    isUpdateErrorPopupOpen: state.isUpdateErrorPopupOpen,
  }
}

const reinitialise = (state, action, popupToggle) => {

  return {
    ...state,
    data: action.payload.newState.data,
    selectedSlots: [],
    checkedSlots: [],
    leagueId: action.payload.newState.league_id,
    roundId: action.payload.newState.round_id,
    activeScreen: action.payload.newState.active_screen,
    minSlotsReg: action.payload.newState.min_slots_reg,
    maxSlotsReg: action.payload.newState.max_slots_reg,
    maxSlotsSignup: action.payload.newState.max_slots_signup,
    remainingSlotsSignupGuests: action.payload.newState.remaining_slots_signup_guests,
    remainingSlotsSignupMembers: action.payload.newState.remaining_slots_signup_members,
    remainingSlotsRegisterGolfers: action.payload.newState.remaining_slots_register_golfers,
    members: action.payload.newState.registered_members,
    currentMemberId: action.payload.newState.current_member_id,
    bookBeforeRegister: action.payload.newState.book_before_register,
    roundName: action.payload.newState.round_name,
    roundPlayAt: action.payload.newState.round_play_at,
    playersCount: action.payload.newState.foursome_count,
    selectablePlayers: action.payload.newState.selectable_players,
    disabledPlayers: action.payload.newState.disabled_players,
    currentUser: action.payload.newState.current_user,
    userInMasterRoster: action.payload.newState.user_in_master_roster,
    isAdminMode: action.payload.newState.is_in_admin_mode,
    isUpdateErrorPopupOpen: state.isUpdateErrorPopupOpen || popupToggle,
  }

}


const dataAfterUpdating = (oldData, newData, originalSelectedSlots, playersCount, screen, admin, newCheckedSlots) => {

  if (admin === true) {
    Object.keys(newData)
      .forEach(key => {
        Array.from(Array(playersCount)
          .keys())
          .forEach(position => {
            if (newData[key].foursome_players[position].player !== null && oldData[key].foursome_players[position].player !== null && oldData[key].foursome_players[position].owned_by_current_member) {
              newData[key].foursome_players[position].owned_by_current_member = true
            }
            if ( newData[key].foursome_players[position].player !== null && oldData[key].foursome_players[position].player === null ) {
              newData[key].foursome_players[position] = oldData[key].foursome_players[position]
            }
          })
      })
  }

  let foundBad = false

  if (screen === OttScreen.CHANGE_TEE_TIME) {

    Object.keys(newData)
      .forEach(key => {
        Array.from(Array(playersCount)
          .keys())
          .forEach(position => {
            if ((newData[key].foursome_players[position].player !== null || newData[key].foursome_players[position].is_reserved) && oldData[key].foursome_players[position].player !== null && !newData[key].foursome_players[position].owned_by_current_member && oldData[key].foursome_players[position].owned_by_current_member) {

              foundBad = true
            }

          })
      })

    if (foundBad === true) {
      return [ newData, true ]
    } else {

      Object.keys(newData)
        .forEach(key => {
          Array.from(Array(playersCount)
            .keys())
            .forEach(position => {
              if (newData[key].foursome_players[position].player !== null && oldData[key].foursome_players[position].player === null && newData[key].foursome_players[position].owned_by_current_member) {
                newData[key].foursome_players[position].player = null
                newData[key].foursome_players[position].is_checked = false
                newData[key].foursome_players[position].is_selected = false
                newData[key].foursome_players[position].is_valid_for_moving = true
                newData[key].has_players_owned_by_members = false

              }
              if (oldData[key].foursome_players[position].player !== null && newData[key].foursome_players[position].player === null) {
                newData[key].foursome_players[position] = oldData[key].foursome_players[position]
                newData[key].has_players_owned_by_members = newData[key].foursome_players[position].owned_by_current_member === true
              }

            })
        })

      originalSelectedSlots.forEach(slot => {
        Object.keys(newData).forEach(key => {
          if (slot.foursome_id === newData[key].foursome_id) {
            Array.from(Array(playersCount).keys()).forEach(position => {
              if (position === slot.player_position && newData[key].foursome_players[position].player === null && (!newData[key].foursome_players[position].is_reserved ||
                newData[key].foursome_players[position].owned_by_current_member
              )) {
                newData[key].foursome_players[position].is_selected = true
                newData[key].has_players_owned_by_members = true
              }
            })
          }
        })
      })

      if (admin === true) {
        newCheckedSlots.forEach(slot => {
          Object.keys(newData).forEach(key => {
            if (slot.foursome_id === newData[key].foursome_id) {
              Array.from(Array(playersCount).keys()).forEach(position => {
                if (position === slot.position && newData[key].foursome_players[position].owned_by_current_member) {
                  newData[key].foursome_players[position].is_checked = true
                }
              })
            }
          })
        })
      }



      return [ newData, false ]
    }
  } else {
    originalSelectedSlots.forEach(slot => {
      Object.keys(newData).forEach(key => {
        if (slot.foursome_id === newData[key].foursome_id) {
          Array.from(Array(playersCount).keys()).forEach(position => {
            if (position === slot.player_position && newData[key].foursome_players[position].player === null && (!newData[key].foursome_players[position].is_reserved ||
              newData[key].foursome_players[position].owned_by_current_member
            )) {
              newData[key].foursome_players[position].is_selected = true
            }
          })
        }
      })
    })

    return [ newData, false ]
  }
}

const slotsAfterUpdating = (newData, originalSelectedSlots, playersCount) => {

  const updatedSelectedSlots = originalSelectedSlots.filter(slot => {
    let meetsCondition = false
    Object.keys(newData).forEach(key => {
      if (slot.foursome_id === newData[key].foursome_id) {
        Array.from(Array(playersCount).keys()).forEach(position => {
          if (position === slot.player_position && newData[key].foursome_players[position].player === null && (!newData[key].foursome_players[position].is_reserved ||
            newData[key].foursome_players[position].owned_by_current_member
          ) || newData[key].foursome_players[position].owned_by_current_member) {
            meetsCondition = true
          }
        })
      }
    })
    return meetsCondition
  })


  return updatedSelectedSlots
}

const showUpdatePopup = (newData, originalSelectedSlots, playersCount, activeScreen) => {

  if (activeScreen !== OttScreen.CHANGE_TEE_TIME) {
    return originalSelectedSlots.length !== slotsAfterUpdating(newData, originalSelectedSlots, playersCount).length
  } else {
    false
  }
}


const switchPlayersData = (data, id1, p1, id2, p2) => {
  const newFoursomeData = data.find(item => item.foursome_id === id2)
  const oldFoursomeData = data.find(item => item.foursome_id === id1)

  const oldFoursomeDataCopy = JSON.parse(JSON.stringify(oldFoursomeData))
  const newFoursomeDataCopy = JSON.parse(JSON.stringify(newFoursomeData))

  const player = oldFoursomeDataCopy.foursome_players[p1]
  const player2 = newFoursomeDataCopy.foursome_players[p2]

  newFoursomeData.foursome_players[p2] = player
  oldFoursomeData.foursome_players[p1] = player2

  return data
}

const unselectAllSlots = (data, selectedIds, playersCount) => {
  Object.keys(data).forEach(key => {
    if (selectedIds === data[key].foursome_id) {
      Array.from(Array(playersCount - 1).keys()).forEach(position => {
        data[key].foursome_players[position].is_selected = false
      })

    }
  })

  return data
}

const toggleSlot = (data, selectedIds, position) => {
  Object.keys(data).forEach(key => {
    if (selectedIds === data[key].foursome_id) {
      data[key].foursome_players[position].is_selected = !data[key].foursome_players[position].is_selected
    }
  })

  return data
}

const togglecheckBox = (data, selectedIds, position, checkedSlotsLength) => {
  Object.keys(data).forEach(key => {
    if (selectedIds === data[key].foursome_id) {
      data[key].foursome_players[position].is_checked = !data[key].foursome_players[position].is_checked
    }
  })

  return updateValidSlots(data, checkedSlotsLength, OttScreen.CHANGE_TEE_TIME)
}

const togglecheckBoxAll = (data, selectedIds, flag, playersCount) => {
  Object.keys(data).forEach(key => {
    if (selectedIds === data[key].foursome_id) {
      for (let i = 0; i < playersCount; i++) {
        if (data[key].foursome_players[i].player) {
          data[key].foursome_players[i].is_checked = flag
        }
      }

    }
  })

  return data
}

const updateValidSlots = (data, checkedSlots, activeScreen) => {
  return data.map((foursomeData) => {
    const modifiedFoursomePlayers = foursomeData.foursome_players.map((player) => {
      if (activeScreen === OttScreen.REMOVE_PLAYERS) {
        return { ...player, is_checked: false }
      }

      const newIsValidForMoving = findAvailableSlots(data, foursomeData.foursome_id, checkedSlots) !== -1
      return { ...player, is_valid_for_moving: newIsValidForMoving }
    })

    return { ...foursomeData, foursome_players: modifiedFoursomePlayers }
  })
}

const getCheckedSLots = (data, screen) => {

  if (screen === OttScreen.CHANGE_TEE_TIME) {
    return filterDataByOwnedMember(data)
  }
  
  return []
}

const filterDataByOwnedMember = (data) => {
  const filteredData = data.reduce((result, item) => {
    const ownedPlayers = item.foursome_players.filter(
      (playerInfo) => playerInfo.owned_by_current_member === true && playerInfo.player !== null,
    )

    if (ownedPlayers.length > 0) {
      ownedPlayers.forEach((playerInfo) => {
        result.push({
          foursome_id: item.foursome_id,
          position: item.foursome_players.indexOf(playerInfo),
        })
      })
    }

    return result
  }, [])

  return filteredData
}

const selectedSlotsOnChangingStarting = (data, foursomeId, oldSlot, startingPosition, playersCount) => {

  const selectedSlots = []

  let firstAvailablePosition = findConsecutiveAvailablePosition2(data, foursomeId, oldSlot.length, startingPosition)

  for (let i = 0; i <= oldSlot.length - 1; i++) {
    const selectedSlot = {
      foursome_id: foursomeId,
      player_position: firstAvailablePosition,
    }
    selectedSlots.push(selectedSlot)

    firstAvailablePosition = getNextAvailablePosition(data, foursomeId, firstAvailablePosition, playersCount)
  }

  return selectedSlots
}

const getFirstAvailablePosition = (data, foursomeId, playersCount) => {

  const foursomeData = data.find(item => item.foursome_id === foursomeId)

  for (let i = 0; i < playersCount; i++) {
    const currentPlayer = foursomeData.foursome_players[i]
    if (!(currentPlayer && (currentPlayer.player !== null || currentPlayer.is_reserved))) {
      return i
    }
  }
}

const getNextAvailablePosition = (data, foursomeId, currentPosition, playersCount) => {
  const foursomeData = data.find(item => item.foursome_id === foursomeId)
  let candidPosition = currentPosition + 1

  if (candidPosition >= playersCount) {
    candidPosition = 0
  }

  for (let i = candidPosition; i < playersCount; i++) {

    const currentPlayer = foursomeData.foursome_players[i]
    if (!(currentPlayer && (currentPlayer.player !== null || currentPlayer.is_reserved))) {
      return i
    }
  }

  for (let i = 0; i < currentPosition; i++) {

    const currentPlayer = foursomeData.foursome_players[i]
    if (!(currentPlayer && (currentPlayer.player !== null || currentPlayer.is_reserved))) {
      return i
    }
  }

}

const moveSlotsStarting = (data, foursomeId, oldSlots, startingPosition, playersCount) => {
  let firstPosition = findConsecutiveAvailablePosition2(data, foursomeId, oldSlots.length, startingPosition)
  const newFoursomeData = data.find(item => item.foursome_id === foursomeId)

  for (let i = 0; i <= oldSlots.length - 1; i++) {
    const initFoursomeId1 = oldSlots[i].foursome_id
    const position1 = oldSlots[i].position
    const oldFoursomeData = data.find(item => item.foursome_id === initFoursomeId1)
    const oldFoursomeDataCopy = JSON.parse(JSON.stringify(oldFoursomeData))

    newFoursomeData.has_players_owned_by_members = true
    const player = oldFoursomeDataCopy.foursome_players[position1]
    newFoursomeData.foursome_players[firstPosition] = player
    newFoursomeData.foursome_players[firstPosition].is_checked = false
    oldFoursomeData.foursome_players[position1].player = null
    oldFoursomeData.foursome_players[position1].is_valid_for_moving = true
    oldFoursomeData.foursome_players[position1].owned_by_current_member = false
    oldFoursomeData.foursome_players[position1].is_checked = false
    oldFoursomeData.has_players_owned_by_members = oldFoursomeData.foursome_players.some(player => player.owned_by_current_member === true)
    firstPosition = getNextAvailablePosition(data, foursomeId, firstPosition, playersCount)
  }

  newFoursomeData.has_players_owned_by_members = true

  return data
}

const findAvailableSlots = (dataArray, foursomeId, checkedSlots) => {
  const foursome = dataArray.find(item => item.foursome_id === foursomeId)

  if (foursome) {
    const players = foursome.foursome_players
    let availableSlotsCount = 0

    for (let i = 0; i < players.length; i++) {
      const currentPlayer = players[i]

      if (currentPlayer && (currentPlayer.player !== null || currentPlayer.is_reserved)) {
        //consecutiveAvailable = false
      } else {
        availableSlotsCount++
      }
    }

    if (availableSlotsCount >= checkedSlots) {
      return true
    }
  }

  return -1
}

const findConsecutiveAvailablePosition2 = (dataArray, foursomeId, x, startingPosition ) => {
  const foursome = dataArray.find(item => item.foursome_id === foursomeId)

  if (foursome) {
    const players = foursome.foursome_players

    if (startingPosition < 0 || startingPosition > players.length - 1) {
      return -1
    }

    for (let i = startingPosition; i <= players.length - x; i++) {
      let consecutiveAvailable = true

      for (let j = 0; j < x; j++) {
        const currentPlayer = players[i + j]

        if (currentPlayer && (currentPlayer.player !== null || currentPlayer.is_reserved)) {
          consecutiveAvailable = false
          if (startingPosition - 1 >= 0) {
            return findConsecutiveAvailablePosition2(dataArray, foursomeId, x, startingPosition - 1)
          } else {
            return startingPosition
          }
        }
      }

      if (consecutiveAvailable) {
        return i
      }
    }

    if (startingPosition - 1 >= 0 && !(players[startingPosition - 1] && (players[startingPosition - 1].player !== null || players[startingPosition - 1].is_reserved)) ) {
      return findConsecutiveAvailablePosition2(dataArray, foursomeId, x, startingPosition - 1)
    }

  }

  return startingPosition
}

export default rootReducer
