import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { onConfirm } from 'Shared/helpers'
import PopupTypes from '../popup_types'
import {
  GlgTableRow,
  GlgTableCell,
  GlgLabel,
} from 'SharedComponents'

const NavigatorOptionsTableRow = ({ isSortable, ...props }) => {
  const popupData = _.pick(props, [
    'id', 'name', 'context', 'url', 'tags', 'alternateTags',
    'weight', 'location', 'internalId', 'helpLinks',
  ])
  popupData.operation = 'edit'
  const handleClick = () => props.openPopup(PopupTypes.NAVIGATOR_OPTION_POPUP,
                                            popupData)
  const handleDelete = () => {
    onConfirm(`Are you sure you want to delete the ${ props.name } option?`, () => {
      props.onOptionDelete(props.id)
    })
  }
  return <GlgTableRow { ...props } isSortable={ isSortable }><GlgTableCell>{ props.rowIndex + 1 }</GlgTableCell><GlgTableCell>{ props.name }</GlgTableCell><GlgTableCell>{ _.capitalize(props.context) }</GlgTableCell><GlgTableCell>{ _.truncate(props.url, { length: 80 }) }</GlgTableCell><GlgTableCell>{props.tags.map(tag => <GlgLabel key={ tag } className="add-margin-right-5">{ tag }</GlgLabel>)}</GlgTableCell><GlgTableCell><a onClick={ handleClick } className="clickable">Edit</a><a onClick={ handleDelete } className="clickable add-margin-left-5">Delete</a></GlgTableCell></GlgTableRow>
}

NavigatorOptionsTableRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  isSortable: PropTypes.bool.isRequired,
  openPopup: PropTypes.func.isRequired,
  onOptionDelete: PropTypes.func.isRequired,
}

export default NavigatorOptionsTableRow
