import React from 'react'
import PropTypes from 'prop-types'

import rootReducer from '../reducers'

import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import LayoutContainer from './layout_container'

//* REACT - Step #2 - Define the root component that will be rendered from the HAML file.
const DashboardRoot = ({ initialState }) => {
  const state = {
    menu: {
      selectedMenuItem: initialState.menu_items.selected_menu_item,
      menuItems: {
        eventOverview: initialState.menu_items.event_overview,
        rounds: initialState.menu_items.rounds,
        customerCenter: initialState.menu_items.customer_center,
        newRound: initialState.menu_items.new_round,
        icons: initialState.menu_items.icons,
        newItems: initialState.menu_items.new_items,
        expressEvent: initialState.menu_items.express_event,
        eventSetup: initialState.menu_items.event_setup,
        wizard: initialState.menu_items.wizard,
      },
    },
    developmentEnvironment: initialState.development_environment,
    widgets: initialState.widgets,
  }

  //* REACT - Step #3 - Initialize the Redux store, passing the reducer and the initial state as params
  const store = configureStore(rootReducer, state)
  window.glg.member.dispatch = store.dispatch

  return (
    //* REACT - Step #4 - Wrap the React app into GlobalProvider and inject the store for global access
    <GlobalProvider store = { store }>
      <LayoutContainer developmentEnvironment={ initialState.development_environment } />
    </GlobalProvider>
  )
}

DashboardRoot.propTypes = {
  initialState: PropTypes.any,
}

export default DashboardRoot
