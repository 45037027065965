import React, { useEffect, useState } from 'react'
import Flyers from './Flyers'
import PropTypes from 'prop-types'

const FlyersList = (props) => {
  const [ cesdkInstance, setCesdkInstance ] = useState(null)

  useEffect(() => {
    const handleCesdkInstanceReady = (event) => {
      setCesdkInstance(event.detail.instance)
    }

    document.addEventListener('cesdkInstanceReady', handleCesdkInstanceReady)

    return () => {
      document.removeEventListener('cesdkInstanceReady', handleCesdkInstanceReady)
    }
  }, [])

  const hasCustomFlyers = props.customFlyers.length !== 0

  return (
    <div className="list-of-flyers">
      <div className="list-1">
        <Flyers
          flyers={props.customFlyers}
          canDelete={true}
          leagueId={props.leagueId}
          customerId={props.customerId}
          isAdmin={props.isAdmin}
          isSystemTemplate={false}
          showCategory={hasCustomFlyers}
          imglyInstance={cesdkInstance}
        />
      </div>
      <div className="list-2">
        <Flyers
          flyers={props.systemFlyers}
          canDelete={false}
          leagueId={props.leagueId}
          customerId={props.customerId}
          isAdmin={props.isAdmin}
          isSystemTemplate={true}
          showCategory={!hasCustomFlyers}
          imglyInstance={cesdkInstance}
          />
      </div>
    </div>
  )
}

FlyersList.propTypes = {
  customFlyers: PropTypes.array,
  systemFlyers: PropTypes.array,
  leagueId: PropTypes.string,
  customerId: PropTypes.number,
  isAdmin: PropTypes.bool,
}

export default FlyersList
