import { connect } from 'react-redux'
import TvShowSlideshowComponent from '../components/slideshow/tv_show_slideshow_component'
import {
  moveToNextSlide,
  updateToLatestSlideshow,
  setHeaderHeight,
  setFooterHeight,
  toggleBlueBoxProperty,
  plainlySetBlueBoxProperty,
  setContainingSlideProperty,
} from '../actions/slideshowData'

const mapStateToProps = (state = {}) => {

  if (state.slideshowData.currentSlide.displayType === 'table') {
    state = {
      ...state,
      slideshowData: {
        ...state.slideshowData,
        currentSlide: {
          ...state.slideshowData.currentSlide,
          currentDivisionFlightNames: (state.slideshowData.slides[state.slideshowData.currentSlide.index]
                                            .flightsByDivisionId[
                                              state.slideshowData.slides[state.slideshowData.currentSlide.index]
                                                  .divisions[state.slideshowData.currentSlide.currentDivisionPosition]
                                                  .id
                                            ] || []).map( flight => flight.label + ' ' + !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_slideshow_container.flight') ),
        },
      },
    }
  }

  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    slideshowDispatchers: {
      moveToNextSlide: () => {
        dispatch( moveToNextSlide() )
      },
      updateToLatestSlideshow: (updateResponse) => { dispatch( updateToLatestSlideshow(updateResponse) ) },
      advancedSlideDispatchers: {
        setContainingSlideCurrentDivisionName: (advancedSlideId, containedSlideId, divisionName) => { dispatch( setContainingSlideProperty(advancedSlideId, containedSlideId, 'divisionName', divisionName) ) },
      },
      blueBoxDispatchers: {
        showBlueBox: () => { dispatch( plainlySetBlueBoxProperty( 'show', true ) ) },
        hideBlueBox: () => { dispatch( plainlySetBlueBoxProperty( 'show', false ) ) },
        setFontSize: (fontSize) => { dispatch( plainlySetBlueBoxProperty('fontSize', parseInt(fontSize)) ) },
        setScrollRate: (scrollRate) => { dispatch( plainlySetBlueBoxProperty('scrollRate', parseFloat(scrollRate)) ) },
        setTopMargin: (topMargin) => { dispatch( plainlySetBlueBoxProperty('topMargin', parseInt(topMargin)) ) },
        setBottomMargin: (bottomMargin) => { dispatch( plainlySetBlueBoxProperty('bottomMargin', parseInt(bottomMargin)) ) },
        setSideMargin: (sideMargin) => { dispatch( plainlySetBlueBoxProperty('sideMargin', parseInt(sideMargin)) ) },
        setHeaderHeight: (headerHeight) => { dispatch( setHeaderHeight(headerHeight) ) },
        setFooterHeight: (footerHeight) => { dispatch( setFooterHeight(footerHeight) ) },
        setTickerDisplay: (event) => { dispatch( plainlySetBlueBoxProperty('tickerDisplay', event.target.value) ) },
        setColorTheme: (value) => { dispatch( plainlySetBlueBoxProperty('colorTheme', value) ) },
        toggleFullscreen: () => { dispatch( toggleBlueBoxProperty('isFullscreen') ) },
        toggleScrolling: () => { dispatch( toggleBlueBoxProperty('isScrolling') ) },
      },
    },
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TvShowSlideshowComponent)
