import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { FormGroup } from 'react-bootstrap'
import {
  GlgPopup,
  GlgPopupForm,
} from '../../../../shared/components'

class SelectDefaultTournament extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount() {
    $('.select-default-tournament-for-mobile').selectpicker()
  }

  componentDidUpdate() {
    setTimeout(
      () => {
        $('.select-default-tournament-for-mobile').selectpicker('destroy')
        $('.select-default-tournament-for-mobile').selectpicker('refresh')
        window.redo_qtip()
      },
      100
    )
  }

  render() {
    const { show, title, text, tournaments } = this.props

    return (
      <GlgPopup title={ title }
                saveButtonText= { !window.I18n ? '' : window.I18n.t('buttons.save').charAt(0)
                  .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.save').slice(1)) }
                closeButtonClass='hidden'
                show={ show }
                onSave={ this.props.onSave }
                onClose={ this.props.onClose }
                saveButtonEnabled={ true }
                modalClass='set-flash-message'>
        <GlgPopupForm className='flash-manager margin_top_10 margin_bottom_10 well white_background' id='adjust-handicap-form'>
            <FormGroup className='add-margin-top-5'>
                <p>{ text }</p>
                <div className='select-handicap'>
                    <select className='selectpicker select-default-tournament-for-mobile'
                            onChange={ event => this.props.setSelectedDefaultRoundTournament(event.target.value !== tournaments[0].name ? event.target.value : null) }>
                    {
                        tournaments.map(tournament =>
                            <option key={ tournament.id }
                                    value={ tournament.id } >
                                { tournament.name }
                            </option> )
                    }
                    </select>
                </div>
            </FormGroup>
        </GlgPopupForm>
      </GlgPopup>
    )
  }
}

SelectDefaultTournament.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  tournaments: PropTypes.array,
  setSelectedDefaultRoundTournament: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
}


SelectDefaultTournament.defaultProps = {
  showModal: false,
}

export default SelectDefaultTournament
