import { connect } from 'react-redux'

import HoleDataComponent from '../components/hole_data_component'
import { openPopup } from '../actions'

const mapStateToProps = (state, ownProps) => {
  return {
    playerName: ownProps.playerName,
    foursomePosition: ownProps.foursomePosition,
    holeNumber: ownProps.holeNumber,
    holeData: ownProps.holeData,
    par: ownProps.par,
    scoreVerified: ownProps.scoreVerified,
    holeView: state.misc.holeView,
  }
}

const mapDispatchToProps = (dispatch) => ({
  openPopup: (playerName, foursomePosition, holeId, holeNumber, shots, isCompleted, par, scoreVerified) => dispatch(openPopup(playerName, foursomePosition, holeId, holeNumber, shots, isCompleted, par, scoreVerified)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HoleDataComponent)
