import { combineReducers } from 'redux'
import misc from './misc'
import filters from './filters'
import popup from './popup'

export default combineReducers({
  misc,
  filters,
  popup,
})
