import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MatchPlayBoardTable from './match_play_board_table'

class MatchPlayBoard extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className='inner-container'>
          <MatchPlayBoardTable matchLines={ this.props.matchLines } 
                            acknowledgedLines={ this.props.acknowledgedLines }
                            refreshUrl={ this.props.refreshUrl }
                            acknowedgedDate={ this.props.acknowedgedDate }
                            lastUpdatedDate={ this.props.lastUpdatedDate }
                            acknowledgeUrl={ this.props.acknowledgeUrl }
                            isTeam={ this.props.isTeam }
                            teamNames={ this.props.teamNames }
                            todayPoints={ this.props.todayPoints }
                            totalPoints={ this.props.totalPoints }
                            acknowledgedTodayPoints={ this.props.acknowledgedTodayPoints }
                            acknowledgedTotalPoints={ this.props.acknowledgedTotalPoints }/>
      </div>
    )
  }
}

MatchPlayBoard.propTypes = {
  matchLines: PropTypes.array,
  acknowledgedLines: PropTypes.array,
  refreshUrl: PropTypes.string,
  acknowedgedDate: PropTypes.string,
  lastUpdatedDate: PropTypes.string,
  acknowledgeUrl: PropTypes.string,
  isTeam: PropTypes.bool,
  teamNames: PropTypes.array,
  todayPoints: PropTypes.array,
  totalPoints: PropTypes.array,
  acknowledgedTodayPoints: PropTypes.array,
  acknowledgedTotalPoints: PropTypes.array,
}

export default MatchPlayBoard
