import { connect } from 'react-redux'

import {
  reorderCategories,
  reorderLeagues,
  closeCategory,
  toggleCategory,
} from '../actions'
import { getSortedVisibleCategoryIds } from '../selectors'
import CategoriesListComponent from '../components/categories_list_component'

const mapStateToProps = state => {
  const categoryIds = getSortedVisibleCategoryIds(state)
  return {
    categoryIds,
  }
}

const mapDispatchToProps = dispatch => ({
  reorderCategories: (...params) => dispatch(reorderCategories(...params)),
  reorderLeagues: (...params) => dispatch(reorderLeagues(...params)),
  closeCategory: id => dispatch(closeCategory(id)),
  toggleCategory: id => dispatch(toggleCategory(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CategoriesListComponent)
