import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
  GlgTableRow,
  GlgTableCell,
  GlgTooltip,
} from 'SharedComponents'

const TransactionsHistoryTableRow = ({ ...props }) => {
  let eventName
  if (props.eventUrl) {
    eventName = <a href={ props.eventUrl } target={ props.target }>{ props.event }</a>
  } else {
    eventName = props.event
  }
  
  return <GlgTableRow { ...props } isSortable={ false } key={ props.id }><GlgTableCell>{ eventName }</GlgTableCell><GlgTableCell><GlgTooltip tooltip={ props.description }><span>{ _.truncate(props.description, { length: 40 }) }</span></GlgTooltip></GlgTableCell><GlgTableCell>{ props.date_time }</GlgTableCell><GlgTableCell>{ props.amount }</GlgTableCell><GlgTableCell>{ props.status }</GlgTableCell><GlgTableCell><a href={ props.printReceiptUrl }>  { props.printReceiptUrl ? !window.I18n ? '' : window.I18n.t('player_activity.transaction_logs.print_receipt') : '-' }</a></GlgTableCell></GlgTableRow>
}

TransactionsHistoryTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  event: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  eventUrl: PropTypes.string,
  description: PropTypes.string.isRequired,
  date_time: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  printReceiptUrl: PropTypes.string,
  target: PropTypes.string,
}

export default TransactionsHistoryTableRow
