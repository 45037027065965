import { connect } from 'react-redux'

import { getCustomersList } from '../selectors'
import CustomerFilterComponent from '../components/filters/customer_filter_component'

const mapStateToProps = (state) => {
  const customers = getCustomersList(state)
  return {
    customers,
    activeCustomerId: state.misc.customerId,
  }
}

const mapDispatchToProps = () => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerFilterComponent)
