import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TetherComponent from 'react-tether'
import onClickOutside from 'react-onclickoutside'

class DropdownMenu extends Component {
  constructor(props) {
    super(props)
  }

  handleItemClick(e, callback) {
    if (callback) {
      e.preventDefault()
      callback()
    }
    if (this.props.onClickItem) {
      this.props.onClickItem()
    }
  }

  stringParameterize(str) {
    return str.trim().toLowerCase()
      .replace(/[^a-zA-Z0-9 -]/, '')
      .replace(/\s/g, '_')
  }

  render() {
    return <ul className="hover-dropdown"><li key="label"><div className="checkbox checkbox-primary no-margin"><b>{ !window.I18n ? '' : window.I18n.t('scorecards_center.scorecards_dropdown.select_categories') }</b></div></li>{this.props.report_center_lists.map((rcl) => <li key={ rcl.id }><div className="checkbox btsp_checkbox checkbox-primary no-margin"><input id={ 'dd_inline_' + this.props.report_center_item.id + '_' + rcl.id } type="checkbox" name="report_center_item[report_center_list_ids][]" onChange={(e) => { this.props.importDiscoverCenter(this.props.report_center_item.id, rcl.id, e) } } defaultChecked={rcl.report_center_items.map(rci => rci.parent_id).indexOf(this.props.report_center_item.id) !== -1 ? 'checked' : '' } /><label htmlFor={ 'dd_inline_' + this.props.report_center_item.id + '_' + rcl.id }>{ !window.I18n ? '' : window.I18n.t(`scorecards_center.scorecards_dropdown.categories_list.${this.stringParameterize(rcl.name)}`, {defaultValue: rcl.name}) }</label></div></li>)}</ul>
  }
}

DropdownMenu.propTypes = {
  report_center_lists: PropTypes.array.isRequired,
  report_center_item: PropTypes.object.isRequired,
  importDiscoverCenter: PropTypes.func.isRequired,
  onClickItem: PropTypes.func,
}

const WrappedDropdownMenu = onClickOutside(DropdownMenu)



class ScorecardsDropdownPublished extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.handleClickOutsideOfMenu = this.handleClickOutsideOfMenu.bind(this)
  }

  handleButtonClick() {
    this.setState({ isOpen: true })
  }

  handleClickOutsideOfMenu(e) {
    if (e.target.parentElement !== this.buttonRef) {
      this.setState({ isOpen: false })
    }
  }

  render() {
    const attachment = 'top center'
    const targetAttachment = 'bottom center'

    return <TetherComponent attachment={ attachment } className="scorecards_center" targetAttachment={ targetAttachment } offset="4px 0"><div><a className="btsp_orange_button dropdown-button dropdown-toggle dropdown_button_icon clickable" onClick={ this.handleButtonClick } ref={ buttonRef => { this.buttonRef = buttonRef } }>{ !window.I18n ? '' : window.I18n.t('scorecards_center.scorecards_dropdown.categories') }<span className="caret"></span></a></div>{this.state.isOpen && <WrappedDropdownMenu report_center_lists={ this.props.report_center_lists } report_center_item={ this.props.report_center_item } importDiscoverCenter={ this.props.importDiscoverCenter } handleClickOutside={ this.handleClickOutsideOfMenu } onClickItem={ this.handleButtonClick }></WrappedDropdownMenu>}</TetherComponent>
  }
}

ScorecardsDropdownPublished.propTypes = {
  // An array of options
  report_center_lists: PropTypes.array.isRequired,
  report_center_item: PropTypes.object.isRequired,
  importDiscoverCenter: PropTypes.func.isRequired,
  // The direction in which the dropdown opens: left or right
  direction: PropTypes.string,
}

ScorecardsDropdownPublished.defaultProps = {
  direction: 'right',
}

export default ScorecardsDropdownPublished
