/*
 * This component is used inside a form and it contains a validated text input.
 */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FormControl, FormGroup, HelpBlock } from 'react-bootstrap'

import GlgFormInput from './glg_form_input'

class GlgFormTextInput extends PureComponent {
  constructor(props) {
    super(props)
    this.onTextChange = this.onTextChange.bind(this)

    const text = props.defaultValue || ''
    this.state = {
      text,
      typedAny: false,
      validity: !_.isEmpty(text),
    }
  }

  componentDidMount() {
    this.updateValidity()
  }

  validateText() {
    if (this.state.text.length > 0) {
      return null
    } else {
      return 'error'
    }
  }

  getTextValidationState() {
    if (!this.state.typedAny) {
      return null
    } else {
      return this.validateText()
    }
  }

  onTextChange(e) {
    this.setState({
      text: e.target.value,
      typedAny: true,
    }, this.updateValidity)
  }

  updateValidity() {
    const oldValidity = this.state.validity
    const newValidity = this.validateText() === null
    if (oldValidity !== newValidity && this.props.onValidityChange) {
      this.props.onValidityChange(newValidity)
    }
    this.setState({
      validity: newValidity,
    })
  }

  render() {
    return <GlgFormInput label={ this.props.label } leftSideColumnsCount={ this.props.leftSideColumnsCount } description={ this.props.description } withoutBorder={this.props.withoutBorder}><FormGroup validationState={ this.getTextValidationState() }><FormControl name={ this.props.name } type="text" value={ this.state.text } onChange={ this.onTextChange } readOnly={ this.props.readOnly } autoFocus={ this.props.autoFocus }></FormControl><HelpBlock>{this.getTextValidationState() !== null && this.props.invalidText }</HelpBlock></FormGroup></GlgFormInput>
  }
}

GlgFormTextInput.propTypes = {
  // Text displayed on the left of the text input
  label: PropTypes.string,
  // Name attribute of the text input
  name: PropTypes.string.isRequired,
  // Text to be displayed unde the input when it's invalid
  invalidText: PropTypes.string,
  // Callback when the validity state of the input changes
  onValidityChange: PropTypes.func,
  // Initial value (optional)
  defaultValue: PropTypes.string,
  // If false, the text input will not be editable
  readOnly: PropTypes.bool,
  // If true, the text input will be focused after render
  autoFocus: PropTypes.bool,
  // The amount of bootstrap columns to distribute to the label (left) side
  leftSideColumnsCount: PropTypes.number,
  // If present it will add a description above the input
  description: PropTypes.shape({
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
  }),
  withoutBorder: PropTypes.bool,
}

GlgFormTextInput.defaultProps = {
  readOnly: false,
  autoFocus: false,
}

export default GlgFormTextInput
