import { connect } from 'react-redux'
import DrivingDistancesTabComponent from '../components/driving_distances_tab_component'


const mapStateToProps = (state) => {
  return {
    drivingStations: state.misc.drivingStations,
  }
}

const mapDispatchToProps = () => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrivingDistancesTabComponent)
