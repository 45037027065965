import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Download extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const report = this.props.report
    return (
      <div className='download'>
        <span className='report-close-download'
              onClick={ this.props.reportSetState } >
          <i className='fa fa-times'></i>
        </span>
        <span className='report-action'
              onClick={ () => window.open(report.job.pdfPath, '_blank') } >
          { report.job.pdfButtonLabel }
        </span>
        {
          report.job.primaryDocxService !== null
            ? report.job.docxPath !== undefined && report.job.docxPath !== null
              ? <span className='report-action'
                      onClick={ () => window.open(report.job.docxPath, '_blank') } >
                { !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.reports.download.download_doc') }
              </span>
              : <span className='report-action'
                      onClick={ this.props.reportGenerateDoc } >
                { !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.reports.download.generate_doc') }
              </span>
            : null
        }
        {
          report.job.generateTxt !== null
            ? report.job.txtPath !== undefined && report.job.txtPath !== null
              ? <span className='report-action'
                      onClick={ () => window.open(report.job.txtPath, '_blank') } >
                { !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.reports.download.download_txt') }
              </span>
              : <span className='report-action'
                      onClick={ this.props.reportGenerateTxt } >
                { !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.reports.download.generate_txt') }
              </span>
            : null
        }
      </div>
    )
  }
}

Download.propTypes = {
  report: PropTypes.object,
  reportSetState: PropTypes.func,
  reportGenerateDoc: PropTypes.func,
  reportGenerateTxt: PropTypes.func,
}


Download.defaultProps = {
  report: {},
}

export default Download
