import {ActionTypes} from '../actions/manager_side_index'

const {MOVE_TO_NOW_PICKING, DRAFT_GOLFER, UNDO_LAST_PICK, SAVE_IN_PROGRESS, REMOVE_FROM_NOW_PICKING} = ActionTypes

const initialState = {
  openModal: false,
  saveInProgress: false,
}

const draftManagerSide = (state = initialState, action) => {
  const pickedGolfers = state.picks?.map(p => p.golfer?.id)?.filter(id => id)

  switch (action.type) {
    case MOVE_TO_NOW_PICKING:
      return {
        ...state,
        availableGolfers: state.golfers.filter(g => !pickedGolfers.includes(g.id) && g.id !== action.data.id),
        nowPicking: {
          ...state.nowPicking,
          golfer: action.data,
        },
      }
    case REMOVE_FROM_NOW_PICKING:
      return {
        ...state,
        availableGolfers: state.golfers.filter(g => !pickedGolfers.includes(g.id)),
        nowPicking: {
          ...state.nowPicking,
          golfer: null,
        },
      }
    case DRAFT_GOLFER: {
      const newState = {
        ...state,
        picks: state.picks.map((pick, idx) => {
          if (idx === state.pickingPos) {
            return {
              ...pick,
              golfer: state.nowPicking.golfer,
            }
          }
          return pick
        }),
        pickingPos: state.pickingPos + 1,
        lastPick: state.nowPicking,
        saveInProgress: false,
      }

      if (pickedGolfers.length < state.golfers.length - 1) {
        newState['nowPicking'] = state.picks[state.pickingPos + 1]
        newState['upcomingPicks'] = state.picks.slice(state.pickingPos + 2, state.pickingPos + 5)
      } else {
        newState['openModal'] = true
      }

      return newState
    }
    case UNDO_LAST_PICK:
      return {
        ...state,
        availableGolfers: state.golfers.filter(g => !pickedGolfers.includes(g.id) && g.id !== state.lastPick.golfer.id),
        picks: state.picks.map((pick, idx) => {
          if (idx === state.pickingPos - 1) {
            return {
              ...pick,
              golfer: null,
            }
          }
          return pick
        }),
        pickingPos: state.pickingPos - 1,
        nowPicking: state.lastPick,
        lastPick: state.pickingPos === 1 ? null : state.picks[state.pickingPos - 2],
        upcomingPicks: state.picks.slice(state.pickingPos, state.pickingPos + 3),
        saveInProgress: false,
      }
    case SAVE_IN_PROGRESS:
      return {
        ...state,
        saveInProgress: true,
      }
    default:
      return state
  }
}

export default draftManagerSide
