import _ from 'lodash'
import { getPlayersOnMonsterBoard, getPlayersOnBackSide, getPlayersOnFrontSide } from 'Shared/course_boards'
import { showNotification, showErrorNotification, callAPI } from 'Shared/helpers'

export const ActionTypes = {
  CHANGE_COLUMN_SORTING: 'CHANGE_COLUMN_SORTING',
  ADD_LINE: 'ADD_LINE',
  REMOVE_LINE: 'REMOVE_LINE',
  REORDER_LINES: 'REORDER_LINES',
  REORDER_SIDE_LINES: 'REORDER_SIDE_LINES',
  SUBMIT_MONSTER_BOARD: 'SUBMIT_MONSTER_BOARD',
  HANDLE_SHOW_MESSAGE_CHANGED: 'HANDLE_SHOW_MESSAGE_CHANGED',
  HANDLE_MESSAGE_CHANGED: 'HANDLE_MESSAGE_CHANGED',
  HANDLE_SCORE_CHANGED: 'HANDLE_SCORE_CHANGED',
  REFRESH_DATA: 'REFRESH_DATA',
  TOOGLE_SHOW_ROUNDS: 'TOGGLE_SHOW_ROUNDS',
  TOGGLE_SHOW_TOTAL: 'TOGGLE_SHOW_TOTAL',
}

export const refreshDataAction = (data) => ({
  type: ActionTypes.REFRESH_DATA,
  data,
})

export const submitMonsterBoardAction = (data) => ({
  type: ActionTypes.SUBMIT_MONSTER_BOARD,
  data,
})

export const changeColumnSortingAction = (column, sortOrder) => ({
  type: ActionTypes.CHANGE_COLUMN_SORTING,
  column,
  sortOrder,
})

export const addLineAction = (memberIds, position, removeMemberIds) => ({
  type: ActionTypes.ADD_LINE,
  memberIds,
  position,
  removeMemberIds,
})

export const removeLineAction = (position, front9) => ({
  type: ActionTypes.REMOVE_LINE,
  position,
  front9,
})

export const reorderLinesAction = (fromPosition, toPosition, from, to, offset) => ({
  type: ActionTypes.REORDER_LINES,
  fromPosition,
  toPosition,
  from,
  to,
  offset,
})

export const reorderSideLinesAction = (fromPosition, toPosition, from, to, offset, front9) => ({
  type: ActionTypes.REORDER_SIDE_LINES,
  fromPosition,
  toPosition,
  from,
  to,
  offset,
  front9,
})

export const handleShowMessageChangedAction = (value) => ({
  type: ActionTypes.HANDLE_SHOW_MESSAGE_CHANGED,
  value,
})

export const handleMessageChangedAction = (value) => ({
  type: ActionTypes.HANDLE_MESSAGE_CHANGED,
  value,
})

export const handleScoreChangedAction = (value) => ({
  type: ActionTypes.HANDLE_SCORE_CHANGED,
  value,
})

export const toggleShowRoundsAction = (value) => ({
  type: ActionTypes.TOOGLE_SHOW_ROUNDS,
  value,
})

export const toggleShowTotalAction = (value) => ({
  type: ActionTypes.TOGGLE_SHOW_TOTAL,
  value,
})

export const changeColumnSorting = (column, sortOrder) => (dispatch) => {
  dispatch(changeColumnSortingAction(column, sortOrder))
}

export const addLine = (memberIds, front9) => (dispatch, getState) => {
  const state = getState()
  const deviceView = state.misc.deviceView
  let lines
  if (deviceView === 'hole-by-hole') {
    lines = getPlayersOnMonsterBoard(state)
  } else if (front9 === true) {
    lines = getPlayersOnFrontSide(state)
  } else {
    lines = getPlayersOnBackSide(state)
  }

  const emptyLine = lines.find( line => line.empty === true)
  let position
  let removeMemberIds
  if (emptyLine === undefined) {
    position = 9
    removeMemberIds = _.last(lines).memberIds
  } else {
    position = emptyLine.position
    removeMemberIds = null
  }
  dispatch(addLineAction(memberIds, position, removeMemberIds)) 
}

export const removeLine = (position, front9) => (dispatch) => {
  dispatch(removeLineAction(position, front9))
}

export const reorderLines = (fromPosition, toPosition) => (dispatch) => {
  let from, to, offset
  if (fromPosition < toPosition) {
    from = fromPosition + 1
    to = toPosition
    offset = -1
  } else {
    from = toPosition
    to = fromPosition - 1
    offset = 1
  }
  dispatch(reorderLinesAction(fromPosition, toPosition, from, to, offset))
}

export const reorderSideLines = (fromPosition, toPosition, front9) => (dispatch) => {
  let from, to, offset
  if (fromPosition < toPosition) {
    from = fromPosition + 1
    to = toPosition
    offset = -1
  } else {
    from = toPosition
    to = fromPosition - 1
    offset = 1
  }
  dispatch(reorderSideLinesAction(fromPosition, toPosition, from, to, offset, front9))
}

export const handleShowMessageChanged = (value) => (dispatch) => {
  dispatch(handleShowMessageChangedAction(value))
}

export const handleMessageChanged = (value) => (dispatch) => {
  dispatch(handleMessageChangedAction(value))
}

export const handleScoreChanged = (value) => (dispatch) => {
  dispatch(handleScoreChangedAction(value))
}

export const submitMonsterBoard = () => (dispatch, getState) => {
  const state = getState()
  const deviceView = state.misc.deviceView
  const url = state.misc.submitUrl
  const withTeams = state.misc.withTeams
  const params = {}
  let lines

  if (deviceView === 'hole-by-hole') {
    lines = getPlayersOnMonsterBoard(state)
  } else {
    const frontLines = getPlayersOnFrontSide(state)
    const backLines = getPlayersOnBackSide(state)
    lines = frontLines.concat(backLines)
  }

  params['show_message'] = state.misc.showMessage
  params['message'] = state.misc.message
  params['score'] = state.misc.score
  params['show_course_abbreviation'] = state.misc.showCourse
  params['show_rounds'] = state.misc.showRounds
  params['show_total'] = state.misc.showTotal
  params['rounds_count'] = state.misc.roundsCount

  lines.map( line => {
    const side = deviceView === 'hole-by-hole' || line.front9 ? 'front' : 'back'
    params[`lines[${side}][${line.position}][member_ids]`] = line.memberIds || ''
    params[`lines[${side}][${line.position}][name]`] = line.empty ? '' : withTeams ? line.name : line.lastName + ', ' + line.firstName.charAt(0)
    params[`lines[${side}][${line.position}][asterisk]`] = line.asterisk || ''
    params[`lines[${side}][${line.position}][today]`] = line.today || ''
    params[`lines[${side}][${line.position}][total_par]`] = line.total || ''
    params[`lines[${side}][${line.position}][total]`] = line.totalScore || ''
    params[`lines[${side}][${line.position}][thru]`] = line.hole || ''
    params[`lines[${side}][${line.position}][course_abbreviation]`] = line.course || ''
    params[`lines[${side}][${line.position}][pars][]`] = line.pars || ''
    params[`lines[${side}][${line.position}][past_rounds_scores][]`] = line.roundsScores || ''
  })
  
  callAPI(url, 'PUT', params, new Headers({ 'Accept': 'application/json' }))
    .then( (response) => {
      dispatch(submitMonsterBoardAction(response))
      showNotification('Monster board successfully submitted!')
    })
    .catch(error => {
      if ( error !== undefined ) {
        showErrorNotification(error)
      } else {
        showErrorNotification('Error while submitting mosnter board!')
      }
    })
}

export const toggleShowRounds = () => (dispatch, getState) => {
  const state = getState()
  const showRounds = state.misc.showRounds

  dispatch(toggleShowRoundsAction(!showRounds))
}

export const toggleShowTotal = () => (dispatch, getState) => {
  const state = getState()
  const showTotal = state.misc.showTotal

  dispatch(toggleShowTotalAction(!showTotal))
}

export const refreshData = () => (dispatch, getState) => {
  const state = getState()
  const url = state.misc.refreshUrl
  callAPI(url, 'GET', {})
    .then(response => {
      if (response.players === undefined || (response.players.length === 0 && state.misc.players.length > 0)) {
        retryRefresh(state, dispatch)
      } else {
        dispatch(refreshDataAction(response)) 
      }
    })
    .catch(() => {
      showErrorNotification('Error while refreshing data!')
    })
}

const retryRefresh = (state, dispatch) => {
  const url = state.misc.refreshUrl
  setTimeout( () => {
    callAPI(url, 'GET', {})
      .then(response => {
        if (response.players !== undefined && response.players.length > 0) {
          dispatch(refreshDataAction(response))
        }
      })
      .catch(() => {
        showErrorNotification('Error while refreshing data!')
      })
  }, 5000)
}
