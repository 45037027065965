import React from 'react'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const GlgTimer = ({seconds, onExpiration}) => {
  const [ minutesDisplay, setMinutesDisplay ] = useState( Math.floor(seconds / 60))
  const [ secondsDisplay, setSecondsDisplay ] = useState(seconds % 60)

  useEffect(() => {
    let min = Math.floor(seconds / 60)
    let sec = seconds % 60

    const interval = setInterval(() => {
      if (sec === 0) {
        if (min !== 0) {
          sec = 59
          setSecondsDisplay(59)
          min -= 1
          setMinutesDisplay(min)
        } else {
          onExpiration()
          clearInterval(interval)
        }
      } else {
        sec -= 1
        setSecondsDisplay(sec)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div>
      {minutesDisplay === 0 && secondsDisplay === 0
        ? null
        : 
        <>
          <span>
            <i className='fa fa-hourglass-start'></i>
            {minutesDisplay}:{secondsDisplay < 10 ? `0${secondsDisplay}` : secondsDisplay}
          </span>
        </>
      }
    </div>
  )
}

GlgTimer.propTypes = {
  seconds: PropTypes.number,
  onExpiration: PropTypes.func,
}

export default GlgTimer
