

export const ActionTypes = {
  SELECT_SLOT: 'SELECT_SLOT',
  UNSELECT_SLOT: 'UNSELECT_SLOT',
  CHECK_SLOT: 'CHECK_SLOT',
  UNCHECK_SLOT: 'UNCHECK_SLOT',
  SET_ACTIVE_SCREEN: 'SET_ACTIVE_SCREEN',
  MOVE_HERE: 'MOVE_HERE',
  SELECT_MOVE_HERE: 'SELECT_MOVE_HERE',
  UNSELECT: 'UNSELECT',
  SELECT_ALL: 'SELECT_ALL',
  UNSELECT_ALL: 'UNSELECT_ALL',
  SWITCH_PLAYERS: 'SWITCH_PLAYERS',
  REINITIALISE: 'REINITIALISE',
  UPDATE: 'UPDATE',
  CLOSE_UPDATE_ERROR: 'CLOSE_UPDATE_ERROR',
  TOGGLE_REFRESH_QUEUE: 'TOGGLE_REFRESH_QUEUE',
  TOGGLE_SWAP_MODE: 'TOGGLE_SWAP_MODE',
  TOGGLE_REFRESH_STATUS_BAR: 'TOGGLE_REFRESH_STATUS_BAR',
  REFRESH_SESSION_START_TIME: 'REFRESH_SESSION_START_TIME',
  HIDE_MOBILE_SCROLL: 'HIDE_MOBILE_SCROLL',
}

export const selectSlot = (slotId, position) => {
  return {
    type: ActionTypes.SELECT_SLOT,
    payload: {slotId, position},
  }
}

export const unselectSlot = (slotId, position) => {
  return {
    type: ActionTypes.UNSELECT_SLOT,
    payload: {slotId, position},
  }
}

export const checkSlot = (slotId, position) => {
  return {
    type: ActionTypes.CHECK_SLOT,
    payload: {slotId, position},
  }
}

export const uncheckSlot = (slotId, position) => {
  return {
    type: ActionTypes.UNCHECK_SLOT,
    payload: {slotId, position},
  }
}

export const setActiveScreen = (screen) => {
  return {
    type: ActionTypes.SET_ACTIVE_SCREEN,
    payload: {screen},
  }
}

export const moveHere = (foursomeId, oldSlot) => {
  return {
    type: ActionTypes.MOVE_HERE,
    payload: {foursomeId, oldSlot},
  }
}

export const selectMoveHere = (foursomeId, oldSlot, position) => {
  return {
    type: ActionTypes.SELECT_MOVE_HERE,
    payload: {foursomeId, oldSlot, position},
  }
}

//uncheck
export const unselect = (slotId) => {
  return {
    type: ActionTypes.UNSELECT,
    payload: {slotId},
  }
}

export const unselectALL = (slotId) => {
  return {
    type: ActionTypes.UNSELECT_ALL,
    payload: {slotId},
  }
}

export const selectAll = (slotId) => {
  return {
    type: ActionTypes.SELECT_ALL,
    payload: {slotId},
  }
}

export const switchPlayers = (id1, p1, id2, p2) => {
  return {
    type: ActionTypes.SWITCH_PLAYERS,
    payload: {id1, p1, id2, p2},
  }
}

export const reinitialise = (newState) => {
  return {
    type: ActionTypes.REINITIALISE,
    payload: {newState},
  }
}

export const update = (newState) => {
  return {
    type: ActionTypes.UPDATE,
    payload: {newState},
  }
}

export const closeUpdateError = (toggle) => {
  return {
    type: ActionTypes.CLOSE_UPDATE_ERROR,
    payload: {toggle},
  }
}

export const toggleRefreshQueue = (toggle) => {
  return {
    type: ActionTypes.TOGGLE_REFRESH_QUEUE,
    payload: {toggle},
  }
}


export const refreshSessionStartTime = (toggle) => {
  return {
    type: ActionTypes.REFRESH_SESSION_START_TIME,
    payload: {toggle},
  }
}

export const toggleSwapMode = (toggle) => {
  return {
    type: ActionTypes.TOGGLE_SWAP_MODE,
    payload: {toggle},
  }
}

export const toggleRefreshStatusBar = (toggle) => {
  return {
    type: ActionTypes.TOGGLE_REFRESH_STATUS_BAR,
    payload: {toggle},
  }
}

export const hideMobileScroll = () => {
  return {
    type: ActionTypes.HIDE_MOBILE_SCROLL,
    payload: {},
  }
}
