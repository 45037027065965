import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ThruBoardTable extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { title, columns, data } = this.props.content

    return (
      <table className='table-striped tables_v2'>
        <thead>
          <tr>
            <th colSpan={3}>{ title }</th>
          </tr>
          <tr>
            <th>{ columns.today }</th>
            <th>{ columns.name }</th>
            <th>{ columns.total }</th>
          </tr>
        </thead>
        <tbody>
            { data.map(rowData => <tr key={ Math.random() }>
              <td className={ rowData.today.par }>{ rowData.today.score }</td>
              <td className='player-name'>{ rowData.name }</td>
              <td className={ rowData.total.par }>{ rowData.total.score }</td>
            </tr>) }
        </tbody>
      </table>
    )
  }
}

ThruBoardTable.propTypes = {
  content: PropTypes.object,
}

export default ThruBoardTable
