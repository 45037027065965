import { connect } from 'react-redux'
import FieldsComponent from '../../components/sidebar_content/fields_component'
import {
  fetchFieldsData, setMemberColumns, checkMemberField, uncheckMemberField, swapFields,
  openDeleteRosterConfirmationPopup, getDeleteRosterConfirmationAnswer, closeFieldsSidebar } from '../../actions/index'

const mapStateToProps = (state) => ({
  leftFields: state.leftSidebarFields.leftFields,
  rightFields: state.leftSidebarFields.rightFields,
  isLoading: state.leftSidebarFields.isLoading,
  isMembersLoading: state.membersData.isLoading,
  membershipPrograms: state.membersData.membershipPrograms,
  cannotDeleteRoster: state.leftSidebarFields.cannotDeleteRoster,
  indexType: state.misc.indexType,
  prettyHandicapMethod: state.misc.prettyHandicapMethod,
  showHandleTooltip: state.leftSidebarFields.showHandleTooltip,
  showLastNameTooltip: state.leftSidebarFields.showLastNameTooltip,
})

const mapDispatchToProps = (dispatch) => ({
  fetchFieldsData: () => dispatch(fetchFieldsData()),
  setMemberColumns: (data) => dispatch(setMemberColumns(data)),
  checkMemberField: (field) => dispatch(checkMemberField(field)),
  uncheckMemberField: (field) => dispatch(uncheckMemberField(field)),
  swapFields: (sourceIndex, destinantionIndex) => dispatch(swapFields(sourceIndex, destinantionIndex)),
  openDeleteRosterConfirmationPopup: () => dispatch(openDeleteRosterConfirmationPopup()),
  getDeleteRosterConfirmationAnswer: () => dispatch(getDeleteRosterConfirmationAnswer()),
  closeFieldsSidebar: () => dispatch(closeFieldsSidebar()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FieldsComponent)
