import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Accordion from 'SharedComponents/glg_accordion'
import ChannelsContainer from '../containers/channels_container'
import TriggersContainer from '../containers/triggers_container'
import RecipientListContainer from '../containers/recipient_list_container'
import ScheduleContainer from '../containers/schedule_container'
import PreviewComponent from './preview_component'
import { FormControl } from 'react-bootstrap'
import { Row, Col } from 'react-flexbox-grid'


class MarketingCampaignsSetupComponent extends Component {
  constructor(props){
    super(props)

    this.state = {
      updateCount: 0,
      recipientsCount: this.updateRecipientsCount(),
    }

    this.buildMarketingCampaign = this.buildMarketingCampaign.bind(this)
    this.refreshPreview = this.refreshPreview.bind(this)
    this.submitDataRequest = this.submitDataRequest.bind(this)
    this.updateRecipientsCount = this.updateRecipientsCount.bind(this)
  }

  submitDataRequest(submitUrl, submitMethod) {
    const {
      customerId,
    } = this.props

    $.ajax({
      url: submitUrl,
      data: this.buildMarketingCampaign(),
      type: submitMethod,
      success: () => {
        // eslint-disable-next-line no-console
        console.log('Success')
        window.location = '/customers/' + customerId + '/marketing_campaigns'
      },
      error: () => {
        // eslint-disable-next-line no-console
        console.log('Error')
      },
    })
  }

  buildMarketingCampaign() {
    const stateBoundMarketingCampaign = {
      name: this.props.general.name,
      channels: this.props.channels,
      trigger: this.props.triggers,
      recipientList: this.props.recipientList,
      schedule: this.props.schedule,
    }
    // deep copy marketingCampaign, so that it doesn't interfere with Redux state
    const marketingCampaign = JSON.parse(JSON.stringify(stateBoundMarketingCampaign))

    Object.keys(marketingCampaign).forEach( sectionType => {
      Object.keys(marketingCampaign[sectionType]).forEach( sectionSetting => {
        if (marketingCampaign[sectionType][sectionSetting].checked !== undefined) {
          marketingCampaign[sectionType][sectionSetting].checked ?
            delete marketingCampaign[sectionType][sectionSetting].checked :
            delete marketingCampaign[sectionType][sectionSetting]
        }
      })
    })

    if (marketingCampaign.channels.email){
      delete marketingCampaign.channels.email.allTemplates
    }
    if (marketingCampaign.channels.textMessage) {
      delete marketingCampaign.channels.textMessage.allTemplates
      if (marketingCampaign.trigger.afterRoundEnd !== undefined) {
        delete marketingCampaign.trigger.afterRoundEnd
      }
      if (marketingCampaign.trigger.afterEventEnd !== undefined) {
        delete marketingCampaign.trigger.afterEventEnd
      }
    }
    if (marketingCampaign.trigger.afterRoundEnd === undefined) {
      if (marketingCampaign.recipientList.roundAttendance) {
        delete marketingCampaign.recipientList.roundAttendance
      }
    }
    if (marketingCampaign.trigger.afterRoundEnd !== undefined || marketingCampaign.trigger.afterEventEnd !== undefined) {
      if (marketingCampaign.recipientList.membershipProgram) {
        delete marketingCampaign.recipientList.membershipProgram
      }
    }
    return marketingCampaign
  }

  refreshPreview() {
    this.updateRecipientsCount()
    this.refreshPreviewComponent()
  }

  refreshPreviewComponent() {
    if (this.props.channels.email.checked || this.props.channels.textMessage.checked) {
      this.setState({
        ...this.state,
        updateCount: this.state.updateCount + 1,
      })
    }
  }

  updateRecipientsCount() {
    $.ajax({
      url: '/customers/' + this.props.customerId + '/marketing_campaigns/matching_recipients_count',
      type: 'POST',
      data: this.buildMarketingCampaign(),
      success: (response) => {
        // eslint-disable-next-line no-console
        console.log('Success')
        this.setState({
          ...this.state,
          recipientsCount: response.matching_recipients_count,
        })
      },
      error: () => {
        // eslint-disable-next-line no-console
        console.log('Error recipients count')
      },
    })
  }

  render() {
    const {
      paths,
      setName,
      leagueCategories,
      membershipPrograms,
      customFields,
      customerCanTextMessage,
      customerCanUseMembershipPrograms,
      general: {
        name,
      },
    } = this.props

    const previewPaths = {
      email: paths.emailPreview,
      textMessage: paths.textMessagePreview,
    }

    const channelsIcon = require('marketing_campaigns/channels.svg')
    const triggersIcon = require('marketing_campaigns/triggers.svg')
    const recipientListIcon = require('marketing_campaigns/recipient_list.svg')
    const scheduleIcon = require('marketing_campaigns/schedule.svg')
    return <div className="container_fluid"><div className="min_height_main_content"><div className="page_title"><div className="text after_text_1">{`${paths.save ? window.I18n.t('marketing_campaigns.components.marketing_campaigns_setup_component.new') :
                    window.I18n.t('marketing_campaigns.components.marketing_campaigns_setup_component.edit')} 
                    ${window.I18n.t('marketing_campaigns.components.marketing_campaigns_setup_component.campaign')}`}</div></div><div id="marketing_campaign_content"><Row className="vertical-align"><Col xs={ 3 } sm={ 1 }><label>{ window.I18n.t('marketing_campaigns.components.marketing_campaigns_setup_component.name') }</label></Col><Col xs={ 9 } sm={ 4 } lg={ 3 }><FormControl type="text" name="marketing_campaign_name" value={ name } onChange={ (e) => { setName(e.target.value) } } autoFocus={ true }></FormControl></Col></Row><hr /><Accordion><ChannelsContainer label={ window.I18n.t('marketing_campaigns.components.marketing_campaigns_setup_component.channels') } iconPath={ channelsIcon } manageEmailTemplatesPath={ paths.manageEmailTemplates } manageTextMessageTemplatesPath={ paths.manageTextMessageTemplates } customerId={this.props.customerId} customerCanTextMessage={ customerCanTextMessage }></ChannelsContainer><TriggersContainer label={ window.I18n.t('marketing_campaigns.components.marketing_campaigns_setup_component.triggers') } iconPath={ triggersIcon } leagueCategories={ leagueCategories }></TriggersContainer><RecipientListContainer label={ window.I18n.t('marketing_campaigns.components.marketing_campaigns_setup_component.recipient_list') } iconPath={ recipientListIcon } membershipPrograms={ membershipPrograms } customFieldsAndAnswers={ customFields } customerCanUseMembershipPrograms={ customerCanUseMembershipPrograms }></RecipientListContainer><ScheduleContainer label={ window.I18n.t('marketing_campaigns.components.marketing_campaigns_setup_component.schedule') } iconPath={ scheduleIcon }></ScheduleContainer></Accordion><hr /><PreviewComponent marketingCampaign={ this.buildMarketingCampaign() } previewPaths={ previewPaths } updateCount={ this.state.updateCount }></PreviewComponent></div><div className="save_bar"><a href={ paths.cancel } className="btsp_gray_button">{ !window.I18n ? '' : window.I18n.t('buttons.cancel').charAt(0)
                  .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.cancel').slice(1)) }</a>{ paths.save && paths.saveAndSchedule && <Fragment><a onClick={ () => { this.submitDataRequest(paths.saveAndSchedule, 'POST') } } className="btsp_orange_button">{ window.I18n.t('marketing_campaigns.components.marketing_campaigns_setup_component.save_and_schedule') }</a><a onClick={ () => { this.submitDataRequest(paths.save, 'POST') } } className="btsp_orange_button">{ !window.I18n ? '' : window.I18n.t('buttons.save').charAt(0)
                    .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.save').slice(1)) }</a></Fragment>}{ paths.update && <a onClick={ () => { this.submitDataRequest(paths.update, 'PUT') } } className="btsp_orange_button">{ window.I18n.t('marketing_campaigns.components.marketing_campaigns_setup_component.update') }</a>}<a onClick={ this.refreshPreview } className="btsp_gray_button float-right">{ window.I18n.t('marketing_campaigns.components.marketing_campaigns_setup_component.preview') }</a>{
                this.state.recipientsCount
                && <span className="recipients-count"><label className="label label-warning">{ this.state.recipientsCount }{ window.I18n.t('marketing_campaigns.components.marketing_campaigns_setup_component.matching_golfers') }</label></span>
              }</div></div></div>
  }
}

MarketingCampaignsSetupComponent.propTypes = {
  paths: PropTypes.shape({
    cancel: PropTypes.string.isRequired,
    emailPreview: PropTypes.string.isRequired,
    textMessagePreview: PropTypes.string.isRequired,
    save: PropTypes.string,
    saveAndSchedule: PropTypes.string,
    update: PropTypes.string,
    manageEmailTemplates: PropTypes.string.isRequired,
    manageTextMessageTemplates: PropTypes.string.isRequired,
  }),
  channels: PropTypes.object.isRequired,
  triggers: PropTypes.object.isRequired,
  recipientList: PropTypes.object.isRequired,
  schedule: PropTypes.object.isRequired,
  setName: PropTypes.func.isRequired,
  general: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  leagueCategories: PropTypes.array.isRequired,
  membershipPrograms: PropTypes.array.isRequired,
  customFields: PropTypes.object.isRequired,
  customerId: PropTypes.string.isRequired,
  customerCanTextMessage: PropTypes.bool.isRequired,
  customerCanUseMembershipPrograms: PropTypes.bool.isRequired,
}

export default MarketingCampaignsSetupComponent
