import { connect } from 'react-redux'
import EditColumns from '../presentational/edit_columns'
import {
  createColumn,
  setActiveBlock,
  setActivePosition,
  displayMode,
  DISPLAY_MODES,
} from '../../actions'

const mapStateToProps = state => ({
  widgets: state.filters.widgets,
  blockID: state.filters.active_block,
  position: state.filters.position,
  usesTranslations: state.filters.uses_translations,
  availableLanguages: state.filters.available_languages,
  currentLanguage: state.filters.current_language,
  // column: state.columns.find(column => column.id === state.filters.active_column),
})

const mapDispatchToProps = dispatch => ({
  cancel: () => {
    dispatch(setActivePosition(null))
    dispatch(setActiveBlock(null))
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
  createColumn: (_blockID, _url, _position, _title, _titles, _viewMore) => {
    dispatch(createColumn(_blockID, _url, _position, _title, _titles, _viewMore))
    dispatch(setActivePosition(null))
    dispatch(setActiveBlock(null))
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
  addNewPage: () => { dispatch(displayMode(DISPLAY_MODES.NEW_PAGE)) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditColumns)
