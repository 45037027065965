import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'
import SideBySideRowComponent from './side_by_side_row_component'

import { GlgTable, GlgTableCol, GlgCheckbox } from 'SharedComponents'
import { getColumnName, getColumnWidth } from 'Shared/course_boards'
import GlgTextInput from '../../shared/components/glg_text_input'

class SideBySideTableComponent extends Component {
  constructor(props) {
    super(props)
  }

  renderFooter() {
    const { showMessage, message, score, handleShowMessageChanged,
      handleScoreChanged, handleMessageChanged,
    } = this.props

    return (
      <Col xs={12} sm={12} md={12} lg={12} className='side-by-side-footer'>
        <Col xs={1} sm={1} md={1} lg={1} className='no-padding checkbox-cell'>
          <GlgCheckbox name='show-message'
                    checked={showMessage}
                    onChange={(checked) => handleShowMessageChanged(checked)}/>
        </Col>
        <Col xs={7} sm={8} md={8} lg={8} className='message-cell no-padding'>
          <span>Message:</span>
          <GlgTextInput placeholder='' value={message} onChange={(value) => handleMessageChanged(value)}/>
        </Col>
        <Col xs={4} sm={3} md={3} lg={3} className='score-cell no-padding'>
          <span>Score:</span>
          <GlgTextInput placeholder='' value={score} onChange={(value) => handleScoreChanged(value)}/>
        </Col>
      </Col>
    )
  }

  render() {
    const { columns, frontLines, backLines, removeLine, reorderFrontLines, reorderBackLines } = this.props
    const rowExtraProps = {
      columns: columns,
      removeLine: removeLine,
    }
    const footer = this.renderFooter()

    return (
      <Fragment>
        <Col xs={12} sm={12} md={12} lg={7} className='no-padding design_area'>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className='page_title'>
              <div className='text'>Monster Leaderboard Design</div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className='text-center side-header no-padding-right'>
            <div>Front 9 Start</div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className='text-center side-header no-padding-left'>
            <div>Back 9 Start</div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className='side_by_side_monster_table no-padding-right left-table'>
            <GlgTable items={frontLines}
                      rowComponent={ SideBySideRowComponent }
                      rowExtraProps={ rowExtraProps }
                      maxHeight={ 466 }
                      isSortable={true}
                      onSortEnd={reorderFrontLines}
                      showFooter={false}>
              {
                columns.map( column => (
                  <GlgTableCol key={column} width={getColumnWidth(column)}>
                    {getColumnName(column)}
                  </GlgTableCol>
                ))
              }
            </GlgTable>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className='side_by_side_monster_table no-padding-left right-table'>
            <GlgTable items={backLines}
                      rowComponent={ SideBySideRowComponent }
                      rowExtraProps={ rowExtraProps }
                      maxHeight={ 466 }
                      isSortable={true}
                      onSortEnd={reorderBackLines}
                      showFooter={false}>
              {
                columns.map( column => (
                  <GlgTableCol key={column} width={getColumnWidth(column)}>
                    {getColumnName(column)}
                  </GlgTableCol>
                ))
              }
            </GlgTable>
          </Col>
          { footer }
        </Col>
      </Fragment>
    )
  }
}

SideBySideTableComponent.propTypes = {
  isMultiRound: PropTypes.bool,
  showCourse: PropTypes.bool,
  frontLines: PropTypes.array,
  backLines: PropTypes.array,
  columns: PropTypes.array,
  showMessage: PropTypes.bool,
  message: PropTypes.string,
  score: PropTypes.string,
  removeLine: PropTypes.func,
  reorderBackLines: PropTypes.func,
  reorderFrontLines: PropTypes.func,
  handleShowMessageChanged: PropTypes.func,
  handleScoreChanged: PropTypes.func,
  handleMessageChanged: PropTypes.func,
}

export default SideBySideTableComponent
