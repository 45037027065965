import { connect } from 'react-redux'
import CourseLeaderboardComponent from '../components/course_leaderboard_component'

import { submitCourseLeaderboard, refreshData } from '../actions'
import { getPlayersOnBoard } from 'Shared/course_boards'

const mapStateToProps = (state) => {
  const lines = getPlayersOnBoard(state)

  return {
    isMultiRound: state.misc.isMultiRound,
    lines: lines,
    roundGgid: state.misc.roundGgid,
    showMessage: state.misc.showMessage,
    message: state.misc.message,
    score: state.misc.score,
    unSavedChanges: state.misc.unSavedChanges,
    lastDeviceUpdate: state.misc.lastDeviceUpdate,
  }
}

const mapDispatchToProps = (dispatch) => ({
  submitCourseLeaderboard: () => dispatch(submitCourseLeaderboard()),
  refreshData: () => dispatch(refreshData()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseLeaderboardComponent)
