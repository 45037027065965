import React from 'react'
import PropTypes from 'prop-types'

const ProfilePictureComponent = ({playerName, profileUrl}) => {

  return (
    <div key={playerName} className="business_card with_link">
      <div className="player-photo-and-name compact">
        <img src={profileUrl ? profileUrl : require('home/logos/default_logo_large.png')}/>
        <div className="name-and-link" style={{marginTop: '10px'}}>
          <div>
            <strong>
              {playerName}
            </strong>
          </div>
        </div>
      </div>
    </div>
  )
}

ProfilePictureComponent.propTypes = {
  playerName: PropTypes.string,
  profileUrl: PropTypes.string,
}

export default ProfilePictureComponent
