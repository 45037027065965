import { ActionTypes } from '../actions'
const {
  FETCH_INITIAL_DATA_REQUEST,
  FETCH_INITIAL_DATA_RECEIVED,
  CHANGE_DEFAULT_COURSE,
  GET_LEAGUES_FIELD_DATA,
  GET_LEAGUE_POTENTIAL_CLONE_CANDIDATE,
} = ActionTypes

const initialMisc = {
}

const misc = (state = initialMisc, action) => {
  let defaultCourseId = null
  switch (action.type) {
    case FETCH_INITIAL_DATA_REQUEST:
      return {
        ...state,
        dataIsLoading: true,
      }
    case FETCH_INITIAL_DATA_RECEIVED:
      if (action.mainData){
        if (action.data.default_courses[Object.keys(action.data.default_courses)[0]]){
          defaultCourseId = action.data.default_courses[Object.keys(action.data.default_courses)[0]].id
        }
      } else {
        if (action.data.managedCustomerData.default_courses[Object.keys(action.data.managedCustomerData.default_courses)[0]]){
          defaultCourseId = action.data.managedCustomerData.default_courses[Object.keys(action.data.managedCustomerData.default_courses)[0]].id
        }
      }
      return {
        ...state,
        ...action.data.misc,
        dataIsLoading: state.initialPopupAction && action.mainData,
        defaultCourseId: defaultCourseId,
      }
    case CHANGE_DEFAULT_COURSE:
      return {
        ...state, 
        defaultCourseId: action.id,
      }
    case GET_LEAGUES_FIELD_DATA:
      return {
        ...state,
        cloneLeaguesDataList: action.leagues,
      }
    case GET_LEAGUE_POTENTIAL_CLONE_CANDIDATE:
      return {
        ...state,
        potentialCloneCandidate: action.leagues,
      }
    default:
      return state
  }
}

export default misc
