import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { computeHeight, preloadImages } from '../../helpers'
import { ANIMATION_TIME } from '../../constants'
import { GlgLoadingSpinner } from 'SharedComponents'

class PhotosComponent extends Component {
  constructor(props) {
    super(props)

    this.initialState = {
      currentPosition: 0,
      animationKey: Math.random(),
      photosLoaded: false,
    }

    this.state = this.initialState
    this.showNextPhoto = this.showNextPhoto.bind(this)
    this.updateScrollRate = this.updateScrollRate.bind(this)
    this.getAnimation = this.getAnimation.bind(this)
  }

  componentDidMount() {
    const { slide, setPlaceholderValues } = this.props
    this._isMounted = true
    this.updateScrollRate()
    if (slide.placeholderValues && setPlaceholderValues) {
      setPlaceholderValues(slide.placeholderValues)
    }
    
    const photos = Array.from((slide.roll), (x) => x.photo)
    preloadImages(photos).then(() => this.setState({ photosLoaded: true}))
  }

  componentWillUnmount() {
    this._isMounted = false
  }
  
  showNextPhoto() {
    const { slide, moveToNextSlide, blueBox } = this.props
    
    const rollLength = Object.keys(slide.roll).length

    if (this._isMounted) {
      clearInterval(this.imageScrollInterval)
      this.imageScrollInterval = null
      if (this.state.currentPosition >= rollLength - 1) {
        this.setState({ 
          currentPosition: 0,
          animationKey: Math.random(),
        }, moveToNextSlide)
      } else {
        if (!blueBox.show) {
          this.setState({ 
            currentPosition: (this.state.currentPosition + 1) % rollLength,
            animationKey: Math.random(),
          })
        }
      }
    }
  }

  componentDidUpdate() {
    if (this._isMounted) {
      this.updateScrollRate()
    }
  }

  updateScrollRate() {
    const { slide, blueBox } = this.props
    
    if (!blueBox.isScrolling) {
      clearInterval(this.imageScrollInterval)
      this.imageScrollInterval = null 
    }

    if (blueBox.isScrolling && !this.imageScrollInterval) {
      this.imageScrollInterval = setInterval(() => {
        this.showNextPhoto()
      }, slide.scrollRate * 1000 / blueBox.scrollRate + ANIMATION_TIME * 1000)
    }
  }

  getAnimation() {
    const animation = parseInt(this.props.slide.animation)
    const animations = [ '', 'image-zoom-out', 'image-zoom-in', 'image-panning-left', 'image-panning-right' ]

    if (animation === animations.length) {
      return animations[this.state.currentPosition % (animations.length - 1) + 1]
    }
    return animations[animation]
  }

  render() {
    const { slide, blueBox, tvShowSettings, advancedSlideHeaderHeight } = this.props
    const { animationKey } = this.state
    const divStyle = this.state.photosLoaded ? {
      height: `calc(100vh - ${ computeHeight(tvShowSettings, blueBox, advancedSlideHeaderHeight) }px)`,
      backgroundImage: `url('${ slide.roll[this.state.currentPosition].photo }')`,
      animation: `${this.getAnimation()} ${ANIMATION_TIME}s ease-in-out infinite`,
      animationIterationCount: blueBox.show ? 0 : 1,
    } : {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }

    return <div key={ animationKey } className={'slide_component_photo_container'} style={{
          ...divStyle,
          ...(advancedSlideHeaderHeight ? { backgroundPositionY: 'center' } : {}),
        }}>{ !this.state.photosLoaded && <GlgLoadingSpinner></GlgLoadingSpinner>}</div>
  }
}

PhotosComponent.propTypes = {
  isFireTv: PropTypes.bool,
  slide: PropTypes.object.isRequired,
  blueBox: PropTypes.object,
  moveToNextSlide: PropTypes.func.isRequired,
  setPlaceholderValues: PropTypes.func,
  placeholderValues: PropTypes.object,
  hasUSGATheme: PropTypes.bool,
  tvShowSettings: PropTypes.object,
  advancedSlideHeaderHeight: PropTypes.number,
}

PhotosComponent.defaultProps = {
  isFireTv: false,
  hasUSGATheme: false,
}

export default PhotosComponent
