import React, { Component } from 'react'

class Preloader extends Component {
  constructor(props){
    super(props)
  }

  render() {
    return (
      <div className='widget-content-preloader'>
        <div className='content'></div>
      </div>
    )
  }
}

export default Preloader
