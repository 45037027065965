import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'

import { getAbsColumnValue, getCellParClass, computePriorScore } from 'Shared/course_boards'

class HoleByHolePreviewComponent extends Component {
  constructor(props) {
    super(props)
    this.renderFooter = this.renderFooter.bind(this)
  }

  renderFooter() {
    const { message, score, showCourse, showRounds, showTotal, roundsCount } = this.props
    const color = getCellParClass(score)
    const absValue = getAbsColumnValue(score)

    let messageColSpan = 19
    messageColSpan += showCourse ? 1 : 0
    messageColSpan += showTotal ? 1 : 0
    messageColSpan += showRounds ? roundsCount : 0

    return (
      <tr className={'footer-row '}>
        <td colSpan={1} className={'score ' + color}><div>{absValue}</div></td>
        <td colSpan={messageColSpan} className='message'><div>{message}</div></td>
      </tr>
    )
  }

  render() {
    const { lines, withTeams, showCourse, tees, roundsCount, showTotal, showRounds, showMessage} = this.props
    const footer = showMessage ? this.renderFooter() : null
    const hiddenClass = roundsCount > 1 ? '' : 'hidden'
    
    return (
      <Fragment>
        <Col xs={12} sm={12} md={12} lg={12} className='no-padding add-margin-top-20'>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className='page_title'>
              <div className='text'>Preview</div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
          <div className='btsp_form_content preview'>
              <div className='preview_container hole_by_hole_preview_container'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th className={hiddenClass} width='5%'>ST</th>
                      <th width='15%'>Leaders</th>
                      <th colSpan={18}></th>
                      {showCourse && <th width='5%'>Course</th>}
                      { showRounds && [ ...Array(roundsCount) ].map( ( _, i) => <th key={'round' + i}>{'R' + (i + 1)}</th> )}
                      { showTotal && <th width='5%'>Total</th> }
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='holes_line'>
                      <td className={hiddenClass}></td>
                      <td>Hole</td>
                      {[ ...Array(18) ].map( ( _, i) => <td key={'hole-' + i} className={ (i + 1) % 3 === 0 ? 'with_border' : ''}>{i + 1}</td>)}
                      {showCourse && <td></td>}
                      { showRounds && [ ...Array(roundsCount) ].map( ( _, i) => <td key={'round' + i}></td> )}
                      { showTotal && <td></td> }
                    </tr>
                    { tees.map( tee => {
                      return (
                        <tr className='par_line' key={tee.id}>
                          <td className={hiddenClass}></td>
                          <td>Par</td>
                          {[ ...Array(18) ].map( ( _, i) => <td key={'hole-' + i} className={ (i + 1) % 3 === 0 ? 'with_border' : ''}>{tee.par[i]}</td>)}
                          {showCourse && <td></td>}
                          { showRounds && [ ...Array(roundsCount) ].map( ( _, i) => <td key={'round' + i}></td> )}
                          { showTotal && <td></td> }
                        </tr>
                      )
                    })}
                    {
                      lines.map( line => {
                        const prior = line.empty ? '' : computePriorScore(line.total, line.today)
                        const colour = getCellParClass(prior)

                        return (
                          <tr key={'aggregate-' + line.id}>
                            <td className={'score ' + colour + ' ' + hiddenClass}>{getAbsColumnValue(prior)}</td>
                            <td>{line.empty ? '' : withTeams ? line.name : line.lastName + ', ' + line.firstName.charAt(0)}{line.asterisk}</td>
                            {[ ...Array(18) ].map( ( _, i) => {
                              const color = line.empty ? '' : getCellParClass(line.pars[i])
                              const borderClass = (i + 1) % 3 === 0 ? 'with_border' : ''
                              return <td key={'hole-' + i} className={'score ' + color + ' ' + borderClass}>{line.empty ? '' : getAbsColumnValue(line.pars[i])}</td>
                            })}
                            {showCourse && <td>{line.course}</td>}
                            {showRounds && [ ...Array(roundsCount) ].map( ( _, i) => <td key={'round' + i}>{line.empty ? '' : line.roundsScores[i]}</td> )}
                            { showTotal && <td>{line.totalScore === '0' ? '-' : line.totalScore}</td> }
                          </tr>
                        )
                      })
                    }
                    { footer}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Col>
      </Fragment>
    )

  }
}

HoleByHolePreviewComponent.propTypes = {
  lines: PropTypes.array,
  showMessage: PropTypes.bool,
  message: PropTypes.string,
  score: PropTypes.string,
  showCourse: PropTypes.bool,
  withTeams: PropTypes.bool,
  tees: PropTypes.array,
  roundsCount: PropTypes.number,
  showTotal: PropTypes.bool,
  showRounds: PropTypes.bool,
}

export default HoleByHolePreviewComponent
