import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Preview extends Component {
  constructor(props){
    super(props)
  }

  render() {
    return (
      <img src={ this.props.report.image_url } alt={ !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.reports.preview.preview_image') } />
    )
  }
}

Preview.propTypes = {
  report: PropTypes.object,
}


Preview.defaultProps = {
  report: {},
}

export default Preview
