import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import AdjustHandicap from './popups/adjust_handicap'

class HandicapAnalysis extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount() {
    window.glg.new_design_shared.init_selectpicker()
  }

  render() {
    const { title, links, tournaments, selected, searchTerm, tablesData, adjustmentHandicapModal } = this.props.content

    return (
      <div className='widget-content-default widget-content-rounds widget-content-handicap-analysis'>
        <div className='title'>
          { title }

          <a href={ links.handicap_analysis.path } className='btn btn-primary widget-white-title-button'>
            { links.handicap_analysis.name }
          </a>
        </div>
        <div className='content'>
          <div className='select-handicap'>
            <select className='selectpicker' onChange={ (event) => this.props.selectRoundTournament(event.target.value) }>
              {
                tournaments.map( tournament => <option key={ tournament.id } value={ tournament.id }>{ tournament.name }</option> )
              }
            </select>
          </div>


        {
          tablesData.settingsTable.data[selected] !== undefined
          && JSON.stringify(tablesData.settingsTable.data[selected]) !== '{}'
          && (tablesData.settingsTable.data[selected].length !== 1 && tablesData.settingsTable.data[selected][0].value !== null)
            ? <div className='tournament-data table-responsive'>
              <table className='table table-striped'>
                <thead>
                  <tr className='header'>
                    <th>{ tablesData.settingsTable.header.settings }</th>
                    <th>{ tablesData.settingsTable.header.value }</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    tablesData.settingsTable.data[selected] !== undefined
                      ? tablesData.settingsTable.data[selected].map( rowData =>
                        <tr key={ rowData.value }>
                          <td className='bold'>{ rowData.setting }</td>
                          <td>{ rowData.value }</td>
                        </tr>
                      ) : null
                  }
                </tbody>
              </table>
            </div>
            : null
        }

        {
          tablesData.dataTable.content[selected] !== undefined
          && JSON.stringify(tablesData.dataTable.content[selected]) !== '{}'
            ? tablesData.dataTable.content[selected].validity !== null
              ? <div className='event-validity'>
                { tablesData.dataTable.content[selected].validity }
              </div>
              : <Fragment>
                  <div className='usability'>
                    <span className='input-group'>
                      <input className='form-control'
                             autoComplete='false'
                             placeholder={ searchTerm.placeholder }
                             value={ searchTerm.value }
                             onChange={ event => this.props.handicapAnalysisSearch(event.target.value) } />

                      <span className='input-group-btn'>
                        <button className='btn btn-default' type='button' onClick={ () => this.props.handicapAnalysisSearch('') }>
                          <i className='fa fa-eraser'></i>
                        </button>
                      </span>
                    </span>

                    <div className='pull-right clear-all-adjustments' onClick={ () => this.props.clearAllPlayerAdjustment() }>{ links.clear_all_adjustments.name }</div>
                  </div>
                  <div className='tournament-data table-responsive'>
                    <table className='table table-striped'>
                        {
                          tablesData.dataTable.content[selected].table_data.map(tournamentTableData => 
                            <Fragment key={ tournamentTableData.header.result_scope_name }>
                              <thead>
                                {
                                  tournamentTableData.header.result_scope_name !== ''
                                  ? <tr className='header-data'>
                                      <td colSpan={ tablesData.dataTable.content[selected].column_count }>
                                        { tournamentTableData.header.result_scope_name }
                                      </td>
                                  </tr> : null
                                }
                                {
                                  tournamentTableData.header.tournament_using !== ''
                                  ? <tr className='header-data'>
                                      <td colSpan={ tablesData.dataTable.content[selected].column_count }
                                          dangerouslySetInnerHTML={{__html: tournamentTableData.header.tournament_using }} />
                                  </tr> : null
                                }
                                <tr className='header-data'>
                                  {
                                    tournamentTableData.header.columns.map(column =>
                                    <td key={ `${selected}-${column}` } className='header-data-columns' dangerouslySetInnerHTML={{__html: column }} />)
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  tournamentTableData.body.map(rowData =>
                                    rowData.name.toLowerCase().includes(searchTerm.value.toLowerCase())
                                    ? <Fragment key={ `${selected}-${rowData.name}` }>
                                        <tr>
                                          <td><div className='show-hidden-data' onClick={ () => this.props.handicapAnalysisShowHiddenDetails(`${selected}-${rowData.name}`) }>{ rowData.name }</div></td>
                                          <td>{ rowData.tee }</td>
                                          { rowData.index !== undefined ? <td dangerouslySetInnerHTML={{ __html: rowData.index }} /> : null }
                                          <td dangerouslySetInnerHTML={{ __html: rowData.handicap_display }} />
                                        </tr>
                                        <tr className={ tablesData.dataTable.hidden_details_to_show !== `${selected}-${rowData.name}` ? 'hidden-content' : '' }>
                                          <td colSpan={ tablesData.dataTable.content[selected].column_count }>
                                            <div dangerouslySetInnerHTML={{ __html: rowData.story }} />
                                            <div>
                                              <a href={ `#${rowData.adjust_link.path}` }
                                                className='btn btn-primary adjust-link'
                                                onClick={ () => this.props.adjustHandicap(rowData.name, rowData.handicap_adjusted, rowData.player_ids, rowData.adjust_link.path, rowData.clear_adjustment_path) }>
                                                { rowData.adjust_link.name }
                                              </a>
                                            </div>
                                          </td>
                                        </tr>
                                      </Fragment>
                                    : null)
                                }
                              </tbody>
                            </Fragment>)
                        }
                    </table>
                  </div>
                </Fragment>
            : null
        }

        <AdjustHandicap showModal={ adjustmentHandicapModal.show }
                        onClose={ () => this.props.cancelOrclearPlayerAdjustment() }
                        onSave={ () => this.props.submitAdjustHandicap() }
                        setAdjustHandicapFormData = { this.props.setAdjustHandicapFormData }
                        formData={ adjustmentHandicapModal.data } />
        </div>
      </div>
    )
  }
}

HandicapAnalysis.propTypes = {
  content: PropTypes.object,
  selectRoundTournament: PropTypes.func,
  handicapAnalysisShowHiddenDetails: PropTypes.func,
  handicapAnalysisSearch: PropTypes.func,
  cancelOrclearPlayerAdjustment: PropTypes.func,
  adjustHandicap: PropTypes.func,
  submitAdjustHandicap: PropTypes.func,
  setAdjustHandicapFormData: PropTypes.func,
  clearAllPlayerAdjustment: PropTypes.func,
}


HandicapAnalysis.defaultProps = {
  content: {
    handicap_analysis_path: '#',
    selected: '',
    tournaments: [],
    table_content: [],
    table_data: [],
    adjust_tournament_form_data: {
      title: null,
      label: null,
      path: null,
      player_ids: null,
      handicap: null,
    },
  },
}

export default HandicapAnalysis
