import React, { Component } from 'react'
import { Icon as FaIcon } from 'react-fa'
import PropTypes from 'prop-types'
import { InputGroup } from 'react-bootstrap'

// import EventStatusFilterTypes from '../../event_status_filter_types'

class CalendarInput extends Component {

  render() {
    return <InputGroup><input className="date-picker-input" readOnly={true} defaultValue={this.props.value} onClick={this.props.onClick} /><span className="input-group-addon" style={{ background: 'white' }}><FaIcon name="calendar" style={{color: '#4F4F4F'}}></FaIcon></span></InputGroup>
  }
}

CalendarInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
}

export default CalendarInput
