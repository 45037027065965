import _ from 'lodash'

export const getColumnName = (columnName, indexType, prettyHandicapMethod, membershipPrograms) => {
  if (columnName.indexOf('Membership Program:') > -1){
    const cols = columnName.split(':')
    const abv = membershipPrograms[cols[1].trim()].abbreviation
    const status = cols[2].trim().indexOf('expiration') > -1 ? (!window.I18n ? '' : window.I18n.t('members_page.helpers.expiration_date')) : (!window.I18n ? '' : window.I18n.t('members_page.helpers.join_date'))
    const title = membershipPrograms[cols[1].trim()].name
    return {name: abv + ' - ' + status, title: title}
  }
  switch (columnName) {
    case 'glg_status':
      return {name: !window.I18n ? '' : window.I18n.t('members_page.helpers.status')}
    case 'full_name':
      return {name: !window.I18n ? '' : window.I18n.t('members_page.helpers.handle')}
    case 'usga_index':
      if (indexType === 'both') {
        return {name: !window.I18n ? '' : window.I18n.t('members_page.helpers.18_hole_hi')}
      }
      return {name: !window.I18n ? '' : window.I18n.t('members_page.helpers.hi')}
    case 'nine_hole_index':
      return {name: !window.I18n ? '' : window.I18n.t('members_page.helpers.9_hole_hi')}
    case 'glg_paid':
      return {name: !window.I18n ? '' : window.I18n.t('members_page.helpers.paid')}
    case 'glg_actions' || 'cpga_actions':
      return {name: !window.I18n ? '' : window.I18n.t('members_page.helpers.actions')}
    case 'tentative_rank':
      return {name: !window.I18n ? '' : window.I18n.t('members_page.helpers.trank')}
    case 'tentative_points':
      return {name: !window.I18n ? '' : window.I18n.t('members_page.helpers.tpoints')}
    case 'tentative_position':
      return {name: !window.I18n ? '' : window.I18n.t('members_page.helpers.tposition')}
    case 'first_name':
      return {name: !window.I18n ? '' : window.I18n.t('members_page.helpers.first_name')}
    case 'last_name':
      return {name: !window.I18n ? '' : window.I18n.t('members_page.helpers.last_name')}
    case 'email':
      return {name: !window.I18n ? '' : window.I18n.t('members_page.helpers.email')}
    case 'waitlist':
      return {name: !window.I18n ? '' : window.I18n.t('members_page.helpers.waitlist')}
    case 'Entry Number':
      return {name: !window.I18n ? '' : window.I18n.t('members_page.helpers.entry_number')}
    case 'automated_handicaps':
      return {name: prettyHandicapMethod}
    case 'email_subscription':
      return {name: !window.I18n ? '' : window.I18n.t('members_page.helpers.email_subscription')}
    default:
      return {name: columnName.replace('_', ' ')}
  }
}

export const getCategoryName = (categoryName) => {
  switch (categoryName.toLowerCase()) {
    case 'membership_programs':
      return !window.I18n ? '' : window.I18n.t('members_page.helpers.filter_by_membership_programs')
    case 'show_only':
      return !window.I18n ? '' : window.I18n.t('members_page.helpers.show_only')
    default:
      return categoryName.replace('_', ' ')
  }
}

export const filepickerOptions = (options) => {
  const newOptions = {}
  for (const k of Object.keys(options)){
    newOptions[`data-fp-${k.replace(/_/g, '-')}`] = options[k]
  }
  return newOptions
}

export const resetHash = (hash) => {
  const clonedHash = _.clone(hash)
  const hashIds = Object.keys(clonedHash)
  for (const hId of hashIds) {
    clonedHash[hId] = false
  }
  return clonedHash
}

export const PAGE_SIZE = 50
export const FILEPICKER_KEY = 'A2G3JUf8gRzee1BpY3CJfz'

export const toolbar = [
  [ 'Undo', 'Redo' ],
  [ 'PasteFromWord' ],
  [ 'Font', 'FontSize', 'lineheight' ],
  [ 'Bold', 'Italic', 'Underline', 'Strike', 'TextColor', 'BGColor' ],
  [ 'Link', 'Unlink' ],
  [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ],
  [ 'NumberedList', 'BulletedList', 'Indent', 'Outdent' ],
  [ 'HorizontalRule', 'Table', 'Image' ],
  [ 'Source' ],
]

export const fontNamesList = 'Arial/Arial, Helvetica, sans-serif;' +
  'Almendra;' +
  'Comic Sans MS/Comic Sans MS, Chalkboard, ChalkboardSE-Regular, cursive;' +
  'Courier New/Courier New, Courier, monospace;' +
  'Georgia/Georgia, serif;' +
  'Helvetica/Helvetica, Arial, Lucinda Grande, sans-serif;' +
  'Helvetica Neue/Helvetica Neue, Helvetica, Arial, Lucinda Grande, sans-serif;' +
  'Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif;' +
  'Roboto;' +
  'Tahoma/Tahoma, Geneva, sans-serif;' +
  'Times New Roman/Times New Roman, Times, serif;' +
  'Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;' +
  'Verdana/Verdana, Geneva, sans-serif;'

export const pluralizeGolfers = (count) => {
  return count === 1 ? (!window.I18n ? '' : window.I18n.t('members_page.helpers.golfer')) : (!window.I18n ? '' : window.I18n.t('members_page.helpers.golfers'))
}
