import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ReportCenterItemDropdown from './report_center_item_dropdown'

class ReportCenterItemBlock extends Component {
  stringParameterize(str) {
    return str.trim().toLowerCase()
                    .replace(/[^a-zA-Z0-9 -]/, '')
                    .replace(/\s/g, '_')
  }
  render() {
    return (
      <tr className={ 'report-list-item report_center_item_block' + ( this.props.report_center_item.display ? '' : ' hidden') + (this.props.job.id || this.props.job.completed_links ? ' in_progress' : '')}>
        <td className="report_center_item_title text-left">
          {
            this.props.job.error && <i className="fa fa-exclamation error"></i>
          }
          {
            // name input field
            this.props.edit_name ? (
              <input autoFocus type='text'
                defaultValue={this.props.report_center_item.name}
                ref={(input) => { this.nameInput = input }}
                onKeyPress={ function (target) {
                                                  if (target.key === 'Enter') {
                                                    this.props.saveItemName(this.nameInput.value)
                                                  }
                                               }.bind(this)
                }
                onBlur={ () => { this.props.saveItemName(this.nameInput.value) }} />
            ) : (
              this.props.report_center_item.action !== null ?
                this.props.report_center_item.action[0].length === 2 ?
                  this.props.report_center_item.report_type === 'TeesheetSetting' ?
                    <a onClick={ [ 'Gpu::Report', 'Pairing' ].indexOf(this.props.report_center_item.report_type) !== -1 ?
                        //if GPU::Report - tell modal to load print_options form from edit_conflicts_path and submit throught printReport or cancel throught setJobId of this very rci
                        //otherwise just printReport right away
                        () => { this.props.openPrintModal({ edit_conflicts_path: this.props.report_center_item.action[0][1], rci_base_printReport: this.props.printReport, rci_base_setJobId: this.props.setJobId}) }
                      : () => { this.props.printReport(this.props.report_center_item.action[0][1]) } }
                        >{ this.props.report_center_item.name.toUpperCase() }</a>
                  :
                    <a onClick={ () => { this.props.redirectUnlessJobsRunning(this.props.report_center_item.action[0][1]) } }>{ this.props.report_center_item.name.toUpperCase() }</a>
                :
                  <a onClick={ [ 'Gpu::Report', 'Pairing' ].indexOf(this.props.report_center_item.report_type) !== -1 ?
                      //if GPU::Report - tell modal to load print_options form from edit_conflicts_path and submit throught printReport or cancel throught setJobId of this very rci
                      //otherwise just printReport right away
                      () => { this.props.openPrintModal({ edit_conflicts_path: this.props.report_center_item.action[1], rci_base_printReport: this.props.printReport, rci_base_setJobId: this.props.setJobId}) }
                    : () => { this.props.printReport() } }
                      >{ this.props.report_center_item.name.toUpperCase() }</a>
              :
                this.props.report_center_item.edit_path !== null ?
                  <a onClick={ () => { this.props.redirectUnlessJobsRunning(this.props.report_center_item.edit_path[1]) } }>{ this.props.report_center_item.name.toUpperCase() }</a>
                :
                  <span>{ this.props.report_center_item.name.toUpperCase() }</span>
            )
          }
          { [ 'unrestricted', 'restricted' ].includes(this.props.report_center_item.access) &&
            !this.props.isTeamCaptain && 
            <Fragment>
              { this.props.report_center_item.access !== 'restricted' &&
                (
                 ([ 'Gpu::Report', 'SpreadsheetReport', 'Gpu::Scorecard' ].indexOf(this.props.report_center_item.report_type) === -1 || this.props.current_report_center_list.read_only) ?
                  <span></span>
                : 
                  <a onClick={this.props.toggleItemName}>
                    <i className="fa fa-pencil fa-fw"></i>
                  </a>
                )
              }
              {
                ([ 'Gpu::Report', 'SpreadsheetReport', 'PublisherTemplate', 'Gpu::Scorecard' ].indexOf(this.props.report_center_item.report_type) !== -1 && !this.props.current_report_center_list.read_only) &&
                  <a onClick={this.props.deleteItem} className='edit-name-link'>
                    <i className='fa fa-trash fa-fw'></i>
                  </a>
              }
            </Fragment>
          }
        </td>
        <td className="text-center">
          <span>
            {
              <span className="add-margin-right-10">
                <i className="fa fa-list fa-fw text-center"></i>
                { this.props.report_center_item.list_count }
              </span>
            }
            <span className="add-margin-left-10">
              <i className="fa fa-print fa-fw text-center"></i>
              { this.props.report_center_item.print_count }
            </span>
          </span>
        </td>
        {/* report buttons saga */}
        <td className="text-right add-padding-right-30">
          { !this.props.isTeamCaptain &&
            <Fragment>
              {
                this.props.report_center_item.enabled_tooltip &&
                  <span>
                    <i className="gga-info-circle" title={ this.props.report_center_item.enabled_tooltip }></i>
                    &nbsp;•&nbsp;
                  </span>
              }
              {
                [ 'unrestricted' ].includes(this.props.report_center_item.access) &&
                this.props.report_center_item.edit_path !== null ?
                  this.props.report_center_item.edit_path[0].length === 2 ?
                    <span>
                      <a onClick={ () => { this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, this.props.report_center_item.edit_path[0][1], this.props.report_center_item.edit_path[0][0]) } }>{this.props.report_center_item.edit_path[0][0]}</a>
                      &nbsp;•&nbsp;
                      <a onClick={ () => { this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, this.props.report_center_item.edit_path[1][1], this.props.report_center_item.edit_path[1][0]) } }>{this.props.report_center_item.edit_path[1][0]}</a>
                      &nbsp;•&nbsp;
                    </span>
                  :
                    <span>
                      <a onClick={() => { this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, this.props.report_center_item.edit_path[1], this.props.report_center_item.edit_path[0]) } }>{this.props.report_center_item.edit_path[0]}</a>
                      &nbsp;•&nbsp;
                    </span> 
                : ''
              }
            </Fragment>
          }
          {
            this.props.report_center_item.action !== null ?
              this.props.report_center_item.action[0].length === 2 ?
                <span>
                  {
                    this.props.job.completed_links === null ?
                      this.props.job.id !== null || this.props.job.show_spiner ?
                        <span><a className="action">{this.props.report_center_item.action[0][0] + 'ing...' }</a>&nbsp;•&nbsp;</span>
                      :
                        this.props.report_center_item.report_type === 'TeesheetSetting' ?
                          <span>
                            <a onClick={ [ 'Gpu::Report', 'Pairing' ].indexOf(this.props.report_center_item.report_type) !== -1 ?
                              //if GPU::Report - tell modal to load print_options form from edit_conflicts_path and submit throught printReport or cancel throught setJobId of this very rci
                              //otherwise just printReport right away
                              () => { this.props.openPrintModal({ edit_conflicts_path: this.props.report_center_item.action[0][1], rci_base_printReport: this.props.printReport, rci_base_setJobId: this.props.setJobId}) }
                            : () => { this.props.printReport(this.props.report_center_item.action[0][1]) } }
                              >{this.props.report_center_item.action[0][0]}</a>&nbsp;•&nbsp;
                          </span>
                        :
                          <span><a onClick={ () => { this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, this.props.report_center_item.action[0][1], this.props.report_center_item.action[0][0]) } }>{this.props.report_center_item.action[0][0]}</a>&nbsp;•&nbsp;</span>
                    :
                      <span>
                        { 
                          this.props.job.completed_links['pdf_path'] &&
                            <span>
                              <a target='_blank' rel='noopener noreferrer'
                                 href={this.props.job.completed_links['pdf_path']}>
                                { this.props.job.completed_links.button_label }
                              </a>&nbsp;•&nbsp;
                            </span>
                        }
                        {
                          this.props.job.completed_links['primary_docx_service'] !== null ?
                            <span>
                              <a onClick={ this.props.generateDocx } >
                              {!window.I18n ? '' : window.I18n.t('download_center.generate_doc')}
                              </a>&nbsp;•&nbsp;
                            </span>
                          : 
                            (this.props.job.completed_links['docx_path'] || null) &&
                              <span>
                                <a target='_blank' rel='noopener noreferrer'
                                   href={this.props.job.completed_links['docx_path']}>
                                  {!window.I18n ? '' : window.I18n.t('download_center.download_doc')}
                                </a>&nbsp;•&nbsp;
                              </span>
                        }
                        {
                          this.props.job.completed_links['generate_txt'] !== null ?
                            <span>
                              <a onClick={ this.props.generateTxt } >
                              {!window.I18n ? '' : window.I18n.t('download_center.generate_txt')}
                              </a>&nbsp;•&nbsp;
                            </span>
                          : 
                            (this.props.job.completed_links['txt_path'] || null) &&
                              <span>
                                <a target='_blank' rel='noopener noreferrer'
                                   href={this.props.job.completed_links['txt_path']}>
                                  {!window.I18n ? '' : window.I18n.t('download_center.download_txt')}
                                </a>&nbsp;•&nbsp;
                              </span>
                        }
                      </span>
                  }
                  <a onClick={ () => { this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, this.props.report_center_item.action[1][1], this.props.report_center_item.action[1][0]) } }>{this.props.report_center_item.action[1][0]}</a>&nbsp;•&nbsp;
                </span>
              :
                this.props.job.id !== null || this.props.job.show_spiner ?
                  <span>
                    <a className="action">{this.props.report_center_item.action[0] + 'ing...'}</a>&nbsp;•&nbsp;
                  </span>
                :
                  this.props.job.id === null && this.props.job.completed_links === null ?
                    <span>
                      <a onClick={ [ 'Gpu::Report', 'Pairing', 'Gpu::Scorecard' ].indexOf(this.props.report_center_item.report_type) !== -1 ?
                          //if GPU::Report - tell modal to load print_options form from edit_conflicts_path and submit throught printReport or cancel throught setJobId of this very rci
                          //otherwise just printReport right away
                          () => { this.props.openPrintModal({ edit_conflicts_path: this.props.report_center_item.action[1], rci_base_printReport: this.props.printReport, rci_base_setJobId: this.props.setJobId}) }
                        : () => { this.props.printReport() } }>
                          {this.props.report_center_item.action[0]}
                      </a>{ !this.props.isTeamCaptain && <span>&nbsp;•&nbsp;</span>}
                    </span>
                  :
                    <span>
                      {
                        this.props.job.completed_links['pdf_path'] &&
                          <span>
                            <a target='_blank' rel='noopener noreferrer'
                               href={this.props.job.completed_links['pdf_path']}>
                              { this.props.job.completed_links.button_label }
                            </a>&nbsp;•&nbsp;
                          </span>
                      }
                      {
                        this.props.job.completed_links['primary_docx_service'] !== null ?
                          <span>
                            <a onClick={ this.props.generateDocx } >
                            {!window.I18n ? '' : window.I18n.t('download_center.generate_doc')}
                            </a> &nbsp;•&nbsp;
                          </span>
                        : 
                          (this.props.job.completed_links['docx_path'] || null) &&
                            <span>
                              <a target='_blank' rel='noopener noreferrer'
                                 href={this.props.job.completed_links['docx_path']}>
                                {!window.I18n ? '' : window.I18n.t('download_center.download_doc')}
                              </a> &nbsp;•&nbsp;
                            </span>
                      }
                      {
                        this.props.job.completed_links['generate_txt'] !== null ?
                          <span>
                            <a onClick={ this.props.generateTxt } >
                            {!window.I18n ? '' : window.I18n.t('download_center.generate_txt')}
                            </a>&nbsp;•&nbsp;
                          </span>
                        : 
                          (this.props.job.completed_links['txt_path'] || null) &&
                            <span>
                              <a target='_blank' rel='noopener noreferrer'
                                  href={this.props.job.completed_links['txt_path']}>
                                {!window.I18n ? '' : window.I18n.t('download_center.download_txt')}
                              </a>&nbsp;•&nbsp;
                            </span>
                      }
                    </span>
            : ''
          }
          { !this.props.isTeamCaptain &&
            <Fragment>
              {
                this.props.report_center_item.report_id !== null ?
                (this.props.current_report_center_list.id === '-1' ?
                  (this.props.report_center_item.needs_import ?
                  <span>
                    <a href="javascript:void(0)"
                       onClick={() => this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, '', 'Copy') }>{!window.I18n ? '' : window.I18n.t('download_center.import')}</a>
                    &nbsp;•&nbsp;
                  </span> : null) :
                  <span>
                    <a href="javascript:void(0)"
                       onClick={() => this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, '', 'Copy') }>{!window.I18n ? '' : window.I18n.t('download_center.copy')}</a>
                    &nbsp;•&nbsp;
                  </span>) :
                  (!this.props.current_report_center_list.read_only ?
                  <span>
                    <a href="javascript:void(0)"
                       onClick={() => this.props.importEvent(this.props.report_center_item, this.props.current_report_center_list, '', 'Copy') }>{!window.I18n ? '' : window.I18n.t('download_center.copy')}</a>
                    &nbsp;•&nbsp;
                  </span> : null)
              }
              {
                ([ 'unrestricted', 'restricted' ].includes(this.props.report_center_item.access) &&
                ((this.props.current_report_center_list.id === '-1' && !this.props.report_center_item.needs_import) || !this.props.current_report_center_list.read_only)) &&
                  <ReportCenterItemDropdown
                    layout={this.props.layout}
                    report_center_lists={this.props.report_center_lists}
                    current_report_center_list={this.props.current_report_center_list}
                    report_center_item={this.props.report_center_item}
                    addRemoveItemToList={this.props.addRemoveItemToList}
                    stringParameterize={this.stringParameterize}/>
              }
            </Fragment>
          }
        </td>
      </tr>
    )
  }
}

ReportCenterItemBlock.propTypes = {
  report_center_item: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
  current_report_center_list: PropTypes.object.isRequired,
  report_center_lists: PropTypes.array.isRequired,
  edit_name: PropTypes.bool.isRequired,
  saveItemName: PropTypes.func.isRequired,
  setJobId: PropTypes.func.isRequired,
  generateDocx: PropTypes.func.isRequired,
  generateTxt: PropTypes.func.isRequired,
  cloneItem: PropTypes.func.isRequired,
  addRemoveItemToList: PropTypes.func.isRequired,
  openPrintModal: PropTypes.func.isRequired,
  printReport: PropTypes.func.isRequired,
  redirectUnlessJobsRunning: PropTypes.func.isRequired,
  toggleItemName: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  isTeamCaptain: PropTypes.bool.isRequired,
  importEvent: PropTypes.func.isRequired,
  layout: PropTypes.string.isRequired,
}

export default ReportCenterItemBlock
