
// Get min & max slots numerical values
import OttScreen from './ott_screen'

export const getMaxSlots = (state) => {
  const maxSlots = [ OttScreen.ADD_ADDITIONAL_PLAYERS, OttScreen.CREATE_BOOKING ].includes(state.activeScreen) ?
    state.maxSlotsReg - (state.data.reduce((acc, item) => {
      return acc + item.foursome_players.filter(player => player.owned_by_current_member && !player.is_reserved).length
    }, 0) ?? 0) :
    state.maxSlotsSignup - (state.data.reduce((acc, item) => {
      return acc + item.foursome_players.filter(player => player.owned_by_current_member && !player.is_reserved).length
    }, 0) ?? 0)

  return maxSlots >= 0 ? maxSlots : 0
}

export const getMinSlots = (state) => {
  if ([ OttScreen.ADD_ADDITIONAL_PLAYERS, OttScreen.CREATE_BOOKING ].includes(state.activeScreen)) {
    return state.selectedSlots.length === state.maxSlotsReg ? 0 :
      state.data.reduce((acc, item) => {
        return acc + item.foursome_players.filter(player => player.owned_by_current_member && !player.is_reserved).length
      }, 0) > 0 ? 1 :
        state.minSlotsReg
  } else {
    return 0
  }
}


// Min & max slots used for Registration with OTT-first
export const checkIfMinSlotsReached = ((state) => state.selectedSlots.length >= state.minSlotsReg)
export const checkIfMaxSlotsReached = ((state) => state.selectedSlots.length === state.maxSlotsReg)

// Max slots used for Registration with OTT-second
export const checkIfMaxSlotsReachedAfterRegister = state => state.selectedSlots.length === state.members?.length

// Max slots used for Registration additional players
export const checkIfMaxSlotsReachedAddPlayersReg = state => state.selectedSlots.length + state.data.reduce((acc, item) => {
  return acc + item.foursome_players.filter(player => player.owned_by_current_member && !player.is_reserved).length
}, 0) >= state.maxSlotsReg

// Max slots used for Signup additional players
export const checkIfMaxSlotsReachedAddPlayersSignup = state => state.selectedSlots.length + state.data.reduce((acc, item) => {
  return acc + item.foursome_players.filter(player => player.owned_by_current_member && (!player.is_reserved || player.player)).length
}, 0) >= state.maxSlotsSignup


export const checkIfAnySlotsChecked = state => state.selectedSlots.length > 0
