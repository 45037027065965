import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Leaderboard extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { title, links, iframeUrl } = this.props.content

    return (
      <div className='widget-content-default widget-content-rounds widget-content-leaderboard'>
        <div className='title'>
          { title }

          <a href={ links.manage_tournaments.path } className='btn btn-primary widget-white-title-button'>
            { links.manage_tournaments.name }
          </a>
        </div>
        <div className='content'>
          <iframe width="100%"
                  id='leaderboard-iframe'
                  height="100%"
                  src={ iframeUrl }
                  frameBorder="0"
                  // eslint-disable-next-line newline-per-chained-call
                  onLoad={ () => $('#leaderboard-iframe').contents().find('.button.back').hide() }
                  // style={{ pointerEvents: this.props.IframePointerOption, visibility: this.state.loaded ? 'visible' : 'hidden' }}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen></iframe>
        </div>
      </div>
    )
  }
}

Leaderboard.propTypes = {
  content: PropTypes.object,
}

Leaderboard.defaultProps = {
  content: {
    title: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.leaderboard.title'),
    links: {
      manage_tournaments: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.leaderboard.manage_tournaments'),
        path: '#',
      },
    },
    iframeUrl: '#',
  },
}

export default Leaderboard
