import PropTypes from 'prop-types'
import React, { useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectSlot,
  unselectSlot,
  selectMoveHere,
  checkSlot,
  uncheckSlot,
  reinitialise,
  closeUpdateError,
  toggleRefreshQueue,
} from '../actions'
import OttScreen from '../ott_screen'
import OttErrorPopup from './ott_error_popup'
import {
  checkIfMaxSlotsReached,
  checkIfMaxSlotsReachedAfterRegister,
} from '../selectors'
import { callAPI } from 'Shared/helpers'
import GlgTimer from 'Shared/components/glg_timer'
import OttErMembersDropdown from './ott_er_members_dropdown'
import {GlgTooltip} from '../../shared/components'
import {GlgTextInput} from '../../shared/components'


const OttSlot = (props) => {
  // Component State
  const dispatch = useDispatch()

  /// Redux State
  const activeScreen = useSelector(state => state.activeScreen)
  const checkedSlots = useSelector(state => state.checkedSlots)
  const foursomeCapacity = useSelector(state => state.playersCount)
  const remainingSlotsSignupGuests = useSelector(state => state.remainingSlotsSignupGuests)
  const remainingSlotsSignupMembers = useSelector(state => state.remainingSlotsSignupMembers)
  const remainingSlotsRegisterGolfers = useSelector(state => state.remainingSlotsRegisterGolfers)
  const selectedSlots = useSelector(state => state.selectedSlots)
  const currentMemberId = useSelector(state => state.currentMemberId)
  const leagueId = useSelector(state => state.leagueId)
  const roundId = useSelector(state => state.roundId)
  const bookBeforeRegistration = useSelector(state => state.bookBeforeRegister)
  const isLoggedInWithGgid = useSelector(state => state.isLoggedInWithGgid)
  const unauthenticatedWithReserved = useSelector(state => state.unauthenticatedWithReserved)
  const maxSlotsReached = useSelector(checkIfMaxSlotsReached)
  const maxSlotsReachedAfterRegister = useSelector(checkIfMaxSlotsReachedAfterRegister)
  const [ isErrorPopupOpen, setErrorPopupOpen ] = useState(false)
  const [ errorPopupMessage, setErrorPopupMessage ] = useState(window.I18n.t('players_choose_tee_times.ott_2_0.error.slot_reserved'))
  const [ isBeingSelected, setIsBeingSelected ] = useState(false)
  const [ isAddGolferPopupOpen, setIsAddGolferPopupOpen ] = useState(false)
  const currentUser = useSelector(state => state.currentUser)
  const updateError = useSelector(state => state.isUpdateErrorPopupOpen)
  const eventOttEnabled = useSelector(state => state.eventOttEnabled)

  // Business Logic
  const openErrorPopup = () => {
    scrollToSlot()
    setErrorPopupOpen(true)
    setIsAddGolferPopupOpen(false)
  }

  const closeErrorPopup = () => {
    setErrorPopupOpen(false)
    dispatch(closeUpdateError(false))
  }

  const handleCheckboxChange = ( foursomeId, position, isChecked) => {

    if (isChecked === false) {
      dispatch(checkSlot(foursomeId, position))

    } else {
      dispatch(uncheckSlot(foursomeId, position))
    }
  }

  const reserveSlotForEventRosterMember = async (foursomeId, position, shouldAddSelfToSlot = true) => {
    setIsBeingSelected(true)

    // Determine slots to reserve for current user + in anticipation, slots for potential additional golfers 
    const slotsToReserve = []
    for (let i = position; i <= Math.min(foursomeCapacity, position + remainingSlotsSignupGuests + remainingSlotsSignupMembers - 1); i++) {
      slotsToReserve.push({ foursome_id: foursomeId, player_position: i })
    }

    // Reserve slots
    const url = `/leagues/${leagueId}/widgets/players_choose_tee_times/reserve_selected_slots`
    callAPI(url, 'POST', { is_er_member_signup_scenario: true, slots_to_reserve: JSON.stringify(slotsToReserve), round_id: roundId })
    .then(() => {
      if (shouldAddSelfToSlot) {
        addEventRosterMemberToSlot(slotsToReserve[0].player_position, slotsToReserve[0].foursome_id)
      }
      setIsBeingSelected(false)

    })
    .catch((error) => {
      dispatch(closeUpdateError(true))
      openErrorPopup()
      if (error && error[0]) {
        setErrorPopupMessage(error[0])
      } else {
        setErrorPopupMessage(window.I18n.t('players_choose_tee_times.ott_2_0.error.general'))
      }
      reinitialiseState()
      setIsBeingSelected(false)
    })
  }

  const reinitialiseState = () => {
    const url = `/leagues/${leagueId}/widgets/players_choose_tee_times/reinitialise_and_set_current_member_for_admin`
    callAPI(url, 'POST', { round_id: roundId, selected_member_id: currentMemberId })
      .then((resp) => {
        dispatch(reinitialise(resp.message))
      })
      .catch(() => {
      })
  }

  const addEventRosterMemberToSlot = (slot, foursomeId) => {
    const url = `/leagues/${leagueId}/widgets/players_choose_tee_times/add_players_on_tee_sheet_ott2`
    const fromSlots = [ currentMemberId ]
    const toSlots = [ {foursome_id: foursomeId, player_position: slot} ]

    callAPI(url, 'POST', { 
      from_slots: JSON.stringify(fromSlots), 
      to_slots: JSON.stringify(toSlots), 
      round_id: roundId,
      current_member_id: currentMemberId, 
    })
    .then(() => {
      reinitialiseState()
      setIsBeingSelected(false)
    })
    .catch(() => {
      openErrorPopup()
      setErrorPopupMessage(window.I18n.t('players_choose_tee_times.ott_2_0.error.unexpected'))
      setIsBeingSelected(false)
    })
  }
  
  const scrollToSlot = () => {
    document
      .getElementById(`${props.foursome_id}_${props.position}`)
      .scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      })
  }

  const toggleSelectButton = (event, foursomeId, position, isSelected, player, owned, checked, isReserved, buttonIsDisabled) => {

    if (buttonIsDisabled) {
      return
    }


    //event.preventDefault()
    if (player === null) {
      dispatch(toggleRefreshQueue(true))
      if (activeScreen === OttScreen.CHANGE_TEE_TIME) {
        dispatch(selectMoveHere(foursomeId, checkedSlots, position))
      } else if (isReserved && owned) {
        dispatch(selectSlot(foursomeId, position))
        scrollToSlot()
        setIsAddGolferPopupOpen(true)
      } else if (activeScreen === OttScreen.EDIT_BOOKING && !maxSlotsReachedAfterRegister) {
        dispatch(selectSlot(foursomeId, position))
        reserveSlotForEventRosterMember(foursomeId, position, false)
        scrollToSlot()
        setIsAddGolferPopupOpen(true)
      } else if (activeScreen === OttScreen.SIGNUP_ER_MEMBER) {
        reserveSlotForEventRosterMember(foursomeId, position)
      } else {
        if (isSelected) {
          dispatch(unselectSlot(foursomeId, position))
        } else {
          dispatch(selectSlot(foursomeId, position))
        }
      }
    }
    if (owned === true) {
      handleCheckboxChange(foursomeId, position, checked)
    }

  }


  // View Helpers
  const isSlotOwnedByCurrentUser = props.player !== null && props.owned_by_current_member === true
  
  const [ buttonBg, buttonColor, buttonText, buttonIsDisabled, buttonBorder ] = (() => {
    if (props.player !== null) {
      if (props.owned_by_current_member) {
        if (activeScreen === OttScreen.REMOVE_PLAYERS) {
          if (props.is_checked) {
            return [ '#E50000', 'white', ' ' + props.player[0], false, 'none' ]
          } else {
            return [ 'white', '#E50000', ' ' + props.player[0], false, '2px solid #E50000' ]
          }
        } else {
          if (activeScreen === OttScreen.CHANGE_TEE_TIME) {
            if (props.is_checked) {
              return [ '#1C9E24', 'white', ' ' + props.player[0], false, 'none' ]
            } else {
              return [ 'white', '#1C9E24', ' ' + props.player[0], false, '2px solid #1C9E24' ]
            }
          } else {
            return [ '#1C9E24', 'white', ' ' + props.player[0], true, 'none' ]
          }
        }

      } else {
        return [ '#DFEFFF', '#333F48', props.player[0], true, 'none' ]
      }
    } else if (activeScreen === OttScreen.REMOVE_PLAYERS) {
      return [ '#D7D7D7', 'white', window.I18n.t('players_choose_tee_times.ott_2_0.slot.available'), true, 'none' ]
    } else if (activeScreen === OttScreen.ADMIN_OR_MANAGER_SCREEN) {
      return [ '#D7D7D7', 'white', window.I18n.t('players_choose_tee_times.ott_2_0.slot.select'), true, 'none' ]
    } else {
      if (props.is_reserved === true) {
        if (props.owned_by_current_member) {
          if (unauthenticatedWithReserved === true) {
            return [ '#25607C', 'white', window.I18n.t('players_choose_tee_times.ott_2_0.slot.reserved'), true, 'none' ]
          } else {
            return [ '#25607C', 'white', window.I18n.t('players_choose_tee_times.ott_2_0.slot.reserved'), false, 'none' ]
          }
        } else {
          return [ '#DFEFFF', '#333F48', window.I18n.t('players_choose_tee_times.ott_2_0.slot.slot_locked'), true, 'none' ]
        }
      } else {
        if (props.is_selected === true) {
          return [ '#1C9E24', 'white', window.I18n.t('players_choose_tee_times.ott_2_0.slot.selected'), false, 'none' ]
        } else {
          if (isBeingSelected) {
            return [ '#25607C', 'white', window.I18n.t('players_choose_tee_times.ott_2_0.slot.select_redirected'), true, 'none' ]
          }
          if (activeScreen === OttScreen.CREATE_BOOKING && (maxSlotsReached === true || (bookBeforeRegistration === false && maxSlotsReachedAfterRegister === true)) ) {
            return [ '#D7D7D7', 'white', window.I18n.t('players_choose_tee_times.ott_2_0.slot.select'), true ]
          } else if (activeScreen === OttScreen.CHANGE_TEE_TIME) {
            if (props.is_valid_for_moving === true && checkedSlots.length > 0) {
              return [ '#25607C', 'white', window.I18n.t('players_choose_tee_times.ott_2_0.table.move_here'), false, 'none' ]
            } else {
              return [ '#D7D7D7', 'white', window.I18n.t('players_choose_tee_times.ott_2_0.table.move_here'), true, 'none' ]
            }
          } else if (activeScreen === OttScreen.ADD_ADDITIONAL_PLAYERS ) {
            if ( selectedSlots.length < remainingSlotsRegisterGolfers ) {
              return [ '#25607C', 'white', window.I18n.t('players_choose_tee_times.ott_2_0.slot.select'), false, 'none' ]
            } else {
              return [ '#D7D7D7', 'white', window.I18n.t('players_choose_tee_times.ott_2_0.slot.select'), true, 'none' ]
            }
          } else if (activeScreen === OttScreen.CHANGE_TEE_TIME) {
            return [ '#25607C', 'white', window.I18n.t('players_choose_tee_times.ott_2_0.table.move_here'), false, 'none' ]
          } else if (activeScreen === OttScreen.EDIT_BOOKING ) {
            if (maxSlotsReachedAfterRegister && currentUser) {
              return [ '#D7D7D7', 'white', window.I18n.t('players_choose_tee_times.ott_2_0.slot.available'), true, 'none' ]
            } else {
              if (remainingSlotsSignupMembers + remainingSlotsSignupGuests > 0) {
                return [ '#25607C', 'white', window.I18n.t('players_choose_tee_times.ott_2_0.slot.add_player'), false, 'none' ]
              } else {
                return [ '#D7D7D7', 'white', window.I18n.t('players_choose_tee_times.ott_2_0.slot.add_player'), true, 'none' ]
              }
            }
          } else if ( !isLoggedInWithGgid && (bookBeforeRegistration === true || currentUser) && unauthenticatedWithReserved === false && (eventOttEnabled || currentMemberId)) {
            return [ '#25607C', 'white', window.I18n.t('players_choose_tee_times.ott_2_0.slot.select'), false, 'none' ]
          } else {
            return [ '#D7D7D7', 'white', window.I18n.t('players_choose_tee_times.ott_2_0.slot.select'), true, 'none' ]
          }
        }
      }
    }
  })()

  const closeAddGolferPopup = () => {
    setIsAddGolferPopupOpen(false)
    reinitialiseState()
    setIsBeingSelected(false)
  }

  const getTooltipMessage = (() => {
    if (isLoggedInWithGgid) {
      return window.I18n.t('players_choose_tee_times.ott_2_0.tooltips.restricted_action')
    } else {
      return ''
    }
  })()

  const ottSlotContent = () => {
    return (
      <div
        className={'inner-box-style-slot'}
        id={`${props.foursome_id}_${props.position}`}
        style={{background: buttonBg, color: buttonColor, border: buttonBorder}}
        onClick={(e) => toggleSelectButton(e, props.foursome_id, props.position, props.is_selected, props.player, props.owned_by_current_member, props.is_checked, props.is_reserved, buttonIsDisabled)}
        disabled={buttonIsDisabled}
        >
        <div className={'overflow-style'}>
          {isSlotOwnedByCurrentUser && (activeScreen === OttScreen.CHANGE_TEE_TIME || (activeScreen === OttScreen.REMOVE_PLAYERS)) && (
            <input
              key={`${props.foursome_id}-${props.position}`}
              type="checkbox"
              checked={props.is_checked}
              className={'checkbox-style'}
            />
          )}
          <span className={'overflow-style-span'}>
          {buttonText} &nbsp;
            {(props.owned_by_current_member && props.is_reserved && props.player === null && props.reservation_seconds_left - 15 >= 0) &&
              <GlgTimer seconds={props.reservation_seconds_left - 15} onExpiration={props.handleExpiration}/>}
          </span>
        </div>

        {props.player !== null && (
          <div className={'handicap-style'}>
            {props.player[1] !== null && props.player[1] !== '' ? props.player[1] : (props.owned_by_current_member === true ?
              props.is_checked === true ? <hr className={'white-line-style'}/> :
                activeScreen === OttScreen.REMOVE_PLAYERS ?
                  <hr className={'red-line-style'}/> :
                  <hr className={'green-line-style'}/>
              : <hr className={'black-line-style'}/>)}
          </div>
        )}

      </div>
    )
  }

  // Render
  return (
    <>
      {(getTooltipMessage || getTooltipMessage !== '')
        ? (
          <GlgTooltip tooltip={ getTooltipMessage } placement="top">
            { ottSlotContent() }
          </GlgTooltip>
        )
        : ottSlotContent()
      }

      {isErrorPopupOpen || updateError && <OttErrorPopup message={errorPopupMessage} handleClose={closeErrorPopup}/>}

      {(isAddGolferPopupOpen && !isErrorPopupOpen) && <AddGolferPopup handleClose={closeAddGolferPopup}/>}

    </>
  )
}

// PropTypes
OttSlot.propTypes = {
  player: PropTypes.array,
  foursome_id: PropTypes.string,
  owned_by_current_member: PropTypes.bool,
  is_reserved: PropTypes.bool,
  is_selected: PropTypes.bool,
  is_valid_for_moving: PropTypes.bool,
  is_checked: PropTypes.bool,
  position: PropTypes.number,
  reservation_seconds_left: PropTypes.number,
  expired_popup: PropTypes.bool,
  handleExpiration: PropTypes.func,
}

export default OttSlot

const AddGolferPopup = ({handleClose}) => {
  const selectedSlots = useSelector(state => state.selectedSlots)
  const leagueId = useSelector(state => state.leagueId)
  const currentMemberId = useSelector(state => state.currentMemberId)
  const remainingSlotsSignupGuests = useSelector(state => state.remainingSlotsSignupGuests)
  const remainingSlotsSignupMembers = useSelector(state => state.remainingSlotsSignupMembers)
  const [ selectedPlayerId, setSelectedPlayerId ] = useState(null)
  const dispatch = useDispatch()
  const roundId = useSelector(state => state.roundId)
  const [ isMemberForm, setIsMemberForm ] = useState(remainingSlotsSignupMembers > 0)
  const [ guestEmail, setGuestEmail ] = useState('')
  const [ guestFirsName, setGuestFirsName ] = useState('')
  const [ guestLastName, setGuestLastName ] = useState('')
  const [ guestHandicapIndex, setGuestHandicapIndex ] = useState('')
  const [ guestMembershipNumber, setGuestMembershipNumber ] = useState('')


  const reinitialiseState = () => {

    const url = `/leagues/${leagueId}/widgets/players_choose_tee_times/reinitialise_and_set_current_member_for_admin`
    callAPI(url, 'POST', { round_id: roundId, selected_member_id: currentMemberId })
      .then((resp) => {
        dispatch(reinitialise(resp.message))
      })
      .catch(() => {
      })
  }

  // Add golfer to slot
  const handleMembersDone = (e) => {
    e.preventDefault()

    const playerId = selectedPlayerId
    const foursomeId = selectedSlots[0].foursome_id
    const position = selectedSlots[0].player_position

    const url = `/leagues/${leagueId}/widgets/players_choose_tee_times/add_player_on_tee_sheet`
    callAPI(url, 'POST', { player_id: playerId, foursome_id: foursomeId, position: position, current_member_id: currentMemberId })
      .then(() => {
        reinitialiseState()
        handleClose()
      })
      .catch(() => {
      })
  }

  const handleGuestsDone = (e) => {
    e.preventDefault()
    
    const url = `/leagues/${leagueId}/widgets/players_choose_tee_times/create_register_guest_ott_v2`
    const params = {
      email: guestEmail,
      first_name: guestFirsName,
      last_name: guestLastName,
      usga_index: guestHandicapIndex,
      handicap_network_id: guestMembershipNumber,
      current_member_id: currentMemberId,
      foursome_id: selectedSlots[0].foursome_id,
      position: selectedSlots[0].player_position,
    }
    callAPI(url, 'POST', params)
      .then(() => {
        reinitialiseState()
        handleClose()
      })
      .catch(() => {
      })
  }

  const handleANewGuestButtonClick = (e) => {
    e.preventDefault()
    setIsMemberForm(false)
  }

  const handleANewMemberButtonClick = (e) => {
    e.preventDefault()
    setIsMemberForm(true)
  }

  return (
    <div className={'popup-container-style'}>
      <div className={'popup-style-add-golfers'}>
        {isMemberForm 
          ?
            <>
              <div className={'ott-title-footer-style black-text'}> {window.I18n.t('players_choose_tee_times.ott_2_0.add_player_popup.register_member')} </div>
              <div className={'add-golfers-canvas'}>
                <label className='black-text'>{window.I18n.t('players_choose_tee_times.ott_2_0.add_player_popup.select_golfer')}</label>
                <OttErMembersDropdown setSelectedPlayerId={setSelectedPlayerId} disablePlayers={true}/>

                {/* eslint-disable react/no-unknown-property */}
                <div div className={'button-container-style'}>
                  <div className={'back-button-style right-button-style'} onClick={handleClose}>
                    {window.I18n.t('players_choose_tee_times.ott_2_0.buttons.cancel')}
                  </div>
                  <div className={'back-button-style enabled-bg-button-style'} onClick={handleMembersDone}>
                    {window.I18n.t('players_choose_tee_times.ott_2_0.buttons.done')}
                  </div>
                </div>

                {remainingSlotsSignupGuests > 0 && (<>
                  <label className='black-text do-you-want-label'>{window.I18n.t('players_choose_tee_times.ott_2_0.add_player_popup.change_to_guest')}</label>
                  <button className='popup_register_button' onClick={handleANewGuestButtonClick}>{window.I18n.t('players_choose_tee_times.ott_2_0.add_player_popup.add_guest_button')}</button>
                  </>)}
              </div>
            </> 
          : 
            <>
              <div className={'ott-title-footer-style black-text'}> {window.I18n.t('players_choose_tee_times.ott_2_0.add_player_popup.register_guest')} </div>

              <form onSubmit={handleGuestsDone}>
                <div className={'add-golfers-canvas'}>
                  <div className='guest-popup-inputs-wrapper'>
                    <label className='black-text'>{window.I18n.t('players_choose_tee_times.ott_2_0.add_player_popup.labels.first_name')} <label className='red-style'> * </label> </label>

                    <GlgTextInput placeholder = '' value={guestFirsName} onChange = {(value) => setGuestFirsName(value) } required />
                  </div>

                  <div className='guest-popup-inputs-wrapper'>
                    <label className='black-text'>{window.I18n.t('players_choose_tee_times.ott_2_0.add_player_popup.labels.last_name')} <label className='red-style'> * </label> </label>
                    <GlgTextInput placeholder = '' value={guestLastName} onChange = {(value) => setGuestLastName(value) } required />
                  </div>

                  <div className='guest-popup-inputs-wrapper'>
                    <label className='black-text'>{window.I18n.t('players_choose_tee_times.ott_2_0.add_player_popup.labels.email')}</label>
                    <GlgTextInput placeholder = '' value={guestEmail} onChange = {(value) => setGuestEmail(value) }/>
                  </div>

                  <div className='guest-popup-inputs-wrapper'>
                    <label className='black-text'>{window.I18n.t('players_choose_tee_times.ott_2_0.add_player_popup.labels.handicap_index')}</label>
                    <GlgTextInput placeholder = '' value={guestHandicapIndex} onChange = {(value) => setGuestHandicapIndex(value) }/>
                  </div>

                  <div className='guest-popup-inputs-wrapper'>
                    <label className='black-text'>{window.I18n.t('players_choose_tee_times.ott_2_0.add_player_popup.labels.membership_number')}</label>
                    <GlgTextInput placeholder = '' value={guestMembershipNumber} onChange = {(value) => setGuestMembershipNumber(value) }/>
                  </div>

                  {/* eslint-disable react/no-unknown-property */}
                  <div div className={'button-container-style'}>
                    <div className={'back-button-style right-button-style'} onClick={handleClose}>
                      {window.I18n.t('players_choose_tee_times.ott_2_0.buttons.cancel')}
                    </div>
                    <button className={'back-button-style enabled-bg-button-style'}>
                      {window.I18n.t('players_choose_tee_times.ott_2_0.buttons.done')}
                    </button>
                  </div>

                  {remainingSlotsSignupMembers > 0 && (<>
                    <label className='black-text do-you-want-label'>{window.I18n.t('players_choose_tee_times.ott_2_0.add_player_popup.change_to_member')}</label>
                    <button className='popup_register_button' onClick={handleANewMemberButtonClick}>{window.I18n.t('players_choose_tee_times.ott_2_0.add_player_popup.add_member_button')}</button>
                  </>)}
                </div>
              </form>
            </> 
        }

      </div>
    </div>
  )
}

AddGolferPopup.propTypes = {
  handleClose: PropTypes.func,
  handleDone: PropTypes.func,
}

