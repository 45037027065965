import { combineReducers } from 'redux'

import membersData from './members_data'
import leftSidebarFields from './left_sidebar_fields'
import cellMeasurerCache from './cell_measurer_cache'
import addNewMembers from './add_new_members'
import leaguesPropagation from './leagues_propagation'
import deleteRosterConfirmation from './delete_roster_confirmation'
import registerMember from './register_member'
import misc from './misc'

export default combineReducers({
  membersData,
  leftSidebarFields,
  cellMeasurerCache,
  addNewMembers,
  leaguesPropagation,
  deleteRosterConfirmation,
  registerMember,
  misc,
})
