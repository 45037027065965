import _ from 'lodash'
import { arrayMove } from 'react-sortable-hoc'

/*
 * items: Array<{ position: number, ... }>
 * fromPosition: value of the source position field
 * toPosition: value of the target position field
 */
export const reorderListPositions = (items, fromPosition, toPosition) => {
  const positions = _.sortBy(items.map(item => item.position))
  const fromIndex = _.indexOf(positions, fromPosition)
  const toIndex = _.indexOf(positions, toPosition)
  const reorderedPositions = arrayMove(positions, fromIndex, toIndex)
  const positionMapping = {}
  positions.forEach((position, index) => {
    positionMapping[reorderedPositions[index]] = position
  })
  const updatedItems = items.map(item => {
    const newPosition = positionMapping[item.position]
    return {
      ...item,
      position: _.isUndefined(newPosition) ? item.position : newPosition,
    }
  })
  return updatedItems
}

/*
 * items: HashMap<id, { id: number, position: number, ... }>
 */
export const deleteListItem = (items, itemId) => {
  const filteredItems = _.omit(items, itemId)
  return normalizeItemPositions(filteredItems)
}

/*
 * items: HashMap<id, { id: number, position: number, ... }>
 */
export const normalizeItemPositions = items => {
  const sortedItems = _.sortBy(items, 'position')
  let normalizedItems = sortedItems.map((item, index) => ({
    ...item,
    position: index + 1,
  }))
  normalizedItems = _.keyBy(normalizedItems, 'id')

  return normalizedItems
}

/*
 * items: HashMap<id, { id: number, position: number, ... }>
 * id: the key where we insert the item
 * item: the item object without the 'position' field
 */
export const addListItem = (items, id, item) => {
  const maxPosition = _.max(_.map(items, 'position')) || 0
  return {
    ...items,
    [id]: {
      ...item,
      position: maxPosition + 1,
    },
  }
}
