import { connect } from 'react-redux'
import SideBySidePreviewComponent from '../components/side_by_side_preview_component'

import { getPlayersOnFrontSide, getPlayersOnBackSide } from 'Shared/course_boards'

const mapStateToProps = (state) => {
  const frontLines = getPlayersOnFrontSide(state)
  const backLines = getPlayersOnBackSide(state)

  return {
    frontLines: frontLines,
    backLines: backLines,
    showMessage: state.misc.showMessage,
    message: state.misc.message,
    score: state.misc.score,
    showCourse: state.misc.showCourse,
    withTeams: state.misc.withTeams,
  }
}

export default connect(
  mapStateToProps,
  null,
)(SideBySidePreviewComponent)
