import onClickOutside from 'react-onclickoutside'
import React, { Component } from 'react'
import PropTypes from 'prop-types'


class InputForm extends Component {
  constructor(props){
    super(props)
    this.state = {
      open: false,
      value: props.value,
    }
  }

  handleClickOutside = (event) => {
    if (this.state.open) {
      this.setState({ open: false }, () => event.target.click())
      this.props.onEditCompleted(this.state.value)
    }
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.setState({ open: false })
      this.props.onEditCompleted(this.state.value)
      event.target.blur()
    }
  }

  render() {
    return (
        <input id={this.props.id} className={this.props.className} type={this.props.type} value={this.state.value} onMouseDown={() => this.setState({ open: true})} onChange={e => this.setState({ value: e.target.value})} onKeyDown={this.handleKeyPress} />
    )
  }
}

InputForm.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  onEditCompleted: PropTypes.func,
}

InputForm.defaultProps = {
  value: '',
  className: '',
  type: 'text',
  id: null,
  onEditCompleted: () => {},
}
export default onClickOutside(InputForm)
