import React, { useState } from 'react'
import { generateTimeItems, levelOptions } from '../key_settings_utils'
import keySettings from '../key_settings'
import Select from 'react-select'
import { applyBulkEdit } from '../actions'
import { GlgButton, GlgSelect } from 'SharedComponents'
import { useSelector, useDispatch } from 'react-redux'
import CalendarCustomInput from '../../dashboard/components/content/event_setup/calendar_custom_input'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import KeySettingsBaseTab from './key_settings_base_tab'
import PropTypes from 'prop-types'
import KeySettingsSelectActions from './key_settings_select_actions'

const KeySettingsBulkEdit = (props) => {
  // Redux state
  const tableData = useSelector(state => state.table.data.table)
  const canInvitationList = useSelector(state => state.table.data.can_invitation_list)
  const canAlwaysShowMrCoupling = useSelector(state => state.table.data.can_always_show_mr_coupling)
  const canOpenRegistration = useSelector(state => state.table.data.can_open_registration)
  const isUkWhs = useSelector(state => state.table.data.is_uk_whs)
  const customerManagers = useSelector(state => state.table.data.customer_managers)
  const hiServices = useSelector(state => state.table.data.hi_services)
  const rosterLeagueCourses = useSelector(state => state.table.data.roster_league_courses)
  const coursesEnabled = useSelector(state => state.table.data.courses_enabled)
  const customerCourses = useSelector(state => state.table.data.customer_courses)
  const leaguesAndRoundsSelected = useSelector(state => state.table.data.leagues_and_rounds_selected)

  // Local State
  const [ selectedLevelBulk, setSelectedLevelBulk ] = useState('')
  const [ selectedKeyBulk, setSelectedKeyBulk ] = useState('')
  const [ selectedValueBulk, setSelectedValueBulk ] = useState('')
  const [ selectedDefaultTeeMBulk, setSelectedDefaultTeeMBulk ] = useState('')
  const [ selectedDefaultTeeFBulk, setSelectedDefaultTeeFBulk ] = useState('')
  const [ selectedHoleTypeBulk, setSelectedHoleTypeBulk ] = useState('')
  const [ isCurrentPageSelected, setIsCurrentPageSelected ] = useState(false)
  const dispatch = useDispatch()

  // View Helpers
  const keys = selectedLevelBulk
    ? keySettings
      .filter(option => 
        option.level === selectedLevelBulk &&
        (option.key !== 'registration_starts_on_for_invitations' || (canInvitationList && canOpenRegistration)) &&
        (option.key !== 'master_roster_coupling' || canAlwaysShowMrCoupling) &&
        (option.key !== 'variable_settings_whsuk_is_qualifying' || isUkWhs) &&
        (option.key !== 'variable_settings_automatically_post_scores' || isUkWhs) &&
        (option.key !== 'min_number_of_members' || canOpenRegistration) &&
        (option.key !== 'max_number_of_members' || canOpenRegistration) &&
        (option.key !== 'min_number_of_guests' || canOpenRegistration) &&
        (option.key !== 'max_number_of_guests' || canOpenRegistration) &&
        (option.key !== 'registration_starts_on' || canOpenRegistration) &&
        (option.key !== 'registration_ends_on' || canOpenRegistration) &&
        (option.key !== 'variable_settings_default_tee_m') &&
        (option.key !== 'variable_settings_default_tee_f') &&
        (option.key !== 'allow_players_to_choose_tee_time') &&
        (option.key !== 'variable_settings_hole_type') &&
        (option.key !== 'balls') &&
        (option.key !== 'format') &&
        (option.key !== 'competition')
      )
      .map(option => option.key)
    : []

  const values = selectedKeyBulk
    ? keySettings
        .find(option => option.key === selectedKeyBulk)?.values || []
    : []
  
  const keyOptions = keys.map(key => {
    const option = keySettings.find(option => option.key === key)
    return { value: key, label: option ? option.display : key }
  }).sort((a, b) => a.label.localeCompare(b.label))

  const areAnyRoundsSelected = () => {
    return Object.values(leaguesAndRoundsSelected).some(league =>
      Object.values(league['are_rounds_selected']).some(round => round.is_selected)
    )
  }
  
  const areAnyLeaguesSelected = () => {
    return Object.values(leaguesAndRoundsSelected).some(league => league['is_selected'])
  }

  const countLeaguesSelected = () => {
    return Object.values(leaguesAndRoundsSelected)
                 .filter(league => league['is_selected'])
                 .length
  }

  const countRoundsSelected = () => {
    let totalSelectedRounds = 0
    Object.values(leaguesAndRoundsSelected).forEach(league => {
      totalSelectedRounds += Object.values(league['are_rounds_selected']).filter(round => round.is_selected).length
    })
    return totalSelectedRounds
  }

  // Business Logic
  const handleLevelSelectBulk = (level) => {
    setSelectedLevelBulk(level)
    setSelectedKeyBulk('')
    setSelectedValueBulk('')
  }

  const handleKeySelectBulk = (key) => {
    setSelectedKeyBulk(key)
    setSelectedValueBulk('')
  }

  const handleValueSelectBulk = (value) => {
    setSelectedValueBulk(value)
    setSelectedDefaultTeeMBulk('')
    setSelectedDefaultTeeFBulk('')
    const selectedSetting = keySettings.find(option => option.key === selectedKeyBulk)
    
    props.setBulkEditData({
      level: selectedLevelBulk,
      key: selectedKeyBulk,
      value: value,
      type: selectedSetting.type,    
    })
  }

  const handleDefaultTeeMSelectBulk = (value) => {
    setSelectedDefaultTeeMBulk(value)

    props.setBulkEditData({
      level: selectedLevelBulk,
      key: selectedKeyBulk,
      value: selectedValueBulk,
      teeM: value,
      teeF: selectedDefaultTeeFBulk,
      holeType: selectedHoleTypeBulk,
    })
  }

  const handleDefaultTeeFSelectBulk = (value) => {
    setSelectedDefaultTeeFBulk(value)

    props.setBulkEditData({
      level: selectedLevelBulk,
      key: selectedKeyBulk,
      value: selectedValueBulk,
      teeM: selectedDefaultTeeMBulk,
      teeF: value,
      holeType: selectedHoleTypeBulk,
    })
  }

  const handleHoleTypeSelectBulk = (value) => {
    setSelectedHoleTypeBulk(value)

    props.setBulkEditData({
      level: selectedLevelBulk,
      key: selectedKeyBulk,
      value: selectedValueBulk,
      teeM: selectedDefaultTeeMBulk,
      teeF: selectedDefaultTeeFBulk,
      holeType: value,
    })
  }

  const handleCoursesEnabledSelectBulk = (value) => {
    props.setBulkEditData({
      level: selectedLevelBulk,
      key: selectedKeyBulk,
      value: selectedValueBulk,
      coursesEnabled: value,
    })
  }

  const handleApplyUpdateClick = () => {
    dispatch(applyBulkEdit(props.bulkEditData))
    props.refreshTable(true, props.currentPage)
  }

  // Render View
  if (!tableData) { return null }

  return (
    <KeySettingsBaseTab title={!window.I18n ? '' : window.I18n.t('key_settings.bulk_edit.title')}>
      <div className="content">
        <KeySettingsSelectActions 
          isCurrentPageSelected={isCurrentPageSelected}
          setIsCurrentPageSelected={setIsCurrentPageSelected}
          expandedLeagues={props.expandedLeagues}
          setExpandedLeagues={props.setExpandedLeagues}
        />

        <div className='filter-container'>
          <label className='less-bold'>{!window.I18n ? '' : window.I18n.t('key_settings.bulk_edit.type')}</label>
          <Select
            className='select-default-size'
            options={levelOptions}
            value={selectedLevelBulk ? { value: selectedLevelBulk, label: selectedLevelBulk } : null}
            onChange={(selectedOption) => handleLevelSelectBulk(selectedOption.value)}
          />
          {selectedLevelBulk && (
            <>
              <label className='less-bold'>{!window.I18n ? '' : window.I18n.t('key_settings.bulk_edit.change_settings')}</label>
              <Select
                className='select-default-size'
                options={keyOptions}
                value={selectedKeyBulk ? keyOptions.find(option => option.value === selectedKeyBulk) : null}
                onChange={(selectedOption) => handleKeySelectBulk(selectedOption.value)}
              />
            </>
          )}
          {selectedKeyBulk && (
            keySettings && (
              <div>
                <label className='less-bold'>{!window.I18n ? '' : window.I18n.t('key_settings.bulk_edit.to')}</label>
              </div>
            )
          )}
          {selectedKeyBulk && (
            <div>
              {selectedKeyBulk.includes('number_of') ? (
                <input
                  value={selectedValueBulk || 0} 
                  min="0" 
                  max="12" 
                  required 
                  className="form-control-input" 
                  type="number"
                  onChange={(e) => handleValueSelectBulk(e.target.value)}
                />
              ) : keySettings.find((option) => option.key === selectedKeyBulk)?.type === 'number' ? (
                <input 
                  value={selectedValueBulk || 0}
                  required 
                  className="form-control-input" 
                  type="number"
                  onChange={(e) => handleValueSelectBulk(e.target.value)}
                />
              ) : keySettings.find((option) => option.key === selectedKeyBulk)?.type === 'date' ? (
                <div className="width-180-px">
                  <DatePicker
                    showTimeSelect
                    timeIntervals={60}
                    customInput={<CalendarCustomInput withTime/>}
                    selected={selectedValueBulk && 
                              moment(selectedValueBulk, 'YYYY-MM-DD HH:mm:ss')
                                .toDate()}
                    dateFormat='MM/dd/yyyy h:mm aa'
                    onChange={(date) => {
                      handleValueSelectBulk(moment(date)
                      .format('YYYY-MM-DD HH:mm:ss'))
                    }}
                    popperClassName="custom-datepicker-popper"
                    popperPlacement="auto"
                  />
                </div>
              ) : selectedKeyBulk === 'communication_officer' ? (
                <GlgSelect
                  key={'communication_officer_bulk'}
                  items={[
                    ...(customerManagers).sort((a, b) => a.full_name.localeCompare(b.full_name)).map(manager => ({
                      value: manager.user_id.toString(),
                      label: manager.full_name,
                    })),
                  ]}
                  placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                  className="default-glg-select"
                  menuPosition='fixed'
                  noPreFill
                  usePortal
                  menuShouldBlockScroll
                  onChange={(e) => handleValueSelectBulk(e)}
                />
              ) : selectedKeyBulk === 'handicaps_updated_via' ? (
                <GlgSelect
                  key={'handicaps_updated_via_bulk'}
                  items={hiServices.sort((a, b) => a[1].localeCompare(b[1])).map(service => ({
                          value: service[1],
                          label: service[0],
                  }))}
                  placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                  className="default-glg-select"
                  menuPosition='fixed'
                  noPreFill
                  usePortal
                  menuShouldBlockScroll
                  onChange={(e) => handleValueSelectBulk(e)}
                />
              ) : selectedKeyBulk === 'variable_settings_default_course' ? (
                <GlgSelect
                  key={'variable_settings_default_course_bulk'}
                  items={
                    rosterLeagueCourses.sort((a, b) => a[1].localeCompare(b[1])).map(service => ({
                          value: service[0].toString(),
                          label: service[1],
                    }))
                  }
                  placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                  className="default-glg-select"
                  menuPosition='fixed'
                  noPreFill
                  usePortal
                  menuShouldBlockScroll
                  onChange={(e) => handleValueSelectBulk(e)}
                />
              ) : selectedKeyBulk === 'variable_settings_auto_move_in_progress_hour' || selectedKeyBulk === 'variable_settings_auto_move_to_completed_hour' ? (
                <GlgSelect
                  key={'variable_settings_auto_move_in_progress_hour_bulk'}
                  items={generateTimeItems()}
                  placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                  className="default-glg-select"
                  menuPosition='fixed'
                  noPreFill
                  usePortal
                  menuShouldBlockScroll
                  onChange={(e) => handleValueSelectBulk(e)}
                />
            ) : selectedKeyBulk === 'courses_enabled' ? (
              <GlgSelect
                key={'courses_enabled_bulk'}
                items={customerCourses.sort((a, b) => a[1].localeCompare(b[1])).map(service => ({
                  value: service[0],
                  label: service[1],
                }))}
                placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                className="default-glg-select"
                menuPosition='fixed'
                noPreFill
                usePortal
                menuShouldBlockScroll
                onChange={(e) => handleValueSelectBulk(e)}
              />
            ) : (
                <Select
                  className='select-boolean-size'
                  value={selectedValueBulk.value}
                  onChange={(e) => handleValueSelectBulk(e.value)}
                  options={values.sort().map(value => ({ value: value.data, label: value.label || value.data }))}
                />
              )}
            </div>
          )}
          {selectedValueBulk && (
            <div>
              {selectedKeyBulk === 'variable_settings_default_course' && (
                <div className='input-group-2'>
                  <label className='less-bold label-margin'>{!window.I18n ? '' : window.I18n.t('key_settings.bulk_edit.with_default_tee_m')}</label>
                  <GlgSelect
                    key={'tee_m_bulk'}
                    items={coursesEnabled[selectedValueBulk]['tees'].sort((a, b) => a['name'].localeCompare(b['name'])).map(tee => ({
                      value: tee['id'],
                      label: tee['name'],
                    }))}
                    placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                    className="default-glg-select"
                    menuPosition='fixed'
                    noPreFill
                    usePortal
                    menuShouldBlockScroll
                    onChange={(e) => handleDefaultTeeMSelectBulk(e)}
                  />
                </div>
              )}
            </div>
          )}
          {selectedValueBulk && (
            <div>
              {selectedKeyBulk === 'variable_settings_default_course' && (
                <div className='input-group-2'>
                  <label className='less-bold label-margin'>{!window.I18n ? '' : window.I18n.t('key_settings.bulk_edit.default_tee_f')}</label>
                  <GlgSelect
                    key={'tee_f_bulk'}
                    items={coursesEnabled[selectedValueBulk]['tees'].sort((a, b) => a['name'].localeCompare(b['name'])).map(tee => ({
                      value: tee['id'],
                      label: tee['name'],
                    }))}
                    placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                    className="default-glg-select"
                    menuPosition='fixed'
                    noPreFill
                    usePortal
                    menuShouldBlockScroll
                    onChange={(e) => handleDefaultTeeFSelectBulk(e)}
                  />
                </div>
              )}
            </div>
          )}
          {selectedValueBulk && (
            <div>
              {selectedKeyBulk === 'variable_settings_default_course' && (
                <div className='input-group-2'>
                  <label className='less-bold label-margin'>{!window.I18n ? '' : window.I18n.t('key_settings.bulk_edit.and_hole_type')}</label>
                  <GlgSelect
                    key={'hole_type_bulk'}
                    items={
                      [
                      { value: '1', label: !window.I18n ? '' : window.I18n.t('key_settings.generic.all18')},
                      { value: '3', label: !window.I18n ? '' : window.I18n.t('key_settings.generic.back9') },
                      { value: '2', label: !window.I18n ? '' : window.I18n.t('key_settings.generic.front9') },
                      ]
                    }
                    placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                    className="small-glg-select"
                    menuPosition='fixed'
                    noPreFill
                    usePortal
                    menuShouldBlockScroll
                    onChange={(e) => handleHoleTypeSelectBulk(e)}
                  />
                </div>
              )}
            </div>
          )}
          {selectedValueBulk && (
            <div>
              {selectedKeyBulk === 'courses_enabled' && (
                <div className='input-group-2'>
                  <label className='less-bold label-margin'>{!window.I18n ? '' : window.I18n.t('key_settings.bulk_edit.is')}</label>
                  <GlgSelect
                    key={'courses_enabled_toggle'}
                    items={
                      [
                      { value: true, label: !window.I18n ? '' : window.I18n.t('key_settings.generic.data_on') },
                      { value: false, label: !window.I18n ? '' : window.I18n.t('key_settings.generic.data_off') },
                      ]
                    }
                    placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                    className="small-glg-select"
                    menuPosition='fixed'
                    noPreFill
                    usePortal
                    menuShouldBlockScroll
                    onChange={(e) => handleCoursesEnabledSelectBulk(e)}
                  />
                </div>
              )}
            </div>
          )}
          {selectedValueBulk && (
            <div>
              {selectedLevelBulk.includes(!window.I18n ? '' : window.I18n.t('key_settings.table.rounds')) ? (
                <GlgButton 
                  text={`${window.I18n ? window.I18n.t('key_settings.bulk_edit.apply_update') : ''} (${countRoundsSelected()} ${countRoundsSelected() === 1 ? 
                    window.I18n ? window.I18n.t('key_settings.bulk_edit.round') : '' :
                    window.I18n ? window.I18n.t('key_settings.table.rounds') : ''})`
                  }
                  onClick={handleApplyUpdateClick}
                  size="normal"
                  className="add-padding-right-5 add-padding-left-5"
                  disabled={selectedLevelBulk === 'Rounds' && !areAnyRoundsSelected()}
                />
              ) : selectedLevelBulk.includes(!window.I18n ? '' : window.I18n.t('key_settings.table.events_and_leagues')) ? (
                <GlgButton 
                  text={`${window.I18n ? window.I18n.t('key_settings.bulk_edit.apply_update') : ''} (${countLeaguesSelected()} ${countLeaguesSelected() === 1 ? 
                    window.I18n ? window.I18n.t('key_settings.bulk_edit.event_league') : '' :
                    window.I18n ? window.I18n.t('key_settings.table.events_and_leagues') : ''})`
                  }
                  onClick={handleApplyUpdateClick}
                  size="normal"
                  className="add-padding-right-5 add-padding-left-5"
                  disabled={(selectedLevelBulk === (window.I18n ? window.I18n.t('key_settings.table.events_and_leagues') : '') && !areAnyLeaguesSelected())}
                />
              ) : null}
            </div>
          )}
        </div>
      </div>
    </KeySettingsBaseTab>
  )
}

KeySettingsBulkEdit.propTypes = {
  bulkEditData: PropTypes.object.isRequired,
  setBulkEditData: PropTypes.func.isRequired,
  refreshTable: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  expandedLeagues: PropTypes.array.isRequired,
  setExpandedLeagues: PropTypes.func.isRequired,
}

export default KeySettingsBulkEdit
