import { connect } from 'react-redux'
import MembersToggle from '../../components/members_filter/members_toggle'

const mapStateToProps = (state) => ({
  filterOptionsSet: state.membersData.filterOptionsSet,
})

const mapDispatchToProps = () => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MembersToggle)
