import React, {Component} from 'react'
import { GlgRadio } from 'SharedComponents'
import { Col } from 'react-flexbox-grid'
import PropTypes from 'prop-types'

export class RegistrationMemberOrGuest extends Component {
  constructor(props){
    super(props)
  }
  
  render(){
    return <Col xs={12} md={8}><div className="checkbox_radio_container"><GlgRadio name={`answer[${this.props.field.id}]`} label="Guest" value="guest" defaultChecked={this.props.answer === 'guest'} disable={this.props.field.disabled} radioClass={'i-checks'} onChange={(val) => this.props.onChange(this.props.field.id, this.props.field.category, this.props.field.name, val)}></GlgRadio></div><div className="checkbox_radio_container"><GlgRadio name={`answer[${this.props.field.id}]`} label="Member" value="member" defaultChecked={this.props.answer === 'member'} disable={this.props.field.disabled} radioClass={'i-checks'} onChange={(val) => this.props.onChange(this.props.field.id, this.props.field.category, this.props.field.name, val)}></GlgRadio></div></Col>
  }
}

RegistrationMemberOrGuest.propTypes = {
  field: PropTypes.object.isRequired,
  answer: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default RegistrationMemberOrGuest
