import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GlgPopup, GlgButton, GlgLoadingSpinner } from 'SharedComponents'
import { NONE, SUCCESS } from '../constants'
import { Row, Col } from 'react-flexbox-grid'
import { FormControl, FormGroup } from 'react-bootstrap'
import _ from 'lodash'

import ImageTitleFilter from './image_title_filter'
import EditableImageTitle from './editable_image_title'

class ChangeImagePopup extends Component {
  
  constructor(props) {
    super(props)

    this.state = {
      isFetchingData: false,
      isUploadingData: false,
      selected: '',
      selectedForUpload: [],
      filenameForUpload: '',
      images: [],
      searchedTitle: '',
    }

    this.setStateProperty = this.setStateProperty.bind(this)
    this.fetchContent = this.fetchContent.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleUpload = this.handleUpload.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.onSearchedTitleChange = this.onSearchedTitleChange.bind(this)
    this.handleFilenameChange = this.handleFilenameChange.bind(this)
  }

  setStateProperty(property, value) {
    this.setState({
      ...this.state,
      [property]: value,
    })
  }

  componentDidUpdate(prevProps) {
    const hasPopupJustOpened = !prevProps.show && this.props.show
    if (hasPopupJustOpened) {
      this.fetchContent()
    }
  }

  fetchContent() {
    this.setStateProperty('isFetchingData', true)
    $.ajax({
      url: this.props.fetchImagesPath,
      type: 'GET',
      data:
        { image_title: this.state.searchedTitle },
      success: (response) => {
        const images = this.props.showNone ? [ { id: null, link: NONE, title: null}, ...response.images ] : response.images
        this.setStateProperty('images', images)
      },
      error: () => {},
      complete: () => { this.setStateProperty('isFetchingData', false) },
    })
  }

  handleFileChange(e) {
    const fileType = e.target.files[0].type
    if (e.target.files[0] !== undefined && (fileType === 'image/x-png' || fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg')) {
      this.setState({
        ...this.state,
        selectedForUpload: e.target.files,
        filenameForUpload: e.target.files[0].name.replace(/\.[^/.]+$/, ''),
      })
    } else {
      this.setState({
        ...this.state,
        selectedForUpload: '',
        filenameForUpload: '',
      })
    }

    if (!(fileType === 'image/x-png' || fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg')) {
      // eslint-disable-next-line no-alert
      alert(!window.I18n ? '' : window.I18n.t('photo_editor.album.file_type_alert'))
    }
  }

  handleUpload() {
    const imageInFormData = new FormData()
    imageInFormData.append('image', this.state.selectedForUpload[0] )
    imageInFormData.append('image_title', this.state.filenameForUpload )
    this.setStateProperty('isUploadingData', true)

    $.ajax({
      url: this.props.uploadImagePath,
      data: imageInFormData,
      processData: false,
      contentType: false,
      type: 'POST',
      success: (response) => {
        if (response.status === SUCCESS) {
          this.setState({
            ...this.state,
            images: [ ...this.state.images, response.image ],
            selected: response.image.link,
          })
          this.handleSave()
          this.props.onClose()
        }
      },
      error: () => {},
      complete: () => { this.setStateProperty('isUploadingData', false) },
    })
  }

  handleSave() {
    this.props.setImage(this.state.selected)
  }

  onSearchedTitleChange(newSearchedTitle) {
    this.setState({
      searchedTitle: newSearchedTitle,
    }, () => {
      this.fetchContent()
    })
  }

  handleFilenameChange(e) {
    if (e.target !== undefined) {
      this.setStateProperty('filenameForUpload', e.target.value)
    }
  }

  updateImageTitle(imageId, newImageTitle) {
    const imageInFormData = new FormData()
    imageInFormData.append('image_id', imageId )
    imageInFormData.append('image_title', newImageTitle )

    $.ajax({
      url: this.props.updateImageTitlePath,
      data: imageInFormData,
      processData: false,
      contentType: false,
      type: 'PUT',
      success: () => {
        const updatedImages = this.state.images
        updatedImages[updatedImages.findIndex(image => image.id === imageId)].title = newImageTitle
        this.setStateProperty('images', updatedImages)
      },
      error: () => {},
    })
  }

  render() {
    return <GlgPopup title={ this.props.title } saveButtonText={ this.props.saveText } saveDisableWith={ this.props.saveDisabledText } closeButtonText={ !window.I18n ? '' : window.I18n.t('buttons.cancel').charAt(0)
                          .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.cancel').slice(1)) } modalClass={'change-image'} show={ this.props.show } onClose={ this.props.onClose } onSave={ this.handleSave }><Row><Col xs={12} md={6} lg={6} xl={6}></Col><Col xs={12} md={6} lg={6} xl={6}><ImageTitleFilter value={ this.state.searchedTitle } onChange={ this.onSearchedTitleChange }></ImageTitleFilter></Col></Row><div className="well btsp_square_dotted"><div className="vertical-align-items"><input id="btn-choose-image" type="file" onChange={ this.handleFileChange } /><GlgButton text={ this.state.isUploadingData ? (!window.I18n ? '' : window.I18n.t('tv_shows.components.change_image_popup.uploading')) : this.props.uploadText } icon="arrow-circle-up" color="orange" disabled={ _.isEmpty(this.state.selectedForUpload) || this.state.isUploadingData } onClick={ this.handleUpload }></GlgButton><div className="add-margin-left-5"><GlgLoadingSpinner show={ this.state.isUploadingData } width={ 20 } height={ 20 }></GlgLoadingSpinner></div></div><Row><Col xs={12} md={6} lg={6} xl={6}>{ this.state.selectedForUpload.length === 0 ? null :
                <FormGroup><label htmlFor="asset_image_title" className="margin_right_5">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.change_image_popup.image_title') }</label><FormControl id="asset_image_title" type="text" placeholder={ !window.I18n ? '' : window.I18n.t('tv_shows.components.change_image_popup.image_title') } className="margin_top_10 width-70-percent d-inline-block" value={ this.state.filenameForUpload } onChange={ this.handleFilenameChange }></FormControl></FormGroup>
              }</Col></Row><p className="no-margin-bottom add-margin-top-10">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.change_image_popup.acceptable_photo_formats') }</p></div><div className="well white_background clearfix"><GlgLoadingSpinner show={ this.state.isFetchingData }></GlgLoadingSpinner><ul className="logos col-xs-12">{ !this.state.isFetchingData && this.state.images.map( (image, index) => <li key={ index } className="col-xs-6 col-md-4 col-lg-3"><div className={ `logo ${this.state.selected === image.link ? 'selected' : ''}` } onClick={ () => { this.setStateProperty('selected', image.link) } }>{ image.link === NONE ?
                    <div className="logo-none">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.change_image_popup.none') }</div> :
                    <img src={ image.link } />
                  }</div>{ image.link === NONE ? null :
                  <center className="margin_top_5"><EditableImageTitle title={ image.title } updateImageTitle={ (newImageTitle) => this.updateImageTitle(image.id, newImageTitle) }></EditableImageTitle></center>
                }</li>)}</ul></div></GlgPopup>
  }
}

ChangeImagePopup.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  showNone: PropTypes.bool,
  uploadText: PropTypes.string,
  saveText: PropTypes.string.isRequired,
  saveDisabledText: PropTypes.string.isRequired,
  fetchImagesPath: PropTypes.string.isRequired,
  uploadImagePath: PropTypes.string.isRequired,
  updateImageTitlePath: PropTypes.string.isRequired,
  setImage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

ChangeImagePopup.defaultProps = {
  uploadText: !window.I18n ? '' : window.I18n.t('tv_shows.components.change_image_popup.upload'),
  showNone: false,
}

export default ChangeImagePopup
