import React from 'react'
import PropTypes from 'prop-types'

const GolferPick = ({type, saveInProgress, golfer, moveToNowPicking, removeFromNowPicking}) => {

  return (
    <li className="golfer_pick" onClick={() => !type && !saveInProgress && moveToNowPicking(golfer)}>
      {type === 'now' && saveInProgress === false &&
        <div className="delete_golfer"><img src={require('delete.png')} onClick={removeFromNowPicking}/></div>}
      <div className="left_side">{golfer.rank}</div>
      <div className="right_side">
        <div className="name">{golfer.name}</div>
        <div className="hi">{golfer.hi}</div>
      </div>
    </li>
  )
}

GolferPick.propTypes = {
  type: PropTypes.string,
  saveInProgress: PropTypes.bool,
  golfer: PropTypes.any,
  moveToNowPicking: PropTypes.func,
  removeFromNowPicking: PropTypes.func,
}

export default GolferPick
