import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'

class DrivingDistancesFilterComponent extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    window.glg.new_design_shared.init_selectpicker()
  }
  
  render() {
    return (
      <Col xs={12} sm={6} md={6} lg={2} className='add-margin-bottom-20 filter_options'>
        <div className='pull-left add-margin-right-10 bold'>{ window.I18n.t('driving_distances.sort') }</div>
        <Col xs={12} sm={12} md={12} lg={12} className='no-padding-left add-padding-right-55'>
          <select className='selectpicker form-control' value={this.props.sortOption} onChange={ (event) => this.props.changeSortOption(event.target.value)}>
            <option key='hole' value='hole'>{ window.I18n.t('driving_distances.hole') }</option>
            <option key='pairing' value='pairing'>{ window.I18n.t('driving_distances.pairing_number') }</option>
            <option key='holeTime' value='holeTime'>{ window.I18n.t('driving_distances.filter.hole_time') }</option>
            <option key='distance' value='distance'>{ window.I18n.t('driving_distances.filter.distance') }</option>
          </select>
        </Col>
        <div className='input-group-addon sorting-order-addon' onClick={() => this.props.changeSortOrderOption()}>
          <a>
            {this.props.sortOrder === 'asc' && <img src={require('bootstrap_theme/icons/sort-by-down.png')} className='sorting-order-icon'/>}
            {this.props.sortOrder === 'desc' && <img src={require('bootstrap_theme/icons/sort-by-up.png')} className='sorting-order-icon'/>}
          </a>
        </div>
      </Col>
    )
  }
}

DrivingDistancesFilterComponent.propTypes = {
  changeSortOption: PropTypes.func,
  changeSortOrderOption: PropTypes.func,
  sortOption: PropTypes.string,
  sortOrder: PropTypes.string,
}

export default DrivingDistancesFilterComponent
