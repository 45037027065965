import { connect } from 'react-redux'
import EventSetupRoundSignups from '../../components/content/event_setup_round_signups'
import { extractValue as selectedRadioOption } from '../../components/content/event_setup/radio_group'
import {
  setGenericData,
  saveEventSettingsWidget,
  loadWidgetContent,
} from '../../actions/index'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { data, links, dataChanged } = ownProps.content
  const ott = data?.open_tee_times?.options
  const signupOptions = data?.signup_options?.options

  const setDataChanged = () => dataChanged || dispatch(setGenericData(ownProps.widgetId, [ 'dataChanged' ], true))
  const set = (keys, value, dataChanged = true) => {
    dispatch(setGenericData(ownProps.widgetId, keys, value))
    dataChanged && setDataChanged()
  }

  const formattedManagers = data.send_email_to_managers?.managers?.reduce((acc, manager) => {
    if (manager.selected) {
      acc[manager.id.toString()] = 'true'
    }
    return acc
  }, {})

  return {
    setData: set,
    save: () => {
      const response = {
        ...(ott && { [ott.ott_enabled.key]: ott.ott_enabled.selected }),
        ...(ott?.ott_enabled?.selected && ott.ott_enabled.reserved_foursome_number.enabled && { [ott.ott_enabled.reserved_foursome_number.key]: ott.ott_enabled.reserved_foursome_number.value }),
        ...(data?.signup_options && !ott?.ott_enabled?.selected && { [signupOptions.allow_round_signups.key]: signupOptions.allow_round_signups.selected}),
        ...(data?.signup_options && data?.signup_options?.guests_allowed && { [signupOptions.guests_allowed.key]: signupOptions.guests_allowed.selected}),
        ...(signupOptions?.guests_allowed?.selected && {[signupOptions.guests_allowed.max_additional_guests.key]: signupOptions.guests_allowed.max_additional_guests.value}),
        ...(! ott?.ott_enabled?.selected && data?.allow_message_to_manager && selectedRadioOption(data.allow_message_to_manager)),
        ...(! ott?.ott_enabled?.selected && data?.allow_message_to_manager?.selected && data?.send_email_to_managers && selectedRadioOption(data.send_email_to_managers)),
        ...(! ott?.ott_enabled?.selected && data?.allow_message_to_manager?.selected && data?.send_email_to_managers && {managers: formattedManagers}),
      }

      dispatch(saveEventSettingsWidget(ownProps.widgetId, links.save.path, response))
        .then(({ state }) => {
          const dependentWidgets = state.widgets[state.menu.selectedMenuItem].filter(w => w.content_type === 'event_setup_pairings')
          dependentWidgets.forEach(widget => {
            dispatch(loadWidgetContent(widget.data_path, widget.id))
          })
        })
    },
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(EventSetupRoundSignups)

