import createDeepSelector from './create_deep_selector'
import _ from 'lodash'

const getTours = (state = {}) => {
  return state.tours
}
const getAddAll = (state, { addAll = false } = {}) => {
  // addAll = !(state.misc.isGCAT && state.misc.directoryName === 'Local Tournaments') 
  return addAll
}
const getAddEmptyOption = (state, { addEmptyOption = true } = {}) => addEmptyOption

const getToursOptions = createDeepSelector(
  [ getTours, getAddAll, getAddEmptyOption ],
  (toursById, addAll, addEmptyOption) => {
    let tours = _.values(toursById)
    tours = _.sortBy(tours, tour => tour.name)
    if (addAll) {
      tours.push({
        id: 'all',
        name: !window.I18n ? '' : window.I18n.t('customer_directories.all_tours'),
      })
    }
    if (addEmptyOption) {
      // tours.push({
      //   id: 'none',
      //   name: 'Majors',
      // })
    }
    return tours
  },
)

export default getToursOptions
