import React, { Component } from 'react'
import PropTypes from 'prop-types'

class MenuBar extends Component {
  constructor(props){
    super(props)
  }

  render(){
    return (
      <div className="dashboard-menu-bar md-visible">
        <button className="dashboard-menu-hamburger-button" onClick={ () => this.props.toggleMenu() }>
          <i className="fa fa-bars"></i>
        </button>
      </div>
    )
  }
}

MenuBar.propTypes = {
  toggleMenu: PropTypes.func,
}

export default MenuBar
