import React, { Component, Fragment } from 'react'
import { GlgSelect, GlgTable, GlgTableCol, GlgButton } from 'SharedComponents'
import TextMessageDeliveryReportTableRow from './text_message_delivery_report_table_row'
import { Row, Col } from 'react-flexbox-grid'
import PropTypes from 'prop-types'

class TextMessageDeliveryReportsComponent extends Component {

  constructor(props) {
    super(props)

    this.state = {
      pages: [],
    }

    this.getReportsPage(props.textMessages.currentPage)

    this.getReportsPage = this.getReportsPage.bind(this)
  }

  getReportsPage(page) {
    const {
      setCurrentTextMessageReports,
      loadTextMessageDeliveryReportsPath,
      buildPageNumbers,
      perPage,
    } = this.props

    const requestFilters = { ...this.props.textMessages.filters }
    Object.keys(requestFilters).forEach((key) => {
      if (requestFilters[key] === 'default') {
        delete requestFilters[key]
      }
    })

    $.ajax({
      url: loadTextMessageDeliveryReportsPath,
      data: {page: page, perPage: perPage, ...requestFilters},
      type: 'POST',
      success: (response) => {
        setCurrentTextMessageReports(response.text_message_delivery_reports, page)
        this.setState({
          pages: buildPageNumbers(response.total_reports),
        })
      },
      error: () => {
        // eslint-disable-next-line no-console
        console.log('Error')
      },
    })
  }

  render() {
    const {
      formatDate,
      filterDropdownValues,
      setTextMessageFilterValue,
      textMessages: {
        filters,
      },
    } = this.props

    return <Fragment><Row className="vertical-align"><Col xs={4} lg={1} className="no-padding-right">{ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.text_message_delivery_reports_component.select_recipient') }</Col><Col xs={8} lg={2}><GlgSelect items={ filterDropdownValues.players } defaultValue={ filters.player } onChange={ setTextMessageFilterValue('player') } searchable={ true }></GlgSelect></Col><Col xs={4} lg={1} className="no-padding-right">{ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.text_message_delivery_reports_component.select_description') }</Col><Col xs={8} lg={2}><GlgSelect items={ filterDropdownValues.descriptions } defaultValue={ filters.description } onChange={ setTextMessageFilterValue('description') } searchable={ true }></GlgSelect></Col><Col xs={4} lg={1}><GlgButton text={ !window.I18n ? '' : window.I18n.t('buttons.go') } onClick={ () => { this.getReportsPage(1) }}></GlgButton></Col></Row><Row className="add-margin-top-10"><Col xs={12}><div className="pull-right">{this.state.pages.map((page) => {
                if (page !== this.props.textMessages.currentPage ) {
                  return <a href={'#' + page} key={page} onClick={ () => this.getReportsPage(page) } className="add-margin-left-3 add-margin-right-3">{page}</a>
                } else {
                  return <em key={page} className="add-margin-left-3 add-margin-right-3">{page}</em>
                }
              })}</div></Col></Row><Row className="add-margin-top-10"><Col xs={12}><GlgTable items={ this.props.textMessages.reports } rowComponent={ TextMessageDeliveryReportTableRow } rowExtraProps={{ formatDate: formatDate }}><GlgTableCol width={ '20%' } dataKey={ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.text_message_delivery_reports_component.player') }></GlgTableCol><GlgTableCol width={ '25%' } dataKey={ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.text_message_delivery_reports_component.description') }></GlgTableCol><GlgTableCol width={ '5%' } dataKey={ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.text_message_delivery_reports_component.body') }></GlgTableCol><GlgTableCol width={ '10%' } dataKey={ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.text_message_delivery_reports_component.to') }></GlgTableCol><GlgTableCol width={ '10%' } dataKey={ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.text_message_delivery_reports_component.status') }></GlgTableCol><GlgTableCol width={ '10%' } dataKey={ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.text_message_delivery_reports_component.last_update') }></GlgTableCol></GlgTable></Col></Row></Fragment>
  }
}

TextMessageDeliveryReportsComponent.propTypes = {
  textMessages: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    reports: PropTypes.array.isRequired,
    filters: PropTypes.shape({
      player: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
      description: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
    }).isRequired,
  }),
  filterDropdownValues: PropTypes.shape({
    players: PropTypes.array.isRequired,
    descriptions: PropTypes.array.isRequired,
  }).isRequired,
  perPage: PropTypes.number.isRequired,
  loadTextMessageDeliveryReportsPath: PropTypes.string.isRequired,
  setCurrentTextMessageReports: PropTypes.func.isRequired,
  setTextMessageFilterValue: PropTypes.func.isRequired,
  formatDate: PropTypes.func,
  buildPageNumbers: PropTypes.func,
}

export default TextMessageDeliveryReportsComponent
