import React, { Component } from 'react'
import GlgTab from './glg_tab'
import PropTypes from 'prop-types'

class GlgTabs extends Component{
  
  constructor(props) {
    super(props)

    this.onClickTabItem = this.onClickTabItem.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.firstTabLabel = this.firstTabLabel.bind(this)

    this.state = {
      activeTab: this.firstTabLabel(),
      hoveredTab: undefined,
    }
  }

  componentDidUpdate() {
    const tabLabels = this.props.children.filter(child => this.doesExist(child)).map( child => child.props.label )
    const activeTabPresent = !!tabLabels.find( label => label === this.state.activeTab )

    if (!activeTabPresent) {
      this.setState({
        ...this.state,
        activeTab: tabLabels[0],
      })
    }
  }

  doesExist(element) {
    return element !== undefined && element !== null
  }

  firstTabLabel() {
    const firstTab = this.props.children.find(child => this.doesExist(child))
    if (firstTab){
      return firstTab.props.label
    }

    return ''
  }

  onClickTabItem(tab) {
    this.setState({
      ...this.state,
      activeTab: tab,
      hoveredTab: undefined,
    })
  }

  handleMouseEnter(tab) {
    if (tab !== this.state.activeTab) {
      this.setState({
        ...this.state,
        hoveredTab: tab,
      })
    }
  }

  handleMouseLeave(tab) {
    if (tab !== this.state.activeTab) {
      this.setState({
        ...this.state,
        hoveredTab: undefined,
      })
    }
  }

  render() {
    const {
      onClickTabItem,
      props: {
        className,
      },
      state: {
        activeTab,
        hoveredTab,
      },
    } = this

    const children = this.props.children.filter( child => this.doesExist(child))

    return <div className={`tabs btsp_custom_tabs ${className}`}><nav className="navbar navbar-default"><ul className="btsp_custom_tabs_menu nav navbar-nav">{children.map( child => {
              const { label } = child.props

              return <GlgTab activeTab={activeTab} key={label} label={label} hoveredOver={hoveredTab === label} onClick={onClickTabItem} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}></GlgTab>
            })}</ul></nav><div className="btsp_custom_tabs_container">{children.map( child => {
            if (child.props.label !== activeTab) {
              return undefined
            }
            return child.props.children
          })}</div></div>

  }

}

GlgTabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  className: PropTypes.string,
}

export default GlgTabs
