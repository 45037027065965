import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'
import { Row } from 'react-bootstrap'

class TabForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '',
      titles: {},
      usesTranslations: this.props.usesTranslations,
      availableLanguages: this.props.availableLanguages,
      shouldHide: true,
      shouldHideButton: false,
    }
    this.setTitle = this.setTitle.bind(this)
    this.setTitles = this.setTitles.bind(this)
  }

  setTitle(_title) {
    this.setState({
      titles: _title,
    })
  }
  
  setTitles(_title, key) {
    this.setState({
      titles: {
        ...this.state.titles,
        [key]: _title,
      },
    })
  }
  
  onCreateTab() {
    this.props.createTab(this.state.title, this.state.titles)
  }

  render() {
    return (
      <Col xs={12} className="manage-columns">
        <Col xs={10} xsOffset={1} className="title">
          <h1>{window.I18n.t('multi_widgets.tabs.add_title')}</h1>
        </Col>
        <Col xs={10} xsOffset={1} className="inner-content">
          <Col xs={10} xsOffset={1} className="form-row">
            {!this.state.shouldHideButton &&
            <Row>
              <Col xs={4} className="labels">{window.I18n.t('multi_widgets.tabs.title_label')}</Col>
              <Col xs={6} className="form-group no-margin-bottom">
                <input type="text"
                      placeholder={window.I18n.t('multi_widgets.tabs.title_placeholder')}
                      className="form-control"
                      value={ this.state.titles[this.props.currentLanguage] }
                      onChange={ e => this.setTitles(e.target.value, this.props.currentLanguage) }
                      autoFocus />
              </Col>
            </Row>
            }
            {this.props.usesTranslations && !this.state.shouldHideButton && 
              <Row>
                {/* eslint-disable react/no-unknown-property */}
                <a xs={10} className="translation_check" href="javascript:void(0)"
                        onClick={ e => { e.preventDefault(); this.setState({ shouldHide: false, shouldHideButton: true }) } } >
                </a>
                {/* eslint-disable react/no-unknown-property */}
                <label xs={2} className="translation_text"
                        onClick={ e => { e.preventDefault(); this.setState({ shouldHide: false, shouldHideButton: true }) } } >
                        {window.I18n.t('pages.edit.portal_page_options.add_translation')}
                </label>
              </Row>
            }
            {this.state.availableLanguages.map(key => (
              <Row key={key} className={this.state.shouldHide ? 'hidden' : undefined}>
                <Col xs={4} className="labels margin-bottom-20">{window.I18n.t('pages.edit.portal_page_options.title_' + key)}</Col>
                <Col xs={6} className="form-group no-margin-bottom">
                  <input type="text"
                        placeholder={window.I18n.t('multi_widgets.tabs.title_placeholder')}
                        className="form-control"
                        value={ this.state.titles[key] }
                        onChange={ e => this.setTitles(e.target.value, key) }
                        autoFocus />
                </Col>
              </Row>
            ))}
          </Col>
        </Col>

        <Col xs={10} xsOffset={1} className="button-row">
          <button className="button submit save-page save-with-external-link btn btn-primary pull-right"
                  onClick={ e => { e.preventDefault(); this.onCreateTab() } } >
                {window.I18n.t('multi_widgets.save')}
          </button>
          <a className="button cancel grey btn btn-default pull-right margin-right-10"
             onClick={ e => { e.preventDefault(); this.props.cancel() } } >
            {window.I18n.t('multi_widgets.cancel')}
          </a>
        </Col>
      </Col>
    )
  }
}

TabForm.propTypes = {
  size: PropTypes.number,
  createTab: PropTypes.func,
  cancel: PropTypes.func,
  usesTranslations: PropTypes.bool,
  availableLanguages: PropTypes.any,
  currentLanguage: PropTypes.any,
}

export default TabForm
