import { connect } from 'react-redux'

import ScoringControlInterfaceTable from '../components/scoring_control_table'

import { getTeesFilteredByCourse, getSortedPairings } from '../selectors'

const mapStateToProps = (state) => {
  return {
    tees: getTeesFilteredByCourse(state),
    foursomes: getSortedPairings(state),
    scoreDisplay: state.filters.displayOption === 'scores' || state.scoringInterfaceData.isStableford,

    isStableford: state.scoringInterfaceData.isStableford,
    isMultiRound: state.scoringInterfaceData.isMultiRound,
    isLoadingTournament: state.scoringInterfaceData.isLoadingTournament,
    pastRounds: state.scoringInterfaceData.pastRounds,
    hasResults: state.scoringInterfaceData.hasResults,
    holeView: state.misc.holeView,
  }
}

export default connect(
  mapStateToProps,
  null
)(ScoringControlInterfaceTable)
