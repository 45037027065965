import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { GlgCheckbox, GlgTooltipIcon } from 'src/shared/components/'
import { GlgLoadingSpinner } from 'SharedComponents'
import { callAPI } from 'Shared/helpers'
import { getAuthenticityToken } from 'Shared/helpers'

const StyledPage = styled.div`
  .spinner {
    padding-top: 10px;
  }

  .checkbox_container {
    display: flex;
    padding: 20px;

    .description {
      max-width: 30vh;

      .sub_title {
        font-weight: bold;
        padding-bottom: 10px;
      }
    }
    .list_of_checkboxes {
      padding-left: 120px;
      display: flex;
      flex-direction: column;

      .check {
        display: flex;

        .icon_div {
          display: flex;
          align-items: center;
          padding-bottom: 4px;
        }
      }

      .set_selected {
        label {
          font-weight: bold;
        }
      }

      .not_clickable {
        pointer-events: none;
      }
    }
  }
`

const CheckboxIndex = (props) => {
  const [ defaultSettings, setDefaultSettings ] = useState({})
  const [ dataIsLoading, setDataIsLoading ] = useState(true)

  useEffect(() => {
    callAPI(`/customers/${props.customerId}/default_visibility_settings`, 'GET')
    .then((json) => {
      setDefaultSettings(json)
      setDataIsLoading(false)
    })
  }, [])

  const updateVisability = (clause) => {
    setDefaultSettings(prevSetting => {
      const updatedSetting = {
        ...prevSetting,
        [clause]: !prevSetting[clause],
      }

      fetch(`/customers/${props.customerId}/default_visibility_settings/${defaultSettings['settings_id']}?my_hubs=${updatedSetting['golfhub_page_visibility_my_hubs']}&agg_hubs=${updatedSetting['golfhub_page_visibility_aggregated_hubs']}`, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': getAuthenticityToken(),
        },
      })

      return updatedSetting
    })
  }

  const updateRelationships = (currentCustomerId, parentId) => {
    const updatedSetting = { ...defaultSettings }

    updatedSetting.customer_parents = updatedSetting.customer_parents.map((parent) => {
      if (parent.id === parentId) {
        return {
          ...parent,
          is_included_in_parent_hub: !parent.is_included_in_parent_hub,
        }
      }

      return parent
    })

    setDefaultSettings(updatedSetting)
    fetch(`/api/customers/${props.customerId}/update_parent_include_relationship?customer_id=${currentCustomerId}&parent_id=${parentId}`, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': getAuthenticityToken(),
      },
    })
  }

  const updateDefaultSetting = (clause, hubName) => {
    if (defaultSettings[clause]) {
      // eslint-disable-next-line no-alert
      if (confirm(window.I18n.t('views.golfhub.checkbox_change_confirmation', {hub_name: hubName}))) {
        updateVisability(clause)
      }
    } else {
      updateVisability(clause)
    }
  }

  const updateParentIncludeSetting = (currentCustomerId, parentId, hubName) => {
    const isChecked = defaultSettings['customer_parents'].find(entry => entry.id === parentId)['is_included_in_parent_hub']
    if (isChecked) {
      // eslint-disable-next-line no-alert
      if (confirm(window.I18n.t('views.golfhub.checkbox_change_confirmation', {hub_name: hubName}))) {
        updateRelationships(currentCustomerId, parentId)
      }
    } else {
      updateRelationships(currentCustomerId, parentId)
    }
  }

  return (
    <StyledPage>
      { dataIsLoading && (
        <GlgLoadingSpinner className='spinner text-center' show={dataIsLoading} />
      )}
      {!dataIsLoading && (
        <div className="checkbox_container">
          <div className="description">
            <div className="sub_title">
              { window.I18n.t('views.golfhub.checkbox_setting') }
            </div>
            <div className="long_desc">
              { window.I18n.t('views.golfhub.checkbox_desc_setting') }
            </div>
          </div>
          <div className="list_of_checkboxes">
            <div className={`check my_hubs ${defaultSettings['golfhub_page_visibility_my_hubs'] ? 'set_selected' : ''}`}
                onClick={() => updateDefaultSetting('golfhub_page_visibility_my_hubs', window.I18n.t('views.golfhub.my_hubs'))}>
              <div className="not_clickable">
                <GlgCheckbox
                  name="golfhub_page_visibility_my_hubs"
                  label={ window.I18n.t('views.golfhub.my_hubs') }
                  checked={ defaultSettings['golfhub_page_visibility_my_hubs'] }
                />
              </div>
              <div className="icon_div">
                <GlgTooltipIcon
                  tooltip = { window.I18n.t('views.golfhub.my_hubs_tooltip') }
                />
              </div>
            </div>
            {defaultSettings['customer_parents'] !== undefined && defaultSettings['customer_parents'].length > 0 && defaultSettings['customer_parents'].map((setting) => {
              return (
                <div className={`check parent_checkbox ${setting['is_included_in_parent_hub'] ? 'set_selected' : ''}`} 
                    key={setting['id']}
                    onClick={() => updateParentIncludeSetting(props.customerId, setting['id'], setting['name'])}>
                  <div className="not_clickable">
                    <GlgCheckbox
                      name={ setting['name'] }
                      label={ setting['name'] + ' ' + window.I18n.t('views.golfhub.hubs') }
                      checked={ setting['is_included_in_parent_hub'] }
                    />
                  </div>
                  <div className="icon_div">
                    <GlgTooltipIcon
                      tooltip = { window.I18n.t('views.golfhub.parent_hubs_tooltip', {parent: setting['name']}) }
                    />
                  </div>
                </div>
              )
            })}
            <div className={`check agg_hubs ${defaultSettings['golfhub_page_visibility_aggregated_hubs'] ? 'set_selected' : ''}`} 
                onClick={() => updateDefaultSetting('golfhub_page_visibility_aggregated_hubs', window.I18n.t('product') + ' ' + window.I18n.t('views.golfhub.hubs'))}>
              <div className="not_clickable">
                <GlgCheckbox
                  name="golfhub_page_visibility_aggregated_hubs"
                  label={ window.I18n.t('product') + ' ' + window.I18n.t('views.golfhub.hubs') }
                  checked={ defaultSettings['golfhub_page_visibility_aggregated_hubs'] }
                />
              </div>
              <div className="icon_div">
                <GlgTooltipIcon
                  tooltip = { window.I18n.t('views.golfhub.golf_genius_hubs_tooltip') }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </StyledPage>
  )
}

CheckboxIndex.propTypes = {
  setPage: PropTypes.func,
  customerId: PropTypes.string,
}

export default CheckboxIndex
