import { connect } from 'react-redux'

import PortalsListComponent from '../components/portals_list_component'


const mapStateToProps = (state) => {
  
  return {
    portals: Object.values(state.portals),
    dataIsLoading: !!state.misc.dataIsLoading,
    scrollPosition: state.filters.scroll,
  }
}

const mapDispatchToProps = () => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PortalsListComponent)
