import React from 'react'
import PropTypes from 'prop-types'
import {Modal} from 'react-bootstrap'

const ModalPopup = ({openModal, onOkClicked, saveInProgress}) => {
  return (
    <Modal show={openModal} popup="true">
      <Modal.Body>
        <div className="text-center">
          <h4 dangerouslySetInnerHTML={{ __html: window.I18n.t('draft.components.manager_side.modal_popup.draft_is_completed') }}/>
          <button type="button" className="btsp_orange_button" onClick={onOkClicked} disabled={saveInProgress}>
            {window.I18n.t(`buttons.${saveInProgress ? 'redirecting' : 'ok'}`)}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

ModalPopup.propTypes = {
  openModal: PropTypes.bool,
  onOkClicked: PropTypes.func,
  saveInProgress: PropTypes.bool,
}

export default ModalPopup
