import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'

class ShotByShotFiltersComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Col xs={6} sm={6} md={3} lg={3} className='add-margin-bottom-20 filter_options'>
        <div className='pull-left add-margin-right-10 bold'>Sort By</div>
        <Col xs={12} sm={12} md={12} lg={12} className='no-padding-left add-padding-right-55'>
          <select className='selectpicker form-control' value={this.props.sortOption} onChange={ (event) => this.props.changeSortOption(event.target.value)}>
            <option key='pairing' value='pairing'>Pairing group number</option>
            <option key='tee_time' value='tee_time'>Tee Time</option>
          </select>
        </Col>
        <div className='input-group-addon sorting-order-addon' onClick={() => this.props.changeSortOrderOption()}>
            <a>
              {this.props.sortOrder === 'asc' && <img src={require('bootstrap_theme/icons/sort-by-down.png')} className='sorting-order-icon'/>}
              {this.props.sortOrder === 'desc' && <img src={require('bootstrap_theme/icons/sort-by-up.png')} className='sorting-order-icon'/>}
            </a>  
          </div>
      </Col>
    )
  }
}

ShotByShotFiltersComponent.propTypes = {
  changeSortOption: PropTypes.func,
  changeSortOrderOption: PropTypes.func,
  sortOption: PropTypes.string,
  sortOrder: PropTypes.string,
}

export default ShotByShotFiltersComponent
