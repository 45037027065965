import React from 'react'
import PropTypes from 'prop-types'

const StickToBreadcrumb = ({ children, className }) => {
  const classes = `alert alert-warning product_warning stick_to_breadcrumb ${ className || '' }`
  return <div className={ classes }>{ children }</div>
}

StickToBreadcrumb.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default StickToBreadcrumb
