export const ActionTypes = {
  SET_FOOTER_UNSAVED_CHANGES: 'SET_FOOTER_UNSAVED_CHANGES',
  TOGGLE_VISIBLE_FOOTER: 'TOGGLE_VISIBLE_FOOTER',
  PLAINLY_SET_FOOTER_PROPERTY: 'PLAINLY_SET_FOOTER_PROPERTY',
  SET_FOOTER_POPUP_PROPERTY: 'SET_FOOTER_POPUP_PROPERTY',
}

export const setUnsavedChanges = (value) => {
  return {
    type: ActionTypes.SET_FOOTER_UNSAVED_CHANGES,
    value,
  }
}

export const toggleVisible = () => {
  return {
    type: ActionTypes.TOGGLE_VISIBLE_FOOTER,
  }
}

export const plainlySetFooterProperty = (property, value) => {
  return {
    type: ActionTypes.PLAINLY_SET_FOOTER_PROPERTY,
    property,
    value,
  }
}

export const setPopupProperty = (popupType, property, value) => {
  return {
    type: ActionTypes.SET_FOOTER_POPUP_PROPERTY,
    popupType,
    property,
    value,
  }
}
