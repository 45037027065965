import React from 'react'
import configureStore from 'Shared/configure_store'
import rootReducer from '../reducers/setup'
import { GlobalProvider } from 'Shared/global_context'
import PropTypes from 'prop-types'

import MarketingCampaignsSetupContainer from './marketing_campaigns_setup_container'

// const formatDateOfBirth = (date) => {
//   const day = String(date.getDate()).padStart(2, '0')
//   const month = String(date.getMonth() + 1).padStart(2, '0')
//   const year = String(date.getFullYear())
// 
//   return month + '/' + day + '/' + year
// }

const defaultSettingsNewCampaign = (props) => {
  return {
    general: {
      name: '',
    },
    channels: {
      email: {
        checked: !props.customerCanTextMessage, // checked by default if customer cannot send text messages
        template: props.emailTemplates.length > 0 ? props.emailTemplates[0].id : '',
        allTemplates: props.emailTemplates,
      },
      textMessage: {
        checked: false,
        template: props.textMessageTemplates.length > 0 ? props.textMessageTemplates[0].id : '',
        allTemplates: props.textMessageTemplates,
      },
    },
    triggers: {
      registrationOpensIn: {
        checked: false,
        days: '2',
        events: 'allEvents',
      },
      registrationRecentlyOpened: {
        checked: false,
        days: '2',
        events: 'allEvents',
      },
      registrationBelowTarget: {
        checked: false,
        percentage: '80',
        events: 'allEvents',
        pauseEvaluation: {
          checked: true,
          days: '7',
        },
      },
      registrationAlmostFull: {
        checked: false,
        percentage: '80',
        events: 'allEvents',
        pauseEvaluation: {
          checked: true,
          days: '7',
        },
      },
      registrationClosesIn: {
        checked: false,
        days: '2',
        events: 'allEvents',
      },
      afterEventEnd: {
        checked: false,
        days: '2',
        events: 'allEvents',
      },
      afterRoundEnd: {
        checked: false,
        days: '2',
        events: 'allEvents',
      },
    },
    recipientList: {
      membershipProgram: {
        checked: false,
        filters: [ {
          id: 1,
          includeExclude: 'include',
          membershipId: 'default',
          equalGreaterOrEqual: 'equal',
          days: '2',
        } ],
      },
      gender: {
        checked: false,
        selected: 'M',
      },
      age: {
        checked: false,
        date: '',
        minimum: '',
        maximum: '',
      },
      handicapIndex: {
        checked: false,
        minimumValue: '',
        maximumValue: '',
      },
      customField: {
        checked: false,
        filters: [ {
          id: 1,
          includeExclude: 'include',
          customFieldId: 'default',
          customFieldValue: 'default',
        } ],
      },
      campaignActivity: {
        checked: false,
        days: '10',
      },
      roundAttendance: {
        checked: false,
        selected: [ 'confirmed' ],
      },
    },
    schedule: {
      frequency: 'daily',
      hour: '12:00_am',
      weekday: 'Sunday',
      dayOfMonth: '1',
    },
  }
}

const defaultSettingsEditCampaign = (props) => {
  const {
    name,
    email,
    textMessage,
    triggers,
    recipientList,
    schedule,
  } = props.currentSettings

  return {
    general: {
      name: name,
    },
    channels: {
      email: {
        checked: !!email,
        template: email && props.emailTemplates.length > 0 ? email : '',
        allTemplates: props.emailTemplates,
      },
      textMessage: {
        checked: !!textMessage,
        template: textMessage && props.textMessageTemplates.length > 0 ? textMessage : '',
        allTemplates: props.textMessageTemplates,
      },
    },
    triggers: {
      registrationOpensIn: {
        checked: !!triggers.registrationOpensIn,
        days: triggers.registrationOpensIn && triggers.registrationOpensIn.days ? triggers.registrationOpensIn.days : '2',
        events: triggers.registrationOpensIn && triggers.registrationOpensIn.events ? triggers.registrationOpensIn.events : 'allEvents',
      },
      registrationRecentlyOpened: {
        checked: !!triggers.registrationRecentlyOpened,
        days: triggers.registrationRecentlyOpened && triggers.registrationRecentlyOpened.days ? triggers.registrationRecentlyOpened.days : '2',
        events: triggers.registrationRecentlyOpened && triggers.registrationRecentlyOpened.events ? triggers.registrationRecentlyOpened.events : 'allEvents',
      },
      registrationBelowTarget: {
        checked: !!triggers.registrationBelowTarget,
        percentage: triggers.registrationBelowTarget && triggers.registrationBelowTarget.percentage ? triggers.registrationBelowTarget.percentage : '80',
        events: triggers.registrationBelowTarget && triggers.registrationBelowTarget.events ? triggers.registrationBelowTarget.events : 'allEvents',
        pauseEvaluation: {
          checked: triggers.registrationBelowTarget && triggers.registrationBelowTarget.pauseEvaluation.checked || !triggers.registrationBelowTarget,
          days: triggers.registrationBelowTarget && triggers.registrationBelowTarget.pauseEvaluation.checked ? triggers.registrationBelowTarget.pauseEvaluation.days : '7',
        },
      },
      registrationAlmostFull: {
        checked: !!triggers.registrationAlmostFull,
        percentage: triggers.registrationAlmostFull && triggers.registrationAlmostFull.percentage ? triggers.registrationAlmostFull.percentage : '80',
        events: triggers.registrationAlmostFull && triggers.registrationAlmostFull.events ? triggers.registrationAlmostFull.events : 'allEvents',
        pauseEvaluation: {
          checked: triggers.registrationAlmostFull && triggers.registrationAlmostFull.pauseEvaluation.checked || !triggers.registrationAlmostFull,
          days: triggers.registrationAlmostFull && triggers.registrationAlmostFull.pauseEvaluation.checked ? triggers.registrationAlmostFull.pauseEvaluation.days : '7',
        },
      },
      registrationClosesIn: {
        checked: !!triggers.registrationClosesIn,
        days: triggers.registrationClosesIn && triggers.registrationClosesIn.days ? triggers.registrationClosesIn.days : '2',
        events: triggers.registrationClosesIn && triggers.registrationClosesIn.events ? triggers.registrationClosesIn.events : 'allEvents',
      },
      afterEventEnd: {
        checked: !!triggers.afterEventEnd,
        days: triggers.afterEventEnd && triggers.afterEventEnd.days ? triggers.afterEventEnd.days : '2',
        events: triggers.afterEventEnd && triggers.afterEventEnd.events ? triggers.afterEventEnd.events : 'allEvents',
      },
      afterRoundEnd: {
        checked: !!triggers.afterRoundEnd,
        days: triggers.afterRoundEnd && triggers.afterRoundEnd.days ? triggers.afterRoundEnd.days : '2',
        events: triggers.afterRoundEnd && triggers.afterRoundEnd.events ? triggers.afterRoundEnd.events : 'allEvents',
      },
    },
    recipientList: {
      membershipProgram: {
        checked: !!recipientList.membershipProgram,
        filters: recipientList.membershipProgram && recipientList.membershipProgram.filters ?
          recipientList.membershipProgram.filters :
          [ {
            id: 1,
            includeExclude: 'include',
            membershipId: 'default',
            equalGreaterOrEqual: 'equal',
            days: '2',
          } ],
      },
      gender: {
        checked: !!recipientList.gender,
        selected: recipientList.gender && recipientList.gender.selected ? recipientList.gender.selected : 'M',
      },
      age: {
        checked: !!recipientList.age,
        date: recipientList.age && recipientList.age.date ? recipientList.age.date : '',
        minimum: recipientList.age && recipientList.age.minimum ? recipientList.age.minimum : '',
        maximum: recipientList.age && recipientList.age.maximum ? recipientList.age.maximum : '',
      },
      handicapIndex: {
        checked: !!recipientList.handicapIndex,
        minimumValue: recipientList.handicapIndex && recipientList.handicapIndex.minimumValue ?
          recipientList.handicapIndex.minimumValue : '',
        maximumValue: recipientList.handicapIndex && recipientList.handicapIndex.maximumValue ?
          recipientList.handicapIndex.maximumValue : '',
      },
      customField: {
        checked: !!recipientList.customField,
        filters: recipientList.customField && recipientList.customField.filters ?
          recipientList.customField.filters :
          [ {
            id: 1,
            includeExclude: 'include',
            customFieldId: 'default',
            customFieldValue: 'default',
          } ],
      },
      campaignActivity: {
        checked: !!recipientList.campaignActivity,
        days: recipientList.campaignActivity && recipientList.campaignActivity.days ?
          recipientList.campaignActivity.days : '10',
      },
      roundAttendance: {
        checked: !!recipientList.roundAttendance,
        selected: recipientList.roundAttendance && recipientList.roundAttendance.selected ? recipientList.roundAttendance.selected : [ 'confirmed' ],
      },
    },
    schedule: {
      frequency: schedule.frequency || 'daily',
      hour: schedule.hour || '12:00_am',
      weekday: schedule.weekday || 'Sunday',
      dayOfMonth: schedule.dayOfMonth || '1',
    },
  }
}

const MarketingCampaignsSetupRoot = (props) => {
  const state = props.currentSettings ? defaultSettingsEditCampaign(props) : defaultSettingsNewCampaign(props)
  const store = configureStore(rootReducer, state)

  return <GlobalProvider store={ store }><MarketingCampaignsSetupContainer paths={ props.paths } leagueCategories={props.leagueCategories} membershipPrograms={props.membershipPrograms} customFields={props.customFields} customerId={props.customerId} customerCanTextMessage={props.customerCanTextMessage} customerCanUseMembershipPrograms={props.customerCanUseMembershipPrograms}></MarketingCampaignsSetupContainer></GlobalProvider>
}

MarketingCampaignsSetupRoot.propTypes = {
  paths: PropTypes.shape({
    cancel: PropTypes.string.isRequired,
    emailPreview: PropTypes.string.isRequired,
    textMessagePreview: PropTypes.string.isRequired,
    save: PropTypes.string,
    saveAndSchedule: PropTypes.string,
    update: PropTypes.string,
    manageEmailTemplates: PropTypes.string.isRequired,
    manageTextMessageTemplates: PropTypes.string.isRequired,
  }),
  emailTemplates: PropTypes.array.isRequired,
  textMessageTemplates: PropTypes.array.isRequired,
  leagueCategories: PropTypes.array.isRequired,
  membershipPrograms: PropTypes.array.isRequired,
  customFields: PropTypes.object.isRequired,
  currentSettings: PropTypes.object,
  customerId: PropTypes.string.isRequired,
  customerCanTextMessage: PropTypes.bool.isRequired,
  customerCanUseMembershipPrograms: PropTypes.bool.isRequired,
}

export default MarketingCampaignsSetupRoot
