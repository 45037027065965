import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import ShotByShotFiltersContainer from '../containers/shot_by_shot_filters_container'
import LegendComponent from './legend_component'
import ShotByShotTableContainer from '../containers/shot_by_shot_table_container'
import HoleSummaryContainer from '../containers/hole_summary_containter'
import SaveBarComponent from './save_bar_component'
class ShotByShotViewComponent extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.timer = setInterval(this.props.refreshData, 30000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    return (
      <Fragment>
        <ShotByShotFiltersContainer />
        <LegendComponent />
        <ShotByShotTableContainer />
        {this.props.visible && <HoleSummaryContainer/>}
        <SaveBarComponent 
          randomShots={this.props.randomShots}
          recomputeStats={this.props.recomputeStats}
          deleteShots={this.props.deleteShots}
          isAdmin={this.props.isAdmin}
          isCustomerManager={this.props.isCustomerManager}
          disableDelete={this.props.hasVerifiedScores}
          />
      </Fragment>
    )
  }

}

ShotByShotViewComponent.propTypes = {
  leagueId: PropTypes.string,
  roundId: PropTypes.string,
  refreshData: PropTypes.func,
  visible: PropTypes.bool,
  closePopup: PropTypes.func,
  randomShots: PropTypes.string,
  recomputeStats: PropTypes.string,
  deleteShots: PropTypes.string,
  isAdmin: PropTypes.bool,
  isCustomerManager: PropTypes.bool,
  hasVerifiedScores: PropTypes.bool,
}

export default ShotByShotViewComponent
