import { connect } from 'react-redux'

import PlayerActivityDashboardComponent from '../components/player_activity_dashboard_component'

import { fetchInitialData } from '../actions'

const mapStateToProps = () => ({
})

const mapDispatchToProps = (dispatch) => ({
  fetchTransactionsData: () => dispatch(fetchInitialData('transactionsHistory')),
  fetchParticipationHistoryData: () => dispatch(fetchInitialData('participationHistory')),
  fetchMembershipProgramsData: () => dispatch(fetchInitialData('membershipPrograms')),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerActivityDashboardComponent)
