export const ActionTypes = {
  SET_HEADER_UNSAVED_CHANGES: 'SET_HEADER_UNSAVED_CHANGES',
  TOGGLE_VISIBLE: 'TOGGLE_VISIBLE',
  CHANGE_HEIGHT: 'CHANGE_HEIGHT',
  CHANGE_OPACITY: 'CHANGE_OPACITY',
  SELECT_BACKGROUND_TYPE: 'SELECT_BACKGROUND_TYPE',
  PLAINLY_SET_HEADER_PROPERTY: 'PLAINLY_SET_HEADER_PROPERTY',
  SET_POPUP_PROPERTY: 'SET_POPUP_PROPERTY',
}

export const setUnsavedChanges = (value) => {
  return {
    type: ActionTypes.SET_HEADER_UNSAVED_CHANGES,
    value,
  }
}

export const toggleVisible = () => {
  return {
    type: ActionTypes.TOGGLE_VISIBLE,
  }
}

export const changeHeight = (newHeight) => {
  return {
    type: ActionTypes.CHANGE_HEIGHT,
    newHeight,
  }
}

export const changeOpacity = (newOpacity) => {
  return {
    type: ActionTypes.CHANGE_OPACITY,
    newOpacity,
  }
}

export const plainlySetHeaderProperty = (property, value) => {
  return {
    type: ActionTypes.PLAINLY_SET_HEADER_PROPERTY,
    property,
    value,
  }
}

export const setPopupProperty = (popupType, property, propertyValue) => {
  return {
    type: ActionTypes.SET_POPUP_PROPERTY,
    popupType,
    property,
    propertyValue,
  }
}
