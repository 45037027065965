import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import DisplayColumns from '../containers/display_columns'

class Block extends Component {
  constructor(props) {
    super(props)

    this.onRemoveBlock = this.onRemoveBlock.bind(this)
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  onRemoveBlock() {
    const result = window.confirm(window.I18n.t('multi_widgets.block.confirm_delete')) // eslint-disable-line no-alert
    if (result) {
      this.props.onRemove()
    }
  }

  render() {
    return (
      <Col xs={12} className="block-container">
        <Row top="xs" className="block-menubar themed-box">
          <Col xs={9} md={10} className="title">
            { `${ this.props.titles ? (this.props.titles[this.props.currentLanguage] ? this.props.titles[this.props.currentLanguage] : this.props.title) : this.props.title} ` }
          </Col>
          <Col xs={3} md={2} className="right-container">
            <span onClick={ () => this.onRemoveBlock() } className="wrapper right" data-toggle="tooltip" data-placement="left" data-original-title={window.I18n.t('multi_widgets.block.remove')}>
              <i className="fa fa-trash"></i>
            </span>
            <span onClick={ this.props.onEdit } className="wrapper" data-toggle="tooltip" data-placement="left" data-original-title={window.I18n.t('multi_widgets.block.edit')}>
              <i className="fa fa-pencil fa-fw"></i>
            </span>
          </Col>
        </Row>
        <Col className="block-content">
          <DisplayColumns blockID={ this.props.id } LAYOUT={ this.props.layout } />
        </Col>
      </Col>
    )
  }
}

Block.propTypes = {
  title: PropTypes.string,
  titles: PropTypes.any,
  columns: PropTypes.array,
  id: PropTypes.any,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  layout: PropTypes.string,
  currentLanguage: PropTypes.any,
}

export default Block
