import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'

import ParticipationHistoryRoundTableRow from './participation_history_round_table_row'

import {
  GlgTable,
  GlgTableCol,
  GlgLoadingSpinner,
} from 'SharedComponents'
import PaginationComponent from './pagination_component'

class RoundListComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let rounds = ''
    let pagination = ''
    let sectionTitle = this.props.sectionTitle

    if (!this.props.dataIsLoading){
      if (this.props.roundsCount === 0) {
        rounds = <div className="no-info">{ this.props.noInfoMessage }</div>
      } else {
        const extraProps = {
          target: this.props.target,
          isOpenedLeaderboardPopup: this.props.isOpenedLeaderboardPopup,
          currentlyRoundLeaderboardPopup: this.props.currentlyRoundLeaderboardPopup,
          past: this.props.past,
          onLeaderboardOpen: this.props.onLeaderboardOpen,
          loadingLeaderboards: this.props.loadingLeaderboards,
          fetchLeaderboardStart: this.props.fetchLeaderboardStart,
          fetchLeaderboardEnd: this.props.fetchLeaderboardEnd,
        }
        const lastRoundIndex = this.props.page * 10
        const firstRoundIndex = lastRoundIndex - 10
        const visibleRounds = this.props.rounds.slice(firstRoundIndex, lastRoundIndex)
        rounds = <GlgTable items={ visibleRounds } rowComponent={ ParticipationHistoryRoundTableRow } rowExtraProps={ extraProps }><GlgTableCol width={ '30%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.name') }></GlgTableCol><GlgTableCol width={ '20%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.season') }></GlgTableCol><GlgTableCol width={ '20%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.date') }></GlgTableCol><GlgTableCol width={ '15%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.tee_time') }></GlgTableCol><GlgTableCol width={ '15%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.actions') }></GlgTableCol></GlgTable>
        sectionTitle += ' • ' + this.props.roundsCount
        if (this.props.roundsCount > 10) {
          pagination = <PaginationComponent page={ this.props.page } elementsCount={ this.props.roundsCount } setCurrentPage={ this.props.setCurrentPage }></PaginationComponent>
        }
      }
    }

    return <Col xs={ 12 }><Row className="title no-lateral-margin">{ sectionTitle }</Row><Row className="no-lateral-margin"><GlgLoadingSpinner show={ this.props.dataIsLoading }></GlgLoadingSpinner>{ pagination }{ rounds }</Row></Col>
  }
}

RoundListComponent.propTypes = {
  rounds: PropTypes.array.isRequired,
  roundsCount: PropTypes.number.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  noInfoMessage: PropTypes.string.isRequired,
  dataIsLoading: PropTypes.bool,
  target: PropTypes.string,
  past: PropTypes.bool,
  isOpenedLeaderboardPopup: PropTypes.bool,
  currentlyRoundLeaderboardPopup: PropTypes.string,
  onLeaderboardOpen: PropTypes.func,
  loadingLeaderboards: PropTypes.bool,
  fetchLeaderboardStart: PropTypes.func,
  fetchLeaderboardEnd: PropTypes.func,
  page: PropTypes.number,
  setCurrentPage: PropTypes.func,
}

export default RoundListComponent
