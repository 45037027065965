import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, InputGroup, FormControl } from 'react-bootstrap'
import { Icon as FaIcon } from 'react-fa'
const { Addon } = InputGroup
import { GlgTooltip } from 'SharedComponents'

class Search extends PureComponent {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.onReset = this.onReset.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.inputRef = React.createRef()
  }

  onChange(event) {
    this.props.onChange('leagueName', event.target.value)
  }

  onReset() {
    this.props.onChange('leagueName', '')
    this.inputRef.focus()
  }

  handleBlur() {
    this.props.onChange('leagueNameRequest', event.target.value)
  }

  render() {
    return <FormGroup className="filter btsp_filter"><GlgTooltip tooltip={ !window.I18n ? '' : window.I18n.t('customer_directories.filters.search_tooltip') } placement={'bottom'}><InputGroup><FormControl type="text" value={ this.props.value } placeholder={ !window.I18n ? '' : window.I18n.t('customer_directories.filters.search_placeholder') } onChange={ this.onChange } onBlur={ this.handleBlur } inputRef={ ref => { this.inputRef = ref } }></FormControl><Addon><a onClick={ this.onReset } className="clickable"><FaIcon name="eraser"></FaIcon></a></Addon></InputGroup></GlgTooltip></FormGroup>
  }
}

Search.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
export default Search
