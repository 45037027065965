import React from 'react'
import PropTypes from 'prop-types'

import HoleDataContainer from '../containers/hole_data_container'

const foursomeData = ({foursome, isMultiRound}) => {
  const players = [ ...foursome.players ]
  return (
    players.map( (player, index) => {
      const holes = [ ...player.holes_data ]
      const pars = [ ...player.pars_array ]
      const firstRowClass = index === 0 ? ' first_row' : ''
      const scoreVerified = foursome.score_verified || player.score_verified
      const allCompleted = player.in_completed && player.out_completed
      return (
        <tr key={player.id} className={'player_row' + firstRowClass}>
          { index === 0 && 
            <td rowSpan={foursome.size} className='first'>
              <p>{foursome.position}.</p>
              <p><b>Course: </b>{foursome.course_name}</p>
              <p><b>Starting Hole: </b>{foursome.starting_hole}</p>
              <p>{foursome.pretty_tee_time}</p>
            </td>
          }
          <td className='second'>
            <div className='handle_text'>
               <span className='full_name'>{player.name}</span>
            </div>
            <div className='disp_label'>{player.disp}</div>
          </td>
          { isMultiRound && <td className='blue_column'>{player.prior}</td> }
          { [ ...Array(9) ].map( ( _, i) =>
            <HoleDataContainer key={'hole-' + i} 
                              playerName={player.name}
                              foursomePosition={foursome.position}
                              holeNumber={i + 1}
                              holeData={holes[i]}
                              par={pars[i]}
                              scoreVerified={scoreVerified || allCompleted}/> )
          }
          <td className='blue_column'>{player.out_completed ? player.out : ''}</td>
          { [ ...Array(9) ].map( ( _, i) => 
            <HoleDataContainer key={'hole-' + i}
                              playerName={player.name}
                              foursomePosition={foursome.position}
                              holeNumber={i + 10}
                              holeData={holes[i + 9]}
                              par={pars[i + 9]}
                              scoreVerified={scoreVerified || allCompleted}/> )
          }
          <td className='blue_column'>{player.in_completed ? player.in : ''}</td>
          <td className='blue_column'>{allCompleted ? player.score : ''}</td>
          { isMultiRound && <td>{player.today}</td>}
          { isMultiRound && <td>{player.total}</td> }
        </tr>
      )
    })
  )
}

foursomeData.propTypes = {
  foursome: PropTypes.object,
  isMultiRound: PropTypes.bool,
}

export default foursomeData
