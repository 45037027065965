import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'

import DrivingDistanceData from './driving_distance_data'

class DrivingDistancesTableComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const players = [ ...this.props.players ]
    return (
      <Col xs={12} sm={12} md={12} lg={12} className='no-padding'>
        <div className='driving_distances_table btsp_ps_container btsp_fheader_table_container'>
          {players.length > 0 ?
            <table className='driving_distances btsp_fheader_table table-bordered'>
              <thead>
              <Fragment>
                <tr className='header'>
                  <th className='hole'>
                    <span>{ window.I18n.t('driving_distances.pairing_number') }</span>
                  </th>
                  <th className='pairing'>
                    <span>{ window.I18n.t('driving_distances.hole') }</span>
                  </th>
                  <th>
                    <span>{ window.I18n.t('driving_distances.table_headers.name') }</span>
                  </th>
                  <th>{ window.I18n.t('driving_distances.table_headers.distance', {distanceUnit: this.props.distanceUnit}) }</th>
                  { this.props.adjustment !== 0 &&
                    <th>
                      <span>{window.I18n.t('driving_distances.table_headers.distance_adjust', {distanceUnit: this.props.distanceUnit})}</span>
                    </th>
                  }
                  <th>{ window.I18n.t('driving_distances.table_headers.actions') }</th>
                </tr>
              </Fragment>
              </thead>
              <tbody>
              {
                players.map ( player =>
                    <DrivingDistanceData
                        key={player.id}
                        player={player}
                        adjustment={this.props.adjustment}
                    />
                )
              }
              </tbody>
            </table>
          : <span className='no_distances'>{ window.I18n.t('driving_distances.no_distances') }</span>}
        </div>
      </Col>
    )
  }
}

DrivingDistancesTableComponent.propTypes = {
  players: PropTypes.array,
  adjustment: PropTypes.number,
  distanceUnit: PropTypes.string,
}

export default DrivingDistancesTableComponent
