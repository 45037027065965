/* eslint react/no-string-refs: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class PrintModalContainer extends Component {
  componentDidUpdate() {
    if (this.props.modal_parameters !== null) {
      const loadCallback = function() {
        //bind custom handler to submit form through the corresponding printReport routine
        const submitCallback = function(event) {
          event.preventDefault()
          const form = $(event.target)
          const type = form.attr('method')
          const data = form.serialize()
          const url = form.attr('action')
          const callback = () => {
            //reset modal's state and UI
            this.props.openPrintModal(null)
            $(this.refs.modal).modal('hide')
          }
          this.props.modal_parameters.rci_base_printReport(url, type, data, callback)
        }.bind(this)

        $(this.refs.modal).find('form')
                          .submit(submitCallback)

        //override cancel button with react rci component state update
        const onClick = function(event) {
          event.preventDefault()
          this.props.openPrintModal(null) //reset modal state
          $(this.refs.modal).modal('hide')
        }.bind(this)
        $(this.refs.modal).find('a.btsp_gray_button, button.btsp_white_button, a.btsp_white_button')
                          .on('click', onClick)

        //inits
        window.glg.new_design_shared.modaliChecks('#print_report, #print_report_options')
        //gpu print options forms
        window.glg.gpu_reports.init_filtering()
        window.glg.gpu_reports.restore_settings()
        window.glg.gpu_reports.context_field_gsub()
        window.glg.layout.initDropdownMenus()
      }.bind(this)
      $(this.refs.modal).find('.modal-body')
                        .load(this.props.modal_parameters.edit_conflicts_path,
                              loadCallback)

      $(this.refs.modal).modal({ show: true, backdrop: 'static', keyboard: false })
    }
  }

  render() {
    return (
      <div className="modal" id="print_report" ref="modal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          {this.props.modal_parameters && (<div className="modal-dialog">
              <div className="modal-content">
                  <div className="modal-body">
                  </div>
              </div>
          </div>)}
      </div>
    )
  }
}

PrintModalContainer.propTypes = {
  modal_parameters: PropTypes.object,
  openPrintModal: PropTypes.func.isRequired,
}

export default PrintModalContainer
