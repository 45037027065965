import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { computeHeight } from '../../helpers'

const ROWS_PER_PAGE_TEE_TIMES = 5
const ROWS_PER_PAGE_INDIVIDUAL = 15
class TeeSheetComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageIndex: 0,
      ready: false,
    }
    this.updateScrollRate = this.updateScrollRate.bind(this)
    this.showNextPage = this.showNextPage.bind(this)
    this.buildPageHTML = this.buildPageHTML.bind(this)
    this.splitTableData = this.splitTableData.bind(this)
  }

  componentDidMount() {
    const { slide, setPlaceholderValues } = this.props
    
    if (slide.placeholderValues && setPlaceholderValues) {
      setPlaceholderValues(slide.placeholderValues)
      this.splitTableData()
    }
    this.updateScrollRate()
  }

  componentWillMount() {
    clearInterval(this.scrollingInterval)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.blueBox !== this.props.blueBox) {
      this.splitTableData()
    }
    this.updateScrollRate()
  }

  splitTableData() {
    const { tvShowSettings, blueBox} = this.props
    const delimiter = this.props.slide.displayMode === 'by_individual' ? '<tr class=\'player_row\' data-course-id' : '<tr class=\'search_rows hidden-xs\' data-course-id='
    let windowHeight = document.body.clientHeight
    let ROWS_PER_PAGE = this.props.slide.displayMode === 'by_individual' ? ROWS_PER_PAGE_INDIVIDUAL : ROWS_PER_PAGE_TEE_TIMES

    if (windowHeight) {
      windowHeight = windowHeight - 50 - computeHeight(tvShowSettings, blueBox)
      ROWS_PER_PAGE = Math.round(this.props.slide.displayMode === 'by_individual' ? windowHeight / 33 : windowHeight / 91) - 1
    }

    this.setState({
      delimiter,
      ROWS_PER_PAGE,
      table: this.props.slide.tee_sheet_html.split(delimiter),
      ready: true,
    })
  }

  updateScrollRate() {
    const { slide, blueBox } = this.props

    clearInterval(this.scrollingInterval)
    this.scrollingInterval = setInterval(() => {
      if (blueBox.isScrolling) {
        this.showNextPage()
      } else {
        clearInterval(this.scrollingInterval)
      }
    }, slide.scrollRate * 1000 / blueBox.scrollRate)
  }

  showNextPage() {
    const { table, ROWS_PER_PAGE } = this.state

    if (this.state.pageIndex >= Math.round(table.length / ROWS_PER_PAGE) - 1) {
      clearInterval(this.scrollingInterval)
      this.setState({
        pageIndex: 0,
      }, this.props.moveToNextSlide)
    } else {
      this.setState({
        pageIndex: this.state.pageIndex + 1,
      })
    }
  }

  buildPageHTML(pageIndex) {
    const { table, delimiter, ROWS_PER_PAGE } = this.state

    return [
      table[0],
      ...table.slice(pageIndex * ROWS_PER_PAGE + 1, (pageIndex + 1) * ROWS_PER_PAGE + 1), 
    ].join(delimiter)
  }

  render() {
    const { ready, pageIndex } = this.state

    if (ready) {
      return <div className="screen"><div className="tv_event tee_sheet"><div dangerouslySetInnerHTML={{__html: this.buildPageHTML(pageIndex) }} className="content_wrapper tv_tee_sheet_wrapper"></div></div></div>
    }
    return <div></div>
    
  }
}

TeeSheetComponent.propTypes = {
  isFireTv: PropTypes.bool,
  slide: PropTypes.object.isRequired,
  blueBox: PropTypes.object,
  moveToNextSlide: PropTypes.func.isRequired,
  setPlaceholderValues: PropTypes.func,
  placeholderValues: PropTypes.object,
  hasUSGATheme: PropTypes.bool,
  tvShowSettings: PropTypes.object,
}

TeeSheetComponent.defaultProps = {
  isFireTv: false,
  hasUSGATheme: false,
}

export default TeeSheetComponent
