import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'
import { GlgButton } from '../../../shared/components'
import ClubSelectPopupTable from './ClubSelectPopupTable'

export const ClubSelectPopup = (props) => {
  const sortedAllClubs = props.clubs?.sort((c1, c2) => c1.name.localeCompare(c2.name))?.map(club => { return {...club} })
  const [ allClubs, setAllClubs ] = useState(sortedAllClubs || [])
  const sortedSelectedClubs = props.clubsSelected?.sort((c1, c2) => c1.name.localeCompare(c2.name))?.map(club => { return {...club} })
  const [ clubsSelected, setClubsSelected ] = useState(sortedSelectedClubs || [])
  const [ filteredClubName, setFilteredClubName ] = useState('')

  const [ selectAllClubs, setSelectAllClubs ] = useState(false)
  const [ selectAllClubsSelected, setSelectAllClubsSelected ] = useState(false)

  const [ sortAscAllClubs, setSortAscAllClubs ] = useState(true)
  const [ sortAscClubsSelected, setSortAscClubsSelected ] = useState(true)

  const [ shiftHeld, setShiftHeld ] = useState(false)
  const [ lastBox, setLastBox ] = useState()
  const [ lastBoxSecondTable, setLastBoxSecondTable ] = useState()

  function downHandler({key}) {
    if (key === 'Shift') {
      setShiftHeld(true)
    }
  }

  function upHandler({key}) {
    if (key === 'Shift') {
      setShiftHeld(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [])

  useEffect(() => {
    const sortedAllClubs = props.clubs?.sort((c1, c2) => c1.name.localeCompare(c2.name))?.map(club => { return {...club} }) || []
    setAllClubs(sortedAllClubs)
  }, [ props.clubs ])

  useEffect(() => {
    setClubsSelected( props.clubsSelected?.sort((c1, c2) => c1.name.localeCompare(c2.name))?.map(club => { return {...club} }))
  }, [ props.clubsSelected ])

  useEffect(() => {
    setAllClubs(
      props.clubs.filter(club => {
        return props.clubsSelected.findIndex(selected => selected.customer_id === club.customer_id) === -1
      }).map(club => { return {...club} })
    )
  }, [ props.clubsSelected, props.clubs ])

  const handleModalClose = () => {
    setClubsSelected(props.clubsSelected.map(club => { return {...club, selected: false} }) || [])

    setAllClubs(props.clubs.filter(club => {
      return props.clubsSelected.findIndex(selected => selected.customer_id === club.customer_id) === -1
    }).map(club => { return {...club} }))
    setFilteredClubName('')
    props.setClubSelectModalOpen(false)
    setSelectAllClubsSelected(false)
    setSelectAllClubs(false)
  }

  const onCancel = () => {
    setAllClubs(props.clubs)
    setClubsSelected(props.clubsSelected)
    handleModalClose() 
  }

  const onSave = () => {
    let auxClubsSelected = []
    clubsSelected.map(selectedClub => {
      const selectedIndex = props.clubsSelected.findIndex(selected => selected.customer_id === selectedClub.customer_id)

      if (selectedClub.customer_id !== undefined) {
        if (selectedIndex === -1) {
          auxClubsSelected = [ ...auxClubsSelected, { customer_id: selectedClub.customer_id, name: selectedClub.name } ]
        } else {
          auxClubsSelected = [ ...auxClubsSelected, { id: props.clubsSelected[selectedIndex].id, customer_id: selectedClub.customer_id, name: selectedClub.name } ]
        }
      }
    })

    props.clubsSelected.map(oldSelectedClubs => {
      const selectedIndex = clubsSelected.findIndex(selected => selected.customer_id === oldSelectedClubs.customer_id)
      if (selectedIndex === -1) {
        auxClubsSelected = [ ...auxClubsSelected, { id: oldSelectedClubs.id, _destroy: true, name: oldSelectedClubs.name } ]
      }
    })

    props.setData({ ...props.data, selected_clubs: auxClubsSelected})
    setFilteredClubName('')
    handleModalClose()
  }

  const handleNewClubCandidate = (clubId, checkboxVal) => {
    const index = allClubs.findIndex(selected => selected.customer_id === clubId)
    if (index !== -1) {
      const tmpAllClubs = [ ...allClubs ]
      tmpAllClubs[index].selected = checkboxVal
      setAllClubs(tmpAllClubs)
      setSelectAllClubs(false)
      setLastBoxSecondTable(null)
      if (shiftHeld === true && lastBox !== null) {
        const boxAllClubs = [ ...allClubs ]
        if (index > lastBox) {
          for (let i = lastBox; i < index; i++) {
            boxAllClubs[i].selected = checkboxVal
          }
        } else {
          for (let i = index + 1; i <= lastBox; i++) {
            boxAllClubs[i].selected = checkboxVal
          }
        }
        setAllClubs(boxAllClubs)
      }
      setLastBox(index)
    } 
  }

  const handleRemovingClubCandidate = (clubId, checkboxVal) => {
    const index = clubsSelected.findIndex(selected => selected.customer_id === clubId)
    if (index !== -1) {
      const tmpClubsSelected = [ ...clubsSelected ]
      tmpClubsSelected[index].selected = checkboxVal
      setClubsSelected(tmpClubsSelected)
      setSelectAllClubsSelected(false)
      setLastBox(null)
      if (shiftHeld === true && lastBoxSecondTable !== null) {
        const boxSelectedClubs = [ ...clubsSelected ]
        if (index > lastBoxSecondTable) {
          for (let i = lastBoxSecondTable; i < index; i++) {
            boxSelectedClubs[i].selected = checkboxVal
          }
        } else {
          for (let i = index + 1; i <= lastBoxSecondTable; i++) {
            boxSelectedClubs[i].selected = checkboxVal
          }
        }
        setClubsSelected(boxSelectedClubs)
      }
      setLastBoxSecondTable(index)
    }
  }

  const addToSelected = () => {
    if (sortAscClubsSelected) {
      setClubsSelected([ ...clubsSelected, ...allClubs.filter(club => club.selected).map(club => { return { customer_id: club.customer_id, name: club.name } }) ]
        .sort((c1, c2) => c1.name.localeCompare(c2.name)))
    } else {
      setClubsSelected([ ...clubsSelected, ...allClubs.filter(club => club.selected).map(club => { return { customer_id: club.customer_id, name: club.name } }) ]
        .sort((c1, c2) => c2.name.localeCompare(c1.name)))
    }
    setAllClubs(allClubs.filter(club => !club.selected).map(club => { return { customer_id: club.customer_id, name: club.name } }))
    setSelectAllClubs(false)
  }

  const removeFromSelected = () => {
    if (sortAscAllClubs) {
      setAllClubs([ ...allClubs, ...clubsSelected.filter(club => club.selected).map(club => { return { customer_id: club.customer_id, name: club.name } }) ]
        .sort((c1, c2) => c1.name.localeCompare(c2.name)))
    } else {
      setAllClubs([ ...allClubs, ...clubsSelected.filter(club => club.selected).map(club => { return { customer_id: club.customer_id, name: club.name } }) ]
        .sort((c1, c2) => c2.name.localeCompare(c1.name)))
    }
    setClubsSelected(clubsSelected.filter(club => !club.selected).map(club => { return { customer_id: club.customer_id, name: club.name } }))
    setSelectAllClubsSelected(false)
  }

  const addToSelectedButtonColor = () => {
    return allClubs.filter(club => club.selected).length > 0 ? 'orange' : 'gray'
  }

  const removeFromSelectedButtonColor = () => {
    return clubsSelected.filter(club => club.selected).length > 0 ? 'orange' : 'gray'
  }

  const handleSelectAllClubsChange = (val) => {
    setSelectAllClubs(val)
    let auxAllClubs = []
    if (val === false) {
      auxAllClubs = allClubs.map(club => { return {...club, selected: val } })
    } else {
      auxAllClubs = allClubs.map(club => { return {...club, selected: val && club.name.toLowerCase().includes(filteredClubName.toLowerCase()) } })
    }
    
    setAllClubs(auxAllClubs)
  }

  const handleSelectAllClubsSelectedChange = (val) => {
    setSelectAllClubsSelected(val)

    let auxSelectedClubs = []
    if (val === false) {
      auxSelectedClubs = clubsSelected.map(club => { return {...club, selected: val } })
    } else {
      auxSelectedClubs = clubsSelected.map(club => { return {...club, selected: val && club.name.toLowerCase().includes(filteredClubName.toLowerCase()) } })
    }

    setClubsSelected(auxSelectedClubs)
  }

  const handleClubNameFilter = (e) => {
    handleSelectAllClubsChange(false)
    handleSelectAllClubsSelectedChange(false)
    setFilteredClubName(e.target.value)
  }

  const handleSort = (shouldSortAsc, shouldSortAllClubs) => {
    if (shouldSortAllClubs) {
      setSortAscAllClubs(shouldSortAsc)
      if (shouldSortAsc) {
        setAllClubs(allClubs.sort((c1, c2) => c1.name.localeCompare(c2.name)))
      } else {
        setAllClubs(allClubs.sort((c1, c2) => c2.name.localeCompare(c1.name)))
      }
    } else {
      setSortAscClubsSelected(shouldSortAsc)
      if (shouldSortAsc) {
        setClubsSelected(clubsSelected.sort((c1, c2) => c1.name.localeCompare(c2.name)))
      } else {
        setClubsSelected(clubsSelected.sort((c1, c2) => c2.name.localeCompare(c1.name)))
      }
    }
  }

  const selectedFilteredClubs = clubsSelected.filter(club => club['_destroy'] !== true && club.customer_id !== undefined && club.name.toLowerCase().includes(filteredClubName.toLowerCase()))

  return (
    <Modal show={props.clubSelectModalOpen} className='select-aggregated-clubs' onHide={() => handleModalClose()}>
      <Modal.Header>
        <h3>{ window.I18n.t('views.golfhub.aggregate_from.select_clubs') }</h3>
      </Modal.Header>
      <Modal.Body>
        <div className='content'>
          <label id='club-name-label' className='bold padding-left-16 font-size-18' htmlFor='club-name-filter'>
            { window.I18n.t('views.golfhub.aggregate_from.club_name') }
          </label>
          <input type='text' id='club-name-filter' name='club-name-filter' onChange={(e) => handleClubNameFilter(e)} className='form-control form-text' placeholder={ window.I18n.t('views.golfhub.aggregate_from.enter_club_name') }/>
          <div className='selection-content'>

            <ClubSelectPopupTable
              clubs={allClubs.filter(club => club.name.toLowerCase().includes(filteredClubName.toLowerCase()))}
              tableName={ window.I18n.t('views.golfhub.aggregate_from.available_clubs') }
              clubsNo={allClubs.length}
              showFilteredText={filteredClubName !== ''}
              handleClubCandidate={handleNewClubCandidate}
              selectAll={selectAllClubs}
              handleSelectAllChange={handleSelectAllClubsChange}
              sortAsc={sortAscAllClubs}
              handleSort={(shouldSortAsc) => handleSort(shouldSortAsc, true)}/>

            <div className='select-buttons-container'>
              <GlgButton text='>' color={addToSelectedButtonColor()} onClick={() => addToSelected()} className='select-button'></GlgButton>
              <GlgButton text='<' color={removeFromSelectedButtonColor()} onClick={() => removeFromSelected()} className='select-button'></GlgButton>
            </div>

            <ClubSelectPopupTable
              clubs={selectedFilteredClubs}
              tableName={ window.I18n.t('views.golfhub.aggregate_from.selected_clubs') }
              clubsNo={selectedFilteredClubs.length}
              showFilteredText={filteredClubName !== ''}
              handleClubCandidate={handleRemovingClubCandidate}
              selectAll={selectAllClubsSelected}
              handleSelectAllChange={handleSelectAllClubsSelectedChange}
              sortAsc={sortAscClubsSelected}
              handleSort={(shouldSortAsc) => handleSort(shouldSortAsc, false)}/>

          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='footer'>
        <Button onClick={() => onCancel()} className = 'cancel-button'>Cancel</Button>
        <Button onClick={() => onSave()} className='button btsp_orange_button save-button'>Save</Button>
      </Modal.Footer>
    </Modal>
  )
}

ClubSelectPopup.propTypes = {
  clubSelectModalOpen: PropTypes.bool,
  setClubSelectModalOpen: PropTypes.func,
  clubsSelected: PropTypes.array,
  clubs: PropTypes.array,
  data: PropTypes.object,
  setData: PropTypes.func,
  customerId: PropTypes.string,
}
