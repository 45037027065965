import { connect } from 'react-redux'
import EmailAndTexts from '../../components/content/email_and_texts'
import { selectEmailsOrTexts } from '../../actions/index'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectEmailsOrTexts: selected => dispatch(selectEmailsOrTexts(ownProps.widgetId, selected)),
})

export default connect(
  null,
  mapDispatchToProps,
)(EmailAndTexts)
