import { ActionTypes } from '../actions'

const {
  CHANGE_COLUMN_SORTING,
} = ActionTypes

const initialState = {
  column: null,
  sortOrder: null,
}

const sortParams = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_COLUMN_SORTING:
      return {
        ...state,
        column: action.column,
        sortOrder: action.sortOrder,
      }
    default:
      return state
  }
}

export default sortParams
