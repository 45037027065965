import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Row = (props) => {

  const [ copyMessage, setCopyMessage ] = useState(window.I18n.t('views.golfhub.copy.copy_to_clipboard'))

  const className = props.isEven ? 'evenRow' : 'oddRow'

  const link = `hubs/${props.data.id}`

  const copyToClipboard = (e) => {
    e.preventDefault()
    const elem = document.createElement('textarea')
    elem.value = e.target.closest('a')
    document.body.appendChild(elem)
    elem.select()
    document.execCommand('copy')
    document.body.removeChild(elem)
    setCopyMessage(window.I18n.t('views.golfhub.copy.copied'))
    setInterval(() => setCopyMessage(window.I18n.t('views.golfhub.copy.copy_to_clipboard')), 5000)
  }

  return (
    <tr className={className} key={props.data.id}>
      <td className='hubName'>
        <a href={link + '/edit'}>
          {props.data.hub_name}
        </a>
      </td>
      <td className='blue-label-container'>
        {
          props.data.aggregated && 
            <div className='blue-label'>
              {window.I18n.t('views.golfhub.aggregated')}
            </div>
        }
      </td>
      <td className='hubLink'>
        <i className="fa fa-clipboard"></i>
        <a className="copy-button add-padding-left-5" href={props.data.hub_link} onClick={ (e) => copyToClipboard(e) }>{copyMessage}</a>  
      </td>
      <td className='hubFilters'>{props.data.filters}</td>
      <td className='action-button'>
        <div className="dropMenu dropdown">
          <a className='btn btn-default btn-xs-dropdown-toggle new_dropdown_button_icon' href="#" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown">
            <i className='fa fa-caret-down fa-lg'></i>
          </a>
          <ul className='dropdown-menu dropdown-menu-right'>
            <li>
              <a href={link + '/edit'}>
                <i className='gga-pencil-45-angle'></i>
                { window.I18n.t('utilities.breadcrumb.edit_hub', {name: ''}) }
              </a>
            </li>
            {( !props.data.default && 
              <li>
                <a onClick={() => props.handleHubDelete()}>
                  <i className='gga-table-delete-member'></i>
                  { window.I18n.t('utilities.breadcrumb.delete_hub') }
                </a>
              </li>  
            )}
          </ul>
        </div>
      </td>
    </tr>
  )
}

Row.propTypes = {
  data: PropTypes.object,
  isEven: PropTypes.bool,
  handleHubDelete: PropTypes.func,
}

export default Row
