import { connect } from 'react-redux'
import EventSetupTeeSheet from '../../components/content/event_setup_tee_sheet'
import {
  saveEventSettingsWidget,
  setGenericData,
  pollJob,
  pollUpdateNotifyMessages,
  ajaxRequest,
} from '../../actions/index'
import { showNotification, showErrorNotification } from 'Shared/helpers'

const internationalize = (...args) => !window.I18n ? '' : window.I18n.t(...args)

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => ({
  setData: (keys, value, { updateDataChanged = true} = {} ) => {
    dispatch(setGenericData(ownProps.widgetId, keys, value))
    if (updateDataChanged && (!ownProps.content.dataChanged)) {
      dispatch(setGenericData(ownProps.widgetId, [ 'dataChanged' ], true))
    }
  },
  save: () => {
    const { links } = ownProps.content
    const roundsData = ownProps.content.rounds_data

    const roundsSaveData = {}
    Object.keys(roundsData).forEach(roundId => {
      const roundData = roundsData[roundId]

      roundsSaveData[roundId] = {
        round_id: roundId,
        new_foursomes: (ownProps.content.newFoursomes || []),
        foursomes: Object.fromEntries(
          Object.entries(roundData.foursomes).map(([ foursomeId, foursome ]) => {
            return [ foursomeId, {
              ...foursome,
              slots: foursome.slots.map(s => s || {}),
            } ]
          })),
      }
    })

    dispatch(saveEventSettingsWidget(ownProps.widgetId, links.save.path, { roundsData: roundsSaveData }))
      .finally(() => {
        const roundIds = Object.keys(roundsData)
        dispatch(ajaxRequest(links.rounds_data.path, 'post', { round_ids: roundIds }, 'json'))
          .then(({ response }) => {
            dispatch(setGenericData(ownProps.widgetId, [ 'rounds_data' ], response))
          })
      })
  },
  createPairings: () => {
    const { links, rounds } = ownProps.content

    dispatch(setGenericData(ownProps.widgetId, [ 'creatingPairings' ], true))
    dispatch(saveEventSettingsWidget(ownProps.widgetId, links.create_pairings.path, { round_id: rounds.selected }, { withNotifications: false }))
      .then(({ response }) => {
        if (response.success) {
          response.message && showNotification(response.message)

          pollJob(response.job_id, pollUpdateNotifyMessages)
            .then(update => {
              showNotification(update.value.friendly_status)
              dispatch(ajaxRequest(links.rounds_data.path, 'post', { round_ids: [ rounds.selected ] }, 'json'))
                .then(({ response }) => {
                  dispatch(setGenericData(ownProps.widgetId, [ 'rounds_data', rounds.selected ], response[rounds.selected] ))
                })
            })
            .catch(() => {
              showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.save_settings_error'))
            })
            .finally(() => {
              dispatch(setGenericData(ownProps.widgetId, [ 'creatingPairings' ], false))
            })
        } else {
          showErrorNotification(response.error)
          dispatch(setGenericData(ownProps.widgetId, [ 'creatingPairings' ], false))
        }
      })
      .catch(() => {
        dispatch(setGenericData(ownProps.widgetId, [ 'creatingPairings' ], false))
      })
  },

  importPairings: () => {
    const { links, rounds } = ownProps.content
    const roundData = ownProps.content.rounds_data[rounds.selected]
    dispatch(setGenericData(ownProps.widgetId, [ 'creatingPairings' ], true))

    dispatch(ajaxRequest(links.import_ce_teesheet.path, 'post', { round_id: rounds.selected, perform_method: 'sync' }, 'json'))
      .then(({ response }) => {
        response.message && showNotification(response.message)
        response.errors && showErrorNotification(response.errors)

        if (response.success) {
          pollJob(response.job_id, update => pollUpdateNotifyMessages(update))
            .then(update => {
              showNotification(update.value.friendly_status)
              dispatch(ajaxRequest(links.rounds_data.path, 'post', { round_ids: [ rounds.selected ] }, 'json'))
                .then(({ response }) => {
                  dispatch(setGenericData(ownProps.widgetId, [ 'rounds_data', rounds.selected ], {
                    ...roundData,
                    ...response[rounds.selected],
                  }))
                })
            })
            .catch((update) => {
              const message = update?.value?.errors?.join('\n')
              showErrorNotification(message)
            })
            .finally(() => {
              dispatch(setGenericData(ownProps.widgetId, [ 'creatingPairings' ], false))
            })
        } else {
          dispatch(setGenericData(ownProps.widgetId, [ 'creatingPairings' ], false))
        }
      })
      .catch(() => {
        dispatch(setGenericData(ownProps.widgetId, [ 'creatingPairings' ], false))
      })
  },

  linkToEvent: () => {
    const { links, rounds } = ownProps.content
    const roundId = rounds.selected
    const eventId = ownProps.content.rounds_data[roundId].integration_events.selected

    dispatch(ajaxRequest(links.link_round_to_external_event.path, 'post', { round_id: roundId, event_id: eventId }, 'json'))
      .then(({ response }) => {
        if (response.success) {
          showNotification('Round linked to event')
          dispatch(setGenericData(ownProps.widgetId, [ 'rounds_data', roundId, 'integration_event_id' ], eventId))
        } else {
          showErrorNotification(response.error)
        }
      })
  },

  toggleDailySync: () => {
    const { links, rounds } = ownProps.content
    const roundId = rounds.selected
    const roundData = ownProps.content.rounds_data[roundId]

    dispatch(ajaxRequest(links.toggle_daily_sync.path, 'post', { round_id: roundId, perform_method: 'sync' }, 'json'))
      .then(({ response }) => {
        if (response.success) {
          showNotification(`Daily sync has been turned ${
            ((!roundData.daily_sync) ?
              internationalize('bootstrap_nav.partials.ce_round_integrations.status_on') :
              internationalize('bootstrap_nav.partials.ce_round_integrations.status_off'))}`)

          dispatch(setGenericData(ownProps.widgetId, [ 'rounds_data', roundId, 'daily_sync' ], !roundData.daily_sync))
        } else {
          showErrorNotification(response.error)
        }
      })
  },
})

export default connect(
  null,
  mapDispatchToProps,
)(EventSetupTeeSheet)

