import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

class TvShowSection extends Component {
  render() {
    return <Fragment>{ this.props.children }</Fragment>
  }
}

TvShowSection.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
}

export default TvShowSection
