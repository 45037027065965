import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ThruBoardTable from './thru_board_table'

class ThruBoard extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.resetTimer()
  }

  componentWillUnmount() {
    this.props.clearTimer()
  }

  render() {
    return (
      <div className='inner-container'>
          <p className='title'>{ this.props.title }</p>
          { this.props.current.show && <ThruBoardTable content={ this.props.current } /> }
          { this.props.upcoming.show && <ThruBoardTable content={ this.props.upcoming } /> }
      </div>
    )
  }
}

ThruBoard.propTypes = {
  resetTimer: PropTypes.func,
  clearTimer: PropTypes.func,
  title: PropTypes.string,
  current: PropTypes.object,
  upcoming: PropTypes.object,
}

export default ThruBoard
