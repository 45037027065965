import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import PopupTypes from '../../popup_types'
import {
  GlgPopup,
  GlgPopupForm,
  GlgRadio,
} from 'SharedComponents'

class InvitationListRegistrationPopup extends Component {
  constructor(props) {
    super(props)
    this.onClose = props.onClose.bind(this, PopupTypes.INVITATION_LIST_REGISTRATION)
    this.onSave = this.onSave.bind(this)
    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.state = {
      anySelected: false,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.show !== nextProps.show ||
           this.state !== nextState
  }

  isSaveButtonEnabled() {
    return this.state.anySelected
  }

  confirmMessage() {
    const membershipNames = _.join(this.props.data.futureMembershipProgramsNames, ', ')
    return `The following Membership Programs are set to a future date: ${membershipNames}.\nThese dates will be REMOVED if you choose to continue.\n\nAre you sure?`
  }

  onSave(data) {
    let status = data['toggle_value']
    if (status === 'closed') {
      status = ''
    }

    if ( data['toggle_value'] === 'closed' || _.isEmpty(this.props.data.futureMembershipProgramsNames) ){
      this.props.onSave(this.props.data.leagueId, status)
    } else {
      if ( confirm(this.confirmMessage()) ) { // eslint-disable-line no-alert
        this.props.onSave(this.props.data.leagueId, status)
      }
    }
  }

  handleOptionChange() {
    this.setState({
      anySelected: true,
    })
  }

  render() {
    return <GlgPopup title="Change Registration to" show={ this.props.show } onClose={ this.onClose } onSave={ this.onSave } saveButtonEnabled={ this.isSaveButtonEnabled() }><GlgPopupForm>{this.props.data.openRegistrationStatus !== 'invitation_list' && this.props.data.canInvitationsList && <GlgRadio name="toggle_value" value="invitation_list" label="Open for Invitation List" defaultChecked={ false } onChange={ this.handleOptionChange }></GlgRadio>}{this.props.data.openRegistrationStatus !== '' && <GlgRadio name="toggle_value" value="closed" label="Closed for everyone" defaultChecked={ false } onChange={ this.handleOptionChange }></GlgRadio>}{this.props.data.openRegistrationStatus !== 'open' && <GlgRadio name="toggle_value" value="open" label="Open for everyone" defaultChecked={ false } onChange={ this.handleOptionChange }></GlgRadio>}{this.props.data.canUseMembershipPrograms && <div className="add-margin-bottom-10"><a href={ this.props.data.registrationDatesURL } target={'_blank'} onClick={ this.onClose }>View registration dates by Membership Program</a></div>}</GlgPopupForm></GlgPopup>
  }
}

InvitationListRegistrationPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default InvitationListRegistrationPopup
