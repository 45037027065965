import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GlgPopup } from 'SharedComponents'
import RoundsSectionComponent from './rounds_section_component'

class LibraryRoundsModal extends Component {
  render() {
    return (
      <GlgPopup
        title={ !window.I18n ? '' : window.I18n.t('tv_shows.components.library_rounds_modal.select_rounds_modal_title') }
        saveButtonText={ !window.I18n ? '' : window.I18n.t('tv_shows.components.library_rounds_modal.continue') }
        saveButtonEnabled={ true }
        show={ this.props.show }
        showCloseButton = { false }
        onClose={ this.props.onClose }
        isSaving={ false }
        modalClass="library-rounds-popup"
        >
          <RoundsSectionComponent { ...this.props.library } { ...this.props.libraryDispatchers } />
      </GlgPopup>
    )
  }
}

LibraryRoundsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  library: PropTypes.object.isRequired,
  libraryDispatchers: PropTypes.object.isRequired,
}

export default LibraryRoundsModal
