import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import { FormControl } from 'react-bootstrap'

import { getAuthenticityToken } from 'Shared/helpers'

const GlgForm = ({ children, withForm, leftSideColumnsCount, showAlert, alert }) => {
  const formUI = <Fragment><FormControl type="hidden" name="authenticity_token" value={ getAuthenticityToken() }></FormControl><Row><Col xs={true} className="btsp_form_content grey_border">{ showAlert && <Row className="alert-row">{ alert }</Row>}<ul className="btsp_ul">{React.Children.map(children, child => (
              (child && child.type && [ 'GlgFormInput', 'GlgFormTextInput' ].includes(child.type.name)) ? (
                React.cloneElement(child,
                                   {
                                     ...child.props,
                                     leftSideColumnsCount,
                                   },
                                   child.props.children)
              ) : (
                child
              )
            ))}</ul></Col></Row></Fragment>

  if (withForm) {
    return <form>{ formUI }</form>
  } else {
    return formUI
  }
}

GlgForm.propTypes = {
  withForm: PropTypes.bool,
  leftSideColumnsCount: PropTypes.number,
  alert: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  showAlert: PropTypes.bool,
  children: PropTypes.array,
}

GlgForm.defaultProps = {
  withForm: true,
}

export default GlgForm
