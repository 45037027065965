/*
 * GlgTableRow is used by GlgTable to build table rows.
 */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const GlgTableRow = ({ children, ...props }) => {
  const style = {
    ...props.draggableProps.style,
  }
  if (props.isDragging) {
    style['display'] = 'table'
  }
  return <tr ref={ props.innerRef } {...props.draggableProps} style={ style } className={ `glg-table-row ${ props.className || '' }` }><Fragment>{!props.isSortable && <td className="hidden" { ...props.dragHandleProps }></td>}{React.Children.map(children, (child, index) => {
          if (!child) {
            return null
          }

          const cellStyle = {
            width: props.columnChildren[index].props.width,
          }
          const cellProps = props.columnChildren[index].props.cellProps

          return <td { ...cellProps } style={ cellStyle }>{ React.cloneElement(child,
                                   child.props,
                                   child.props.children)
              }</td>
        })}</Fragment></tr>
}

GlgTableRow.propTypes = {
  children: PropTypes.node.isRequired,
  draggableProps: PropTypes.object.isRequired,
  dragHandleProps: PropTypes.object,
  isDragging: PropTypes.bool.isRequired,
  innerRef: PropTypes.func.isRequired,
  columnChildren: PropTypes.array.isRequired,
  className: PropTypes.string,
  isSortable: PropTypes.bool.isRequired,
}

export default GlgTableRow
