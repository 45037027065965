import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Rows from './Rows'
import { getAuthenticityToken, callAPI } from 'Shared/helpers'
import { GlgLoadingSpinner } from 'SharedComponents'

const StyledPage = styled.div`
  .name-input {
    width: 400px;
  }
  .fa-clipboard {
    color: #00A0CE !important;
  }

  .app-container {
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 1rem 0rem;
  }
  
  th, td {
    text-align: left;
    padding: 8px 0px 8px 24px;
  }

  .hubName {
    color: #00A0CE;
    width: 250px;
  }

  .hubLink {
    width: 250px;
  }

  .hubFilters {
    padding-right: 250px;
  }

  .evenRow {
    background-color: #FFFFFF;
  }

  .oddRow {
    background-color: #F7F7F7;
  }

  tr { 
    border: none;
  }
  
  .action-button {
    text-align: center;
  }

  .table {
    table-layout: auto;
  }

  th {
    background-color: #FFFFFF;
  }
`

const HubIndex = () => {
  const [ hubs, setHubs ] = useState([])
  const [ dataIsLoading, setDataIsLoading ] = useState(true)

  const getHubs = () => {
    callAPI('hubs', 'GET')
      .then((json) => {
        setHubs(json)
        setDataIsLoading(false)
      })
  }

  const handleHubDelete = (deletedHub) => {
    // eslint-disable-next-line no-alert
    if (confirm(window.I18n.t('views.golfhub.delete_confirmation').replace('#{name}', deletedHub.hub_name))){
      fetch(`hubs/${deletedHub.id}`, {
        method: 'DELETE',
        headers: {'X-CSRF-Token': getAuthenticityToken()}})
      .then(() => {
        const newHubs = hubs.filter((hub) => hub.id !== deletedHub.id)
        setHubs(newHubs)
      })
    }
  }

  useEffect(getHubs, [])

  return (
    <StyledPage>
      <div className='btsp_form_content col-xs-12'>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding add-padding-top-10">
          <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 add-padding-top-10'>
            <div>
              <a className='btsp_bullet_button pointer' href={'hubs/new'}>
                <div className='icon'><i className='gga-circle-plus'></i></div>
                <div className='btsp_align-middle'>{ window.I18n.t('utilities.breadcrumb.new_hub') }</div>
              </a>
            </div>
            { dataIsLoading && (<GlgLoadingSpinner className='text-center' show={dataIsLoading}/>)}
            { !dataIsLoading &&
              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 add-padding-top-10 app-container'>
                <table className='no-padding'>
                  <Rows hubs={hubs} handleHubDelete={handleHubDelete}/>
                </table>
              </div>  
            }
          </div>
        </div>
      </div>
    </StyledPage> 
  )
}

export default HubIndex
