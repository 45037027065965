import { connect } from 'react-redux'
import MonsterBoardDeviceComponent from '../components/monster_board_device_component'
import { refreshData } from '../actions'

const mapStateToProps = (state) => {

  return {
    boardAvailable: state.misc.boardAvailable,
    deviceView: state.misc.deviceView,
    lastUpdate: state.misc.lastUpdate,
  }
}

const mapDispatchToProps = (dispatch) => ({
  refreshData: () => dispatch(refreshData()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MonsterBoardDeviceComponent)
