import React, { Fragment } from 'react'

import GlgTabs from 'SharedComponents/glg_tabs'
import EmailDeliveryReportsContainer from '../../containers/delivery_reports/email_delivery_reports_container'
import TextMessageDeliveryReportsContainer from '../../containers/delivery_reports/text_message_delivery_reports_container'
import { Row, Col } from 'react-flexbox-grid'
import PropTypes from 'prop-types'

const formatDate = (date) => {
  let formatted = ''
  let ampm = 'AM'

  const month = date.getMonth() + 1 // month is 0 indexed
  formatted += (month < 10 ? '0' + month : '' + month) + '-'

  const day = date.getDate()
  formatted += (day < 10 ? '0' + day : '' + day) + '-'

  const year = date.getFullYear()
  formatted += year + ' '

  let hour = date.getHours()
  ampm = (hour >= 12) ? 'PM' : 'AM'
  hour = (hour > 12) ? hour - 12 : hour
  hour = (hour === 0) ? 12 : hour
  formatted += hour + ':'

  const mins = date.getMinutes()
  formatted += (mins < 10 ? '0' + mins : '' + mins) + ':'

  const seconds = date.getSeconds()
  formatted += (seconds < 10 ? '0' + seconds : '' + seconds ) + ' '

  formatted += ampm

  return formatted
}

const reportsPerPage = 20
const buildPageNumbers = (totalReports) => {
  const pages = []
  let totalPages = totalReports / reportsPerPage
  totalPages = totalPages - Math.floor(totalPages) > 0 ? Math.floor(totalPages) + 1 : Math.floor(totalPages)

  for (let page = 1; page <= totalPages; ++page){
    pages.push(page)
  }

  return pages
}

const DeliveryReportsComponent = (props) => {
  return <Fragment><GlgTabs>{ props.hasChannel.email && <div label={ window.I18n.t('marketing_campaigns.components.delivery_reports.delivery_reports_component.email') }><Row className="no-margin"><Col xs={ 12 } className="add-padding-all-20"><EmailDeliveryReportsContainer filterDropdownValues={props.filterDropdownValues.email} perPage={reportsPerPage} loadEmailDeliveryReportsPath={props.paths.load_email_delivery_reports} formatDate={formatDate} buildPageNumbers={ buildPageNumbers }></EmailDeliveryReportsContainer></Col></Row></div>}{ props.hasChannel.textMessage && <div label={ window.I18n.t('marketing_campaigns.components.delivery_reports.delivery_reports_component.text_message') }><Row className="no-margin"><Col xs={ 12 } className="add-padding-all-20"><TextMessageDeliveryReportsContainer filterDropdownValues={props.filterDropdownValues.text_message} perPage={reportsPerPage} loadTextMessageDeliveryReportsPath={props.paths.load_text_message_delivery_reports} formatDate={formatDate} buildPageNumbers={ buildPageNumbers }></TextMessageDeliveryReportsContainer></Col></Row></div>}</GlgTabs></Fragment>
}

DeliveryReportsComponent.propTypes = {
  paths: PropTypes.shape({
    load_email_delivery_reports: PropTypes.string.isRequired,
    load_text_message_delivery_reports: PropTypes.string.isRequired,
  }).isRequired,
  filterDropdownValues: PropTypes.shape({
    email: PropTypes.shape({
      players: PropTypes.array.isRequired,
      subjects: PropTypes.array.isRequired,
      statuses: PropTypes.array.isRequired,
    }),
    text_message: PropTypes.shape({
      players: PropTypes.array.isRequired,
      descriptions: PropTypes.array.isRequired,
    }),
  }).isRequired,
  hasChannel: PropTypes.shape({
    email: PropTypes.number,
    textMessage: PropTypes.number,
  }).isRequired,
}

export default DeliveryReportsComponent
