import React from 'react'
import PropTypes from 'prop-types'
import {GlgTableRow, GlgTableCell} from 'SharedComponents'

import { getTotalColumnClass, getAbsColumnValue, getTodayColumnClass } from 'Shared/course_boards'

const previewRowComponent = ({...props}) => {
  const totalColor = getTotalColumnClass(props.total)
  const todayColor = getTodayColumnClass(props.today)
  const absTotal = getAbsColumnValue(props.total)
  const absToday = getAbsColumnValue(props.today)

  return (
    <GlgTableRow { ...props } className={props.className + ' ' + totalColor + ' ' + todayColor}>
      <GlgTableCell>{props.empty ? '' : absToday}</GlgTableCell>
      <GlgTableCell>{props.empty ? '' : props.withTeams ? props.name : props.lastName + ', ' + props.firstName.charAt(0)}</GlgTableCell>
      <GlgTableCell>{props.empty ? '' : props.hole}</GlgTableCell>
      {props.showCourse && <GlgTableCell>{props.empty ? '' : props.course}</GlgTableCell>}
      <GlgTableCell>{props.empty ? '' : absTotal}</GlgTableCell>
    </GlgTableRow>
  )
}

previewRowComponent.propTypes = {
  today: PropTypes.string,
  name: PropTypes.string,
  hole: PropTypes.string,
  total: PropTypes.string,
  className: PropTypes.string,
  empty: PropTypes.bool,
  showCourse: PropTypes.bool,
  withTeams: PropTypes.bool,
  lastName: PropTypes.string,
  firstName: PropTypes.string,
  course: PropTypes.string,
}

export default previewRowComponent
