import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NotificationContainer } from 'react-notifications'

import PopupTypes from '../popup_types'

import {
  GlgTable,
  GlgTableCol,
  GlgButton,
  GlgLoadingSpinner,
} from 'SharedComponents'

import NavigatorOptionsTableRow from './navigator_options_table_row'
import NavigatorOptionsPopupsContainer from '../containers/navigator_options_popups_container'

class AdminNavigatorOptionsComponent extends Component {
  render() {
    const rowExtraProps = {
      openPopup: this.props.openPopup,
      onOptionDelete: this.props.onOptionDelete,
    }
    const openAddPopup = () => {
      if (!this.props.creatingNavigatorOption) {
        this.props.openPopup(
          PopupTypes.NAVIGATOR_OPTION_POPUP, {
            operation: 'add',
          }
        )
      }
    }
    return <div><h1>Navigator Options</h1><GlgButton text="Add New Navigator Option" className="add-margin-bottom-10" disabled={ this.props.creatingNavigatorOption } onClick={ openAddPopup }></GlgButton><GlgLoadingSpinner show={ this.props.creatingNavigatorOption } height={ 30 } width={ 30 } className="loading-spinner"></GlgLoadingSpinner><GlgTable items={ this.props.navigatorOptions } rowComponent={ NavigatorOptionsTableRow } rowExtraProps={ rowExtraProps }><GlgTableCol width="5%" dataKey="#"></GlgTableCol><GlgTableCol width="20%" dataKey="Name"></GlgTableCol><GlgTableCol width="10%" dataKey="Context"></GlgTableCol><GlgTableCol width="30%" dataKey="URL">URL</GlgTableCol><GlgTableCol width="30%" dataKey="Tags"></GlgTableCol><GlgTableCol width="10%" dataKey="Actions"></GlgTableCol></GlgTable><NavigatorOptionsPopupsContainer></NavigatorOptionsPopupsContainer><NotificationContainer></NotificationContainer></div>
  }
}

AdminNavigatorOptionsComponent.propTypes = {
  navigatorOptions: PropTypes.array.isRequired,
  creatingNavigatorOption: PropTypes.bool,
  openPopup: PropTypes.func.isRequired,
  onOptionDelete: PropTypes.func.isRequired,
}

export default AdminNavigatorOptionsComponent
