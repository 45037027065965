import React from 'react'
import PropTypes from 'prop-types'
import rootReducer from '../reducers'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import DrivingDistancesTabContainer from './driving_distances_tab_container'

const DrivingDistancesRoot = ({roundId, distanceUnit, drivingStations, fetchPlayersForStationPath}) => {
  const state = {
    misc: {
      roundId,
      distanceUnit,
      drivingStations,
      fetchPlayersForStationPath,
    },
  }

  const store = configureStore(rootReducer, state)

  return (
    <GlobalProvider store={ store }>
      <DrivingDistancesTabContainer />
    </GlobalProvider>
  )
}

DrivingDistancesRoot.propTypes = {
  roundId: PropTypes.string,
  distanceUnit: PropTypes.string,
  drivingStations: PropTypes.array,
  fetchPlayersForStationPath: PropTypes.string,
}

export default DrivingDistancesRoot
