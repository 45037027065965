import { connect } from 'react-redux'
import EventSetupScoring from '../../components/content/event_setup_scoring'
import {
  setGenericData,
  saveEventSettingsWidget,
} from '../../actions/index'
import { extractValues as selectedCheckboxes } from '../../components/content/event_setup/checkbox_options'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => ({
  setData: (keys, value) => {
    dispatch(setGenericData(ownProps.widgetId, keys, value))
    if (! ownProps.content.dataChanged) {
      dispatch(setGenericData(ownProps.widgetId, [ 'dataChanged' ], true))
    }
  },
  save: () => {
    const { data, links } = ownProps.content

    const requestData = {
      'tie_breaking': data.tie_breaking.selected,
      'stableford_quota': selectedCheckboxes(data.stableford_quota),
      'display_options': {
        'misc_options': {
          ...selectedCheckboxes(data.display_options.misc_options),
          // ...leaderboardOptionsData,
          ...('country_flags' in data.display_options.misc_options.options &&
            ((option) => ({
              [option.key]: option.selected,
            }))(data.display_options.misc_options.options.country_flags.display_leaderboard_options)
          ),
        },
        // 'hole_by_hole_view': data.display_options.hole_by_hole_view.selected,
        // 'player_name_format': data.display_options.player_name_format.selected,
      },
      'holes_required': {
        [data.holes_required.key]: data.holes_required.value,
      },
      ...(data['confirmation_live_scoring'] && {
        confirmation_live_scoring: selectedCheckboxes(data.confirmation_live_scoring),
      }),
    }
    dispatch(saveEventSettingsWidget(ownProps.widgetId, links.save.path, requestData))
  },
})

export default connect(
  null,
  mapDispatchToProps,
)(EventSetupScoring)
