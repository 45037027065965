import React, { useState, useCallback, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'
import Cropper from 'react-easy-crop'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-body {
    height: 670px;
  }

  .modal-dialog {
    width: 60%;
  }
  
  .crop-container {
    position: relative;
    height: 500px;
  }

`

const ThumbnailsCrop = (props) => {
  return (
    <Fragment>
      <div className='add-margin-bottom-15 btsp_bullet_button pointer' onClick={() => props.setModalOpen(true)}>
        <div className='icon'><i className='gga-circle-plus'></i></div>
        <div className='btsp_align-middle'>Crop</div>
      </div>
    </Fragment>
  )
}

export const ThumbnailsCropPopup = (props) => {
  const [ imagePreview, setImagePreview ] = useState({image: props.selectedCourseAlbum.photos[0].course_url, crop: ''})
  const [ crop, setCrop ] = useState({ x: 0, y: 0 })
  const [ zoom, setZoom ] = useState(1)

  useEffect(() => {
    setImagePreview({image: props.selectedCourseAlbum.photos[0].course_url, crop: ''})
  }, [ props.selectedCourseAlbum ])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    if (imagePreview.image) {
      setImagePreview({...imagePreview, crop: croppedAreaPixels})
    }
  }, [ imagePreview.image ])

  const handleModalClose = () => {
    props.setModalOpen(false)
    setImagePreview({image: props.selectedCourseAlbum.photos[0].course_url, crop: ''})
  }

  const saveImage = () => {
    fetch(imagePreview.image, {
      method: 'GET',
      cache: 'no-store',
    })
    .then((response) => {
      response.blob()
      .then((blob) => {
        const filename = imagePreview.image.split('/')
                          .slice(-1)
                          .join()
        const file = new File([ blob ], filename, {type: blob.type})
        const croppedImage = {image: URL.createObjectURL(file), imageFile: file, crop: imagePreview.crop}

        props.setNewThumbnail(croppedImage)
        handleModalClose()
      })
    })
  }

  return (
    <StyledModal show={props.modalOpen} onHide={() => handleModalClose()}>
      <Modal.Header closeButton>
        Add Thumbnail Photo
      </Modal.Header>
      <Modal.Body>
        <div className='error-message btsp_alert_notices hidden add-margin-bottom-10 add-padding-all-5'></div>
        <div className='crop-container'>
          <Cropper
            image={imagePreview.image}
            crop={crop}
            zoom={zoom}
            aspect={16 / 9}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handleModalClose()}>{window.I18n.t('views.golfhub.modal.close')}</Button>
        <Button className='add-margin-left-10 button btsp_orange_button' disabled={!imagePreview.image} onClick={() => saveImage()} >{window.I18n.t('views.golfhub.modal.save')}</Button>
      </Modal.Footer>
    </StyledModal>
  )
}

ThumbnailsCrop.propTypes = {
  setModalOpen: PropTypes.func,
}

ThumbnailsCropPopup.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  selectedCourseAlbum: PropTypes.object,
  newThumbnail: PropTypes.object,
  setNewThumbnail: PropTypes.func,
}

export default ThumbnailsCrop
