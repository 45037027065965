import _ from 'lodash'

export const SCHEDULE_FREQUENCY_OPTIONS = [
  {
    value: 'right_now',
    label: 'right now',
  },
  {
    value: 'daily',
    label: 'daily',
  },
  {
    value: 'weekly',
    label: 'weekly',
  },
  {
    value: 'monthly',
    label: 'monthly',
  },
]
  
const computeSchedulingHours = () => {
  const schedulingHours = []
  const hours = [ 12 ].concat(_.range(1, 12));

  [ 'am', 'pm' ].forEach( ampm => {
    hours.forEach( hour => {
      schedulingHours.push({
        value: hour + ':00_' + ampm,
        label: hour + ':00 ' + ampm,
      })
    })
  })

  return schedulingHours
}

const computeSchedulingWeekdays = () => {
  const schedulingWeekdays = []
  const weekdays = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ]

  weekdays.forEach( weekday => {
    schedulingWeekdays.push({
      value: weekday,
      label: weekday,
    })
  } )

  return schedulingWeekdays
}

const dateSuffix = (dayOfMonth) => {
  const lastDigit = dayOfMonth % 10
  const beforeLastDigit = Math.floor(dayOfMonth / 10) % 10
  let suffix = 'th'

  if (beforeLastDigit !== 1){
    if (lastDigit === 1){
      suffix = 'st'
    } else if (lastDigit === 2){
      suffix = 'nd'
    } else if (lastDigit === 3){
      suffix = 'rd'
    }
  }

  return suffix
}

const computeSchedulingDaysOfMonth = () => {
  const schedulingDaysOfMonth = []
  const daysOfMonth = _.range(1, 32)

  daysOfMonth.forEach( dayOfMonth => {
    schedulingDaysOfMonth.push({
      value: dayOfMonth + '',
      label: dayOfMonth + dateSuffix(dayOfMonth),
    })
  } )

  return schedulingDaysOfMonth
}

export const SCHEDULE_HOUR_OPTIONS = computeSchedulingHours()
export const SCHEDULE_WEEKDAY_OPTIONS = computeSchedulingWeekdays()
export const SCHEDULE_DAYS_OF_MONTH_OPTIONS = computeSchedulingDaysOfMonth()
