import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'

const StickyMember = (props) => {
  const member = props.member
  const dateStr = member.date.slice(0, 5) + '\n' + member.date.slice(-4)
  return (
    <Col md={4} className={'sticky-members'}>
      <div className={'sticky-member date'}> {dateStr} </div>  
      <div className={'sticky-member name-event'}>
        <a href = { member.league_link} target="_blank">{member.first_name + ' ' + member.last_name}</a>
        <br/>
        {member.league_name}
      </div>
    </Col>
  )
}
StickyMember.propTypes = {
  member: PropTypes.object,
}

export default StickyMember
