import React, { Children } from 'react'
import PropTypes from 'prop-types'

const KeySettingsThGroup = (props) => {
  const renderThChildrenWithAdditionalProps = () => {
    return Children.map(props.children.filter((child) => child), (child, index) => {
      // The first th from a KeySettingsThGroup will display the expandable group name.
      if (index === 0) {
        return React.cloneElement(child, { // KeySettingsTh
          isExpanded: props.isExpanded,
          groupName: index === 0 ? props.name : null,
          onToggle: () => props.onToggle(),
          isHeaderTwoLeveled: props.isHeaderTwoLeveled,
        })
      }

      // The rest of the ths should be rendered if the group is expanded and hide otherwise.
      return React.cloneElement(child, { // KeySettingsTh
        isHidden: !props.isExpanded,
        isHeaderTwoLeveled: props.isHeaderTwoLeveled,
      })
    })
  }
  return (
    <>
      { renderThChildrenWithAdditionalProps() }
    </>
  )
}

KeySettingsThGroup.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  isExpanded: PropTypes.bool,
  onToggle: PropTypes.func,
  isHeaderTwoLeveled: PropTypes.bool,
}

export default KeySettingsThGroup
