import React, { useState } from 'react'
import PropTypes from 'prop-types'

const SearchBar = ({ flyers, setFlyers }) => {
  const [ searchTerm, setSearchTerm ] = useState('')

  // Function to handle search input change
  const handleInputChange = (event) => {
    const input = event.target.value
    setSearchTerm(input)
    if (input === '') {
      setFlyers(flyers)
    } else {
      const matches = flyers.filter((flyer) =>
        flyer.name.toLowerCase().includes(input.toLowerCase())
      )
      setFlyers(matches)
    }
  }

  return (
    <div className='input-wrapper'>
      <input
        type="text"
        value={searchTerm}
        onChange={handleInputChange}
        placeholder={window.I18n.t('views.golfhub.placeholder_flyer_name')}
        className='input-search'
      />
    </div>
  )
}

SearchBar.propTypes = {
  flyers: PropTypes.array,
  setFlyers: PropTypes.func,
}

export default SearchBar
