import { connect } from 'react-redux'
import PrePlayAudit from '../../components/content/pre_play_audit'
import { getAuditsStatus, getAuditErrorMessages } from '../../actions/index'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAuditsStatus: () => dispatch(getAuditsStatus(ownProps.widgetId, ownProps.content.links.audit_status.path)),
  getAuditErrorMessages: (path) => dispatch(getAuditErrorMessages(ownProps.widgetId, path)),
  refresh: () => dispatch(ownProps.refresh),
})

export default connect(
  null,
  mapDispatchToProps,
)(PrePlayAudit)
