import { createSelector } from 'reselect'
import moment from 'moment'

const getLeague = state => state.misc.potentialCloneCandidate
const getRoundsById = state => state.managedCustomerData.rounds || state.rounds

const getLeagueRounds = createSelector(
  [ getLeague, getRoundsById ],
  (league, roundsById) => {
    return league.rounds.map(roundId => {
      const round = roundsById[roundId]
      return {
        name: round.name,
        date: moment(round.date).format('YYYY-MM-DD'),
      }
    })
  },
)

export default getLeagueRounds
