import { connect } from 'react-redux'

import { fetchInitialData, changeFilter } from '../actions'
import DirectoriesComponent from '../components/directories_component'

const mapStateToProps = (state) => {
  const shouldLoadData = !!state.misc.fetchInitialDataURL
  let tourName
  if (state.filters.tour !== 'all' && state.filters.tour !== 'none' && state.tours[state.filters.tour]) {
    tourName = state.tours[state.filters.tour].name
  }
  return {
    customerName: state.misc.customerName,
    directoryName: state.misc.directoryName,
    directoryDescription: state.misc.directoryDescription,
    tourName: tourName,
    tourId: state.misc.selectedTour,
    shouldLoadData,
    dataIsLoading: !!state.misc.dataIsLoading,
    filters: state.filters,
    myRegistrationsVisible: state.misc.statusFilterVisible.myRegistrations,
    shouldShowLotteryFilter: state.misc.shouldShowLotteryFilter,
    shouldShowFilters: state.misc.shouldShowFilters,
    shouldHideHeader: state.misc.shouldHideHeader,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchData: (tourId) => dispatch(fetchInitialData(tourId)),
  changeFilter: (filter, value) => dispatch(changeFilter(filter, value)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DirectoriesComponent)
