import { connect } from 'react-redux'
import Menu from '../components/menu/menu'
import {
  selectMenuItem,
  loadWidgets,
  toggleNewItemsModal,
  getNewItemsLinks,
  newItemsSearch,
  newItemsSelectRound,
} from '../actions/index'

const mapStateToProps = (state) => {
  return {
    mobileVisibility: state.menu.mobileVisibility,
    menuItems: state.menu.menuItems,
    selectedMenuItem: state.menu.selectedMenuItem,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectMenuItem: (id, path, lastViewedPagePath) => {
    dispatch(selectMenuItem(id, lastViewedPagePath))
    dispatch(loadWidgets(path, id))
    dispatch(newItemsSelectRound(id))
  },
  toggleNewItemsModal: (visible) => {
    dispatch(toggleNewItemsModal(visible))
    if (visible === true) {
      dispatch(getNewItemsLinks(ownProps.getLinksPath))
    } else {
      dispatch(newItemsSearch(''))
    }
  },
  newItemsSearch: (searchTerm) => dispatch(newItemsSearch(searchTerm)),
  newItemsSelectRound: (selectedRound) => dispatch(newItemsSelectRound(selectedRound)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Menu)
