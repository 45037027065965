import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { GlgTooltip } from '../../../shared/components'

class EventPlayers extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { title, links, playersInfoData } = this.props.content

    return (
      <div className='widget-content-default widget-content-event-players'>
        <div className='title'>
          { title }

          <a href={ links.manage_player_roster.path } className='btn btn-primary widget-white-title-button'>
            { links.manage_player_roster.name }
          </a>
        </div>
        <div className='buttons-container-players pull-right'>
          {
            playersInfoData.map( playerInfo =>
              <div className='btn btn-primary player-info' key={ playerInfo.name }>{ `${playerInfo.name}: ${playerInfo.data}` }</div>
            )
          }
        </div>
        <div className='content'>
          {
            links.registration_form.enabled || links.registration_payments.enabled
              ? <div className='buttons-container'>
                {links.registration_form.golf_hub_registration_active ?
                  <GlgTooltip tooltip={ window.I18n.t('bootstrap_nav.apps.golf_hub.premium_registration_disabled') } placement='bottom' >
                    { links.registration_form.enabled ?
                      <a href={ links.registration_form.path }
                        className={'btn btn-primary white-buttons disabled'}
                        style={ { pointerEvents: 'all' } }>
                          { links.registration_form.name }
                      </a> : null }
                  </GlgTooltip>
                :
                  links.registration_form.enabled ? <a href={ links.registration_form.path }
                                                       className='btn btn-primary white-buttons'>
                                                      { links.registration_form.name }
                                                    </a> : null
                }
                { links.registration_payments.enabled ? <a href={ links.registration_payments.path } className='btn btn-primary white-buttons'>{ links.registration_payments.name }</a> : null }
                { links.payment_center.enabled ? <a href={ links.payment_center.path } className='btn btn-primary white-buttons'>{ links.payment_center.name }</a> : null }
              </div>
              : null
          }
          <div className='buttons-container'>
            { links.roster_activity_log.enabled ? <a href={ links.roster_activity_log.path } className='btn btn-primary top-buttons'>{ links.roster_activity_log.name }</a> : null }
            { links.update_hanidcap.enabled ? <a href={ links.update_hanidcap.path } className='btn btn-primary top-buttons'>{ links.update_hanidcap.name }</a> : null }
            { links.last_access_handicap.enabled ? <a href={ links.last_access_handicap.path } className='handicap-log'>{ links.last_access_handicap.name }</a> : null}
          </div>
          <div className='buttons-container'>
            { links.import_golfers.enabled ? <a href={ links.import_golfers.path } className='btn btn-primary black-buttons'>{ links.import_golfers.name }</a> : null }
            { links.manually_add_players.enabled ? <a href={ links.manually_add_players.path } className='btn btn-primary black-buttons'>{ links.manually_add_players.name }</a> : null }
            { links.upload_roster.enabled ? <a href={ links.upload_roster.path } className='btn btn-primary black-buttons'>{ links.upload_roster.name }</a> : null }
          </div>
        </div>
      </div>
    )
  }
}

EventPlayers.propTypes = { 
  content: PropTypes.object,
}

EventPlayers.defaultProps = {
  title: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_players.title'),
  playersInfoData: [],
  roundsCount: 0,
  links: {
    manage_player_roster: {
      name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_players.manage_player_roster'),
      path: '#',
    },
    roster_activity_log: {
      name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_players.roster_activity_log'),
      path: '#',
    },
    update_hanidcap: {
      name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_players.update_hi_from_ghin'),
      path: '#',
    },
    import_golfers: {
      name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_players.import_golfers'),
      path: '#',
    },
    manually_add_players: {
      name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_players.manually_add_players_to_roster'),
      path: '#',
    },
    upload_roster: {
      name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_players.upload_roster_from_spreadsheet'),
      path: '#',
    },
    registration_form: {
      name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_players.registration_center'),
      path: '#',
    },
    registration_payments: {
      name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.event_players.registration_payments'),
      path: '#',
    },
  },
}

export default EventPlayers
