import React from 'react'
import PropTypes from 'prop-types'
import rootReducer from '../reducers'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import GroupViewContainer from './group_view_container'

const GroupViewRoot = ({fetchInitialDataUrl, roundId, leagueId, tournaments, selectedTournamentId, selectedTournamentName, holeView, isPortal, rounds}) => {
  const state = {
    misc: {
      roundId,
      leagueId,
      fetchInitialDataUrl,
      tournaments,
      selectedTournamentId,
      selectedTournamentName,
      holeView,
      isPortal,
      rounds,
    },
  }

  const store = configureStore(rootReducer, state)

  return (
    <GlobalProvider store = { store }>
      <GroupViewContainer/>
    </GlobalProvider>
  )
}

GroupViewRoot.propTypes = {
  fetchInitialDataUrl: PropTypes.string,
  roundId: PropTypes.string,
  leagueId: PropTypes.string,
  tournamnentId: PropTypes.string,
  tournaments: PropTypes.object,
  selectedTournamentId: PropTypes.string,
  holeView: PropTypes.string,
  isPortal: PropTypes.bool,
  rounds: PropTypes.object,
  selectedTournamentName: PropTypes.string,
}

export default GroupViewRoot
