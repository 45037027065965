import React from 'react'
import PropTypes from 'prop-types'
import {GlobalProvider} from '../../../shared/global_context'
import configureStore from '../../../shared/configure_store'
import DraftContainer from './draft_container'
import draftSetupReducer from '../../reducers/draft_setup_index'

const DraftRoot = (initialState) => {

  const state = {
    drafts: {
      id: initialState.draft.id,
      teamsetId: initialState.draft.teamsetId,
      name: initialState.draft.name,
      order: initialState.draft.order,
      roundsOrder: initialState.draft.roundsOrder || {},
      rankingMethod: initialState.draft.rankingMethod,
      prevLeagueId: initialState.draft.prevLeagueId,
      grossScores: initialState.draft.grossScores,
      pickTime: initialState.draft.pickTime,
    },

    misc: {
      importGolfersUrl: initialState.importGolfersUrl,
      manuallyAddGolfersUrl: initialState.manuallyAddGolfersUrl,
      uploadFromSpreadsheetUrl: initialState.uploadFromSpreadsheetUrl,
      teams: initialState.teams,
      golfers: initialState.golfers,
      roundsCount: initialState.teams.length === 0 ? 0 : Math.ceil((initialState.golfers.length - Object.keys(initialState.teamCaptains || {}).length) / initialState.teams.length),
      previousLeagues: initialState.previousLeagues,
      teamCaptains: initialState.teamCaptains || {},
      draftsUrl: initialState.draftsUrl,
      action: initialState.action,
    },
  }

  const store = configureStore(draftSetupReducer, state)

  return (
    <GlobalProvider store={store}>
      <DraftContainer/>
    </GlobalProvider>
  )
}

DraftRoot.propTypes = {
  initialState: PropTypes.any,
}

export default DraftRoot
