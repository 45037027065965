import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { GlgCheckbox } from 'SharedComponents'

const ROUND_NAME_LIMIT = 25

class RoundsSectionComponent extends Component {

  buildTooltip = (round) => {
    let tooltip = round.pretty_date
    if (round.name.length > ROUND_NAME_LIMIT) {
      tooltip = `${round.name} &bull; ${round.pretty_date}`
    }
    return tooltip
  }

  render() {
    const {
      rounds,
      toggleRound,
      checkAllRounds,
    } = this.props

    return <Fragment><div className="rounds no-padding"><div className="check_uncheck"><a onClick={ () => checkAllRounds(true) }>{ !window.I18n ? '' : window.I18n.t('tv_shows.components.rounds_section_component.check_all') }</a><span>{ ' / ' }</span><a onClick={ () => checkAllRounds(false) }>{ !window.I18n ? '' : window.I18n.t('tv_shows.components.rounds_section_component.uncheck_all') }</a></div><div className="clear"></div><div className="round_options"><ul className="clearfix">{ Object.keys(rounds).map( (roundId) => { 
                  const round = rounds[roundId]
                  return <li key={roundId} className="single_round"><GlgCheckbox label={ round.name.slice(0, ROUND_NAME_LIMIT) } tooltip={ this.buildTooltip(round) } key={ roundId } name={ round.input_name } checked={ round.checked } onChange={ (checked) => toggleRound(checked, roundId) } inline={ true }></GlgCheckbox></li>
                })
              }</ul></div></div></Fragment>
  }
}

RoundsSectionComponent.propTypes = {
  rounds: PropTypes.object.isRequired,
  toggleRound: PropTypes.func.isRequired,
  checkAllRounds: PropTypes.func.isRequired,
}

export default RoundsSectionComponent
