import { combineReducers } from 'redux'
import navigatorOptions from './navigator_options'
import navigatorHelpLinks from './navigator_help_links'
import bookmarks from './bookmarks'
import rounds from './rounds'
import misc from './misc'

import popupReducerCreator from 'Shared/reducers/popup_reducer_creator'
import PopupTypes from '../popup_types'
const popupStates = popupReducerCreator(PopupTypes)

export default combineReducers({
  navigatorOptions,
  navigatorHelpLinks,
  bookmarks,
  rounds,
  misc,
  popupStates,
})
