import { connect } from 'react-redux'

import GroupViewTableComponent from '../components/group_view_table_component'

import { getTeesFilteredByCourse, getSortedPairings } from '../selectors'

const mapStateToProps = (state) => {
  return {
    tees: getTeesFilteredByCourse(state),
    foursomes: getSortedPairings(state),
    scoreDisplay: state.filters.displayOption === 'scores' || state.groupViewData.isStableford,
    isStableford: state.groupViewData.isStableford,
    isMultiRound: state.groupViewData.isMultiRound,
    isLoadingTournament: state.groupViewData.isLoadingTournament,
    pastRounds: state.groupViewData.pastRounds,
    holeView: state.misc.holeView,
    isPortal: state.misc.isPortal,
  }
}


export default connect(
  mapStateToProps,
  null
)(GroupViewTableComponent)
