import React from 'react'
import PropTypes from 'prop-types'
import rootReducer from '../reducers'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import CourseBoardContainer from './couse_board_container'

const CourseBoardRoot = ({roundGgid, lines, showMessage, message, score, boardAvailable, showCourse, lastUpdate, initialShowMessage, initialNoRows} ) => {
  const state = {
    misc: {
      roundGgid,
      lines,
      showMessage,
      message,
      score,
      boardAvailable,
      showCourse,
      lastUpdate,
      initialShowMessage,
      initialNoRows,
    },
  }

  const store = configureStore(rootReducer, state)

  return (
    <GlobalProvider store={ store }>
      <CourseBoardContainer />
    </GlobalProvider>
  )
}

CourseBoardRoot.propTypes = {
  roundGgid: PropTypes.string,
  lines: PropTypes.array,
  showMessage: PropTypes.bool,
  message: PropTypes.string,
  score: PropTypes.string,
  boardAvailable: PropTypes.bool,
  showCourse: PropTypes.bool,
  lastUpdate: PropTypes.string,
  initialShowMessage: PropTypes.string,
  initialNoRows: PropTypes.number,
}

export default CourseBoardRoot
