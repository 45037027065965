import { connect } from 'react-redux'
import HoleByHoleTableComponent from '../components/hole_by_hole_table_component'
import { getTableColumns, getPlayersOnMonsterBoard } from 'Shared/course_boards' 
import { removeLine, reorderLines, handleShowMessageChanged, handleMessageChanged, handleScoreChanged } from '../actions' 

const mapStateToProps = (state) => {
  const isMultiRound = state.misc.isMultiRound
  const withTeams = state.misc.withTeams
  const showCourse = state.misc.showCourse
  const columns = getTableColumns(isMultiRound, withTeams, showCourse, true)
  const lines = getPlayersOnMonsterBoard(state)

  return {
    isMultiRound: isMultiRound,
    withTeams: withTeams,
    showCourse: showCourse,
    lines: lines,
    columns: columns,
    showMessage: state.misc.showMessage,
    message: state.misc.message,
    score: state.misc.score,
  }
}

const mapDispatchToProps = (dispatch) => ({
  removeLine: (position, front9) => dispatch(removeLine(position, front9)),
  reorderLines: (fromPosition, toPosition) => dispatch(reorderLines(fromPosition, toPosition)),
  handleShowMessageChanged: (value) => dispatch(handleShowMessageChanged(value)),
  handleMessageChanged: (value) => dispatch(handleMessageChanged(value)),
  handleScoreChanged: (value) => dispatch(handleScoreChanged(value)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HoleByHoleTableComponent)
