import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const popupVerifyScoresComponent = ({isMultiRound, scoreData, handleScoreChange, matchedScores, handleKeyPress}) => {
  const scores = [ ...scoreData.scores ]

  return (
    <tr className='verify_scores_row'>
        <td className='with_border'>Verify</td>
        { [ ...Array(9) ].map( ( _, i) => (
            <Fragment key={i}>
                <td key={'hole-' + i} className={matchedScores[i] ? 'with_border' : 'with_border yellow_column'}>
                    <input className='new_score'
                           name={'score-' + i}
                           inputMode='numeric'
                           autoComplete='off'
                           value={scores[i] !== 0 && scores[i] !== null ? scores[i] : ''}
                           onChange={(e) => handleScoreChange(i, e.target.value)}
                           onFocus={(e) => e.target.select()}
                           onKeyDown={(e) => handleKeyPress(i, e)} />
                </td>
                { (i + 1) % 3 === 0 && i !== 8 && <td className="with_border gray_column"></td> }
            </Fragment>
        )) }
        <td className='with_border blue_column'>{scoreData.outScore !== 0 ? scoreData.outScore : ''}</td>
        { [ ...Array(9) ].map( ( _, i) => (
            <Fragment key={i}>
                <td key={'hole-' + i} className={matchedScores[i + 9] ? 'with_border' : 'with_border yellow_column'}>
                    <input className='new_score'
                           name={'score-' + (i + 9)}
                           inputMode='numeric'
                           value={scores[i + 9] !== 0 && scores[i + 9] !== null ? scores[i + 9] : ''}
                           onChange={(e) => handleScoreChange(i + 9, e.target.value)}
                           onFocus={(e) => e.target.select()}
                           onKeyDown={(e) => handleKeyPress(i + 9, e)} />
                </td>
                { (i + 1) % 3 === 0 && i !== 8 && <td className="with_border gray_column"></td> }
            </Fragment>
        )) }
        <td className='with_border blue_column'>{scoreData.inScore !== 0 ? scoreData.inScore : ''}</td>
        <td className='with_border blue_column'>{scoreData.roundScore !== 0 ? scoreData.roundScore : ''}</td>
        { isMultiRound && <td className='with_border blue_column'>{scoreData.totalScore !== 0 ? scoreData.totalScore : ''}</td> }
    </tr>
  )
}

popupVerifyScoresComponent.propTypes = {
  isMultiRound: PropTypes.bool,
  scoreData: PropTypes.object,
  handleScoreChange: PropTypes.func,
  matchedScores: PropTypes.array,
  handleKeyPress: PropTypes.func,
}

export default popupVerifyScoresComponent
