import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import AccordionSection from './glg_accordion_section'

class Accordion extends Component {
  
  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this)

    this.state = { 
      openSections: {},
    }
  }

  onClick(label) {
    const {
      state: { openSections },
    } = this

    const isOpen = !!openSections[label]

    this.setState({
      openSections: {
        [label]: !isOpen,
      },
    })
  }

  render() {
    const {
      onClick,
      props: { children },
      state: { openSections },
    } = this

    return (
      <Fragment>
        {children.map((child, index) => (
          <AccordionSection
            key={index}
            isOpen={!!openSections[child.props.label]}
            label={child.props.label}
            iconPath={child.props.iconPath}
            onClick={onClick}
          >
            {child}
          </AccordionSection>
        ))}
      </Fragment>
    )
  }
}

Accordion.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
}

export default Accordion
