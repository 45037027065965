import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
// import { Row, Col } from 'react-flexbox-grid'
import { Icon as FaIcon } from 'react-fa'

class ClearFilters extends PureComponent {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange() {
    this.props.onChange('clear', '')
  }

  render() {
  
    
    return <div className="clear-filters" onClick={ this.handleChange }><div className="text">{ !window.I18n ? '' : window.I18n.t('customer_directories.filters.clear_filters') }</div><div className="icon" onClick={ this.handleChange }><FaIcon name={'times'}></FaIcon></div></div>
  }
}

ClearFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default ClearFilters
