import { ActionTypes } from '../actions/setup'

const {
  TOGGLE_FILTER,
  SET_GENDER,
  SET_AGE,
  TOGGLE_ROUND_ATTENDANCE_OPTION,
  SET_HANDICAP_INDEX,
  ADD_NEW_FILTER,
  REMOVE_FILTER,
  SET_FILTER_ATTRIBUTE_VALUE,
  SET_DROPDOWN_FILTER_VALUE,
} = ActionTypes

const getSelectedOptions = (state, action) => {
  const selectedOptions = [ ...state.roundAttendance.selected ]
  if (selectedOptions.includes(action.playerStatus)){
    selectedOptions.splice(selectedOptions.indexOf(action.playerStatus), 1)
  } else {
    selectedOptions.push(action.playerStatus)
  }
  return selectedOptions
}

const initialState = {}
const nextIdForFilter = (filterType) => {
  const filters = filterType.filters
  const maxId = Math.max(...filters.map(filter => filter.id))
  return maxId + 1
}

const defaults = {
  customField: {
    customFieldId: 'default',
    customFieldValue: 'default',
  },
  membershipProgram: {
    membershipId: 'default',
    equalGreaterOrEqual: 'equal',
    days: '2',
  },
}

function recipientList(state = initialState, action) {
  switch (action.type){
    case TOGGLE_FILTER:
      return {
        ...state,
        [action.filter]: {
          ...state[action.filter],
          checked: !state[action.filter].checked,
        },
      }
    case SET_GENDER:
      return {
        ...state,
        gender: {
          ...state.gender,
          selected: action.gender,
        },
      }
    case SET_AGE:
      return {
        ...state,
        age: {
          ...state.age,
          [action.minOrMaxOrDate]: action.age,
        },
      }
    case TOGGLE_ROUND_ATTENDANCE_OPTION:
      return {
        ...state,
        roundAttendance: {
          ...state.roundAttendance,
          selected: getSelectedOptions(state, action),
        },
      }
    case SET_HANDICAP_INDEX:
      return {
        ...state,
        handicapIndex: {
          ...state.handicapIndex,
          [action.minOrMax]: action.value,
        },
      }
    case SET_FILTER_ATTRIBUTE_VALUE:
      return {
        ...state,
        [action.filterType]: {
          ...state[action.filterType],
          [action.filterAttribute]: action.value,
        },
      }
    case SET_DROPDOWN_FILTER_VALUE:
      return {
        ...state,
        [action.filterType]: {
          ...state[action.filterType],
          filters: state[action.filterType].filters.map( filter => {
            if (filter.id === action.filterId) {
              return {
                ...filter,
                [action.dropdownName]: action.dropdownValue,
              }
            }
            return filter
          }),
        },
      }
    case ADD_NEW_FILTER:
      return {
        ...state,
        [action.filterType]: {
          ...state[action.filterType],
          filters: state[action.filterType].filters.concat({
            id: nextIdForFilter(state[action.filterType]),
            includeExclude: 'include',
            ...defaults[action.filterType],
          }),
        },
      }
    case REMOVE_FILTER:
      return {
        ...state,
        [action.filterType]: {
          ...state[action.filterType],
          filters: state[action.filterType].filters.filter((filter) => {
            return filter.id !== action.filterId
          }),
        },
      }
    default:
      return state
  }
}

export default recipientList
