import { connect } from 'react-redux'
import { closeDeleteRosterConfirmationPopup, deleteEntireRoster, toggleDownloadRoster } from '../../actions/index'
import DeleteRosterConfirmationPopup from '../../components/sidebar_content/delete_roster_confirmation_popup'

const mapStateToProps = (state) => ({
  show: state.deleteRosterConfirmation.isOpen,
  isLoading: state.deleteRosterConfirmation.isLoading,
  actionMessage: state.deleteRosterConfirmation.actionMessage,
  isDeleteRosterLoading: state.deleteRosterConfirmation.isDeleteRosterLoading,
  isRosterDownloaded: state.deleteRosterConfirmation.isRosterDownloaded,
  downloadLeagueSpreadsheetfilesPath: state.misc.downloadLeagueSpreadsheetfilesPath,
})

const mapDispatchToProps = (dispatch) => ({
  closeDeleteRosterConfirmationPopup: () => dispatch(closeDeleteRosterConfirmationPopup()),
  deleteEntireRoster: () => dispatch(deleteEntireRoster()),
  toggleDownloadRoster: () => dispatch(toggleDownloadRoster()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteRosterConfirmationPopup)
