import { createSelector } from 'reselect'
import _ from 'lodash'

const getDefaultTeesById = (state, courseId) => {
  if (Object.keys(state).length > 0){
    return state.find(x => x.id === courseId).tees
  } else {
    return {}
  }
}

const getSortedDefaultTeesIds = createSelector(
  [ getDefaultTeesById ],
  (defaultTeesbyId) => {
    return _.sortBy(_.keys(defaultTeesbyId), defaultTeeId => defaultTeesbyId[defaultTeeId].position)
  },
)

export default getSortedDefaultTeesIds
