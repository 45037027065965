import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import { GlgLoadingSpinner } from 'SharedComponents'
import { NONE, SUCCESS } from '../constants'
import ImageTitleFilter from './image_title_filter'
class AlbumPickerComponent extends Component {
  
  constructor(props) {
    super(props)

    this.state = {
      isFetchingData: false,
      isUploadingData: false,
      selected: '',
      selectedForUpload: [],
      imagesLinks: [],
      searchedTitle: '',
    }

    this.setStateProperty = this.setStateProperty.bind(this)
    this.fetchContent = this.fetchContent.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleUpload = this.handleUpload.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.onSearchedTitleChange = this.onSearchedTitleChange.bind(this)
  }

  setStateProperty(property, value) {
    this.setState({
      ...this.state,
      [property]: value,
    })
  }

  componentDidMount() {
    this.fetchContent()
  }

  fetchContent() {
    this.setStateProperty('isFetchingData', true)
    $.ajax({
      url: this.props.fetchImagesPath,
      type: 'GET',
      data:
        { image_title: this.state.searchedTitle },
      success: (response) => {
        const imageLinks = response.albums.map((album) => {
          if (album.photos.length > 0 && album.photos[0].link) {
            return album.photos[0].link
          }
          return NONE
        })
        this.setStateProperty('imagesLinks', imageLinks)
        this.setStateProperty('albums', response.albums)
      },
      error: () => {},
      complete: () => { this.setStateProperty('isFetchingData', false) },
    })
  }

  handleFileChange(e) {
    this.setStateProperty('selectedForUpload', e.target.files)
  }

  handleUpload() {
    const imageInFormData = new FormData()
    const { imagesLinks } = this.state
    imageInFormData.append('image', this.state.selectedForUpload[0] )
    this.setStateProperty('isUploadingData', true)

    $.ajax({
      url: this.props.uploadImagePath,
      data: imageInFormData,
      processData: false,
      contentType: false,
      type: 'POST',
      success: (response) => {
        if (response.status === SUCCESS) {
          this.setState({
            ...this.state,
            imagesLinks: [ ...imagesLinks, response.link ],
            selected: response.link,
          })
          this.handleSave()
        }
      },
      error: () => {},
      complete: () => { this.setStateProperty('isUploadingData', false) },
    })
  }

  handleSave() {
  }

  onSearchedTitleChange(newSearchedTitle) {
    this.setState({
      searchedTitle: newSearchedTitle,
    }, () => {
      this.fetchContent()
    })
  }

  render() {
    const { albums, imagesLinks, isFetchingData } = this.state
    if (this.props.selectedAlbumPhotos.length > 0) {
      return <div className="div"></div>
    }

    return <div className="div"><div className={ 'well white_background clearfix album_picker_container text-align-center' }><Row><Col xs={12} md={6} lg={6} xl={6}></Col><Col xs={12} md={6} lg={6} xl={6}><ImageTitleFilter value={ this.state.searchedTitle } onChange={ this.onSearchedTitleChange }></ImageTitleFilter></Col></Row><GlgLoadingSpinner show={ isFetchingData }></GlgLoadingSpinner>{ !isFetchingData && imagesLinks.length === 0 && 'There are no albums in this Event.' }<ul className="logos col-xs-12 album_picker_roll_container">{ !isFetchingData && imagesLinks.map( (imageLink, index) => <li key={ index } className={ 'align-items-center album_picker_pictures' }><div className={ `logo ${this.state.selected === imageLink ? 'selected' : ''}` } onClick={ () => { this.setStateProperty('selected', imageLink) } }>{ imageLink === NONE ?
                    <img src={ require('../../../../assets/images/league_placeholder.png') } /> :
                    <img src={ imageLink } onClick={ () => { this.props.selectAlbum(albums[index].photos) }} />
                  }</div><div className="text-center">{ `${albums[index].name}` }<br />{ `${albums[index].photos.length} photo${albums[index].photos.length !== 1 ? 's' : ''}` }<p className={'album_picker_picture_frame'} onClick={ () => { this.props.addToRoll(albums[index].id, 'album', albums[index].photos) }}>{'Add to Roll'}</p></div></li>)}</ul></div></div>
  }
}

AlbumPickerComponent.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  showNone: PropTypes.bool,
  uploadText: PropTypes.string,
  saveText: PropTypes.string.isRequired,
  saveDisabledText: PropTypes.string.isRequired,
  fetchImagesPath: PropTypes.string.isRequired,
  uploadImagePath: PropTypes.string.isRequired,
  addToRoll: PropTypes.func.isRequired,
  selectedAlbumPhotos: PropTypes.array.isRequired,
  selectAlbum: PropTypes.func.isRequired,
}

AlbumPickerComponent.defaultProps = {
  uploadText: 'Upload',
  showNone: false,
  show: true,
  saveText: 'Save',
  saveDisabledText: 'Save',
}

export default AlbumPickerComponent
