/*
{
  id: null,
  name: null,
  url: null,
}
*/

const customers = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default customers
