import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { GlgList } from 'SharedComponents'
import CategoryContainer from '../containers/category_container'

class CategoriesListComponent extends Component {
  constructor(props) {
    super(props)
    this.handleCategoriesSortEnd = this.handleCategoriesSortEnd.bind(this)
    this.handleLeaguesSortEnd = this.handleLeaguesSortEnd.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.categoryIds, nextProps.categoryIds)
  }

  // Categories drag&drop
  handleCategoriesSortEnd(fromIndex, toIndex) {
    this.props.reorderCategories(this.props.categoryIds[fromIndex],
                                 this.props.categoryIds[toIndex])
  }

  // Category View drag&drop
  handleLeaguesSortEnd(fromIndex, toIndex, fromCategoryId, toCategoryId) {
    // Dropping a league on the category header puts it inside the category
    if (toCategoryId.startsWith('header_')) {
      toCategoryId = toCategoryId.substr('header_'.length)

      // Dropping a league on it's own category header will not work
      if (fromCategoryId === toCategoryId) {
        return
      }

      setTimeout(() => {
        this.props.closeCategory(toCategoryId)
        this.props.toggleCategory(toCategoryId)
      }, 200)
    }
    this.props.reorderLeagues(fromIndex,
                              toIndex,
                              fromCategoryId,
                              toCategoryId)
  }

  render() {
    return <GlgList items={ this.props.categoryIds.map(id => ({ id })) } itemComponent={ CategoryContainer } isSortable={ true } onSortEnd={ this.handleCategoriesSortEnd } onNestedSortEnd={ this.handleLeaguesSortEnd }></GlgList>
  }
}

CategoriesListComponent.propTypes = {
  categoryIds: PropTypes.array.isRequired,
  reorderCategories: PropTypes.func.isRequired,
  reorderLeagues: PropTypes.func.isRequired,
  closeCategory: PropTypes.func.isRequired,
  toggleCategory: PropTypes.func.isRequired,
}

export default CategoriesListComponent
