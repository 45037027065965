import { connect } from 'react-redux'
import EventSetupHandicaps from '../../components/content/event_setup_handicaps'
import {
  setGenericData,
  saveEventSettingsWidget,
} from '../../actions/index'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => ({
  setData: (keys, value) => {
    dispatch(setGenericData(ownProps.widgetId, keys, value))
    if (! ownProps.content.dataChanged) {
      dispatch(setGenericData(ownProps.widgetId, [ 'dataChanged' ], true))
    }
  },
  save: () => {
    const { data, links } = ownProps.content

    const extractHandicapIndexes = (obj) => ({
      'eighteen_hole': {
        'handicap_index': obj['eighteen_hole']['handicap_index']['value'],
        'course_handicap': obj['eighteen_hole']['course_handicap']['value'],
      },
      'nine_hole': {
        'handicap_index': obj['nine_hole']['handicap_index']['value'],
        'course_handicap': obj['nine_hole']['course_handicap']['value'],
      },
    })

    const requestData = {
      'card_type': data.card_type.selected,
      'maximum_handicap_indexes': extractHandicapIndexes(data.maximum_handicap_indexes),
      'allocate_by_subset': {
        [data.allocate_by_subset.key]: data.allocate_by_subset.selected,
      },
      'day_handicap': {
        [data.day_handicap.key]: data.day_handicap.selected,
      },
      'day_handicap_method': {
        [data.day_handicap_method.key]: data.day_handicap_method.value,
      },
      'peoria_holes': {
        [data.peoria_holes.key]: data.peoria_holes.holes,
      },
    }
    dispatch(saveEventSettingsWidget(ownProps.widgetId, links.save.path, requestData))
  },
})

export default connect(
  null,
  mapDispatchToProps,
)(EventSetupHandicaps)
