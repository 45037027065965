import React, { Component, Fragment } from 'react'
import { GlgSelect, GlgTable, GlgTableCol, GlgButton } from 'SharedComponents'
import EmailDeliveryReportTableRow from './email_delivery_report_table_row'
import { Row, Col } from 'react-flexbox-grid'
import PropTypes from 'prop-types'

class EmailDeliveryReportsComponent extends Component {

  constructor(props) {
    super(props)

    this.state = {
      pages: [],
    }

    this.getReportsPage(props.emails.currentPage)

    this.getReportsPage = this.getReportsPage.bind(this)
  }

  getReportsPage(page) {
    const {
      setCurrentEmailReports,
      loadEmailDeliveryReportsPath,
      buildPageNumbers,
      perPage,
    } = this.props

    const requestFilters = { ...this.props.emails.filters }
    Object.keys(requestFilters).forEach((key) => {
      if (requestFilters[key] === 'default') {
        delete requestFilters[key]
      }
    })

    $.ajax({
      url: loadEmailDeliveryReportsPath,
      data: {page: page, perPage: perPage, ...requestFilters},
      type: 'POST',
      success: (response) => {
        setCurrentEmailReports(response.email_delivery_reports, page)
        this.setState({
          pages: buildPageNumbers(response.total_reports),
        })
      },
      error: () => {
        // eslint-disable-next-line no-console
        console.log('Error')
      },
    })
  }

  render() {
    const {
      formatDate,
      filterDropdownValues,
      setEmailFilterValue,
      emails: {
        filters,
      },
    } = this.props

    return <Fragment><Row className="vertical-align"><Col xs={4} lg={1} className="no-padding-right">{ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.email_delivery_reports_component.select_recipient') }</Col><Col xs={8} lg={2}><GlgSelect items={ filterDropdownValues.players } defaultValue={ filters.player } onChange={ setEmailFilterValue('player') } searchable={ true }></GlgSelect></Col><Col xs={4} lg={1} className="no-padding-right">{ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.email_delivery_reports_component.select_subject') }</Col><Col xs={8} lg={2}><GlgSelect items={ filterDropdownValues.subjects } defaultValue={ filters.subject } onChange={ setEmailFilterValue('subject') } searchable={ true }></GlgSelect></Col><Col xs={4} lg={1} className="no-padding-right">{ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.email_delivery_reports_component.select_status') }</Col><Col xs={8} lg={2}><GlgSelect items={ filterDropdownValues.statuses } defaultValue={ filters.status } onChange={ setEmailFilterValue('status') } searchable={ true }></GlgSelect></Col><Col xs={4} lg={1}><GlgButton text={ !window.I18n ? '' : window.I18n.t('buttons.go') } onClick={ () => { this.getReportsPage(1) }}></GlgButton></Col></Row><Row className="add-margin-top-10"><Col xs={12}><div className="pull-right">{this.state.pages.map((page) => {
                if (page !== this.props.emails.currentPage ) {
                  return <a href={'#' + page} key={page} onClick={ () => this.getReportsPage(page) } className="add-margin-left-3 add-margin-right-3">{page}</a>
                } else {
                  return <em key={page} className="add-margin-left-3 add-margin-right-3">{page}</em>
                }
              })}</div></Col></Row><Row className="add-margin-top-10"><Col xs={12}><GlgTable items={ this.props.emails.reports } rowComponent={ EmailDeliveryReportTableRow } rowExtraProps={{ formatDate: formatDate }}><GlgTableCol width={ '15%' } dataKey={ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.email_delivery_reports_component.player') }></GlgTableCol><GlgTableCol width={ '25%' } dataKey={ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.email_delivery_reports_component.subject') }></GlgTableCol><GlgTableCol width={ '5%' } dataKey={ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.email_delivery_reports_component.body') }></GlgTableCol><GlgTableCol width={ '20%' } dataKey={ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.email_delivery_reports_component.to') }></GlgTableCol><GlgTableCol width={ '10%' } dataKey={ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.email_delivery_reports_component.status') }></GlgTableCol><GlgTableCol width={ '5%' } dataKey={ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.email_delivery_reports_component.opens') }></GlgTableCol><GlgTableCol width={ '5%' } dataKey={ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.email_delivery_reports_component.clicks') }></GlgTableCol><GlgTableCol width={ '15%' } dataKey={ !window.I18n ? '' : window.I18n.t('marketing_campaigns.components.delivery_reports.email_delivery_reports_component.last_update') }></GlgTableCol></GlgTable></Col></Row></Fragment>
  }
}

EmailDeliveryReportsComponent.propTypes = {
  emails: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    reports: PropTypes.array.isRequired,
    filters: PropTypes.shape({
      player: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
      subject: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
      status: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
    }).isRequired,
  }),
  filterDropdownValues: PropTypes.shape({
    players: PropTypes.array.isRequired,
    subjects: PropTypes.array.isRequired,
    statuses: PropTypes.array.isRequired,
  }).isRequired,
  perPage: PropTypes.number.isRequired,
  loadEmailDeliveryReportsPath: PropTypes.string.isRequired,
  setCurrentEmailReports: PropTypes.func.isRequired,
  setEmailFilterValue: PropTypes.func.isRequired,
  formatDate: PropTypes.func,
  buildPageNumbers: PropTypes.func,
}

export default EmailDeliveryReportsComponent
