import { showErrorNotification, callAPI } from 'Shared/helpers'

export const ActionTypes = {
  FETCH_STATISTIC_DATA: 'FETCH_STATISTIC_DATA',
  FETCH_POPUP_STATISTIC_DATA: 'FETCH_POPUP_STATISTIC_DATA',
  FETCH_POPUP_STATISTIC_DATA_STARTED: 'FETCH_POPUP_STATISTIC_DATA_STARTED',
  OPEN_POPUP: 'OPEN_POPUP',
  CLOSE_POPUP: 'CLOSE_POPUP',
  FETCH_STATISTIC_DATA_FAILED: 'FETCH_STATISTIC_DATA_FAILED',
}

export const fetchStatisticData = (data, key) => ({
  type: ActionTypes.FETCH_STATISTIC_DATA,
  key: key,
  data: data,
})

export const fetchPopupStatisticData = (data) => ({
  type: ActionTypes.FETCH_POPUP_STATISTIC_DATA,
  data: data,
})

export const fetchPopupStatisticDataStarted = (round) => ({
  type: ActionTypes.FETCH_POPUP_STATISTIC_DATA_STARTED,
  selectedRound: round,
})

export const loadStatisticData = (statKey, roundId = 0, fromPopup = false) => (dispatch, getState) => {
  const state = getState()
  const url = state.misc.fetchInitialDataUrl
  if (fromPopup) {
    const round = state.misc.rounds.find((round) => round.id === roundId)
    dispatch(fetchPopupStatisticDataStarted(round))
  }
  callAPI(url, 'GET', {statistic: statKey, round_id: roundId})
    .then(response => {
      fromPopup ? dispatch(fetchPopupStatisticData(response)) : dispatch(fetchStatisticData(response, statKey))
    })
    .catch(() => {
      const statName = fromPopup ? state.popup.statistic.title : state.statistics.find((s) => s.key === statKey).name
      showErrorNotification(`Error while fetching data for ${statName}`, 1500)
      dispatch({type: ActionTypes.FETCH_STATISTIC_DATA_FAILED, key: statKey})
    })
}

export const openPopupAction = (statKey, title, columns, content) => (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: ActionTypes.OPEN_POPUP,
    statistic: {
      key: statKey,
      title: title,
      columns: columns,
      content: content,
    },
    selectedRound: state.misc.rounds[state.misc.rounds.length - 1],
  })
}

export const closePopupAction = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_POPUP,
  })
}
