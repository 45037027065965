/*
 * This reducer is for data used when creating and cloning a new league while
 * viewing an other customer than the one the user manages.
 */
import { ActionTypes } from '../actions'

const {
  FETCH_INITIAL_DATA_RECEIVED,
} = ActionTypes

const managedCustomerData = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INITIAL_DATA_RECEIVED:
      return {
        ...state,
        ...action.data.managedCustomerData,
      }
    default:
      return state
  }
}

export default managedCustomerData
