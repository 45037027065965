import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import QuickEventPopup from './quick_event_popup'
import CreateEventPopup from './create_event_popup'
import CloneEventPopup from './clone_event_popup'
import CloneEventCopyOptionsPopup from './clone_event_copy_options_popup'
import CloneEventDatesPopup from './clone_event_dates_popup'
import CategoryPopup from './category_popup'
import ChangeCategoryPopup from './change_category_popup'
import ChangeSeasonPopup from './change_season_popup'
import ChangeTourPopup from './change_tour_popup'
import InvitationListRegistrationPopup from './invitation_list_registration_popup'
import ExistingTimesPopup from './existing_times_popup'

const CCPopupsComponent = ({
  closePopup,
  quickEvent, createEvent, cloneEvent, categoryPopup,
  cloneEventCopyOptions, advanceToCopyOptions,
  cloneEventDates, advanceToDates,
  invitationListRegistrationPopup,
  changeCategory, changeSeason, changeTour,
  updateCategory, createCategory, changeLeagueCategory, changeLeagueSeason, changeLeagueTour,
  updateOpenRegistrationStatus,
  userIsAdmin, canCreateDCP, getLeagueFieldData,
  existingTimesPopup, advanceToInvitationList,
}) => <Fragment><QuickEventPopup { ...quickEvent } onClose={ closePopup }></QuickEventPopup><CreateEventPopup { ...createEvent } onClose={ closePopup } canCreateDCP={ canCreateDCP }></CreateEventPopup><CloneEventPopup { ...cloneEvent } onSave={ advanceToCopyOptions } onClose={ closePopup } canCreateDCP={ canCreateDCP } getLeagueFieldData={ getLeagueFieldData }></CloneEventPopup><CloneEventCopyOptionsPopup { ...cloneEventCopyOptions } onSave={ advanceToDates } onClose={ closePopup }></CloneEventCopyOptionsPopup><CloneEventDatesPopup { ...cloneEventDates } onClose={ closePopup }></CloneEventDatesPopup><CategoryPopup { ...categoryPopup } onClose={ closePopup } onUpdate={ updateCategory } onCreate={ createCategory } userIsAdmin={ userIsAdmin }></CategoryPopup><ChangeCategoryPopup { ...changeCategory } onClose={ closePopup } onSave={ changeLeagueCategory }></ChangeCategoryPopup><ChangeSeasonPopup { ...changeSeason } onClose={ closePopup } onSave={ changeLeagueSeason }></ChangeSeasonPopup><ChangeTourPopup { ...changeTour } onClose={ closePopup } onSave={ changeLeagueTour }></ChangeTourPopup><InvitationListRegistrationPopup { ...invitationListRegistrationPopup } onClose={ closePopup } onSave={ updateOpenRegistrationStatus }></InvitationListRegistrationPopup><ExistingTimesPopup { ...existingTimesPopup } onClose={ closePopup } onSave={ advanceToInvitationList }></ExistingTimesPopup></Fragment>

CCPopupsComponent.propTypes = {
  closePopup: PropTypes.func.isRequired,
  quickEvent: PropTypes.object.isRequired,
  createEvent: PropTypes.object.isRequired,
  cloneEvent: PropTypes.object.isRequired,
  cloneEventCopyOptions: PropTypes.object.isRequired,
  cloneEventDates: PropTypes.object.isRequired,
  categoryPopup: PropTypes.object.isRequired,
  invitationListRegistrationPopup: PropTypes.object.isRequired,
  changeCategory: PropTypes.object.isRequired,
  changeSeason: PropTypes.object.isRequired,
  changeTour: PropTypes.object.isRequired,
  updateCategory: PropTypes.func.isRequired,
  createCategory: PropTypes.func.isRequired,
  changeLeagueCategory: PropTypes.func.isRequired,
  changeLeagueSeason: PropTypes.func.isRequired,
  changeLeagueTour: PropTypes.func.isRequired,
  updateOpenRegistrationStatus: PropTypes.func.isRequired,
  advanceToCopyOptions: PropTypes.func.isRequired,
  advanceToDates: PropTypes.func.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
  canCreateDCP: PropTypes.bool,
  getLeagueFieldData: PropTypes.func,
  existingTimesPopup: PropTypes.object.isRequired,
  advanceToInvitationList: PropTypes.func.isRequired,
}

export default CCPopupsComponent
