import { ActionTypes } from '../actions'
import _ from 'lodash'

const {
  FETCH_MEMBERS_DATA_REQUEST,
  FETCH_MEMBERS_DATA_RECEIVED,
  FETCH_MEMBERS_DATA_RECEIVED_WITH_MEMBER_IDS,
  GET_MEMBERS_COUNT_REQUEST,
  GET_MEMBERS_COUNT_RECEIVED,
  FETCH_INITIAL_MEMBERS_DATA_REQUEST,
  FETCH_INITIAL_MEMBERS_DATA_RECEIVED,
  GET_FILTER_OPTIONS_REQUEST,
  GET_FILTER_OPTIONS_RECEIVED,
  CHANGE_FILTER_OPTIONS,
  DELETE_MEMBER_RECEIVED,
  TOGGLE_ACTIONS_DROPDOWN,
} = ActionTypes

const initialState = {
  isLoading: false,
  fetchMembersDataUrl: '',
  members: [],
  columns: [],
  membersCount: 0,
  isMembersCountLoading: false,
  reloadAll: false,
  searchParams: {},
  lastNameOnly: false,
  filterOptions: {},
  membershipPrograms: {},
  filterOptionsSet: false,
  isActionsDropdownOpen: {},
  showInvalidEmailsMessage: false,
  loadedMembersCount: 0,
}

const membersData = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MEMBERS_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        searchParams: action.searchParams,
      }
    case FETCH_MEMBERS_DATA_RECEIVED:
      return {
        ...state,
        isLoading: false,
        ...action.data,
        members: [ ...state.members, ...action.data.members ],
        showInvalidEmailsMessage: [ ...state.members, ...action.data.members ].filter(m => m.error === 'error').length > 0,
        loadedMembersCount: action.data.members.length,
      }
    case FETCH_INITIAL_MEMBERS_DATA_REQUEST:
      return {
        ...initialState,
        fetchMembersDataUrl: state.fetchMembersDataUrl,
        getMembersCountUrl: state.getMembersCountUrl,
        loadFilterOptionsUrl: state.loadFilterOptionsUrl,
        searchParams: state.searchParams,
        columns: state.columns,
        filterOptions: state.filterOptions,
        membershipPrograms: state.membershipPrograms,
        isMembersCountLoading: true,
        filterOptionsSet: state.filterOptionsSet,
        isLoading: true,
        canUseMasterRosterRegistration: state.canUseMasterRosterRegistration,
        leagueId: state.leagueId,
      }
    case FETCH_INITIAL_MEMBERS_DATA_RECEIVED:
      return {
        ...state,
        isLoading: false,
        ...action.data,
        showInvalidEmailsMessage: action.data.members.filter(m => m.error === 'error').length > 0,
        loadedMembersCount: action.data.members.length,
      }
    case FETCH_MEMBERS_DATA_RECEIVED_WITH_MEMBER_IDS: {
      let membersClone = _.clone(state.members)
      let removedMembersCount = 0
      const foundMembers = {} 
      for (const m in membersClone) {
        if (action.data.members[membersClone[m].id]) {
          membersClone[m] = action.data.members[membersClone[m].id]
          foundMembers[membersClone[m].id] = true
        }
      }
      const memberIds = Object.keys(action.data.members)
      for (const mId of memberIds){
        if (!foundMembers[mId]) {
          membersClone = [ action.data.members[mId], ...membersClone ]
        }
      }
      if (memberIds.length === 0){
        membersClone = membersClone.filter(m => action.paramMemberIds.indexOf(m.id) <= -1)
        removedMembersCount = action.paramMemberIds.length
      }
      return {
        ...state,
        isLoading: false,
        members: membersClone,
        showInvalidEmailsMessage: membersClone.filter(m => m.error === 'error').length > 0,
        membersCount: state.membersCount + (memberIds.length - Object.keys(foundMembers).length) - removedMembersCount,
      }
    }
    case GET_MEMBERS_COUNT_REQUEST:
      return {
        ...state,
        isMembersCountLoading: true,
      }
    case GET_MEMBERS_COUNT_RECEIVED:
      return {
        ...state,
        isMembersCountLoading: false,
        membersCount: action.membersCount,
      }
    case GET_FILTER_OPTIONS_REQUEST:
      return {
        ...state,
        filterOptionsSet: false,
      }
    case GET_FILTER_OPTIONS_RECEIVED:
      return {
        ...state,
        filterOptionsSet: true,
        filterOptions: action.filterOptions,
      }
    case CHANGE_FILTER_OPTIONS: {
      const filterOptionsClone = _.clone(state.filterOptions)
      for (const filterIndex in filterOptionsClone[action.category]){
        if (filterOptionsClone[action.category][filterIndex].value === action.value) {
          filterOptionsClone[action.category][filterIndex].checked = action.checked
        }
      }
      return {
        ...state,
        filterOptions: filterOptionsClone,
      }
    }
    case DELETE_MEMBER_RECEIVED: {
      if (action.action === 'delete') {
        return {
          ...state,
          members: state.members.filter(x => x.id !== action.id),
          showInvalidEmailsMessage: state.members.filter(x => x.id !== action.id).filter(m => m.error === 'error').length > 0,
          membersCount: state.membersCount - 1,
        }
      } else {
        return {
          ...state,
        }
      }
    }
    case TOGGLE_ACTIONS_DROPDOWN:
      return {
        ...state,
        isActionsDropdownOpen: {
          ...state.isActionsDropdownOpen,
          [action.id]: action.value,
        },
      }
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default membersData
