import { ActionTypes } from '../actions/footer'

function footer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.SET_FOOTER_UNSAVED_CHANGES:
      return {
        ...state,
        hasUnsavedChanges: action.value,
      }

    case ActionTypes.TOGGLE_VISIBLE_FOOTER:
      return {
        ...state,
        isVisible: !state.isVisible,
      }

    case ActionTypes.PLAINLY_SET_FOOTER_PROPERTY:
      return {
        ...state,
        [action.property]: action.value,
      }

    case ActionTypes.SET_FOOTER_POPUP_PROPERTY:
      return {
        ...state,
        popups: {
          ...state.popups,
          [action.popupType]: {
            ...state.popups[action.popupType],
            [action.property]: action.value,
          },
        },
      }

    default:
      return state
  }
}

export default footer
