import { connect } from 'react-redux'
import ShotByShotViewComponent from '../components/shot_by_shot_view_component'

import { refreshData, closePopup } from '../actions'

const mapStateToProps = (state) => {
  return {
    leagueId: state.misc.leagueId,
    roundId: state.misc.roundId,
    visible: state.popup.visible,
    player_name: state.popup.player_name,
    hole_number: state.popup.hole_number,
    randomShots: state.misc.randomShotsUrl,
    recomputeStats: state.misc.recomputeStatsUrl,
    deleteShots: state.misc.deleteShotsUrl,
    isAdmin: state.misc.isAdmin,
    isCustomerManager: state.misc.isCustomerManager,
    hasVerifiedScores: state.misc.hasVerifiedScores,
  }
}

const mapDispatchToProps = (dispatch) => ({
  refreshData: () => dispatch(refreshData()),
  closePopup: () => dispatch(closePopup()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShotByShotViewComponent)
