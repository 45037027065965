import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-bootstrap-toggle'

class RoundProgress extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount() {
    window.glg.new_design_shared.initBootstrapCheckboxes()
  }

  render() {
    const { title, links, roundToggleText, roundOptions, roundStates, roundProgress, roundId } = this.props.content

    return (
      <div className='widget-content-default widget-content-rounds widget-content-round-progress'>
        <div className='title'>
          { title }

          <a href={ links.round_profile.path } className='btn btn-primary widget-white-title-button'>
            { links.round_profile.name }
          </a>
        </div>
        <div className='content'>
          <div className='round-options'>
            { roundOptions.show_parings_in_event_portal.name }
            <Toggle onClick={ () => { this.props.toggleRoundOptions(roundOptions.show_parings_in_event_portal.path, 'show_parings_in_event_portal', roundId) } }
                    on={ roundToggleText.yes }
                    off={ roundToggleText.no }
                    className='rounded'
                    disabled={ roundOptions.show_parings_in_event_portal.disabled }
                    active={ roundOptions.show_parings_in_event_portal.status } />
          </div>

          <div className='round-options'>
            { roundOptions.enable_digital_scorecards.name }
            <Toggle onClick={ () => { this.props.toggleRoundOptions(roundOptions.enable_digital_scorecards.path, 'enable_digital_scorecards', roundId) } }
                    on={ roundToggleText.yes }
                    off={ roundToggleText.no }
                    className='rounded'
                    disabled={ roundOptions.enable_digital_scorecards.disabled }
                    active={ roundOptions.enable_digital_scorecards.status } />
          </div>

          <div className='round-options'>
            { roundOptions.enable_mobile_score_entry.name }
            <Toggle onClick={ () => { this.props.toggleRoundOptions(roundOptions.enable_mobile_score_entry.path, 'enable_mobile_score_entry', roundId) } }
                    on={ roundToggleText.yes }
                    off={ roundToggleText.no }
                    className='rounded'
                    disabled={ roundOptions.enable_mobile_score_entry.disabled }
                    active={ roundOptions.enable_mobile_score_entry.status } />
          </div>

          <div className='round-options'>
            { roundOptions.enable_walking_scorer.name }
            <Toggle onClick={ () => { this.props.toggleRoundOptions(roundOptions.enable_walking_scorer.path, 'enable_walking_scorer', roundId) } }
                    on={ roundToggleText.yes }
                    off={ roundToggleText.no }
                    className='rounded'
                    disabled={ roundOptions.enable_walking_scorer.disabled }
                    active={ roundOptions.enable_walking_scorer.status } />
          </div>

          <div className='round-options'>
            { roundOptions.show_events_in_results_portal.name }
            <Toggle onClick={ () => { this.props.toggleRoundOptions(roundOptions.show_events_in_results_portal.path, 'show_events_in_results_portal', roundId) } }
                    on={ roundToggleText.yes }
                    off={ roundToggleText.no }
                    className='rounded'
                    disabled={ roundOptions.show_events_in_results_portal.disabled }
                    active={ roundOptions.show_events_in_results_portal.status } />
          </div>

          <div className='round-status'>
            <div className='btn-group' role='group'>
              <button
                type='button'
                className={ `btn btn-default btn-progress ${roundProgress === 'not started' ? 'active' : ''}` }
                onClick={ () => this.props.setRoundStatus('not started', roundStates.round_not_started.path, roundId) } >
                { roundStates.round_not_started.name }
              </button>
              <button
                type='button'
                className={ `btn btn-default btn-progress ${roundProgress === 'in progress' ? 'active' : ''}` }
                onClick={ () => this.props.setRoundStatus('in progress', roundStates.round_in_progress.path, roundId) } >
                { roundStates.round_in_progress.name }
              </button>
              <button
                type='button'
                className={ `btn btn-default btn-progress ${roundProgress === 'completed' ? 'active' : ''}` }
                onClick={ () => this.props.setRoundStatus('completed', roundStates.round_completed.path, roundId) } >
                { roundStates.round_completed.name }
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

RoundProgress.propTypes = {
  content: PropTypes.object,
  setRoundStatus: PropTypes.func,
  toggleRoundOptions: PropTypes.func,
}


RoundProgress.defaultProps = {
  content: {
    show_parings_in_event_portal: {
      status: false,
      disabled: true,
      path: '#',
    },
    enable_digital_scorecards: {
      status: false,
      disabled: true,
      path: '#',
    },
    enable_mobile_score_entry: {
      status: false,
      disabled: true,
      path: '#',
    },
    enable_walking_scorer: {
      status: false,
      disabled: true,
      path: '#',
    },
    show_events_in_results_portal: {
      status: false,
      disabled: true,
      path: '#',
    },
    round_not_started_path: '#',
    round_in_progress_path: '#',
    round_completed_path: '#',
    roundId: '',
    round_progress: 'not started',
  },
}

export default RoundProgress
