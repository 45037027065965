import React, { Component } from 'react'
import PropTypes from 'prop-types'

class RoundOverview extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { title, rounds, header, links, tableContent, courses } = this.props.content

    return (
      <div className='widget-content-default widget-content-rounds-overview'>
        <div className='title'>
          { title }

          <a href={ links.event_profile.path } className='btn btn-primary widget-white-title-button'>
            { links.event_profile.name }
          </a>
        </div>
        <div className='courses'>
          <a href={ courses.path }>{ courses.text }</a>
          {
            courses.list.length >= 1
              ? courses.list.map( course => <span key={ course }>{ course }</span> )
              : null
          }
        </div>
        <div className='content table-responsive'>
          {
            rounds.count > 0
              ? <table className='table table-striped'>
                <thead>
                  <tr className='header'>
                    {
                      header.map((headerText, index) =>
                        <th key={ `${headerText}-${index}` } colSpan={ index === 0 ? 2 : 1 }>{ headerText }</th>)
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    tableContent.map( rowData =>
                      <tr key={ rowData.date }>
                        <td>{ rowData.date }</td>
                        <td>{ rowData.status }</td>
                        <td>{ rowData.pairing_data }</td>
                        <td>{ rowData.tournaments_info.map( tournamentInfo => <p key={ tournamentInfo }>{ tournamentInfo }</p>) }</td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
              : <div className='no-rounds-warning'>
                { rounds.warning }
              </div>
          }
        </div>
      </div>
    )
  }
}

RoundOverview.propTypes = {
  content: PropTypes.object,
}

RoundOverview.defaultProps = {
  content: {
    title: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.rounds_overview.title'),
    roundsCount: 0,
    header: [],
    links: {
      add_rounds: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.rounds_overview.add_rounds'),
        path: '#',
      },
      manage_rounds: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.rounds_overview.manage_rounds'),
        path: '#',
      },
    },
    tableContent: [],
  },
}

export default RoundOverview
