import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FormGroup } from 'react-bootstrap'
import { Row, Col } from 'react-flexbox-grid'

import EventStatusFilterTypes from '../../event_status_filter_types'
import {
  GlgCustomDropdown,
  GlgTooltipIcon,
} from 'SharedComponents'
import CheckboxPanel from './event_status/checkbox_panel'
import DropdownButton from './event_status/dropdown_button'

class EventStatusFilter extends PureComponent {
  constructor(props) {
    super(props)
    this.onChange = props.onChange.bind(this)
  }

  render() {
    const OPTIONS = [
      {
        value: EventStatusFilterTypes.REGISTERED,
        label: !window.I18n ? '' : window.I18n.t('customer_directories.filters.event_status_options.my_registrations'),
        checked: this.props.values.includes(EventStatusFilterTypes.REGISTERED),
      },
      {
        value: EventStatusFilterTypes.OPEN,
        label: !window.I18n ? '' : window.I18n.t('customer_directories.filters.event_status_options.registration_open'),
        checked: this.props.values.includes(EventStatusFilterTypes.OPEN),
      },
      {
        value: EventStatusFilterTypes.PAST,
        label: !window.I18n ? '' : window.I18n.t('customer_directories.filters.event_status_options.past_events'),
        checked: this.props.values.includes(EventStatusFilterTypes.PAST),
      },
      {
        value: EventStatusFilterTypes.UPCOMING,
        label: !window.I18n ? '' : window.I18n.t('customer_directories.filters.event_status_options.upcoming_events'),
        checked: this.props.values.includes(EventStatusFilterTypes.UPCOMING),
      },
    ]

    if (this.props.shouldShowLotteryFilter) {
      OPTIONS.push(
        {
          value: EventStatusFilterTypes.LOTTERY,
          label: !window.I18n ? '' : window.I18n.t('customer_directories.filters.event_status_options.lottery_events'),
          checked: this.props.values.includes(EventStatusFilterTypes.LOTTERY),
        }
      )
    }

    const EVENT_STATUS_OPTIONS_VALUES = []
    let options = _.clone(OPTIONS)

    if (!this.props.statusFilterVisible.myRegistrations) {
      EVENT_STATUS_OPTIONS_VALUES.push(EventStatusFilterTypes.REGISTERED)
    }
    if (!this.props.statusFilterVisible.open) {
      EVENT_STATUS_OPTIONS_VALUES.push(EventStatusFilterTypes.OPEN)
    }
    
    options = options.filter(option => (
      !EVENT_STATUS_OPTIONS_VALUES.includes(option.value)
    ))
    
    const tooltipText = (
      <div>
        { !window.I18n ? '' : window.I18n.t('customer_directories.filters.event_status_tooltip_text.my_registrations') }
        <br/>
        { !window.I18n ? '' : window.I18n.t('customer_directories.filters.event_status_tooltip_text.registration_open') }
        <br/>
        { !window.I18n ? '' : window.I18n.t('customer_directories.filters.event_status_tooltip_text.past_events') }
        <br/>
        { !window.I18n ? '' : window.I18n.t('customer_directories.filters.event_status_tooltip_text.upcoming_events') }
        <br/>
        { this.props.shouldShowLotteryFilter && (!window.I18n ? '' : window.I18n.t('customer_directories.filters.event_status_tooltip_text.lottery_events')) }
      </div>
    )
    const panel = CheckboxPanel
    return <FormGroup><Row><Col xs={true}><div className="add-margin-top-5"></div><h4><span className="label filter-label"><div className="add-margin-top-5"><span>{ !window.I18n ? '' : window.I18n.t('customer_directories.filters.event_status_title') }</span><span className="pull-right"><GlgTooltipIcon tooltip={tooltipText} placement={'bottom'}><i className="gga-info-circle"></i></GlgTooltipIcon></span></div></span></h4></Col></Row><Row><Col xs={true}>{/*%GlgSelect(items={ options }
                       value={ this.props.value }
                       onChange={ this.onChange })*/}<GlgCustomDropdown dropdownPanel={panel} button={<DropdownButton options={options} showTours={this.props.showTours}></DropdownButton>} offsetY={36} optionsForPanel={options} onChange={ this.onChange } buttonContainerId={'#event_status'}></GlgCustomDropdown></Col></Row></FormGroup>
  }
}

EventStatusFilter.propTypes = {
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  statusFilterVisible: PropTypes.object,
  showTours: PropTypes.bool,
  shouldShowLotteryFilter: PropTypes.bool,
}


export default EventStatusFilter
