import { connect } from 'react-redux'
import Scoring from '../../components/content/scoring'
import {
  setRoundStatus,
  toggleRoundOptions,
  selectTournamentToggleModal,
  setSelectedDefaultRoundTournament,
  setDefaultRoundTournament,
  walkingScorerStationToggleModal,
  saveWalkingScorerStationSettings,
} from '../../actions/index'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRoundStatus: (status, url, roundId) => dispatch(setRoundStatus(ownProps.widgetId, url, status, roundId)),
  toggleRoundOptions: (url, option, roundId) => dispatch(toggleRoundOptions(ownProps.widgetId, url, option, roundId)),
  refresh: () => dispatch(ownProps.refresh),
  selectTournamentToggleModal: (visibility) => dispatch(selectTournamentToggleModal(ownProps.widgetId, visibility)),
  setSelectedDefaultRoundTournament: (selected) => dispatch(setSelectedDefaultRoundTournament(ownProps.widgetId, selected)),
  setDefaultRoundTournament: (path, tournamentId) => dispatch(setDefaultRoundTournament(ownProps.widgetId, path, tournamentId)),
  walkingScorerStationToggleModal: (visibility) => dispatch(walkingScorerStationToggleModal(ownProps.widgetId, visibility)),
  saveWalkingScorerStationSettings: (path, newSettings) => dispatch(saveWalkingScorerStationSettings(ownProps.widgetId, path, newSettings)),
})

export default connect(
  null,
  mapDispatchToProps,
)(Scoring)
