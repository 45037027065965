import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Row } from 'react-bootstrap'
import { GlgLoadingSpinner } from 'SharedComponents'
import MembershipNameComponent from './membership_name_component'
import RenewalDateComponent from './renewal_date_component'
import JoinDateComponent from './join_date_component'

class MembershipProgramsComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let membershipPrograms = []
    if (!this.props.dataIsLoading) {
      membershipPrograms = Object.values(this.props.memberships)
    }
    
    let membershipTitle
    if (Object.keys(this.props.memberships).length > 0) {
      membershipTitle = <Row className="title"><span>{ !window.I18n ? '' : window.I18n.t('player_activity.registration.membership.memberships') }</span>{this.props.membershipPortalLink && <a href={ this.props.membershipPortalLink } target={ this.props.target } className="edit-btn"><i className="fa fa-edit"></i>{ ' ' + !window.I18n ? '' : window.I18n.t('player_activity.registration.membership.edit_memberships') }</a>}</Row>
    }
    
    return <div className="membership-programs">{ membershipTitle }<GlgLoadingSpinner show={ this.props.dataIsLoading }></GlgLoadingSpinner>{ 
          membershipPrograms.map((e, index) => {
            const colSmSize = e.renewal_date ? 4 : 6
            const renewalDateComp = e.renewal_date ? <RenewalDateComponent renewalDate={ e.renewal_date } colSmSize={ colSmSize } isExpired={ e.isExpired } membershipPortalLink={ this.props.membershipPortalLink } target={ this.props.target }></RenewalDateComponent> : ''
            return <Row key={ index } className="no-lateral-margin"><MembershipNameComponent membershipName={ e.name } colSmSize={ colSmSize }></MembershipNameComponent><JoinDateComponent joinDate={ e.join_date } colSmSize={ colSmSize }></JoinDateComponent>              { renewalDateComp }</Row>
          })
        }</div>
  }
}

MembershipProgramsComponent.propTypes = {
  memberships: PropTypes.object.isRequired,
  membershipPortalLink: PropTypes.string,
  dataIsLoading: PropTypes.bool,
  target: PropTypes.string,
}

export default MembershipProgramsComponent
