import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GlgPopup } from 'SharedComponents'

import PopupHolesRow from './popup_holes_row'
import PopupParRow from './popup_par_row'
import PopupScoresRow from './popup_scores_row'
import PopupShotsRow from './popup_shots_row'
import PopupVerifyScoresComponent from './popup_verify_scores_component'
import PopupStablefordPointsRow from './popup_stableford_points_component'
class VerificationPopupComponent extends Component {
  constructor(props) {
    super(props)
    const scoreData = this.props.scoreData
    this.state = this.props.autoFillScore ?
      {
        scores: [ ...scoreData.scores_array ],
        outScore: scoreData.out,
        inScore: scoreData.in,
        roundScore: scoreData.round_score,
        totalScore: scoreData.total_score,
        stablefordPointsArray: scoreData.stableford_points_array,
        stablefordOut: scoreData.stableford_out,
        stablefordIn: scoreData.stableford_in,
        stablefordTotal: scoreData.stableford_total,
        stablefordValues: JSON.parse(scoreData.stableford_values ?? '{}'),
      }
      :
      {
        scores: new Array(18).fill(0),
        outScore: 0,
        inScore: 0,
        roundScore: 0,
        totalScore: scoreData.total_score - scoreData.round_score,
      }

    this.handleScoreChange = this.handleScoreChange.bind(this)
    this.onSave = this.onSave.bind(this)
    this.onShow = this.onShow.bind(this)
    this.keyPress = this.keyPress.bind(this)
    this.moveToLeft = this.moveToLeft.bind(this)
    this.moveToRight = this.moveToRight.bind(this)
    this.backSpacePressed = this.backSpacePressed.bind(this)
    this.tabPressed = this.tabPressed.bind(this)
    this.handleOrientationChange = this.handleOrientationChange.bind(this)
  }

  handleScoreChange(holeNumber, score) {
    let { outScore, inScore, roundScore, totalScore, stablefordOut, stablefordIn, stablefordTotal } = this.state
    const { stablefordPointsArray, stablefordValues } = this.state
    const { pars } = this.props
    const oldStablefordPoint = stablefordPointsArray?.[holeNumber]
    
    const scores = [ ...this.state.scores ]
    let oldScore = scores[holeNumber]

    let inputX = false
    if ( score === 'x' || score === 'X') {
      inputX = true
    }
    if ( score === '' || isNaN(score) || (typeof oldScore === 'string' && oldScore.includes('X')) ) {
      score = 0
    }

    if ( oldScore === null || (typeof oldScore === 'string' && oldScore.includes('X')) ) {
      oldScore = 0
    }

    roundScore = parseInt(roundScore) - parseInt(oldScore) + parseInt(score)
    totalScore = parseInt(totalScore) - parseInt(oldScore) + parseInt(score)

    let newStablefordValue = stablefordValues?.[(parseInt(score) - pars[holeNumber]).toString()]
    if (newStablefordValue > 4) {
      newStablefordValue = 4
    }
    stablefordTotal = parseInt(stablefordTotal) - parseInt(oldStablefordPoint) + parseInt(newStablefordValue)
    if (stablefordPointsArray) {
      stablefordPointsArray[holeNumber] = parseInt(newStablefordValue)
    }
    if ( holeNumber < 9) {
      outScore = parseInt(outScore) - parseInt(oldScore) + parseInt(score)
      stablefordOut = parseInt(stablefordOut) - parseInt(oldStablefordPoint) + parseInt(newStablefordValue)
    } else {
      inScore = parseInt(inScore) - parseInt(oldScore) + parseInt(score)
      stablefordIn = parseInt(stablefordIn) - parseInt(oldStablefordPoint) + parseInt(newStablefordValue)
    }

    if ( inputX ) {
      scores[holeNumber] = 'X'
    } else {
      scores[holeNumber] = parseInt(score)
    }
    this.setState({scores: scores, outScore: outScore, inScore: inScore, roundScore: roundScore, totalScore: totalScore, stablefordPointsArray: stablefordPointsArray, stablefordIn: stablefordIn, stablefordOut: stablefordOut, stablefordTotal: stablefordTotal})

    if (score >= 2) {
      const nextField = document.querySelector(`input[name=score-${holeNumber + 1}]`)
      if (nextField !== null) {
        nextField.focus()
      }
    }
  }

  keyPress(holeNumber, event) {
    switch (event.key) {
      case 'ArrowLeft':
        event.preventDefault()
        this.moveToLeft(holeNumber)
        break
      case 'ArrowRight':
        event.preventDefault()
        this.moveToRight(holeNumber)
        break
      case 'Backspace':
        this.backSpacePressed(holeNumber, event)
        break
      case 'Tab':
        event.preventDefault()
        this.tabPressed(holeNumber, event)
        break
    }
  }

  moveToLeft(holeNumber) {
    const previousField = document.querySelector(`input[name=score-${holeNumber + -1}]`)
    if (previousField !== null) {
      previousField.focus()
    }
  }

  moveToRight(holeNumber) {
    const nextField = document.querySelector(`input[name=score-${holeNumber + 1}]`)
    if (nextField !== null) {
      nextField.focus()
    }
  }

  backSpacePressed(holeNumber, event) {
    const scores = [ ...this.state.scores ]
    if ( scores[holeNumber] === 0 || scores[holeNumber] === null) {
      event.preventDefault()
      this.moveToLeft(holeNumber)
    }
  }

  tabPressed(holeNumber, event) {
    if (event.shiftKey) {
      this.moveToLeft(holeNumber)
    } else {
      this.moveToRight(holeNumber)
    }
  }

  handleOrientationChange() {
    const focusedInput = document.activeElement
    if (focusedInput !== null) {
      focusedInput.blur()
      setTimeout( () => focusedInput.focus(), 100)
    }
  }

  onSave() {
    const { verifyScore, scoreData } = this.props
    const { scores } = this.state

    verifyScore(scoreData.scores_array, scores)
  }

  onShow() {
    const firstInput = document.querySelector('input[name=score-0]')
    if (firstInput !== null) {
      setTimeout( () => firstInput.focus(), 100)
    }
    window.glg.new_design_shared.applyCustomScrollbar('.' + this.props.step + '_verification_table', 'horizontal')
    window.addEventListener('orientationchange', this.handleOrientationChange)
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.handleOrientationChange)
  }

  render() {
    const { playerName, pars, scoreData, shots, show, tableTitle, isMultiRound, step, showShots, closePopup, isStableford } = this.props
    const toParScore = isMultiRound ? scoreData.total : scoreData.today
    const title = playerName + ' (' + toParScore + ')'
    const saveButtonEnabled = !this.state.scores.includes(0) && !this.state.scores.includes(null)
    const matchedScores = Array(18).fill()
        .map((_, i) => this.state.scores[i] === 0 || scoreData.scores_array[i] === this.state.scores[i])

    return (
        <GlgPopup modalClass='modal-lg large-popup'
                  title={ title }
                  show={ show }
                  onClose={ closePopup }
                  onSave={ this.onSave }
                  onShow={ this.onShow }
                  manualClose={ true }
                  saveButtonEnabled={ saveButtonEnabled }
                  saveButtonText='Verify Scores'>

          <div className={step + '_verification_table btsp_ps_container btsp_fheader_table_container'}>
            <table className={step + '_verification btsp_fheader_table'}>
              <thead>
              <tr className='header'>
                <th colSpan={ isMultiRound ? '27' : '26'}>{tableTitle}</th>
              </tr>
              </thead>
              <tbody>
              <PopupHolesRow isMultiRound={isMultiRound}
                             matchedScores={matchedScores}/>
              <PopupParRow pars={pars}
                           isMultiRound={isMultiRound}
                           matchedScores={matchedScores}/>
              { isStableford &&
              <PopupStablefordPointsRow scoreData={scoreData}
                                        isMultiRound={isMultiRound}
                                        matchedScores={matchedScores}/> }
              <PopupScoresRow scoreData={scoreData}
                              isMultiRound={isMultiRound}
                              matchedScores={matchedScores}/>
              { showShots &&
              <PopupShotsRow shots={shots}
                             isMultiRound={isMultiRound}
                             matchedScores={matchedScores}/> }
              <PopupVerifyScoresComponent isMultiRound={isMultiRound}
                                          scoreData={this.state}
                                          handleScoreChange={this.handleScoreChange}
                                          matchedScores={matchedScores}
                                          handleKeyPress={this.keyPress}/>
              </tbody>
            </table>
          </div>
        </GlgPopup>
    )
  }
}

VerificationPopupComponent.propTypes = {
  playerName: PropTypes.string,
  pars: PropTypes.array,
  scoreData: PropTypes.object,
  shots: PropTypes.array,
  show: PropTypes.bool,
  closePopup: PropTypes.func,
  tableTitle: PropTypes.string,
  isMultiRound: PropTypes.bool,
  autoFillScore: PropTypes.bool,
  showShots: PropTypes.bool,
  verifyScore: PropTypes.func,
  step: PropTypes.string,
  isStableford: PropTypes.bool,
}

export default VerificationPopupComponent
