import React from 'react'
import { Image } from 'react-bootstrap'

const getGifURL = () => {
  const prefix = $('body').hasClass('usga') ? 'usga' : 'gg'
  return `https://s3-us-west-1.amazonaws.com/productionggs/${ prefix }_navigator_intro.gif`
}

const NavigatorFeaturePopupContent = () => <div className="navigator-feature-popup">{ !window.I18n ? '' : window.I18n.t('navigator.components.navigator_feature_popup_content.introducing_new_navigator') }<div className="content"><ul><li>{ !window.I18n ? '' : window.I18n.t('navigator.components.navigator_feature_popup_content.quickly_search') }</li><li>{ !window.I18n ? '' : window.I18n.t('navigator.components.navigator_feature_popup_content.bookmark_used_pages') }</li><li>{ !window.I18n ? '' : window.I18n.t('navigator.components.navigator_feature_popup_content.organize_bookmarked_pages') }</li></ul><div className="add-margin-top-5 add-margin-bottom-10"><Image src={ getGifURL() } responsive={ true }></Image></div>For full details, refer to our <a href={ 'http://docs.golfgenius.com/article/show/88812-the-navigator' } target={ '_blank' }>Knowledge Base article</a>{ '.' }</div></div>

export default NavigatorFeaturePopupContent
