import React from 'react'
import PropTypes from 'prop-types'

import {
  GlgTableRow,
  GlgTableCell,
} from 'SharedComponents'

const MoneyEarnedInformationTableRow = ({ ...props }) => {
  
  let tournamentName = props.tournamentName
  if (props.date !== '') {
    tournamentName += ' • ' + props.date 
  }

  return <GlgTableRow { ...props } isSortable={ false }><GlgTableCell>{ tournamentName }</GlgTableCell><GlgTableCell>{ props.position }</GlgTableCell><GlgTableCell>{ props.score }</GlgTableCell><GlgTableCell>{ props.toPar }</GlgTableCell><GlgTableCell>{ props.purse }</GlgTableCell></GlgTableRow>
}

MoneyEarnedInformationTableRow.propTypes = {
  id: PropTypes.number.isRequired,
  tournamentName: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  score: PropTypes.string.isRequired,
  toPar: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  purse: PropTypes.string.isRequired,
}

export default MoneyEarnedInformationTableRow
