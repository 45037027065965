import { connect } from 'react-redux'

import {
  reorderLeagues,
  deleteLeague,
  archiveLeague,
  restoreLeague,
  openPopup,
  openRegistration,
  closeRegistration,
} from '../actions'
import {
  getFormattedLeagues, checkShowSeasons, checkShowTours, checkShowRegistration,
} from '../selectors'
import LeaguesTableComponent from '../components/leagues_table_component'

const mapStateToProps = (state, props) => {
  // props.leagueIds can be null (List View)
  const formattedLeagues = getFormattedLeagues(state, props, props.leagueIds)
  const showSeasonsColumn = checkShowSeasons(state, { checkLeagues: true })
  const seasonsEnabled = checkShowSeasons(state)
  const showTours = checkShowTours(state, { checkLeagues: true })
  const toursEnabled = checkShowTours(state)
  const showRegistration = checkShowRegistration(state)
  
  return {
    leagues: formattedLeagues,
    isSortable: state.sorting.column === 'position',
    sortingColumn: state.sorting.column,
    sortingOrder: state.sorting.order || 'asc',
    userIsCustomerManager: state.misc.userIsCustomerManager,
    userIsTourManager: state.misc.userIsTourManager,
    userIsVisageAdmin: state.misc.userIsVisageAdmin,
    showTours,
    toursEnabled,
    showSeasonsColumn,
    seasonsEnabled,
    showRegistration,
    golfHubEnabled: state.misc.golfHubEnabled,
    isTmLite: state.misc.isTmLite,
  }
}

const mapDispatchToProps = dispatch => ({
  reorderLeagues: (...params) => dispatch(reorderLeagues(...params)),
  leagueActions: {
    deleteLeague: (id) => dispatch(deleteLeague(id)),
    archiveLeague: (id) => dispatch(archiveLeague(id)),
    restoreLeague: (id) => dispatch(restoreLeague(id)),
    openPopup: (id, data) => dispatch(openPopup(id, data)),
    openRegistration: (id) => dispatch(openRegistration(id)),
    closeRegistration: (id) => dispatch(closeRegistration(id)),
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeaguesTableComponent)
