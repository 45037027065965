import React from 'react'
import PropTypes from 'prop-types'
import FaIcon from 'react-fa'
import {GlgTableRow, GlgTableCell} from 'SharedComponents'

const Handle = ({...props}) => {
  return (
    <span {...props}>
      <FaIcon name='bars' />
    </span>
  )
}

const boardRowComponent = ({columns, removeLine, ...props}) => {
  return (
    <GlgTableRow { ...props}>
      { columns.map( column => {
          if (column === 'drag') {
            return <GlgTableCell key={column}><Handle { ...props.dragHandleProps } /></GlgTableCell>
          } else if (column === 'actions') {
            return (
              <GlgTableCell key={column}>
                { props.empty ? '' : <a className='force_red' onClick={() => removeLine(props.position)}>Hide</a> }
              </GlgTableCell>
            )
          } else {
            return <GlgTableCell key={column}>{props.empty ? '' : props[`${column}`]}</GlgTableCell>
          }
        })
      }
    </GlgTableRow>
  )
}

boardRowComponent.propTypes = {
  columns: PropTypes.array,
  dragHandleProps: PropTypes.object,
  empty: PropTypes.bool,
  removeLine: PropTypes.func,
  position: PropTypes.number,
}

export default boardRowComponent
