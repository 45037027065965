import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GlgPopup from '../../../shared/components/glg_popup'
import PopupTypes from '../../popup_types'

class ExistingTimesPopup extends Component {
  constructor(props){
    super(props)
    this.onClose = props.onClose.bind(this, PopupTypes.EXISTING_TIMES)
    this.handleSave = this.handleSave.bind(this)
  }

  handleSave() {
    this.props.onSave(this.props.data)
  }

  render() {
    return <GlgPopup title={!window.I18n ? '' : window.I18n.t('leagues.existing_times.manually_opening') + ' ' + window.I18n.t('leagues.existing_times.want_to_continue')} show={ this.props.show } onClose={ this.onClose } onSave={ this.handleSave } closeButtonText="Cancel" saveButtonText="Continue"></GlgPopup>
  }
}

ExistingTimesPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default ExistingTimesPopup
