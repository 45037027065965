import { ActionTypes } from '../actions/setup'

const {
  TOGGLE_TRIGGER,
  SET_TRIGGER_DAYS,
  SET_TRIGGER_PERCENTAGE,
  SET_TRIGGER_EVENTS,
  TOGGLE_TRIGGER_PAUSE_EVALUATION,
  SET_TRIGGER_PAUSE_EVALUATION_DAYS,
} = ActionTypes

const initialState = {}

const setAllTriggersOnFalse = (triggers) => {
  const keys = Object.keys(triggers)
  for (const key of keys){
    triggers[key].checked = false
  }
  return triggers
}

function triggers(state = initialState, action) {
  let allTriggersOnFalse
  switch (action.type){
    case TOGGLE_TRIGGER:
      allTriggersOnFalse = setAllTriggersOnFalse({...state})
      return {
        ...allTriggersOnFalse,
        [action.trigger]: {
          ...state[action.trigger],
          checked: true,
        },
      }
    case SET_TRIGGER_DAYS:
      return {
        ...state,
        [action.trigger]: {
          ...state[action.trigger],
          days: action.days,
        },
      }
    case SET_TRIGGER_PERCENTAGE:
      return {
        ...state,
        [action.trigger]: {
          ...state[action.trigger],
          percentage: action.percentage,
        },
      }
    case SET_TRIGGER_EVENTS:
      return {
        ...state,
        [action.trigger]: {
          ...state[action.trigger],
          events: action.events,
        },
      }
    case TOGGLE_TRIGGER_PAUSE_EVALUATION:
      return {
        ...state,
        [action.trigger]: {
          ...state[action.trigger],
          pauseEvaluation: {
            ...state[action.trigger].pauseEvaluation,
            checked: !state[action.trigger].pauseEvaluation.checked,
          },
        },
      }
    case SET_TRIGGER_PAUSE_EVALUATION_DAYS:
      return {
        ...state,
        [action.trigger]: {
          ...state[action.trigger],
          pauseEvaluation: {
            ...state[action.trigger].pauseEvaluation,
            days: action.days,
          },
        },
      }
    default:
      return state
  }
}

export default triggers
