import { connect } from 'react-redux'
import Portal from '../../components/content/portal'
import {
  changeFlashMessageData,
  changeGgid,
  copyText,
  toggleEditGgid,
  toggleSetFlashMessageModal,
  updateFlashMessage,
  updateGgid,
  validateGgidUniqueness,
} from '../../actions/index'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => ({
  copyText: textToCopy => copyText(textToCopy),
  toggleSetFlashMessageModal: show => dispatch(toggleSetFlashMessageModal(ownProps.widgetId, show)),
  changeFlashMessageData: (key, value) => dispatch(changeFlashMessageData(ownProps.widgetId, key, value)),
  updateFlashMessage: () => updateFlashMessage(ownProps.content.links.set_flash.path, ownProps.content.flashMessageModal.data),
  toggleEditGgid: () => dispatch(toggleEditGgid(ownProps.widgetId)),
  changeGgid: (newGgid) => {
    dispatch(changeGgid(ownProps.widgetId, newGgid))
    dispatch(validateGgidUniqueness(ownProps.widgetId, ownProps.content.links.validate_ggid.path, newGgid))
  },
  updateGgid: () => {
    dispatch(updateGgid(ownProps.widgetId, ownProps.content.links.update_ggid.path, ownProps.content.ggid.new))
    setTimeout(
      () => dispatch(ownProps.refresh),
      3000
    )
  },
})

export default connect(
  null,
  mapDispatchToProps,
)(Portal)
