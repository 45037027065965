import ProfilePictureComponent from './profile_picture_component'
import React from 'react'
import PropTypes from 'prop-types'

const PlayersProfilesComponent = ({teamPlayersData}) => {

  return (
    <div className="biz_cards_row multiple">
      {teamPlayersData.map((teamMember, idx) =>
        <ProfilePictureComponent key={idx} playerName={teamMember.name}
                                 profileUrl={teamMember.profile}/>
      )}
    </div>
  )
}


PlayersProfilesComponent.propTypes = {
  teamPlayersData: PropTypes.array,
}

export default PlayersProfilesComponent
