import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { scoreClass } from '../helpers'

const foursomeData = ({foursome, scoreDisplay, isStableford, isMultiRound, holeView, isPortal, isLast}) => {
  const aggregates = [ ...foursome.aggregates ] 
  return (
    <Fragment>
      {
        aggregates.map( (agg, index) => {
          const displayValues = scoreDisplay ? [ ...agg.scores ] : [ ...agg.parCumulative ]
          const firstRowClass = index === 0 ? ' first_row' : ''
          const lastFirstRowClass = index === 0 && isLast ? ' last' : ''
          return (
            <tr key={'foursome-' + foursome.id + '-agg-' + index} className={'player_row' + firstRowClass + lastFirstRowClass}>
              {index === 0 && 
                <td rowSpan={foursome.size} className='first'>
                  <p>{foursome.position}.</p>
                  <p><b>{!window.I18n ? '' : window.I18n.t('group_view.foursome_data.course')}: </b>{foursome.courseName}</p>
                  <p><b>{!window.I18n ? '' : window.I18n.t('group_view.foursome_data.starting_hole')}: </b>{foursome.startingHole}</p>
                  <p>{foursome.prettyTeeTime}</p>
                </td>
              }
              <td className='second'>
                <div className='handle_text'>
                  <span className='full_name'>{
                    agg.link_to_shot_stats ? <a href={agg.link_to_shot_stats}>{agg.name}</a> : agg.name
                  }</span>
                </div>
                {!isPortal && <div className='disp_label'>{agg.disposition}</div>}
              </td>
              {isMultiRound && <td>{agg.prior}</td>}
              {[ ...Array(9) ].map( ( _, i) => <td className={isStableford ? null : scoreClass(agg.points[i], displayValues[i], holeView)} key={'hole-' + i}><span>{displayValues[i]}</span></td>)}
              {scoreDisplay && <td>{agg.scoresSumFront}</td>}
              {[ ...Array(9) ].map( ( _, i) => <td className={isStableford ? null : scoreClass(agg.points[i + 9], displayValues[i + 9], holeView)} key={'hole-' + i + 9}><span>{displayValues[i + 9]}</span></td>)}
              {scoreDisplay && <td>{agg.scoresSumBack}</td>}
              {!scoreDisplay && isMultiRound ? null : <td>{agg.scoresSum}</td>}
              {scoreDisplay && !isStableford && isMultiRound && <td>{agg.todayPar}</td>}
              {scoreDisplay && !isStableford && isMultiRound && <td>{agg.totalPar}</td>}
              {isStableford && isMultiRound && <td>{agg.overall}</td>}
              {!scoreDisplay && isMultiRound && 
                agg.pastResults.map( result => <td key={result.round}>{result.grossScore}</td>)
              }
              {!scoreDisplay && isMultiRound && <td>{agg.totalScore}</td>}
            </tr>
          )
        })
      }
    </Fragment>
  )
}

foursomeData.propTypes = {
  foursome: PropTypes.object,
  scoreDisplay: PropTypes.bool,
  isStableford: PropTypes.bool,
  isMultiRound: PropTypes.bool,
  holeView: PropTypes.string,
  isPortal: PropTypes.bool,
  isLast: PropTypes.bool,
}

export default foursomeData
