import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Preloader from './content/preloader'

class Widget extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount() {
    this.props.loadWidgetContent()
  }

  render() {
    const { widgetComponent, widgetClass } = this.props.selectComponent()
    const showWidget = this.props.widget.content === undefined ? true : (this.props.widget.content.show_widget ? true : false)

    return (
      <div className={ `dashboard-widget ${ widgetClass } ${showWidget ? '' : 'hidden'}` }>
        { typeof this.props.widget.content === 'undefined' ? <Preloader /> : widgetComponent }
      </div>
    )
  }
}

Widget.propTypes = {
  widget: PropTypes.object,
  loadWidgetContent: PropTypes.func,
  selectComponent: PropTypes.func,
}

export default Widget
