import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const popupStablefordPointsRow = ({scoreData, isMultiRound, matchedScores}) => {

  const scores = [ ...scoreData.stableford_points_array ]

  return (
        <tr className='scores_row'>
            <td className='with_border'>{ window.I18n.t('helpers.column_titles_helper.stableford_points') }</td>
            { [ ...Array(9) ].map( ( _, i) => (
                <Fragment key={i}>
                    <td key={'hole-' + i} className={matchedScores[i] ? 'with_border' : 'with_border yellow_column'}>{scores[i]}</td>
                    { (i + 1) % 3 === 0 && i !== 8 && <td className="with_border gray_column"></td> }
                </Fragment>
            )) }
            <td className='with_border blue_column'>{scoreData.stableford_out}</td>
            { [ ...Array(9) ].map( ( _, i) => (
                <Fragment key={i}>
                    <td key={'hole-' + i} className={matchedScores[i + 9] ? 'with_border' : 'with_border yellow_column'}>{scores[i + 9]}</td>
                    { (i + 1) % 3 === 0 && i !== 8 && <td className="with_border gray_column"></td> }
                </Fragment>
            )) }
            <td className='with_border blue_column'>{scoreData.stableford_in}</td>
            <td className='with_border blue_column'>{scoreData.stableford_total}</td>
            { isMultiRound && <td className='with_border blue_column'>{scoreData.aggregated_total}</td> }
        </tr>
  )
}

popupStablefordPointsRow.propTypes = {
  scoreData: PropTypes.object,
  isMultiRound: PropTypes.bool,
  matchedScores: PropTypes.array,
}

export default popupStablefordPointsRow
