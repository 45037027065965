import React from 'react'
import PropTypes from 'prop-types'
import rootReducer from './reducers'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import ThruBoardContainer from './containers/thru_board_container'

const ThruBoardRoot = ({
  fetchDataPath,
  title,
  current,
  upcoming}) => {
  const state = {
    misc: {
      fetchDataPath,
      title,
      current,
      upcoming,
    },
  }

  const store = configureStore(rootReducer, state)

  return (
    <GlobalProvider store = { store }>
      <ThruBoardContainer />
    </GlobalProvider>
  )
}

ThruBoardRoot.propTypes = {
  fetchDataPath: PropTypes.string,
  title: PropTypes.string,
  current: PropTypes.object,
  upcoming: PropTypes.object,
}

export default ThruBoardRoot
