import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { GlgRoundedButtonIcon, GlgTooltipIcon, GlgSelect, GlgTooltip } from 'SharedComponents'
import { NotificationManager } from 'react-notifications'
import Checkbox from './event_setup/checkbox'
import CalendarCustomInput from './event_setup/calendar_custom_input'
import DivisionsFlightsTable from './event_setup/divisions_flights_table'

const internationalize = (...args) => !window.I18n ? '' : window.I18n.t(...args)


const renderDivision = (division, inSet, props) => (
  <li key={division.id} className='division_container'>
    <div className={!inSet ? 'flex' : ''}>
      <label className='division_name'>{ division.name }</label>
      <span className='summary'>
        {
          division.summary.map((data, i) =>
            <>
              { i > 0 && <strong>{' | '}</strong> }
              <strong>{ data }</strong>
            </>
          )
        }
      </span>
      {!inSet && <a className={division.name === 'All Golfers' ? 'right disabled' : 'right'} onClick={() => props.removeDivision(division.id)}> 
        {props.content.links.delete_divisions.name}
      </a>}
    </div>
  </li>
)

class DivisionsOverview extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount() {
    window.NotificationManager = NotificationManager
  }

  render() {
    const divisionsWithoutDivisionset = this.props.content.divisions_without_divisionset
    const { divisionsets } = this.props.content

    return (
      <>
        <li className='add-division-button-container'>
          <GlgRoundedButtonIcon
            text={ internationalize('divisions.index.form_division.add_new_divisions') }
            icon={ 'gga-circle-plus' }
            onClick={ () => { this.props.setData([ 'addingDivision' ], true) }}
          />
        </li>
        { divisionsWithoutDivisionset.map(division => renderDivision(division, false, this.props)) }
        {
          divisionsets.map(divisionset => (
            <li key={divisionset.id} className='division_container'>
              <div className='divisionset_name flex'>
                { divisionset.name }
                <a className='right' onClick={() => this.props.removeDivisionset(divisionset.id)}>{this.props.content.links.delete_divisionsets.name}</a> 
              </div>
              <ul className='divisionset_divisions'> { divisionset.divisions.map(division => renderDivision(division, true, this.props)) } </ul>
            </li>
          ))
        }
      </>
    )
  }
}

const GolfersDivisionsCount = (props) => {
  const golfersDivisions = props.content.create_division.golfers_divisions
  const customType = props.content.create_division.custom_types.selected

  useEffect(() => {
    // compute division count errors
    const error = (parseInt(golfersDivisions.choices[golfersDivisions.selected].value) || 0 > 0) ? undefined : (
      golfersDivisions.selected === 'number_of_golfers' ? internationalize('divisions.number_golfers_per_division') : internationalize('divisions.number_of_division')
    )
    props.setError(GolfersDivisionsCount.name, error)

    return () => {
      // on unmount, retract errors
      props.setError(GolfersDivisionsCount.name, undefined)
    }
  }, [ golfersDivisions.selected, golfersDivisions.choices[golfersDivisions.selected].value ])

  const selectCountType = (countType) => {
    props.setData([ 'create_division', 'golfers_divisions', 'selected' ], countType)
  }
  const setCount = (value) => {
    const countType = golfersDivisions.selected
    props.setData([ 'create_division', 'golfers_divisions', 'choices', countType, 'value' ], value)
    if (countType === 'number_of_golfers') {
      (props.content.create_division.divisions || []).forEach((_, i) => {
        props.setData([ 'create_division', 'divisions', i, 'golfers_per_division' ], value)
      })
    }
  }


  return (
    <div className='radio-option golfers_divisions_count'>
      <div className='options-container vertical'>
        {
          Object.keys(golfersDivisions.choices).map((choiceKey, index) => {
            const choice = golfersDivisions.choices[choiceKey]
            return (
              <div className='grid' key={`golfers_divisions_${index}`}>
                <label className={`icheck-label ${choice.enabled ? '' : 'disabled'}`} htmlFor={`golfers_divisions_${index}`}>
                  <div className={`iradio_square-orange icheck-item ${choice.enabled ? '' : 'disabled'} ${choiceKey === golfersDivisions.selected ? 'checked' : ''}`}>
                    <input
                      id={`golfers_divisions_${index}`}
                      className='i-checks icheck-input'
                      type='radio'
                      name={`golfers_divisions_${index}`}
                      value={choiceKey}
                      onChange={ event => choice.enabled && selectCountType(event.target.value) }
                      checked={choiceKey === golfersDivisions.selected}
                      disabled={! choice.enabled}
                    />
                  </div>
                  <span>{choice.label}</span>
                </label>
                <input
                  className={`form-control ${choiceKey === golfersDivisions.selected ? '' : 'disabled'}`}
                  value={ choice.value }
                  onChange={ e => setCount(e.target.value) }
                  onBlur={ e => {
                    const count = Math.max(0, parseInt(e.target.value) || 0)
                    setCount(count)
                  }}
                  disabled={ choiceKey !== golfersDivisions.selected }
                />
              </div>
            )
          })
        }
        {
          golfersDivisions.selected === 'number_of_divisions' &&
            <>
              <Checkbox
                key='auto_fill'
                label={golfersDivisions.auto_fill.label}
                selected={golfersDivisions.auto_fill.selected}
                setOption={(v) => { props.setData( [ 'create_division', 'golfers_divisions', 'auto_fill', 'selected' ], v) }}
              />
              <Checkbox
                key='maximum_handicap'
                label={customType === 'combined_index_usga_index' ? golfersDivisions.maximum_handicap.label_handicap_index : golfersDivisions.maximum_handicap.label_playing_handicap}
                selected={golfersDivisions.maximum_handicap.selected}
                setOption={(v) => { props.setData( [ 'create_division', 'golfers_divisions', 'maximum_handicap', 'selected' ], v) }}
              />
            </>
            
        }
      </div>
    </div>
  )
}

class CreateDivision extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const customTypes = this.props.content.create_division.custom_types
    const customType = customTypes.selected
    const customFields = this.props.content.create_division.custom_fields
    const dateForAge = this.props.content.create_division.date_for_age
    const genderAnswers = this.props.content.create_division.gender_answers
    const availableMembersCount = this.props.content.create_division.available_members_count
    const tees = this.props.content.create_division.tees
    const golfersPerDivision = this.props.content.create_division.golfers_divisions.choices.number_of_golfers.value
    const numberOfDivisions = this.props.content.create_division.golfers_divisions.choices.number_of_divisions.value
    const divisions = this.props.content.create_division.divisions || []
    const indexes = this.props.content.create_division.available_members_index || []
    const byGolfersOrDivisionsCount = this.props.content.create_division.golfers_divisions.selected
    const byHandicap = customType === 'by_course_handicap' || customType.startsWith('combined_index')

    const setDivisionData = (keys, value) => { this.props.setData([ 'create_division', 'divisions' ].concat(keys), value) }
    const setIndex = (value) => { this.props.setData([ 'create_division', 'available_members_index' ], value) }
    const setCount = (value) => {
      const golfersDivisions = this.props.content.create_division.golfers_divisions
  
      const countType = golfersDivisions.selected
      this.props.setData([ 'create_division', 'golfers_divisions', 'choices', countType, 'value' ], value)
      if (countType === 'number_of_golfers') {
        (this.props.content.create_division.divisions || []).forEach((_, i) => {
          this.props.setData([ 'create_division', 'divisions', i, 'golfers_per_division' ], value)
        })
      }
    }
    return (
      <div className='add_division_container'>
        <div className='radio-option select_custom_type'>
          <b>{ customTypes.label }</b>

          <div className='options-container vertical'>
            {
              customTypes.choices.map((choice, index) =>
                <label key={`custom_types_${index}`} className={`icheck-label ${choice.enabled ? '' : 'disabled'}`} htmlFor={`custom_types_${index}`}>
                  <div className={`iradio_square-orange icheck-item ${choice.enabled ? '' : 'disabled'} ${choice.value === customTypes.selected ? 'checked' : ''}`}>
                    <input
                      id={`custom_types_${index}`}
                      className='i-checks icheck-input'
                      type='radio'
                      name={`custom_types_${index}`}
                      value={choice.value}
                      onChange={ event => choice.enabled && this.props.setData([ 'create_division', 'custom_types', 'selected' ], event.target.value) }
                      checked={choice.value === customTypes.selected}
                      disabled={! choice.enabled}
                    />
                  </div>
                  <span>{choice.label}</span>
                  { choice.tooltip && <GlgTooltipIcon tooltip={ choice.tooltip } /> }
                </label>
              )
            }
          </div>
        </div>
        {
          <div className='division_options'>
            { (customType === 'by_course_handicap' || customType.startsWith('combined_index')) && <GolfersDivisionsCount {...this.props} /> }
            { customTypes.selected === 'by_age_gender' &&
              <div className='date_to_calculate_age'>
                <label>{ internationalize('divisions.index.age_gender.date_to_calculate_age') }</label>
                <DatePicker
                  customInput={<CalendarCustomInput />}
                  selected={ dateForAge && moment.unix(dateForAge).toDate() }
                  dateFormat='yyyy/MM/dd'
                  onChange={(date) => {
                    date = date ? moment(date).unix() : date
                    this.props.setData([ 'create_division', 'date_for_age' ], date)
                  }}
                />
              </div>
            }
            { customTypes.selected === 'by_custom_field' &&
              <div className='select_custom_field'>
                <label>{ internationalize('divisions.index.add_new_division_or_refill.choose_custom_field') }</label>
                <GlgSelect
                  key='select_custom_field'
                  items={ customFields.choices }
                  placeholder='Custom field'
                  value={ customFields.selected }
                  noPreFill={true}
                  onChange={ v => { this.props.setData([ 'create_division', 'custom_fields', 'selected' ], v) }}
                />
              </div>
            }
            {
              customType !== 'by_custom_field' &&
                <DivisionsFlightsTable
                  category='division'
                  withIndexUpTo={byHandicap && byGolfersOrDivisionsCount === 'number_of_divisions' && this.props.content.create_division.golfers_divisions.maximum_handicap.selected}
                  withNumberOfGolfers={byHandicap && byGolfersOrDivisionsCount === 'number_of_golfers'}
                  withGenders={customType === 'by_age_gender'}
                  withAgeRange={customType === 'by_age_gender'}
                  fixedItems={{
                    type: byHandicap ? (byGolfersOrDivisionsCount === 'number_of_divisions' ? 'fixed' : 'max_golfers') : 'disabled',
                    total: availableMembersCount,
                    value: parseInt(byGolfersOrDivisionsCount === 'number_of_divisions' ? numberOfDivisions : golfersPerDivision) || 0,
                    dependencies: [ this.props.content.create_division.golfers_divisions, customType ],
                  }}
                  genderAnswers={genderAnswers}
                  tees={tees}
                  items={divisions}
                  setItemData={setDivisionData}
                  setError={this.props.setError}
                  errorCheckingDependencies={[ customType, this.props.content.create_division.divisions, this.props.content.create_division.golfers_divisions ]}
                  rowClass={customType}
                  indexes={indexes}
                  setIndex={setIndex}
                  setCount={setCount}
                  golfersDivisionsFlights={this.props.content.create_division.golfers_divisions}
                />
            }
          </div>
        }
      </div>
    )
  }
}

class EventSetupDivisions extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { title, dataChanged, saveInProgress, links, addingDivision } = this.props.content

    const errors = Object.values(this.props.content.create_division.errors || {}).filter(x => x)

    return (
      <div className='widget-content-default widget-event-setup-divisions'>
        <div className='title'>
          { title }

          {
            dataChanged &&
              (() => {
                const saveButton = (
                  <a
                    onClick={saveInProgress || errors.length > 0 ? undefined : this.props.save}
                    className='btn btn-primary widget-white-title-button'
                    disabled={errors.length > 0}
                  >
                    { saveInProgress ? internationalize('dashboard_plus.widgets.saving') : links.save.name }
                  </a>
                )

                return errors.length > 0 ? <GlgTooltip tooltip={errors.join('\n')}>{saveButton}</GlgTooltip> : saveButton
              })()
          }
          {
            addingDivision &&
              <a
                onClick={ () => {
                  this.props.setData([ 'addingDivision' ], false)
                  this.props.setData([ 'dataChanged' ], false)
                }}
                className='btn btn-primary widget-white-title-button'
              >
                { internationalize('buttons.cancel') }
              </a>
          }
        </div>

        <div className='content flex-list-layout'>
          <ul>
            {
              addingDivision ?
                <CreateDivision {...this.props} />
                :
                <DivisionsOverview {...this.props} />
            }
          </ul>
        </div>
      </div>
    )
  }
}

EventSetupDivisions.propTypes = {
  content: PropTypes.object,
  setData: PropTypes.func,
  save: PropTypes.func,
  setError: PropTypes.func,
}
DivisionsOverview.propTypes = EventSetupDivisions.propTypes
CreateDivision.propTypes = EventSetupDivisions.propTypes
GolfersDivisionsCount.propTypes = EventSetupDivisions.propTypes

EventSetupDivisions.defaultProps = {
  content: {
    title: 'EVENT DIVISIONS',
    data: {},
  },
}

export default EventSetupDivisions
