import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NotificationContainer } from 'react-notifications'
import { Portal } from 'react-portal'
import onClickOutside from 'react-onclickoutside'
import ReactGA from 'react-ga4'

import { onConfirm, initializeReactGA4 } from 'Shared/helpers'

import {
  GlgIcon,
  GlgTooltip,
} from 'SharedComponents'

import NavigatorPanelContainer from '../containers/navigator_panel_container'
import PopupsContainer from '../containers/popups_containers'
import NavigatorFeaturePopupContent from './navigator_feature_popup_content'

class NavigatorPanelWrapper extends Component {
  constructor(props){
    super(props)
  }

  handleClickOutside = (event) => {
    this.props.handleClickOutside(event)
  }

  render() {
    return ( <NavigatorPanelContainer {...this.props} />)
  }
}

NavigatorPanelWrapper.propTypes = {
  handleClickOutside: PropTypes.func,
}

const WrappedNavigatorPanelContainer = onClickOutside(NavigatorPanelWrapper)

class NavigatorComponent extends Component {
  constructor(props) {
    super(props)

    this.handleNavigatorToggle = this.handleNavigatorToggle.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.onDropdownStateChange = this.onDropdownStateChange.bind(this)
    this.keyboardListener = this.keyboardListener.bind(this)
    initializeReactGA4()
  }
  componentDidMount() {
    ReactGA.send({hitType: 'pageview', page: '/navigator'})

    document.addEventListener('keydown', this.keyboardListener, false)

    if (this.props.showFeatureNotification) {
      onConfirm(NavigatorFeaturePopupContent, () => {
        window.glg.highlighter.highlight($('.navigator-container:visible'))
      }, {
        showCloseButton: false,
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyboardListener, false)
  }

  keyboardListener(event) {
    if (event.keyCode === 27) {
      if (this.props.isDropdownOpen) {
        this.onDropdownStateChange(false)
      }
    }
  }

  handleNavigatorToggle() {
    // Close navbar menus
    if ($('.navigation_menu .opened').length > 0) {
      $('.menu_items').find('li.active a')
                      .click()
    } else {
      const navbarMenuTile = $('.customer_menu_items .highlighted .menu_tile.active')
      if (navbarMenuTile.length > 0) {
        navbarMenuTile.find('a').click()
      }
    }

    // Close the opened menu on small screens
    const navbarToggle = $('.navbar-toggle:visible')
    if (navbarToggle.length > 0) {
      // navbarToggle.click()
      $('#menu-navbar-collapse').on('hidden.bs.collapse', () => {
        this.onDropdownStateChange(!this.props.isDropdownOpen)
        $('#menu-navbar-collapse').off('hidden.bs.collapse')
      })
      $('#menu-navbar-collapse').collapse('hide')
    } else {
      this.onDropdownStateChange(!this.props.isDropdownOpen)
    }
  }

  getMenuClass() {
    return `navigator-menu clickable ${ this.props.isDropdownOpen ? 'opened' : '' }`
  }

  handleClickOutside(event) {
    // Because we use portals, some "outside" elements are actually inside.
    const validOutsideElements = [ '.menu-portal-inner', '.navigator-panel', '.glg-popup', '.fade.modal' ]
    if ($(event.target).closest(validOutsideElements.join(', ')).length === 0) {
      this.onDropdownStateChange(false)
    }
  }

  onDropdownStateChange(value) {
    this.props.onDropdownStateChange(value)
    const action = value ? !window.I18n ? '' : window.I18n.t('navigator.components.navigator_component.open') : !window.I18n ? '' : window.I18n.t('buttons.close').charAt(0)
      .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.close').slice(1))
    ReactGA.event({
      category: !window.I18n ? '' : window.I18n.t('navigator.components.navigator_component.navigator'),
      action,
      label: this.props.filterValue,
    })
  }

  render() {
    let menu = <div className={ this.getMenuClass() + 'no-lite'} onClick={ this.handleNavigatorToggle }><GlgIcon icon="navigator-icon" iconClass="navigator-main-icon"></GlgIcon>{this.props.showNavigatorText && <span className="btsp_uppercase_text navigator-menu-text add-padding-left-5"><div className={ this.props.context === 'customer' ? 'visible-xs visible-sm hidden-md visible-lg' : '' }>{ !window.I18n ? '' : window.I18n.t('navigator.components.navigator_component.navigator') }</div></span>}</div>
    if (!this.props.isDropdownOpen) {
      menu = <GlgTooltip tooltip={ !window.I18n ? '' : window.I18n.t('navigator.components.navigator_component.navigator') } placement="bottom">{ menu }</GlgTooltip>
    }

    return <div id="navigator-root">{ menu }<Portal>{this.props.isDropdownOpen && <WrappedNavigatorPanelContainer handleClickOutside={ this.handleClickOutside } excludeScrollbar={ true }></WrappedNavigatorPanelContainer>}</Portal><NotificationContainer></NotificationContainer><PopupsContainer></PopupsContainer></div>
  }
}

NavigatorComponent.propTypes = {
  isDropdownOpen: PropTypes.bool.isRequired,
  context: PropTypes.string.isRequired,
  filterValue: PropTypes.string.isRequired,
  showFeatureNotification: PropTypes.bool,
  showNavigatorText: PropTypes.bool,
  onDropdownStateChange: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
}

NavigatorComponent.defaultProps = {
  showNavigatorText: true,
}

export default NavigatorComponent
