import { connect } from 'react-redux'
import { closeLeaguesPropagationPopup, propagateLeagues } from '../../actions/index'
import LeaguesPropagationPopup from '../../components/propagation/leagues_propagation_popup'

const mapStateToProps = (state) => ({
  show: state.leaguesPropagation.isOpen,
  isLoading: state.leaguesPropagation.isLoading,
  leagues: state.leaguesPropagation.leagues,
  isPropagateLeaguesLoading: state.leaguesPropagation.isPropagateLeaguesLoading,
  isCancelPropagateLeaguesLoading: state.leaguesPropagation.isCancelPropagateLeaguesLoading,
  canUseGolfChannelFlightRanking: state.misc.canUseGolfChannelFlightRanking,
})

const mapDispatchToProps = (dispatch) => ({
  closeLeaguesPropagationPopup: () => dispatch(closeLeaguesPropagationPopup()),
  propagateLeagues: (checkedLeagues, action) => dispatch(propagateLeagues(checkedLeagues, action)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaguesPropagationPopup)
