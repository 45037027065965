/* eslint-disable no-alert */
import React from 'react'
import PropTypes from 'prop-types'
import { GlgTableRow, GlgTableCell } from 'SharedComponents'

import { copyToClipboard } from '../helpers'

const TvShowTableRow = ({...props}) => {
  return <GlgTableRow { ...props } isSortable={ false }><GlgTableCell>{''}</GlgTableCell><GlgTableCell><a href={'#'}></a>{ props.name }</GlgTableCell><GlgTableCell>{ props.slideCount }</GlgTableCell><GlgTableCell><a href={`${props.requestProtocol}${props.slideshowLink}`} target={'_blank'}>{ `${props.slideshowLink}` }</a><a onClick={ () => { copyToClipboard(props.slideshowLink, !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_show_table_row.link_copied')) } } className="fa fa-copy"></a></GlgTableCell><GlgTableCell><span>{ props.pin.toUpperCase() }</span><a onClick={ () => { copyToClipboard(props.pin.toUpperCase(), !window.I18n ? '' : window.I18n.t('tv_shows.components.tv_show_table_row.ggid_copied')) } } className="fa fa-copy"></a></GlgTableCell>{ props.is_template && <GlgTableCell><a href="#" onClick={ (e) => sendToggleAutoIncludeRequest(e, props) }>{ !window.I18n ? '' : (props.auto_include ? window.I18n.t('tv_shows.components.tv_show_table_row.affirmative') : window.I18n.t('tv_shows.components.tv_show_table_row.negative')) }</a></GlgTableCell>}<GlgTableCell><a href={`${props.editLink}`}>{ !window.I18n ? '' : window.I18n.t('buttons.edit') }</a>{ ' • ' }<a onClick={ () => {
          if (window.confirm(!window.I18n ? '' : window.I18n.t('tv_shows.components.tv_show_table_row.delete_confirm', {name: `${props.name}`}))) {
            sendDeleteRequest(props)
          }
        } } className="cursor-pointer">{ !window.I18n ? '' : window.I18n.t('buttons.delete') }</a></GlgTableCell></GlgTableRow>
}

const sendDeleteRequest = (props) => {
  $.ajax({
    url: `/rounds/${props.round_id}/tv_shows/${props.id}`,
    type: 'DELETE',
    success: () => {
      props.removeTvShow(props.id)
    },
    error: () => {
    },
  })
}

const sendToggleAutoIncludeRequest = (e, props) => {
  e.preventDefault()
  $.ajax({
    url: `/rounds/${props.round_id}/tv_shows/${props.id}/include_exclude_template`,
    type: 'PATCH',
    success: () => {
      props.toggleAutoInclude(props.id)
    },
    error: () => {
    },
  })
}

TvShowTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  slideCount: PropTypes.number.isRequired,
  requestProtocol: PropTypes.string.isRequired,
  slideshowLink: PropTypes.string.isRequired,
  pin: PropTypes.string.isRequired,
  editLink: PropTypes.string.isRequired,
  is_template: PropTypes.bool.isRequired,
  auto_include: PropTypes.bool.isRequired,
  round_id: PropTypes.string.isRequired,
  removeTvShow: PropTypes.func.isRequired,
  toggleAutoInclude: PropTypes.func.isRequired,
}

export default TvShowTableRow
