import _ from 'lodash'

import { ActionTypes } from '../actions'
import { reorderListPositions } from 'Shared/list_helpers'

const {
  FETCH_INITIAL_DATA_RECEIVED,
  DELETE_LEAGUE,
  REORDER_LEAGUES,
  CHANGE_LEAGUE_SEASON,
  CHANGE_LEAGUE_TOUR,
  CHANGE_LEAGUE_CATEGORY,
  OPEN_REGISTRATION,
  CLOSE_REGISTRATION,
  UPDATE_OPEN_REGISTRATION_STATUS,
  DELETE_CATEGORY,
  CREATE_CATEGORY_RESPONSE,
  GET_LEAGUES,
  APPEND_LEAGUES,
} = ActionTypes

/*
const DEFAULT_LEAGUE_DATA = {
  id: null,
  name: null,
  position: null,
  product: 'event',
  startDate: null,
  category: null,
  season: null,
  tour: null,
  golfers: {
    confirmed: null,
    waitlisted: null,
    pending: null,
  },
  canManage: true,
  openRegistrationData: {
    hasOpenRegistration: false,
    disabledRegistrationButton: false,
    openRegistrationStatus: '',
    canInvitationsList: false,
    registrationStartsOn: null,
    registrationEndsOn: null,
  },
  leagueURL: null,
  portalURL: null,
  stripeAccount: false,
  copyStripeAndFees: false,
  isExpressEvent: false,
  timeZone: 'Europe/Bucharest',
  rounds: [],
  managers: [],
  changeTourURL: null,
}
*/

const changeLeagueAttribute = (state, leagueId, attrName, attrValue) => ({
  ...state,
  [leagueId]: {
    ...state[leagueId],
    [attrName]: attrValue,
  },
})

// Move the league to the first position of the target category
const changeLeagueCategory = (state, leagueId, toCategoryId) => {
  const leagues = _.values(state)
                   .filter(league => league.category === toCategoryId)
  leagues.push(state[leagueId])
  const minPosition = _.min(leagues.map(league => league.position))
  let updatedLeagues = reorderListPositions(leagues, state[leagueId].position, minPosition)
  updatedLeagues = _.keyBy(updatedLeagues, 'id')
  updatedLeagues[leagueId].category = toCategoryId
  return {
    ...state,
    ...updatedLeagues,
  }
}

const leagues = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INITIAL_DATA_RECEIVED: {
      const receivedLeagues = _.mapValues(action.data.leagues, league => ({
        ...state[league.id],
        ...league,
      }))

      return {
        ...state,
        ...receivedLeagues,
      }
    }

    case GET_LEAGUES: {
      const receivedLeagues = _.mapValues(action.leagues, league => ({
        ...state[league.id],
        ...league,
      }))

      return receivedLeagues
    }

    case APPEND_LEAGUES: {
      const receivedLeagues = _.mapValues(action.leagues, league => ({
        ...state[league.id],
        ...league,
      }))

      return {
        ...state,
        ...receivedLeagues,
      }
    }

    case DELETE_LEAGUE:
      return _.omit(state, action.id)

    // Used for reordering leagues in Category View (same or different categories) and List View
    case REORDER_LEAGUES: {
      const {
        fromId,
        toCategoryId,
        toId,
      } = action
      if (action.fromCategoryId !== toCategoryId) {
        // move to target category
        state = changeLeagueCategory(state, fromId, toCategoryId)
      }

      // toId is null when we move to another category's first slot
      if (toId) {
        // move to target position
        const fromPosition = state[fromId].position
        const toPosition = state[toId].position
        let leagues = _.values(state)
        if (toCategoryId) {
          // Category View drag&drop, only affects leagues within the category
          leagues = leagues.filter(league => league.category === toCategoryId)
        }
        let updatedLeagues = reorderListPositions(leagues, fromPosition, toPosition)
        updatedLeagues = _.keyBy(updatedLeagues, 'id')

        state = {
          ...state,
          ...updatedLeagues,
        }
      }

      return state
    }

    case CHANGE_LEAGUE_SEASON:
      return changeLeagueAttribute(state, action.leagueId, 'season', action.seasonId)
    case CHANGE_LEAGUE_TOUR:
      return changeLeagueAttribute(state, action.leagueId, 'tour', action.tourId)
    case CHANGE_LEAGUE_CATEGORY: {
      return changeLeagueCategory(state, action.leagueId, action.toCategoryId)
    }
    case OPEN_REGISTRATION:
      return changeLeagueAttribute(state, action.leagueId, 'openRegistrationData', {
        ...state[action.leagueId].openRegistrationData,
        openRegistrationStatus: 'open',
      })
    case CLOSE_REGISTRATION:
      return changeLeagueAttribute(state, action.leagueId, 'openRegistrationData', {
        ...state[action.leagueId].openRegistrationData,
        openRegistrationStatus: '',
      })
    case UPDATE_OPEN_REGISTRATION_STATUS:
      return changeLeagueAttribute(state, action.leagueId, 'openRegistrationData', {
        ...state[action.leagueId].openRegistrationData,
        openRegistrationStatus: action.status,
      })
    case DELETE_CATEGORY: {
      let movedLeagues = _.pickBy(state, league => league.category === action.id)
      movedLeagues = _.mapValues(movedLeagues, league => ({
        ...league,
        category: action.defaultCategoryId,
      }))
      return {
        ...state,
        ...movedLeagues,
      }
    }
    case CREATE_CATEGORY_RESPONSE:
      if (action.newId) {
        return _.mapValues(state, league => {
          if (league.category === action.oldId) {
            return {
              ...league,
              category: action.newId,
            }
          } else {
            return league
          }
        })
      } else {
        return state
      }
    default:
      return state
  }
}

export default leagues
