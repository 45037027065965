import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'
import {Form} from 'react-bootstrap'
import TermsAndPrivacyComponent from '../terms_and_privacy/terms_and_privacy_component'
import { FILEPICKER_KEY, filepickerOptions } from '../../helpers'
import Loader from 'react-loader-spinner'

export class UploadSpreadsheetComponent extends Component {
  constructor(props) {
    super(props)
    this.handleChangeFileUrl = this.handleChangeFileUrl.bind(this)
    this.filepickerRef = React.createRef()
  }
  
  componentDidMount(){
    window.filepicker.setKey(FILEPICKER_KEY)
    if (this.filepickerRef) {
      window.filepicker.constructWidget(this.filepickerRef.current)
      for (const el of document.getElementsByClassName('react-spreadsheet-btn')) {
        el.classList.toggle('disabled')
        el.setAttribute('disabled', '')
      }
      this.filepickerRef.current.addEventListener('change', this.handleChangeFileUrl, false)
    }
  }
  
  componentWillUnmount(){
    this.filepickerRef.current.removeEventListener('change', this.handleChangeFileUrl, false)
  }
  
  handleChangeFileUrl(e){
    const data = {
      filepicker_url: e.fpfile.url,
      attachment_link: e.fpfile.key,
      attachment_size: e.fpfile.size,
      attachment_name: e.fpfile.filename,
    }
    this.props.uploadRosterSpreadsheet(data)
  }
  
  handleSubmit(){

  }

  render() {    
    return <Col xs={12} className={'leftsidebar_container no_padding_right no_padding_left left_sidebar_open_container'} id="fields_options_content"><Form onSubmit={this.handleSubmit}><div className="btsp_ps_container_normal_scroll leftsidebar_paddings no-flex" id="choose_league_columns_scroll"><div className="league_form"><div className="title">{ !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.upload_spreadsheet_component.upload_roster_from_spreadsheet') }</div><div className="clear subtitle">{ !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.upload_spreadsheet_component.info') }</div><div className="sidebar_form_content"><TermsAndPrivacyComponent branding={this.props.branding} type={'roster'} termsAndConditionsUrl={this.props.termsAndConditionsUrl} privacyPolicyUrl={this.props.privacyPolicyUrl} toggleTermsAndPrivacy={(checked) => {
                    if (this.filepickerRef){
                      for (const el of document.getElementsByClassName('react-spreadsheet-btn')){
                        el.classList.toggle('disabled')
                        !checked ? el.setAttribute('disabled', '') : el.removeAttribute('disabled')
                      }
                    }
                    this.props.toggleTermsAndPrivacy(checked, 'uploadRoster')
                  }} checked={this.props.termsAndPrivacyChecked.uploadRoster}></TermsAndPrivacyComponent></div></div></div><fieldset className="submit arrange_right submit_buttons no-top-border"><a href="#" className="btn button btsp_white_button close_sidebar" onClick={this.props.closeUploadSpreadsheetSidebar}>{ !window.I18n ? '' : window.I18n.t('buttons.cancel').charAt(0)
                .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.cancel').slice(1)) }</a><fieldset className="inline pull-right"><input type="filepicker" ref={this.filepickerRef} className={'btsp_orange_button react-spreadsheet-btn'} disabled={true} {...filepickerOptions(this.props.filepickerOptions)} /></fieldset><div className="inline pull-right glg-loading-spinner">{this.props.isUploadRosterLoading && <Loader type="Oval" color={ '#0088CE' } height={ 30 } width={ 30 }></Loader>}</div></fieldset></Form></Col>
  }
}

UploadSpreadsheetComponent.propTypes = {
  closeUploadSpreadsheetSidebar: PropTypes.func.isRequired,
  branding: PropTypes.string.isRequired,
  termsAndConditionsUrl: PropTypes.string.isRequired,
  privacyPolicyUrl: PropTypes.string.isRequired,
  toggleTermsAndPrivacy: PropTypes.func.isRequired,
  termsAndPrivacyChecked: PropTypes.object.isRequired,
  filepickerOptions: PropTypes.object.isRequired,
  uploadRosterSpreadsheet: PropTypes.func.isRequired,
  isUploadRosterLoading: PropTypes.bool.isRequired,
}

export default UploadSpreadsheetComponent
