import {ActionTypes} from '../actions/setup_index'

const {
  CHANGE_ORDER,
  CHANGE_ROUND_ORDER,
  RECALCULATE_ROUND_ORDER,
  CHANGE_RANKING_METHOD,
  CHANGE_PREV_LEAGUE,
  CHANGE_GROSS_SCORES,
  CHANGE_PICK_TIME,
} = ActionTypes

const initialState = {
  teamsetId: '',
  name: '',
  order: '',
  roundsOrder: {},
  roundsCount: 0,
  rankingMethod: '',
  prevLeagueId: null,
  grossScores: 0,
  pickTime: 0,
}

const drafts = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_ORDER:
      return {
        ...state,
        order: action.data,
      }
    case CHANGE_ROUND_ORDER:
      return {
        ...state,
        roundsOrder: {
          ...state.roundsOrder,
          [action.data.roundNo]: action.data.order,
        },
      }
    case RECALCULATE_ROUND_ORDER: {
      const roundsOrder = state.roundsOrder
      Object.keys(roundsOrder).
        forEach(k => {
          if (k >= action.roundsCount - 1) {
            delete roundsOrder[k]
          }
        })
      return {
        ...state,
        roundsOrder: roundsOrder,
      }
    }
    case CHANGE_RANKING_METHOD:
      return {
        ...state,
        rankingMethod: action.data,
      }
    case CHANGE_PICK_TIME:
      return {
        ...state,
        pickTime: action.data,
      }
    case CHANGE_PREV_LEAGUE:
      return {
        ...state,
        prevLeagueId: action.data,
      }
    case CHANGE_GROSS_SCORES:
      return {
        ...state,
        grossScores: action.data,
      }
    default:
      return state
  }
}

export default drafts
