import rootReducer from '../../tour_event_results/reducers'
import React from 'react'
import PropTypes from 'prop-types'
import {GlobalProvider} from 'Shared/global_context'
import configureStore from '../../shared/configure_store'
import TourEventResultsContainer from './tour_event_results_container'

const TourEventResultsRoot = ({fetchDataBaseUrl, selectedTourEvent, tourEvents}) => {
  const state = {
    tourEventResultsData: {
      fetchDataBaseUrl,
      selectedTourEvent,
      tourEvents,
      playersResults: [],
      gameState: '',
      dataIsLoading: true,
      reloading: false,
    },
  }

  const store = configureStore(rootReducer, state)

  return (
    <GlobalProvider store={store}>
      <TourEventResultsContainer/>
    </GlobalProvider>
  )
}

TourEventResultsRoot.propTypes = {
  fetchDataBaseUrl: PropTypes.string,
  selectedTourEvent: PropTypes.string,
  tourEvents: PropTypes.any,
}

export default TourEventResultsRoot
