import React, { Component } from 'react'
import PropTypes from 'prop-types'

class DrivingDistancesSearchComponent extends Component {
  constructor(props) {
    super(props)
    this.handleChangeSearch = this.handleChangeSearch.bind(this)
  }
  
  handleChangeSearch = (event) => {
    const target = event.target
    const value = target.value
    
    this.props.changeSearchValue(value)
  }

  render() {
    return (
      <div className='col-xs-12 col-sm-6 col-md-6 col-lg-2 add-margin-top-20 add-margin-bottom-20'>
        <input type='text'
               onChange={this.handleChangeSearch}
               placeholder={ window.I18n.t('driving_distances.search_player_label') }
               className='form-control'/>
      </div>
    )
  }
}

DrivingDistancesSearchComponent.propTypes = {
  changeSearchValue: PropTypes.func,
}

export default DrivingDistancesSearchComponent
