import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga4'

import { onConfirm, initializeReactGA4 } from 'Shared/helpers'

import {
  GlgLoadingSpinner,
  GlgTextInput,
  GlgTooltipIcon,
  GlgIcon,
} from 'SharedComponents'

import NavigatorBookmarksContainer from '../containers/navigator_bookmarks_container'
import NavigatorOptionsList from './navigator_options_list'


class NavigatorPanelComponent extends Component {
  constructor(props) {
    super(props)
    this.onKbClick = this.onKbClick.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.handleSaveBookmarksClick = this.handleSaveBookmarksClick.bind(this)
    this.handleClearBookmarksClick = this.handleClearBookmarksClick.bind(this)
    this.state = {
      panelHeight: this.computePanelHeight(),
    }
    initializeReactGA4()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleScroll)

    if (!this.props.dataIsRequested) {
      this.props.fetchInitialData()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleScroll)
  }

  computePanelHeight() {
    let navbar = $('.top_menu_navbar')
    if (navbar.length === 0) {
      navbar = $('.customer-navbar')
    }
    const navbarBottom = navbar.offset().top + navbar.outerHeight(true) - $(window).scrollTop()
    const panelHeight = window.innerHeight - Math.max(0, navbarBottom)
    return panelHeight
  }

  handleScroll() {
    this.setState({
      panelHeight: this.computePanelHeight(),
    })
  }

  onKbClick() {
    this.props.changeDropdownState(false)
    $('#support_bubble').click()
  }

  getPanelStyle() {
    return {
      height: this.state.panelHeight,
    }
  }

  onFilterChange(value) {
    this.props.onFilterChange(value)
    if (this.filterEventTimer) {
      clearTimeout(this.filterEventTimer)
    }
    if (value) {
      // After 2 seconds of not typing, it is considered a query
      this.filterEventTimer = setTimeout(() => {
        ReactGA.event({
          category: !window.I18n ? '' : window.I18n.t('navigator.components.navigator_panel_component.navigator'),
          action: !window.I18n ? '' : window.I18n.t('navigator.components.navigator_panel_component.search'),
          label: value.toLowerCase(),
        })
      }, 2000)
    }
  }

  handleSaveBookmarksClick() {
    onConfirm(!window.I18n ? '' : window.I18n.t('navigator.components.navigator_panel_component.save_confirm'),
              this.props.saveBookmarksTemplate)
  }

  handleClearBookmarksClick() {
    onConfirm(!window.I18n ? '' : window.I18n.t('navigator.components.navigator_panel_component.delete_confirm'),
              this.props.clearAllBookmarks)
  }

  render() {
    return <div style={ this.getPanelStyle() } className="navigator-panel flexbox-context"><div className="navigator-panel-section navigator-title-section"><span className="pull-left add-margin-right-5"><GlgIcon icon="navigator-icon" iconClass="navigator-main-icon"></GlgIcon></span><span className="glg-uppercase-text pull-left">{ !window.I18n ? '' : window.I18n.t('navigator.components.navigator_panel_component.navigator') }</span><div className="pull-right navigator-close-icon add-margin-left-30 clickable"><GlgIcon icon="close-x" onClick={ () => this.props.changeDropdownState(false) }></GlgIcon></div>{this.props.showSaveBookmarks && <div className="pull-right"><span onClick={ this.handleSaveBookmarksClick } className="orange_text clickable">{ !window.I18n ? '' : window.I18n.t('navigator.components.navigator_panel_component.save_bookmarks_as_defaults') }</span><GlgTooltipIcon tooltip={ !window.I18n ? '' : window.I18n.t('navigator.components.navigator_panel_component.when_creating_new_event') } placement="left"></GlgTooltipIcon></div>}</div><div className="navigator-panel-section navigator-panel-results-section"><GlgTextInput value={ this.props.navigatorFilter } onChange={ this.onFilterChange } withAddon={ true } focusOnMount={ true }></GlgTextInput><GlgLoadingSpinner show={ this.props.dataIsLoading }></GlgLoadingSpinner>{!this.props.dataIsLoading && <Fragment><p className="btsp_bold_text navigator-search-text">{ !window.I18n ? '' : window.I18n.t('navigator.components.navigator_panel_component.search_for_any_page',
                  {category: this.props.context === 'event' ? (
                  !window.I18n ? '' : window.I18n.t('navigator.components.navigator_panel_component.categories_1')
                ) : (
                  !window.I18n ? '' : window.I18n.t('navigator.components.navigator_panel_component.categories_2')
                )})}</p>{this.props.navigatorFilter.length === 0 ? <Fragment><div className="add-margin-top-10"><span className="btsp_bold_text navigator-search-text no-margin">{ !window.I18n ? '' : window.I18n.t('navigator.components.navigator_panel_component.bookmarks') }</span>{this.props.anyBookmarks && <span onClick={ this.handleClearBookmarksClick } className="clickable orange_text add-margin-left-5 pull-right">{ !window.I18n ? '' : window.I18n.t('navigator.components.navigator_panel_component.clear_saved_bookmarks') }</span>}</div><div className="navigator-results"><NavigatorBookmarksContainer></NavigatorBookmarksContainer></div></Fragment> : <div className="navigator-results"><NavigatorOptionsList navigatorOptions={ this.props.navigatorOptions }></NavigatorOptionsList></div>}</Fragment>}</div><div className="navigator-panel-section text-right"><div><a onClick={ this.onKbClick } className="clickable glg-uppercase-text">{ !window.I18n ? '' : window.I18n.t('navigator.components.navigator_panel_component.knowledge_base') }</a></div></div></div>
  }
}

NavigatorPanelComponent.propTypes = {
  navigatorOptions: PropTypes.array,
  dataIsLoading: PropTypes.bool.isRequired,
  dataIsRequested: PropTypes.bool.isRequired,
  navigatorFilter: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
  showSaveBookmarks: PropTypes.bool.isRequired,
  anyBookmarks: PropTypes.bool.isRequired,
  fetchInitialData: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  saveBookmarksTemplate: PropTypes.func.isRequired,
  clearAllBookmarks: PropTypes.func.isRequired,
  changeDropdownState: PropTypes.func.isRequired,
}

export default NavigatorPanelComponent
