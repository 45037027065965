/*
 * This is only useful for building other components so that the passed icon props can be generalized.
 */
import React from 'react'
import PropTypes from 'prop-types'

import GlgIcon from './glg_icon'
import FaIcon from 'react-fa'

const GlgGeneralIcon = ({ icon }) => {
  if (icon.startsWith('gga')) {
    const ggaIcon = icon.substr(4)
    return <GlgIcon icon={ ggaIcon }></GlgIcon>
  } else {
    const faIcon = icon.substr(3)
    return <FaIcon name={ faIcon }></FaIcon>
  }
}

GlgGeneralIcon.propTypes = {
  icon: PropTypes.string.isRequired,
}

export default GlgGeneralIcon
