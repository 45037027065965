import _ from 'lodash'
import { PopupActionTypes } from '../actions'
const {
  OPEN_POPUP,
  CLOSE_POPUP,
} = PopupActionTypes

const popupReducerCreator = (popupTypes) => {
  const initialpopupState = {
    show: false,
    data: {},
  }
  const initialpopupStates = _
    .chain(popupTypes)
    .mapKeys(value => value)
    .mapValues(() => initialpopupState)
    .value()

  const popupStates = (state = initialpopupStates, action) => {
    switch (action.type) {
      case OPEN_POPUP:
        return {
          ...state,
          [action.id]: {
            show: true,
            data: action.data || {},
          },
        }
      case CLOSE_POPUP:
        return {
          ...state,
          [action.id]: {
            show: false,
            data: {},
          },
        }
      default:
        return state
    }
  }
  
  return popupStates
}

export default popupReducerCreator
