import React, { Component, Fragment } from 'react'
import { GlgRangeSlider, GlgSwitch } from 'SharedComponents'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { MIN_HEADER_HEIGHT, MAX_HEADER_HEIGHT, MIN_FOOTER_HEIGHT, MAX_FOOTER_HEIGHT } from '../constants'

class BlueBox extends Component {

  constructor(props) {
    super(props)

    this.controls = [
      {
        name: 'fontSize',
        step: 1,
        minValue: 10,
        maxValue: 100,
        ref: React.createRef(),
        setValue: props.setFontSize,
      },
      {
        name: 'scrollRate',
        step: 0.1,
        minValue: 0.1,
        maxValue: 3,
        ref: React.createRef(),
        setValue: props.setScrollRate,
      },
      {
        name: 'topMargin',
        step: 5,
        minValue: 0,
        maxValue: 100,
        ref: React.createRef(),
        setValue: props.setTopMargin,
      },
      {
        name: 'bottomMargin',
        step: 5,
        minValue: 0,
        maxValue: 100,
        ref: React.createRef(),
        setValue: props.setBottomMargin,
      },
      {
        name: 'sideMargins',
        step: 5,
        minValue: 0,
        maxValue: 100,
        ref: React.createRef(),
        setValue: props.setSideMargin,
      },
      {
        name: 'headerHeight',
        step: 1,
        minValue: MIN_HEADER_HEIGHT,
        maxValue: MAX_HEADER_HEIGHT,
        ref: React.createRef(),
        setValue: props.setHeaderHeight,
      },
      {
        name: 'footerHeight',
        step: 1,
        minValue: MIN_FOOTER_HEIGHT,
        maxValue: MAX_FOOTER_HEIGHT,
        ref: React.createRef(),
        setValue: props.setFooterHeight,
      },
      {
        name: 'tickerDisplayScrolling',
        ref: React.createRef(),
      },
      {
        name: 'tickerDisplayCenter',
        ref: React.createRef(),
      },
      {
        name: 'tickerDisplayLeft',
        ref: React.createRef(),
      },
      {
        name: 'tickerDisplayRight',
        ref: React.createRef(),
      },
      {
        name: 'themeDark',
        ref: React.createRef(),
        setValue: props.setColorTheme,
      },
      {
        name: 'closeButton',
        ref: React.createRef(),
      },
    ]

    window.glg.tvShows = {}

    this.state = {
      focusedControlIndex: -1,
    }

    this.manageFullscreen = this.manageFullscreen.bind(this)

    this.shareBlueBoxManipulationWithFireTV = this.shareBlueBoxManipulationWithFireTV.bind(this)
    this.handleClickForFireTv = this.handleClickForFireTv.bind(this)
    this.handleControlNavigationForFireTV = this.handleControlNavigationForFireTV.bind(this)
    this.handleControlSlideForFireTV = this.handleControlSlideForFireTV.bind(this)
    this.handlePlayPauseForFireTV = this.handlePlayPauseForFireTV.bind(this)
    this.handleChangeSlideForFireTV = this.handleChangeSlideForFireTV.bind(this)
    this.keyPress = this.keyPress.bind(this)
  }

  componentDidMount() {
    this.shareBlueBoxManipulationWithFireTV()
    document.addEventListener('fullscreenchange', this.manageFullscreen, false)
    document.addEventListener('keydown', this.keyPress)
  }

  componentWillUnmount() {
    document.removeEventListener('fullscreenchange', this.manageFullscreen, false)
    document.removeEventListener('keydown', this.keyPress)
  }

  keyPress(e) {
    switch (e.key) {
      case 'n':
        this.handleClickForFireTv()
        break
      case 's':
        this.handleControlNavigationForFireTV('next')
        break
      case 'w':
        this.handleControlNavigationForFireTV('previous')
        break
      case 'a':
        this.handleControlSlideForFireTV('left')
        break
      case 'd':
        this.handleControlSlideForFireTV('right')
        break
    }
  }

  shareBlueBoxManipulationWithFireTV() {
    window.glg.tvShows.clickFocusedInput = this.handleClickForFireTv
    window.glg.tvShows.focusElement = this.handleControlNavigationForFireTV
    window.glg.tvShows.slide = this.handleControlSlideForFireTV
    window.glg.tvShows.playOrPause = this.handlePlayPauseForFireTV
    window.glg.tvShows.changeSlide = this.handleChangeSlideForFireTV
  }

  handleClickForFireTv() {
    const { show, showBlueBox } = this.props
    const { focusedControlIndex } = this.state

    if ( !show ) {
      showBlueBox()
    } else if ( _.get(this.controls[focusedControlIndex], 'ref.current') ) {
      $(this.controls[focusedControlIndex].ref.current).click()
    }
  }

  handleControlNavigationForFireTV(direction) {
    let newIndex = this.state.focusedControlIndex
    if (direction === 'next') {
      do {
        newIndex = (newIndex + 1) % this.controls.length
      } while ( this.controls[newIndex].ref && !this.controls[newIndex].ref.current )
    } else if (direction === 'previous') {
      do {
        newIndex = (newIndex - 1 + this.controls.length) % this.controls.length
      } while ( this.controls[newIndex].ref && !this.controls[newIndex].ref.current )
    }

    this.setState({ focusedControlIndex: newIndex }, () => {
      if (_.get(this.controls[this.state.focusedControlIndex], 'ref.current')) {
        this.controls[this.state.focusedControlIndex].ref.current.focus()
      }
    })
  }

  handleControlSlideForFireTV(direction) {
    const currentControl = this.controls[this.state.focusedControlIndex]
    let newValue = _.get(currentControl, 'ref.current.value', -1)
    if (newValue < 0) { return }

    newValue = parseFloat(newValue)
    if (direction === 'left') {
      newValue = Math.round( (newValue - currentControl.step) * 10 ) / 10
    } else if ( direction === 'right' ) {
      newValue = Math.round( (newValue + currentControl.step) * 10 ) / 10
    }

    if (
      newValue !== currentControl.ref.current.value &&
      currentControl.minValue <= newValue &&
      newValue <= currentControl.maxValue
    ) {
      currentControl.setValue(newValue)
    }
  }

  handlePlayPauseForFireTV() {
    this.props.toggleScrolling()
  }

  handleChangeSlideForFireTV() {}

  manageFullscreen() {
    this.props.toggleFullscreen()
  }

  render() {
    const { show, isFireTv, hideBlueBox, header, footer } = this.props
    const controlClass = isFireTv ? 'fire-tv' : ''

    return <div className={`control_pane ${show ? 'down' : 'up'}`}><h3>{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.gg_screen_viewer') }</h3><p className="note desktop">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.note') }</p><table className="sliders"><tbody><tr><td className="title">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.font_size') }</td><td>{
                  ( slider => <GlgRangeSlider innerRef={ slider.ref } className={ controlClass } value={ this.props.fontSize } min={ slider.minValue } max={ slider.maxValue } step={ slider.step } unit={ 'px' } onChange={ this.props.setFontSize }></GlgRangeSlider>
                  )(this.controls.find( control => control.name === 'fontSize' ))
              }</td></tr><tr><td className="title">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.scroll_rate') }</td><td>{
                  ( slider => <GlgRangeSlider innerRef={ slider.ref } className={ controlClass } value={ this.props.scrollRate } min={ slider.minValue } max={ slider.maxValue } step={ slider.step } unit={ 'x' } onChange={ this.props.setScrollRate }></GlgRangeSlider>
                  )(this.controls.find( control => control.name === 'scrollRate' ))
                }</td></tr><tr><td className="title">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.top_margin') }</td><td>{
                  ( slider => <GlgRangeSlider innerRef={ slider.ref } className={ controlClass } value={ this.props.topMargin } min={ slider.minValue } max={ slider.maxValue } step={ slider.step } unit={ '%' } onChange={ this.props.setTopMargin }></GlgRangeSlider>
                  )(this.controls.find( control => control.name === 'topMargin' ))
                }</td></tr><tr><td className="title">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.bottom_margin') }</td><td>{
                  ( slider => <GlgRangeSlider innerRef={ slider.ref } className={ controlClass } value={ this.props.bottomMargin } min={ slider.minValue } max={ slider.maxValue } slider={ slider.step } unit={ 'px' } onChange={ this.props.setBottomMargin }></GlgRangeSlider>
                  )(this.controls.find( control => control.name === 'bottomMargin' ))
                }</td></tr><tr><td className="title">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.side_margins') }</td><td>{
                  ( slider => <GlgRangeSlider innerRef={ slider.ref } className={ controlClass } value={ this.props.sideMargin } min={ slider.minValue } max={ slider.maxValue } step={ slider.step } unit={ 'px' } onChange={ this.props.setSideMargin }></GlgRangeSlider>
                  )(this.controls.find( control => control.name === 'sideMargins' ))
                }</td></tr>{ header.isVisible && <tr><td className="title">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.header_height') }</td><td>{
                    ( slider => <GlgRangeSlider innerRef={ slider.ref } className={ controlClass } value={ header.height } min={ slider.minValue } max={ slider.maxValue } step={ slider.step } unit={ 'px' } onChange={ this.props.setHeaderHeight }></GlgRangeSlider>
                    )(this.controls.find( control => control.name === 'headerHeight' ))
                  }</td></tr> }{ footer.isVisible && <tr><td className="title">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.footer_height') }</td><td>{
                    ( slider => <GlgRangeSlider innerRef={ slider.ref } className={ controlClass } value={ footer.height } min={ slider.minValue } max={ slider.maxValue } step={ slider.step } unit={ 'px' } onChange={ this.props.setFooterHeight }></GlgRangeSlider>
                    )(this.controls.find( control => control.name === 'footerHeight' ))
                  }</td></tr> }<tr><td className="title">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.ticker_display') }</td><td>{ isFireTv ? <Fragment><input ref={ this.controls.find( control => control.name === 'tickerDisplayScrolling' ).ref } type={ 'radio' } checked={ this.props.tickerDisplay === 'scrolling' } value={ 'scrolling' } name={ 'tickerDisplay' } id={ 'tickerScrolling' } onChange={ this.props.setTickerDisplay } /><label htmlFor={ 'tickerScrolling' } className="ticker-label">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.scrolling') }</label><input ref={ this.controls.find( control => control.name === 'tickerDisplayCenter' ).ref } type={ 'radio' } checked={ this.props.tickerDisplay === 'center' } value={ 'center' } name={ 'tickerDisplay' } id={ 'tickerCenter' } onChange={ this.props.setTickerDisplay } /><label htmlFor={ 'tickerCenter' } className="ticker-label">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.centered') }</label><input ref={ this.controls.find( control => control.name === 'tickerDisplayLeft' ).ref } type={ 'radio' } checked={ this.props.tickerDisplay === 'left' } value={ 'left' } name={ 'tickerDisplay' } id={ 'tickerLeft' } onChange={ this.props.setTickerDisplay } /><label htmlFor={ 'tickerLeft' } className="ticker-label">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.left_justified') }</label><input ref={ this.controls.find( control => control.name === 'tickerDisplayRight' ).ref } type={ 'radio' } checked={ this.props.tickerDisplay === 'right' } value={ 'right' } name={ 'tickerDisplay' } id={ 'tickerRight' } onChange={ this.props.setTickerDisplay } /><label htmlFor={ 'tickerRight' } className="ticker-label">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.right_justified') }</label></Fragment> :
                    <select
                      className={`ticker-select ${controlClass}`}
                      value={ this.props.tickerDisplay }
                      onChange={ this.props.setTickerDisplay }
                    >
                      <option value='scrolling'>{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.scrolling') }</option>
                      <option value='center'>{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.centered') }</option>
                      <option value='left'>{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.left_justified') }</option>
                      <option value='right'>{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.right_justified') }</option>
                    </select>
                }</td></tr><tr><td className="title">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.dark') }</td><td>{
                  ( uiSwitch => <GlgSwitch id={ 'slideshow-color-theme' } innerRef={ uiSwitch.ref } text={ [ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.toggle_on'), 
                               !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.toggle_off') ] } widthInPixels={ 60 } checked={ this.props.colorTheme === 'dark' } onChange={ () => { uiSwitch.setValue( this.props.colorTheme === 'light' ? 'dark' : 'light' ) } }></GlgSwitch>)(this.controls.find( control => control.name === 'themeDark' ))
                }</td></tr></tbody></table><div className="buttons-group clearfix">{ isFireTv ? <div id="close-button"><button className={ controlClass } ref={ this.controls.find( control => control.name === 'closeButton' ).ref } onClick={ () => hideBlueBox() }>{ !window.I18n ? '' : window.I18n.t('buttons.close').charAt(0)
                  .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.close').slice(1)) }</button></div> : <Fragment><div className="fullscreen-buttons">{ this.props.isFullscreen ? <button onClick={ () => { 
                    if (window.document.exitFullscreen) { 
                      window.document.exitFullscreen()
                    } else if (window.document.mozCancelFullScreen) {
                      window.document.mozCancelFullScreen()
                    } else if (window.document.webkitExitFullscreen) {
                      window.document.webkitExitFullscreen()
                    } else if (window.document.msExitFullscreen) {
                      window.document.msExitFullscreen()
                    }
                  } }>{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.exit_fullscreen') }</button> : <button onClick={ () => { 
                    if (document.documentElement.requestFullscreen) {
                      document.documentElement.requestFullscreen()
                    } else if (document.documentElement.webkitRequestFullscreen) {
                      document.documentElement.webkitRequestFullscreen()
                    } else if (document.documentElement.mozRequestFullscreen) { 
                      document.documentElement.mozRequestFullscreen()
                    } else if (document.documentElement.msRequestFullscreen) {
                      document.documentElement.msRequestFullscreen()
                    }
                  } }>{ !window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.fullscreen') }</button> }</div><div className="play-pause-buttons"><button onClick={ this.props.toggleScrolling }>{ this.props.isScrolling ? (!window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.pause_scrolling')) : 
                    (!window.I18n ? '' : window.I18n.t('tv_shows.components.blue_box_component.resume_scrolling')) }</button></div></Fragment> }</div></div>
  }

}

BlueBox.propTypes = {
  show: PropTypes.bool.isRequired,
  fontSize: PropTypes.number.isRequired,
  scrollRate: PropTypes.number.isRequired,
  topMargin: PropTypes.number.isRequired,
  bottomMargin: PropTypes.number.isRequired,
  sideMargin: PropTypes.number.isRequired,
  header: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
  tickerDisplay: PropTypes.string.isRequired,
  colorTheme: PropTypes.string.isRequired,
  isFullscreen: PropTypes.bool.isRequired,
  isScrolling: PropTypes.bool.isRequired,
  isFireTv: PropTypes.bool.isRequired,

  showBlueBox: PropTypes.func.isRequired,
  hideBlueBox: PropTypes.func.isRequired,
  setFontSize: PropTypes.func.isRequired,
  setScrollRate: PropTypes.func.isRequired,
  setTopMargin: PropTypes.func.isRequired,
  setBottomMargin: PropTypes.func.isRequired,
  setSideMargin: PropTypes.func.isRequired,
  setHeaderHeight: PropTypes.func.isRequired,
  setFooterHeight: PropTypes.func.isRequired,
  setTickerDisplay: PropTypes.func.isRequired,
  setColorTheme: PropTypes.func.isRequired,
  toggleFullscreen: PropTypes.func.isRequired,
  toggleScrolling: PropTypes.func.isRequired,
}

export default BlueBox
