import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'

import TeeData from './tee_data_component'
import FoursomeData from './foursome_data_component'
import FoursomeTeamData from './foursome_team_data_component'

class DoubleScoreVerificationTableComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { tees, foursomes, isMultiRound, holeView, openPopup, openTeamPopup, teamscoreVerification } = this.props
    const foursomesForStep = foursomes.filter(foursome => foursome.size > 0)

    return foursomesForStep.length > 0 ? (
      <Col xs={12} sm={12} md={12} lg={12} className='no-padding'>
        <div className='double_score_verification_table btsp_ps_container btsp_fheader_table_container'>
          <table className='double_score_verifications btsp_fheader_table'>
            <thead> 
              <TeeData 
                tees={tees}
                isMultiRound={isMultiRound}
                teamscoreVerification={teamscoreVerification}
              />
            </thead>
            <tbody>
              {
                teamscoreVerification
                ? foursomesForStep.map( (foursome, index) => 
                  <FoursomeTeamData 
                    key={foursome.id}
                    foursome={foursome}
                    isMultiRound={isMultiRound}
                    foursomeIndex={index}
                    holeView={holeView}
                    openTeamPopup={openTeamPopup}/>
                )
                : foursomesForStep.map( (foursome, index) =>
                  <FoursomeData
                    key={foursome.id}
                    foursome={foursome}
                    isMultiRound={isMultiRound}
                    foursomeIndex={index}
                    holeView={holeView}
                    openPopup={openPopup}/>
                )
              }
            </tbody>
          </table>
        </div>
      </Col>
    )
      : 
      <div className='text-center add-margin-top-20'>All players are verified for this step.</div>
  }
}

DoubleScoreVerificationTableComponent.propTypes = {
  tees: PropTypes.object,
  foursomes: PropTypes.array,
  isMultiRound: PropTypes.bool,
  holeView: PropTypes.string,
  openPopup: PropTypes.func,
  openTeamPopup: PropTypes.func,
  teamscoreVerification: PropTypes.bool,
}

export default DoubleScoreVerificationTableComponent
