import { ActionTypes } from '../actions/delivery_reports'

const {
  SET_EMAIL_DELIVERY_REPORTS,
  SET_TEXT_MESSAGE_DELIVERY_REPORTS,
  SET_EMAIL_FILTER_VALUE,
  SET_TEXT_MESSAGE_FILTER_VALUE,
} = ActionTypes

function deliveryReports(state = {}, action) {
  switch (action.type) {
    case SET_EMAIL_DELIVERY_REPORTS:
      return {
        ...state,
        emails: {
          ...state.emails,
          reports: action.reports,
          currentPage: action.page,
        },
      }
    case SET_TEXT_MESSAGE_DELIVERY_REPORTS:
      return {
        ...state,
        textMessages: {
          ...state.textMessages,
          reports: action.reports,
          currentPage: action.page,
        },
      }
    case SET_EMAIL_FILTER_VALUE:
      return {
        ...state,
        emails: {
          ...state.emails,
          filters: {
            ...state.emails.filters,
            [action.filterType]: action.filterValue,
          },
        },
      }
    case SET_TEXT_MESSAGE_FILTER_VALUE:
      return {
        ...state,
        textMessages: {
          ...state.textMessages,
          filters: {
            ...state.textMessages.filters,
            [action.filterType]: action.filterValue,
          },
        },
      }
    default:
      return state
  }
}

export default deliveryReports
