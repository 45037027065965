import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

class Hover extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { report, reportAction, reportImport } = this.props

    return (
      <div className='overlay'>
        {
          (report.enabled === true || report.enabled === undefined)
            ? <Fragment>
              {
                report.action !== null
                  ? report.action.flat().length > 2
                    ? report.action.map(actionPath =>
                      <div className='report-action'
                           key={ actionPath[1] }
                           onClick={ () => reportAction(actionPath[1], report.id, actionPath[0].toLowerCase()) } >
                        { actionPath[0] }
                      </div>)
                    : <div className='report-action'
                            onClick={ () => reportAction(report.action[1], report.id, report.action[0].toLowerCase()) } >
                      { report.action[0] }
                    </div>
                  : null
              }
              {
                report.access === 'unrestricted' &&
                (
                report.edit_path !== null
                  ? report.edit_path.flat().length > 2
                    ? report.edit_path.map(editPath =>
                      <span key={ editPath[1] }
                            onClick={
                              () => report.needs_import === false
                                ? reportAction(editPath[1], report.id, 'url')
                                : reportImport(report.needs_import, report.id, editPath[0]) } >
                        <div className='report-action'>{ editPath[0] }</div>
                      </span>)
                    : <span onClick={
                              () => report.needs_import === false
                                ? reportAction(report.edit_path[1], report.id, 'url')
                                : reportImport(report.needs_import, report.id, report.edit_path[0]) }>
                        <div className='report-action'>{ report.edit_path[0] }</div>
                      </span>
                  : null
                )
              }
            </Fragment>
            : <span className='disabled-message'>{ report.enabled_tooltip }</span>
        }
      </div>
    )
  }
}

Hover.propTypes = {
  report: PropTypes.object,
  reportPrint: PropTypes.func,
  reportAction: PropTypes.func,
  reportImport: PropTypes.func,
}


Hover.defaultProps = {
  report: {},
}

export default Hover
