import { ActionTypes } from '../actions'
const {
  FETCH_INITIAL_DATA_RECEIVED,
} = ActionTypes

// const DEFAULT_SEASON_DATA = {
//   id: null,
//   name: null,
//   isCurrent: false,
// }

const seasons = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INITIAL_DATA_RECEIVED:
      return {
        ...state,
        ...action.data.seasons,
      }
    default:
      return state
  }
}

export default seasons
