/* eslint react/no-direct-mutation-state: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ReportCenterCreateUpdateList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: this.props.list !== {} ? this.props.list.name || '' : '',
      popup: null, //to be set by textarea ref callback
    }

    this.createUpdate = this.createUpdate.bind(this)
  }

  componentDidMount() {
    $(this.state.popup).on('shown.bs.modal', () => {
      $('.create_list textarea:visible').focus()
    })

    const onKeyPress = function (e) {
      if (e.which === 13) {
        e.preventDefault()
        this.createUpdate()
      }
    }.bind(this)
    $(this.state.popup).find('textarea')
                       .keypress(onKeyPress)
  }

  createUpdate() {
    if (this.props.list.id === undefined) {
      this.props.createList(this.state.name, this.state.popup); this.setState({ name: '' })
    } else {
      this.props.editList(this.props.list.id, this.state.name, this.state.popup)
    }
  }

  render() {
    return (
      <div className='modal fade'
           ref={(_popup) => { this.state.popup = _popup }}
           id={ 'create_edit_list_' + this.props.list.id }
           tabIndex='-1'
           role='dialog'
           aria-labelledby='mySmallModalLabel'>
        <div className='modal-dialog modal-sl' role='document'>
          <div className='modal-content'>
            <div className='create_list'>
              { this.props.list.id === undefined ?
                  <h3>{!window.I18n ? '' : window.I18n.t('download_center.report_create_update.add_new_category')}</h3>
                :
                  <h3>{!window.I18n ? '' : window.I18n.t('download_center.report_create_update.edit_category')}</h3>
              }
              <textarea id='list_name'
                        onChange={ (event) => { this.setState({ name: event.target.value }) }}
                        value={this.state.name}
                        placeholder={!window.I18n ? '' : window.I18n.t('download_center.report_create_update.name')}/>
              <a className='btsp_white_button pull-left'
                 onClick={ () => { $(this.state.popup).modal('hide') }}>
                 {!window.I18n ? '' : window.I18n.t('download_center.report_create_update.cancel')}
              </a>
              <a className='btsp_orange_button pull-right' onClick={this.createUpdate}>{!window.I18n ? '' : window.I18n.t('download_center.report_create_update.save')}</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ReportCenterCreateUpdateList.propTypes = {
  list: PropTypes.object.isRequired,
  createList: PropTypes.func.isRequired,
  editList: PropTypes.func.isRequired,
}

export default ReportCenterCreateUpdateList
