import React from 'react'
import PropTypes from 'prop-types'

import rootReducer from '../reducers'
import { fetchInitialData } from '../actions'

import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import NavigatorContainer from './navigator_container'


const NavigatorRoot = ({
  fetchInitialDataURL,
  context,
  userIsAdmin,
  defaultRoundId,
  showFeatureNotification,
  showNavigatorText,
}) => {
  const state = {
    misc: {
      fetchInitialDataURL,
      context,
      userIsAdmin,
      defaultRoundId,
    },
  }

  // 2 navigators can use a shared store (i.e. on the Customer Center)
  let store = window.glg.navigatorStore
  if (!store) {
    store = configureStore(rootReducer, state)
    window.glg.navigatorStore = store
  }

  // Some non-React components will need to be able to refresh the navigator's permissions
  window.glg.navigatorApi = {
    refreshPermissions: roundId => store.dispatch(fetchInitialData(roundId)),
  }

  return <GlobalProvider store={ store }><NavigatorContainer showFeatureNotification={ showFeatureNotification } showNavigatorText={ showNavigatorText }></NavigatorContainer></GlobalProvider>
}

NavigatorRoot.propTypes = {
  fetchInitialDataURL: PropTypes.string.isRequired,
  context: PropTypes.oneOf([ 'customer', 'event' ]),
  userIsAdmin: PropTypes.bool.isRequired,
  defaultRoundId: PropTypes.string,
  showFeatureNotification: PropTypes.bool,
  showNavigatorText: PropTypes.bool,
}

NavigatorRoot.defaultProps = {
  showNavigatorText: true,
}


export default NavigatorRoot
