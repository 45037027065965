import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import { GlgSelect, GlgIcon, GlgTooltip } from 'SharedComponents'
import { computeHour, computeMinutes } from './event_setup/time_helpers'
import { showErrorNotification } from 'Shared/helpers'
import RadioGroup from './event_setup/radio_group'

const LeftAllignedDiv = styled.div`
  text-align: left
`

const internationalize = (...args) => !window.I18n ? '' : window.I18n.t(...args)
const unique = (array) => [ ...new Set(array) ]
const notNull = (array) => array.filter(e => e)

const computeTeeName = (tees, foursome) => {
  const slots = notNull(foursome.slots)

  const courseNames = notNull(unique(slots.map(s => tees[s.tee_id]?.course_name)))
  if (courseNames.length === 0) {
    return { name: internationalize('assets.application.pairings.course_tee_not_specified'), error: false }
  }

  if (courseNames.length > 1) {
    return { name: internationalize('assets.application.pairings.multiple_courses'), error: internationalize('assets.application.pairings.pairing_group_incorrect') }
  }

  let teeNames = notNull(slots.map(s => tees[s.tee_id]?.short_name))
  if (teeNames.length !== slots.length) {
    return { name: internationalize('assets.application.pairings.course_tee_incomplete'), error: false }
  } else {
    teeNames = unique(teeNames)
  }
  const teeNamesShort = notNull(unique(slots.map(s => tees[s.tee_id]?.initial_name)))
  // const optionalHoles = notNull(unique(slots.map(s => tees[s.tee_id].optional_hole)))

  return { name: `${courseNames[0]} - ${teeNames.length === 1 ? teeNames[0] : teeNamesShort.join(', ')}`, error: false }
}

class EventSetupTeeSheet extends Component {
  constructor(props){
    super(props)
  }

  componentDidUpdate() {
    if (!this.props.content.saveInProgress && this.props.content.doSave) {
      this.props.setData([ 'doSave' ], false)
      this.props.save()
    }
  }

  render() {
    const { title, product, rounds, tees, activeAction, actionState, selectedSlots, dataChanged, saveInProgress, creatingPairings, integration, links } = this.props.content

    if (_.isEmpty(rounds)) {
      return (
        <div className='widget-content-default widget-event-setup-tee-sheet'>
          <div className='title'>
            { title }
          </div>

          <div className='content flex-list-layout'>
            <label className='no-rounds-message'>
              { internationalize('dashboard_plus.event_setup_tee_sheet.no_rounds_message') }
            </label>
          </div>
        </div>
      )
    }

    const allMemberCount = this.props.content.all_member_count
    const setCourseTees = this.props.content.set_course_tees
    const foursomeDataHeaders = this.props.content.foursome_data_headers
    const roundData = this.props.content.rounds_data[rounds?.selected]
    const foursomes = roundData.foursomes
    const orderedFoursomes = Object.values(foursomes).sort((f1, f2) => f1.position - f2.position)
    const foursomeGroupings = _.groupBy(orderedFoursomes.map(f => f.id), id => foursomes[id].hour)
    const activeSlots = 'swap-players swap-pairs swap-foursomes move-pairings user-plus user-minus map-pointer'.includes(activeAction)
    const activeHoles = 'swap-hole'.includes(activeAction)

    /* eslint-disable-next-line */
    const unavailablePlayers = new Set(Object.values(foursomes).map(f => f.slots.filter(s => s).map(s => s.id)).flat())
    const availablePlayers = Object.keys(roundData.players).filter(playerId => !unavailablePlayers.has(playerId))

    const setFoursomesData = (keys, value) => this.props.setData([ 'rounds_data', rounds.selected, 'foursomes' ].concat(keys), value)

    const isSelected = (foursomeId, slotIndex = -1) => {
      if (!activeAction) { return false }
      if (!selectedSlots) { return false }

      switch (activeAction) {
        case 'swap-players':
          return selectedSlots.foursomeId === foursomeId && selectedSlots.slotIndex === slotIndex
        case 'swap-pairs':
          return selectedSlots.foursomeId === foursomeId && (selectedSlots.slotIndex === slotIndex || selectedSlots.slotIndex + 1 === slotIndex)
        case 'swap-foursomes':
        case 'swap-hole':
          return selectedSlots.foursomeId === foursomeId
        case 'move-pairings': {
          const index = foursomes[foursomeId].position

          if ('end' in (selectedSlots || {})) {
            const start = Math.min(selectedSlots.start, selectedSlots.end)
            const end = Math.max(selectedSlots.start, selectedSlots.end)
            return start <= index && index <= end
          } else {
            return selectedSlots?.start === index
          }
        }
        case 'map-pointer': {
          return selectedSlots[`${foursomeId}_${slotIndex}`]
        }
      }
    }

    const saveFoursomes = () => { this.props.setData([ 'rounds_data', rounds.selected, 'foursomes' ], foursomes) }

    const swapPlayers = (slot1, slot2) => {
      const { foursomeId: foursomeId1, slotIndex: slotIndex1 } = slot1
      const { foursomeId: foursomeId2, slotIndex: slotIndex2 } = slot2

      const aux = foursomes[foursomeId1].slots[slotIndex1]
      foursomes[foursomeId1].slots[slotIndex1] = foursomes[foursomeId2].slots[slotIndex2]
      foursomes[foursomeId2].slots[slotIndex2] = aux
    }

    const swapFoursomes = (foursomeId1, foursomeId2) => {
      for (let i = 0; i < Math.min(foursomes[foursomeId1].slots.length, foursomes[foursomeId2].slots.length); ++i) {
        swapPlayers({ foursomeId: foursomeId1, slotIndex: i }, { foursomeId: foursomeId2, slotIndex: i })
      }
    }

    const newFoursomes = this.props.content.newFoursomes || []
    const addNewFoursome = () => {
      const newPosition = orderedFoursomes.map(f => f.hour).filter(f => !f).length

      const foursome = {
        id: newFoursomes.length,
        position: newPosition,
        tee_at: null,
        hour: '',
        hole: '',
        slots: Array(roundData.slot_count).fill(null),
      }
      orderedFoursomes.slice(newPosition).forEach(f => f.position += 1)
      this.props.setData([ 'newFoursomes' ], newFoursomes.concat([ foursome.id ]))
      setFoursomesData([ foursome.id ], foursome)
    }

    const mostFrequentElement = (l) => {
      const frequencies = l.map(x => _.sum(l.map(e => 0 + (x === e))))
      return l[frequencies.indexOf(_.max(frequencies))]
    }

    const swapHoles = (foursomeId1, foursomeId2) => {
      let aux = foursomes[foursomeId1].tee_at
      foursomes[foursomeId1].tee_at = foursomes[foursomeId2].tee_at
      foursomes[foursomeId2].tee_at = aux

      aux = foursomes[foursomeId1].hour
      foursomes[foursomeId1].hour = foursomes[foursomeId2].hour
      foursomes[foursomeId2].hour = aux

      aux = foursomes[foursomeId1].hole
      foursomes[foursomeId1].hole = foursomes[foursomeId2].hole
      foursomes[foursomeId2].hole = aux

      aux = foursomes[foursomeId1].position
      foursomes[foursomeId1].position = foursomes[foursomeId2].position
      foursomes[foursomeId2].position = aux

      const majorityTee = [
        mostFrequentElement(notNull(foursomes[foursomeId1].slots).map(s => s.tee_id)),
        mostFrequentElement(notNull(foursomes[foursomeId2].slots).map(s => s.tee_id)),
      ]

      foursomes[foursomeId1].slots.map(s => s && (s.tee_id = majorityTee[1]))
      foursomes[foursomeId2].slots.map(s => s && (s.tee_id = majorityTee[0]))
    }

    const clickSlot = (foursomeId, slotIndex = -1) => {
      switch (activeAction) {
        case 'swap-players':
          if (!selectedSlots) {
            this.props.setData([ 'selectedSlots' ], { foursomeId, slotIndex })
          } else {
            swapPlayers(selectedSlots, { foursomeId, slotIndex })
            this.props.setData([ 'selectedSlots' ], undefined)
            saveFoursomes()
          }
          break

        case 'swap-pairs':
          if (!selectedSlots) {
            this.props.setData([ 'selectedSlots' ], { foursomeId, slotIndex: slotIndex - (slotIndex % 2)})
          } else {
            slotIndex = slotIndex - (slotIndex % 2)
            swapPlayers(selectedSlots, { foursomeId, slotIndex })
            if (foursomes[selectedSlots.foursomeId].slots.length > selectedSlots.slotIndex + 1 &&
              foursomes[foursomeId].slots.length > slotIndex + 1) {
              selectedSlots.slotIndex += 1
              swapPlayers(selectedSlots, { foursomeId, slotIndex: slotIndex + 1 })
            }
            this.props.setData([ 'selectedSlots' ], undefined)
            saveFoursomes()
          }
          break

        case 'swap-foursomes':
          if (!selectedSlots) {
            this.props.setData([ 'selectedSlots' ], { foursomeId })
          } else {
            swapFoursomes(selectedSlots.foursomeId, foursomeId)
            this.props.setData([ 'selectedSlots' ], undefined)
            saveFoursomes()
          }
          break

        case 'move-pairings':
          if (actionState.operation === 'select') {
            const index = foursomes[foursomeId].position
            const which = !selectedSlots || 'end' in selectedSlots ? 'start' : 'end'
            this.props.setData([ 'selectedSlots' ], { start: selectedSlots?.start, [which]: index })
            which === 'end' && this.props.setData([ 'actionState', 'operation' ], 'move')
          } else if (actionState.operation === 'move' && !isSelected(foursomeId)) {
            const start = Math.min(selectedSlots.start, selectedSlots.end)
            const end = Math.max(selectedSlots.start, selectedSlots.end)
            const index = foursomes[foursomeId].position

            let firstSectionCount = -1
            let foursomesToChange = null
            if (index < start) {
              foursomesToChange = _.range(index + (actionState.position === 'after'), end + 1)
              firstSectionCount = start - index - (actionState.position === 'after')
            } else {
              foursomesToChange = _.range(start, index - (actionState.position === 'before') + 1)
              firstSectionCount = end - start + 1
            }
            foursomesToChange = foursomesToChange.map(idx => orderedFoursomes[idx].id)

            for (let i = 0; i < firstSectionCount; ++i) {
              for (let j = 0; j < foursomesToChange.length - 1; ++j) {
                swapFoursomes(foursomesToChange[j], foursomesToChange[j + 1])
              }
            }

            saveFoursomes()
            this.props.setData([ 'actionState' ], { operation: 'select', position: 'before' })
            this.props.setData([ 'selectedSlots' ], undefined)
          }
          break

        case 'user-plus':
          if (actionState.playerToAddId) {
            const player = roundData.players[actionState.playerToAddId]
            this.props.setData([ 'rounds_data', rounds.selected, 'players', actionState.playerToAddId, 'status' ], 'accepted')

            const existingPlayer = roundData.players[foursomes[foursomeId].slots[slotIndex]?.id]
            if (existingPlayer) {
              this.props.setData([ 'rounds_data', rounds.selected, 'players', existingPlayer.id, 'status' ], null)
            }

            setFoursomesData([ foursomeId, 'slots', slotIndex ], player)
            this.props.setData([ 'actionState', 'playerToAddId' ], availablePlayers?.[0 + (availablePlayers?.[0] === actionState.playerToAddId)] || existingPlayer?.id)
          }
          break

        case 'user-minus': {
          const existingPlayer = roundData.players[foursomes[foursomeId].slots[slotIndex]?.id]
          if (existingPlayer) {
            this.props.setData([ 'rounds_data', rounds.selected, 'players', existingPlayer.id, 'status' ], null)
          }
          setFoursomesData([ foursomeId, 'slots', slotIndex ], null)
          break
        }

        case 'swap-hole': {
          if (!selectedSlots) {
            this.props.setData([ 'selectedSlots' ], { foursomeId })
          } else {
            swapHoles(selectedSlots.foursomeId, foursomeId)
            this.props.setData([ 'selectedSlots' ], undefined)
            saveFoursomes()
          }
          break
        }

        case 'map-pointer':
          if (! foursomes[foursomeId].slots[slotIndex]) { return }
          this.props.setData([ 'selectedSlots', `${foursomeId}_${slotIndex}` ], !((selectedSlots || {})[`${foursomeId}_${slotIndex}`]))
          break

      }
    }

    const playerTooltip = (player) => {
      const tee = tees[player.tee_id]
      const side = tee?.optional_hole === 'Front 9' ? 'front9_' : tee?.optional_hole === 'Back 9' ? 'back9_' : ''

      return (
        <LeftAllignedDiv>
          <label>{ player.name }</label>
          { tee &&
              <>
                <br/>
                <div>{ tee.name }</div>
                <div>{ `Rating: ${tee[`${side}rating`]}` }</div>
                <div>{ `SLOPE®: ${tee[`${side}slope`]}` }</div>
              </>
          }
        </LeftAllignedDiv>
      )
    }

    return (
      <div className='widget-content-default widget-event-setup-tee-sheet'>
        <div className='title'>
          { title }

          {
            dataChanged &&
              <a
                onClick={saveInProgress ? undefined : () => {
                  this.props.setData([ 'activeAction' ], undefined)
                  this.props.setData([ 'doSave' ], true)
                }}
                className='btn btn-primary widget-white-title-button'>
                { saveInProgress ? internationalize('dashboard_plus.widgets.saving') : links.save.name }
              </a>
          }
        </div>

        <div className='content flex-list-layout'>
          {
            rounds &&
              <div className='select_round_container'>
                <label>{ internationalize('pages.edit.tee_sheets.select_round') }</label>

                <div className='indented-container'>
                  <GlgSelect
                    items={ rounds.options }
                    value={ rounds.selected }
                    onChange={ round => {
                      this.props.setData([ 'rounds', 'selected' ], round, { updateDataChanged: false })
                      this.props.setData([ 'activeAction' ], undefined, { updateDataChanged: false })
                      this.props.setData([ 'actionState' ], undefined, { updateDataChanged: false })
                      this.props.setData([ 'selectedSlots' ], undefined, { updateDataChanged: false })

                      const roundData = this.props.content.rounds_data[round]
                      if ('integration_events' in roundData && roundData.integration_events.events.map(e => e['COMPETITIONID']).indexOf(roundData.integration_event_id) !== -1) {
                        this.props.setData([ 'rounds_data', round, 'integration_events', 'selected' ], roundData.integration_event_id, { updateDataChanged: false })
                        this.props.setData([ 'integration', 'import_from_integration', 'selected' ], true, { updateDataChanged: false })
                      } else {
                        this.props.setData([ 'integration', 'import_from_integration', 'selected' ], false, { updateDataChanged: false })
                      }
                    }}
                    usePortal={ true }
                    menuShouldBlockScroll={ true }
                  />

                  {
                    (() => { // [re]create pairings button
                      if (roundData.has_scores || roundData.allow_players_to_choose_tee_time) { return }

                      const importCondition = 'integration_events' in roundData && integration.import_from_integration.selected
                      const isRoundLinkedToEvent = 'integration_events' in roundData && roundData.integration_events.selected === roundData.integration_event_id

                      const disabledCondition = creatingPairings || !roundData.started_planning || !roundData.schedule.automatic_enabled || allMemberCount === 0
                      return (
                        <>
                          {
                            'integration_events' in roundData &&
                              <RadioGroup
                                className='import_from_integration'
                                {...integration['import_from_integration']}
                                setOption={v => this.props.setData([ 'integration', 'import_from_integration', 'selected' ], v === 'true', { updateDataChanged: false })}
                              />
                          }

                          <div className='integration_event_and_import_button_container'>
                            {
                              importCondition &&
                                <GlgSelect
                                  items={ roundData.integration_events.events.map(e => ({ label: e['COMPETITIONNAME'], value: e['COMPETITIONID']})) }
                                  value={ roundData.integration_events.selected }
                                  onChange={ eventid => {
                                    this.props.setData([ 'rounds_data', rounds.selected, 'integration_events', 'selected' ], eventid, { updateDataChanged: false })
                                  }}
                                  usePortal={ true }
                                  menuShouldBlockScroll={ true }
                                />
                            }

                            {
                              importCondition ?
                                <>
                                  <div className={`${creatingPairings ? 'not-allowed-button-wrapper' : ''}`}>
                                    <a
                                      className={`${creatingPairings ? 'disabled' : ''} btn btn-primary widget-white-content-button`}
                                      onClick={isRoundLinkedToEvent ? this.props.importPairings : this.props.linkToEvent}>
                                      {
                                        isRoundLinkedToEvent ?
                                          internationalize('dashboard_plus.event_setup_tee_sheet.import_pairings')
                                          :
                                          internationalize('dashboard_plus.event_setup_tee_sheet.link_to_event')
                                      }
                                    </a>
                                  </div>
                                </>
                                :
                                <div className={`${disabledCondition ? 'not-allowed-button-wrapper' : ''}`}>
                                  <a
                                    className={`btn btn-primary widget-white-content-button ${ disabledCondition ? 'disabled' : ''}`}
                                    onClick={this.props.createPairings}>
                                    { internationalize(`dashboard_plus.event_setup_tee_sheet.${(roundData.has_pairings && !_.isEmpty(foursomes)) ? 're' : ''}create_pairings`) }
                                  </a>
                                </div>
                            }
                          </div>

                          {
                            importCondition &&
                              <>
                                {
                                  isRoundLinkedToEvent ?
                                    <div className='add-margin-top-5 daily_sync_container'>
                                      <div className='error'>
                                        {
                                          internationalize('bootstrap_nav.partials.ce_round_integrations.daily_sync_is_on_or_off', {
                                            on_or_off: (roundData.daily_sync ?
                                              internationalize('bootstrap_nav.partials.ce_round_integrations.status_on') :
                                              internationalize('bootstrap_nav.partials.ce_round_integrations.status_off')),
                                          })
                                        }
                                      </div>
                                      { /* See the below file for push vs sync */ }
                                      { /* app/views/shared/bootstrap_nav/partials/_ce_round_integrations.html.haml:142 */ }
                                      <a href='javascript:void(0)' onClick={this.props.toggleDailySync}>
                                        <i className='fa fa-power-off' />
                                        { internationalize('buttons.change') }
                                      </a>
                                    </div>
                                    :
                                    <div className='add-margin-top-5 error'>{internationalize('dashboard_plus.event_setup_tee_sheet.round_not_connected_message', { source: this.props.content.integration_name })}</div>
                                }
                              </>
                          }
                          {
                            allMemberCount === 0 &&
                              <div className='add-margin-top-5 error'>{internationalize('rounds.bootstrap_partials.settings_panel.no_golfers_added', { product: internationalize(`${product}_name`) })}</div>
                          }
                          {
                            !roundData.schedule?.automatic_enabled &&
                              <div className='add-margin-top-5 error'>{roundData.schedule?.automatic_enabled_message}</div>
                          }
                          {
                            !roundData.started_planning &&
                              <div className='add-margin-top-5 error'>{internationalize('dashboard_plus.event_setup_tee_sheet.start_planning_message')}</div>
                          }
                        </>
                      )
                    })()
                  }
                </div>
              </div>
          }

          {
            (roundData.allow_players_to_choose_tee_time || (roundData.has_pairings && (!_.isEmpty(foursomes)))) &&
              <div className='pairings-table-container'>
                <div className='edit_pairings_table_wrapper'>
                  <table className='edit_pairings_table table'>
                    <thead>
                      <tr>
                        <th>{ internationalize('pairings.edit.additional_information') }</th>

                        {
                          Object.entries(foursomeDataHeaders).map(([ key, header ]) =>
                            <th key={key} className='foursome_info_header'>
                              <GlgIcon icon={header.icon} />
                              <label>{ header.label }</label>
                            </th>
                          )
                        }
                      </tr>
                    </thead>

                    <tbody>
                      {
                        Object.entries(foursomeGroupings).map(([ groupTime, foursomeIds ]) => {

                          return (
                            <>
                              <tr className='foursomes-group-time'>
                                <td colSpan={1 + Object.keys(foursomeDataHeaders).length}>
                                  <label>{ groupTime }</label>
                                </td>
                              </tr>

                              {
                                foursomeIds.map(foursomeId => {
                                  const foursome = foursomes[foursomeId]
                                  const { name: teeName, error: error } = computeTeeName(tees, foursome)

                                  const holeAttributes = {
                                    className: `${activeHoles ? 'active' : ''} ${activeHoles && isSelected(foursomeId) ? 'selected' : ''}`,
                                    onClick: () => clickSlot(foursomeId),
                                  }

                                  return (
                                    <tr className={`foursome ${error ? 'error' : ''}`} key={`foursome_${foursomeId}`}>
                                      <td className={`slots-list-container ${activeSlots && isSelected(foursomeId) ? 'selected' : ''} ${activeAction ? 'active-action' : ''}`}>
                                        <>
                                          { activeAction === 'move-pairings' && actionState.operation === 'move' && actionState.position === 'before' && !isSelected(foursomeId) && <i className='fa fa-hand-o-right fa-lg' /> }
                                          <div className='slots-list'>
                                            {
                                              foursome.slots.map((slot, index) => {
                                                const slotDisplay = (<div
                                                  key={`slot_${index}`}
                                                  className={`slot ${activeSlots ? 'active' : ''} ${ activeSlots && isSelected(foursomeId, index) ? 'selected' : ''}`}
                                                  onClick={() => activeSlots && clickSlot(foursomeId, index)}>
                                                  { slot?.name }
                                                </div>)

                                                return slot ? <GlgTooltip key={`slot_${index}_tooltip`} tooltip={playerTooltip(slot)}>{ slotDisplay }</GlgTooltip> : slotDisplay
                                              })
                                            }
                                          </div>
                                          { activeAction === 'move-pairings' && actionState.operation === 'move' && actionState.position === 'after' && !isSelected(foursomeId) && <i className='fa fa-hand-o-right fa-lg' /> }
                                        </>
                                      </td>

                                      <td {...holeAttributes}>
                                        {
                                          activeAction !== 'set-tee-time' ?
                                            <label>{ foursome.tee_at}</label>
                                            :
                                            (() => {
                                              const formatTime = (hour, minutes, amPm) => {
                                                const timeRe = /[0-9]{0,2}/
                                                return `${timeRe.exec(hour || '')}:${timeRe.exec(minutes || '')} ${/AM|PM/.exec(amPm || 'AM')}`
                                              }
                                              const setFormattedTime = (hour, minutes, amPm) => setFoursomesData([ foursome.id, 'tee_at' ], formatTime(hour, minutes, amPm))

                                              const [ time, amPm ] = (foursome.tee_at || '').split(' ')
                                              const [ hour, minutes ] = time.split(':')

                                              return (
                                                <div className='tee-time-edit-container'>
                                                  <input
                                                    value={hour}
                                                    onChange={ e => setFormattedTime(e.target.value, minutes, amPm) }
                                                    onBlur={ e => {
                                                      const [ hour, amPm ] = computeHour(e.target.value)
                                                      setFormattedTime(hour || '00', minutes || '00', amPm || 'AM')
                                                    }}
                                                  />
                                                  <label> : </label>
                                                  <input
                                                    value={minutes}
                                                    onChange={ e => setFormattedTime(hour, e.target.value, amPm) }
                                                    onBlur={ e => {
                                                      const minutes = computeMinutes(e.target.value)
                                                      setFormattedTime(hour || '00', minutes || '00', amPm || 'AM')
                                                    }}
                                                  />
                                                  <a
                                                    className='am_pm'
                                                    href='javascript:void(0)'
                                                    onClick={ () => setFormattedTime(hour || '00', minutes || '00', (amPm || 'AM') === 'AM' ? 'PM' : 'AM')}>
                                                    { amPm || 'AM' }
                                                  </a>
                                                </div>
                                              )
                                            })()
                                        }
                                      </td>

                                      <td {...holeAttributes}>
                                        <label>{ teeName }</label>
                                      </td>

                                      <td {...holeAttributes} className={`${(foursome.hole && activeAction !== 'set-tee-time') ? 'hole' : ''} ${holeAttributes.className}`}>
                                        {
                                          activeAction !== 'set-tee-time' ?
                                            <div>{ foursome.hole || '' }</div>
                                            :
                                            (() => {
                                              const setHole = (number, letter) => {
                                                const holeNumber = parseInt(/[0-9]{0,2}/.exec(number) || 0)
                                                const hole = holeNumber === 0 ? '' : `${Math.min(holeNumber, 18)}`
                                                const holeLetter = (/[a-zA-Z]/.exec(letter) || [ '' ])[0].toUpperCase()
                                                setFoursomesData([ foursome.id, 'hole' ], `${hole}${holeLetter}`)

                                                return [ hole, holeLetter ]
                                              }

                                              // eslint-disable-next-line no-unused-vars
                                              const [ _, holeNumber, holeLetter ] = /([0-9]{0,2})([a-zA-Z]?)/.exec(foursome.hole || '')

                                              if (!foursome.refs) {
                                                foursome.refs = {
                                                  holeNumber: React.createRef(),
                                                  holeLetter: React.createRef(),
                                                }
                                              }
                                              const focusNext = (type) => {
                                                if (foursome.position + 1 < orderedFoursomes.length) {
                                                  orderedFoursomes[foursome.position + 1].refs[type].current.focus()
                                                }
                                              }
                                              const focusPrevious = (type) => {
                                                if (foursome.position - 1 > 0) {
                                                  orderedFoursomes[foursome.position - 1].refs[type].current.focus()
                                                }
                                              }
                                              const tabFocusHandle = type => e => {
                                                if (e.key === 'Tab') {
                                                  e.preventDefault()
                                                  if (!event.shiftKey) {
                                                    focusNext(type)
                                                  } else {
                                                    focusPrevious(type)
                                                  }
                                                }
                                              }

                                              return (
                                                <div className='hole-edit-container'>
                                                  <input
                                                    ref={foursome.refs.holeNumber}
                                                    value={holeNumber}
                                                    onFocus={ e => e.target.select() }
                                                    onChange={ e => {
                                                      const [ newHoleNumber ] = setHole(e.target.value, holeLetter)
                                                      if (newHoleNumber.length === 2) {
                                                        focusNext('holeNumber')
                                                      }
                                                    }}
                                                    onKeyDown={ tabFocusHandle('holeNumber') }
                                                  />
                                                  <input
                                                    ref={foursome.refs.holeLetter}
                                                    value={holeLetter}
                                                    placeholder='A'
                                                    onFocus={ e => e.target.select() }
                                                    onChange={ e => {
                                                      setHole(holeNumber, e.target.value)
                                                      focusNext('holeLetter')
                                                    }}
                                                    onKeyDown={ tabFocusHandle('holeLetter') }
                                                  />
                                                </div>
                                              )
                                            })()
                                        }
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>

                <div className='actions'>
                  {
                    roundData.actions.map(action =>
                      <a
                        className={`action ${activeAction === action.icon.split(' ')[0] ? 'active' : ''}`}
                        key={action.link} href='javascript:void(0)'
                        onClick={() => {
                          const actionType = action.icon.split(' ')[0]

                          if (actionType === 'new-row') {
                            addNewFoursome()
                            return
                          }

                          this.props.setData([ 'activeAction' ], activeAction === actionType ? undefined : actionType)
                          this.props.setData([ 'selectedSlots' ], undefined)
                          this.props.setData([ 'actionState' ], undefined)
                          switch (actionType) {
                            case 'move-pairings':
                              this.props.setData([ 'actionState' ], { operation: 'select', position: 'before' })
                              break
                            case 'user-plus':
                              this.props.setData([ 'actionState' ], { playerToAddId: availablePlayers?.[0] })
                              break

                            case 'set-tee-time':
                              if (activeAction) {
                                // recompute the groupings
                                orderedFoursomes.forEach(f => {
                                  const [ hourMinutes, amPm ] = (f.tee_at || '').split(' ')
                                  if (hourMinutes) {
                                    const [ hour ] = hourMinutes.split(':')
                                    f.hour = `${('0' + hour).slice(-2)}:00 ${amPm}`
                                  }
                                })
                              }
                              break
                          }
                        }}>
                        <GlgIcon icon={action.icon} />
                        <div className='action-text'>{ action.title }</div>
                      </a>
                    )
                  }
                </div>

                {
                  'move-pairings user-plus map-pointer'.includes(activeAction) &&
                  <div className='action-settings'>
                    {
                      activeAction === 'move-pairings' &&
                        <div className='move-pairings-settings'>
                          <div>
                            <GlgSelect
                              items={[ {
                                label: internationalize('dashboard_plus.widgets.select'),
                                  value: 'select',
                              }, {
                                label: internationalize('dashboard_plus.widgets.move'),
                                value: 'move',
                              } ]}
                              value={ actionState.operation }
                              onChange={ op => { this.props.setData([ 'actionState', 'operation' ], op) }}
                              disabled={ !('end' in (selectedSlots || {})) }
                              usePortal={ true }
                              menuShouldBlockScroll={ true }
                            />

                            <span>{internationalize(`dashboard_plus.event_setup_tee_sheet.pairing_groups${actionState.operation === 'select' ? '_to_move' : ''}`)}</span>

                            <GlgSelect
                              items={[ {
                                label: internationalize('pairings.move_foursomes.before'),
                                  value: 'before',
                              }, {
                                label: internationalize('pairings.move_foursomes.after'),
                                value: 'after',
                              } ]}
                              value={ actionState.position }
                              onChange={ pos => { this.props.setData([ 'actionState', 'position' ], pos) }}
                              usePortal={ true }
                              menuShouldBlockScroll={ true }
                            />

                            <span>{internationalize('dashboard_plus.event_setup_tee_sheet.a_pairing_group')}</span>
                          </div>
                          <span className='info'>
                            {
                              actionState.operation === 'select' ?
                                internationalize('dashboard_plus.event_setup_tee_sheet.select_range_to_move')
                                :
                                internationalize('dashboard_plus.event_setup_tee_sheet.select_pairing_group')
                            }
                          </span>
                        </div>
                    }

                    {
                      activeAction === 'user-plus' &&
                        <div className='user-plus-settings'>
                          <label>{ internationalize('helpers.members.player') }</label>
                          <GlgSelect
                            items={
                              availablePlayers.map(playerId => ({
                                label: roundData.players[playerId].name,
                                value: playerId,
                              }))
                            }
                            value={ actionState.playerToAddId }
                            onChange={ playerId => {
                              this.props.setData([ 'actionState', 'playerToAddId' ], playerId)
                            }}
                            placeholder={ internationalize('pairings.add_players.select_player') }
                            noPreFill={ true }
                            disabled={ availablePlayers.length === 0 }
                            usePortal={ true }
                            menuShouldBlockScroll={ true }
                          />
                        </div>
                    }

                    {
                      activeAction === 'set-tee-time' &&
                        <div className='set-tee-time-settings'>
                        </div>
                    }

                    {
                      activeAction === 'map-pointer' &&
                        <div className='set-course-tee-settings'>
                          <div className='select-tee-hole'>
                            <GlgSelect
                              items={ Object.values(setCourseTees.tee_options) }
                              placeholder={internationalize('dashboard_plus.widgets.select_a_tee')}
                              value={ setCourseTees.selected_tee }
                              noPreFill={ true }
                              usePortal={ true }
                              menuShouldBlockScroll={ true }
                              onChange={ tee => {
                                this.props.setData([ 'set_course_tees', 'selected_tee' ], tee)
                                this.props.setData([ 'set_course_tees', 'selected_hole' ],
                                  Object.values(setCourseTees.tee_options[tee].holes)[0]?.value || -1
                                )
                              }} />

                            {
                              ! $.isEmptyObject(setCourseTees.tee_options[setCourseTees.selected_tee]?.holes) &&
                                <GlgSelect
                                  items={ Object.values(setCourseTees.tee_options[setCourseTees.selected_tee].holes) }
                                  value={ setCourseTees.selected_hole }
                                  noPreFill={ true }
                                  usePortal={ true }
                                  menuShouldBlockScroll={ true }
                                  onChange={ hole => {
                                    this.props.setData([ 'set_course_tees', 'selected_hole' ], hole)
                                  }} />
                            }
                          </div>

                          <a
                            className='btn btn-primary widget-white-content-button'
                            onClick={() => {
                              if (! setCourseTees.selected_tee) {
                                showErrorNotification(internationalize('dashboard_plus.widgets.select_a_tee'))
                                return
                              }

                              const activeSlots = Object.keys(selectedSlots || {}).filter(s => selectedSlots[s])
                              const holes = setCourseTees.tee_options[setCourseTees.selected_tee]?.holes

                              activeSlots.forEach(s => {
                                const [ foursomeId, slotIndex ] = s.split('_')

                                if (setCourseTees.selected_tee.startsWith('c_')) { // apply default tee by gender
                                  setFoursomesData([ foursomeId, 'slots', slotIndex, 'tee_id' ],
                                    holes[setCourseTees.selected_hole || 'all'].default_tee_by_gender[foursomes[foursomeId].slots[slotIndex].gender || setCourseTees.default_gender]
                                  )
                                } else {
                                  if (! setCourseTees.selected_hole) {
                                    setFoursomesData([ foursomeId, 'slots', slotIndex, 'tee_id' ], setCourseTees.selected_tee)
                                  } else {
                                    setFoursomesData([ foursomeId, 'slots', slotIndex, 'tee_id' ], holes[setCourseTees.selected_hole].virtual_id)
                                  }
                                }
                              })
                              this.props.setData([ 'selectedSlots' ], undefined)
                            }}>
                            { internationalize('pairings.button_apply') }
                          </a>
                        </div>
                    }
                  </div>
                }
              </div>
          }
        </div>
      </div>
    )
  }
}

EventSetupTeeSheet.propTypes = {
  content: PropTypes.object,
  setData: PropTypes.func,
  save: PropTypes.func,
  createPairings: PropTypes.func,
  importPairings: PropTypes.func,
  linkToEvent: PropTypes.func,
  toggleDailySync: PropTypes.func,
}

EventSetupTeeSheet.defaultProps = {
  content: {
    title: 'PAIRINGS',
    data: {},
  },
}

export default EventSetupTeeSheet

