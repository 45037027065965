import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Row } from 'react-bootstrap'
import {
  GlgLoadingSpinner, 
  GlgTable,
  GlgTableCol, 
} from 'SharedComponents'
import MembershipProgramsTableRow from './membership_program_table_row_component'

class MembershipProgramsTableComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let table
    if (!this.props.dataIsLoading) {
      const membershipPrograms = Object.values(this.props.memberships)
      const rowExtraProps = {
        hasRenewals: this.props.hasRenewals,
      }
      if (this.props.hasRenewals) {
        table = <GlgTable items={ membershipPrograms } rowComponent={ MembershipProgramsTableRow } rowExtraProps={ rowExtraProps }><GlgTableCol width={ '50%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.registration.membership.name') }></GlgTableCol><GlgTableCol width={ '25%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.registration.membership.join_date') }></GlgTableCol><GlgTableCol width={ '25%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.registration.membership.renewal_date') }></GlgTableCol></GlgTable>
      } else {
        table = <GlgTable items={ membershipPrograms } rowComponent={ MembershipProgramsTableRow } rowExtraProps={ rowExtraProps }><GlgTableCol width={ '50%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.registration.membership.name') }></GlgTableCol><GlgTableCol width={ '50%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.registration.membership.join_date') }></GlgTableCol></GlgTable>
      }
    }
    
    let membershipTitle
    if (Object.keys(this.props.memberships).length > 0) {
      membershipTitle = <Row className="title"><span>{ !window.I18n ? '' : window.I18n.t('player_activity.registration.membership.memberships') }</span>{this.props.membershipPortalLink && <a href={ this.props.membershipPortalLink } target={ this.props.target } className="edit-btn"><i className="fa fa-edit"></i>            { ' ' + !window.I18n ? '' : window.I18n.t('player_activity.registration.membership.edit_memberships') }</a>}</Row>
    }
    
    return <div className="membership-programs">{ membershipTitle }<GlgLoadingSpinner show={ this.props.dataIsLoading }></GlgLoadingSpinner>{ table }</div>
  }
}

MembershipProgramsTableComponent.propTypes = {
  memberships: PropTypes.object.isRequired,
  membershipPortalLink: PropTypes.string.isRequired,
  dataIsLoading: PropTypes.bool,
  hasRenewals: PropTypes.bool,
  target: PropTypes.string,
}

export default MembershipProgramsTableComponent
