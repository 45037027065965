import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FormControl, FormGroup, HelpBlock } from 'react-bootstrap'
import { Row } from 'react-flexbox-grid'

import PopupTypes from '../../popup_types'
import {
  GlgPopupForm,
  GlgFormInput,
  GlgColorPicker,
  GlgCheckbox,
  GlgPopup,
} from 'SharedComponents'

class CategoryPopup extends Component {
  constructor(props) {
    super(props)
    this.onClose = props.onClose.bind(this, PopupTypes.CATEGORY_POPUP)
    this.onUpdate = props.onUpdate.bind(this)
    this.onCreate = props.onCreate.bind(this)
    this.onSave = this.onSave.bind(this)
    this.onNameChange = this.onNameChange.bind(this)
    this.state = {
      name: this.props.data.name || '',
      typedAny: false,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.show !== nextProps.show ||
           this.state !== nextState
  }

  onSave(data) {
    const formattedData = {
      id: this.props.data.id,
      name: data.list.name,
      color: data.list.color,
      isFree: data.list.is_free === 'on',
    }
    if (this.props.data.operation === 'add') {
      this.onCreate(formattedData)
    } else {
      this.onUpdate(formattedData)
    }
  }

  // For when a new category popup is opened
  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      if (prevProps.data.name !== this.props.data.name) {
        // Edit
        this.setState({
          name: this.props.data.name || '',
          typedAny: !!this.props.data.name,
        })
      } else {
        this.setState({
          name: '',
          typedAny: false,
        })
      }
    }
  }

  getColor() {
    return this.props.data.color || '#333333'
  }

  getIsFree() {
    return this.props.data.isFree || false
  }

  isNameReadOnly() {
    return !!this.props.data.specialStatus
  }

  onNameChange(e) {
    this.setState({
      name: e.target.value,
      typedAny: true,
    })
  }

  validateName() {
    if (this.state.name.length > 0) {
      return null
    } else {
      return 'error'
    }
  }

  getNameValidationState() {
    if (!this.state.typedAny) {
      return null
    } else {
      return this.validateName()
    }
  }

  render() {
    return <GlgPopup title={ !window.I18n ? '' : window.I18n.t('customer_center_menu.components.popups.category_popup.title', { popup_title: `${ _.capitalize(this.props.data.operation) }` }) } saveButtonText={ !window.I18n ? '' : _.capitalize(window.I18n.t('buttons.save')) } saveButtonEnabled={ this.validateName() === null } closeButtonText={ !window.I18n ? '' : _.capitalize(window.I18n.t('buttons.close')) } show={ this.props.show } onClose={ this.onClose } onSave={ this.onSave }><Row className="btsp_notices add-margin-bottom-10">{this.isNameReadOnly()
            ? (!window.I18n ? '' : window.I18n.t('customer_center_menu.components.popups.category_popup.this_is_a_default_category'))
            : (!window.I18n ? '' : window.I18n.t('customer_center_menu.components.popups.category_popup.please_complete_the_name_and_optionally_select_a_color'))
          }</Row><GlgPopupForm><GlgFormInput label={!window.I18n ? '' : window.I18n.t('customer_center_menu.components.popups.category_popup.name')}><FormGroup validationState={ this.getNameValidationState() }><FormControl name="list[name]" type="text" value={ this.state.name } onChange={ this.onNameChange } readOnly={ this.isNameReadOnly() } autoFocus={ !this.isNameReadOnly() }></FormControl><HelpBlock>{this.getNameValidationState() !== null &&
                  (!window.I18n ? '' : window.I18n.t('customer_center_menu.components.popups.category_popup.category_name_must_have_at_least_one_chr'))
                }</HelpBlock></FormGroup></GlgFormInput><GlgFormInput label={!window.I18n ? '' : window.I18n.t('customer_center_menu.components.popups.category_popup.color')}><GlgColorPicker name="list[color]" initialColor={ this.getColor() }></GlgColorPicker></GlgFormInput>{this.props.userIsAdmin && <GlgFormInput label={!window.I18n ? '' : window.I18n.t('customer_center_menu.components.popups.category_popup.bypass_all_billing')}><GlgCheckbox name="list[is_free]" defaultChecked={ this.getIsFree() }></GlgCheckbox></GlgFormInput>}</GlgPopupForm></GlgPopup>
  }
}

CategoryPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  data: PropTypes.object,
  userIsAdmin: PropTypes.bool.isRequired,
}

CategoryPopup.defaultProps = {
  data: {
    id: '',
    name: '',
    color: '#333333',
    isFree: false,
  },
}

export default CategoryPopup
