import { connect } from 'react-redux'
import TriggersComponent from '../components/triggers_component'

import {
  toggleTrigger,
  setTriggerDays,
  setTriggerPercentage,
  setTriggerEvents,
  toggleTriggerPauseEvaluation,
  setTriggerPauseEvaluationDays,
} from '../actions/setup'

const mapStateToProps = (state) => {
  
  return {
    triggers: state.triggers,
    textMessageChannelChecked: state.channels.textMessage.checked,
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleTrigger: (trigger) => () => { dispatch(toggleTrigger(trigger)) },
  setTriggerDays: (trigger, days) => { dispatch(setTriggerDays(trigger, days)) },
  setTriggerPercentage: (trigger, percentage) => { dispatch(setTriggerPercentage(trigger, percentage)) },
  setTriggerEvents: (trigger, events = null) => (selectedEvents) => { dispatch(setTriggerEvents(trigger, events || selectedEvents)) },
  toggleTriggerPauseEvaluation: (trigger) => () => { dispatch(toggleTriggerPauseEvaluation(trigger)) },
  setTriggerPauseEvaluationDays: (trigger, days) => { dispatch(setTriggerPauseEvaluationDays(trigger, days)) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TriggersComponent)
