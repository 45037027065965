// import _ from 'lodash'

import { ActionTypes } from '../actions'

const {
  FETCH_INITIAL_DATA_RECEIVED,
} = ActionTypes

/*
const DEFAULT_ROUND_DATA = {
  id: '',
  name: '',
  pairingId: '',
}
*/

const rounds = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INITIAL_DATA_RECEIVED:
      return {
        ...state,
        ...action.data.rounds,
      }
    default:
      return state
  }
}

export default rounds
