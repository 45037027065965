import {connect} from 'react-redux'
import LastPickArea from '../../components/manager_side/picking_area/last_pick_area'
import {undoLastPick} from '../../actions/manager_side_index'

const mapStateToProps = (state) => ({
  lastPick: state.draftManagerSide.lastPick,
  saveInProgress: state.draftManagerSide.saveInProgress,
})

const mapDispatchToProps = (dispatch) => ({
  undoLastPick: () => dispatch(undoLastPick()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LastPickArea)
