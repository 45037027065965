import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'

const saveBarComponent = ({submit, refreshLeaderboard, showMessage, lastDeviceUpdate}) => {
  return (
    <div className='save_bar'>
      <Col xs={12} sm={12} md={12} lg={12} className='no-padding'>
        <Col xs={4} sm={4} md={4} lg={4}>
          <a className='btsp_gray_button' onClick={refreshLeaderboard}>{ window.I18n.t('course_leaderboards.refresh_leaderboard') }</a>
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} className='text-center'>
          <div className='add-margin-top-20'>
            {showMessage && <div className='bold-note notice-message'>{ window.I18n.t('course_leaderboards.not_published_changes') }</div>}
            {lastDeviceUpdate && <div className='bold-note large-font-note notice-message'>{ window.I18n.t('course_leaderboards.last_update_on') }{lastDeviceUpdate}</div>}
          </div>
        </Col>
        <Col xs={4} sm={4} md={4} lg={4}>
          <a className='btsp_orange_button' onClick={submit}>{ window.I18n.t('course_leaderboards.distribute_to_device') }</a>
        </Col>
      </Col>
    </div>
  )
}

saveBarComponent.propTypes = {
  submit: PropTypes.func,
  refreshLeaderboard: PropTypes.func,
  showMessage: PropTypes.bool,
  lastDeviceUpdate: PropTypes.string,
}

export default saveBarComponent
