import { callAPI, getAuthenticityToken } from 'Shared/helpers'

export const ActionTypes = {
  FETCH_TRANSACTIONS_HISTORY_DATA_REQUEST: 'FETCH_TRANSACTIONS_HISTORY_DATA_REQUEST',
  FETCH_TRANSACTIONS_HISTORY_DATA_RECEIVED: 'FETCH_TRANSACTIONS_HISTORY_DATA_RECEIVED',
  FETCH_PARTICIPATION_HISTORY_DATA_REQUEST: 'FETCH_PARTICIPATION_HISTORY_DATA_REQUEST',
  FETCH_PARTICIPATION_HISTORY_DATA_RECEIVED: 'FETCH_PARTICIPATION_HISTORY_DATA_RECEIVED',
  FETCH_MEMBERSHIP_PROGRAMS_DATA_REQUEST: 'FETCH_MEMBERSHIP_PROGRAMS_DATA_REQUEST',
  FETCH_MEMBERSHIP_PROGRAMS_DATA_RECEIVED: 'FETCH_MEMBERSHIP_PROGRAMS_DATA_RECEIVED',
  FETCH_MONEY_EARNED_INFORMATION_DATA_REQUEST: 'FETCH_MONEY_EARNED_INFORMATION_DATA_REQUEST',
  FETCH_MONEY_EARNED_INFORMATION_DATA_RECEIVED: 'FETCH_MONEY_EARNED_INFORMATION_DATA_RECEIVED',
  TOGGLE_MONEY_EARNED_INFORMATION_POPUP: 'TOGGLE_MONEY_EARNED_INFORMATION_POPUP',
  DELETE_PAYMENT_METHOD_REQUEST: 'DELETE_PAYMENT_METHOD_REQUEST',
  DELETE_PAYMENT_METHOD_RESPONSE: 'DELETE_PAYMENT_METHOD_RESPONSE',
  DEFAULT_PAYMENT_METHOD_REQUEST: 'DEFAULT_PAYMENT_METHOD_REQUEST',
  DEFAULT_PAYMENT_METHOD_RESPONSE: 'DEFAULT_PAYMENT_METHOD_RESPONSE',
  CHANGE_TRANSACTIONS_FILTER: 'CHANGE_TRANSACTIONS_FILTER',
  CHANGE_PARTICIPATION_HISTORY_FILTER: 'CHANGE_PARTICIPATION_HISTORY_FILTER',
  DELETE_CHECK_IN_REQUEST: 'DELETE_CHECK_IN_REQUEST',
  DELETE_CHECK_IN_RESPONSE: 'DELETE_CHECK_IN_RESPONSE',
  TOGGLE_LEADERBOARD_POPUP: 'TOGGLE_LEADERBOARD_POPUP',
  FETCH_LEADERBOARDS_DATA_REQUEST: 'FETCH_LEADERBOARDS_DATA_REQUEST',
  FETCH_LEADERBOARDS_DATA_RECEIVED: 'FETCH_LEADERBOARDS_DATA_RECEIVED',
  VIEW_CHANGE_OPTION: 'VIEW_CHANGE_OPTION',
  PAGE_CHANGED: 'PAGE_CHANGED',
}

const fetchTransactionsHistoryDataRequest = () => ({
  type: ActionTypes.FETCH_TRANSACTIONS_HISTORY_DATA_REQUEST,
})

const fetchTransactionsHistoryDataReceived = (data) => ({
  type: ActionTypes.FETCH_TRANSACTIONS_HISTORY_DATA_RECEIVED,
  data,
})

const fetchParticipationHistoryDataRequest = () => ({
  type: ActionTypes.FETCH_PARTICIPATION_HISTORY_DATA_REQUEST,
})

const fetchParticipationHistoryDataReceived = (data) => ({
  type: ActionTypes.FETCH_PARTICIPATION_HISTORY_DATA_RECEIVED,
  data,
})

const fetchMembershipProgramsDataRequest = () => ({
  type: ActionTypes.FETCH_MEMBERSHIP_PROGRAMS_DATA_REQUEST,
})

const fetchMembershipProgramsDataReceived = (data) => ({
  type: ActionTypes.FETCH_MEMBERSHIP_PROGRAMS_DATA_RECEIVED,
  data,
})

const defaultPaymentMethodRequest = () => ({
  type: ActionTypes.DEFAULT_PAYMENT_METHOD_REQUEST,
})

const defaultPaymentMethodResponse = (data) => ({
  type: ActionTypes.DEFAULT_PAYMENT_METHOD_RESPONSE,
  data,
})

const fetchMoneyEarnedInformationDataRequest = (eventId) => ({
  type: ActionTypes.FETCH_MONEY_EARNED_INFORMATION_DATA_REQUEST,
  eventId: eventId,
})

const fetchMoneyEarnedInformationDataReceived = (data) => ({
  type: ActionTypes.FETCH_MONEY_EARNED_INFORMATION_DATA_RECEIVED,
  data,
})

const deletePaymentMethodRequest = () => ({
  type: ActionTypes.DELETE_PAYMENT_METHOD_REQUEST,
})

const deletePaymentMethodResponse = (data) => ({
  type: ActionTypes.DELETE_PAYMENT_METHOD_RESPONSE,
  data,
})

const deleteCheckInRequest = () => ({
  type: ActionTypes.DELETE_CHECK_IN_REQUEST,
})

const deleteCheckInResponse = (data) => ({
  type: ActionTypes.DELETE_CHECK_IN_RESPONSE,
  data,
})

export const leaderboardDataReceived = () => ({
  type: ActionTypes.FETCH_LEADERBOARDS_DATA_RECEIVED,
})

export const leaderboardDataRequest = () => ({
  type: ActionTypes.FETCH_LEADERBOARDS_DATA_REQUEST,
})

export const viewChanged = (option) => ({
  type: ActionTypes.VIEW_CHANGE_OPTION,
  option,
})

export const pageChanged = (entity, period, page) => ({
  type: ActionTypes.PAGE_CHANGED,
  entity,
  period,
  page,
})

export const filterData = (type, filterValue) => (dispatch, getState) => {
  const state = getState()
  let actionType
  let currentFilterValue
  switch (type) {
    case 'transactionsHistory':
      actionType = ActionTypes.CHANGE_TRANSACTIONS_FILTER
      currentFilterValue = state.transactionsHistory.eventId
      break
    case 'participationHistory':
      actionType = ActionTypes.CHANGE_PARTICIPATION_HISTORY_FILTER
      currentFilterValue = state.participationHistory.seasonId
      break
  }
  
  if (currentFilterValue !== filterValue) {
    dispatch({
      type: actionType,
      value: filterValue,
    })
  }
}

export const togglePopup = (isShown, eventId) => (dispatch) => {
  dispatch({
    type: ActionTypes.TOGGLE_MONEY_EARNED_INFORMATION_POPUP,
    shown: isShown,
    eventId: eventId,
  })
}

export const toggleLeaderboardPopup = (isShown, roundId) => (dispatch) => {
  dispatch({
    type: ActionTypes.TOGGLE_LEADERBOARD_POPUP,
    shown: isShown,
    roundId: roundId,
  })
}

export const deletePaymentMethod = (paymentMethodId) => (dispatch, getState) => {
  const state = getState()
  const url = state.transactionsHistory.deletePaymentUrl + paymentMethodId
  dispatch(deletePaymentMethodRequest())
  
  callAPI(url, 'DELETE', { paymentMethodId: paymentMethodId }, new Headers({ 'Accept': 'application/json' }))
  .then((json) => dispatch(deletePaymentMethodResponse(json)))
}

export const defaultPaymentMethod = (paymentMethodId) => (dispatch, getState) => {
  const state = getState()
  const url = state.transactionsHistory.defaultPaymentUrl + paymentMethodId + '/default'
  dispatch(defaultPaymentMethodRequest())
  
  callAPI(url, 'GET', { paymentMethodId: paymentMethodId }, new Headers({ 'Accept': 'application/json' }))
  .then((json) => dispatch(defaultPaymentMethodResponse(json)))
}

export const deleteCheckIn = (checkInId) => (dispatch, getState) => {
  const state = getState()
  const url = state.participationHistory.deleteCheckInUrl + checkInId
  dispatch(deleteCheckInRequest())
  
  callAPI(url, 'DELETE', { checkInId: checkInId }, new Headers({ 'Accept': 'application/json', 'X-CSRF-Token': getAuthenticityToken(), 'Content-Type': 'application/json'}))
  .then((json) => dispatch(deleteCheckInResponse(json)))
  .catch((error) => alert(error)) // eslint-disable-line no-alert
}

export const exportToExcel = () => (dispatch, getState) => {
  const state = getState()
  const baseUrl = state.transactionsHistory.exportToExcelUrl
  const eventId = state.transactionsHistory.eventId
  if (baseUrl) {
    let data 
    if (eventId) {
      data = { event: eventId }
    } else {
      data = {}
    }
    callAPI(baseUrl, 'GET', data, new Headers({ 'Accept': 'application/json' }))
      .then((json) => {
        window.top.location.href = json.jobLink
      })
  }
}

export const fetchInitialMoneyData = (eventId) => (dispatch, getState) => {
  const state = getState()
  const loadedEventsIds = Object.keys(state.participationHistory.loadedEvents)
  const loaded = loadedEventsIds.some(id => id === eventId) || state.participationHistory.areLoadingEventsIds.some(id => id === eventId)
  const dataURL = state.participationHistory.fetchMoneyEarnedInformationDataUrl
  const requestData = { event: eventId }

  if (!loaded) {
    dispatch(fetchMoneyEarnedInformationDataRequest(eventId))
    if (dataURL) {
      callAPI(dataURL, 'GET', requestData, new Headers({ 'Accept': 'application/json' }))
        .then(json => {
          dispatch(fetchMoneyEarnedInformationDataReceived(json))
        })
    }
  }
}

export const fetchInitialData = (type) => (dispatch, getState) => {
  const state = getState()
  let loaded
  let requestAction
  let receivedAction
  let dataURL
  const requestData = {}
  switch (type) {
    case 'membershipPrograms':
      loaded = state.membershipPrograms.loaded
      requestAction = fetchMembershipProgramsDataRequest
      receivedAction = fetchMembershipProgramsDataReceived
      dataURL = state.membershipPrograms.fetchMembershipProgramsDataUrl
      break
    case 'participationHistory':
      requestAction = fetchParticipationHistoryDataRequest
      receivedAction = fetchParticipationHistoryDataReceived
      requestData.season = state.participationHistory.seasonId
      loaded = state.participationHistory.viewOption === 'round' ? state.participationHistory.loadedRoundsData : state.participationHistory.loadedEventsData
      dataURL = state.participationHistory.viewOption === 'round' ? state.participationHistory.fetchParticipationHistoryRoundsDataUrl : state.participationHistory.fetchParticipationHistoryEventsDataUrl
      break
    case 'transactionsHistory':
      loaded = state.transactionsHistory.loaded
      requestAction = fetchTransactionsHistoryDataRequest
      receivedAction = fetchTransactionsHistoryDataReceived
      dataURL = state.transactionsHistory.fetchTransactionsHistoryDataUrl
      break
    default:
      break
  }
  
  if (!loaded) {
    dispatch(requestAction())
    if (dataURL) {
      callAPI(dataURL, 'GET', requestData, new Headers({ 'Accept': 'application/json' }))
        .then(json => {
          dispatch(receivedAction(json))
        })
    }
  }
}


