import {connect} from 'react-redux'
import {fetchData, resetTimer} from '../actions'
import TourEventResultsComponent from '../components/tour_event_results_component'

const mapStateToProps = state => ({
  selectedTourEvent: state.tourEventResultsData.selectedTourEvent,
  playersResults: state.tourEventResultsData.playersResults,
  gameState: state.tourEventResultsData.gameState,
  tourEvents: state.tourEventResultsData.tourEvents,
  dataIsLoading: state.tourEventResultsData.dataIsLoading,
  reloading: state.tourEventResultsData.reloading,
})

const mapDispatchToProps = (dispatch) => ({
  fetchTourEventResults: (id, reloading) => dispatch(fetchData(id, reloading)),
  resetTimer: () => dispatch(resetTimer()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TourEventResultsComponent)
