import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import FlyerImage from './FlyerImage'
import { getAuthenticityToken } from 'Shared/helpers'

const FlyerImageList = (props) => {

  const [ showImages, setShowImages ] = useState(false)
  const [ images, setImages ] = useState(props.images)

  const handleImageDelete = (deleteImage, defaultImage) => {
    // eslint-disable-next-line no-alert
    if (confirm('Are you sure you want to delete this image?')) {
      fetch(`/leagues/${props.leagueId}/flyers_images/${deleteImage.id}?default=${defaultImage}`, {
        method: 'DELETE',
        headers: {'X-CSRF-Token': getAuthenticityToken()},
      })
      .then(() => {
        const newImages = images.filter((image) => image.id !== deleteImage.id)
        setImages(newImages)
        props.setDefaultImages(newImages)
      })
    }
  }

  const toggleShow = () => {
    setShowImages(!showImages)
  }

  useEffect(() => {
    setImages(props.images)
  }, [ props.images ])

  return (
    <div className="list">
      <div className="category" onClick={toggleShow}>
        <div className="category_name">
          {props.default ? 'Default Images' : 'My Images'}
        </div>
        <div className="caret-display">
          <i className={`fa ${!showImages ? 'fa-caret-down' : 'fa-caret-up'}`}></i>
        </div>
      </div>
      {showImages && 
        <div className="images_list">
          {images !== null && images.length > 0 ? ( images.map((image) => {
            return (
              <FlyerImage
                key={image.id}
                url={image.url}
                deleteImage={() => handleImageDelete(image, props.default)}>
              </FlyerImage>
            )
          })) : (
            <div className="no_results_found">
              { window.I18n.t('views.golfhub.no_results_found') }
            </div>
          )}
        </div>
      }
    </div>
  )
}

FlyerImageList.propTypes = {
  images: PropTypes.array,
  leagueId: PropTypes.string,
  default: PropTypes.bool,
  setDefaultImages: PropTypes.func,
}

export default FlyerImageList
