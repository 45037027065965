import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { VIDEO_TYPE } from '../../constants'
import { GlgPopup, GlgSwitch, GlgTextInput } from 'SharedComponents'
import { Row, Col } from 'react-flexbox-grid'
import YouTubeVideo from '../youtube_component'

class VideoSlidePopupComponent extends Component {

  constructor(props) {
    super(props)

    this.state = {
      videoId: '',
      isSaving: false,
    }

    this.saveToDB = this.saveToDB.bind(this)
    this.onLinkChange = this.onLinkChange.bind(this)
  }

  componentDidUpdate(previousProps) {
    const { show, videoId } = this.props

    if (show && !previousProps.show) {
      this.setState({ videoId })
    }
  }

  saveToDB() {
    const {
      videoId,
    } = this.state
    
    const {
      slideId,
      isVisible,
      backgroundImage,
    } = this.props

    const slide = {
      slideId,
      visible: isVisible,
      type: VIDEO_TYPE,
      description: 'Video Slide',
      background: backgroundImage,
      settings: {
        displayType: VIDEO_TYPE,
        videoId,
      },
    }

    this.setState({ isSaving: true })
    $.ajax({
      url: this.props.paths.saveSlide,
      type: 'POST',
      data: slide,
      success: (response) => {
        if (slideId) {
          this.props.updateSlide({
            id: slideId,
            type: VIDEO_TYPE,
            description: 'Video Slide',
            visible: slide.visible,
          })
        } else {
          this.props.addSlide({
            id: response.id,
            type: VIDEO_TYPE,
            description: 'Video Slide',
            visible: slide.visible,
          })
        }
        this.setState({ isSaving: false }, this.props.onClose)
      },
      error: () => {
      },
    })
  }

  onLinkChange(url) {
    const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = url.match(regExp)
    if (match && match[2].length === 11) {
      this.setState({ videoId: match[2] })
    }
  }

  render() {
    const {
      slideId,
    } = this.props

    return <GlgPopup title={ `${slideId ? 'Edit' : 'New' } Video Slide` } saveButtonText={ slideId ? 'Update' : 'Save' } saveButtonEnabled={ !!this.state.videoId } show={ this.props.show } modalClass={ 'video-popup' } manualClose={ true } onClose={ this.props.onClose } onSave={ this.saveToDB } isSaving={ this.state.isSaving }><div id="slide_setup"><div className="visible-switch justify-center"><GlgSwitch id={ 'leaderboard-visible-switch' } text={ [ 'Visible', 'Hidden' ] } widthInPixels={ 80 } checked={ !!this.props.isVisible } onChange={ () => { this.props.setVisible(!this.props.isVisible) } }></GlgSwitch></div><ul className="no-padding"><li className="form_row add-margin-top-20 add-padding-bottom-20"><div className="row popup-section-title no-padding-bottom"><Col xs={ 12 }>{ 'Video Source' }</Col></div><div className="row popup-section vertical-align-items no-padding-bottom"><Col xs={ 12 } sm={ 12 }><Row className="vertical-align-items add-padding-top-5 add-padding-bottom-5"><Col xs={ 12 } md={ 4 } lg={ 3 }>{ 'YouTube Link' }</Col><Col xs={ 12 } md={ 8 } lg={ 9 }><GlgTextInput onChange={ (link) => this.onLinkChange(link) } placeholder={ 'Enter the video link...' }></GlgTextInput></Col></Row></Col></div>{ this.state.videoId && <div className="row popup-section vertical-align-items no-padding-bottom"><Col xs={ 12 } sm={ 12 }><Row className="vertical-align-items add-padding-top-5 add-padding-bottom-5"><Col xs={ 12 } md={ 4 } lg={ 3 }>{ 'Preview' }</Col><Col xs={ 12 } md={ 8 } lg={ 9 }><YouTubeVideo id={ this.state.videoId } height={'300px'}></YouTubeVideo></Col></Row></Col></div>}</li></ul></div></GlgPopup>
  }
}

VideoSlidePopupComponent.propTypes = {
  paths: PropTypes.object.isRequired,
  slideId: PropTypes.string,
  type: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool,
  backgroundImage: PropTypes.string,
  setVisible: PropTypes.func,
  addSlide: PropTypes.func.isRequired,
  updateSlide: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  videoId: PropTypes.string,
}

export default VideoSlidePopupComponent
