/*
 * This is a class for defining js handlers for Navigator options.
 * For an option with internal_id = 'print_scorecards' you can define
 *
 * static handlePrintScorecards() {
 * }
 *
 */

class NavigatorHandlers {
  static changeRound(roundId) {
    return new Promise(resolve => {
      if (roundId === $('#round_panel_selector').val()) {
        resolve()
      } else {
        $('#round_panel_selector').val(roundId)
                                  .trigger('change')
        const checkerInterval = setInterval(() => {
          if ($('.load_rounds_spinner:visible').length === 0) {
            clearInterval(checkerInterval)
            resolve()
          }
        }, 500)
      }
    })
  }

  static highlightRoundOption(roundId, elementFn) {
    $('#nav_rounds').click()
    setTimeout(() => {
      this.changeRound(roundId).then(() => {
        const element = $(elementFn())
        if (element.length > 0) {
          window.glg.highlighter.highlight(element)
        } else {
          $('#nav_rounds').click()
          $('.navigator-main-icon').click()
        }
      })
    }, 500)
  }

  static highlightNavMenuOption(menuElement, element) {
    $(menuElement).click()
    setTimeout(() => {
      window.glg.highlighter.highlight(element)
    }, 500)
  }

  static highlightAppsOption(element) {
    this.highlightNavMenuOption($('#nav_apps'), element)
  }

  static highlightEventOption(element) {
    this.highlightNavMenuOption($('#nav_league'), element)
  }

  static highlightCCEventOption(element) {
    this.highlightNavMenuOption($('#nav_leagues_and_events'), element)
  }

  static handleChoosePlayingDivisions(roundId) {
    this.highlightRoundOption(roundId, () => $('#choose_divisions').closest('li'))
  }

  static handleEnableMobileScoreEntry(roundId) {
    this.highlightRoundOption(roundId, () => $('ul.toggle_realtime_mode').closest('li'))
  }

  static handleEnableWalkingScorer(roundId) {
    this.highlightRoundOption(roundId, () => $('ul.toggle_walking_scorer').closest('li'))
  }

  static handleChangeRoundStatus(roundId) {
    this.highlightRoundOption(roundId, () => $('li.tournament-state'))
  }

  static handleShowPairingsInEventPortal(roundId) {
    this.highlightRoundOption(roundId, () => $('ul.teesheet_member_portal').closest('li'))
  }

  static handleShowResultsInEventPortal(roundId) {
    this.highlightRoundOption(roundId, () => $('ul.results_member_portal').closest('li'))
  }

  static handleSetFlashMessage() {
    const element = $('#set-flash-message').closest('li')
    this.highlightAppsOption(element)
  }

  static handleRemoveFlashMessage() {
    const element = $('#remove-flash-message').closest('li')
    this.highlightAppsOption(element)
  }

  static handleOpenRegistration() {
    const element = $('.open_reg_link_toggle')
    this.highlightEventOption(element)
  }

  static handleCloseRegistration() {
    this.handleOpenRegistration()
  }

  static handleCreateEventLeagueTrip() {
    const element = $('.create_new_league_from_header')
    this.highlightCCEventOption(element)
  }

  static handleCloneEventLeagueTrip() {
    const element = $('.clone_league_from_header')
    this.highlightCCEventOption(element)
  }
}

export default NavigatorHandlers
