import { ActionTypes } from '../actions'

const {
  SET_TABLE_DATA,
  CHANGE_SCORE,
  CHANGE_TOURNAMENT_RECEIVED,
  CHANGE_ROUND_RECEIVED,
} = ActionTypes

const initialState = {
  courses: [],
  foursomes: [],
  hasResults: false,
  holes: [],
  isMultiRound: false,
  isStableford: false,
  pastRounds: null,
  tees: {},
  newScores: {},
  hasTournaments: false,
  noTournamentsMessage: '',
}

const scoringInterfaceData = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_TOURNAMENT_RECEIVED:
      return {
        ...state,
        ...action.data,
      }
    case CHANGE_ROUND_RECEIVED:
      return {
        ...state,
        ...action.data,
      }
    case SET_TABLE_DATA:
      return {
        ...state,
        ...action.data,
      }
    case CHANGE_SCORE:
      return {
        ...state,
        newScores: action.newScores,
      }
    default:
      return state
  }
}

export default scoringInterfaceData
