import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

const EventRegularItemComponent = ({
  id, name, GGIDGuestLoginURL,
}) => {

  return <center><table className="table table-hover table-striped table-condensed table-bordered customer_directory_table"><tbody className="render_directories_portals"><Fragment><tr key={id} className={'white-row'}><Fragment><td className="portal-label"></td><td><Row><Fragment><Col lg={9} md={9} sm={9} xs={12}><b className="portal-name"><a href={GGIDGuestLoginURL} target={'_top'}>{name}</a></b></Col><Col lg={3} md={3} sm={3} xs={12}><div className="links text-right"><span className="semi-bold"><a href={GGIDGuestLoginURL} target={'_top'}></a></span></div></Col></Fragment></Row></td></Fragment></tr><tr className="height-20"></tr></Fragment></tbody></table></center>
}

EventRegularItemComponent.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  GGIDGuestLoginURL: PropTypes.string,
}

EventRegularItemComponent.defaultProps = {
}

export default EventRegularItemComponent
