import React, {useEffect, useState} from 'react'
import GolfersSection from './sections/golfers_component'
import PropTypes from 'prop-types'
import TeamsSection from './sections/teams_component'
import TeamCaptainsSection from './sections/team_captains_component'
import DraftOrderSection from './sections/draft_order_component'
import DraftSettingsSection from './sections/draft_settings_component'

const Draft = ({
  importGolfersUrl,
  manuallyAddGolfersUrl,
  uploadFromSpreadsheetUrl,
  teams,
  teamCaptains,
  golfers,
  previousLeagues,
  defaultSetupEmptyTeamset,
  addNewTeam,
  deleteTeam,
  changeTeamName,
  changeTeamCaptain,
  reorderTeams,
  order,
  changeOrder,
  roundsCount,
  roundsOrder,
  changeRoundOrder,
  rankingMethod,
  changeRankingMethod,
  pickTime,
  changePickTime,
  prevLeagueId,
  changePrevLeagueId,
  grossScores,
  changeGrossScores,
  draftsUrl,
  saveDraft,
  saveInProgress,
  action,
}) => {

  const [ activeSection, setActiveSection ] = useState()
  const [ validForm, setValidForm ] = useState(false)

  useEffect(() => {
    if (action === 'new') {
      const params = new URLSearchParams(window.location.search)
      let replaceParams = `?name=${params.get('name')}&teamset_id=${params.get('teamset_id')}`
      if (params.get('locale')){
        replaceParams += `&locale=${params.get('locale')}`
      }

      window.history.replaceState(null, null, window.location.href.replace(/\?.*/, replaceParams))
    }
    
    document.getElementById('content').
      classList.
      add('draft_content')
    document.getElementById('main_content').
      classList.
      add('draft_main_content')
    const firstActiveSection = document.getElementById('golfers-section')
    activateSection(firstActiveSection)
    setActiveSection(firstActiveSection)

    if (teams.length === 0) {
      defaultSetupEmptyTeamset()
    }
  }, [])

  useEffect(() => {
    const validOrder = [ 'recurring', 'snake', 'combination' ].includes(order)
    const validRoundsOrder = order !== 'combination' || Object.values(roundsOrder).filter(o => [ 'recurring', 'snake' ].includes(o)).length === roundsCount - 1
    const validRankingMethod = [ 'previous', 'alpha', 'hi' ].includes(rankingMethod)
    const validPrevLeagueId = rankingMethod !== 'previous' || Object.keys(previousLeagues).includes(prevLeagueId)
    const validGrossScores = rankingMethod !== 'previous' || (Number.isInteger(grossScores) && grossScores >= 0)
    const validPickTime = Number.isInteger(pickTime) && pickTime >= 0
    const validTeams = teams.filter(t => t.name.trim().length > 0).length > 0

    const allValid = validOrder && validRoundsOrder && validRankingMethod && validPrevLeagueId && validGrossScores && validPickTime && validTeams
    setValidForm(allValid)
  }, [ order, roundsOrder, rankingMethod, prevLeagueId, grossScores, pickTime, teams ])

  const changeSection = (event) => {
    const section = event.target.closest('.section')
    activateSection(section)
  }

  const activateSection = (section) => {
    if (activeSection) {
      minimizeSection(activeSection)
      if (!section.isSameNode(activeSection)) {
        maximizeSection(section)
      }
    } else {
      maximizeSection(section)
    }

    const isActive = document.getElementsByClassName('section active')[0]

    if (isActive) {
      setActiveSection(section)
    } else {
      setActiveSection(null)
    }
  }

  const minimizeSection = (section) => {
    section.getElementsByClassName('section_content')[0].classList.add('hidden')
    section.classList.remove('active')
  }

  const maximizeSection = (section) => {
    section.getElementsByClassName('section_content')[0].classList.remove('hidden')
    section.classList.add('active')
  }

  return (
    <>
      <div id="draft_setup">
        <GolfersSection importGolfersUrl={importGolfersUrl} manuallyAddGolfersUrl={manuallyAddGolfersUrl}
                        uploadFromSpreadsheetUrl={uploadFromSpreadsheetUrl} changeSection={changeSection}/>
        <TeamsSection changeSection={changeSection} teams={teams} defaultSetupEmptyTeamset={defaultSetupEmptyTeamset}
                      addNewTeam={addNewTeam} deleteTeam={deleteTeam}
                      changeTeamName={changeTeamName}/>
        <TeamCaptainsSection changeSection={changeSection} teams={teams} teamCaptains={teamCaptains} golfers={golfers}
                             changeTeamCaptain={changeTeamCaptain}/>
        <DraftOrderSection changeSection={changeSection} teams={teams} reorderTeams={reorderTeams} order={order}
                           changeOrder={changeOrder} roundsCount={roundsCount} roundsOrder={roundsOrder}
                           changeRoundOrder={changeRoundOrder}/>
        <DraftSettingsSection changeSection={changeSection} rankingMethod={rankingMethod}
                              changeRankingMethod={changeRankingMethod} pickTime={pickTime}
                              changePickTime={changePickTime} previousLeagues={previousLeagues}
                              prevLeagueId={prevLeagueId} changePrevLeagueId={changePrevLeagueId}
                              grossScores={grossScores} changeGrossScores={changeGrossScores}/>
      </div>
      <div className="save_bar pull-right">
        <fieldset>
          <a href={draftsUrl} className="button grey btsp_gray_button">{window.I18n.t('buttons.cancel')}</a>
          <button onClick={saveInProgress || !validForm ? undefined : saveDraft}
                  className="button orange btsp_orange_button"
                  disabled={saveInProgress || !validForm}>{window.I18n.t(`buttons.${saveInProgress ? 'saving' : 'save'}`)}</button>
        </fieldset>
      </div>
    </>
  )
}

Draft.propTypes = {
  importGolfersUrl: PropTypes.string,
  manuallyAddGolfersUrl: PropTypes.string,
  uploadFromSpreadsheetUrl: PropTypes.string,
  teams: PropTypes.array,
  teamCaptains: PropTypes.any,
  golfers: PropTypes.array,
  previousLeagues: PropTypes.any,
  defaultSetupEmptyTeamset: PropTypes.func,
  addNewTeam: PropTypes.func,
  deleteTeam: PropTypes.func,
  changeTeamName: PropTypes.func,
  changeTeamCaptain: PropTypes.func,
  reorderTeams: PropTypes.func,
  order: PropTypes.string,
  changeOrder: PropTypes.func,
  roundsCount: PropTypes.number,
  roundsOrder: PropTypes.any,
  changeRoundOrder: PropTypes.func,
  rankingMethod: PropTypes.string,
  changeRankingMethod: PropTypes.func,
  pickTime: PropTypes.number,
  changePickTime: PropTypes.func,
  prevLeagueId: PropTypes.string,
  changePrevLeagueId: PropTypes.func,
  grossScores: PropTypes.number,
  changeGrossScores: PropTypes.func,
  draftsUrl: PropTypes.string,
  saveDraft: PropTypes.func,
  saveInProgress: PropTypes.bool,
  action: PropTypes.string,
}

export default Draft
