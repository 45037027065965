import React, { useState, useEffect, Fragment } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import PropTypes from 'react'
import { callAPI } from 'Shared/helpers'
import { GlgLoadingSpinner } from 'SharedComponents'
import { getAuthenticityToken } from 'Shared/helpers'
import { GlgTooltip } from '../../../shared/components'

const BannersIndex = (props) => {

  const [ banners, setBanners ] = useState( [] )
  const [ dataIsLoading, setDataIsLoading ] = useState(true)

  useEffect(() => {
    
    callAPI('/api/customers/' + props.customerId + '/course_albums/', 'GET')
      .then((json) => {
        setBanners(json)
        setDataIsLoading(false)
      })
  }, [])

  const handleDelete = ({id, allowDestroy, defaultBannerCarousel}) => {
    if (defaultBannerCarousel) {
      // eslint-disable-next-line no-alert
      if (confirm(window.I18n.t('views.golfhub.remove_banner_confirmation_message')) === true) {
        let temp = [ ...banners ]
        temp = temp.filter((v) => v.id !== id)
        
        fetch('/api/customers/' + props.customerId + '/default_banner_carousel_relationships/' + id, {
          method: 'DELETE',
          headers: {'X-CSRF-Token': getAuthenticityToken()}})
        .then(() => setBanners(temp))
      }
    } else {
      if (allowDestroy) {
        // eslint-disable-next-line no-alert
        if (confirm(window.I18n.t('views.golfhub.remove_banner_confirmation_message')) === true) {
          let temp = [ ...banners ]
          temp = temp.filter((v) => v.id !== id)
          
          fetch('/api/customers/' + props.customerId + '/course_albums/' + id, {
            method: 'DELETE',
            headers: {'X-CSRF-Token': getAuthenticityToken()}})
          .then(() => setBanners(temp))
        }
      } else {
        // eslint-disable-next-line no-alert
        alert(window.I18n.t('views.golfhub.unable_to_remove_banner'))
      }
    }
  }

  return (
    <Row className='no-margin'>
      <Col className='add-padding-all-20' xs={ 12 }>
        <div className='btsp_bullet_button pointer' onClick={() => props.setPage('new')}>
          <div className='icon'><i className='gga-file-plus'></i></div>
          <div className='btsp_align-middle'>New Banner Template</div>
        </div>
        { dataIsLoading && (<GlgLoadingSpinner className='text-center' show={dataIsLoading}/>)}

        { !dataIsLoading && banners.length === 0 && (
          <div className='text-center'>No banners yet</div>
        )}

        { !dataIsLoading && banners.length > 0 && (
          <div className='table-responsive btsp_table_container table_with_dropdown'>
            <table className='table table-striped table-drop'>
              <thead>
                <tr className='header'>
                  <td className='w-20 middle'><b>NAME</b></td>
                  <td className='w-10 middle'></td>
                  <td className='pdl-40 middle'><b>PHOTOS</b></td>
                  <td className='w-10 middle'></td>
                </tr>
              </thead>
              <tbody>
                {banners.map(banner => (
                  <tr key={banner.id}>
                    <BannersRow handleDelete={handleDelete} setPage={props.setPage} {...banner}/>
                  </tr>
                ))}
                
                
              </tbody>
                  
            </table>
          </div>
        )}

      </Col>
    </Row>
  )
}

const BannersRow = (props) => {
  const [ open, setOpen ] = useState( false )

  return (
    <Fragment>
      <td className='w-20 template-name'>
        {
          !props.defaultBannerCarousel &&
            <a onClick={() => props.setPage('edit/' + props.id)}>{props.name}</a>
        }
        {
          props.defaultBannerCarousel &&
            <GlgTooltip tooltip={window.I18n.t('views.golfhub.default_banners_tooltip')} placement="top"><span>{props.name}</span></GlgTooltip>
        }
      </td>
      <td className='w-10 middle blue-label-container'>
        {
          props.primary === true && 
            <div className='blue-label'>
              { window.I18n.t('views.golfhub.default') }
            </div>
        }
      </td>
      <td className='template-content'>
        { props.photos && props.photos.map((p, index) => (
          <div className='img-container course-album-image' key={props.id + '-' + index}>
            <img src={p}/>
          </div>
        ))}
      </td>
      <td className='w-10 template-content'>
        <div className={'dropMenu dropdown' + (open ? ' open' : '')} onClick={() => setOpen(!open)}>
          <a className='btn btn-default btn-xs new_dropdown_button_icon' href='javascript:void(0);'>
            <i className={'fa fa-lg ' + (open ? 'fa-caret-up' : 'fa-caret-down') }/>
          </a>
          <ul className='dropdown-menu'>
            {
              !props.defaultBannerCarousel &&
                <li><a onClick={() => props.setPage('edit/' + props.id)}>{ window.I18n.t('views.golfhub.edit') }</a></li>
            }
            <li><a onClick={() => props.handleDelete({id: props.id, allowDestroy: props.allowDestroy, defaultBannerCarousel: props.defaultBannerCarousel})}>{ window.I18n.t('views.golfhub.delete') }</a></li>
          </ul>
        </div>
      </td>
    </Fragment>
  )
}

BannersRow.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  primary: PropTypes.string,
  photos: PropTypes.array,
  allowDestroy: PropTypes.bool,
  defaultBannerCarousel: PropTypes.bool,
  setPage: PropTypes.func,
  handleDelete: PropTypes.func,
}

BannersIndex.propTypes = {
  setPage: PropTypes.func,
  customerId: PropTypes.string,
}

export default BannersIndex
