import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import TeeData from './tee_data_component'
import FoursomeData from './foursome_data_component'
import { GlgLoadingSpinner } from 'SharedComponents'

class GroupViewTableComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const tees = { ...this.props.tees }
    const foursomes = [ ...this.props.foursomes ]

    return (
      <Fragment>
        {this.props.isLoadingTournament && 
          <GlgLoadingSpinner 
            show={this.props.isLoadingTournament}
            className='add-margin-top-40 text-center'
            width={85} 
            height={85}
            />
        }
        {!this.props.isLoadingTournament &&
          <div className='group_view_table'>
            <table className='group_view'>
              <thead>
                <tr className='header'>
                  <th className='with_border first'>{!window.I18n ? '' : window.I18n.t('group_view.table.group_info')}</th>
                  <th className='with_border second'>{!window.I18n ? '' : window.I18n.t('group_view.table.hole')}</th>
                  {this.props.isMultiRound && <th className='with_border'>{!window.I18n ? '' : window.I18n.t('group_view.table.prior')}</th>}
                  {[ ...Array(9) ].map( ( _, i) => <th key={'hole-' + i} className='with_border'>{i + 1}</th>)}
                  {this.props.scoreDisplay && <th className='with_border'>{!window.I18n ? '' : window.I18n.t('group_view.table.out')}</th>}
                  {[ ...Array(9) ].map( ( _, i) => <th key={'hole-' + i} className='with_border'>{i + 10}</th>)}
                  {this.props.scoreDisplay && <th className='with_border'>{!window.I18n ? '' : window.I18n.t('group_view.table.in')}</th>}
                  {!this.props.scoreDisplay && this.props.isMultiRound ? null : <th className='with_border'>{!window.I18n ? '' : window.I18n.t('group_view.table.total')}</th>}
                  {this.props.scoreDisplay && !this.props.isStableford && this.props.isMultiRound && <th className='with_border'>{!window.I18n ? '' : window.I18n.t('group_view.table.today')}</th>}
                  {this.props.scoreDisplay && !this.props.isStableford && this.props.isMultiRound && <th className='with_border'>{!window.I18n ? '' : window.I18n.t('group_view.table.par_total')}</th>}
                  {this.props.isStableford && this.props.isMultiRound && <th className='with_border'>{!window.I18n ? '' : window.I18n.t('group_view.table.overall')}</th>}
                  {!this.props.scoreDisplay && this.props.isMultiRound && 
                    this.props.pastRounds.map( round => <th className='with_border' key={round}>{round}</th>)
                  }
                  {!this.props.scoreDisplay && this.props.isMultiRound && <th className='with_border'>{!window.I18n ? '' : window.I18n.t('group_view.table.total')}</th>}
                </tr>
                {
                  Object.keys(tees).map( key => {
                    return tees[key].map( (tee, index) => 
                      <TeeData 
                        key={tee.id} 
                        tee={tee}
                        scoreDisplay={this.props.scoreDisplay}
                        isMultiRound={this.props.isMultiRound}
                        isStableford={this.props.isStableford}
                        pastRounds={this.props.pastRounds}
                        showPar={index === tees[key].length - 1}
                    />)
                  }
                    
                  )
                }
              </thead>
              <tbody>
                {
                  foursomes.map( (foursome, index) => {
                    const isLast = index === foursomes.length - 1
                    return <FoursomeData
                      key={foursome.id}
                      foursome={foursome}
                      scoreDisplay={this.props.scoreDisplay}
                      isStableford={this.props.isStableford}
                      isMultiRound={this.props.isMultiRound}
                      holeView={this.props.holeView}
                      isPortal={this.props.isPortal}
                      isLast={isLast}
                    />
                  }
                  )
                }
              </tbody>
            </table>
          </div> 
        }
      </Fragment>
    )
  }
}

GroupViewTableComponent.propTypes = {
  tees: PropTypes.object,
  foursomes: PropTypes.array,
  scoreDisplay: PropTypes.bool,
  isStableford: PropTypes.bool,
  isMultiRound: PropTypes.bool,
  isLoadingTournament: PropTypes.bool,
  pastRounds: PropTypes.array,
  holeView: PropTypes.string,
  isPortal: PropTypes.bool,
}

export default GroupViewTableComponent
