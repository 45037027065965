import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import ReportCard from './reports/report_card'
import PrintOptionsModal from './popups/print_modal_options'
import ReportPreview from './popups/report_preview'

class Reports extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount() {
    window.glg.new_design_shared.init_selectpicker()
  }

  stringParameterize(str) {
    return str.trim().toLowerCase()
      .replace(/[^a-zA-Z0-9 -]/g, '')
      .replace(/ +/g, ' ')
      .replace(/[\s-]/g, '_')
  }

  render() {
    const { title, links, reportCenterLists, selectedList, reportStates, noReportsWarning, reportPreviewModal, searchTerm } = this.props.content

    return (
      <div className='widget-content-default widget-content-rounds widget-content-reports'>
        <div className='title'>
          { title }

          <a href={ links.report_center.path } className='btn btn-primary widget-white-title-button'>
            { links.report_center.name }
          </a>
        </div>
        <div className='content'>
          <div className='reports-category'>
            <div className='report-lists-container'>
              <div className='select-list'>
                <select className='selectpicker' onChange={ (event) => this.props.reportsSelectList(event.target.value) } data-dropup-auto='false'>
                  {
                    Object.entries(reportCenterLists).map( list => <option key={ list[1].id } value={ list[1].id }>{ !window.I18n ? '' : window.I18n.t(`rounds.reports_center.select_report_list.names.${this.stringParameterize(list[1].name)}`, {defaultValue: list[1].name}) }</option> )
                  }
                </select>

                
                <input className='form-control report-search'
                       autoComplete='false'
                       placeholder={ searchTerm.placeholder }
                       value={ searchTerm.value }
                       onChange={ event => this.props.reportsSearch(event.target.value) } />
              </div>

              <div className='top-buttons-container'>
                <a href={ links.print_scorecards.path } className='btn btn-primary widget-white-content-button top-buttons'>
                  { links.print_scorecards.name }
                </a>
                <a href={ links.community_reports.path } className='btn btn-primary widget-white-content-button top-buttons no-lite'>
                  { links.community_reports.name }
                </a>
              </div>
            </div>
            <div className='reports-container'>
              {
                reportCenterLists[selectedList].report_center_items.length > 0
                  ? <Fragment>
                    <div className='scroll-bar'>
                      <i className='fa fa-caret-left left-scroll-arrow' onMouseDown={ () => this.props.scrollReports('left') }></i>
                    </div>
                    <div className='reports-panel'>
                      {
                        reportCenterLists[selectedList].report_center_items.map(report =>
                          report.name.toLowerCase().includes(searchTerm.value.toLowerCase())
                            ? <ReportCard key={ `${report.id}${report.name}` }
                                          report={ report }
                                          reportPrint={ this.props.reportPrint }
                                          reportSetState={ this.props.reportSetState }
                                          reportStates={ reportStates }
                                          reportAction={ this.props.reportAction }
                                          reportPollJobStatus={ this.props.reportPollJobStatus }
                                          reportImport={ this.props.reportImport }
                                          reportGenerateDoc={ this.props.reportGenerateDoc }
                                          reportGenerateTxt={ this.props.reportGenerateTxt }
                                          reportTogglePreviewModal={ this.props.reportTogglePreviewModal } />
                            : null)
                      }
                    </div>
                    <div className='scroll-bar'>
                      <i className='fa fa-caret-right right-scroll-arrow' onMouseDown={ () => this.props.scrollReports('right') }></i>
                    </div>
                  </Fragment>
                  : <div className='no-report-warning'>{ noReportsWarning }</div>
              }
            </div>
          </div>
        </div>

        <PrintOptionsModal />
        <ReportPreview reportPreviewModal={ reportPreviewModal }
                       onClose={ () => this.props.reportTogglePreviewModal(false, '', '') } />
      </div>
    )
  }
}

Reports.propTypes = {
  content: PropTypes.object,
  reportSetState: PropTypes.func,
  scrollReports: PropTypes.func,
  reportPrint: PropTypes.func,
  reportsSelectList: PropTypes.func,
  reportsSearch: PropTypes.func,
  reportAction: PropTypes.func,
  reportImport: PropTypes.func,
  reportPollJobStatus: PropTypes.func,
  reportGenerateDoc: PropTypes.func,
  reportGenerateTxt: PropTypes.func,
  reportTogglePreviewModal: PropTypes.func,
}

Reports.defaultProps = {
  content: {
    title: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.reports.title'),
    categoryName: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.reports.this_event'),
    links: {
      report_center: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.reports.report_center'),
        path: '#',
      },
      print_scorecards: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.reports.print_scorecards'),
        path: '#',
      },
      community_reports: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.reports.community_reports'),
        path: '#',
      },
    },
    reports: [],
  },
}

export default Reports
