import { ActionTypes } from '../actions'

const {
  FETCH_INITIAL_DATA_RECEIVED,
} = ActionTypes

/*
const DEFAULT_NAVIGATOR_HELP_LINK_DATA = {
  name: '',
  url: '',
  navigatorOptionId: '',
}
*/

const navigatorHelpLinks = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INITIAL_DATA_RECEIVED:
      return {
        ...state,
        ...action.data.navigatorHelpLinks,
      }
    default:
      return state
  }
}

export default navigatorHelpLinks
