import React, { useState, Fragment } from 'react'
import PropTypes from 'react'
import {
  GlgCheckbox,
} from 'SharedComponents'
import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import DatePicker from 'react-datepicker'
import { getAuthenticityToken } from '../../../shared/helpers'

const StyledPage = styled.div`
  .name-input {
    width: 400px;
  }
`

const DraggableItem = styled.div`
  
  margin-top: 15px;

  .react-datepicker__time-container{
    width: 100px;
  }
  
  .react-datepicker__time {
    width: 100px;
  }

  .react-datepicker__time-box {
    width: 100px !important;
  }

  .react-datepicker__time-list {
    width: 100px;
  }

  .react-datepicker__time-list-item {
    width: 100px;
  }

  .time-input {
    width: 100px !important;
    margin-right: 25px;
  }

  .container {
    display: inline-block;
    width: 15em; height: 10em;
    text-align: center;
    white-space: nowrap;
  }
  .container img {
    display: inline-block;
    width: auto; height: auto;
    max-width: 100%; max-height: 100%;
    vertical-align: middle;
  }
  .container:after {
    display: inline-block;
    height: 100%; width: 0;
    vertical-align: middle;
    content: "";
  }
  
  .form-control {
    width: auto;
    display: inline;
  }
  i {
    cursor: pointer;
  }

  .react-datepicker__tab-loop {
    float: left;
  }

  .inline-attributes {
    display: inline-block;
    float: right;

    &.item-activity {
      width: calc(100% - 152px);
    }
  }
`
const SchedulesNew = (props) => {
  let initialActivities

  if (props.data?.activities) {
    initialActivities = props.data?.activities.map(v => ({time: new Date(new Date(v.time).toLocaleString('en-US', {timeZone: props.data?.timezone})), activity: v.activity}))
  }
  const [ formData, setFormData ] = useState(props.data || {primary: false})
  const [ schedules, setSchedules ] = useState( initialActivities || [ {time: new Date(), activity: ''} ] )
  const [ saveDisabled, setDisabled ] = useState(false)

  const handleFormData = (e, key) => {
    const value = typeof(e) === 'object' ? e.target.value : e
    setFormData({...formData, [key]: value})
  }

  const saveSchedule = (update) => {
    if (saveDisabled) {
      return
    }

    setDisabled(true)

    const fd = new FormData()
    
    fd.append('name', formData.name)
    fd.append('primary', formData.primary)
    fd.append('schedule_times', JSON.stringify($('input[name="schedule_times[]"]').map(function() { return $(this).val() })
      .get()))
    fd.append('schedule_activities', JSON.stringify($('input[name="schedule_activities[]"]').map(function() { return $(this).val() })
      .get()))

    if (update) {
      fetch('/api/customers/' + props.customerId + '/directory_schedules/' + props.data.id, {
        method: 'PATCH',
        body: fd,
        headers: {'X-CSRF-Token': getAuthenticityToken()}})
      .then(() => { 
        setDisabled(true)
        props.setPage('index')
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.log(e))
    } else {
      fetch('/api/customers/' + props.customerId + '/directory_schedules', {
        method: 'POST',
        body: fd,
        headers: {'X-CSRF-Token': getAuthenticityToken()}})
      .then(() => { 
        setDisabled(true)
        props.setPage('index')
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.log(e))
    } 
  }

  return (
    <StyledPage>
      <div className='btsp_form_content col-xs-12'>
        <div className="col-xs-12 col-sm-11 col-md-11 col-lg-11 col-sm-offset-1 col-md-offset-1 col-lg-offset-1 tabs_content btsp_form_content">
          <ul className='btsp_ul'>
            <li className='form_row'>
              <div className='row'>
                <div className='col-sm-3 col-md-3 col-lg-3 hidden-xs'>
                  <label>Name</label>
                </div>
                <div className='col-xs-12 col-sm-9 col-md-9 col-lg-9'>
                  <input defaultValue={props.data?.name} onChange={(e) => handleFormData(e, 'name')} type='text' className='form-control form-text name-input'/>
                </div>
              </div>
            </li>
            <li className='form_row'>
              <div className='row'>
                <div className='col-sm-3 col-md-3 col-lg-3 hidden-xs'>
                  <label>Default</label>
                </div>
                <div className='col-xs-12 col-sm-9 col-md-9 col-lg-9'>
                  <GlgCheckbox 
                    name="primary"
                    value="true"
                    label="Mark this Schedule template as default"
                    defaultChecked={ props.data?.primary }
                    onChange={ (e) => handleFormData(e, 'primary') }/>
                </div>
              </div>
            </li>
            <li className='form_row'>
              <div className='row'>
                <div className='col-sm-3 col-md-3 col-lg-3 hidden-xs'>
                  <label>Schedule</label>
                </div>
                <div className='col-xs-12 col-sm-9 col-md-9 col-lg-9'>
                  <ScheduleItems schedules={schedules} setSchedules={setSchedules}/>
                </div>

              </div>
            </li>
          </ul>
        </div>
      </div>
      
      <div className='save_bar'>
        <a className='button btsp_gray_button' onClick={() => props.setPage('index')}>Cancel</a>
        <a className='button btsp_orange_button' onClick={() => saveSchedule(props.data?.name ? 'update' : undefined)}>{props.data?.name ? 'Update' : 'Save'}</a>
      </div>
    </StyledPage>
  )
}

const CustomTimeInput = ({ value, onClick }) => (
  <input name='schedule_times[]' className='form-control time-input' type='text' onClick={onClick} value={value}/>
)

export const ScheduleItems = ({schedules, setSchedules}) => {
  const handleScheduleData = (e, index) => {
    const tempArr = [ ...schedules ]
    tempArr[index].activity = e.target.value

    setSchedules(tempArr)
  }

  const remove = (index) => {
    const tempArr = [ ...schedules ]
    tempArr.splice(index, 1)[0]?.id
    setSchedules(tempArr)
  }

  const swapSchedules = (result) => {
    const tempArr = [ ...schedules ]
    const tempVar = tempArr[ result.source.index ]
    tempArr.splice(result.source.index, 1)
    tempArr.splice(result.destination.index, 0, tempVar)

    setSchedules(tempArr)
  }

  const handleTimeChange = (date, index) => {
    const temp = [ ...schedules ]
    temp[index].time = date

    setSchedules(temp)
  }

  const addSchedule = () => {
    const temp = [ ...schedules ]
    let date
    if (temp.length === 0) {
      date = new Date()
    } else {
      const lastindex = temp.length - 1
      date = temp[lastindex].time
    }
    temp.push({time: date, activity: ''})
    setSchedules(temp)
  }

  schedules.sort((date1, date2) => date1.time - date2.time)

  return (
    <Fragment>
      <div className='add-margin-bottom-20 btsp_bullet_button pointer' onClick={() => addSchedule()}>
        <div className='icon'><i className='gga-circle-plus'></i></div>
        <div className='btsp_align-middle pointer'>Add Item</div>
      </div>
      <DragDropContext onDragEnd={swapSchedules}>
        <Droppable droppableId="droppable">
        {(provided) => (
          <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className='img-container'>
            
            {schedules.map((schedule, i) => (
              <Draggable key={'draggable-' + i} draggableId={'draggable-' + i} index={i} >
                {(prov) => (
                  <DraggableItem
                    ref={prov.innerRef}
                    {...prov.draggableProps}
                    {...prov.dragHandleProps}>
                    <i className='fa fa-bars fa-lg add-margin-right-10'></i>
                    <DatePicker
                      selected={schedules[i].time}
                      onChange={date => handleTimeChange(date, i)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      customInput={<CustomTimeInput />}
                    />
                    <div className='inline-attributes item-activity'>
                      <input name="schedule_activities[]" placeholder="Describe item" value={schedule.activity} onChange={(e) => handleScheduleData(e, i)} type='text' className='form-control form-text'/>
                      <i className='fa fa-trash fa-lg add-margin-left-10' onClick={() => remove(i)}></i>
                    </div>
                  </DraggableItem>
                )}
              </Draggable>
            ))}
          </div>
        )}
        </Droppable>
      </DragDropContext>
    </Fragment>
  )
}

CustomTimeInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
}

SchedulesNew.propTypes = {
  setPage: PropTypes.func,
  customerId: PropTypes.string,
  data: PropTypes.object,
}

ScheduleItems.propTypes = {
  schedules: PropTypes.array,
  setSchedules: PropTypes.func,
}

export default SchedulesNew
