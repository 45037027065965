import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import {Input} from './commons'

const TeamCaptainsSection = ({changeSection, teams, golfers, teamCaptains, changeTeamCaptain}) => {

  const [ captainInputValues, setCaptainInputValues ] = useState({})

  useEffect(() => {
    const captains = {}
    teams.filter(t => t.name.trim().length > 0).forEach(t => captains[t.id] = getTeamCaptain(t)?.label)
    setCaptainInputValues(captains)
  }, [])

  const onCaptainInputChange = (inputValue, action, team) => {
    if (action === 'input-blur') {
      const value = getTeamCaptain(team)
      setCaptainInputValues({...captainInputValues, [team.id]: value ? value.label : ''})
    } else if (action === 'input-change') {
      setCaptainInputValues({...captainInputValues, [team.id]: inputValue})
    }
  }

  const onChangeCaptain = (option, team) => {
    changeTeamCaptain({...option, teamId: team.id})
    setCaptainInputValues({...captainInputValues, [team.id]: option ? option.label : ''})
  }

  const getCaptainOptionsForTeam = (team) => {
    return golfers.filter(g => g.id === teamCaptains[team.id] || !Object.values(teamCaptains).includes(g.id))
      .map(g => ({value: g.id, label: g.name}))
  }

  const getTeamCaptain = (team) => {
    const g = golfers.find(g => g.id === teamCaptains[team.id])
    if (g !== undefined && g !== null) {
      return {value: g.id, label: g.name}
    } else {
      return null
    }
  }

  return (
    <div className="section" id="team-captains-section">
      <hr/>
      <div className="section_header" onClick={(e) => changeSection(e)}>
        <div className="row no-margin-right">
          <div className="col-xs-11 col-sm-7 col-lg-6 title">
            <div className="label label-primary">3.</div>
            <span className="header_text">{window.I18n.t('draft.components.team_captains_component.title')}</span>
          </div>
          <div className="hidden-xs col-sm-4 col-lg-5"/>
          <div className="col-xs-1 caret-container add-padding-top-15">
            <i className="gga-carret-down"/>
            <i className="gga-carret-up"/>
          </div>
        </div>
      </div>
      <hr/>

      <div className="section_content btsp_square_solid hidden">
        {teams.filter(t => t.name.trim().length > 0).length === 0 ? <div className="row">
            <strong>{window.I18n.t('draft.components.team_captains_component.note')}</strong>
            {window.I18n.t('draft.components.team_captains_component.add_teams_first')}
          </div>
          : <>
            <div className="row">
              <div className="hidden-xs hidden-sm col-md-6 col-lg-4 no-padding-left">
                <label className="draft_label btsp_uppercase_text">{window.I18n.t('draft.components.team_captains_component.team')}</label>
              </div>
              <div className="hidden-xs hidden-sm col-md-6 col-lg-4">
                <label className="draft_label btsp_uppercase_text">{window.I18n.t('draft.components.team_captains_component.captain')}</label>
              </div>
            </div>
            <div className="row">
              {teams.filter(t => t.name.trim().length > 0).map((team, tIdx) =>
                <div key={tIdx} className="col-xs-12 col-sm-12 col-md-12 col-lg-12 team_comp">
                  <div className="col-xs-12 col-sm-9 col-md-6 col-lg-4">
                    <div className="col-xs-2 col-sm-2 hidden-md hidden-lg label_div">
                      <label className="draft_label">{window.I18n.t('draft.components.team_captains_component.team')}</label>
                    </div>
                    <div className="col-xs-10 col-sm-10 col-md-12 col-lg-12">
                      <input className="form-control form-text" type="text" value={team.name} disabled/>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-9 col-md-6 col-lg-4">
                    <div className="col-xs-2 col-sm-2 hidden-md hidden-lg label_div">
                      <label className="draft_label">{window.I18n.t('draft.components.team_captains_component.captain')}</label>
                    </div>
                    <div className="col-xs-10 col-sm-10 col-md-12 col-lg-12">
                      <Select options={getCaptainOptionsForTeam(team)}
                              value={getTeamCaptain(team)}
                              onChange={e => onChangeCaptain(e, team)}
                              inputValue={captainInputValues[team.id]}
                              onInputChange={(e, {action}) => onCaptainInputChange(e, action, team)}
                              controlShouldRenderValue={false}
                              isClearable={true}
                              menuPortalTarget={document.body}
                              styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                              placeholder={window.I18n.t('draft.components.team_captains_component.search_for_a_player')}
                              loadingMessage={() => window.I18n.t('draft.components.team_captains_component.loading')}
                              noOptionsMessage={() => window.I18n.t('draft.components.team_captains_component.no_players')}
                              classNamePrefix="captain_dropdown" components={{IndicatorSeparator: null, Input}}/>
                    </div>
                  </div>
                </div>)}
            </div>
          </>}
      </div>
    </div>
  )
}

TeamCaptainsSection.propTypes = {
  changeSection: PropTypes.func,
  teams: PropTypes.array,
  golfers: PropTypes.array,
  teamCaptains: PropTypes.any,
  changeTeamCaptain: PropTypes.func,
}

export default TeamCaptainsSection
