import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { GlgTooltip } from 'SharedComponents'
import { Col } from 'react-bootstrap'


class MembershipNameComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <Col sm={ this.props.colSmSize } xs={ 12 }><div className="panel panel-default"><div className="panel-heading text-center">{ !window.I18n ? '' : window.I18n.t('player_activity.registration.membership.membership_program') }</div><div className="panel-body text-center"><GlgTooltip tooltip={ this.props.membershipName }><span>{ _.truncate(this.props.membershipName, { length: 40 }) }</span></GlgTooltip></div></div></Col>
  }
}

MembershipNameComponent.propTypes = {
  membershipName: PropTypes.string.isRequired,
  colSmSize: PropTypes.number.isRequired,
}

MembershipNameComponent.defaultProps = {
  membershipName: '',
}

export default MembershipNameComponent
