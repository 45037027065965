import { ActionTypes } from '../actions/setup'

const {
  SET_SCHEDULE_FREQUENCY,
  SET_SCHEDULE_HOUR,
  SET_SCHEDULE_WEEKDAY,
  SET_SCHEDULE_DAY_OF_MONTH,
} = ActionTypes

const initialState = {}

function schedule(state = initialState, action) {
  switch (action.type){
    case SET_SCHEDULE_FREQUENCY:
      return {
        ...state,
        frequency: action.frequency,
      }
    case SET_SCHEDULE_HOUR:
      return {
        ...state,
        hour: action.hour,
      }
    case SET_SCHEDULE_WEEKDAY:
      return {
        ...state,
        weekday: action.weekday,
      }
    case SET_SCHEDULE_DAY_OF_MONTH:
      return {
        ...state,
        dayOfMonth: action.dayOfMonth,
      }
    default:
      return state
  }
}

export default schedule
