import { connect } from 'react-redux'
import SaveBarComponent from '../components/save_bar_component'

import { submitMonsterBoard, toggleShowRounds, toggleShowTotal, refreshData } from '../actions'

const mapStateToProps = (state, props) => {
  return {
    showMessage: props.showMessage,
    showRounds: state.misc.showRounds,
    showTotal: state.misc.showTotal,
    deviceView: state.misc.deviceView,
    lastDeviceUpdate: state.misc.lastDeviceUpdate,
  }
}

const mapDispatchToProps = (dispatch) => ({
  submit: () => dispatch(submitMonsterBoard()),
  toggleRounds: () => dispatch(toggleShowRounds()),
  toggleTotal: () => dispatch(toggleShowTotal()),
  refreshData: () => dispatch(refreshData()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaveBarComponent)
