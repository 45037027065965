import { connect } from 'react-redux'
import LeaderboardTableComponent from '../components/leaderboard_table_component'

import { getTableColumns, getSortedPlayers } from 'Shared/course_boards'
import {changeColumnSorting, addLine, removeLine, refreshData} from '../actions'

const mapStateToProps = (state) => {
  const isMultiRound = state.misc.isMultiRound
  const withTeams = state.misc.withTeams
  const showCourse = state.misc.showCourse
  const columns = getTableColumns(isMultiRound, withTeams, showCourse, false)
  
  return {
    players: getSortedPlayers(state),
    columns: columns,
    sortParams: state.sortParams,
    deviceView: state.misc.deviceView,
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeColumnSorting: (column, sortOrder) => dispatch(changeColumnSorting(column, sortOrder)),
  addLine: (memberIds, front9) => dispatch(addLine(memberIds, front9)),
  removeLine: (position, front9) => dispatch(removeLine(position, front9)),
  forceRefresh: () => dispatch(refreshData()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaderboardTableComponent)
