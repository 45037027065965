import React, { Component } from 'react'
import PropTypes from 'prop-types'

import PopupTypes from '../../popup_types'
import GeneralCreateEventButton from './general_create_event_button'

class QuickEventButton extends Component {
  constructor(props) {
    super(props)
    this.openPopup = props.openPopup.bind(this, PopupTypes.QUICK_EVENT)
  }

  render() {
    const actionNameText = (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.quick_event_button.quick_event_setup'))
    const actionDescriptionText = (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.quick_event_button.click_to_create'))
    return <GeneralCreateEventButton actionName={actionNameText} actionDescription={actionDescriptionText} iconName="golf-ball-time" openPopup={ this.openPopup } disabled={ this.props.disabled }></GeneralCreateEventButton>
  }
}

QuickEventButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  openPopup: PropTypes.func.isRequired,
}

export default QuickEventButton
