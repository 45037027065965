import { ActionTypes } from '../actions'
const {
  FETCH_INITIAL_DATA_REQUEST,
  FETCH_INITIAL_DATA_RECEIVED,
  FETCH_ALL_DATA_RECEIVED,
  ADD_TO_CART_RESPONSE,
} = ActionTypes

const initialMisc = {
}

const misc = (state = initialMisc, action) => {
  switch (action.type) {
    case FETCH_INITIAL_DATA_REQUEST:
      return {
        ...state,
        dataIsLoading: true,
        allDataIsLoading: true,
      }
    case FETCH_INITIAL_DATA_RECEIVED:
      return {
        ...state,
        ...action.data.misc,
        dataIsLoading: false,
      }
    case FETCH_ALL_DATA_RECEIVED:
      return {
        ...state,
        ...action.data.misc,
        allDataIsLoading: false,
      }
    case ADD_TO_CART_RESPONSE:
      return {
        ...state,
        cartMerchantAccountId: action.data.merchant_account_id,
        cartMerchantAccountType: action.data.merchant_account_type,
      }
    default:
      return state
  }
}

export default misc
