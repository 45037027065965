export const getTableColumns = (multiRound, withTeams, showCourse, isDraggable, sideBySide = false) => {
  let columns
  if (multiRound && !sideBySide) {
    columns = [ 'hole', 'today', 'total', 'actions' ]
  } else {
    columns = [ 'hole', 'today', 'actions' ]
  }

  if (showCourse) {
    columns.splice(1, 0, 'course')
  }

  if (withTeams) {
    columns = [ 'name' ].concat(columns)
  } else {
    columns = [ 'firstName', 'lastName' ].concat(columns)
  }

  if (isDraggable) {
    return [ 'drag' ].concat(columns)
  } else {
    return columns
  }
}

export const getColumnName = (column) => {
  switch (column) {
    case 'name':
      return window.I18n.t('course_leaderboards.name')
    case 'firstName':
      return window.I18n.t('course_leaderboards.first_name')
    case 'lastName':
      return window.I18n.t('course_leaderboards.last_name')
    case 'hole':
      return window.I18n.t('course_leaderboards.hole')
    case 'today':
      return window.I18n.t('course_leaderboards.today')
    case 'total':
      return window.I18n.t('course_leaderboards.total_to_par')
    case 'actions':
      return window.I18n.t('course_leaderboards.actions')
    case 'course':
      return window.I18n.t('course_leaderboards.course')
    default:
      return ''
  }
}

export const getColumnWidth = (column) => {
  switch (column) {
    case 'name':
      return '40%'
    case 'firstName':
      return '20%'
    case 'lastName':
      return '20%'
    case 'hole':
      return '10%'
    case 'today':
      return '15%'
    case 'total':
      return '15%'
    case 'actions':
      return '10%'
    case 'course':
      return '10%'
    default:
      return '1%'
  }
}

export const toIntValue = (value) => {
  value = value.replace('*', '')
  if ( value === 'E') {
    return 0
  } else if (value === 'F') {
    return 19
  } else if (isNaN(value)) {
    return 9999
  } else {
    return parseInt(value)
  }
}

export const getTotalColumnClass = (value) => {
  if (value === 'E' || parseInt(value) === 0) {
    return 'black_total'
  } else if (isNaN(value) || value === '' || value === null) {
    return ''
  } else {
    return parseInt(value) > 0 ? 'black_total' : 'red_total'
  }
}

export const getTodayColumnClass = (value) => {
  if (value === 'E') {
    return 'black_today_second'
  } else if (isNaN(value) || value === '' || parseInt(value) === 0 || value === null) {
    return ''
  } else {
    return parseInt(value) > 0 ? 'black_today_second' : 'red_today_second'
  }
}

export const getAbsColumnValue = (value) => {
  if (isNaN(value) || value === '' || value === null) {
    return value
  } else if ( parseInt(value) === 0 ) {
    return 'E'
  } else {
    return Math.abs(parseInt(value))
  }
}

export const getCellParClass = (value) => {
  if (isNaN(value) || value === '' || parseInt(value) >= 0 || value === null) {
    return ''
  } else {
    return 'red_cell'
  }
}

export const computePriorScore = (total, today) => {
  if (today === '-') {
    return toIntValue(total)
  } else {
    return toIntValue(total) - toIntValue(today)
  }
}
