import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FlyerImageList from './FlyerImageList'
import FlyerShapeList from './FlyerShapeList'
import UploadFlyerImage from './UploadFlyerImage'
import UploadFlyerShape from './UploadFlyerShape'

const FlyerImages = (props) => {

  const [ defaultImages, setDefaultImages ] = useState(props.defaultImages)
  const [ customImages, setCustomImages ] = useState(props.customImages)
  const [ shapes, setShapes ] = useState(props.shapes)
  const [ openUploadModal, setOpenUploadModal ] = useState(false)
  const [ newDefault, setNewDefault ] = useState(true)
  const [ openUploadImageModal, setOpenUploadShapeModal ] = useState(false)

  const openImageModal = (newDefault) => {
    setOpenUploadModal(true)
    setNewDefault(newDefault)
  }

  return (
    <div className="body">
      <div className="operative_buttons">
        <div className="add_image_button" onClick={() => openImageModal(false)}>
          <div className="btsp_bullet_button no-lite">
            <div className="icon">
              <i className='gga-file-plus'></i>
            </div>
            <div className="btsp_align-middle">
              { window.I18n.t('views.golfhub.new_custom_image') }
            </div>
          </div>
        </div>
        {props.isAdmin &&
         <>
          <div className="add_image_button" onClick={() => openImageModal(true)}>
            <div className="btsp_bullet_button no-lite">
              <div className="icon">
                <i className='gga-file-plus'></i>
              </div>
              <div className="btsp_align-middle">
                { window.I18n.t('views.golfhub.new_default_image') }
              </div>
            </div>
          </div>
          <div className="add_image_button" onClick={() => setOpenUploadShapeModal(true)}>
            <div className="btsp_bullet_button no-lite">
              <div className="icon">
                <i className='gga-file-plus'></i>
              </div>
              <div className="btsp_align-middle">
                { window.I18n.t('views.golfhub.new_shape') }
              </div>
            </div>
          </div>  
         </>
        }
      </div>
      <div className="list-of-images">
        <>
          <UploadFlyerImage
            leagueId={props.leagueId}
            openUploadModal={openUploadModal}
            setOpenUploadModal={setOpenUploadModal}
            defaultImages={defaultImages}
            setDefaultImages={setDefaultImages}
            customImages={customImages}
            setCustomImages={setCustomImages}
            newDefault={newDefault} 
            />
          <UploadFlyerShape 
            leagueId={props.leagueId}
            openModal={openUploadImageModal}
            setOpenModal={setOpenUploadShapeModal}
            shapes={shapes}
            setShapes={setShapes}
          />
          {props.isAdmin && 
            <FlyerImageList
              images={defaultImages}
              leagueId={props.leagueId}
              default={true}
              setDefaultImages={setDefaultImages}
            />
          }
          <FlyerImageList
            images={customImages}
            leagueId={props.leagueId}
            default={false}
            setDefaultImages={setCustomImages} 
          />
          <FlyerShapeList
            shapes={shapes}
            setShapes={setShapes}
            leagueId={props.leagueId}
          />
        </>
      </div>
    </div>
  )
}

FlyerImages.propTypes = {
  defaultImages: PropTypes.array,
  customImages: PropTypes.array,
  shapes: PropTypes.array,
  leagueId: PropTypes.string,
  isAdmin: PropTypes.bool,
}

export default FlyerImages
