import { combineReducers } from 'redux'

import misc from './misc'
import groupViewData from './group_view_data'
import filters from './filters'

export default combineReducers({
  misc,
  groupViewData,
  filters,
})
