import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
// import { Row, Col } from 'react-flexbox-grid'

import { GlgIcon } from 'SharedComponents'

class AdvancedFiltersToggle extends PureComponent {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange() {
    if (!this.props.disabled) {
      this.props.onChange('advancedFiltersOn', !this.props.open)
    }
  }

  render() {
    const icon = `${ this.props.open ? 'carret-up' : 'carret-down' }`
    const iconClass = `${ this.props.disabled ? '' : 'clickable' }`
    
    return <div className="advanced-filters-toggle" onClick={ this.handleChange }><div className="text cursor-default">{ !window.I18n ? '' : window.I18n.t('customer_directories.filters.advanced_filters_toggle_text') }</div><div className="icon" onClick={ this.handleChange }><GlgIcon icon={ icon } iconClass={iconClass}></GlgIcon></div></div>
  }
}

AdvancedFiltersToggle.propTypes = {
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default AdvancedFiltersToggle
