import { createSelector } from 'reselect'
import _ from 'lodash'

const getCategoriesById = (state, useManagedCustomer = false) => {
  if (useManagedCustomer) {
    return state.managedCustomerData.categories || state.categories
  } else {
    return state.categories
  }
}

const getSortedCategoryIds = createSelector(
  [ getCategoriesById ],
  (categoriesbyId) => {
    return _.sortBy(_.keys(categoriesbyId), categoryId => categoriesbyId[categoryId].position)
  },
)

export default getSortedCategoryIds
