import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getColumnName } from '../../helpers'
import { Draggable } from 'react-beautiful-dnd'

import {
  GlgCheckbox,
  GlgTooltip,
} from 'SharedComponents'

export class FieldsListComponent extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.getSortableField = this.getSortableField.bind(this)
  }

  handleChange(checked, value){
    if (checked){
      this.props.checkMemberField(value)
    } else {
      this.props.uncheckMemberField(value)
    }
  }
  
  getSortableField(provided, field){
    const {name} = getColumnName(field, this.props.indexType, this.props.prettyHandicapMethod, this.props.membershipPrograms)
    let checked = true
    if (field === 'full_name' && this.props.showHandleTooltip){
      checked = false
    } else if (field === 'last_name' && this.props.showLastNameTooltip){
      checked = false
    }
    return <li ref={provided.innerRef} {...provided.draggableProps} className="sortable-item"><div className="left_select"><div className="checkbox_container"><GlgCheckbox label={name} name={'league[options_leftfields][]'} value={field} checked={checked} onChange={this.handleChange} className="i-checks"></GlgCheckbox></div><div style={{position: 'relative', marginRight: '25px'}} {...provided.dragHandleProps} className="image drag_handle"><i className="fa fa-bars"></i></div><div className="clear"></div></div></li>
  }
  
  render() {
    let fieldsList
    if (this.props.checkedColumns){
      fieldsList = <ul className={'sortable-list to_left'} style={{listStyleType: 'none'}} {...this.props.provided.droppableProps} ref={this.props.provided.innerRef}>{this.props.fields.map((field, index) => <Draggable key={field} draggableId={field} index={index}>{(provided) => ((field === 'full_name' && this.props.showHandleTooltip) || (field === 'last_name' && this.props.showLastNameTooltip)) ? <GlgTooltip tooltip={ !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.fields_list_component.visible_columns') }>{ this.getSortableField(provided, field) }</GlgTooltip> : this.getSortableField(provided, field) }</Draggable>)}</ul>
    } else {
      fieldsList = <ul className={'sortable-list to_right'} style={{listStyleType: 'none'}}>{this.props.fields.map((field) => <li key={field}><GlgCheckbox label={getColumnName(field, this.props.indexType, this.props.prettyHandicapMethod, this.props.membershipPrograms).name} name={`league[options_${this.props.checkedColumns ? 'left' : 'right'}fields][]`} value={field} checked={this.props.checkedColumns} onChange={this.handleChange} className="i-checks"></GlgCheckbox></li>)}</ul>
    }
    return fieldsList
  }
}

FieldsListComponent.propTypes = {
  fields: PropTypes.array.isRequired,
  checkedColumns: PropTypes.bool.isRequired,
  showHandleTooltip: PropTypes.bool,
  showLastNameTooltip: PropTypes.bool,
  checkMemberField: PropTypes.func.isRequired,
  uncheckMemberField: PropTypes.func.isRequired,
  provided: PropTypes.any,
  indexType: PropTypes.string,
  prettyHandicapMethod: PropTypes.string,
  membershipPrograms: PropTypes.object.isRequired,
}

export default FieldsListComponent
