import React, { Component } from 'react'
import PropTypes from 'prop-types'

import PopupTypes from '../../popup_types'
import GeneralCreateEventButton from './general_create_event_button'

class CreateEventButton extends Component {
  constructor(props) {
    super(props)
    this.openPopup = props.openPopup.bind(this, PopupTypes.CREATE_EVENT)
    this.actionText = ''
  }

  getActionName() {
    if (this.props.customerOnlyEvents) {
      return (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.create_event_button.create_event'))
    } else if (this.props.customerOnlyLeagues) {
      return (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.create_event_button.create_league'))
    } else if (!this.props.canCreateTrips) {
      return (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.create_event_button.create_event_league'))
    } else {
      return (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.create_event_button.create_event_league_trip'))
    }
  }

  getActionDescription() {
    if (this.props.customerOnlyEvents) {
      return (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.create_event_button.create_event_from_scratch'))
    } else if (this.props.customerOnlyLeagues) {
      this.actionText = (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.create_event_button.create_league_from_scratch'))
      if (this.props.customerNeedsSubscription) {
        this.actionText += (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.create_event_button.card_charged'))
      }
      return this.actionText
    } else if (!this.props.canCreateTrips) {
      return (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.create_event_button.event_league_from_scratch'))
    } else {
      return (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.create_event_button.event_league_trip_from_scratch'))
    }
  }

  render() {
    return <GeneralCreateEventButton actionName={ this.getActionName() } actionDescription={ this.getActionDescription() } iconName="golf-ball-plus" openPopup={ this.openPopup } disabled={ this.props.disabled }></GeneralCreateEventButton>
  }
}

CreateEventButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  customerOnlyEvents: PropTypes.bool.isRequired,
  customerNeedsSubscription: PropTypes.bool.isRequired,
  customerOnlyLeagues: PropTypes.bool.isRequired,
  canCreateTrips: PropTypes.bool.isRequired,
  openPopup: PropTypes.func.isRequired,
}

export default CreateEventButton
