import { connect } from 'react-redux'

import RegistrationComponent from '../components/registration/registration_component'

const mapStateToProps = (state, props) => ({
  MRR: state.membershipPrograms.MRR,
  memberships: state.membershipPrograms.memberships,
  personalData: state.membershipPrograms.personalData,
  membershipPortalLink: state.membershipPrograms.membershipPortalLink,
  editRegistrationLink: state.membershipPrograms.editRegistrationLink,
  dataIsLoading: state.membershipPrograms.dataIsLoading,
  hasRenewals: state.membershipPrograms.hasRenewals,
  target: props.target,
})

export default connect(
  mapStateToProps,
)(RegistrationComponent)
