import { createSelector } from 'reselect'

const getLeaguesById = leaguesById => leaguesById
const getLeagueIds = (leaguesById, leagueIds) => leagueIds

const getLeaguesFromIds = createSelector(
  [ getLeagueIds, getLeaguesById ],
  (leagueIds, leaguesById) => {
    return leagueIds.map(leagueId => leaguesById[leagueId])
  }
)

export default getLeaguesFromIds
