import React, { Component } from 'react'
import Toggle from 'react-bootstrap-toggle'
import PropTypes from 'prop-types'

import SelectDefaultTournament from '../content/popups/select_default_tournament'
import WalkingScorerStationPopup from '../content/popups/walking_scorer_station_popup'
import DigitalScorecardsStationPopup from '../content/popups/digital_scorecards_station_popup'
import MobileScoreWarningPopup from '../content/popups/mobile_score_warning_popup'
class Scoring extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount() {
    $('.tournament-state').on('click', () => {
      // eslint-disable-next-line newline-per-chained-call
      if (this.props.content.roundId === $('#round_panel_selector').find('option:selected').val()) {
        setTimeout(
          () => this.props.refresh(),
          5000
        )
      }
    })

    $('#enable_digital_scorecards').on('change', () => {
      // eslint-disable-next-line newline-per-chained-call
      if (this.props.content.roundId === $('#round_panel_selector').find('option:selected').val()) {
        setTimeout(
          () => this.props.refresh(),
          5000
        )
      }
    })
    $('#real_time_mode').on('change', () => {
      // eslint-disable-next-line newline-per-chained-call
      if (this.props.content.roundId === $('#round_panel_selector').find('option:selected').val()) {
        setTimeout(
          () => this.props.refresh(),
          5000
        )
      }
    })
    $('#enable_walking_scorer').on('change', () => {
      // eslint-disable-next-line newline-per-chained-call
      if (this.props.content.roundId === $('#round_panel_selector').find('option:selected').val()) {
        setTimeout(
          () => this.props.refresh(),
          5000
        )
      }
    })
    $('#show_results_member_portal').on('change', () => {
      // eslint-disable-next-line newline-per-chained-call
      if (this.props.content.roundId === $('#round_panel_selector').find('option:selected').val()) {
        setTimeout(
          () => this.props.refresh(),
          5000
        )
      }
    })

    $('#enable_shot_trackings').on('change', () => {
      // eslint-disable-next-line newline-per-chained-call
      if (this.props.content.roundId === $('#round_panel_selector').find('option:selected').val()) {
        setTimeout(
          () => this.props.refresh(),
          5000
        )
      }
    })
  }

  render() {
    const { title, links, roundToggleText, roundStates, roundProgress, roundOptions, roundId, selectTournamentModal, walkingScorerStationModal, showMobileScoreWarning } = this.props.content
    return (
      <div className='widget-content-default widget-content-rounds widget-content-scoring'>
        <div className='title'>
          { title }

          <a href={ links.enter_scores.path } className='btn btn-primary widget-white-title-button'>
            { links.enter_scores.name }
          </a>
        </div>
        <div className='content'>
            {
              roundOptions.enable_digital_scorecards.show
                ? <div className='round-options'>
                  <a className={`toggle-option-label ${roundOptions.enable_digital_scorecards.status || $('#premium_customer_and_active_popup').val() === 'false' ? 'hide' : ''}`} id='show_digital_scorecards_popup_link' href={'#'} onClick={ () => {
                      $('#digital_scorecards_stations').modal('show')
                    }}>
                    { roundOptions.enable_digital_scorecards.name }
                  </a>
                  <a className={`toggle-option-label ${roundOptions.enable_digital_scorecards.status || $('#premium_customer_and_active_popup').val() === 'false' ? '' : 'hide'}`} id='go_digital_scorecards_page_link' href={links.round_digital_scorecards.path} >
                    { roundOptions.enable_digital_scorecards.name }
                  </a>
                  <Toggle id="toggle_dashboard_digital_scorecards" onClick={ () => { this.props.toggleRoundOptions(roundOptions.enable_digital_scorecards.path, 'enable_digital_scorecards', roundId) } }
                          on={ roundToggleText.yes }
                          off={ roundToggleText.no }
                          className='rounded'
                          disabled={ roundOptions.enable_digital_scorecards.disabled }
                          active={ roundOptions.enable_digital_scorecards.status } />
                </div>
                : null
            }

          {
            roundOptions.enable_mobile_score_entry.show
              ? <div className='round-options'>
                { roundOptions.enable_mobile_score_entry.name }
                <Toggle onClick={ () => { this.props.toggleRoundOptions(roundOptions.enable_mobile_score_entry.path, 'enable_mobile_score_entry', roundId) } }
                        on={ roundToggleText.yes }
                        off={ roundToggleText.no }
                        className='rounded'
                        disabled={ roundOptions.enable_mobile_score_entry.disabled }
                        active={ roundOptions.enable_mobile_score_entry.status } />
              </div>
              : null
          }

          {
            roundOptions.enable_walking_scorer.show
              ? <div className='round-options'>
                { roundOptions.enable_walking_scorer.name }
                <Toggle onClick={ () => { this.props.toggleRoundOptions(roundOptions.enable_walking_scorer.path, 'enable_walking_scorer', roundId) } }
                        on={ roundToggleText.yes }
                        off={ roundToggleText.no }
                        className='rounded'
                        disabled={ roundOptions.enable_walking_scorer.disabled }
                        active={ roundOptions.enable_walking_scorer.status } />
              </div>
              : null
          }

          {
            roundOptions.show_events_in_results_portal.show
              ? <div className='round-options'>
                { roundOptions.show_events_in_results_portal.name }
                <Toggle onClick={ () => { this.props.toggleRoundOptions(roundOptions.show_events_in_results_portal.path, 'show_events_in_results_portal', roundId) } }
                        on={ roundToggleText.yes }
                        off={ roundToggleText.no }
                        className='rounded'
                        disabled={ roundOptions.show_events_in_results_portal.disabled }
                        active={ roundOptions.show_events_in_results_portal.status } />
              </div>
              : null
          }

          {
            roundOptions.enable_shot_trackings.show
              ? <div className='round-options'>
                { roundOptions.enable_shot_trackings.name }
                <Toggle onClick={ () => { this.props.toggleRoundOptions(roundOptions.enable_shot_trackings.path, 'enable_shot_trackings', roundId) } }
                        on={ roundToggleText.yes }
                        off={ roundToggleText.no }
                        className='rounded'
                        disabled={ roundOptions.enable_shot_trackings.disabled }
                        active={ roundOptions.enable_shot_trackings.status }/>
              </div>
              : null
          }
          
          {
            roundOptions.enable_double_score_verification.show
              ? <div className='round-options'>
                <a href={ links.double_score_verification.path } className='toggle-option-label'>
                  { roundOptions.enable_double_score_verification.name }
                </a>
                <Toggle onClick={ () => { this.props.toggleRoundOptions(roundOptions.enable_double_score_verification.path, 'enable_double_score_verification', roundId) } }
                        on={ roundToggleText.yes }
                        off={ roundToggleText.no }
                        className='rounded'
                        disabled={ roundOptions.enable_double_score_verification.disabled }
                        active={ roundOptions.enable_double_score_verification.status } />
              </div>
              : null
          }
          


          <div className='round-status'>
            <div className='btn-group' role='group'>
              {
                roundStates.round_not_started.enabled
                  ? <button
                      type='button'
                      className={ `btn btn-default btn-progress ${roundProgress === 'not started' ? 'active' : ''}` }
                      onClick={ () => this.props.setRoundStatus('not started', roundStates.round_not_started.path, roundId) } >
                    { roundStates.round_not_started.name }
                  </button>
                  : null
              }
              {
                roundStates.round_in_progress.enabled
                  ? <button
                      type='button'
                      className={ `btn btn-default btn-progress ${roundProgress === 'in progress' ? 'active' : ''}` }
                      onClick={ () => this.props.setRoundStatus('in progress', roundStates.round_in_progress.path, roundId) } >
                    { roundStates.round_in_progress.name }
                  </button>
                  : null
              }
              {
                roundStates.round_completed.enabled
                  ? (showMobileScoreWarning && roundOptions.enable_mobile_score_entry.status)
                    ? <button
                        type='button'
                        className={ `btn btn-default btn-progress ${roundProgress === 'completed' ? 'active' : ''}` }
                        onClick={ () => {
                          $('.widget-content-scoring #disable_mobile_scoring_modal').modal('show')
                        }}>
                      { roundStates.round_completed.name }
                    </button>
                    : <button
                        type='button'
                        className={ `btn btn-default btn-progress ${roundProgress === 'completed' ? 'active' : ''}` }
                        onClick={ () => this.props.setRoundStatus('completed', roundStates.round_completed.path, roundId) } >
                      { roundStates.round_completed.name }
                    </button>
                  : null
              }
            </div>
          </div>
          {/* {
            // eslint-disable-next-line no-unused-vars
            Object.entries(scores).map(([ _key, tmpScore ], index) =>
              <div key={ `${index}-${tmpScore.name}-${tmpScore.value}`} className={ `data-buttons ${index === 0 ? 'first' : ''}` }>
                { tmpScore.name }
                <span className='data'>
                  { tmpScore.value }
                </span>
                <span>
                  |
                </span>
              </div>
            )
          } */}
          <br />
          <div className='buttons-container'>
            <a href={ links.tv_display.path } className='btn btn-primary widget-white-content-button bottom-button no-lite'>
              { links.tv_display.name }
            </a>

            {
              links.group_view.show
                ? <a href={ links.group_view.path } className='btn btn-primary widget-white-content-button bottom-button'>
                  { links.group_view.name }
                </a>
                : null
            }

            {
              links.shot_by_shot_view.show 
                ? <a href={ links.shot_by_shot_view.path } className='btn btn-primary widget-white-content-button bottom-button'>
                  { links.shot_by_shot_view.name }
                </a>
                : null
            }

            <a href={ links.post_scores.path } className='btn btn-primary widget-white-content-button bottom-button'>
              { links.post_scores.name }
            </a>
          </div>
        </div>

        <SelectDefaultTournament
          onClose={ () => this.props.selectTournamentToggleModal(false) }
          onSave={ () => this.props.setDefaultRoundTournament(selectTournamentModal.setDefaultTournamentsPath, selectTournamentModal.selected) }
          title={ selectTournamentModal.title }
          text={ selectTournamentModal.text }
          show={ selectTournamentModal.show }
          setSelectedDefaultRoundTournament={ tournamentId => this.props.setSelectedDefaultRoundTournament(tournamentId) }
          tournaments={ selectTournamentModal.tournaments } />

        <WalkingScorerStationPopup
          onClose={ () => this.props.walkingScorerStationToggleModal(false) }
          onSave={ (newSettings) => this.props.saveWalkingScorerStationSettings(walkingScorerStationModal.walkingScorerStationSavePath, newSettings) }
          title={ walkingScorerStationModal.title }
          show={ walkingScorerStationModal.show }
          playoffHoles= {walkingScorerStationModal.playoffHoles}
          walkingScorerStationSettings={ () => walkingScorerStationModal.walkingScorerStationSettings } />

        <DigitalScorecardsStationPopup
          link={ links.round_digital_scorecards.path}/>

        <MobileScoreWarningPopup
          toggleTournamentStatusLink={ links.toggle_tournament_status.path }
          disableMobileScoringLink={ links.disable_mobile_scoring.path }/>
      </div>
    )
  }
}

Scoring.propTypes = {
  content: PropTypes.object,
  setRoundStatus: PropTypes.func,
  toggleRoundOptions: PropTypes.func,
  selectTournamentToggleModal: PropTypes.func,
  walkingScorerStationToggleModal: PropTypes.func,
  saveWalkingScorerStationSettings: PropTypes.func,
  refresh: PropTypes.func,
  setSelectedDefaultRoundTournament: PropTypes.func,
  setDefaultRoundTournament: PropTypes.func,
}


Scoring.defaultProps = {
  content: {
    enter_scores_path: '#',
    scores_not_entered: 0,
    partial_scores: 0,
    completed_scores: 0,
    tv_display_path: '#',
    post_scores_path: '#',
  },
}

export default Scoring
