// List of key settings supported
export default [
  //Events & Leagues
  { 
    key: 'play_at', 
    level: 'Events & Leagues', 
    type: 'date', 
    display: window.I18n.t('key_settings.table.date'),
  },
  { 
    key: 'master_roster_coupling', 
    level: 'Events & Leagues', 
    type: 'boolean', 
    display: window.I18n.t('key_settings.table.master_roster_coupling'), 
    values: [ { data: window.I18n.t('key_settings.generic.data_on') }, { data: window.I18n.t('key_settings.generic.data_off') } ],
  },
  { 
    key: 'min_number_of_members', 
    level: 'Events & Leagues', 
    type: 'number', 
    display: window.I18n.t('key_settings.table.min_number_of_members'),
  },
  { 
    key: 'max_number_of_members', 
    level: 'Events & Leagues', 
    type: 'number', 
    display: window.I18n.t('key_settings.table.max_number_of_members'),
  },
  { 
    key: 'min_number_of_guests', 
    level: 'Events & Leagues', 
    type: 'number', 
    display: window.I18n.t('key_settings.table.min_number_of_guests'),
  },
  { 
    key: 'max_number_of_guests', 
    level: 'Events & Leagues', 
    type: 'number', 
    display: window.I18n.t('key_settings.table.max_number_of_guests'),
  },
  { 
    key: 'registration_starts_on', 
    level: 'Events & Leagues', 
    type: 'date', 
    display: window.I18n.t('key_settings.table.registration_opens'),
  },
  { 
    key: 'registration_ends_on', 
    level: 'Events & Leagues', 
    type: 'date', 
    display: window.I18n.t('key_settings.table.registration_closes'),
  },
  { 
    key: 'registration_starts_on_for_invitations', 
    level: 'Events & Leagues', 
    type: 'date', 
    display: window.I18n.t('key_settings.table.registration_opens_invitation'),
  },
  { 
    key: 'communication_officer', 
    level: 'Events & Leagues', 
    type: 'string', 
    display: window.I18n.t('key_settings.table.communication_officer'),
  },
  { 
    key: 'handicaps_updated_via', 
    level: 'Events & Leagues', 
    type: 'string', 
    display: window.I18n.t('key_settings.table.hi_service'),
  },
  { 
    key: 'variable_settings_auto_update_hi_from_mr', 
    level: 'Events & Leagues', 
    type: 'boolean', 
    display: window.I18n.t('key_settings.table.auto_hi_update_from_mr_into_er'),
    values: [ { data: window.I18n.t('key_settings.generic.data_on') }, { data: window.I18n.t('key_settings.generic.data_off') } ],
  },
  { 
    key: 'variable_settings_show_default_tee_by_gender', 
    level: 'Events & Leagues', 
    type: 'boolean', 
    display: window.I18n.t('key_settings.table.show_default_tee'),
    values: [ { data: window.I18n.t('key_settings.generic.data_on') }, { data: window.I18n.t('key_settings.generic.data_off') } ],
  },
  { 
    key: 'variable_settings_default_course', 
    level: 'Events & Leagues', 
    type: 'string', 
    display: window.I18n.t('key_settings.table.default_course'),
  },
  { 
    key: 'variable_settings_hole_type', 
    level: 'Events & Leagues', 
    type: 'string', 
    display: window.I18n.t('key_settings.table.hole_type'),
  },
  { 
    key: 'variable_settings_default_tee_m', 
    level: 'Events & Leagues', 
    type: 'string', 
    display: window.I18n.t('key_settings.table.default_tee_m'),
  },
  { 
    key: 'variable_settings_default_tee_f', 
    level: 'Events & Leagues', 
    type: 'string', 
    display: window.I18n.t('key_settings.table.default_tee_f'),
  },
  { 
    key: 'courses_enabled', 
    level: 'Events & Leagues', 
    type: 'string', 
    display: window.I18n.t('key_settings.table.courses_enabled'),
  },
  // Rounds
  { 
    key: 'play_at', 
    level: 'Rounds', 
    type: 'date', 
    display: window.I18n.t('key_settings.table.date'),
  },
  { 
    key: 'allow_players_to_choose_tee_time', 
    level: 'Rounds', 
    type: 'boolean', 
    display: window.I18n.t('key_settings.table.open_tee_times'),
    values: [ { data: window.I18n.t('key_settings.generic.data_on') }, { data: window.I18n.t('key_settings.generic.data_off') } ],
  },
  { 
    key: 'variable_settings_auto_move_in_progress', 
    level: 'Rounds', 
    type: 'boolean', 
    display: window.I18n.t('key_settings.table.auto_move_round_to_in_progress'),
    values: [ { data: window.I18n.t('key_settings.generic.data_on') }, { data: window.I18n.t('key_settings.generic.data_off') } ],
  },
  { 
    key: 'variable_settings_auto_move_in_progress_hour', 
    level: 'Rounds', 
    type: 'string', 
    display: window.I18n.t('key_settings.table.move_to_in_progress_at'),
  },
  { 
    key: 'variable_settings_auto_move_to_completed', 
    level: 'Rounds', 
    type: 'boolean', 
    display: window.I18n.t('key_settings.table.auto_move_round_to_completed'),
    values: [ { data: window.I18n.t('key_settings.generic.data_on') }, { data: window.I18n.t('key_settings.generic.data_off') } ],
  },
  { 
    key: 'variable_settings_auto_move_to_completed_hour', 
    level: 'Rounds', 
    type: 'string', 
    display: window.I18n.t('key_settings.table.move_to_completed_at'),
  },
  { 
    key: 'variable_settings_whsuk_is_qualifying', 
    level: 'Rounds', 
    type: 'boolean', 
    display: window.I18n.t('key_settings.table.qualifying'), 
    values: [ { data: window.I18n.t('key_settings.generic.data_on') }, { data: window.I18n.t('key_settings.generic.data_off') } ],
  },
  { 
    key: 'variable_settings_automatically_post_scores', 
    level: 'Rounds', 
    type: 'boolean', 
    display: window.I18n.t('key_settings.table.auto_score_posting'), 
    values: [ { data: window.I18n.t('key_settings.generic.data_on') }, { data: window.I18n.t('key_settings.generic.data_off') } ],
  },
  { 
    key: 'variable_settings_show_default_tee_by_gender', 
    level: 'Rounds', 
    type: 'boolean', 
    display: window.I18n.t('key_settings.table.show_default_tee'),
    values: [ { data: window.I18n.t('key_settings.generic.data_on') }, { data: window.I18n.t('key_settings.generic.data_off') } ],
  },
  { 
    key: 'variable_settings_default_course', 
    level: 'Rounds', 
    type: 'string', 
    display: window.I18n.t('key_settings.table.default_course'),
  },
  { 
    key: 'variable_settings_hole_type', 
    level: 'Rounds', 
    type: 'string', 
    display: window.I18n.t('key_settings.table.hole_type'),
  },
  { 
    key: 'variable_settings_default_tee_m', 
    level: 'Rounds', 
    type: 'string', 
    display: window.I18n.t('key_settings.table.default_tee_m'),
  },
  { 
    key: 'variable_settings_default_tee_f', 
    level: 'Rounds', 
    type: 'string', 
    display: window.I18n.t('key_settings.table.default_tee_f'),
  },
]
