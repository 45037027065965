import React from 'react'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'
import PropTypes from 'prop-types'

import TvShowSlideshowContainer from './tv_show_slideshow_container'
import slideshowReducer from '../reducers/tv_shows_slideshow'

const TvShowSlideshowRoot = (props) => {
  const state = initialState(props)
  const store = configureStore(slideshowReducer, state)

  return <GlobalProvider store={ store }><TvShowSlideshowContainer paths={ props.paths }></TvShowSlideshowContainer></GlobalProvider>
}

const initialState = (props) => {
  return {
    slideshowData: {
      isFireTv: props.isFireTv,
      currentSlide: {
        index: 0,
      },
      slides: props.slides,
      tvShowSettings: props.tvShowSettings,
      blueBox: {
        show: false,
        fontSize: 14,
        scrollRate: 1,
        topMargin: 0,
        bottomMargin: 0,
        sideMargin: 0,
        tickerDisplay: 'center',
        colorTheme: 'light',
        isFullscreen: false,
        isScrolling: true,
      },
    },
  }
}

TvShowSlideshowRoot.propTypes = {
  tvShowSettings: PropTypes.object.isRequired,
  paths: PropTypes.object.isRequired,
}

export default TvShowSlideshowRoot
