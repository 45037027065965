import { createSelector } from 'reselect'
import _ from 'lodash'

const getLeaguesById = (state, { useManagedCustomer = false } = {}) => {
  if (useManagedCustomer) {
    return state.managedCustomerData.leagues || state.leagues
  } else {
    return state.leagues
  }
}
const getToursCount = (state, { useManagedCustomer = false } = {}) => {
  let tours
  if (useManagedCustomer) {
    tours = state.managedCustomerData.tours || state.tours
  } else {
    tours = state.tours
  }
  return _.size(tours)
}
const getToursEnabled = (state, { useManagedCustomer = false } = {}) => {
  if (useManagedCustomer) {
    return state.managedCustomerData.toursEnabled || state.misc.toursEnabled
  } else {
    return state.misc.toursEnabled
  }
}
const getCheckLeagues = (state, { checkLeagues = false } = {}) => checkLeagues

const checkShowTours = createSelector(
  [ getLeaguesById, getToursCount, getToursEnabled, getCheckLeagues ],
  (leaguesById, toursCount, toursEnabled, checkLeagues) => (
    toursEnabled
      && toursCount > 0
      && (!checkLeagues || _.some(leaguesById, league => !!league.tour) || _.isEmpty(leaguesById))
  ),
)

export default checkShowTours
