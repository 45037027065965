import { ActionTypes } from '../actions'
const {
  CHANGE_SORTING_COLUMN,
  CHANGE_SORTING_ORDER,
  CHANGE_FILTER,
} = ActionTypes

const initialSorting = {
  column: 'position',
  order: '',
}

const sorting = (state = initialSorting, action) => {
  if (!state.column) {
    state = {
      ...initialSorting,
      ...state,
    }
  }
  switch (action.type) {
    case CHANGE_SORTING_COLUMN:
      return {
        ...state,
        column: action.column,
      }
    case CHANGE_SORTING_ORDER:
      return {
        ...state,
        order: action.order,
      }
    case CHANGE_FILTER:
      if (action.filter === 'viewType' &&
          action.value === 'category' &&
          state.column === 'categoryName') {
        return {
          ...state,
          column: 'position',
        }
      } else {
        return state
      }
    default:
      return state
  }
}

export default sorting
