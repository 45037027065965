import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { Row } from 'react-flexbox-grid'
import GroupViewFiltersContainer from '../containers/group_view_filters_container'
import GroupViewTableContainer from '../containers/group_view_table_container'
import { GlgLoadingSpinner } from 'SharedComponents'

class GroupViewComponent extends Component {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    if (this.props.roundId) {
      this.props.fetchInitialGroupViewData()
      this.timer = setInterval(this.props.refreshData, 60000)
    }

    this.scrollingWindow = window.parent.location === window.location || window.parent.$('#embeded').length > 0 || window.parent.$('#multi-widget-admin').length > 0 ? window : window.parent
    this.initialHeaderPositionTop = 0
    this.scrollingWindow.addEventListener('scroll', this.handleScroll)
  }

  componentDidUpdate() {
    setTimeout(() => {
      try {
        this.initialHeaderPositionTop = $('.group_view_table thead').offset().top
        if (this.scrollingWindow.location !== window.location) {
          this.initialHeaderPositionTop += this.scrollingWindow.$('[name=page_iframe]').offset().top
        }
      } catch (error) {
        this.initialHeaderPositionTop = 0
      }
    }, 500)
  }

  handleScroll() {
    if (this.scrollingWindow.pageYOffset > this.initialHeaderPositionTop) {
      $('.group_view_table thead').css('transform', `translateY(${this.scrollingWindow.pageYOffset - this.initialHeaderPositionTop}px)`)
    } else {
      $('.group_view_table thead').css('transform', 'translateY(0)')
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
    this.scrollingWindow.removeEventListener('scroll', this.handleScroll)
  }
  
  render() {
    return ( 
      <Fragment>
        {this.props.isLoading && 
          <GlgLoadingSpinner 
            show={this.props.isLoading}
            className='add-margin-top-20 text-center'
            width={85} 
            height={85}
            />}
        {!this.props.isLoading &&
          <Row className='no-margin'>
            {!this.props.isPortal && <GroupViewFiltersContainer.GroupViewFilters />}
            {this.props.isPortal && <GroupViewFiltersContainer.GroupViewPortalFilters />}
          </Row>}
        {!this.props.isLoading && this.props.isPortal && this.props.selectedTournamentName &&
          <div className='tournament_container'>
            <h2 className='tournament'>
              <div>{this.props.selectedTournamentName}</div>
            </h2>
          </div>
        }
        {!this.props.isLoading && (!this.props.hasResults || !this.props.roundId) &&
          <div className='text-center add-margin-top-20'>{!window.I18n ? '' : window.I18n.t(this.props.selectedTournamentName === '' ? 'group_view.no_tournament_selected' : 'group_view.no_tournament')}</div>
        }
        {!this.props.isLoading && this.props.hasResults &&
          <div className='group_views'>
            <GroupViewTableContainer/>
          </div>
        }
      </Fragment>
    )

  }
}

GroupViewComponent.propTypes = {
  fetchInitialGroupViewData: PropTypes.func,
  isLoading: PropTypes.bool,
  isPortal: PropTypes.bool,
  hasResults: PropTypes.bool,
  selectedTournamentName: PropTypes.string,
  roundId: PropTypes.string,
  refreshData: PropTypes.func,
}

export default GroupViewComponent
