import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

import DirectoriesTitle from '../components/directories_title'
import FiltersLabelComponent from '../components/filters_label_component'
import DirectoriesFiltersContainer from '../containers/directories_filters_container'
import DirectoriesContentContainer from '../containers/directories_content_container'
import IntlPolyfill from 'react-intl-polyfill'

class DirectoriesComponent extends Component {
  constructor(props) {
    super(props)
    this.scrollMethod = this.scrollMethod.bind(this)
  }

  componentDidMount() {
    if (this.props.shouldLoadData) {
      this.props.fetchData(this.props.tourId)
    }
    try {
      window.parent.addEventListener('scroll', this.scrollMethod)  
    } catch (err) {
      // 1 == 1
    }
  }

  scrollMethod() {
    if (!this.props.dataIsLoading) {
      const initialScroll = window.parent.pageYOffset
      setTimeout(() => {
        const scrollAfterDelay = window.parent.pageYOffset

        if (initialScroll === scrollAfterDelay) {
          this.props.changeFilter('scroll', initialScroll)
        }
      }, 2000)
    }
  }
  render() {
    let filterClass = 'categories-filters-wrapper'
    if (!this.props.shouldShowFilters) {
      filterClass += ' hidden'
    }
    let subtitle = this.props.directoryName
    if (this.props.directoryName === 'Local Tournaments') {
      if (this.props.tourName) {
        subtitle = this.props.directoryName + ' ● ' + this.props.tourName
      }
    }

    return <IntlPolyfill locales={ [ 'en', 'fr', 'vi' ] }><div id="directories_content"><DirectoriesTitle title={this.props.customerName} subtitle={subtitle} description={this.props.directoryDescription} shouldHideHeader={this.props.shouldHideHeader}></DirectoriesTitle><Row className={filterClass}><Col xs={true}><DirectoriesFiltersContainer></DirectoriesFiltersContainer></Col></Row><FiltersLabelComponent filters={this.props.filters} dataIsLoading={this.props.dataIsLoading} myRegistrationsVisible={this.props.myRegistrationsVisible} shouldShowLotteryFilter={this.props.shouldShowLotteryFilter}></FiltersLabelComponent><DirectoriesContentContainer dataIsLoading={false} allDataIsLoading={false}></DirectoriesContentContainer></div></IntlPolyfill>
  }
}

DirectoriesComponent.propTypes = {
  shouldLoadData: PropTypes.bool,
  fetchData: PropTypes.func,
  customerName: PropTypes.string,
  directoryName: PropTypes.string,
  tourName: PropTypes.string,
  tourId: PropTypes.string,
  changeFilter: PropTypes.func,
  dataIsLoading: PropTypes.bool,
  filters: PropTypes.shape({
    status: PropTypes.array,
    startDate: PropTypes.string,
  }),
  myRegistrationsVisible: PropTypes.bool,
  directoryDescription: PropTypes.string,
  shouldShowLotteryFilter: PropTypes.bool,
  shouldShowFilters: PropTypes.bool,
  shouldHideHeader: PropTypes.bool,
}
export default DirectoriesComponent
