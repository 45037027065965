import React, { Component } from 'react'
import PropTypes from 'prop-types'

class MenuItemCloseButton extends Component {
  constructor(props){
    super(props)
  }

  render(){
    return (
      <button className="dashboard-menu-item md-visible close-button" onClick={ () => this.props.toggleMenu() }>
        <i className="fa fa-times-circle-o"></i> Close
      </button>
    )
  }
}

MenuItemCloseButton.propTypes = {
  toggleMenu: PropTypes.func,
}

export default MenuItemCloseButton
