import React, { useEffect, useState, useRef } from 'react'
import OttDesktopLayout from './ott_desktop_layout'
import OttMobileLayout from './ott_mobile_layout'
import OttHeader from './ott_header'
import OttTitle from './ott_title'
import OttFooter from './ott_footer'
import OttRefreshStatusBar from './ott_refresh_status_bar'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'
import OttScreen from '../ott_screen'


const OttLayout = () => { 
  const [ isMobile, setIsMobile ] = useState(window.innerWidth <= 800)
  const headerRef = useRef(null)
  const [ mobileHeaderHeightOffset, setMobileHeaderHeightOffset ] = useState(0) // eslint-disable-line no-unused-vars
  const isAdminMode = useSelector(state => state.isAdminMode)
  const activeScreen = useSelector(state => state.activeScreen)

  useEffect(() => {
    const websiteContainer = window.parent.document.querySelector('body.website.container')

    const cleanUpBeforeLeavingOttPage = () => {
      const ottHeaderWrapper = window.parent.document.getElementById('ott-header-wrapper')
      if (ottHeaderWrapper) {
        ottHeaderWrapper.remove()
      }
      
      const ottMobileFooter = window.parent.document.querySelector('.ott-mobile-footer')
      if (ottMobileFooter) {
        ottMobileFooter.remove()
      }

      // Reset overflow before leaving the page
      if (websiteContainer) {
        websiteContainer.style.overflow = ''
      }
    }

    if (isMobile) {
      window.parent.scrollTo({top: 0})
      
      const headerWrapperDiv = document.createElement('div')
      headerWrapperDiv.id = 'ott-header-wrapper'
      window.parent.document.getElementById('main_content').prepend(headerWrapperDiv)

      if (websiteContainer) {
        websiteContainer.style.overflow = 'hidden'
      }
  
      window.addEventListener('visibilitychange', cleanUpBeforeLeavingOttPage)
    }

    // Observe header height changes and adjust the mobile layout offset accordingly
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === headerRef.current) {
          setMobileHeaderHeightOffset(entry.contentRect.height)
        }
      }
    })
    

    if (headerRef.current) {
      resizeObserver.observe(headerRef.current)
    }


    // Observe window resize and display the desktop/mobile layout accordingly
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      // if (headerRef.current) { resizeObserver.unobserve(headerRef.current) }
      window.removeEventListener('resize', handleResize)
      
      if (isMobile) {
        cleanUpBeforeLeavingOttPage()
      }
    }
  }, [ isMobile ])

  return (
    <body className="ott-v2">
      <div className="ott-page-root">
        {isMobile
          ?
          <>
            {/* <OttTitle/> */}
            {isAdminMode
              ?
                <section className="ott-mobile-header margin-bottom-20-px height-auto" ref={headerRef}>
                  <OttHeader/>
                </section>
              :
              createPortal(
                <section className="ott-mobile-header" ref={headerRef}>
                  <OttHeader/>
                </section>
              , window.parent.document.getElementById('main_content').firstElementChild)
            }

            <section className="ott-mobile-layout">
              <OttMobileLayout/>
            </section>
            
            {createPortal(
              <section className="ott-mobile-footer">
                <OttFooter/>
              </section>
              , window.parent.document.getElementById('main_content'))}
          </>
          :
          <>
            {activeScreen === OttScreen.CREATE_BOOKING ?
              <div className="desktop-header-container">
                <OttTitle/>
                <OttHeader/>
              </div>
              :
              <>
              <OttTitle/>
              <OttHeader/>
              </>
            }
            <OttDesktopLayout/>
            <OttFooter/>
            {createPortal(<OttRefreshStatusBar/>, window.parent.document.getElementById('main_content'))}
          </>
        }
      </div>
    </body>
  )
}

export default OttLayout
