import _ from 'lodash'
import createDeepSelector from './create_deep_selector'

import { getSortedDefaultTeesIds } from './index'

const _getSortedDefaultTeesIds = (state, courseId) => {
  return getSortedDefaultTeesIds(state, courseId)
}
const getDefaultTees = (state, courseId) => {
  if (Object.keys(state).length > 0 && courseId){
    return state.find(x => x.id === courseId).tees
  } else {
    return {}
  }
}

const getDefaultTeesOptions = createDeepSelector(
  [ getDefaultTees, _getSortedDefaultTeesIds ],
  (defaultTeesesById, sortedDefaultTeesIds) => {
    const defaultTeesOptions = sortedDefaultTeesIds.map(
      categoryId => _.pick(defaultTeesesById[categoryId], [ 'id', 'name' ]),
    )
    return defaultTeesOptions
  },
)

export default getDefaultTeesOptions
