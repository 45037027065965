import { ActionTypes } from '../actions' 

const {
  REFRESH_DATA,
  VERIFY_SCORE,
  VERIFY_TEAM_SCORE,
} = ActionTypes

const initialState = {
}

const misc = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_DATA:
      return {
        ...state,
        ...action.data,
      }
    case VERIFY_SCORE:
      return {
        ...state,
        foursomes: state.foursomes.map( foursome => {
          return ( foursome.id === action.data.foursome_id ) ? 
            {
              ...foursome,
              size: foursome.size - 1,
              players: foursome.players.map( player => {
                return ( player.id === action.data.player_id ) ?
                  {
                    ...player,
                    verified_for_step: action.data.verified_for_step,
                  } 
                  : player
              }),
            }
            : foursome
        }),
      }
    case VERIFY_TEAM_SCORE:
      return {
        ...state,
        foursomes: state.foursomes.map( foursome => {
          return ( foursome.id === action.data.foursome_id ) ?
            {
              ...foursome,
              size: foursome.size - action.data.size_to_subtract,
              teams: foursome.teams.map( team => {
                return ( team.pretty_team_name === action.data.team_name ) ?
                  {
                    ...team,
                    verified_for_step: action.data.verified_for_step,
                  }
                  : team
              }),
            }
            : foursome
        }),
      }
    default:
      return state
  }
}

export default misc
