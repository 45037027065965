import React from 'react'
import PropTypes from 'prop-types'
import {Pagination} from 'react-bootstrap'

const ReportCenterListPagination = ({ ...props }) => {
  const nrPages = Math.ceil(props.elementsCount / props.perPage)
  const isCurrentPageFirst = props.page === 1
  const isCurrentPageLast = props.page === nrPages

  let showEllipsis
  const pageNumbers = [ ...new Array(nrPages) ].map((_, index) => {
    const pageNumber = index + 1
    const isPageNumberFirst = pageNumber === 1
    const isPageNumberLast = pageNumber === nrPages
    const isCurrentPageVisible = Math.abs(pageNumber - props.page) <= 1
    if (isPageNumberFirst || isPageNumberLast || isCurrentPageVisible) {
      showEllipsis = false
      return (
        <Pagination.Item key={ pageNumber } active={ pageNumber === props.page } onClick={ () => changePage(pageNumber) }>
          { pageNumber }
        </Pagination.Item>
      )
    }
    if (!showEllipsis) {
      showEllipsis = true
      return (
        <Pagination.Ellipsis key={ pageNumber } />
      )
    }
    return null
  })

  const changePage = (nr) => {
    if (nr < 1 || nr > nrPages) {
      return
    }

    if (props.page !== nr) {
      props.setCurrentPage(nr)
    }
  }

  return (
    <div className="pagination-container">
      <Pagination>
        <Pagination.Prev disabled={ isCurrentPageFirst } onClick={ () => changePage(props.page - 1) } />
        { pageNumbers }
        <Pagination.Next disabled={ isCurrentPageLast } onClick={ () => changePage(props.page + 1) } />
      </Pagination>
    </div>
  )
}

ReportCenterListPagination.propTypes = {
  page: PropTypes.number,
  perPage: PropTypes.number,
  elementsCount: PropTypes.number,
  setCurrentPage: PropTypes.func,
}

export default ReportCenterListPagination
