import { showErrorNotification } from 'Shared/helpers'

export const ActionTypes = {
  CHANGE_FILTER: 'CHANGE_FILTER',
  FETCH_INITIAL_DATA_REQUEST: 'FETCH_INITIAL_DATA_REQUEST',
  FETCH_INITIAL_DATA_RECEIVED: 'FETCH_INITIAL_DATA_RECEIVED',
  FETCH_ALL_DATA_RECEIVED: 'FETCH_ALL_DATA_RECEIVED',
  ADD_TO_CART_REQUEST: 'ADD_TO_CART_REQUEST',
  ADD_TO_CART_RESPONSE: 'ADD_TO_CART_RESPONSE',
}

const fetchInitialDataRequest = () => ({
  type: ActionTypes.FETCH_INITIAL_DATA_REQUEST,
})

const fetchInitialDataReceived = (data) => ({
  type: ActionTypes.FETCH_INITIAL_DATA_RECEIVED,
  data,
})

const fetchAllDataReceived = (data) => ({
  type: ActionTypes.FETCH_ALL_DATA_RECEIVED,
  data,
})

const addToCartRequest = () => ({
  type: ActionTypes.ADD_TO_CART_REQUEST,
})

const addToCartResponse = (data) => ({
  type: ActionTypes.ADD_TO_CART_RESPONSE,
  data,
})

const changeFilterAction = (filter, value) => ({
  type: ActionTypes.CHANGE_FILTER,
  filter,
  value,
})
const clearFilters = (dispatch, state) => {
  dispatch(changeFilterAction('startDate', ''))
  dispatch(changeFilterAction('endDate', ''))
  dispatch(changeFilterAction('leagueName', ''))
  dispatch(changeFilterAction('location', { coords: {lat: '', lng: ''}, radius: '', city: ''}))
  dispatch(changeFilterAction('status', state.misc.isGCAT ? [ 'registration_open', 'my_registrations' ] : [ 'registration_open', 'my_registrations', 'past_events', 'upcoming_events' ]))
}

export const changeFilter = (filter, value) => (dispatch, getState) => {
  // Only do something if filter value has changed
  if (getState().filters[filter] !== value && filter !== 'clear') {
    dispatch({
      type: ActionTypes.CHANGE_FILTER,
      filter,
      value,
    })
  }
  const state = getState()
  let data
  const url = state.misc.saveFiltersURL

  if (!state.misc.dataIsLoading) {
    if (filter !== 'advancedFiltersOn' && filter !== 'leagueName') {
      if (filter === 'startDate' || filter === 'endDate') {
        value = value.toString()
      }

      const isGCAT = state.misc.isGCAT

      if (filter === 'clear') {
        clearFilters(dispatch, state)
        const status = isGCAT ? [ 'registration_open', 'my_registrations' ] : [ 'registration_open', 'my_registrations', 'past_events', 'upcoming_events' ]
        
        data = {
          'startDate': '',
          'endDate': '',
          'leagueNameRequest': '',
          'location': { coords: {lat: '', lng: ''}, radius: '', city: ''},
          'status': status,
        }
      } else {
        data = {
          [filter]: value,
        }
      }
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        credentials: 'include',
        headers: {
          'X-CSRF-Token': getAuthenticityToken(),
          'Content-Type': 'application/json',
        },
      }).then()
    }
  } else {
    if (filter === 'currentLocation') {
      data = {
        [filter]: value,
      }
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        credentials: 'include',
        headers: {
          'X-CSRF-Token': getAuthenticityToken(),
          'Content-Type': 'application/json',
        },
      }).then()
    }
  }
}

export const getAuthenticityToken = () => (
  $('head meta[name=\'csrf-token\']').attr('content')
)

export const addToCart = (url) => (dispatch) => {
  dispatch(addToCartRequest())
  fetch(url, {
    method: 'POST', // or 'PUT'
    body: '', // data can be `string` or {object}!
    credentials: 'include',
    headers: {
      'X-CSRF-Token': getAuthenticityToken(),
    },
  }).then((response) => Promise.all([ response, response.json() ]))
  .then(([ response, json ]) => {
    if (response.status === 200) {
      dispatch(addToCartResponse(json))
      parent.document.getElementById('shopping_cart_content').classList.remove('hidden')
      parent.document.getElementById('shopping_cart_continue').classList.remove('hidden')
      parent.document.getElementById('shopping_cart_content').getElementsByClassName('cartships')[0].innerHTML = json.cartships_size
    }
  })
  .catch(showErrorNotification('Error while adding registration to cart!'))
}

const callAPI = (baseURL) => {
  const url = baseURL
  return fetch(url, { method: 'GET', credentials: 'same-origin' })
  .then(response => Promise.all([ response, response.json() ]))
  .then(([ response, json ]) => {
    if (response.status === 200) {
      return json
    } else {
      showErrorNotification('Error while fetching initial data!')
    }
  })
  .catch(() => {
    showErrorNotification('Error while fetching initial data!')
  })
}

export const fetchInitialData = (tourId) => (dispatch, getState) => {
  dispatch(fetchInitialDataRequest())
  const state = getState()
  //#TODO Test with logged in member selected
  let paramSign
  state.misc.fetchInitialDataURL.includes('?') ? paramSign = '&' : paramSign = '?'
  const initialDataURL = state.misc.fetchInitialDataURL + paramSign + 'selected_tour_id=' + tourId + '&season_id=' + state.misc.seasonId
  const dataURL = state.misc.fetchInitialDataURL
  if (initialDataURL) {
    callAPI(initialDataURL)
    .then(json => {
      dispatch(fetchInitialDataReceived(json))
    })
    if (tourId && tourId !== 'all') {
      callAPI(dataURL)
      .then(json => {
        dispatch(fetchAllDataReceived(json))
      })
    }
  }
}


