import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GlgPopup, GlgPopupForm, GlgLoadingSpinner, GlgCheckbox, GlgTooltip } from 'SharedComponents'
import _ from 'lodash'

export class LeaguesPropagationPopup extends Component {
  constructor(props) {
    super(props)
    this.onSave = this.onSave.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.selectAll = this.selectAll.bind(this)
    this.unselectAll = this.unselectAll.bind(this)
    this.toggleCheckedLeague = this.toggleCheckedLeague.bind(this)
    this.state = {
      checkedLeagues: {},
      lastCheckedLeagueIndex: null,
    }
    this.indexToLeagueId = {}
  }
  
  selectAll(){
    const leagueIds = this.props.leagues.map(l => l[0])
    const checkedLeagues = {}
    for (const lId of leagueIds){
      checkedLeagues[lId] = true
    }
    this.setState({checkedLeagues})
  }
  
  unselectAll(){
    this.setState({checkedLeagues: {}})
  }

  onSave() {
    this.props.propagateLeagues(this.state.checkedLeagues, 'propagate')
    window.onbeforeunload = null
  }

  onClose(){
    this.props.closeLeaguesPropagationPopup()
    window.onbeforeunload = null
  }
  
  onCancel(){
    this.props.propagateLeagues(this.state.checkedLeagues, 'cancel')
    window.onbeforeunload = null
  }
  
  toggleCheckedLeague(id, value, checkedIndex, e) {
    if (e.shiftKey && this.state.lastCheckedLeagueIndex !== null) {
      let lastCheckedLeagueIndex = this.state.lastCheckedLeagueIndex
      let cloneCheckedIndex = checkedIndex
      
      if (cloneCheckedIndex < lastCheckedLeagueIndex) {
        const aux = cloneCheckedIndex
        cloneCheckedIndex = lastCheckedLeagueIndex
        lastCheckedLeagueIndex = aux
      }
      
      const checkedLeagues = _.clone(this.state.checkedLeagues)
      for (let i = lastCheckedLeagueIndex; i <= cloneCheckedIndex; i++) {
        // console.log(checkedLeagues[this.indexToLeagueId[lastCheckedLeagueIndex]])
        checkedLeagues[this.indexToLeagueId[i]] = checkedLeagues[this.indexToLeagueId[this.state.lastCheckedLeagueIndex]]
      }
      this.setState({
        checkedLeagues,
      })
    } else {
      this.setState({
        checkedLeagues: {
          ...this.state.checkedLeagues,
          [id]: value,
        },
      })
    }
    this.setState({lastCheckedLeagueIndex: checkedIndex})
  }
  
  render() {
    const popUpTitle = (!window.I18n ? '' : (this.props.canUseGolfChannelFlightRanking ? window.I18n.t('members_page.components.propagation.leagues_propagation_popup.hi_flights') : window.I18n.t('members_page.components.propagation.leagues_propagation_popup.only_hi')))
    const propagateButtonText = (!window.I18n ? '' : (this.props.isPropagateLeaguesLoading ? window.I18n.t('members_page.components.propagation.leagues_propagation_popup.propagating') : window.I18n.t('members_page.components.propagation.leagues_propagation_popup.propagate')))
    const cancelButtonText = (!window.I18n ? '' : (this.props.isCancelPropagateLeaguesLoading ? window.I18n.t('members_page.components.propagation.leagues_propagation_popup.hi_flights') : window.I18n.t('buttons.cancel').charAt(0)
      .toUpperCase() + window.I18n.t('buttons.cancel').slice(1)))
    const isSomethingChecked = Object.keys(this.state.checkedLeagues).some(x => this.state.checkedLeagues[x] === true)
    const propagateButtonDisabled = this.props.isPropagateLeaguesLoading || this.props.isCancelPropagateLeaguesLoading || !isSomethingChecked
    const cancelButtonDisabled = this.props.isPropagateLeaguesLoading || this.props.isCancelPropagateLeaguesLoading
    return <GlgPopup title={() => popUpTitle} modalClass="modal-lg get_propagate_leagues" show={ this.props.show } onClose={ this.onClose } manualClose={ true } showCloseButton={ false } showSaveButton={ false } customButtons={[
                  { class: 'gdpr_btn btsp_orange_button save_button pull-right btn ' + (propagateButtonDisabled ? 'disabled' : ''), text: propagateButtonText, onClick: this.onSave, disabled: propagateButtonDisabled },
                  { class: 'cancel_roster btn button btsp_gray_button pull-right ' + (cancelButtonDisabled ? 'disabled' : ''), text: cancelButtonText, onClick: this.onCancel, disabled: cancelButtonDisabled },
                ]}><div className="alert alert-warning product_warning">{ !window.I18n ? '' : window.I18n.t('members_page.components.propagation.leagues_propagation_popup.hi_flights') }</div><div style={{fontWeight: 'normal'}} className="pull-right f_check_all"><a href="javascript:void(0)" className="select_all_leagues blue_normal" onClick={this.selectAll}>{ !window.I18n ? '' : window.I18n.t('members_page.components.propagation.leagues_propagation_popup.select_all') }</a><span>{ ' / ' }</span><a href="javascript:void(0)" className="unselect_all_leagues blue_normal" onClick={this.unselectAll}>{ !window.I18n ? '' : window.I18n.t('members_page.components.propagation.leagues_propagation_popup.unselect_all') }</a></div><div className="clear"></div><GlgPopupForm><GlgLoadingSpinner show={ this.props.isLoading } height={ 50 } width={ 50 } className="loading-spinner"></GlgLoadingSpinner>{ this.props.leagues.map((l, index) => {
            this.indexToLeagueId[index] = l[0]
            return <GlgTooltip tooltip={ l[1] } key={`league_propagation_checkbox_${l[0]}`}><div className="propagate_leagues_checkbox"><fieldset className="check leagues_daily_email"><GlgCheckbox name={`league_propagation_checkbox_${l[0]}`} label={_.truncate(l[1], {length: 25})} onChange={(checked, val, e) => this.toggleCheckedLeague(l[0], checked, index, e)} checked={this.state.checkedLeagues[l[0]] || false} inline={true}></GlgCheckbox></fieldset></div></GlgTooltip>
            }
          )}</GlgPopupForm></GlgPopup>
  }
}

LeaguesPropagationPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  closeLeaguesPropagationPopup: PropTypes.func.isRequired,
  canUseGolfChannelFlightRanking: PropTypes.bool.isRequired,
  leagues: PropTypes.array.isRequired,
  propagateLeagues: PropTypes.func.isRequired,
  isPropagateLeaguesLoading: PropTypes.bool.isRequired,
  isCancelPropagateLeaguesLoading: PropTypes.bool.isRequired,
}

export default LeaguesPropagationPopup
