import React from 'react'
import PropTypes from 'prop-types'
import { ControlLabel } from 'react-bootstrap'
import { Row, Col } from 'react-flexbox-grid'

const GlgFormInput = ({ label, children, leftSideColumnsCount, description, withoutBorder }) => {
  const rightSideColumnsCount = 12 - leftSideColumnsCount
  return <li className={`form_row ${withoutBorder ? 'without_border' : ''}`}><Row><Col sm={ leftSideColumnsCount } className="label_names"><ControlLabel className="btsp_label">{ label }</ControlLabel></Col><Col xs={12} sm={ rightSideColumnsCount }>{ description && <Col xs={12} className="description"><div className="bold">{ description.title }</div><div className="clear">{ description.text }</div></Col>}<Col xs={12} className="content_fields"><Col xs={12} className="no_padding list-name">{ children }</Col></Col></Col></Row></li>
}

GlgFormInput.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  // The amount of bootstrap columns to distribute to the label (left) side
  leftSideColumnsCount: PropTypes.number,
  description: PropTypes.shape({
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
  }),
  withoutBorder: PropTypes.bool,
}

GlgFormInput.defaultProps = {
  leftSideColumnsCount: 4,
  withoutBorder: false,
}

export default GlgFormInput
