import React, { Fragment, Component } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { /*GlgSelect,*/ GlgButton } from 'SharedComponents'
import ChangeImagePopup from './change_image_popup'
import PropTypes from 'prop-types'
import { NONE } from '../constants'

class GeneralComponent extends Component {

  render(){
    const {
      // sponsorLogos,
      backgroundImage,
    } = this.props

    return <Fragment><Row><Col xs={ 12 } className="subsection_name no-padding-left no-margin-top">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.general_component.background') }</Col></Row><Row className="vertical-align-items"><Col xs={ 12 } lg={ 2 } className="no-padding-left"><div className="background-container">{ backgroundImage === NONE ? !window.I18n ? '' : window.I18n.t('tv_shows.components.general_component.none') : <img src={ backgroundImage } /> }</div></Col><Col xs={ 12 } lg={ 1 } className="no-padding-left"><GlgButton text={ !window.I18n ? '' : window.I18n.t('tv_shows.components.general_component.change') } color="white" size="small" onClick={ () => { this.props.setPopupProperty('backgroundImage', 'show', true) } }></GlgButton></Col></Row>{/*%Row
          %Col.subsection_name.no-padding-left(xs={ 12 })
            { 'Slide Transition' }
        %Row
          %Col.no-padding-left(xs={ 12 } sm={ 6 } lg={ 2 })
            %GlgSelect( items={ TRANSISTION_ITEMS }
                        value={ TRANSISTION_ITEMS[1].value }
                        onChange={ () => {} }
              )
        %Row
          %Col.subsection_name.no-padding-left(xs={ 12 })
            { 'Sponsor logos' }
        %Row.vertical-align-items
          %Col.no-padding-left(xs={ 12 } lg={ 6 })
            .image_roll
              { sponsorLogos.map( (imgSrc, index) => {
                return <div key={ index } className="image_container customer_sponsor_logo"><img src={ imgSrc } /></div>
              })}
          %Col.no-padding-left(xs={ 2 } lg={ 1 })
            %GlgButton( text="Add New"
                        icon="plus"
                        color="white"
                        size="small"
            )*/}{<ChangeImagePopup
          { ...this.props.popups.backgroundImage }
          showNone={ true }
          fetchImagesPath={ this.props.paths.fetchBackgroundImagesLinks }
          uploadImagePath={ this.props.paths.uploadBackgroundImage }
          updateImageTitlePath={ this.props.paths.updateBackgroundImageTitle }
          setImage={ this.props.setBackground }
          onClose={ () => { this.props.setPopupProperty('backgroundImage', 'show', false) } }
        />}</Fragment>
  }
}

// const TRANSISTION_ITEMS = [
//   {
//     label: 'None',
//     value: 'None',
//   },
//   {
//     label: 'Fade',
//     value: 'Fade',
//   },
//   {
//     label: 'Flip',
//     value: 'Flip',
//   },
//   {
//     label: 'Slide',
//     value: 'Slide',
//   },
// ]

GeneralComponent.propTypes = {
  paths: PropTypes.object.isRequired,
  sponsorLogos: PropTypes.arrayOf(PropTypes.string).isRequired,
  backgroundImage: PropTypes.string.isRequired,
  popups: PropTypes.object.isRequired,
  setPopupProperty: PropTypes.func.isRequired,
  setBackground: PropTypes.func.isRequired,
}

export default GeneralComponent
