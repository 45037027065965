import { ActionTypes } from '../actions'

const {
  CHANGE_TOURNAMENT_REQUEST,
  CHANGE_ROUND_REQUEST,
  CHANGE_ROUND_RECEIVED,
} = ActionTypes

const initialState = {
}

const misc = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_TOURNAMENT_REQUEST:
      return {
        ...state,
        selectedTournamentId: action.tournamentId,
      }
    case CHANGE_ROUND_REQUEST: 
      return {
        ...state,
        roundId: action.roundId,
      }
    case CHANGE_ROUND_RECEIVED:
      return {
        ...state,
        holes: action.data.holes,
        courses: action.data.courses,
      }
    default:
      return state
  }
}

export default misc
