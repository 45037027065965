import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const teeData = ({tees, isMultiRound, teamscoreVerification}) => {
  return (
    <Fragment>
      <tr className='header'>
        <th className='with_border first'>Group Info</th>
        { teamscoreVerification &&
          <th className='with_border second'>Team</th>
        }
        { teamscoreVerification &&
          <th className='with_border'>Player</th>
        }
        <th className={ teamscoreVerification ? 'with_border' : 'with_border second'}>Hole</th>
        { [ ...Array(9) ].map( ( _, i) => (
          <Fragment key={i}>
            <th key={'hole-' + i} className='with_border'>{i + 1}</th>
            { (i + 1) % 3 === 0 && i !== 8 && <th className="with_border gray_column"></th> }
          </Fragment>
        )) }
        <th className='with_border'>Out</th>
        { [ ...Array(9) ].map( ( _, i) => (
          <Fragment key={i}>
            <th key={'hole-' + i} className='with_border'>{i + 10}</th>
            { (i + 1) % 3 === 0 && i !== 8 && <th className="with_border gray_column"></th> }
          </Fragment>
        )) }
        <th className='with_border'>In</th>
        <th className='with_border'>Score</th>
        { isMultiRound && <th className='with_border'>Today</th> }
        { isMultiRound && <th className='with_border'>Total</th> }
        { isMultiRound && <th className='with_border'>Overall Score</th> }
      </tr>
      {
        Object.keys(tees).map( par => {
          return tees[par].map( (tee, index) =>  
            <Fragment key={tee.id}>
              <tr className='yardage_row'>
                <td className='first'>{tee.name}</td>
                { teamscoreVerification && <td></td> }
                { teamscoreVerification && <td></td> }
                <td className={teamscoreVerification ? '' : 'second'}>Yardage</td>
                { [ ...Array(9) ].map( ( _, i) => (
                  <Fragment key={i}>
                    <td key={'hole-' + i}>{tee.yardage[i]}</td>
                    { (i + 1) % 3 === 0 && i !== 8 && <td className="gray_column"></td> }
                  </Fragment>
                )) }
                <td>{tee.yardage_sum_front}</td>
                { [ ...Array(9) ].map( ( _, i) => (
                  <Fragment key={i}>
                    <td key={'hole-' + i}>{tee.yardage[i + 9]}</td>
                    { (i + 1) % 3 === 0 && i !== 8 && <td className="gray_column"></td> }
                  </Fragment>
                )) }
                <td>{tee.yardage_sum_back}</td>
                <td>{tee.yardage_sum}</td>
                { isMultiRound && <td></td> }
                { isMultiRound && <td></td> }
                { isMultiRound && <td></td> }
              </tr>
              { index === tees[par].length - 1 && 
                <tr className='par_row'>
                  <td className='first'></td>
                  { teamscoreVerification && <td></td> }
                  { teamscoreVerification && <td></td> }
                  <td className={teamscoreVerification ? '' : 'second'}>Par</td>
                  { [ ...Array(9) ].map( ( _, i) => (
                    <Fragment key={i}>
                      <td key={'hole-' + i}>{tee.par[i]}</td>
                      { (i + 1) % 3 === 0 && i !== 8 && <td className="gray_column"></td> }
                    </Fragment>
                  )) }
                  <td>{tee.par_sum_front}</td>
                  { [ ...Array(9) ].map( ( _, i) => (
                    <Fragment key={i}>
                      <td key={'hole-' + i}>{tee.par[i + 9]}</td>
                      { (i + 1) % 3 === 0 && i !== 8 && <td className="gray_column"></td> }
                    </Fragment>
                  )) }
                  <td>{tee.par_sum_back}</td>
                  <td>{tee.par_sum}</td>
                  { isMultiRound && <td></td> }
                  { isMultiRound && <td></td> }
                  { isMultiRound && <td></td> }
                </tr> 
              }
            </Fragment>
          )
        })
      }
    </Fragment>
  )
}

teeData.propTypes = {
  tees: PropTypes.object,
  isMultiRound: PropTypes.bool,
  teamscoreVerification: PropTypes.bool,
}

export default teeData
