import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Column from './column'
import AddColumn from './add_column'
import { Row, Col } from 'react-flexbox-grid'

class ListColumns extends Component {
  constructor(props) {
    super(props)
    this.getUrl = this.getUrl.bind(this)
    this.getViewMoreUrl = this.getViewMoreUrl.bind(this)
  }

  flatten(input) {
    const stack = [ ...input ]
    const res = []
    while (stack.length) {
      // pop value from stack
      const next = stack.pop()
      if (Array.isArray(next)) {
        // push back array items, won't modify the original input
        stack.push(...next)
      } else {
        res.push(next)
      }
    }
    //reverse to restore input order
    return res.reverse()
  }

  getUrl(id) {
    const _widget = this.flatten(this.props.widgets.map(section => section.pages))
      .find(page => page.widget_id === id)
      

    return _widget !== undefined ? _widget.url : null
  }

  getViewMoreUrl(id) {
    const _widget = this.flatten(this.props.widgets.map(section => section.pages))
      .find(page => page.widget_id === id)

    return _widget !== undefined ? _widget.view_more : null
  }

  render() {
    return (
      <Row className="columns-list">
        <Col xs={12}>
          { this.props.data.layout === '1' ?
            <Row>
              <Col xs={12} className="column">
                { this.props.data.columns.map(column =>
                    <Column key={ column.id }
                            ColumnChangePosition={ _position => this.props.columnChangePosition(column.id, _position) }
                            DraggableElement={ this.props.draggableElement }
                            DraggedOverElement={ this.props.draggedOverElement }
                            DraggedOverPosition={ this.props.draggedOverPosition }
                            IframePointerOption={ this.props.iframePointerOptions }
                            IframeUrl={ this.getUrl(column.widget_id.toString()) }
                            onEdit={ () => this.props.editColumn(column.id) }
                            onEditProps={ () => this.props.editColumnProps(column.id) }
                            onRemove={ () => this.props.removeColumn(column.id) }
                            resizeColumn={ (height) => this.props.resizeColumn(column.id, height)}
                            setColumnDisplay={ _display => this.props.setColumnDisplay(column.id, _display) }
                            setDraggebleElement={ (_element) => this.props.setDraggebleElement(_element) }
                            setDraggedOverElement={ (_element) => this.props.setDraggedOverElement(_element) }
                            setDraggedOverPosition={ _position => this.props.setDraggedOverPosition(_position) }
                            setIframePointerOptions={ (_option) => this.props.setIframePointerOptions(_option) }
                            setIframeFormState={ () => this.props.setIframeFormState(null) }
                            swapColumns={ (_col1, _col2, _position) => this.props.swapColumns(_col1, _col2, _position) }
                            ViewMoreUrl={ this.getViewMoreUrl(column.widget_id.toString()) }
                            usesTranslations={ this.props.usesTranslations }
                            availableLanguages={ this.props.availableLanguages }
                            currentLanguage={ this.props.currentLanguage }
                            {...column} /> ) }
              </Col>
              <Col xs={12} className="column">
                <AddColumn size={ '12' }
                           POSITION={ null }
                           DraggedOverElement={ this.props.draggedOverElement }
                           setDraggedOverElement={ (_element) => this.props.setDraggedOverElement(_element) }
                           count={ this.props.data.columns.length }
                           AddColumn={ () => this.props.addColumn(this.props.blockID, null) } />
              </Col>
            </Row> :
            <Row>
              <Col xs={12} md={ this.props.data.left.width } className="column">
                { this.props.data.left.columns.map(column =>
                    <Column key={ column.id }
                            ColumnChangePosition={ _position => this.props.columnChangePosition(column.id, _position) }
                            DraggableElement={ this.props.draggableElement }
                            DraggedOverElement={ this.props.draggedOverElement }
                            DraggedOverPosition={ this.props.draggedOverPosition }
                            IframePointerOption={ this.props.iframePointerOptions }
                            IframeUrl={ this.getUrl(column.widget_id.toString()) }
                            onEdit={ () => this.props.editColumn(column.id) }
                            onEditProps={ () => this.props.editColumnProps(column.id) }
                            onRemove={ () => this.props.removeColumn(column.id) }
                            resizeColumn={ (height) => this.props.resizeColumn(column.id, height)}
                            setColumnDisplay={ _display => this.props.setColumnDisplay(column.id, _display) }
                            setDraggebleElement={ (_element) => this.props.setDraggebleElement(_element) }
                            setDraggedOverElement={ (_element) => this.props.setDraggedOverElement(_element) }
                            setDraggedOverPosition={ _position => this.props.setDraggedOverPosition(_position) }
                            setIframePointerOptions={ (_option) => this.props.setIframePointerOptions(_option) }
                            setIframeFormState={ () => this.props.setIframeFormState(null) }
                            swapColumns={ (_col1, _col2, _position) => this.props.swapColumns(_col1, _col2, _position) }
                            ViewMoreUrl={ this.getViewMoreUrl(column.widget_id.toString()) }
                            usesTranslations={ this.props.usesTranslations }
                            availableLanguages={ this.props.availableLanguages }
                            currentLanguage={ this.props.currentLanguage }
                            {...column} /> ) }
                  <AddColumn size={ this.props.data.left.width }
                             count={ this.props.data.left.columns.length }
                             POSITION='left'
                             DraggedOverElement={ this.props.draggedOverElement }
                             setDraggedOverElement={ (_element) => this.props.setDraggedOverElement(_element) }
                             AddColumn={ () => this.props.addColumn(this.props.blockID, 'left') } />
              </Col>
              <Col xs={12} md={ this.props.data.right.width } className="column">
                { this.props.data.right.columns.map(column =>
                    <Column key={ column.id }
                            ColumnChangePosition={ _position => this.props.columnChangePosition(column.id, _position) }
                            DraggableElement={ this.props.draggableElement }
                            DraggedOverElement={ this.props.draggedOverElement }
                            DraggedOverPosition={ this.props.draggedOverPosition }
                            IframePointerOption={ this.props.iframePointerOptions }
                            IframeUrl={ this.getUrl(column.widget_id.toString()) }
                            onEdit={ () => this.props.editColumn(column.id) }
                            onEditProps={ () => this.props.editColumnProps(column.id) }
                            onRemove={ () => this.props.removeColumn(column.id) }
                            resizeColumn={ (height) => this.props.resizeColumn(column.id, height)}
                            setColumnDisplay={ _display => this.props.setColumnDisplay(column.id, _display) }
                            setDraggebleElement={ (_element) => this.props.setDraggebleElement(_element) }
                            setDraggedOverElement={ (_element) => this.props.setDraggedOverElement(_element) }
                            setDraggedOverPosition={ _position => this.props.setDraggedOverPosition(_position) }
                            setIframePointerOptions={ (_option) => this.props.setIframePointerOptions(_option) }
                            setIframeFormState={ () => this.props.setIframeFormState(null) }
                            swapColumns={ (_col1, _col2, _position) => this.props.swapColumns(_col1, _col2, _position) }
                            ViewMoreUrl={ this.getViewMoreUrl(column.widget_id.toString()) }
                            usesTranslations={ this.props.usesTranslations }
                            availableLanguages={ this.props.availableLanguages }
                            currentLanguage={ this.props.currentLanguage }
                            {...column} /> ) }
                  <AddColumn size={ this.props.data.right.width }
                             count={ this.props.data.right.columns.length }
                             DraggedOverElement={ this.props.draggedOverElement }
                             POSITION='right'
                             setDraggedOverElement={ (_element) => this.props.setDraggedOverElement(_element) }
                             AddColumn={ () => this.props.addColumn(this.props.blockID, 'right') } />
              </Col>
            </Row>
          }
        </Col>
      </Row>
    )
  }
}

ListColumns.propTypes = {
  addColumn: PropTypes.func,
  blockID: PropTypes.any,
  columnChangePosition: PropTypes.func,
  data: PropTypes.object,
  draggableElement: PropTypes.number,
  draggedOverElement: PropTypes.any,
  draggedOverPosition: PropTypes.any,
  editColumn: PropTypes.func,
  editColumnProps: PropTypes.func,
  iframePointerOptions: PropTypes.string,
  removeColumn: PropTypes.func,
  resizeColumn: PropTypes.func,
  setColumnDisplay: PropTypes.func,
  setDraggebleElement: PropTypes.func,
  setDraggedOverElement: PropTypes.func,
  setDraggedOverPosition: PropTypes.func,
  setIframePointerOptions: PropTypes.func,
  setIframeFormState: PropTypes.func,
  swapColumns: PropTypes.func,
  widgets: PropTypes.any,
  usesTranslations: PropTypes.bool,
  availableLanguages: PropTypes.any,
  currentLanguage: PropTypes.any,
}

export default ListColumns
