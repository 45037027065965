import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row } from 'react-flexbox-grid'

class ColumnMenubar extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  render() {
    return (
      <Row top="xs" className={ `column-menubar ${(this.props.title !== '' || this.props.view_more === true) ? '' : 'without-title'}` }>
        <div className="column-drag-arrow pull-left"
             onDragStart={ (e) => this.props.DragStart(e) }
             onDragEnd={ this.props.DragEnd }
             data-toggle="tooltip"
             data-placement="right"
             data-original-title={window.I18n.t('multi_widgets.column_menu.drag_drop_tooltip')}
             draggable>
          <i className="fa fa-arrows"></i>
        </div>
        <div className="title">
          <span className="pull-left column-menubar-center">
            <div className="title-elipsis float-left">
              { this.props.titles ? (this.props.titles[this.props.currentLanguage] ? this.props.titles[this.props.currentLanguage] : this.props.title) : this.props.title }
            </div>
            <div onClick={ () => this.props.onEditProps() }
                  className="wrapper right"
                  data-toggle="tooltip"
                  data-placement="right"
                  data-original-title={window.I18n.t('multi_widgets.column_menu.edit_title')}>
              <i className="fa fa-pencil fa-fw"></i>
            </div>
          </span>
        </div>
        <div>
          { (this.props.view_more === true && this.props.url !== null) ?
          <span className="pull-right view-more-link">
            <a target="_blank" rel="noopener noreferrer" href={ this.props.url }>{window.I18n.t('multi_widgets.column_menu.view_more')}</a>
          </span> : null }
        </div>
        <div className="right-container pull-right">
          <span onClick={ this.props.onRemove } className="wrapper right" data-toggle="tooltip" data-original-title={window.I18n.t('multi_widgets.column_menu.remove')}>
            <i className="fa fa-trash"></i>
          </span>
          { this.props.url !== null
            ? <span onClick={ this.props.onEdit } className="wrapper" data-toggle="tooltip" data-original-title={window.I18n.t('multi_widgets.column_menu.edit_content')}>
              <i className="fa fa-gear fa-fw"></i>
            </span> : null }
        </div>
      </Row>
    )
  }
}

ColumnMenubar.propTypes = {
  title: PropTypes.any,
  titles: PropTypes.any,
  view_more: PropTypes.bool,
  url: PropTypes.string,
  onEdit: PropTypes.func,
  onEditProps: PropTypes.func,
  onRemove: PropTypes.func,
  DragStart: PropTypes.func,
  DragEnd: PropTypes.func,
  usesTranslations: PropTypes.bool,
  availableLanguages: PropTypes.any,
  currentLanguage: PropTypes.any,
}

export default ColumnMenubar
