import { ActionTypes } from '../actions'
import _ from 'lodash'

const {
  FETCH_TRANSACTIONS_HISTORY_DATA_REQUEST,
  FETCH_TRANSACTIONS_HISTORY_DATA_RECEIVED,
  CHANGE_TRANSACTIONS_FILTER,
  DELETE_PAYMENT_METHOD_RESPONSE,
  DEFAULT_PAYMENT_METHOD_RESPONSE,
} = ActionTypes

const initialFilters = {
  eventId: 'all',
}

const transactionsHistory = (state = {}, action) => {
  let newState = {}
  let paymentsIds
  switch (action.type) {
    case FETCH_TRANSACTIONS_HISTORY_DATA_REQUEST:
      return {
        ...state,
        dataIsLoading: true,
      }
    case FETCH_TRANSACTIONS_HISTORY_DATA_RECEIVED:
      return {
        ...state,
        ...action.data,
        ...initialFilters,
        dataIsLoading: false,
        loaded: true,
      }
    case CHANGE_TRANSACTIONS_FILTER:
      return {
        ...state,
        eventId: action.value,
      }
    case DELETE_PAYMENT_METHOD_RESPONSE:
      newState = _.clone(state)
      paymentsIds = Object.keys(state.payments)
      paymentsIds = paymentsIds.filter(paymentId => paymentId !== `${action.data.paymentId}`)
      newState.payments = {}
      paymentsIds.forEach((elem) => newState.payments[elem] = state.payments[elem])
      return {
        ...newState,
      }
    case DEFAULT_PAYMENT_METHOD_RESPONSE:
      newState = _.clone(state)
      paymentsIds = Object.keys(state.payments)
      newState.payments = {}
      paymentsIds.forEach((elem) => newState.payments[elem] = action.data.payments[elem])
      return {
        ...newState,
      }
    default:
      return state
  }
}

export default transactionsHistory
