import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-bootstrap-toggle'

class GolfHubTile extends Component {
  constructor(props){
    super(props)
  }

  componentDidUpdate() {
    window.redo_qtip()
  }

  render() {
    const { golfhubPage, links, texts } = this.props.content

    return (
      <div className='widget-content-default widget-content-golf-hub-tile'>
        {/* Golf Hub Logo */}
        <div className='title'>
          <a href={ links.golfhubFrontendURL }>
            <img src={ require('golfhub/event_dashboard_logo.svg') } className='golfhub-logo' loading='lazy' />
          </a>
        </div>

        <div className='setup-buttons-container border-bottom'>
          {/* Create / Edit Golf Hub Page */}
          <a href={ links.createOrEditGolfHubPage }
              className={ 'btn btn-primary create-golfhub-button orange-buttons' }
              style={ { pointerEvents: 'all' } }>
                { golfhubPage.created ? texts.editGolfhubPage : texts.createGolfhubPage }
          </a>
          {/* Published / Not Published Toggle */}
          { golfhubPage.created &&  
              <div className='golfhub-published-container'>
                <div className='page-status-content'> 
                  <div className='page-is bold'> { texts.pageIs } </div> 
                  <Toggle onClick={ () => { this.props.toggleGolfHubPublished(golfhubPage.enabled, links.togglePublished) } }
                    on={ texts.published }
                    off={ texts.notPublished }
                    className='rounded published-toggle' 
                    id='toggle-golfhub-page-published'
                    active={ golfhubPage.enabled }
                  />
                </div>
                { golfhubPage.enabled &&
                    <div className={ 'copy-link '} title={ links.copyGolfhubPage } onClick={ () => this.props.copyText(links.copyGolfhubPage) } >
                      <i className='fa fa-files-o'></i> { texts.copyEventLinkToClipboard }
                    </div>
                }
              </div> 
          }
        </div>

        <div className={`setup-buttons-container ${golfhubPage.flyersIntegrationEnabled && 'border-bottom'}`}>
          {/* Add / Edit Registration and Payments */}
          <a href={ !golfhubPage.created || golfhubPage.leaguePremiumRegistrationActive ? 'javascript:void(0)' : links.createOrEditRegistrationAndPayments }
              title={ golfhubPage.created ? (golfhubPage.leaguePremiumRegistrationActive ? texts.registrationDisabledRegistrationCenter : '') : texts.registrationDisabledNoGolfhubPage }
              disabled={ !golfhubPage.created || golfhubPage.leaguePremiumRegistrationActive }
              className={ 'btn btn-primary registration-button orange-buttons' }
              style={ { pointerEvents: 'all' } }>
                { golfhubPage.registrationCreated && !golfhubPage.leaguePremiumRegistrationActive ? texts.editRegistrationAndPayments : texts.addRegistrationAndPayments }
          </a>
        
          {/* Open / Closed Registration Toggle */}
          { golfhubPage.registrationCreated ? 
              <div className='golfhub-published-container'>
                <div className='page-status-content'> 
                  <div className='page-is bold'> { texts.registrationIs } </div> 
                  <Toggle onClick={ () => { this.props.toggleEventRegistration(golfhubPage.registrationOpen, links.toggleRegistration) } }
                    on={ texts.open }
                    off={ texts.closed }
                    className='rounded published-toggle'
                    id='toggle-event-registration'
                    active={ golfhubPage.registrationOpen } 
                  />
                </div>
              </div>
                : 
              golfhubPage.created &&
                <div></div>
          }
        </div>
        
        {/* Flyers */}
        { golfhubPage.flyersIntegrationEnabled &&
            <div className='flyers-container setup-buttons-container'>
              <a href={ golfhubPage.enabled ? links.flyers : 'javascript:void(0)' }
                oldtitle={ golfhubPage.enabled ? '' : texts.flyersDisabledNoGolfhubPage }
                className={ 'btn btn-primary flyers-button black-buttons rounded' }
                disabled={ !golfhubPage.enabled }
                style={ { pointerEvents: 'all' } }>
                  { texts.flyers }
              </a>
              { golfhubPage.created && /* this is used to align button */
                <div></div>
              }
            </div> 
        }
      </div>
    )
  }
}

GolfHubTile.propTypes = {
  content: PropTypes.object,
  toggleGolfHubPublished: PropTypes.func,
  toggleEventRegistration: PropTypes.func,
  copyText: PropTypes.func,
}

export default GolfHubTile
