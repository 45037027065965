import React from 'react'
import {useSelector} from 'react-redux'
import {GlgSelect} from '../../shared/components'
import PropTypes from 'prop-types'


const OttErMembersDropdown = ({setSelectedPlayerId, disablePlayers}) => {

  const disabledPlayers = useSelector(state => state.disabledPlayers)
  const selectablePlayers = useSelector(state => state.selectablePlayers)

  const selectablePlayersForGlgSelect = selectablePlayers.map((player) => ({
    label: player[0],
    value: player[1],
  }))

  const checkIfOptionDisabled = (option) => {

    return disabledPlayers.find(disabledPlayer => disabledPlayer === option.value) ? disablePlayers : false
  }

  return (
      <GlgSelect
        items={ selectablePlayersForGlgSelect }
        onChange={(option) => { setSelectedPlayerId(option) }}
        placeholder={ window.I18n.t('players_choose_tee_times.ott_2_0.add_player_popup.dropdown_select') }
        usePortal={ true }
        noPreFill={ true }
        menuShouldBlockScroll={ true }
        searchable = { true }
        isOptionDisabled={ (option) => checkIfOptionDisabled(option) }
      />
  )
}

export default OttErMembersDropdown

OttErMembersDropdown.propTypes = {
  setSelectedPlayerId: PropTypes.func,
  disablePlayers: PropTypes.bool,
}
