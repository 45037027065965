import { ActionTypes } from '../actions'

const {
  SHOW_TABLE_DATA,
  EDIT_LEAGUE,
  EDIT_LEAGUE_REGISTRATION,
  EDIT_LEAGUE_COURSES_ENABLED,
  EDIT_ROUND,
  SUBMIT,
  TOGGLE_LEAGUE_CHECKBOX,
  TOGGLE_ROUND_CHECKBOX,
  MARK_DATA_AS_FETCHED,
  MARK_DATA_AS_UNFETCHED,
  SELECT_ALL_CHECKBOXES,
  SELECT_ALL_LEAGUES_AND_EVENTS_CHECKBOXES,
  SELECT_ALL_ROUNDS_CHECKBOXES,
  CLEAR_SELECTION_CHECKBOXES,
  ZERO_MODIFIED_DATA_COUNT,
  SET_COURSES_ENABLED,
} = ActionTypes

const table = (state, action) => {
  switch (action.type) {
    case SHOW_TABLE_DATA:{
      const { data } = action.payload

      const updatedState = {
        ...state,
        data: data,
        isDataFetched: true,
      }

      if (!state.modifiedData || Object.keys(state.modifiedData).length === 0) {
        updatedState.modifiedDataCount = 0
      }

      return updatedState
    }
    
    case SET_COURSES_ENABLED: {
      const { data } = action.payload
      const leaguesData = state.data.table
      
      return {
        ...state,
        data: {
          ...state.data,
          table: Object.fromEntries(Object.entries(leaguesData).map(([ leagueKey, leagueData ]) => [
            leagueKey,
            {
              ...leagueData,
              courses_enabled: data[leagueKey] || {},
            },
          ])),
        },
      }
    }
    

    case MARK_DATA_AS_FETCHED: {
      return {
        ...state,
        isDataFetched: true,
      }
    }

    case MARK_DATA_AS_UNFETCHED: {
      return {
        ...state,
        isDataFetched: false,
      }
    }

    case TOGGLE_LEAGUE_CHECKBOX:{
      const { leagueKey } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          leagues_and_rounds_selected: {
            ...state.data.leagues_and_rounds_selected, 
            [leagueKey]: {
              ...state.data.leagues_and_rounds_selected[leagueKey],
              is_selected: !state.data.leagues_and_rounds_selected[leagueKey]['is_selected'],
            },
          },
        },
      }
    }

    case TOGGLE_ROUND_CHECKBOX:{
      const { leagueKey, roundKey } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          leagues_and_rounds_selected: {
            ...state.data.leagues_and_rounds_selected, 
            [leagueKey]: {
              ...state.data.leagues_and_rounds_selected[leagueKey],
              are_rounds_selected: {
                ...state.data.leagues_and_rounds_selected[leagueKey]['are_rounds_selected'],
                [roundKey]: {
                  ...state.data.leagues_and_rounds_selected[leagueKey]['are_rounds_selected'][roundKey],
                  is_selected: !state.data.leagues_and_rounds_selected[leagueKey]['are_rounds_selected'][roundKey]['is_selected'],
                },
              },
            },
          },
        },
      }
    }

    case SELECT_ALL_CHECKBOXES: {
      const { isCurrentPageSelected } = action.payload
      const newLeaguesAndRoundSelected = state.data.leagues_and_rounds_selected
      const leagues = state.data.table

      for (const leagueKey in newLeaguesAndRoundSelected) {
        const newLeague = newLeaguesAndRoundSelected[leagueKey]
        
        if ((newLeague['is_past'] === true) || (isCurrentPageSelected && !(leagueKey in leagues))) {
          continue
        }

        newLeaguesAndRoundSelected[leagueKey]['is_selected'] = true

        for (const roundKey in newLeague['are_rounds_selected']){
          if (newLeague['are_rounds_selected'][roundKey]['is_past'] === true || (isCurrentPageSelected && !(roundKey in leagues[leagueKey]['rounds']))){
            continue
          }

          newLeaguesAndRoundSelected[leagueKey]['are_rounds_selected'][roundKey]['is_selected'] = true
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          leagues_and_rounds_selected: newLeaguesAndRoundSelected,
        },
      }
    }

    case SELECT_ALL_LEAGUES_AND_EVENTS_CHECKBOXES: {
      const { isCurrentPageSelected } = action.payload
      const leagues = state.data.table
      const newLeaguesAndRoundSelected = state.data.leagues_and_rounds_selected

      for (const leagueKey in newLeaguesAndRoundSelected) {
        const newLeague = newLeaguesAndRoundSelected[leagueKey]
        
        if ((newLeague['is_past'] === true) || (isCurrentPageSelected && !(leagueKey in leagues))) {
          continue
        }

        newLeaguesAndRoundSelected[leagueKey]['is_selected'] = true
      }

      return {
        ...state,
        data: {
          ...state.data,
          leagues_and_rounds_selected: newLeaguesAndRoundSelected,
        },
      }
    }

    case SELECT_ALL_ROUNDS_CHECKBOXES: {
      const { isCurrentPageSelected } = action.payload
      const leagues = state.data.table
      const newLeaguesAndRoundSelected = state.data.leagues_and_rounds_selected

      for (const leagueKey in newLeaguesAndRoundSelected) {
        const newLeague = newLeaguesAndRoundSelected[leagueKey]
        
        if ((newLeague['is_past'] === true) || (isCurrentPageSelected && !(leagueKey in leagues))) {
          continue
        }

        for (const roundKey in newLeague['are_rounds_selected']){
          if (newLeague['are_rounds_selected'][roundKey]['is_past'] === true || (isCurrentPageSelected && !(roundKey in leagues[leagueKey]['rounds']))){
            continue
          }
          newLeaguesAndRoundSelected[leagueKey]['are_rounds_selected'][roundKey]['is_selected'] = true
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          leagues_and_rounds_selected: newLeaguesAndRoundSelected,
        },
      }
    }

    case CLEAR_SELECTION_CHECKBOXES: {
      const newLeaguesAndRoundSelected = { ...state.data.leagues_and_rounds_selected }
      const { isCurrentPageSelected } = action.payload
      const leagues = state.data.table
    
      for (const leagueKey in newLeaguesAndRoundSelected) {
        const newLeague = newLeaguesAndRoundSelected[leagueKey]

        if (isCurrentPageSelected && !(leagueKey in leagues)) {
          continue
        }
    
        newLeaguesAndRoundSelected[leagueKey]['is_selected'] = false
    
        for (const roundKey in newLeague['are_rounds_selected']) {
          if (isCurrentPageSelected && !(roundKey in leagues[leagueKey]['rounds'])){
            continue
          }
          newLeaguesAndRoundSelected[leagueKey]['are_rounds_selected'][roundKey]['is_selected'] = false
        }
      }
    
      return {
        ...state,
        data: {
          ...state.data,
          leagues_and_rounds_selected: newLeaguesAndRoundSelected,
        },
      }
    }
    

    case EDIT_LEAGUE_REGISTRATION:{
      const { leagueKey, field, value } = action.payload

      const currentValue = state.data.table[leagueKey].registration_form?.[field]
      if (checkIfFieldWasResetToOriginalValue(field, currentValue, value)) {
        const tempState = {...state}
        delete tempState.modifiedData[leagueKey].registration_form[field]
        return {...tempState, modifiedDataCount: tempState.modifiedDataCount - 1}
      }

      const isThisANewFieldUpdated = state.modifiedData[leagueKey]?.registration_form?.[field]
      let incrementModifiedDataCount = 0
      if (!isThisANewFieldUpdated) {
        incrementModifiedDataCount = 1
      }

      const baseLeagueData = state.modifiedData[leagueKey] || { registration_form: {} }

      const updatedLeagueData = {
        ...baseLeagueData,
        registration_form: {
          ...baseLeagueData.registration_form,
          [field]: value,
        },
      }

      return {
        ...state,
        modifiedData: {
          ...state.modifiedData,
          [leagueKey]: updatedLeagueData,
        },
        modifiedDataCount: state.modifiedDataCount + incrementModifiedDataCount,
      }
    }
    
    case EDIT_LEAGUE_COURSES_ENABLED: {
      const { leagueKey, courseKey, isEnabled } = action.payload
      
      let newValue
      if (isEnabled) {
        newValue = {...state.data.table[leagueKey].courses_enabled, [courseKey]: true}
      } else {
        newValue = {...state.data.table[leagueKey].courses_enabled}
        delete newValue[courseKey]
      }
        
      const isThisANewFieldUpdated = state.modifiedData[leagueKey]?.courses_enabled
      let incrementModifiedDataCount = 0
      if (!isThisANewFieldUpdated) {
        incrementModifiedDataCount = 1
      }

      const modifiedData = {
        ...state.modifiedData,
        [leagueKey]: {
          ...state.modifiedData[leagueKey],
          courses_enabled: newValue,
        },
      }

      return {
        ...state,
        modifiedData: modifiedData,
        modifiedDataCount: state.modifiedDataCount + incrementModifiedDataCount,
      }
    }

    case EDIT_LEAGUE:{
      const { leagueKey, field, value } = action.payload

      const currentValue = state.data.table[leagueKey][field]
      if (checkIfFieldWasResetToOriginalValue(field, currentValue, value)) {
        const tempState = {...state}
        delete tempState.modifiedData[leagueKey][field]
        return {...tempState, modifiedDataCount: tempState.modifiedDataCount - 1}
      }

      const isThisANewFieldUpdated = state.modifiedData[leagueKey]?.[field]
      let incrementModifiedDataCount = 0
      if (!isThisANewFieldUpdated) {
        incrementModifiedDataCount = 1
      }

      if (state.modifiedData === undefined){
        state.modifiedData = {}
      }
    
      const modifiedData = {
        ...state.modifiedData,
        [leagueKey]: {
          ...state.modifiedData[leagueKey],
          [field]: value,
        },
      }

      return {
        ...state,
        modifiedData: modifiedData,
        modifiedDataCount: state.modifiedDataCount + incrementModifiedDataCount,
      }
    }

    case EDIT_ROUND:{
      const { leagueKey, roundKey, field, value } = action.payload
      
      const currentValue = state.data.table[leagueKey].rounds?.[roundKey]?.[field]
      if (checkIfFieldWasResetToOriginalValue(field, currentValue, value)) {
        const tempState = {...state}
        delete tempState.modifiedData[leagueKey].rounds[roundKey][field]
        return {...tempState, modifiedDataCount: tempState.modifiedDataCount - 1}
      }

      const isThisANewFieldUpdated = state.modifiedData[leagueKey]?.rounds?.[roundKey]?.[field]
      let incrementModifiedDataCount = 0
      if (!isThisANewFieldUpdated) {
        incrementModifiedDataCount = 1
      }

      if (state.modifiedData === undefined){
        state.modifiedData = {}
      }

      const modifiedData = {
        ...state.modifiedData,
        [leagueKey]: {
          ...state.modifiedData[leagueKey],
          rounds: {
            ...state.modifiedData[leagueKey]?.rounds,
            [roundKey]: {
              ...state.modifiedData[leagueKey]?.rounds?.[roundKey],
              [field]: value,
            },
          },
        },
      }

      return {
        ...state,
        modifiedData: modifiedData,
        modifiedDataCount: state.modifiedDataCount + incrementModifiedDataCount,
      }
    }

    case ZERO_MODIFIED_DATA_COUNT: {
      return {
        ...state,
        modifiedDataCount: 0,
      }
    }

    case SUBMIT:{
      return {
        ...state,
        modifiedData: {},
      }
    }

    default:
      return {
        ...state,
      }
  }
}

const checkIfFieldWasResetToOriginalValue = (field, currentValue, newValue) => {
  if (currentValue === undefined || currentValue === null) { return false }
  
  return currentValue.toString() === newValue.toString()
}

export default table
