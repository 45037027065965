import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-bootstrap-toggle'

class Pairings extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount() {
    $('#show_teesheet_member_portal').on('change', () => {
      // eslint-disable-next-line newline-per-chained-call
      if (this.props.content.roundId === $('#round_panel_selector').find('option:selected').val()) {
        setTimeout(
          () => this.props.refresh(),
          5000
        )
      }
    })
  }

  render() {
    const { title, links, data, roundToggleText, roundOptions, roundId } = this.props.content

    return (
      <div className='widget-content-default widget-content-rounds widget-content-pairings'>
        <div className='title'>
          { title }

          {
            links.pairings.display
              ? <a href={ links.pairings.path } className='btn btn-primary widget-white-title-button'>
                { links.pairings.name }
              </a>
              : null
          }
        </div>
        <div className='content'>
          <div className='buttons-container'>
            <div className='pairings-count'>
              { data.pairings_count.name }
              <span className='data'>
                { data.pairings_count.value }
              </span>
              <span>
                :
              </span>
            </div>
          </div>
          <div className='round-options no-lite'>
            { roundOptions.show_parings_in_event_portal.name }
            <Toggle onClick={ () => { this.props.toggleRoundOptions(roundOptions.show_parings_in_event_portal.path, 'show_parings_in_event_portal', roundId) } }
                    on={ roundToggleText.yes }
                    off={ roundToggleText.no }
                    className='rounded'
                    disabled={ roundOptions.show_parings_in_event_portal.disabled }
                    active={ roundOptions.show_parings_in_event_portal.status } />
          </div>
          <div className='play-data-container'>
            { data.course_and_tee.name }
            { data.course_and_tee.value.map(coursAndTee => <div key={ coursAndTee } className={ `${data.course_and_tee.empty ? 'no-play-data-warning' : 'play-data'}` }>{ coursAndTee }</div>) }
          </div>
          <div className='play-data-container'>
            { data.starting_time.name }
            { data.starting_time.value.map(time => <div key={ time } className={ `${data.starting_time.empty ? 'no-play-data-warning' : 'play-data'}` }>{ time }</div>) }
          </div>
        </div>
      </div>
    )
  }
}

Pairings.propTypes = {
  content: PropTypes.object,
  toggleRoundOptions: PropTypes.func,
  refresh: PropTypes.func,
}

Pairings.defaultProps = {
  content: {
    title: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.pairings.title'),
    links: {
      pairings: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.pairings.create_new_pairings'),
        value: '#',
      },
    },
    data: {
      pairings_count: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.pairings.pairings_count'),
        value: 0,
      },
      course_and_tee: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.pairings.course_and_tee'),
        value: ' - ',
      },
      starting_time: {
        name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.pairings.starting_time'),
        value: null,
      },
    },
  },
}

export default Pairings
