import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'
import HoleByHoleRowComponent from './hole_by_hole_row_component'

import { GlgTable, GlgTableCol, GlgCheckbox } from 'SharedComponents'
import { getColumnName, getColumnWidth } from 'Shared/course_boards'
import GlgTextInput from '../../shared/components/glg_text_input'

class HoleByHoleTableComponent extends Component {
  constructor(props) {
    super(props)
    this.renderFooter = this.renderFooter.bind(this)
  }

  renderFooter() {
    const { isMultiRound, withTeams, showCourse, showMessage, 
      message, score, handleShowMessageChanged,
      handleScoreChanged, handleMessageChanged,
    } = this.props

    const scoreSpan = isMultiRound ? 2 : 1
    let messageSpan = withTeams ? 2 : 3
    if (showCourse) {
      messageSpan += 1
    }

    return (
      <tr className='footer-row'>
        <td className='glg-table-header' colSpan={1}>
          <GlgCheckbox name='show-message'
                      checked={showMessage}
                      onChange={(checked) => handleShowMessageChanged(checked)}/>
        </td>
        <td className='glg-table-header message-cell' colSpan={messageSpan}>
          <span>Message:</span>
          <GlgTextInput placeholder='' value={message} onChange={(value) => handleMessageChanged(value)}/>
        </td>
        <td className='glg-table-header score-cell' colSpan={scoreSpan}>
          <span>Score:</span>
          <GlgTextInput placeholder='' value={score} onChange={(value) => handleScoreChanged(value)}/>
        </td>
        <td className='glg-table-header'/>
      </tr>
    )
  }

  render() {
    const { columns, lines, removeLine, reorderLines } = this.props
    const rowExtraProps = {
      columns: columns,
      removeLine: removeLine,
    }
    const footer = this.renderFooter()
    
    return (
      <Fragment>
        <Col xs={12} sm={12} md={6} lg={6} className='no-padding'>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className='page_title'>
              <div className='text'>Monster Leaderboard Design</div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className='hole_by_hole_monster_table'>
            <GlgTable items={lines}
                      rowComponent={ HoleByHoleRowComponent }
                      rowExtraProps={ rowExtraProps }
                      maxHeight={ 466 }
                      isSortable={true}
                      onSortEnd={reorderLines}
                      showFooter={true}
                      footerComponent={ footer }>
              {
                columns.map( column => (
                  <GlgTableCol key={column} width={getColumnWidth(column)}>
                    {getColumnName(column)}
                  </GlgTableCol>
                ))
              }
            </GlgTable>
          </Col>
        </Col>
      </Fragment>
    )
  }
}

HoleByHoleTableComponent.propTypes = {
  isMultiRound: PropTypes.bool,
  withTeams: PropTypes.bool,
  showCourse: PropTypes.bool,
  lines: PropTypes.array,
  columns: PropTypes.array,
  showMessage: PropTypes.bool,
  message: PropTypes.string,
  score: PropTypes.string,
  removeLine: PropTypes.func,
  reorderLines: PropTypes.func,
  handleShowMessageChanged: PropTypes.func,
  handleScoreChanged: PropTypes.func,
  handleMessageChanged: PropTypes.func,
}

export default HoleByHoleTableComponent
