import React, { Component } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import { get } from 'lodash'

import ReportCenterItemBase from './report_center_item_base'
import PrintModalContainer from './print_modal_container'
import flat from 'array.prototype.flat'

class DiscoverCenter extends Component {
  constructor(props) {
    super(props)
    //preselect scorecards category when coming from scorecards center
    const URL = window.location.search
    const urlFilter = /category=([^&]+)/.exec(URL)
    if (urlFilter){
      const targetFilter = this.props.filters['Category'].find((opt) => { return opt.value.toLowerCase() === urlFilter[1] })
      if (targetFilter){
        targetFilter.checked = true
      }
    }

    //hide Others & Player list categories at customer level (no round)
    if (this.props.round_id === null){
      this.props.filters['Category'] = this.props.filters['Category'].filter((opt) => { return [ 'Others', 'Player Lists' ].includes(opt.value) })
    }

    //precompute report_center_items categories string
    this.props.report_center_items.forEach((rci) => {
      rci.categories_string = flat(Object.values(rci.report_center_categories))
                                    .map((cc) => { return cc.value })
                                    .join(' ')
                                    .toLowerCase()
    })

    this.state = {
      report_center_items: this.props.report_center_items,
      report_center_lists: this.props.report_center_lists,
      search_term: this.props.search_term || '',
      filters: this.props.filters,
      filtered_types: {
        'Gpu::Report': { checked: false, name: 'Report' },
        'SpreadsheetReport': { checked: false, name: 'Spreadsheet' },
        'Gpu::Scorecard': { checked: false, name: 'Scorecard' },
      },
      layout: 'inline',
      sort_by: 'Printed',
      preview_rci: null,
      modal_parameters: null,
    }

    this.setPreview = this.setPreview.bind(this)
    this.addRemoveItemToList = this.addRemoveItemToList.bind(this)
    this.updateItemStateAttribute = this.updateItemStateAttribute.bind(this)
    this.openPrintModal = this.openPrintModal.bind(this)
    this.setFilterTerm = this.setFilterTerm.bind(this)
    this.toggleItemsLayout = this.toggleItemsLayout.bind(this)
    this.cloneItem = this.cloneItem.bind(this)
  }

  clearFilters(_callback) {
    this.setState(update(this.state, {
      filters: { $set: this.props.filters },
    }), _callback)
  }

  scrollToReport(_id) {
    //reset filters to show all reports
    this.clearFilters(() => {
      $('html').animate({
        scrollTop: $('#' + _id).offset().top,
      }, 300)
    })
  }

  componentDidMount() {
    this.initializeCustomDropdowns()
  }

  componentDidUpdate() {
    this.initializeCustomDropdowns()
  }

  initializeCustomDropdowns() {
    $('.hover-dropdown-button').off('click')
    $('.hover-dropdown-button').on('click', function(e){
      e.stopPropagation()
      const ul = $(this).next('ul')
      const wasOpen = ul.attr('data-is-open')
      $('.hover-dropdown').hide()
      $('.hover-dropdown').attr('data-is-open', 'closed')
      if (wasOpen === 'opened') {
        ul.attr('data-is-open', 'closed')
      } else {
        ul.show()
        ul.attr('data-is-open', 'opened')
      }
    })

    $(document).on('click', (e) => {
      const ul = e.target.closest('ul')
      if (!ul || ul.className !== 'hover-dropdown') {
        $('.hover-dropdown').hide()
        $('.hover-dropdown').attr('data-is-open', 'closed')
      }
    })
  }

  updateItemStateAttribute(rciId, key, value) {
    const j = this.state.report_center_items.findIndex(rci => (rci.id === rciId))
    const map = { report_center_items: { [j]: { [key]: { $set: value} }} }

    this.setState(update(this.state, map))
  }

  toggleFilter(category, index) {
    this.setState(update(this.state, {
      filters: {
        [category]: {
          [index]: { checked: { $set: !this.state.filters[category][index].checked }},
        },
      },
    }))
  }

  toggleFilterType(type) {
    this.setState(update(this.state, {
      filtered_types: {
        [type]: {
          checked: { $set: !this.state.filtered_types[type].checked },
        },
      },
    }))
  }

  openPrintModal(opts) {
    this.setState({ modal_parameters: opts })
  }

  setFilterTerm(event) {
    this.setState({ search_term: event.target.value })
  }

  toggleItemsLayout(layout) {
    this.setState(update(this.state, {
      layout: { $set: layout },
    }))
  }

  filteredSortedResults() {
    let c = JSON.parse(JSON.stringify(this.state.report_center_items))

    //filter by search term
    if (this.state.search_term !== '') {
      c = c.filter(rci => {
        const searchTerm = this.state.search_term.toLowerCase().trim()
        const customerName = this.props.customers_info[rci.customer_id].customer_name.toLowerCase()

        const inRciName = rci.name.toLowerCase().indexOf(searchTerm) !== -1
        const inCustomerName = customerName.indexOf(searchTerm) !== -1
        const inRciCategory = rci.categories_string.indexOf(searchTerm) !== -1
        const inRciTags = rci.tags.toLowerCase().indexOf(searchTerm) !== -1

        return inRciName || inCustomerName || inRciCategory || inRciTags
      })
    }

    //filter by customer id
    if (this.state.preview_rci !== null){
      c = c.filter(rci => {
        return rci.customer_id === this.state.preview_rci.customer_id
      })
    }

    //filter by filters
    c = c.filter(rci => {
      let catMatch = true
      Object.keys(this.state.filters).forEach(category => {
        const selectedVals = this.state.filters[category].filter(option => option.checked).map(value => value.id)
        const rciVals = (rci.report_center_categories[category] || []).map(value => value.id)

        if (selectedVals.length !== 0 && $(selectedVals).filter(rciVals).length === 0) {
          catMatch = false
        }
      })

      const formatTypes = []
      Object.keys(this.state.filtered_types).forEach(t => { if (this.state.filtered_types[t].checked) { formatTypes.push(t) } } )
      catMatch = catMatch && (formatTypes.length === 0 || (formatTypes.indexOf(rci.report_type) !== -1))
      return catMatch
    })

    //sort by sort options
    const sortFunc = this.state.sort_by === 'Alphabetical' ? (rci1, rci2) => (rci1.name > rci2.name ? 1 : -1) : (this.state.sort_by === 'Printed' ? ((rci1, rci2) => rci2.print_count - rci1.print_count) : (rci1, rci2) => rci2.list_count - rci1.list_count)
    c = c.sort(sortFunc)

    return c
  }

  setPreview(rci) {
    this.setState(update(this.state, {
      preview_rci: { $set: rci },
    }))
  }

  stringParameterize(str) {
    return str.trim().toLowerCase()
      .replace(/[^a-zA-Z0-9 -]/g, '')
      .replace(/ +/g, ' ')
      .replace(/[\s-]/g, '_')
  }

  //clones and adds to listId api call
  cloneItem(rciId, listId) {
    const rclIndex = this.state.report_center_lists.findIndex(rcl => (rcl.id === listId))

    $.ajax('/api/report_center/report_center_items/' + rciId + '/import', {
      method: 'PATCH',
      data: { report_center_list_id: listId, league_id: this.props.league_id, round_id: this.props.round_id },
      success: (data) => {
        const newRci = data['rci']
        this.setState(
          update(this.state, {
            report_center_lists: {
              [rclIndex]: {
                report_center_items: { $push: [ newRci ] },
              },
            },
          }),
          () => {
            this.updateItemStateAttribute(rciId, 'list_count', data['list_count'])
          }
        )
      },
      error: () => { console.error('unable to clone report center item to list') },
    })
  }

  addRemoveItemToList(rciId, listId, op) {
    const newRclIndex = this.state.report_center_lists.findIndex(rcl => (rcl.id === listId))

    if (op.target.checked) {
      this.cloneItem(rciId, listId)
    } else {
      const newRcl = this.state.report_center_lists.filter(rcl => (rcl.id === listId))[0]
      const rciIndex = newRcl.report_center_items.findIndex(rci => (rci.parent_id === rciId))
      const rci = newRcl.report_center_items[rciIndex]

      $.ajax('/api/report_center/report_center_items_lists/0', {
        method: 'DELETE',
        data: { report_center_items_lists: { report_center_list_id: listId, report_center_item_id: rci.id }, rci_print_id: rciId, league_id: this.state.league_id},
        success: (data) => {
          this.setState(
            update(this.state, {
              report_center_lists: {
                [newRclIndex]: {
                  report_center_items: { $splice: [ [ rciIndex, 1 ] ] },
                },
              },
            }),
            () => {
              this.updateItemStateAttribute(rciId, 'list_count', data['list_count'])
            },
          )
        },
        error: () => { console.error('unable to add/remove report center item to list') },
      })
    }
  }

  render() {
    let categoriesArray, hidden
    if (this.props.round_id !== null) {
      categoriesArray = [ 'Category', 'Tournament Type', 'Data', 'Paper Size' ]
      hidden = ''
    } else {
      categoriesArray = [ 'Category', 'Paper Size' ]
      hidden = 'hidden' // Remove when adding REPORT COMPOSER
    }
    return (
      <div className='discover_center_wrapper'>
        <div className='row filter'>
          <div className='col-xs-12 col-sm-7 col-lg-8'>
            <ul className='filters'>
              <li>
                <div className={'dropdown ' + hidden}>
                  <button className='btn btn-default hover-dropdown-button'
                          type='button'>
                    {!window.I18n ? '' : window.I18n.t('download_center.discover_center.format')}
                    <i className='fa fa-fw fa-caret-down' aria-hidden='true'></i>
                  </button>
                  <ul className='hover-dropdown'>
                    {
                      Object.keys(this.state.filtered_types).map(type => {
                        return (
                          <li key={ 'format_' + type }>
                            <div className="checkbox btsp_checkbox checkbox-primary no-margin">
                              <input type='checkbox'
                                     id={ 'i_' + type }
                                     name="format_type"
                                     onChange={ () => { this.toggleFilterType(type) } }
                                     checked={ this.state.filtered_types[type].checked } />
                              <label htmlFor={ 'i_' + type } >
                                { this.state.filtered_types[type].name }
                              </label>
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </li>

              { categoriesArray.map(category => {
                  const trans = this.stringParameterize(category)
                  return (
                    <li key={category}>
                      <div>
                        <button className='btn btn-default hover-dropdown-button'
                                type='button'
                                id='dropdownMenu1'>
                          {!window.I18n ? '' : window.I18n.t(`download_center.discover_center.${trans}`)}
                          <i className='fa fa-fw fa-caret-down' aria-hidden='true'></i>
                        </button>
                        <ul className='hover-dropdown'>
                          {
                            this.state.filters[category].map((value, index) => {
                              const val = this.stringParameterize(value.value)
                              return (
                                <li key={value.id}>
                                  <div className="checkbox btsp_checkbox checkbox-primary no-margin">
                                    <input type='checkbox'
                                           id={ 'i_' + category + '_' + value.id }
                                           name={ category + '[]' }
                                           onChange={() => { this.toggleFilter(category, index) } }
                                           checked={ value.checked } />
                                    <label htmlFor={ 'i_' + category + '_' + value.id } >
                                    {!window.I18n ? '' : window.I18n.t(`download_center.discover_center.${trans}_values.${val}`)}
                                    </label>
                                  </div>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    </li>
                  )
                })
              }
              {
                <li>
                  <div className='dropdown'>
                    <button className='btn btn-default hover-dropdown-button'
                            type='button'>
                      {!window.I18n ? '' : window.I18n.t('download_center.discover_center.sort')}
                      <i className='fa fa-fw fa-caret-down' aria-hidden='true'></i>
                    </button>
                    <ul className='hover-dropdown'>
                      <li className='add-margin-left-10 add-margin-bottom-10 add-margin-top-10'>
                        <a href='#' onClick={ () => { this.setState({ sort_by: 'Alphabetical' }) } } >{ this.state.sort_by === 'Alphabetical' ? <strong>{!window.I18n ? '' : window.I18n.t('download_center.discover_center.alphabetical')}</strong> : !window.I18n ? '' : window.I18n.t('download_center.discover_center.alphabetical') }</a>
                      </li>

                      <li className='add-margin-left-10 add-margin-bottom-10'>
                        <a href='#' onClick={ () => { this.setState({ sort_by: 'Popular' }) } } >{ this.state.sort_by === 'Popular' ? <strong>{!window.I18n ? '' : window.I18n.t('download_center.discover_center.most_listed')}</strong> : !window.I18n ? '' : window.I18n.t('download_center.discover_center.most_listed') }</a>
                      </li>

                      <li className='add-margin-left-10 add-margin-bottom-10'>
                        <a href='#' onClick={ () => { this.setState({ sort_by: 'Printed' }) } } >{ this.state.sort_by === 'Printed' ? <strong>{!window.I18n ? '' : window.I18n.t('download_center.discover_center.most_printed')}</strong> : !window.I18n ? '' : window.I18n.t('download_center.discover_center.most_printed') }</a>
                      </li>
                    </ul>
                  </div>
                </li>
              }
              {
                this.props.is_admin &&
                  <li>
                    <div className='dropdown'>
                      <button className='btn btn-default hover-dropdown-button'
                              type='button'>
                        {!window.I18n ? '' : window.I18n.t('download_center.discover_center.published_status')}
                        <i className='fa fa-fw fa-caret-down' aria-hidden='true'></i>
                      </button>
                      <ul className='hover-dropdown'>
                        {
                          [ 'published', 'pending', 'denied' ].map( (status) => {
                            return (
                              <li key={ status } className={ 'add-padding-left-10 add-padding-bottom-5 add-padding-top-5 ' + ( (this.props.published_status === status) ? 'active' : '' )}>
                                <a href={ '?published_status=' + status }>{!window.I18n ? '' : window.I18n.t(`download_center.discover_center.status.${status}`)}</a>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </li>
              }
            </ul>
          </div>
          <div className='col-xs-10 col-sm-3 col-lg-3'>
            <div className='input-group'>
              <input value={ this.state.search_term } className='form-control search_term' onChange={this.setFilterTerm} placeholder= {!window.I18n ? '' : window.I18n.t('download_center.discover_center.search')}></input>
              <span className='input-group-btn'>
                <div className='btn btn-default' type='button'>
                  <a onClick={ () => { this.setState({ search_term: '' }) }}>
                    <i className='fa fa-search'></i>
                  </a>
                </div>
              </span>
            </div>
          </div>
          <div className='col-xs-2 col-sm-2 col-lg-1'>
            <div className='pull-right'>
              <a href='javascript: void(0)' onClick={() => this.toggleItemsLayout('inline')} className={ this.state.layout === 'inline' ? 'active' : 'inactive'} >
                <i className='fa fa-th-large fa-fw'></i>
              </a>
              <a href='javascript: void(0)' onClick={() => this.toggleItemsLayout('block')} className={ this.state.layout === 'block' ? 'active' : 'inactive'} >
                <i className='fa fa-bars fa-fw'></i>
              </a>
            </div>
          </div>
        </div>
        <div className='row status'>
          <div className='col col-sm-12'>
            <b>&middot;</b> { this.filteredSortedResults().length } {!window.I18n ? '' : window.I18n.t('download_center.discover_center.matching_results')} <b>&middot;</b>
          </div>
        </div>
        <div className='row data discover_center_reports_wrapper' >
            {
              this.state.preview_rci !== null &&
                <div className='col preview pull-left col-xs-12 col-sm-5 author-box'>
                <a onClick={ () => { this.clearFilters(() => this.setPreview(null)) } } className="btsp_small_white_button" href="javascript: void(0);">{!window.I18n ? '' : window.I18n.t('download_center.discover_center.back')}</a>
                <div>
                  <div>
                    <img src={ get(this.props.customers_info[this.state.preview_rci.customer_id], 'customer_logo') || require('usga/home/logos/default_logo_large.png') } />
                    <div> { get(this.props.customers_info[this.state.preview_rci.customer_id], 'customer_name') || '-' }</div>
                    <div> { get(this.props.customers_info[this.state.preview_rci.customer_id], 'customer_city') || '-' }</div>
                  </div>
                  <div>
                    <div>
                      {
                        (get(this.props.customers_info[this.state.preview_rci.customer_id], 'customer_categories_list') || []).map((cat, _index) => {
                          return (<a key={ _index } href="javascript: void(0);" onClick={ () => this.clearFilters(() => { this.toggleFilter('Tournament Type', this.state.filters['Tournament Type'].map(v => v.value).indexOf(cat)) }) } >
                                    <span className="rounded_button">{!window.I18n ? '' : window.I18n.t(`models.report_center_category.tournament_type.${this.stringParameterize(cat)}`, {defaultValue: cat})} </span>
                                  </a>)
                        })
                      }
                    </div>
                    <div>
                      <i className="fa fa-fw fa-trophy"></i>{ get(this.props.customers_info[this.state.preview_rci.customer_id], 'customer_print_rank') || '-' }<br/>
                      <i className="fa fa-fw fa-trophy"></i>{ get(this.props.customers_info[this.state.preview_rci.customer_id], 'customer_list_rank') || '-' }
                    </div>
                    <div>
                      <span>{!window.I18n ? '' : window.I18n.t('download_center.discover_center.recent_documents')}</span>
                      <ul>
                        {
                          (get(this.props.customers_info[this.state.preview_rci.customer_id], 'customer_recent_documents_list') || []).map(rci => (
                            <li key={ rci[0] }>
                              <a onClick={ () => this.scrollToReport(rci[0]) } href="javascript: void(0);">{ rci[1] }</a>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className={ 'col col-xs-12 pull-right' + ( this.state.preview_rci !== null ? ' col-sm-7' : ' col-sm-12' )} style={ {height: '100%' }}>
              <div className='results'>
                {
                  this.state.layout === 'block' ?
                    <div className="table-responsive btsp_table_container btsp_fheader_table_container">
                      <table className="table btsp_fheader_table">
                        <thead>
                          <tr className="header">
                            <th className={ ( this.state.preview_rci !== null ? '' : '') + ' text-center' }><strong className='pull-left'>{!window.I18n ? '' : window.I18n.t('download_center.discover_center.report')}</strong></th>
                            {
                              this.state.preview_rci === null && this.state.layout === 'block' &&
                                <th className='text-center' style={ {width: '60px'} }></th>
                            }
                            {
                              this.state.preview_rci === null && this.state.layout === 'block' &&
                                <th className='text-left'><strong>{!window.I18n ? '' : window.I18n.t('download_center.discover_center.author')}</strong></th>
                            }
                            <th className='text-center stats'><strong>{!window.I18n ? '' : window.I18n.t('download_center.discover_center.statistics')}</strong></th>
                            <th className='text-center list' colSpan="2"><strong>{!window.I18n ? '' : window.I18n.t('download_center.discover_center.actions')}</strong></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.filteredSortedResults().map(rci => (
                              <ReportCenterItemBase key={rci.id}
                                                    is_admin={this.props.is_admin}
                                                    published_status={this.props.published_status}
                                                    report_center_lists={this.state.report_center_lists }
                                                    report_center_item={rci}
                                                    expanded={ this.state.preview_rci !== null }
                                                    setPreview = {this.setPreview}
                                                    addRemoveItemToList = {this.addRemoveItemToList}
                                                    customers_info = {this.props.customers_info}
                                                    league_id={this.props.league_id}
                                                    updateItemStateAttribute={this.updateItemStateAttribute}
                                                    mode="community_reports"
                                                    layout="block"
                                                    openPrintModal={ this.openPrintModal } />
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  :
                    (
                      this.filteredSortedResults().map(rci => (
                        <ReportCenterItemBase key={rci.id}
                                              is_admin={this.props.is_admin}
                                              published_status={this.props.published_status}
                                              report_center_lists={this.state.report_center_lists }
                                              report_center_item={rci}
                                              expanded={ this.state.preview_rci !== null }
                                              setPreview = {this.setPreview}
                                              addRemoveItemToList = {this.addRemoveItemToList}
                                              customers_info = {this.props.customers_info}
                                              league_id={this.props.league_id}
                                              updateItemStateAttribute={this.updateItemStateAttribute}
                                              mode="community_reports"
                                              layout="inline"
                                              openPrintModal={ this.openPrintModal } />
                      ))
                    )
                }
              </div>
            </div>
          </div>
          <PrintModalContainer modal_parameters={ this.state.modal_parameters }
                               openPrintModal = {this.openPrintModal } />
          <img style={ { height: '0px' }} src={require('report_center/no-image.png')} />
      </div>
    )
  }
}

DiscoverCenter.propTypes = {
  report_center_items: PropTypes.array.isRequired,
  report_center_lists: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  customers_info: PropTypes.object.isRequired,
  league_id: PropTypes.string.isRequired,
  round_id: PropTypes.string,
  is_admin: PropTypes.bool.isRequired,
  published_status: PropTypes.string,
  search_term: PropTypes.string,
}

export default DiscoverCenter
