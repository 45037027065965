import React, { Component } from 'react'
import PropTypes from 'prop-types'

import PopupTypes from '../../popup_types'
import GeneralCreateLeaguePopup from './general_create_league_popup'
import _ from 'lodash'

class CloneEventPopup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      prepareLeagues: false,
      leaguesReady: false,
    }

    this.onClose = props.onClose.bind(this, PopupTypes.CLONE_EVENT)
    this.onSave = this.onSave.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    let update = this.props.show !== nextProps.show

    if (update && nextProps.show) {
      update = false

      this.setState({
        prepareLeagues: true,
      })

      this.props.getLeagueFieldData.bind(this)()
  
      setTimeout(() => {
        this.setState({
          prepareLeagues: false,
          leaguesReady: true,
        })
      }, 2000)
    }

    if (nextState.leaguesReady) {
      update = true

      this.setState({
        leaguesReady: false,
      })
    }

    return update
  }

  onSave(data) {
    this.props.onSave({
      fromLeague: data.league.from,
      fromProduct: data.league.product,
      leagueName: data.league.name,
      category: data.category,
      season: data.season,
      tour: data.tour,
      isExpressEvent: data.league.is_express_event,
      paymentModel: data.league.payment_model,
    })
  }

  getTitle() {
    if (this.props.customerOnlyEvents) {
      return !window.I18n ? '' : window.I18n.t('customer_center_menu.components.popups.clone_event_popup.clone_an_event')
    } else if (this.props.customerOnlyLeagues) {
      return !window.I18n ? '' : window.I18n.t('customer_center_menu.components.popups.clone_event_popup.clone_a_league')
    } else if (!this.props.canCreateTrips) {
      return !window.I18n ? '' : window.I18n.t('customer_center_menu.components.popups.clone_event_popup.clone_an_event_or_league')
    } else {
      return !window.I18n ? '' : window.I18n.t('customer_center_menu.components.popups.clone_event_popup.clone_an_event_or_league_or_trip')
    }
  }

  getLeagueId() {
    return this.props.data.leagueId
  }

  render() {
    return <GeneralCreateLeaguePopup type="cloneEvent" title={ this.getTitle() } saveButtonText={ _.capitalize(window.I18n.t('buttons.next')) } closeButtonText={ _.capitalize(window.I18n.t('buttons.cancel')) } show={ this.props.show } onSave={ this.onSave } onClose={ this.onClose } leagueToClone={ this.getLeagueId() }></GeneralCreateLeaguePopup>
  }
}

CloneEventPopup.propTypes = {
  data: PropTypes.object,
  show: PropTypes.bool.isRequired,
  customerOnlyEvents: PropTypes.bool.isRequired,
  customerOnlyLeagues: PropTypes.bool,
  canCreateTrips: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  getLeagueFieldData: PropTypes.func,
}

export default CloneEventPopup
