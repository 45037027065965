import { combineReducers } from 'redux'
import general from './general'
import header from './header'
import footer from './footer'
import slides from './slides'
import library from './library'

export default combineReducers({
  general,
  header,
  footer,
  slides,
  library,
})
