/* eslint-disable no-alert */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Col } from 'react-bootstrap'

class PaymentmethodNameComponent extends Component {
  constructor(props) {
    super(props)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleDefault = this.handleDefault.bind(this)
  }

  handleDelete() {
    this.props.deletePaymentMethod(this.props.id)
  }
  
  handleDefault() {
    this.props.defaultPaymentMethod(this.props.id)
  }

  render() {
    let showImage = ''
    if (this.props.typeName !== !window.I18n ? '' : window.I18n.t('player_activity.registration.payment_methods.saved_credit_card')) {
      showImage = <span className="image">< img src = { this.props.typeName } /></span>
    }
    let defaultBtn = ''
    if (this.props.usedDefault !== '(' + !window.I18n ? '' : window.I18n.t('player_activity.registration.payment_methods.default') + ')') {
      defaultBtn = <Col sm={6} xs={6}><a onClick={e => { if (window.confirm(!window.I18n ? '' : window.I18n.t('player_activity.registration.payment_methods.make_default_method_confirmation'))){ this.handleDefault(e) } } } className="edit-btn p-edit-btn">{ ' ' + !window.I18n ? '' : window.I18n.t('player_activity.registration.payment_methods.default') }</a></Col>
    } else {
      defaultBtn = <Col sm={6} xs={6}><span className="default">{ '(' + !window.I18n ? '' : window.I18n.t('player_activity.registration.payment_methods.default') + ')' }</span></Col>
    }
    return <Col sm={ this.props.colSmSize } xs={ 12 } className="payment-method-single"><div className="panel panel-default"><div className="panel-body text-left"><Col sm={3} xs={4}>{ _.truncate(this.props.cardholder, { length: 40 }) }{ showImage }</Col><Col sm={3} xs={4}>{ _.truncate(this.props.expirationName, { length: 40 }) }</Col><Col sm={3} xs={4}>{ _.truncate(this.props.lastdigitName, { length: 40 }) }</Col><Col sm={3} xs={12}>{ defaultBtn }<Col sm={6} xs={6}><a onClick={e => { if (window.confirm(!window.I18n ? '' : window.I18n.t('player_activity.registration.payment_methods.delete_method_confirmation'))){ this.handleDelete(e) } } } className="edit-btn p-edit-btn"><i className="fa fa-remove"></i>{ ' ' + !window.I18n ? '' : window.I18n.t('player_activity.registration.payment_methods.delete') }</a></Col></Col></div></div></Col>
  }
}

PaymentmethodNameComponent.propTypes = {
  typeName: PropTypes.string.isRequired,
  cardholder: PropTypes.string.isRequired,
  expirationName: PropTypes.string.isRequired,
  lastdigitName: PropTypes.string.isRequired,
  usedDefault: PropTypes.string.isRequired,
  colSmSize: PropTypes.number.isRequired,
  deletePaymentMethod: PropTypes.func,
  defaultPaymentMethod: PropTypes.func,
  id: PropTypes.string,
}

PaymentmethodNameComponent.defaultProps = {
  typeName: '',
  cardholder: '',
  expirationName: '',
  lastdigitiName: '',
}

export default PaymentmethodNameComponent
