import { css } from 'styled-components'

export const commonStyles = css`
  letter-spacing: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
`
