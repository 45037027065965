import React, {Fragment} from 'react'
import PropTypes from 'prop-types'

const TourEventsEarningsTable = ({tourEvents}) => {

  return (
    <Fragment>
      <div className="table-responsive">
        <table className="table inner_table">
          <thead>
          <tr>
            <th className="dates">{window.I18n.t('tour_games_season_standings.tour_events_earnings_table.tour_game_dates')}</th>
            <th className="name">{window.I18n.t('tour_games_season_standings.tour_events_earnings_table.tour_game_name')}</th>
            <th>{window.I18n.t('tour_games_season_standings.tour_events_earnings_table.earnings')}</th>
          </tr>
          </thead>
          <tbody>
          {tourEvents?.map((result, rIdx) =>
            <tr className={`aggregate-row ${rIdx & 1 ? 'odd' : 'even'}`}
                key={rIdx}>
              <td className="dates">{result.dates}</td>
              <td className="name">{result.name}</td>
              <td>{result.earnings.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</td>
            </tr>,
          )}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

TourEventsEarningsTable.propTypes = {
  tourEvents: PropTypes.array,
}

export default TourEventsEarningsTable
