import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { getUniqueElementId } from '../helpers'

class GlgList extends Component {
  constructor(props) {
    super(props)
    this.handleSortEnd = this.handleSortEnd.bind(this)
    this.renderItem = this.renderItem.bind(this)
    this.droppableId = getUniqueElementId()
  }

  handleSortEnd(result) {
    if (!result.destination) {
      // dropped outside the list
      return
    }

    if (result.type === 'list') {
      // top level drag&drop
      this.props.onSortEnd(result.source.index,
                           result.destination.index)
    } else {
      // nested drag&drop
      this.props.onNestedSortEnd(result.source.index,
                                 result.destination.index,
                                 result.source.droppableId,
                                 result.destination.droppableId)
    }
  }

  renderItem(item, dragHandleProps, snapshot) {
    const ItemComponent = this.props.itemComponent
    const isSortable = this.props.isSortable

    const allProps = {
      ...item,
      ...this.props.itemExtraProps,
    }

    return <Fragment>{!isSortable && <div className="hidden" { ...dragHandleProps }></div>}<ItemComponent { ...allProps } isDragging={ snapshot.isDragging } dragHandleProps={ isSortable ? dragHandleProps : {} }></ItemComponent></Fragment>
  }

  render() {
    return <div className="glg-react-list"><DragDropContext onDragEnd={ this.handleSortEnd }><Droppable droppableId={ this.droppableId } type="list">{(provided) => <div ref={ provided.innerRef }>{this.props.items.map((item, index) => <Draggable key={ item.id } draggableId={ item.id } index={ index } type="list">{(provided, snapshot) => <div ref={ provided.innerRef } {...provided.draggableProps} style={ provided.draggableProps.style } className={ snapshot.isDragging ? 'dragging' : '' }>{ this.renderItem({ ...item, index }, provided.dragHandleProps, snapshot) }</div>}</Draggable>)}{ provided.placeholder }</div>}</Droppable></DragDropContext></div>
  }
}

GlgList.propTypes = {
  // Array of list items
  items: PropTypes.array.isRequired,
  // The component used to render each item
  itemComponent: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.func.isRequired,
  ]).isRequired,
  isSortable: PropTypes.bool,
  // Callback for drag&drop between list items. Only used if sortable is true.
  onSortEnd: PropTypes.func,
  // Callback for drag&drop between nested items
  onNestedSortEnd: PropTypes.func,
  // Extra props for an item
  itemExtraProps: PropTypes.object,

}

GlgList.defaultProps = {
  isSortable: false,
}

export default GlgList
