import {showErrorNotification, showNotification} from '../../shared/helpers'

export const ActionTypes = {
  EMPTY_TEAMSET_SETUP: 'EMPTY_TEAMSET_SETUP',
  ADD_NEW_TEAM: 'ADD_NEW_TEAM',
  DELETE_TEAM: 'DELETE_TEAM',
  CHANGE_TEAM_NAME: 'CHANGE_TEAM_NAME',
  CHANGE_ROUNDS_COUNT: 'CHANGE_ROUNDS_COUNT',
  CHANGE_TEAM_CAPTAIN: 'CHANGE_TEAM_CAPTAIN',
  REORDER_TEAMS: 'REORDER_TEAMS',
  CHANGE_ORDER: 'CHANGE_ORDER',
  CHANGE_ROUND_ORDER: 'CHANGE_ROUND_ORDER',
  CHANGE_RANKING_METHOD: 'CHANGE_RANKING_METHOD',
  CHANGE_PICK_TIME: 'CHANGE_PICK_TIME',
  CHANGE_PREV_LEAGUE: 'CHANGE_PREV_LEAGUE',
  CHANGE_GROSS_SCORES: 'CHANGE_GROSS_SCORES',
  RECALCULATE_ROUND_ORDER: 'RECALCULATE_ROUND_ORDER',
  SAVING_DRAFT: 'SAVING_DRAFT',
}

export const defaultSetupEmptyTeamset = () => (dispatch) => {
  dispatch({type: ActionTypes.EMPTY_TEAMSET_SETUP})
}

export const addNewTeam = () => (dispatch) => {
  dispatch({type: ActionTypes.ADD_NEW_TEAM})
}

export const deleteTeam = (team) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.DELETE_TEAM,
    data: team,
  })

  dispatch({type: ActionTypes.CHANGE_ROUNDS_COUNT})

  dispatch({
    type: ActionTypes.RECALCULATE_ROUND_ORDER,
    roundsCount: getState().misc.roundsCount,
  })
}

export const changeTeamName = (data) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.CHANGE_TEAM_NAME,
    data: data,
  })

  dispatch({type: ActionTypes.CHANGE_ROUNDS_COUNT})

  dispatch({
    type: ActionTypes.RECALCULATE_ROUND_ORDER,
    roundsCount: getState().misc.roundsCount,
  })
}

export const changeTeamCaptain = (data) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.CHANGE_TEAM_CAPTAIN,
    data: data,
  })

  dispatch({type: ActionTypes.CHANGE_ROUNDS_COUNT})

  dispatch({
    type: ActionTypes.RECALCULATE_ROUND_ORDER,
    roundsCount: getState().misc.roundsCount,
  })
}

export const reorderTeams = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.REORDER_TEAMS,
    data: data,
  })
}

export const changeOrder = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.CHANGE_ORDER,
    data: data,
  })
}

export const changeRoundOrder = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.CHANGE_ROUND_ORDER,
    data: data,
  })
}

export const changeRankingMethod = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.CHANGE_RANKING_METHOD,
    data: data,
  })
}

export const changePickTime = (data) => (dispatch) => {
  const pickTime = parseInt(data)
  if (pickTime && pickTime > 999999999) {
    return
  }

  dispatch({
    type: ActionTypes.CHANGE_PICK_TIME,
    data: pickTime,
  })
}

export const changePrevLeagueId = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.CHANGE_PREV_LEAGUE,
    data: data,
  })
}

export const changeGrossScores = (data) => (dispatch) => {
  const grossScores = parseInt(data)
  if (grossScores && grossScores > 999999999) {
    return
  }

  dispatch({
    type: ActionTypes.CHANGE_GROSS_SCORES,
    data: grossScores,
  })
}

export const saveDraft = () => (dispatch, getState) => {
  dispatch({type: ActionTypes.SAVING_DRAFT})

  const state = getState()
  const action = state.misc.action
  const method = action === 'new' ? 'POST' : 'PUT'
  const url = state.misc.draftsUrl + (action === 'new' ? '' : `/${state.drafts.id}`)

  $.ajax({
    method: method,
    url,
    data: {
      teamset_id: state.drafts.teamsetId,
      name: state.drafts.name,
      draft: JSON.stringify({
        teams: state.misc.teams,
        order: state.drafts.order,
        rounds_order: state.drafts.roundsOrder,
        ranking_method: state.drafts.rankingMethod,
        prev_league_id: state.drafts.prevLeagueId,
        gross_scores: state.drafts.grossScores,
        pick_time: state.drafts.pickTime,
      }),
    },
    success: (data) => {
      if (data.errorMessage) {
        showErrorNotification(data.errorMessage)
      } else {
        showNotification(data.successMessage)
        window.location.href = state.misc.draftsUrl
      }
    },
    error: () => {
      showErrorNotification(window.I18n.t('draft.actions.setup_index.error_creating_or_updating_draft', {creating_or_updating: action === 'new' ? 'creating' : 'updating'}))
    },
  })
}
