import { connect } from 'react-redux'

import SalesDemoLeaguesTableComponent from '../../components/sales_demo/sales_demo_leagues_table_component'

const mapStateToProps = (state, props) => {
  const categoryId = props.categoryId
  const leaguesById = state.leagues
  const leagues = state.categories[categoryId].leagues.map(leagueId => leaguesById[leagueId])
  return {
    leagues,
  }
}

const mapDispatchToProps = () => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesDemoLeaguesTableComponent)
