import { connect } from 'react-redux'
import { changeFilters, changeSeason, changeStrength } from '../actions'
import FiltersComponent from '../components/filters_component'


const mapStateToProps = (state) => {
  return { 
    field: state.filters.field,
    fieldValue: state.filters.fieldValue,
    strength: state.filters.strength,
    activeSeason: state.filters.season,
    stickyMemberCard: state.sticky.memberCard,
    stickyMembers: state.sticky.members,
    stickyIndex: state.sticky.index,
    scrollY: state.misc.scrollY,
    seasons: state.misc.seasons,
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeFilters: (newFilters) => dispatch(changeFilters(newFilters)),
  changeSeason: (newSeason) => dispatch(changeSeason(newSeason)),
  changeStrength: (newStrength) => dispatch(changeStrength(newStrength)),
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersComponent)
