import React from 'react'
import PropTypes from 'prop-types'

import {
  GlgTable,
  GlgTableCol,
} from 'SharedComponents'

import SalesDemoLeagueRowComponent from './sales_demo_league_row_component'

const SalesDemoLeaguesTableComponent = ({ leagues }) => <GlgTable items={ leagues } rowComponent={ SalesDemoLeagueRowComponent } showHeader={ false }><GlgTableCol width="60%" dataKey="name"></GlgTableCol><GlgTableCol width="20%" dataKey="portalURL"></GlgTableCol><GlgTableCol width="20%" dataKey="leagueURL"></GlgTableCol></GlgTable>

SalesDemoLeaguesTableComponent.propTypes = {
  leagues: PropTypes.array.isRequired,
}

export default SalesDemoLeaguesTableComponent
