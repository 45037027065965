import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import { getUniqueElementId } from '../helpers'

class GlgTooltip extends PureComponent {
  constructor(props) {
    super(props)
    this.tooltipId = getUniqueElementId()
  }

  render() {
    const tooltip = <Tooltip id={ this.tooltipId }>{ this.props.tooltip }</Tooltip>
    return <OverlayTrigger placement={ this.props.placement } overlay={ tooltip }>{ this.props.children }</OverlayTrigger>
  }
}

GlgTooltip.propTypes = {
  // Show tooltip on hover over this element 
  children: PropTypes.node,
  // The text of the tooltip
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  // The placement of the tooltip
  placement: PropTypes.oneOf([ 'top', 'bottom', 'left', 'right' ]),
}

GlgTooltip.defaultProps = {
  placement: 'top',
}

export default GlgTooltip
