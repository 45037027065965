import React from 'react'
import PropTypes from 'prop-types'
import { GlgCheckbox } from '../../../shared/components'

const ClubSelectPopupRow = (props) => {

  const className = props.isEven ? 'evenRow' : 'oddRow'
  const id = props.club.customer_id

  const handleChange = (val) => {
    props.handleClubCandidate(id, val)
  }

  if (id !== undefined) {
    return (
      <tr className={className} key={id}>
        <td>
          <GlgCheckbox
            name={id?.toString()}
            label={props.club.name}
            onChange={(val) => handleChange(val)}
            checked={props.club.selected === true}>
          </GlgCheckbox>
        </td>
      </tr>
    )
  } else {
    return null
  }
}

ClubSelectPopupRow.propTypes = {
  club: PropTypes.object,
  isEven: PropTypes.bool,
  handleClubCandidate: PropTypes.func,
}

export default ClubSelectPopupRow
