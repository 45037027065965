import React from 'react'
import PropTypes from 'prop-types'
import { GlgCheckbox } from '../../../shared/components'
import ClubSelectPopupRow from './ClubSelectPopupRow'

const ClubSelectPopupTable = (props) => {
  return (
    <div className='clubs-container'>
      <h4 className='bold'>
        {props.tableName} ({props.clubsNo})
        {props.showFilteredText && <i className='filteredText'>{' / ' + `${window.I18n.t('views.golfhub.aggregate_from.filtered')}`} {`(${props.clubs.length})`}</i> } 
      </h4>
      <div className='table-container'>
        <table className='no-padding'>
          <tbody>
            <tr className='oddRow firstClubRow'>
              <th>
                <div className='all-clubs-selector'>
                  <GlgCheckbox
                    label='Club'
                    inline={true}
                    onChange={(val) => props.handleSelectAllChange(val)}
                    checked={props.selectAll}>
                  </GlgCheckbox>
                    {props.sortAsc ?
                    <i className='gga-carret-up clubs-carret' onClick={() => props.handleSort(false)}></i>
                    : <i className='gga-carret-down clubs-carret' onClick={() => props.handleSort(true)}></i>
                    }
                </div>
              </th>
            </tr>
            {props.clubs.map((club, index) =>
              <ClubSelectPopupRow key={index} club={club} isEven={index % 2 === 0} handleClubCandidate={props.handleClubCandidate}/>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

ClubSelectPopupTable.propTypes = {
  clubs: PropTypes.array,
  tableName: PropTypes.string,
  clubsNo: PropTypes.number,
  showFilteredText: PropTypes.bool,
  handleClubCandidate: PropTypes.func,
  selectAll: PropTypes.bool,
  handleSelectAllChange: PropTypes.func,
  sortAsc: PropTypes.bool,
  handleSort: PropTypes.func,
}

export default ClubSelectPopupTable
