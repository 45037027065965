import { ActionTypes } from '../actions'

const {
  FETCH_MEMBERSHIP_PROGRAMS_DATA_REQUEST,
  FETCH_MEMBERSHIP_PROGRAMS_DATA_RECEIVED,
  TOGGLE_MEMBERSHIP_AUTO_RENEW,
} = ActionTypes

const initialMisc = {
}

const membershipPrograms = (state = initialMisc, action) => {

  switch (action.type) {
    case FETCH_MEMBERSHIP_PROGRAMS_DATA_REQUEST:
      return {
        ...state,
        dataIsLoading: true,
      }
    case FETCH_MEMBERSHIP_PROGRAMS_DATA_RECEIVED:
      return {
        ...state,
        ...action.data,
        dataIsLoading: false,
        loaded: true,
      }
    case TOGGLE_MEMBERSHIP_AUTO_RENEW:
      return {
        ...state,
        memberships: {
          ...state.memberships,
          [action.id]: {
            ...state.memberships[action.id],
            has_auto_renew: !state.memberships[action.id].has_auto_renew,
          },
        },
      }
    default:
      return state
  }
}

export default membershipPrograms
