import React, { useState } from 'react'
import { Col } from 'react-flexbox-grid'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const SaveBar = ({ onCancel, onSave, disableHelper }) => {
  const [ isDisabled, setIsDisabled ] = useState(false)
  const modifiedDataCount = useSelector((state) => state.table.modifiedDataCount)

  const handleOnClick = (e) => {
    e.preventDefault()
    setIsDisabled(true)
    onSave()
  }

  return (
    <div className='save_bar'>
      <Col xs={12} sm={12} md={12} lg={12} className='no-padding'>
        <Col xs={6} sm={6} md={6} lg={6}>
          <a className='btsp_gray_button' onClick={onCancel}>
            Cancel
          </a>
        </Col>
        <Col xs={6} sm={6} md={6} lg={6}>
          <a className={`btsp_orange_button ${isDisabled || disableHelper ? 'disabled' : ''}`} onClick={handleOnClick}>
            {modifiedDataCount === 1 ? 'Save (1 Change)' : `Save (${modifiedDataCount} Changes)`}
          </a>
        </Col>
      </Col>
    </div>
  )
}

SaveBar.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  disableHelper: PropTypes.bool.isRequired,
}

export default SaveBar
