import { connect } from 'react-redux'
import GroupViewComponent from '../components/group_view_component'

import { fetchInitialData, refreshData } from '../actions'

const mapStateToProps = (state) => {
  return {
    leagueId: state.misc.leagueId,
    roundId: state.misc.roundId,
    isLoading: state.groupViewData.isLoading,
    isPortal: state.misc.isPortal,
    hasResults: state.groupViewData.hasResults,
    selectedTournamentName: state.misc.selectedTournamentName,
    showFilters: state.filters.showFilters,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchInitialGroupViewData: () => dispatch(fetchInitialData()),
  refreshData: () => dispatch(refreshData()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupViewComponent)
