import React from 'react'
import PropTypes from 'prop-types'
import {Pagination} from 'react-bootstrap'

const PaginationComponent = ({ ...props }) => {
  const changePage = (nr) => {
    if (props.page !== nr) {
      if ((nr < 1 || nr > nrPages) || props.page === nr) {
        return
      }
      props.setCurrentPage(nr)
    }
  }

  const nrPages = Math.ceil(props.elementsCount / props.elementsOnPage)
  const isCurrentPageFirst = props.page === 1
  const isCurrentPageLast = props.page === nrPages

  let showEllipsis
  const pageNumbers = [ ...new Array(nrPages) ].map((_, index) => {
    const pageNumber = index + 1
    const isPageNumberFirst = pageNumber === 1
    const isPageNumberLast = pageNumber === nrPages
    const isCurrentPageVisible = Math.abs(pageNumber - props.page) <= 1
    if (isPageNumberFirst || isPageNumberLast || isCurrentPageVisible) {
      showEllipsis = false
      return (
        <Pagination.Item key={ pageNumber } active={ pageNumber === props.page } onClick={ () => changePage(pageNumber) }>
          { pageNumber }
        </Pagination.Item>
      )
    }
    if (!showEllipsis) {
      showEllipsis = true
      return (
        <Pagination.Ellipsis key={ pageNumber } />
      )
    }
    return null
  })
  return (
    <div className="pagination-container">
      <Pagination>
        <Pagination.Prev disabled={ isCurrentPageFirst} onClick={ () => changePage(props.page - 1) } />
        { pageNumbers }
        <Pagination.Next disabled={ isCurrentPageLast} onClick={ () => changePage(props.page + 1) } />
      </Pagination>
    </div>
  )
}

PaginationComponent.propTypes = {
  page: PropTypes.number,
  elementsCount: PropTypes.number,
  setCurrentPage: PropTypes.func,
  elementsOnPage: PropTypes.number,
}

PaginationComponent.defaultProps = {
  elementsOnPage: 10,
}

export default PaginationComponent
