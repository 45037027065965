import React from 'react'
import { selectAllCheckboxes, selectAllLeaguesAndEventsCheckboxes, 
  selectAllRoundsCheckboxes, clearSelectionCheckboxes } from '../actions'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'


const KeySettingsSelectActions = (props) => {  
  // Redux state
  const leaguesAndRoundsSelected = useSelector(state => state.table.data.leagues_and_rounds_selected)
  const tableData = useSelector(state => state.table.data.table)

  // Local state
  const dispatch = useDispatch()

  // Business logic
  const toggleExapndAllLeagues = () => {
    let allLeagueKeys
    if (!props.isCurrentPageSelected) {
      allLeagueKeys = Object.keys(leaguesAndRoundsSelected)
    } else {
      allLeagueKeys = Object.keys(tableData)
    }
    props.setExpandedLeagues((prevExpanded) => {
      if (prevExpanded.length === allLeagueKeys.length) {
        return []
      } else {
        return allLeagueKeys
      }
    })
  }

  const handleSelectAllClick = () => {
    toggleExapndAllLeagues()
    dispatch(selectAllCheckboxes(props.isCurrentPageSelected))
  }

  const handleSelectLeaguesAndEventsClick = () => {
    dispatch(selectAllLeaguesAndEventsCheckboxes(props.isCurrentPageSelected))
  }

  const handleSelectRoundsClick = () => {
    toggleExapndAllLeagues()
    dispatch(selectAllRoundsCheckboxes(props.isCurrentPageSelected))
  }

  const handleClearSelectionClick = () => {
    dispatch(clearSelectionCheckboxes(props.isCurrentPageSelected))
  }

  const handleSelectRecordsFrom = (value) => {
    const booleanValue = value === 'true'
    props.setIsCurrentPageSelected(booleanValue)
  }

  // Render View
  return (
    <div className="select-wrapper">
      <div className="select-records">
        <label>{!window.I18n ? '' : window.I18n.t('key_settings.select_actions.select_records_from')}</label>
        <label className="custom-radio">
          <div className={`iradio_square-orange icheck-item ${props.isCurrentPageSelected === false ? 'checked' : ''}`}>
            <input
              className='i-checks icheck-input'
              type='radio'
              value='false'
              name="select-current-or-all-pages"
              onChange={ event => handleSelectRecordsFrom(event.target.value) }
              checked={props.isCurrentPageSelected === false}
            />
          </div>
          {!window.I18n ? '' : window.I18n.t('key_settings.select_actions.all_pages')}
        </label>
        <label className="custom-radio">
          <div className={`iradio_square-orange icheck-item ${props.isCurrentPageSelected === true ? 'checked' : ''}`}>
            <input
              className='i-checks icheck-input'
              type='radio'
              value='true'
              name="select-current-or-all-pages"
              onChange={ event => handleSelectRecordsFrom(event.target.value) }
              checked={props.isCurrentPageSelected === true}
            />
          </div>
          {!window.I18n ? '' : window.I18n.t('key_settings.select_actions.current_page')}
        </label>
      </div>

      <div className="select-actions">
        <a onClick={() => toggleExapndAllLeagues()}>
          {(props.expandedLeagues.length === Object.keys(tableData).length) || (props.expandedLeagues.length === Object.keys(leaguesAndRoundsSelected).length) ? !window.I18n ? '' : window.I18n.t('key_settings.select_actions.collapse_all') : !window.I18n ? '' : window.I18n.t('key_settings.select_actions.expand_all')}
        </a>
        <span className="vertical-line"></span>
        <a onClick={() => handleSelectAllClick()}>{!window.I18n ? '' : window.I18n.t('key_settings.select_actions.select_all')}</a>
        <span className="vertical-line"></span>
        <a onClick={() => handleSelectLeaguesAndEventsClick()}>{!window.I18n ? '' : window.I18n.t('key_settings.select_actions.select_leagues_and_events')}</a>
        <span className="vertical-line"></span>
        <a onClick={() => handleSelectRoundsClick()}>{!window.I18n ? '' : window.I18n.t('key_settings.select_actions.select_rounds')}</a>
        <span className="vertical-line"></span>
        <a onClick={() => handleClearSelectionClick()}>{!window.I18n ? '' : window.I18n.t('key_settings.select_actions.clear_selection')}</a>
      </div> 
    </div>
  )
}

KeySettingsSelectActions.propTypes = {
  isCurrentPageSelected: PropTypes.bool.isRequired,
  setIsCurrentPageSelected: PropTypes.func.isRequired,
  expandedLeagues: PropTypes.array.isRequired,
  setExpandedLeagues: PropTypes.func.isRequired,
}

export default KeySettingsSelectActions
