import { connect } from 'react-redux'

import FoursomeData from '../components/foursome_data'

import { changeScore } from '../actions'

const mapStateToProps = (state, props) => {
  return {
    foursome: props.foursome,
    aggregates: [ ...props.foursome.aggregates ],
    scoreDisplay: props.scoreDisplay,
    isStableford: props.isStableford,
    isMultiRound: props.isMultiRound,
    holeView: props.holeView,
    isPortal: props.isPortal,
    isLast: props.isLast,
    newScores: state.scoringInterfaceData.newScores,
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeScore: (teeId, playerId, foursomeId, scores, newScoreValue, newScorePosition) => dispatch(changeScore(teeId, playerId, foursomeId, scores, newScoreValue, newScorePosition)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FoursomeData)
