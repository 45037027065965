import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import HelpLinksPopup from './help_links_popup'

const PopupsComponent = ({
  closePopup, helpLinks,
}) => <Fragment><HelpLinksPopup { ...helpLinks } onClose={ closePopup }></HelpLinksPopup></Fragment>

PopupsComponent.propTypes = {
  closePopup: PropTypes.func.isRequired,
  helpLinks: PropTypes.object.isRequired,
}

export default PopupsComponent
