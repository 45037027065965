import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class YouTubeVideo extends Component {
  constructor(props) {
    super(props)
    this._isMounted = false
    this.loadVideo = this.loadVideo.bind(this)
    this.onPlayerReady = this.onPlayerReady.bind(this)
    this.onPlayerStateChange = this.onPlayerStateChange.bind(this)
    this.jumpstartPlayer = this.jumpstartPlayer.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id ) {
      this.player.loadVideoById(this.props.id)
    }
  }

  componentDidMount () {
    // On mount, check to see if the API script is already loaded
    this._isMounted = true
    if (!window.YT) { // If not, load the script asynchronously
      const tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'

      // onYouTubeIframeAPIReady will load the video after the script is loaded
      window.onYouTubeIframeAPIReady = this.loadVideo

      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

    } else { // If script is already there, load the video directly
      this.loadVideo()
    }
  }

  loadVideo () {
    const { id } = this.props

    // the Player object is created uniquely based on the id in props
    this.player = new window.YT.Player(`youtube-player-${id}`, {
      videoId: id,
      playerVars: {
        'autoplay': 1,
      },
      events: {
        onReady: this.onPlayerReady,
        onStateChange: this.onPlayerStateChange,
        // eslint-disable-next-line no-console
        onError: (error) => console.log(error),
      },
      height: this.props.height || '100%',
      width: this.props.width || '100%',
    })
  }

  onPlayerStateChange (newState){
    if (newState && newState.data === 0) {
      if (this.props.onVideoEnd) {
        this.props.onVideoEnd()
      }
    }
  }

  onPlayerReady (event){
    if (this.props.autoPlay) {
      event.target.playVideo()
    }
    this.props.setPlayer(event.target)
    this.jumpstartPlayer(event.target)
  }

  async jumpstartPlayer(player) {
    for (let i = 0; i < 10; i++) {
      await new Promise(r => setTimeout(r, 2000))
      if (player.getPlayerState() === 1) {
        break
      }
      if (player.getPlayerState() === 2 || player.getPlayerState() === -1) {
        player.mute()
        player.playVideo()
        break
      }
    }
  }

  render () {
    const { id } = this.props
    return (
      <div id={`youtube-player-${id}`} height={this.props.height} width={this.props.width}/>
    )
  }
}

YouTubeVideo.propTypes = {
  id: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  onVideoEnd: PropTypes.func,
  height: PropTypes.number,
  width: PropTypes.number,
  setPlayer: PropTypes.func.isRequired,
}
