import _ from 'lodash'
import { showErrorNotification, showNotification, callAPI } from 'Shared/helpers'
import { getPlayersOnBoard } from 'Shared/course_boards'

export const ActionTypes = {
  CHANGE_COLUMN_SORTING: 'CHANGE_COLUMN_SORTING',
  ADD_LINE: 'ADD_LINE',
  REMOVE_LINE: 'REMOVE_LINE',
  REORDER_LINES: 'REORDER_LINES',
  SUBMIT_COURSE_LEADERBOARD: 'SUBMIT_COURSE_LEADERBOARD',
  HANDLE_SHOW_MESSAGE_CHANGED: 'HANDLE_SHOW_MESSAGE_CHANGED',
  HANDLE_MESSAGE_CHANGED: 'HANDLE_MESSAGE_CHANGED',
  HANDLE_SCORE_CHANGED: 'HANDLE_SCORE_CHANGED',
  REFRESH_DATA: 'REFRESH_DATA',
}

export const changeColumnSortingAction = (column, sortOrder) => ({
  type: ActionTypes.CHANGE_COLUMN_SORTING,
  column,
  sortOrder,
})

export const addLineAction = (memberIds, position, removeMemberIds) => ({
  type: ActionTypes.ADD_LINE,
  memberIds,
  position,
  removeMemberIds,
})

export const removeLineAction = (position) => ({
  type: ActionTypes.REMOVE_LINE,
  position,
})

export const reorderLinesAction = (fromPosition, toPosition, from, to, offset) => ({
  type: ActionTypes.REORDER_LINES,
  fromPosition,
  toPosition,
  from,
  to,
  offset,
})

export const submitCourseLeaderboardAction = (data) => ({
  type: ActionTypes.SUBMIT_COURSE_LEADERBOARD,
  data,
})

export const handleShowMessageChangedAction = (value) => ({
  type: ActionTypes.HANDLE_SHOW_MESSAGE_CHANGED,
  value,
})

export const handleMessageChangedAction = (value) => ({
  type: ActionTypes.HANDLE_MESSAGE_CHANGED,
  value,
})

export const handleScoreChangedAction = (value) => ({
  type: ActionTypes.HANDLE_SCORE_CHANGED,
  value,
})

export const refreshDataAction = (data) => ({
  type: ActionTypes.REFRESH_DATA,
  data,
})

export const changeColumnSorting = (column, sortOrder) => (dispatch) => {
  dispatch(changeColumnSortingAction(column, sortOrder))
}

export const addLine = (memberIds) => (dispatch, getState) => {
  const state = getState()
  const lines = getPlayersOnBoard(state)
  const emptyLine = lines.find( line => line.empty === true)
  let position
  let removeMemberIds
  if (emptyLine === undefined) {
    position = 7
    removeMemberIds = _.last(lines).memberIds
  } else {
    position = emptyLine.position
    removeMemberIds = null
  }
  dispatch(addLineAction(memberIds, position, removeMemberIds))
}

export const removeLine = (position) => (dispatch) => {
  dispatch(removeLineAction(position))
}

export const reorderLines = (fromPosition, toPosition) => (dispatch) => {
  let from, to, offset
  if (fromPosition < toPosition) {
    from = fromPosition + 1
    to = toPosition
    offset = -1
  } else {
    from = toPosition
    to = fromPosition - 1
    offset = 1
  }
  dispatch(reorderLinesAction(fromPosition, toPosition, from, to, offset))
}

export const submitCourseLeaderboard = () => (dispatch, getState) => {
  const state = getState()
  const lines = getPlayersOnBoard(state)
  const url = state.misc.submitUrl
  const params = {}
  const withTeams = state.misc.withTeams

  params['show_message'] = state.misc.showMessage
  params['message'] = state.misc.message
  params['score'] = state.misc.score

  lines.map( line => {
    params[`lines[${line.position}][member_ids]`] = line.memberIds || ''
    params[`lines[${line.position}][name]`] = line.empty ? '' : withTeams ? line.name : line.lastName + ', ' + line.firstName.charAt(0)
    params[`lines[${line.position}][today]`] = line.today || ''
    params[`lines[${line.position}][total]`] = line.total || ''
    params[`lines[${line.position}][hole]`] = line.hole || ''
    params[`lines[${line.position}][course_abbreviation]`] = line.course || ''
    params[`lines[${line.position}][show_course_abbreviation]`] = state.misc.showCourse
  })
  
  callAPI(url, 'PUT', params, new Headers({ 'Accept': 'application/json' }))
    .then( (response) => {
      dispatch(submitCourseLeaderboardAction(response))
      showNotification(!window.I18n ? '' : window.I18n.t('course_leaderboards.successfully_submitted'))
    })
    .catch(error => {
      if ( error !== undefined ) {
        showErrorNotification(error)
      } else {
        showErrorNotification(!window.I18n ? '' : window.I18n.t('course_leaderboards.error_submit'))
      }
    })
}

export const handleShowMessageChanged = (value) => (dispatch) => {
  dispatch(handleShowMessageChangedAction(value))
}

export const handleMessageChanged = (value) => (dispatch) => {
  dispatch(handleMessageChangedAction(value))
}

export const handleScoreChanged = (value) => (dispatch) => {
  dispatch(handleScoreChangedAction(value))
}

export const refreshData = () => (dispatch, getState) => {
  const state = getState()
  const url = state.misc.refreshUrl
  callAPI(url, 'GET', {})
    .then(response => {
      if (response.players === undefined || (response.players.length === 0 && state.misc.players.length > 0)) {
        retryRefresh(state, dispatch)
      } else {
        dispatch(refreshDataAction(response)) 
      }
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('course_leaderboards.error_refresh'))
    })
}

const retryRefresh = (state, dispatch) => {
  const url = state.misc.refreshUrl
  setTimeout( () => {
    callAPI(url, 'GET', {})
      .then(response => {
        if (response.players !== undefined && response.players.length > 0) {
          dispatch(refreshDataAction(response))
        }
      })
      .catch(() => {
        showErrorNotification(!window.I18n ? '' : window.I18n.t('course_leaderboards.error_refresh'))
      })
  }, 5000)
}
