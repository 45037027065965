import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const WithSpacing = styled.div`
  ${ props => '0123456789'.includes(props.spacing[0]) ? 'gap' : 'justify-content'}: ${props => props.spacing};
`

function LabeledInput(props) {
  const spacing = props.spacing || 'space-between'

  return (
    <WithSpacing spacing={spacing} key={ props.diffKey || props.label } className={ `labeled-input-container ${props.className || ''}` }>
      <label>{ props.label }</label>
      <div className={ `children ${props.childrenClass}` }> { props.children } </div>
    </WithSpacing>
  )
}

LabeledInput.propTypes = {
  diffKey: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  childrenClass: PropTypes.string,
  spacing: function(props, propName, componentName) {
    if (!/flex-start|center|flex-end|space-between|space-around|space-evenly|[0-9]+(px|r?em)/.test(props[propName])) {
      return new Error( 'Invalid prop `' + propName + '` supplied to' + ' `' + componentName + '`. Validation failed.')
    }
  },
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default LabeledInput

