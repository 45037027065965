import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PersonalDataComponent from './personal_data_component'
import MembershipProgramsComponent from './membership_programs_component'
import MembershipProgramsTableComponent from './membership_programs_table_component'

class EditMembershipComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let mpComp
    if (Object.keys(this.props.memberships).length > 1) {
      mpComp = <MembershipProgramsTableComponent memberships={ this.props.memberships } membershipPortalLink={ this.props.membershipPortalLink } dataIsLoading={ this.props.dataIsLoading } hasRenewals={ this.props.hasRenewals } target={ this.props.target }></MembershipProgramsTableComponent>
    } else {
      mpComp = <MembershipProgramsComponent memberships={ this.props.memberships } membershipPortalLink={ this.props.membershipPortalLink } dataIsLoading={ this.props.dataIsLoading } target={ this.props.target }></MembershipProgramsComponent>
    }
    
    return <div><PersonalDataComponent personalData={ this.props.personalData } editRegistrationLink={ this.props.editRegistrationLink } dataIsLoading={ this.props.dataIsLoading } target={ this.props.target } MRR={ this.props.MRR }></PersonalDataComponent>{ mpComp }</div>
  }
}

EditMembershipComponent.propTypes = {
  memberships: PropTypes.object.isRequired,
  personalData: PropTypes.array.isRequired,
  editRegistrationLink: PropTypes.string.isRequired,
  membershipPortalLink: PropTypes.string,
  dataIsLoading: PropTypes.bool,
  hasRenewals: PropTypes.bool,
  target: PropTypes.string,
  MRR: PropTypes.bool,
  cbEnabled: PropTypes.bool,
  cbBalance: PropTypes.number,
  currency: PropTypes.string,
}

export default EditMembershipComponent
