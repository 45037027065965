import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {Col, Row} from 'react-bootstrap'
import { 
  GlgLoadingSpinner,
} from 'SharedComponents'

import PaymentmethodNameComponent from './paymentmethod_name_component'

class PaymentMethodsComponent extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {       
    let paymentMethods = []
    if (!this.props.dataIsLoading) {
      paymentMethods = Object.values(this.props.payments)
    }
    
    const paymentmethodTitle = <Row className="title">{ !window.I18n ? '' : window.I18n.t('player_activity.registration.payment_methods.title') }</Row>
    
    return <div className="payment-methods">{ paymentmethodTitle }<Col xs={ 12 }><GlgLoadingSpinner show={ this.props.dataIsLoading }></GlgLoadingSpinner>{ paymentMethods.length === 0 && !this.props.dataIsLoading && <p className="no-info">{ !window.I18n ? '' : window.I18n.t('player_activity.registration.payment_methods.no_saved_methods') }</p>}{
            paymentMethods.map((e, index) => {
              const colSmSize = 12
              return <Row key={ index } className="no-lateral-margin"><PaymentmethodNameComponent deletePaymentMethod={ this.props.deletePaymentMethod } defaultPaymentMethod={ this.props.defaultPaymentMethod } typeName={ e.type } expirationName={ e.expiration } lastdigitName={ e.last_4_digit } usedDefault={ e.used_default } cardholder={ e.cardholder } colSmSize={ colSmSize } id={e.id}></PaymentmethodNameComponent></Row>
            })
          }</Col></div>
  }
}

PaymentMethodsComponent.propTypes = {
  payments: PropTypes.object,
  dataIsLoading: PropTypes.bool,
  target: PropTypes.string,
  deletePaymentMethod: PropTypes.func,
  defaultPaymentMethod: PropTypes.func,
}

export default PaymentMethodsComponent
