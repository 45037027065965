import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

class ReportCenterItemBlockPublished extends Component {
  stringParameterize(str) {
    return str.trim().toLowerCase()
      .replace(/[^a-zA-Z0-9 -]/g, '')
      .replace(/ +/g, ' ')
      .replace(/[\s-]/g, '_')
  }

  render() {
    return (
      <tr className="report-list-item report_center_item_block">
        <td className={ ( this.props.expanded ? 'col-sm-7' : '') + ' report_center_item_title text-left' } id={this.props.report_center_item.id}>
          {
            this.props.job.error && <i className="fa fa-exclamation error"></i>
          }
          {
            this.props.report_center_item.action !== null ?
              <a onClick={ [ 'Gpu::Report', 'Pairing' ].indexOf(this.props.report_center_item.report_type) !== -1 ?
                  //if GPU::Report - tell modal to load print_options form from edit_conflicts_path and submit throught printReport or cancel throught setJobId of this very rci
                  //otherwise just printReport right away
                  () => { this.props.openPrintModal({ edit_conflicts_path: this.props.report_center_item.action[1], rci_base_printReport: this.props.printReport, rci_base_setJobId: this.props.setJobId}) }
                : () => { this.props.printReport() } }>
                  { this.props.report_center_item.name.toUpperCase() }
              </a>
            :
              <a target="_blank" rel='noopener noreferrer'
                 href={this.props.report_center_item.edit_path[1]}>
                { this.props.report_center_item.name.toUpperCase() }
              </a>
        }
        </td>
        { 
          !this.props.expanded &&
            <td className="report_center_item_title text-left">
              <div style={ { backgroundImage: 'url(' + (get(this.props.customers_info[this.props.report_center_item.customer_id], 'customer_logo') || require('usga/home/logos/default_logo_large.png')) + ')' } } className='customer-icon pull-left'>
              </div>
            </td>
        }
        {
          !this.props.expanded &&
            <td className="report_center_item_title text-left">
              <div className="customer-name">
                <a href="#" onClick={ () => { this.props.setPreview(this.props.report_center_item) } }> { get(this.props.customers_info[this.props.report_center_item.customer_id], 'customer_name') || '-' } </a>
              </div>  
            </td>
        }
        <td className="text-center stats">
          <span className="text-center add-margin-right-10">
            <i className="fa fa-list fa-fw text-center"></i>
              { this.props.report_center_item.list_count }
          </span>
          <span className="text-center add-margin-left-10">
            <i className="fa fa-print fa-fw text-center"></i>
              { this.props.report_center_item.print_count }
          </span>
        </td>
        <td className="text-center">
          { /* buttons saga */ }
          <span className="pull-right">
            <i className="fa fa-print fa-fw"></i>
            {
              this.props.job.id !== null || this.props.job.show_spiner ?
                <span>
                  <a className="action">{this.props.report_center_item.action[0] + 'ing...'}</a>&nbsp;•&nbsp;
                </span>
              :
                this.props.job.id === null && this.props.job.completed_links === null ?
                  <span>
                    <a onClick={ [ 'Gpu::Report', 'Pairing', 'Gpu::Scorecard' ].indexOf(this.props.report_center_item.report_type) !== -1 ?
                        //if GPU::Report - tell modal to load print_options form from edit_conflicts_path and submit throught printReport or cancel throught setJobId of this very rci
                        //otherwise just printReport right away
                        () => { this.props.openPrintModal({ edit_conflicts_path: this.props.report_center_item.action[1], rci_base_printReport: this.props.printReport, rci_base_setJobId: this.props.setJobId}) }
                      : () => { this.props.printReport() } }>
                        {this.props.report_center_item.action[0]}
                    </a>
                  </span>
                :
                  this.props.job.id === null && this.props.job.completed_links !== null &&
                    <span>
                      { 
                        this.props.job.completed_links['pdf_path'] &&
                          <span>
                            <a target='_blank' rel='noopener noreferrer'
                               href={this.props.job.completed_links['pdf_path']}>
                              { this.props.job.completed_links.button_label }
                            </a>
                          </span>
                      }
                    </span>
            }
          </span>
        </td>
        <td className="text-center list">
          <div className="pull-left">
            <button className="btn btn-default hover-dropdown-button" >
              <i className="fa fa-list fa-fw"></i>
              {!window.I18n ? '' : window.I18n.t('download_center.categories')}&nbsp;
              <span className="caret"></span>
            </button>
            <ul className="hover-dropdown">
              {
                this.props.report_center_lists.filter(rcl => !rcl.read_only).map(function (rcl) {
                  return (
                    <li key={rcl.id}>
                      <div className="checkbox btsp_checkbox checkbox-primary no-margin">
                        <input id={ 'dd_inline_' + this.props.report_center_item.id + '_' + rcl.id }
                               type='checkbox'
                               name='report_center_item[report_center_list_ids][]'
                               onChange={(e) => {
                                  this.props.addRemoveItemToList(this.props.report_center_item.id, rcl.id, e)
                               }}
                               defaultChecked={rcl.report_center_items.map(rci => rci.id).indexOf(this.props.report_center_item.id) !== -1 ? true : false } />
                        <label htmlFor={ 'dd_inline_' + this.props.report_center_item.id + '_' + rcl.id }>
                          {!window.I18n ? '' : window.I18n.t(`download_center.report_center_list.names.${this.stringParameterize(rcl.name)}`, {defaultValue: rcl.name})}
                        </label>
                      </div>
                    </li>)
                  }, this)
              }
            </ul>
          </div>
        </td>
      </tr>
    )
  }
}

ReportCenterItemBlockPublished.propTypes = {
  expanded: PropTypes.bool.isRequired,
  report_center_lists: PropTypes.array.isRequired,
  report_center_item: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
  customers_info: PropTypes.object.isRequired,
  addRemoveItemToList: PropTypes.func.isRequired,
  setPreview: PropTypes.func.isRequired,
  setJobId: PropTypes.func.isRequired,
  printReport: PropTypes.func.isRequired,
  openPrintModal: PropTypes.func.isRequired,
}

export default ReportCenterItemBlockPublished
