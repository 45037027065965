import { ActionTypes } from '../actions'

const {
  CREATE_COLUMN,
  UPDATE_COLUMN,
  SWAP_COLUMNS,
  RESIZE_COLUMN,
  REMOVE_COLUMN,
  COLUMN_DISPLAY,
  COLUMN_CHANGE_POSITION,
} = ActionTypes

const columns = (state = [], action) => {
  switch (action.type) {
    case CREATE_COLUMN: {
      return [
        ...state,
        {
          id: action.id,
          position: action.position,
          height: 262,
          widget_id: action.widget_id,
          blockId: action.blockId,
          index: action.index,
          title: action.title,
          titles: action.titles,
          view_more: action.viewMore,
          display: true,
        },
      ]
    }
    case UPDATE_COLUMN: {
      return state.map(column =>
        (column.id === action.id)
          ? { ...column, title: action.title, titles: action.titles, view_more: action.viewMore }
          : column)
    }
    case SWAP_COLUMNS: {
      if (action.colID1 === null) {
        return state
      }
      // position
      const column1 = state.filter(column => column.id === action.colID1)[0]
      // The element that is being moved
      const column2 = state.filter(column => column.id === action.colID2)[0]

      const orderedArray = state.sort( (a, b) => a.index < b.index ? -1 : 1 )
        .filter(column => column.blockId === column2.blockId ? column.index : null)

      const indexArray = state.filter(column => column.blockId === column2.blockId ? column.index : null)
        .map( column => column.id === action.colID2
          ? (action.position === 'beforebegin'
            ? { ...column, index: column1.index - 0.5, position: column1.position }
            : { ...column, index: column1.index + 0.5, position: column1.position })
          : column)
        .sort( (a, b) => a.index < b.index ? -1 : 1 )

      const finalArray = indexArray.map( (column, i) => ({ ...column, index: orderedArray[i].index }) )

      return [
        ...state.filter(column => column.blockId !== column2.blockId),
        ...finalArray,
      ]
    }
    case RESIZE_COLUMN: {
      return state.map(column =>
        (column.id === action.id)
          ? {...column, height: action.height}
          : column)
    }
    case REMOVE_COLUMN: {
      return state.filter(column => column.id !== action.id)
    }
    case COLUMN_DISPLAY: {
      return state.map(column =>
        (column.id === action.id)
          ? {...column, display: action.display}
          : column)
    }
    case COLUMN_CHANGE_POSITION: {
      return state.map(column =>
        (column.id === action.id)
          ? {...column, position: action.position}
          : column)
    }
    default:
      return state
  }
}

export default columns
