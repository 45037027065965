import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import TetherComponent from 'react-tether'
import onClickOutside from 'react-onclickoutside'

import GlgIcon from './glg_icon'

class GlgCustomDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.handleClickOutsideOfMenu = this.handleClickOutsideOfMenu.bind(this)
    this.onChange = props.onChange.bind(this)
  }

  // This component can be controlled (by passing the isOpen prop)
  static getDerivedStateFromProps(nextProps) {
    if (!_.isUndefined(nextProps.isOpen)) {
      return {
        isOpen: nextProps.isOpen,
      }
    } else {
      return null
    }
  }

  setIsOpen(value) {
    this.setState({
      isOpen: value,
    }, () => {
      if (this.props.onStateChange) {
        this.props.onStateChange(value)
      }
    })
  }

  handleButtonClick() {
    if (this.props.setIsOpen){
      this.props.setIsOpen(!this.state.isOpen)
    } else {
      this.setIsOpen(!this.state.isOpen)
    }
  }

  handleClickOutsideOfMenu(e) {
    if (e.target.parentElement !== this.buttonRef.children[0] && e.target !== this.buttonRef.children[0] && !e.target.parentElement.closest(this.props.buttonContainerId)) {
      if (this.props.setIsOpen) {
        this.props.setIsOpen(false)
      } else {
        this.setIsOpen(false)
      }
    }
  }

  render() {
    const otherDirection = this.props.direction === 'left' ? 'right' : 'left'
    const attachment = `top ${ otherDirection }`
    const targetAttachment = `bottom ${ otherDirection }`

    const CustomButton = this.props.button
    const WrappedDropdownPanel = onClickOutside(this.props.dropdownPanel)

    return <div className="glg-react-custom-dropdown"><TetherComponent attachment={ attachment } targetAttachment={ targetAttachment } offset={ `${ -this.props.offsetY }px 0` } constraints={ [ { to: 'window', pin: true } ] }><div className="dropdown content-dropdown"><a className="dropdown-button dropdown-toggle dropdown_button_icon clickable" onClick={ this.handleButtonClick } ref={ buttonRef => { this.buttonRef = buttonRef } }>{CustomButton
                ? CustomButton
                : <GlgIcon icon="carret-down"></GlgIcon>
              }</a></div>{this.state.isOpen && <WrappedDropdownPanel onChange={this.onChange} options={this.props.optionsForPanel} handleClickOutside={ this.handleClickOutsideOfMenu } {...this.props}></WrappedDropdownPanel>}</TetherComponent></div>
  }
}

GlgCustomDropdown.propTypes = {
  // The direction in which the dropdown opens: left or right
  direction: PropTypes.string,
  // The anchor that toggles the dropdown panel
  button: PropTypes.node,
  // The component that represents the dropdown panel
  dropdownPanel: PropTypes.func.isRequired,
  // The offset for then dropdown panel relative to the anchor
  offsetY: PropTypes.number,
  // (Optional) Whether the dropdown panel is opened or not.
  // Pass only if you want a controlled component
  isOpen: PropTypes.bool,
  // Callback for when the dropdown panel's visibility has changed
  onStateChange: PropTypes.func,
  // options for panel checkboxes
  optionsForPanel: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  // onChange event for panel checkboxes,
  onChange: PropTypes.func,
  // DOM id of the button container (e.g. '#event_status')
  buttonContainerId: PropTypes.string,
  // method for outside handling of the dropdown state
  setIsOpen: PropTypes.func,
}

GlgCustomDropdown.defaultProps = {
  direction: 'right',
  offsetY: 4,
}

export default GlgCustomDropdown
