import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import FaIcon from 'react-fa'

import { getColumnDataKey, isExtraColumn, getColumnName } from '../column_helpers'
import {
  GlgTable,
  GlgTableCol,
} from 'SharedComponents'
import LeagueRowComponent from './league_row_component'

class LeaguesTableComponent extends PureComponent {
  getRowStyle() {
    return {
      borderLeft: `3px solid ${ this.props.categoryColor }`,
    }
  }

  getEmptyMessage() {
    if (this.props.categoryId) {
      return 'There are no events in this category.'
    } else {
      return 'There are no events.'
    }
  }

  render() {
    const sortingColumn = this.props.sortingColumn
    const rowExtraProps = {
      ...this.props.leagueActions,
      sortingColumn: getColumnDataKey(sortingColumn),
      sortingOrder: this.props.sortingOrder,
      includeCategoryColumn: this.props.includeCategoryColumn,
      userIsCustomerManager: this.props.userIsCustomerManager,
      userIsTourManager: this.props.userIsTourManager,
      userIsVisageAdmin: this.props.userIsVisageAdmin,
      showTours: this.props.showTours,
      toursEnabled: this.props.toursEnabled,
      showSeasonsColumn: this.props.showSeasonsColumn,
      seasonsEnabled: this.props.seasonsEnabled,
      showRegistration: this.props.showRegistration,
      golfHubEnabled: this.props.golfHubEnabled,
      isTmLite: this.props.isTmLite,
    }
    const userIsManager = this.props.userIsCustomerManager || this.props.userIsTourManager
    return <GlgTable items={ this.props.leagues } rowComponent={ LeagueRowComponent } isSortable={ this.props.isSortable } onSortEnd={ this.props.reorderLeagues } maxHeight={ this.props.maxHeight } rowStyle={ this.getRowStyle() } rowExtraProps={ rowExtraProps } nestedId={ this.props.categoryId } emptyMessage={ this.getEmptyMessage() }>{this.props.isSortable && <GlgTableCol width="3%"></GlgTableCol>}<GlgTableCol width="25%" dataKey="name"></GlgTableCol>{this.props.golfHubEnabled && <GlgTableCol width="9%" dataKey="golfHub">Golf Hub Page</GlgTableCol>}{userIsManager && this.props.includeCategoryColumn && <GlgTableCol width="15%" dataKey="categoryName">Category</GlgTableCol>}{userIsManager && this.props.showSeasonsColumn && <GlgTableCol width="10%" dataKey="seasonName">Season</GlgTableCol>}{userIsManager && this.props.showTours && <GlgTableCol width="10%" dataKey="tourName">Tour</GlgTableCol>}<GlgTableCol width="9%" dataKey="startDate"><FaIcon name="calendar"></FaIcon><span className="add-margin-left-5">Start Date</span></GlgTableCol><GlgTableCol width="15%" dataKey="golfers"><FaIcon name="users"></FaIcon><span className="add-margin-left-5">Golfers</span></GlgTableCol>{this.props.showRegistration && <GlgTableCol width="10%" dataKey="registrationMessage">Registration</GlgTableCol>}{isExtraColumn(sortingColumn) && <GlgTableCol width="10%" dataKey={ sortingColumn } cellProps={ { className: 'extra-column' } }>{ getColumnName(sortingColumn) }</GlgTableCol>}<GlgTableCol width="6%">Actions</GlgTableCol></GlgTable>
  }
}

LeaguesTableComponent.propTypes = {
  leagues: PropTypes.array.isRequired,
  isSortable: PropTypes.bool.isRequired,
  categoryId: PropTypes.string,
  categoryColor: PropTypes.string,
  sortingColumn: PropTypes.string,
  sortingOrder: PropTypes.string,
  // Used for List View
  reorderLeagues: PropTypes.func.isRequired,
  leagueActions: PropTypes.object.isRequired,
  maxHeight: PropTypes.number,
  includeCategoryColumn: PropTypes.bool,
  userIsCustomerManager: PropTypes.bool.isRequired,
  userIsTourManager: PropTypes.bool.isRequired,
  userIsVisageAdmin: PropTypes.bool.isRequired,
  showTours: PropTypes.bool.isRequired,
  toursEnabled: PropTypes.bool.isRequired,
  showSeasonsColumn: PropTypes.bool.isRequired,
  seasonsEnabled: PropTypes.bool.isRequired,
  showRegistration: PropTypes.bool.isRequired,
  golfHubEnabled: PropTypes.bool,
  isTmLite: PropTypes.bool,
}

export default LeaguesTableComponent
