import React, { Component } from 'react'
import { Col } from 'react-flexbox-grid'
import PropTypes from 'prop-types'
import { GlgTextInput } from 'SharedComponents'
import Loader from 'react-loader-spinner'
import MembersFilterContainer from '../containers/members_filter/members_filter_container'

export class MembersSearchBarComponent extends Component {
  constructor(props) {
    super(props)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.onEnterPressed = this.onEnterPressed.bind(this)
    this.state = {
      value: '',
    }
  }
  
  componentDidMount(){
  }
  
  onFilterChange(value){
    this.setState({value})
    if (this.searchMembersTimer) {
      clearTimeout(this.searchMembersTimer)
    }
    this.searchMembersTimer = setTimeout(() => {
      this.props.fetchInitialMembersData({ filter_letters: this.state.value.toLowerCase().trim() })
    }, 600)
  }
  
  onEnterPressed(){
    this.props.fetchInitialMembersData({ filter_letters: this.state.value.toLowerCase().trim() })
  }
  
  render(){
    //TODO: search bar show_member_options ? "col-xs-10 col-sm-9" : "col-xs-9 col-sm-8"
    return <div><Col xs={3} sm={3} md={2} className={'number_of_golfers no_padding_left no_padding_right'}><Col xs={12} sm={12} md={12} lg={9} className="btsp_square_dotted no_padding_left no_padding_right"><div className="pull-left icon_of_g add-padding-left-5 hidden-xs"><i className="gga-event-roster-stats"></i></div><div className="pull-left number_of_g add-padding-left-5 g_number_container hidden-xs"><span className="glg-loading-spinner">{this.props.isMembersCountLoading ? <Loader type="ThreeDots" color={ '#0088CE' } height={ 15 } width={ 30 }></Loader> : this.props.membersCount}</span><span>{ this.props.membersCount !== 1 ? ` ${!window.I18n ? '' : window.I18n.t('members_page.components.members_search_bar_component.golfers')}` : ` ${!window.I18n ? '' : window.I18n.t('members_page.components.members_search_bar_component.golfer')}` }</span></div><div className="pull-left number_of_g_xs add-padding-left-10 g_number_container_xs hidden-sm hidden-md hidden-lg">{this.props.membersCount}</div></Col></Col>{ this.props.showMemberOptions && <Col xs={9} sm={9} md={5} lg={5} className="member_options add-margin-top-5"><MembersFilterContainer></MembersFilterContainer></Col>}<Col md={5} sm={this.props.showMemberOptions ? 12 : 8} xs={this.props.showMemberOptions ? 12 : 8} mdOffset={this.props.showMemberOptions ? 0 : 5} lgOffset={this.props.showMemberOptions ? 0 : 5} xsOffset={this.props.showMemberOptions ? 0 : 1} smOffset={this.props.showMemberOptions ? 0 : 1} className="no_padding_left margin_top_7"><Col xs={12} className="container_search_bar no_padding"><div className="filter btsp_filter"><GlgTextInput value={this.state.value} onChange={this.onFilterChange} placeholder={ !window.I18n ? '' : window.I18n.t('members_page.components.members_search_bar_component.searching') } onKeyPress={ this.onEnterPressed } withAddon={true}></GlgTextInput></div></Col></Col></div>
  }
}

MembersSearchBarComponent.propTypes = {
  membersCount: PropTypes.number.isRequired,
  showMemberOptions: PropTypes.bool.isRequired,
  fetchInitialMembersData: PropTypes.func.isRequired,
  searchParams: PropTypes.object.isRequired,
  filterOptions: PropTypes.object.isRequired,
  fetchFilterOptions: PropTypes.func.isRequired,
  isMembersDataLoading: PropTypes.bool.isRequired,
  isMembersCountLoading: PropTypes.bool.isRequired,
}

export default MembersSearchBarComponent
