import { connect } from 'react-redux'
import PgaRankingsComponent from '../components/pga_rankings_component'

const mapStateToProps = (state) => {
  return {
    leagueId: state.misc.leagueId,
    fetchInitialDataUrl: state.misc.fetchInitialDataUrl,
    statistics: state.statistics,
    showPopup: state.popup.visible,
    hasShotTrackings: state.misc.hasShotTrackings,
  }
}

export default connect(
  mapStateToProps,
  null
)(PgaRankingsComponent)

