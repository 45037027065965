//* REDUX - Container

import { connect } from 'react-redux'
import Layout from '../components/layout'
import { refreshCurrentWidgets, getNewItemsLinks } from '../actions/index'

const mapStateToProps = (state) => {
  return {
    widgets: state.widgets[state.menu.selectedMenuItem],
    getLinksPath: state.menu.menuItems.newItems.getLinksPath,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  refreshCurrentWidgets: (widgets, getLinksPath) => {
    dispatch(refreshCurrentWidgets(widgets, ownProps.developmentEnvironment))
    dispatch(getNewItemsLinks(getLinksPath, true))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Layout)
