import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import MenuItem from './menu_item'

class MenuEventSetupPlus extends Component {
  constructor(props){
    super(props)

    this.state = { isOpen: true }
  }

  render() {
    return (
      <Fragment>
        <MenuItem
          icon={ this.props.icon }
          label={ this.props.label }
          active={ false }
          selectMenuItem={ () => this.setState((prevState) => ({ isOpen: !prevState.isOpen })) }/>

        <div className='dashboard-menu-event-setup-plus-container' style={this.state.isOpen ? {} : { maxHeight: 0}}>
          {
            this.props.sections.map( (menuItem, index ) =>
              <Fragment key={ menuItem.id }>
                <div className={ `dashboard-menu-event-setup-plus-item-marker ${this.props.selectedMenuItem === menuItem.id ? 'active' : ''}` }>
                  { this.props.sections.length > 1 ? (this.props.selectedMenuItem === menuItem.id ? '◉' : '●') : null}
                </div>
                { index !== 0 ? <div className='dashboard-menu-event-setup-plus-item-line'></div> : null }
                <MenuItem url={ menuItem.url }
                  label={ menuItem.label }
                  active={ this.props.selectedMenuItem === menuItem.id }
                  selectMenuItem={ () => this.props.selectMenuItem(menuItem.id, menuItem.widgetPath, menuItem.lastViewedPagePath) }
                  type='dashboard-menu-event-setup-plus-items' />
              </Fragment>)
          }
        </div>
      </Fragment>
    )
  }
}

MenuEventSetupPlus.propTypes = {
  sections: PropTypes.array,
  icon: PropTypes.string,
  label: PropTypes.string,
  selectedMenuItem: PropTypes.string,
  selectMenuItem: PropTypes.func,
}

export default MenuEventSetupPlus
