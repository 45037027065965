import React, {Component} from 'react'
import { GlgBasicSelect } from 'SharedComponents'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'

export class CountrySelectComponent extends Component {
  constructor(props){
    super(props)
  }
  
  render(){
    return <Col xs={12} md={8}><GlgBasicSelect items={this.props.sortedCountries.map( x => ({label: x[0], value: x[1]}))} value={this.props.answer ? this.props.answer : ''} onChange={(val) => this.props.onChange(this.props.field.id, this.props.field.category, this.props.field.name, val)} selectClass="form-control" prompt={ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.custom_fields_components.country_select_component.select_country') }></GlgBasicSelect></Col>
  }
}

CountrySelectComponent.propTypes = {
  field: PropTypes.object.isRequired,
  answer: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  sortedCountries: PropTypes.array.isRequired,
}

export default CountrySelectComponent
