import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import { GlgList } from 'SharedComponents'
import EventRegularItemComponent from './event_regular_item_component'
import EventCondensedItemComponent from './event_condensed_item_component'

class EventsListComponent extends PureComponent {

  componentDidUpdate(prevProps) {
    if (prevProps.dataIsLoading && !this.props.dataIsLoading) {
      // Prevent scrolling if you are in a Multi Widget page
      let fromMultiWidgets = false
      // Prevent code from running if on manager side
      let onSharePage = false
      // Check if the element is in an iframe and if the iframe's id contains `widget`
      if (window.frameElement) {
        if (window.parent.document.getElementById('widget_code')) {
          onSharePage = true
        }
        if (window.frameElement.id.search('widget') === 0) {
          fromMultiWidgets = true
        }
      }
      if (!fromMultiWidgets && !onSharePage) {
        setTimeout(() => {
          try {
            window.parent.scroll(0, this.props.scrollPosition)
          } catch (err) {
            // 1 == 1
          }
        }, 0)
      }
    }
  }

  render() {
    const itemExtraProps = {
      addToCart: this.props.addToCart,
      idsForLabels: this.props.idsForLabels,
      cartMerchantAccountId: this.props.cartMerchantAccountId,
      cartMerchantAccountType: this.props.cartMerchantAccountType,
      statusFilter: this.props.statusFilter,
      showCourseInfo: this.props.showCourseInfo,
      directoryHideGolfers: this.props.directoryHideGolfers,
      shouldSort: this.props.shouldSort,
      shouldHideRegistration: this.props.shouldHideRegistration,
    }

    let eventListItemComponent = this.props.viewType === 'regular' 
      ? EventRegularItemComponent 
      : EventCondensedItemComponent

    let width = window.innerWidth
    try {
      width = window.parent.innerWidth
    } catch (err) {
      // 1 == 1
    }
    if (window.glg.new_design_shared.isMobile() || width < 992) {
      eventListItemComponent = EventCondensedItemComponent
    }
    return <Fragment>{!this.props.dataIsLoading && this.props.leagues.length === 0 && <p className="text-center">{ !window.I18n ? '' : window.I18n.t('customer_directories.no_events') }</p>}{this.props.leagues.length > 0 && <div className="add-margin-top-10"><GlgList items={ this.props.leagues } itemComponent={ eventListItemComponent } isSortable={ false } itemExtraProps={itemExtraProps}></GlgList></div>}</Fragment>      
  }
}

EventsListComponent.propTypes = {
  viewType: PropTypes.string,
  leagues: PropTypes.array,
  addToCart: PropTypes.func,
  idsForLabels: PropTypes.object,
  cartMerchantAccountId: PropTypes.string,
  cartMerchantAccountType: PropTypes.string,
  statusFilter: PropTypes.array,
  dataIsLoading: PropTypes.bool,
  scrollPosition: PropTypes.number,
  showCourseInfo: PropTypes.bool,
  directoryHideGolfers: PropTypes.bool,
  shouldSort: PropTypes.bool,
  shouldHideRegistration: PropTypes.bool,
}

export default EventsListComponent
