export const ActionTypes = {
  SET_GENERAL_UNSAVED_CHANGES: 'SET_GENERAL_UNSAVED_CHANGES',
  SET_GENERAL_POPUP_PROPERTY: 'SET_GENERAL_POPUP_PROPERTY',
  PLAINLY_SET_GENERAL_PROPERTY: 'PLAINLY_SET_GENERAL_PROPERTY',
}

export const setUnsavedChanges = (value) => {
  return {
    type: ActionTypes.SET_GENERAL_UNSAVED_CHANGES,
    value,
  }
}

export const setGeneralPopupProperty = (popupType, property, propertyValue) => {
  return {
    type: ActionTypes.SET_GENERAL_POPUP_PROPERTY,
    popupType,
    property,
    propertyValue,
  }
}

export const plainlySetGeneralProperty = (property, value) => {
  return {
    type: ActionTypes.PLAINLY_SET_GENERAL_PROPERTY,
    property,
    value,
  }
}
