import React from 'react'
import PropTypes from 'prop-types'
import rootReducer from '../reducers'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import ShotByShotViewContainer from './shot_by_shot_view_container'

const ShotByShotViewRoot = ({leagueId, roundId, refreshDataUrl, randomShotsUrl, recomputeStatsUrl, deleteShotsUrl, tees, foursomes, isMultiRound, holeView, isAdmin, isCustomerManager, hasVerifiedScores}) => {
  const state = {
    misc: {
      leagueId,
      roundId,
      refreshDataUrl,
      randomShotsUrl,
      recomputeStatsUrl,
      deleteShotsUrl,
      tees,
      foursomes,
      isMultiRound,
      holeView,
      isAdmin,
      isCustomerManager,
      hasVerifiedScores,
    },
  }

  const store = configureStore(rootReducer, state)

  return (
    <GlobalProvider store= { store }>
      <ShotByShotViewContainer />
    </GlobalProvider>
  )
}

ShotByShotViewRoot.propTypes = {
  leagueId: PropTypes.string,
  roundId: PropTypes.string,
  refreshDataUrl: PropTypes.string,
  randomShotsUrl: PropTypes.string,
  recomputeStatsUrl: PropTypes.string,
  deleteShotsUrl: PropTypes.string,
  tees: PropTypes.object,
  foursomes: PropTypes.array,
  isMultiRound: PropTypes.bool,
  holeView: PropTypes.string,
  isAdmin: PropTypes.bool,
  isCustomerManager: PropTypes.bool,
  hasVerifiedScores: PropTypes.bool,
}

export default ShotByShotViewRoot
