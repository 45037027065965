import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'
import _ from 'lodash'

import { GlgTable, GlgTableCol } from 'SharedComponents'
import CourseBoardRowComponent from './course_board_row_component'
import { getTotalColumnClass, getAbsColumnValue } from 'Shared/course_boards'
import { showErrorNotification, callAPI } from 'Shared/helpers'

class PreviewComponent extends Component {
  constructor(props) {
    super(props)
    this.renderFooter = this.renderFooter.bind(this)
    this.refresh = this.refresh.bind(this)
    this.updateBoard = this.updateBoard.bind(this)
  }

  componentDidMount() {
    this.timer = setInterval(this.refresh, 10000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  renderFooter() {
    const { message, score, showCourse } = this.props
    const color = getTotalColumnClass(score)
    const absValue = getAbsColumnValue(score)
    return (
      <tr className={'footer-row ' + color}>
        <td className='glg-table-header' colSpan={showCourse ? 4 : 3}><div>{message}</div></td>
        <td className='glg-table-header' colSpan={1}><div>{absValue}</div></td>
      </tr>
    )
  }

  refresh() {
    const url = '/boards/' + this.props.roundGgid + '?rows=' + this.props.initialNoRows + '&message=' + this.props.initialShowMessage
    callAPI(url, 'GET', {})
      .then(response => {
        this.updateBoard(response)
      })
      .catch(() => {
        showErrorNotification(!window.I18n ? '' : window.I18n.t('course_leaderboards.error_refresh'))
      })
  }

  updateBoard(message){
    const oldProps = _.pick(this.props, [ 'roundGgid', 'lines', 'showMessage', 'message', 'score', 'boardAvailable', 'showCourse', 'lastUpdate' ])

    if (!_.isEqual(oldProps, message)) {
      this.props.refreshData(message)
    }
  }

  render() {
    const { lines, showMessage, boardAvailable, showCourse, lastUpdate } = this.props
    const rowExtraProps = {
      showCourse: showCourse,
    }
    const footer = this.renderFooter()

    return (
      <Fragment>
        <Col xs={12} sm={12} md={12} lg={12} className='no-padding'>
          { boardAvailable &&
            <Col xs={12} sm={12} md={12} lg={12} className='text-center bold'>
              { window.I18n.t('course_leaderboards.last_update_on') }{lastUpdate}
            </Col>
          }
          
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className='course_board'>
              <div className='course_board_container'>
                { boardAvailable ?
                  <GlgTable items={lines}
                            rowComponent={ CourseBoardRowComponent }
                            rowExtraProps={ rowExtraProps }
                            showFooter={showMessage}
                            footerComponent={ footer }>
                    <GlgTableCol width='5%'>{ window.I18n.t('course_leaderboards.today') }</GlgTableCol>
                    <GlgTableCol width='65%'>{ window.I18n.t('course_leaderboards.leaders') }</GlgTableCol>
                    <GlgTableCol width='10%'>{ window.I18n.t('course_leaderboards.hole') }</GlgTableCol>
                    { showCourse && <GlgTableCol width='15%'>{ window.I18n.t('course_leaderboards.course') }</GlgTableCol> }
                    <GlgTableCol width='5%'>{ window.I18n.t('course_leaderboards.total') }</GlgTableCol>
                  </GlgTable>
                  : <div>{ window.I18n.t('course_leaderboards.no_board_available') }</div>
                } 
              </div>
            </div>
          </Col>
        </Col>
      </Fragment>
    )

  }
}

PreviewComponent.propTypes = {
  roundGgid: PropTypes.string,
  lines: PropTypes.array,
  showMessage: PropTypes.bool,
  message: PropTypes.string,
  score: PropTypes.string,
  boardAvailable: PropTypes.bool,
  refreshData: PropTypes.func,
  showCourse: PropTypes.bool,
  lastUpdate: PropTypes.string,
  initialShowMessage: PropTypes.string,
  initialNoRows: PropTypes.number,
}

export default PreviewComponent
