import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import FaIcon from 'react-fa'

class GlgButton extends PureComponent {
  constructor(props) {
    super(props)
  }

  getButtonClass() {
    const size = this.props.size !== 'normal' ? `${ this.props.size }_` : ''
    const smallBtnClass = this.props.size ? 'btn-xs' : ''
    return `btn ${ smallBtnClass } btsp_${ size }${ this.props.color }_button`
  }

  render() {
    return <Button onClick={ this.props.onClick } href={ this.props.href } className={`${ this.getButtonClass() } ${ this.props.className }`} disabled={ this.props.disabled } bsClass="glg-btn">{ this.props.icon && <FaIcon name={ this.props.icon } className="add-margin-right-5"></FaIcon> }{ this.props.text }</Button>
  }
}

GlgButton.propTypes = {
  // The button text
  text: PropTypes.string.isRequired,
  // If present, respective icon will be prepended to the text
  icon: PropTypes.string,
  // Callback when button is clicked
  onClick: PropTypes.func,
  // Link to redirect to when button is clicked
  href: PropTypes.string,
  // Classes to append to the default ones
  className: PropTypes.string,
  // The color of the button
  color: PropTypes.oneOf([ 'orange', 'gray', 'white' ]),
  // The size of the button
  size: PropTypes.oneOf([ 'normal', 'small' ]),
  // If true, the button is disabled
  disabled: PropTypes.bool,
}

GlgButton.defaultProps = {
  className: '',
  color: 'orange',
  size: 'normal',
  disabled: false,
}

export default GlgButton
