import { ActionTypes } from '../actions/tvShowsIndex'

function tvShowIndex( state = {}, action ) {
  switch (action.type) {
    case ActionTypes.REMOVE_TV_SHOW:
      return {
        ...state,
        tvShows: state.tvShows.filter( (tvShow) => {
          return tvShow.id !== action.tvShowId
        } ),
      }
    case ActionTypes.TOGGLE_AUTO_INCLUDE:
      return {
        ...state,
        tvShows: state.tvShows.map( (tvShow) => {
          return tvShow.id === action.tvShowId ? {...tvShow, auto_include: !tvShow.auto_include} : tvShow
        }),
      }
    default:
      return state
  }
}

export default tvShowIndex
