import {connect} from 'react-redux'
import {
  filterData,
  togglePopup,
  fetchInitialMoneyData,
  deleteCheckIn,
  toggleLeaderboardPopup,
  leaderboardDataRequest,
  leaderboardDataReceived, viewChanged, pageChanged, fetchInitialData,
} from '../actions'

import ParticipationHistoryComponent from '../components/participation_history/participation_history_component'
import {
  getFutureEvents,
  getFutureRounds,
  getPastEvents, getPastRounds,
  getTotalMoneyEarned,
} from '../selectors/participation_history_season_selector'

const mapStateToProps = (state) => {
  const futureEvents = getFutureEvents(state)
  const futureRounds = getFutureRounds(state)
  const pastEvents = getPastEvents(state)
  const pastRounds = getPastRounds(state)
  const totalMoneyEarned = getTotalMoneyEarned(state)

  return {
    futureEvents,
    futureEventsCount: futureEvents.length,
    pastEvents,
    pastEventsCount: pastEvents.length,
    seasons: state.participationHistory.seasons,
    seasonId: state.participationHistory.seasonId,
    playerName: state.participationHistory.playerName,
    dataIsLoading: state.participationHistory.dataIsLoading,
    moneyEarnedTotal: totalMoneyEarned,
    isPGA: state.participationHistory.isPGA,
    areLoadingEventsIds: state.participationHistory.areLoadingEventsIds,
    loadedEvents: state.participationHistory.loadedEvents,
    isOpenedEventPopup: state.participationHistory.isOpenedEventPopup,
    currentlyOpenedEventPopup: state.participationHistory.currentlyOpenedEventPopup,
    checkInList: state.participationHistory.checkInList,
    checkInsCount: state.participationHistory.checkInsCount,
    showCheckInTable: state.participationHistory.showCheckInTable,
    usgaIndex: state.participationHistory.usgaIndex,
    photoUrl: state.participationHistory.photoUrl,
    currentlyRoundLeaderboardPopup: state.participationHistory.currentlyRoundLeaderboardPopup,
    isOpenedLeaderboardPopup: state.participationHistory.isOpenedLeaderboardPopup,
    loadingLeaderboards: state.participationHistory.loadingLeaderboards,
    viewOption: state.participationHistory.viewOption,
    futureRounds,
    pastRounds,
    futureRoundsCount: futureRounds.length,
    pastRoundsCount: pastRounds.length,
    editProfileUrl: state.participationHistory.editProfileUrl,
    pageFutureRounds: state.participationHistory.pageFutureRounds,
    pagePastRounds: state.participationHistory.pagePastRounds,
    pageFutureEvents: state.participationHistory.pageFutureEvents,
    pagePastEvents: state.participationHistory.pagePastEvents,
  }
}

const mapDispatchToProps = (dispatch) => ({
  filterParticipationHistory: (season) => dispatch(filterData('participationHistory', season)),
  fetchMoneyEarnedInformation: (eventId) => dispatch(fetchInitialMoneyData(eventId)),
  selectOpenedMoneyEarnedInformationPopup: (isShown, eventId) => dispatch(togglePopup(isShown, eventId)),
  deleteCheckIn: (checkInId) => dispatch(deleteCheckIn(checkInId)),
  onLeaderboardOpen: (isShown, roundId) => dispatch(toggleLeaderboardPopup(isShown, roundId)),
  fetchLeaderboardStart: () => dispatch(leaderboardDataRequest()),
  fetchLeaderboardEnd: () => dispatch(leaderboardDataReceived()),
  viewChanged: (option) => dispatch(viewChanged(option)),
  setPage: (entity, period, page) => dispatch(pageChanged(entity, period, page)),
  fetchParticipationHistoryData: () => dispatch(fetchInitialData('participationHistory')),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParticipationHistoryComponent)
