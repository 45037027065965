import _ from 'lodash'

const ROUND_RELATED_FIELDS = [ 'pairing_id', 'schedule_id' ]

export const formatNavigatorOption = (navigatorOption, state) => {
  const selectedFields = [
    'id', 'name', 'disabledMessage', 'internalId', 'score',
    'fetchingPermissions', 'disabled',
  ]
  const filteredNavigatorOption = _.pick(navigatorOption, selectedFields)

  const leagueTerminology = _.capitalize(state.misc.leagueProduct)
  let filteredLocation = navigatorOption.location
  filteredLocation = filteredLocation.replace(/%league%/g, leagueTerminology)
  filteredNavigatorOption.location = filteredLocation

  let filteredName = navigatorOption.name
  filteredName = filteredName.replace(/%league%/g, leagueTerminology)
  filteredNavigatorOption.name = filteredName

  let url = navigatorOption.url || ''
  if (url.includes(':?round_id')) {
    url = url.replace(':?round_id', navigatorOption.selectedRoundId)
  } else {
    const roundField = _.find(ROUND_RELATED_FIELDS, field => url.includes(`:?${ field }`))
    if (roundField) {
      const round = state.rounds[navigatorOption.selectedRoundId]
      if (round) {
        const fieldValue = round[_.camelCase(roundField)]
        url = url.replace(`:?${ roundField }`, fieldValue)
      }
    }
  }
  filteredNavigatorOption.url = url

  filteredNavigatorOption.bookmarked = !!state.bookmarks[navigatorOption.id]

  filteredNavigatorOption.helpLinks = _.values(state.navigatorHelpLinks)
                                       .filter(helpLink => helpLink.navigatorOptionId === navigatorOption.id)

  return filteredNavigatorOption
}
