import React from 'react'
import PropTypes from 'prop-types'

import GlgForm from './glg_form'

const GlgPopupForm = ({ children, ...props }) => <GlgForm withForm={ false } { ...props }>{ children }</GlgForm>

GlgPopupForm.propTypes = {
}

GlgPopupForm.propTypes = {
  children: PropTypes.node,
}

export default GlgPopupForm
