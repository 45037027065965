import { showErrorNotification, callAPI } from 'Shared/helpers'

export const ActionTypes = {
  SET_DATA: 'SET_DATA',
  RESET_TIMER: 'RESET_TIMER',
  CLEAR_TIMER: 'CLEAR_TIMER',
}

export const fetchData = () => (dispatch, getState) => {
  const url = getState().misc.fetchDataPath

  callAPI(url, 'GET', {}, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.SET_DATA,
        data: response.data,
      })
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('thru_board.show.errors.fetching_data'))
    })
}

export const resetTimer = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_TIMER,
    fetchMethod: () => dispatch(fetchData()),
  })
}

export const clearTimer = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLEAR_TIMER,
  })
}
