import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'

class BlockReorder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      draggedItem: null,
      blocks: this.props.blocks.map(block =>
        (block.titles !== null)
          ? {...block, title: Object. values(block.titles).filter(value => value !== null && value !== '')[0] }
          : block),
    }
    this.onDragStart = this.onDragStart.bind(this)
    this.onDragOver = this.onDragOver.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.swapBlocks = this.swapBlocks.bind(this)
  }

  swapBlocks(block1Id, block2Id) {
    const block1 = this.state.blocks.filter(block => block.id === block1Id)[0]
    const block2 = this.state.blocks.filter(block => block.id === block2Id)[0]

    this.setState({
      blocks: this.state.blocks.map(block =>
        (block.id === block1Id)
          ? {...block, index: block2.index}
          : block)
        .map(block =>
          (block.id === block2Id)
            ? {...block, index: block1.index}
            : block) })
  }

  onDragStart(e, blockId) {
    this.setState({
      draggedItem: blockId,
    })
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/html', e.target.parentNode)
    e.dataTransfer.setDragImage(e.target.parentNode, e.target.parentNode.width, e.target.parentNode.height)
  }

  onDragOver(blockId) {
    if (this.state.draggedItem === blockId) {
      return
    }
    this.swapBlocks(blockId, this.state.draggedItem)
    return
  }

  onDragEnd() {
    this.setState({
      draggedItem: null,
    })
  }

  render() {
    return (
      <Col className="manage-tabs" xs={12}>
        <Col xs={10} xsOffset={1} className="title">
          <h1> {window.I18n.t('multi_widgets.reorder_blocks')} </h1>
        </Col>
        <Col xs={10} xsOffset={1} className="inner-content">
          { this.state.blocks.sort( (a, b) => a.index <= b.index ? -1 : 1 ).map(block =>
              <Col xs={12} key={block.id} className="tab" onDragOver={() => this.onDragOver(block.id)}>
                <Col xs={2} md={1}
                     className="movable-icon"
                     draggable
                     onDragStart={ (e) => this.onDragStart(e, block.id) }
                     onDragEnd={ this.onDragEnd } >
                  <i className="fa fa-bars"></i>
                </Col>
                <Col xs={6} md={9}>
                  <span>
                    { block.titles !== null
                        ? `${block.title}, ${window.I18n.t('multi_widgets.tiles')}: ${this.props.columns.filter(c => c.blockId === block.id).length}`
                        : ` - , ${window.I18n.t('multi_widgets.tiles')}: ${this.props.columns.filter(c => c.blockId === block.id).length}` }
                  </span>
                </Col>
            </Col> )}
        </Col>

        <Col xs={10} xsOffset={1} className="button-row">
          <button className="button submit save-page save-with-external-link btn btn-primary pull-right"
                  onClick={ e => { e.preventDefault(); this.props.reorderBlocks(this.state.blocks); this.props.cancel(); return false } } >
                  { window.I18n.t('multi_widgets.save') }
          </button>
          <a className="button cancel grey btn btn-default pull-right margin-right-10"
             onClick={ e => { e.preventDefault(); this.props.cancel() } } >
            { window.I18n.t('multi_widgets.cancel') }
          </a>
        </Col>
      </Col>
    )
  }
}

BlockReorder.propTypes = {
  blocks: PropTypes.array,
  columns: PropTypes.array,
  reorderBlocks: PropTypes.func,
  cancel: PropTypes.func,
}

export default BlockReorder
