import _ from 'lodash'
import { ActionTypes } from '../actions'

const {
  CREATING_NAVIGATOR_OPTION,
  CREATED_NAVIGATOR_OPTION,
} = ActionTypes

const initialState = {
  creatingNavigatorOption: false,
}

const misc = (state = {}, action) => {
  if (_.isUndefined(state.creatingNavigatorOption)) {
    state = {
      ...initialState,
      ...state,
    }
  }
  switch (action.type) {
    case CREATING_NAVIGATOR_OPTION:
      return {
        ...state,
        creatingNavigatorOption: true,
      }
    case CREATED_NAVIGATOR_OPTION:
      return {
        ...state,
        creatingNavigatorOption: false,
      }
    default:
      return state
  }
}

export default misc
