import _ from 'lodash'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from 'react-bootstrap'
import { Row, Col } from 'react-flexbox-grid'

import {
  GlgButton,
} from 'SharedComponents'


const GlgOptionsList = ({
  inputName, columns, defaultOptionValues, placeholders,
}) => {
  if (!defaultOptionValues || _.isEmpty(defaultOptionValues)) {
    defaultOptionValues = [ columns ? {} : '' ]
  }
  const [ inputValues, setInputValues ] = useState(defaultOptionValues)

  const handleInputChange = (index, value) => {
    const newInputValues = [ ...inputValues ]
    newInputValues[index] = value
    setInputValues(newInputValues)
  }

  const handleInputColumnChange = (index, column, value) => {
    const newInputValues = [ ...inputValues ]
    newInputValues[index] = {
      ...newInputValues[index],
      [column]: value,
    }
    setInputValues(newInputValues)
  }

  const handleOptionDelete = (index) => {
    const newInputValues = [ ...inputValues ]
    newInputValues.splice(index, 1)
    setInputValues(newInputValues)
  }

  return <div className="glg-options-list flexbox-context">{inputValues.map((inputValue, index) => <Row key={ index } className="add-margin-bottom-5"><Col xs={9} className="no-padding-right"><Row>{_.isEmpty(columns) ? <Col xs={true}><FormControl name={ inputName } type="text" placeholder={ placeholders[0] } value={ inputValue } onChange={ (e) => { handleInputChange(index, e.target.value) } }></FormControl></Col> : (
                columns.map((column, colIndex) => <Col xs={true} key={ colIndex } className={ colIndex > 0 ? 'no-padding-left' : '' }><FormControl name={ `${ inputName }[${ index }][${ column }]` } type="text" placeholder={ placeholders[colIndex] } value={ inputValue[column] || '' } onChange={ (e) => { handleInputColumnChange(index, column, e.target.value) } }></FormControl></Col>)
              )}</Row></Col><Col xs={3} className="no-padding-left">{inputValues.length > 1 && <GlgButton size="small" text="-" className="option-button" onClick={ () => { handleOptionDelete(index) } }></GlgButton>}{index === inputValues.length - 1 && <GlgButton size="small" text="+" className="option-button" onClick={ () => { setInputValues([ ...inputValues, '' ]) } }></GlgButton>}</Col></Row>)}</div>
}

GlgOptionsList.propTypes = {
  // The name of the input (the name of the array in the back-end)
  inputName: PropTypes.string.isRequired,
  // (optional) If unspecified, the values are just strings. If columns=[ 'field1', 'field2' ], then
  // the values are { field1: value1, field2: value2 }
  columns: PropTypes.array,
  // (optional) If columns are not specified, it should be an array of string. Otherwise,
  // it should be an array of { field1: value1, field2: value2 }
  defaultOptionValues: PropTypes.array,
  // The placeholders for all columns
  placeholders: PropTypes.array,
}

GlgOptionsList.defaultProps = {
  placeholders: [],
}

export default GlgOptionsList
