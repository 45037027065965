import { connect } from 'react-redux'
import moment from 'moment'
import EventSetupRegistration from '../../components/content/event_setup_registration.jsx'
import { extractValues as selectedCheckboxes } from '../../components/content/event_setup/checkbox_options'
import { extractValue as selectedRadioOption } from '../../components/content/event_setup/radio_group'
import {
  setGenericData,
  saveEventSettingsWidget,
  getPossibleCustomFieldAnswers,
} from '../../actions/index'

// const mapStateToProps = (state) => {}

const keyValue = (obj, transform = x => x) => {
  const res = {}
  res[obj.key] = transform(obj.value)
  return res
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  setData: (keys, value) => {
    dispatch(setGenericData(ownProps.widgetId, keys, value))
    if (! ownProps.content.dataChanged) {
      dispatch(setGenericData(ownProps.widgetId, [ 'dataChanged' ], true))
    }
  },

  save: () => {
    const { registration, links } = ownProps.content
    const registrationWithCreditCardEnabled = registration.data.registration_enablement.options.registration_with_credit_card?.selected
    const minMaxMembersGuests = registration.data.registration_setup.min_max_members_guests
    const youthLeague = registration.data.youth_league

    const registrationData = {
      ...selectedCheckboxes(registration.data.registration_enablement),
      ...selectedCheckboxes(registration.data.show_list_of_participants),
      ...selectedCheckboxes(registration.data.include_following_list),
      ...selectedCheckboxes(registration.data.registration_setup),
      ...selectedRadioOption(registration.data.registration_setup.options.enable_pending_registration.added_golfers),
      ...selectedRadioOption(registration.data.registration_setup.options.enable_pending_registration.custom_fields),
      ...keyValue(registration.data.registration_setup.options.enable_pending_registration.custom_field_answer, answer => answer.map(e => e.value)),
      ...keyValue(registration.data.player_limit),
      ...keyValue(registration.data.registration_dates.open, timestamp => timestamp ? moment.unix(timestamp).format('Y/MM/DD hh:mm A') : ''),
      ...keyValue(registration.data.registration_dates.close, timestamp => timestamp ? moment.unix(timestamp).format('Y/MM/DD hh:mm A') : ''),
      ...(
        youthLeague ?
          {
            [minMaxMembersGuests.children.key_min]: registrationWithCreditCardEnabled ? 0 : (minMaxMembersGuests.children.min || 1),
            [minMaxMembersGuests.children.key_max]: registrationWithCreditCardEnabled ? 0 : (minMaxMembersGuests.children.max || 1),
            [registration.data.members_and_guests_radio.key]: registration.data.members_and_guests_radio.selected,
          }
          :
          {
            ...(
              'members' in minMaxMembersGuests ? {
                [minMaxMembersGuests.members.key_min]: registrationWithCreditCardEnabled ? 0 : (minMaxMembersGuests?.members?.min || 0),
                [minMaxMembersGuests.members.key_max]: registrationWithCreditCardEnabled ? 0 : (minMaxMembersGuests?.members?.max || 0),
              } : { }
            ),
            [minMaxMembersGuests.guests.key_min]: registrationWithCreditCardEnabled ? 0 : (minMaxMembersGuests?.guests?.min || 0),
            [minMaxMembersGuests.guests.key_max]: registrationWithCreditCardEnabled ? 0 : (minMaxMembersGuests?.guests?.max || 0),
          }
      ),
    }

    let playersData = {}
    if ('players' in ownProps.content) {
      const players = ownProps.content.players
      playersData = {
        ...selectedCheckboxes(players.data.member_eligibility_criteria),
        ...selectedCheckboxes(players.data.member_eligibility_criteria.options.use_gender.gender_answers),

        ...keyValue(players.data.member_eligibility_criteria.options.use_index_range.min_handicap_index),
        ...keyValue(players.data.member_eligibility_criteria.options.use_index_range.max_handicap_index),
        ...selectedCheckboxes(players.data.member_eligibility_criteria.options.use_index_range.ignore_requirement),

        ...keyValue(players.data.member_eligibility_criteria.options.use_age_range.age_date, timestamp => moment.unix(timestamp).format('Y/MM/DD')),
        ...keyValue(players.data.member_eligibility_criteria.options.use_age_range.min_age),
        ...keyValue(players.data.member_eligibility_criteria.options.use_age_range.max_age),
      }
    }

    dispatch(saveEventSettingsWidget(ownProps.widgetId, links.save.path, {...registrationData, ...playersData}))
  },

  selectCustomField: (customFieldId) => {
    const { links } = ownProps.content

    dispatch(setGenericData(ownProps.widgetId, [ 'registration', 'data', 'registration_setup', 'options', 'enable_pending_registration', 'custom_fields', 'selected' ], customFieldId))
    if (! ownProps.content.dataChanged) {
      dispatch(setGenericData(ownProps.widgetId, [ 'dataChanged' ], true))
    }

    customFieldId && dispatch(getPossibleCustomFieldAnswers(ownProps.widgetId, decodeURI(links.get_custom_field_answers.path).replace(/{selected_field_id}/g, customFieldId)))
  },
})

export default connect(
  null,
  mapDispatchToProps,
)(EventSetupRegistration)
