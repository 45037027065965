import _ from 'lodash'
import { ActionTypes } from '../actions'

const {
  FETCH_INITIAL_DATA_REQUEST,
  FETCH_INITIAL_DATA_RECEIVED,
  CHANGE_DROPDOWN_STATE,
  CHANGE_FILTER,
  SAVED_BOOKMARKS_TEMPLATE,
} = ActionTypes

const initialState = {
  dataIsLoading: false,
  dataIsRequested: false,
  isDropdownOpen: false,
  navigatorFilter: '',
}

const misc = (state = {}, action) => {
  if (_.isUndefined(state.isDropdownOpen)) {
    state = {
      ...initialState,
      ...state,
    }
  }
  switch (action.type) {
    case FETCH_INITIAL_DATA_REQUEST:
      return {
        ...state,
        dataIsLoading: true,
        dataIsRequested: true,
      }
    case FETCH_INITIAL_DATA_RECEIVED:
      return {
        ...state,
        dataIsLoading: false,
        ...action.data.misc,
      }
    case CHANGE_DROPDOWN_STATE:
      return {
        ...state,
        isDropdownOpen: action.isOpen,
      }
    case CHANGE_FILTER:
      return {
        ...state,
        navigatorFilter: action.value,
      }
    case SAVED_BOOKMARKS_TEMPLATE:
      return {
        ...state,
        savedTemplateOptionIds: action.bookmarkedOptionIds,
      }
    default:
      return state
  }
}

export default misc
