import { connect } from 'react-redux'
import RecipientListComponent from '../components/recipient_list_component'
import {
  toggleFilter,
  setGender,
  setAge,
  setHandicapIndex,
  addNewFilter,
  removeFilter,
  setFilterAttributeValue,
  setDropdownFilterValue,
  toggleRoundAttendanceOption,
} from '../actions/setup'

const mapStateToProps = (state) => {
  return {
    recipientList: state.recipientList,
    triggers: {
      eventEnd: state.triggers.afterEventEnd,
      roundEnd: state.triggers.afterRoundEnd,
    },
    textMessageChannelChecked: state.channels.textMessage.checked,
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleFilter: (filter) => () => { dispatch(toggleFilter(filter)) },
  setGender: (gender) => () => { dispatch(setGender(gender)) },
  toggleRoundAttendanceOption: (playerStatus) => () => { dispatch(toggleRoundAttendanceOption(playerStatus)) },
  setAge: (minOrMaxOrDate, age) => { dispatch(setAge(minOrMaxOrDate, age)) },
  setHandicapIndex: (minOrMax, value) => { dispatch(setHandicapIndex(minOrMax, value)) },
  addNewFilter: (filterType) => { dispatch(addNewFilter(filterType)) },
  removeFilter: (filterType, filterId) => { dispatch(removeFilter(filterType, filterId)) },
  setFilterAttributeValue: (filterType, filterAttribute, value) => { dispatch(setFilterAttributeValue(filterType, filterAttribute, value)) },
  setDropdownFilterValue: (filterType, filterId, dropdownName) => (dropdownValue) => { dispatch(setDropdownFilterValue(filterType, filterId, dropdownName, dropdownValue)) },
  setTextFieldFilterValue: (filterType, filterId, textFieldName, textFieldValue) => { dispatch(setDropdownFilterValue(filterType, filterId, textFieldName, textFieldValue)) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecipientListComponent)
