import React, {Fragment, PureComponent} from 'react'
import PropTypes from 'prop-types'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import { GlgIcon } from 'SharedComponents'

class ViewType extends PureComponent {
  constructor(props) {
    super(props)
    this.handleEventViewClick = this.handleEventViewClick.bind(this)
    this.handleRoundViewClick = this.handleRoundViewClick.bind(this)
  }

  handleEventViewClick() {
    if (!this.props.disabled && this.props.viewType !== 'event') {
      this.props.onChange('event')
      this.props.fetchData()
    }
  }

  handleRoundViewClick() {
    if (!this.props.disabled && this.props.viewType !== 'round') {
      this.props.onChange('round')
      this.props.fetchData()
    }
  }

  render() {
    const eventViewStatus = this.props.viewType === 'event' ? 'active' : 'inactive'
    const eventClasses = `event-view ${ eventViewStatus }`
    const roundViewStatus = this.props.viewType === 'round' ? 'active' : 'inactive'
    const roundClasses = `round-view ${ roundViewStatus }`
    const iconClass = this.props.disabled ? '' : 'clickable'
    const eventViewTooltip = <Tooltip id="event_view_tooltip">{ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.event_view') }</Tooltip>
    const roundViewTooltip = <Tooltip id="round_view_tooltip">{ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.round_view') }</Tooltip>
    return <Fragment><span onClick={ this.handleRoundViewClick } className={ roundClasses }><OverlayTrigger placement="top" overlay={ roundViewTooltip }><GlgIcon icon="four-lines" iconClass={ iconClass }></GlgIcon></OverlayTrigger></span><span onClick={ this.handleEventViewClick } className={ eventClasses }><OverlayTrigger placement="top" overlay={ eventViewTooltip }><GlgIcon icon="list-view" iconClass={ iconClass }></GlgIcon></OverlayTrigger></span></Fragment>
  }
}

ViewType.propTypes = {
  viewType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default ViewType
