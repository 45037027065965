import { connect } from 'react-redux'
import DrivingDistancesComponent from '../components/driving_distances_component'

import { closePopup, loadPlayersForStation, refreshPlayersForStation } from '../actions'

const mapStateToProps = (state, ownProps) => {
  return {
    test: state.misc.test,
    roundId: state.misc.roundId,
    visible: state.popup.visible,
    players: state.station.players,
    stationId: ownProps.stationId,
  }
}

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => dispatch(closePopup()),
  loadPlayers: (stationId) => dispatch(loadPlayersForStation(stationId)),
  refreshPlayers: (stationId) => dispatch(refreshPlayersForStation(stationId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrivingDistancesComponent)
