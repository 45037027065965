import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'
import { GlgTooltipIcon } from 'SharedComponents'

const saveBarComponent = ({randomShots, recomputeStats, deleteShots, isAdmin, isCustomerManager, disableDelete}) => {
  const disableClass = disableDelete ? 'disabled' : ''
  return (
    <div className='save_bar'>
      <Col xs={12} sm={12} md={6} lg={6} className='no-padding'>
        {isAdmin &&
          <a className='btsp_gray_button' href={randomShots} data-confirm={window.I18n.t('shot_by_shot.fill_all_holes')}>
            {window.I18n.t('shot_by_shot.random_shots')}
          </a>
        }
        {isAdmin &&
          <a className='btsp_gray_button' href={recomputeStats} data-confirm={window.I18n.t('shot_by_shot.recompute_all_hole_statistics')}>
            {window.I18n.t('shot_by_shot.recompute_statistics')}
          </a>
        }
        {isCustomerManager &&
          <a className={'btsp_gray_button ' + disableClass} href={deleteShots} data-confirm={window.I18n.t('shot_by_shot.delete_all_shots')}>
            {window.I18n.t('shot_by_shot.clear_all_shots')}
            {disableDelete && <GlgTooltipIcon tooltip={window.I18n.t('shot_by_shot.shots_cannot_be_cleared')}/>}
          </a>
        }
      </Col>
    </div>
  )
}

saveBarComponent.propTypes = {
  randomShots: PropTypes.string,
  recomputeStats: PropTypes.string,
  deleteShots: PropTypes.string,
  isAdmin: PropTypes.bool,
  isCustomerManager: PropTypes.bool,
  disableDelete: PropTypes.bool,
}

export default saveBarComponent
