import { connect } from 'react-redux'

import DrivingDistanceEditComponent from '../components/driving_distance_edit_component'
import { openPopup } from '../actions'

const mapStateToProps = (state, ownProps) => {
  return {
    playerName: ownProps.playerName,
    distance: ownProps.distance,
    distanceId: ownProps.distanceId,
  }
}

const mapDispatchToProps = (dispatch) => ({
  openPopup: (playerName, distance, distanceId) => dispatch(openPopup(playerName, distance, distanceId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrivingDistanceEditComponent)
