import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import LeaderboardTableContainer from '../containers/leaderboard_table_container'
import BoardTableContainer from '../containers/board_table_container'
import SaveBarComponent from './save_bar_component'
import PreviewContainer from '../containers/preview_container'
class CourseLeaderboardComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      oldLines: [ ...props.lines ],
      oldMessage: props.message,
      oldScore: props.score,
      oldShowMessage: props.showMessage,
      height: 392,
    }
    this.changesNotPublished = this.changesNotPublished.bind(this)
    this.setHeight = this.setHeight.bind(this)
  }

  componentDidMount() {
    this.timer = setInterval(this.props.refreshData, 30000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.unSavedChanges === true && this.props.unSavedChanges === false) {
      this.setState({oldLines: [ ...this.props.lines ], oldShowMessage: this.props.showMessage, oldMessage: this.props.message, oldScore: this.props.score })
    }
  }

  setHeight(newHeight) {
    this.setState({...this.state, height: newHeight})
  }

  changesNotPublished() {
    const { oldLines, oldMessage, oldScore, oldShowMessage } = this.state
    const { lines, message, score, showMessage, unSavedChanges } = this.props
    
    if (oldMessage === message && oldScore === score && oldShowMessage === showMessage && _.isEqual(oldLines, lines)) {
      return false
    }
    return unSavedChanges
  }

  render() {
    const roundGgid = this.props.roundGgid
    const showMessage = this.changesNotPublished()
    const noRows = this.props.lines.length
    const showMessageValue = this.props.showMessage
    const boardPath = '/boards/' + this.props.roundGgid + '?rows=' + noRows + '&message=' + showMessageValue

    return (
      <Fragment>
        <div className='row row-no-padding'>
          <div className='alert product_warning stick_to_breadcrumb'>
            <ul className='list-inline'>
              <li className='bold-note'>{ window.I18n.t('course_leaderboards.note') }:</li>
              <li>
                { window.I18n.t('course_leaderboards.course_leaderboard_is_accesible_at') }
                <a href={boardPath} target='_blank'>{'www.golfgenius.com/boards/' + roundGgid + '?rows=' + noRows + '&message=' + showMessageValue}</a>
              </li>
            </ul>
          </div>
        </div>
        <LeaderboardTableContainer height={this.state.height}/>
        <BoardTableContainer setHeight={this.setHeight}/>
        <PreviewContainer />
        <SaveBarComponent submit={() => this.props.submitCourseLeaderboard()} 
                          showMessage={showMessage}
                          refreshLeaderboard={() => this.props.refreshData()}
                          lastDeviceUpdate={this.props.lastDeviceUpdate}/>
      </Fragment>
    )
  }
}

CourseLeaderboardComponent.propTypes = {
  isMultiRound: PropTypes.bool,
  lines: PropTypes.array,
  roundGgid: PropTypes.string,
  submitCourseLeaderboard: PropTypes.func,
  message: PropTypes.string,
  showMessage: PropTypes.bool,
  score: PropTypes.string,
  refreshData: PropTypes.func,
  unSavedChanges: PropTypes.bool,
  lastDeviceUpdate: PropTypes.string,
}

export default CourseLeaderboardComponent
