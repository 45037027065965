import React, { Component } from 'react'
import PropTypes from 'prop-types'

class DrivingDistanceEditComponent extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    const { playerName, distance, distanceId, openPopup } = this.props
    return <td>
      <a onClick={() => openPopup(playerName, distance, distanceId, true)}>{window.I18n.t('driving_distances.edit')}</a>
    </td>
  }
  
}

DrivingDistanceEditComponent.propTypes = {
  playerName: PropTypes.string,
  distance: PropTypes.number,
  openPopup: PropTypes.func,
  distanceId: PropTypes.string,
}

export default DrivingDistanceEditComponent
