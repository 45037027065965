import { connect } from 'react-redux'
import ListTabs from '../presentational/list_tabs'
import { setActiveTab, editTab, updateTab, removeTab } from '../../actions'

const mapStateToProps = state => ({
  tabs: state.tabs.sort( (a, b) => a.index <= b.index ? -1 : 1 ),
  activeTab: state.filters.active_tab,
  usesTranslations: state.filters.uses_translations,
  availableLanguages: state.filters.available_languages,
  currentLanguage: state.filters.current_language,
})

const mapDispatchToProps = dispatch => ({
  setActiveTab: id => dispatch(setActiveTab(id)),
  editTab: (id) => dispatch(editTab(id)),
  updateTab: (id, title) => dispatch(updateTab(id, title)),
  removeTab: id => dispatch(removeTab(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListTabs)
