import { ActionTypes } from '../actions'
import _ from 'lodash'

const {
  FETCH_INITIAL_DATA_RECEIVED,
  FETCH_NAVIGATOR_OPTION_PERMISSIONS,
  RECEIVE_NAVIGATOR_OPTION_PERMISSIONS,
  SELECT_NAVIGATOR_OPTION_ROUND,
} = ActionTypes

/*
const DEFAULT_NAVIGATOR_OPTION_DATA = {
  id: '',
  name: '',
  tags: [],
  alternateTags: [],
  location: '',
  url: '',
  weight: 1.0,
  internalId: '',
  selectedRoundId: '',
  disabled: '',
}
*/

const navigatorOptions = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INITIAL_DATA_RECEIVED:
      return _.mapValues(action.data.navigatorOptions, navigatorOption => ({
        ...navigatorOption,
        selectedRoundId: action.data.misc.defaultRoundId,
      }))
    case SELECT_NAVIGATOR_OPTION_ROUND:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          selectedRoundId: action.roundId,
        },
      }
    case FETCH_NAVIGATOR_OPTION_PERMISSIONS:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          fetchingPermissions: true,
        },
      }
    case RECEIVE_NAVIGATOR_OPTION_PERMISSIONS:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          fetchingPermissions: false,
          disabledMessage: action.data.disabledMessage || '',
        },
      }
    default:
      return state
  }
}

export default navigatorOptions
