import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ScorecardsDropdownPublished from './scorecards_dropdown_published'

class ReportCenterItemPublished extends Component {
  render() {
    return (
      <div className={ [ 2, 3 ].includes(this.props.listIndex) ? 'ios-9-no-overlap' : '' }>
      <div className={ 'report-list-item report_center_item_inline rci_published' + ( this.props.job.error ? ' error' : '' ) } id={ this.props.report_center_item.id }>
        <div className= { 'name' + (this.props.report_center_item.name.length > 18 ? ' small-name' : '')}>
          <div className='nameContainer'>
            { this.props.report_center_item.name.toUpperCase() }
          </div>
        </div>
        <div className='body'>
          {
            //job in progress 
            this.props.job.id !== null &&
              <div className="job">
                <img src={require('bootstrap_theme/job/Spinning-circle.gif')} />
              </div>
          }
          <div className={ this.props.job.id === null && this.props.job.completed_links === null ? '' : 'hidden' }>
            <div className={ 'picture' + ((this.props.report_center_item.image_url === null) ? ' no-image' : '')}>
              <img className={this.props.report_center_item.report_type === 'SpreadsheetReport' && this.props.report_center_item.image_url !== null ? 'spreadsheet' : '' } src={this.props.report_center_item.image_url || require('report_center/no-image.png')} />
            </div>
            <div className='menu'>
              <a onClick={ [ 'Gpu::Report', 'Pairing', 'Gpu::Scorecard' ].indexOf(this.props.report_center_item.report_type) !== -1 ?
                  //if GPU::Report - tell modal to load print_options form from edit_conflicts_path and submit throught printReport or cancel throught setJobId of this very rci
                  //otherwise just printReport right away
                  () => { this.props.openPrintModal({ edit_conflicts_path: this.props.report_center_item.action[1], rci_base_printReport: this.props.printReport, rci_base_setJobId: this.props.setJobId}) }
                : () => { this.props.printReport() } }>
                <div className='btsp_orange_button'>
                  {this.props.report_center_item.action[0]}
                </div>
              </a>
              { !this.props.isTeamCaptain && 
                <div>
                  {
                    this.props.report_center_item.edit_path &&
                      (
                        this.props.report_center_item.edit_path[0].length === 2 ?
                          <div>
                            <a target="_blank" rel='noopener noreferrer'
                               href={ this.props.report_center_item.edit_path[0][1] } >
                              <div className={ 'btsp_orange_button'}>
                                {this.props.report_center_item.edit_path[0][0]}
                              </div>
                            </a>
                            <a target="_blank" rel='noopener noreferrer'
                               href={ this.props.report_center_item.edit_path[1][1] }>
                              <div className={ 'btsp_orange_button'}>
                                {this.props.report_center_item.edit_path[1][0]}
                              </div>
                            </a>
                          </div>
                        :
                          <a target="_blank" rel='noopener noreferrer'
                             href={ this.props.report_center_item.edit_path[1] }>
                            <div className={ 'btsp_orange_button'}>
                              {this.props.report_center_item.edit_path[0]}
                            </div>
                          </a>
                      )
                  }
                  {
                    <div>
                      <ScorecardsDropdownPublished report_center_lists={this.props.report_center_lists}
                        report_center_item={this.props.report_center_item}
                        importDiscoverCenter={this.props.importDiscoverCenter} />
                    </div>
                  }
                </div>
              }
            </div>
          </div>
          {
            //overlay after download has finished succesfully
            this.props.job.id === null && this.props.job.completed_links !== null &&
              <div className='menu post_print'>
                <a onClick={ () => this.props.setJobId({ id: null, error: false, completed_links: null}) }>
                  <i className='fa fa-times'></i>
                </a>
                {
                  this.props.job.completed_links['pdf_path'] ?
                    <div>
                      <a target='_blank' rel='noopener noreferrer'
                         href={this.props.job.completed_links['pdf_path']}>
                        <div className={ 'btsp_orange_button'}>
                          { this.props.job.completed_links.button_label }
                        </div>
                      </a>
                    </div> : ''
                }
                { 
                  this.props.job.completed_links['primary_docx_service'] !== null ?
                    (
                      <a onClick={ this.props.generateDocx }>
                        <div className='btsp_orange_button'>
                          { !window.I18n ? '' : window.I18n.t('scorecards_center.generate_doc') }
                        </div>
                      </a>
                    ) : (
                      (this.props.job.completed_links['docx_path'] || null) !== null ?
                        <div>
                          <a target='_blank' rel='noopener noreferrer'
                             href={ this.props.job.completed_links['docx_path'] }>
                            <div className={ 'btsp_orange_button'}>
                              { !window.I18n ? '' : window.I18n.t('scorecards_center.download_doc') }
                            </div>
                          </a>
                        </div> : ''
                    ) 
                }
              </div>
          }
        </div>
        <div className='customer-icon-wrapper float-left'>
          <a href="#" onClick={ () => {} }>
            <div className='customer-icon' style={ { backgroundImage: 'url(' + this.props.report_center_item.customer_logo + ')' }} />
          </a>
        </div>
        <div className='stats pull-right'>
          <div className='customer-name'>
            <a href="#" onClick={ () => { }}>{ this.props.report_center_item.customer_name }</a>
          </div>
          <span className='pull-left'>
            <i className='fa fa-fw fa-list'></i>
            { this.props.report_center_item.list_count || 0}
          </span>
          { 
            this.props.report_center_item.image_url &&
              <a onClick={ this.props.togglePreview } className="zoom">
                <i className='fa fa-fw fa-search'></i>
                {
                  this.props.show_preview && 
                    <div>
                      <div className="shadow"></div>
                      <img src={this.props.report_center_item.image_url || require('report_center/no-image.png')} />
                      <a onClick={ this.props.togglePreview }>
                        <img src={require('bootstrap_theme/close.png')} className="close_button"/>
                      </a>  
                    </div>
                }
              </a>
          }
          <span className='pull_right'>
            <i className='fa fa-fw fa-print'></i>
            { this.props.report_center_item.print_count || 0 }
          </span>
        </div>
      </div>
      </div>
    )
  }
}

ReportCenterItemPublished.propTypes = {
  job: PropTypes.object.isRequired,
  report_center_item: PropTypes.object.isRequired,
  report_center_lists: PropTypes.array.isRequired,
  //customers_info: PropTypes.object.isRequired,
  importDiscoverCenter: PropTypes.func.isRequired,
  show_preview: PropTypes.bool.isRequired,
  setJobId: PropTypes.func.isRequired,
  generateDocx: PropTypes.func.isRequired,
  togglePreview: PropTypes.func.isRequired,
  addRemoveItemToList: PropTypes.func.isRequired,
  printReport: PropTypes.func.isRequired,
  openPrintModal: PropTypes.func.isRequired,
  listIndex: PropTypes.number.isRequired,
  isTeamCaptain: PropTypes.bool.isRequired,
}

export default ReportCenterItemPublished
