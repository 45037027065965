export const ActionTypes = {
  SET_NAME: 'SET_NAME',

  TOGGLE_CHANNEL: 'TOGGLE_CHANNEL',
  SET_CHANNEL_TEMPLATE: 'SET_CHANNEL_TEMPLATE',
  SET_CHANNEL_ALL_TEMPLATES: 'SET_CHANNEL_ALL_TEMPLATES',

  TOGGLE_TRIGGER: 'TOGGLE_TRIGGER',
  SET_TRIGGER_DAYS: 'SET_TRIGGER_DAYS',
  SET_TRIGGER_PERCENTAGE: 'SET_TRIGGER_PERCENTAGE',
  SET_TRIGGER_EVENTS: 'SET_TRIGGER_EVENTS',
  TOGGLE_TRIGGER_PAUSE_EVALUATION: 'TOGGLE_TRIGGER_PAUSE_EVALUATION',
  SET_TRIGGER_PAUSE_EVALUATION_DAYS: 'SET_TRIGGER_PAUSE_EVALUATION_DAYS',

  TOGGLE_FILTER: 'TOGGLE_FILTER',
  SET_GENDER: 'SET_GENDER',
  SET_AGE: 'SET_AGE',
  TOGGLE_ROUND_ATTENDANCE_OPTION: 'TOGGLE_ROUND_ATTENDANCE_OPTION',
  SET_HANDICAP_INDEX: 'SET_HANDICAP_INDEX',
  SET_FILTER_ATTRIBUTE_VALUE: 'SET_FILTER_ATTRIBUTE_VALUE',
  SET_DROPDOWN_FILTER_VALUE: 'SET_DROPDOWN_FILTER_VALUE',

  ADD_NEW_FILTER: 'ADD_NEW_FILTER',
  REMOVE_FILTER: 'REMOVE_FILTER',

  SET_SCHEDULE_FREQUENCY: 'SET_SCHEDULE_FREQUENCY',
  SET_SCHEDULE_HOUR: 'SET_SCHEDULE_HOUR',
  SET_SCHEDULE_WEEKDAY: 'SET_SCHEDULE_WEEKDAY',
  SET_SCHEDULE_DAY_OF_MONTH: 'SET_SCHEDULE_DAY_OF_MONTH',
}

export const setName = (name) => {
  return {
    type: ActionTypes.SET_NAME,
    name,
  }
}

export const toggleChannel = (channel, defaultValue) => {
  return {
    type: ActionTypes.TOGGLE_CHANNEL,
    channel,
    defaultValue,
  }
}

export const setChannelTemplate = (channel, template) => {
  return {
    type: ActionTypes.SET_CHANNEL_TEMPLATE,
    channel,
    template,
  }
}

export const setChannelAllTemplates = (channel, templates) => {
  return {
    type: ActionTypes.SET_CHANNEL_ALL_TEMPLATES,
    channel,
    templates,
  }
}

export const toggleTrigger = (trigger) => {
  return {
    type: ActionTypes.TOGGLE_TRIGGER,
    trigger,
  }
}

export const setTriggerDays = (trigger, days) => {
  return {
    type: ActionTypes.SET_TRIGGER_DAYS,
    trigger,
    days,
  }
}

export const setTriggerPercentage = (trigger, percentage) => {
  return {
    type: ActionTypes.SET_TRIGGER_PERCENTAGE,
    trigger,
    percentage,
  }
}

export const setTriggerEvents = (trigger, events) => {
  return {
    type: ActionTypes.SET_TRIGGER_EVENTS,
    trigger,
    events,
  }
}

export const toggleTriggerPauseEvaluation = (trigger) => {
  return {
    type: ActionTypes.TOGGLE_TRIGGER_PAUSE_EVALUATION,
    trigger,
  }
}

export const setTriggerPauseEvaluationDays = (trigger, days) => {
  return {
    type: ActionTypes.SET_TRIGGER_PAUSE_EVALUATION_DAYS,
    trigger,
    days,
  }
}

export const toggleFilter = (filter) => {
  return {
    type: ActionTypes.TOGGLE_FILTER,
    filter,
  }
}

export const setGender = (gender) => {
  return {
    type: ActionTypes.SET_GENDER,
    gender,
  }
}

export const toggleRoundAttendanceOption = (playerStatus) => {
  return {
    type: ActionTypes.TOGGLE_ROUND_ATTENDANCE_OPTION,
    playerStatus,
  }
}

export const setAge = (minOrMaxOrDate, age) => {
  return {
    type: ActionTypes.SET_AGE,
    minOrMaxOrDate,
    age,
  }
}

export const setHandicapIndex = (minOrMax, value) => {
  return {
    type: ActionTypes.SET_HANDICAP_INDEX,
    minOrMax,
    value,
  }
}

export const setFilterAttributeValue = (filterType, filterAttribute, value) => {
  return {
    type: ActionTypes.SET_FILTER_ATTRIBUTE_VALUE,
    filterType,
    filterAttribute,
    value,
  }
}

export const setDropdownFilterValue = (filterType, filterId, dropdownName, dropdownValue) => {
  return {
    type: ActionTypes.SET_DROPDOWN_FILTER_VALUE,
    filterType,
    filterId,
    dropdownName,
    dropdownValue,
  }
}

export const addNewFilter = (filterType) => {
  return {
    type: ActionTypes.ADD_NEW_FILTER,
    filterType,
  }
}

export const removeFilter = (filterType, filterId) => {
  return {
    type: ActionTypes.REMOVE_FILTER,
    filterType,
    filterId,
  }
}

export const setScheduleFrequency = (frequency) => {
  return {
    type: ActionTypes.SET_SCHEDULE_FREQUENCY,
    frequency,
  }
}

export const setScheduleHour = (hour) => {
  return {
    type: ActionTypes.SET_SCHEDULE_HOUR,
    hour,
  }
}

export const setScheduleWeekday = (weekday) => {
  return {
    type: ActionTypes.SET_SCHEDULE_WEEKDAY,
    weekday,
  }
}

export const setScheduleDayOfMonth = (dayOfMonth) => {
  return {
    type: ActionTypes.SET_SCHEDULE_DAY_OF_MONTH,
    dayOfMonth,
  }
}
