/* eslint-disable no-alert */
import React from 'react'
import PropTypes from 'prop-types'


import {
  GlgTableRow,
  GlgTableCell,
} from 'SharedComponents'

const CheckInTableRow = ({ ...props }) => {
  return <GlgTableRow { ...props } isSortable={ false }><GlgTableCell>{ props.date_time }</GlgTableCell><GlgTableCell>{ props.location_name }</GlgTableCell><GlgTableCell><a href="#" onClick={() => { if (window.confirm(!window.I18n ? '' : window.I18n.t('player_activity.participation_history.delete_checkin_confirmation'))) { props.deleteCheckIn(props.id) } }}>{ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.delete_checkin') }</a></GlgTableCell></GlgTableRow>
}

CheckInTableRow.propTypes = {
  date_time: PropTypes.string,
  deleteCheckIn: PropTypes.func,
  id: PropTypes.string,
  location_name: PropTypes.string,
}

export default CheckInTableRow
