import { connect } from 'react-redux'
import HandicapAnalysis from '../../components/content/handicap_analysis'
import {
  selectRoundTournament,
  getRoundTournamentData,
  handicapAnalysisShowHiddenDetails,
  handicapAnalysisSearch,
  toggleAdjustHandicapModal,
  setAdjustHandicapFormData,
  submitAdjustHandicap,
  clearPlayerAdjustment,
  clearAllPlayerAdjustment,
} from '../../actions/index'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectRoundTournament: selected => {
    dispatch(selectRoundTournament(ownProps.widgetId, selected))
    dispatch(handicapAnalysisShowHiddenDetails(ownProps.widgetId, null))
    dispatch(handicapAnalysisSearch(ownProps.widgetId, ''))
    const hasContent = (ownProps.content.tablesData.settingsTable.data[selected] !== undefined && JSON.stringify(ownProps.content.tablesData.settingsTable.data[selected]) !== '{}')
    dispatch(getRoundTournamentData(ownProps.widgetId, ownProps.content.links.load_tournaments_data.path, selected, hasContent))
  },
  handicapAnalysisShowHiddenDetails: showKey => dispatch(handicapAnalysisShowHiddenDetails(ownProps.widgetId, showKey)),
  handicapAnalysisSearch: searchTerm => dispatch(handicapAnalysisSearch(ownProps.widgetId, searchTerm)),
  toggleAdjustHandicapModal: show => dispatch(toggleAdjustHandicapModal(ownProps.widgetId, show)),
  setAdjustHandicapFormData: (golfer, handicap, playerIds, adjustmentPath, clearAdjustmentPath) => dispatch(setAdjustHandicapFormData(ownProps.widgetId, golfer, handicap, playerIds, adjustmentPath, clearAdjustmentPath)),
  adjustHandicap: (golfer, handicap, playerIds, adjustmentPath, clearAdjustmentPath) => {
    dispatch(setAdjustHandicapFormData(ownProps.widgetId, golfer, handicap, playerIds, adjustmentPath, clearAdjustmentPath))
    dispatch(toggleAdjustHandicapModal(ownProps.widgetId, true))
  },
  submitAdjustHandicap: () => {
    dispatch(submitAdjustHandicap(
      ownProps.widgetId,
      ownProps.content.adjustmentHandicapModal.data.adjustment_path,
      ownProps.content.adjustmentHandicapModal.data.handicap,
      ownProps.content.adjustmentHandicapModal.data.player_ids
    ))
    dispatch(getRoundTournamentData(ownProps.widgetId, ownProps.content.links.load_tournaments_data.path, ownProps.content.selected, false))
  },
  cancelOrclearPlayerAdjustment: () => {
    dispatch(toggleAdjustHandicapModal(ownProps.widgetId, false))
    if (ownProps.content.adjustmentHandicapModal.data.clear_adjustment_path.path !== '#') {
      dispatch(clearPlayerAdjustment(ownProps.content.adjustmentHandicapModal.data.clear_adjustment_path.path))
      dispatch(getRoundTournamentData(ownProps.widgetId, ownProps.content.links.load_tournaments_data.path, ownProps.content.selected, false))
    }
  },
  clearAllPlayerAdjustment: () => {
    dispatch(clearAllPlayerAdjustment(ownProps.content.links.clear_all_adjustments.path, ownProps.content.selected, ownProps.content.links.clear_all_adjustments.confirmation))
    dispatch(getRoundTournamentData(ownProps.widgetId, ownProps.content.links.load_tournaments_data.path, ownProps.content.selected, false))
  },
})

export default connect(
  null,
  mapDispatchToProps,
)(HandicapAnalysis)
