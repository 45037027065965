import React from 'react'
import GlgTabs from 'SharedComponents/glg_tabs'
import ComponentsContainer from './ComponentsContainer'
import PropTypes from 'prop-types'
import HubPage from './club/HubPage'

const DirectorySetupRoot = ({customerId, hubId, roleType, renderTab, isSuperCustomer, strongFilters, allClubs, selectedClubs, availableSeasons, allCustomerManagers, selectedManagers}) => {
  return (
    <div className='golf-hub-setup-root'>
      {
        renderTab === 'hub_index' ? 
          <GlgTabs>
            <div label={window.I18n.t('views.golfhub.my_hubs')}>
              <ComponentsContainer customerId={customerId} hubId={hubId} roleType={roleType} tab={renderTab}/>
            </div>
            <span className='spacer'></span>
            <div label={window.I18n.t('views.golfhub.editable.banners')}>
              <ComponentsContainer customerId={customerId} tab={'banners'}/>
            </div>
            <div label={window.I18n.t('views.golfhub.editable.sponsors')}>
              <ComponentsContainer customerId={customerId} tab={'sponsors'}/>
            </div>
            <div label={window.I18n.t('views.golfhub.editable.schedules')}>
              <ComponentsContainer customerId={customerId} tab={'schedules'}/>
            </div>
            <span className='spacer'></span>
            <div label={window.I18n.t('views.golfhub.editable.settings')}>
              <ComponentsContainer customerId={customerId} tab={'settings'}/>
            </div>
          </GlgTabs>
          : <div>
              <HubPage customerId={customerId} roleType={roleType} hubId={hubId} isSuperCustomer={isSuperCustomer} strongFilters = {strongFilters} allClubs={allClubs} selectedClubs={selectedClubs} availableSeasons={availableSeasons} allCustomerManagers={allCustomerManagers} selectedManagers={selectedManagers}/>
            </div>
      }
    </div>
  )
}

DirectorySetupRoot.propTypes = {
  customerId: PropTypes.string,
  hubId: PropTypes.string,
  roleType: PropTypes.string,
  renderTab: PropTypes.string,
  isSuperCustomer: PropTypes.string,
  allClubs: PropTypes.array,
  selectedClubs: PropTypes.array,
  strongFilters: PropTypes.array,
  availableSeasons: PropTypes.array,
  allCustomerManagers: PropTypes.array,
  selectedManagers: PropTypes.array,
}


export default DirectorySetupRoot
