import React, {Component} from 'react'
import { GlgRadio, GlgBasicSelect } from 'SharedComponents'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'

export class StringComponent extends Component {
  constructor(props){
    super(props)
  }
  
  render(){
    let stringComponent
    if (this.props.field.value){
      if (this.props.field.value.length <= 5 && this.props.field.name !== 'Age Bracket'){
        stringComponent = <Col xs={12} md={8}>{this.props.field.value.map((v, i) => <div key={`string_component_${i}`} className="checkbox_radio_container"><GlgRadio name={`answer[${this.props.field.id}]`} label={v} value={v} defaultChecked={this.props.answer === v} disabled={this.props.field.disabled} radioClass={'i-checks'} onChange={(val) => this.props.onChange(this.props.field.id, this.props.field.category, this.props.field.name, val)}></GlgRadio></div>)}</Col>
      } else {
        stringComponent = <Col xs={12} md={8}><GlgBasicSelect items={this.props.field.value.map(x => ({value: x, label: x}))} value={this.props.answer ? this.props.answer : ''} onChange={(val) => this.props.onChange(this.props.field.id, this.props.field.category, this.props.field.name, val)} prompt={ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.custom_fields_components.string_component.please_select') } selectClass="form-control"></GlgBasicSelect></Col>
      }
    } else {
      stringComponent = <Col xs={12} md={8}><span style={{fontWeight: 'bold'}}>{ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.custom_fields_components.string_component.without_answer') }</span></Col>
    }
    return stringComponent
  }
}

StringComponent.propTypes = {
  field: PropTypes.object.isRequired,
  answer: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default StringComponent
