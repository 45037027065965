import { connect } from 'react-redux'
import TextMessageDeliveryReportsComponent from '../../components/delivery_reports/text_message_delivery_reports_component'

import {
  setCurrentTextMessageReports,
  setTextMessageFilterValue,
} from '../../actions/delivery_reports'

const mapStateToProps = (state) => {
  return state.deliveryReports
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentTextMessageReports: (textMessageDeliveryReports, page) => { dispatch(setCurrentTextMessageReports(textMessageDeliveryReports, page)) },
  setTextMessageFilterValue: (filterType) => (filterValue) => { dispatch(setTextMessageFilterValue(filterType, filterValue)) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TextMessageDeliveryReportsComponent)
