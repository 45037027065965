import React from 'react'
import PropTypes from 'prop-types'
import rootReducer from '../reducers'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import CourseLeaderboardContainer from './course_leaderboard_container'

const CourseLeaderboardRoot = ({leagueId, roundId, roundGgid, isMultiRound, withTeams, showCourse, players, showMessage, message, score, submitUrl, refreshUrl, lastDeviceUpdate, noRowsOnCourseLeaderboard}) => {
  const state = {
    misc: {
      leagueId,
      roundId,
      roundGgid,
      isMultiRound,
      withTeams,
      showCourse,
      players,
      showMessage,
      message,
      score,
      submitUrl,
      refreshUrl,
      lastDeviceUpdate,
      noRowsOnCourseLeaderboard,
    },
  }

  const store = configureStore(rootReducer, state)

  return (
    <GlobalProvider store={ store }>
      <CourseLeaderboardContainer />
    </GlobalProvider>
  )
}

CourseLeaderboardRoot.propTypes = {
  leagueId: PropTypes.string,
  roundId: PropTypes.string,
  roundGgid: PropTypes.string,
  isMultiRound: PropTypes.bool,
  withTeams: PropTypes.bool,
  showCourse: PropTypes.bool,
  players: PropTypes.array,
  showMessage: PropTypes.bool,
  message: PropTypes.string,
  score: PropTypes.string,
  submitUrl: PropTypes.string,
  refreshUrl: PropTypes.string,
  lastDeviceUpdate: PropTypes.string,
  noRowsOnCourseLeaderboard: PropTypes.number,
}

export default CourseLeaderboardRoot
