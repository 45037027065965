import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'
import { Row } from 'react-bootstrap'

class EditColumnPropsForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: this.props.title,
      titles: this.props.titles,
      view_more: this.props.view_more,
    }
    this.setTitle = this.setTitle.bind(this)
    this.setViewMore = this.setViewMore.bind(this)
  }

  setTitle(_title) {
    this.setState({
      title: _title,
    })
  }
  
  setTitles(_title, key) {
    this.setState({
      titles: {
        ...this.state.titles,
        [key]: _title,
      },
    })
  }
  
  setViewMore() {
    this.setState({
      view_more: !this.state.view_more,
    })
  }

  render() {
    return (
      <Col xs={12} className="manage-columns">
        <Col xs={10} xsOffset={1} className="title">
          <h1>{window.I18n.t('multi_widgets.edit_column.title')}</h1>
        </Col>

        
        <Col xs={10} xsOffset={1} className="inner-content">
          <Col xs={10} xsOffset={1} className="form-row">
          {this.props.usesTranslations ? (
            <Row>
              {this.props.availableLanguages.map(key => (
                <Row key={key}>
                  <Col xs={4} className="labels">{window.I18n.t('pages.edit.portal_page_options.title_' + key)}</Col>
                  <Col xs={6} className="form-group">
                    <input placeholder={window.I18n.t('multi_widgets.edit_column.title_label')}
                          className="form-control"
                          value={ this.state.titles ? (this.state.titles[key] ? this.state.titles[key] : this.state.title) : this.state.title }
                          onChange={ e => this.setTitles(e.target.value, key) }
                          autoFocus />
                    <div className="labels link" onClick={ () => this.setTitles('', key) }>
                      {window.I18n.t('multi_widgets.edit_column.clear_title')}
                    </div>
                  </Col>
                </Row>
              ))}
            </Row>
          ) : (
            <Col xs={6} className="form-group">
              <input placeholder={window.I18n.t('multi_widgets.edit_column.title_label')}
                    className="form-control"
                    value={ this.state.title }
                    onChange={ e => this.setTitle(e.target.value) }
                    autoFocus />
              <div className="labels link" onClick={ () => this.setTitle('') }>
                {window.I18n.t('multi_widgets.edit_column.clear_title')}
              </div>
            </Col>
          )}
          </Col>
        </Col>

        <Col xs={10} xsOffset={1} className="no-padding-left">
          <Col xs={6} className="no-padding-left">
            <label className="checkbox-inline">
              <input type="checkbox"
                      onChange={ () => this.setViewMore() }
                      defaultChecked={ this.state.view_more } />
              <span dangerouslySetInnerHTML={{__html: window.I18n.t('multi_widgets.edit_column.show_view_more_link', {view_more: window.I18n.t('multi_widgets.edit_column.view_more')} )}}/>
            </label>
          </Col>
        </Col>
        
        <Col xs={10} xsOffset={1} className="button-row">
          <button className="button submit save-page save-with-external-link btn btn-primary pull-right"
                  onClick={ e => { e.preventDefault(); this.props.updateColumn(this.props.id, this.state.title, this.state.titles, this.state.view_more) } } >
                  {window.I18n.t('multi_widgets.save')}
          </button>
          <a className="button cancel grey btn btn-default pull-right margin-right-10"
             onClick={ e => { e.preventDefault(); this.props.cancel() } } >
            {window.I18n.t('multi_widgets.cancel')}
          </a>
        </Col>
      </Col>
    )
  }
}

EditColumnPropsForm.propTypes = {
  id: PropTypes.any,
  title: PropTypes.any,
  titles: PropTypes.any,
  view_more: PropTypes.any,
  updateColumn: PropTypes.func.isRequired,
  cancel: PropTypes.func,
  usesTranslations: PropTypes.bool,
  availableLanguages: PropTypes.any,
}

export default EditColumnPropsForm
