import { ActionTypes, DISPLAY_MODES } from '../actions'

const {
  SET_ACTIVE_TAB,
  DISPLAY_MODE,
  SET_ACTIVE_COLUMN,
  SET_ACTIVE_BLOCK,
  SET_ACTIVE_POSITION,
  SET_IFRAME_POINTER_OPTIONS,
  SET_DRAGGABLE_ELEMENT,
  SET_IFRAME_FORM_STATE,
  SET_DRAGGED_OVER_ELEMENT,
  SET_DRAGGED_OVER_POSITION,
  ADD_NEW_WIDGET,
} = ActionTypes

const filters = (state = {}, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB: {
      return {
        ...state,
        active_tab: action.id,
      }
    }
    case DISPLAY_MODE: {
      if (action.display_mode === DISPLAY_MODES.DEFAULT) {
        $('#popup-element').modal('hide')
      } else {
        $('#popup-element').modal({ show: true, backdrop: 'static', keyboard: false })
      }
      return {
        ...state,
        display_mode: action.display_mode,
      }
    }
    case SET_ACTIVE_COLUMN: {
      return {
        ...state,
        active_column: action.column_id,
      }
    }
    case SET_ACTIVE_BLOCK: {
      return {
        ...state,
        active_block: action.block_id,
      }
    }
    case SET_ACTIVE_POSITION: {
      return {
        ...state,
        position: action.position,
      }
    }
    case SET_IFRAME_POINTER_OPTIONS: {
      return {
        ...state,
        iframe_pointer_options: action.option,
      }
    }
    case SET_DRAGGABLE_ELEMENT: {
      return {
        ...state,
        draggable_element: action.element,
      }
    }
    case SET_DRAGGED_OVER_ELEMENT: {
      return {
        ...state,
        dragged_over_element: action.element,
      }
    }
    case SET_DRAGGED_OVER_POSITION: {
      return {
        ...state,
        dragged_over_position: action.position,
      }
    }
    case SET_IFRAME_FORM_STATE: {
      return {
        ...state,
        form_submitted: action.status,
      }
    }
    case ADD_NEW_WIDGET: {
      if (state.widgets.filter(section => section.title === action.widget.title).lenght > 0) {
        const newWidgets = state.widgets.map(section =>
          (section.title === action.widget.title)
            ? { ...section, pages: [ ...section.pages, action.widget.page ] }
            : section )

        return {
          ...state,
          widgets: newWidgets,
        }
      } else {
        return {
          ...state,
          widgets: [
            ...state.widgets,
            { title: action.widget.title, pages: [ action.widget.page ] },
          ],
        }
      }
    }
    default:
      return state
  }
}

export default filters
