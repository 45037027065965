import { connect } from 'react-redux'
import VerificationTeamPopupComponent from '../components/verification_team_popup_component'

import { closePopup, verifyTeamScore } from '../actions'

const mapStateToProps = (state) => {
  return {
    players: state.teamPopup.players,
    teamName: state.teamPopup.teamName,
    show: state.teamPopup.visible,
    tableTitle: state.misc.roundName + ' - ' + state.misc.courseName + ' - ' + state.teamPopup.teeTime,
    isMultiRound: state.misc.isMultiRound,
    autoFillScore: state.misc.autoFillScore,
    showShots: state.misc.step === 'volunteer_step' && state.misc.shotTrackingEnabled,
    step: state.misc.step,
    scoresData: state.teamPopup.scoresData,
    tournamentType: state.misc.tournamentType,
    useTeamTotals: state.misc.useTeamTotals,
    teamTotals: state.teamPopup.teamTotals,
    isStableford: state.misc.isStableford,
    stablefordPoints: state.teamPopup.stablefordPoints,
  }
}

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => dispatch(closePopup()),
  verifyScore: (scores) => dispatch(verifyTeamScore(scores)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificationTeamPopupComponent)
