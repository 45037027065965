import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

const RegistrationLeagueComponent = ({ leagueName, url }) => <Row><Col xs={true}>{url && <Fragment>Master Portal: <a href={ url }>{ leagueName }</a></Fragment>}</Col></Row>

RegistrationLeagueComponent.propTypes = {
  leagueName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default RegistrationLeagueComponent
