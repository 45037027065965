import React, { Component } from 'react'
import PropTypes from 'prop-types'

class GlgTab extends Component{
  
  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
  }

  onClick() {
    const { label, onClick } = this.props
    onClick(label)
  }

  onMouseEnter() {
    const { label, onMouseEnter } = this.props
    onMouseEnter(label)
  }

  onMouseLeave() {
    const { label, onMouseLeave } = this.props
    onMouseLeave(label)
  }

  render() {
    const {
      onClick,
      onMouseEnter,
      onMouseLeave,
      props: {
        activeTab,
        label,
        hoveredOver,
      },
    } = this

    let className = 'tab-list-item'
    if (activeTab === label) {
      className += ' tab-list-active'
    }
    if (hoveredOver) {
      className += ' tab-list-hover'
    }

    return <li className={`ui-corner-top ${className}`} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>{label}</li>

  }

}

GlgTab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hoveredOver: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
}

export default GlgTab
