import { ActionTypes } from '../actions'

const {
  OPEN_POPUP,
  CLOSE_POPUP,
  FETCH_POPUP_STATISTIC_DATA,
  FETCH_POPUP_STATISTIC_DATA_STARTED,
  FETCH_STATISTIC_DATA_FAILED,
} = ActionTypes

const initialState = {
  visible: false,
}

const popup = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_POPUP:
      return {
        ...state,
        statistic: action.statistic,
        selectedRound: action.selectedRound,
        visible: true,
      }
    case CLOSE_POPUP:
      return {
        ...state,
        statistic: undefined,
        selectedRound: undefined,
        visible: false,
      }
    case FETCH_POPUP_STATISTIC_DATA_STARTED:
      return {
        ...state,
        statistic: {
          ...state.statistic,
          content: undefined,
        },
        selectedRound: action.selectedRound,
      }
    case FETCH_POPUP_STATISTIC_DATA:
      return {
        ...state,
        statistic: {
          ...state.statistic,
          content: action.data.players,
        },
      }
    case FETCH_STATISTIC_DATA_FAILED:
      return {
        ...state,
        statistic: {
          ...state.statistic,
          content: state.content || [],
        },
      }
    default:
      return state
  }
}

export default popup
