import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { showNotification, showErrorNotification } from 'Shared/helpers'
import EventSetupFlights from '../../components/content/event_setup_flights'
import {
  saveEventSettingsWidget,
  setGenericData,
  pollJob,
  reloadWidgetContent,
  pollUpdateNotifyMessages,
} from '../../actions/index'

const asHTML = v => <div dangerouslySetInnerHTML={{ __html: v }} />

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => ({
  setData: (keys, value) => {
    dispatch(setGenericData(ownProps.widgetId, keys, value))
    if (! ownProps.content.dataChanged) {
      dispatch(setGenericData(ownProps.widgetId, [ 'dataChanged' ], true))
    }
  },
  setError: (key, val) => {
    dispatch(setGenericData(ownProps.widgetId, [ 'errors', key ], val))
  },
  save: () => {
    const content = ownProps.content
    const links = content.links

    const autoFill = content.auto_fill_by
    const groupType = content.flights_number_of

    const requestData = {
      division_id: content.divisions.selected,
      create_by: content.create_flights_by.selected,
      flights: content.flights || [],
      with_tees: !!content.tees,

      custom_field_id: content.division_custom_fields[content.divisions.selected].selected,

      auto_fill_by: autoFill.selected,
      auto_fill_options: (autoFill.selected !== 'age_gender' ?
        { // by handicap index
          group_type: (groupType.selected === 'golfers' ? 'components' : 'flights') + '_number',
          [`${groupType.selected === 'golfers' ? 'components' : 'flights'}_number`]: groupType.choices[groupType.selected].value,
        }
        :
        { // by age / gender
          anchor_date: moment.unix(content.date_for_age).format('Y MM DD'),
          age_gender_split_type: content.divide_by.selected,
        }
      ),
    }

    dispatch(saveEventSettingsWidget(ownProps.widgetId, links.save.path, requestData, { withNotifications: false }))
      .then(({ response }) => {
        response.message && showNotification(asHTML(response.message))
        response.errors && showErrorNotification(asHTML(response.errors))
        dispatch(ownProps.refresh)

        if ('job_id' in response) {
          pollJob(response.job_id, update => pollUpdateNotifyMessages(update, asHTML))
            .catch((update) => {
              const message = update?.value?.errors?.join('\n') || (!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.save_settings_error'))
              showErrorNotification(asHTML(message))
            })
            .finally(() => { 
              dispatch(ownProps.refresh) 
              dispatch(reloadWidgetContent('event_setup_divisions'))
            })
        }
      })
  },
})

export default connect(
  null,
  mapDispatchToProps,
)(EventSetupFlights)
