import React, { Component } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import { onConfirm } from 'Shared/helpers'
import ReportCenterList from './report_center_list'
import ReportCenterCreateUpdateList from './report_center_create_update_list'
import ReportCenterNewItem from './report_center_item_new'
import PrintModalContainer from './print_modal_container'
import Preloader from 'medium-loader.gif'


class DownloadCenter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dcp_specs: props.dcp_specs,
      report_center_lists: props.report_center_lists,
      search_term: '',
      league_id: props.league_id,
      round_id: props.round_id,
      layout: 'inline',
      modal_parameters: null,
      delay_timer: null,
      current_tour_id: '',
    }

    this.toggleFavourite = this.toggleFavourite.bind(this)
    this.addRemoveItemToList = this.addRemoveItemToList.bind(this)
    this.cloneItem = this.cloneItem.bind(this)
    this.importItem = this.importItem.bind(this)
    this.removeList = this.removeList.bind(this)
    this.createList = this.createList.bind(this)
    this.editList = this.editList.bind(this)
    this.updateItemStateAttribute = this.updateItemStateAttribute.bind(this)
    this.openPrintModal = this.openPrintModal.bind(this)
    this.removeAllItemInstances = this.removeAllItemInstances.bind(this)
    this.setFilterTerm = this.setFilterTerm.bind(this)
    this.toggleArchiveItem = this.toggleArchiveItem.bind(this)
  }

  componentDidMount() {
    window.redo_qtip()
  }

  toggleFavourite(listId, value) {
    $.ajax('/api/report_center/report_center_lists/' + listId, {
      method: 'PATCH',
      data: { report_center_list: { leagues_report_center_list: { league_id: this.state.league_id, favourite: value }}, league_id: this.state.league_id},
      success: () => {
        const rclIndex = this.state.report_center_lists.findIndex((rcl) => { return rcl.id === listId })

        this.setState(update(this.state, {
          report_center_lists: {
            [rclIndex]: {
              favourite: { $set: value },
            },
          },
        }))
      },
    })
  }

  toggleItemsLayout(layout) {
    const changeState = () => {
      this.setState(update(this.state, {
        layout: { $set: layout },
      }))
    }
    if ($('tr.in_progress, div.in_progress').length === 0) {
      changeState()
    } else {
      onConfirm(!window.I18n ? '' : window.I18n.t('download_center.reports_being_generated'), () => {
        changeState()
      })
    }
  }

  updateItemStateAttribute(rciId, key, value) {
    const map = { report_center_lists: {} }
    this.state.report_center_lists.forEach((list, i) => {
      const j = list.report_center_items.findIndex(rci => (rci.id === rciId))
      if (j !== -1) {
        map.report_center_lists[i] = { report_center_items: { [j]: { [key]: { $set: value} }}}
      }
    })
    this.setState(update(this.state, map), () => { this.filterResults(this.state.search_term) })
  }

  addRemoveItemToList(rciId, oldListId, newListId, op) {
    const oldRcl = this.state.report_center_lists.filter((rcl) => { return rcl.id === oldListId })[0]
    const newRclIndex = this.state.report_center_lists.findIndex((rcl) => { return rcl.id === newListId })

    if (op.target.checked) {
      const rci = oldRcl.report_center_items.filter((rci) => { return rci.id === rciId })[0]

      $.ajax('/api/report_center/report_center_items_lists', {
        method: 'POST',
        data: { report_center_items_lists: { report_center_list_id: newListId, report_center_item_id: rciId}, rci_print_id: rciId, league_id: this.state.league_id },
        success: (data) => {
          this.setState(
            update(
              this.state,
              {
                report_center_lists: {
                  [newRclIndex]: {
                    report_center_items: { $push: [ rci ] },
                  },
                },
              }),
            () => { this.updateItemStateAttribute(rciId, 'list_count', data['list_count']) }
          )
        },
        error: () => { console.error('unable to add report center item to list') },
      })
    } else {
      const newRcl = this.state.report_center_lists.filter((rcl) => { return rcl.id === newListId })[0]
      const rciIndex = newRcl.report_center_items.findIndex((rci) => { return rci.id === rciId })

      $.ajax('/api/report_center/report_center_items_lists/0', {
        method: 'DELETE',
        data: { report_center_items_lists: { report_center_list_id: newListId, report_center_item_id: rciId }, rci_print_id: rciId, league_id: this.state.league_id, customer_id: this.props.customer_id},
        success: (data) => {
          if (data.success){
            this.setState(
              update(
                this.state,
                {
                  report_center_lists: {
                    [newRclIndex]: {
                      report_center_items: { $splice: [ [ rciIndex, 1 ] ] },
                    },
                  },
                }
              ),
              () => { this.updateItemStateAttribute(rciId, 'list_count', data['list_count']) }
            )
          }
        },
        error: () => { console.error('unable to add report center item to list') },
      })
    }
  }

  sortPosition(a, b) {
    return a.position - b.position
  }

  setFilterTerm(event) {
    const term = event.target.value.toLowerCase()
    if (term === '') {
      this.filterResults(term, null)
      return
    }

    clearTimeout(this.state.delay_timer)
    const timer = window.setTimeout(() => {
      const rclIndex = this.state.report_center_lists.findIndex((rcl) => { return rcl.id === '-2' })
      if (this.state.round_id !== null && this.state.search_term !== '') {
        fetch(`/leagues/${this.state.league_id}/rounds/${this.state.round_id}/load_community_reports?search_term=${term}`)
          .then(response => response.json())
          .then(data => {
            this.setState(
              update(this.state, {
                report_center_lists: {
                  [rclIndex]: { $set: { ...data, display: (this.state.search_term !== '' && data.report_center_items.length !== 0) } },
                },
              })
            )
            this.filterResults(term, null)
          })
      }
      if (!this.state.round_id) {
        this.filterResults(term, null)
      }
    }, 1000)

    this.setState(update(this.state, {
      delay_timer: { $set: timer },
      search_term: { $set: term },
    }))
  }

  filterResults(term, timer = null, tourId = this.state.current_tour_id) {
    const displayHash = { search_term: { $set: term }, report_center_lists: {}, delay_timer: { $set: timer }, current_tour_id: { $set: tourId } }

    this.state.report_center_lists.forEach((list, i) => {
      displayHash.report_center_lists[i] = {
        display: { $set: (term === '') },
        report_center_items: {},
      }

      list.report_center_items.forEach((item, j) => {
        if ((item.tour_id === tourId || (list.id === '-2')) &&
            (item.name.toLowerCase().indexOf(term) !== -1 || 
            (item.tags !== undefined && item.tags.toLowerCase().indexOf(term) !== -1))){
          displayHash.report_center_lists[i].report_center_items[j] = { display: { $set: true }}
          displayHash.report_center_lists[i].display = { $set: true }
        } else {
          displayHash.report_center_lists[i].report_center_items[j] = { display: { $set: false }}
        }
      })

      // Display the Community Reports list as soon as the search begins to let users know something is happening
      if (list.id === '-2') {
        displayHash.report_center_lists[i].display = { $set: (term !== '') }
      }
    })

    this.setState(update(this.state, { ...displayHash }))
  }

  cloneItem(rci, listId) {
    let rclIndex

    $.ajax('/api/report_center/report_center_items/' + rci.id + '/clone', {
      method: 'PATCH',
      data: { report_center_list_id: listId, league_id: this.state.league_id, round_id: this.state.round_id },
      success: (data) => {
        const newRci = data['rci']

        if (data['new_rcl_id']){
          rclIndex = this.state.report_center_lists.findIndex((rcl) => { return rcl.id === data['new_rcl_id'] })
        } else { 
          rclIndex = this.state.report_center_lists.findIndex((rcl) => { return rcl.id === listId })
        }

        const rcis = this.state.report_center_lists[rclIndex].report_center_items
                          .filter(rci => rci.display &&
                                  rci.tour_id === this.state.current_tour_id &&
                                  (this.state.search_term === '' || this.state.search_term !== '' && rci.name.toLowerCase().indexOf(this.state.search_term) !== -1))
        const rciIndex = rcis.findIndex((rciCurrent) => { return rciCurrent === rci }) + 1

        this.setState(update(this.state, {
          report_center_lists: {
            [rclIndex]: {
              report_center_items: { $set: [ ...rcis.slice(0, rciIndex), newRci, ...rcis.slice(rciIndex) ] },
            },
          },
        }), () => { this.filterResults(this.state.search_term) })

        if (data['new_rcl_id']){
          window.show_flash(window.I18n.t('rounds.reports_center.template.template_cloned'))
        }
      },
      error: () => { console.error('unable to clone report center item to list') },
    })
  }

  importItem(newRci, newRclId) {
    const newRclIndex = this.state.report_center_lists.findIndex((rcl) => { return rcl.id === newRclId })

    this.setState(update(this.state, {
      report_center_lists: {
        [newRclIndex]: {
          report_center_items: { $push: [ newRci ] },
        },
      },
    }), () => { this.filterResults(this.state.search_term) })
  }

  removeAllItemInstances(rciId) {
    const updatedReportCenterLists = this.state.report_center_lists.map(list => {
      const updatedList = { ...list }
      updatedList.report_center_items = list.report_center_items.filter(item => item.id !== rciId)

      return updatedList
    })

    this.setState(update(this.state, {
      report_center_lists: { $set: updatedReportCenterLists},
    }), () => { this.filterResults(this.state.search_term) })
  }

  removeList(rclId) {
    const rclIndex = this.state.report_center_lists.findIndex((rcl) => { return rcl.id === rclId })
    $.ajax('/api/report_center/report_center_lists/' + rclId, {
      method: 'DELETE',
      data: { league_id: this.state.league_id },
      success: () => {
        this.setState(update(this.state, {
          report_center_lists: { $splice: [ [ rclIndex, 1 ] ] },
        }))
        $('#create_list').modal('hide')
      },
      error: () => { console.error('unable to remove new report center list') },
    })
  }

  createList(name, ref) {
    $.ajax('/api/report_center/report_center_lists', {
      method: 'POST',
      data: { name: name, league_id: this.state.league_id, round_id: this.state.round_id },
      success: (data) => {
        const newRcl = data['report_center_list']
        this.setState(update(this.state, {
          report_center_lists: { $push: [ newRcl ] },
        }), () => { this.filterResults(this.state.search_term) } )
        $(ref).modal('hide')
      },
      error: () => { console.error('unable to create new report center list to list') },
    })
  }

  editList(rclId, newName, ref) {
    const rclIndex = this.state.report_center_lists.findIndex((rcl) => { return rcl.id === rclId })

    $.ajax('/api/report_center/report_center_lists/' + rclId, {
      method: 'PATCH',
      data: { report_center_list: { name: newName }, league_id: this.state.league_id },
      success: () => {
        this.setState(update(this.state, {
          report_center_lists: { [rclIndex]: { name: { $set: newName } }},
        }))
        $(ref).modal('hide')
      },
      error: () => { console.error('unable to create edit report center list name') },
    })
  }

  openPrintModal(opts) {
    this.setState({ modal_parameters: opts })
  }

  updateListPositions(listOrder, listId, newListPosition) {
    const data = { report_center_lists: { list_order: listOrder, league_id: this.state.league_id }}
    $.ajax( '/api/report_center/leagues_report_center_lists/set_positions', {
      method: 'PATCH',
      data: data,
      success: () => {
        const rclIndex = this.state.report_center_lists.findIndex((rcl) => { return rcl.id === listId })

        this.setState(update(this.state, {
          report_center_lists: {
            [rclIndex]: {
              position: { $set: newListPosition },
            },
          },
        }))
      },
    })
  }

  getLists(rcls) {
    return rcls.reduce((rv, x) => {
      (rv[x['favourite']] = rv[x['favourite']] || []).push(x)
      return rv
    }, {})
  }

  setFilterTour(tourId) {
    this.filterResults(this.state.search_term, null, tourId)
  }

  toggleArchiveItemInstances(rci, newListId) {
    const newRclIndex = this.state.report_center_lists.findIndex(rcl => rcl.id === newListId)

    this.removeAllItemInstances(rci.id)
    this.setState(update(this.state, {
      report_center_lists: {
        [newRclIndex]: {
          report_center_items: { $push: [ rci ] },
        },
      },
    }), () => { this.filterResults(this.state.search_term); this.updateItemStateAttribute(rci.id, 'list_count', 1) })
  }
  
  toggleArchiveItem(rci, action) {
    let newListId
    let message

    if (action === 'unarchive') {
      message = !window.I18n ? '' : window.I18n.t('download_center.unarchive_question')
      if (this.props.round_id) {
        newListId = this.state.report_center_lists.find(rcl => rcl.name.toLowerCase() === 'this event' || rcl.name.toLowerCase() === 'this league').id
      } else {
        newListId = this.state.report_center_lists.find(rcl => rcl.name.toLowerCase() === 'spreadsheet composer').id
      }
    } else {
      message = !window.I18n ? '' : window.I18n.t('download_center.archive_question')
      newListId = this.state.report_center_lists.find(rcl => rcl.archive_list).id
    }

    onConfirm(message, () => {
      $.ajax('/api/report_center/report_center_items/' + rci.id + '/toggle_archive', {
        method: 'POST',
        data: { new_list_id: newListId, league_id: this.state.league_id },
        success: function () {
          this.toggleArchiveItemInstances(rci, newListId)
        }.bind(this),
      })
    })
  }

  render() {
    let discoverReportsPath
    if (this.state.round_id !== null) {
      discoverReportsPath = '/leagues/' + this.state.league_id + '/rounds/' + this.state.round_id + '/community_reports'
    } else {
      discoverReportsPath = '/leagues/' + this.state.league_id + '/community_reports'
    }

    const loadingSpinner = this.state.delay_timer && this.state.search_term !== ''
    const tourOptions = this.props.tours.map((tour) => (
      <option key={tour.id} value={tour.id || ''}>
        {tour.name}
      </option>
    ))

    return (
      <div className='row'>
        <div className='download-center-header add-padding-bottom-50'>
        { this.props.is_team_captain ?
          <div className='col col-sm-6'> 
          </div>
        :
          <div className='col col-sm-6'>
            {
              this.props.enable_discover &&
                <a href={ discoverReportsPath }>
                  <div className='btsp_bullet_button highlighted no-lite'>
                    <div className='icon'><i className='gga-circle-compass'></i></div>
                    <div className='btsp_align-middle'>{!window.I18n ? '' : window.I18n.t('download_center.download_center.community_reports')}</div>
                  </div>
                </a>
            }
            {
              this.props.round_id &&
                <a href={ '/rounds/' + this.props.round_id + '/report_headers' }>
                  <div className='btsp_bullet_button no-lite'>
                    <div className='icon'><i className='gga-report-headers'></i></div>
                    <div className='btsp_align-middle'>{!window.I18n ? '' : window.I18n.t('report_headers.index.headers')}</div>
                  </div>
                </a>
            }
            <a href="#" id='open_new_list' data-toggle='modal' data-target='#create_edit_list_undefined'>
              <div className='btsp_bullet_button no-lite'>
                <div className='icon'><i className='gga-new-list'></i></div>
                <div className='btsp_align-middle'>{!window.I18n ? '' : window.I18n.t('download_center.download_center.new_category')}</div>
              </div>
            </a>
            {
              this.props.round_id === null ?
                <a href={ '/leagues/' + this.props.league_id + '/spreadsheet/reports/new' }>
                  <div className='btsp_bullet_button'>
                    <div className='icon'><i className='gga-file-plus'></i></div>
                    <div className='btsp_align-middle'>{!window.I18n ? '' : window.I18n.t('download_center.download_center.new_spreadsheet')}</div>
                  </div>
                </a>
              :
                <a href='#'>
                  <div className='btsp_bullet_button no-lite'>
                    <div className='icon'><i className='gga-file-plus'></i></div>
                    <div className='btsp_align-middle'
                         data-toggle='modal'
                         data-target='.create_item_wrapper'>{!window.I18n ? '' : window.I18n.t('download_center.download_center.new_document')}</div>
                  </div>
                </a>
            }
            {
              this.props.can_set_permissions && 
              !this.props.round_id && 
                <a href={ '/leagues/' + this.props.league_id + '/report_permissions' }>
                  <div className='btsp_bullet_button no-lite'>
                    <div className='icon'><i className='gga-event-administration'></i></div>
                    <div className='btsp_align-middle'>{!window.I18n ? '' : window.I18n.t('download_center.download_center.permissions')}</div>
                  </div>
                </a>
            }
          </div>
          }
          <div className='col col-xs-9 col-sm-4'>
            <div className='input-group'>
              <input className='form-control search_term' value={this.state.search_term} onChange={this.setFilterTerm} placeholder={!window.I18n ? '' : window.I18n.t('download_center.download_center.search_for')}></input>
              <span className='input-group-btn'>
                <div className='btn btn-default' type='button'>
                  <a onClick={ () => { this.filterResults('') } } >
                    <i className='fa fa-search'></i>
                  </a>
                </div>
              </span>
            </div>
            {
              this.props.tour_filter_access &&
              <div className='input-group add-margin-top-10 add-margin-bottom-10 dc-selector-container'>
              <span className='dc-label'>{!window.I18n ? '' : window.I18n.t('download_center.download_center.tours')}</span>
              <select className='form-control' onChange={(event) => this.setFilterTour(event.target.value)}>
                {tourOptions}
              </select>
            </div>
            }
          </div>
          <div className='col col-sm-2'>
            <a href='javascript: void(0)' onClick={() => this.toggleItemsLayout('inline')} className={ this.state.layout === 'inline' ? 'active' : 'inactive'} >
              <i className='fa fa-th-large fa-fw'></i>
            </a>
            <a href='javascript: void(0)' onClick={() => this.toggleItemsLayout('block')} className={ this.state.layout === 'block' ? 'active' : 'inactive'} >
              <i className='fa fa-bars fa-fw'></i>
            </a>
          </div>
        </div>
        { (loadingSpinner) ? 
          <div className='col-sm-12'>
            <div className='spinner-container'>
              <img src={ Preloader } alt={!window.I18n ? '' : window.I18n.t('download_center.report_center_list.loading')} className="report-list-preloader" />
              <br/><p>{!window.I18n ? '' : window.I18n.t('download_center.report_center_list.searching')}</p>
            </div>
          </div>
            :
          <div className='download-center-outter-body col-sm-12'>
            <div className='row' style={ { height: '100%'} }>
              <div className='download-center-body-wrapper col-sm-12'>
                {
                  [ 'true', 'separator', 'false' ].map((value) => {
                    const lists = this.getLists(this.state.report_center_lists)
                    const list = lists[value] || []
                    //decide what to print for the separator
                    if (value === 'separator') {
                      if (Object.keys(lists).length === 1){
                        return (<div key="separator"></div>)
                      } else if (Object.keys(lists).length === 0){
                        return (<div key="separator" style={ { textAlign: 'center' }}>{!window.I18n ? '' : window.I18n.t('download_center.download_center.no_results')}</div>)
                      }
                    }
                    return (
                      <div className={ 'favourite-' + value } key={ 'favourite-' + value }>
                        {
                          list.sort(this.sortPosition).map((reportCenterList, index) => {
                            return (
                              <ReportCenterList key={reportCenterList.id}
                                                data_id={reportCenterList.id}
                                                report_center_list={reportCenterList}
                                                report_center_lists={this.state.report_center_lists}
                                                layout={this.state.layout}
                                                toggleFavourite={this.toggleFavourite}
                                                search_term={this.state.search_term}
                                                delay_timer={this.state.delay_timer}
                                                addRemoveItemToList={this.addRemoveItemToList}
                                                cloneItem={this.cloneItem}
                                                importItem={this.importItem}
                                                removeList={this.removeList}
                                                createList={this.createList}
                                                editList={this.editList}
                                                index={index}
                                                league_id={this.props.league_id}
                                                updateItemStateAttribute={ this.updateItemStateAttribute }
                                                openPrintModal={this.openPrintModal}
                                                removeAllItemInstances={this.removeAllItemInstances} 
                                                isTeamCaptain={this.props.is_team_captain}
                                                discoverReportsPath={discoverReportsPath}
                                                dcpSpecs={this.state.dcp_specs}
                                                current_tour_id={this.state.current_tour_id}
                                                toggleArchiveItem={this.toggleArchiveItem}/>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        }
        <ReportCenterCreateUpdateList list={ {} }
                                      createList={this.createList}
                                      editList={this.editList} />
        <ReportCenterNewItem league_id={this.state.league_id} round_id={this.state.round_id} />
        <PrintModalContainer modal_parameters={ this.state.modal_parameters }
                             openPrintModal = {this.openPrintModal } />
        <img style={ { height: '0px' }} src={ require('report_center/no-image.png') } />
      </div>
    )
  }
}

DownloadCenter.propTypes = {
  dcp_specs: PropTypes.array,
  customer_id: PropTypes.number.isRequired,
  report_center_lists: PropTypes.array.isRequired,
  league_id: PropTypes.string.isRequired,
  round_id: PropTypes.string,
  enable_discover: PropTypes.bool.isRequired,
  is_team_captain: PropTypes.bool.isRequired,
  can_set_permissions: PropTypes.bool.isRequired,
  tours: PropTypes.array.isRequired,
  tour_filter_access: PropTypes.bool.isRequired,
}

export default DownloadCenter
