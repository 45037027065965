import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Image } from 'react-bootstrap'

export class MembersLeftSidebarComponent extends Component {
  constructor(props) {
    super(props)
    this.toggleFieldsSidebar = this.toggleFieldsSidebar.bind(this)
    this.toggleHandleFormatSidebar = this.toggleHandleFormatSidebar.bind(this)
    this.toggleUploadSpreadsheetSidebar = this.toggleUploadSpreadsheetSidebar.bind(this)
  }
  
  componentDidMount() {
    window.glg.member.showImportWarning(this.props.newLeagueImportPath)
  }
  
  toggleFieldsSidebar(){
    if (!this.props.isOpen.fields){
      this.props.openFieldsSidebar()
      this.props.fetchFieldsData()
    } else {
      this.props.closeFieldsSidebar()
    }
  }
  
  toggleHandleFormatSidebar(){
    if (!this.props.isOpen.handleFormat) {
      this.props.openHandleFormatSidebar()
    } else {
      this.props.closeHandleFormatSidebar()
    }
  }
  
  toggleUploadSpreadsheetSidebar(){
    if (!this.props.isOpen.uploadSpreadsheet) {
      this.props.openUploadSpreadsheetSidebar()
    } else {
      this.props.closeUploadSpreadsheetSidebar()
    }
  }
  
  render(){
    return <div style={{height: '100%'}}><ul className="list_of_actions"><li className={'open_in_sidebar ' + (this.props.isOpen.fields ? '' : 'closed ')} id="fields_options"><a href="#" onClick={this.toggleFieldsSidebar} className={this.props.isOpen.fields ? 'active' : ''}><div className="padding_top_8"><Image src={this.props.imageUrls.fields} async="on"></Image></div><p>{!window.I18n ? '' : window.I18n.t('members_page.components.members_left_side_component.fields')}</p></a></li><li className={'open_in_sidebar no-lite ' + (this.props.isOpen.handleFormat ? '' : 'closed ')} id="name_format_options"><a href="#" onClick={this.toggleHandleFormatSidebar} className={this.props.isOpen.handleFormat ? 'active' : ''}><Image src={this.props.imageUrls.name_format}></Image><p dangerouslySetInnerHTML={{__html: !window.I18n ? '' : window.I18n.t('members_page.components.members_left_side_component.handle_format')}}></p></a></li>{!this.props.isCustomerRosterLeague && this.props.canManageLeague && <li className="closed" id="import_golfers_options"><a href={this.props.isDailySync ? '#' : this.props.newLeagueImportPath} className={this.props.isDailySync ? 'display-warning' : ''}><div className={this.props.customerIsGolfAdvisor ? '' : 'padding_top_8'}><Image src={this.props.imageUrls.import_golfers} async="on"></Image></div><p dangerouslySetInnerHTML={{__html: !window.I18n ? '' : (this.props.customerIsGolfAdvisor ? window.I18n.t('members_page.components.members_left_side_component.import_golfers_from_ghin') : window.I18n.t('members_page.components.members_left_side_component.import_golfers'))}}></p></a></li>
          }<li className={(this.props.showAddGolfersManuallyPopup ? '' : 'closed ')} id="add_golfers_options"><a href="#" onClick={ this.props.openAddGolfersManuallyPopup } className={this.props.showAddGolfersManuallyPopup ? '' : 'active '}><Image src={this.props.imageUrls.add_golfers} async="on"></Image><p dangerouslySetInnerHTML={{__html: !window.I18n ? '' : window.I18n.t('members_page.components.members_left_side_component.add_golfers_manually')}}></p></a></li>{!(this.props.isTourManager && this.props.isCustomerRosterLeague) && <li className={'open_in_sidebar ' + (this.props.isOpen.uploadSpreadsheet ? '' : 'closed ')} id="upload_roster_options"><a href="#" onClick={ this.toggleUploadSpreadsheetSidebar } className={this.props.isOpen.uploadSpreadsheet ? 'active' : ''}><Image src={this.props.imageUrls.upload_roster} async="on"></Image><p dangerouslySetInnerHTML={{__html: !window.I18n ? '' : window.I18n.t('members_page.components.members_left_side_component.upload_spreadsheet')}}></p></a></li>
          }{!(this.props.isTourManager && this.props.isCustomerRosterLeague) && <li className="closed" id="download_spreadsheet_options"><a href={this.props.downloadLeagueSpreadsheetfilesPath}><Image src={this.props.imageUrls.download_spreadsheet} async="on"></Image><p dangerouslySetInnerHTML={{__html: !window.I18n ? '' : window.I18n.t('members_page.components.members_left_side_component.download_spreadsheet')}}></p></a></li>
          }  <li className="closed" id="print_roster_options"><a href={this.props.schedulePrintLeagueMembersPath}><div className="padding_top_8"><Image src={this.props.imageUrls.print_roster} async="on"></Image></div><p dangerouslySetInnerHTML={{__html: !window.I18n ? '' : window.I18n.t('members_page.components.members_left_side_component.roster_report')}}></p></a></li><li className="closed" id="bulk_upload_photos"><a href={this.props.bulkUploadPhotosPath}><div className="padding_top_8"><Image src={this.props.imageUrls.upload_photo} async="on"></Image><p dangerouslySetInnerHTML={{__html: !window.I18n ? '' : window.I18n.t('members_page.components.members_left_side_component.upload_photos')}}></p></div></a></li></ul></div>
  }
}

MembersLeftSidebarComponent.propTypes = {
  isOpen: PropTypes.object.isRequired,
  isCustomerRosterLeague: PropTypes.bool.isRequired,
  canManageLeague: PropTypes.bool.isRequired,
  isDailySync: PropTypes.bool.isRequired,
  customerIsGolfAdvisor: PropTypes.bool.isRequired,
  isTourManager: PropTypes.bool.isRequired,
  newLeagueImportPath: PropTypes.string.isRequired,
  downloadLeagueSpreadsheetfilesPath: PropTypes.string.isRequired,
  schedulePrintLeagueMembersPath: PropTypes.string.isRequired,
  bulkUploadPhotosPath: PropTypes.string.isRequired,
  openFieldsSidebar: PropTypes.func.isRequired,
  closeFieldsSidebar: PropTypes.func.isRequired,
  fetchFieldsData: PropTypes.func.isRequired,
  isMembersDataLoading: PropTypes.bool.isRequired,
  imageUrls: PropTypes.object.isRequired,
  openHandleFormatSidebar: PropTypes.func.isRequired,
  closeHandleFormatSidebar: PropTypes.func.isRequired,
  showAddGolfersManuallyPopup: PropTypes.bool.isRequired,
  openAddGolfersManuallyPopup: PropTypes.func.isRequired,
  openUploadSpreadsheetSidebar: PropTypes.func.isRequired,
  closeUploadSpreadsheetSidebar: PropTypes.func.isRequired,
}

export default MembersLeftSidebarComponent
