import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from 'react-bootstrap'

import GlgTooltipIcon from './glg_tooltip_icon'

class GlgRadio extends Component {
  constructor(props) {
    super(props)
    this.toggleChecked = this.toggleChecked.bind(this)
  }

  // In case it's used as a controlled component and the value changes
  componentDidUpdate(prevProps) {
    if (prevProps.checked !== this.props.checked) {
      this.setState({
        checked: this.props.checked,
      })
    }
  }

  toggleChecked() {
    if (!this.props.disabled) {
      if (!this.inputRef.checked) {
        this.inputRef.click()
      }
      if (this.props.onChange) {
        this.props.onChange(this.props.value)
      }
    }
  }

  getInlineClass() {
    return this.props.inline ? 'radio-inline' : ''
  }

  render() {
    return <div className={ `glg-radio radio btsp_radio radio-primary ${ this.getInlineClass() }` } onClick={ this.toggleChecked }><FormControl type="radio" name={ this.props.name } defaultChecked={ this.props.defaultChecked } checked={ this.props.checked } value={ this.props.value } disabled={ this.props.disabled } inputRef={ ref => { this.inputRef = ref } } className={this.props.radioClass}></FormControl><label className={this.props.checked || this.props.defaultChecked ? 'checked' : ''}><span>{ this.props.label }{this.props.tooltip && <GlgTooltipIcon tooltip={ this.props.tooltip }></GlgTooltipIcon>}</span></label></div>
  }
}

GlgRadio.propTypes = {
  // The input name
  name: PropTypes.string.isRequired,
  // The controlled value
  checked: PropTypes.bool,
  // The initial value
  defaultChecked: PropTypes.bool,
  // If disabled is true the checkbox is disabled
  disabled: PropTypes.bool,
  // The radio button value
  value: PropTypes.string,
  // Text on the right of the radio button
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  // Callback for when the value changes
  onChange: PropTypes.func,
  // If tooltip is passed there will be an icon with this text
  tooltip: PropTypes.string,
  // Whether the radio to be on the same line as surrounding elements
  inline: PropTypes.bool,
  // Extra classes for the radio button
  radioClass: PropTypes.string,
}

export default GlgRadio
