import { connect } from 'react-redux'
import DoubleScoreVerificationComponent from '../components/double_score_verification_component'

import { refreshData } from '../actions'

const mapStateToProps = (state) => {
  return {
    leagueId: state.misc.leagueId,
    roundId: state.misc.roundId,
    doubleScoreVerificationId: state.misc.doubleScoreVerificationId,
    leagueName: state.misc.leagueName,
    courseName: state.misc.courseName,
    step: state.misc.step,
    showPopup: state.popup.visible,
    showTeamPopup: state.teamPopup.visible,
    teamscoreVerification: state.misc.teamscoreVerification,
  }
}

const mapDispatchToProps = (dispatch) => ({
  refreshData: () => dispatch(refreshData()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DoubleScoreVerificationComponent)
