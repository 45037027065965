import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'

class JoinDateComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <Col sm={ this.props.colSmSize } xs={ 12 }><div className="panel panel-default"><div className="panel-heading text-center">{ !window.I18n ? '' : window.I18n.t('player_activity.registration.membership.join_date') }</div><div className="panel-body text-center">{ this.props.joinDate }</div></div></Col>
  }
}

JoinDateComponent.propTypes = {
  joinDate: PropTypes.string.isRequired,
  colSmSize: PropTypes.number.isRequired,
}

JoinDateComponent.defaultProps = {
  joinDate: '',
}

export default JoinDateComponent
