import PropTypes from 'prop-types'
import React, {Fragment} from 'react'
import _ from 'lodash'

const GolferDetailedResultsTableComponent = ({teamPlayersData, columns, gameType, gameClosed}) => {

  return (
    <Fragment>
      <div className="table-responsive results_table">
        <table className="tour_event_results">
          <thead>
          <tr className="header">
            <th className="player_name">{window.I18n.t('tour_event_results.components.golfer_detailed_results_table_component.player')}</th>
            {
              _.times(columns, (i) =>
                <th key={i}>{`R${i + 1}`}</th>,
              )
            }
            <th className="total">{window.I18n.t('tour_event_results.components.golfer_detailed_results_table_component.to_par_total')}</th>
            {gameClosed && gameType === 'money_grabber' &&
              <th className="earnings">{window.I18n.t('tour_event_results.components.golfer_detailed_results_table_component.earnings')}</th>}
          </tr>
          </thead>
          <tbody>
          {teamPlayersData.map((teamMember, mIdx) =>
            <tr className={`aggregate-row ${mIdx & 1 ? 'even' : 'odd'}`} key={teamMember.name}>
              <td className="player_name">{teamMember.name}</td>
              {teamMember.scores?.map(score =>
                <td key={score.round}>
                  {score.score === 0 ? 'E' : score.score}
                  {score.status && <span className="score_status">{` (${score.status})`}</span>}
                </td>
              )}
              <td className="total">{teamMember.total === 0 ? 'E' : teamMember.total}</td>
              {gameClosed && gameType === 'money_grabber' &&
                <td className="earnings">{[ 0, undefined, null ].includes(teamMember.earnings) ? '-' : teamMember.earnings.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</td>}
            </tr>,
          )}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

GolferDetailedResultsTableComponent.propTypes = {
  teamPlayersData: PropTypes.array,
  columns: PropTypes.number,
  gameType: PropTypes.string,
  gameClosed: PropTypes.bool,
}

export default GolferDetailedResultsTableComponent
