import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import PopupTypes from '../popup_types'
import {
  GlgPopup,
  GlgPopupForm,
  GlgRoundedButtonIcon,
} from 'SharedComponents'

class HelpLinksPopup extends Component {
  constructor(props) {
    super(props)
    this.onClose = props.onClose.bind(this, PopupTypes.HELP_LINKS)
  }

  shouldComponentUpdate(nextProps) {
    return this.props.show !== nextProps.show
  }

  render() {
    return <GlgPopup title={!window.I18n ? '' : window.I18n.t('navigator.components.help_links_popup.for', {option_name: `${ _.get(this.props.data, 'optionName')}`}) } show={ this.props.show } onClose={ this.onClose } showSaveButton={ false }><GlgPopupForm><div className="add-padding-top-20">{this.props.data && this.props.data.helpLinks && this.props.data.helpLinks.map(({ name, url }, index) => <div key={ index }><GlgRoundedButtonIcon text={ name } url={ url } icon="fa-book" target="_blank"></GlgRoundedButtonIcon></div>)}</div></GlgPopupForm></GlgPopup>
  }
}

HelpLinksPopup.propTypes = {
  data: PropTypes.object,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default HelpLinksPopup
