import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import AddTab from '../containers/add_tab'
// import Tab from './tab'
import { TabContainer, Nav, NavItem, Navbar} from 'react-bootstrap'

const { Collapse, Toggle } = Navbar

class ListTabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonMenuClass: 'down',
    }
  }

  render() {
    return (
      <Col xs={12}>
        <TabContainer defaultActiveKey={ this.props.tabs.length >= 1 ? `mw-tab-${this.props.tabs[0].id}` : 0 } id="mw-tabs">
          <Row>
            <Navbar collapseOnSelect={ true } expand="sm">
              <Toggle onClick={ () => this.setState({ buttonMenuClass: (this.state.buttonMenuClass === 'down' ? 'up' : 'down') }) }>
                { this.props.tabs.length >= 1 ? this.props.tabs.filter(tab => tab.id === this.props.activeTab)[0].title : '' }
                <i className={ 'glyphicon glyphicon-menu-' + this.state.buttonMenuClass }></i>
              </Toggle>
              <Collapse>
                <Row>
                  <Nav as='ul' onSelect={ () => { this.setState({ buttonMenuClass: 'down' }) } }>
                    {
                      this.props.tabs.length >= 1
                        ? this.props.tabs.map(tab =>
                          <NavItem key={tab.id} eventKey={ `mw-tab-${tab.id}` } as="li" onClick={ () => this.props.setActiveTab(tab.id) }>
                            <span className='section-title'>
                              { tab.titles && Object.keys(tab.titles).length > 0 ? ( tab.titles[this.props.currentLanguage] ? tab.titles[this.props.currentLanguage] : Object. values(tab.titles)[0] ) : tab.title }
                            </span>
                          </NavItem>
                        ) : null
                    }
                    <AddTab />
                  </Nav>
                </Row>
              </Collapse>
            </Navbar>
          </Row>
        </TabContainer>
      </Col>
    )
  }
}

ListTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  activeTab: PropTypes.number,
  usesTranslations: PropTypes.bool,
  availableLanguages: PropTypes.any,
  currentLanguage: PropTypes.any,
}

export default ListTabs
