import React, {Component} from 'react'
import PropTypes from 'prop-types'

export class MembersAlertComponent extends Component {
  constructor(props){
    super(props)
    this.onClick = this.onClick.bind(this)
  }
  
  onClick(){
    this.props.openLeaguesPropagationPopup()
    this.props.getLeaguesPropagations()
  }
  
  render(){
    return <div className="btsp_alert_notices">{this.props.displayPropagationAlert && <div>{ !window.I18n ? '' : window.I18n.t('members_page.components.members_alert_component.hi_updated') }<span><a href="#" onClick={this.onClick}>{ ` ${!window.I18n ? '' : window.I18n.t('buttons.here')} ` }</a></span></div>}{this.props.showInvalidEmailsMessage && <div>{ !window.I18n ? '' : window.I18n.t('members_page.components.members_alert_component.golfers_in_red') }</div>}</div>
  }
}

MembersAlertComponent.propTypes = {
  openLeaguesPropagationPopup: PropTypes.func.isRequired,
  getLeaguesPropagations: PropTypes.func.isRequired,
  displayPropagationAlert: PropTypes.bool.isRequired,
  showInvalidEmailsMessage: PropTypes.bool.isRequired,
}

export default MembersAlertComponent
