import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GlgPopup, GlgPopupForm } from 'SharedComponents'
import { Table, Button} from 'react-bootstrap'
import { Col } from 'react-flexbox-grid'
import TermsAndPrivacyComponent from '../terms_and_privacy/terms_and_privacy_component'
import Scrollbar from 'react-scrollbars-custom'

export class AddGolfersManuallyComponent extends Component {
  constructor(props) {
    super(props)
    this.onClose = this.onClose.bind(this)
    this.getTitles = this.getTitles.bind(this)
    this.getInputs = this.getInputs.bind(this)
    this.getRowFields = this.getRowFields.bind(this)
    this.openDivisionsPopup = this.openDivisionsPopup.bind(this)
    this.openCustomFieldsPopup = this.openCustomFieldsPopup.bind(this)
    this.changeField = this.changeField.bind(this)
    this.onSave = this.onSave.bind(this)
    this.addMoreGolfers = this.addMoreGolfers.bind(this)
  }
  
  onSave(){
    this.props.addNewMembers()
    window.onbeforeunload = null
  }
  
  onClose(){
    this.props.closeAddGolfersManuallyPopup(true)
    window.onbeforeunload = null
  }
  
  getTitles(){
    const lastNameTitle = <th key="last_name_title">{ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.last_name') }<span style={{color: 'red'}}>*</span></th>
    const firstNameTitle = <th key="first_name_title">{ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.first_name') }</th>
    const emailTitle = <th key="email_title">{ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.email') }</th>
    let titles = [ lastNameTitle, firstNameTitle, emailTitle ]
    const hi9HoleTitle = <th key="9_hole_index_title" className="index"><div title={ this.props.customerIsGolfAdvisor ? (!window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.9_hole_hi_long')) : ''} data={{ myposition: 'top center', atposition: 'bottom center' }} className="index-with-title">{ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.9_hole') }<br />{ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.hi') }{ this.props.customerIsGolfAdvisor && <i className="gga-info-circle"></i> }</div></th>
    const hi18HoleTitle = <th key="18_hole_index_title" className="index"><div title={ this.props.customerIsGolfAdvisor ? (!window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.18_hole_hi_long')) : ''} data={{ myposition: 'top center', atposition: 'bottom center' }} className="index-with-title">{this.props.indexType === 'both' ? (!window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.18_hole')) : ''}{this.props.indexType === 'both' ? <br /> : ''}{ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.hi') }{ this.props.customerIsGolfAdvisor && <i className="gga-info-circle"></i> }</div></th>
    titles = titles.concat(this.props.indexType === 'both' ? [ hi18HoleTitle, hi9HoleTitle ] : ( this.props.indexType === '9 hole' ? [ hi9HoleTitle ] : [ hi18HoleTitle ]))
    if (this.props.hasAutomatedHandicaps) {
      const handicapMethodTitle = <th key="pretty_handicap_method_title">{this.props.prettyHandicapMethod}</th>
      titles.push(handicapMethodTitle)
    }
    if (this.props.hasDivisions) {
      const divisionTitle = <th key="divisions_title">{ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.divisions') }</th>
      titles.push(divisionTitle)
    }
    const customFieldsTitle = <th style={{position: 'relative'}} key="custom_fields_title" className="edit_cf">{ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.custom_fields') }</th>
    titles.push(customFieldsTitle)
    return titles
  }
  
  openDivisionsPopup(){
  }
  
  openCustomFieldsPopup(playerIndex){
    this.props.closeAddGolfersManuallyPopup(false)
    this.props.openEditCustomFieldsPopup(playerIndex)
    this.props.fetchLeagueMetadata()
  }
  
  getInputs(i){
    let inputs = []
    const hi9HoleInput = <td key={`9_hole_input_${i}`} className={`index ${i > 0 ? 'hidden-xs' : ''}`}><input type="text" name="nine_index[]" placeholder={ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.9_hole_hi') } value={this.props.newMembers[i].hi9Hole} onChange={(e) => this.changeField(e.target.value, i, 'hi9Hole')} className={i > 0 ? 'hidden-xs' : ''} /></td>
    const hi18HoleInput = <td key={`18_hole_input_${i}`} className={`index ${i > 0 ? 'hidden-xs' : ''}`}><input type="text" name="index[]" placeholder={ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.18_hole_hi') } value={this.props.newMembers[i].hi18Hole} onChange={(e) => this.changeField(e.target.value, i, 'hi18Hole') } className={i > 0 ? 'hidden-xs' : ''} /></td>
    inputs = inputs.concat(this.props.indexType === 'both' ? [ hi18HoleInput, hi9HoleInput ] : ( this.props.indexType === '9 hole' ? [ hi9HoleInput ] : [ hi18HoleInput ]))
    if (this.props.hasAutomatedHandicaps) {
      const handicapMethodInput = <td key={`handicap_network_id_${i}`} className={`ghin ${i > 0 ? 'hidden-xs' : ''}`}><input type="text" name="handicap_network_id[]" placeholder={this.props.prettyHandicapMethod} value={this.props.newMembers[i].handicapNetworkId} onChange={(e) => this.changeField(e.target.value, i, 'handicapNetworkId') } /></td>
      inputs.push(handicapMethodInput)
    }
    if (this.props.hasDivisions) {
      const divisionsInput = <td key={`division_ids_${i}`} className={`${i > 0 ? 'hidden-xs' : ''}`}><a href="#" className="select_divisions btn btn-default disabled" onClick={ this.openDivisionsPopup }>{ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.divisions') }</a></td>
      inputs.push(divisionsInput)
    }
    const customFieldsInput = <td key={`division_ids_${i}`} className={`edit_cf ${i > 0 ? 'hidden-xs' : ''}`}><a href="#" className={'select_custom_fields btn btn-default ' + (this.props.newMembers[i].lastName === '' ? 'disabled' : '')} onClick={ () => this.openCustomFieldsPopup(i) }>{ this.props.nrCustomFields[i] > 0 ? (!window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.edit_field', {name: this.props.nrCustomFields[i]})) : (!window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.edit_fields')) }</a></td>
    inputs.push(customFieldsInput)
    return inputs
  }
  
  changeField(value, index, field){
    this.props.saveNewMemberField(field, value, index)
  }
  
  getRowFields(){
    const rows = []
    for (let i = 0; i < this.props.newMembers.length; i++) {
      const inputs = this.getInputs(i)
      const row = <tr key={`mass_add_row_${i}`} className="mass_invite"><td className={`${i > 0 ? 'hidden-xs' : ''}`}><input type="text" name="last_name[]" maxLength={255} placeholder={ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.last_name_required') } value={this.props.newMembers[i].lastName} onChange={(e) => this.changeField(e.target.value, i, 'lastName') } autoFocus={i === 0 ? true : false} /></td><td className={`${i > 0 ? 'hidden-xs' : ''}`}><input type="text" name="first_name[]" maxLength={255} placeholder={ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.first_name') } value={this.props.newMembers[i].firstName} onChange={(e) => this.changeField(e.target.value, i, 'firstName') } /></td><td className={`${i > 0 ? 'hidden-xs' : ''}`}><input type="email" name="email[]" maxLength={255} placeholder={ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.email') } value={this.props.newMembers[i].email} onChange={(e) => this.changeField(e.target.value, i, 'email') } /></td>{inputs.map((x) => x)}</tr>
      rows.push(row)
    }
    return rows
  }
  
  addMoreGolfers(){
    this.props.addMoreGolfers()
  }

  render() {
    const isSaveButtonDisabled = (!this.props.newMembers.some(x => x.lastName !== '') || !this.props.termsAndPrivacyChecked.addGolfersManually) || this.props.isNewMembersSaving
    const customButtons = [
      {
        class: 'btsp_orange_button pull-right btn button ' + (isSaveButtonDisabled ? 'disabled' : ''),
        text: this.props.isNewMembersSaving ? (!window.I18n ? '' : window.I18n.t('buttons.saving')) : (!window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.save_roster')),
        onClick: this.onSave,
      },
      {
        class: 'btn button btsp_gray_button pull-right',
        text: !window.I18n ? '' : window.I18n.t('buttons.cancel').charAt(0)
          .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.cancel').slice(1)),
        onClick: this.onClose,
      },
    ]
    const hiTitles = this.getTitles()
    const popUpTitle = <div key="popup_title" className="title">{ !window.I18n ? '' : window.I18n.t('add_members.get_manager.add_golfers_manually') }</div>
    //TODO check for exist_div_class 
    return <GlgPopup title={() => popUpTitle} modalClass="modal-lg add_golfers_manually_modal use-custom-scrollbars" show={ this.props.show } onClose={ this.onClose } manualClose={ true } showCloseButton={ false } showSaveButton={ false } customButtons={customButtons} id="add_golfers_manually_modal"><p className="hidden-xs"><i>{ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.fields_marked_with') }<span style={{color: 'red'}}>{ ' * ' }</span>{ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.mandatory_fields') }</i></p><Scrollbar style={{height: 'auto'}} wrapperProps={{
            renderer: props => {
              const { elementRef, ...restProps } = props
              return <div {...restProps} ref={elementRef} style={{...restProps.style, position: 'relative', maxHeight: '50vh'}} />
            },
          }} scrollerProps={{
            renderer: props => {
              const { elementRef, ...restProps } = props
              return <div {...restProps} ref={elementRef} style={{...restProps.style, position: 'relative', maxHeight: '50vh'}} />
            },
          }} trackYProps={{
            renderer: props => {
              const { elementRef, ...restProps } = props
              return <span {...restProps} ref={elementRef} style={{...restProps.style, height: 'calc(100%)'}} />
            },
          }} disableTracksWidthCompensation={true}><GlgPopupForm><Table className="mass_add_members table-responsive"><thead><tr className="header hidden-xs">{hiTitles.map((x) => x)}</tr></thead><tbody>{ this.getRowFields() }</tbody></Table><Col sm={3} md={2} style={{marginBottom: '15px'}} className="hidden-xs"><Button onClick={ this.addMoreGolfers } className="btn btn-default btsp_white_button" id="add_more_golfers">{ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.add_golfers_manually_component.add_more_golfers') }</Button></Col></GlgPopupForm></Scrollbar><div className="add-margin-top-10"><TermsAndPrivacyComponent branding={this.props.branding} type={'general'} termsAndConditionsUrl={this.props.termsAndConditionsUrl} privacyPolicyUrl={this.props.privacyPolicyUrl} toggleTermsAndPrivacy={(checked) => this.props.toggleTermsAndPrivacy(checked, 'addGolfersManually')} checked={this.props.termsAndPrivacyChecked.addGolfersManually}></TermsAndPrivacyComponent></div></GlgPopup>
  }
}

AddGolfersManuallyComponent.propTypes = {
  show: PropTypes.bool.isRequired,
  closeAddGolfersManuallyPopup: PropTypes.func.isRequired,
  openEditCustomFieldsPopup: PropTypes.func.isRequired,
  fetchLeagueMetadata: PropTypes.func.isRequired,
  customerIsGolfAdvisor: PropTypes.bool.isRequired,
  hasAutomatedHandicaps: PropTypes.bool.isRequired,
  hasDivisions: PropTypes.bool.isRequired,
  indexType: PropTypes.string.isRequired,
  prettyHandicapMethod: PropTypes.string,
  saveNewMemberField: PropTypes.func.isRequired,
  newMembers: PropTypes.array.isRequired,
  nrCustomFields: PropTypes.array.isRequired,
  addMoreGolfers: PropTypes.func.isRequired,
  addNewMembers: PropTypes.func.isRequired,
  isNewMembersSaving: PropTypes.bool.isRequired,
  branding: PropTypes.string.isRequired,
  termsAndConditionsUrl: PropTypes.string.isRequired,
  privacyPolicyUrl: PropTypes.string.isRequired,
  toggleTermsAndPrivacy: PropTypes.func.isRequired,
  termsAndPrivacyChecked: PropTypes.object.isRequired,
  elementRef: PropTypes.any,
}

export default AddGolfersManuallyComponent
