import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { Row, Col } from 'react-flexbox-grid'

import { GlgIcon } from 'SharedComponents'

class ViewTypeFilter extends PureComponent {
  constructor(props) {
    super(props)
    this.onChange = (e) => props.onChange('viewType', e.target.value)
    this.handleCategoryViewClick = this.handleCategoryViewClick.bind(this)
    this.handleListViewClick = this.handleListViewClick.bind(this)
  }

  handleCategoryViewClick() {
    if (!this.props.disabled) {
      this.props.onChange('viewType', 'category')
    }
  }

  handleListViewClick() {
    if (!this.props.disabled) {
      this.props.onChange('viewType', 'list')
    }
  }

  render() {
    const categoryViewStatus = this.props.viewType === 'category' ? 'active' : 'inactive'
    const categoryClasses = `category-view ${ categoryViewStatus }`
    const listViewStatus = this.props.viewType === 'list' ? 'active' : 'inactive'
    const listClasses = `list-view ${ listViewStatus }`
    const iconClass = this.props.disabled ? '' : 'clickable'
    const categoryViewText = (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.view_type_filter.category_view'))
    const categoryViewTooltip = <Tooltip id="category-view-tooltip">{categoryViewText}</Tooltip>
    const listViewText = (!window.I18n ? '' : window.I18n.t('customer_center_filters.components.filters.view_type_filter.list_view'))
    const listViewTooltip = <Tooltip id="list-view-tooltip">{listViewText}</Tooltip>
    return <Row center="xs" className="view-type-filter"><Col xs={true}><div onClick={ this.handleCategoryViewClick } className={ categoryClasses }><OverlayTrigger placement="top" overlay={ categoryViewTooltip }><GlgIcon icon="category-view" iconClass={ iconClass }></GlgIcon></OverlayTrigger></div></Col><Col xs={true}><div onClick={ this.handleListViewClick } className={ listClasses }><OverlayTrigger placement="top" overlay={ listViewTooltip }><GlgIcon icon="four-lines" iconClass={ iconClass }></GlgIcon></OverlayTrigger></div></Col></Row>
  }
}

ViewTypeFilter.propTypes = {
  viewType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default ViewTypeFilter
