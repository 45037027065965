import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'

import { GlgTable, GlgTableCol } from 'SharedComponents'
import PreviewRowComponent from './preview_row_component'
import { getTotalColumnClass, getAbsColumnValue } from 'Shared/course_boards'

class PreviewComponent extends Component {
  constructor(props) {
    super(props)
    this.renderFooter = this.renderFooter.bind(this)
  }

  renderFooter() {
    const { message, score, showCourse } = this.props
    const color = getTotalColumnClass(score)
    const absValue = getAbsColumnValue(score)
    return (
      <tr className={'footer-row ' + color}>
        <td className='glg-table-header' colSpan={showCourse ? 4 : 3}><div>{message}</div></td>
        <td className='glg-table-header' colSpan={1}><div>{absValue}</div></td>
      </tr>
    )
  }

  render() {
    const { lines, showMessage, showCourse, withTeams } = this.props
    const footer = this.renderFooter()
    const rowExtraProps = {
      showCourse: showCourse,
      withTeams: withTeams,
    }
    
    return (
      <Fragment>
        <Col xs={12} sm={12} md={12} lg={12} className='no-padding add-margin-top-20'>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className='page_title'>
              <div className='text'>Preview</div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className='btsp_form_content preview'>
              <div className='preview_container'>
                <GlgTable items={lines}
                          rowComponent={ PreviewRowComponent }
                          rowExtraProps={ rowExtraProps }
                          showFooter={showMessage}
                          footerComponent={ footer }>
                  <GlgTableCol width='5%'>{ window.I18n.t('course_leaderboards.today') }</GlgTableCol>
                  <GlgTableCol width='65%'>{ window.I18n.t('course_leaderboards.leaders') }</GlgTableCol>
                  <GlgTableCol width='10%'>{ window.I18n.t('course_leaderboards.hole') }</GlgTableCol>
                  { showCourse && <GlgTableCol width='15%'>{ window.I18n.t('course_leaderboards.course') }</GlgTableCol> }
                  <GlgTableCol width='5%'>{ window.I18n.t('course_leaderboards.total') }</GlgTableCol>
                </GlgTable>
              </div>
            </div>
          </Col>
        </Col>
      </Fragment>
    )

  }
}

PreviewComponent.propTypes = {
  lines: PropTypes.array,
  showMessage: PropTypes.bool,
  message: PropTypes.string,
  score: PropTypes.string,
  showCourse: PropTypes.bool,
  withTeams: PropTypes.bool,
}

export default PreviewComponent
