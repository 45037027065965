import React from 'react'
import PropTypes from 'prop-types'
import { GlgCheckbox } from 'SharedComponents'
import { Label } from 'react-bootstrap'

const Member = (props) => {

  const member = props.member
  const checked = props.mergedMemberIds.includes(member.id)

  const STRENGTH_LABELS = {
    'strong': 'success',
    'medium': 'warning',
    'weak': 'danger',
    'manual': 'default',
    'original': 'default',
  }

  const handleSetMerge = (checked) => {
    checked ? props.addMembersToSticky([ member ] ) : props.removeMembersFromSticky([ member ])
  }

  const handleSetSplit = (checked) => {
    checked ? props.addMembersToSplit([ member ] ) : props.removeMembersFromSplit([ member ])
  }

  const duringSplit = props.type === 'SPLIT'

  return (
    <tr key = {member.id} className={checked ? 'selected' : undefined}>
      { props.stickyId && props.isSplitting && props.isSticky && <td> <GlgCheckbox name='splitt-checkbox' onChange= {checked => handleSetSplit(checked)} /> </td>}
      { !duringSplit && props.stickyId && !props.isSticky && <td> <GlgCheckbox name='merge-checkbox' checked={checked} onChange= {checked => handleSetMerge(checked)} /> </td>}
      <td>{member.date}</td>
      <td><a href = { member.league_link} target="_blank">{member.first_name + ' ' + member.last_name}</a></td>
      <td>{member.match_strength && <Label className='strength-label' bsStyle={STRENGTH_LABELS[member.match_strength]}> {window.I18n.t(`member_cards.${member.match_strength}`)}</Label>}</td>
      <td>{member.league_name}</td>
      <td>{member.email}</td>
      <td>{member.handicap_network_id}</td>
      <td>{member.user_id}</td>
      <td>{props.mcIdentifierSet ? member.custom_field_value : member.identifier_id}</td>
    </tr>)
}

Member.propTypes = {
  member: PropTypes.object,
  stickyId: PropTypes.string,
  isSticky: PropTypes.bool,
  addMembersToSticky: PropTypes.func,
  removeMembersFromSticky: PropTypes.func,
  addMembersToSplit: PropTypes.func,
  removeMembersFromSplit: PropTypes.func,
  isSplitting: PropTypes.bool,
  isMerging: PropTypes.bool,
  type: PropTypes.string,
  checked: PropTypes.bool,
  mergedMemberIds: PropTypes.array,
  mcIdentifierSet: PropTypes.bool,
}
export default Member
