import { connect } from 'react-redux'
import { 
  syncMrRecord,
  relinkMembers,
  deleteRelinkMembers,
  setSticky,
  addAllMembersToSticky,
  removeMembersFromSticky,
  fetchMoreMembers,
  fetchLessMembers } from '../actions'
import MemberCard from '../components/member_card_component'

const mapStateToProps = (state, props) => {
  return { 
    memberCard: props.memberCard,
    members: props.members,
    index: props.index,
    indexSticky: state.sticky.index,
    type: state.sticky.type,
    loadingMembersMc: state.members.loadingMembersMc,
    handicapProvider: state.misc.handicapProvider,
    mcIdentifierSet: state.misc.mcIdentifierSet,
    customFieldName: state.misc.possibleCustomFields,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSticky: (memberCard, index, type) => dispatch(setSticky(memberCard, index, type)),
  addAllMembersToSticky: (memberCardId) => dispatch(addAllMembersToSticky(memberCardId)),
  removeMembersFromSticky: (members) => dispatch(removeMembersFromSticky(members)),
  showMoreMembers: (memberCardId) => dispatch(fetchMoreMembers(memberCardId)),
  showLessMembers: (memberCardId) => dispatch(fetchLessMembers(memberCardId)),
  syncMrRecord: (memberCardId) => dispatch(syncMrRecord(memberCardId)),
  relinkMembers: (memberCardId) => dispatch(relinkMembers(memberCardId)),
  deleteRelinkMembers: (memberCardId) => dispatch(deleteRelinkMembers(memberCardId)),
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberCard)
