import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'

import HoleByHoleTableContainer from '../containers/hole_by_hole_table_container'
import SideBySideTableContainer from '../containers/side_by_side_table_container'

class MonsterBoardDeviceComponent extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.timer = setInterval(this.props.refreshData, 10000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    const { boardAvailable, lastUpdate, deviceView } = this.props

    return (
      <Fragment>
        <Col xs={12} sm={12} md={12} lg={12} className='no-padding'>
          { boardAvailable &&
            <Col xs={12} sm={12} md={12} lg={12} className='text-center bold'>
              Last update on: {lastUpdate}
            </Col>
          }
        </Col>
        { deviceView === 'hole-by-hole' ? <HoleByHoleTableContainer/> : <SideBySideTableContainer/> }
      </Fragment>
    )
  }
}

MonsterBoardDeviceComponent.propTypes = {
  deviceView: PropTypes.string,
  boardAvailable: PropTypes.bool,
  lastUpdate: PropTypes.string,
  refreshData: PropTypes.func,
}

export default MonsterBoardDeviceComponent
