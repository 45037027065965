import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SetFlashMessage from './popups/set_flash_message'

class Portal extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { title, contactPerson, links, subtitles, copy, ggid, flashMessageModal } = this.props.content
    
    return (
      <div className='widget-content-default widget-content-portal'>
        <div className='title'>
          { title }


          <a href={ links.portal.path } className='btn btn-primary widget-white-title-button'>
            { links.edit_member_portal.name }
          </a>
        </div>
        <div className='content'>
          {
            contactPerson.name !== ''
              ? <div className='contact-person'>
                { `● ${contactPerson.label} | ` }
                <span className='contact'>{ contactPerson.name }</span>
              </div>
              : null
          }
          <div className='sso-links ggid-container'>
            <div className='subtitle'>{ subtitles.portal_address }</div>
            <div className='sso-link-container'>
              <div className='sso-link-name'>{ links.portal_website.path }</div>
              {/* <span className='sso-link-name'>{ links.portal.path }</span> */}

              <span className='copy-link' title={ `${copy.label}:\n${links.portal_website.path}` } onClick={ () => this.props.copyText(links.portal_website.path) } >
                <i className='fa fa-files-o'></i> { copy.message }
              </span>
            </div>
            {
              links.sso.enabled
                ? <div className='sso-link-container'>
                  {/* <p>
                    <span className='sso-link-name' dangerouslySetInnerHTML={{__html: links.sso.name }} />
                  </p> */}
                  <a href={ links.sso.path }>
                    <i className='gga-popup'></i> <span className='sso-link-name'>{ links.sso.name }</span>
                  </a>

                  <span className='copy-link' title={ `${copy.label}:\n${links.sso.path}` } onClick={ () => this.props.copyText(links.sso.path) } >
                    <i className='fa fa-files-o'></i> { copy.message }
                  </span>
                </div>
                : null
            }
            {
              links.ggid.enabled
              ?
              <span>
                <div className='subtitle'>{ subtitles.ggid_link }</div>
                <div className='sso-link-container'>
                  <p>
                    <span className='sso-link-name'>{ links.ggid.path }</span>
                  </p>
                  {/* <a href={ links.ggid.path }>
                    <i className='gga-popup'></i> <span className='sso-link-name'>{ links.ggid.name }</span>
                  </a> */}
    
                  <span className='copy-link' title={ `${copy.label}:\n${links.ggid.path}` } onClick={ () => this.props.copyText(links.ggid.path) } >
                    <i className='fa fa-files-o'></i> { copy.message }
                  </span>
                </div>
              </span>
              : null
            }
            <div className='ggid-code'>
              { `● ${ggid.label} | ` }
              {
                ggid.edit
                  ? <span>
                      <input defaultValue={ ggid.value } placeholder='GGID' className={ `form-control ggid-input ${ (ggid.new !== null && ggid.value !== null && ggid.new.toLowerCase() === ggid.value.toLowerCase()) || !ggid.valid ? 'ggid-input-error' : '' }` } onChange={ event => this.props.changeGgid(event.target.value) } />
                      <button className='btn btn-primary ggid-edit-buttons' disabled={ (ggid.new !== null && ggid.value !== null && ggid.new.toLowerCase() === ggid.value.toLowerCase()) || !ggid.valid } onClick={ () => this.props.updateGgid() }>{ ggid.action_update }</button>
                      <button className='btn btn-primary ggid-edit-buttons' onClick={ () => this.props.toggleEditGgid() }>{ ggid.action_cancel }</button>
                    </span>
                  : <span className='ggid'>
                      { ggid.value }
                      <span className='ggid-edit-container' onClick={ () => this.props.toggleEditGgid() }>
                        <i className='fa fa-pencil'></i> { ggid.action_edit }
                      </span>
                    </span>
              }
            </div>
            <div className='firestick-msg'>
              <span>
                { links.need_ggid.name }
              </span>
              <a href={ links.need_ggid.path }> { links.need_ggid.here_button }</a>
            </div>
            <a href={ links.send_ggid.path } className='btn btn-primary widget-white-content-button'>
              { links.send_ggid.name }
            </a>
          </div>
          <div className='buttons-container'>
            <a href='#' className='btn btn-primary widget-white-content-button' onClick={ () => this.props.toggleSetFlashMessageModal(true) }>
              { links.set_flash.name }
            </a>

            <SetFlashMessage showModal={ flashMessageModal.show }
                             onClose={ () => this.props.toggleSetFlashMessageModal(false) }
                             onSave={ () => this.props.updateFlashMessage() }
                             changeFlashMessageData={ this.props.changeFlashMessageData }
                             formURL={ links.set_flash.path }
                             formData={ flashMessageModal.data } />
          </div>
        </div>
      </div>
    )
  }
}

Portal.propTypes = { 
  content: PropTypes.object,
  copyText: PropTypes.func,
  toggleSetFlashMessageModal: PropTypes.func,
  changeFlashMessageData: PropTypes.func,
  updateFlashMessage: PropTypes.func,
  toggleEditGgid: PropTypes.func,
  validate_ggid_url: PropTypes.func,
  changeGgid: PropTypes.func,
  updateGgid: PropTypes.func,
}


Portal.defaultProps = {
  content: {
    contact_person: '',
    portal_url: '#',
    sso_url: '#',
    ggid_url: '#',
    ggid: '',
    edit_ggid: false,
    new_ggid_valid: false,
    validate_ggid_url: '#',
    update_ggid_url: '#',
    send_ggid: '',
    edit_member_portal_url: '#',
    set_flash_url: '#',
    show_set_flash_message_modal: false,
    flash_message_data: {
      message: '',
      show_flash_with_manager_name: false,
      show_flash_for_everyone: false,
      author_id: '',
    },
  },
}

export default Portal
