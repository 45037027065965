import { connect } from 'react-redux'
import PreviewComponent from '../components/preview_component'

import { getPlayersOnBoard } from 'Shared/course_boards'

const mapStateToProps = (state) => {
  const lines = getPlayersOnBoard(state)

  return {
    lines: lines,
    showMessage: state.misc.showMessage,
    message: state.misc.message,
    score: state.misc.score,
    showCourse: state.misc.showCourse,
    withTeams: state.misc.withTeams,
  }
}

export default connect(
  mapStateToProps,
  null,
)(PreviewComponent)
