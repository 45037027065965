import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { commonStyles } from './common.js'

const StyledSuggestion = styled.span`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
  ${commonStyles};
  border-color: transparent;
`

const Suggestion = ({
  needle,
  shouldRenderSuggestion,
  value,
}) => {
  if (shouldRenderSuggestion && value && !shouldRenderSuggestion(value)) {
    return null
  }

  return (
    <StyledSuggestion>
      {value}
      {needle}
    </StyledSuggestion>
  )
}

Suggestion.propTypes = {
  shouldRenderSuggestion: PropTypes.func,
  needle: PropTypes.string,
  value: PropTypes.string,
}

export default Suggestion
