import React, {Component} from 'react'
import { Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/lib/Row'

class PlayerInfoComponent extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <Col xs={ 12 }><Row className="player-info-container"><Col xs={ 12 } sm={ 3 }></Col><Col xs={ 12 } sm={ 6 }><div className="player-info"><div className="name">{this.props.name}</div>{this.props.photoUrl ? <div className="player-photo-container"><img src={this.props.photoUrl} className={'photo'} /></div> : <div className="player-photo-container"><img src={require('home/logos/default_logo_large.png')} className={'photo'} /></div>}{this.props.usgaIndex && this.props.usgaIndex !== '' && <div className="usga-index"><div className="hi-text">{ !window.I18n ? '' : window.I18n.t('player_activity.participation_history.hi') }</div><div className="hi">{this.props.usgaIndex}</div></div>}</div></Col><Col xs={ 12 } sm={ 3 } className="edit-profile"><a href={ this.props.editProfileUrl } target={ this.props.target } className="edit-btn"><i className="fa fa-edit"></i>{ !window.I18n ? '' : window.I18n.t('player_activity.edit_player_profile') }</a></Col></Row></Col>
  }
}
PlayerInfoComponent.propTypes = {
  name: PropTypes.string,
  usgaIndex: PropTypes.string,
  photoUrl: PropTypes.string,
  editProfileUrl: PropTypes.string,
  target: PropTypes.string,
}

export default PlayerInfoComponent
