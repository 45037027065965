import React from 'react'
import PropTypes from 'prop-types'
import rootReducer from './reducers'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import ScoringControlInterface from './containers/scoring_control_interface_container'

const ScoringControlInterfaceRoot = ({
  leagueId,
  roundId,
  fetchInitialDataPath,
  updateScoresPath,
  tournaments,
  selectedTournamentId,
  holeView,
  isLoading}) => {
  const state = {
    misc: {
      leagueId,
      roundId,
      fetchInitialDataPath,
      updateScoresPath,
      tournaments,
      selectedTournamentId,
      holeView,
      isLoading,
    },
  }

  const store = configureStore(rootReducer, state)

  return (
    <GlobalProvider store = { store }>
      <ScoringControlInterface />
    </GlobalProvider>
  )
}

ScoringControlInterfaceRoot.propTypes = {
  leagueId: PropTypes.string,
  roundId: PropTypes.string,
  fetchInitialDataPath: PropTypes.string,
  updateScoresPath: PropTypes.string,

  tournaments: PropTypes.object,
  selectedTournamentId: PropTypes.string,
  holeView: PropTypes.string,

  isLoading: PropTypes.bool,
}

export default ScoringControlInterfaceRoot
