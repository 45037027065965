import React from 'react'
import PropTypes from 'prop-types'
import rootReducer from './reducers'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import MatchPlayBoardContainer from './containers/match_play_board_container'

const MatchPlayBoardRoot = ({
  matchLines, acknowledgedLines, refreshUrl, acknowedgedDate, lastUpdatedDate, acknowledgeUrl, isTeam, teamNames, todayPoints, totalPoints, acknowledgedTodayPoints, acknowledgedTotalPoints}) => {
  const state = {
    misc: {
      matchLines,
      acknowledgedLines,
      refreshUrl,
      acknowedgedDate,
      lastUpdatedDate,
      acknowledgeUrl,
      isTeam,
      teamNames,
      todayPoints,
      totalPoints,
      acknowledgedTodayPoints,
      acknowledgedTotalPoints,
    },
  }

  const store = configureStore(rootReducer, state)

  return (
    <GlobalProvider store = { store }>
      <MatchPlayBoardContainer />
    </GlobalProvider>
  )
}

MatchPlayBoardRoot.propTypes = {
  matchLines: PropTypes.array,
  acknowledgedLines: PropTypes.array,
  refreshUrl: PropTypes.string,
  acknowedgedDate: PropTypes.string,
  lastUpdatedDate: PropTypes.string,
  acknowledgeUrl: PropTypes.string,
  isTeam: PropTypes.bool,
  teamNames: PropTypes.array,
  todayPoints: PropTypes.array,
  totalPoints: PropTypes.array,
  acknowledgedTodayPoints: PropTypes.array,
  acknowledgedTotalPoints: PropTypes.array,
}

export default MatchPlayBoardRoot
