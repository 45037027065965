import React from 'react'
import PropTypes from 'prop-types'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'
import rootReducer from '../reducers'

import PlayerActivityDashboardContainer from '../containers/player_activity_dashboard_container'
import Cookies from 'js-cookie'

function getViewOptionCookie() {
  const option = Cookies.get('view_option')
  if (option && (option === 'round' || option === 'event')) {
    return option
  }
  Cookies.set('view_option', 'event')
  return 'event'
}

const PlayerActivityDashboardRoot = ({ cbEnabled, cbBalance, currency, memberCardId, HISummaryUrl, fetchTransactionsHistoryDataUrl, 
  fetchParticipationHistoryEventsDataUrl, fetchParticipationHistoryRoundsDataUrl, fetchMembershipProgramsDataUrl, fetchMoneyEarnedInformationDataUrl, usgaIndex, isGcat, isGcatRequest, hasPlayerProfiles, editProfileUrl, deletePaymentUrl, defaultPaymentUrl, deleteCheckInUrl, MRR, photoUrl, usesPayments, seasonId}) => {
  const state = {
    transactionsHistory: {
      fetchTransactionsHistoryDataUrl,
      deletePaymentUrl,
      defaultPaymentUrl,
      payments: {},
      transactions: [],
      playerName: '',
      hasTransactions: false,
      events: [],
      loaded: false,
      exportToExcelBaseUrl: '',
      dataIsLoading: true,
      cbEnabled,
      cbBalance,
      currency,
    },
    participationHistory: {
      fetchParticipationHistoryEventsDataUrl,
      fetchParticipationHistoryRoundsDataUrl,
      futureEvents: [],
      pastEvents: [],
      seasons: [],
      seasonId: seasonId,
      futureEventsCount: 0,
      pastEventsCount: 0,
      playerName: '',
      loadedEventsData: false,
      loadedRoundsData: false,
      dataIsLoading: true,
      isPGA: false,
      moneyEarnedTotal: {},
      fetchMoneyEarnedInformationDataUrl,
      areLoadingEventsIds: [],
      loadedEvents: {},
      isOpenedEventPopup: false,
      currentlyOpenedEventPopup: '',
      checkInList: [],
      checkInsCount: 0,
      deleteCheckInUrl,
      usgaIndex: usgaIndex,
      photoUrl: photoUrl,
      isOpenedLeaderboardPopup: false,
      currentlyRoundLeaderboardPopup: '',
      loadingLeaderboards: false,
      viewOption: getViewOptionCookie(),
      futureRounds: [],
      pastRounds: [],
      futureRoundsCount: 0,
      pastRoundsCount: 0,
      pageFutureRounds: 1,
      pagePastRounds: 1,
      pageFutureEvents: 1,
      pagePastEvents: 1,
      editProfileUrl: editProfileUrl,
    },
    membershipPrograms: {
      cbEnabled,
      cbBalance,
      currency,
      MRR,
      fetchMembershipProgramsDataUrl,
      memberships: {},
      personalData: [],
      loaded: false,
      editRegistrationLink: '',
      membershipPortalLink: '',
      dataIsLoading: true,
      hasRenewals: false,
    },
  }
  
  const store = configureStore(rootReducer, state)

  return <GlobalProvider store={ store }><PlayerActivityDashboardContainer memberCardId={ memberCardId } HISummaryUrl={HISummaryUrl} usgaIndex={usgaIndex} name={name} isGcat={isGcat} isGcatRequest={isGcatRequest} hasPlayerProfiles={hasPlayerProfiles} editProfileUrl={editProfileUrl} usesPayments={usesPayments}></PlayerActivityDashboardContainer></GlobalProvider>
}

PlayerActivityDashboardRoot.propTypes = {
  memberCardId: PropTypes.string.isRequired,
  HISummaryUrl: PropTypes.string.isRequired,
  usgaIndex: PropTypes.string.isRequired,
  fetchTransactionsHistoryDataUrl: PropTypes.string.isRequired,
  fetchParticipationHistoryEventsDataUrl: PropTypes.string.isRequired,
  fetchParticipationHistoryRoundsDataUrl: PropTypes.string.isRequired,
  fetchMembershipProgramsDataUrl: PropTypes.string.isRequired,
  fetchMoneyEarnedInformationDataUrl: PropTypes.string.isRequired,
  isGcat: PropTypes.bool.isRequired,
  isGcatRequest: PropTypes.bool.isRequired,
  hasPlayerProfiles: PropTypes.bool.isRequired,
  editProfileUrl: PropTypes.string.isRequired,
  deletePaymentUrl: PropTypes.string.isRequired,
  defaultPaymentUrl: PropTypes.string.isRequired,
  deleteCheckInUrl: PropTypes.string.isRequired,
  MRR: PropTypes.bool,
  cbEnabled: PropTypes.bool,
  cbBalance: PropTypes.number,
  currency: PropTypes.string,
  photoUrl: PropTypes.string,
  usesPayments: PropTypes.bool,
  seasonId: PropTypes.string,
}

export default PlayerActivityDashboardRoot
