import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import FaIcon from 'react-fa'
import moment from 'moment-timezone'

import { getTodayWithTimezone, onConfirm } from 'Shared/helpers'
import { isExtraColumn, isRegistrationExtraColumn, registrationDisplaysTime, isRegistrationOpenForMembership } from '../column_helpers'
import PopupTypes from '../popup_types'
import {
  GlgTableRow,
  GlgTableCell,
  GlgDropdown,
  GlgTooltipIcon,
  GlgLabel,
} from 'SharedComponents'

const getRegistrationLabelType = (registrationMessage) => {
  if (registrationMessage.includes('Open')) {
    return 'success'
  } else if (registrationMessage === 'Not yet open') {
    return 'info'
  } else if (registrationMessage === 'Invitation List') {
    return 'warning'
  } else if (registrationMessage === 'Membership Programs') {
    return 'primary'
  } else {
    return 'default'
  }
}
const getGolfHubPageValue = (golfHubPage) => {
  if (golfHubPage === 'Published'){
    return 'golfhubpublished'
  } else if (golfHubPage === 'Created'){
    return 'golfhubcreated'
  } else {
    return 'golfhubabsent'
  }
}

const getFutureMembershipProgramsNames = (membershipProgramsInfo, timezone) => {
  const today = getTodayWithTimezone(timezone)
  const mpNames = membershipProgramsInfo.filter(mp => moment(mp.startDate).isAfter(today))
                                        .map(mp => mp.name)
  return mpNames
}

const Handle = ({canManage, ...props}) => {
  const style = canManage ? 'clickable' : 'hidden'
  return <span className={style} { ...props }><FaIcon name="bars"></FaIcon></span> 
}

const LeagueRowComponent = ({
  id, name, product, startDate, registrationMessage,
  seasonId, seasonName, tourId, tourName,
  golfers, leagueURL, portalURL, canManage,
  categoryId, categoryStatus, isSortable,
  includeCategoryColumn, categoryName,
  openRegistrationData, timeZoneDisplay,
  userIsCustomerManager, userIsTourManager, userIsVisageAdmin,
  showTours, toursEnabled, showSeasonsColumn, seasonsEnabled, showRegistration,
  deleteLeague, archiveLeague, restoreLeague, openPopup,
  isTeamCaptain, isShotManager, golfHub, golfHubEnabled, isTmLite,
  ...props
}) => {
  let dropdownOptions
  const userIsManager = userIsCustomerManager || userIsTourManager
  if (canManage) {
    dropdownOptions = []
    if (!isTmLite) {
      dropdownOptions.push({
        label: 'Go to Portal',
        url: portalURL,
      })
    }
    dropdownOptions.push({
      label: `Go to ${ _.capitalize(product) }`,
      url: leagueURL,
    })
    if (userIsManager) {
      if (!isTmLite){
        dropdownOptions.push({
          label: `Clone ${ _.capitalize(product) }`,
          onClick: () => openPopup(PopupTypes.CLONE_EVENT, {
            leagueId: id,
          }),
        })
        dropdownOptions.push({
          label: 'Change Category',
          onClick: () => openPopup(PopupTypes.CHANGE_CATEGORY, {
            categoryId,
            leagueName: name,
            leagueId: id,
          }),
        })
        if (seasonsEnabled) {
          dropdownOptions.push({
            label: 'Change Season',
            onClick: () => openPopup(PopupTypes.CHANGE_SEASON, {
              leagueName: name,
              leagueId: id,
              seasonId,
            }),
          })
        }
      }
      if (toursEnabled) {
        dropdownOptions.push({
          label: 'Change Tour',
          onClick: () => openPopup(PopupTypes.CHANGE_TOUR, {
            leagueName: name,
            leagueId: id,
            tourId,
          }),
        })
      }
      if (showRegistration && openRegistrationData.hasOpenRegistration) {
        if (openRegistrationData.disabledRegistrationButton) {
          dropdownOptions.push({
            label: 'Open Registration',
            disabled: true,
            tooltip: 'You cannot open registration because you did not save the Open Registration form. Please verify and save the form to enable this option.',
            id,
          })
        } else {
          if (openRegistrationData.canInvitationsList || openRegistrationData.canUseMembershipPrograms) {
            dropdownOptions.push({
              label: 'Open/Close Registration',
              onClick: () => openPopup(PopupTypes.EXISTING_TIMES, {
                leagueId: id,
                openRegistrationStatus: openRegistrationData.openRegistrationStatus,
                canInvitationsList: openRegistrationData.canInvitationsList,
                canUseMembershipPrograms: openRegistrationData.canUseMembershipPrograms,
                futureMembershipProgramsNames: getFutureMembershipProgramsNames(openRegistrationData.membershipProgramsInfo, props.timeZone),
                registrationDatesURL: openRegistrationData.registrationDatesURL,
              }),
            })
          } else if (openRegistrationData.openRegistrationStatus === 'open') {
            dropdownOptions.push({
              label: 'Close Registration',
              onClick: () => openPopup(PopupTypes.EXISTING_TIMES, {
                leagueId: id,
                openRegistrationStatus: openRegistrationData.openRegistrationStatus,
                canInvitationsList: openRegistrationData.canInvitationsList,
                canUseMembershipPrograms: openRegistrationData.canUseMembershipPrograms,
                futureMembershipProgramsNames: getFutureMembershipProgramsNames(openRegistrationData.membershipProgramsInfo, props.timeZone),
                registrationDatesURL: openRegistrationData.registrationDatesURL,
              }),
              tooltip: 'Your registration will end automatically on the set end date. If you close registration by clicking here, the system will reset the selected end date.',
              id,
            })
          } else { // registration is closed
            dropdownOptions.push({
              label: 'Open Registration',
              onClick: () => openPopup(PopupTypes.EXISTING_TIMES, {
                leagueId: id,
                openRegistrationStatus: openRegistrationData.openRegistrationStatus,
                canInvitationsList: openRegistrationData.canInvitationsList,
                canUseMembershipPrograms: openRegistrationData.canUseMembershipPrograms,
                futureMembershipProgramsNames: getFutureMembershipProgramsNames(openRegistrationData.membershipProgramsInfo, props.timeZone),
                registrationDatesURL: openRegistrationData.registrationDatesURL,
              }),
              tooltip: 'Your registration will start automatically on the set start date. If you open registration by clicking here, the system will reset the selected start date.',
              id,
            })
          }
        }
      }

      let archiveOption
      if (categoryStatus === 'archived') {
        archiveOption = {
          label: 'Restore',
          onClick: () => restoreLeague(id),
        }
      } else {
        archiveOption = {
          label: 'Archive',
          onClick: () => {
            onConfirm(`Are you sure you want to archive ${ name }? ` +
              'You will still be able to access this event and you can always restore it.', () => {
              archiveLeague(id)
            })
          },
        }
      }
      dropdownOptions.push(archiveOption)
      dropdownOptions.push({
        label: 'Delete',
        onClick: () => {
          onConfirm(`Are you sure you want to delete ${ name }? `, () => {
            deleteLeague(id)
          })
        },
      })
    }
  }

  const membershipNames = _.join(openRegistrationData.membershipProgramsInfo.map(mp => mp.name), ', ')

  return <GlgTableRow { ...props } isSortable={ isSortable }>{isSortable && <GlgTableCell><Handle { ...props.dragHandleProps } canManage={canManage}></Handle></GlgTableCell>}<GlgTableCell><a href={ canManage || isTeamCaptain || isShotManager ? leagueURL : portalURL }>{ name }</a></GlgTableCell>{golfHubEnabled && <GlgTableCell><GlgLabel type={ getGolfHubPageValue(golfHub)}>{ golfHub }</GlgLabel></GlgTableCell>}{userIsManager && includeCategoryColumn && <GlgTableCell>{ categoryName }</GlgTableCell>}{userIsManager && showSeasonsColumn && <GlgTableCell><GlgLabel type="success">{ seasonName }</GlgLabel></GlgTableCell>}{userIsManager && showTours && <GlgTableCell><GlgLabel type="success">{ tourName }</GlgLabel></GlgTableCell>}<GlgTableCell>{ startDate }</GlgTableCell><GlgTableCell>{ golfers }</GlgTableCell>{showRegistration && <GlgTableCell>{registrationMessage && <Fragment><GlgLabel type={ getRegistrationLabelType(registrationMessage) }>{ registrationMessage }{registrationDisplaysTime(registrationMessage) && <GlgTooltipIcon tooltip={ `Date and time are displayed in ${ timeZoneDisplay } time` }></GlgTooltipIcon>}{ isRegistrationOpenForMembership(registrationMessage) && <GlgTooltipIcon tooltip={ `${ membershipNames }` }></GlgTooltipIcon>}</GlgLabel></Fragment>}</GlgTableCell>}{isExtraColumn(props.sortingColumn) && <GlgTableCell>{ props[props.sortingColumn] }{props[props.sortingColumn] && isRegistrationExtraColumn(props.sortingColumn) && <GlgTooltipIcon tooltip={ `Date and time are displayed in ${ timeZoneDisplay } time` }></GlgTooltipIcon>}</GlgTableCell>}<GlgTableCell>{userIsVisageAdmin || isTeamCaptain || isShotManager ? <a href={ leagueURL }>Go to { _.capitalize(product) }</a> : (canManage ? <GlgDropdown items={ dropdownOptions } direction="left"></GlgDropdown> : <a href={ portalURL }>Go to Portal</a>)}</GlgTableCell></GlgTableRow>
}

LeagueRowComponent.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  golfHub: PropTypes.string,
  product: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  registrationMessage: PropTypes.string,
  seasonId: PropTypes.string,
  seasonName: PropTypes.string,
  tourId: PropTypes.string,
  tourName: PropTypes.string,
  golfers: PropTypes.array,
  leagueURL: PropTypes.string.isRequired,
  portalURL: PropTypes.string,
  categoryId: PropTypes.string,
  categoryName: PropTypes.string,
  categoryStatus: PropTypes.string,
  canManage: PropTypes.bool.isRequired,
  includeCategoryColumn: PropTypes.bool,
  openRegistrationData: PropTypes.object.isRequired,
  timeZoneDisplay: PropTypes.string.isRequired,
  userIsCustomerManager: PropTypes.bool.isRequired,
  userIsTourManager: PropTypes.bool.isRequired,
  userIsVisageAdmin: PropTypes.bool.isRequired,
  isSortable: PropTypes.bool.isRequired,
  // From the sorting library; need to apply it to the handle
  dragHandleProps: PropTypes.object,
  showTours: PropTypes.bool.isRequired,
  toursEnabled: PropTypes.bool.isRequired,
  showSeasonsColumn: PropTypes.bool.isRequired,
  seasonsEnabled: PropTypes.bool.isRequired,
  showRegistration: PropTypes.bool.isRequired,
  sortingColumn: PropTypes.string,
  deleteLeague: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  archiveLeague: PropTypes.func.isRequired,
  restoreLeague: PropTypes.func.isRequired,
  isTeamCaptain: PropTypes.bool.isRequired,
  timeZone: PropTypes.string.isRequired,
  isShotManager: PropTypes.bool.isRequired,
  golfHubEnabled: PropTypes.bool,
  isTmLite: PropTypes.bool,
}

LeagueRowComponent.defaultProps = {
}

Handle.propTypes = {
  canManage: PropTypes.bool,
}

export default LeagueRowComponent
