import { combineReducers } from 'redux'
import filters from './filters'
import misc from './misc'
import leagues from './leagues'
import portals from './portals'
import tours from './tours'

export default combineReducers({
  misc,
  filters,
  leagues,
  portals,
  tours,
})
