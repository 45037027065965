import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GlgPopup } from 'SharedComponents'
import StatisticTableComponent from './statistic_table_component'

class StatisticPopupComponent extends Component {
  constructor(props) {
    super(props)
    this.handleRoundChange = this.handleRoundChange.bind(this)
  }

  handleRoundChange(roundId) {
    this.selectedRoundId = roundId
    this.props.loadStatisticData(this.props.statistic.key, roundId)
  }

  render() {
    const roundCount = this.props.rounds.length
    return (
      <GlgPopup modalClass='modal-lg large-popup'
                title={ this.props.title }
                show={ this.props.visible }
                onClose={ this.props.closePopup }
                showSaveButton={false}
                showCloseButton={false}>
        {
          roundCount > 5 ?
            <select className={'selectpicker form-control round_select'} value={this.props.selectedRound.id} onChange={(event => this.handleRoundChange(event.target.value))}>
              {
                this.props.rounds.map( round =>
                  <option key={round.id} value={round.id}>{round.name}</option>
                )
              }
            </select>
            :
            <div className={'btn-group'}>
              {this.props.rounds.map((round) => <a key={round.id} onClick={() => this.handleRoundChange(round.id)} className={`btn btn-default btn-xs round_select_btn ${round.id === this.props.selectedRound.id ? 'btsp_orange_button selected' : 'btsp_white_button'}`}>{round.name}</a>)}
            </div>
        }
        <StatisticTableComponent
            pathToPlayerStats={this.props.selectedRound.pathToPlayerStats}
            statKey={this.props.statistic.key}
            isPopup={true}
            columns={this.props.statistic.columns}
            content={this.props.statistic.content}/>
      </GlgPopup>
    )
  }
}

StatisticPopupComponent.propTypes = {
  visible: PropTypes.bool,
  closePopup: PropTypes.func,
  title: PropTypes.string,
  rounds: PropTypes.array,
  statistic: PropTypes.object,
  loadStatisticData: PropTypes.func,
  selectedRound: PropTypes.object,
}

export default StatisticPopupComponent
