import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'

import { GlgTable, GlgTableCol } from 'SharedComponents'
import LeaderboardRowComponent from './leaderboard_row_component'
import { getColumnName, getColumnWidth } from 'Shared/course_boards'
import {GlgLoadingSpinner} from '../../shared/components'

class LeaderboardTableComponent extends Component {
  constructor(props) {
    super(props)
    this.renderHeaderCell = this.renderHeaderCell.bind(this)
    this.getSortableColumnHeader = this.getSortableColumnHeader.bind(this)
    this.getSortIcon = this.getSortIcon.bind(this)
    this.sortTableByColumn = this.sortTableByColumn.bind(this)
  }

  componentDidMount() {
    if (this.props.players === undefined || this.props.players.length === 0){
      setTimeout( () => this.props.forceRefresh(), 5000)
    }
  }

  renderHeaderCell(column) {
    let cellData
    if (column === 'actions') {
      cellData = getColumnName(column)
    } else {
      cellData = this.getSortableColumnHeader(column)
    }
    return (
      <GlgTableCol dataKey={column} key={column} width={getColumnWidth(column)}>
        {cellData}
      </GlgTableCol>
    )
  }

  getSortableColumnHeader(column) {
    const columnName = getColumnName(column)
    const sortIcon = this.getSortIcon(column)
    return (
      <div className='header_cell' onClick={() => this.sortTableByColumn(column)}>
        <span>{columnName}</span>
        {sortIcon}
      </div>
    )
  }

  getSortIcon(column) {
    if (column === this.props.sortParams.column){
      if (this.props.sortParams.sortOrder === 'asc'){
        return (
          <div className='icon_wrapper'>
            <img src={require('widgets/asc.gif')} />
          </div>
        )
      } else {
        return (
          <div className='icon_wrapper'>
            <img src={require('widgets/desc.gif')} />
          </div>
        )
      }
    } else {
      return (
        <span className='on_hover_icons'>
          <i className='gga-carret-up'></i>
          <i className='gga-carret-down'></i>
        </span>
      )
    }
  }

  sortTableByColumn(column) {
    const sortOrder = this.props.sortParams.column === column ? (this.props.sortParams.sortOrder === 'asc' ? 'desc' : 'asc') : 'asc'
    this.props.changeColumnSorting(column, sortOrder)
  }

  render() {
    const { players, columns, addLine, removeLine} = this.props
    const rowExtraProps = {
      columns: columns,
      addLine: addLine,
      removeLine: removeLine,
    }
    
    return (
      <Fragment>
        <Col xs={12} sm={12} md={6} lg={6} className='no-padding'>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className='page_title'>
              <div className='text'>{ window.I18n.t('course_leaderboards.live_leaderboard') }</div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className='live_leaderboard_table add-margin-bottom-20'>
            {
              players === undefined || players.length === 0 ?
                <GlgLoadingSpinner className={'text-center'}/>
                :
                <GlgTable items={players}
                          rowComponent={ LeaderboardRowComponent}
                          rowExtraProps={ rowExtraProps }
                          maxHeight={ this.props.height }>
                  {
                    columns.map( column => this.renderHeaderCell(column) )
                  }
                </GlgTable>
            }
          </Col>
        </Col>
      </Fragment>
    )

  }
}

LeaderboardTableComponent.propTypes = {
  players: PropTypes.array,
  columns: PropTypes.array,
  sortParams: PropTypes.object,
  changeColumnSorting: PropTypes.func,
  addLine: PropTypes.func,
  removeLine: PropTypes.func,
  height: PropTypes.number,
  forceRefresh: PropTypes.func,
}

export default LeaderboardTableComponent
