import React from 'react'
import PropTypes from 'prop-types'
import rootReducer from '../reducers'

import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import AdminNavigatorOptionsContainer from './admin_navigator_options_container'

const AdminNavigatorOptionsRoot = ({
  navigatorOptions,
  misc,
}) => {
  const state = {
    navigatorOptions,
    misc,
  }
  const store = configureStore(rootReducer, state)

  return <GlobalProvider store={ store }><AdminNavigatorOptionsContainer></AdminNavigatorOptionsContainer></GlobalProvider>
}

AdminNavigatorOptionsRoot.propTypes = {
  navigatorOptions: PropTypes.object.isRequired,
  misc: PropTypes.object.isRequired,
}

export default AdminNavigatorOptionsRoot
