import React, { Component } from 'react'
import PropTypes from 'prop-types'

import PopupTypes from '../../popup_types'
import GeneralCreateEventButton from './general_create_event_button'

class CloneEventButton extends Component {
  constructor(props) {
    super(props)
    this.openPopup = () => props.openPopup.bind(this, PopupTypes.CLONE_EVENT)()
    this.actionText = ''
  }

  getActionName() {
    if (this.props.customerOnlyEvents) {
      return (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.clone_event_button.clone_event'))
    } else if (this.props.customerOnlyLeagues) {
      return (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.clone_event_button.clone_league'))
    } else if (!this.props.canCreateTrips) {
      return (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.clone_event_button.clone_event_league'))
    } else {
      return (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.clone_event_button.clone_event_league_trip'))
    }
  }

  getActionDescription() {
    if (this.props.customerOnlyEvents) {
      return (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.clone_event_button.start_prev_event'))
    } else if (this.props.customerOnlyLeagues) {
      this.actionText = (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.clone_event_button.create_new_league'))
      if (this.props.customerNeedsSubscription) {
        this.actionText += (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.clone_event_button.card_charged'))
      }
      return this.actionText
    } else if (!this.props.canCreateTrips) { 
      return (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.clone_event_button.start_previous'))
    } else {
      return (!window.I18n ? '' : window.I18n.t('customer_center_comp.components.action_buttons.clone_event_button.start_from_previous'))
    }
  }

  render() {
    return <GeneralCreateEventButton actionName={ this.getActionName() } actionDescription={ this.getActionDescription() } iconName="golf-balls" openPopup={ this.openPopup } disabled={ this.props.disabled }></GeneralCreateEventButton>
  }
}

CloneEventButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  customerOnlyEvents: PropTypes.bool.isRequired,
  customerOnlyLeagues: PropTypes.bool.isRequired,
  canCreateTrips: PropTypes.bool.isRequired,
  customerNeedsSubscription: PropTypes.bool.isRequired,
  openPopup: PropTypes.func.isRequired,
  canCreateDCP: PropTypes.bool,
}

export default CloneEventButton
