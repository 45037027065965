import { combineReducers } from 'redux'
import misc from './misc'
import popup from './popup'
import filters from './filters'
import station from './station'

export default combineReducers({
  misc,
  popup,
  filters,
  station,
})
