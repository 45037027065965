import React, { Component } from 'react'
import PropTypes from 'prop-types'

class MatchPlayBoardTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      matchLines: props.matchLines,
      acknowledgedLines: props.acknowledgedLines,
      refreshUrl: props.refreshUrl,
      acknowedgedDate: props.acknowedgedDate,
      lastUpdatedDate: props.lastUpdatedDate,
      acknowledgeUrl: props.acknowledgeUrl,
      isTeam: props.isTeam,
      teamNames: props.teamNames,
      todayPoints: props.todayPoints,
      totalPoints: props.totalPoints,
      acknowledgedTodayPoints: props.acknowledgedTodayPoints,
      acknowledgedTotalPoints: props.acknowledgedTotalPoints,
    }
  }

  isDifferentStatus(status1, status2) {
    return (status1 !== '' && status1 !== status2)
  }
  
  isDifferentHole(hole1, hole2) {
    return hole1 !== hole2
  }
  

  componentDidMount() {
    this.intervalId = setInterval(this.fetchData, 20000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  getTeamSummaryBackgroundColor(index) {
    const { todayPoints, totalPoints, acknowledgedTodayPoints, acknowledgedTotalPoints } = this.state

    if (!acknowledgedTodayPoints || !acknowledgedTotalPoints) {
      return 'transparent'
    }

    if (
      todayPoints[index] !== acknowledgedTodayPoints[index] || 
      totalPoints[index] !== acknowledgedTotalPoints[index]
    ) {
      return 'yellow'
    }

    return 'transparent'
  }

  getHoleToShow(hole) {
    if (hole === 18) {
      return 'F'
    }
    if (hole === 0) {
      return '-'
    }
    return hole
  }

  fetchData = () => {
    const { refreshUrl } = this.props
    fetch(refreshUrl)
      .then(response => response.json())
      .then(data => {
        this.setState({ matchLines: data.matchLines,
          acknowledgedLines: data.acknowledgedLines,
          refreshUrl: data.refreshUrl,
          acknowledgedDate: data.acknowedgedDate,
          lastUpdatedDate: data.lastUpdatedDate,
          todayPoints: data.todayPoints,
          totalPoints: data.totalPoints,
          acknowledgedTodayPoints: data.acknowledgedTodayPoints,
          acknowledgedTotalPoints: data.acknowledgedTotalPoints})
      })
      .catch(error => {
        console.error('Error fetching data:', error)
      })
  }

  enableAcknowledgeButton = () => {
    const { matchLines, acknowledgedLines } = this.state
    for (let i = 0; i < matchLines.length; i++) {
      const currentLine = matchLines[i]
      const acknowledgedLine = acknowledgedLines[i]
      if (this.isDifferentStatus(currentLine.statuses[0], acknowledgedLine.statuses[0]) ||
          this.isDifferentStatus(currentLine.statuses[1], acknowledgedLine.statuses[1]) ||
          this.isDifferentHole(currentLine.hole, acknowledgedLine.hole)) {
        return true
      }
    }
    return false
  }

  handleAcknowledge = () => {
    const { acknowledgeUrl } = this.props

    const confirmAcknowledge = window.confirm(window.I18n.t('match_play_board.table.acknowledge_confirm'))

    if (confirmAcknowledge) {
      fetch(acknowledgeUrl, {
        method: 'POST',
      })
      .then(response => response.json())
      .then(data => {
        this.setState({
          matchLines: data.matchLines,
          acknowledgedLines: data.acknowledgedLines,
          acknowedgedDate: data.acknowedgedDate,
          lastUpdatedDate: data.lastUpdatedDate,
          todayPoints: data.todayPoints,
          totalPoints: data.totalPoints,
          acknowledgedTodayPoints: data.acknowledgedTodayPoints,
          acknowledgedTotalPoints: data.acknowledgedTotalPoints})
      })
      .catch(error => {
        console.error('Error on acknowledge:', error)
      })
    }
  }

  render() {
    const { matchLines, acknowledgedLines, acknowedgedDate, lastUpdatedDate, isTeam, teamNames, todayPoints, totalPoints } = this.state
    const enableAcknowledgeButton = this.enableAcknowledgeButton()
    const acknowledgedStyle = enableAcknowledgeButton ? {} : {color: 'gray', opacity: 0.5 }

    return (
      <div>
        {isTeam && teamNames.length < 4 && (
          <table className='summary table-striped tables_v2'>
            <thead>
              <tr>
                <th>{window.I18n.t('match_play_board.table.today')}</th>
                <th>{window.I18n.t('match_play_board.table.team')}</th>
                <th>{window.I18n.t('match_play_board.table.total')}</th>
              </tr>
            </thead>
            <tbody>
              {teamNames.map((teamName, index) => (
                <tr key={teamName}>
                  <td style={{ color: teamName[1], backgroundColor: this.getTeamSummaryBackgroundColor(index) }}>{todayPoints[index]}</td>
                  <td style={{ color: teamName[1] }}>{teamName[0]}</td>
                  <td style={{ color: teamName[1], backgroundColor: this.getTeamSummaryBackgroundColor(index) }}>{totalPoints[index]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className='dates-container' style={{ border: '1px solid black', padding: '10px', marginBottom: '10px' }}>
          <p>{window.I18n.t('match_play_board.table.acknowledged_date')} {acknowedgedDate}</p>
          <p>{window.I18n.t('match_play_board.table.updated_date')} {lastUpdatedDate}</p>
        </div>
        <div className='acknowledge-btn-container'>
            <button className='acknowledge-btn' onClick={this.handleAcknowledge} disabled={!enableAcknowledgeButton} style={acknowledgedStyle}>
            {window.I18n.t('match_play_board.table.acknowledged')}
            </button>
        </div>
        <table className='table-striped tables_v2'>
            <thead>
            <tr>
                <th className='status-header'>{ window.I18n.t('match_play_board.table.status') }</th>
                <th className='players-header'></th>
                <th className='hole-header'>{ window.I18n.t('match_play_board.table.hole') }</th>
                <th className='players-header'></th>
                <th className='status-header'>{ window.I18n.t('match_play_board.table.status') }</th>
            </tr>
            </thead>
            <tbody>
                {matchLines.map((item, index) => {
                    const acknowledgedLine = acknowledgedLines[index]
                    const winnerIndex = item.statuses.findIndex(status => status !== '')
                    return (
                    <tr key={ item.id } >
                        <td style={acknowledgedLine && this.isDifferentStatus(item.statuses[0], acknowledgedLine.statuses[0]) ? {backgroundColor: 'yellow'} : {}}>
                            { (winnerIndex === 0 || item.statuses[0] === 'Tied') ? item.statuses[0] : '' }</td>
                        <td>
                            { isTeam ? item.member_names[0].slice(0, -1).map((name, idx) => (
                                <div key={idx} style={{ color: item.member_names[0].slice(-1)[0] }}>{name}</div>
                            )) : item.member_names[0]}
                        </td>
                        <td style={acknowledgedLine && this.isDifferentHole(item.hole, acknowledgedLine.hole) ? {backgroundColor: 'yellow'} : {}}>
                            { this.getHoleToShow(item.hole) }</td>
                        <td>
                            { isTeam ? item.member_names[1].slice(0, -1).map((name, idx) => (
                                <div key={idx} style={{ color: item.member_names[1].slice(-1)[0] }}>{name}</div>
                            )) : item.member_names[1]}
                        </td>
                        <td style={acknowledgedLine && this.isDifferentStatus(item.statuses[1], acknowledgedLine.statuses[1]) ? {backgroundColor: 'yellow'} : {}}>
                            { (winnerIndex === 1 || item.statuses[0] === 'Tied') ? item.statuses[1] : '' }</td>
                    </tr>
                    )
                })}
            </tbody>
        </table>
      </div>
    )
  }
}

MatchPlayBoardTable.propTypes = {
  matchLines: PropTypes.array,
  acknowledgedLines: PropTypes.array,
  refreshUrl: PropTypes.string,
  acknowedgedDate: PropTypes.string,
  lastUpdatedDate: PropTypes.string,
  acknowledgeUrl: PropTypes.string,
  isTeam: PropTypes.bool,
  teamNames: PropTypes.array,
  todayPoints: PropTypes.array,
  totalPoints: PropTypes.array,
  acknowledgedTodayPoints: PropTypes.array,
  acknowledgedTotalPoints: PropTypes.array,
}

export default MatchPlayBoardTable
