import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup } from 'react-bootstrap'
import { Row, Col } from 'react-flexbox-grid'

import { mapToSelectArray } from 'Shared/helpers'
import { GlgSelect, GlgTooltipIcon } from 'SharedComponents'

class TourFilter extends Component {
  constructor(props) {
    super(props)
    this.onChange = props.onChange.bind(this, 'tour')
  } 

  shouldComponentUpdate(nextProps) {
    // The content of the tours doesn't change, so it's enough to check the count
    return this.props.toursOptions.length !== nextProps.toursOptions.length ||
           this.props.value !== nextProps.value || this.props.disabled !== nextProps.disabled
  }

  render() {
    const toursSelectOptions = mapToSelectArray(this.props.toursOptions, { changeNone: false })

    return <FormGroup><Row><Col xs={true}><h4><span className="label filter-label"><div className="add-margin-top-5"><span>{ !window.I18n ? '' : window.I18n.t('customer_directories.filters.tour_filter_title') }</span><span className="pull-right"><GlgTooltipIcon tooltip={ !window.I18n ? '' : window.I18n.t('customer_directories.filters.tour_filter_tooltip') }><i className="gga-info-circle"></i></GlgTooltipIcon></span></div></span></h4></Col></Row><Row><Col xs={true}><GlgSelect items={ toursSelectOptions } value={ this.props.value } onChange={ this.onChange } disabled={this.props.disabled}></GlgSelect></Col></Row></FormGroup>
  }
}

TourFilter.propTypes = {
  toursOptions: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default TourFilter
