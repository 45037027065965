export const ActionTypes = {
  SET_EMAIL_DELIVERY_REPORTS: 'SET_EMAIL_DELIVERY_REPORTS',
  SET_TEXT_MESSAGE_DELIVERY_REPORTS: 'SET_TEXT_MESSAGE_DELIVERY_REPORTS',
  SET_EMAIL_FILTER_VALUE: 'SET_EMAIL_FILTER_VALUE',
  SET_TEXT_MESSAGE_FILTER_VALUE: 'SET_TEXT_MESSAGE_FILTER_VALUE',
}

export const setCurrentEmailReports = (emailDeliveryReports, page) => {
  return {
    type: ActionTypes.SET_EMAIL_DELIVERY_REPORTS,
    reports: emailDeliveryReports,
    page: page,
  }
}

export const setCurrentTextMessageReports = (textMessageDeliveryReports, page) => {
  return {
    type: ActionTypes.SET_TEXT_MESSAGE_DELIVERY_REPORTS,
    reports: textMessageDeliveryReports,
    page: page,
  }
}

export const setEmailFilterValue = (filterType, filterValue) => {
  return {
    type: ActionTypes.SET_EMAIL_FILTER_VALUE,
    filterType: filterType,
    filterValue: filterValue,
  }
}

export const setTextMessageFilterValue = (filterType, filterValue) => {
  return {
    type: ActionTypes.SET_TEXT_MESSAGE_FILTER_VALUE,
    filterType: filterType,
    filterValue: filterValue,
  }
}
