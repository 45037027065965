import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const popupVerifyScoresTeamComponent = ({isMultiRound, scoreData, handleScoreChange, matchedScores, handleKeyPress, playerIndex}) => {
  const scores = [ ...scoreData.scores_array ]
  const hasAllOutScores = !scores.slice(0, 9).includes(undefined) && !scores.slice(0, 9).includes(null)
  const hasAllInScores = !scores.slice(9).includes(undefined) && !scores.slice(9).includes(null)

  return (
    <tr className='verify_scores_row with_delimiter'>
      <td className='with_border'>Verify</td>
      { [ ...Array(9) ].map( ( _, i) => (
        <Fragment key={i}>
          <td key={'hole-' + i} className={matchedScores[i] ? 'with_border' : 'with_border yellow_column'}>
            <input className='new_score' 
                  name={'score-' + i}
                  inputMode='numeric'
                  autoComplete='off'
                  maxLength={2}
                  value={scores[i] !== 0 && scores[i] !== null ? scores[i] : ''} 
                  onChange={(e) => handleScoreChange(playerIndex, i, e.target.value)} 
                  onFocus={(e) => e.target.select()}
                  onKeyDown={(e) => handleKeyPress(playerIndex, i, e)} />
          </td>
          { (i + 1) % 3 === 0 && i !== 8 && <td className="with_border gray_column"></td> }
        </Fragment>
      )) }
      <td className='with_border blue_column'>{scoreData.out !== 0 && hasAllOutScores ? scoreData.out : ''}</td>
      { [ ...Array(9) ].map( ( _, i) => (
        <Fragment key={i}>
          <td key={'hole-' + i} className={matchedScores[i + 9] ? 'with_border' : 'with_border yellow_column'}>
            <input className='new_score' 
                  name={'score-' + (i + 9)} 
                  inputMode='numeric'
                  maxLength={2}
                  value={scores[i + 9] !== 0 && scores[i + 9] !== null ? scores[i + 9] : ''} 
                  onChange={(e) => handleScoreChange(playerIndex, i + 9, e.target.value)} 
                  onFocus={(e) => e.target.select()}
                  onKeyDown={(e) => handleKeyPress(playerIndex, i + 9, e)} />
          </td>
          { (i + 1) % 3 === 0 && i !== 8 && <td className="with_border gray_column"></td> }
        </Fragment>
      )) }
      <td className='with_border blue_column'>{scoreData.in !== 0 && hasAllInScores ? scoreData.in : ''}</td>
      <td className='with_border blue_column'>{scoreData.round_score !== 0 && hasAllOutScores && hasAllInScores ? scoreData.round_score : ''}</td>
      { isMultiRound && <td className='with_border blue_column'>{scoreData.total_score !== 0 ? scoreData.total_score : ''}</td> }
    </tr>
  )
}

popupVerifyScoresTeamComponent.propTypes = {
  isMultiRound: PropTypes.bool,
  scoreData: PropTypes.object,
  handleScoreChange: PropTypes.func,
  matchedScores: PropTypes.array,
  handleKeyPress: PropTypes.func,
}

export default popupVerifyScoresTeamComponent
