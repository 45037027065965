import React, { Component } from 'react'
import GlgSelect from '../../../shared/components/glg_select'
import PropTypes from 'prop-types'
import CreditBookComponent from './credit_book_component'
import { Row, Col } from 'react-bootstrap'

import {
  GlgTable,
  GlgTableCol,
  GlgButton,
  GlgLoadingSpinner,
} from 'SharedComponents'

import TransactionsHistoryTableRow from './transactions_history_table_row'
import PaymentMethodsComponent from './payment_methods_component'

class TransactionsHistoryComponent extends Component {
  constructor(props) {
    super(props)
    this.filterTransactionsHistory = this.props.filterTransactionsHistory.bind(this)
    this.state = {
      excelButtonDisabled: false,
      excelButtonText: !window.I18n ? '' : window.I18n.t('player_activity.transaction_logs.export_to_excel_btn'),
    }
    this.exportToExcel = this.exportToExcel.bind(this)
    this.propsExportToExcel = this.props.exportToExcel.bind(this)
  }
  
  exportToExcel() {
    this.setState({excelButtonDisabled: true, excelButtonText: !window.I18n ? '' : window.I18n.t('player_activity.transaction_logs.export_to_excel_btn_disable')})
    this.propsExportToExcel()
  }
  
  render() {
    let table = ''
    let exportToExcelButton
    if (!this.props.dataIsLoading) {
      if (this.props.hasTransactions) {
        const extraProps = { target: this.props.target }
        table = <GlgTable items={ this.props.transactions } rowComponent={ TransactionsHistoryTableRow } rowExtraProps={ extraProps }><GlgTableCol width={ '15%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.transaction_logs.event') }></GlgTableCol><GlgTableCol width={ '35%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.transaction_logs.description') }></GlgTableCol><GlgTableCol width={ '20%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.transaction_logs.date_time') }></GlgTableCol><GlgTableCol width={ '10%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.transaction_logs.amount') }></GlgTableCol><GlgTableCol width={ '10%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.transaction_logs.status') }></GlgTableCol><GlgTableCol width={ '10%' } dataKey={ !window.I18n ? '' : window.I18n.t('player_activity.transaction_logs.actions') }></GlgTableCol></GlgTable>
        exportToExcelButton = <GlgButton onClick={ this.exportToExcel } text={ this.state.excelButtonText } disabled={ this.state.excelButtonDisabled } className="btsp_small_orange_button"></GlgButton>
      } else {
        table = <div className="no-info">{ !window.I18n ? '' : window.I18n.t('player_activity.transaction_logs.no_transactions') }</div>
      }
    }
    
    return <div><Row xs={ 12 }><CreditBookComponent creditBook={ this.props.personalData } editRegistrationLink={ this.props.editRegistrationLink } dataIsLoading={ this.props.dataIsLoading } target={ this.props.target } MRR={ this.props.MRR } cbEnabled={ this.props.cbEnabled } cbBalance={ this.props.cbBalance } currency={ this.props.currency }></CreditBookComponent></Row><Row xs={ 12 }><PaymentMethodsComponent payments={ this.props.payments } dataIsLoading={ this.props.dataIsLoading } target={ this.props.target } deletePaymentMethod={ this.props.deletePaymentMethod } defaultPaymentMethod={ this.props.defaultPaymentMethod }></PaymentMethodsComponent></Row><Row className="transactions"><Col xs={ 12 } className="title">{ !window.I18n ? '' : window.I18n.t('player_activity.transaction_logs.transactions') }</Col><Col xs={ 12 } sm={ 5 }><div className="panel panel-default short-panel"><div className="panel-body"><Col xs={ 5 } sm={ 4 }><div className="panel-body-title">{ !window.I18n ? '' : window.I18n.t('player_activity.transaction_logs.event') }</div></Col><Col xs={ 7 } sm={ 8 }><GlgSelect items={ this.props.events } onChange={ this.filterTransactionsHistory } value={ this.props.eventId }></GlgSelect></Col></div></div></Col><Col xs={ 12 } sm={ 7 } className="export-excel">{ exportToExcelButton }</Col><Col xs={ 12 }><GlgLoadingSpinner show={ this.props.dataIsLoading }></GlgLoadingSpinner>{ table }</Col></Row></div>
  }
}

TransactionsHistoryComponent.propTypes = {
  transactions: PropTypes.array.isRequired,
  playerName: PropTypes.string.isRequired,
  hasTransactions: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  filterTransactionsHistory: PropTypes.func,
  exportToExcel: PropTypes.func,
  eventId: PropTypes.string,
  dataIsLoading: PropTypes.bool,
  target: PropTypes.string,
  payments: PropTypes.object,
  deletePaymentMethod: PropTypes.func,
  defaultPaymentMethod: PropTypes.func,
  editRegistrationLink: PropTypes.string.isRequired,
  creditBook: PropTypes.array.isRequired,
  MRR: PropTypes.bool,
  cbEnabled: PropTypes.bool,
  cbBalance: PropTypes.number,
  currency: PropTypes.string,
  personalData: PropTypes.array.isRequired,
}

export default TransactionsHistoryComponent
