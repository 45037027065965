import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {FormGroup, InputGroup, FormControl} from 'react-bootstrap'
import {Icon as FaIcon} from 'react-fa'

const {Addon} = InputGroup

const GolferNameFilter = ({filterGolfers, onEnterKeyPress}) => {

  const [ value, setValue ] = useState('')
  const inputRef = useRef(null)

  useEffect(() => {
    filterGolfers(value.toLowerCase())
  }, [ value ])

  const onChange = (event) => {
    const newValue = event.target.value
    setValue(newValue)
  }

  const onReset = () => {
    setValue('')
    inputRef.current.focus()
  }

  return (
    <FormGroup className="golfer_search filter btsp_filter">
      <InputGroup>
        <FormControl type="text" value={value}
                     placeholder={window.I18n.t('draft.components.manager_side.golfer_name_filter.search_placeholder')}
                     onChange={onChange} inputRef={r => (inputRef.current = r)}
                     onKeyPress={e => e.key === 'Enter' && onEnterKeyPress(setValue)}/>
        <Addon><FaIcon name="eraser" onClick={onReset}/></Addon>
      </InputGroup>
    </FormGroup>
  )
}

GolferNameFilter.propTypes = {
  filterGolfers: PropTypes.func,
  onEnterKeyPress: PropTypes.func,
}

export default GolferNameFilter
