import { ActionTypes } from '../actions'

const {
  CHANGE_DISPLAY_OPTION_FILTER,
  CHANGE_COURSE_FILTER,
  CHANGE_STARTING_HOLE_FILTER,
  CHANGE_SORT_OPTION,
  CHANGE_SORT_ORDER_OPTION,
  CHANGE_ROUND_RECEIVED,
  SHOW_HIDE_FILTERS,
} = ActionTypes

const initialState = {
  sortByOption: 'pairing',
  sortOrder: 'asc',
  selectedHole: 'all',
  displayOption: 'scores',
  selectedCourse: 'all',
  showFilters: false,
}

const filters = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_DISPLAY_OPTION_FILTER:
      return {
        ...state,
        displayOption: action.value,
      }
    case CHANGE_COURSE_FILTER:
      return {
        ...state,
        selectedCourse: action.value,
      }
    case CHANGE_STARTING_HOLE_FILTER:
      return {
        ...state,
        selectedHole: action.value,
      }
    case CHANGE_SORT_OPTION:
      return {
        ...state,
        sortByOption: action.value,
      }
    case CHANGE_SORT_ORDER_OPTION:
      return {
        ...state,
        sortOrder: action.value,
      }
    case CHANGE_ROUND_RECEIVED:
      return {
        ...state,
        selectedCourse: 'all',
        selectedHole: 'all',
      }
    case SHOW_HIDE_FILTERS:
      return {
        ...state,
        showFilters: action.value,
      }
    default:
      return state
  }
}

export default filters
