import { showErrorNotification, showNotification, callAPI } from 'Shared/helpers'

export const ActionTypes = {
  REFRESH_DATA: 'REFRESH_DATA',
  OPEN_VERIFICATION_POPUP: 'OPEN_VERIFICATION_POPUP',
  CLOSE_POPUP: 'CLOSE_POPUP',
  OPEN_TEAM_VERIFICATION_POPUP: 'OPEN_TEAM_VERIFICATION_POPUP',
  CLOSE_TEAM_POPUP: 'CLOSE_POPUP',
  VERIFY_SCORE: 'VERIFY_SCORE',
  VERIFY_TEAM_SCORE: 'VERIFY_TEAM_SCORE',
}

export const refreshDataAction = (data) => ({
  type: ActionTypes.REFRESH_DATA,
  data,
})

export const openPopupAction = (playerId, playerName, scoreData, shots, pars, teeTime) => ({
  type: ActionTypes.OPEN_VERIFICATION_POPUP,
  playerId,
  playerName,
  scoreData,
  shots,
  pars,
  teeTime,
})

export const openTeamPopupAction = (players, scoresData, teamName, teeTime, foursomeId, teamTotals, stablefordPoints) => ({
  type: ActionTypes.OPEN_TEAM_VERIFICATION_POPUP,
  players,
  scoresData,
  teamName,
  teeTime,
  foursomeId,
  teamTotals,
  stablefordPoints,
})

export const closePopupAction = () => ({
  type: ActionTypes.CLOSE_POPUP,
})

export const verifyScoreAction = (data) => ({
  type: ActionTypes.VERIFY_SCORE,
  data,
})

export const verifyTeamScoreAction = (data) => ({
  type: ActionTypes.VERIFY_TEAM_SCORE,
  data,
})

export const refreshData = () => (dispatch, getState) => {
  const state = getState()
  const url = state.misc.refreshDataUrl
  callAPI(url, 'GET', {})
    .then(response => {
      dispatch(refreshDataAction(response))
    })
    .catch(() => {
      showErrorNotification('Error while refreshing data!')
    })
}

export const openPopup = (playerId, playerName, scoreData, shots, pars, teeTime) => (dispatch) => {
  dispatch(openPopupAction(playerId, playerName, scoreData, shots, pars, teeTime))
}

export const openTeamPopup = (players, scoresData, teamName, teeTime, foursomeId, teamTotals, stablefordPoints) => (dispatch) => {
  dispatch(openTeamPopupAction(players, scoresData, teamName, teeTime, foursomeId, teamTotals, stablefordPoints))
}

export const closePopup = () => (dispatch) => {
  dispatch(closePopupAction())
}

export const verifyScore = (oldScores, newScores) => (dispatch, getState) => {
  const state = getState()

  const step = state.misc.step
  const playerId = state.popup.playerId
  const url = state.misc.verifyScoresUrl
  
  oldScores = oldScores.map( score => score === null ? '' : score )
  newScores = newScores.map( score => score === null ? '' : score )

  const params = {}
  params['step'] = step
  params['player_id'] = playerId
  params['old_scores[]'] = oldScores
  params['new_scores[]'] = newScores
  
  callAPI(url, 'PUT', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch(closePopupAction())
      dispatch(verifyScoreAction(response))
      showNotification('Player successfully verified!')
    })
    .catch(() => {
      dispatch(closePopupAction())
      showErrorNotification('Error while verifing scores!')
    })
}

export const verifyTeamScore = (scores) => (dispatch, getState) => {
  const state = getState()
  const url = state.misc.verifyTeamScoresUrl
  const players = state.teamPopup.players
  const teamName = state.teamPopup.teamName
  const foursomeId = state.teamPopup.foursomeId
  const step = state.misc.step
  const params = {}
  const playersData = []
  players.forEach((player, index) => {
    const data = {}
    data['player_id'] = player.id
    data['player_scores'] = scores[index].scores_array
    data['original_scores'] = player.score_data.original_scores
    data['team_name'] = teamName
    data['foursome_id'] = foursomeId
    data['team_ids'] = player.team_ids
    playersData.push(data)
  })
  params['step'] = step
  params['players_data[]'] = JSON.stringify(playersData)

  callAPI(url, 'PUT', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch(closePopupAction())
      dispatch(verifyTeamScoreAction(response))
      showNotification('Team successfully verified!')
    })
    .catch(() => {
      dispatch(closePopupAction())
      showErrorNotification('Error while verifing scores!')
    })
}
