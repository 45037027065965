/*
 * Returns leagues that pass all filters
 * Received optional 3rd parameter categoryId: it only takes leagues from that category
 */
import _ from 'lodash'
import moment from 'moment'
import createDeepSelector from './create_deep_selector'

import checkShowTours from './check_show_tours'
import EventStatusFilterTypes from '../event_status_filter_types'

const getLeaguesById = state => state.leagues
const isEmpty = (value) => _.isNil(value) || value === ''

const getFilters = state => {
  const filters = _.clone(state.filters)
  const showTours = checkShowTours(state, { checkLeagues: true })

  if (!showTours) {
    filters['tour'] = 'all'
  }
  return filters
}

const getLeaguesIds = state => {
  return _.keys(state.leagues)
}

export const filterLeagueIds = (leagueIds, leaguesById, filters) => {
  let {
    leagueName,
  } = filters
  const {
    tour,
    status,
    endDate,
    startDate,
    location,
  } = filters

  if (location) {
    if (location.coords.lat && location.coords.lng && location.radius) {
      const lat1 = location.coords.lat
      const lng1 = location.coords.lng
      const radius = parseInt(location.radius) * 1.6 * 1000

      leagueIds = leagueIds.filter(leagueId => {
        const lat2 = leaguesById[leagueId].coords.lat
        const lng2 = leaguesById[leagueId].coords.lng

        const R = 6371e3 // metres
        const f1 = lat1 * Math.PI / 180 //Convert to radians
        const f2 = lat2 * Math.PI / 180
        const df = (lat2 - lat1) * Math.PI / 180 
        const dl = (lng2 - lng1) * Math.PI / 180

        const a = Math.sin(df / 2) * Math.sin(df / 2) +
                Math.cos(f1) * Math.cos(f2) *
                Math.sin(dl / 2) * Math.sin(dl / 2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

        const d = R * c

        return (d < radius) || leaguesById[leagueId].registered || !(lat2 || lng2)
      })
    }
  }
  if (startDate) {
    leagueIds = leagueIds.filter(leagueId => (
      leaguesById[leagueId].registered || moment(leaguesById[leagueId].startDate).isSameOrAfter(moment(startDate, 'LLLL'))
    ))
  }
  if (endDate) {
    leagueIds = leagueIds.filter(leagueId => (
      leaguesById[leagueId].registered || moment(leaguesById[leagueId].endDate).isSameOrBefore(moment(endDate, 'LLLL'))
    ))
  }
  if (leagueName.length > 0) {
    leagueName = leagueName.toLowerCase()
    leagueIds = leagueIds.filter(leagueId => (
      leaguesById[leagueId].name.toLowerCase().includes(leagueName) || leaguesById[leagueId].courses.join('|').toLowerCase()
      .includes(leagueName)//course
    ))
  }

  if (tour !== 'all' && !(location.coords.lat && location.coords.lng && location.radius)) {
    leagueIds = leagueIds.filter(leagueId => (
      leaguesById[leagueId].registered || leaguesById[leagueId].tour === tour ||
      (tour === 'none' && _.isEmpty(leaguesById[leagueId].tour)) || leaguesById[leagueId].secondaryTours.includes(tour)
    ))
  }

  if (!status.includes(EventStatusFilterTypes.ALL)) {
    let filterMethod
    let openLeagueIds = [], registeredLeagueIds = [], pastLeagueIds = [], upcomingLeagueIds = [], lotteryLeagueIds = []
    if (status.includes(EventStatusFilterTypes.OPEN)) {
      filterMethod = leagueId => {
        return leaguesById[leagueId].registrationInfo.status === 'Open' || leaguesById[leagueId].registrationInfo.status === 'Invitation List'
      }
      openLeagueIds = leagueIds.filter(filterMethod)
    }
    if (status.includes(EventStatusFilterTypes.REGISTERED)) {
      filterMethod = leagueId => {
        return leaguesById[leagueId].registered === true
      }
      registeredLeagueIds = leagueIds.filter(filterMethod)
    }
    if (status.includes(EventStatusFilterTypes.PAST)) {
      filterMethod = leagueId => {
        const datesToSort = leaguesById[leagueId].datesToSort
        return moment(datesToSort).isBefore(moment().startOf('day'))
      }
      pastLeagueIds = leagueIds.filter(filterMethod)

    }
    if (status.includes(EventStatusFilterTypes.UPCOMING)) {
      filterMethod = leagueId => {
        const datesToSort = leaguesById[leagueId].datesToSort
        return moment().startOf('day')
                       .isSameOrBefore(moment(datesToSort))
      }
      upcomingLeagueIds = leagueIds.filter(filterMethod)
    }
    if (status.includes(EventStatusFilterTypes.LOTTERY)) {
      filterMethod = leagueId => {
        return leaguesById[leagueId].hasLottery === true
      }
      lotteryLeagueIds = leagueIds.filter(filterMethod)
    }
    leagueIds = _.uniq([ ...openLeagueIds, ...registeredLeagueIds, ...pastLeagueIds, ...upcomingLeagueIds, ...lotteryLeagueIds ])
  }

  const now = JSON.stringify(new Date().toISOString()
    .split('T')[0])

  const sortedFuturePastDates = _.groupBy(leagueIds, l => (JSON.stringify(leaguesById[l].datesToSort) >= now))
  const past = (sortedFuturePastDates[false] || []).sort((leagueId1, leagueId2) => {
    const league1 = [ !leaguesById[leagueId1].registered, leaguesById[leagueId1].datesToSort, leaguesById[leagueId1].name ]
    const league2 = [ !leaguesById[leagueId2].registered, leaguesById[leagueId2].datesToSort, leaguesById[leagueId2].name ]
    for (let i = 0; i < league1.length; i++) {
      if (!isEmpty(league1[i]) || !isEmpty(league2[i])) {
        if (isEmpty(league1[i]) !== isEmpty(league2[i])) {
          return isEmpty(league1[i]) < isEmpty(league2[i]) ? -1 : 1
        } else if (league1[i] !== league2[i]) {
          // sort desc by round date
          if (i === 1){
            return league1[i] > league2[i] ? -1 : 1
          } else if (i === 2 || i === 0){ // sort asdc by league name and registered
            return league1[i] < league2[i] ? -1 : 1
          }
        }
      }
    }
    return 0
  })

  const future = (sortedFuturePastDates[true] || []).sort((leagueId1, leagueId2) => {
    const league1 = [ !leaguesById[leagueId1].registered, leaguesById[leagueId1].datesToSort, leaguesById[leagueId1].name ]
    const league2 = [ !leaguesById[leagueId2].registered, leaguesById[leagueId2].datesToSort, leaguesById[leagueId2].name ]
    for (let i = 0; i < league1.length; i++) {
      if (!isEmpty(league1[i]) || !isEmpty(league2[i])) {
        if (isEmpty(league1[i]) !== isEmpty(league2[i])) {
          return isEmpty(league1[i]) < isEmpty(league2[i]) ? -1 : 1
        } else if (league1[i] !== league2[i]) {
          // sort desc by round date
          if (i === 1){
            return league1[i] < league2[i] ? -1 : 1
          } else if (i === 2 || i === 0){ // sort asdc by league name and registered
            return league1[i] < league2[i] ? -1 : 1
          }
        }
      }
    }
    return 0
  })

  const allDates = future.concat(past)
  return allDates
}

const getVisibleLeagueIds = createDeepSelector(
  [ getLeaguesIds, getLeaguesById, getFilters ],
  filterLeagueIds
)

export default getVisibleLeagueIds
