import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import TourEventsEarningsTable from './tour_events_earnings_table'
import GolferInfoComponent from './golfer_info_component'

const TourGamesSeasonStandingsRoot = ({playersResults, isPortal = false}) => {

  const showHidePlayerResults = (id) => {
    const elem = document.getElementById(`details_player_result_${id}`)
    if (elem.classList.contains('hidden')) {
      elem.classList.remove('hidden')
    } else {
      elem.classList.add('hidden')
    }
  }

  return (
    <Fragment>
      {isPortal && <h4>{window.I18n.t('tour_games_season_standings.tour_games_season_standings_root.tour_games_season_standings')}</h4>}
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top_10 no_padding">
        <div className="table-responsive">
          <table
            className={`table ${isPortal ? 'table-striped table-bordered table-hover table-condensed' : ''} season_standings_table`}>
            <thead>
            <tr className="header">
              <th>{window.I18n.t('tour_games_season_standings.tour_games_season_standings_root.rank')}</th>
              <th className="name">{window.I18n.t('tour_games_season_standings.tour_games_season_standings_root.player')}</th>
              <th>{window.I18n.t('tour_games_season_standings.tour_games_season_standings_root.times_played')}</th>
              <th>{window.I18n.t('tour_games_season_standings.tour_games_season_standings_root.earnings')}</th>
            </tr>
            </thead>
            <tbody>
            {
              playersResults.map((rowData, rIdx) =>
                <>
                  <tr
                    className={`aggregate-row ${rIdx & 1 ? 'odd' : 'even'}`}
                    key={rIdx}>
                    <td>{rowData.rank}</td>
                    <td className="name">
                      {!isPortal ?
                        <a href="#"
                           onClick={() => showHidePlayerResults(rIdx)}>{rowData.name}</a>
                        :
                        <a href={rowData.infoResultsLink}>{rowData.name}</a>
                      }
                    </td>
                    <td>{rowData.timesPlayed}</td>
                    <td>{rowData.earnings.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</td>
                  </tr>
                  {!isPortal &&
                    <tr className="hidden" key={`${rIdx}_hidden`} id={`details_player_result_${rIdx}`}>
                      <td colSpan="9" className="nohover">
                        <div className="player_results_container">
                          <GolferInfoComponent
                            profileUrl= {rowData.photo}
                            name={rowData.name} earnings={rowData.earnings} won={rowData.wonGames}
                            totalEvents={rowData.timesPlayed}/>
                          <TourEventsEarningsTable tourEvents={rowData.tourEvents}/>
                        </div>
                      </td>
                    </tr>
                  }
                </>,
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  )
}

TourGamesSeasonStandingsRoot.propTypes = {
  playersResults: PropTypes.array,
  isPortal: PropTypes.bool,
}

export default TourGamesSeasonStandingsRoot
