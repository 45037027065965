import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { FormControl } from 'react-bootstrap'
import { Col } from 'react-flexbox-grid'

export class SingleStringComponent extends Component {
  constructor(props){
    super(props)
  }
  
  render(){
    return <Col xs={12} md={8}><FormControl type="text" name={`answer[${this.props.field.id}]`} defaultValue={this.props.answer} onChange={(e) => this.props.onChange(this.props.field.id, this.props.field.category, this.props.field.name, e.target.value)}></FormControl></Col>
  }
}

SingleStringComponent.propTypes = {
  field: PropTypes.object.isRequired,
  answer: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default SingleStringComponent
