import React from 'react'
import PropTypes from 'prop-types'
import Row from './Row'

const Rows = (props) => {

  return (
    <tbody>
      <tr>
        <th>{window.I18n.t('views.golfhub.columns.hub_name')}</th>
        <th></th>
        <th>{window.I18n.t('views.golfhub.columns.hub_link')}</th>
        <th>{window.I18n.t('views.golfhub.columns.filter')}</th>
        <th className='action-button'>{window.I18n.t('views.golfhub.columns.actions')}</th>
      </tr>
      {props.hubs.map((hub, index) => 
        <Row key={hub.id} data={hub} isEven={index % 2} handleHubDelete={() => props.handleHubDelete(hub)}/>
      )}   
    </tbody>
  )
}

Rows.propTypes = {
  hubs: PropTypes.array,
  handleHubDelete: PropTypes.func,
}

export default Rows
