import { createSelector } from 'reselect'

const getPlayers = state => state.misc.players

const getPlayersOnMonsterBoard = createSelector(
  [ getPlayers ],
  ( players ) => {
    return [ ...Array(10) ].map( (_, pos) => {
      const player = players.find( p => p.position === pos)
      return player === undefined ? { id: `${pos}`, empty: true, position: pos } : { ...player, empty: false }
    })
  },
)

export default getPlayersOnMonsterBoard
