import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'
import { FormControl } from 'react-bootstrap'

export class DefaultComponent extends Component {
  constructor(props){
    super(props)
  }
  
  render(){
    return <Col xs={12} md={8}><FormControl type="text" defaultValue={this.props.answer} onChange={(e) => this.props.onChange(this.props.field.id, this.props.field.category, this.props.field.name, e.target.value)}></FormControl></Col>
  }
}

DefaultComponent.propTypes = {
  field: PropTypes.object.isRequired,
  answer: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default DefaultComponent
