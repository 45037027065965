import { connect } from 'react-redux'
import ScheduleComponent from '../components/schedule_component'
import {
  setScheduleFrequency,
  setScheduleHour,
  setScheduleWeekday,
  setScheduleDayOfMonth,
} from '../actions/setup'

const mapStateToProps = (state) => {
  return {
    schedule: state.schedule,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setScheduleFrequency: (frequency) => { dispatch(setScheduleFrequency(frequency)) },
  setScheduleHour: (time) => { dispatch(setScheduleHour(time)) },
  setScheduleWeekday: (weekday) => { dispatch(setScheduleWeekday(weekday)) },
  setScheduleDayOfMonth: (dayOfMonth) => { dispatch(setScheduleDayOfMonth(dayOfMonth)) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScheduleComponent)
