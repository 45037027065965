import { ActionTypes } from '../actions'

const {
  EDIT_DISTANCE,
  LOAD_PLAYERS,
  START_LOAD_PLAYERS,
  CHANGE_SEARCH_VALUE,
} = ActionTypes

const initialState = {
}

const station = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_DISTANCE:
      return {
        ...state,
        players: state.players.map( player => {
          return ( player.id.toString() === action.data.distance_id) ?
            {
              ...player,
              distance: action.data.distance,
            }
            : player
        }),
      }
    case LOAD_PLAYERS:
      return {
        ...state,
        players: action.data.players,
        adjustment: action.data.adjustment,
      }
    case START_LOAD_PLAYERS:
      return {
        ...state,
        players: undefined,
        adjustment: undefined,
      }
    case CHANGE_SEARCH_VALUE:
      return {
        ...state,
        filterName: action.value,
      }
    default:
      return state
  }
}

export default station
