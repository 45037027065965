import { connect } from 'react-redux'
import TvShowSetupComponent from '../components/tv_show_setup_component'
import {
  setUnsavedChanges as setGeneralUnsavedChanges,
  setGeneralPopupProperty,
  plainlySetGeneralProperty,
} from '../actions/general'

import {
  setUnsavedChanges as setHeaderUnsavedChanges,
  toggleVisible,
  changeHeight,
  changeOpacity,
  plainlySetHeaderProperty,
  setPopupProperty,
} from '../actions/header'

import {
  setUnsavedChanges as setFooterUnsavedChanges,
  toggleVisible as toggleVisibleFooter,
  plainlySetFooterProperty,
  setPopupProperty as setFooterPopupProperty,
} from '../actions/footer'

import {
  setUnsavedChanges as setSlidesUnsavedChanges,
  setNewSlideProperty,
  setNewSlideConfigurationProperty,
  addSlide,
  updateSlide,
  removeSlide,
  checkNewSlideDivision,
  uncheckNewSlideDivision,
  checkNewSlideFlight,
  uncheckNewSlideFlight,
  computeSlidesLayout,
  setSlidesPositions,
  plainlySetSlidesProperty,
} from '../actions/slides'

import {
  setUnsavedChanges as setLibraryUnsavedChanges,
  handleSetTemplate,
  setAutoIncludeOption,
  toggleRound,
  checkAllRounds,
} from '../actions/library'

import {
  initialSlideState,
  slideToConfigurationAdapter,
} from '../helpers'

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    generalDispatchers: {
      markAsSaved: () => { dispatch(setGeneralUnsavedChanges(false)) },
      changeViewDisplayed: (newView) => { dispatch(plainlySetGeneralProperty('viewDisplayed', newView)) },
      setName: (name) => {
        dispatch( plainlySetGeneralProperty('name', name) )
        dispatch( setGeneralUnsavedChanges(true) )
      },
      setBackground: (value) => {
        dispatch(plainlySetGeneralProperty('backgroundImage', value))
        dispatch(setGeneralUnsavedChanges(true))
      },
      setPopupProperty: (popupType, property, propertyValue) => {
        dispatch(setGeneralPopupProperty(popupType, property, propertyValue))
      },
      setHasUSGATheme: (value) => {
        dispatch( plainlySetGeneralProperty('hasUSGATheme', value) )
        dispatch( setGeneralUnsavedChanges(true) )
      },
    },
    headerDispatchers: {
      markAsSaved: () => { dispatch(setHeaderUnsavedChanges(false)) },
      toggleVisible: () => {
        dispatch(toggleVisible())
        dispatch(setHeaderUnsavedChanges(true))
      },
      changeHeight: (sliderValue) => {
        dispatch(changeHeight(sliderValue))
        dispatch(setHeaderUnsavedChanges(true))
      },
      changeOpacity: (sliderValue) => {
        dispatch(changeOpacity(sliderValue))
        dispatch(setHeaderUnsavedChanges(true))
      },
      selectBackgroundType: (backgroundType) => {
        dispatch(plainlySetHeaderProperty('selectedBackground', backgroundType))
        dispatch(setHeaderUnsavedChanges(true))
      },
      setText: (newText) => {
        dispatch(plainlySetHeaderProperty('text', newText))
        dispatch(setHeaderUnsavedChanges(true))
      },
      setBackgroundColor: (hex) => {
        dispatch(plainlySetHeaderProperty('backgroundColor', hex))
        dispatch(setHeaderUnsavedChanges(true))
      },
      setImage: (logoOrBackground, value) => {
        dispatch(plainlySetHeaderProperty(logoOrBackground, value))
        dispatch(setHeaderUnsavedChanges(true))
      },
      setEditorInstance: (editor) => { dispatch(plainlySetHeaderProperty('editorInstance', editor)) },
      setPopupProperty: (popupType, property, propertyValue) => { dispatch(setPopupProperty(popupType, property, propertyValue)) },
    },
    footerDispatchers: {
      markAsSaved: () => { dispatch(setFooterUnsavedChanges(false)) },
      toggleVisible: () => {
        dispatch( toggleVisibleFooter() )
        dispatch( setFooterUnsavedChanges(true) )
      },
      changeHeight: (sliderValue) => {
        dispatch( plainlySetFooterProperty('height', parseInt(sliderValue)) )
        dispatch( setFooterUnsavedChanges(true) )
      },
      changeOpacity: (sliderValue) => {
        dispatch( plainlySetFooterProperty('backgroundOpacity', parseInt(sliderValue)) )
        dispatch( setFooterUnsavedChanges(true) )
      },
      selectBackgroundType: (backgroundType) => {
        dispatch( plainlySetFooterProperty('selectedBackground', backgroundType) )
        dispatch( setFooterUnsavedChanges(true) )
      },
      setText: (newText) => {
        dispatch(plainlySetFooterProperty('text', newText))
        dispatch(setFooterUnsavedChanges(true))
      },
      setBackgroundColor: (hex) => {
        dispatch(plainlySetFooterProperty('backgroundColor', hex))
        dispatch(setFooterUnsavedChanges(true))
      },
      setImage: (value) => {
        dispatch( plainlySetFooterProperty('backgroundImage', value) )
        dispatch( setFooterUnsavedChanges(true) )
      },
      setEditorInstance: (editor) => { dispatch(plainlySetFooterProperty('editorInstance', editor)) },
      setPopupProperty: (popupType, property, propertyValue) => { dispatch(setFooterPopupProperty(popupType, property, propertyValue)) },
    },
    slidesDispatchers: {
      markAsSaved: () => { dispatch( setSlidesUnsavedChanges(false) ) },
      markAsDirty: () => { dispatch( setSlidesUnsavedChanges(true) ) },
      openPopup: (popupType) => { dispatch(setNewSlideProperty(popupType, true)) },
      closePopup: (popupType) => { dispatch(setNewSlideProperty(popupType, false)) },
      selectNewSlide: (slideType) => { dispatch(setNewSlideProperty('configuration', initialSlideState(slideType))) },
      editExistingSlide: (slide) => { dispatch(setNewSlideProperty('configuration', slideToConfigurationAdapter(slide))) },
      setSlidePopupConfiguration: (configuration) => { dispatch( setNewSlideProperty('configuration', configuration) ) },
      setNewSlideDataSources: (dataSources) => { dispatch(setNewSlideProperty('dataSources', dataSources)) },
      setCurrentColsCount: (cols) => { dispatch(plainlySetSlidesProperty('currentColsCount', cols)) },
      computeSlidesLayout: (colsNumber) => { dispatch(computeSlidesLayout(colsNumber)) },
      setSlidesPositions: (currentGridLayout) => { dispatch(setSlidesPositions(currentGridLayout)) },
      genericSlideDispatchers: {
        addSlide: (slide) => { dispatch(addSlide(slide)) },
        updateSlide: (slide) => { dispatch( updateSlide(slide) ) },
        removeSlide: (slideId) => { dispatch( removeSlide(slideId) ) },
        setVisible: (value) => { dispatch(setNewSlideConfigurationProperty('isVisible', value)) },
        changeScrollRate: (sliderValue) => { dispatch(setNewSlideConfigurationProperty('scrollRate', parseInt(sliderValue))) },
        setSlideDescription: (value) => { dispatch(setNewSlideConfigurationProperty('description', value)) },
      },
      leaderboardSlideDispatchers: {
        setRound: (roundId) => { dispatch(setNewSlideConfigurationProperty('roundId', roundId)) },
        setTournament: (tournamentId) => { dispatch(setNewSlideConfigurationProperty('tournamentId', tournamentId)) },
        setDivisionIds: (divisionIds) => { dispatch(setNewSlideConfigurationProperty('divisionIds', divisionIds)) },
        setBackgroundOpacity: (opacity) => { dispatch(setNewSlideConfigurationProperty('backgroundOpacity', opacity)) },
        checkDivision: (divisionId) => { dispatch( checkNewSlideDivision(divisionId) ) },
        uncheckDivision: (divisionId) => { dispatch( uncheckNewSlideDivision(divisionId) ) },
        setFlights: (flights) => { dispatch( setNewSlideConfigurationProperty('flightIdsAndValuesByDivisionId', flights) ) },
        checkFlight: (divisionId, flight) => { dispatch(checkNewSlideFlight(divisionId, flight)) },
        uncheckFlight: (divisionId, flightId) => { dispatch(uncheckNewSlideFlight(divisionId, flightId)) },
      },
    },
    libraryDispatchers: {
      markAsSaved: () => { dispatch(setLibraryUnsavedChanges(false)) },
      handleSetTemplate: (value) => { 
        dispatch(handleSetTemplate(value)) 
        dispatch(setLibraryUnsavedChanges(true))
      },
      setAutoIncludeOption: (value) => { 
        dispatch(setAutoIncludeOption(value)) 
        dispatch(setLibraryUnsavedChanges(true))
      },
      toggleRound: (checked, roundId) => { 
        dispatch(toggleRound(checked, roundId)) 
        dispatch(setLibraryUnsavedChanges(true))
      },
      checkAllRounds: (check) => {
        dispatch(checkAllRounds(check))
        dispatch(setLibraryUnsavedChanges(true))
      },
    },
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TvShowSetupComponent)
