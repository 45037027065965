import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CheckboxOptions from './event_setup/checkbox_options'
import RadioGroup from './event_setup/radio_group'
import InputForm from './event_setup/input_form'

class EventSetupRoundSignups extends Component {
  constructor(props){
    super(props)
    this.state = {
      dataChanged: false,
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.content.data !== prevProps.content.data && this.props.content.dataChanged) || this.state.dataChanged) {
      this.props.save()
      this.setState({dataChanged: false})
    }
  }

  render() {
    const { data, title } = this.props.content
    const ott = data?.open_tee_times?.options
    const signupOptions = data?.signup_options?.options
    const managers = data?.send_email_to_managers?.managers

    const setter = (keys) => (value) => this.props.setData([ 'data' ].concat(keys), value)

    return (
      <div className='widget-content-default widget-event-setup-round-signups'>
        <div className='title'>
          { title }
        </div>

        <div className='content flex-list-layout'>
          <ul>
            {
              data?.open_tee_times &&
                <li>
                  <CheckboxOptions
                    {...data?.open_tee_times}
                    setOption={ (keys) => setter([ 'open_tee_times' ].concat(keys)) }
                    conditionalChildrenByKey={{
                      ott_enabled: {
                        condition: ott.ott_enabled.selected && ott.ott_enabled.reserved_foursome_number.enabled,
                        value: (
                          <>
                            <span> { ott.ott_enabled.reserved_foursome_number.label } </span>
                            <InputForm
                              className='form-control'
                              type='number'
                              value={ ott?.ott_enabled.reserved_foursome_number.value }
                              onEditCompleted={value => {
                                setter([ 'open_tee_times', 'options', 'ott_enabled', 'reserved_foursome_number', 'value' ])(value)
                                this.setState({dataChanged: true})
                              }}
                            />
                          </>
                        ),
                      },
                    }}
                  />
                </li>
            }

            {
              data?.signup_options &&
                <li>
                  <CheckboxOptions
                    label={data.signup_options.label}
                    options={ Object.fromEntries(Object.entries(data.signup_options.options).filter(([ key ]) => key !== 'allow_round_signups' || !ott?.ott_enabled?.selected )) }
                    setOption={ (keys) => setter([ 'signup_options' ].concat(keys)) }
                    conditionalChildrenByKey={{
                      guests_allowed: {
                        condition: signupOptions?.guests_allowed?.selected,
                        value: (
                          <>
                            <span> { signupOptions?.guests_allowed?.max_additional_guests.label } </span>
                            <InputForm
                              className='form-control'
                              type='number'
                              value={ signupOptions?.guests_allowed?.max_additional_guests.value }
                              onEditCompleted={value => {
                                setter([ 'signup_options', 'options', 'guests_allowed', 'max_additional_guests', 'value' ])(value)
                                this.setState({dataChanged: true})
                              }}
                            />
                          </>
                        ),
                      },
                    }}
                  />
                </li>
            }

            {
              !ott?.ott_enabled?.selected && signupOptions.allow_round_signups?.selected &&
                <>
                  <li>
                    <RadioGroup
                      id='allow_message_to_manager'
                      {...data['allow_message_to_manager']}
                      setOption={v => setter([ 'allow_message_to_manager', 'selected' ])(v === 'true')}
                      vertical={true}
                    />
                  </li>

                  {
                    data?.allow_message_to_manager?.selected && data?.send_email_to_managers &&
                      <li>
                        <RadioGroup
                          id='send_email_to_managers'
                          {...data['send_email_to_managers']}
                          setOption={v => setter([ 'send_email_to_managers', 'selected' ])(v === 'true')}
                          vertical={true}
                        />
                      </li>
                  }
                  {
                    data?.allow_message_to_manager?.selected && data?.send_email_to_managers?.selected &&
                    <div>
                      {managers.map((manager, index) => (
                        <div key={manager.id} className="col-xs-6 col-sm-6 col-md-4 col-lg-4 add-margin-top-5 no-padding">
                          <div className="checkbox btsp_checkbox checkbox-primary no-margin-bottom">
                          <input
                            type="checkbox"
                            id={`manager_to_send_email_${manager.id}`}
                            onChange={() => setter([ 'send_email_to_managers', 'managers', index, 'selected' ])(!manager.selected)}
                            checked={manager.selected}
                            className="manager_checkbox"
                          />
                            <label htmlFor={`manager_to_send_email_${manager.id}`}>
                              <span>{manager.full_name}</span>
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                </>
            }

          </ul>
        </div>

      </div>
    )
  }
}

EventSetupRoundSignups.propTypes = {
  content: PropTypes.object,
  setData: PropTypes.func,
  save: PropTypes.func,
}

EventSetupRoundSignups.defaultProps = {
  content: {
    title: 'ROUND SIGNUPS',
    data: {},
  },
}

export default EventSetupRoundSignups

