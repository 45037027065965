import { connect } from 'react-redux'
import MenuBar from '../components/menu/menu_bar'
import { toggleMenu } from '../actions/index'

// const mapStateToProps = () => {}

const mapDispatchToProps = (dispatch) => ({
  toggleMenu: () => {
    dispatch(toggleMenu())
  },
})

export default connect(
  null, // mapStateToProps,
  mapDispatchToProps,
)(MenuBar)
