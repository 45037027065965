import React from 'react'
import PropTypes from 'prop-types'
import rootReducer from '../reducers'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import MonsterBoardContainer from './monster_board_container'

const CourseLeaderboardRoot = ({leagueId, roundId, roundGgid, isMultiRound, withTeams, showCourse, players, showMessage, message, score, showRounds, showTotal, submitUrl, refreshUrl, tees, roundsCount, deviceView, lastDeviceUpdate}) => {
  const state = {
    misc: {
      leagueId,
      roundId,
      roundGgid,
      isMultiRound,
      withTeams,
      showCourse,
      players,
      showMessage,
      message,
      score,
      showRounds,
      showTotal,
      submitUrl,
      refreshUrl,
      tees,
      roundsCount,
      deviceView,
      lastDeviceUpdate,
    },
  }

  const store = configureStore(rootReducer, state)

  return (
    <GlobalProvider store={ store }>
      <MonsterBoardContainer />
    </GlobalProvider>
  )
}

CourseLeaderboardRoot.propTypes = {
  leagueId: PropTypes.string,
  roundId: PropTypes.string,
  roundGgid: PropTypes.string,
  isMultiRound: PropTypes.bool,
  withTeams: PropTypes.bool,
  showCourse: PropTypes.bool,
  players: PropTypes.array,
  showMessage: PropTypes.bool,
  message: PropTypes.string,
  score: PropTypes.string,
  showRounds: PropTypes.bool,
  showTotal: PropTypes.bool,
  submitUrl: PropTypes.string,
  refreshUrl: PropTypes.string,
  tees: PropTypes.array,
  roundsCount: PropTypes.number,
  deviceView: PropTypes.string,
  lastDeviceUpdate: PropTypes.string,
}

export default CourseLeaderboardRoot
