import {connect} from 'react-redux'
import {onOkClicked} from '../../actions/manager_side_index'
import ModalPopup from '../../components/manager_side/modal_popup'

const mapStateToProps = (state) => ({
  openModal: state.draftManagerSide.openModal,
  saveInProgress: state.draftManagerSide.saveInProgress,
})

const mapDispatchToProps = (dispatch) => ({
  onOkClicked: () => dispatch(onOkClicked()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalPopup)
