import { connect } from 'react-redux'
import MembersSearchBarComponent from '../components/members_search_bar_component'
import { fetchInitialMembersData, fetchFilterOptions } from '../actions/index'

const mapStateToProps = (state) => ({
  membersCount: state.membersData.membersCount,
  searchParams: state.membersData.searchParams,
  filterOptions: state.membersData.filterOptions,
  isMembersDataLoading: state.membersData.isLoading,
  isMembersCountLoading: state.membersData.isMembersCountLoading,
  showMemberOptions: state.misc.showMemberOptions,
})

const mapDispatchToProps = (dispatch) => ({
  fetchInitialMembersData: (searchParams) => dispatch(fetchInitialMembersData(searchParams)),
  fetchFilterOptions: () => dispatch(fetchFilterOptions()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersSearchBarComponent)
