import React, { Component } from 'react'
import PropTypes from 'prop-types'

class HostVenue extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { title, links, checkStatus, table } = this.props.content

    return (
        <div className='widget-content-default widget-event-venue'>
            <div className='title'>
                { title }

                <a href={ links.course_details.path } className='btn btn-primary widget-white-title-button'>
                    { links.course_details.name }
                </a>
            </div>
            <div className='content'>
                <div className='event-venue-status'>
                    <span className='btn btn-primary widget-white-content-button' onClick={ () => this.props.checkHostVenueStatus(checkStatus.path) }>
                        { checkStatus.name }
                    </span>
                </div>
                <div className='event-venue table-responsive'>
                    <table className='table table-striped'>
                        <thead>
                            <tr className='header'>
                            <th colSpan='2'>{ table.columns.club_round }</th>
                            <th>{ table.columns.approval_status }</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                table.data.length > 0
                                    ? table.data.map(row =>
                                    <tr key={ Math.random() }>
                                        <td>{ row.club }</td>
                                        <td>{ row.round }</td>
                                        <td>{ row.status }</td>
                                    </tr>
                                    )
                                    : null
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
  }
}

HostVenue.propTypes = { 
  content: PropTypes.object,
  checkHostVenueStatus: PropTypes.func,
}

HostVenue.defaultProps = {
  content: {
    title: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.host_venue_approvals.title'),
    check_status: {
      name: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.host_venue_approvals.check_status'),
      path: '#',
    },
    data: [],
  },
}

export default HostVenue
