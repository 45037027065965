import React, { Component } from 'react'
import PropTypes from 'prop-types'

class MobileScoreWarningPopup extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const toggleTournamentStatusLink = this.props.toggleTournamentStatusLink
    const disableMobileScoringLink = this.props.disableMobileScoringLink
    return (
      <div className="modal" id="disable_mobile_scoring_modal" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-md">
            <div className="modal-content">
                <div className="modal-body">
                  <div>{window.I18n.t('rounds.bootstrap_partials.portal_and_mobile.mobile_score_warning')}</div>
                  <a href={ toggleTournamentStatusLink } className='add-margin-left-10 btn button btsp_gray_button pull-right cancel-enable-tee' >{window.I18n.t('rounds.bootstrap_partials.portal_and_mobile.no_button')}</a>
                  <a href={ disableMobileScoringLink } className='btsp_orange_button save_button pull-right btn save-enable-tee' >{window.I18n.t('rounds.bootstrap_partials.portal_and_mobile.yes_button')}</a>
                </div>
            </div>
        </div>
      </div>
    )
  }
}


MobileScoreWarningPopup.propTypes = {
  toggleTournamentStatusLink: PropTypes.string,
  disableMobileScoringLink: PropTypes.string,
}


export default MobileScoreWarningPopup
