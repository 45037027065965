import React, { Component } from 'react'
import { Icon as FaIcon } from 'react-fa'
import PropTypes from 'prop-types'
import _ from 'lodash'

// import EventStatusFilterTypes from '../../event_status_filter_types'

class DropdownButton extends Component {

  render() {
    const label = []
    this.props.options.forEach( (item) => {
      if (item.checked) {
        label.push(item.label)
      }
    })

    let width = window.innerWidth
    try {
      width = window.parent.innerWidth
    } catch (err) {
      // 1 == 1
    }

    let length
    this.props.showTours ? length = 29 : length = 42
    if (window.innerWidth < 750 ) {
      length = 20
    } else if (width < 920) {
      this.props.showTours ? length = 20 : length = 29
    }
    
    return <div id="event_status"><span>{_.truncate(label.join(', '), {'length': length} )}</span><span className="dropdown_button_icon pull-right"><FaIcon name="chevron-down" style={{color: '#CCCCCC'}}></FaIcon></span></div>
  }
}

DropdownButton.propTypes = {
  options: PropTypes.array,
  showTours: PropTypes.bool,
}

export default DropdownButton
