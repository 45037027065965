import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ReportCenterNewItem extends Component {
  render() {
    let newGpuPath, newSpreadsheetPath, hidden, ccTextHidden
    if (this.props.round_id !== null) {
      newGpuPath = '/leagues/' + this.props.league_id + '/rounds/' + this.props.round_id + '/gpu/reports/new?report_type=report_writer'
      newSpreadsheetPath = '/leagues/' + this.props.league_id + '/rounds/' + this.props.round_id + '/spreadsheet/reports/new'
      hidden = ''
      ccTextHidden = 'hidden'
    } else {
      newGpuPath = '/leagues/' + this.props.league_id + '/gpu/reports/new?report_type=report_writer'
      newSpreadsheetPath = '/leagues/' + this.props.league_id + '/spreadsheet/reports/new'
      hidden = 'hidden'
      ccTextHidden = ''
    }
    return (
      <div className="modal fade create_item_wrapper" id='create_item' tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">{!window.I18n ? '' : window.I18n.t('download_center.report_center_item_new.new_document')}</h3>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className='create_item'>
              {/* Change to this when showing REPORT COMPOSER
              Please select the type of document that you would like to create: report or spreadsheet. Reports are the most advanced, allowing you to create custom tee sheets, alpha lists, pace of play worksheets. Reports also include headers, footers and advanced sorting and filtering capabilities. Choose Spreadsheet if you wish to generate an XLS / CSV file with your data.*/}
              <p className = {ccTextHidden}>{!window.I18n ? '' : window.I18n.t('download_center.report_center_item_new.create_spreadsheet')}</p>
              <p className = {hidden}>{!window.I18n ? '' : window.I18n.t('download_center.report_center_item_new.select_document_type')}</p>
              <div className="items">
                <a href={newGpuPath} className={hidden}/* REPORT COMPOSER */>
                  <div className="new_item">
                    <div className="">
                    </div>
                    <div className="">
                      <span>{!window.I18n ? '' : window.I18n.t('download_center.report_center_item_new.new')}<br/>{!window.I18n ? '' : window.I18n.t('download_center.report_center_item_new.report')}</span>
                    </div>
                  </div>
                </a>
                <a href={'/leagues/' + this.props.league_id + '/rounds/' + this.props.round_id + '/gpu/reports/new'} className = {hidden}>
                  <div className="new_item">
                    <div className=""></div>
                    <div className="">
                      <span>{!window.I18n ? '' : window.I18n.t('download_center.report_center_item_new.new')}<br/>{!window.I18n ? '' : window.I18n.t('download_center.report_center_item_new.page')}</span>
                    </div>
                  </div>
                </a>
                <a href={newSpreadsheetPath}>
                  <div className="new_item">
                    <div className=""></div>
                    <div className="">
                      <span>{!window.I18n ? '' : window.I18n.t('download_center.report_center_item_new.new')}<br/>{!window.I18n ? '' : window.I18n.t('download_center.report_center_item_new.spreadsheet')}</span>
                    </div>
                  </div>
                </a>
                <a href={'/leagues/' + this.props.league_id + '/rounds/' + this.props.round_id + '/gpu/scorecards/new'}>
                  <div className="new_item">
                    <div className=""></div>
                    <div className="">
                      <span>{!window.I18n ? '' : window.I18n.t('download_center.report_center_item_new.new')}<br/>{!window.I18n ? '' : window.I18n.t('download_center.report_center_item_new.scorecard')}</span>
                    </div>
                  </div>
                </a>
                <a href={'/rounds/' + this.props.round_id + '/microsoft_publisher_scoreboards'} className={hidden}>
                  <div className="new_item">
                    <div className=""></div>
                    <div className="">
                      <span>{!window.I18n ? '' : window.I18n.t('download_center.report_center_item_new.new_publisher')}<br/>{!window.I18n ? '' : window.I18n.t('download_center.report_center_item_new.scoreboard')}</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ReportCenterNewItem.propTypes = {
  round_id: PropTypes.string,
  league_id: PropTypes.string.isRequired,
}

export default ReportCenterNewItem
