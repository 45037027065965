import { connect } from 'react-redux'
import HandleFormatComponent from '../../components/sidebar_content/handle_format_component'
import { closeHandleFormatSidebar, reformatHandles } from '../../actions/index'

const mapStateToProps = (state) => ({
  isMembersLoading: state.membersData.isLoading,
  membersHandle: state.leftSidebarFields.membersHandle,
  setHandleFormatUrl: state.leftSidebarFields.setHandleFormatUrl,
  isReformatNamesLoading: state.leftSidebarFields.isReformatNamesLoading,
  isPgaCustomer: state.misc.isPgaCustomer,
  isJapanCustomer: state.misc.isJapanCustomer,
  customHandlesName: state.misc.customHandlesName,
  customFieldsNames: state.misc.customFieldsNames,
  hasOverrideFirstName: state.misc.hasOverrideFirstName,
  hasOverrideWithKanji: state.misc.hasOverrideWithKanji,
})

const mapDispatchToProps = (dispatch) => ({
  closeHandleFormatSidebar: () => dispatch(closeHandleFormatSidebar()),
  reformatHandles: (handle, selectedField, overrideFirstName, overrideWithKanji) => dispatch(reformatHandles(handle, selectedField, overrideFirstName, overrideWithKanji)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HandleFormatComponent)
