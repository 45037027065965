import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import keySettings from '../key_settings'
import KeySettingsBaseTab from './key_settings_base_tab'
import Select from 'react-select'
import Checkbox from '../../dashboard/components/content/event_setup/checkbox'
import CalendarCustomInput from '../../dashboard/components/content/event_setup/calendar_custom_input'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { GlgSelect } from 'SharedComponents'
import { generateTimeItems, levelOptions } from '../key_settings_utils'


const relationalOperators = [
  '=', '>=', '<=', '>', '<', (!window.I18n ? '' : window.I18n.t('key_settings.filtering.between')),
]

const KeySettingsFilteringTab = (props) => {
  // Redux state
  const tableData = useSelector(state => state.table.data.table)
  const canInvitationList = useSelector(state => state.table.data.can_invitation_list)
  const canAlwaysShowMrCoupling = useSelector(state => state.table.data.can_always_show_mr_coupling)
  const canOpenRegistration = useSelector(state => state.table.data.can_open_registration)
  const isUkWhs = useSelector(state => state.table.data.is_uk_whs)
  const categories = useSelector(state => state.table.data.categories)
  const seasons = useSelector(state => state.table.data.seasons)
  const currentSeason = useSelector(state => state.table.data.current_season)
  const customerManagers = useSelector(state => state.table.data.customer_managers)
  const hiServices = useSelector(state => state.table.data.hi_services)
  const rosterLeagueCourses = useSelector(state => state.table.data.roster_league_courses)
  const coursesEnabled = useSelector(state => state.table.data.courses_enabled)
  const customerCourses = useSelector(state => state.table.data.customer_courses)

  // Local state
  const [ selectedLevel, setSelectedLevel ] = useState('')
  const [ selectedKey, setSelectedKey ] = useState('')
  const [ selectedValue, setSelectedValue ] = useState('')
  const [ selectedBetweenValue, setSelectedBetweenValue ] = useState('')
  const [ selectedRelationalOperator, setSelectedRelationalOperator ] = useState('=')
  const [ selectedTee, setSelectedTee ] = useState('')

  
  // Business Logic
  const handleCategorySelect = (array) => {
    if (array.value === props.selectedCategories[0].value) {
      return
    }

    props.setSelectedCategories([ array ])
  }

  const handleSeasonSelect = (array) => {
    if (array.value === props.selectedSeasons[0].value) {
      return
    }

    props.setSelectedSeasons([ array ])
  }

  const handleLevelSelect = (level) => {
    setSelectedLevel(level)
    setSelectedKey('')
    setSelectedValue('')
    setSelectedRelationalOperator('=')
  }

  const handleKeySelect = (key) => {
    setSelectedKey(key)
    setSelectedValue('')
    setSelectedRelationalOperator('=')
  }

  const handleValueSelect = (value) => {
    setSelectedValue(value)

    if (value === selectedValue || selectedRelationalOperator === (!window.I18n ? '' : window.I18n.t('key_settings.filtering.between'))) {
      return
    }

    const selectedSetting = keySettings.find(option => option.key === selectedKey)

    props.setAdvancedQueryData({
      level: selectedLevel,
      key: selectedKey,
      value: value,
      type: selectedSetting.type,
      relationalOperator: selectedRelationalOperator,   
    })
  }

  const handleDefaultTeeSelect = (value) => {
    if (value === selectedTee) {
      return
    }

    setSelectedTee(value)

    props.setAdvancedQueryData({
      level: selectedLevel,
      key: 'default_tee',
      value: value,
    })
  }

  const handleBetweenValueSelect = (value) => {
    if (value === selectedBetweenValue) {
      return
    }

    setSelectedBetweenValue(value)
    const selectedSetting = keySettings.find(option => option.key === selectedKey)

    props.setAdvancedQueryData({
      level: selectedLevel,
      key: selectedKey,
      value: selectedValue,
      betweenValue: value,
      type: selectedSetting.type,
      relationalOperator: selectedRelationalOperator,   
    })
  }

  const handleRelationalOperatorSelect = (value) => {
    setSelectedRelationalOperator(value)

    if (!selectedValue) {
      return
    }

    const selectedSetting = keySettings.find(option => option.key === selectedKey)

    props.setAdvancedQueryData({
      level: selectedLevel,
      key: selectedKey,
      value: selectedValue,
      type: selectedSetting.type,
      relationalOperator: value,   
    })
  }

  const handlePastRecords = () => {
    props.setShowPastRecords(!props.showPastRecords)
  }

  // View Helpers
  const keys = selectedLevel
    ? keySettings
      .filter(option => 
        option.level === selectedLevel &&
        (option.key !== 'registration_starts_on_for_invitations' || (canInvitationList && canOpenRegistration)) &&
        (option.key !== 'master_roster_coupling' || canAlwaysShowMrCoupling) &&
        (option.key !== 'variable_settings_whsuk_is_qualifying' || isUkWhs) &&
        (option.key !== 'variable_settings_automatically_post_scores' || isUkWhs) &&
        (option.key !== 'min_number_of_members' || canOpenRegistration) &&
        (option.key !== 'max_number_of_members' || canOpenRegistration) &&
        (option.key !== 'min_number_of_guests' || canOpenRegistration) &&
        (option.key !== 'max_number_of_guests' || canOpenRegistration) &&
        (option.key !== 'registration_starts_on' || canOpenRegistration) &&
        (option.key !== 'registration_ends_on' || canOpenRegistration) &&
        (option.key !== 'variable_settings_default_tee_m') &&
        (option.key !== 'variable_settings_default_tee_f')
      )
      .map(option => option.key)
    : []

  const values = selectedKey
    ? keySettings
        .find(option => option.key === selectedKey)?.values || []
    : []

  const seasonOptions = [
    { value: 'All', label: !window.I18n ? '' : window.I18n.t('key_settings.filtering.all') },
    ...seasons.sort().map((season) => {
      return {
        value: season,
        label: season === currentSeason ? `${season} (Current)` : season,
      }
    }),
  ]

  const categoryOptions = [
    { value: 'All', label: !window.I18n ? '' : window.I18n.t('key_settings.filtering.all')},
    ...categories.sort().map((category) => ({
      value: category,
      label: category,
    })),
  ]
                          
  const keyOptions = keys.map(key => {
    const option = keySettings.find(option => option.key === key)
    return { value: key, label: option ? option.display : key }
  }).sort((a, b) => a.label.localeCompare(b.label))  
  

  // Render view
  if (!tableData) { return null }

  return (
    <KeySettingsBaseTab title={!window.I18n ? '' : window.I18n.t('key_settings.filtering.title')}>
      <div className="content">
        <strong className='quick-color'>{!window.I18n ? '' : window.I18n.t('key_settings.filtering.quick')}</strong>
        <div className='filter-container'>
          <label className='less-bold'>{!window.I18n ? '' : window.I18n.t('key_settings.filtering.seasons')}</label>
          <Select
            className='select-default-size-quick-filtering'
            options={seasonOptions}
            value={props.selectedSeasons}
            onChange={handleSeasonSelect}
          />
          <label className='less-bold'>{!window.I18n ? '' : window.I18n.t('key_settings.filtering.categories')}</label>
          <Select
            className='select-default-size-quick-filtering'
            options={categoryOptions}
            value={props.selectedCategories}
            onChange={handleCategorySelect}
          />
          <Checkbox
            selected={props.showPastRecords}
            setOption={() => handlePastRecords()}
            className="custom-checkbox-past-records"
          />
          <label className='less-bold'>{!window.I18n ? '' : window.I18n.t('key_settings.filtering.show_past_records')}</label>
        </div>
      </div>

      <div className="content">
        <strong className='quick-color'>{!window.I18n ? '' : window.I18n.t('key_settings.filtering.advanced')}</strong>
          <div className='filter-container'>
            <label className='less-bold'>{!window.I18n ? '' : window.I18n.t('key_settings.filtering.with_level')}</label>
            <Select
              className='select-default-size'
              options={levelOptions}
              value={selectedLevel ? { value: selectedLevel, label: selectedLevel } : null}
              onChange={(selectedOption) => handleLevelSelect(selectedOption.value)}
            />
            {selectedLevel && (
              <>
                <label className='less-bold'>{!window.I18n ? '' : window.I18n.t('key_settings.filtering.settings')}</label>
                <Select
                  className='select-default-size-filtering'
                  options={keyOptions}
                  value={selectedKey ? keyOptions.find(option => option.value === selectedKey) : null}
                  onChange={(selectedOption) => handleKeySelect(selectedOption.value)}
                />
              </>
            )}
            {selectedKey && (
              keySettings && (
                <div>
                  {keySettings.find((option) => option.key === selectedKey)?.type === 'boolean' || keySettings.find((option) => option.key === selectedKey)?.type === 'string' ? (
                    <label className='less-bold'>{!window.I18n ? '' : window.I18n.t('key_settings.filtering.is')}</label>
                  ) : (
                    <Select
                      className="conditional-operator"
                      value={relationalOperators.find(option => option === selectedRelationalOperator) ? { value: selectedRelationalOperator, label: selectedRelationalOperator } : { value: relationalOperators[0], label: relationalOperators[0] }}
                      onChange={(e) => handleRelationalOperatorSelect(e.value)}
                      options={relationalOperators.map(condition => ({ value: condition, label: condition }))}
                    />
                  )}
                </div>
              )
            )}
            {selectedKey && (
              <div>
                {selectedRelationalOperator === (!window.I18n ? '' : window.I18n.t('key_settings.filtering.between')) && selectedKey.includes('number_of') ? (
                  <div className="input-group-2">
                    <input
                      key={'between_number_of_1'}
                      value={selectedValue || 0} 
                      min="0" 
                      max="12" 
                      required 
                      className="form-control-input" 
                      type="number"
                      onKeyDown={() => { return false }}
                      onChange={(e) => handleValueSelect(e.target.value)}
                    />
                    <span className="and-text">{!window.I18n ? '' : window.I18n.t('key_settings.filtering.and')}</span>
                    <input
                      key={'between_number_of_2'}
                      value={selectedBetweenValue || 0} 
                      min="0" 
                      max="12" 
                      required 
                      className="form-control-input" 
                      type="number"
                      onKeyDown={() => { return false }}
                      onChange={(e) => handleBetweenValueSelect(e.target.value)}
                    />
                  </div>
                ) : selectedKey.includes('number_of') ? (
                  <input
                    key={'number_of'}
                    value={selectedValue || 0} 
                    min="0" 
                    max="12" 
                    required 
                    className="form-control-input" 
                    type="number"
                    onKeyDown={() => { return false }}
                    onChange={(e) => handleValueSelect(e.target.value)}
                  />
                ) : keySettings.find((option) => option.key === selectedKey)?.type === 'number' ? (
                  <input 
                    value={selectedValue || 0}
                    required
                    className="form-control-input" 
                    type="number"
                    onKeyDown={() => { return false }}
                    onChange={(e) => handleValueSelect(e.target.value)}
                  />
                ) : keySettings.find((option) => option.key === selectedKey)?.type === 'date' && selectedRelationalOperator === 'between' ? (
                  <div className="input-group-2">
                    <div className="width-180-px">
                      <DatePicker
                        customInput={<CalendarCustomInput withTime/>}
                        selected={selectedValue && 
                          moment(selectedValue, 'YYYY-MM-DD HH:mm:ss')
                            .toDate()}
                        dateFormat='MM/dd/yyyy h:mm aa'
                        onChange={(date) => {
                          handleValueSelect(moment(date)
                          .format('YYYY-MM-DD HH:mm:ss'))
                        }}
                        popperClassName="custom-datepicker-popper"
                        popperPlacement="auto"
                      />
                    </div>
                    <span className="and-text">{!window.I18n ? '' : window.I18n.t('key_settings.filtering.and')}</span>
                    <div className="width-180-px">
                      <DatePicker
                        showTimeSelect
                        timeIntervals={60}
                        customInput={<CalendarCustomInput withTime/>}
                        selected={selectedBetweenValue && 
                          moment(selectedBetweenValue, 'YYYY-MM-DD HH:mm:ss')
                            .toDate()}
                        dateFormat='MM/dd/yyyy h:mm aa'
                        onChange={(date) => {
                          handleBetweenValueSelect(moment(date)
                          .format('YYYY-MM-DD HH:mm:ss'))
                        }}
                        popperClassName="custom-datepicker-popper"
                        popperPlacement="auto"
                      />
                    </div>
                  </div>
                ) : keySettings.find((option) => option.key === selectedKey)?.type === 'date' ? (
                  <div className="width-180-px">
                    <DatePicker
                      showTimeSelect
                      timeIntervals={60}
                      customInput={<CalendarCustomInput withTime/>}
                      selected={selectedValue && 
                        moment(selectedValue, 'YYYY-MM-DD HH:mm:ss')
                          .toDate()}
                      dateFormat='MM/dd/yyyy h:mm aa'
                      onChange={(date) => {
                        handleValueSelect(moment(date)
                        .format('YYYY-MM-DD HH:mm:ss'))
                      }}
                      popperClassName="custom-datepicker-popper"
                      popperPlacement="auto"
                    />
                  </div>
                ) : selectedKey === 'communication_officer' ? (
                  <GlgSelect
                    showTimeSelect
                    timeIntervals={60}
                    key={'communication_officer'}
                    items={[
                      ...(customerManagers).sort((a, b) => a.full_name.localeCompare(b.full_name)).map(manager => ({
                        value: manager.user_id.toString(),
                        label: manager.full_name,
                      })),
                    ]}
                    placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                    className="default-glg-select"
                    menuPosition='fixed'
                    noPreFill
                    usePortal
                    menuShouldBlockScroll
                    onChange={(e) => handleValueSelect(e)}
                  />
                ) : selectedKey === 'handicaps_updated_via' ? (
                  <GlgSelect
                    key={'handicaps_updated_via'}
                    items={
                      hiServices.sort((a, b) => a[1].localeCompare(b[1])).map(service => ({
                            value: service[1],
                            label: service[0],
                      }))
                    }
                    placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                    className="default-glg-select"
                    menuPosition='fixed'
                    noPreFill
                    usePortal
                    menuShouldBlockScroll
                    onChange={(e) => handleValueSelect(e)}
                  />
                ) : selectedKey === 'variable_settings_hole_type' ? (
                  <GlgSelect
                    key={'variable_settings_hole_type'}
                    items={
                      [
                        { value: '1', label: !window.I18n ? '' : window.I18n.t('key_settings.generic.all18')},
                        { value: '3', label: !window.I18n ? '' : window.I18n.t('key_settings.generic.back9') },
                        { value: '2', label: !window.I18n ? '' : window.I18n.t('key_settings.generic.front9') },
                      ]
                    }
                    placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                    className="small-glg-select"
                    menuPosition='fixed'
                    noPreFill
                    usePortal
                    menuShouldBlockScroll
                    onChange={(e) => handleValueSelect(e)}
                  />
                ) : selectedKey === 'variable_settings_default_course' ? (
                    <GlgSelect
                      key={'variable_settings_default_course'}
                      items={
                        rosterLeagueCourses.sort((a, b) => a[1].localeCompare(b[1])).map(service => ({
                              value: service[0].toString(),
                              label: service[1],
                        }))
                      }
                      placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                      className="default-glg-select"
                      menuPosition='fixed'
                      noPreFill
                      usePortal
                      menuShouldBlockScroll
                      onChange={(e) => handleValueSelect(e)}
                    />
                ) : selectedKey === 'variable_settings_auto_move_in_progress_hour' || selectedKey === 'variable_settings_auto_move_to_completed_hour' ? (
                  <GlgSelect
                    key={'variable_settings_auto_move_in_progress_hour'}
                    items={generateTimeItems()}
                    placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                    className="default-glg-select"
                    menuPosition='fixed'
                    noPreFill
                    usePortal
                    menuShouldBlockScroll
                    onChange={(e) => handleValueSelect(e)}
                  />
                ) : selectedKey === 'courses_enabled' ? (
                  <GlgSelect
                    key={'courses_enabled'}
                    items={customerCourses.sort((a, b) => a[1].localeCompare(b[1])).map(service => ({
                      value: service[0],
                      label: service[1],
                    }))}
                    placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                    className="default-glg-select"
                    menuPosition='fixed'
                    noPreFill
                    usePortal
                    menuShouldBlockScroll
                    onChange={(e) => handleValueSelect(e)}
                  />
                ) : (
                  <Select
                    className='select-boolean-size'
                    value={selectedValue.value}
                    onChange={(selectedOption) => handleValueSelect(selectedOption.value)}
                    options={values.sort().map(value => ({ value: value.data, label: value.label || value.data }))}
                  />
                )}
              </div>
            )}
            {selectedValue && (
              <div>
                {selectedKey === 'variable_settings_default_course' && (
                  <div className='input-group-2'>
                    <label className='less-bold label-margin'>{!window.I18n ? '' : window.I18n.t('key_settings.filtering.with_default_tee')}</label>
                    <GlgSelect
                      key={'tee'}
                      items={coursesEnabled[selectedValue]['tees'].sort((a, b) => a['name'].localeCompare(b['name'])).map(tee => ({
                        value: tee['id'],
                        label: tee['name'],
                      }))}
                      placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                      className="default-glg-select"
                      menuPosition='fixed'
                      noPreFill
                      usePortal
                      menuShouldBlockScroll
                      onChange={handleDefaultTeeSelect}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
      </div>
    </KeySettingsBaseTab>   
  )
}

KeySettingsFilteringTab.propTypes = {
  selectedCategories: PropTypes.array,
  setSelectedCategories: PropTypes.func,
  setAdvancedQueryData: PropTypes.func,
  showPastRecords: PropTypes.bool,
  setShowPastRecords: PropTypes.func,
  selectedSeasons: PropTypes.array,
  setSelectedSeasons: PropTypes.func,
}

export default KeySettingsFilteringTab
