import { ActionTypes } from '../actions'

const {
  RESET_TIMER,
  CLEAR_TIMER,
  SET_IS_LOADING,
  CHANGE_TOURNAMENT_REQUEST,
} = ActionTypes

const initialState = {
  timer: null,
  isLoading: false,
}

const misc = (state = initialState, action) => {
  switch (action.type) {
    case RESET_TIMER:
      if (state.timer !== null) {
        clearInterval(state.timer)
      }

      return {
        ...state,
        timer: setInterval(() => action.refreshMethod(), 30000),
      }

    case CLEAR_TIMER:
      if (state.timer !== null) {
        clearInterval(state.timer)
      }

      return {
        ...state,
        timer: null,
      }

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      }


    case CHANGE_TOURNAMENT_REQUEST:
      return {
        ...state,
        selectedTournamentId: action.tournamentId,
      }

    default:
      return {
        ...state,
      }
  }
}

export default misc
