import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const popupHolesRow = ({isMultiRound, matchedScores}) => {
  return (
    <tr className='holes_row bold'>
      <td className='with_border'>Hole</td>
      { [ ...Array(9) ].map( ( _, i) => (
        <Fragment key={i}>
          <td key={'hole-' + i} className={matchedScores[i] ? 'with_border' : 'with_border yellow_column'}>{i + 1}</td>
          { (i + 1) % 3 === 0 && i !== 8 && <td className="with_border gray_column"></td> }
        </Fragment>
      )) }
      <td className='with_border blue_column'>Out</td>
      { [ ...Array(9) ].map( ( _, i) => (
        <Fragment key={i}>
          <td key={'hole-' + i} className={matchedScores[i + 9] ? 'with_border' : 'with_border yellow_column'}>{i + 10}</td>
          { (i + 1) % 3 === 0 && i !== 8 && <td className="with_border gray_column"></td> }
        </Fragment>
      )) }
      <td className='with_border blue_column'>In</td>
      <td className='with_border blue_column'>Score</td>
      { isMultiRound && <td className='with_border blue_column'>Overall Score</td> }
    </tr>
  )
}

popupHolesRow.propTypes = {
  isMultiRound: PropTypes.bool,
  matchedScores: PropTypes.array,
}

export default popupHolesRow
