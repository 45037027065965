import { connect } from 'react-redux'
import GolfHubTile from '../../components/content/golf_hub_tile'
import { copyText, toggleGolfHubPublished, toggleEventRegistration } from '../../actions/index'

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleGolfHubPublished: (status, url) => dispatch(toggleGolfHubPublished(ownProps.widgetId, status, url)),
  toggleEventRegistration: (status, url) => dispatch(toggleEventRegistration(ownProps.widgetId, status, url)),
  copyText: textToCopy => copyText(textToCopy),
  refresh: () => dispatch(ownProps.refresh),
})

export default connect(
  null,
  mapDispatchToProps,
)(GolfHubTile)
