import { createSelector } from 'reselect'

const getTransactionsById = state => state.transactionsHistory.transactions
const getFilter = state => state.transactionsHistory.eventId

const getTransactions = createSelector(
  [ getTransactionsById, getFilter ],
  (transactions, eventId) => {
    if (eventId === 'all'){
      return transactions
    }
    const visibleTransactions = transactions.filter((t) => t.eventId === eventId)
    return visibleTransactions
  },
)

export default getTransactions
