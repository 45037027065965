import _ from 'lodash'
import { PopupActionTypes } from 'Shared/actions'
export { openPopup, closePopup } from 'Shared/actions'

export const ActionTypes = {
  ...PopupActionTypes,
  FETCH_INITIAL_DATA_REQUEST: 'FETCH_INITIAL_DATA_REQUEST',
  FETCH_INITIAL_DATA_RECEIVED: 'FETCH_INITIAL_DATA_RECEIVED',
  CHANGE_DROPDOWN_STATE: 'CHANGE_DROPDOWN_STATE',
  CHANGE_FILTER: 'CHANGE_FILTER',
  FETCH_NAVIGATOR_OPTION_PERMISSIONS: 'FETCH_NAVIGATOR_OPTION_PERMISSIONS',
  RECEIVE_NAVIGATOR_OPTION_PERMISSIONS: 'RECEIVE_NAVIGATOR_OPTION_PERMISSIONS',
  SELECT_NAVIGATOR_OPTION_ROUND: 'SELECT_NAVIGATOR_OPTION_ROUND',
  CHANGE_BOOKMARKED_STATUS: 'CHANGE_BOOKMARKED_STATUS',
  SAVED_BOOKMARKS_TEMPLATE: 'SAVED_BOOKMARKS_TEMPLATE',
  REORDER_BOOKMARKS: 'REORDER_BOOKMARKS',
  CLEAR_ALL_BOOKMARKS: 'CLEAR_ALL_BOOKMARKS',
}

import { showNotification, showErrorNotification, callAPI } from 'Shared/helpers'

// Will receive roundId only when changing round select
export const fetchInitialData = roundId => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.FETCH_INITIAL_DATA_REQUEST,
  })

  const state = getState()
  const dataURL = state.misc.fetchInitialDataURL
  const params = {
    context: state.misc.context,
    round_id: roundId || state.misc.defaultRoundId,
  }
  callAPI(dataURL, 'GET', params)
  .then(response => {
    if (roundId) {
      response.misc.defaultRoundId = roundId
    } else {
      response.misc.defaultRoundId = state.misc.defaultRoundId
    }
    dispatch({
      type: ActionTypes.FETCH_INITIAL_DATA_RECEIVED,
      data: response,
    })
  })
  .catch(() => {
    showErrorNotification(!window.I18n ? '' : window.I18n.t('navigator.actions.index.error_fetching_navigator_data'))
  })
}

export const changeDropdownState = isOpen => ({
  type: ActionTypes.CHANGE_DROPDOWN_STATE,
  isOpen,
})

export const changeFilter = filter => ({
  type: ActionTypes.CHANGE_FILTER,
  value: filter,
})

export const changeNavigatorOptionSelectedRound = (navigatorOptionId, roundId) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SELECT_NAVIGATOR_OPTION_ROUND,
    id: navigatorOptionId,
    roundId,
  })
  dispatch({
    type: ActionTypes.FETCH_NAVIGATOR_OPTION_PERMISSIONS,
    id: navigatorOptionId,
  })

  const state = getState()
  const url = state.navigatorOptions[navigatorOptionId].fetchPermissionsURL
  const data = {
    round_id: roundId,
  }
  callAPI(url, 'GET', data)
  .then(json => {
    dispatch({
      type: ActionTypes.RECEIVE_NAVIGATOR_OPTION_PERMISSIONS,
      id: navigatorOptionId,
      data: json,
    })
  })
  .catch(() => {
    showErrorNotification(!window.I18n ? '' : window.I18n.t('navigator.actions.index.error_fetching_navigator_permissions'))
  })
}

export const changeBookmarkedStatus = (id, status) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.CHANGE_BOOKMARKED_STATUS,
    id,
    status,
  })

  const state = getState()
  const url = state.navigatorOptions[id].changeBookmarkedStatusURL
  const data = {
    status,
  }

  callAPI(url, 'POST', data)
  .catch((errorMessage = `${ status ? 
    (!window.I18n ? '' : window.I18n.t('navigator.actions.index.error_while_bookmarking')) : 
    (!window.I18n ? '' : window.I18n.t('navigator.actions.index.error_while_unbookmarking')) }`) => {
    showErrorNotification(errorMessage)
  })
}

export const saveBookmarksTemplate = () => (dispatch, getState) => {
  const state = getState()

  const bookmarkedOptions = _.sortBy(state.bookmarks, 'position')
  const bookmarkedOptionIds = _.map(bookmarkedOptions, 'id')
  dispatch({
    type: ActionTypes.SAVED_BOOKMARKS_TEMPLATE,
    bookmarkedOptionIds,
  })

  const url = state.misc.saveBookmarksTemplateURL

  callAPI(url, 'POST')
  .then(() => {
    showNotification(!window.I18n ? '' : window.I18n.t('navigator.actions.index.saved_default_bookmarks_template'))
  })
  .catch((errorMessage = !window.I18n ? '' : window.I18n.t('navigator.actions.index.error_saving_bookmarks_template')) => {
    showErrorNotification(errorMessage)
  })
}

export const reorderBookmarks = (fromOptionId, toOptionId) => (dispatch, getState) => {
  if (fromOptionId === toOptionId) {
    return
  }

  const state = getState()

  dispatch({
    type: ActionTypes.REORDER_BOOKMARKS,
    fromOptionId,
    toOptionId,
  })

  const url = state.misc.reorderBookmarksURL
  const data = {
    from_option_id: fromOptionId,
    to_option_id: toOptionId,
  }

  callAPI(url, 'POST', data)
  .catch((errorMessage = !window.I18n ? '' : window.I18n.t('navigator.actions.index.error_reordering_bookmarks')) => {
    showErrorNotification(errorMessage)
  })
}

export const clearAllBookmarks = () => (dispatch, getState) => {
  const state = getState()

  dispatch({
    type: ActionTypes.CLEAR_ALL_BOOKMARKS,
  })

  const url = state.misc.clearBookmarksURL
  callAPI(url, 'POST')
  .then(() => {
    showNotification(!window.I18n ? '' : window.I18n.t('navigator.actions.index.successfully_deleted_all_bookmarks'))
  })
  .catch((errorMessage = !window.I18n ? '' : window.I18n.t('navigator.actions.index.error_deleting_bookmarks')) => {
    showErrorNotification(errorMessage)
  })
}
