export const levelOptions = [
  {value: 'Events & Leagues', label: 'Events & Leagues'},
  {value: 'Rounds', label: 'Rounds'},
]

export const generateTimeItems = () => (
  Array.from({ length: 24 }, (_, index) => {
    const hour = index % 12 === 0 ? 12 : index % 12
    const suffix = index < 12 ? 'AM' : 'PM'
    return {
      value: `${hour}:00:${suffix}`,
      label: `${hour}:00:${suffix}`,
    }
  })
)
