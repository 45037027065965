import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Row, Col } from 'react-bootstrap'
import { GlgLoadingSpinner } from 'SharedComponents'

class PersonalDataComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <div className="personal-data"><Row className="title"><span>{ !window.I18n ? '' : window.I18n.t('player_activity.registration.personal_data.title') }</span>{ this.props.MRR && <a href={ this.props.editRegistrationLink } target={ this.props.target } className="edit-btn"><i className="fa fa-edit"></i>{ !window.I18n ? '' : window.I18n.t('player_activity.registration.personal_data.edit_registration_btn') }</a>}</Row><Row className="content"><div className="panel"><div className="panel-body"><GlgLoadingSpinner show={ this.props.dataIsLoading }></GlgLoadingSpinner>{ this.props.personalData.map((e, index) => {
                  if (e.label !== '' && e.value !== '') {
                    return <Col sm={ 6 } xs={ 12 } key={ index }><span className="field">{ e.label + ':' }</span><span className="value">{ e.value }</span></Col>
                  } else {
                    return ''
                  }
                })
              }</div></div></Row></div>
  }
}

PersonalDataComponent.propTypes = {
  editRegistrationLink: PropTypes.string.isRequired,
  dataIsLoading: PropTypes.bool,
  personalData: PropTypes.array.isRequired,
  target: PropTypes.string,
  MRR: PropTypes.bool,
}

export default PersonalDataComponent
