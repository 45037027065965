import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

class ReportCenterItemInlineArchived extends Component {
  stringParameterize(str) {
    return str.trim().toLowerCase()
      .replace(/[^a-zA-Z0-9 -]/g, '')
      .replace(/ +/g, ' ')
      .replace(/[\s-]/g, '_')
  }

  render() {
    return (
      <Fragment>
        <div className= { 'report-list-item report_center_item_inline' + ( this.props.report_center_item.display ? '' : ' hidden')} >
          <div className= { 'name' + ([ 'Gpu::Report', 'Pairing' ].indexOf(this.props.report_center_item.report_type) !== -1 && this.props.report_center_item.name.length > 18 ? ' small-name' : '')}>
            <div className='nameContainer'>
              {!window.I18n ? '' : 
                              window.I18n.t(`download_center.item_name.${this.stringParameterize(this.props.report_center_item.name)}`,
                              {defaultValue: this.props.report_center_item.name.toUpperCase()})}
            </div>
          </div>

          <div className='body'>
            <div>
              <div className={ 'picture' + ((this.props.report_center_item.image_url === null) ? ' no-image' : '')}>
                <img className={this.props.report_center_item.report_type === 'SpreadsheetReport' && this.props.report_center_item.image_url !== null ? 'spreadsheet' : '' } src={this.props.report_center_item.image_url || require('report_center/no-image.png')} loading="lazy"/>
              </div>
              <div className='menu'>
                {/* report buttons saga */}
                { [ 'unrestricted', 'restricted' ].includes(this.props.report_center_item.access) &&
                  <div>
                    <a onClick={this.props.unarchiveItem}>
                      <div className={ 'btsp_orange_button'}>
                        {!window.I18n ? '' : window.I18n.t('download_center.unarchive')}
                      </div>
                    </a>
                    <a onClick={this.props.deleteItem}>
                      <div className='btsp_orange_button'>
                      {!window.I18n ? '' : window.I18n.t('download_center.delete')}
                      </div>
                    </a>
                    {
                      this.props.report_center_item.enabled_tooltip && 
                        <div className="report-disabled">{ this.props.report_center_item.enabled_tooltip }</div>
                    }
                  </div>  
                }
              </div>
            </div>
          </div>

          <div className='stats'>
            <span className='pull-left'>
              <i className='fa fa-fw fa-list'></i>
              { this.props.report_center_item.list_count || 0}
            </span>
            { 
              this.props.report_center_item.image_url ?
                <a onClick={ this.props.togglePreview } className="zoom">
                  <i className='fa fa-fw fa-search'></i>
                  {
                    this.props.show_preview && 
                      <div>
                        <div className="shadow"></div>
                        <img src={this.props.report_center_item.image_url || require('report_center/no-image.png')} />
                        <a onClick={ this.props.togglePreview }>
                          <img src={require('bootstrap_theme/close.png')} className="close_button"/>
                        </a>  
                      </div>
                  }
                </a>
              :
                <i className="gga-info-circle" title={!window.I18n ? '' : window.I18n.t('download_center.run_report_for_preview')}></i>
            }
            <span className='pull_right'>
              <i className='fa fa-fw fa-print'></i>
              { this.props.report_center_item.print_count || 0 }
            </span>
          </div>
        </div>
      </Fragment>
    )
  }
}

ReportCenterItemInlineArchived.propTypes = {
  report_center_item: PropTypes.object.isRequired,
  togglePreview: PropTypes.func.isRequired,
  show_preview: PropTypes.bool.isRequired,
  deleteItem: PropTypes.func.isRequired,
  unarchiveItem: PropTypes.func.isRequired,
}

export default ReportCenterItemInlineArchived
