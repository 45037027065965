import _ from 'lodash'

import { PopupActionTypes, openPopup } from 'Shared/actions'
import PopupTypes from '../popup_types'
export { openPopup, closePopup } from 'Shared/actions'

import { showErrorNotification, showNotification } from 'Shared/helpers'
import { getSortedVisibleLeagueIds } from '../selectors'

export const ActionTypes = {
  ...PopupActionTypes,
  OPEN_CATEGORY: 'OPEN_CATEGORY',
  CLOSE_CATEGORY: 'CLOSE_CATEGORY',
  GET_LEAGUES: 'GET_LEAGUES',
  SET_CATEGORY_LEAGUE_COUNTS: 'SET_CATEGORY_LEAGUE_COUNTS',
  TOGGLE_CATEGORY: 'TOGGLE_CATEGORY',
  APPEND_LEAGUES: 'APPEND_LEAGUES',
  SET_CATEGORY_LOADING_DATA: 'SET_CATEGORY_LOADING_DATA',
  CHANGE_FILTER: 'CHANGE_FILTER',
  CHANGE_FILTER_TIMER: 'CHANGE_FILTER_TIMER',
  GET_LEAGUES_FIELD_DATA: 'GET_LEAGUES_FIELD_DATA',
  GET_LEAGUE_POTENTIAL_CLONE_CANDIDATE: 'GET_LEAGUE_POTENTIAL_CLONE_CANDIDATE',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  UPDATE_CATEGORY: 'UPDATE_CATEGORY',
  CREATE_CATEGORY: 'CREATE_CATEGORY',
  CREATE_CATEGORY_RESPONSE: 'CREATE_CATEGORY_RESPONSE',
  REORDER_LEAGUES: 'REORDER_LEAGUES',
  REORDER_CATEGORIES: 'REORDER_CATEGORIES',
  CHANGE_SORTING_COLUMN: 'CHANGE_SORTING_COLUMN',
  CHANGE_SORTING_ORDER: 'CHANGE_SORTING_ORDER',
  DELETE_LEAGUE: 'DELETE_LEAGUE',
  CHANGE_LEAGUE_CATEGORY: 'CHANGE_LEAGUE_CATEGORY',
  CHANGE_LEAGUE_SEASON: 'CHANGE_LEAGUE_SEASON',
  CHANGE_LEAGUE_TOUR: 'CHANGE_LEAGUE_TOUR',
  OPEN_REGISTRATION: 'OPEN_REGISTRATION',
  CLOSE_REGISTRATION: 'CLOSE_REGISTRATION',
  UPDATE_OPEN_REGISTRATION_STATUS: 'UPDATE_OPEN_REGISTRATION_STATUS',
  FETCH_INITIAL_DATA_REQUEST: 'FETCH_INITIAL_DATA_REQUEST',
  FETCH_INITIAL_DATA_RECEIVED: 'FETCH_INITIAL_DATA_RECEIVED',
  CHANGE_DEFAULT_COURSE: 'CHANGE_DEFAULT_COURSE',
  SET_FILTER_LOADING_STATUS: 'SET_FILTER_LOADING_STATUS',
}

export const toggleCategory = id => (dispatch, getState) => {
  const filter = getState().filters.leagueName
  if (filter !== '') {
    return
  }

  const path = getState().misc.fetchCategoryLeaguesURL
  const filters = getState().filters

  const isCategoryOpen = getState().categories[id].opened
  if (isCategoryOpen) {
    dispatch({
      type: ActionTypes.TOGGLE_CATEGORY,
      id,
    })

    return
  }

  dispatch({
    type: ActionTypes.SET_CATEGORY_LOADING_DATA,
    id: id,
    loadingData: true,
  })
  $.ajax({
    method: 'GET',
    url: path,
    data: {
      format: 'json',
      categoryId: id,
      page: 0,
      filters: filters,
    },
  })
  .done((response) => {
    dispatch({
      type: ActionTypes.GET_LEAGUES,
      leagues: response.data,
    })

    if (response.categoryCounts !== null) {
      dispatch({
        type: ActionTypes.SET_CATEGORY_LEAGUE_COUNTS,
        categoryCounts: response.categoryCounts,
      })
    }

    dispatch({
      type: ActionTypes.TOGGLE_CATEGORY,
      id,
    })
  })
  .fail(() => {
    dispatch({
      type: ActionTypes.SET_CATEGORY_LOADING_DATA,
      id: id,
      loadingData: false,
    })
    showErrorNotification('Error while retreiving the category leagues!')
  })
}

export const addLeaguesWithPagination = id => (dispatch, getState) => {
  let leaguesCount
  let maxLeaguesCount
  if (id !== null) {
    leaguesCount = Object.values(getState().leagues).filter(league => league.category === id).length
    maxLeaguesCount = getState().categories[id].leaguesCount
  } else {
    leaguesCount = Object.values(getState().leagues).length
    maxLeaguesCount = Object.values(getState().categories)
                            .map(category => category.leaguesCount)
                            .reduce((a, b) => a + b, 0)
  }

  if (leaguesCount <= 0 || leaguesCount >= maxLeaguesCount) {
    return
  }

  const path = getState().misc.fetchCategoryLeaguesURL
  const filters = getState().filters
  if (id !== null) {
    dispatch({
      type: ActionTypes.SET_CATEGORY_LOADING_DATA,
      id: id,
      loadingData: true,
    })
  }
  $.ajax({
    method: 'GET',
    url: path,
    data: {
      format: 'json',
      categoryId: id,
      filters: filters,
      page: Math.floor(leaguesCount / 100),
    },
  })
  .done((response) => {
    dispatch({
      type: ActionTypes.APPEND_LEAGUES,
      leagues: response.data,
    })

    if (id !== null) {
      dispatch({
        type: ActionTypes.SET_CATEGORY_LOADING_DATA,
        id: id,
        loadingData: false,
      })
    }
  })
  .fail(() => {
    if (id !== null) {
      dispatch({
        type: ActionTypes.SET_CATEGORY_LOADING_DATA,
        id: id,
        loadingData: false,
      })
    }
    showErrorNotification('Error while retreiving the category leagues!')
  })
}

export const openCategory = id => ({
  type: ActionTypes.OPEN_CATEGORY,
  id,
})

export const closeCategory = id => ({
  type: ActionTypes.CLOSE_CATEGORY,
  id,
})

export const changeDefaultCourse = id => ({
  type: ActionTypes.CHANGE_DEFAULT_COURSE,
  id,
})

const callUserSettingsUpdateApi = (url, key, value) => {
  if (url) {
    $.ajax({
      method: 'POST',
      url,
      data: {
        name: 'customer_center',
        update_hash: {
          [key]: value,
        },
      },
    })
  } else {
    console.warn('Skipped user settings update because no url available')
  }
}

const PERSISTENT_SETTINGS = [
  'viewType',
  'advancedFiltersOn',
  'season',
  'category',
  'tour',
  'registration',
]

export const changeFilter = (filter, value) => (dispatch, getState) => {
  const path = getState().misc.fetchCategoryLeaguesURL
  const filters = getState().filters

  const tmpFilters = _.cloneDeep(filters)
  const categories = getState().categories
  const openedCategory = Object.values(categories).filter(category => category.opened === true)
  if (openedCategory.length !== 0) {
    tmpFilters['category'] = openedCategory[0].id
  }

  clearTimeout(filters.timer)
  dispatch({
    type: ActionTypes.SET_FILTER_LOADING_STATUS,
    loading: true,
  })
  const timer = window.setTimeout(() => {
    $.ajax({
      method: 'GET',
      url: path,
      data: {
        format: 'json',
        filters: tmpFilters,
        currentFilterName: filter,
        currentFilterValue: value,
        page: 0,
      },
    })
    .done((response) => {
      dispatch({
        type: ActionTypes.GET_LEAGUES,
        leagues: response.data,
      })

      if (response.categoryCounts !== null) {
        dispatch({
          type: ActionTypes.SET_CATEGORY_LEAGUE_COUNTS,
          categoryCounts: response.categoryCounts,
        })
      }

      dispatch({
        type: ActionTypes.SET_FILTER_LOADING_STATUS,
        loading: false,
      })

      dispatch({
        type: ActionTypes.CHANGE_FILTER_TIMER,
        timer: null,
      })
    })
    .fail(() => {
      showErrorNotification('Error while retreiving filtered leagues!')

      dispatch({
        type: ActionTypes.SET_FILTER_LOADING_STATUS,
        loading: false,
      })
    })
  }, 1000)

  dispatch({
    type: ActionTypes.CHANGE_FILTER_TIMER,
    timer: timer,
  })

  dispatch({
    type: ActionTypes.CHANGE_FILTER,
    filter,
    value,
  })

  if (PERSISTENT_SETTINGS.includes(filter)) {
    const url = getState().misc.updateUserSettingsURL
    callUserSettingsUpdateApi(url, filter, value)
  }
}

export const getLeagueFieldData = () => (dispatch, getState) => {
  const path = getState().misc.fetchCloneLeaguesURL
  const leagueId = getState().popupStates.cloneEvent.data.leagueId

  $.ajax({
    method: 'GET',
    url: path,
    data: {
      format: 'json',
      page: 0,
      league_id: leagueId,
    },
  })
  .done((response) => {
    dispatch({
      type: ActionTypes.GET_LEAGUES_FIELD_DATA,
      leagues: response.data,
    })
    dispatch({
      type: ActionTypes.GET_LEAGUE_POTENTIAL_CLONE_CANDIDATE,
      leagues: response.league,
    })
  })
  .fail(() => {
    showErrorNotification('Error while retreiving filtered leagues for clone event!')
  })
}

export const getLeaguePotentialCloneCandidate = (leagueID) => (dispatch, getState) => {
  const path = getState().misc.fetchLeaguePotentialCloneCandidateURL

  $.ajax({
    method: 'GET',
    url: path,
    data: {
      format: 'json',
      league_id: leagueID,
    },
  })
  .done((response) => {
    dispatch({
      type: ActionTypes.GET_LEAGUE_POTENTIAL_CLONE_CANDIDATE,
      leagues: response.data,
    })
  })
  .fail(() => {
    showErrorNotification('Error while retreiving potential clone candidate for clone event!')
  })
}

export const deleteCategory = (id) => (dispatch, getState) => {
  const state = getState()
  const category = state.categories[id]
  const url = category.updateURL
  const categoryName = category.name
  const leaguesCount = category.leagues.length
  const defaultCategory = _.find(_.values(state.categories), [ 'specialStatus', 'default' ])
  $.ajax({
    method: 'DELETE',
    url,
    success: () => {
      let notificationMessage = `Category ${ categoryName } removed successfully.`
      if (leaguesCount > 0) {
        notificationMessage += ` Your leagues / events were moved to ${ defaultCategory.name }.`
      }
      showNotification(notificationMessage)
    },
    error: () => {
      showErrorNotification(`Error while deleting category ${ categoryName }.`)
    },
  })
  dispatch({
    type: ActionTypes.DELETE_CATEGORY,
    id,
    defaultCategoryId: defaultCategory.id,
  })
}

export const updateCategory = (id, data) => (dispatch, getState) => {
  const url = getState().categories[id].updateURL
  const categoryName = getState().categories[id].name
  $.ajax({
    method: 'PATCH',
    url,
    data: {
      format: 'json',
      list: {
        name: data.name,
        color: data.color,
        is_free: data.isFree,
      },
    },
    success: (responseData) => {
      if (responseData.errorMessages && !_.isEmpty(responseData.errorMessages)) {
        showErrorNotification(`Error while updating category ${ categoryName }. ${ responseData.errorMessages }`)
      }
    },
    error: () => {
      showErrorNotification(`Error while updating category ${ categoryName }.`)
    },
  })
  dispatch({
    type: ActionTypes.UPDATE_CATEGORY,
    id,
    data,
  })
}

const START_CATEGORY_ID = 1000000000
let NEXT_CATEGORY_ID = START_CATEGORY_ID
export const createCategory = (data) => (dispatch, getState) => {
  const customerId = getState().misc.managedCustomerId
  const url = getState().misc.createCategoryURL
  const position = 0
  const tempId = `TEMP_${ NEXT_CATEGORY_ID++ }`

  $.ajax({
    method: 'POST',
    url,
    data: {
      format: 'json',
      list: {
        name: data.name,
        color: data.color,
        is_free: data.isFree,
        customer_id: customerId,
        position,
      },
    },
    success: (responseData) => {
      if (responseData.errorMessages && !_.isEmpty(responseData.errorMessages)) {
        showErrorNotification(responseData.errorMessages)
      } else {
        showNotification(`Successfully created category ${ data.name }.`)
      }
      dispatch({
        type: ActionTypes.CREATE_CATEGORY_RESPONSE,
        oldId: tempId,
        newId: responseData.categoryId,
        updateURL: responseData.updateURL,
      })
    },
    error: () => {
      showErrorNotification(`Error while creating category ${ data.name }.`)
    },
  })
  dispatch({
    type: ActionTypes.CREATE_CATEGORY,
    id: tempId,
    data: {
      ...data,
      id: tempId,
      position,
    },
  })
}

const getLeagueIdByCategoryAndIndex = (state, categoryId, index) => {
  const sortedLeagueIds = getSortedVisibleLeagueIds(state, categoryId)
  if (index < sortedLeagueIds.length) {
    return sortedLeagueIds[index]
  } else {
    // This should only happen when dragging to an empty category
    return null
  }
}

// Can be from Category View or List View
export const reorderLeagues = (fromIndex, toIndex, fromCategoryId, toCategoryId) => (dispatch, getState) => {
  if (fromIndex === toIndex && fromCategoryId === toCategoryId) {
    return
  }
  if ((fromCategoryId && fromCategoryId.startsWith('TEMP')) ||
      (toCategoryId && toCategoryId.startsWith('TEMP'))) {
    return
  }
  const state = getState()
  const url = state.misc.reorderLeaguesURL
  const customerId = state.misc.customerId
  const fromId = getLeagueIdByCategoryAndIndex(state, fromCategoryId, fromIndex)

  if (fromCategoryId !== toCategoryId) {
    // The source league will be moved to the first position of the target category,
    // so the target id will be calculated with the adjusted target index
    toIndex -= 1
  }

  let toId
  if (toIndex === -1) {
    // Because we move it to the first position of the target category anyway,
    // no need to adjust it after
    toId = null
  } else {
    toId = getLeagueIdByCategoryAndIndex(state, toCategoryId, toIndex)
  }

  $.ajax({
    method: 'POST',
    url,
    data: {
      from_id: fromId,
      to_id: toId,
      id: fromCategoryId,
      to_list_id: toCategoryId,
      customer_id: customerId,
      format: 'json',
    },
    success: data => {
      if (data.errorMessage) {
        showErrorNotification(data.errorMessage)
      }
    },
    error: () => {
      showErrorNotification('Error when reordering events!')
    },
  })

  dispatch({
    type: ActionTypes.REORDER_LEAGUES,
    fromCategoryId,
    fromId,
    toCategoryId,
    toId,
  })
}

export const reorderCategories = (fromId, toId) => (dispatch, getState) => {
  if (fromId === toId) {
    return
  }
  const url = getState().misc.reorderCategoriesURL
  $.ajax({
    method: 'POST',
    url,
    data: {
      from_id: fromId,
      to_id: toId,
    },
    error: () => {
      showErrorNotification('Error when reordering categories!')
    },
  })
  dispatch({
    type: ActionTypes.REORDER_CATEGORIES,
    fromId,
    toId,
  })
}

const changeSortingData = (type, key) => (value) => (dispatch, getState) => {
  if (getState().sorting[key] !== value) {
    dispatch({
      type,
      [key]: value,
    })
    const url = getState().misc.updateUserSettingsURL
    callUserSettingsUpdateApi(url, `sorting_${ key }`, value)
  }
}
export const changeSortingColumn = changeSortingData(ActionTypes.CHANGE_SORTING_COLUMN, 'column')
export const changeSortingOrder = changeSortingData(ActionTypes.CHANGE_SORTING_ORDER, 'order')

const callChangeCategoryAPI = requestObject => {
  $.ajax({
    success: data => {
      if (data.errorMessage) {
        showErrorNotification(data.errorMessage)
      }
    },
    ...requestObject,
  })
}

export const deleteLeague = (id) => (dispatch, getState) => {
  const state = getState()
  const url = state.misc.changeCategoryURL
  const fromCategoryId = state.leagues[id].category
  const archivedCategoryId = _.findKey(state.categories, { specialStatus: 'archived' })
  const leagueName = state.leagues[id].name
  const leagueProduct = state.leagues[id].product
  $.ajax({
    method: 'POST',
    url,
    data: {
      league_id: id,
      from_list_id: fromCategoryId,
      id: archivedCategoryId,
      delete: true,
      format: 'json',
    },
    success: (data) => {
      if (data.errorMessage) {
        showErrorNotification(data.errorMessage)
      } else {
        showNotification(`Successfully deleted ${ _.capitalize(leagueProduct) } ${ leagueName }.`)
      }
    },
    error: () => {
      showErrorNotification(`Error while deleting ${ _.capitalize(leagueProduct) } ${ leagueName }.`)
    },
  })
  dispatch({
    type: ActionTypes.DELETE_LEAGUE,
    id,
    categoryId: getState().leagues[id].category,
  })
}

export const archiveLeague = (id) => (dispatch, getState) => {
  const url = getState().misc.changeCategoryURL
  const fromCategoryId = getState().leagues[id].category
  const archivedCategoryId = _.findKey(getState().categories, { specialStatus: 'archived' })
  callChangeCategoryAPI({
    method: 'POST',
    url,
    data: {
      league_id: id,
      from_list_id: fromCategoryId,
      id: archivedCategoryId,
      format: 'json',
    },
  })
  dispatch({
    type: ActionTypes.CHANGE_LEAGUE_CATEGORY,
    leagueId: id,
    fromCategoryId,
    toCategoryId: archivedCategoryId,
  })
}

export const restoreLeague = (id) => (dispatch, getState) => {
  const url = getState().misc.changeCategoryURL
  const fromCategoryId = getState().leagues[id].category
  const defaultCategoryId = _.findKey(getState().categories, { specialStatus: 'default' })
  callChangeCategoryAPI({
    method: 'POST',
    url,
    data: {
      league_id: id,
      from_list_id: fromCategoryId,
      id: defaultCategoryId,
      format: 'json',
    },
  })
  dispatch({
    type: ActionTypes.CHANGE_LEAGUE_CATEGORY,
    leagueId: id,
    fromCategoryId,
    toCategoryId: defaultCategoryId,
  })
}

export const changeLeagueCategory = (leagueId, toCategoryId) => (dispatch, getState) => {
  // If the target category is not created yet (temporary id), skip
  if (toCategoryId.startsWith('TEMP')) {
    return
  }
  const url = getState().misc.changeCategoryURL
  const fromCategoryId = getState().leagues[leagueId].category

  if (fromCategoryId !== toCategoryId) {
    callChangeCategoryAPI({
      method: 'POST',
      url,
      data: {
        league_id: leagueId,
        from_list_id: fromCategoryId,
        id: toCategoryId,
        format: 'json',
      },
    })
    dispatch({
      type: ActionTypes.CHANGE_LEAGUE_CATEGORY,
      leagueId,
      fromCategoryId,
      toCategoryId,
    })
  }
}

export const changeLeagueSeason = (leagueId, seasonId) => (dispatch, getState) => {
  const url = getState().misc.changeSeasonURL
  $.ajax({
    method: 'POST',
    url,
    data: {
      league_id: leagueId,
      season_id: seasonId || '0',
    },
  })
  dispatch({
    type: ActionTypes.CHANGE_LEAGUE_SEASON,
    leagueId,
    seasonId,
  })
}

export const changeLeagueTour = (leagueId, tourId) => (dispatch, getState) => {
  const url = getState().leagues[leagueId].changeTourURL
  $.ajax({
    method: 'POST',
    url,
    data: {
      tour_id: tourId,
    },
  })
  dispatch({
    type: ActionTypes.CHANGE_LEAGUE_TOUR,
    leagueId,
    tourId,
  })
}

export const openRegistration = (leagueId) => (dispatch, getState) => {
  const url = getState().misc.updateRegistrationURL
  $.ajax({
    method: 'POST',
    url,
    data: {
      league_id: leagueId,
      toggle_value: 'open',
    },
  })

  dispatch({
    type: ActionTypes.OPEN_REGISTRATION,
    leagueId,
  })
}

export const closeRegistration = (leagueId) => (dispatch, getState) => {
  const url = getState().misc.updateRegistrationURL
  $.ajax({
    method: 'POST',
    url,
    data: {
      league_id: leagueId,
      toggle_value: '',
    },
  })

  dispatch({
    type: ActionTypes.CLOSE_REGISTRATION,
    leagueId,
  })
}

export const updateOpenRegistrationStatus = (leagueId, status) => (dispatch, getState) => {
  const url = getState().misc.updateRegistrationURL
  $.ajax({
    method: 'POST',
    url,
    data: {
      league_id: leagueId,
      toggle_value: status,
    },
  })

  dispatch({
    type: ActionTypes.UPDATE_OPEN_REGISTRATION_STATUS,
    leagueId,
    status,
  })
}

const fetchInitialDataRequest = () => ({
  type: ActionTypes.FETCH_INITIAL_DATA_REQUEST,
})

const fetchInitialDataReceived = (data, mainData) => ({
  type: ActionTypes.FETCH_INITIAL_DATA_RECEIVED,
  data,
  mainData,
})

const callAPI = (baseURL, isMainData, filters = null) => {
  let url = baseURL
  url += url.includes('?') ? '&' : '?'
  url += `main_data=${ isMainData }`

  if (filters !== null) {
    url += url.includes('?') ? '&' : '?'
    url += `filters=${ JSON.stringify(filters) }`
  }

  return fetch(url, { method: 'GET', credentials: 'same-origin' })
  .then(response => Promise.all([ response, response.json() ]))
  .then(([ response, json ]) => {
    if (response.status === 200) {
      return json
    } else {
      showErrorNotification('Error while fetching initial data!')
    }
  })
  .catch(() => {
    showErrorNotification('Error while fetching initial data!')
  })
}

export const fetchInitialData = (filters = null) => (dispatch, getState) => {
  dispatch(fetchInitialDataRequest())
  const state = getState()
  const dataURL = state.misc.fetchInitialDataURL
  const isSalesDemo = state.misc.isSalesDemo
  const initialPopupAction = state.misc.initialPopupAction
  if (!isSalesDemo && dataURL) {
    callAPI(dataURL, true, filters)
      .then(mainJson => {
        const leaguesData = mainJson.leagues
        mainJson.leagues = {}

        dispatch(fetchInitialDataReceived(mainJson, true))

        const managedCustomerId = state.misc.managedCustomerId
        if (managedCustomerId) {
          callAPI(dataURL, false, filters)
            .then(json => {
              const combinedLeaguesData = _.mapValues(leaguesData, league => ({
                ...json.leagues[league.id],
                ...league,
              }))
              json.leagues = combinedLeaguesData

              dispatch(fetchInitialDataReceived(json, false))
              const initialPopupId = getInitialPopup(initialPopupAction)
              if (initialPopupId) {
                dispatch(openPopup(initialPopupId, {
                  operation: 'add', // for Create New Category
                }))
              }
            })
        }
      })
  }
}

const getInitialPopup = (initialPopupAction) => {
  if (initialPopupAction === 'clone') {
    return PopupTypes.CLONE_EVENT
  } else if (initialPopupAction === 'create') {
    return PopupTypes.CREATE_EVENT
  } else if (initialPopupAction === 'quick_event') {
    return PopupTypes.QUICK_EVENT
  } else if (initialPopupAction === 'category') {
    return PopupTypes.CATEGORY_POPUP
  } else {
    return null
  }
}
