import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TetherComponent from 'react-tether'
import onClickOutside from 'react-onclickoutside'

import GlgIcon from './glg_icon'
import GlgTooltipIcon from './glg_tooltip_icon'

class DropdownMenu extends Component {
  constructor(props) {
    super(props)
  }

  handleItemClick(e, callback) {
    if (callback) {
      e.preventDefault()
      callback()
    }
    if (this.props.onClickItem) {
      this.props.onClickItem()
    }
  }

  render() {
    return <ul className="dropdown-menu glg-react-dropdown-menu">{this.props.items.map((item, index) => <li key={ index }><a href={ item.url || '#' } onClick={ e => this.handleItemClick(e, item.onClick) } className={ item.disabled ? 'disabled' : '' }>{ item.label }{item.tooltip && <GlgTooltipIcon tooltip={ item.tooltip }></GlgTooltipIcon>}</a></li>)}</ul>
  }
}

DropdownMenu.propTypes = {
  items: PropTypes.array.isRequired,
  handleClickOutside: PropTypes.func.isRequired,
  onClickItem: PropTypes.func,
}

const WrappedDropdownMenu = onClickOutside(DropdownMenu)



class GlgDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.handleClickOutsideOfMenu = this.handleClickOutsideOfMenu.bind(this)
  }

  handleButtonClick() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleClickOutsideOfMenu(e) {
    if (e.target.parentElement !== this.buttonRef) {
      this.setState({ isOpen: false })
    }
  }

  render() {
    const otherDirection = this.props.direction === 'left' ? 'right' : 'left'
    const attachment = `top ${ otherDirection }`
    const targetAttachment = `bottom ${ otherDirection }`

    return <div className="glg-react-dropdown"><TetherComponent attachment={ attachment } targetAttachment={ targetAttachment } offset="4px 0" constraints={ [ { to: 'window', pin: true } ] }><div className="dropdown content-dropdown"><a className="dropdown-button dropdown-toggle dropdown_button_icon clickable" onClick={ this.handleButtonClick } ref={ buttonRef => { this.buttonRef = buttonRef } }><GlgIcon icon="carret-down"></GlgIcon></a></div>{this.state.isOpen && <WrappedDropdownMenu items={ this.props.items } handleClickOutside={ this.handleClickOutsideOfMenu } onClickItem={ this.handleButtonClick }></WrappedDropdownMenu>}</TetherComponent></div>
  }
}

GlgDropdown.propTypes = {
  // An array of options
  items: PropTypes.arrayOf(PropTypes.shape({
    // the text of the option
    label: PropTypes.string.isRequired,
    // if url is passed, it's just a link
    url: PropTypes.string,
    // if onClick is passed the url is ignored and the callback is called
    onClick: PropTypes.func,
    // if true, the option will be disabled
    disabled: PropTypes.bool,
    // if tooltip is passed there will be an icon with this text (and a tooltip id is needed)
    tooltip: PropTypes.string,
    id: PropTypes.string,
  })).isRequired,
  // The direction in which the dropdown opens: left or right
  direction: PropTypes.string,
}

GlgDropdown.defaultProps = {
  direction: 'right',
}

export default GlgDropdown
