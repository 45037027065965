import { connect } from 'react-redux'
import MenuItemCloseButton from '../components/menu/menu_item_close_button'
import { toggleMenu } from '../actions/index'

// const mapStateToProps = () => {}

const mapDispatchToProps = (dispatch) => ({
  toggleMenu: () => {
    dispatch(toggleMenu())
  },
})

export default connect(
  null, // mapStateToProps,
  mapDispatchToProps,
)(MenuItemCloseButton)
