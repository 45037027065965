import { connect } from 'react-redux'
import VerificationPopupComponent from '../components/verification_popup_component'

import { closePopup, verifyScore } from '../actions'

const mapStateToProps = (state) => {
  return {
    playerName: state.popup.playerName,
    pars: state.popup.pars,
    scoreData: state.popup.scoreData,
    shots: state.popup.shots,
    show: state.popup.visible,
    tableTitle: state.misc.roundName + ' - ' + state.misc.courseName + ' - ' + state.popup.teeTime,
    isMultiRound: state.misc.isMultiRound,
    autoFillScore: state.misc.autoFillScore,
    showShots: state.misc.step === 'volunteer_step' && state.misc.shotTrackingEnabled,
    step: state.misc.step,
    isStableford: state.misc.isStableford,
  }
}

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => dispatch(closePopup()),
  verifyScore: (oldScores, newScores) => dispatch(verifyScore(oldScores, newScores)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificationPopupComponent)
