import { connect } from 'react-redux'
import Pairings from '../../components/content/pairings'
import { toggleRoundOptions } from '../../actions/index'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleRoundOptions: (url, option, roundId) => dispatch(toggleRoundOptions(ownProps.widgetId, url, option, roundId)),
  refresh: () => dispatch(ownProps.refresh),
})

export default connect(
  null,
  mapDispatchToProps,
)(Pairings)
