import { connect } from 'react-redux'
import EventSetupOtherOptions from '../../components/content/event_setup_other_options'
import {
  saveEventSettingsWidget,
  setGenericData,
} from '../../actions/index'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => ({
  setData: (keys, value, { updateDataChanged = true } = {}) => {
    dispatch(setGenericData(ownProps.widgetId, keys, value))
    if (updateDataChanged && !ownProps.content.dataChanged) {
      dispatch(setGenericData(ownProps.widgetId, [ 'dataChanged' ], true))
    }
  },
  save: () => {
    const { data, links } = ownProps.content
    const requestData = {
      default_gender: data.default_gender.selected,
      [ data.league_name.key ]: data.league_name.value,
    }
    if ('rounds' in data) {
      requestData.rounds_data = data.rounds.data
    }
    dispatch(saveEventSettingsWidget(ownProps.widgetId, links.save.path, requestData))
      .finally(() => { dispatch(ownProps.refresh) })
  },
})

export default connect(
  null,
  mapDispatchToProps,
)(EventSetupOtherOptions)
