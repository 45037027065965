import { showErrorNotification, callAPI } from 'Shared/helpers'

export const ActionTypes = {
  REFRESH_DATA: 'REFRESH_DATA',
}

export const refreshDataAction = (message) => ({
  type: ActionTypes.REFRESH_DATA,
  message,
})

export const refreshData = () => (dispatch, getState) => {
  const state = getState()
  const roundGgid = state.misc.roundGgid
  const url = '/monster_boards/' + roundGgid

  callAPI(url, 'GET', {})
    .then(response => {
      dispatch(refreshDataAction(response))
    })
    .catch(() => {
      showErrorNotification('Error while refreshing data!')
    })
}
