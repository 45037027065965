import React, { Component } from 'react'
import { GlgCustomDropdown } from 'SharedComponents'
import { MembersCheckboxPanel } from './members_checkbox_panel'
import PropTypes from 'prop-types'
import MembersToggleContainer from '../../containers/members_filter/members_toggle_container'
// import MembersToggle from './members_toggle'

export class MembersFilterComponent extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }
  
  onChange(checked, value, category){
    this.props.changeFilterOptions(category, value, checked)
  }
  
  render(){
    const panel = MembersCheckboxPanel
    return <div className="dropdown dropdown_show_more_members"><GlgCustomDropdown dropdownPanel={panel} button={<MembersToggleContainer></MembersToggleContainer>} optionsForPanel={this.props.filterOptions} offsetY={0} onChange={ this.onChange } buttonContainerId={this.props.dropdownId} isMembersDataLoading={this.props.isMembersDataLoading} isMembersCountLoading={this.props.isMembersCountLoading}></GlgCustomDropdown></div>
  }
}

MembersFilterComponent.propTypes = {
  dropdownId: PropTypes.string.isRequired,
  fetchInitialMembersData: PropTypes.func.isRequired,
  filterOptions: PropTypes.object.isRequired,
  searchParams: PropTypes.object.isRequired,
  isMembersDataLoading: PropTypes.bool.isRequired,
  isMembersCountLoading: PropTypes.bool.isRequired,
  changeFilterOptions: PropTypes.func.isRequired,
  filterOptionsSet: PropTypes.bool.isRequired,
}

export default MembersFilterComponent
