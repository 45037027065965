import { connect } from 'react-redux'
import HostVenue from '../../components/content/host_venue'
import { checkHostVenueStatus } from '../../actions/index'

// const mapStateToProps = (state) => {}

const mapDispatchToProps = (dispatch, ownProps) => ({
  checkHostVenueStatus: (url) => dispatch(checkHostVenueStatus(ownProps.widgetId, url)),
})

export default connect(
  null,
  mapDispatchToProps,
)(HostVenue)
