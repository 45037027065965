import React, { Component } from 'react'
import PropTypes from 'prop-types'

class PlayersCount extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const { count, description } = this.props.content

    return (
      <div className='widget-content-statistics'>
          <div className='count'>{ count }</div>
          <div className='description'>{ description }</div>
      </div>
    )
  }
}

PlayersCount.propTypes = { 
  content: PropTypes.object,
}


PlayersCount.defaultProps = {
  content: {
    count: 0,
    description: !window.I18n ? '' : window.I18n.t('dashboard_menu.components.content.players_count.description'),
  },
}

export default PlayersCount
