import { connect } from 'react-redux'
import LeaderboardTableComponent from '../components/leaderboard_table_component'

import { getTableColumns, getSortedPlayers } from 'Shared/course_boards'
import {addLine, changeColumnSorting, refreshData, removeLine} from '../actions'

const mapStateToProps = (state, ownProps) => {
  const isMultiRound = state.misc.isMultiRound
  const withTeams = state.misc.withTeams
  const showCourse = state.misc.showCourse
  const columns = getTableColumns(isMultiRound, withTeams, showCourse, false)

  return {
    players: getSortedPlayers(state),
    columns: columns,
    sortParams: state.sortParams,
    height: ownProps.height,
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeColumnSorting: (column, sortOrder) => dispatch(changeColumnSorting(column, sortOrder)),
  addLine: (memberIds) => dispatch(addLine(memberIds)),
  removeLine: (position) => dispatch(removeLine(position)),
  forceRefresh: () => dispatch(refreshData()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaderboardTableComponent)
